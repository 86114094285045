import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const KnsTooltip = styled.div`
  line-height: 20px;
  background-color: #ffffff;
  font-variant-numeric: tabular-nums;

  .kns-tooltip-header {
    color: rgba(0, 0, 0, 0.85);
  }

  .kns-tooltip-item {
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    margin-top: 4px;

    .kns-tooltip-item-label {
      flex: 1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 8px;

      > span {
        vertical-align: middle;

        &.kns-tooltip-item-icon {
          margin-right: 8px;
          padding-right: 0;
        }
      }
    }

    .kns-tooltip-item-value {
      font-weight: 500;
      flex: 0 0 auto;
      text-align: right;
      color: rgba(0, 0, 0, 0.85);
    }
  }
`;

const propTypes = {
  points: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  header: PropTypes.string,
};

const defaultProps = {
  header: undefined,
};

class ChartTooltip extends PureComponent {
  convertUnicode = (input) =>
    input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
      const charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });

  generateGenericTooltipContent() {
    const { points, type } = this.props;
    const circle = this.convertUnicode('\u25CF');

    const sortedPoints =
      type === 'line' ? points.sort((a, b) => b.y - a.y) : points;

    return sortedPoints.map((point) => {
      if (!point.series) {
        return null;
      }

      const {
        name,
        userOptions: { nameFormatter, unitFormatter },
      } = point.series;

      return (
        <div key={name} className='kns-tooltip-item'>
          <div className='kns-tooltip-item-label'>
            <span
              className='kns-tooltip-item-icon'
              style={{ color: point.color, fontSize: '20px' }}
            >
              {circle}
            </span>
            <span className='kns-tooltip-item-name'>
              {nameFormatter ? nameFormatter(name) : name}
            </span>
          </div>
          <div className='kns-tooltip-item-value'>
            {unitFormatter ? unitFormatter(point.y) : point.y}
          </div>
        </div>
      );
    });
  }

  render() {
    const { header } = this.props;

    const headerElement = header ? (
      <div className='kns-tooltip-header'>{header}</div>
    ) : null;

    return (
      <KnsTooltip>
        {headerElement}
        {this.generateGenericTooltipContent()}
      </KnsTooltip>
    );
  }
}

ChartTooltip.propTypes = propTypes;
ChartTooltip.defaultProps = defaultProps;

export default ChartTooltip;
