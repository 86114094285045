import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getTime, parseISO } from 'date-fns/fp';
import { map, mapValues, max, min, pipe } from 'lodash/fp';

import { Label } from './sparkline.styles';

const HEIGHT = 36; // 20px line-height + 8px tick + 2 x 4px padding

const dateAsSecondsSinceEpoch = (date) =>
  pipe(parseISO, getTime, (ms) => Math.round(ms / 1000))(date);

const propTypes = {
  data: PropTypes.array.isRequired,
  leftRightMargin: PropTypes.number.isRequired,
  selectedDate: PropTypes.string,
  width: PropTypes.number.isRequired,
};

const defaultProps = {
  selectedDate: undefined,
};

const SparklineLabels = ({ data, leftRightMargin, selectedDate, width }) => {
  const refs = useRef({});
  const [widths, setWidths] = useState({});

  const dates = useMemo(
    () => map((d) => dateAsSecondsSinceEpoch(d.date), data),
    [data],
  );

  const usableWidth = width - 2 * leftRightMargin;
  const seriesMin = min(dates);
  const seriesMax = max(dates);
  const scalingFactor = usableWidth / (seriesMax - seriesMin);
  const selectedDateAsSeconds = selectedDate
    ? dateAsSecondsSinceEpoch(selectedDate)
    : undefined;

  useEffect(() => {
    setWidths(
      mapValues(
        (node) => node && node.getBBox && node.getBBox().width,
        refs.current,
      ),
    );
  }, [data, selectedDate]);

  const labelWithTick = (date, x, i, labelX, label) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={i}>
      <line x1={x} y1='4' x2={x} y2='12' stroke='#D9D9D9' />
      <Label
        x={labelX}
        y={HEIGHT - 6.25}
        ref={(ref) => {
          refs.current[date] = ref;
        }}
      >
        {label}
      </Label>
    </Fragment>
  );

  const multiLabel = () =>
    data.map((point, i) => {
      const x = (dates[i] - seriesMin) * scalingFactor + leftRightMargin;
      const labelX = x - (widths[point.date] || 0) / 2;
      const maxLabelX = width - widths[point.date];
      const fixedLabelX = Math.min(Math.max(0, labelX), maxLabelX);

      if (selectedDateAsSeconds) {
        return dates[i] === selectedDateAsSeconds
          ? labelWithTick(point.date, x, i, fixedLabelX, point.label)
          : undefined;
      }

      return labelWithTick(point.date, x, i, fixedLabelX, point.label);
    });

  const singleLabel = () => {
    const point = data[0];

    return labelWithTick(
      point.date,
      width / 2,
      0,
      (width - widths[point.date]) / 2,
      point.label,
    );
  };

  return (
    <svg viewBox={`0 0 ${width} ${HEIGHT}`} width={width} height={HEIGHT}>
      {data.length > 1 ? multiLabel() : singleLabel()}
    </svg>
  );
};

SparklineLabels.propTypes = propTypes;
SparklineLabels.defaultProps = defaultProps;

export default SparklineLabels;
