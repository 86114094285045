import { createSelector } from 'reselect';
import { filter, get, map, mapValues, mergeAll, pipe } from 'lodash/fp';
import { isAfter, parseISO } from 'date-fns/fp';
import attributesSelector from './attributesSelector';

const attributeFieldsSelector = createSelector(
  attributesSelector,
  (state, props) => get('locationId', props),
  (state, props) => get('type', props),
  (state, props) => get('date', props),
  (attributes, locationId, type, date) =>
    pipe(
      get(['values', locationId, 'attributes', type, 'time_series']),
      filter((entry) => !isAfter(parseISO(date), parseISO(entry.date))),
      map('fields'),
      mergeAll,
      mapValues('value'),
    )(attributes),
);

export default attributeFieldsSelector;
