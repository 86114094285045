import { useSelector } from 'react-redux';
import { get } from 'lodash/fp';
import { PathwaysVisualisationsError } from './error';
import { PathwaysVisualisationsPlaceholder } from './placeholder';
import { PathwaysVisualisationsGenerating } from './generating';
import { PathwaysVisualisationsResults } from './results';
import { VisualisationLayout } from './pathways-visualisations.styles';

const propTypes = {};
const defaultProps = {};

const PathwaysVisualisations = () => {
  const pathwaysState = useSelector(get(['pathways', 'state']));

  return (
    <VisualisationLayout>
      {pathwaysState === 'placeholder' && <PathwaysVisualisationsPlaceholder />}
      {pathwaysState === 'generating-fresh' && (
        <PathwaysVisualisationsGenerating />
      )}
      {pathwaysState === 'error' && <PathwaysVisualisationsError />}

      {['generating', 'fulfilled'].includes(pathwaysState) && (
        <PathwaysVisualisationsResults />
      )}
    </VisualisationLayout>
  );
};

PathwaysVisualisations.propTypes = propTypes;
PathwaysVisualisations.defaultProps = defaultProps;

export { PathwaysVisualisations };
