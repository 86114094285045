import PropTypes from 'prop-types';
import { Stack, Select, LabelText } from '@kinesis/bungle';
import { get, first, isEqual, filter } from 'lodash/fp';
import { useRef, useState, useCallback, useMemo } from 'react';
import {
  itemsFilter,
  labelOfMeasureOrBreakdown,
  groupKeyOf,
} from 'data/block/visualisation/configuration/series-select';
import {
  Row,
  Separator,
  InputContainer,
} from './visualisation-series-select.styles';
import { BreakdownLabel } from './breakdown-label';
import { DeleteSeriesButton } from './delete-series-button';
import { AddSeriesButton } from './add-series-button';

const propTypes = {
  defaultExpanded: PropTypes.number,
  defaultQuery: PropTypes.string,
  defaultAddSeries: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  expression: PropTypes.object.isRequired,
  unit: PropTypes.string,
};

const defaultProps = {
  defaultExpanded: undefined,
  defaultQuery: undefined,
  defaultAddSeries: false,
  unit: undefined,
};

const itemsRender = (item) => {
  const label = labelOfMeasureOrBreakdown(item);
  switch (item.type) {
    case 'expression':
      return label;
    case 'breakdown':
      return <BreakdownLabel label={label} />;
    default:
      return undefined;
  }
};

const SingleExpression = ({
  defaultExpanded,
  defaultQuery,
  defaultAddSeries,
  label,
  options,
  expression,
  onChange,
  onDelete,
  unit,
}) => {
  const expressions = useMemo(
    () => filter((o) => o.type === 'expression', options),
    [options],
  );
  const [addingSeries, setAddingSeries] = useState(defaultAddSeries);
  const newSeriesRef = useRef();
  const addNewSeries = useCallback(() => {
    if (newSeriesRef.current) {
      newSeriesRef.current.focus();
    }
    setAddingSeries(true);
  }, [setAddingSeries]);
  const removeNewSeries = useCallback(
    () => setAddingSeries(false),
    [setAddingSeries],
  );
  const handleOnChangeAtSlot = useCallback(
    (position) => (v) => {
      removeNewSeries();
      return onChange({ position, value: v });
    },
    [onChange, removeNewSeries],
  );
  const optionsWithoutSelection = useMemo(
    () => filter((o) => !isEqual(o.value, expression), options),
    [options, expression],
  );
  const allOptionsUsed = useMemo(
    () =>
      options.length === 1 && isEqual(get('value', first(options)), expression),
    [options, expression],
  );

  return (
    <Stack space='xsmall'>
      <LabelText>{label}</LabelText>
      {!addingSeries && (
        <InputContainer>
          <Select
            defaultExpanded={defaultExpanded === 0}
            defaultQuery={defaultQuery}
            itemKey='key'
            groupKey={groupKeyOf(expressions)}
            value={expression}
            onChange={handleOnChangeAtSlot(0)}
            options={expressions}
            search
            itemsLabel='measures'
            overrideEmptyText='Select an output to view available measures.'
            magnitude='large'
            renderSelected={itemsRender}
            itemsFilter={itemsFilter}
          />
        </InputContainer>
      )}
      {addingSeries && (
        <>
          <Row>
            <InputContainer>
              <Select
                defaultExpanded={defaultExpanded === 0}
                defaultQuery={defaultQuery}
                itemKey='key'
                groupKey={groupKeyOf(expressions)}
                value={expression}
                onChange={handleOnChangeAtSlot(0)}
                options={expressions}
                search
                itemsLabel='measures'
                overrideEmptyText='Select an output to view available measures.'
                magnitude='large'
                renderSelected={itemsRender}
                itemsFilter={itemsFilter}
              />
            </InputContainer>
            <Separator />
            <DeleteSeriesButton onClick={onDelete(0)} />
          </Row>
          <Row>
            <InputContainer>
              <Select
                defaultExpanded={defaultExpanded === 1}
                defaultQuery={defaultQuery}
                itemKey='key'
                groupKey={groupKeyOf(optionsWithoutSelection)}
                value={undefined}
                onChange={handleOnChangeAtSlot(1)}
                options={optionsWithoutSelection}
                search
                itemsLabel='measure or breakdowns'
                overrideEmptyText='Select an output to view available measures or breakdowns.'
                magnitude='large'
                renderSelected={itemsRender}
                itemsFilter={itemsFilter}
                ref={newSeriesRef}
              />
            </InputContainer>
            <Separator />
            <DeleteSeriesButton onClick={removeNewSeries} />
          </Row>
        </>
      )}
      <div>
        <AddSeriesButton
          tooltip={
            allOptionsUsed
              ? `All available ${unit} measures are shown`
              : undefined
          }
          disabled={allOptionsUsed}
          onClick={addNewSeries}
        />
      </div>
    </Stack>
  );
};

SingleExpression.propTypes = propTypes;
SingleExpression.defaultProps = defaultProps;

export { SingleExpression };
