import styled from 'styled-components';

const MapLegendIsland = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 16px;
  width: 304px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 2;
`;

export { MapLegendIsland };
