import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Separator = styled.div`
  flex: 0 0 4px;
`;

// FIXME: this is a workaround for having utility buttons that are the same
// size as the selects they sit next to, while keeping the icon size smaller.
const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  flex: 0 0 auto;
`;

const InputContainer = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;

const deleteIconColorOf = (theme) => theme.color.red6;

export {
  Row,
  Separator,
  ButtonContainer,
  InputContainer,
  IconContainer,
  deleteIconColorOf,
};
