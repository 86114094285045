import { createSelector } from 'reselect';
import workspaceSelector from 'selectors/workspaceSelector';
import { get } from 'lodash/fp';

const blockTimeSelector = createSelector(
  workspaceSelector,
  (state, props) => props.blockId,
  (workspace, blockId) =>
    get(['aggregationFilters', 'blocks', blockId, 'year'], workspace),
);

export default blockTimeSelector;
