import PropTypes from 'prop-types';
import GenericSettings from './attribute-settings.generic';

const propTypes = {
  setting: PropTypes.object.isRequired,
  instantiation: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};
const defaultProps = {};

const description = 'The geographic position of a location.';

const SpatialSettings = ({ instantiation, setting, workspaceId }) => (
  <GenericSettings
    description={description}
    hasSettings={false}
    instantiation={instantiation}
    setting={setting}
    workspaceId={workspaceId}
  />
);

SpatialSettings.defaultProps = defaultProps;
SpatialSettings.propTypes = propTypes;

export default SpatialSettings;
