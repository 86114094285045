import PropTypes from 'prop-types';
import { Strong, Placeholder } from '@kinesis/bungle';
import useTheme from 'hooks/useTheme';
import {
  Main,
  Container,
  YAxis,
  XAxis,
  Wireframe,
  WireframeContainer,
} from './xy-chart-placeholder.styles';

const propTypes = {
  yAxisLabel: PropTypes.string,
  xAxisLabel: PropTypes.string,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  mode: PropTypes.oneOf(['unconfigured', 'empty']),
};

const defaultProps = {
  yAxisLabel: 'Y axis',
  xAxisLabel: 'X axis',
  mode: 'unconfigured',
  maxHeight: undefined,
  maxWidth: undefined,
};

const XYChartPlaceholder = ({
  maxWidth,
  maxHeight,
  mode,
  xAxisLabel,
  yAxisLabel,
}) => {
  const theme = useTheme();
  switch (mode) {
    case 'empty':
      return (
        <Container>
          <Main maxHeight={maxHeight} maxWidth={maxWidth}>
            <YAxis>
              <Strong>{yAxisLabel}</Strong>
            </YAxis>
            <WireframeContainer>
              <Wireframe borderColor={theme.color.gray4}>
                <Placeholder>There is no data.</Placeholder>
              </Wireframe>
              <XAxis>
                <Strong>{xAxisLabel}</Strong>
              </XAxis>
            </WireframeContainer>
          </Main>
        </Container>
      );
    case 'unconfigured':
      return (
        <Container>
          <Main maxHeight={maxHeight} maxWidth={maxWidth}>
            <YAxis>
              <Placeholder>{yAxisLabel}</Placeholder>
            </YAxis>
            <WireframeContainer>
              <Wireframe borderColor={theme.color.gray4} />
              <XAxis>
                <Placeholder>{xAxisLabel}</Placeholder>
              </XAxis>
            </WireframeContainer>
          </Main>
        </Container>
      );
    default:
      return null;
  }
};

XYChartPlaceholder.propTypes = propTypes;
XYChartPlaceholder.defaultProps = defaultProps;

export { XYChartPlaceholder };
