import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

const publicWorkspaceSelector = createSelector(
  get('publicWorkspaces'),
  (state, props) => props.workspaceId,
  (publicWorkspaces, workspaceId) => publicWorkspaces[workspaceId] ?? {},
);

export default publicWorkspaceSelector;
