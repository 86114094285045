import { createSelector } from 'reselect';
import { filter, get, pipe } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const locationsByLayerSelector = createSelector(
  pipe(workspaceSelector, get('locations')),
  (state, props) => get('layerId', props),
  (locations, layerId) => filter({ layer: layerId }, locations),
);

export default locationsByLayerSelector;
