import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const geographyFetch = createAsyncThunk(
  'geography/fetch',
  async ({ bundle }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.get(`/api/geography-bundle/${bundle}`);
    return response.data;
  },
);

export { geographyFetch };
