import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetVersionFetch = createAsyncThunk(
  'dataset-version/fetch',
  async ({ versionId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.get(`/api/dataset-versions/${versionId}`);
    return response.data;
  },
);

export { datasetVersionFetch };
