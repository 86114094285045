import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const boardDelete = createAsyncThunk(
  'board/delete',
  async ({ workspaceId, boardId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.delete(
      `/api/workspace/${workspaceId}/board/${boardId}`,
    );

    return response;
  },
);

export { boardDelete };
