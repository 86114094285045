import PropTypes from 'prop-types';
import GenericSettings from './attribute-settings.generic';

const propTypes = {
  setting: PropTypes.object.isRequired,
  instantiation: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};
const defaultProps = {};

const description =
  'Fine area correspondence describes the distribution for re-aggregation areas.';

const FineAreaCorrespondenceSettings = ({
  instantiation,
  setting,
  workspaceId,
}) => (
  <GenericSettings
    description={description}
    hasSettings={false}
    instantiation={instantiation}
    setting={setting}
    workspaceId={workspaceId}
  />
);

FineAreaCorrespondenceSettings.defaultProps = defaultProps;
FineAreaCorrespondenceSettings.propTypes = propTypes;

export default FineAreaCorrespondenceSettings;
