import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { Select } from '@kinesis/bungle';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import visualisationTableOptionsSelector from 'selectors/visualisationTableOptionsSelector';
import blockDataSelector from 'selectors/blockDataSelector';
import isCapsuleMismatchSelector from 'selectors/isCapsuleMismatchSelector';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    'indicator',
    'choropleth',
    'stacked-column',
    'stacked-area',
    'line',
    'clustered-column',
  ]).isRequired,
  value: PropTypes.string,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  defaultExpanded: false,
  value: undefined,
};

const isError = (data) =>
  get('type', data) === 'error' &&
  get(['data', 'code'], data) === 'invalid-query-resource';

const VisualisationTableSelect = ({
  blockId,
  defaultExpanded,
  onChange,
  type,
  value,
  workspaceId,
}) => {
  const scenarioId = useScenarioId();
  const outputOptions = useSelectorWithProps(
    visualisationTableOptionsSelector,
    { type, workspaceId },
  );
  const blockData = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const capsuleMismatch = useSelectorWithProps(isCapsuleMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });

  return (
    <Select
      defaultExpanded={defaultExpanded}
      label='Output'
      value={value}
      onChange={onChange}
      options={outputOptions}
      search
      itemsLabel='outputs'
      wrap
      tone={isError(blockData) && !capsuleMismatch ? 'critical' : 'neutral'}
    />
  );
};

VisualisationTableSelect.propTypes = propTypes;
VisualisationTableSelect.defaultProps = defaultProps;

export { VisualisationTableSelect };
