import { useMatch } from 'react-router-dom';

function useBlockId() {
  const match = [
    useMatch('/embed/:workspaceId/boards/:boardId/blocks/:blockId/*'),
    useMatch('/public/:workspaceId/boards/:boardId/blocks/:blockId/*'),
    useMatch('/workspaces/:workspaceId/boards/:boardId/blocks/:blockId/*'),
  ].find((matchObj) => matchObj);

  return match?.params?.blockId;
}

export { useBlockId };
