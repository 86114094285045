import styled from 'styled-components';
import { CardsWrapper } from 'components/card/card.styles';

const ImmutableBlockGridGrid = styled(CardsWrapper)({
  gridTemplateColumns: 'repeat(6, 1fr)',
  maxWidth: '1024px',
  position: 'relative',
});

const RawBlock = styled.div(({ span }) => ({
  gridColumn: `span ${span}`,
}));

export { ImmutableBlockGridGrid, RawBlock };
