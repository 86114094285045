import { find, get, isNil } from 'lodash/fp';
import { createSelector } from 'reselect';
import makeFeatureFlagSelector from 'selectors/makeFeatureFlagSelector';
import boardSelector from './boardSelector';
import sortedAppsSelector from './sortedAppsSelector';

const enableLimitedReleaseAppsSelector = makeFeatureFlagSelector(
  'app.limited-release-apps',
);

const boardAppSelector = createSelector(
  boardSelector,
  sortedAppsSelector,
  enableLimitedReleaseAppsSelector,
  (board, apps, enableLimitedReleaseApps) => {
    if (isNil(get('source', board))) {
      return undefined;
    }

    return find((app) => {
      const resources = enableLimitedReleaseApps
        ? get('resources', get('limited', app) || get('available', app))
        : get('resources', get('available', app));

      return !isNil(
        find((resource) => resource.family === board.source, resources),
      );
    })(apps);
  },
);

export default boardAppSelector;
