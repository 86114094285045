import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { geographyUpdate } from 'actions/geographyUpdate';

const { reducer, actions } = createSlice({
  name: 'shapes',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(workspaceFetch.fulfilled, (state, action) =>
      keyBy('id', action.payload.shapes),
    );

    builder.addCase(workspacePatch.fulfilled, (state, action) =>
      keyBy('id', action.payload.shapes),
    );

    builder.addCase(geographyUpdate.fulfilled, (state, action) =>
      keyBy('id', action.payload.shapes),
    );

    builder.addCase(appAdd.fulfilled, (state, action) =>
      keyBy('id', action.payload.shapes),
    );
  },
});

export { reducer, actions };
