import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';
import blockStateSelector from './blockStateSelector';

const blockScenarioKeysSelector = createSelector(
  pipe(blockStateSelector, get('scenarios')),
  (state, props) => props.scenarioId,
  (scenarios, scenarioId) => get([scenarioId, 'scenarioKeys'], scenarios),
);

export default blockScenarioKeysSelector;
