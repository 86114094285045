import { createAsyncThunk } from '@reduxjs/toolkit';
import createCdnApi from 'api/createCdnApi';

const publicWorkspaceDataFetch = createAsyncThunk(
  'publicWorkspaceDataFetch',
  async ({ workspaceId, perspectives }) => {
    const api = createCdnApi();
    // FIX this to remove the network call...
    const { data: version } = await api.get(`workspace/${workspaceId}`);
    return Promise.all(
      perspectives.map((perspective) =>
        api
          .get(`workspace/${version}/data/${perspective}.json`)
          .then((response) => response.data),
      ),
    );
  },
);

export { publicWorkspaceDataFetch };
