import { createAsyncThunk } from '@reduxjs/toolkit';
import { take, drop, isEmpty } from 'lodash/fp';
import createApiFromState from 'api/createApiFromState';

const workspacePreviews = createAsyncThunk(
  'workspace/previews',
  async ({ workspaces }, { dispatch, getState }) => {
    const active = take(12, workspaces);
    const rest = drop(12, workspaces);
    if (isEmpty(active)) {
      return [];
    }
    const api = createApiFromState({ dispatch, getState });
    try {
      const response = await api.post(`/api/workspace-previews`, {
        workspaces: active,
      });
      if (!isEmpty(rest)) {
        dispatch(workspacePreviews({ workspaces: rest }));
      }
      return response.data;
    } catch (e) {
      if (!isEmpty(rest)) {
        dispatch(workspacePreviews({ workspaces: rest }));
      }
      return {};
    }
  },
);

export { workspacePreviews };
