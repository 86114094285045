import PropTypes from 'prop-types';
import { isEmpty, keyBy, mapValues, pipe, set } from 'lodash/fp';
import Units from 'data/units';
import Layout from 'components/layout';
import { Placeholder } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import {
  DataGrid,
  DataGridCell,
  DataGridRow,
  DataGridRowGroup,
} from 'components/data-grid';
import { NumberEntry } from 'components/number-entry';
import { GridPane } from './attribute-values.styles';

const units = new Units();

const propTypes = {
  entries: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
};

const defaultProps = {
  privacy: 'private',
};

const formatter = (type) =>
  type ? units.parseColumn({ unit: { type } }).formatCell : undefined;

const symbol = (type) => units.parseColumn({ unit: { type } }).symbol || type;

const CompoundAttributeValues = ({ entries, onChange, privacy }) => {
  if (isEmpty(entries)) {
    return (
      <Layout>
        <Content alignX='center' alignY='center'>
          <Placeholder>
            There are no items defined at this location.
          </Placeholder>
        </Content>
      </Layout>
    );
  }

  return (
    <Layout direction='row'>
      <Layout direction='column'>
        <GridPane>
          <DataGrid borderClip={['left', 'right']}>
            {entries.map((entry) => (
              <DataGridRowGroup key={entry.key}>
                {entry.fields.map((field) => (
                  <DataGridRow title={field.label}>
                    <DataGridCell>
                      <NumberEntry
                        readOnly={privacy === 'public'}
                        format={formatter(field.unitType)}
                        max={field.unitLabel === 'proportion' ? 1 : undefined}
                        min={0}
                        onChange={(value) => {
                          onChange(
                            entry.key,
                            pipe(
                              keyBy('key'),
                              mapValues('value'),
                              set(field.key, value),
                            )(entry.fields),
                          );
                        }}
                        units={field.unitLabel || symbol(field.unitType)}
                        value={field.value}
                      />
                    </DataGridCell>
                  </DataGridRow>
                ))}
              </DataGridRowGroup>
            ))}
          </DataGrid>
        </GridPane>
      </Layout>
    </Layout>
  );
};

CompoundAttributeValues.defaultProps = defaultProps;
CompoundAttributeValues.propTypes = propTypes;

export default CompoundAttributeValues;
