import { clamp } from 'lodash/fp';

const POPOVER_WIDTH = 304;
const VERTICAL_OFFSET = 24;
const PADDING = 16;

function calculateX(x) {
  return clamp(
    PADDING,
    window.innerWidth - POPOVER_WIDTH - PADDING,
    x - POPOVER_WIDTH / 2,
  );
}

function calculateY(y, popoverElem = {}) {
  return popoverElem.offsetHeight &&
    y + VERTICAL_OFFSET + PADDING + popoverElem.offsetHeight >
      window.innerHeight
    ? y - VERTICAL_OFFSET - popoverElem.offsetHeight
    : y + VERTICAL_OFFSET;
}

export { POPOVER_WIDTH, calculateX, calculateY };
