import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash/fp';
import {
  Tooltip,
  AnimatedPopover,
  Portal,
  UtilityButton,
  useEventListener,
  useOnClickOutside,
} from '@kinesis/bungle';
import { PopulationUpload } from 'components/population-upload';
import { Dropzone, Hidden } from './population-upload-control.styles';

const propTypes = {
  disabled: PropTypes.bool,
  onUpload: PropTypes.func,
  openOnLoad: PropTypes.bool,
  progress: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  onUpload: noop,
  progress: false,
  openOnLoad: false,
};

const validFileTypes = ['application/zip'];

const PopulationUploadControl = ({
  disabled,
  onUpload,
  openOnLoad,
  progress,
}) => {
  const dropzoneRef = useRef();
  const fileInputRef = useRef();
  const uploadPaneRef = useRef();
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(openOnLoad);
  const [showDropzone, setShowDropzone] = useState(false);
  const toggle = useCallback(() => setIsOpen((o) => !o), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const handleOnUpload = useCallback(
    (e) => {
      close();
      onUpload(e);
    },
    [close, onUpload],
  );

  const onFileInputSave = useCallback(
    (e) => handleOnUpload(e.target.files),
    [handleOnUpload],
  );

  const handlePopulationUploadClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  useOnClickOutside(
    useMemo(() => [uploadPaneRef, ref], [uploadPaneRef, ref]),
    close,
  );

  const onFileInput = useCallback(() => {
    handleOnUpload(get(['current', 'files'], fileInputRef));
  }, [handleOnUpload, fileInputRef]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!showDropzone && dropzoneRef.current)
        dropzoneRef.current.style.display = 'none';
    }, 200);

    return () => {
      clearTimeout(timerId);
    };
  }, [showDropzone]);

  useEventListener(
    'dragenter',
    useCallback(() => {
      if (!progress) {
        setShowDropzone(true);
        dropzoneRef.current.style.display = 'block';
      }
    }, [progress]),
  );

  useEventListener(
    'dragover',
    useCallback(
      (e) => {
        if (!progress) {
          setShowDropzone(true);
          e.preventDefault();
        }
      },
      [progress],
    ),
  );

  useEventListener('dragleave', () => {
    setShowDropzone(false);
  });

  useEventListener(
    'drop',
    useCallback(
      (e) => {
        if (!progress) {
          e.preventDefault();
          setShowDropzone(false);
          dropzoneRef.current.style.display = 'none';

          const { files } = e.dataTransfer;
          fileInputRef.current.files = files;
          onFileInput();
        }
      },
      [onFileInput, progress],
    ),
  );

  return (
    <>
      <Tooltip title='Upload population input data' placement='top' ref={ref}>
        <UtilityButton
          disabled={disabled}
          magnitude='small'
          icon='upload'
          onClick={toggle}
        >
          Upload
        </UtilityButton>
      </Tooltip>
      <AnimatedPopover
        justify='end'
        placement='bottom'
        offset={4}
        open={isOpen}
        targetRef={ref}
      >
        <PopulationUpload
          ref={uploadPaneRef}
          onClick={handlePopulationUploadClick}
        />
      </AnimatedPopover>
      <Portal>
        <Dropzone ref={dropzoneRef}>Drop file to upload.</Dropzone>
      </Portal>
      <Hidden>
        <input
          accept={validFileTypes}
          type='file'
          onChange={onFileInputSave}
          ref={fileInputRef}
        />
      </Hidden>
    </>
  );
};

PopulationUploadControl.defaultProps = defaultProps;
PopulationUploadControl.propTypes = propTypes;

export { PopulationUploadControl };
