import { pipe, get, first } from 'lodash/fp';

const geographyOf = (location, attributeValues, geographies) => {
  const geographyId = pipe(
    get([location, 'attributes', 'geography', 'time_series']),
    first,
    get('value'),
  )(attributeValues);
  return get([geographyId], geographies);
};

const pointOf = (location, attributeValues, geographies) =>
  get('point', geographyOf(location, attributeValues, geographies));

export { geographyOf, pointOf };
