import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';
import blockValueSelector from './blockValueSelector';
import bubbleChartCapsuleSelector from './bubbleChartCapsuleSelector';
import genericChartCapsuleSelector from './genericChartCapsuleSelector';
import scatterChartCapsuleSelector from './scatterChartCapsuleSelector';

const chartDetailsDataSelector = createSelector(
  pipe(blockValueSelector, get(['chart', 'type'])),
  (...args) => args,
  (type, args) => {
    if (!type) {
      return {};
    }

    switch (type) {
      case 'stacked-area':
      case 'line':
      case 'clustered-column':
      case 'column':
      case 'stacked-column':
        return genericChartCapsuleSelector(...args);
      case 'bubble':
        return bubbleChartCapsuleSelector(...args);
      case 'scatter':
        return scatterChartCapsuleSelector(...args);
      default:
    }
  },
);

export default chartDetailsDataSelector;
