import { identity, map, pipe } from 'lodash/fp';

import createDeepEqualSelector from 'utils/createDeepEqualSelector';
import { trimBlock } from 'utils/blocks';
import blocksSelector from './blocksSelector';

const blocksForBlockGridSelector = createDeepEqualSelector(
  pipe(blocksSelector, map(trimBlock)),
  identity,
);

export default blocksForBlockGridSelector;
