import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash/fp';

const { reducer, actions } = createSlice({
  name: 'workspaceMisc',
  initialState: {
    showAppManagementModal: false,
  },
  reducers: {
    setShowAppManagementModal: set('showAppManagementModal', true),
    clearShowAppManagementModal: set('showAppManagementModal', false),
  },
});

export { reducer, actions };
