import { get, values, pipe, flatten, keyBy, mapValues } from 'lodash/fp';

const knownPlatformLocations = {
  'nsw-lga': [
    { label: 'Albury (C)', id: 'Albury (C)', geography: 591095 },
    {
      label: 'Armidale Regional (A)',
      id: 'Armidale Regional (A)',
      geography: 591096,
    },
    { label: 'Ballina (A)', id: 'Ballina (A)', geography: 591097 },
    { label: 'Balranald (A)', id: 'Balranald (A)', geography: 591098 },
    {
      label: 'Bathurst Regional (A)',
      id: 'Bathurst Regional (A)',
      geography: 591099,
    },
    { label: 'Bayside (A)', id: 'Bayside (A)', geography: 591100 },
    { label: 'Bega Valley (A)', id: 'Bega Valley (A)', geography: 591101 },
    { label: 'Bellingen (A)', id: 'Bellingen (A)', geography: 591102 },
    { label: 'Berrigan (A)', id: 'Berrigan (A)', geography: 591103 },
    { label: 'Blacktown (C)', id: 'Blacktown (C)', geography: 591104 },
    { label: 'Bland (A)', id: 'Bland (A)', geography: 591105 },
    { label: 'Blayney (A)', id: 'Blayney (A)', geography: 591106 },
    {
      label: 'Blue Mountains (C)',
      id: 'Blue Mountains (C)',
      geography: 591107,
    },
    { label: 'Bogan (A)', id: 'Bogan (A)', geography: 591108 },
    { label: 'Bourke (A)', id: 'Bourke (A)', geography: 591109 },
    { label: 'Brewarrina (A)', id: 'Brewarrina (A)', geography: 591110 },
    { label: 'Broken Hill (C)', id: 'Broken Hill (C)', geography: 591111 },
    { label: 'Burwood (A)', id: 'Burwood (A)', geography: 591112 },
    { label: 'Byron (A)', id: 'Byron (A)', geography: 591113 },
    { label: 'Cabonne (A)', id: 'Cabonne (A)', geography: 591114 },
    { label: 'Camden (A)', id: 'Camden (A)', geography: 591115 },
    {
      label: 'Campbelltown (C) (NSW)',
      id: 'Campbelltown (C) (NSW)',
      geography: 591116,
    },
    { label: 'Canada Bay (A)', id: 'Canada Bay (A)', geography: 591117 },
    {
      label: 'Canterbury-Bankstown (A)',
      id: 'Canterbury-Bankstown (A)',
      geography: 591118,
    },
    { label: 'Carrathool (A)', id: 'Carrathool (A)', geography: 591119 },
    {
      label: 'Central Coast (C) (NSW)',
      id: 'Central Coast (C) (NSW)',
      geography: 591120,
    },
    {
      label: 'Central Darling (A)',
      id: 'Central Darling (A)',
      geography: 591121,
    },
    { label: 'Cessnock (C)', id: 'Cessnock (C)', geography: 591122 },
    {
      label: 'Clarence Valley (A)',
      id: 'Clarence Valley (A)',
      geography: 591123,
    },
    { label: 'Cobar (A)', id: 'Cobar (A)', geography: 591124 },
    { label: 'Coffs Harbour (C)', id: 'Coffs Harbour (C)', geography: 591125 },
    { label: 'Coolamon (A)', id: 'Coolamon (A)', geography: 591126 },
    { label: 'Coonamble (A)', id: 'Coonamble (A)', geography: 591127 },
    {
      label: 'Cootamundra-Gundagai Regional (A)',
      id: 'Cootamundra-Gundagai Regional (A)',
      geography: 591128,
    },
    { label: 'Cowra (A)', id: 'Cowra (A)', geography: 591129 },
    { label: 'Cumberland (A)', id: 'Cumberland (A)', geography: 591130 },
    {
      label: 'Dubbo Regional (A)',
      id: 'Dubbo Regional (A)',
      geography: 591131,
    },
    { label: 'Dungog (A)', id: 'Dungog (A)', geography: 591132 },
    { label: 'Edward River (A)', id: 'Edward River (A)', geography: 591133 },
    { label: 'Eurobodalla (A)', id: 'Eurobodalla (A)', geography: 591134 },
    { label: 'Fairfield (C)', id: 'Fairfield (C)', geography: 591135 },
    { label: 'Federation (A)', id: 'Federation (A)', geography: 591136 },
    { label: 'Forbes (A)', id: 'Forbes (A)', geography: 591137 },
    { label: 'Georges River (A)', id: 'Georges River (A)', geography: 591138 },
    { label: 'Gilgandra (A)', id: 'Gilgandra (A)', geography: 591139 },
    {
      label: 'Glen Innes Severn (A)',
      id: 'Glen Innes Severn (A)',
      geography: 591140,
    },
    {
      label: 'Goulburn Mulwaree (A)',
      id: 'Goulburn Mulwaree (A)',
      geography: 591141,
    },
    {
      label: 'Greater Hume Shire (A)',
      id: 'Greater Hume Shire (A)',
      geography: 591142,
    },
    { label: 'Griffith (C)', id: 'Griffith (C)', geography: 591143 },
    { label: 'Gunnedah (A)', id: 'Gunnedah (A)', geography: 591144 },
    { label: 'Gwydir (A)', id: 'Gwydir (A)', geography: 591145 },
    { label: 'Hawkesbury (C)', id: 'Hawkesbury (C)', geography: 591146 },
    { label: 'Hay (A)', id: 'Hay (A)', geography: 591147 },
    { label: 'Hilltops (A)', id: 'Hilltops (A)', geography: 591148 },
    { label: 'Hornsby (A)', id: 'Hornsby (A)', geography: 591149 },
    { label: 'Hunters Hill (A)', id: 'Hunters Hill (A)', geography: 591150 },
    { label: 'Inner West (A)', id: 'Inner West (A)', geography: 591151 },
    { label: 'Inverell (A)', id: 'Inverell (A)', geography: 591152 },
    { label: 'Junee (A)', id: 'Junee (A)', geography: 591153 },
    { label: 'Kempsey (A)', id: 'Kempsey (A)', geography: 591154 },
    { label: 'Kiama (A)', id: 'Kiama (A)', geography: 591155 },
    { label: 'Ku-ring-gai (A)', id: 'Ku-ring-gai (A)', geography: 591156 },
    { label: 'Kyogle (A)', id: 'Kyogle (A)', geography: 591157 },
    { label: 'Lachlan (A)', id: 'Lachlan (A)', geography: 591158 },
    {
      label: 'Lake Macquarie (C)',
      id: 'Lake Macquarie (C)',
      geography: 591159,
    },
    { label: 'Lane Cove (A)', id: 'Lane Cove (A)', geography: 591160 },
    { label: 'Leeton (A)', id: 'Leeton (A)', geography: 591161 },
    { label: 'Lismore (C)', id: 'Lismore (C)', geography: 591162 },
    { label: 'Lithgow (C)', id: 'Lithgow (C)', geography: 591163 },
    { label: 'Liverpool (C)', id: 'Liverpool (C)', geography: 591164 },
    {
      label: 'Liverpool Plains (A)',
      id: 'Liverpool Plains (A)',
      geography: 591165,
    },
    { label: 'Lockhart (A)', id: 'Lockhart (A)', geography: 591166 },
    { label: 'Maitland (C)', id: 'Maitland (C)', geography: 591167 },
    { label: 'Mid-Coast (A)', id: 'Mid-Coast (A)', geography: 591168 },
    {
      label: 'Mid-Western Regional (A)',
      id: 'Mid-Western Regional (A)',
      geography: 591169,
    },
    { label: 'Moree Plains (A)', id: 'Moree Plains (A)', geography: 591170 },
    { label: 'Mosman (A)', id: 'Mosman (A)', geography: 591171 },
    { label: 'Murray River (A)', id: 'Murray River (A)', geography: 591172 },
    { label: 'Murrumbidgee (A)', id: 'Murrumbidgee (A)', geography: 591173 },
    { label: 'Muswellbrook (A)', id: 'Muswellbrook (A)', geography: 591174 },
    {
      label: 'Nambucca Valley (A)',
      id: 'Nambucca Valley (A)',
      geography: 591175,
    },
    { label: 'Narrabri (A)', id: 'Narrabri (A)', geography: 591176 },
    { label: 'Narrandera (A)', id: 'Narrandera (A)', geography: 591177 },
    { label: 'Narromine (A)', id: 'Narromine (A)', geography: 591178 },
    { label: 'Newcastle (C)', id: 'Newcastle (C)', geography: 591179 },
    { label: 'North Sydney (A)', id: 'North Sydney (A)', geography: 591180 },
    {
      label: 'Northern Beaches (A)',
      id: 'Northern Beaches (A)',
      geography: 591181,
    },
    { label: 'Oberon (A)', id: 'Oberon (A)', geography: 591182 },
    { label: 'Orange (C)', id: 'Orange (C)', geography: 591183 },
    { label: 'Parkes (A)', id: 'Parkes (A)', geography: 591184 },
    { label: 'Parramatta (C)', id: 'Parramatta (C)', geography: 591185 },
    { label: 'Penrith (C)', id: 'Penrith (C)', geography: 591186 },
    {
      label: 'Port Macquarie-Hastings (A)',
      id: 'Port Macquarie-Hastings (A)',
      geography: 591187,
    },
    { label: 'Port Stephens (A)', id: 'Port Stephens (A)', geography: 591188 },
    {
      label: 'Queanbeyan-Palerang Regional (A)',
      id: 'Queanbeyan-Palerang Regional (A)',
      geography: 591189,
    },
    { label: 'Randwick (C)', id: 'Randwick (C)', geography: 591190 },
    {
      label: 'Richmond Valley (A)',
      id: 'Richmond Valley (A)',
      geography: 591191,
    },
    { label: 'Ryde (C)', id: 'Ryde (C)', geography: 591192 },
    { label: 'Shellharbour (C)', id: 'Shellharbour (C)', geography: 591193 },
    { label: 'Shoalhaven (C)', id: 'Shoalhaven (C)', geography: 591194 },
    { label: 'Singleton (A)', id: 'Singleton (A)', geography: 591195 },
    {
      label: 'Snowy Monaro Regional (A)',
      id: 'Snowy Monaro Regional (A)',
      geography: 591196,
    },
    { label: 'Snowy Valleys (A)', id: 'Snowy Valleys (A)', geography: 591197 },
    { label: 'Strathfield (A)', id: 'Strathfield (A)', geography: 591198 },
    {
      label: 'Sutherland Shire (A)',
      id: 'Sutherland Shire (A)',
      geography: 591199,
    },
    { label: 'Sydney (C)', id: 'Sydney (C)', geography: 591200 },
    {
      label: 'Tamworth Regional (A)',
      id: 'Tamworth Regional (A)',
      geography: 591201,
    },
    { label: 'Temora (A)', id: 'Temora (A)', geography: 591202 },
    { label: 'Tenterfield (A)', id: 'Tenterfield (A)', geography: 591203 },
    {
      label: 'The Hills Shire (A)',
      id: 'The Hills Shire (A)',
      geography: 591204,
    },
    { label: 'Tweed (A)', id: 'Tweed (A)', geography: 591205 },
    {
      label: 'Unincorporated NSW',
      id: 'Unincorporated NSW',
      geography: 591206,
    },
    {
      label: 'Upper Hunter Shire (A)',
      id: 'Upper Hunter Shire (A)',
      geography: 591207,
    },
    {
      label: 'Upper Lachlan Shire (A)',
      id: 'Upper Lachlan Shire (A)',
      geography: 591208,
    },
    { label: 'Uralla (A)', id: 'Uralla (A)', geography: 591209 },
    { label: 'Wagga Wagga (C)', id: 'Wagga Wagga (C)', geography: 591210 },
    { label: 'Walcha (A)', id: 'Walcha (A)', geography: 591211 },
    { label: 'Walgett (A)', id: 'Walgett (A)', geography: 591212 },
    { label: 'Warren (A)', id: 'Warren (A)', geography: 591213 },
    {
      label: 'Warrumbungle Shire (A)',
      id: 'Warrumbungle Shire (A)',
      geography: 591214,
    },
    { label: 'Waverley (A)', id: 'Waverley (A)', geography: 591215 },
    { label: 'Weddin (A)', id: 'Weddin (A)', geography: 591216 },
    { label: 'Wentworth (A)', id: 'Wentworth (A)', geography: 591217 },
    { label: 'Willoughby (C)', id: 'Willoughby (C)', geography: 591218 },
    { label: 'Wingecarribee (A)', id: 'Wingecarribee (A)', geography: 591219 },
    { label: 'Wollondilly (A)', id: 'Wollondilly (A)', geography: 591220 },
    { label: 'Wollongong (C)', id: 'Wollongong (C)', geography: 591221 },
    { label: 'Woollahra (A)', id: 'Woollahra (A)', geography: 591222 },
    { label: 'Yass Valley (A)', id: 'Yass Valley (A)', geography: 591223 },
  ],
  'nsw-sa2': [
    { label: 'Acacia Gardens', id: 'Acacia Gardens', geography: 591224 },
    {
      label: 'Adamstown - Kotara',
      id: 'Adamstown - Kotara',
      geography: 591225,
    },
    {
      label: 'Albion Park - Macquarie Pass',
      id: 'Albion Park - Macquarie Pass',
      geography: 591226,
    },
    { label: 'Albion Park Rail', id: 'Albion Park Rail', geography: 591227 },
    { label: 'Albury - East', id: 'Albury - East', geography: 591228 },
    { label: 'Albury - North', id: 'Albury - North', geography: 591229 },
    { label: 'Albury - South', id: 'Albury - South', geography: 591230 },
    { label: 'Albury Region', id: 'Albury Region', geography: 591231 },
    { label: 'Anna Bay', id: 'Anna Bay', geography: 591232 },
    { label: 'Armidale', id: 'Armidale', geography: 591233 },
    {
      label: 'Armidale Region - North',
      id: 'Armidale Region - North',
      geography: 591234,
    },
    {
      label: 'Armidale Region - South',
      id: 'Armidale Region - South',
      geography: 591235,
    },
    {
      label: 'Arncliffe - Bardwell Valley',
      id: 'Arncliffe - Bardwell Valley',
      geography: 591236,
    },
    {
      label: 'Ashcroft - Busby - Miller',
      id: 'Ashcroft - Busby - Miller',
      geography: 591237,
    },
    { label: 'Ashfield', id: 'Ashfield', geography: 591238 },
    {
      label: 'Asquith - Mount Colah',
      id: 'Asquith - Mount Colah',
      geography: 591239,
    },
    { label: 'Auburn - Central', id: 'Auburn - Central', geography: 591240 },
    { label: 'Auburn - North', id: 'Auburn - North', geography: 591241 },
    { label: 'Auburn - South', id: 'Auburn - South', geography: 591242 },
    {
      label: 'Austral - Greendale',
      id: 'Austral - Greendale',
      geography: 591243,
    },
    {
      label: 'Avalon - Palm Beach',
      id: 'Avalon - Palm Beach',
      geography: 591244,
    },
    {
      label: 'Avoca Beach - Copacabana',
      id: 'Avoca Beach - Copacabana',
      geography: 591245,
    },
    { label: 'Badgerys Creek', id: 'Badgerys Creek', geography: 591246 },
    {
      label: 'Balgowlah - Clontarf - Seaforth',
      id: 'Balgowlah - Clontarf - Seaforth',
      geography: 591247,
    },
    {
      label: 'Balgownie - Fairy Meadow',
      id: 'Balgownie - Fairy Meadow',
      geography: 591248,
    },
    { label: 'Ballina', id: 'Ballina', geography: 591249 },
    { label: 'Ballina Region', id: 'Ballina Region', geography: 591250 },
    { label: 'Balmain', id: 'Balmain', geography: 591251 },
    { label: 'Bangalow', id: 'Bangalow', geography: 591252 },
    { label: 'Banksmeadow', id: 'Banksmeadow', geography: 591253 },
    { label: 'Bankstown - North', id: 'Bankstown - North', geography: 591254 },
    { label: 'Bankstown - South', id: 'Bankstown - South', geography: 591255 },
    { label: 'Banora Point', id: 'Banora Point', geography: 591256 },
    { label: 'Bargo', id: 'Bargo', geography: 591257 },
    {
      label: 'Bass Hill - Georges Hall',
      id: 'Bass Hill - Georges Hall',
      geography: 591258,
    },
    {
      label: 'Bateau Bay - Killarney Vale',
      id: 'Bateau Bay - Killarney Vale',
      geography: 591259,
    },
    { label: 'Batemans Bay', id: 'Batemans Bay', geography: 591260 },
    {
      label: 'Batemans Bay - South',
      id: 'Batemans Bay - South',
      geography: 591261,
    },
    { label: 'Bathurst', id: 'Bathurst', geography: 591262 },
    { label: 'Bathurst - East', id: 'Bathurst - East', geography: 591263 },
    { label: 'Bathurst Region', id: 'Bathurst Region', geography: 591264 },
    {
      label: 'Baulkham Hills (East)',
      id: 'Baulkham Hills (East)',
      geography: 591265,
    },
    {
      label: 'Baulkham Hills (West) - Bella Vista',
      id: 'Baulkham Hills (West) - Bella Vista',
      geography: 591266,
    },
    {
      label: 'Bayview - Elanora Heights',
      id: 'Bayview - Elanora Heights',
      geography: 591267,
    },
    {
      label: 'Beacon Hill - Narraweena',
      id: 'Beacon Hill - Narraweena',
      geography: 591268,
    },
    { label: 'Bega - Tathra', id: 'Bega - Tathra', geography: 591269 },
    {
      label: 'Bega-Eden Hinterland',
      id: 'Bega-Eden Hinterland',
      geography: 591270,
    },
    { label: 'Bellingen', id: 'Bellingen', geography: 591271 },
    {
      label: 'Belmont - Bennetts Green',
      id: 'Belmont - Bennetts Green',
      geography: 591272,
    },
    {
      label: 'Belmont South - Blacksmiths',
      id: 'Belmont South - Blacksmiths',
      geography: 591273,
    },
    {
      label: 'Belmore - Belfield',
      id: 'Belmore - Belfield',
      geography: 591274,
    },
    { label: 'Berala', id: 'Berala', geography: 591275 },
    {
      label: 'Beresfield - Hexham',
      id: 'Beresfield - Hexham',
      geography: 591276,
    },
    {
      label: 'Berkeley - Lake Heights - Cringila',
      id: 'Berkeley - Lake Heights - Cringila',
      geography: 591277,
    },
    {
      label: 'Berowra - Brooklyn - Cowan',
      id: 'Berowra - Brooklyn - Cowan',
      geography: 591278,
    },
    {
      label: 'Berry - Kangaroo Valley',
      id: 'Berry - Kangaroo Valley',
      geography: 591279,
    },
    { label: 'Bexley', id: 'Bexley', geography: 591280 },
    {
      label: 'Bidwill - Hebersham - Emerton',
      id: 'Bidwill - Hebersham - Emerton',
      geography: 591281,
    },
    {
      label: 'Bilpin - Colo - St Albans',
      id: 'Bilpin - Colo - St Albans',
      geography: 591282,
    },
    {
      label: 'Blackheath - Megalong Valley',
      id: 'Blackheath - Megalong Valley',
      geography: 591283,
    },
    {
      label: 'Blacktown (East) - Kings Park',
      id: 'Blacktown (East) - Kings Park',
      geography: 591284,
    },
    {
      label: 'Blacktown (North) - Marayong',
      id: 'Blacktown (North) - Marayong',
      geography: 591285,
    },
    { label: 'Blacktown (South)', id: 'Blacktown (South)', geography: 591286 },
    { label: 'Blacktown (West)', id: 'Blacktown (West)', geography: 591287 },
    {
      label: 'Blaxland - Warrimoo - Lapstone',
      id: 'Blaxland - Warrimoo - Lapstone',
      geography: 591288,
    },
    { label: 'Blayney', id: 'Blayney', geography: 591289 },
    {
      label: 'Blue Haven - San Remo',
      id: 'Blue Haven - San Remo',
      geography: 591290,
    },
    {
      label: 'Blue Mountains - North',
      id: 'Blue Mountains - North',
      geography: 591291,
    },
    {
      label: 'Blue Mountains - South',
      id: 'Blue Mountains - South',
      geography: 591292,
    },
    {
      label: 'Bolton Point - Teralba',
      id: 'Bolton Point - Teralba',
      geography: 591293,
    },
    { label: 'Bombala', id: 'Bombala', geography: 591294 },
    {
      label: 'Bondi - Tamarama - Bronte',
      id: 'Bondi - Tamarama - Bronte',
      geography: 591295,
    },
    {
      label: 'Bondi Beach - North Bondi',
      id: 'Bondi Beach - North Bondi',
      geography: 591296,
    },
    {
      label: 'Bondi Junction - Waverly',
      id: 'Bondi Junction - Waverly',
      geography: 591297,
    },
    {
      label: 'Bonnells Bay - Silverwater',
      id: 'Bonnells Bay - Silverwater',
      geography: 591298,
    },
    {
      label: 'Bonnyrigg Heights - Bonnyrigg',
      id: 'Bonnyrigg Heights - Bonnyrigg',
      geography: 591299,
    },
    {
      label: 'Bossley Park - Abbotsbury',
      id: 'Bossley Park - Abbotsbury',
      geography: 591300,
    },
    { label: 'Botany', id: 'Botany', geography: 591301 },
    {
      label: 'Bourke - Brewarrina',
      id: 'Bourke - Brewarrina',
      geography: 591302,
    },
    { label: 'Bowral', id: 'Bowral', geography: 591303 },
    {
      label: 'Box Head - MacMasters Beach',
      id: 'Box Head - MacMasters Beach',
      geography: 591304,
    },
    {
      label: 'Bradbury - Wedderburn',
      id: 'Bradbury - Wedderburn',
      geography: 591305,
    },
    { label: 'Braidwood', id: 'Braidwood', geography: 591306 },
    {
      label: 'Branxton - Greta - Pokolbin',
      id: 'Branxton - Greta - Pokolbin',
      geography: 591307,
    },
    { label: 'Broken Hill', id: 'Broken Hill', geography: 591111 },
    { label: 'Broulee - Tomakin', id: 'Broulee - Tomakin', geography: 591309 },
    {
      label: 'Brunswick Heads - Ocean Shores',
      id: 'Brunswick Heads - Ocean Shores',
      geography: 591310,
    },
    {
      label: 'Budgewoi - Buff Point - Halekulani',
      id: 'Budgewoi - Buff Point - Halekulani',
      geography: 591311,
    },
    {
      label: 'Bulahdelah - Stroud',
      id: 'Bulahdelah - Stroud',
      geography: 591312,
    },
    { label: 'Burwood - Croydon', id: 'Burwood - Croydon', geography: 591313 },
    { label: 'Byron Bay', id: 'Byron Bay', geography: 591314 },
    {
      label: 'Cabramatta - Lansvale',
      id: 'Cabramatta - Lansvale',
      geography: 591315,
    },
    {
      label: 'Cabramatta West - Mount Pritchard',
      id: 'Cabramatta West - Mount Pritchard',
      geography: 591316,
    },
    { label: 'Calga - Kulnura', id: 'Calga - Kulnura', geography: 591317 },
    {
      label: 'Callala Bay - Currarong',
      id: 'Callala Bay - Currarong',
      geography: 591318,
    },
    { label: 'Cambridge Park', id: 'Cambridge Park', geography: 591319 },
    {
      label: 'Camden - Ellis Lane',
      id: 'Camden - Ellis Lane',
      geography: 591320,
    },
    {
      label: 'Campbelltown - Woodbine',
      id: 'Campbelltown - Woodbine',
      geography: 591321,
    },
    {
      label: 'Canley Vale - Canley Heights',
      id: 'Canley Vale - Canley Heights',
      geography: 591322,
    },
    {
      label: 'Canterbury (North) - Ashbury',
      id: 'Canterbury (North) - Ashbury',
      geography: 591323,
    },
    {
      label: 'Canterbury (South) - Campsie',
      id: 'Canterbury (South) - Campsie',
      geography: 591324,
    },
    { label: 'Caringbah', id: 'Caringbah', geography: 591325 },
    { label: 'Caringbah South', id: 'Caringbah South', geography: 591326 },
    { label: 'Carlingford', id: 'Carlingford', geography: 591327 },
    { label: 'Casino', id: 'Casino', geography: 591328 },
    { label: 'Casino Region', id: 'Casino Region', geography: 591329 },
    {
      label: 'Castle Hill - Central',
      id: 'Castle Hill - Central',
      geography: 591330,
    },
    {
      label: 'Castle Hill - East',
      id: 'Castle Hill - East',
      geography: 591331,
    },
    {
      label: 'Castle Hill - North',
      id: 'Castle Hill - North',
      geography: 591332,
    },
    {
      label: 'Castle Hill - South',
      id: 'Castle Hill - South',
      geography: 591333,
    },
    {
      label: 'Castle Hill - West',
      id: 'Castle Hill - West',
      geography: 591334,
    },
    {
      label: 'Castlereagh - Cranebrook',
      id: 'Castlereagh - Cranebrook',
      geography: 591335,
    },
    { label: 'Casula', id: 'Casula', geography: 591336 },
    { label: 'Cecil Hills', id: 'Cecil Hills', geography: 591337 },
    { label: 'Centennial Park', id: 'Centennial Park', geography: 576910 },
    { label: 'Cessnock', id: 'Cessnock', geography: 591339 },
    { label: 'Cessnock Region', id: 'Cessnock Region', geography: 591340 },
    {
      label: 'Charlestown - Dudley',
      id: 'Charlestown - Dudley',
      geography: 591341,
    },
    {
      label: 'Chatswood (East) - Artarmon',
      id: 'Chatswood (East) - Artarmon',
      geography: 591342,
    },
    {
      label: 'Chatswood (West) - Lane Cove North',
      id: 'Chatswood (West) - Lane Cove North',
      geography: 591343,
    },
    { label: 'Cherrybrook', id: 'Cherrybrook', geography: 591344 },
    {
      label: 'Chester Hill - Sefton',
      id: 'Chester Hill - Sefton',
      geography: 591345,
    },
    {
      label: 'Chipping Norton - Moorebank',
      id: 'Chipping Norton - Moorebank',
      geography: 591346,
    },
    {
      label: 'Chittaway Bay - Tumbi Umbi',
      id: 'Chittaway Bay - Tumbi Umbi',
      geography: 591347,
    },
    { label: 'Chullora', id: 'Chullora', geography: 591348 },
    {
      label: 'Claymore - Eagle Vale - Raby',
      id: 'Claymore - Eagle Vale - Raby',
      geography: 591349,
    },
    { label: 'Cobar', id: 'Cobar', geography: 591350 },
    {
      label: 'Cobbitty - Leppington',
      id: 'Cobbitty - Leppington',
      geography: 591351,
    },
    {
      label: 'Coffs Harbour - North',
      id: 'Coffs Harbour - North',
      geography: 591352,
    },
    {
      label: 'Coffs Harbour - South',
      id: 'Coffs Harbour - South',
      geography: 591353,
    },
    {
      label: 'Colyton - Oxley Park',
      id: 'Colyton - Oxley Park',
      geography: 591354,
    },
    {
      label: 'Concord - Mortlake - Cabarita',
      id: 'Concord - Mortlake - Cabarita',
      geography: 591355,
    },
    {
      label: 'Concord West - North Strathfield',
      id: 'Concord West - North Strathfield',
      geography: 591356,
    },
    { label: 'Condell Park', id: 'Condell Park', geography: 591357 },
    { label: 'Condobolin', id: 'Condobolin', geography: 591358 },
    { label: 'Coogee - Clovelly', id: 'Coogee - Clovelly', geography: 591359 },
    { label: 'Cooma', id: 'Cooma', geography: 591360 },
    { label: 'Cooma Region', id: 'Cooma Region', geography: 591361 },
    { label: 'Coonabarabran', id: 'Coonabarabran', geography: 591362 },
    { label: 'Coonamble', id: 'Coonamble', geography: 591363 },
    { label: 'Cootamundra', id: 'Cootamundra', geography: 591364 },
    {
      label: 'Coramba - Nana Glen - Bucca',
      id: 'Coramba - Nana Glen - Bucca',
      geography: 591365,
    },
    { label: 'Corowa', id: 'Corowa', geography: 591366 },
    { label: 'Corowa Region', id: 'Corowa Region', geography: 591367 },
    {
      label: 'Corrimal - Tarrawanna - Bellambi',
      id: 'Corrimal - Tarrawanna - Bellambi',
      geography: 591368,
    },
    { label: 'Cowra', id: 'Cowra', geography: 591369 },
    { label: 'Cowra Region', id: 'Cowra Region', geography: 591370 },
    {
      label: 'Cremorne - Cammeray',
      id: 'Cremorne - Cammeray',
      geography: 591371,
    },
    { label: 'Cromer', id: 'Cromer', geography: 591372 },
    {
      label: 'Cronulla - Kurnell - Bundeena',
      id: 'Cronulla - Kurnell - Bundeena',
      geography: 591373,
    },
    {
      label: 'Crows Nest - Waverton',
      id: 'Crows Nest - Waverton',
      geography: 591374,
    },
    {
      label: 'Croydon Park - Enfield',
      id: 'Croydon Park - Enfield',
      geography: 591375,
    },
    { label: 'Culburra Beach', id: 'Culburra Beach', geography: 591376 },
    { label: 'Dapto - Avondale', id: 'Dapto - Avondale', geography: 591377 },
    { label: 'Darlinghurst', id: 'Darlinghurst', geography: 591378 },
    {
      label: 'Dee Why - North Curl Curl',
      id: 'Dee Why - North Curl Curl',
      geography: 591379,
    },
    { label: 'Deniliquin', id: 'Deniliquin', geography: 591380 },
    { label: 'Deniliquin Region', id: 'Deniliquin Region', geography: 591381 },
    { label: 'Deua - Wadbilliga', id: 'Deua - Wadbilliga', geography: 591382 },
    {
      label: 'Doonside - Woodcroft',
      id: 'Doonside - Woodcroft',
      geography: 591383,
    },
    { label: 'Dorrigo', id: 'Dorrigo', geography: 591384 },
    {
      label: 'Double Bay - Bellevue Hill',
      id: 'Double Bay - Bellevue Hill',
      geography: 591385,
    },
    {
      label: 'Douglas Park - Appin',
      id: 'Douglas Park - Appin',
      geography: 591386,
    },
    { label: 'Dover Heights', id: 'Dover Heights', geography: 591387 },
    {
      label: 'Drummoyne - Rodd Point',
      id: 'Drummoyne - Rodd Point',
      geography: 591388,
    },
    { label: 'Dubbo - East', id: 'Dubbo - East', geography: 591389 },
    { label: 'Dubbo - South', id: 'Dubbo - South', geography: 591390 },
    { label: 'Dubbo - West', id: 'Dubbo - West', geography: 591391 },
    { label: 'Dubbo Region', id: 'Dubbo Region', geography: 591392 },
    {
      label: 'Dulwich Hill - Lewisham',
      id: 'Dulwich Hill - Lewisham',
      geography: 591393,
    },
    { label: 'Dungog', id: 'Dungog', geography: 591132 },
    {
      label: 'Dural - Kenthurst - Wisemans Ferry',
      id: 'Dural - Kenthurst - Wisemans Ferry',
      geography: 591395,
    },
    {
      label: 'Eastwood - Denistone',
      id: 'Eastwood - Denistone',
      geography: 591396,
    },
    { label: 'Eden', id: 'Eden', geography: 591397 },
    { label: 'Edensor Park', id: 'Edensor Park', geography: 591398 },
    {
      label: 'Edgeworth - Cameron Park',
      id: 'Edgeworth - Cameron Park',
      geography: 591399,
    },
    {
      label: 'Elderslie - Harrington Park',
      id: 'Elderslie - Harrington Park',
      geography: 591400,
    },
    {
      label: 'Emu Plains - Leonay',
      id: 'Emu Plains - Leonay',
      geography: 591401,
    },
    { label: 'Engadine', id: 'Engadine', geography: 591402 },
    {
      label: 'Epping - North Epping',
      id: 'Epping - North Epping',
      geography: 591403,
    },
    {
      label: 'Erina - Green Point',
      id: 'Erina - Green Point',
      geography: 591404,
    },
    {
      label: 'Ermington - Rydalmere',
      id: 'Ermington - Rydalmere',
      geography: 591405,
    },
    { label: 'Erskine Park', id: 'Erskine Park', geography: 591406 },
    {
      label: 'Erskineville - Alexandria',
      id: 'Erskineville - Alexandria',
      geography: 591407,
    },
    {
      label: 'Ettrema - Sassafras - Budawang',
      id: 'Ettrema - Sassafras - Budawang',
      geography: 577968,
    },
    {
      label: 'Eurobodalla Hinterland',
      id: 'Eurobodalla Hinterland',
      geography: 591409,
    },
    { label: 'Evans Head', id: 'Evans Head', geography: 591410 },
    { label: 'Fairfield', id: 'Fairfield', geography: 591411 },
    { label: 'Fairfield - East', id: 'Fairfield - East', geography: 591412 },
    { label: 'Fairfield - West', id: 'Fairfield - West', geography: 591413 },
    { label: 'Far West', id: 'Far West', geography: 591414 },
    {
      label: 'Figtree - Keiraville',
      id: 'Figtree - Keiraville',
      geography: 591415,
    },
    {
      label: 'Five Dock - Abbotsford',
      id: 'Five Dock - Abbotsford',
      geography: 591416,
    },
    { label: 'Forbes', id: 'Forbes', geography: 591417 },
    {
      label: 'Forestville - Killarney Heights',
      id: 'Forestville - Killarney Heights',
      geography: 591418,
    },
    { label: 'Forster', id: 'Forster', geography: 591419 },
    {
      label: 'Forster-Tuncurry Region',
      id: 'Forster-Tuncurry Region',
      geography: 591420,
    },
    {
      label: 'Frenchs Forest - Belrose',
      id: 'Frenchs Forest - Belrose',
      geography: 591421,
    },
    {
      label: 'Freshwater - Brookvale',
      id: 'Freshwater - Brookvale',
      geography: 591422,
    },
    {
      label: 'Galston - Laughtondale',
      id: 'Galston - Laughtondale',
      geography: 591423,
    },
    { label: 'Gilgandra', id: 'Gilgandra', geography: 591424 },
    {
      label: 'Gladesville - Huntleys Point',
      id: 'Gladesville - Huntleys Point',
      geography: 591425,
    },
    {
      label: 'Glebe - Forest Lodge',
      id: 'Glebe - Forest Lodge',
      geography: 591426,
    },
    { label: 'Glen Innes', id: 'Glen Innes', geography: 591140 },
    {
      label: 'Glendale - Cardiff - Hillsborough',
      id: 'Glendale - Cardiff - Hillsborough',
      geography: 591428,
    },
    {
      label: 'Glendenning - Dean Park',
      id: 'Glendenning - Dean Park',
      geography: 591429,
    },
    { label: 'Glenhaven', id: 'Glenhaven', geography: 591430 },
    {
      label: 'Glenmore Park - Regentville',
      id: 'Glenmore Park - Regentville',
      geography: 591431,
    },
    { label: 'Glenwood', id: 'Glenwood', geography: 591432 },
    { label: 'Gloucester', id: 'Gloucester', geography: 591433 },
    { label: 'Goonellabah', id: 'Goonellabah', geography: 591434 },
    { label: 'Gordon - Killara', id: 'Gordon - Killara', geography: 591435 },
    {
      label: 'Gorokan - Kanwal - Charmhaven',
      id: 'Gorokan - Kanwal - Charmhaven',
      geography: 591436,
    },
    {
      label: 'Gosford - Springfield',
      id: 'Gosford - Springfield',
      geography: 591437,
    },
    { label: 'Goulburn', id: 'Goulburn', geography: 591438 },
    { label: 'Goulburn Region', id: 'Goulburn Region', geography: 591439 },
    { label: 'Grafton', id: 'Grafton', geography: 591440 },
    { label: 'Grafton Region', id: 'Grafton Region', geography: 591441 },
    { label: 'Granville - Clyde', id: 'Granville - Clyde', geography: 591442 },
    { label: 'Green Valley', id: 'Green Valley', geography: 591443 },
    {
      label: 'Greenacre - Mount Lewis',
      id: 'Greenacre - Mount Lewis',
      geography: 591444,
    },
    {
      label: 'Greenfield Park - Prairiewood',
      id: 'Greenfield Park - Prairiewood',
      geography: 591445,
    },
    { label: 'Grenfell', id: 'Grenfell', geography: 591446 },
    {
      label: 'Greystanes - Pemulwuy',
      id: 'Greystanes - Pemulwuy',
      geography: 591447,
    },
    { label: 'Griffith (NSW)', id: 'Griffith (NSW)', geography: 591448 },
    { label: 'Griffith Region', id: 'Griffith Region', geography: 591449 },
    {
      label: 'Guildford - South Granville',
      id: 'Guildford - South Granville',
      geography: 591450,
    },
    {
      label: 'Guildford West - Merrylands West',
      id: 'Guildford West - Merrylands West',
      geography: 591451,
    },
    { label: 'Gundagai', id: 'Gundagai', geography: 591452 },
    { label: 'Gunnedah', id: 'Gunnedah', geography: 591453 },
    { label: 'Gunnedah Region', id: 'Gunnedah Region', geography: 591454 },
    {
      label: 'Gymea - Grays Point',
      id: 'Gymea - Grays Point',
      geography: 591455,
    },
    {
      label: 'Haberfield - Summer Hill',
      id: 'Haberfield - Summer Hill',
      geography: 591456,
    },
    {
      label: 'Hamilton - Broadmeadow',
      id: 'Hamilton - Broadmeadow',
      geography: 591457,
    },
    {
      label: 'Hassall Grove - Plumpton',
      id: 'Hassall Grove - Plumpton',
      geography: 591458,
    },
    { label: 'Hay', id: 'Hay', geography: 591459 },
    {
      label: 'Heathcote - Waterfall',
      id: 'Heathcote - Waterfall',
      geography: 591460,
    },
    { label: 'Helensburgh', id: 'Helensburgh', geography: 591461 },
    {
      label: 'Hill Top - Colo Vale',
      id: 'Hill Top - Colo Vale',
      geography: 591462,
    },
    { label: 'Hinchinbrook', id: 'Hinchinbrook', geography: 591463 },
    {
      label: 'Holsworthy - Wattle Grove',
      id: 'Holsworthy - Wattle Grove',
      geography: 591464,
    },
    {
      label: 'Holsworthy Military Area',
      id: 'Holsworthy Military Area',
      geography: 591465,
    },
    { label: 'Homebush', id: 'Homebush', geography: 591466 },
    {
      label: 'Homebush Bay - Silverwater',
      id: 'Homebush Bay - Silverwater',
      geography: 591467,
    },
    { label: 'Hornsby - East', id: 'Hornsby - East', geography: 591468 },
    { label: 'Hornsby - West', id: 'Hornsby - West', geography: 591469 },
    {
      label: 'Horsley - Kembla Grange',
      id: 'Horsley - Kembla Grange',
      geography: 591470,
    },
    {
      label: 'Horsley Park - Kemps Creek',
      id: 'Horsley Park - Kemps Creek',
      geography: 591471,
    },
    {
      label: 'Hoxton Park - Carnes Hill - Horningsea Park',
      id: 'Hoxton Park - Carnes Hill - Horningsea Park',
      geography: 591472,
    },
    {
      label: 'Hunters Hill - Woolwich',
      id: 'Hunters Hill - Woolwich',
      geography: 591473,
    },
    { label: 'Hurstville', id: 'Hurstville', geography: 591474 },
    {
      label: 'Huskisson - Vincentia',
      id: 'Huskisson - Vincentia',
      geography: 591475,
    },
    {
      label: 'Illawarra Catchment Reserve',
      id: 'Illawarra Catchment Reserve',
      geography: 591476,
    },
    {
      label: 'Illawong - Alfords Point',
      id: 'Illawong - Alfords Point',
      geography: 591477,
    },
    {
      label: 'Ingleburn - Denham Court',
      id: 'Ingleburn - Denham Court',
      geography: 591478,
    },
    { label: 'Inverell', id: 'Inverell', geography: 591479 },
    {
      label: 'Inverell Region - East',
      id: 'Inverell Region - East',
      geography: 591480,
    },
    {
      label: 'Inverell Region - West',
      id: 'Inverell Region - West',
      geography: 591481,
    },
    {
      label: 'Jamisontown - South Penrith',
      id: 'Jamisontown - South Penrith',
      geography: 591482,
    },
    {
      label: 'Jilliby - Yarramalong',
      id: 'Jilliby - Yarramalong',
      geography: 591483,
    },
    {
      label: 'Jindabyne - Berridale',
      id: 'Jindabyne - Berridale',
      geography: 591484,
    },
    { label: 'Junee', id: 'Junee', geography: 591485 },
    { label: 'Karabar', id: 'Karabar', geography: 591486 },
    { label: 'Kariong', id: 'Kariong', geography: 591487 },
    { label: 'Katoomba - Leura', id: 'Katoomba - Leura', geography: 591488 },
    { label: 'Kellyville', id: 'Kellyville', geography: 591489 },
    { label: 'Kempsey', id: 'Kempsey', geography: 591490 },
    { label: 'Kempsey Region', id: 'Kempsey Region', geography: 591491 },
    { label: 'Kensington (NSW)', id: 'Kensington (NSW)', geography: 591492 },
    { label: 'Kiama', id: 'Kiama', geography: 591493 },
    {
      label: 'Kiama Downs - Minnamurra',
      id: 'Kiama Downs - Minnamurra',
      geography: 591494,
    },
    {
      label: 'Kiama Hinterland - Gerringong',
      id: 'Kiama Hinterland - Gerringong',
      geography: 591495,
    },
    {
      label: 'Kincumber - Picketts Valley',
      id: 'Kincumber - Picketts Valley',
      geography: 591496,
    },
    {
      label: 'Kingscliff - Fingal Head',
      id: 'Kingscliff - Fingal Head',
      geography: 591497,
    },
    { label: 'Kingsford', id: 'Kingsford', geography: 591498 },
    {
      label: 'Kingsgrove (North) - Earlwood',
      id: 'Kingsgrove (North) - Earlwood',
      geography: 591499,
    },
    {
      label: 'Kingsgrove (South) - Bardwell Park',
      id: 'Kingsgrove (South) - Bardwell Park',
      geography: 591500,
    },
    {
      label: 'Kingswood - Werrington',
      id: 'Kingswood - Werrington',
      geography: 591501,
    },
    { label: 'Kogarah', id: 'Kogarah', geography: 591502 },
    {
      label: 'Kogarah Bay - Carlton - Allawah',
      id: 'Kogarah Bay - Carlton - Allawah',
      geography: 591503,
    },
    {
      label: 'Korora - Emerald Beach',
      id: 'Korora - Emerald Beach',
      geography: 591504,
    },
    {
      label: 'Kurrajong Heights - Ebenezer',
      id: 'Kurrajong Heights - Ebenezer',
      geography: 591505,
    },
    {
      label: 'Kurri Kurri - Abermain',
      id: 'Kurri Kurri - Abermain',
      geography: 591506,
    },
    { label: 'Kyogle', id: 'Kyogle', geography: 591507 },
    {
      label: 'Lake Munmorah - Mannering Park',
      id: 'Lake Munmorah - Mannering Park',
      geography: 591508,
    },
    { label: 'Lakemba', id: 'Lakemba', geography: 591509 },
    {
      label: 'Lalor Park - Kings Langley',
      id: 'Lalor Park - Kings Langley',
      geography: 591510,
    },
    {
      label: 'Lambton - New Lambton',
      id: 'Lambton - New Lambton',
      geography: 591511,
    },
    {
      label: 'Lane Cove - Greenwich',
      id: 'Lane Cove - Greenwich',
      geography: 591512,
    },
    {
      label: 'Laurieton - Bonny Hills',
      id: 'Laurieton - Bonny Hills',
      geography: 591513,
    },
    { label: 'Lavington', id: 'Lavington', geography: 591514 },
    {
      label: 'Lawson - Hazelbrook - Linden',
      id: 'Lawson - Hazelbrook - Linden',
      geography: 591515,
    },
    { label: 'Leeton', id: 'Leeton', geography: 591516 },
    {
      label: 'Leichhardt - Annandale',
      id: 'Leichhardt - Annandale',
      geography: 591517,
    },
    {
      label: 'Lemon Tree Passage - Tanilba Bay',
      id: 'Lemon Tree Passage - Tanilba Bay',
      geography: 591518,
    },
    {
      label: 'Lennox Head - Skennars Head',
      id: 'Lennox Head - Skennars Head',
      geography: 591519,
    },
    {
      label: 'Lethbridge Park - Tregear',
      id: 'Lethbridge Park - Tregear',
      geography: 591520,
    },
    {
      label: 'Leumeah - Minto Heights',
      id: 'Leumeah - Minto Heights',
      geography: 591521,
    },
    { label: 'Lidcombe', id: 'Lidcombe', geography: 591522 },
    {
      label: 'Lilli Pilli - Port Hacking - Dolans Bay',
      id: 'Lilli Pilli - Port Hacking - Dolans Bay',
      geography: 591523,
    },
    {
      label: 'Lilyfield - Rozelle',
      id: 'Lilyfield - Rozelle',
      geography: 591524,
    },
    {
      label: 'Lindfield - Roseville',
      id: 'Lindfield - Roseville',
      geography: 591525,
    },
    { label: 'Lismore', id: 'Lismore', geography: 591526 },
    { label: 'Lismore Region', id: 'Lismore Region', geography: 591527 },
    { label: 'Lithgow', id: 'Lithgow', geography: 591528 },
    { label: 'Lithgow Region', id: 'Lithgow Region', geography: 591529 },
    { label: 'Liverpool', id: 'Liverpool', geography: 591530 },
    {
      label: 'Loftus - Yarrawarrah',
      id: 'Loftus - Yarrawarrah',
      geography: 591531,
    },
    { label: 'Lord Howe Island', id: 'Lord Howe Island', geography: 591532 },
    {
      label: 'Lurnea - Cartwright',
      id: 'Lurnea - Cartwright',
      geography: 591533,
    },
    {
      label: 'Macksville - Scotts Head',
      id: 'Macksville - Scotts Head',
      geography: 591534,
    },
    {
      label: 'Maclean - Yamba - Iluka',
      id: 'Maclean - Yamba - Iluka',
      geography: 591535,
    },
    {
      label: 'Macquarie Fields - Glenfield',
      id: 'Macquarie Fields - Glenfield',
      geography: 591536,
    },
    {
      label: 'Macquarie Park - Marsfield',
      id: 'Macquarie Park - Marsfield',
      geography: 591537,
    },
    { label: 'Maitland', id: 'Maitland', geography: 591538 },
    { label: 'Maitland - East', id: 'Maitland - East', geography: 591539 },
    { label: 'Maitland - North', id: 'Maitland - North', geography: 591540 },
    { label: 'Maitland - West', id: 'Maitland - West', geography: 591541 },
    {
      label: 'Malabar - La Perouse - Chifley',
      id: 'Malabar - La Perouse - Chifley',
      geography: 591542,
    },
    { label: 'Manly - Fairlight', id: 'Manly - Fairlight', geography: 591543 },
    {
      label: 'Manly Vale - Allambie Heights',
      id: 'Manly Vale - Allambie Heights',
      geography: 591544,
    },
    { label: 'Maroubra - North', id: 'Maroubra - North', geography: 591545 },
    { label: 'Maroubra - South', id: 'Maroubra - South', geography: 591546 },
    { label: 'Maroubra - West', id: 'Maroubra - West', geography: 591547 },
    { label: 'Marrickville', id: 'Marrickville', geography: 591548 },
    {
      label: 'Maryland - Fletcher - Minmi',
      id: 'Maryland - Fletcher - Minmi',
      geography: 591549,
    },
    {
      label: 'Mascot - Eastlakes',
      id: 'Mascot - Eastlakes',
      geography: 591550,
    },
    {
      label: 'Mayfield - Warabrook',
      id: 'Mayfield - Warabrook',
      geography: 591551,
    },
    {
      label: 'Menai - Lucas Heights - Woronora',
      id: 'Menai - Lucas Heights - Woronora',
      geography: 591552,
    },
    {
      label: 'Merewether - The Junction',
      id: 'Merewether - The Junction',
      geography: 591553,
    },
    {
      label: 'Merimbula - Tura Beach',
      id: 'Merimbula - Tura Beach',
      geography: 591554,
    },
    {
      label: 'Merrylands - Holroyd',
      id: 'Merrylands - Holroyd',
      geography: 591555,
    },
    {
      label: 'Minto - St Andrews',
      id: 'Minto - St Andrews',
      geography: 591556,
    },
    {
      label: 'Miranda - Yowie Bay',
      id: 'Miranda - Yowie Bay',
      geography: 591557,
    },
    { label: 'Mittagong', id: 'Mittagong', geography: 591558 },
    { label: 'Moama', id: 'Moama', geography: 591559 },
    {
      label: 'Monterey - Brighton-le-Sands - Kyeemagh',
      id: 'Monterey - Brighton-le-Sands - Kyeemagh',
      geography: 591560,
    },
    { label: 'Moree', id: 'Moree', geography: 591561 },
    { label: 'Moree Region', id: 'Moree Region', geography: 591562 },
    {
      label: 'Morisset - Cooranbong',
      id: 'Morisset - Cooranbong',
      geography: 591563,
    },
    {
      label: 'Mortdale - Penshurst',
      id: 'Mortdale - Penshurst',
      geography: 591564,
    },
    {
      label: 'Moruya - Tuross Head',
      id: 'Moruya - Tuross Head',
      geography: 591565,
    },
    { label: 'Mosman', id: 'Mosman', geography: 591171 },
    {
      label: 'Moss Vale - Berrima',
      id: 'Moss Vale - Berrima',
      geography: 591567,
    },
    {
      label: 'Mount Annan - Currans Hill',
      id: 'Mount Annan - Currans Hill',
      geography: 591568,
    },
    {
      label: 'Mount Druitt - Whalan',
      id: 'Mount Druitt - Whalan',
      geography: 591569,
    },
    {
      label: 'Mount Hutton - Windale',
      id: 'Mount Hutton - Windale',
      geography: 591570,
    },
    { label: 'Mudgee', id: 'Mudgee', geography: 591571 },
    {
      label: 'Mudgee Region - East',
      id: 'Mudgee Region - East',
      geography: 591572,
    },
    {
      label: 'Mudgee Region - West',
      id: 'Mudgee Region - West',
      geography: 591573,
    },
    {
      label: 'Mulgoa - Luddenham - Orchard Hills',
      id: 'Mulgoa - Luddenham - Orchard Hills',
      geography: 591574,
    },
    { label: 'Mullumbimby', id: 'Mullumbimby', geography: 591575 },
    { label: 'Murwillumbah', id: 'Murwillumbah', geography: 591576 },
    {
      label: 'Murwillumbah Region',
      id: 'Murwillumbah Region',
      geography: 591577,
    },
    { label: 'Muswellbrook', id: 'Muswellbrook', geography: 591578 },
    {
      label: 'Muswellbrook Region',
      id: 'Muswellbrook Region',
      geography: 591579,
    },
    { label: 'Nambucca Heads', id: 'Nambucca Heads', geography: 591580 },
    {
      label: 'Nambucca Heads Region',
      id: 'Nambucca Heads Region',
      geography: 591581,
    },
    { label: 'Narara', id: 'Narara', geography: 591582 },
    {
      label: 'Narooma - Bermagui',
      id: 'Narooma - Bermagui',
      geography: 591583,
    },
    {
      label: 'Narrabeen - Collaroy',
      id: 'Narrabeen - Collaroy',
      geography: 591584,
    },
    { label: 'Narrabri', id: 'Narrabri', geography: 591585 },
    { label: 'Narrabri Region', id: 'Narrabri Region', geography: 591586 },
    { label: 'Narrandera', id: 'Narrandera', geography: 591587 },
    { label: 'Narromine', id: 'Narromine', geography: 591588 },
    {
      label: 'Narwee - Beverly Hills',
      id: 'Narwee - Beverly Hills',
      geography: 591589,
    },
    {
      label: 'Nelson Bay Peninsula',
      id: 'Nelson Bay Peninsula',
      geography: 591590,
    },
    {
      label: 'Neutral Bay - Kirribilli',
      id: 'Neutral Bay - Kirribilli',
      geography: 591591,
    },
    {
      label: 'Newcastle - Cooks Hill',
      id: 'Newcastle - Cooks Hill',
      geography: 591592,
    },
    {
      label: 'Newcastle Port - Kooragang',
      id: 'Newcastle Port - Kooragang',
      geography: 574169,
    },
    { label: 'Newport - Bilgola', id: 'Newport - Bilgola', geography: 591594 },
    {
      label: 'Newtown - Camperdown - Darlington',
      id: 'Newtown - Camperdown - Darlington',
      geography: 591595,
    },
    {
      label: 'Niagara Park - Lisarow',
      id: 'Niagara Park - Lisarow',
      geography: 591596,
    },
    {
      label: 'Normanhurst - Thornleigh - Westleigh',
      id: 'Normanhurst - Thornleigh - Westleigh',
      geography: 591597,
    },
    {
      label: 'North Nowra - Bomaderry',
      id: 'North Nowra - Bomaderry',
      geography: 591598,
    },
    { label: 'North Parramatta', id: 'North Parramatta', geography: 591599 },
    { label: 'North Rocks', id: 'North Rocks', geography: 591600 },
    {
      label: 'North Ryde - East Ryde',
      id: 'North Ryde - East Ryde',
      geography: 591601,
    },
    {
      label: 'North Sydney - Lavender Bay',
      id: 'North Sydney - Lavender Bay',
      geography: 591602,
    },
    { label: 'Northmead', id: 'Northmead', geography: 591603 },
    { label: 'Nowra', id: 'Nowra', geography: 591604 },
    { label: 'Nyngan - Warren', id: 'Nyngan - Warren', geography: 591605 },
    {
      label: 'Oatlands - Dundas Valley',
      id: 'Oatlands - Dundas Valley',
      geography: 591606,
    },
    {
      label: 'Oatley - Hurstville Grove',
      id: 'Oatley - Hurstville Grove',
      geography: 591607,
    },
    { label: 'Oberon', id: 'Oberon', geography: 591608 },
    {
      label: 'Old Bar - Manning Point - Red Head',
      id: 'Old Bar - Manning Point - Red Head',
      geography: 591609,
    },
    { label: 'Orange', id: 'Orange', geography: 591610 },
    { label: 'Orange - North', id: 'Orange - North', geography: 591611 },
    { label: 'Orange Region', id: 'Orange Region', geography: 591612 },
    {
      label: 'Ourimbah - Fountaindale',
      id: 'Ourimbah - Fountaindale',
      geography: 591613,
    },
    {
      label: 'Oyster Bay - Como - Jannali',
      id: 'Oyster Bay - Como - Jannali',
      geography: 591614,
    },
    {
      label: 'Paddington - Moore Park',
      id: 'Paddington - Moore Park',
      geography: 591615,
    },
    { label: 'Padstow', id: 'Padstow', geography: 591616 },
    {
      label: 'Pagewood - Hillsdale - Daceyville',
      id: 'Pagewood - Hillsdale - Daceyville',
      geography: 591617,
    },
    {
      label: 'Panania - Milperra - Picnic Point',
      id: 'Panania - Milperra - Picnic Point',
      geography: 591618,
    },
    { label: 'Parkes (NSW)', id: 'Parkes (NSW)', geography: 591619 },
    { label: 'Parkes Region', id: 'Parkes Region', geography: 591620 },
    {
      label: 'Parklea - Kellyville Ridge',
      id: 'Parklea - Kellyville Ridge',
      geography: 591621,
    },
    {
      label: 'Parramatta - Rosehill',
      id: 'Parramatta - Rosehill',
      geography: 591622,
    },
    {
      label: 'Peakhurst - Lugarno',
      id: 'Peakhurst - Lugarno',
      geography: 591623,
    },
    {
      label: 'Pendle Hill - Girraween',
      id: 'Pendle Hill - Girraween',
      geography: 591624,
    },
    {
      label: 'Pennant Hills - Cheltenham',
      id: 'Pennant Hills - Cheltenham',
      geography: 591625,
    },
    { label: 'Penrith', id: 'Penrith', geography: 591626 },
    {
      label: 'Petersham - Stanmore',
      id: 'Petersham - Stanmore',
      geography: 591627,
    },
    {
      label: 'Picton - Tahmoor - Buxton',
      id: 'Picton - Tahmoor - Buxton',
      geography: 591628,
    },
    {
      label: 'Pitt Town - McGraths Hill',
      id: 'Pitt Town - McGraths Hill',
      geography: 591629,
    },
    {
      label: 'Point Clare - Koolewong',
      id: 'Point Clare - Koolewong',
      geography: 591630,
    },
    {
      label: 'Port Botany Industrial',
      id: 'Port Botany Industrial',
      geography: 576909,
    },
    {
      label: 'Port Kembla - Warrawong',
      id: 'Port Kembla - Warrawong',
      geography: 591632,
    },
    {
      label: 'Port Kembla Industrial',
      id: 'Port Kembla Industrial',
      geography: 591633,
    },
    {
      label: 'Port Macquarie - East',
      id: 'Port Macquarie - East',
      geography: 591634,
    },
    {
      label: 'Port Macquarie - West',
      id: 'Port Macquarie - West',
      geography: 591635,
    },
    {
      label: 'Port Macquarie Region',
      id: 'Port Macquarie Region',
      geography: 591636,
    },
    {
      label: 'Potts Point - Woolloomooloo',
      id: 'Potts Point - Woolloomooloo',
      geography: 591637,
    },
    { label: 'Pottsville', id: 'Pottsville', geography: 591638 },
    {
      label: 'Prestons - Edmondson Park',
      id: 'Prestons - Edmondson Park',
      geography: 591639,
    },
    {
      label: 'Prospect Reservoir',
      id: 'Prospect Reservoir',
      geography: 591640,
    },
    { label: 'Punchbowl', id: 'Punchbowl', geography: 591641 },
    { label: 'Putney', id: 'Putney', geography: 591642 },
    { label: 'Pymble', id: 'Pymble', geography: 591643 },
    { label: 'Pyrmont - Ultimo', id: 'Pyrmont - Ultimo', geography: 591644 },
    { label: 'Quakers Hill', id: 'Quakers Hill', geography: 591645 },
    { label: 'Queanbeyan', id: 'Queanbeyan', geography: 591646 },
    { label: 'Queanbeyan - East', id: 'Queanbeyan - East', geography: 591647 },
    { label: 'Queanbeyan Region', id: 'Queanbeyan Region', geography: 591648 },
    {
      label: 'Queanbeyan West - Jerrabomberra',
      id: 'Queanbeyan West - Jerrabomberra',
      geography: 591649,
    },
    { label: 'Quirindi', id: 'Quirindi', geography: 591650 },
    { label: 'Randwick - North', id: 'Randwick - North', geography: 591651 },
    { label: 'Randwick - South', id: 'Randwick - South', geography: 591652 },
    { label: 'Raymond Terrace', id: 'Raymond Terrace', geography: 591653 },
    {
      label: 'Redfern - Chippendale',
      id: 'Redfern - Chippendale',
      geography: 591654,
    },
    { label: 'Redhead', id: 'Redhead', geography: 591655 },
    { label: 'Regents Park', id: 'Regents Park', geography: 591656 },
    { label: 'Revesby', id: 'Revesby', geography: 591657 },
    {
      label: 'Richmond - Clarendon',
      id: 'Richmond - Clarendon',
      geography: 591658,
    },
    {
      label: 'Riverstone - Marsden Park',
      id: 'Riverstone - Marsden Park',
      geography: 591659,
    },
    { label: 'Riverwood', id: 'Riverwood', geography: 591660 },
    {
      label: 'Robertson - Fitzroy Falls',
      id: 'Robertson - Fitzroy Falls',
      geography: 591661,
    },
    {
      label: 'Rockdale - Banksia',
      id: 'Rockdale - Banksia',
      geography: 591662,
    },
    { label: 'Rookwood Cemetery', id: 'Rookwood Cemetery', geography: 567528 },
    {
      label: 'Rooty Hill - Minchinbury',
      id: 'Rooty Hill - Minchinbury',
      geography: 591664,
    },
    {
      label: 'Rose Bay - Vaucluse - Watsons Bay',
      id: 'Rose Bay - Vaucluse - Watsons Bay',
      geography: 591665,
    },
    { label: 'Roselands', id: 'Roselands', geography: 591666 },
    {
      label: 'Rosemeadow - Glen Alpine',
      id: 'Rosemeadow - Glen Alpine',
      geography: 591667,
    },
    {
      label: 'Rouse Hill - Beaumont Hills',
      id: 'Rouse Hill - Beaumont Hills',
      geography: 591668,
    },
    {
      label: 'Royal National Park',
      id: 'Royal National Park',
      geography: 578895,
    },
    { label: 'Ryde', id: 'Ryde', geography: 591670 },
    {
      label: 'Sans Souci - Ramsgate',
      id: 'Sans Souci - Ramsgate',
      geography: 591671,
    },
    {
      label: 'Saratoga - Davistown',
      id: 'Saratoga - Davistown',
      geography: 591672,
    },
    { label: 'Sawtell - Boambee', id: 'Sawtell - Boambee', geography: 591673 },
    { label: 'Scone', id: 'Scone', geography: 591674 },
    { label: 'Scone Region', id: 'Scone Region', geography: 591675 },
    {
      label: 'Seaham - Woodville',
      id: 'Seaham - Woodville',
      geography: 591676,
    },
    {
      label: 'Seven Hills - Toongabbie',
      id: 'Seven Hills - Toongabbie',
      geography: 591677,
    },
    {
      label: 'Shellharbour - Flinders',
      id: 'Shellharbour - Flinders',
      geography: 591678,
    },
    {
      label: 'Shellharbour - Oak Flats',
      id: 'Shellharbour - Oak Flats',
      geography: 591679,
    },
    {
      label: 'Shortland - Jesmond',
      id: 'Shortland - Jesmond',
      geography: 591680,
    },
    { label: 'Singleton', id: 'Singleton', geography: 591681 },
    { label: 'Singleton Region', id: 'Singleton Region', geography: 591682 },
    {
      label: 'Smithfield - Wetherill Park',
      id: 'Smithfield - Wetherill Park',
      geography: 591683,
    },
    {
      label: 'Smithfield Industrial',
      id: 'Smithfield Industrial',
      geography: 567935,
    },
    {
      label: 'South Hurstville - Blakehurst',
      id: 'South Hurstville - Blakehurst',
      geography: 591685,
    },
    { label: 'South West Rocks', id: 'South West Rocks', geography: 591686 },
    {
      label: 'Southern Highlands',
      id: 'Southern Highlands',
      geography: 591687,
    },
    {
      label: 'Springwood - Winmalee',
      id: 'Springwood - Winmalee',
      geography: 591688,
    },
    { label: 'St Clair', id: 'St Clair', geography: 591689 },
    {
      label: 'St Georges Basin - Erowal Bay',
      id: 'St Georges Basin - Erowal Bay',
      geography: 591690,
    },
    { label: 'St Ives', id: 'St Ives', geography: 591691 },
    {
      label: 'St Johns Park - Wakeley',
      id: 'St Johns Park - Wakeley',
      geography: 591692,
    },
    {
      label: 'St Leonards - Naremburn',
      id: 'St Leonards - Naremburn',
      geography: 591693,
    },
    {
      label: 'St Marys - North St Marys',
      id: 'St Marys - North St Marys',
      geography: 591694,
    },
    {
      label: 'Stockton - Fullerton Cove',
      id: 'Stockton - Fullerton Cove',
      geography: 591695,
    },
    { label: 'Strathfield', id: 'Strathfield', geography: 591696 },
    { label: 'Strathfield South', id: 'Strathfield South', geography: 591697 },
    {
      label: 'Summerland Point - Gwandalan',
      id: 'Summerland Point - Gwandalan',
      geography: 591698,
    },
    { label: 'Surry Hills', id: 'Surry Hills', geography: 591699 },
    {
      label: 'Sussex Inlet - Berrara',
      id: 'Sussex Inlet - Berrara',
      geography: 591700,
    },
    {
      label: 'Sutherland - Kirrawee',
      id: 'Sutherland - Kirrawee',
      geography: 591701,
    },
    {
      label: 'Swansea - Caves Beach',
      id: 'Swansea - Caves Beach',
      geography: 591702,
    },
    {
      label: 'Sydenham - Tempe - St Peters',
      id: 'Sydenham - Tempe - St Peters',
      geography: 591703,
    },
    {
      label: 'Sydney - Haymarket - The Rocks',
      id: 'Sydney - Haymarket - The Rocks',
      geography: 591704,
    },
    { label: 'Sydney Airport', id: 'Sydney Airport', geography: 591705 },
    {
      label: 'Sylvania - Taren Point',
      id: 'Sylvania - Taren Point',
      geography: 591706,
    },
    { label: 'Tamworth - East', id: 'Tamworth - East', geography: 591707 },
    { label: 'Tamworth - North', id: 'Tamworth - North', geography: 591708 },
    { label: 'Tamworth - West', id: 'Tamworth - West', geography: 591709 },
    { label: 'Tamworth Region', id: 'Tamworth Region', geography: 591710 },
    { label: 'Taree', id: 'Taree', geography: 591711 },
    { label: 'Taree Region', id: 'Taree Region', geography: 591712 },
    {
      label: 'Tea Gardens - Hawks Nest',
      id: 'Tea Gardens - Hawks Nest',
      geography: 591713,
    },
    { label: 'Temora', id: 'Temora', geography: 591714 },
    { label: 'Tenterfield', id: 'Tenterfield', geography: 591203 },
    {
      label: 'Terranora - North Tumblegum',
      id: 'Terranora - North Tumblegum',
      geography: 591716,
    },
    {
      label: 'Terrey Hills - Duffys Forest',
      id: 'Terrey Hills - Duffys Forest',
      geography: 591717,
    },
    {
      label: 'Terrigal - North Avoca',
      id: 'Terrigal - North Avoca',
      geography: 591718,
    },
    { label: 'The Entrance', id: 'The Entrance', geography: 591719 },
    {
      label: 'The Oaks - Oakdale',
      id: 'The Oaks - Oakdale',
      geography: 591720,
    },
    {
      label: 'Thirroul - Austinmer - Coalcliff',
      id: 'Thirroul - Austinmer - Coalcliff',
      geography: 591721,
    },
    {
      label: 'Thornton - Millers Forest',
      id: 'Thornton - Millers Forest',
      geography: 591722,
    },
    {
      label: 'Tocumwal - Finley - Jerilderie',
      id: 'Tocumwal - Finley - Jerilderie',
      geography: 591723,
    },
    {
      label: 'Tomerong - Wandandian - Woollamia',
      id: 'Tomerong - Wandandian - Woollamia',
      geography: 591724,
    },
    {
      label: 'Toongabbie - Constitution Hill',
      id: 'Toongabbie - Constitution Hill',
      geography: 591725,
    },
    { label: 'Toronto - Awaba', id: 'Toronto - Awaba', geography: 591726 },
    {
      label: 'Toukley - Norah Head',
      id: 'Toukley - Norah Head',
      geography: 591727,
    },
    {
      label: 'Tuggerah - Kangy Angy',
      id: 'Tuggerah - Kangy Angy',
      geography: 591728,
    },
    { label: 'Tumbarumba', id: 'Tumbarumba', geography: 591729 },
    { label: 'Tumut', id: 'Tumut', geography: 591730 },
    { label: 'Tumut Region', id: 'Tumut Region', geography: 591731 },
    { label: 'Tuncurry', id: 'Tuncurry', geography: 591732 },
    { label: 'Turramurra', id: 'Turramurra', geography: 591733 },
    { label: 'Tweed Heads', id: 'Tweed Heads', geography: 591734 },
    { label: 'Tweed Heads South', id: 'Tweed Heads South', geography: 591735 },
    { label: 'Ulladulla', id: 'Ulladulla', geography: 591736 },
    { label: 'Ulladulla Region', id: 'Ulladulla Region', geography: 591737 },
    {
      label: 'Umina - Booker Bay - Patonga',
      id: 'Umina - Booker Bay - Patonga',
      geography: 591738,
    },
    {
      label: 'Unanderra - Mount Kembla',
      id: 'Unanderra - Mount Kembla',
      geography: 591739,
    },
    { label: 'Urunga', id: 'Urunga', geography: 591740 },
    {
      label: 'Valentine - Eleebana',
      id: 'Valentine - Eleebana',
      geography: 591741,
    },
    {
      label: 'Wagga Wagga - East',
      id: 'Wagga Wagga - East',
      geography: 591742,
    },
    {
      label: 'Wagga Wagga - North',
      id: 'Wagga Wagga - North',
      geography: 591743,
    },
    {
      label: 'Wagga Wagga - South',
      id: 'Wagga Wagga - South',
      geography: 591744,
    },
    {
      label: 'Wagga Wagga - West',
      id: 'Wagga Wagga - West',
      geography: 591745,
    },
    {
      label: 'Wagga Wagga Region',
      id: 'Wagga Wagga Region',
      geography: 591746,
    },
    {
      label: 'Wahroonga (East) - Warrawee',
      id: 'Wahroonga (East) - Warrawee',
      geography: 591747,
    },
    {
      label: 'Waitara - Wahroonga (West)',
      id: 'Waitara - Wahroonga (West)',
      geography: 591748,
    },
    { label: 'Walcha', id: 'Walcha', geography: 591749 },
    {
      label: 'Walgett - Lightning Ridge',
      id: 'Walgett - Lightning Ridge',
      geography: 591750,
    },
    {
      label: 'Wallsend - Elermore Vale',
      id: 'Wallsend - Elermore Vale',
      geography: 591751,
    },
    {
      label: 'Wamberal - Forresters Beach',
      id: 'Wamberal - Forresters Beach',
      geography: 591752,
    },
    {
      label: 'Wangi Wangi - Rathmines',
      id: 'Wangi Wangi - Rathmines',
      geography: 591753,
    },
    {
      label: 'Waratah - North Lambton',
      id: 'Waratah - North Lambton',
      geography: 591754,
    },
    { label: 'Warilla', id: 'Warilla', geography: 591755 },
    {
      label: 'Warners Bay - Boolaroo',
      id: 'Warners Bay - Boolaroo',
      geography: 591756,
    },
    {
      label: 'Warnervale - Wadalba',
      id: 'Warnervale - Wadalba',
      geography: 591757,
    },
    {
      label: 'Warragamba - Silverdale',
      id: 'Warragamba - Silverdale',
      geography: 591758,
    },
    {
      label: 'Warriewood - Mona Vale',
      id: 'Warriewood - Mona Vale',
      geography: 591759,
    },
    { label: 'Warwick Farm', id: 'Warwick Farm', geography: 591760 },
    {
      label: 'Waterloo - Beaconsfield',
      id: 'Waterloo - Beaconsfield',
      geography: 591761,
    },
    { label: 'Wauchope', id: 'Wauchope', geography: 591762 },
    { label: 'Wellington', id: 'Wellington', geography: 591763 },
    {
      label: 'Wentworth - Buronga',
      id: 'Wentworth - Buronga',
      geography: 591764,
    },
    { label: 'Wentworth Falls', id: 'Wentworth Falls', geography: 591765 },
    {
      label: 'Wentworth-Balranald Region',
      id: 'Wentworth-Balranald Region',
      geography: 591766,
    },
    {
      label: 'Wentworthville - Westmead',
      id: 'Wentworthville - Westmead',
      geography: 591767,
    },
    {
      label: 'West Hoxton - Middleton Grange',
      id: 'West Hoxton - Middleton Grange',
      geography: 591768,
    },
    {
      label: 'West Pennant Hills',
      id: 'West Pennant Hills',
      geography: 591769,
    },
    {
      label: 'West Ryde - Meadowbank',
      id: 'West Ryde - Meadowbank',
      geography: 591770,
    },
    {
      label: 'West Wallsend - Barnsley - Killingworth',
      id: 'West Wallsend - Barnsley - Killingworth',
      geography: 591771,
    },
    { label: 'West Wyalong', id: 'West Wyalong', geography: 591772 },
    {
      label: 'Wetherill Park Industrial',
      id: 'Wetherill Park Industrial',
      geography: 568753,
    },
    {
      label: 'Wickham - Carrington - Tighes Hill',
      id: 'Wickham - Carrington - Tighes Hill',
      geography: 591774,
    },
    { label: 'Wiley Park', id: 'Wiley Park', geography: 591775 },
    {
      label: 'Williamtown - Medowie - Karuah',
      id: 'Williamtown - Medowie - Karuah',
      geography: 591776,
    },
    {
      label: 'Willoughby - Castle Cove - Northbridge',
      id: 'Willoughby - Castle Cove - Northbridge',
      geography: 591777,
    },
    { label: 'Windang - Primbee', id: 'Windang - Primbee', geography: 591778 },
    {
      label: 'Windsor - Bligh Park',
      id: 'Windsor - Bligh Park',
      geography: 591779,
    },
    { label: 'Wingham', id: 'Wingham', geography: 591780 },
    { label: 'Winston Hills', id: 'Winston Hills', geography: 591781 },
    {
      label: 'Wollangambe - Wollemi',
      id: 'Wollangambe - Wollemi',
      geography: 591782,
    },
    { label: 'Wollongong - East', id: 'Wollongong - East', geography: 591783 },
    { label: 'Wollongong - West', id: 'Wollongong - West', geography: 591784 },
    {
      label: 'Woolaware - Burraneer',
      id: 'Woolaware - Burraneer',
      geography: 591785,
    },
    {
      label: 'Woolgoolga - Arrawarra',
      id: 'Woolgoolga - Arrawarra',
      geography: 591786,
    },
    { label: 'Woollahra', id: 'Woollahra', geography: 591787 },
    {
      label: 'Woonona - Bulli - Russell Vale',
      id: 'Woonona - Bulli - Russell Vale',
      geography: 591788,
    },
    { label: 'Woronora Heights', id: 'Woronora Heights', geography: 591789 },
    {
      label: 'Woy Woy - Blackwall',
      id: 'Woy Woy - Blackwall',
      geography: 591790,
    },
    { label: 'Wyoming', id: 'Wyoming', geography: 591791 },
    { label: 'Wyong', id: 'Wyong', geography: 591792 },
    { label: 'Yagoona - Birrong', id: 'Yagoona - Birrong', geography: 591793 },
    {
      label: 'Yarramundi - Londonderry',
      id: 'Yarramundi - Londonderry',
      geography: 591794,
    },
    { label: 'Yass', id: 'Yass', geography: 591795 },
    { label: 'Yass Region', id: 'Yass Region', geography: 591796 },
    {
      label: 'Yennora Industrial',
      id: 'Yennora Industrial',
      geography: 567936,
    },
    { label: 'Young', id: 'Young', geography: 591798 },
    { label: 'Young Region', id: 'Young Region', geography: 591799 },
  ],
  'nsw-sa3': [
    { label: 'Albury', id: 'Albury', geography: 591800 },
    { label: 'Armidale', id: 'Armidale', geography: 591801 },
    { label: 'Auburn', id: 'Auburn', geography: 591802 },
    { label: 'Bankstown', id: 'Bankstown', geography: 591803 },
    { label: 'Bathurst', id: 'Bathurst', geography: 591804 },
    { label: 'Baulkham Hills', id: 'Baulkham Hills', geography: 591805 },
    { label: 'Blacktown', id: 'Blacktown', geography: 591806 },
    { label: 'Blacktown - North', id: 'Blacktown - North', geography: 591807 },
    { label: 'Blue Mountains', id: 'Blue Mountains', geography: 591808 },
    {
      label: 'Blue Mountains - South',
      id: 'Blue Mountains - South',
      geography: 591292,
    },
    { label: 'Botany', id: 'Botany', geography: 591810 },
    {
      label: 'Bourke - Cobar - Coonamble',
      id: 'Bourke - Cobar - Coonamble',
      geography: 591811,
    },
    {
      label: 'Bringelly - Green Valley',
      id: 'Bringelly - Green Valley',
      geography: 591812,
    },
    {
      label: 'Broken Hill and Far West',
      id: 'Broken Hill and Far West',
      geography: 591813,
    },
    { label: 'Camden', id: 'Camden', geography: 591814 },
    {
      label: 'Campbelltown (NSW)',
      id: 'Campbelltown (NSW)',
      geography: 591815,
    },
    { label: 'Canada Bay', id: 'Canada Bay', geography: 591816 },
    { label: 'Canterbury', id: 'Canterbury', geography: 591817 },
    { label: 'Carlingford', id: 'Carlingford', geography: 591818 },
    {
      label: 'Chatswood - Lane Cove',
      id: 'Chatswood - Lane Cove',
      geography: 591819,
    },
    { label: 'Clarence Valley', id: 'Clarence Valley', geography: 591820 },
    { label: 'Coffs Harbour', id: 'Coffs Harbour', geography: 591821 },
    {
      label: 'Cronulla - Miranda - Caringbah',
      id: 'Cronulla - Miranda - Caringbah',
      geography: 591822,
    },
    {
      label: 'Dapto - Port Kembla',
      id: 'Dapto - Port Kembla',
      geography: 591823,
    },
    { label: 'Dubbo', id: 'Dubbo', geography: 591824 },
    {
      label: 'Dural - Wisemans Ferry',
      id: 'Dural - Wisemans Ferry',
      geography: 591825,
    },
    {
      label: 'Eastern Suburbs - North',
      id: 'Eastern Suburbs - North',
      geography: 591826,
    },
    {
      label: 'Eastern Suburbs - South',
      id: 'Eastern Suburbs - South',
      geography: 591827,
    },
    { label: 'Fairfield', id: 'Fairfield', geography: 591828 },
    { label: 'Gosford', id: 'Gosford', geography: 591829 },
    {
      label: 'Goulburn - Mulwaree',
      id: 'Goulburn - Mulwaree',
      geography: 591830,
    },
    { label: 'Great Lakes', id: 'Great Lakes', geography: 591831 },
    {
      label: 'Griffith - Murrumbidgee (West)',
      id: 'Griffith - Murrumbidgee (West)',
      geography: 591832,
    },
    { label: 'Hawkesbury', id: 'Hawkesbury', geography: 591833 },
    { label: 'Hornsby', id: 'Hornsby', geography: 591834 },
    { label: 'Hurstville', id: 'Hurstville', geography: 591835 },
    {
      label: 'Illawarra Catchment Reserve',
      id: 'Illawarra Catchment Reserve',
      geography: 591476,
    },
    {
      label: 'Inverell - Tenterfield',
      id: 'Inverell - Tenterfield',
      geography: 591837,
    },
    {
      label: 'Kempsey - Nambucca',
      id: 'Kempsey - Nambucca',
      geography: 591838,
    },
    {
      label: 'Kiama - Shellharbour',
      id: 'Kiama - Shellharbour',
      geography: 591839,
    },
    {
      label: 'Kogarah - Rockdale',
      id: 'Kogarah - Rockdale',
      geography: 591840,
    },
    { label: 'Ku-ring-gai', id: 'Ku-ring-gai', geography: 591841 },
    { label: 'Lachlan Valley', id: 'Lachlan Valley', geography: 591842 },
    {
      label: 'Lake Macquarie - East',
      id: 'Lake Macquarie - East',
      geography: 591843,
    },
    {
      label: 'Lake Macquarie - West',
      id: 'Lake Macquarie - West',
      geography: 591844,
    },
    { label: 'Leichhardt', id: 'Leichhardt', geography: 591845 },
    { label: 'Lithgow - Mudgee', id: 'Lithgow - Mudgee', geography: 591846 },
    { label: 'Liverpool', id: 'Liverpool', geography: 591847 },
    { label: 'Lord Howe Island', id: 'Lord Howe Island', geography: 591532 },
    { label: 'Lower Hunter', id: 'Lower Hunter', geography: 591849 },
    { label: 'Lower Murray', id: 'Lower Murray', geography: 591850 },
    { label: 'Maitland', id: 'Maitland', geography: 591851 },
    { label: 'Manly', id: 'Manly', geography: 591852 },
    {
      label: 'Marrickville - Sydenham - Petersham',
      id: 'Marrickville - Sydenham - Petersham',
      geography: 591853,
    },
    {
      label: 'Merrylands - Guildford',
      id: 'Merrylands - Guildford',
      geography: 591854,
    },
    { label: 'Moree - Narrabri', id: 'Moree - Narrabri', geography: 591855 },
    { label: 'Mount Druitt', id: 'Mount Druitt', geography: 591856 },
    { label: 'Newcastle', id: 'Newcastle', geography: 591857 },
    {
      label: 'North Sydney - Mosman',
      id: 'North Sydney - Mosman',
      geography: 591858,
    },
    { label: 'Orange', id: 'Orange', geography: 591859 },
    { label: 'Parramatta', id: 'Parramatta', geography: 591860 },
    {
      label: 'Pennant Hills - Epping',
      id: 'Pennant Hills - Epping',
      geography: 591861,
    },
    { label: 'Penrith', id: 'Penrith', geography: 591862 },
    { label: 'Pittwater', id: 'Pittwater', geography: 591863 },
    { label: 'Port Macquarie', id: 'Port Macquarie', geography: 591864 },
    { label: 'Port Stephens', id: 'Port Stephens', geography: 591865 },
    { label: 'Queanbeyan', id: 'Queanbeyan', geography: 591866 },
    {
      label: 'Richmond - Windsor',
      id: 'Richmond - Windsor',
      geography: 591867,
    },
    {
      label: 'Richmond Valley - Coastal',
      id: 'Richmond Valley - Coastal',
      geography: 591868,
    },
    {
      label: 'Richmond Valley - Hinterland',
      id: 'Richmond Valley - Hinterland',
      geography: 591869,
    },
    {
      label: 'Rouse Hill - McGraths Hill',
      id: 'Rouse Hill - McGraths Hill',
      geography: 591870,
    },
    {
      label: 'Ryde - Hunters Hill',
      id: 'Ryde - Hunters Hill',
      geography: 591871,
    },
    { label: 'Shoalhaven', id: 'Shoalhaven', geography: 591872 },
    { label: 'Snowy Mountains', id: 'Snowy Mountains', geography: 591873 },
    { label: 'South Coast', id: 'South Coast', geography: 591874 },
    {
      label: 'Southern Highlands',
      id: 'Southern Highlands',
      geography: 591875,
    },
    { label: 'St Marys', id: 'St Marys', geography: 591876 },
    {
      label: 'Strathfield - Burwood - Ashfield',
      id: 'Strathfield - Burwood - Ashfield',
      geography: 591877,
    },
    {
      label: 'Sutherland - Menai - Heathcote',
      id: 'Sutherland - Menai - Heathcote',
      geography: 591878,
    },
    { label: 'Sydney Inner City', id: 'Sydney Inner City', geography: 591879 },
    {
      label: 'Tamworth - Gunnedah',
      id: 'Tamworth - Gunnedah',
      geography: 591880,
    },
    {
      label: 'Taree - Gloucester',
      id: 'Taree - Gloucester',
      geography: 591881,
    },
    {
      label: 'Tumut - Tumbarumba',
      id: 'Tumut - Tumbarumba',
      geography: 591882,
    },
    { label: 'Tweed Valley', id: 'Tweed Valley', geography: 591205 },
    { label: 'Upper Hunter', id: 'Upper Hunter', geography: 591884 },
    {
      label: 'Upper Murray exc. Albury',
      id: 'Upper Murray exc. Albury',
      geography: 591885,
    },
    { label: 'Wagga Wagga', id: 'Wagga Wagga', geography: 591886 },
    { label: 'Warringah', id: 'Warringah', geography: 591887 },
    { label: 'Wollondilly', id: 'Wollondilly', geography: 591888 },
    { label: 'Wollongong', id: 'Wollongong', geography: 591889 },
    { label: 'Wyong', id: 'Wyong', geography: 591890 },
    { label: 'Young - Yass', id: 'Young - Yass', geography: 591891 },
  ],
  'nsw-lhd': [
    {
      label: 'Central Coast Local Health District',
      id: 'Central Coast Local Health District',
      geography: 592664,
    },
    {
      label: 'Far West Local Health District',
      id: 'Far West Local Health District',
      geography: 592665,
    },
    {
      label: 'Hunter New England Local Health District',
      id: 'Hunter New England Local Health District',
      geography: 592666,
    },
    {
      label: 'Illawarra Shoalhaven Local Health District',
      id: 'Illawarra Shoalhaven Local Health District',
      geography: 592667,
    },
    {
      label: 'Mid North Coast Local Health District',
      id: 'Mid North Coast Local Health District',
      geography: 592668,
    },
    {
      label: 'Murrumbidgee Local Health District',
      id: 'Murrumbidgee Local Health District',
      geography: 592669,
    },
    {
      label: 'Nepean Blue Mountains Local Health District',
      id: 'Nepean Blue Mountains Local Health District',
      geography: 592670,
    },
    {
      label: 'Northern NSW Local Health District',
      id: 'Northern NSW Local Health District',
      geography: 592671,
    },
    {
      label: 'Northern Sydney Local Health District',
      id: 'Northern Sydney Local Health District',
      geography: 592672,
    },
    {
      label: 'South Eastern Sydney Local Health District',
      id: 'South Eastern Sydney Local Health District',
      geography: 592673,
    },
    {
      label: 'South Western Sydney Local Health District',
      id: 'South Western Sydney Local Health District',
      geography: 592674,
    },
    {
      label: 'Southern NSW Local Health District',
      id: 'Southern NSW Local Health District',
      geography: 592675,
    },
    {
      label: 'Sydney Local Health District',
      id: 'Sydney Local Health District',
      geography: 592676,
    },
    {
      label: 'Western NSW Local Health District',
      id: 'Western NSW Local Health District',
      geography: 592677,
    },
    {
      label: 'Western Sydney Local Health District',
      id: 'Western Sydney Local Health District',
      geography: 592678,
    },
  ],
  'nsw-phn': [
    {
      label: 'Central and Eastern Sydney',
      id: 'Central and Eastern Sydney',
      geography: 592724,
    },
    {
      label: 'Hunter New England and Central Coast',
      id: 'Hunter New England and Central Coast',
      geography: 592725,
    },
    { label: 'Murrumbidgee', id: 'Murrumbidgee', geography: 592726 },
    {
      label: 'Nepean Blue Mountains',
      id: 'Nepean Blue Mountains',
      geography: 592727,
    },
    { label: 'North Coast', id: 'North Coast', geography: 592728 },
    { label: 'Northern Sydney', id: 'Northern Sydney', geography: 592729 },
    { label: 'South Eastern NSW', id: 'South Eastern NSW', geography: 592730 },
    {
      label: 'South Western Sydney',
      id: 'South Western Sydney',
      geography: 592731,
    },
    { label: 'Western NSW', id: 'Western NSW', geography: 592732 },
    { label: 'Western Sydney', id: 'Western Sydney', geography: 592733 },
  ],
  'nsw-ced-2018': [
    { label: 'Banks', id: 'Banks', geography: 593987 },
    { label: 'Barton', id: 'Barton', geography: 593988 },
    { label: 'Bennelong', id: 'Bennelong', geography: 593989 },
    { label: 'Berowra', id: 'Berowra', geography: 593990 },
    { label: 'Blaxland', id: 'Blaxland', geography: 593991 },
    { label: 'Bradfield', id: 'Bradfield', geography: 593992 },
    { label: 'Calare', id: 'Calare', geography: 593993 },
    { label: 'Chifley', id: 'Chifley', geography: 593994 },
    { label: 'Cook', id: 'Cook', geography: 593995 },
    { label: 'Cowper', id: 'Cowper', geography: 593996 },
    { label: 'Cunningham', id: 'Cunningham', geography: 593997 },
    { label: 'Dobell', id: 'Dobell', geography: 593998 },
    { label: 'Eden-Monaro', id: 'Eden-Monaro', geography: 593999 },
    { label: 'Farrer', id: 'Farrer', geography: 594000 },
    { label: 'Fowler', id: 'Fowler', geography: 594001 },
    { label: 'Gilmore', id: 'Gilmore', geography: 594002 },
    { label: 'Grayndler', id: 'Grayndler', geography: 594003 },
    { label: 'Greenway', id: 'Greenway', geography: 594004 },
    { label: 'Hughes', id: 'Hughes', geography: 594005 },
    { label: 'Hume', id: 'Hume', geography: 594006 },
    { label: 'Hunter', id: 'Hunter', geography: 594007 },
    { label: 'KingsfordSmith', id: 'KingsfordSmith', geography: 594008 },
    { label: 'Lindsay', id: 'Lindsay', geography: 594009 },
    { label: 'Lyne', id: 'Lyne', geography: 594010 },
    { label: 'Macarthur', id: 'Macarthur', geography: 594011 },
    { label: 'Mackellar', id: 'Mackellar', geography: 594012 },
    { label: 'Macquarie', id: 'Macquarie', geography: 594013 },
    { label: 'McMahon', id: 'McMahon', geography: 594014 },
    { label: 'Mitchell', id: 'Mitchell', geography: 594015 },
    { label: 'NewEngland', id: 'NewEngland', geography: 594016 },
    { label: 'Newcastle', id: 'Newcastle', geography: 594017 },
    { label: 'NorthSydney', id: 'NorthSydney', geography: 594018 },
    { label: 'Page', id: 'Page', geography: 594019 },
    { label: 'Parkes', id: 'Parkes', geography: 594020 },
    { label: 'Parramatta', id: 'Parramatta', geography: 594021 },
    { label: 'Paterson', id: 'Paterson', geography: 594022 },
    { label: 'Reid', id: 'Reid', geography: 594023 },
    { label: 'Richmond', id: 'Richmond', geography: 594024 },
    { label: 'Riverina', id: 'Riverina', geography: 594025 },
    { label: 'Robertson', id: 'Robertson', geography: 594026 },
    { label: 'Shortland', id: 'Shortland', geography: 594027 },
    { label: 'Sydney', id: 'Sydney', geography: 594028 },
    { label: 'Warringah', id: 'Warringah', geography: 594029 },
    { label: 'Watson', id: 'Watson', geography: 594030 },
    { label: 'Wentworth', id: 'Wentworth', geography: 594031 },
    { label: 'Werriwa', id: 'Werriwa', geography: 594032 },
    { label: 'Whitlam', id: 'Whitlam', geography: 594033 },
  ],
  'nsw-sed-2020': [
    { label: 'Albury', id: 'Albury', geography: 594066 },
    { label: 'Auburn', id: 'Auburn', geography: 594067 },
    { label: 'Ballina', id: 'Ballina', geography: 594068 },
    { label: 'Balmain', id: 'Balmain', geography: 594069 },
    { label: 'Bankstown', id: 'Bankstown', geography: 594070 },
    { label: 'Barwon', id: 'Barwon', geography: 594071 },
    { label: 'Bathurst', id: 'Bathurst', geography: 594072 },
    { label: 'BaulkhamHills', id: 'BaulkhamHills', geography: 594073 },
    { label: 'Bega', id: 'Bega', geography: 594074 },
    { label: 'Blacktown', id: 'Blacktown', geography: 594075 },
    { label: 'BlueMountains', id: 'BlueMountains', geography: 594076 },
    { label: 'Cabramatta', id: 'Cabramatta', geography: 594077 },
    { label: 'Camden', id: 'Camden', geography: 594078 },
    { label: 'Campbelltown', id: 'Campbelltown', geography: 594079 },
    { label: 'Canterbury', id: 'Canterbury', geography: 594080 },
    { label: 'CastleHill', id: 'CastleHill', geography: 594081 },
    { label: 'Cessnock', id: 'Cessnock', geography: 594082 },
    { label: 'Charlestown', id: 'Charlestown', geography: 594083 },
    { label: 'Clarence', id: 'Clarence', geography: 594084 },
    { label: 'CoffsHarbour', id: 'CoffsHarbour', geography: 594085 },
    { label: 'Coogee', id: 'Coogee', geography: 594086 },
    { label: 'Cootamundra', id: 'Cootamundra', geography: 594087 },
    { label: 'Cronulla', id: 'Cronulla', geography: 594088 },
    { label: 'Davidson', id: 'Davidson', geography: 594089 },
    { label: 'Drummoyne', id: 'Drummoyne', geography: 594090 },
    { label: 'Dubbo', id: 'Dubbo', geography: 594091 },
    { label: 'EastHills', id: 'EastHills', geography: 594092 },
    { label: 'Epping', id: 'Epping', geography: 594093 },
    { label: 'Fairfield', id: 'Fairfield', geography: 594094 },
    { label: 'Gosford', id: 'Gosford', geography: 594095 },
    { label: 'Goulburn', id: 'Goulburn', geography: 594096 },
    { label: 'Granville', id: 'Granville', geography: 594097 },
    { label: 'Hawkesbury', id: 'Hawkesbury', geography: 594098 },
    { label: 'Heathcote', id: 'Heathcote', geography: 594099 },
    { label: 'Heffron', id: 'Heffron', geography: 594100 },
    { label: 'Holsworthy', id: 'Holsworthy', geography: 594101 },
    { label: 'Hornsby', id: 'Hornsby', geography: 594102 },
    { label: 'Keira', id: 'Keira', geography: 594103 },
    { label: 'Kiama', id: 'Kiama', geography: 594104 },
    { label: 'Kogarah', id: 'Kogarah', geography: 594105 },
    { label: 'Ku-ring-gai', id: 'Ku-ring-gai', geography: 594106 },
    { label: 'LakeMacquarie', id: 'LakeMacquarie', geography: 594107 },
    { label: 'Lakemba', id: 'Lakemba', geography: 594108 },
    { label: 'LaneCove', id: 'LaneCove', geography: 594109 },
    { label: 'Lismore', id: 'Lismore', geography: 594110 },
    { label: 'Liverpool', id: 'Liverpool', geography: 594111 },
    { label: 'Londonderry', id: 'Londonderry', geography: 594112 },
    { label: 'MacquarieFields', id: 'MacquarieFields', geography: 594113 },
    { label: 'Maitland', id: 'Maitland', geography: 594114 },
    { label: 'Manly', id: 'Manly', geography: 594115 },
    { label: 'Maroubra', id: 'Maroubra', geography: 594116 },
    { label: 'Miranda', id: 'Miranda', geography: 594117 },
    { label: 'Monaro', id: 'Monaro', geography: 594118 },
    { label: 'MountDruitt', id: 'MountDruitt', geography: 594119 },
    { label: 'Mulgoa', id: 'Mulgoa', geography: 594120 },
    { label: 'Murray', id: 'Murray', geography: 594121 },
    { label: 'MyallLakes', id: 'MyallLakes', geography: 594122 },
    { label: 'Newcastle', id: 'Newcastle', geography: 594123 },
    { label: 'Newtown', id: 'Newtown', geography: 594124 },
    { label: 'NorthShore', id: 'NorthShore', geography: 594125 },
    {
      label: 'NorthernTablelands',
      id: 'NorthernTablelands',
      geography: 594126,
    },
    { label: 'Oatley', id: 'Oatley', geography: 594127 },
    { label: 'Orange', id: 'Orange', geography: 594128 },
    { label: 'Oxley', id: 'Oxley', geography: 594129 },
    { label: 'Parramatta', id: 'Parramatta', geography: 594130 },
    { label: 'Penrith', id: 'Penrith', geography: 594131 },
    { label: 'Pittwater', id: 'Pittwater', geography: 594132 },
    { label: 'PortMacquarie', id: 'PortMacquarie', geography: 594133 },
    { label: 'PortStephens', id: 'PortStephens', geography: 594134 },
    { label: 'Prospect', id: 'Prospect', geography: 594135 },
    { label: 'Riverstone', id: 'Riverstone', geography: 594136 },
    { label: 'Rockdale', id: 'Rockdale', geography: 594137 },
    { label: 'Ryde', id: 'Ryde', geography: 594138 },
    { label: 'SevenHills', id: 'SevenHills', geography: 594139 },
    { label: 'Shellharbour', id: 'Shellharbour', geography: 594140 },
    { label: 'SouthCoast', id: 'SouthCoast', geography: 594141 },
    { label: 'Strathfield', id: 'Strathfield', geography: 594142 },
    { label: 'SummerHill', id: 'SummerHill', geography: 594143 },
    { label: 'Swansea', id: 'Swansea', geography: 594144 },
    { label: 'Sydney', id: 'Sydney', geography: 594145 },
    { label: 'Tamworth', id: 'Tamworth', geography: 594146 },
    { label: 'Terrigal', id: 'Terrigal', geography: 594147 },
    { label: 'TheEntrance', id: 'TheEntrance', geography: 594148 },
    { label: 'Tweed', id: 'Tweed', geography: 594149 },
    { label: 'UpperHunter', id: 'UpperHunter', geography: 594150 },
    { label: 'Vaucluse', id: 'Vaucluse', geography: 594151 },
    { label: 'WaggaWagga', id: 'WaggaWagga', geography: 594152 },
    { label: 'Wakehurst', id: 'Wakehurst', geography: 594153 },
    { label: 'Wallsend', id: 'Wallsend', geography: 594154 },
    { label: 'Willoughby', id: 'Willoughby', geography: 594155 },
    { label: 'Wollondilly', id: 'Wollondilly', geography: 594156 },
    { label: 'Wollongong', id: 'Wollongong', geography: 594157 },
    { label: 'Wyong', id: 'Wyong', geography: 594158 },
  ],
  'nsw-sa4-2016': [
    { label: 'Capital Region', id: 'Capital Region', geography: 596751 },
    { label: 'Central Coast', id: 'Central Coast', geography: 596752 },
    { label: 'Central West', id: 'Central West', geography: 596753 },
    {
      label: 'Coffs Harbour - Grafton',
      id: 'Coffs Harbour - Grafton',
      geography: 596754,
    },
    {
      label: 'Far West and Orana',
      id: 'Far West and Orana',
      geography: 596755,
    },
    {
      label: 'Hunter Valley exc Newcastle',
      id: 'Hunter Valley exc Newcastle',
      geography: 596756,
    },
    { label: 'Illawarra', id: 'Illawarra', geography: 596757 },
    { label: 'Mid North Coast', id: 'Mid North Coast', geography: 596758 },
    { label: 'Murray', id: 'Murray', geography: 596759 },
    {
      label: 'New England and North West',
      id: 'New England and North West',
      geography: 596760,
    },
    {
      label: 'Newcastle and Lake Macquarie',
      id: 'Newcastle and Lake Macquarie',
      geography: 596761,
    },
    { label: 'Richmond - Tweed', id: 'Richmond - Tweed', geography: 596762 },
    { label: 'Riverina', id: 'Riverina', geography: 596763 },
    {
      label: 'Southern Highlands and Shoalhaven',
      id: 'Southern Highlands and Shoalhaven',
      geography: 596764,
    },
    {
      label: 'Sydney - Baulkham Hills and Hawkesbury',
      id: 'Sydney - Baulkham Hills and Hawkesbury',
      geography: 596765,
    },
    {
      label: 'Sydney - Blacktown',
      id: 'Sydney - Blacktown',
      geography: 596766,
    },
    {
      label: 'Sydney - City and Inner South',
      id: 'Sydney - City and Inner South',
      geography: 596767,
    },
    {
      label: 'Sydney - Eastern Suburbs',
      id: 'Sydney - Eastern Suburbs',
      geography: 596768,
    },
    {
      label: 'Sydney - Inner South West',
      id: 'Sydney - Inner South West',
      geography: 596769,
    },
    {
      label: 'Sydney - Inner West',
      id: 'Sydney - Inner West',
      geography: 596770,
    },
    {
      label: 'Sydney - North Sydney and Hornsby',
      id: 'Sydney - North Sydney and Hornsby',
      geography: 596771,
    },
    {
      label: 'Sydney - Northern Beaches',
      id: 'Sydney - Northern Beaches',
      geography: 596772,
    },
    {
      label: 'Sydney - Outer South West',
      id: 'Sydney - Outer South West',
      geography: 596773,
    },
    {
      label: 'Sydney - Outer West and Blue Mountains',
      id: 'Sydney - Outer West and Blue Mountains',
      geography: 596774,
    },
    {
      label: 'Sydney - Parramatta',
      id: 'Sydney - Parramatta',
      geography: 596775,
    },
    { label: 'Sydney - Ryde', id: 'Sydney - Ryde', geography: 596776 },
    {
      label: 'Sydney - South West',
      id: 'Sydney - South West',
      geography: 596777,
    },
    {
      label: 'Sydney - Sutherland',
      id: 'Sydney - Sutherland',
      geography: 596778,
    },
  ],
};

const buildKnownPlatformLocationLabels = (platformKey) =>
  pipe(
    get(platformKey),
    values,
    flatten,
    keyBy('geography'),
    mapValues('label'),
  )(knownPlatformLocations);

export { knownPlatformLocations, buildKnownPlatformLocationLabels };
