/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isFunction, noop } from 'lodash/fp';
import DeckGL from '@deck.gl/react';
import { InteractiveMap, FlyToInterpolator } from 'react-map-gl';
import { WebMercatorViewport } from '@math.gl/web-mercator';
import { easeCubic } from 'd3-ease';
import useOnce from 'hooks/useOnce';
import LIGHT_STYLE from './styles/light.json';
import DARK_STYLE from './styles/dark.json';
import 'mapbox-gl/dist/mapbox-gl.css';

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const MAP_STYLES = {
  custom: 'mapbox://styles/kinesis/cjsjkjn282u7f1fqhfmctm41i',
  dark: DARK_STYLE,
  decimal: 'mapbox://styles/kinesis/cjk9cgk4098q32sqz64lmp1xx',
  light: LIGHT_STYLE,
  light3d: 'mapbox://styles/kinesis/cjyqutsek0gev1cjzw66mw4q7',
  outdoors: 'mapbox://styles/mapbox/outdoors-v11?optimize=true',
  satellite: 'mapbox://styles/mapbox/satellite-v9?optimize=true',
};

const propTypes = {
  bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  defaultZoom: PropTypes.number,
  dragPan: PropTypes.bool,
  getCursor: PropTypes.func,
  layers: PropTypes.array,
  mapStyle: PropTypes.oneOf([
    'custom',
    'dark',
    'decimal',
    'light',
    'light3d',
    'outdoors',
    'satellite',
  ]),
  mapType: PropTypes.string,
  mapboxAccessToken: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDragStart: PropTypes.func,
  onHover: PropTypes.func,
  onMouseOut: PropTypes.func,
  onViewportChange: PropTypes.func,
  padding: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  scrollZoom: PropTypes.bool,
  target: PropTypes.object,
};

const defaultProps = {
  bounds: undefined,
  dragPan: true,
  getCursor: undefined,
  layers: [],
  mapStyle: 'light',
  mapType: undefined,
  onClick: undefined,
  onDragStart: undefined,
  onHover: undefined,
  onMouseOut: undefined,
  onViewportChange: undefined,
  padding: 20,
  paddingBottom: undefined,
  paddingLeft: undefined,
  paddingRight: undefined,
  paddingTop: undefined,
  scrollZoom: true,
  defaultZoom: 4,
  target: undefined,
};

const Map = forwardRef(
  (
    {
      bounds,
      defaultZoom,
      dragPan,
      getCursor,
      layers,
      mapStyle,
      mapType,
      mapboxAccessToken,
      onClick,
      onHover,
      onDragStart,
      onMouseOut,
      onViewportChange,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      scrollZoom,
      target,
    },
    ref,
  ) => {
    const boundedRef = useRef(false);
    const [mapState, setMapState] = useState({
      bearing: 0,
      latitude: target ? target.latitude : -25,
      longitude: target ? target.longitude : 134,
      pitch: mapType === 'location-3d' ? 50 : 0,
      zoom: target ? target.zoom : defaultZoom,
    });
    const { height, width } = mapState;
    const hasFiniteDimensions = useOnce(
      typeof height === 'number' &&
        height > 0 &&
        typeof width === 'number' &&
        width > 0,
    );

    const handleViewportChange = useCallback(
      (state) => {
        setMapState((current) => {
          const newState = isFunction(state) ? state(current) : state;
          if (onViewportChange) {
            onViewportChange(newState);
          }
          return newState;
        });
      },
      [onViewportChange],
    );

    useEffect(() => {
      if (target) {
        handleViewportChange((state) => ({
          ...state,
          latitude: target.latitude,
          longitude: target.longitude,
          transitionDuration: 5000,
          transitionEasing: easeCubic,
          transitionInterpolator: new FlyToInterpolator(),
          zoom: target.zoom ? target.zoom : state.zoom,
        }));
      }
    }, [handleViewportChange, target]);

    useEffect(() => {
      if (hasFiniteDimensions && Array.isArray(bounds)) {
        handleViewportChange((state) => {
          try {
            const viewport = new WebMercatorViewport(state);
            const newViewport = viewport.fitBounds(bounds, {
              padding: {
                top: paddingTop !== undefined ? paddingTop : padding,
                right: paddingRight !== undefined ? paddingRight : padding,
                bottom: paddingBottom !== undefined ? paddingBottom : padding,
                left: paddingLeft !== undefined ? paddingLeft : padding,
              },
            });

            return {
              ...newViewport,
              pitch: mapType === 'location-3d' ? 50 : 0,
              transitionDuration: boundedRef.current ? 1000 : undefined,
              transitionEasing: easeCubic,
              transitionInterpolator: new FlyToInterpolator(),
              zoom: Math.min(newViewport.zoom, 15),
            };
          } catch (error) {
            return state;
          } finally {
            boundedRef.current = true;
          }
        });
      }
    }, [
      handleViewportChange,
      hasFiniteDimensions,
      bounds,
      mapType,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
    ]);

    return (
      <MapContainer ref={ref}>
        <InteractiveMap
          {...mapState}
          dragPan={dragPan}
          onHover={onHover}
          onMouseOut={onMouseOut}
          getCursor={getCursor}
          height='100%'
          keyboard={false}
          mapStyle={MAP_STYLES[mapStyle]}
          mapboxApiAccessToken={mapboxAccessToken}
          onViewportChange={handleViewportChange}
          ref={ref}
          scrollZoom={scrollZoom}
          width='100%'
        >
          <DeckGL
            getCursor={noop}
            layers={layers}
            onClick={onClick}
            onDragStart={onDragStart}
            viewState={mapState}
          />
        </InteractiveMap>
      </MapContainer>
    );
  },
);

Map.propTypes = propTypes;
Map.defaultProps = defaultProps;

export default Map;
