import { createSelector } from 'reselect';
import { map, noop, get } from 'lodash/fp';
import Units from 'data/units';
import blockDataSelector from 'selectors/blockDataSelector';
import { X, Y } from 'data/xy-chart';
import namedScenariosSelector from 'selectors/namedScenariosSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import xyChartSeriesSelector from 'selectors/xyChartSeriesSelector';
import locationsSelector from 'selectors/locationsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';

const units = new Units();

const options = (blockData, series, locations, instances, namedScenarios) => {
  const metadata = get(['data', 'metadata'], blockData);
  const xUnit =
    metadata &&
    units.parseColumn(
      get(['x', 'column'], metadata),
      locations,
      instances,
      namedScenarios,
    );
  const xUnitComparator = xUnit ? xUnit.compare : noop;
  const xUnitFormatter = xUnit ? xUnit.format : noop;
  const xLevels = X.levels(metadata, xUnitComparator);
  const xLabel = X.label(metadata);
  const yUnit =
    metadata &&
    units.parseColumn(
      get(['y', 'column'], metadata),
      locations,
      instances,
      namedScenarios,
    );
  const yLabel = Y.label(metadata, yUnit);
  switch (get(['data', 'type'], blockData)) {
    case 'stacked-column':
    case 'stacked-column-time-series': {
      return {
        chart: {
          type: 'column',
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          },
        },
        yAxis: {
          title: {
            text: yLabel,
          },
          ...Y.bounds(metadata),
        },
        xAxis: {
          categories: map(xUnitFormatter, xLevels),
          labels: {},
          crosshair: {
            color: '#e9f3f7',
            zIndex: -1,
          },
          title: {
            text: xLabel,
          },
        },
        series,
      };
    }
    case 'clustered-column':
    case 'clustered-column-time-series':
      return {
        chart: {
          type: 'column',
        },
        yAxis: {
          title: {
            text: yLabel,
          },
          ...Y.bounds(metadata),
        },
        xAxis: {
          categories: map(xUnitFormatter, xLevels),
          labels: {},
          crosshair: {
            color: '#e9f3f7',
            zIndex: -1,
          },
          title: {
            text: xLabel,
          },
        },
        series,
      };
    case 'line':
    case 'line-time-series':
      return {
        chart: {
          type: 'line',
        },
        plotOptions: {
          series: {
            marker: { enabled: xLevels.length === 1, symbol: 'circle' },
          },
        },
        yAxis: {
          title: {
            text: yLabel,
          },
          ...Y.bounds(metadata),
        },
        xAxis: {
          categories: map(xUnitFormatter, xLevels),
          labels: {},
          crosshair: {
            color: '#e9f3f7',
            zIndex: -1,
          },
          title: {
            text: xLabel,
          },
        },
        series,
      };
    case 'stacked-area':
    case 'stacked-area-time-series':
      return {
        chart: {
          type: 'area',
        },
        plotOptions: {
          area: {
            stacking: 'normal',
          },
          series: {
            marker: { enabled: xLevels.length === 1, symbol: 'circle' },
          },
        },
        yAxis: {
          title: {
            text: yLabel,
          },
          ...Y.bounds(metadata),
        },
        xAxis: {
          categories: map(xUnitFormatter, xLevels),
          labels: {},
          crosshair: {
            color: '#e9f3f7',
            zIndex: -1,
          },
          title: {
            text: xLabel,
          },
        },
        series,
      };
    default:
      return undefined;
  }
};

const xyChartOptionsSelector = createSelector(
  blockDataSelector,
  locationsSelector,
  workspaceInstantiationsSelector,
  namedScenariosSelector,
  makeAggregationFilterSelector('year'),
  xyChartSeriesSelector,
  (blockData, locations, instances, namedScenarios, selectedYear, series) =>
    options(blockData, series, locations, instances, namedScenarios),
);

export default xyChartOptionsSelector;
