import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAppHeaderContext from 'components/app-header/useAppHeaderContext';

const propTypes = {
  to: PropTypes.string.isRequired,
};
const defaultProps = {};

const BackButton = ({ to }) => {
  const { setBackTo } = useAppHeaderContext();

  useEffect(() => {
    setBackTo(to);
  }, [setBackTo, to]);

  return null;
};

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;

export default BackButton;
