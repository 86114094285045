import { forwardRef, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns/fp';
import {
  Avatar,
  Card,
  CardBanner,
  CardButton,
  CardContent,
  CardMenu,
  CardMenuItem,
  CardSubtitle,
  CardTitle,
  Tooltip,
} from '@kinesis/bungle';
import useDateDistance from 'hooks/useDateDistance';

const propTypes = {
  editor: PropTypes.bool,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  organisationImageUrl: PropTypes.string,
  organisationName: PropTypes.string,
  previewImageUrl: PropTypes.string,
  to: PropTypes.string.isRequired,
  touchedAt: PropTypes.string.isRequired,
};

const defaultProps = {
  editor: false,
  organisationImageUrl: undefined,
  organisationName: '',
  previewImageUrl: undefined,
};

const WorkspaceCard = forwardRef(
  (
    {
      editor,
      id,
      name,
      onDelete,
      onDuplicate,
      organisationImageUrl,
      organisationName,
      previewImageUrl,
      to,
      touchedAt,
    },
    ref,
  ) => {
    const handleDelete = useCallback(() => {
      onDelete(id);
    }, [onDelete, id]);

    const handleDuplicate = useCallback(() => {
      onDuplicate(id);
    }, [onDuplicate, id]);

    const touchedAtDate = useMemo(
      () => (touchedAt ? parseISO(touchedAt) : undefined),
      [touchedAt],
    );
    const dateDistance = useDateDistance(touchedAtDate);

    return (
      <Card ref={ref}>
        <CardButton as={Link} to={to}>
          <CardBanner alt='map' image={previewImageUrl}>
            <Tooltip placement='top' title={organisationName}>
              <Avatar
                alt={`${organisationName} logo`}
                appearance='outlined'
                image={organisationImageUrl}
                magnitude='large'
                variant='organisation'
              >
                {organisationName.slice(0, 1)}
              </Avatar>
            </Tooltip>
          </CardBanner>
          <CardContent noWrap>
            <CardTitle>{name}</CardTitle>
            {touchedAt && (
              <Tooltip title={format('d MMM yyyy, h:mm a', touchedAtDate)}>
                <CardSubtitle>Last updated {dateDistance}</CardSubtitle>
              </Tooltip>
            )}
          </CardContent>
        </CardButton>
        <CardMenu variant='concealed'>
          <CardMenuItem onSelect={handleDuplicate}>Duplicate</CardMenuItem>
          <CardMenuItem
            variant='danger'
            disabled={!editor}
            onSelect={handleDelete}
          >
            Delete
          </CardMenuItem>
        </CardMenu>
      </Card>
    );
  },
);

WorkspaceCard.propTypes = propTypes;
WorkspaceCard.defaultProps = defaultProps;

export default WorkspaceCard;
