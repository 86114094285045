import PropTypes from 'prop-types';
import { isEmpty, map, pipe, placeholder, size, times, zip } from 'lodash/fp';
import { ConfirmationDialog } from '@kinesis/bungle';
import Plural from 'components/plural/plural';
import { AppLabel } from './app-management.styles';

const propTypes = {
  animation: PropTypes.bool,
  appName: PropTypes.string.isRequired,
  dependencyLabels: PropTypes.arrayOf(PropTypes.string),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const defaultProps = {
  animation: true,
  dependencyLabels: [],
};

const oxfordCommaSeperators = (length) => {
  switch (length) {
    case 1:
      return [];
    case 2:
      return [' and '];
    default:
      return [...times(() => ', ', length - 2), ', and '];
  }
};

const AddConfirmationDialog = ({
  animation,
  appName,
  dependencyLabels,
  onCancel,
  onConfirm,
}) => {
  const appLabels = pipe(
    map((label) => <AppLabel>{label}</AppLabel>),
    zip(placeholder, oxfordCommaSeperators(size(dependencyLabels))),
  )(dependencyLabels);

  if (isEmpty(dependencyLabels)) {
    return null;
  }
  return (
    <ConfirmationDialog
      animation={animation}
      cancelText='Don’t add'
      confirmText={
        <>
          Add{' '}
          <Plural
            formatCount={(val) => (val === 1 ? '' : val)}
            count={size(dependencyLabels)}
            word='apps'
            inclusive
          />
        </>
      }
      icon='exclamation'
      minWidth={320}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={`“${appName}” depends on other apps to produce outputs.`}
      variant='primary'
    >
      {appLabels} will also be added as this app depends on{' '}
      {size(dependencyLabels) > 1 ? 'them' : 'it'}.
    </ConfirmationDialog>
  );
};

AddConfirmationDialog.defaultProps = defaultProps;
AddConfirmationDialog.propTypes = propTypes;

export default AddConfirmationDialog;
