import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'lodash/fp';
import {
  deselectLinePoints,
  returnLinesToUnselectedState,
  selectHoveredLinePoints,
  selectLinePoints,
} from 'utils/charts/line';
import chartLegendTitleSelector from 'selectors/chartLegendTitleSelector';
import genericChartCapsuleSelector from 'selectors/genericChartCapsuleSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import ChartLegend from './ChartLegend';
import ChartImpl from './ChartImpl';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  boardId: PropTypes.number.isRequired,
  perspectives: PropTypes.object,
  privacy: PropTypes.oneOf(['private', 'public']),
  scenarioId: PropTypes.number.isRequired,
  showLegend: PropTypes.bool,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  perspectives: undefined,
  privacy: 'private',
  showLegend: false,
};

const LineChart = ({
  blockId,
  boardId,
  perspectives,
  privacy,
  scenarioId,
  showLegend,
  workspaceId,
  ...restProps
}) => {
  const title = useSelectorWithProps(chartLegendTitleSelector, {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });
  const { options, series, xUnit } = useSelectorWithProps(
    genericChartCapsuleSelector,
    {
      blockId,
      boardId,
      perspectives,
      public: privacy === 'public',
      scenarioId,
      workspaceId,
    },
  );

  const handleSelectPoints = useCallback((chart) => {
    if (chart.hoverPoints) {
      deselectLinePoints(chart);
      selectHoveredLinePoints(chart);
    }
  }, []);

  const handleSelectPointValue = useCallback((chart, value) => {
    if (value) {
      selectLinePoints(chart, value);
    } else {
      returnLinesToUnselectedState(
        flatten(chart.series.map((s) => s.points)),
        chart,
      );
    }
    chart.redraw();
  }, []);

  return (
    <ChartImpl
      blockId={blockId}
      formatLabel={xUnit.format}
      legend={
        showLegend ? (
          <ChartLegend icon='line' series={series} title={title} />
        ) : undefined
      }
      onExternalClick={returnLinesToUnselectedState}
      onSelectPoints={handleSelectPoints}
      onSelectPointValue={handleSelectPointValue}
      options={options}
      {...restProps}
    />
  );
};

LineChart.propTypes = propTypes;
LineChart.defaultProps = defaultProps;

export default LineChart;
