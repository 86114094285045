import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';
import { RedirectReload } from 'components/redirect-reload';
import * as RedirectTo from 'services/redirect-to';

const propTypes = {
  isLoggedIn: PropTypes.bool,
};

const defaultProps = {
  isLoggedIn: false,
};

const WorkspacesSharesRoutes = ({ isLoggedIn }) => {
  const { action, token } = useParams();

  useEffect(() => {
    if (!isLoggedIn) {
      RedirectTo.set(`/workspaces/shares/${token}`);
    }
  }, [isLoggedIn, token]);

  if (isLoggedIn) {
    return <Navigate to={`/workspaces/shares/${token}`} />;
  }

  if (action === 'accept') {
    return <RedirectReload to={`/workspaces/shares/${token}`} />;
  }

  if (action === 'register') {
    return <RedirectReload to={`/workspaces/shares/${token}/registration`} />;
  }

  return null;
};

WorkspacesSharesRoutes.propTypes = propTypes;
WorkspacesSharesRoutes.defaultProps = defaultProps;

export { WorkspacesSharesRoutes };
