import { createSlice } from '@reduxjs/toolkit';
import { reduce, set } from 'lodash/fp';
import { workspacePreview } from 'actions/workspacePreview';
import { workspacePreviews } from 'actions/workspacePreviews';

const { reducer, actions } = createSlice({
  name: 'workspacePreview',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(workspacePreview.fulfilled, (state, action) =>
      set(action.meta.arg.id, action.payload, state),
    );

    builder.addCase(workspacePreviews.fulfilled, (state, action) =>
      reduce(
        (acc, { id, previewImageUrl }) => set(id, { previewImageUrl }, acc),
        state,
        action.payload.workspaces,
      ),
    );
  },
});

export { reducer, actions };
