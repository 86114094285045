import { get } from 'lodash/fp';
import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import workspaceSelector from 'selectors/workspaceSelector';

const mapToInts = (things) => things.map((thing) => parseInt(thing, 10));

const generateTargetPairs = (state, { targetScenarioIds, workspaceId }) => {
  const { scenarios } = workspaceSelector(state, { workspaceId });
  const targetScenarioIdsInt = mapToInts(targetScenarioIds);

  const targetPairs = targetScenarioIdsInt.map((targetScenarioId) => {
    const targetScenario = get(['named', targetScenarioId], scenarios);
    const targetScenarioKey =
      get(['draft', 'scenario'], targetScenario) ||
      get(['published', 'scenario'], targetScenario);
    return {
      targetScenarioId,
      targetScenarioKey,
    };
  });

  return targetPairs;
};

const workspacePatch = createAsyncThunk(
  'workspace/patch',
  async (
    { actions, patchOps, targetScenarioIds, workspaceId },
    { dispatch, getState },
  ) => {
    const api = createApiFromState({ dispatch, getState });
    const state = getState();

    const response = await api.patch(`/api/workspace/${workspaceId}`, {
      actions,
      patchOps,
      targetPairs: generateTargetPairs(state, {
        targetScenarioIds,
        workspaceId,
      }),
    });

    return { ...response.data, scenarioIds: mapToInts(targetScenarioIds) };
  },
  {
    getPendingMeta: (
      { arg: { targetScenarioIds, workspaceId } },
      { getState },
    ) => {
      const state = getState();

      return {
        targets: generateTargetPairs(state, { targetScenarioIds, workspaceId }),
      };
    },
  },
);

export { workspacePatch };
