import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetsList = createAsyncThunk(
  'datasets/list',
  async (_, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.get(`/api/datasets`);
    return response.data;
  },
);

export { datasetsList };
