const qualitativeColors = [
  '#2D4F75',
  '#6FB6C5',
  '#2D755B',
  '#6FC58B',
  '#752D49',
  '#C56FA8',
  '#2E3033',
  '#518FD5',
  '#D5972B',
  '#732E9D',
];

const sequentialColors = ['#A6DCE1', '#092D66'];

// This is altered to allow for the gray background of maps, but maintain some of the brightness
const divergingChoroplethColors = [
  '#003FA3',
  '#78EEF7',
  '#D1FFB8',
  '#F4F778',
  '#A35100',
];

export { qualitativeColors, sequentialColors, divergingChoroplethColors };
