import { useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  sortBy,
  isEmpty,
  isNil,
  filter,
  find,
  first,
  get,
  keyBy,
  pipe,
  placeholder,
} from 'lodash/fp';
import { H2, Input, Menu, Stack, UtilityButton } from '@kinesis/bungle';
import { Toolbar, ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import Modal from 'components/modals/modal/Modal';
import Layout from 'components/layout';
import { Loading } from 'components/loading';
import { LoadableContent } from 'components/loadable-content';
import { LegacyContent } from 'components/legacy-content';
import { ResourceAvailable } from 'components/resource-available';
import { PlaceholderMessage } from 'components/placeholder-message';
import { ScenarioDropdown } from 'components/scenario-dropdown';
import tablesSelector from 'selectors/tablesSelector';
import loadedAppVersionsSelector from 'selectors/loadedAppVersionsSelector';
import loadedAppsSelector from 'selectors/loadedAppsSelector';
import resourceAvailableSelector from 'selectors/resourceAvailableSelector';
import sortedScenariosSelector from 'selectors/sortedScenariosSelector';
import { downloadCreate } from 'actions/downloadCreate';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { ScrollablePane } from 'components/styled/ScrollablePane';
import { ModalHeader, ModalHeading } from '../modal-header';
import {
  InputBackground,
  Metadata,
  DescriptionPane,
  P,
  Processing,
  SidePane,
  ToolbarBackground,
} from './manage-data.styles';

const propTypes = {
  animation: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  animation: true,
};

const findPublishedBy = (apps, versions, resource) =>
  pipe(
    find((v) => find({ resource }, v.resources)),
    get('app'),
    get(placeholder, keyBy('id', apps)),
  )(versions);

const ManageDataModal = ({ animation, onClose, workspaceId }) => {
  const dispatch = useDispatch();
  const apps = useSelectorWithProps(loadedAppsSelector, { workspaceId });
  const appVersions = useSelectorWithProps(loadedAppVersionsSelector, {
    workspaceId,
  });
  const tables = useSelectorWithProps(tablesSelector, { workspaceId });
  const sorted = useMemo(() => sortBy('label', tables), [tables]);
  const scenarios = useSelectorWithProps(sortedScenariosSelector, {
    workspaceId,
  });
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState(first(sorted));
  const [selectedScenario, setSelectedScenario] = useState();
  const selectedScenarioKey = useMemo(() => {
    const scenario = find({ id: selectedScenario }, scenarios);
    return (
      get(['draft', 'scenario'], scenario) ||
      get(['published', 'scenario'], scenario)
    );
  }, [selectedScenario, scenarios]);
  const available = useSelectorWithProps(resourceAvailableSelector, {
    resource: get('resourceId', selected),
    scenarioKey: selectedScenarioKey,
    workspaceId,
  });
  const loading = isNil(available);
  const publishedBy = useMemo(
    () =>
      selected
        ? findPublishedBy(apps, appVersions, selected.resourceId)
        : undefined,
    [apps, appVersions, selected],
  );
  const filtered = useMemo(
    () =>
      filter(
        ({ label }) =>
          isEmpty(searchValue) ||
          label.toLowerCase().includes(searchValue.toLowerCase()),
        sorted,
      ),
    [sorted, searchValue],
  );
  const download = useCallback(() => {
    dispatch(
      downloadCreate({
        workspace: workspaceId,
        scenario: selectedScenarioKey,
        resource: selected.resourceId,
      }),
    );
  }, [dispatch, workspaceId, selectedScenarioKey, selected]);
  const headerLabel = 'Manage data';

  return (
    <Modal
      header={
        <ModalHeader>
          <ModalHeading>{headerLabel}</ModalHeading>
        </ModalHeader>
      }
      aria-label={headerLabel}
      animation={animation}
      onClose={onClose}
      saveable={false}
    >
      <Layout direction='column'>
        <Layout direction='row'>
          <SidePane>
            <Layout direction='column' overflow='hidden'>
              <InputBackground>
                <Input
                  onChange={setSearchValue}
                  placeholder='Filter data…'
                  value={searchValue}
                />
              </InputBackground>
              <ScrollablePane>
                <LegacyContent>
                  <Menu magnitude='large' spacing='none'>
                    <Menu.Item content='App outputs' collapsible header>
                      {filtered.map((table) => (
                        <Menu.Item
                          icon='table'
                          content={table.label}
                          key={table.resourceId}
                          onClick={() => setSelected(table)}
                          variant={
                            get('resourceId', selected) === table.resourceId
                              ? 'accent'
                              : undefined
                          }
                        />
                      ))}
                    </Menu.Item>
                  </Menu>
                </LegacyContent>
              </ScrollablePane>
            </Layout>
          </SidePane>
          <Layout direction='column'>
            <ToolbarBackground>
              <Toolbar justify='space-between'>
                <ToolbarGroup>
                  {!loading && !available && (
                    <>
                      <ToolbarItem>
                        <Loading magnitude='small' />
                      </ToolbarItem>
                      <ToolbarItem>
                        <Processing>Processing output</Processing>
                      </ToolbarItem>
                    </>
                  )}
                  <ToolbarItem>
                    {(loading || available) && (
                      <UtilityButton
                        magnitude='small'
                        icon='download'
                        disabled={loading || isEmpty(selected)}
                        onClick={download}
                      >
                        Download as CSV
                      </UtilityButton>
                    )}
                  </ToolbarItem>
                </ToolbarGroup>
                <ToolbarGroup>
                  <ToolbarItem>
                    <ScenarioDropdown
                      disabled={loading || isEmpty(selected)}
                      scenarios={scenarios}
                      value={selectedScenario}
                      onSelect={setSelectedScenario}
                    />
                  </ToolbarItem>
                </ToolbarGroup>
              </Toolbar>
            </ToolbarBackground>
            <PlaceholderMessage
              text='Select an output to view its properties.'
              isEmpty={isEmpty(selected)}
            >
              <ResourceAvailable
                resource={get('resourceId', selected)}
                scenarioKey={selectedScenarioKey}
                workspaceId={workspaceId}
              >
                <LoadableContent loading={loading}>
                  <LegacyContent>
                    <Stack space='medium'>
                      <H2>{get('label', selected)}</H2>
                      <DescriptionPane>
                        {selected &&
                          (selected.notes || []).map((note) => (
                            <P key={note}>{note}</P>
                          ))}

                        <Stack>
                          {publishedBy && (
                            <Metadata>
                              Published by {publishedBy.label} (
                              {publishedBy.owner.label})
                            </Metadata>
                          )}
                          <Metadata>
                            Version {get('version', selected)}
                          </Metadata>
                        </Stack>
                      </DescriptionPane>
                    </Stack>
                  </LegacyContent>
                </LoadableContent>
              </ResourceAvailable>
            </PlaceholderMessage>
          </Layout>
        </Layout>
      </Layout>
    </Modal>
  );
};

ManageDataModal.defaultProps = defaultProps;
ManageDataModal.propTypes = propTypes;

export { ManageDataModal };
