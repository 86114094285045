import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const blockUpdateDetails = createAsyncThunk(
  'block/update-details',
  async (
    { workspaceId, boardId, blockId, blockParams },
    { dispatch, getState },
  ) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.patch(
      `/api/workspace/${workspaceId}/board/${boardId}/block/${blockId}`,
      { blockParams },
    );

    return response.data;
  },
);

export { blockUpdateDetails };
