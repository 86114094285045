import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetCreate = createAsyncThunk(
  'dataset/create',
  async ({ owner, name, type }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/datasets`, {
      owner,
      name,
      type,
    });
    return response.data;
  },
);

export { datasetCreate };
