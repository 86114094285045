import styled from 'styled-components';

// NOTE: unfortunately, this is coming from bungle which doesn't expose elements to build
// a legend in this style, only a fixed layout. Using the exact styles to avoid variation.

const LegendGroup = styled.section`
  &&:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

const LegendHeading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  color: ${({ subheading, theme }) =>
    subheading ? theme.color.gray7 : theme.color.gray9};
`;

const LegendNoData = styled.p`
  &&:before {
    content: 'No data.';
  }
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  color: ${({ theme }) => theme.color.gray6};
  padding: 16px 0;
  margin: 0;
  text-align: center;
`;

const LegendList = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const LegendItemWrapper = styled.li`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-variant-numeric: tabular-nums;
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  display: flex;
  margin-bottom: 4px;
`;

export {
  LegendGroup,
  LegendHeading,
  LegendList,
  LegendNoData,
  LegendItemWrapper,
};
