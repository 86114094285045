import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { UtilityButton } from '@kinesis/bungle';
import useAppHeaderContext from './useAppHeaderContext';
import { BreadcrumbSeparator, StyledNavLink } from './breadcrumbs.styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  root: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
};

const defaultProps = {
  root: false,
  to: undefined,
};

const Breadcrumb = ({ children, root, to, ...props }) => {
  const { breadcrumbPortalNode } = useAppHeaderContext();

  if (!breadcrumbPortalNode) {
    return null;
  }

  const breadcrumb = to ? (
    <UtilityButton as={StyledNavLink} {...props} to={to}>
      {children}
    </UtilityButton>
  ) : (
    children
  );

  return ReactDOM.createPortal(
    <>
      {!root && <BreadcrumbSeparator>/</BreadcrumbSeparator>}
      {breadcrumb}
    </>,
    breadcrumbPortalNode,
  );
};

Breadcrumb.propTypes = propTypes;
Breadcrumb.defaultProps = defaultProps;

export default Breadcrumb;
