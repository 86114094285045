import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { easeQuadOut } from 'd3-ease';
import { H4, Icon, useId } from '@kinesis/bungle';
import { motion } from 'framer-motion';
import {
  ToolboxSectionWrapper,
  ToolboxSectionInner,
  ToolboxSectionHeader,
  ToolboxSectionHeaderInner,
  ToolboxSectionClickArea,
  ToolboxSectionContent,
} from './toolbox-section.styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  collapsible: PropTypes.bool,
  title: PropTypes.string,
};

const defaultProps = {
  collapsible: false,
  title: undefined,
};

const ToolboxSection = ({ children, collapsible, title }) => {
  const theme = useTheme();
  const id = useId();
  const [isClosed, setClosed] = useState(false);

  const toggleOpen = useCallback(() => {
    setClosed((state) => !state);
  }, []);

  return (
    <ToolboxSectionWrapper closed={isClosed}>
      <ToolboxSectionInner closed={isClosed}>
        {title && (
          <ToolboxSectionHeader
            aria-controls={
              collapsible ? `bungle-toolbox-section-${id}-content` : undefined
            }
            aria-expanded={(collapsible && !isClosed) || undefined}
            collapsible={collapsible}
          >
            <H4 noWrap>
              {collapsible ? (
                <ToolboxSectionClickArea onClick={toggleOpen}>
                  <Icon
                    color={theme.color.gray6}
                    magnitude='xsmall'
                    transform={isClosed ? 'rotate(-90deg)' : undefined}
                    transitionDuration={100}
                    type='down'
                  />
                  <ToolboxSectionHeaderInner>{title}</ToolboxSectionHeaderInner>
                </ToolboxSectionClickArea>
              ) : (
                title
              )}
            </H4>
          </ToolboxSectionHeader>
        )}
        <motion.div
          aria-hidden={isClosed || undefined}
          id={`bungle-toolbox-section-${id}-content`}
          style={{ overflow: 'hidden' }}
          animate={{ height: isClosed ? 0 : 'auto' }}
          transition={{ ease: easeQuadOut, duration: 0.1 }}
        >
          <ToolboxSectionContent>{children}</ToolboxSectionContent>
        </motion.div>
      </ToolboxSectionInner>
    </ToolboxSectionWrapper>
  );
};

ToolboxSection.propTypes = propTypes;
ToolboxSection.defaultProps = defaultProps;

export { ToolboxSection };
