import { createSelector } from 'reselect';
import { get, includes, map, pipe, values, isEqual } from 'lodash/fp';
import blockValueSelector from 'selectors/blockValueSelector';
import schemaSelector from './schemaSelector';

const isDimension = pipe(get('classification'), includes('dimension'));
const isMeasure = pipe(get('classification'), includes('measure'));
const isHex = pipe(get(['unit', 'type']), isEqual('hex'));

const filterableColumnsSelector = createSelector(
  schemaSelector,
  blockValueSelector,
  (schema, blockValue) => {
    const query = get([get('visualisation', blockValue), 'query'], blockValue);

    if (!query) {
      return [];
    }

    const aggregateColumns = map('column', query.aggregate);

    // NOTE: we exclude hex here at the moment as we don't have filter system
    // for hex id's (it is coded to use string/categorical filtering, but that
    // isn't reasonable for hex-ids, it would need to be spatial in some way.
    return values(schema).filter(
      (col) =>
        (isDimension(col) ||
          (isMeasure(col) && aggregateColumns.includes(col.key))) &&
        !isHex(col),
    );
  },
);

export default filterableColumnsSelector;
