import { get, isEmpty, every, curry } from 'lodash/fp';
import { labelOfMeasure } from 'data/block';

const itemsFilter = curry((query, item) => {
  if (item.type === 'expression') {
    return labelOfMeasure(item).toLowerCase().includes(query.toLowerCase());
  }
  if (item.type === 'breakdown') {
    return item.label.toLowerCase().includes(query.toLowerCase());
  }
  return false;
});

const labelOfMeasureOrBreakdown = (item) => {
  switch (item.type) {
    case 'expression':
      return labelOfMeasure(item);
    case 'breakdown':
      return `By ${item.label}`;
    default:
      return undefined;
  }
};

const groupKeyOf = (options) => {
  if (
    !isEmpty(options) &&
    every((option) => get(['value', 'type'], option) === 'identity', options)
  ) {
    return undefined;
  }
  return 'measure';
};

export { itemsFilter, labelOfMeasureOrBreakdown, groupKeyOf };
