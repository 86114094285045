import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { groupBy, get, isEmpty, isNil } from 'lodash/fp';
import pluralize from 'pluralize';
import {
  H3,
  Icon,
  Inline,
  InlineItem,
  Secondary,
  Stack,
  Strong,
  Text,
} from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import Modal from 'components/modals/modal/Modal';
import { ModalHeader, ModalHeading } from '../modal-header';

const propTypes = {
  animation: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.object,
};

const defaultProps = {
  animation: true,
  error: { id: 'n/a', errors: [] },
};

const renderError = (err) => {
  if (err.type === 'missing-region') {
    return <span>Missing region: {err.region}</span>;
  }
  if (err.type === 'duplicate-region') {
    return <span>Duplicate region: {err.region}</span>;
  }
  if (err.type === 'unrecognised-region') {
    return <span>Invalid region: {err.region}</span>;
  }
  if (err.type === 'missing-years') {
    return (
      <span>
        Missing years for{' '}
        <Strong>
          {err.region}
          {err.year ? `/${err.year}` : ''}
        </Strong>
        : {err.years.join(', ')}
      </span>
    );
  }
  if (err.type === 'duplicate-years') {
    return (
      <span>
        Duplicate years for{' '}
        <Strong>
          {err.region}
          {err.year ? `/${err.year}` : ''}
        </Strong>
        : {err.years.join(', ')}
      </span>
    );
  }
  if (err.type === 'missing-sexes') {
    return (
      <span>
        Missing sexes for <Strong>{err.region}</Strong>: {err.sexes.join(', ')}
      </span>
    );
  }
  if (err.type === 'duplicate-sexes') {
    return (
      <span>
        Duplicate sexes for <Strong>{err.region}</Strong>:{' '}
        {err.sexes.join(', ')}
      </span>
    );
  }
  if (err.type === 'unrecognised-sex') {
    return (
      <span>
        Invalid sex for <Strong>{err.region}</Strong>: {err.sex}
      </span>
    );
  }
  if (err.type === 'value-error') {
    return (
      <span>
        Invalid value for{' '}
        <Strong>
          {err.region}
          {err.sex ? `/${err.sex}` : ''}/{err.year}
        </Strong>
        : {err.value}{' '}
        {!isNil(get(['bounds', 'lower'])) || !isNil(get(['bounds', 'upper']))
          ? `(${(!isNil(get(['bounds', 'lower'], err))
              ? [`minimum allowed ${err.bounds.lower}`]
              : []
            )
              .concat(
                !isNil(get(['bounds', 'upper'], err))
                  ? [`maximum allowed ${err.bounds.upper}`]
                  : [],
              )
              .join(', ')})`
          : null}
      </span>
    );
  }
  if (err.type === 'sum-error') {
    return <span>Internal sums are not approximately equal</span>;
  }
  if (err.type === 'sum-total-error') {
    return <span>Intrastate total error</span>;
  }
  if (err.type === 'upload-error') {
    return (
      <span>
        Could not find file <Strong>{err.file}.csv</Strong> in upload. Make sure
        to include the tables in a folder named{' '}
        <Strong>custom.dpie.population</Strong> within the uploaded zip file.
      </span>
    );
  }
  if (err.type === 'csv-read-error') {
    const columns = {
      fertility: ['year', 'region', 'tfr'],
      'overseas-migration': ['year', 'region', 'net_overseas_migration'],
      'interstate-migration': ['year', 'region', 'net_interstate_migration'],
      'intrastate-migration': ['year', 'region', 'net_intrastate_migration'],
      'left-expectancy': ['year', 'sex', 'region', 'life_expectancy_at_birth'],
      'dwelling-projection': [
        'year',
        'region',
        'change_in_attached_dwellings',
        'change_in_detached_dwellings',
        'change_in_residents_in_npds',
      ],
    }[err.dataset];
    return (
      <span>
        Error decoding <Strong>{err.file}.csv</Strong>. Make sure the table
        includes the columns{' '}
        {columns
          .map((x) => <Strong>{x}</Strong>)
          .reduce((acc, x, i) =>
            i === columns.length - 1 ? [acc, ', and ', x] : [acc, ', ', x],
          )}
        .
      </span>
    );
  }
  return <span>Unknown error: {err.id}</span>;
};

const PopulationInputDataErrorModal = ({ animation, onClose, error }) => {
  const theme = useTheme();
  const byDataset = useMemo(() => groupBy('dataset', error.errors), [error]);
  const headerLabel = `${error.errors.length} upload ${pluralize(
    'errors',
    error.errors.length,
  )}`;
  return (
    <Modal
      aria-label={headerLabel}
      header={
        <ModalHeader>
          <ModalHeading>{headerLabel}</ModalHeading>
        </ModalHeader>
      }
      animation={animation}
      onClose={onClose}
      saveable={false}
      dismissLabel='Dismiss'
      magnitude='large'
    >
      <Content>
        <Stack space='medium'>
          {!isEmpty(byDataset.fertility) && (
            <Stack space='small'>
              <Stack space='none'>
                <H3>Total fertility rate</H3>
                <Secondary>table.dpie.tfr.csv</Secondary>
              </Stack>
              <Stack space='xsmall'>
                {byDataset.fertility.map((err) => (
                  <Inline space='small'>
                    <InlineItem>
                      <Icon
                        magnitude='small'
                        color={theme.color.red5}
                        type='exclamation-circle'
                      />
                    </InlineItem>
                    <InlineItem sizing='fill-container'>
                      <Text>{renderError(err)}</Text>
                    </InlineItem>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          )}
          {!isEmpty(byDataset['life-expectancy']) && (
            <Stack space='small'>
              <Stack space='none'>
                <H3>Life expectancy at birth</H3>
                <Secondary>table.dpie.life-expectancy-at-birth.csv</Secondary>
              </Stack>
              <Stack space='xsmall'>
                {byDataset['life-expectancy'].map((err) => (
                  <Inline space='small'>
                    <InlineItem>
                      <Icon
                        magnitude='small'
                        color={theme.color.red5}
                        type='exclamation-circle'
                      />
                    </InlineItem>
                    <InlineItem sizing='fill-container'>
                      <Text>{renderError(err)}</Text>
                    </InlineItem>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          )}
          {!isEmpty(byDataset['overseas-migration']) && (
            <Stack space='small'>
              <Stack space='none'>
                <H3>Net overseas migration</H3>
                <Secondary>table.dpie.net-overseas-migration.csv</Secondary>
              </Stack>
              <Stack space='xsmall'>
                {byDataset['overseas-migration'].map((err) => (
                  <Inline space='small'>
                    <InlineItem>
                      <Icon
                        magnitude='small'
                        color={theme.color.red5}
                        type='exclamation-circle'
                      />
                    </InlineItem>
                    <InlineItem sizing='fill-container'>
                      <Text>{renderError(err)}</Text>
                    </InlineItem>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          )}
          {!isEmpty(byDataset['interstate-migration']) && (
            <Stack space='small'>
              <Stack space='none'>
                <H3>Net interstate migration</H3>
                <Secondary>table.dpie.net-interstate-migration.csv</Secondary>
              </Stack>
              <Stack space='xsmall'>
                {byDataset['interstate-migration'].map((err) => (
                  <Inline space='small'>
                    <InlineItem>
                      <Icon
                        magnitude='small'
                        color={theme.color.red5}
                        type='exclamation-circle'
                      />
                    </InlineItem>
                    <InlineItem sizing='fill-container'>
                      <Text>{renderError(err)}</Text>
                    </InlineItem>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          )}
          {!isEmpty(byDataset['intrastate-migration']) && (
            <Stack space='small'>
              <Stack space='none'>
                <H3>Net intrastate migration</H3>
                <Secondary>table.dpie.net-intrastate-migration.csv</Secondary>
              </Stack>
              <Stack space='xsmall'>
                {byDataset['intrastate-migration'].map((err) => (
                  <Inline space='small'>
                    <InlineItem>
                      <Icon
                        magnitude='small'
                        color={theme.color.red5}
                        type='exclamation-circle'
                      />
                    </InlineItem>
                    <InlineItem sizing='fill-container'>
                      <Text>{renderError(err)}</Text>
                    </InlineItem>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          )}
          {!isEmpty(byDataset['dwelling-projection']) && (
            <Stack space='small'>
              <Stack space='none'>
                <H3>Dwelling growth</H3>
                <Secondary>table.dpie.hum-dwelling-projection.csv</Secondary>
              </Stack>
              <Stack space='xsmall'>
                {byDataset['dwelling-projection'].map((err) => (
                  <Inline space='small'>
                    <InlineItem>
                      <Icon
                        magnitude='small'
                        color={theme.color.red5}
                        type='exclamation-circle'
                      />
                    </InlineItem>
                    <InlineItem sizing='fill-container'>
                      <Text>{renderError(err)}</Text>
                    </InlineItem>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          )}
          {!isEmpty(byDataset[undefined]) && (
            <Stack space='small'>
              <H3>Upload</H3>
              <Stack space='xsmall'>
                {byDataset.undefined.map((err) => (
                  <Inline space='small'>
                    <InlineItem>
                      <Icon
                        magnitude='small'
                        color={theme.color.red5}
                        type='exclamation-circle'
                      />
                    </InlineItem>
                    <InlineItem sizing='fill-container'>
                      <Text>{renderError(err)}</Text>
                    </InlineItem>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Content>
    </Modal>
  );
};

PopulationInputDataErrorModal.defaultProps = defaultProps;
PopulationInputDataErrorModal.propTypes = propTypes;

export { PopulationInputDataErrorModal };
