import { createSlice } from '@reduxjs/toolkit';
import {
  assign,
  conforms,
  defaultTo,
  findIndex,
  get,
  includes,
  keyBy,
  mapValues,
  pipe,
  placeholder,
  set,
  size,
  sortBy,
  unset,
  update,
} from 'lodash/fp';
import { when } from 'utils/functionalUtils';

const { actions, reducer } = createSlice({
  name: 'genericAttributes',
  initialState: {
    activeStageKey: undefined,
    categories: {},
    defaultDate: undefined,
    entries: {},
    stages: {},
  },
  reducers: {
    addStage(state, action) {
      const { activeStageKey, defaultDate, stages } = state;
      const { date, stageKey } = action.payload;

      if (size(stages) === 0 && stageKey !== activeStageKey) {
        return pipe(
          set('activeStageKey', stageKey),
          set('stages', {
            [activeStageKey]: {
              key: activeStageKey,
              date: defaultDate,
              values: {},
            },
            [stageKey]: {
              key: stageKey,
              date,
              values: {},
            },
          }),
        )(state);
      }

      return pipe(
        set('activeStageKey', stageKey),
        set(['stages', stageKey], {
          key: stageKey,
          date,
          values: {},
        }),
      )(state);
    },
    deleteStage(state, action) {
      const { activeStageKey } = state;
      const { stageKey } = action.payload;

      if (stageKey === activeStageKey) {
        const sortedStages = sortBy('date', state.stages);
        const index = findIndex({ key: stageKey }, sortedStages);

        if (sortedStages.length <= 1) {
          return pipe(
            when(
              conforms({
                variant: includes(placeholder, ['categorical', 'itemised']),
              }),
              update('entries', mapValues(set('visible', false))),
            ),
            set('stages', {}),
          )(state);
        }

        return pipe(
          set(
            'activeStageKey',
            get([index === 0 ? 1 : index - 1, 'key'], sortedStages),
          ),
          unset(['stages', stageKey]),
        )(state);
      }

      return unset(['stages', stageKey], state);
    },
    setActiveStageKey(state, action) {
      const { stageKey } = action.payload;

      return set('activeStageKey', stageKey, state);
    },
    setDate(state, action) {
      const { date, stageKey } = action.payload;

      return update(
        ['stages', stageKey],
        pipe(
          set('key', stageKey),
          set('date', date),
          update('values', defaultTo({})),
        ),
        state,
      );
    },
    setValue(state, action) {
      const { activeStageKey, defaultDate } = state;
      const { entryKey, value } = action.payload;

      return update(
        ['stages', activeStageKey],
        pipe(
          set('key', activeStageKey),
          update('date', defaultTo(defaultDate)),
          set(['values', entryKey], value),
        ),
        state,
      );
    },
    setStages(state, action) {
      const { stages } = action.payload;

      const activeStageKey = includes(state.activeStageKey, stages)
        ? state.activeStageKey
        : get([0, 'key'], stages);

      return pipe(
        set('activeStageKey', activeStageKey),
        set('stages', keyBy('key', stages)),
      )(state);
    },
    resetActiveStage(state, action) {
      const { activeStageKey } = state;
      const { date, values = {} } = action.payload;

      return update(
        ['stages', activeStageKey],
        assign(placeholder, { date, values }),
        state,
      );
    },
    showEntry(state, action) {
      const { activeStageKey, defaultDate } = state;
      const { entryKey } = action.payload;

      return pipe(
        set(['entries', entryKey, 'visible'], true),
        update(
          ['stages', activeStageKey],
          pipe(
            set('key', activeStageKey),
            update('date', defaultTo(defaultDate)),
            set(['values', entryKey], { amount: 0 }),
          ),
        ),
      )(state);
    },
  },
});

export { actions, reducer };
