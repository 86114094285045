import { has, pipe, set } from 'lodash/fp';
import { matchPath } from 'react-router';
import { workspacesFetch } from 'actions/workspacesFetch';

function workspaceMiddleware({ location }) {
  return ({ getState }) =>
    (next) =>
    (action) => {
      const state = getState();
      const { pathname } = location;
      const match =
        matchPath({ path: '/workspaces/:workspaceId/*' }, pathname) ??
        matchPath({ path: '/public/:workspaceId/*' }, pathname) ??
        matchPath({ path: '/embed/:workspaceId/*' }, pathname);
      const workspaceId = match?.params?.workspaceId;
      const isPublic =
        pathname.startsWith('/public') || pathname.startsWith('/embed');

      if (
        action.type !== workspacesFetch.pending.type &&
        !has(['meta', 'arg', 'workspaceId'], action) &&
        (has(workspaceId, state.workspaces) ||
          has(workspaceId, state.publicWorkspaces))
      ) {
        next(
          pipe(
            set(['meta', 'arg', 'workspaceId'], workspaceId),
            set(['meta', 'arg', 'isPublic'], isPublic),
          )(action),
        );
      } else {
        next(action);
      }
    };
}

export default workspaceMiddleware;
