import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { timeOptions } from 'utils/visualisationConfigUtils';
import blockValueSelector from 'selectors/blockValueSelector';

const visualisationTimeOptionsSelector = createSelector(
  (state, props = {}) => props.tableRules,
  (state, props = {}) => get(['resources', props.table], state),
  blockValueSelector,
  timeOptions,
);

export default visualisationTimeOptionsSelector;
