import styled from 'styled-components';

const DataGridCellWrapper = styled('div').attrs({
  role: 'gridcell',
})(
  ({ theme }) => ({
    alignItems: 'center',
    borderRight: `1px solid ${theme.borderColor}`,
    boxSizing: 'border-box',
    display: 'flex',
    flex: '1 1 0',
    margin: 0,
    minWidth: 0,
    position: 'relative',
    '&:last-child': {
      borderRightWidth: 0,
    },
    '&:hover': {
      zIndex: 2,
    },
    '&:focus-within': {
      zIndex: 3,
    },
  }),
  ({ variant }) =>
    variant === 'text'
      ? {
          padding: '0 12px',
        }
      : undefined,
);

const DataGridCellInner = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: 'calc(100% + 2px)',
  margin: -1,
  width: 'calc(100% + 2px)',
  [`[data-grid-border-clip-right="true"] ${DataGridCellWrapper}:last-child &&`]:
    {
      marginRight: 0,
    },
});

export { DataGridCellWrapper, DataGridCellInner };
