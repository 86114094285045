import { useSelector } from 'react-redux';
import { get } from 'lodash/fp';
import styled from 'styled-components';
import { Anchor, Button, Inline, InlineItem } from '@kinesis/bungle';
import { Toolbar, ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import KinesisLogoSvg from 'utils/KinesisLogoSvg';
import useResponsive from 'hooks/useResponsive';

const Banner = styled.div(({ theme }) => ({
  height: '40',
  width: '100%',
  background: theme.colors.gray2.hex,
  borderTop: `1px solid ${theme.borderColor}`,
}));

const SmallKinesisLogo = styled.div`
  float: left;
  margin-right: 8px;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const PoweredBy = styled.div`
  line-height: 24px;
`;

const PublicCtaBanner = () => {
  const { isLoggedIn } = useSelector(get('auth'));
  const { isTabletLandscapeAndUp } = useResponsive();

  return (
    <Banner>
      <Toolbar justify='space-between'>
        <ToolbarGroup style={{ maxWidth: 'none' }}>
          <ToolbarItem flex={1} style={{ maxWidth: 'none' }}>
            <Anchor href='https://platform.kinesis.org'>
              <SmallKinesisLogo>
                <KinesisLogoSvg />
              </SmallKinesisLogo>
            </Anchor>
            <PoweredBy>
              Powered by{' '}
              <Anchor href='https://platform.kinesis.org'>Kinesis</Anchor>
              {isTabletLandscapeAndUp &&
                ', a collaborative analytics platform for urban planners and sustainability professionals.'}
            </PoweredBy>
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarItem>
            {isLoggedIn ? (
              <Button as='a' href='/' magnitude='small' variant='secondary'>
                Go to Kinesis
              </Button>
            ) : (
              <Inline space='small'>
                <InlineItem>
                  <Button as='a' href='/' magnitude='small' variant='secondary'>
                    Log in
                  </Button>
                </InlineItem>
                <InlineItem>
                  <Button
                    as='a'
                    href='/registration'
                    magnitude='small'
                    variant='action'
                  >
                    Try it now
                  </Button>
                </InlineItem>
              </Inline>
            )}
          </ToolbarItem>
        </ToolbarGroup>
      </Toolbar>
    </Banner>
  );
};

export default PublicCtaBanner;
