import PropTypes from 'prop-types';
import GenericSettings from './attribute-settings.generic';

const propTypes = {
  instantiation: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const description =
  'Solar PV systems supply usable solar power, and consists of different components such as solar arrays and batteries.';

const SolarSettings = ({ instantiation, setting, workspaceId }) => (
  <GenericSettings
    description={description}
    hasSettings={false}
    instantiation={instantiation}
    setting={setting}
    workspaceId={workspaceId}
  />
);

SolarSettings.defaultProps = defaultProps;
SolarSettings.propTypes = propTypes;

export default SolarSettings;
