import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';
import blockStateSelector from './blockStateSelector';

const blockDataSelector = createSelector(
  get('data'),
  pipe(blockStateSelector, get('scenarios')),
  (state, props) => props.scenarioId,
  (data, scenarios, scenarioId) => {
    const dataId = scenarios?.[scenarioId]?.dataId;

    return data[dataId];
  },
);

export default blockDataSelector;
