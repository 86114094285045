import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  Cards,
  ConfirmationDialog,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  Placeholder,
  UtilityButton,
} from '@kinesis/bungle';
import {
  Content,
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
} from '@kinesis/bungle/legacy';
import { useSelectorWithProps } from 'hooks';
import baselineScenarioSelector from 'selectors/baselineScenarioSelector';
import sortedBoardsSelector from 'selectors/sortedBoardsSelector';
import { boardCreate } from 'actions/boardCreate';
import { boardDelete } from 'actions/boardDelete';
import { ContentSection, ActionsCard } from 'components/content-section';
import BoardItem from 'components/workspace/BoardItem';

const propTypes = {
  editor: PropTypes.bool,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  editor: false,
};

const BoardChooser = ({ editor, workspaceId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [boardToDelete, setBoardToDelete] = useState();
  const [sortBoardsField, setSortBoardsField] = useState('name');
  const sortedBoards = useSelectorWithProps(sortedBoardsSelector, {
    sortField: sortBoardsField,
    workspaceId,
  });
  const { id: baselineScenarioId } = useSelectorWithProps(
    baselineScenarioSelector,
    { workspaceId },
  );

  const handleCreate = useCallback(async () => {
    const { id } = await dispatch(boardCreate({ workspaceId })).unwrap();

    if (id) {
      navigate({
        pathname: `/workspaces/${workspaceId}/boards/${id}`,
        state: { autoSelect: true },
      });
    }
  }, [dispatch, navigate, workspaceId]);

  const handleDelete = useCallback(() => {
    dispatch(boardDelete({ workspaceId, boardId: boardToDelete.id }));
    setBoardToDelete(undefined);
  }, [boardToDelete, dispatch, workspaceId]);

  const handleCancel = useCallback(() => {
    setBoardToDelete(undefined);
  }, []);

  return (
    <ContentSection title='Boards'>
      <ActionsCard>
        <Toolbar inline>
          <ToolbarGroup>
            <ToolbarItem>
              <DropdownMenu justify='start'>
                <DropdownMenuButton
                  as={UtilityButton}
                  magnitude='small'
                  icon='sort'
                />
                <DropdownMenuList>
                  <DropdownMenuItem
                    onSelect={() => setSortBoardsField('name')}
                    variant={sortBoardsField === 'name' ? 'accent' : undefined}
                  >
                    Name
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={() => setSortBoardsField('updatedAt')}
                    variant={
                      sortBoardsField === 'updatedAt' ? 'accent' : undefined
                    }
                  >
                    Last updated
                  </DropdownMenuItem>
                </DropdownMenuList>
              </DropdownMenu>
            </ToolbarItem>
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarItem>
              <UtilityButton
                disabled={!editor}
                icon='plus'
                magnitude='small'
                onClick={handleCreate}
              >
                New board
              </UtilityButton>
            </ToolbarItem>
          </ToolbarGroup>
        </Toolbar>
      </ActionsCard>
      {sortedBoards.length === 0 && (
        <Content alignX='center' alignY='center'>
          <Placeholder>There are no boards in this workspace.</Placeholder>
        </Content>
      )}
      <Cards grow view='grid'>
        {sortedBoards.map((board) => (
          <BoardItem
            key={board.id}
            baselineScenarioId={baselineScenarioId}
            board={board}
            editor={editor}
            onDelete={setBoardToDelete}
            workspaceId={workspaceId}
          />
        ))}
      </Cards>
      {boardToDelete && (
        <ConfirmationDialog
          cancelText='Don’t delete'
          confirmText='Delete board'
          icon='question'
          title={`Delete “${boardToDelete.name}”?`}
          onConfirm={handleDelete}
          onCancel={handleCancel}
          variant='danger'
        >
          This board will be permanently deleted.
        </ConfirmationDialog>
      )}
    </ContentSection>
  );
};

BoardChooser.propTypes = propTypes;
BoardChooser.defaultProps = defaultProps;

export { BoardChooser };
