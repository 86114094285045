import { deleteToken, setToken } from 'services/authService';
import { userLogout } from 'actions/userLogout';
import { reauthRequired } from 'actions/reauthRequired';
import { userLogin } from 'actions/userLogin';
import { userRelogin } from 'actions/userRelogin';

function authMiddleware() {
  return () => (next) => (action) => {
    switch (action.type) {
      case userLogin.fulfilled.type:
      case userRelogin.fulfilled.type:
        setToken(action.payload.access_token);
        break;

      case userLogin.rejected.type:
      case userRelogin.rejected.type:
        deleteToken();
        break;

      case reauthRequired.type:
      case userLogout.fulfilled:
        deleteToken();
        break;

      default:
    }

    next(action);
  };
}

export default authMiddleware;
