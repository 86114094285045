import { createSelector } from 'reselect';
import { get, lowerCase, orderBy, pipe } from 'lodash/fp';
import scenariosSelector from 'selectors/scenariosSelector';

const sortedScenariosSelector = createSelector(
  scenariosSelector,
  (state, props = {}) => props.sortField || 'name',
  (scenarios, sortField) =>
    orderBy(
      ['isDefault', pipe(get(sortField), lowerCase)],
      ['desc', sortField === 'name' ? 'asc' : 'desc'],
    )(scenarios),
);

export default sortedScenariosSelector;
