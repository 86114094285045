import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import {
  Icon,
  Menu,
  UtilityButton,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuList,
  DropdownMenuItem,
} from '@kinesis/bungle';
import { useSelectorWithProps } from 'hooks';
import settingsSummarySelector from 'selectors/settingsSummarySelector';
import AttributeSettings from 'components/modals/attribute-settings/AttributeSettings';
import NewAttributeSettings from 'components/modals/new-attribute-settings/NewAttributeSettings';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import { workspacePatch } from 'actions/workspacePatch';
import { attributeKey } from 'data/attributes';
import ClearValuesConfirmationDialog from './attribute-settings-section.clear-values-confirmation-dialog';

const propTypes = {
  editor: PropTypes.bool,
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  editor: false,
};

const AttributeSettingsSection = ({ editor, scenarioId, workspaceId }) => {
  const dispatch = useDispatch();
  const settings = useSelectorWithProps(settingsSummarySelector, {
    scenarioId,
    workspaceId,
  });
  const isPatchingWorkspace = useSelector(get(['misc', 'isPatchingWorkspace']));
  const [showSettings, setShowSettings] = useState(undefined);
  const [showNewSettings, setShowNewSettings] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState(undefined);
  const toggleShowNewSettings = useCallback(
    () => setShowNewSettings((current) => !current),
    [setShowNewSettings],
  );
  const handleClearValues = useCallback(
    (instantiation) => {
      const key = attributeKey(instantiation);
      if (!key) {
        return;
      }
      const targets = [scenarioId];
      const operations = [
        {
          'clear-values': {
            attribute: key,
          },
        },
      ];
      const actions = [
        {
          type: 'clear-values',
          value: {
            name: key,
          },
        },
      ];
      dispatch(
        workspacePatch({
          actions,
          optimisticState: {},
          patchOps: operations,
          targetScenarioIds: targets,
          workspaceId,
        }),
      );
      setShowConfirmationDialog(undefined);
    },
    [dispatch, scenarioId, workspaceId],
  );

  const menuAction = (setting) => (
    <DropdownMenu justify='end'>
      <DropdownMenuButton
        as={UtilityButton}
        disabled={!editor || isPatchingWorkspace}
        stopPropagation
        icon='ellipsis'
        magnitude='small'
      />
      <DropdownMenuList>
        <DropdownMenuItem
          variant='danger'
          disabled={setting.attribute === 'geography'}
          onClick={(e) => {
            e.stopPropagation();
            setShowConfirmationDialog(setting);
          }}
        >
          Remove all values
        </DropdownMenuItem>
      </DropdownMenuList>
    </DropdownMenu>
  );

  return (
    <>
      <ToolboxSection collapsible title='Attributes'>
        {showSettings && (
          <AttributeSettings
            attributeKey={showSettings.key}
            scenarioId={scenarioId}
            onClose={() => setShowSettings(undefined)}
            workspaceId={workspaceId}
          />
        )}
        {showNewSettings && (
          <NewAttributeSettings
            onClose={toggleShowNewSettings}
            scenarioId={scenarioId}
            workspaceId={workspaceId}
          />
        )}
        <Menu>
          {settings.map((setting) => (
            <Menu.Item
              disabled={!editor || isPatchingWorkspace}
              key={attributeKey(setting)}
              icon={<Icon type='setting' magnitude='small' />}
              onClick={() => setShowSettings(setting)}
              content={setting.label}
              actions={[menuAction(setting)]}
            />
          ))}
        </Menu>
        <div style={{ paddingTop: 16 }}>
          <UtilityButton
            data-testid='add-custom-attribute'
            disabled={!editor || isPatchingWorkspace}
            magnitude='small'
            onClick={toggleShowNewSettings}
            pressed={showNewSettings}
            icon='plus'
          >
            Add custom attribute
          </UtilityButton>
        </div>
      </ToolboxSection>
      {showConfirmationDialog && (
        <ClearValuesConfirmationDialog
          onCancel={() => setShowConfirmationDialog(undefined)}
          onConfirm={() => handleClearValues(showConfirmationDialog)}
          label={showConfirmationDialog.label}
        />
      )}
    </>
  );
};

AttributeSettingsSection.propTypes = propTypes;
AttributeSettingsSection.defaultProps = defaultProps;

export default AttributeSettingsSection;
