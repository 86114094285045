import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import blockDataVersionSelector from 'selectors/blockDataVersionSelector';

const blockValueAtDataVersionSelector = createSelector(
  blockDataVersionSelector,
  get('blockValues'),
  get,
);

export default blockValueAtDataVersionSelector;
