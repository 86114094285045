import { createSelector } from 'reselect';
import { filter, includes, map, placeholder, where } from 'lodash/fp';
import sortedAppsSelector from 'selectors/sortedAppsSelector';
import loadedAppVersionsSelector from './loadedAppVersionsSelector';

const loadedAppsSelector = createSelector(
  sortedAppsSelector,
  loadedAppVersionsSelector,
  (apps, loadedAppVersions) =>
    filter(
      where({
        id: includes(placeholder, map('app', loadedAppVersions)),
      }),
      apps,
    ),
);

export default loadedAppsSelector;
