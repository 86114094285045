const GrabberSvg = () => (
  <svg
    width='6'
    height='14'
    viewBox='0 0 6 14'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
  >
    <path d='M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0Z' />
    <path d='M5 0C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2C5.55228 2 6 1.55228 6 1C6 0.447715 5.55228 0 5 0Z' />
    <path d='M0 5C0 4.44772 0.447715 4 1 4C1.55228 4 2 4.44772 2 5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5Z' />
    <path d='M5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4Z' />
    <path d='M0 9C0 8.44771 0.447715 8 1 8C1.55228 8 2 8.44771 2 9C2 9.55229 1.55228 10 1 10C0.447715 10 0 9.55229 0 9Z' />
    <path d='M5 8C4.44772 8 4 8.44771 4 9C4 9.55229 4.44772 10 5 10C5.55228 10 6 9.55229 6 9C6 8.44771 5.55228 8 5 8Z' />
    <path d='M0 13C0 12.4477 0.447715 12 1 12C1.55228 12 2 12.4477 2 13C2 13.5523 1.55228 14 1 14C0.447715 14 0 13.5523 0 13Z' />
    <path d='M5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14C5.55228 14 6 13.5523 6 13C6 12.4477 5.55228 12 5 12Z' />
  </svg>
);

export default GrabberSvg;
