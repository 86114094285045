import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { isArray, isEmpty } from 'lodash/fp';
import { H3, Placeholder, Tooltip, UtilityButton } from '@kinesis/bungle';
import AttributeSettings from 'components/modals/attribute-settings/AttributeSettings';
import AttributeValues from 'components/modals/attribute-values/AttributeValues';
import {
  Card,
  Header,
  Toolbar,
  ToolbarItem,
  Item,
  Label,
  Value,
} from './attribute-card.styles';

const propTypes = {
  attributeKey: PropTypes.string.isRequired,
  editor: PropTypes.bool.isRequired,
  entries: PropTypes.array,
  isPatchingWorkspace: PropTypes.bool,
  isScalar: PropTypes.bool,
  locationId: PropTypes.number.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  scenarioId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  entries: undefined,
  isPatchingWorkspace: false,
  isScalar: false,
  privacy: 'private',
};

const NoValues = ({ isScalar }) => (
  <Placeholder>
    {isScalar ? 'No value defined.' : 'No values defined.'}
  </Placeholder>
);

NoValues.propTypes = {
  isScalar: PropTypes.bool,
};

NoValues.defaultProps = {
  isScalar: false,
};

const AttributeCard = ({
  attributeKey,
  editor,
  entries,
  isPatchingWorkspace,
  isScalar,
  locationId,
  privacy,
  scenarioId,
  title,
  workspaceId,
}) => {
  const [showValues, setShowValues] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const handleEnter = useCallback((event) => {
    if (event.key === 'Enter') {
      setShowValues(true);
    }
  }, []);

  return (
    <Card
      clickable={privacy === 'public'}
      onClick={privacy === 'public' ? () => setShowValues(true) : undefined}
      onKeyDown={privacy === 'public' ? handleEnter : undefined}
      tabIndex={privacy === 'public' ? '0' : '-1'}
    >
      {showValues && (
        <AttributeValues
          attributeKey={attributeKey}
          locationId={locationId}
          onClose={() => setShowValues(false)}
          privacy={privacy}
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      )}
      {showSettings && (
        <AttributeSettings
          attributeKey={attributeKey}
          scenarioId={scenarioId}
          onClose={() => setShowSettings(false)}
          workspaceId={workspaceId}
        />
      )}
      {privacy !== 'public' && (
        <Toolbar justify='space-between'>
          <ToolbarItem>
            <Tooltip placement='top' title='Edit values'>
              <UtilityButton
                disabled={!editor || isPatchingWorkspace}
                icon='edit'
                magnitude='small'
                onClick={() => setShowValues(true)}
                style={{ marginLeft: 'auto' }}
              />
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip placement='top' title='Edit settings'>
              <UtilityButton
                disabled={!editor || isPatchingWorkspace}
                icon='setting'
                magnitude='small'
                onClick={() => setShowSettings(true)}
              />
            </Tooltip>
          </ToolbarItem>
        </Toolbar>
      )}
      <Header addMargin={!!entries}>
        <H3>{title}</H3>
      </Header>
      {isArray(entries) && (
        <div>
          {isEmpty(entries) && <NoValues isScalar={isScalar} />}
          {entries.map((entry) => (
            <Item key={entry.label}>
              <Label>{entry.label || 'New category'}</Label>
              <Value>{entry.amount}</Value>
            </Item>
          ))}
        </div>
      )}
    </Card>
  );
};

AttributeCard.defaultProps = defaultProps;
AttributeCard.propTypes = propTypes;

export default AttributeCard;
