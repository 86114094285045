import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import baselineScenarioKeySelector from 'selectors/baselineScenarioKeySelector';

const scenarioCreate = createAsyncThunk(
  'scenario/create',
  async ({ workspaceId }, { dispatch, getState }) => {
    const state = getState();
    const api = createApiFromState({ dispatch, getState });
    const scenarioKey = baselineScenarioKeySelector(state, { workspaceId });

    const response = await api.post(`/api/scenario`, {
      name: 'New scenario',
      scenarioKey,
      workspaceId,
    });

    return response.data;
  },
);

export { scenarioCreate };
