import styled from 'styled-components';

const BasicLocationPopover = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  color: ${({ theme }) => theme.color.gray8};
  padding: 16px;
  pointer-events: none;
`;

// eslint-disable-next-line import/prefer-default-export
export { BasicLocationPopover };
