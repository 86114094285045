import { createSlice } from '@reduxjs/toolkit';
import { publicWorkspaceFetch } from 'actions/publicWorkspaceFetch';
import { keyBy, pipe, get, flatMap, map } from 'lodash/fp';
import { normalizeColumns } from 'data/levels';
import { appAdd } from 'actions/appAdd';
import { appsUpgrade } from 'actions/appsUpgrade';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';

const normalizeTableResource = (table) => ({
  resourceId: table.resource,
  resource: table.resource,
  family: table.family,
  owner: table.owner,
  type: table.type,
  key: table.key,
  version: table.version,
  columns: map('key', table.schema.columns),
  schema: keyBy('key', normalizeColumns(table.schema.columns)),
  label: table.label,
  notes: table.notes,
  dependencies: table.dependencies,
  dataUpdatedAt: table.data_updated,
  modelsUpdatedAt: table.models_updated,
});

const normalizeModelResource = (model) => ({
  resourceId: model.resource,
  resource: model.resource,
  family: model.family,
  owner: model.owner,
  type: model.type,
  key: model.key,
  version: model.version,
  subscriptions: model.subscriptions,
  publications: model.publications,
  // NOTE: We deliberately elide executor details.
});

const normalizeBoardResource = (board) => ({
  resourceId: board.resource,
  resource: board.resource,
  family: board.family,
  owner: board.owner,
  type: board.type,
  key: board.key,
  version: board.version,
  // NOTE: We deliberately elide board details, it isn't
  // relevant at this point, as they only get consumed at
  // the app level, and things go via capsule as required.
});

const normalizeRequirementResource = (requirement) => ({
  resourceId: requirement.resource,
  resource: requirement.resource,
  family: requirement.family,
  owner: requirement.owner,
  type: requirement.type,
  key: requirement.key,
  version: requirement.version,
  attributes: requirement.attributes,
});

const normalizeSettingResource = (setting) => ({
  resourceId: setting.resource,
  resource: setting.resource,
  family: setting.family,
  owner: setting.owner,
  type: setting.type,
  key: setting.key,
  version: setting.version,
  attribute: setting.attribute,
  label: setting.label,
  group: setting.group,
  settingType: setting['setting-type'],
  order: setting.order,
  constraints: setting.constraints,
  // NOTE: We deliberately elide setting details.
});

const normalizeResource = (resource) => {
  switch (resource.type) {
    case 'model':
      return [normalizeModelResource(resource)];
    case 'table':
      return [normalizeTableResource(resource)];
    case 'board':
      return [normalizeBoardResource(resource)];
    case 'requirement':
      return [normalizeRequirementResource(resource)];
    case 'setting':
      return [normalizeSettingResource(resource)];
    default:
      return [];
  }
};
const { reducer, actions } = createSlice({
  name: 'resources',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(appAdd.fulfilled, (state, action) => ({
      ...state,

      ...pipe(
        get('resources'),
        flatMap(normalizeResource),
        keyBy('resourceId'),
      )(action.payload),
    }));

    builder.addCase(appsUpgrade.fulfilled, (state, action) => ({
      ...state,

      ...pipe(
        get('resources'),
        flatMap(normalizeResource),
        keyBy('resourceId'),
      )(action.payload),
    }));

    builder.addCase(workspaceFetch.fulfilled, (state, action) => ({
      ...state,

      ...pipe(
        get('resources'),
        flatMap(normalizeResource),
        keyBy('resourceId'),
      )(action.payload),
    }));

    builder.addCase(workspacePatch.fulfilled, (state, action) => ({
      ...state,

      ...pipe(
        get('resources'),
        flatMap(normalizeResource),
        keyBy('resourceId'),
      )(action.payload),
    }));

    builder.addCase(publicWorkspaceFetch.fulfilled, (state, action) => ({
      ...state,

      ...pipe(
        get('resources'),
        flatMap(normalizeResource),
        keyBy('resourceId'),
      )(action.payload),
    }));
  },
});

export { reducer, actions };
