import chroma from 'chroma-js';
import { flatten } from 'lodash/fp';
import { areaFills } from './opacities';
import {
  returnLinesToUnselectedState,
  deselectLinePoints,
  deselectLinePoint,
  selectLinePoint,
} from './line';

const opacify = (color, opacity) => chroma(color).alpha(opacity).css();

const selectAreaLinePoint = (point) => {
  selectLinePoint(point);

  // lighten the areas
  point.series.update(
    { fillColor: opacify(point.series.color, areaFills.notSelected) },
    false,
  );
};

const selectAreaLinePoints = (chart, value) => {
  const allPoints = flatten(chart.series.map((s) => s.points));
  // NOTE: it is important to action all deselects, then all
  // selects in a different pass so that line opacity is sorted.
  allPoints.forEach((point) => {
    if (point.category !== value) {
      deselectLinePoint(point);
    }
  });
  allPoints.forEach((point) => {
    if (point.category === value) {
      selectAreaLinePoint(point);
    }
  });
};

const selectHoveredAreaLinePoints = (chart) => {
  if (chart.hoverPoints) {
    chart.hoverPoints.forEach((point) => {
      selectAreaLinePoint(point);
    });
  }
};

const returnAreaLinesToUnselectedState = (points, chart) => {
  returnLinesToUnselectedState(points, chart);

  // restore the areas
  chart.series.forEach((s) => {
    s.update({ fillColor: opacify(s.color, areaFills.normal) }, false);
  });
};

export {
  deselectLinePoints as deselectAreaLinePoints,
  returnAreaLinesToUnselectedState,
  selectHoveredAreaLinePoints,
  selectAreaLinePoints,
};
