import PropTypes from 'prop-types';
import { Label, Section } from './form-entry.styles';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]).isRequired,
  label: PropTypes.string.isRequired,
};

const defaultProps = {};

const FormEntry = ({ label, children }) => (
  <Section>
    <Label>
      {label}
      {children}
    </Label>
  </Section>
);

FormEntry.defaultProps = defaultProps;
FormEntry.propTypes = propTypes;

export default FormEntry;
