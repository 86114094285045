import { useContext, useMemo, useReducer } from 'react';
import {
  conforms,
  filter,
  get,
  has,
  keyBy,
  lte,
  map,
  mapValues,
  maxBy,
  minBy,
  pipe,
  placeholder,
  set,
  some,
  update,
  values,
} from 'lodash/fp';
import { format, startOfYear } from 'date-fns/fp';
import { nanoid } from 'nanoid';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { reducer as genericAttributesReducer } from 'reducers/genericAttributesReducer';
import { reducer as usageAttributesReducer } from 'reducers/usageAttributesReducer';
import attributeSettingSelector from 'selectors/attributeSettingSelector';
import workspaceInstantiationSelector from 'selectors/workspaceInstantiationSelector';
import locationAttributesSelector from 'selectors/locationAttributesSelector';
import {
  normalizeAttributeEntries,
  normalizeAttributeStages,
  normalizeCompoundAttributeStages,
  normalizeUsageAttributeCategories,
  normalizeUsageAttributeEntries,
  normalizeUsageAttributeStages,
} from 'utils/attributesUtils';
import { DateContext } from 'contexts';

function useAttributesReducer({
  attributeKey,
  initialDate,
  locationId,
  scenarioId,
  workspaceId,
}) {
  const setting = useSelectorWithProps(attributeSettingSelector, {
    key: attributeKey,
    scenarioId,
    workspaceId,
  });
  const instantiation = useSelectorWithProps(workspaceInstantiationSelector, {
    key: attributeKey,
    workspaceId,
  });
  const type = instantiation.attribute;

  const reducer =
    type === 'usage-v2' ? usageAttributesReducer : genericAttributesReducer;

  const timeSeries = get(
    ['attributes', attributeKey, 'time_series'],
    useSelectorWithProps(locationAttributesSelector, {
      locationId,
      scenarioId,
      workspaceId,
    }),
  );

  const stages = useMemo(() => {
    const storedItems = (() => {
      switch (type) {
        case 'service-v2':
        case 'rainwater-tank':
        case 'recycled-water-system':
          return normalizeAttributeStages(timeSeries);

        case 'land':
        case 'solar':
          return normalizeCompoundAttributeStages(timeSeries, {
            entryKey: attributeKey,
          });

        case 'usage-v2':
          return normalizeUsageAttributeStages(timeSeries);

        default:
      }

      return map(
        (item) => ({
          date: get('date', item),
          values: {
            value: get('value', item),
          },
        }),
        timeSeries,
      );
    })();

    return pipe(map(update('key', nanoid)), keyBy('key'))(storedItems);
  }, [timeSeries, type, attributeKey]);

  const categories = useMemo(
    () =>
      type === 'usage-v2'
        ? normalizeUsageAttributeCategories(
            setting.categories,
            instantiation.categories,
          )
        : {},
    [setting, instantiation, type],
  );

  const entries = useMemo(() => {
    switch (type) {
      case 'land':
        return {
          land: {
            key: 'land',
            label: 'Land',
            visible: true,
          },
        };

      case 'solar':
        return {
          solar: {
            key: 'solar',
            label: 'Solar',
            visible: true,
          },
        };

      case 'service-v2':
      case 'rainwater-tank':
      case 'recycled-water-system':
        return pipe(
          normalizeAttributeEntries,
          mapValues((entry) =>
            set('visible', some(has(['values', entry.key]), stages), entry),
          ),
        )(setting.entries, instantiation.entries);

      case 'usage-v2':
        return pipe(
          normalizeUsageAttributeEntries,
          mapValues((entry) =>
            set('visible', some(has(['values', entry.key]), stages), entry),
          ),
        )(setting.categories, instantiation.categories);

      default:
    }

    return {
      value: {
        key: 'value',
        label: 'Value',
        encoding: 'string',
      },
    };
  }, [setting, instantiation, stages, type]);

  const fields = useMemo(() => {
    switch (type) {
      case 'land':
        return {
          area: {
            entry: 'land',
            key: 'area',
            label: 'Area',
            encoding: 'float64',
            unitType: 'sqm',
          },
          'floor-space-ratio': {
            entry: 'land',
            key: 'floor-space-ratio',
            label: 'Floor space ratio',
            encoding: 'float64',
            unitType: 'ratio',
          },
          'roof-area': {
            entry: 'land',
            key: 'roof-area',
            label: 'Roof area',
            encoding: 'float64',
            unitType: 'sqm',
          },
          'rainwater-connected-proportion': {
            entry: 'land',
            key: 'rainwater-connected-proportion',
            label: 'Rainwater connected proportion',
            encoding: 'float64',
            unitLabel: 'proportion',
            unitType: 'decimal',
          },
        };

      case 'solar':
        return {
          'peak-capacity': {
            entry: 'solar',
            key: 'peak-capacity',
            label: 'Peak capacity',
            encoding: 'float64',
            unitType: 'kw',
          },
          storage: {
            entry: 'solar',
            key: 'storage',
            label: 'Storage',
            encoding: 'float64',
            unitType: 'kwh',
          },
          'peak-power': {
            entry: 'solar',
            key: 'peak-power',
            label: 'Peak power',
            encoding: 'float64',
            unitType: 'kw',
          },
          'rate-of-charge': {
            entry: 'solar',
            key: 'rate-of-charge',
            label: 'Rate of charge',
            encoding: 'float64',
            unitType: 'kw',
          },
        };

      default:
    }

    return {};
  }, [type]);

  const minDateKey = pipe(values, minBy('date'), get('key'))(stages);
  const initialDateKey = pipe(
    values,
    filter(conforms({ date: lte(placeholder, initialDate) })),
    maxBy('date'),
    get('key'),
  )(stages);
  const baseDate = useContext(DateContext) || new Date();

  return useReducer(reducer, {
    activeStageKey: initialDateKey || minDateKey || nanoid(),
    categories,
    defaultDate: format('yyyy-MM-dd', startOfYear(baseDate)),
    entries,
    fields,
    variant: {
      land: 'compound',
      'rainwater-tank': 'itemised',
      'recycled-water-system': 'itemised',
      'service-v2': 'itemised',
      solar: 'compound',
      'usage-v2': 'categorical',
    }[type],
    stages,
  });
}

export default useAttributesReducer;
