import PropTypes from 'prop-types';

const propTypes = {
  datasetId: PropTypes.number.isRequired,
};

const defaultProps = {};

const DatasetAttributesGrid = ({ datasetId }) => <div>todo {datasetId}</div>;

DatasetAttributesGrid.propTypes = propTypes;
DatasetAttributesGrid.defaultProps = defaultProps;

export { DatasetAttributesGrid };
