import { createSelector } from 'reselect';
import { instantiationByKey } from 'data/attributes';
import workspaceSelector from './workspaceSelector';

const workspaceInstantiationSelector = createSelector(
  workspaceSelector,
  (state, props) => props.key,
  (workspace, key) => instantiationByKey(key, workspace),
);

export default workspaceInstantiationSelector;
