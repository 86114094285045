import { createCachedSelector } from 're-reselect';
import {
  defaultTo,
  get,
  filter,
  map,
  pipe,
  placeholder,
  reject,
  isNil,
} from 'lodash/fp';
import { getActiveVersion, normalizeBlock } from 'data/block';
import boardSelector from './boardSelector';
import workspaceSelector from './workspaceSelector';

const blocksSelector = createCachedSelector(
  (state, props = {}) =>
    props.blockIds || pipe(boardSelector, get('blocks'))(state, props),
  pipe(workspaceSelector, get('blocks')),
  get('blockValues'),
  (blockIds, blockStates, blockValues) =>
    pipe(
      map(get(placeholder, blockStates)),
      reject(isNil),
      map((blockState) =>
        normalizeBlock(
          blockState,
          get(getActiveVersion(blockState), blockValues),
        ),
      ),
      // This is to allow for new block types to be gradually added
      // without breaking things... expand this filter with new blocks.
      filter((block) =>
        [
          'visualisation',
          'row-break',
          'section',
          'text',
          'editable-visualisation',
          'pathway-input',
        ].includes(block.blockType),
      ),
    )(blockIds),
)((state, props = {}) =>
  defaultTo(
    [],
    props.blockIds || pipe(boardSelector, get('blocks'))(state),
  ).join(','),
);

export default blocksSelector;
