import styled from 'styled-components';

const ToolboxWrapper = styled('div')(
  ({ theme }) => ({
    backgroundColor: 'white',
    border: `0 solid ${theme.borderColor}`,
    boxSizing: 'border-box',
    color: theme.color.default,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: theme.fontFamily.default,
    fontSize: theme.fontSize.default,
    lineHeight: theme.lineHeight.default,
    height: '100%',
    minWidth: 0,
  }),
  ({ arrangement }) =>
    ({
      left: { borderRightWidth: 1 },
      right: { borderLeftWidth: 1 },
    })[arrangement],
  ({ width }) => ({ width }),
);

export { ToolboxWrapper };
