import PropTypes from 'prop-types';
import { Heading } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import locationSelector from 'selectors/locationSelector';
import LocationAttributesSection from 'components/toolbox/sections/LocationAttributesSection';
import LocationDetailSection from 'components/toolbox/sections/LocationDetailSection';
import NearbyLocationsSection from 'components/toolbox/sections/NearbyLocationsSection';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import usePermission from 'hooks/usePermission';
import AutoFocus from 'components/util/AutoFocus';
import { useSelectorWithProps } from 'hooks';
import { get, pipe } from 'lodash/fp';
import layerSelector from 'selectors/layerSelector';

const propTypes = {
  locationId: PropTypes.number.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const LocationPane = ({ locationId, privacy, workspaceId }) => {
  const location = useSelectorWithProps(locationSelector, {
    locationId,
    public: privacy === 'public',
    workspaceId,
  });
  const editor = usePermission('editor');

  const layerType = useSelectorWithProps(pipe(layerSelector, get('type')), {
    layerId: location?.layer,
    public: privacy === 'public',
    workspaceId,
  });

  if (!location) {
    return null;
  }

  return (
    <AutoFocus>
      <ToolboxPanel fadeScroll>
        <Content as='header' overflow='visible' padding='medium'>
          {privacy === 'public' || layerType === 'synthetic' ? (
            <Heading as='h2' size='large'>
              {location.label}
            </Heading>
          ) : (
            <LocationDetailSection
              key={location.id}
              editor={editor}
              id={location.id}
              label={location.label}
              layer={location.layer}
              workspaceId={workspaceId}
            />
          )}
        </Content>
        <LocationAttributesSection
          editor={editor && layerType !== 'synthetic'}
          locationId={location.id}
          privacy={privacy}
          workspaceId={workspaceId}
        />
        {layerType !== 'synthetic' && privacy !== 'public' && (
          <NearbyLocationsSection
            locationId={location.id}
            privacy={privacy}
            workspaceId={workspaceId}
          />
        )}
      </ToolboxPanel>
    </AutoFocus>
  );
};

LocationPane.propTypes = propTypes;
LocationPane.defaultProps = defaultProps;

export { LocationPane };
