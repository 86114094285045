import { useMemo } from 'react';
import {
  Accordion,
  AccordionItem,
  Fieldset,
  Placeholder,
  Stack,
  Strong,
} from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import {
  DataGrid,
  DataGridCell,
  DataGridRow,
  DataGridRowGroup,
} from 'components/data-grid';
import {
  curry,
  get,
  values,
  map,
  uniq,
  pipe,
  isEmpty,
  filter,
  fromPairs,
} from 'lodash/fp';
import PropTypes from 'prop-types';
import { encodingOf, isScalar, isTensor } from 'data/settings';
import {
  valueInBounds,
  toTensorGridColumns,
  toTensorGridData,
} from 'utils/settingsModal';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import { useSelectorWithProps } from 'hooks';
import {
  ensureTensorSettingItem,
  updateTensorSettingItem,
  replaceTensorSettingItemKey,
} from 'data/attributes';
import EditableCell from './editable-cell';
import EditableTensorGrid from './editable-tensor-grid';

const propTypes = {
  item: PropTypes.object.isRequired,
  setSetting: PropTypes.func.isRequired,
  settings: PropTypes.arrayOf(PropTypes.object).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const SettingsGrid = ({
  item,
  setSetting,
  settings,
  specifications,
  workspaceId,
}) => {
  const instances = values(
    useSelectorWithProps(workspaceInstantiationsSelector, { workspaceId }),
  );

  const groups = useMemo(
    () =>
      pipe(
        map('group'),
        uniq,
        filter((group) => {
          const children = filter({ group }, settings);
          return !isEmpty(children);
        }),
      )(specifications),
    [settings, specifications],
  );

  const tensorColumns = useMemo(
    () =>
      pipe(
        filter(isTensor),
        map((s) => [s.family, toTensorGridColumns(s, instances)]),
        fromPairs,
      )(settings),
    [settings, instances],
  );

  const onSave = curry((specification, key, _column, value) => {
    if (!valueInBounds(specification, value)) {
      return;
    }
    setSetting(
      pipe(
        replaceTensorSettingItemKey(
          item.key,
          specification.key,
          specification.family,
        ),
        ensureTensorSettingItem(item.key, specification.family, {
          group: {
            label: specification.group,
          },
          label: specification.label,
          key: specification.family,
          value: {
            encoding: encodingOf(specification),
            value: [{ key, value }],
          },
        }),
        updateTensorSettingItem(item.key, specification.family, key, value),
      ),
    );
  });

  return (
    <Fieldset>
      <Stack space='medium'>
        {!isEmpty(groups) ? (
          groups.map((group, ix) => {
            const isLastItem = ix + 1 < groups.length;
            const scalarSettings = filter(
              (s) => s.group === group && isScalar(s),
              settings,
            );
            const tensorSettings = filter(
              (s) =>
                s.group === group &&
                isTensor(s) &&
                !isEmpty(toTensorGridData(s, item, instances)),
              settings,
            );
            if (isEmpty(scalarSettings) && isEmpty(tensorSettings)) return null;
            return (
              <Content
                padding='none'
                borderPlacement={isLastItem ? 'bottom' : undefined}
                paddingBottom={isLastItem ? 'large' : undefined}
              >
                <Stack space='medium'>
                  <Strong>{group}</Strong>
                  {!isEmpty(scalarSettings) && (
                    <DataGrid>
                      <DataGridRowGroup key={group}>
                        {scalarSettings.map((specification) => (
                          <DataGridRow
                            key={specification.key}
                            variant='default'
                            title={specification.label}
                          >
                            <DataGridCell>
                              <EditableCell
                                specifications={specifications}
                                specification={specification}
                                item={item}
                                setSetting={setSetting}
                              />
                            </DataGridCell>
                          </DataGridRow>
                        ))}
                      </DataGridRowGroup>
                    </DataGrid>
                  )}
                  {!isEmpty(tensorSettings) && (
                    <Stack space='xsmall'>
                      {tensorSettings.map((specification) => (
                        <Accordion>
                          <AccordionItem title={specification.label}>
                            <EditableTensorGrid
                              columns={get(specification.family, tensorColumns)}
                              data={toTensorGridData(
                                specification,
                                item,
                                instances,
                              )}
                              onSave={onSave(specification)}
                            />
                          </AccordionItem>
                        </Accordion>
                      ))}
                    </Stack>
                  )}
                </Stack>
              </Content>
            );
          })
        ) : (
          <Placeholder>There are no settings for this item.</Placeholder>
        )}
      </Stack>
    </Fieldset>
  );
};

SettingsGrid.propTypes = propTypes;
SettingsGrid.defaultProps = defaultProps;

export default SettingsGrid;
