import styled from 'styled-components';
import { Menu, Tabs } from '@kinesis/bungle';
import { LegacyContent } from 'components/legacy-content';

const AppLabel = styled('span')`
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray9};
`;

const AppListPane = styled.div`
  flex: 0 0 304px;
  width: 304px;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  overflow: auto;
`;

const AppMenu = styled(Menu)`
  margin-bottom: 16px;
`;

const AppMenuItem = styled(Menu.Item)`
  height: auto;
`;

const AppHeaderContent = styled(LegacyContent)`
  display: flex;
  background-color: ${({ theme }) => theme.color.gray2};
  flex: 0 0 auto;
  padding-bottom: 8px;
  justify-content: space-between;
  align-items: center;
`;

const Paragraph = styled('p')(({ theme, gray }) => ({
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.default,
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.default,
  marginTop: 0,
  marginBottom: 16,
  color: gray ? theme.color[`gray${gray}`] : theme.color.gray8,
}));

const NoData = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
`;

const BoardsList = styled('ul')`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const BoardsListItem = styled('li')`
  display: flex;
  margin-bottom: 4px;
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 16px;
  margin-right: 8px;
  color: ${({ theme }) => theme.color.gray7};
`;

const MenuSeparator = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  margin: 16px 0;
`;

const UpgradeMenuItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UpgradeMenuBadge = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 0;
`;

const UpgradeCard = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 16px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 2px;
`;

const UpgradeCardHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 46px;
`;

const UpgradeName = styled('div')`
  flex: 1 0 auto;
  font-size: ${({ theme }) => theme.fontSize.md}px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.lineHeight.md};
  color: ${({ theme }) => theme.color.gray9};
  padding-left: 8px;
`;

const UpgradeVersion = styled('div')`
  flex: 0 1 auto;
  font-size: ${({ theme }) => theme.fontSize.md}px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.md};
  color: ${({ theme }) => theme.color.gray7};
`;

const UpgradeCards = styled('div')`
  & > div {
    margin-bottom: 16px;
  }
  padding-bottom: 8px;
`;

const TabsFlex = styled(Tabs)`
  flex: 1 0 auto;
`;

export {
  AppHeaderContent,
  AppLabel,
  AppListPane,
  AppMenu,
  AppMenuItem,
  BoardsList,
  BoardsListItem,
  IconWrapper,
  MenuSeparator,
  NoData,
  Paragraph,
  TabsFlex,
  UpgradeMenuItem,
  UpgradeMenuBadge,
  UpgradeCard,
  UpgradeCardHeader,
  UpgradeCards,
  UpgradeName,
  UpgradeVersion,
};
