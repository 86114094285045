import { createSelector } from 'reselect';
import { pipe, get, values } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const workspaceAppsSelector = createSelector(
  pipe(workspaceSelector, get('apps')),
  values,
);

export default workspaceAppsSelector;
