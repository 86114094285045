import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  distributionRows,
  ensureSettingItem,
  updateSettingItem,
} from 'data/attributes';
import { H3, NumberInput } from '@kinesis/bungle';
import { pipe, get, keyBy, set } from 'lodash/fp';
import { Container, Row, Label, Amount, Arrow } from './distribution.styles';

const propTypes = {
  category: PropTypes.object.isRequired,
  setSetting: PropTypes.func.isRequired,
  instantiation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const left = (
  <Arrow>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor'>
      <path d='M13.5 13C13.2239 13 13 13.2239 13 13.5L13 19H15.3868L12.5 24L9.61328 19H12L12 13.5C12 12.6716 12.6716 12 13.5 12H24V13L13.5 13Z' />
    </svg>
  </Arrow>
);

const Distribution = ({ disabled, category, instantiation, setSetting }) => {
  const rows = useMemo(() => {
    const handleTypeUpdate = (type) => (v) => {
      setSetting((current) => {
        const complete = ensureSettingItem(
          category.key,
          `distribution-of-types`,
          {
            group: { label: 'Category settings' },
            label: `Default distribution of values by type`,
            key: `distribution-of-types`,
            value: {},
          },
          current,
        );
        const base = pipe(
          get(['fields']),
          keyBy('key'),
          get([`distribution-of-types`, 'value']),
        )(category);
        return updateSettingItem(
          category.key,
          'distribution-of-types',
          set(['fields', type], { value: v / 100, encoding: 'float64' }, base),
          complete,
        );
      });
    };
    const handleItemUpdate = (type, item) => (v) => {
      setSetting((current) => {
        const complete = ensureSettingItem(
          category.key,
          `distribution-of-values-${type}`,
          {
            group: { label: 'Category settings' },
            label: `Default distribution of values for ${type}`,
            key: `distribution-of-values-${type}`,
            value: {
              fields: {
                [item]: {
                  encoding: 'float64',
                },
              },
            },
          },
          current,
        );
        const base = pipe(
          get(['fields']),
          keyBy('key'),
          get([`distribution-of-values-${type}`, 'value']),
        )(category);
        return updateSettingItem(
          category.key,
          `distribution-of-values-${type}`,
          set(['fields', item], { value: v / 100, encoding: 'float64' }, base),
          complete,
        );
      });
    };

    return distributionRows(category, instantiation)
      .map((row, ix) => ({
        ...row,
        key: `${row.type}-${row.label}-${row.value}-${ix}`,
      }))
      .map((row) => {
        if (row.type === 'type') {
          return set('onChange', handleTypeUpdate(row.typeValue), row);
        }
        if (row.type === 'item') {
          return set(
            'onChange',
            handleItemUpdate(row.typeValue, row.itemValue.key),
            row,
          );
        }
        return row;
      });
  }, [category, instantiation, setSetting]);

  return (
    <Container>
      <H3>Default distribution of values</H3>
      {rows.map((row) => (
        <Row key={row.key} type={row.type}>
          <Label error={row.error}>
            {row.error ? 'The total must equal 100%' : row.label}
          </Label>
          {row.type === 'type' && left}
          <Amount>
            <NumberInput
              textAlign='right'
              readOnly={
                ['type-total', 'item-total'].includes(row.type) || disabled
              }
              numberType='integer'
              value={row.value}
              onChange={row.onChange}
              maxWidth={41}
              suffix='%'
              tone={row.error ? 'critical' : 'neutral'}
            />
          </Amount>
        </Row>
      ))}
    </Container>
  );
};

Distribution.defaultProps = defaultProps;
Distribution.propTypes = propTypes;

export default Distribution;
