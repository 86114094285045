import { createSelector } from 'reselect';
import {
  identity,
  concat,
  find,
  map,
  placeholder,
  curry,
  get,
  filter,
  pipe,
  values,
  flatMap,
} from 'lodash/fp';
import {
  attributeKey,
  orderSettings,
  supportedTypes,
  typeOf,
} from 'data/settings';
import { keyFor } from 'data/attributes';
import {
  USAGE_SPECIFICATIONS,
  RAINWATER_TANK_SPECIFICATIONS,
  RECYCLED_WATER_SYSTEM_SPECIFICATIONS,
} from 'constants/attributes';
import workspaceSelector from 'selectors/workspaceSelector';
import workspaceAppsSelector from 'selectors/workspaceAppsSelector';

const mapWithIndex = map.convert({ cap: false });

const injectOrder = mapWithIndex((object, index) => ({
  ...object,
  order: index,
}));

const injectResourceAndFamily = map((resource) => ({
  ...resource,
  family: `setting:kinesis:${resource.key}`,
  resource: `setting:kinesis:${resource.key}:1`,
}));

const constantSettings = flatMap(pipe(injectOrder, injectResourceAndFamily), [
  USAGE_SPECIFICATIONS,
  RAINWATER_TANK_SPECIFICATIONS,
  RECYCLED_WATER_SYSTEM_SPECIFICATIONS,
]);

const filterSettingsByAttribute = curry((attribute, specifications) =>
  filter(
    (specification) =>
      keyFor(attribute) === attributeKey(specification.attribute),
    specifications,
  ),
);

const filterSettings = curry(
  (attribute, workspaceResources, supported, resources) =>
    pipe(
      values,
      map(get(placeholder, resources)),
      filter({ type: 'setting' }),
      filterSettingsByAttribute(attribute),
      filter((setting) => supported.includes(typeOf(setting))),
    )(workspaceResources),
);

const fallbackSettings = curry((constants, specifications) =>
  pipe(
    concat(
      filter(
        (constant) => !find({ family: constant.family }, specifications),
        constants,
      ),
    ),
  )(specifications),
);

const appMapping = {
  core: 1,
};

const hasAppAtLeastVersion = curry((app, version, workspaceApps) =>
  pipe(
    find({ app: appMapping[app] }),
    get('version'),
    (installedVersion) => installedVersion >= version,
  )(workspaceApps),
);

const requiresConstantSettings = (workspaceApps) =>
  !hasAppAtLeastVersion('core', 1005, workspaceApps);

const settingsSelector = createSelector(
  (_, props = {}) => props.attribute,
  () => constantSettings,
  pipe(workspaceSelector, get('resources')),
  get('resources'),
  workspaceAppsSelector,
  (attribute, constants, workspaceResources, resources, workspaceApps) =>
    pipe(
      filterSettings(attribute, workspaceResources, supportedTypes),
      requiresConstantSettings(workspaceApps)
        ? fallbackSettings(filterSettingsByAttribute(attribute, constants))
        : identity,
      orderSettings,
    )(resources),
);

export default settingsSelector;
