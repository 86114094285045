import { Children, cloneElement, isValidElement, useContext } from 'react';
import PropTypes from 'prop-types';
import { TableRowImpl } from './table-row.styles';
import { tableContext } from './table-context';

const cloneValidElement = (children, props) =>
  isValidElement(children) ? cloneElement(children, props) : children;

const propTypes = {
  children: PropTypes.node.isRequired,
  columnBegin: PropTypes.number,
  columnEnd: PropTypes.number,
  firstChild: PropTypes.bool,
  head: PropTypes.bool,
  lastChild: PropTypes.bool,
  stickToRow: PropTypes.number,
};

const defaultProps = {
  columnBegin: 0,
  columnEnd: undefined,
  firstChild: false,
  head: false,
  lastChild: false,
  stickToRow: undefined,
};

const TableRow = ({
  children,
  columnBegin,
  columnEnd,
  firstChild,
  head,
  lastChild,
  stickToRow,
  ...restProps
}) => {
  const childrenArray = Children.toArray(children);
  const { rowHeight, scrollHeight, tableHeight } = useContext(tableContext);

  return (
    <TableRowImpl
      firstChild={firstChild}
      head={head}
      lastChild={lastChild}
      rowHeight={rowHeight}
      showBottomBorder={tableHeight > scrollHeight}
      stickToX={
        typeof stickToRow === 'number' ? stickToRow * rowHeight : undefined
      }
      {...restProps}
    >
      {childrenArray.length < columnBegin
        ? cloneValidElement(children, { children: '\u00a0' })
        : childrenArray.slice(columnBegin, columnEnd).map((child, index) =>
            cloneValidElement(child, {
              columnIndex: columnBegin + index,
              lastChild:
                columnEnd === undefined && index === childrenArray.length - 1,
            }),
          )}
    </TableRowImpl>
  );
};

TableRow.propTypes = propTypes;
TableRow.defaultProps = defaultProps;

export { TableRow };
