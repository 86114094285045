import { useState } from 'react';
import PropTypes from 'prop-types';
import AppHeaderContext from './AppHeaderContext';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]),
};

const defaultProps = {
  children: null,
};

const AppHeaderProvider = ({ children }) => {
  const [breadcrumbPortalNode, setBreadcrumbPortalNode] = useState();
  const [appToolbarPortalNode, setAppToolbarPortalNode] = useState();
  const [backTo, setBackTo] = useState();

  // FIX This lint is pointing out a real problem but is part of
  // a larger issue that as there is assumptions of mutation of
  // some state that can't just be blindly fixed, see console errors.
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const state = {
    breadcrumbPortalNode,
    setBreadcrumbPortalNode,
    appToolbarPortalNode,
    setAppToolbarPortalNode,
    backTo,
    setBackTo,
  };
  return (
    <AppHeaderContext.Provider value={state}>
      {children}
    </AppHeaderContext.Provider>
  );
};

AppHeaderProvider.propTypes = propTypes;
AppHeaderProvider.defaultProps = defaultProps;

export default AppHeaderProvider;
