import { useSelector } from 'react-redux';
import { get } from 'lodash/fp';
import { Avatar } from '@kinesis/bungle';

const OwnerIcon = () => {
  const app = useSelector(get(['apps', 'entities', 769]));
  return (
    <Avatar
      alt={app.label}
      image={`data:image/png;base64,${app.image}`}
      magnitude='xsmall'
      variant='organisation'
    >
      {' '}
    </Avatar>
  );
};

OwnerIcon.propTypes = {};
OwnerIcon.defaultProps = {};

export { OwnerIcon };
