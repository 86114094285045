import { createSelector } from 'reselect';
import { get, keys, memoize, pipe } from 'lodash/fp';
import scenarioKeySelector from 'selectors/scenarioKeySelector';
import workspaceSelector from 'selectors/workspaceSelector';

const memoizeKeys = memoize(keys);

const attributeKeysSelector = createSelector(
  pipe(workspaceSelector, get('attributes')),
  scenarioKeySelector,
  (attributes, scenarioKey) =>
    memoizeKeys(get([scenarioKey, 'settings'], attributes)),
);

export default attributeKeysSelector;
