import { Children, useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  add,
  get,
  map,
  max,
  pipe,
  placeholder,
  range,
  set,
  slice,
  sum,
  update,
} from 'lodash/fp';
import { flattenChildren, useCachedValue, useRect } from '@kinesis/bungle';
import { TableWrapper } from './table.styles';
import { TableResizeBar } from './table-resize-bar';
import { tableContext } from './table-context';

const { Provider } = tableContext;

const propTypes = {
  children: PropTypes.node.isRequired,
  minColumnWidth: PropTypes.number,
  resizable: PropTypes.bool,
  rowHeight: PropTypes.number,
  stickyColumnCount: PropTypes.number,
};

const defaultProps = {
  rowHeight: 33,
  minColumnWidth: 50,
  resizable: false,
  stickyColumnCount: 0,
};

const Table = ({
  children,
  minColumnWidth,
  resizable,
  rowHeight,
  stickyColumnCount,
}) => {
  const ref = useRef();
  const rect = useRect(ref);
  const [columnWidths, setColumnWidths] = useState([]);
  const initialColumnWidths = useCachedValue(columnWidths, get('width', rect));
  const tableHeight = get('height', rect);
  const tableWidth = get('width', rect);
  const scrollHeight = rowHeight * Children.count(flattenChildren(children));
  const scrollWidth = sum(columnWidths);

  const contextValue = useMemo(
    () => ({
      columnWidths,
      rowHeight,
      setColumnWidth: (index, width) => {
        if (width !== columnWidths[index]) {
          setColumnWidths(
            update(index, (currentWidth) =>
              max([minColumnWidth, currentWidth, width]),
            ),
          );
        }
      },
      scrollHeight,
      scrollWidth,
      stickyColumns: map(
        pipe(slice(0, placeholder, columnWidths), sum),
        range(0, stickyColumnCount),
      ),
      tableHeight,
      tableWidth,
    }),
    [
      columnWidths,
      minColumnWidth,
      rowHeight,
      scrollHeight,
      scrollWidth,
      stickyColumnCount,
      tableHeight,
      tableWidth,
    ],
  );

  const handleResize = useCallback((index, width) => {
    setColumnWidths(update(index, add(width)));
  }, []);

  const handleColumnReset = useCallback(
    (index) => {
      setColumnWidths(set(index, get(index, initialColumnWidths)));
    },
    [initialColumnWidths],
  );

  return (
    <Provider value={contextValue}>
      <TableWrapper ref={ref}>
        {children}
        {resizable &&
          columnWidths.map((colWidth, i) => (
            <TableResizeBar
              columnIndex={i}
              left={sum(columnWidths.slice(0, i + 1))}
              minOffset={minColumnWidth - colWidth}
              onReset={handleColumnReset}
              onResize={handleResize}
            />
          ))}
      </TableWrapper>
    </Provider>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export { Table };
