import PropTypes from 'prop-types';
import { Icon, Inline, InlineItem, Strong, Text } from '@kinesis/bungle';
import { Link } from 'react-router-dom';

const propTypes = {
  scenarioLabel: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
  workspaceLabel: PropTypes.string.isRequired,
};
const defaultProps = {};

const PathwaysPublishSuccessNotice = ({
  scenarioLabel,
  workspaceId,
  workspaceLabel,
}) => (
  <Inline space='xsmall'>
    <InlineItem>
      <Text>
        Scenario <Strong>{scenarioLabel}</Strong> published to workspace{' '}
        <Strong>{workspaceLabel}</Strong>.
      </Text>
    </InlineItem>
    <InlineItem>
      <Link to={`/workspaces/${workspaceId}`}>
        <Inline space='xsmall'>
          <InlineItem>Go to workspace</InlineItem>
          <InlineItem>
            <Icon type='arrow-right' />
          </InlineItem>
        </Inline>
      </Link>
    </InlineItem>
  </Inline>
);

PathwaysPublishSuccessNotice.propTypes = propTypes;
PathwaysPublishSuccessNotice.defaultProps = defaultProps;

export { PathwaysPublishSuccessNotice };
