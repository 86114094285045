import { createSlice } from '@reduxjs/toolkit';
import { identity, keyBy } from 'lodash/fp';
import { locationCreate } from 'actions/locationCreate';
import { locationRename } from 'actions/locationRename';
import { appAdd } from 'actions/appAdd';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { geographyUpdate } from 'actions/geographyUpdate';

const keyById = keyBy('id');

const { reducer, actions } = createSlice({
  name: 'locations',
  initialState: {},

  reducers: {
    removeLocation: identity,
  },

  extraReducers: (builder) => {
    builder.addCase(locationCreate.fulfilled, (state, action) => {
      const { location } = action.payload;

      if (location?.id) {
        const { id, label, layer } = location;

        state[id] = {
          id,
          label,
          layer,
        };
      }
    });

    builder.addCase(locationRename.pending, (state, action) => {
      const {
        arg: { label, locationId },
        requestId,
      } = action.meta;

      // optimistic update
      if (!state[locationId].requestStatus) {
        state[locationId].requestId = requestId;
        state[locationId].requestStatus = 'pending';
        state[locationId].previousLabel = state[locationId].label;
        state[locationId].label = label;
      }
    });

    builder.addCase(locationRename.fulfilled, (state, action) => {
      const {
        arg: { locationId },
        requestId,
      } = action.meta;

      // confirm optimistic update
      if (
        state[locationId].requestStatus === 'pending' &&
        state[locationId].requestId === requestId
      ) {
        delete state[locationId].requestId;
        delete state[locationId].requestStatus;
        delete state[locationId].previousLabel;
      }
    });

    builder.addCase(locationRename.rejected, (state, action) => {
      const {
        arg: { locationId },
        requestId,
      } = action.meta;

      // reverse optimistic update
      if (
        state[locationId].requestStatus === 'pending' &&
        state[locationId].requestId === requestId
      ) {
        state[locationId].label = state[locationId].previousLabel;
        delete state[locationId].requestId;
        delete state[locationId].requestStatus;
        delete state[locationId].previousLabel;
      }
    });

    builder.addCase(appAdd.fulfilled, (state, action) =>
      keyById(action.payload.locations),
    );

    builder.addCase(workspaceFetch.fulfilled, (state, action) =>
      keyById(action.payload.locations),
    );

    builder.addCase(workspacePatch.fulfilled, (state, action) =>
      keyById(action.payload.locations),
    );

    builder.addCase(geographyUpdate.fulfilled, (state, action) =>
      keyById(action.payload.locations),
    );
  },
});

export { reducer, actions };
