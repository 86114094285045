import styled from 'styled-components';

const ScenarioContainer = styled('div')`
  display: flex;
`;

const ScenarioPublishTypeItem = styled('div')`
  flex: 0 0 144px;
  margin-right: 4px;
`;

const ScenarioInputItem = styled('div')`
  flex: 1 1 auto;
  margin-left: 4px;
`;

export { ScenarioContainer, ScenarioPublishTypeItem, ScenarioInputItem };
