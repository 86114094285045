import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash/fp';
import { useTheme } from 'styled-components';
import { Icon, Text, Stack, Strong } from '@kinesis/bungle';
import { Inline as OldInline } from '@kinesis/bungle/legacy';
import Units from 'data/units';
import {
  Indicator,
  IndicatorValue,
  PolicyType,
} from './budget-indicator.styles';

const units = new Units();

const propTypes = {
  policy: PropTypes.string.isRequired,
};

const defaultProps = {};

const policyNames = {
  current: 'Current\u00a0settings',
  intermediate: 'Intermediate',
  aggressive: 'Aggressive',
};

const BudgetIndicator = ({ policy }) => {
  const theme = useTheme();
  const policyUnit = useSelector(get(['pathways', 'budget', 'unit']));
  const unit = units.parseType(policyUnit);
  const policyValue = useSelector(get(['pathways', 'budget', policy]));
  const policyName = policyNames[policy];

  return (
    <Indicator>
      <Stack space='medium'>
        <Stack space='xsmall'>
          <IndicatorValue>
            {policyValue > 0 && '+'}
            {unit.formatCell(policyValue)}
          </IndicatorValue>
          <Text>{unit.symbol}</Text>
        </Stack>
        <OldInline justify='center' align='center' space='small'>
          <Icon
            magnitude='small'
            type='circle'
            color={
              policyValue < 0
                ? theme.color.layer.yellow.fill
                : theme.color.green6
            }
          />
          <PolicyType>
            <Strong>{policyName}</Strong>
          </PolicyType>
        </OldInline>
      </Stack>
    </Indicator>
  );
};

BudgetIndicator.propTypes = propTypes;
BudgetIndicator.defaultProps = defaultProps;

export { BudgetIndicator };
