import styled from 'styled-components';

const ChartContainer = styled.div`
  background: white;
  padding: 16px;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  border-radius: 2px;
`;

const HighchartsWrapper = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;

const LegendContainer = styled.div`
  width: 166px;
  flex: 0 0 166px;
  margin-left: 16px;
`;

// NOTE: Taken from qualitativeColors in src/settings.js
const policyContextColors = {
  current: '#6FB6C5',
  intermediate: '#2D4F75',
  aggressive: '#6FC58B',
};

// NOTE: Taken from qualitativeColors in src/settings.js
const interventionCategoryColors = {
  energy: '#6FB6C5',
  transport: '#2D4F75',
  waste: '#6FC58B',
};

export {
  ChartContainer,
  HighchartsWrapper,
  LegendContainer,
  policyContextColors,
  interventionCategoryColors,
};
