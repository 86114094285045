import { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  DropdownMenuText,
  Stack,
  UtilityButton,
} from '@kinesis/bungle';
import {
  Content,
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
} from '@kinesis/bungle/legacy';
import { filter, get, isEmpty, pipe } from 'lodash/fp';
import { attributeType as type, entries, supportsItems } from 'data/attributes';
import { useSelectorWithProps } from 'hooks';
import useActions from 'hooks/useActions';
import { actions as locationFiltersActions } from 'reducers/locationFiltersReducer';
import workspaceSelector from 'selectors/workspaceSelector';
import { ItemisedAttributeCard } from 'components/locations-filtering-cards';
import settingsSummarySelector from 'selectors/settingsSummarySelector';
import { BoundedDialog } from './locations-filtering-dialog.styles';

const propTypes = {
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const LocationsFilteringDialog = ({ scenarioId, workspaceId }) => {
  const { addFilter, clearAllFilters } = useActions(locationFiltersActions);

  const summaries = useSelectorWithProps(
    pipe(
      settingsSummarySelector,
      filter((s) => supportsItems(type(s))),
    ),
    { scenarioId, workspaceId },
  );

  const filters =
    useSelectorWithProps(
      pipe(workspaceSelector, get(['locationFilters', 'operand'])),
      { workspaceId },
    ) ?? [];

  const add = useCallback(
    (attributeType) => {
      addFilter({
        operand: null,
        attributeType,
      });
    },
    [addFilter],
  );

  const clearAll = useCallback(() => {
    clearAllFilters();
  }, [clearAllFilters]);

  const filterMap = {
    itemisedAttribute: ItemisedAttributeCard,
  };

  return (
    <BoundedDialog>
      <Content>
        <Stack space='medium'>
          {filters.length > 0 && <div>Only show locations where:</div>}
          {filters.map((f) => {
            const Card = filterMap[f.type];
            return (
              <Card key={f.key} expression={f} workspaceId={workspaceId} />
            );
          })}
          <Toolbar inline>
            <ToolbarGroup>
              <ToolbarItem>
                <DropdownMenu>
                  <DropdownMenuButton
                    as={UtilityButton}
                    magnitude='small'
                    icon='plus'
                  >
                    Add filter
                  </DropdownMenuButton>
                  <DropdownMenuList>
                    {isEmpty(summaries) && (
                      <DropdownMenuText>
                        There are no filters available.
                      </DropdownMenuText>
                    )}
                    {!isEmpty(summaries) &&
                      summaries.map((summary) => (
                        <DropdownMenuItem
                          key={summary.key}
                          disabled={isEmpty(entries(summary))}
                          onClick={() => add(type(summary))}
                        >
                          {summary.label}
                        </DropdownMenuItem>
                      ))}
                  </DropdownMenuList>
                </DropdownMenu>
              </ToolbarItem>
            </ToolbarGroup>
            {filters.length > 0 && (
              <ToolbarGroup>
                <ToolbarItem>
                  <UtilityButton
                    magnitude='small'
                    onClick={clearAll}
                    variant='danger'
                    icon='clear-all'
                  >
                    Clear all
                  </UtilityButton>
                </ToolbarItem>
              </ToolbarGroup>
            )}
          </Toolbar>
        </Stack>
      </Content>
    </BoundedDialog>
  );
};

LocationsFilteringDialog.propTypes = propTypes;
LocationsFilteringDialog.defaultProps = defaultProps;

export default LocationsFilteringDialog;
