import PropTypes from 'prop-types';
import { defaultTo, find, get, pipe } from 'lodash/fp';
import pluralize from 'pluralize';
import currentAttributesSelector from 'selectors/currentAttributesSelector';
import Units from 'data/units';
import { useSelectorWithProps } from 'hooks';

const units = new Units();
const { format: areaFormat } = units.sqm();
const { format: countFormat } = units.count();

const propTypes = {
  amount: PropTypes.number.isRequired,
  scenarioId: PropTypes.number.isRequired,
  serviceKey: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const LocationServiceLabel = ({
  amount,
  scenarioId,
  serviceKey,
  workspaceId,
}) => {
  const { label, unit = {} } = useSelectorWithProps(
    pipe(
      currentAttributesSelector,
      get(['settings', 'service-v2', 'entries']),
      find({ key: serviceKey }),
      defaultTo({}),
    ),
    { scenarioId, workspaceId },
  );

  if (unit.type === 'area-sqm') {
    return `${areaFormat(amount)} (${label})`;
  }

  return `${countFormat(amount)} ${pluralize(
    unit.label || units.parseType(unit).symbol,
    amount,
  )} (${label})`;
};

LocationServiceLabel.propTypes = propTypes;

export default LocationServiceLabel;
