import { createSelector } from 'reselect';
import { get, map, pipe } from 'lodash/fp';
import blocksWithTimeSelector from 'selectors/blocksWithTimeSelector';
import workspaceSelector from './workspaceSelector';

const publicMultiBlockYearMinMaxSelector = createSelector(
  blocksWithTimeSelector,
  pipe(workspaceSelector, get(['time', 'blocks'])),
  (blocksWithTime, times) =>
    map(({ id }) => [get([id, 'yearMin'], times), get([id, 'yearMax'], times)])(
      blocksWithTime,
    ),
);

export default publicMultiBlockYearMinMaxSelector;
