import styled from 'styled-components';

const Body = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  background: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 20px;
  overflow: hidden;
`;

const Message = styled('div')`
  font-size: ${({ theme }) => theme.fontSize.md}px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.md};
`;

const TechnicalDetails = styled('div')`
  color: ${({ theme }) => theme.color.gray7};
  padding-top: 16px;
`;

const MaxWidth = styled('div')`
  max-width: 496px;
  overflow: hidden;
`;

const IconContainer = styled.div`
  display: flex;
  font-size: 28px;
  justify-content: center;
  margin-bottom: 16px;
`;

export { Body, Message, MaxWidth, IconContainer, TechnicalDetails };
