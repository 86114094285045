import styled from 'styled-components';

const SparklineGraph = styled.div`
  padding: 2px 0;

  svg {
    margin: 0;
    display: block;
  }
`;

const Label = styled('text')(({ theme }) => ({
  fill: theme.color.gray7,
  fontWeight: theme.fontWeight.regular,
  fontFamily: theme.fontFamily.default,
  fontSize: `${theme.fontSize.default}px`,
}));

export { SparklineGraph, Label };
