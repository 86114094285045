import styled from 'styled-components';

const Collapse = styled.span`
  color: ${({ theme }) => theme.color.gray6};
  padding-right: 8px;
  font-size: ${({ theme }) => theme.fontSize.default}px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  & > :first-child {
    flex: 0 0 340px;
    border-right: 1px solid ${({ theme }) => theme.borderColor};
  }
  & > :last-child {
    flex: 1 1 auto;
  }
`;

export { Collapse, Row };
