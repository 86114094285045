import { keyBy, mapValues, pipe, set } from 'lodash/fp';
import { createSlice } from '@reduxjs/toolkit';
import { availabilityFetch } from 'actions/availabilityFetch';

const { actions, reducer } = createSlice({
  name: 'availability',

  initialState: {
    polling: 'idle',
    scenarios: {},
  },

  extraReducers: (builder) => {
    builder.addCase(availabilityFetch.pending, (state) => {
      state.polling = 'pending';
    });

    builder.addCase(availabilityFetch.fulfilled, (state, action) =>
      pipe(
        set('polling', 'idle'),
        set(
          ['scenarios', action.meta.arg.scenarioKey],
          mapValues(
            'available',
            keyBy('resource', action.payload.availability),
          ),
        ),
      )(state),
    );
  },
});

export { actions, reducer };
