import { createAsyncThunk } from '@reduxjs/toolkit';
import { workspacePatch } from 'actions/workspacePatch';

const locationsDatasetImport = createAsyncThunk(
  'locations/dataset-import',
  async (
    { datasetId, keys, layer, scenarioIds, workspaceId },
    { dispatch },
  ) => {
    const operations = [
      {
        'import-dataset-locations': {
          layer,
          dataset: datasetId,
          keys,
        },
      },
    ];
    const actions = [
      {
        type: 'import-locations',
        value: {
          count: keys.length,
        },
      },
    ];

    dispatch(
      workspacePatch({
        actions,
        patchOps: operations,
        targetScenarioIds: scenarioIds,
        workspaceId,
      }),
    );

    return {};
  },
);

export { locationsDatasetImport };
