import styled from 'styled-components';
import { UtilityButton } from '@kinesis/bungle';

const NavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0px 16px 0px;
`;

const LogoWrapper = styled.div`
  flex: 0 0 auto;
  padding: 0 16px 4px 0;
`;

const ToolbarLeft = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const ToolbarRight = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

const NavStyledButton = styled(UtilityButton)`
  width: 100%;
  justify-content: center;
`;

const HeadingWrapper = styled.div`
  padding: 4px 8px;
`;

const AvatarContainer = styled.div`
  padding: 16px 0;
`;

export {
  AvatarContainer,
  HeadingWrapper,
  LogoWrapper,
  NavStyledButton,
  NavigationContainer,
  ToolbarLeft,
  ToolbarRight,
};
