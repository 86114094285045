import { useLocation, useMatch } from 'react-router-dom';

function useScenarioId() {
  const { search } = useLocation();
  const match1 = useMatch('/workspaces/:id/scenarios/:scenario/*');
  const match2 = useMatch('/public/:id/scenarios/:scenario/*');
  const match = match1 ?? match2;

  if (match?.params) {
    return parseInt(match.params.scenario, 10);
  }

  const urlSearchParams = new URLSearchParams(search);

  return urlSearchParams.has('scenario')
    ? parseInt(urlSearchParams.get('scenario'), 10)
    : undefined;
}

export { useScenarioId };
