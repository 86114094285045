import { createSelector } from 'reselect';
import { get, isArray, pipe } from 'lodash/fp';
import blockValueSelector from 'selectors/blockValueSelector';
import multiDimensionCapsuleDataSelector from 'selectors/multiDimensionCapsuleDataSelector';
import multiScenarioCapsuleDataSelector from 'selectors/multiScenarioCapsuleDataSelector';
import publicChartVisualisationDataSelector from 'selectors/publicChartVisualisationDataSelector';
import secondaryCategorisationCapsuleDataSelector from 'selectors/secondaryCategorisationCapsuleDataSelector';
import columnCapsuleDataSelector from 'selectors/columnCapsuleDataSelector';

const capsuleDataSelector = createSelector(
  (state, props) => props?.public,
  pipe(blockValueSelector, get(['chart', 'y'])),
  (...args) => args,
  (isPublic, y, args) => {
    if (isPublic) {
      return publicChartVisualisationDataSelector(...args);
    }

    if (isArray(y.series)) {
      return multiDimensionCapsuleDataSelector(...args);
    }
    if (y.scenarios) {
      return multiScenarioCapsuleDataSelector(...args);
    }
    if (get(['series', 'secondary'], y)) {
      return secondaryCategorisationCapsuleDataSelector(...args);
    }

    return columnCapsuleDataSelector(...args);
  },
);

export default capsuleDataSelector;
