import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, sumBy } from 'lodash/fp';
import { Placeholder } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import {
  DataGrid,
  DataGridCell,
  DataGridRow,
  DataGridRowGroup,
} from 'components/data-grid';
import { NumberEntry } from 'components/number-entry';
import Layout from 'components/layout';
import Section from 'components/section';
import Sparkline from 'components/sparkline/Sparkline';
import Units from 'data/units';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import attributeFieldsSelector from 'selectors/attributeFieldsSelector';
import { GridPane, SidePane } from './attribute-values.styles';
import { Summary, Item, Label, Value } from './attribute-values.summary.styles';

const units = new Units();
const formatter = units.sqm();

const propTypes = {
  categories: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  history: PropTypes.array,
  locationId: PropTypes.number.isRequired,
  onCategoryDwellingsChange: PropTypes.func.isRequired,
  onCategorySpaceChange: PropTypes.func.isRequired,
  onEntryDwellingsChange: PropTypes.func.isRequired,
  onEntrySpaceChange: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  history: [],
  privacy: 'private',
};

const UsageAttributes = ({
  categories,
  date,
  history,
  locationId,
  onCategoryDwellingsChange,
  onCategorySpaceChange,
  onEntryDwellingsChange,
  onEntrySpaceChange,
  privacy,
  scenarioId,
  workspaceId,
}) => {
  const { area, 'floor-space-ratio': floorSpaceRatio } = useSelectorWithProps(
    attributeFieldsSelector,
    {
      date,
      locationId,
      public: privacy === 'public',
      scenarioId,
      type: 'land',
      workspaceId,
    },
  );

  const total = useMemo(() => sumBy('space', categories), [categories]);

  if (isEmpty(categories)) {
    return (
      <Layout>
        <Content alignX='center' alignY='center'>
          <Placeholder>
            There are no entries defined at this location.
          </Placeholder>
        </Content>
      </Layout>
    );
  }

  return (
    <Layout direction='row'>
      <SidePane>
        <Section collapsible={false} heading='Overview'>
          <Sparkline
            data={history}
            height={36}
            labels={history}
            leftRightMargin={16}
            selectedDate={date}
            width={272}
          />
          <Summary>
            <Item>
              <Label>Total</Label>
              <Value>{formatter.format(total)}</Value>
            </Item>
            {area && (
              <Item>
                <Label>Land area</Label>
                <Value>{formatter.format(area)}</Value>
              </Item>
            )}
            {floorSpaceRatio && (
              <Item>
                <Label>Floor space ratio (entered)</Label>
                <Value>{units.ratio().format(floorSpaceRatio)}:1</Value>
              </Item>
            )}
            {area && (
              <Item>
                <Label>Floor space ratio (calculated)</Label>
                <Value>{units.ratio().format(total / area)}:1</Value>
              </Item>
            )}
          </Summary>
        </Section>
      </SidePane>
      <Layout direction='column'>
        <GridPane>
          <DataGrid borderClip={['left', 'right']}>
            {categories.map((cat) => (
              <DataGridRowGroup key={cat.key}>
                <DataGridRow
                  key={cat.key}
                  title={cat.label || 'New item'}
                  variant='primary'
                >
                  {cat.hasDwellings && (
                    <DataGridCell>
                      <NumberEntry
                        readOnly={privacy === 'public'}
                        format={formatter.formatCell}
                        onChange={(value) => {
                          onCategoryDwellingsChange(cat.key, value);
                        }}
                        units='dwellings'
                        value={get('dwellings', cat)}
                      />
                    </DataGridCell>
                  )}
                  <DataGridCell>
                    <NumberEntry
                      readOnly={privacy === 'public'}
                      format={formatter.formatCell}
                      onChange={(value) => {
                        onCategorySpaceChange(cat.key, value);
                      }}
                      units={formatter.symbol}
                      value={get('space', cat)}
                    />
                  </DataGridCell>
                </DataGridRow>
                {cat.entries.map((entry) => (
                  <DataGridRow
                    key={entry.key}
                    title={entry.label || 'New item'}
                    variant='secondary'
                  >
                    {entry.hasDwellings && (
                      <DataGridCell>
                        <NumberEntry
                          readOnly={privacy === 'public'}
                          format={formatter.formatCell}
                          onChange={(value) => {
                            onEntryDwellingsChange(entry.key, value);
                          }}
                          units='dwellings'
                          value={get('dwellings', entry)}
                        />
                      </DataGridCell>
                    )}
                    <DataGridCell>
                      <NumberEntry
                        readOnly={privacy === 'public'}
                        format={formatter.formatCell}
                        onChange={(value) => {
                          onEntrySpaceChange(entry.key, value);
                        }}
                        units={formatter.symbol}
                        value={get('space', entry)}
                      />
                    </DataGridCell>
                  </DataGridRow>
                ))}
              </DataGridRowGroup>
            ))}
          </DataGrid>
        </GridPane>
      </Layout>
    </Layout>
  );
};

UsageAttributes.defaultProps = defaultProps;
UsageAttributes.propTypes = propTypes;

export default UsageAttributes;
