import styled from 'styled-components';
import { opacify } from 'utils/colors';
import { divergingChoroplethColors } from 'settings/colors';

const GradientPill = styled('div')({
  height: 44,
  width: 16,
  backgroundImage: `linear-gradient(${divergingChoroplethColors
    .map((color) => opacify(color, 0.45))
    .join(', ')})`,
  backgroundColor: '#F5F4F2',
  borderRadius: '8px',
});

export { GradientPill };
