import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Stack, Checkbox } from '@kinesis/bungle';

const propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

const MultiCheckCheckbox = ({ checked, children, onChange, value }) => {
  const handleClick = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <Stack space='small'>
      <Checkbox checked={checked} onChange={handleClick}>
        {children}
      </Checkbox>
    </Stack>
  );
};

MultiCheckCheckbox.propTypes = propTypes;

export default MultiCheckCheckbox;
