import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspacePreview = createAsyncThunk(
  'workspace/preview',
  async ({ geographies }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    try {
      const response = await api.post(`/api/workspace-preview`, {
        geographies,
      });
      return response.data;
    } catch (e) {
      return {};
    }
  },
);

export { workspacePreview };
