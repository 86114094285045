import { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers';
import { StaticMap } from 'react-map-gl';
import {
  concat,
  map,
  isEmpty,
  isNil,
  filter,
  update,
  noop,
  reject,
  eq,
} from 'lodash/fp';
import { useRect } from '@kinesis/bungle';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { geographyBundle } from 'actions/geographyBundle';
import makePublicTokensSelector from 'selectors/makePublicTokenSelector';
import fineareaGeographySelector from 'selectors/fineareaGeographySelector';
import { FineAreaCorrespondenceTooltip } from 'components/fine-area-correspondence-tooltip';
import {
  baseColor,
  diffColor,
  selectedLineColor,
  deselectedLineColor,
} from 'components/spatial-visualisation/layer-colors';

import LIGHT_STYLE from 'components/map/styles/light.json';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Full } from './attribute-values.styles';
import {
  fineareas,
  subregions,
  regions,
  geographyLabels,
} from './attribute-values.fine-area-correspondence.data';

const mapboxAccessTokenSelector = makePublicTokensSelector('mapboxAccessToken');
const NSW = {
  longitude: 146,
  latitude: -32,
  zoom: 4,
};

const propTypes = {
  granularity: PropTypes.oneOf(['regions', 'subregions', 'fineareas']),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

const defaultProps = {
  granularity: 'regions',
  selected: [],
  setSelected: noop,
};

const buildHover = (object) => ({ object, label: geographyLabels[object.id] });

const FineAreaCorrespondenceMap = ({ granularity, selected, setSelected }) => {
  const ref = useRef();
  const rect = useRect(ref);
  const dispatch = useDispatch();
  const theme = useTheme();
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);
  const [hover, setHover] = useState(undefined);
  const [hoverPosition, setHoverPosition] = useState(undefined);
  const geojson = useSelectorWithProps(fineareaGeographySelector, {
    granularity,
  });
  const onHover = useCallback(
    ({ object, x, y }) => {
      if (object) {
        setHoverPosition({ x, y });
        setHover((current) =>
          !current || !current.object || current.object.id !== object.id
            ? buildHover(object)
            : current,
        );
      } else {
        setHover(undefined);
      }
    },
    [setHover, setHoverPosition],
  );
  const onClick = useCallback(
    ({ object }, { srcEvent }) => {
      if (object) {
        const { id } = object;
        setSelected((current) => {
          if (srcEvent.shiftKey) {
            if (current.includes(id)) {
              return reject(eq(id), current);
            }
            return concat(id, current);
          }
          return [id];
        });
        return true;
      }
    },
    [setSelected],
  );

  const onMapClick = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  useEffect(() => {
    if (!geojson) {
      const geographies = map(
        'geography',
        {
          fineareas,
          regions,
          subregions,
        }[granularity],
      );
      dispatch(
        geographyBundle({
          dataId: `fine-area-${granularity}`,
          geographies,
        }),
      );
    }
  }, [dispatch, granularity, geojson]);

  const hovergeojson = useMemo(() => {
    if (!hover || !hover.object || !hover.object.id || !geojson) {
      return null;
    }
    return update('features', filter({ id: hover.object.id }), geojson);
  }, [hover, geojson]);

  const selectedgeojson = useMemo(() => {
    if (isEmpty(selected)) {
      return null;
    }
    return update(
      'features',
      filter(({ id }) => selected.includes(id)),
      geojson,
    );
  }, [selected, geojson]);

  const layers = useMemo(
    () => [
      new GeoJsonLayer({
        id: 'geojson-layer',
        data: geojson,
        pickable: true,
        stroked: true,
        filled: true,
        getFillColor: baseColor(theme.color.layer.purple.fill),
        getLineColor: deselectedLineColor(theme.color.layer.purple.fill),
        getLineWidth: 1,
        lineWidthMinPixels: 1,
        onHover,
        onClick,
      }),
      new GeoJsonLayer({
        id: 'geojson-selected-layer',
        data: selectedgeojson,
        visible: !isNil(selectedgeojson),
        pickable: false,
        stroked: true,
        filled: true,
        getFillColor: diffColor(theme.color.layer.purple.fill),
        getLineColor: selectedLineColor(theme.color.layer.purple.fill),
        getLineWidth: 1,
        lineWidthMinPixels: 1,
      }),
      new GeoJsonLayer({
        id: 'geojson-hover-layer',
        data: hovergeojson,
        visible: !isNil(hovergeojson),
        pickable: false,
        stroked: true,
        filled: false,
        getLineColor: selectedLineColor(theme.color.layer.purple.fill),
        getLineWidth: 1,
        lineWidthMinPixels: 1,
      }),
    ],
    [geojson, hovergeojson, selectedgeojson, theme, onHover, onClick],
  );

  const getCursor = useCallback(() => (hover ? 'pointer' : 'grab'), [hover]);

  return (
    <Full ref={ref}>
      <DeckGL
        layers={layers}
        controller
        initialViewState={NSW}
        onClick={onMapClick}
        getCursor={getCursor}
      >
        <StaticMap
          reuseMaps
          mapboxApiAccessToken={mapboxAccessToken}
          mapStyle={LIGHT_STYLE}
          preventStyleDiffing
        />
      </DeckGL>
      {hover && hoverPosition && (
        <FineAreaCorrespondenceTooltip
          x={hoverPosition.x + rect.x}
          y={hoverPosition.y + rect.y}
          label={hover.label}
        />
      )}
    </Full>
  );
};

FineAreaCorrespondenceMap.propTypes = propTypes;
FineAreaCorrespondenceMap.defaultProps = defaultProps;

export { FineAreaCorrespondenceMap };
