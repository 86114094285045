import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';
import blockStateSelector from 'selectors/blockStateSelector';
import { getActiveVersion } from 'data/block';

const blockValueSelector = createSelector(
  pipe(blockStateSelector, getActiveVersion),
  get('blockValues'),
  get,
);

export default blockValueSelector;
