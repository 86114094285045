import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import useAppHeaderContext from './useAppHeaderContext';

const propTypes = {
  children: PropTypes.node.isRequired,
};
const defaultProps = {};

const AppToolbar = ({ children }) => {
  const { appToolbarPortalNode } = useAppHeaderContext();
  if (!appToolbarPortalNode) return null;

  return ReactDOM.createPortal(children, appToolbarPortalNode);
};

AppToolbar.propTypes = propTypes;
AppToolbar.defaultProps = defaultProps;

export default AppToolbar;
