import { createCachedSelector } from 're-reselect';
import { get, pipe } from 'lodash/fp';
import blockStateSelector from 'selectors/blockStateSelector';

const blockPerspectiveStateSelector = createCachedSelector(
  pipe(blockStateSelector, get('scenarios')),
  (state, props) => props.scenarioId,
  (scenarioData, scenarioId) => get([scenarioId, 'state'], scenarioData),
)((state, props) => props.blockId);

export default blockPerspectiveStateSelector;
