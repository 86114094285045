import {
  differenceInSeconds,
  formatDistanceStrictWithOptions,
} from 'date-fns/fp';

const formatDistanceStrict = formatDistanceStrictWithOptions({
  addSuffix: true,
});

function formatDistance(dateLeft, dateRight) {
  if (Math.abs(differenceInSeconds(dateRight, dateLeft)) < 30) {
    return 'just now';
  }

  return formatDistanceStrict(dateLeft, dateRight);
}

export { formatDistance };
