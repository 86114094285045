import { createSlice } from '@reduxjs/toolkit';
import { get, isNil, pipe, set, uniq, update, xor } from 'lodash/fp';

const initialState = {
  board: undefined,
  blocks: [],
};

const { reducer, actions } = createSlice({
  name: 'blockSelections',
  initialState,
  reducers: {
    selectBoard: (state, action) =>
      action.payload === state.board
        ? state
        : {
            board: action.payload,
            blocks: [],
          },
    selectBlock: (state, action) => {
      if (isNil(get('board', state))) {
        return state;
      }
      return set('blocks', [action.payload], state);
    },
    selectBlocks: (state, action) => {
      if (isNil(get('board', state))) {
        return state;
      }
      return set('blocks', action.payload, state);
    },
    toggleBlock: (state, action) => {
      if (isNil(get('board', state))) {
        return state;
      }
      return update('blocks', pipe(xor([action.payload]), uniq), state);
    },
    resetBlocks: set('blocks', []),
    reset: () => initialState,
  },
});

export { reducer, actions };
