import { datasetCreate } from 'actions/datasetCreate';
import { datasetFetch } from 'actions/datasetFetch';
import { datasetSetName } from 'actions/datasetSetName';
import { datasetSetDescription } from 'actions/datasetSetDescription';
import { datasetSetType } from 'actions/datasetSetType';
import { datasetSetOwner } from 'actions/datasetSetOwner';
import { datasetsList } from 'actions/datasetsList';
import { datasetImportSchema } from 'actions/datasetImportSchema';

import { createSlice } from '@reduxjs/toolkit';
import {
  concat,
  get,
  includes,
  map,
  reduce,
  set,
  unset,
  update,
} from 'lodash/fp';

const { reducer, actions } = createSlice({
  name: 'datasets',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(datasetFetch.pending.type, (state, action) =>
      set([action.meta.arg.datasetId, 'lifecycle'], 'fetching', state),
    );

    builder.addCase(datasetFetch.rejected.type, (state, action) =>
      unset([action.meta.arg.datasetId, 'lifecycle'], state),
    );

    builder.addCase(datasetFetch.fulfilled.type, (state, action) =>
      set(
        action.meta.arg.datasetId,
        {
          lifecycle: 'fetched',
          id: action.payload.id,
          owner: action.payload.owner,
          name: action.payload.name,
          nameInitialized: action.payload.name_initialized,
          type: action.payload.type,
          description: action.payload.description,
          createdAt: action.payload.created_at,
          updatedAt: action.payload.updated_at,
          versions: map('id', action.payload.versions),
        },
        state,
      ),
    );

    builder.addCase(datasetsList.fulfilled.type, (state, action) =>
      reduce(
        (accumulator, dataset) =>
          update(
            [dataset.id],
            (current) => ({
              id: dataset.id,
              owner: dataset.owner,
              name: dataset.name,
              nameInitialized: dataset.name_initialized,
              type: dataset.type,
              description: dataset.description,
              createdAt: dataset.created_at,
              updatedAt: dataset.updated_at,
              ...current,
            }),
            accumulator,
          ),
        state,
        action.payload.datasets,
      ),
    );

    builder.addCase(datasetCreate.fulfilled.type, (state, action) =>
      set(
        action.payload.id,
        {
          lifecycle: 'fetched',
          id: action.payload.id,
          owner: action.payload.owner,
          name: action.payload.name,
          nameInitialized: action.payload.name_initialized,
          type: action.payload.type,
          description: action.payload.description,
          createdAt: action.payload.created_at,
          updatedAt: action.payload.updated_at,
          versions: map('id', action.payload.versions),
        },
        state,
      ),
    );

    builder.addCase(datasetSetName.pending.type, (state, action) =>
      set([action.meta.arg.id, 'name'], action.meta.arg.name, state),
    );

    builder.addCase(datasetSetDescription.pending.type, (state, action) =>
      set(
        [action.meta.arg.id, 'description'],
        action.meta.arg.description,
        state,
      ),
    );

    builder.addCase(datasetSetType.pending.type, (state, action) =>
      set([action.meta.arg.id, 'type'], action.meta.arg.type, state),
    );

    builder.addCase(datasetSetOwner.pending.type, (state, action) =>
      set([action.meta.arg.id, 'owner'], action.meta.arg.owner, state),
    );

    builder.addCase(datasetImportSchema.fulfilled.type, (state, action) =>
      includes(
        action.payload.id,
        get([action.meta.arg.datasetId, 'versions'], state),
      )
        ? state
        : update(
            [action.meta.arg.datasetId, 'versions'],
            concat(action.payload.id),
            state,
          ),
    );
  },
});

export { reducer, actions };
