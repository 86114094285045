import PropTypes from 'prop-types';
import { get, identity } from 'lodash/fp';
import { Select } from '@kinesis/bungle';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import visualisationDimensionOptionsSelector from 'selectors/visualisationDimensionOptionsSelector';
import blockDataSelector from 'selectors/blockDataSelector';
import isCapsuleMismatchSelector from 'selectors/isCapsuleMismatchSelector';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  criteria: PropTypes.func,
  defaultExpanded: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.exact({
      type: PropTypes.oneOf(['column']).isRequired,
      column: PropTypes.string.isRequired,
    }),
    PropTypes.exact({
      type: PropTypes.oneOf(['none']).isRequired,
    }),
  ]),
  table: PropTypes.string,
  includeScenarioDimension: PropTypes.bool,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  defaultExpanded: false,
  value: undefined,
  table: undefined,
  criteria: identity,
  includeScenarioDimension: false,
};

const isError = (data) =>
  get('type', data) === 'error' &&
  ['query-missing-geography'].includes(get(['data', 'code'], data));

const VisualisationDimensionSelect = ({
  blockId,
  defaultExpanded,
  label,
  table,
  value,
  criteria,
  includeScenarioDimension,
  onChange,
  workspaceId,
}) => {
  const scenarioId = useScenarioId();
  const options = useSelectorWithProps(visualisationDimensionOptionsSelector, {
    table,
    criteria,
    includeScenarioDimension,
  });
  const blockData = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const capsuleMismatch = useSelectorWithProps(isCapsuleMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });

  return (
    <Select
      defaultExpanded={defaultExpanded}
      itemKey='key'
      label={label}
      value={value}
      onChange={onChange}
      options={options}
      search
      itemsLabel='dimensions'
      overrideEmptyText='Select an output to view available dimensions.'
      tone={isError(blockData) && !capsuleMismatch ? 'critical' : 'neutral'}
    />
  );
};

VisualisationDimensionSelect.propTypes = propTypes;
VisualisationDimensionSelect.defaultProps = defaultProps;

export { VisualisationDimensionSelect };
