import { createSelector } from 'reselect';
import { get, map, pipe, placeholder, reverse, sortBy } from 'lodash/fp';

const datasetVersionsSelector = createSelector(
  (state, { datasetId }) => state.datasets[datasetId]?.versions,
  get('datasetVersions'),
  (versions, datasetsVersions) =>
    pipe(
      map(get(placeholder, datasetsVersions)),
      sortBy(({ publishedAs, createdAt, updatedAt }) =>
        publishedAs ? updatedAt : createdAt,
      ),
      reverse,
    )(versions),
);

export default datasetVersionsSelector;
