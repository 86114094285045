import { createSelector } from 'reselect';
import { get, map, pipe, reduce, set, values, zip } from 'lodash/fp';
import blocksWithTimeSelector from 'selectors/blocksWithTimeSelector';
import multiBlockYearsSelector from 'selectors/multiBlockYearsSelector';

const countYearsAndGenerateLabels = reduce((acc, [year, label]) => {
  if (!year) return acc;
  return get(year, acc)
    ? set(
        year,
        {
          count: get([year, 'count'], acc) + 1,
          label: `${get([year, 'count'], acc) + 1} visualisations`,
          year,
        },
        acc,
      )
    : set(
        year,
        {
          count: 1,
          label: label || '1 visualisation',
          year,
        },
        acc,
      );
}, {});

const multiBlockYearsWithLabelSelector = createSelector(
  blocksWithTimeSelector,
  multiBlockYearsSelector,
  (blocksWithTime, blockYears) =>
    pipe(
      map(get(['capsule', 'title'])),
      zip(blockYears),
      countYearsAndGenerateLabels,
      values,
    )(blocksWithTime),
);

export default multiBlockYearsWithLabelSelector;
