import { sharingPermissionAdd } from 'actions/sharingPermissionAdd';
import { appAdd } from 'actions/appAdd';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { scenarioPublish } from 'actions/scenarioPublish';
import { userLogin } from 'actions/userLogin';
import { userRelogin } from 'actions/userRelogin';
import { get, keyBy, map, pipe } from 'lodash/fp';

// FIX this should be addressed, but is a new rule, and don't
// want to bundle a large refactor so just leaving the fixes.
// This one should change to createSlice.
// eslint-disable-next-line default-param-last
function identitiesReducer(state = {}, action) {
  switch (action.type) {
    case userLogin.fulfilled.type:
    case userRelogin.fulfilled.type:
    case sharingPermissionAdd.fulfilled.type:
    case appAdd.fulfilled.type:
    case workspaceFetch.fulfilled.type:
    case workspacePatch.fulfilled.type:
    case scenarioPublish.fulfilled.type: {
      if (!get('identities', action.payload)) return state;

      const identities = pipe(
        map((i) =>
          i.identity.split('/')[0] === 'user'
            ? {
                identity: i.identity,
                id: i.id,
                email: i.email,
                name: i.name,
                firstName: i.first_name,
                lastName: i.last_name,
                picture: i.picture,
              }
            : {
                identity: i.identity,
                id: i.id,
                name: i.name,
                avatarUrl: i.avatar_url,
                activeMembersCount: i.active_members_count,
              },
        ),
        keyBy('identity'),
      )(action.payload.identities);

      return {
        ...state,
        ...identities,
      };
    }

    default:
  }
  return state;
}

export default identitiesReducer;
