import styled from 'styled-components';
import { DropdownMenuText } from '@kinesis/bungle';

// TODO: bungle?
const MenuParagraph = styled(DropdownMenuText)`
  > b {
    color: ${({ theme }) => theme.color.gray8};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

const IconWrapper = styled.span`
  display: inline-block;
  vertical-align: sub;
`;

const InfoCircleIconWrapper = styled(IconWrapper)`
  color: ${({ theme }) => theme.color.yellow6};
  font-size: 14px;
  margin-right: 4px;
`;

const UnpublishedModificationsWrapper = styled.div`
  white-space: nowrap;
`;

const LastUpdatedSpan = styled.span`
  cursor: default;
  line-height: 32px;
  white-space: nowrap;
`;

const SavingSpan = styled.span`
  line-height: 32px;
  color: ${({ theme }) => theme.color.gray7};
`;

export {
  InfoCircleIconWrapper,
  LastUpdatedSpan,
  MenuParagraph,
  SavingSpan,
  UnpublishedModificationsWrapper,
};
