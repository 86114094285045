import { createAsyncThunk } from '@reduxjs/toolkit';
import { workspacePatch } from 'actions/workspacePatch';

const locationsWorkspaceImport = createAsyncThunk(
  'locations/workspace-import',
  async (
    {
      currentWorkspaceId,
      layer,
      locations,
      scenarioKey,
      scenarioIds,
      selectedWorkspaceId,
    },
    { dispatch },
  ) => {
    const operations = [
      {
        'import-workspace-locations': {
          layer,
          workspace: selectedWorkspaceId,
          scenario: scenarioKey,
          locations,
        },
      },
    ];
    const actions = [
      {
        type: 'import-locations',
        value: {
          count: locations.length,
        },
      },
    ];

    dispatch(
      workspacePatch({
        targetScenarioIds: scenarioIds,
        patchOps: operations,
        actions,
        workspaceId: currentWorkspaceId,
      }),
    );

    return {};
  },
);

export { locationsWorkspaceImport };
