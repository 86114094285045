/* eslint-disable no-bitwise */
import { max, reverse, identity, sortBy } from 'lodash/fp';

const hexStringToIntArray = (mask) =>
  reverse(mask).reduce((acc, x0, ix) => {
    const x = parseInt(x0, 16);
    let offset = 0;
    while (offset < 4) {
      if ((x & (1 << offset)) !== 0) {
        acc.push(ix * 4 + offset);
      }
      offset += 1;
    }
    return acc;
  }, []);

const intArrayToHexString = (arr, size) => {
  const orderedArray = sortBy(identity, arr);
  const maxInt = max(orderedArray) || 0;
  const hexLength = Math.ceil((size || maxInt) / 4);
  const base = new Array(hexLength).fill(0);
  const updated = orderedArray.reduce((acc, int) => {
    const ix = Math.floor(int / 4);
    const offset = int % 4;
    const nibble = acc[ix] | (1 << offset);
    acc[ix] = nibble;
    return acc;
  }, base);
  return updated
    .reverse()
    .map((x) => x.toString(16))
    .join('');
};

const bitVecBuilder = (self) => ({
  toIntArray: () => self.array,
  toHexString: (size) => intArrayToHexString(self.array, size),
});

const BitVec = {
  fromHexString: (mask) => bitVecBuilder({ array: hexStringToIntArray(mask) }),
  fromIntArray: (array) => bitVecBuilder({ array }),
};

export { BitVec };
