import PropTypes from 'prop-types';
import { map, pipe, sum } from 'lodash/fp';
import { Toolbar, ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { ToolbarBackground } from 'components/toolbar-background';
import { ProcessingStatus } from 'components/processing-status';
import { ToolbarText } from './board-toolbar.styles';

const propTypes = {
  isPublished: PropTypes.bool.isRequired,
  processing: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      isBaseline: PropTypes.bool,
      complete: PropTypes.number.isRequired,
    }),
  ),
  total: PropTypes.number,
};

const defaultProps = {
  processing: [],
  total: 0,
};

const BoardToolbar = ({ isPublished, processing, total }) => {
  const remaining = pipe(
    map(({ complete }) => total - complete),
    sum,
  )(processing);

  return (
    <ToolbarBackground>
      <Toolbar justify='space-between'>
        <ToolbarGroup>
          <ToolbarItem>
            <ToolbarText>
              {isPublished
                ? 'Viewing published scenarios'
                : 'Viewing draft scenarios'}
            </ToolbarText>
          </ToolbarItem>
        </ToolbarGroup>
        {remaining > 0 && (
          <ToolbarGroup justify='end'>
            <ToolbarItem>
              <ProcessingStatus processing={processing} total={total} />
            </ToolbarItem>
          </ToolbarGroup>
        )}
      </Toolbar>
    </ToolbarBackground>
  );
};

BoardToolbar.propTypes = propTypes;
BoardToolbar.defaultProps = defaultProps;

export { BoardToolbar };
