import PropTypes from 'prop-types';

const propTypes = {
  datasetId: PropTypes.number.isRequired,
};

const defaultProps = {};

const DatasetLocationsGrid = ({ datasetId }) => <div>todo {datasetId}</div>;

DatasetLocationsGrid.propTypes = propTypes;
DatasetLocationsGrid.defaultProps = defaultProps;

export { DatasetLocationsGrid };
