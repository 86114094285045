import {
  first,
  flatMap,
  find,
  filter,
  identity,
  sortBy,
  omitBy,
  isNil,
  map,
  set,
  zipWith,
  values,
  pipe,
  get,
  has,
  reduce,
  curry,
  indexOf,
  defaultTo,
} from 'lodash/fp';
import { pickQualitative } from 'utils/colors';
import { qualitativeColors } from 'settings/colors';
import { labelOfExpressionWithColumns } from 'data/block';
import Units from 'data/units';

const units = new Units();

const immutableSort = curry((comparator, arr) => arr.slice().sort(comparator));

const omitNil = omitBy(isNil);

const Series = {
  // NOTE: PRECONDITON: series and metadata series types must align.
  name: (series) => {
    switch (get('type', series)) {
      case 'scalar':
        return series.id.value;
      case 'id': {
        return series.id;
      }
      default:
        return undefined;
    }
  },
  formatter: curry((metadata, locations, instances, namedScenarios, id) => {
    switch (get(['series', 'type'], metadata)) {
      case 'by-value': {
        const unit = units.parseColumn(
          get(['series', 'column'], metadata),
          locations,
          instances,
          namedScenarios,
        );
        return unit.format(id);
      }
      case 'by-id': {
        const expression = get(['series', 'expressions', id], metadata);
        const columns = get(['series', 'columns'], metadata);
        return labelOfExpressionWithColumns(expression, columns);
      }
      default:
        return undefined;
    }
  }),
  comparator: curry(
    (metadata, locations, instances, namedScenarios, id1, id2) => {
      switch (get(['series', 'type'], metadata)) {
        case 'by-value': {
          const unit = units.parseColumn(
            get(['series', 'column'], metadata),
            locations,
            instances,
            namedScenarios,
          );
          return unit.compare(id1, id2);
        }
        case 'by-id': {
          return id1 - id2;
        }
        default:
          return undefined;
      }
    },
  ),
};

// NOTE: This must default to `null' to appease highcharts.
const yValue = pipe(get(['y', 'value']), defaultTo(null));

const ChartData = {
  fromBlockData: (
    data,
    metadata,
    xLevels,
    yFormatter,
    seriesFormatter,
    seriesComparator,
  ) =>
    pipe(
      // NOTE: The position of the data in the `data' array is in relation to
      // the x-axis. We need to assure they align, and not by accident.
      sortBy((datum) => indexOf(datum.x.value, xLevels)),
      reduce((acc, datum) => {
        const name = Series.name(datum.series);
        if (!has(name, acc)) {
          return set(
            name,
            {
              name,
              data: [yValue(datum)],
              unitFormatter: yFormatter,
              nameFormatter: seriesFormatter,
              maxPointWidth: 120,
            },
            acc,
          );
        }
        acc[name].data.push(yValue(datum));
        return acc;
      }, {}),
      values,
      immutableSort((d1, d2) => seriesComparator(d1.name, d2.name)),
      (series) =>
        zipWith(
          (color, datum) => set('color', color, datum),
          pickQualitative(qualitativeColors, series.length),
          series,
        ),
    )(data),
};

const X = {
  levels: (metadata, xComparator) =>
    pipe(
      get(['x', 'levels']),
      map('value'),
      immutableSort(xComparator),
    )(metadata),
  label: (metadata) => get(['x', 'column', 'label'], metadata),
};

const Y = {
  bounds: (metadata) =>
    omitNil({
      min: get(['y', 'min', 'value'], metadata),
      max: get(['y', 'max', 'value'], metadata),
    }),
  label: (metadata, yUnit) => {
    switch (get(['series', 'type'], metadata)) {
      case 'by-id': {
        const symbol = get('symbol', yUnit);
        const expressions = get(['series', 'expressions'], metadata);
        const columns = get(['series', 'columns'], metadata);
        if (expressions.length === 1) {
          return `${labelOfExpressionWithColumns(
            first(expressions),
            columns,
          )} (${symbol})`;
        }
        return symbol;
      }
      case 'by-value': {
        const symbol = get('symbol', yUnit);
        const column = get(['y', 'column'], metadata);
        const expression = get(['series', 'expression'], metadata);
        return `${labelOfExpressionWithColumns(expression, [
          column,
        ])} (${symbol})`;
      }
      default:
        return undefined;
    }
  },
};

const seriesModeOfSeriesExpression = (seriesExpression) => {
  switch (get('type', seriesExpression)) {
    case 'by-expression': {
      if (seriesExpression.series.length === 0) {
        return 'empty';
      }
      if (seriesExpression.series.length === 1) {
        return 'single-expression';
      }
      return 'multi-expression';
    }
    case 'by-dimension':
      return 'breakdown';
    default:
      return 'empty';
  }
};

const seriesModeOf = (blockValue) => {
  switch (get(['scenario', 'type'], blockValue)) {
    case 'all': {
      switch (get(['x', 'type'], blockValue)) {
        case 'column':
          return 'breakdown-by-scenario';
        case 'by-scenario':
        default:
          return seriesModeOfSeriesExpression(get('y', blockValue));
      }
    }
    case 'selected':
    case 'by-id':
    default:
      return seriesModeOfSeriesExpression(get('y', blockValue));
  }
};

const flattenSeriesExpression = (seriesExpression) => {
  switch (get('type', seriesExpression)) {
    case 'by-dimension':
      return [seriesExpression.expression, seriesExpression.by];
    case 'by-expression':
      return seriesExpression.series;
    default:
      return [];
  }
};

const flattenY = (blockValue) => {
  switch (get(['scenario', 'type'], blockValue)) {
    case 'all':
      switch (get(['x', 'type'], blockValue)) {
        case 'column':
          return get('y', blockValue) ? [get('y', blockValue)] : [];
        case 'by-scenario':
          return flattenSeriesExpression(blockValue.y);
        default:
          return [];
      }
    case 'by-id':
    case 'selected':
      return flattenSeriesExpression(blockValue.y);
    default:
      return [];
  }
};

const seriesSelectCriteriaOf = (tableResource, blockValue) => (column) => {
  const usedDimensions = [
    get(['x', 'type'], blockValue) === 'column'
      ? get(['x', 'column'], blockValue)
      : undefined,
  ];
  const firstSelectedExpression = first(flattenY(blockValue));
  const unit = pipe(
    get('schema'),
    find(
      (c) =>
        c.key === get('column', firstSelectedExpression) ||
        get('measure', firstSelectedExpression),
    ),
    get('unit'),
  )(tableResource);
  return (
    isNil(find((dimension) => dimension === column.key, usedDimensions)) ||
    column.unit === unit
  );
};

const timeTableRulesOf = (blockValue) => {
  const usedDimensions = flatMap(identity, [
    get(['x', 'type'], blockValue) === 'column' ? [get('x', blockValue)] : [],
    filter((x) => x.type === 'column', flattenY(blockValue)),
  ]);
  return [
    (column) =>
      !isNil(
        find((dimension) => dimension.column === column.key, usedDimensions),
      ),
  ];
};

export {
  ChartData,
  Series,
  X,
  Y,
  seriesModeOf,
  seriesSelectCriteriaOf,
  flattenSeriesExpression,
  flattenY,
  timeTableRulesOf,
};
