import { keyBy, map, omit, pipe, set } from 'lodash/fp';

export const normaliseScenario = (scenario) =>
  pipe(
    set('id', scenario.scenario),
    set('settings', keyBy('key', scenario.definitions)),
    set(
      'values',
      pipe(
        map((location) =>
          set('attributes', keyBy('key', location.attributes), location),
        ),
        keyBy('id'),
      )(scenario.locations),
    ),
    omit(['scenario', 'definitions', 'locations']),
  )(scenario);
