import { ScatterplotLayer } from '@deck.gl/layers';
import { filter, get } from 'lodash/fp';

const bubbleLayers = ({
  data,
  focus,
  visible,
  hoverId,
  selectedId,
  onClick,
  onHover,
  zoom,
}) => {
  const radiusScale = zoom ? Math.max(2 ** (15 - zoom), 1) : 1;
  const radiusMinPixels = 10;
  return [
    new ScatterplotLayer({
      id: 'bubble-base-layer',
      visible,
      data,
      pickable: true,
      stroked: false,
      filled: true,
      radiusScale,
      radiusMinPixels,
      getRadius: get('radius'),
      getPosition: get('position'),
      getFillColor: get(['colors', 'base']),
      getLineWidth: 0,
      onClick,
      onHover,
    }),
    new ScatterplotLayer({
      id: 'bubble-light-hover-layer',
      visible: visible && hoverId && hoverId !== selectedId && !focus,
      data: filter({ id: hoverId }, data),
      pickable: false,
      stroked: true,
      filled: false,
      radiusScale,
      radiusMinPixels,
      lineWidthMinPixels: 2,
      getRadius: get('radius'),
      getPosition: get('position'),
      getFillColor: [0, 0, 0, 0],
      getLineColor: get(['colors', 'deselectedBorder']),
      getLineWidth: 4,
    }),
    new ScatterplotLayer({
      id: 'bubble-diff-base-layer',
      visible: visible && focus,
      data,
      pickable: false,
      stroked: false,
      filled: true,
      radiusScale,
      radiusMinPixels,
      getRadius: get('radius'),
      getPosition: get('position'),
      getFillColor: get(['colors', 'diff']),
      getLineWidth: 0,
    }),
    new ScatterplotLayer({
      id: 'bubble-selected-layer',
      visible: visible && selectedId,
      data: filter({ id: selectedId }, data),
      pickable: false,
      stroked: true,
      filled: true,
      radiusScale,
      radiusMinPixels,
      lineWidthMinPixels: 2,
      getRadius: get('radius'),
      getPosition: get('position'),
      getFillColor: get(['colors', 'diff']),
      getLineColor: get(['colors', 'selectedBorder']),
      getLineWidth: 4,
    }),
    new ScatterplotLayer({
      id: 'bubble-dark-hover-layer',
      visible: visible && hoverId && focus && hoverId !== selectedId,
      data: filter({ id: hoverId }, data),
      pickable: false,
      stroked: true,
      filled: false,
      radiusScale,
      radiusMinPixels,
      lineWidthMinPixels: 2,
      getRadius: get('radius'),
      getPosition: get('position'),
      getFillColor: [0, 0, 0, 0],
      getLineColor: get(['colors', 'selectedBorder']),
      getLineWidth: 4,
    }),
  ];
};

export default bubbleLayers;
