import { createAsyncThunk } from '@reduxjs/toolkit';
import createApi from 'api/createApi';

const userLogin = createAsyncThunk(
  'user/login',
  async ({ token }, { getState }) => {
    const { auth } = getState();
    const useableToken = token || auth.token;
    const api = createApi(useableToken);

    const response = await api.get('/api/authenticate');

    return response.data;
  },
  {
    condition: ({ token }) => {
      if (!token) {
        return false;
      }
    },
    dispatchConditionRejection: true,
  },
);

export { userLogin };
