import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { InPlaceInput, Select, Stack } from '@kinesis/bungle';
import FormSection from 'components/form-section/FormSection';
import Modal from 'components/modals/modal/Modal';
import Layout from 'components/layout';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import { ToolboxWrapper } from 'components/toolbox/toolbox-wrapper';
import { workspacePatch } from 'actions/workspacePatch';
import NewAttributeSettingsHeader from './new-attribute-settings.header';
import { Description } from './new-attribute-settings.styles';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const AttributeSettings = ({ onClose, scenarioId, workspaceId }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [type, setType] = useState('text');

  const onSave = useCallback(() => {
    // This looks/is dumb, it is to allow onBlur to fire before we pick up the values,
    // there must be a better way, but at the moments the components race, and this
    // ensures onBlur wins, and save happens after, it probably only needs to be 0,
    // to give up the thread, but there isn't a reliable test case that verifies this.
    setTimeout(() => {
      const operations = [
        {
          'add-instantiation': {
            instantiation: { attribute: 'custom', name },
          },
        },
      ];
      const optimisticState = {
        settings: [
          {
            definition: 'custom',
            name,
            key: `custom:${name}`,
          },
        ],
      };
      dispatch(
        workspacePatch({
          targetScenarioIds: [],
          patchOps: operations,
          actions: [],
          optimisticState,
          workspaceId,
        }),
      );
      onClose();
    }, 100);
  }, [name, dispatch, workspaceId, onClose]);

  return (
    <Modal
      onSave={onSave}
      onClose={onClose}
      header={
        <NewAttributeSettingsHeader
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      }
      aria-label='New attribute settings'
    >
      <Layout direction='row'>
        <ToolboxWrapper arrangement='left' width={304}>
          <ToolboxSection collapsible title='Description'>
            <Description>
              Custom attributes allow locations to be annotated with text.
            </Description>
          </ToolboxSection>
          <ToolboxSection collapsible title='Used by'>
            <Description>
              Custom attributes do not affect app outputs.
            </Description>
          </ToolboxSection>
        </ToolboxWrapper>
        <Layout direction='column'>
          <FormSection>
            <Stack space='medium'>
              <InPlaceInput
                onChange={setName}
                placeholder='Attribute name'
                value={name}
                variant='title'
                autoFocus
              />
              <Select
                label='Attribute type'
                onChange={setType}
                value={type}
                options={[{ label: 'Text', value: 'text' }]}
              />
            </Stack>
          </FormSection>
        </Layout>
      </Layout>
    </Modal>
  );
};

AttributeSettings.defaultProps = defaultProps;
AttributeSettings.propTypes = propTypes;

export default AttributeSettings;
