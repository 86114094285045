import styled from 'styled-components';
import { Dialog } from '@kinesis/bungle';

const TimeSliderFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.borderColor};
`;

const TimeSliderDialog = styled(Dialog)`
  width: 496px;
  margin: 4px 8px;
`;

const TimeSliderWrapper = styled('div')(({ theme }) => ({
  background: 'white',
  borderTop: `1px solid ${theme.borderColor}`,
}));

const TimeSliderInput = styled('div')({
  width: 104,
});

const TimeSliderTimeline = styled('div')({
  padding: 16,
  width: 304,
});

const TimeSliderTimelineText = styled('p')({
  margin: '0 0 16px',
});

const TimeSliderTimelineEntries = styled('div')({
  display: 'flex',
  marginBottom: 16,
});

const TimeSliderTimelineEntry = styled('div')({
  flex: '1 1 0',
  padding: '0 4px',
  '&:first-child': {
    paddingLeft: 0,
  },
  '&:last-child': {
    paddingRight: 0,
  },
});

const TimeSliderTimelineFinal = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const SliderOverlay = styled('div')({
  position: 'absolute',
  left: 8,
  right: 8,
});

const SliderStripedTrackWrapper = styled('div')({
  borderRadius: 2,
  height: 4,
  margin: 8,
  overflow: 'hidden',
  position: 'relative',
});

const SliderStripedTrack = styled('div')(
  {
    background: `repeating-linear-gradient(
      40deg,
      rgba(0,0,0,0),
      rgba(0,0,0,0) 2px,
      rgba(0,0,0,0.1) 2px,
      rgba(0,0,0,0.1) 4px
    )`,
    height: 4,
    position: 'absolute',
  },
  ({ width, left }) => ({
    width,
    left,
  }),
);

export {
  SliderOverlay,
  SliderStripedTrack,
  SliderStripedTrackWrapper,
  TimeSliderDialog,
  TimeSliderFooter,
  TimeSliderInput,
  TimeSliderTimeline,
  TimeSliderTimelineEntries,
  TimeSliderTimelineEntry,
  TimeSliderTimelineFinal,
  TimeSliderTimelineText,
  TimeSliderWrapper,
};
