import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const scenarioSend = createAsyncThunk(
  'scenario/send',
  async (
    { actions, payload, scenarioId, scenarioKey, workspaceId },
    { dispatch, getState },
  ) => {
    const api = createApiFromState({ dispatch, getState });
    const scenarioIdInt = parseInt(scenarioId, 10);

    const response = await api.post(`/api/workspace/${workspaceId}/scenario`, {
      actions,
      patch: {
        based_on: scenarioKey,
        scenario_id: scenarioIdInt,
        ...payload,
      },
    });

    const { data } = response;

    return { ...data, scenarioId: scenarioIdInt };
  },
);

export { scenarioSend };
