import PropTypes from 'prop-types';
import { Stack, Select, LabelText, Text, Strong } from '@kinesis/bungle';
import { useMemo } from 'react';
import { filter } from 'lodash/fp';
import {
  itemsFilter,
  labelOfMeasureOrBreakdown,
  groupKeyOf,
} from 'data/block/visualisation/configuration/series-select';
import { BreakdownLabel } from './breakdown-label';
import { AddSeriesButton } from './add-series-button';
import { InputContainer } from './visualisation-series-select.styles';

const propTypes = {
  defaultExpanded: PropTypes.number,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  expression: PropTypes.object,
  tone: PropTypes.string.isRequired,
};

const defaultProps = {
  defaultExpanded: undefined,
  expression: undefined,
};

const scenarioBreakdownOptions = [
  {
    label: <BreakdownLabel label='By Scenario' />,
    value: 'scenario-dimension',
  },
];

const BreakdownByScenario = ({
  defaultExpanded,
  label,
  options,
  expression,
  onChange,
  tone,
}) => {
  const expressions = useMemo(
    () => filter((o) => o.type === 'expression', options),
    [options],
  );
  return (
    <Stack space='xsmall'>
      <LabelText>{label}</LabelText>
      <InputContainer>
        <Select
          defaultExpanded={defaultExpanded}
          itemKey='key'
          groupKey={groupKeyOf(expressions)}
          value={expression}
          onChange={onChange}
          options={expressions}
          search
          itemsLabel='measures'
          overrideEmptyText='Select an output to view available measures.'
          tone={tone}
          magnitude='large'
          renderSelected={labelOfMeasureOrBreakdown}
          itemsFilter={itemsFilter}
        />
      </InputContainer>
      <InputContainer>
        <Select
          defaultExpanded={defaultExpanded}
          itemKey='key'
          value='scenario-dimension'
          disabled
          options={scenarioBreakdownOptions}
          magnitude='large'
        />
      </InputContainer>
      <div>
        <AddSeriesButton
          tooltip={
            <Text displayMode='dark'>
              Select <Strong displayMode='dark'>Scenario</Strong> for the X axis
              to compare scenarios across multiple series
            </Text>
          }
          disabled
        />
      </div>
    </Stack>
  );
};

BreakdownByScenario.propTypes = propTypes;
BreakdownByScenario.defaultProps = defaultProps;

export { BreakdownByScenario };
