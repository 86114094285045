import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { get, pipe } from 'lodash/fp';
import { actions as previewActions } from 'reducers/previewReducer';
import workspaceSelector from 'selectors/workspaceSelector';
import { useSelectorWithProps, useWorkspaceId } from 'hooks';

function usePreviewYear() {
  const workspaceId = useWorkspaceId();
  const { constrainedBounds, year, yearSetManually } = useSelectorWithProps(
    pipe(workspaceSelector, get(['preview'])),
    { workspaceId },
  );

  const dispatch = useDispatch();
  const setYear = useCallback(
    (value, setManually = false) => {
      dispatch(
        previewActions.setPreviewYear({
          year: value,
          yearSetManually: setManually,
        }),
      );
    },
    [dispatch],
  );

  const constrainBounds = useCallback(
    (scenarioId, bounds) => {
      dispatch(previewActions.constrainBounds({ scenarioId, bounds }));
    },
    [dispatch],
  );

  return useMemo(
    () => [year, setYear, yearSetManually, constrainedBounds, constrainBounds],
    [constrainBounds, constrainedBounds, setYear, year, yearSetManually],
  );
}

export default usePreviewYear;
