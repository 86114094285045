import { createSelector } from 'reselect';
import { map, reject, isNil, pipe } from 'lodash/fp';
import multiBlockTimeSelector from 'selectors/multiBlockTimeSelector';

const multiBlockYearsSelector = createSelector(
  multiBlockTimeSelector,
  (times) => pipe(map('year'), reject(isNil))(times),
);

export default multiBlockYearsSelector;
