import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { PathwayUrl } from 'data/pathways';
import { pathwayCalculate } from 'actions/pathwayCalculate';
import usePathwayLocations from 'hooks/usePathwayLocations';
import usePathwayLocationType from 'hooks/usePathwayLocationType';
import usePathwayInterventionAreas from 'hooks/usePathwayInterventionAreas';

const usePathwayInputs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const useUpdatedLocations = true;
  const query = useMemo(
    () => PathwayUrl.new(search, useUpdatedLocations),
    [useUpdatedLocations, search],
  );
  const [urlInterventionAreas] = usePathwayInterventionAreas();
  const [urlLocations] = usePathwayLocations();
  const [urlLocationType] = usePathwayLocationType();
  const urlInputs = useMemo(
    () => ({
      input: urlInterventionAreas,
      locations: urlLocations,
      locationType: urlLocationType,
    }),
    [urlInterventionAreas, urlLocations, urlLocationType],
  );
  const calculate = useCallback(
    ({ interventionAreas: input, locations, locationType }) => {
      dispatch(
        pathwayCalculate({
          input,
          locations,
          locationType,
        }),
      );
    },
    [dispatch],
  );
  const urlUpdate = useCallback(
    (inputs) => {
      navigate({
        search: query
          .withInterventionAreas(inputs.interventionAreas)
          .withLocationType(inputs.locationType)
          .withLocations(inputs.locations)
          .toString(),
      });
      calculate(inputs);
    },
    [navigate, query, calculate],
  );
  return [urlInputs, urlUpdate];
};

export default usePathwayInputs;
