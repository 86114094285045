import { useCallback, useRef, useState } from 'react';
import { useRect } from '@kinesis/bungle';
import PropTypes from 'prop-types';
import { fromPairs, isNil } from 'lodash/fp';
import Map from 'components/map/Map';
import { SpatialTools } from 'components/spatial-tools';
import { SpatialPopover } from 'components/spatial-popover';
import MapLegend from 'components/map/MapLegend';
import { useScenarioId } from 'hooks';
import useTheme from 'hooks/useTheme';
import { visualisationLocationLayers } from './location-layers';
import bubbleLayers from './bubble-layers';
import hexLayers from './hex-layers';

const propTypes = {
  allowScrollZoom: PropTypes.bool,
  bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  colorFormat: PropTypes.func,
  colorHeading: PropTypes.string,
  colorStops: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  interactive: PropTypes.bool,
  layers: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  mapboxAccessToken: PropTypes.string.isRequired,
  onDetailSelect: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onLocationSelect: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  selectedLocationId: PropTypes.number,
  selectedSpatialDetailId: PropTypes.any,
  showFixedOverlays: PropTypes.bool,
  sizeHeading: PropTypes.string,
  sizeFormat: PropTypes.func,
  sizeMax: PropTypes.number,
  sizeMin: PropTypes.number,
  viewMode: PropTypes.oneOf(['maximised', 'minimised', 'standalone'])
    .isRequired,
  visualisationLayerEnabled: PropTypes.bool.isRequired,
  visualisationType: PropTypes.string,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  allowScrollZoom: true,
  bounds: undefined,
  colorFormat: undefined,
  colorHeading: undefined,
  interactive: false,
  privacy: 'private',
  selectedLocationId: undefined,
  selectedSpatialDetailId: undefined,
  showFixedOverlays: true,
  sizeFormat: undefined,
  sizeHeading: undefined,
  sizeMax: undefined,
  sizeMin: undefined,
  visualisationType: undefined,
};

const SpatialVisualisation = ({
  allowScrollZoom,
  bounds,
  colorFormat,
  colorHeading,
  colorStops,
  data,
  interactive,
  layers,
  locations,
  mapboxAccessToken,
  onDetailSelect,
  onDragStart,
  onLocationSelect,
  onReset,
  privacy,
  selectedLocationId,
  selectedSpatialDetailId,
  showFixedOverlays,
  sizeFormat,
  sizeHeading,
  sizeMax,
  sizeMin,
  viewMode,
  visualisationLayerEnabled,
  visualisationType,
  workspaceId,
}) => {
  const theme = useTheme();
  const [pointer, setPointer] = useState({});
  const [hover, setHover] = useState({});
  const ref = useRef();
  const rect = useRect(ref);
  const [zoom, setZoom] = useState(undefined);
  const scenarioId = useScenarioId();

  const handleViewportChange = useCallback((vp) => {
    setZoom(vp.zoom);
  }, []);

  const onHover = (onHoverType) => (e) => {
    const { object, x, y } = e;
    if (!object) {
      setHover({ id: undefined, type: undefined });
      return;
    }

    if (hover.type === onHoverType && object.id === hover.id) {
      return null;
    }

    setHover({ id: object.id || object.properties.id, type: onHoverType });
    setPointer({ x, y });
  };

  const generateLayers = (mapZoom) => {
    const deckLayers = [];

    if (visualisationType === 'bubble') {
      deckLayers.push(
        ...bubbleLayers({
          data,
          visible: visualisationLayerEnabled,
          hoverId: hover.type === 'bubble' ? hover.id : undefined,
          selectedId: selectedSpatialDetailId,
          focus: isNil(selectedSpatialDetailId) && isNil(selectedLocationId),
          onClick: (v, event) => {
            if (interactive) {
              onDetailSelect(v.object.id, event);
            }
            return true;
          },
          zoom: mapZoom,
          onHover: onHover('bubble'),
        }),
      );
    }

    if (visualisationType === 'hex') {
      deckLayers.push(
        ...hexLayers({
          data,
          visible: visualisationLayerEnabled,
          hoverId: hover.type === 'hex' ? hover.id : undefined,
          selectedId: selectedSpatialDetailId,
          focus: isNil(selectedSpatialDetailId) && isNil(selectedLocationId),
          onHover: onHover('hex'),
          onClick: (v, event) => {
            if (interactive) {
              onDetailSelect(v.object.id, event);
            }
            return true;
          },
        }),
      );
    }

    if (visualisationType !== 'location-3d') {
      const layerColors = fromPairs(
        layers.map((layer) => [layer.id, theme.color.layer[layer.color]]),
      );
      const locationColors = fromPairs(
        locations.map((location) => [location.id, layerColors[location.layer]]),
      );
      const locationData = locations
        .map((location) => ({
          ...location,
          layerColor: locationColors[location.id],
        }))
        .filter(({ layerColor }) => layerColor);

      deckLayers.push(
        ...visualisationLocationLayers({
          locations: locationData,
          hoverId: hover.type === 'location' ? hover.id : undefined,
          selectedId: selectedLocationId,
          focus: isNil(selectedSpatialDetailId) && isNil(selectedLocationId),
          onClick: (v, event) => {
            if (interactive) {
              onLocationSelect(v.object.id || v.object.properties.id, event);
            }

            return true;
          },
          onHover: onHover('location'),
        }),
      );
    }

    return deckLayers;
  };

  // isDragging + isHovering are internal InteractiveMap states.
  const getCursor = ({ isDragging, isHovering }) => {
    if (hover.id || isHovering) {
      return interactive ? 'pointer' : 'default';
    }
    if (isDragging) {
      return 'grabbing';
    }

    return 'grab';
  };

  return (
    <>
      <Map
        ref={ref}
        bounds={bounds}
        dragPan
        getCursor={getCursor}
        layers={generateLayers(zoom)}
        mapStyle={visualisationType === 'location-3d' ? 'light3d' : 'light'}
        mapType={visualisationType}
        mapboxAccessToken={mapboxAccessToken}
        onClick={onReset}
        onDragStart={viewMode === 'minimised' ? onDragStart : undefined}
        onViewportChange={handleViewportChange}
        paddingTop={viewMode === 'minimised' ? 60 : undefined}
        scrollZoom={allowScrollZoom}
      />
      {['bubble', 'hex'].includes(visualisationType) &&
        visualisationLayerEnabled &&
        showFixedOverlays && (
          <MapLegend
            colorHeading={colorHeading}
            colorStops={colorStops}
            sizeHeading={sizeHeading}
            sizeMin={sizeFormat ? sizeFormat(sizeMin) : sizeMin}
            sizeMax={sizeFormat ? sizeFormat(sizeMax) : sizeMax}
          />
        )}
      {hover.type && !isNil(hover.id) && pointer.x && pointer.y && (
        <SpatialPopover
          type={hover.type}
          id={hover.id}
          x={pointer.x + rect.x}
          y={pointer.y + rect.y}
          locations={locations}
          data={data}
          colorHeading={colorHeading}
          colorFormat={colorFormat}
          sizeHeading={sizeHeading}
          sizeFormat={sizeFormat}
        />
      )}
      {showFixedOverlays && (
        <SpatialTools
          privacy={privacy}
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      )}
    </>
  );
};

SpatialVisualisation.propTypes = propTypes;
SpatialVisualisation.defaultProps = defaultProps;

export default SpatialVisualisation;
