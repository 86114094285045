import { useState, useCallback, useEffect } from 'react';
import { isNil } from 'lodash/fp';
import PropTypes from 'prop-types';
import { H3 } from '@kinesis/bungle';
import { StaticCell, EditableCell } from './cell.styles';

const propTypes = {
  appearance: PropTypes.oneOf(['dark', 'light']),
  variant: PropTypes.oneOf(['default', 'bare']),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]),
  editable: PropTypes.bool,
  heading: PropTypes.bool,
  indent: PropTypes.oneOf(['none', 'single', 'double']),
  multiple: PropTypes.bool,
  numeric: PropTypes.bool,
  onSave: PropTypes.func,
  value: PropTypes.number,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
};

const defaultProps = {
  appearance: 'light',
  variant: 'default',
  children: undefined,
  editable: false,
  heading: false,
  indent: 'none',
  multiple: false,
  numeric: false,
  onSave: undefined,
  value: undefined,
  minimum: undefined,
  maximum: undefined,
};

const Cell = ({
  appearance,
  children,
  editable,
  heading,
  indent,
  multiple,
  numeric,
  onSave,
  value,
  variant,
  minimum,
  maximum,
  ...restProps
}) => {
  const [cellValue, setCellValue] = useState(value);
  useEffect(() => setCellValue(value), [value]);
  const [editMode, setEditMode] = useState(false);
  const enableEditMode = useCallback(() => setEditMode(true), [setEditMode]);
  const disableEditMode = useCallback(() => {
    setEditMode(false);
    setCellValue(value);
  }, [setEditMode, setCellValue, value]);
  const onChange = useCallback(
    (event) => setCellValue(event.currentTarget.value),
    [setCellValue],
  );
  const onBlur = useCallback(() => {
    const newValue = parseFloat(cellValue);
    if (
      (!isNil(minimum) && newValue < minimum) ||
      (!isNil(maximum) && newValue > maximum)
    ) {
      setCellValue(value);
    } else if (newValue !== value) {
      onSave(newValue);
    }
    disableEditMode();
  }, [cellValue, value, disableEditMode, onSave, minimum, maximum]);

  if (!editMode) {
    const content = multiple ? <em>Multiple values</em> : children;

    return (
      <StaticCell
        variant={variant}
        appearance={appearance}
        indent={indent}
        numeric={numeric}
        onClick={editable ? enableEditMode : undefined}
        onFocus={editable ? enableEditMode : undefined}
        tabIndex={editable ? 0 : undefined}
        {...restProps}
      >
        {heading ? <H3>{content}</H3> : content}
      </StaticCell>
    );
  }

  return (
    <EditableCell
      onBlur={onBlur}
      onChange={onChange}
      value={cellValue}
      onFocus={(event) => event.target.select()}
      autoFocus
      {...restProps}
    />
  );
};

Cell.propTypes = propTypes;
Cell.defaultProps = defaultProps;

export { Cell };
