import PropTypes from 'prop-types';
import { Text, Strong } from '@kinesis/bungle';
import { get, first, map } from 'lodash/fp';
import {
  keyedLocations,
  locationsToLocationTypes,
  locationTypeLabels,
} from 'data/pathways';
import { Row, RowItem } from './pathway-input-block.styles';

// NOTE: The locations are assumed to always be non-empty, and are all of the
// same location type.
const locationLabel = (locations) => {
  if (locations.length === 1) {
    return get('label', keyedLocations[first(locations)]);
  }
  const locationType = locationsToLocationTypes[first(locations)];
  const locationTypeLabel = locationTypeLabels[locationType];
  return `${locations.length} ${locationTypeLabel}s`;
};

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      locations: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
  ).isRequired,
};
const defaultProps = {};

const Locations = ({ data }) => (
  <Row>
    <RowItem>
      <Strong>Locations</Strong>
    </RowItem>
    {map(
      (datum) => (
        <RowItem>
          <Text>{locationLabel(datum.locations)}</Text>
        </RowItem>
      ),
      data,
    )}
  </Row>
);

Locations.propTypes = propTypes;
Locations.defaultProps = defaultProps;

export { Locations };
