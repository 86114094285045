import { Stack, Divider } from '@kinesis/bungle';
import { AnnualEmissions } from './annual-emissions';
import { EmissionsReductions } from './emissions-reductions';
import { PathwaysVisualisationsDownloadLink } from './download-link';
import { BudgetIndicators } from './budget-indicators';
import { VisualisationContainer } from './pathways-visualisations.styles';

const PathwaysVisualisationsResults = () => (
  <VisualisationContainer>
    <Stack space='medium'>
      <Stack space='large'>
        <BudgetIndicators />
        <AnnualEmissions />
        <EmissionsReductions />
        <Divider />
      </Stack>
      <PathwaysVisualisationsDownloadLink />
    </Stack>
  </VisualisationContainer>
);

export { PathwaysVisualisationsResults };
