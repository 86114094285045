import { createSlice } from '@reduxjs/toolkit';
import { keyBy, get, pipe, every, flatMap, values } from 'lodash/fp';
import { workspaceProgress } from 'actions/workspaceProgress';
import { blockProgress } from 'actions/blockProgress';

const { reducer, actions } = createSlice({
  name: 'progress',

  initialState: {
    pollCount: 0,
    polling: 'idle',
    blockPollCount: 0,
    blockPolling: 'idle',
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(blockProgress.pending.type, (state) => {
      state.blockPolling = 'pending';
    });

    builder.addCase(blockProgress.fulfilled.type, (state, action) => {
      const complete = pipe(
        flatMap('progress'),
        every('available'),
      )(action.payload.scenarios);
      return {
        ...state,
        blockPollCount: complete ? 0 : state.pollCount + 1,
        blockPolling: complete ? 'idle' : 'polling',
      };
    });

    builder.addCase(workspaceProgress.pending.type, (state) => {
      state.polling = 'pending';
    });

    builder.addCase(workspaceProgress.fulfilled.type, (state, action) => {
      const complete = pipe(
        flatMap(pipe(get('resources'), values)),
        every('available'),
      )(action.payload.scenarios);
      return {
        ...state,
        pollCount: complete ? 0 : state.pollCount + 1,
        polling: complete ? 'idle' : 'polling',
        scenarios: {
          ...state.scenarios,
          ...keyBy('scenarioKey', action.payload.scenarios),
        },
      };
    });
  },
});

export { reducer, actions };
