import { createSelector } from 'reselect';
import { filter, get, values, pipe, sortBy, lowerCase } from 'lodash/fp';

const appsSelector = createSelector(
  get(['apps', 'entities']),
  pipe(
    values,
    filter(get(['permissions', 'viewer'])),
    sortBy(pipe(get('label'), lowerCase)),
  ),
);

export default appsSelector;
