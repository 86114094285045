import { publicWorkspaceFetch } from 'actions/publicWorkspaceFetch';
import { appAdd } from 'actions/appAdd';
import { appsList } from 'actions/appsList';
import { appsUpgrade } from 'actions/appsUpgrade';
import { blockAdd } from 'actions/blockAdd';
import { blockUpdateDetails } from 'actions/blockUpdateDetails';
import { boardCreate } from 'actions/boardCreate';
import { blockValueCreate } from 'actions/blockValueCreate';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { createSlice } from '@reduxjs/toolkit';
import { get, keys, keyBy, set, has, reject } from 'lodash/fp';
import { empty, rowBreak } from 'utils/blockVersions';

const { reducer, actions } = createSlice({
  name: 'blocks',

  // used for optimistic loading
  initialState: {
    [rowBreak]: {
      address: rowBreak,
      type: 'row-break',
    },
    [empty]: {
      address: empty,
      type: 'empty',
    },
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(workspaceFetch.fulfilled, (state, action) => ({
      ...state,
      ...keyBy('address', action.payload.blocks),
    }));

    builder.addCase(workspacePatch.fulfilled, (state, action) => ({
      ...state,
      ...keyBy('address', action.payload.blocks),
    }));

    builder.addCase(publicWorkspaceFetch.fulfilled, (state, action) => ({
      ...state,
      ...keyBy('address', action.payload.blocks),
    }));

    builder.addCase(appAdd.fulfilled.type, (state, action) => ({
      ...state,
      ...keyBy('address', action.payload.blocks),
    }));

    builder.addCase(appsUpgrade.fulfilled.type, (state, action) => ({
      ...state,
      ...keyBy('address', action.payload.blocks),
    }));

    builder.addCase(blockAdd.fulfilled.type, (state, action) => ({
      ...state,
      ...keyBy('address', action.payload.capsules),
    }));

    builder.addCase(blockUpdateDetails.pending.type, (state, action) => {
      const {
        requestId,
        arg: { blockParams },
      } = action.meta;

      return set(requestId, set('address', requestId, blockParams), state);
    });

    builder.addCase(blockUpdateDetails.fulfilled.type, (state, action) => {
      const { requestId } = action.meta;
      const { [requestId]: _deleted, ...rest } = state; // remove the old block from the state
      const newCapsules = reject(
        ({ address }) => has(address, state),
        action.payload.capsules,
      );
      return {
        ...rest,
        ...keyBy('address', newCapsules),
      };
    });

    builder.addCase(blockValueCreate.pending.type, (state, action) => {
      const {
        requestId,
        arg: { blockParams },
      } = action.meta;

      return set(requestId, set('address', requestId, blockParams), state);
    });

    builder.addCase(blockValueCreate.fulfilled.type, (state, action) => {
      const { requestId } = action.meta;
      const { newCapsule } = action.payload;
      const { [requestId]: _deleted, ...rest } = state; // remove the old block keyed by requestId from the state

      return {
        ...rest,
        [get('address', newCapsule)]: newCapsule,
      };
    });

    builder.addCase(boardCreate.fulfilled.type, (state, action) => ({
      ...state,
      ...keyBy('address', action.payload.capsules),
    }));

    builder.addCase(appsList.fulfilled.type, (state, action) => {
      const appBlocks = {};

      action.payload.forEach((app) => {
        if (app.available) {
          const { capsules, resources } = app.available;

          keys(capsules).forEach((boardKey) => {
            const appBoardResource = resources.find(
              (r) => r.resource === boardKey,
            );

            capsules[boardKey].forEach((blockVersion, index) => {
              appBlocks[blockVersion] = {
                address: blockVersion,
                ...appBoardResource.blocks[index],
              };
            });
          });
        }
      });

      return { ...state, ...appBlocks };
    });
  },
});

export { reducer, actions };
