import { createSlice } from '@reduxjs/toolkit';
import { keyBy, omitBy, isNil, get, set, pipe } from 'lodash/fp';
import { appsList } from 'actions/appsList';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';

const omitNil = omitBy(isNil);

const { reducer, actions } = createSlice({
  name: 'appVersions',

  initialState: {
    currentRequestId: undefined,
    entities: {},
    error: null,
    loading: 'idle',
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(workspaceFetch.fulfilled, (state, action) => {
      const { appVersions } = action.payload;

      return pipe(
        set('loading', 'idle'),
        set('entities', {
          ...(state.entities || {}),
          ...keyBy('id', appVersions),
        }),
      )(state);
    });

    builder.addCase(workspacePatch.fulfilled, (state, action) => {
      const { appVersions } = action.payload;

      return pipe(
        set('loading', 'idle'),
        set('entities', {
          ...(state.entities || {}),
          ...keyBy('id', appVersions),
        }),
      )(state);
    });

    builder.addCase(appsList.fulfilled, (state, action) => ({
      ...state,

      entities: {
        ...(state.entities || {}),
        ...keyBy(
          'id',
          omitNil(
            action.payload
              .map(get('available'))
              .concat(action.payload.map(get('limited'))),
          ),
        ),
      },
    }));
  },
});

export { reducer, actions };
