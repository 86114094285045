import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { get, identity, isEmpty, find, pipe, first } from 'lodash/fp';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  Icon,
  UtilityButton,
} from '@kinesis/bungle';

const propTypes = {
  disabled: PropTypes.bool,
  scenarios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      isDefault: PropTypes.bool,
    }),
  ),
  value: PropTypes.number,
  onSelect: PropTypes.func,
};
const defaultProps = {
  disabled: false,
  value: undefined,
  onSelect: identity,
  scenarios: [],
};

const ScenarioDropdown = ({ disabled, scenarios, value, onSelect }) => {
  const theme = useTheme();
  const selected = useMemo(
    () => find({ id: value }, scenarios),
    [value, scenarios],
  );
  useEffect(() => {
    if (!selected && !isEmpty(scenarios)) {
      onSelect(pipe(first, get('id'))(scenarios));
    }
  }, [selected, scenarios, onSelect]);
  return (
    <DropdownMenu justify='end'>
      <DropdownMenuButton
        as={UtilityButton}
        disabled={disabled}
        dropdown
        icon={
          get('isDefault', selected) && (
            <Icon
              color={theme.color.gray7}
              magnitude='xsmall'
              type='baseline'
            />
          )
        }
        magnitude='small'
      >
        {get('name', selected)}
      </DropdownMenuButton>
      <DropdownMenuList width={240}>
        {scenarios.map(({ id, name }) => (
          <DropdownMenuItem
            key={id}
            onSelect={() => onSelect(id)}
            variant={id === value ? 'accent' : undefined}
          >
            {name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuList>
    </DropdownMenu>
  );
};

ScenarioDropdown.defaultProps = defaultProps;
ScenarioDropdown.propTypes = propTypes;

export { ScenarioDropdown };
