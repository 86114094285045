import PropTypes from 'prop-types';
import { map, pipe, split } from 'lodash/fp';
import { LegacyContent } from 'components/legacy-content';
import { Paragraph } from './app-management.styles';

const propTypes = {
  description: PropTypes.string,
  version: PropTypes.number,
};

const defaultProps = {
  description: undefined,
  version: undefined,
};

const OverviewTabContent = ({ description, version }) => (
  <LegacyContent>
    {pipe(
      split('\n\n'),
      map((p) => <Paragraph>{p}</Paragraph>),
    )(description)}
    {version && <Paragraph gray={7}>Version {version}</Paragraph>}
  </LegacyContent>
);

OverviewTabContent.defaultProps = defaultProps;
OverviewTabContent.propTypes = propTypes;

export default OverviewTabContent;
