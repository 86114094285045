import { createCachedSelector } from 're-reselect';
import { get, pipe } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const scenarioKeySelector = createCachedSelector(
  (state, props) => props.scenarioId,
  (state, props) => props?.public,
  workspaceSelector,
  (scenarioId, isPublic, workspace) => {
    const privateWorkspaceScenarioKey = (arg) =>
      pipe(
        get(['scenarios', 'named', scenarioId]),
        (entry = {}) => entry.draft || entry.published,
        get('scenario'),
      )(arg);

    return pipe(
      isPublic
        ? get(['scenarios', scenarioId, 'scenario'])
        : privateWorkspaceScenarioKey,
    )(workspace);
  },
)((state, props = {}) => props.scenarioId);

export default scenarioKeySelector;
