import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash/fp';
import createApiFromState from 'api/createApiFromState';

const populationDatasetUpdate = createAsyncThunk(
  'population-dataset/update',
  async (
    { source, modifications, onSave },
    { dispatch, getState, rejectWithValue },
  ) => {
    const api = createApiFromState({ dispatch, getState });
    try {
      const response = await api.post(
        `/api/resources/custom:dpie:population/datasets/${source}`,
        { modifications },
      );
      if (onSave) {
        onSave(response.data);
      }
      return response.data;
    } catch (err) {
      return rejectWithValue({
        status: get(['response', 'status'], err),
        data: get(['response', 'data'], err),
      });
    }
  },
);

export { populationDatasetUpdate };
