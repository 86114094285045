import { createCachedSelector } from 're-reselect';
import { get, pipe } from 'lodash/fp';
import locationsSelector from 'selectors/locationsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import Units from 'data/units';
import blockStateSelector from './blockStateSelector';
import blockValueSelector from './blockValueSelector';

const units = new Units();

const colorFormatSelector = createCachedSelector(
  pipe(blockStateSelector, get('schema')),
  blockValueSelector,
  locationsSelector,
  workspaceInstantiationsSelector,
  (schema, visualisation, locations, instantiations) => {
    if (!visualisation) {
      return undefined;
    }
    const colorKey = get(
      [get('visualisation', visualisation), 'colour', 'column'],
      visualisation,
    );
    return colorKey && schema[colorKey]
      ? units.parseColumn(schema[colorKey], locations, instantiations).format
      : undefined;
  },
)((state, props) => props.blockId);

export default colorFormatSelector;
