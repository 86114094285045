import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';
import boardSelector from './boardSelector';

const blockIdsSelector = createSelector(
  (_, props = {}) => props.blockIds,
  pipe(boardSelector, get('blocks')),
  (blockIdsFromProps, blockIdsFromBoard) =>
    blockIdsFromProps || blockIdsFromBoard,
);

export default blockIdsSelector;
