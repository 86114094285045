import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { get, isNil } from 'lodash/fp';
import { Stack, InPlaceInput } from '@kinesis/bungle';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { VerticalPane, VerticalPanes } from 'components/vertical-panes';
import { useAppId } from 'hooks';
import { ContentPadded } from 'components/styled/ContentPadded';

const AppPane = () => {
  const location = useLocation();
  const { autoSelect = false } = location.state || {};
  const appId = useAppId();
  const app = useSelector(get(['apps', 'entities', appId]));
  const loading = isNil(app);

  const onSaveName = useCallback(
    (name) => {
      console.log('Save name', appId, name);
    },
    [appId],
  );

  const onSaveDescription = useCallback(
    (description) => {
      console.log('Save description', appId, description);
    },
    [appId],
  );

  if (loading) return null;
  return (
    <ToolboxPanel>
      <VerticalPanes>
        <VerticalPane>
          <ContentPadded>
            <Stack space='medium'>
              <InPlaceInput
                autoFocus={autoSelect}
                onChange={onSaveName}
                placeholder='App name'
                value={get('label', app)}
                variant='title'
              />
              <InPlaceInput
                onChange={onSaveDescription}
                placeholder='Write a description'
                value={get('description', app) || ''}
              />
            </Stack>
          </ContentPadded>
        </VerticalPane>
      </VerticalPanes>
    </ToolboxPanel>
  );
};

export { AppPane };
