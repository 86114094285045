import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card,
  CardButton,
  CardContent,
  CardMenu,
  CardMenuItem,
  CardSubtitle,
  CardTitle,
  Icon,
  Prompt,
  Tooltip,
} from '@kinesis/bungle';
import { format } from 'date-fns/fp';
import useDateDistance from 'hooks/useDateDistance';
import usePermission from 'hooks/usePermission';
import { ScenarioIcon } from './named-scenarios.styles';

const propTypes = {
  isDefault: PropTypes.bool,
  locationsCount: PropTypes.number,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  publishMode: PropTypes.oneOf(['draft', 'published']),
  scenarioId: PropTypes.number.isRequired,
  showPrompt: PropTypes.bool,
  updatedAt: PropTypes.instanceOf(Date),
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  isDefault: false,
  locationsCount: 0,
  onDelete: undefined,
  onDuplicate: undefined,
  publishMode: undefined,
  showPrompt: false,
  updatedAt: undefined,
};

const NamedScenarioItem = ({
  isDefault,
  locationsCount,
  name,
  onDelete,
  onDuplicate,
  publishMode,
  scenarioId,
  showPrompt,
  updatedAt,
  workspaceId,
  ...restProps
}) => {
  const dateDistance = useDateDistance(updatedAt);
  const editor = usePermission('editor');

  const handleDuplicate = useCallback(() => {
    onDuplicate(scenarioId);
  }, [onDuplicate, scenarioId]);

  const handleDelete = useCallback(() => {
    onDelete(scenarioId);
  }, [onDelete, scenarioId]);

  return (
    <Prompt
      disabled={!isDefault || !editor}
      dismissOnKeyPress={false}
      maxWidth={600}
      placement='top'
      showPrompt={showPrompt}
      title='Define your area of interest by creating or importing locations'
    >
      <Card {...restProps}>
        <CardButton
          as={Link}
          to={`/workspaces/${workspaceId}/scenarios/${scenarioId}`}
        >
          <CardContent clipRight noWrap>
            <CardTitle>
              {name}
              {publishMode === 'draft' ? ' (Draft)' : ''}
              {isDefault && (
                <Tooltip title='Baseline scenario' placement='top'>
                  <ScenarioIcon>
                    <Icon type='baseline' />
                  </ScenarioIcon>
                </Tooltip>
              )}
            </CardTitle>
            <CardSubtitle>
              {isDefault && locationsCount === 0
                ? 'Not defined'
                : updatedAt && (
                    <Tooltip title={format('d MMM yyyy, h:mm a', updatedAt)}>
                      <span>Last updated {dateDistance}</span>
                    </Tooltip>
                  )}
            </CardSubtitle>
          </CardContent>
        </CardButton>
        <CardMenu disabled={!editor}>
          <CardMenuItem onSelect={handleDuplicate}>Duplicate</CardMenuItem>
          <CardMenuItem
            variant='danger'
            disabled={isDefault}
            onSelect={handleDelete}
          >
            Delete
          </CardMenuItem>
        </CardMenu>
      </Card>
    </Prompt>
  );
};

NamedScenarioItem.propTypes = propTypes;
NamedScenarioItem.defaultProps = defaultProps;

export default NamedScenarioItem;
