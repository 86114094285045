import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { eq, get, map, max, min, pipe, size } from 'lodash/fp';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { actions as publicWorkspaceActions } from 'reducers/publicWorkspacesReducer';
import blocksWithTimeSelector from 'selectors/blocksWithTimeSelector';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import multiBlockYearsWithLabelSelector from 'selectors/multiBlockYearsWithLabelSelector';
import publicMultiBlockYearBoundsSelector from 'selectors/publicMultiBlockYearBoundsSelector';
import { ContinuousTimeSlider } from 'components/time-slider';

const propTypes = {
  boardId: PropTypes.number.isRequired,
  workspaceId: PropTypes.string.isRequired,
};

const defaultProps = {};

const PublicBoardTimeSlider = ({ boardId, workspaceId }) => {
  const dispatch = useDispatch();
  const selectedBlockIds = useSelector(blockSelectionsSelector);

  const blockIds = useMemo(
    () =>
      // Returning undefined will default to board's blocks
      size(selectedBlockIds) > 0 ? selectedBlockIds : undefined,
    [selectedBlockIds],
  );

  const selectedBlocksWithYear = useSelectorWithProps(blocksWithTimeSelector, {
    blockIds,
    boardId,
    public: true,
    workspaceId,
  });

  const blockYearsWithLabel = useSelectorWithProps(
    multiBlockYearsWithLabelSelector,
    {
      blockIds,
      boardId,
      public: true,
      workspaceId,
    },
  );
  const blockLabels = useMemo(
    () =>
      pipe(size, eq(1))(blockYearsWithLabel)
        ? []
        : map('label', blockYearsWithLabel),
    [blockYearsWithLabel],
  );
  const blockYears = useMemo(
    () => map('year', blockYearsWithLabel),
    [blockYearsWithLabel],
  );
  const setMultipleBlockYear = useCallback(
    (value) => {
      dispatch(
        publicWorkspaceActions.setMultipleBlockYear({
          blockIds: map(get('id'), selectedBlocksWithYear),
          year: value,
        }),
      );
    },
    [dispatch, selectedBlocksWithYear],
  );

  const [outerMin, outerMax] = useSelectorWithProps(
    publicMultiBlockYearBoundsSelector,
    { boardId, public: true, workspaceId },
  );
  const [innerMin, innerMax] = useSelectorWithProps(
    publicMultiBlockYearBoundsSelector,
    { blockIds, boardId, public: true, workspaceId },
  );

  const [[localMin, localMax], setLocalBounds] = useState([outerMin, outerMax]);
  useEffect(() => {
    setLocalBounds([outerMin, outerMax]);
  }, [outerMin, outerMax]);

  const resetYear = useCallback(() => {
    setMultipleBlockYear(outerMax);

    // reset bounds
    if (localMax < outerMax) {
      setLocalBounds([outerMin, outerMax]);
    }
  }, [localMax, outerMax, outerMin, setMultipleBlockYear]);

  const changeBounds = useCallback(
    (newBounds) => {
      const [newMin, newMax] = newBounds;

      if (min(blockYears) < newMin) {
        setMultipleBlockYear(newMin);
      } else if (max(blockYears) > newMax) {
        setMultipleBlockYear(newMax);
      }

      setLocalBounds(newBounds);
    },
    [blockYears, setMultipleBlockYear],
  );

  return (
    <ContinuousTimeSlider
      boundsDefaultMax={outerMax}
      boundsDefaultMin={outerMin}
      boundsInnerMax={innerMax}
      boundsInnerMin={innerMin}
      boundsMax={localMax}
      boundsMin={localMin}
      defaultYear={outerMax}
      labels={blockLabels}
      onChangeBounds={changeBounds}
      onChangeYear={setMultipleBlockYear}
      onResetYear={resetYear}
      showStripedTracks
      years={blockYears}
    />
  );
};

PublicBoardTimeSlider.propTypes = propTypes;
PublicBoardTimeSlider.defaultProps = defaultProps;

export { PublicBoardTimeSlider };
