import {
  set,
  isEqual,
  includes,
  identity,
  concat,
  negate,
  eq,
  findIndex,
  sum,
  curry,
  filter,
  find,
  flatMap,
  has,
  get,
  isEmpty,
  isNil,
  isNaN,
  last,
  map,
  pipe,
  reduce,
  reject,
  some,
  sortBy,
  omitBy,
  keyBy,
  defaultTo,
  update,
} from 'lodash/fp';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  parseISO,
  getYear,
} from 'date-fns/fp';
import { ATTRIBUTE_TYPE_MAP } from 'constants/attributes';
import { keyToSettingFamily } from 'data/settings';

const omitNil = omitBy(isNil);

const mapping = {
  usage: 'usage-v2',
  service: 'service-v2',
};

const keyOf = get('key');

const keyFor = (key) => mapping[key] || key;

const categories = curry((setting) => get('categories', setting) || []);

const attributes = get('attributes');

const attributeKey = get('key');

const attributeType = curry((x) => get('attribute', x) || get('definition', x));

const timeSeries = get('time_series');

const fields = curry((itemOrCategory) => get('fields', itemOrCategory) || []);

const entries = curry(
  (settingOrCategory) =>
    get('entries', settingOrCategory) ||
    get('categories', settingOrCategory) ||
    [],
);

const hasEntries = (settingOrCategory) => !isEmpty(entries(settingOrCategory));

const flatten = curry((setting) =>
  isNil(get('categories', setting))
    ? pipe(
        entries,
        map((entry) => ({ ...entry, entry: keyOf(entry) })),
      )(setting)
    : pipe(
        categories,
        flatMap((category) =>
          [{ ...category, category: keyOf(category) }].concat(
            pipe(
              entries,
              map((entry) => ({
                ...entry,
                category: keyOf(category),
                parent: category,
                entry: keyOf(entry),
              })),
            )(category),
          ),
        ),
      )(setting),
);

const items = curry(
  (setting) =>
    get('entries', setting) || flatMap('entries', categories(setting)),
);

const itemByKey = curry((key, setting) => pipe(items, find({ key }))(setting));

const categoryByKey = curry((key, setting) =>
  pipe(categories, find({ key }))(setting),
);

const syntheticSettings = (settings) => ({
  'cohort-preferences': {
    key: 'cohort-preferences',
    entries: [
      {
        key: 'pwc',
        label: 'PwC survey',
        unit: {
          type: 'inline-table',
        },
        table: {
          columns: [
            { key: 'base-cohort', label: 'Cohort' },
            { key: 'domain', label: 'Domain' },
            { key: 'service', label: 'Service' },
            { key: 'preference', label: 'Preference' },
          ],
          data: [],
        },
      },
    ],
  },
  cohorts: {
    key: 'cohorts',
    entries: [
      {
        key: 'default',
        label: 'Default',
        unit: {
          type: 'inline-table',
        },
        table: {
          columns: [
            { key: 'cohort', label: 'Cohort' },
            { key: 'starting-age', label: 'Starting age' },
          ],
          data: [],
        },
      },
    ],
  },
  demography: {
    key: 'demography',
    entries: [
      {
        key: 'Paramatta',
        label: 'Sydney - Parramatta',
        unit: {
          type: 'sa4',
        },
        fields: [
          {
            key: 'seed-sa4',
            label: 'Demographic (SA4)',
            value: { encoding: 'int64', value: 125 },
            group: { label: 'Census' },
          },
        ],
      },
    ],
  },
  ...settings,
});

const syntheticInstantiations = (instantiations) => ({
  'cohort-preferences': {
    key: 'cohort-preferences',
    attribute: 'cohort-preferences',
    entries: [
      {
        key: 'pwc',
        label: 'PwC survey',
        item_type: 'inline-table',
      },
    ],
  },
  cohorts: {
    key: 'cohorts',
    attribute: 'cohorts',
    entries: [
      {
        key: 'default',
        label: 'Default',
        item_type: 'inline-table',
      },
    ],
  },
  demography: {
    key: 'demography',
    attribute: 'demography',
    entries: [
      {
        key: 'Paramatta',
        label: 'Sydney - Parramatta',
        item_type: 'sa4',
      },
    ],
  },
  ...instantiations,
});

const settingByKey = curry((key, scenario) =>
  pipe(get('settings'), syntheticSettings, get(keyFor(key)))(scenario),
);

const instantiationByKey = curry((key, workspace) =>
  pipe(
    get('instantiations'),
    syntheticInstantiations,
    get(keyFor(key)),
  )(workspace),
);

const locationById = curry((id, scenario) => get(['values', id], scenario));

const isCategorySetting = pipe(attributeType, eq('usage-v2'));

const isItemSetting = pipe(attributeType, (type) =>
  includes(type, ['service-v2', 'recycled-water-system', 'rainwater-tank']),
);

const isValueSetting = (setting) =>
  !isCategorySetting(setting) && !isItemSetting(setting);

const labelOf = (attribute) => {
  const name = get('name', attribute);
  const label = get('label', attribute);
  const type = attributeType(attribute);
  if (label) return label;
  if (['usage', 'usage-v2'].includes(type)) return 'Usage';
  if (['service', 'service-v2'].includes(type)) return 'Services';
  if (['spatial', 'position', 'geography'].includes(type)) return 'Position';
  if (['solar'].includes(type)) return 'Solar';
  if (['land'].includes(type)) return 'Land area';
  if (['cohort-preferences'].includes(type)) return 'Cohort preferences';
  if (['cohorts'].includes(type)) return 'Cohorts';
  if (['demography'].includes(type)) return 'Demography';
  if (['recycled-water-system'].includes(type)) return 'Recycled water';
  if (['rainwater-tank'].includes(type)) return 'Rainwater storage';
  if (['fine-area-correspondence'].includes(type))
    return 'Fine area correspondence';
  if (['custom'].includes(type) && name) return name;
  if (['custom'].includes(type) && !name) return 'New attribute';
  return `Unknown ${type}`;
};

const eventByDate = curry((date, value) =>
  pipe(timeSeries, find({ date }))(value),
);

const supportsStages = (type) =>
  !['spatial', 'fine-area-correspondence'].includes(type);

const supportsItems = (type) =>
  ['quantified-itemised', 'selectable-itemised'].includes(
    get(type, ATTRIBUTE_TYPE_MAP),
  );

const concatIfNotNil = curry((element, array) =>
  isNil(element) ? array : array.concat([element]),
);

const findAttribute = curry((selector, location) =>
  pipe(attributes, find(selector))(location),
);

const withAttribute = curry((selector, func, location) => ({
  ...location,
  attributes: pipe(
    attributes,
    reject(selector),
    concatIfNotNil(func(pipe(attributes, find(selector))(location))),
  )(location),
}));

const emptyAttribute = (setting) => {
  const empty = {
    attribute: attributeType(setting),
    key: attributeKey(setting),
    time_series: [],
  };
  if (attributeType(setting) === 'custom') {
    empty.name = setting.name;
  }
  return empty;
};

const newStage = (setting, target) => {
  const year = target.getFullYear();
  const date = format('yyyy-MM-dd', target);
  const empty = { year, date };
  if (isCategorySetting(setting)) {
    empty.categories = [];
  }
  if (isItemSetting(setting)) {
    empty.entries = [];
  }
  // FUTURE: This is clumsy, it shouldn't be required but we don't have not-set yet.
  if (isValueSetting(setting)) {
    if (attributeType(setting) === 'custom') {
      empty.encoding = 'string';
      empty.value = '';
    } else {
      empty.fields = {};
    }
  }
  return empty;
};

const emptyStage = (setting) => {
  const stage = new Date();
  stage.setMonth(0);
  stage.setDate(1);
  return newStage(setting, stage);
};

// selectorFor:
//   return an object to use with find/filter/reject on a
//   list of attributes, that matches the specified setting.
const selectorFor = (setting) => ({
  key: setting.key,
});

const emptyIfMissing = curry((setting, current) =>
  isNil(current) ? emptyAttribute(setting) : current,
);

const atLeastOneStage = curry((setting, current) => ({
  ...current,
  time_series: isEmpty(timeSeries(current))
    ? [emptyStage(setting)]
    : timeSeries(current),
}));

const stageTemplate = curry((setting, current) => {
  const allValues = pipe(timeSeries, flatMap(flatten))(current);
  const availableItems = flatten(setting);
  const usedItems = filter(
    (item) =>
      !isNil(
        find(
          (value) =>
            value.category === item.category && value.entry === item.entry,
        )(allValues),
      ),
  )(availableItems);
  return usedItems;
});

const applyTemplate = curry((setting, template, current, stage) => {
  if (isEmpty(template)) return stage;
  const cutoff = parseISO(stage.date);
  const relevent = pipe(
    timeSeries,
    filter((s) => !isAfter(cutoff, parseISO(s.date))),
  )(current);
  if (isCategorySetting(setting)) {
    const categoryTemplate = filter((t) => isNil(t.entry), template);
    const entryTemplate = (category) =>
      filter((t) => !isNil(t.entry) && t.category === category, template);
    const findValue = (category, entry) =>
      pipe(
        flatMap((s) => {
          const c = find({ key: category }, s.categories);
          if (!c) return [];
          const e = find({ key: entry }, c.entries);
          if (!e) return [];
          if (!e.amount) return [];
          return [e.amount];
        }),
        last,
      )(relevent) || { encoding: 'float64', value: 0 };
    return {
      ...stage,
      categories: map((ct) => ({
        key: ct.category,
        entries: map(
          (t) =>
            find(
              { key: t.entry },
              get(
                'entries',
                find({ key: ct.category }, get('categories', stage)),
              ),
            ) || { key: t.entry, amount: findValue(ct.category, t.entry) },
        )(entryTemplate(ct.category)),
      }))(categoryTemplate),
    };
  }
  if (isItemSetting(setting)) {
    const findValue = (entry) =>
      pipe(
        flatMap((s) => {
          const e = find({ key: entry }, s.entries);
          if (!e) return [];
          if (!e.amount) return [];
          return [e.amount];
        }),
        last,
      )(relevent) || { encoding: 'float64', value: 0 };

    return {
      ...stage,
      entries: map(
        (t) =>
          find({ key: t.entry }, get('entries', stage)) || {
            key: t.entry,
            amount: findValue(t.entry),
          },
      )(template),
    };
  }
  return stage;
});

const applyValueConsistency = curry((setting, current, stage) => {
  if (!isValueSetting(setting) || !stage.date) return stage;
  const cutoff = parseISO(stage.date);
  const relevent = pipe(
    timeSeries,
    filter((s) => isBefore(cutoff, parseISO(s.date))),
    last,
  )(current);
  if (!relevent || !relevent.fields) return stage;
  return { ...stage, fields: { ...relevent.fields, ...stage.fields } };
});

const consistentValueStages = curry((setting, current) => ({
  ...current,
  time_series: pipe(
    timeSeries,
    map(applyValueConsistency(setting, current)),
  )(current),
}));

const consistentStages = curry((setting, current) => {
  if (isValueSetting(setting)) {
    return consistentValueStages(setting, current);
  }
  const template = stageTemplate(setting, current);
  return {
    ...current,
    time_series: pipe(
      timeSeries,
      map(applyTemplate(setting, template, current)),
    )(current),
  };
});

const orderedStages = curry((current) => ({
  ...current,
  time_series: pipe(timeSeries, sortBy(get('date')))(current),
}));

// normalise:
//   1. make sure there is an attribute for specified setting.
//   2. make sure there is at least one stage for attribute.
//   3. make sure all stages have consistent items and are sorted.
//   4. make sure the stages are in order
const normalise = curry((setting, location) =>
  pipe(
    withAttribute(selectorFor(setting), emptyIfMissing(setting)),
    withAttribute(selectorFor(setting), atLeastOneStage(setting)),
    withAttribute(selectorFor(setting), consistentStages(setting)),
    withAttribute(selectorFor(setting), orderedStages),
  )(location),
);

const removeStage = curry((setting, date, location) =>
  pipe(
    withAttribute(selectorFor(setting), (current) => ({
      ...current,
      time_series: pipe(
        timeSeries,
        filter((e) => e.date !== date),
      )(current),
    })),
    normalise(setting),
  )(location),
);

const addStage = curry((setting, location) =>
  pipe(
    withAttribute(selectorFor(setting), (current) => ({
      ...current,
      time_series: isEmpty(timeSeries(current))
        ? [emptyStage(setting)]
        : timeSeries(current).concat([
            newStage(
              setting,
              addDays(1, parseISO(last(current.time_series).date)),
            ),
          ]),
    })),
    normalise(setting),
  )(location),
);

const updateStage = curry((setting, item, amount, stage) => {
  if (isValueSetting(setting)) {
    return { ...stage, ...amount };
  }
  if (isItemSetting(setting)) {
    return {
      ...stage,
      entries: pipe(
        get('entries'),
        map((e) =>
          e.key !== item.entry
            ? e
            : {
                ...e,
                amount,
              },
        ),
      )(stage),
    };
  }
  if (isCategorySetting(setting)) {
    return {
      ...stage,
      categories: pipe(
        get('categories'),
        map((c) =>
          c.key !== item.category
            ? c
            : {
                ...c,
                entries: pipe(
                  get('entries'),
                  map((e) =>
                    e.key !== item.entry
                      ? e
                      : {
                          ...e,
                          amount,
                        },
                  ),
                )(c),
              },
        ),
      )(stage),
    };
  }
  return stage;
});

const updateItem = curry((setting, date, item, amount, location) =>
  pipe(
    withAttribute(selectorFor(setting), (current) => ({
      ...current,
      time_series: pipe(
        timeSeries,
        map((s) =>
          s.date !== date ? s : updateStage(setting, item, amount, s),
        ),
      )(current),
    })),
    normalise(setting),
  )(location),
);

const updateStructuredField = curry((setting, date, field, amount, location) =>
  pipe(
    withAttribute(selectorFor(setting), (current) => ({
      ...current,
      time_series: pipe(
        timeSeries,
        map((s) =>
          s.date !== date
            ? s
            : {
                ...s,
                fields: omitNil({
                  ...(s.fields || {}),
                  ...{
                    [field]:
                      isNil(amount.value) || isNaN(amount.value)
                        ? null
                        : amount,
                  },
                }),
              },
        ),
      )(current),
    })),
    normalise(setting),
  )(location),
);

const updatePrimitiveField = curry((setting, date, amount, location) =>
  pipe(
    withAttribute(selectorFor(setting), (current) => ({
      ...current,
      time_series: pipe(
        timeSeries,
        map((s) => (s.date !== date ? s : { ...s, ...amount })),
      )(current),
    })),
    normalise(setting),
  )(location),
);

const updateStageDate = curry((setting, currentDate, targetDate, location) =>
  pipe(
    withAttribute(selectorFor(setting), (current) => ({
      ...current,
      time_series: pipe(
        timeSeries,
        map((s) =>
          s.date !== currentDate
            ? s
            : { ...s, date: targetDate, year: getYear(parseISO(targetDate)) },
        ),
      )(current),
    })),
    normalise(setting),
  )(location),
);

const addItemToStage = curry((setting, item, stage) => {
  if (isItemSetting(setting)) {
    return {
      ...stage,
      entries: stage.entries.concat([
        {
          key: item.entry,
          amount: { encoding: 'float64', value: 0 },
        },
      ]),
    };
  }
  if (isCategorySetting(setting)) {
    const patchedCategories = !isNil(
      find({ key: item.category }, stage.categories),
    )
      ? stage.categories
      : stage.categories.concat([
          {
            key: item.category,
            entries: [],
          },
        ]);
    return {
      ...stage,
      categories: pipe(
        map((c) =>
          c.key !== item.category
            ? c
            : {
                ...c,
                entries: c.entries.concat([
                  {
                    key: item.entry,
                    amount: { encoding: 'float64', value: 0 },
                  },
                ]),
              },
        ),
      )(patchedCategories),
    };
  }
  return stage;
});

const addItemToAllStages = curry((setting, item, location) =>
  pipe(
    withAttribute(selectorFor(setting), (current) => ({
      ...current,
      time_series: pipe(
        timeSeries,
        map(addItemToStage(setting, item)),
      )(current),
    })),
    normalise(setting),
  )(location),
);

const replaceAttribute = curry((setting, replacement, location) =>
  pipe(
    withAttribute(selectorFor(setting), () => replacement),
    normalise(setting),
  )(location),
);

const itemExists = curry((setting, item, location) =>
  pipe(
    findAttribute(selectorFor(setting)),
    timeSeries,
    flatMap(flatten),
    some((i) => item.category === i.category && item.entry === i.entry),
  )(location),
);

const ensureItem = curry((setting, item, location) =>
  itemExists(setting, item, location)
    ? location
    : addItemToAllStages(setting, item, location),
);

const ensureItems = curry((setting, items_, location) =>
  reduce(
    (accumulator, item) => ensureItem(setting, item, accumulator),
    location,
    items_,
  ),
);

const mergeInstance = curry((newInstance, currentInstance) => {
  const newEntries = has('entries', currentInstance)
    ? get('entries', currentInstance).concat(
        pipe(
          get('entries'),
          reject((e) =>
            has(e.key, keyBy('key', get('entries', currentInstance))),
          ),
        )(newInstance),
      )
    : undefined;

  const newCategories = has('categories', currentInstance)
    ? pipe(
        get('categories'),
        map((category) => ({
          ...category,
          entries: get('entries', category).concat(
            pipe(
              get('categories'),
              find({ key: get('key', category) }),
              get('entries'),
              defaultTo([]),
              reject((e) => has(e.key, keyBy('key', get('entries', category)))),
            )(newInstance),
          ),
        })),
      )(currentInstance).concat(
        pipe(
          get('categories'),
          reject((e) =>
            has(e.key, keyBy('key', get('categories', currentInstance))),
          ),
        )(newInstance),
      )
    : undefined;
  return omitNil({
    ...currentInstance,
    entries: newEntries,
    categories: newCategories,
  });
});

// Add items in newDefinition to currentDefinition, iff they don't exist.
const mergeDefinition = curry((newDefinition, currentDefinition) => {
  const newEntries = has('entries', currentDefinition)
    ? get('entries', currentDefinition).concat(
        pipe(
          get('entries'),
          reject((e) =>
            has(e.key, keyBy('key', get('entries', currentDefinition))),
          ),
        )(newDefinition),
      )
    : undefined;

  const newCategories = has('categories', currentDefinition)
    ? pipe(
        get('categories'),
        map((category) => ({
          ...category,
          entries: get('entries', category).concat(
            pipe(
              get('categories'),
              find({ key: get('key', category) }),
              get('entries'),
              defaultTo([]),
              reject((e) => has(e.key, keyBy('key', get('entries', category)))),
            )(newDefinition),
          ),
        })),
      )(currentDefinition).concat(
        pipe(
          get('categories'),
          reject((e) =>
            has(e.key, keyBy('key', get('categories', currentDefinition))),
          ),
        )(newDefinition),
      )
    : undefined;
  return omitNil({
    ...currentDefinition,
    entries: newEntries,
    categories: newCategories,
  });
});

const ensureSettingItem = curry((item, field, template, setting) =>
  omitNil({
    ...setting,
    entries: has('entries', setting)
      ? pipe(
          get('entries'),
          map((e) =>
            e.key !== item
              ? e
              : {
                  ...e,
                  fields: pipe(get('fields'), (fs) =>
                    isNil(find({ key: field }, fs))
                      ? fs.concat([template])
                      : fs,
                  )(e),
                },
          ),
        )(setting)
      : undefined,
    categories: has('categories', setting)
      ? pipe(
          get('categories'),
          map((e) =>
            e.key !== item
              ? {
                  ...e,
                  entries: pipe(
                    get('entries'),
                    map((ee) =>
                      ee.key !== item
                        ? ee
                        : {
                            ...ee,
                            fields: pipe(get('fields'), (fs) =>
                              isNil(find({ key: field }, fs))
                                ? fs.concat([template])
                                : fs,
                            )(ee),
                          },
                    ),
                  )(e),
                }
              : {
                  ...e,
                  fields: pipe(get('fields'), (fs) =>
                    isNil(find({ key: field }, fs))
                      ? fs.concat([template])
                      : fs,
                  )(e),
                },
          ),
        )(setting)
      : undefined,
  }),
);

const replaceSettingItemKey = curry((item, field, newField, setting) =>
  keyToSettingFamily(field) !== newField // we only replace the field with the newField if the newField is the defauly family of the field
    ? setting
    : omitNil({
        ...setting,
        entries: has('entries', setting)
          ? pipe(
              get('entries'),
              map((e) =>
                e.key !== item
                  ? e
                  : {
                      ...e,
                      fields: pipe(
                        get('fields'),
                        map((f) =>
                          f.key !== field ? f : { ...f, key: newField },
                        ),
                      )(e),
                    },
              ),
            )(setting)
          : undefined,
        categories: has('categories', setting)
          ? pipe(
              get('categories'),
              map((e) =>
                e.key !== item
                  ? {
                      ...e,
                      entries: pipe(
                        get('entries'),
                        map((ee) =>
                          ee.key !== item
                            ? ee
                            : {
                                ...ee,
                                fields: pipe(
                                  get('fields'),
                                  map((f) =>
                                    f.key !== field
                                      ? f
                                      : { ...f, key: newField },
                                  ),
                                )(ee),
                              },
                        ),
                      )(e),
                    }
                  : {
                      ...e,
                      fields: pipe(
                        get('fields'),
                        map((f) =>
                          f.key !== field ? f : { ...f, key: newField },
                        ),
                      )(e),
                    },
              ),
            )(setting)
          : undefined,
      }),
);

const ensureTensorSettingItem = ensureSettingItem;
const replaceTensorSettingItemKey = replaceSettingItemKey;

const setItemDefinitionType = curry((key, type, setting) =>
  omitNil({
    ...setting,
    entries: has('entries', setting)
      ? pipe(
          get('entries'),
          map((ee) =>
            ee.key !== key
              ? ee
              : {
                  ...ee,
                  unit: { type },
                  fields: [],
                },
          ),
        )(setting)
      : undefined,
    categories: has('categories', setting)
      ? pipe(
          get('categories'),
          map((e) => ({
            ...e,
            entries: pipe(
              get('entries'),
              map((ee) =>
                ee.key !== key
                  ? ee
                  : {
                      ...ee,
                      unit: { type },
                      fields: [],
                    },
              ),
            )(e),
          })),
        )(setting)
      : undefined,
  }),
);

const newItemsPatch = (setting) => {
  const isNewItem = (e) => e.newItem;
  const cs = flatMap(
    (c) =>
      some(isNewItem, get('entries', c))
        ? [{ ...c, entries: filter(isNewItem, get('entries', c)) }]
        : [],
    get('categories', setting),
  );
  const es = filter((e) => e.newItem, get('entries', setting));
  return !isEmpty(es) || !isEmpty(cs)
    ? omitNil({
        ...setting,
        categories: isEmpty(cs) ? undefined : cs,
        entries: isEmpty(es) ? undefined : es,
      })
    : {};
};

const editedLabelsPatch = (instantiation) => {
  const isEditedLabel = (e) => e.editedLabel;
  const cs = pipe(
    filter((c) => isEditedLabel(c) || some(isEditedLabel, get('entries', c))),
    map(({ key, label, entries: es }) => ({
      key,
      label,
      entries: filter(isEditedLabel, es),
    })),
  )(get('categories', instantiation));
  const es = filter(isEditedLabel, get('entries', instantiation));
  return !isEmpty(es) || !isEmpty(cs)
    ? omitNil({
        attribute: attributeType(instantiation),
        key: attributeKey(instantiation),
        categories: isEmpty(cs) ? undefined : cs,
        entries: isEmpty(es) ? undefined : es,
      })
    : {};
};

const addItemToInstantiationWithCategory = curry(
  (category, key, instantiation, item_type) =>
    omitNil({
      ...instantiation,
      categories: has('categories', instantiation)
        ? pipe(
            get('categories'),
            map((e) =>
              e.key === category
                ? {
                    ...e,
                    entries: get('entries', e).concat({
                      key,
                      newItem: true,
                      label: '',
                      item_type,
                    }),
                  }
                : e,
            ),
          )(instantiation)
        : undefined,
    }),
);

const mapInstanceItem = curry((key, f, instance) =>
  omitNil({
    ...instance,
    categories: has('categories', instance)
      ? pipe(
          get('categories'),
          map((c) => ({
            ...c,
            entries: map(f, get('entries', c)),
          })),
        )(instance)
      : undefined,
    entries: has('entries', instance)
      ? map(f, get('entries', instance))
      : undefined,
  }),
);

const updateItemType = curry((key, itemType, instance) =>
  mapInstanceItem(
    key,
    (e) => (e.key === key ? set('item_type', itemType, e) : e),
    instance,
  ),
);

// Sort an array of values, each with a key field, using the ordering of
// related keys in the given instance. The ordering is maintained if there is
// no matching key in the instance.
const sortByInstance = curry((instance, keyedValues) =>
  pipe(flatten, (itemsAndCategories) =>
    sortBy(
      (keyedValue) => findIndex({ key: keyedValue.key }, itemsAndCategories),
      keyedValues,
    ),
  )(instance),
);

const addItemToDefinitionWithCategory = curry((category, key, setting, unit) =>
  omitNil({
    ...setting,
    categories: has('categories', setting)
      ? pipe(
          get('categories'),
          map((e) =>
            e.key === category
              ? {
                  ...e,
                  entries: get('entries', e).concat({
                    key,
                    newItem: true,
                    label: '',
                    fields: [],
                    unit,
                  }),
                }
              : e,
          ),
        )(setting)
      : undefined,
  }),
);

const updateSettingItem = curry((item, field, value, setting) =>
  omitNil({
    ...setting,
    entries: has('entries', setting)
      ? pipe(
          get('entries'),
          map((e) =>
            e.key !== item
              ? e
              : {
                  ...e,
                  fields: pipe(
                    get('fields'),
                    map((f) =>
                      f.key !== field
                        ? f
                        : {
                            ...f,
                            value,
                          },
                    ),
                  )(e),
                },
          ),
        )(setting)
      : undefined,
    categories: has('categories', setting)
      ? pipe(
          get('categories'),
          map((e) =>
            e.key !== item
              ? {
                  ...e,
                  entries: pipe(
                    get('entries'),
                    map((ee) =>
                      ee.key !== item
                        ? ee
                        : {
                            ...ee,
                            fields: pipe(
                              get('fields'),
                              map((f) =>
                                f.key !== field
                                  ? f
                                  : {
                                      ...f,
                                      value,
                                    },
                              ),
                            )(ee),
                          },
                    ),
                  )(e),
                }
              : {
                  ...e,
                  fields: pipe(
                    get('fields'),
                    map((f) =>
                      f.key !== field
                        ? f
                        : {
                            ...f,
                            value,
                          },
                    ),
                  )(e),
                },
          ),
        )(setting)
      : undefined,
  }),
);

const upsertTensorField = curry((key, value, fieldValue) =>
  update(
    'value',
    isNil(find({ key }, get('value', fieldValue)))
      ? concat({ key, value })
      : map((v) => (isEqual(v.key, key) ? { ...v, value } : v)),
    fieldValue,
  ),
);

const mapSettingItemField = curry((item, f, setting) =>
  omitNil({
    ...setting,
    entries: has('entries', setting)
      ? pipe(
          get('entries'),
          map((e) =>
            e.key !== item
              ? e
              : {
                  ...e,
                  fields: pipe(get('fields'), map(f))(e),
                },
          ),
        )(setting)
      : undefined,
    categories: has('categories', setting)
      ? pipe(
          get('categories'),
          map((e) =>
            e.key !== item
              ? {
                  ...e,
                  entries: pipe(
                    get('entries'),
                    map((ee) =>
                      ee.key !== item
                        ? ee
                        : {
                            ...ee,
                            fields: pipe(get('fields'), map(f))(ee),
                          },
                    ),
                  )(e),
                }
              : e,
          ),
        )(setting)
      : undefined,
  }),
);

const updateTensorSettingItem = curry((item, field, tensor, value, setting) =>
  mapSettingItemField(
    item,
    (f) =>
      f.key !== field
        ? f
        : {
            ...f,
            value: pipe(get('value'), upsertTensorField(tensor, value))(f),
          },
    setting,
  ),
);

const relabelItem = curry((item, label, setting) =>
  omitNil({
    ...setting,
    entries: has('entries', setting)
      ? pipe(
          get('entries'),
          map((e) =>
            e.key !== item
              ? e
              : {
                  ...e,
                  label,
                  editedLabel: true,
                },
          ),
        )(setting)
      : undefined,
    categories: has('categories', setting)
      ? pipe(
          get('categories'),
          map((e) =>
            e.key !== item
              ? {
                  ...e,
                  entries: pipe(
                    get('entries'),
                    map((ee) =>
                      ee.key !== item
                        ? ee
                        : {
                            ...ee,
                            label,
                            editedLabel: true,
                          },
                    ),
                  )(e),
                }
              : {
                  ...e,
                  label,
                  editedLabel: true,
                },
          ),
        )(setting)
      : undefined,
  }),
);

const distributionRows = (category, instantiation) => {
  const labels = {
    'floor-space-and-dwellings': 'Residential',
    'floor-space': 'Non-residential',
  };

  const distributionOfTypes = pipe(
    get(['fields']),
    keyBy('key'),
    get(['distribution-of-types', 'value', 'fields']),
  )(category);
  const distributionOfFloorSpace = pipe(
    get(['fields']),
    keyBy('key'),
    get(['distribution-of-values-floor-space', 'value', 'fields']),
  )(category);
  const distributionOfFloorSpaceDwellings = pipe(
    get(['fields']),
    keyBy('key'),
    get([
      'distribution-of-values-floor-space-and-dwellings',
      'value',
      'fields',
    ]),
  )(category);
  const distributions = {
    'floor-space-and-dwellings': distributionOfFloorSpaceDwellings,
    'floor-space': distributionOfFloorSpace,
  };

  const instanceValues = pipe(flatten, keyBy('key'))(instantiation);

  const rowData = flatMap(
    (type) => {
      const empty = isEmpty(
        entries(category).filter(
          (item) => get(['unit', 'type'], item) === type,
        ),
      );
      if (empty) return [];
      const typeRow = {
        type: 'type',
        label: get(type, labels),
        value: (get([type, 'value'], distributionOfTypes) || 0) * 100,
        typeValue: type,
      };
      const itemRows = entries(category)
        .filter((item) => get(['unit', 'type'], item) === type)
        .map((item) => ({
          type: 'item',
          label: get([item.key, 'label'], instanceValues) || 'New item',
          value: (get([type, item.key, 'value'], distributions) || 0) * 100,
          itemValue: item,
          typeValue: type,
        }));
      const total = sum(map('value', itemRows));
      const totalRow = {
        type: 'item-total',
        value: total,
        error: total !== 100,
        category,
      };
      return [typeRow].concat(itemRows).concat([totalRow]);
    },
    ['floor-space-and-dwellings', 'floor-space'],
  );

  const typeTotal = pipe(filter({ type: 'type' }), map('value'), sum)(rowData);

  return rowData.concat([
    {
      type: 'type-total',
      value: typeTotal,
      error: typeTotal !== 100,
      category,
    },
  ]);
};

const curatedAttributeOrdering = [
  'geography',
  'spatial',
  'land',
  'usage-v2',
  'service-v2',
  'solar',
  'rainwater-tank',
  'recycled-water-system',
  'demography',
  'cohort-preferences',
  'fine-area-correspondence',
  'custom',
];

const attributeCompare = curry((attributeOrdering, a1, a2) => {
  const t1 = attributeType(a1);
  const t2 = attributeType(a2);
  const o1 = findIndex(eq(t1), attributeOrdering);
  const o2 = findIndex(eq(t2), attributeOrdering);
  const cmp = o1 - o2;
  if (cmp > 0) {
    return 1;
  }
  if (cmp < 0) {
    return -1;
  }
  return 0;
});

const customAttributeCompare = (a1, a2) => {
  const n1 = get('name', a1);
  const n2 = get('name', a2);
  if (n1 > n2) {
    return 1;
  }
  if (n1 < n2) {
    return -1;
  }
  return 0;
};

const immutableSort = curry((f, arr) => arr.slice().sort(f));

const sortAttributesBy = curry((lens, input) => {
  const keyedAttributeCompare = (a1, a2) =>
    attributeCompare(curatedAttributeOrdering, lens(a1), lens(a2));
  const keyedCustomAttributeCompare = (a1, a2) =>
    customAttributeCompare(lens(a1), lens(a2));
  const isCustom = (x) => attributeType(lens(x)) === 'custom';
  const isUnknown = (x) =>
    !curatedAttributeOrdering.includes(attributeType(lens(x)));
  const nonCustom = filter(negate(isCustom), input);
  const unknowns = filter(isUnknown, input);
  const custom = filter(isCustom, input);
  return pipe(
    concat(unknowns),
    concat(immutableSort(keyedAttributeCompare, nonCustom)),
  )(immutableSort(keyedCustomAttributeCompare, custom));
});

const sortAttributes = sortAttributesBy(identity);

export {
  attributes,
  categories,
  categoryByKey,
  fields,
  hasEntries,
  entries,
  eventByDate,
  items,
  itemByKey,
  keyOf,
  labelOf,
  locationById,
  settingByKey,
  instantiationByKey,
  supportsItems,
  supportsStages,
  updateItem,
  updateStageDate,
  flatten,
  addItemToAllStages,
  removeStage,
  addStage,
  normalise,
  withAttribute,
  emptyAttribute,
  ensureItem,
  ensureItems,
  replaceAttribute,
  updateStructuredField,
  updatePrimitiveField,
  ensureSettingItem,
  updateSettingItem,
  relabelItem,
  selectorFor,
  mergeDefinition,
  addItemToDefinitionWithCategory,
  addItemToInstantiationWithCategory,
  setItemDefinitionType,
  newItemsPatch,
  distributionRows,
  editedLabelsPatch,
  sortAttributes,
  sortAttributesBy,
  attributeKey,
  attributeType,
  mergeInstance,
  keyFor,
  ensureTensorSettingItem,
  updateTensorSettingItem,
  upsertTensorField,
  updateItemType,
  mapInstanceItem,
  sortByInstance,
  replaceSettingItemKey,
  replaceTensorSettingItemKey,
};
