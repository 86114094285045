import { cond, curry, identity, pick, pipe, stubTrue, values } from 'lodash/fp';

const ifElse = curry((condition, success, failure) =>
  cond([
    [condition, success],
    [stubTrue, failure],
  ]),
);

const when = curry((condition, success) =>
  ifElse(condition, success, identity),
);

const unless = curry((condition, failure) =>
  ifElse(condition, identity, failure),
);

const pickToArray = curry((cols, obj) => pipe(pick(cols), values)(obj));

export { ifElse, pickToArray, unless, when };
