import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, H3, Icon, Subheading } from '@kinesis/bungle';

const AppCardWrapper = styled.section`
  display: flex;
  align-items: center;
  padding: 4px 0;
`;

const OrganisationAvatarWrapper = styled.div`
  position: relative;
  margin-right: 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: white;
  position: absolute;
  right: -5px;
  bottom: -5px;
  z-index: 100;
  color: ${({ status, theme }) =>
    status === 'success' ? theme.color.green6 : theme.color.yellow6};
`;

const propTypes = {
  label: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  publisher: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['success', 'info', undefined]),
};

const defaultProps = {
  status: undefined,
};

const AppCard = ({ label, publisher, logo, status }) => (
  <AppCardWrapper>
    <OrganisationAvatarWrapper>
      <Avatar
        alt={`${publisher} logo`}
        image={`data:image/png;base64,${logo}`}
        magnitude='medium'
        variant='organisation'
      >
        {publisher.slice(0, 1)}
      </Avatar>
      {status && (
        <IconWrapper status={status}>
          <Icon
            type={status === 'success' ? 'check-circle' : 'exclamation-circle'}
            magnitude='small'
          />
        </IconWrapper>
      )}
    </OrganisationAvatarWrapper>
    <div>
      <H3>{label}</H3>
      <Subheading>{publisher}</Subheading>
    </div>
  </AppCardWrapper>
);

AppCard.defaultProps = defaultProps;
AppCard.propTypes = propTypes;

export default AppCard;
