import {
  findIndex,
  zip,
  defaultTo,
  concat,
  map,
  flatMap,
  isNaN,
  negate,
  isNil,
  get,
  some,
  find,
  pipe,
  first,
  curry,
  filter,
  toPairs,
  every,
  any,
  isEqual,
  reduce,
  omitBy,
  fromPairs,
} from 'lodash/fp';
import {
  maximumOf,
  minimumOf,
  numericDefaultOf,
  optionsOf,
  typeOf,
  tensorDefaultOf,
  fillValueOf,
  labelsOf,
  dimensionLabelsOf,
  attributeKey,
  isTensor,
  unitLabel,
  unitType,
  findByFamily,
  valueFromField,
} from 'data/settings';
import { keyFor, flatten } from 'data/attributes';
import Units from 'data/units';

const units = new Units();

const formatter = (type) =>
  type ? units.parseColumn({ unit: { type } }).formatCell : undefined;

const symbol = (type) =>
  type ? units.parseColumn({ unit: { type } }).symbol : undefined;

const alignment = (type) =>
  type ? units.parseColumn({ unit: { type } }).align : undefined;

const omitNil = omitBy(isNil);

const foldLabelPair = curry(
  (f, labelPair) => labelPair && f(first(toPairs(labelPair))),
);

const isItem = pipe(get('entry'), negate(isNil));

const findInstance = curry((attributeFamily, instances) =>
  find((a) => keyFor(a.key) === attributeKey(attributeFamily), instances),
);

const isCategory = (selectedEntry) =>
  isNil(get('entry', selectedEntry)) && !isNil(get('category', selectedEntry));

const checkItemConstraints = curry((selectedEntry, itemConstraints) =>
  pipe(toPairs, first, (constraint) => {
    if (!constraint) return false;
    const [key, value] = constraint;
    const item_type = get(['unit', 'type'], selectedEntry);
    switch (key) {
      case 'item-type-is':
        return value === item_type;
      case 'item-type-is-not':
        return value !== item_type;
      case 'all':
        return every(checkItemConstraints(selectedEntry), value);
      case 'any':
        return some(checkItemConstraints(selectedEntry), value);
      default:
        throw new Error(`Unknown item constraint type: ${key}`);
    }
  })(itemConstraints),
);

const findOptionDefault = (validOptions) =>
  reduce(
    (acc, option) =>
      (!isNil(option.priority) && isNil(acc.priority)) ||
      (!isNil(option.priority) &&
        !isNil(acc.priority) &&
        option.priority < acc.priority)
        ? option
        : acc,
    first(validOptions),
    validOptions,
  );

const checkSettingHasValue = (condition, settingValue) =>
  pipe(toPairs, first, (constraint) => {
    if (!constraint) return false;
    const [conditionType, conditionValue] = constraint;
    switch (conditionType) {
      case 'string-eq':
      case 'int64-eq':
        return conditionValue === settingValue;
      case 'string-neq':
      case 'int64-neq':
        return conditionValue !== settingValue;
      case 'float64-greater-than':
      case 'int64-greater-than':
        return settingValue > conditionValue;
      case 'float64-less-than':
      case 'int64-less-than':
        return settingValue < conditionValue;
      case 'string-is-in':
        return any(isEqual(settingValue), conditionValue);
      case 'string-not-in':
        return every(negate(isEqual(settingValue)), conditionValue);
      default:
        throw new Error(`Unknown settingHasValue type: ${conditionType}`);
    }
  })(condition);

const checkSettingConstraints = curry(
  (specifications, selectedEntry, findScalarValue, settingConstraints) =>
    pipe(toPairs, first, (constraint) => {
      if (!constraint) return false;
      const [key, value] = constraint;
      switch (key) {
        case 'setting-has-value':
          return checkSettingHasValue(
            value.condition,
            findScalarValue(specifications, value.key, selectedEntry),
          );
        case 'all':
          return every(
            checkSettingConstraints(
              specifications,
              selectedEntry,
              findScalarValue,
            ),
            value,
          );
        case 'any':
          return some(
            checkSettingConstraints(
              specifications,
              selectedEntry,
              findScalarValue,
            ),
            value,
          );
        default:
          throw new Error(`Unknown setting constraint type: ${key}`);
      }
    })(settingConstraints),
);

const checkConstraints = curry(
  (specifications, selectedEntry, findScalarValue, optionOrSpecification) =>
    optionOrSpecification &&
    every(([key, value]) => {
      switch (key) {
        case 'item-constraint':
          return checkItemConstraints(selectedEntry, value);
        case 'setting-constraint':
          return checkSettingConstraints(
            specifications,
            selectedEntry,
            findScalarValue,
            value,
          );
        default:
          throw new Error(`Unknown constraint type: ${key}`);
      }
    }, toPairs(optionOrSpecification.constraints)),
);

const getItemListKeys = (labelValue, instances) => {
  const instance = findInstance(get('attribute', labelValue), instances);
  const itemType = get('item_type', labelValue);
  return pipe(
    flatten,
    flatMap((i) =>
      isItem(i) && (!itemType || get('item_type', i) === itemType)
        ? [i.key]
        : [],
    ),
  )(instance);
};

const getTensorKeys = (allLabels, instances) =>
  reduce(
    (acc, labels) => {
      const [labelType, labelValue] = first(toPairs(labels));
      switch (labelType) {
        case 'string-list':
          return flatMap((a) => map(concat(a), labelValue), acc);
        case 'item-list':
          return flatMap(
            (a) => map(concat(a), getItemListKeys(labelValue, instances)),
            acc,
          );
        default:
          throw new Error(`Unknown tensor label type: ${labelType}`);
      }
    },
    [[]],
    allLabels,
  );

const isStringList = foldLabelPair(
  (labelPair) => first(labelPair) === 'string-list',
);

const defaultTensorKey = (key, specification) =>
  pipe(
    zip(labelsOf(specification)),
    flatMap(([l, k]) => (isStringList(l) ? [k] : [])),
  )(key);

const findTensorValue = (specification, selectedEntry, instances) => {
  if (!isTensor(specification)) return undefined;

  return map(
    (key) => {
      const findValueByKey = curry((k, values) =>
        pipe(find(pipe(get('key'), isEqual(k))), get('value'))(values),
      );
      const value = pipe(
        findValueByKey(key),
        defaultTo(
          findValueByKey(
            defaultTensorKey(key, specification),
            tensorDefaultOf(specification),
          ),
        ),
        defaultTo(fillValueOf(specification)),
      )(valueFromField(selectedEntry, specification.family));
      return { key, value };
    },
    getTensorKeys(labelsOf(specification), instances),
  );
};

const formatTensorColumn = curry(
  (specification, instances, dimensionLabel, tensorKey) =>
    pipe(
      (s) =>
        get(
          findIndex(isEqual(dimensionLabel), dimensionLabelsOf(s)),
          labelsOf(s),
        ),
      foldLabelPair(([labelType, labelValue]) => {
        switch (labelType) {
          case 'item-list':
            return pipe(
              findInstance(get('attribute', labelValue)),
              flatten,
              find({ key: tensorKey }),
              (item) => get('label', item) || 'New item',
            )(instances);
          case 'string-list':
            return tensorKey;
          default:
            return undefined;
        }
      }),
    )(specification),
);

const toTensorGridColumns = (specification, instances) => {
  if (!isTensor(specification)) return undefined;

  const fmt = formatTensorColumn(specification, instances);
  const unitLabelOrSymbol =
    unitLabel(specification) || symbol(unitType(specification));

  return concat(
    map(
      (d) => ({ dataKey: d, label: d, format: fmt(d) }),
      dimensionLabelsOf(specification),
    ),
    omitNil({
      dataKey: 'value',
      label: unitLabelOrSymbol ? `Value (${unitLabelOrSymbol})` : 'Value',
      dataType: 'number',
      editable: true,
      format: formatter(unitType(specification)),
      align: alignment(unitType(specification)),
      max: maximumOf(specification),
      min: minimumOf(specification),
    }),
  );
};

const toTensorGridData = (specification, selectedEntry, instances) => {
  if (!isTensor(specification)) return undefined;

  const columnValues = (tensorValue) =>
    pipe(
      (s) => zip(dimensionLabelsOf(s), tensorValue.key),
      fromPairs,
      (details) => ({ ...details, ...tensorValue }),
    )(specification);

  return map(
    columnValues,
    findTensorValue(specification, selectedEntry, instances),
  );
};

const findScalarValue = (specifications, family, selectedEntry) => {
  const specification = find({ family }, specifications);
  if (!specification) return undefined;
  const setValue = valueFromField(selectedEntry, specification.family);
  switch (typeOf(specification)) {
    case 'numeric':
      return !isNil(setValue) ? setValue : numericDefaultOf(specification);
    case 'select':
      return pipe(
        filter(
          checkConstraints(specifications, selectedEntry, findScalarValue),
        ),
        (validOptions) => {
          const setValueOption = find(
            (option) => option.value === setValue,
            validOptions,
          );
          if (isNil(setValue) || isNil(setValueOption))
            return findOptionDefault(validOptions);
          return setValueOption;
        },
        get('value'),
      )(optionsOf(specification));
    default:
      return undefined;
  }
};

const validOptions = (selectedEntry, specifications, options) =>
  filter(
    checkConstraints(specifications, selectedEntry, findScalarValue),
    options,
  );

const validSettings = (selectedEntry, specifications) =>
  filter((specification) => {
    if (!selectedEntry) return false;
    if (!isItem(selectedEntry)) return false;
    if (isNil(get(['unit', 'type'], selectedEntry))) return false;
    if (
      specification.deprecated &&
      isNil(findByFamily(specification.family, selectedEntry.fields))
    )
      return false;

    return checkConstraints(
      specifications,
      selectedEntry,
      findScalarValue,
      specification,
    );
  }, specifications);

const valueInBounds = (specification, value) => {
  switch (typeOf(specification)) {
    case 'tensor':
    case 'numeric': {
      if (isNaN(value) || isNil(value)) return false;
      const minimum = minimumOf(specification);
      const maximum = maximumOf(specification);
      return (
        (isNil(minimum) || minimum <= value) &&
        (isNil(maximum) || maximum >= value)
      );
    }
    default:
      return true;
  }
};

export {
  validSettings,
  findScalarValue,
  findTensorValue,
  isItem,
  isCategory,
  valueInBounds,
  validOptions,
  checkSettingHasValue,
  checkSettingConstraints,
  checkItemConstraints,
  checkConstraints,
  getTensorKeys,
  toTensorGridColumns,
  toTensorGridData,
  formatTensorColumn,
  valueFromField,
};
