import { visualisationQuery } from 'actions/visualisationQuery';
import { appAdd } from 'actions/appAdd';
import { locationCreate } from 'actions/locationCreate';
import { appsUpgrade } from 'actions/appsUpgrade';
import { blockInitialiseStaticVis } from 'actions/blockInitialiseStaticVis';
import { blockUpdateDetails } from 'actions/blockUpdateDetails';
import { blockAdd } from 'actions/blockAdd';
import { blockValueCreate } from 'actions/blockValueCreate';
import { boardCreate } from 'actions/boardCreate';
import { blocksReorder } from 'actions/blocksReorder';
import { dataFetch } from 'actions/dataFetch';
import { rowBreak } from 'utils/blockVersions';
import { blockProgress } from 'actions/blockProgress';
import { scenarioDelete } from 'actions/scenarioDelete';
import { scenarioSend } from 'actions/scenarioSend';
import { normalizeColumns } from 'data/levels';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { tagUpdate } from 'actions/tagUpdate';
import {
  blockProcessingStates,
  getActiveVersion,
  queryStates,
} from 'data/block';
import { createSlice } from '@reduxjs/toolkit';
import {
  assign,
  mapValues,
  set,
  has,
  update,
  pipe,
  keyBy,
  flatMap,
  map,
  get,
  reduce,
  omit,
  every,
  filter,
  isNil,
  groupBy,
  toPairs,
  some,
  identity,
  pick,
  curry,
  unset,
  fromPairs,
} from 'lodash/fp';
import { nanoid } from 'nanoid';

const initialiseBlock = (block, capsule) => ({
  permalink: block.permalink,
  id: block.permalink,
  key: block.permalink,
  version: block.version,
  source: block.source,
  dependencies: [],
  filters: {},
  scenarios: {},
  schema: {},
  columns: [],

  // deprecated, will eventually go.
  table: get([get('visualisation', capsule), 'query', 'from'], capsule),
  hasInitialised: !get([get('visualisation', capsule), 'query'], capsule),
  requiresScenario: !!get([get('visualisation', capsule), 'query'], capsule),
  isFetching: false,
});

const processBlocks = (state, payload) =>
  reduce(
    (accumulator, block) =>
      has(block.permalink, accumulator)
        ? accumulator
        : set(
            block.permalink,
            initialiseBlock(
              block,
              get(block.version, keyBy('address', payload.capsules)),
            ),
            accumulator,
          ),
    state,
    payload.blocks,
  );

const discardPerspective = (scenarioId, blocks) =>
  mapValues(
    pipe(
      unset(['scenarios', scenarioId]),
      update(
        ['scenarios'],
        pipe(
          toPairs,
          flatMap(([key, perspective]) =>
            has(['scenarioKeys', scenarioId], perspective)
              ? []
              : [[key, perspective]],
          ),
          fromPairs,
        ),
      ),
    ),
    blocks,
  );

const discardUnselectedPerspectives = curry((blockId, scenarioId, state) =>
  update([blockId, 'scenarios'], pick(scenarioId), state),
);

const unsetLastQueried = (blockId) =>
  update([blockId, 'scenarios'], mapValues(omit('lastQueried')));

const discardAllPerspectives = (blockId, state) =>
  unset([blockId, 'scenarios'], state);

const updateDataVersion = curry(
  (blockId, requestId, newCapsuleVersion, currentState) =>
    reduce(
      (acc, [scenarioId, scenario]) => {
        if (get('dataVersion', scenario) === requestId) {
          return set(
            [blockId, 'scenarios', scenarioId, 'dataVersion'],
            newCapsuleVersion,
            acc,
          );
        }
        return acc;
      },
      currentState,
      toPairs(get([blockId, 'scenarios'], currentState)),
    ),
);

const { reducer, actions } = createSlice({
  name: 'blocks',
  initialState: {},

  reducers: {
    setVisualisationContext: (state, action) => {
      const { blockId, key, value } = action.payload;
      return update(
        blockId,
        (block) => ({
          ...block,
          filters: { ...block.filters, [key]: value },
          scenarios: mapValues(
            (scenario) =>
              pipe(
                set('isFresh', false),
                set(
                  ['state', 'block'],
                  get('data', scenario)
                    ? blockProcessingStates.LOADING_STALE
                    : blockProcessingStates.LOADING_INITIAL,
                ),
                set(['state', 'query'], queryStates.INITIAL),
              )(scenario),
            block.scenarios,
          ),
        }),
        state,
      );
    },
    setVisualisationDimension: (state, action) => {
      const { blockId, key, value } = action.payload;
      return update(
        blockId,
        (block) => ({
          ...block,
          filters: { ...block.filters, [key]: value },
          scenarios: mapValues(
            (scenario) =>
              pipe(
                set('isFresh', false),
                set(
                  ['state', 'block'],
                  get('data', scenario)
                    ? blockProcessingStates.LOADING_STALE
                    : blockProcessingStates.LOADING_INITIAL,
                ),
                set(['state', 'query'], queryStates.INITIAL),
              )(scenario),
            block.scenarios,
          ),
        }),
        state,
      );
    },
  },

  extraReducers: (builder) => {
    builder.addCase(workspaceFetch.fulfilled, (state, action) =>
      pipe(
        flatMap('blocks'),
        map((block) =>
          initialiseBlock(
            block,
            get(block.version, keyBy('address', action.payload.blocks)),
          ),
        ),
        keyBy('permalink'),
      )(action.payload.boards),
    );

    builder.addCase(workspacePatch.fulfilled, (state, action) =>
      pipe(
        flatMap('blocks'),
        map((block) =>
          initialiseBlock(
            block,
            get(block.version, keyBy('address', action.payload.blocks)),
          ),
        ),
        keyBy('permalink'),
      )(action.payload.boards),
    );

    builder.addCase(appAdd.fulfilled.type, (state, action) =>
      pipe(
        flatMap('blocks'),
        map((block) =>
          initialiseBlock(
            block,
            get(block.version, keyBy('address', action.payload.blocks)),
          ),
        ),
        keyBy('permalink'),
        assign(state),
      )(action.payload.boards),
    );

    builder.addCase(appsUpgrade.fulfilled.type, (state, action) =>
      pipe(
        flatMap('blocks'),
        map((block) =>
          initialiseBlock(
            block,
            get(block.version, keyBy('address', action.payload.blocks)),
          ),
        ),
        keyBy('permalink'),
        assign(state),
      )(action.payload.boards),
    );

    builder.addCase(blockAdd.fulfilled.type, (state, action) =>
      processBlocks(state, action.payload),
    );

    builder.addCase(boardCreate.fulfilled.type, (state, action) =>
      processBlocks(state, action.payload),
    );

    builder.addCase(blocksReorder.pending.type, (state, action) => {
      const { requestId } = action.meta;
      return set(
        requestId,
        {
          id: requestId,
          key: requestId,
          permalink: requestId,
          version: rowBreak,
          blockType: 'row-break',
        },
        state,
      );
    });

    builder.addCase(blocksReorder.fulfilled.type, (state, action) =>
      processBlocks(state, action.payload),
    );

    builder.addCase(blockInitialiseStaticVis.pending.type, (state, action) =>
      set(
        action.meta.arg.blockId,
        initialiseBlock(
          {
            permalink: action.meta.arg.blockId,
            version: action.meta.arg.blockVersion,
          },
          action.meta.arg.capsule,
        ),
        state,
      ),
    );

    builder.addCase(blockValueCreate.pending.type, (state, action) => {
      const {
        requestId,
        arg: { blockId, scenarioId },
      } = action.meta;

      return pipe(
        set([blockId, 'requestVersion'], requestId),
        set([blockId, 'draftVersion'], requestId),
        discardUnselectedPerspectives(blockId, scenarioId),
        unsetLastQueried(blockId),
      )(state);
    });

    builder.addCase(scenarioDelete.fulfilled.type, (state, action) => {
      const scenarioId = get(['meta', 'arg', 'scenarioId'], action);

      return discardPerspective(scenarioId, state);
    });

    builder.addCase(blockValueCreate.fulfilled.type, (state, action) => {
      const { requestId } = action.meta;
      const { blockId } = action.meta.arg;
      const {
        newCapsule: { address: newCapsuleVersion },
      } = action.payload;

      return pipe(
        set([blockId, 'draftVersion'], newCapsuleVersion),
        set([blockId, 'status'], 'idle'),
        updateDataVersion(blockId, requestId, newCapsuleVersion),
      )(state);
    });

    builder.addCase(blockUpdateDetails.pending.type, (state, action) => {
      const {
        requestId,
        arg: { blockId, scenarioId, clearPerspectives },
      } = action.meta;

      return pipe(
        set([blockId, 'version'], requestId),
        set([blockId, 'requestVersion'], requestId),
        (s) =>
          clearPerspectives
            ? discardAllPerspectives(blockId, s)
            : discardUnselectedPerspectives(blockId, scenarioId, s),
        unsetLastQueried(blockId),
      )(state);
    });

    builder.addCase(blockUpdateDetails.fulfilled.type, (state, action) => {
      const { requestId } = action.meta;
      const { blockId } = action.meta.arg;
      const { version: newCapsuleVersion } = action.payload.blocks.find(
        (b) => b.permalink === blockId,
      );

      return pipe(
        set([blockId, 'version'], newCapsuleVersion),
        set([blockId, 'status'], 'idle'),
        updateDataVersion(blockId, requestId, newCapsuleVersion),
      )(state);
    });

    builder.addCase(tagUpdate.fulfilled, (state) =>
      mapValues(
        (block) => ({
          ...block,
          scenarios: mapValues((scenario) => {
            const hasPriorData = get('data', scenario);
            const newState = hasPriorData
              ? blockProcessingStates.PROCESSING_STALE
              : blockProcessingStates.PROCESSING_INITIAL;
            return pipe(
              set(['state', 'block'], newState),
              set(['state', 'query'], queryStates.INITIAL),
            )(scenario);
          }, block.scenarios),
        }),
        state,
      ),
    );

    builder.addCase(scenarioSend.fulfilled, (state, action) => {
      const { scenarioId } = action.payload;
      const dirtyBlocks = pipe(
        filter(({ scenarios }) => has(scenarioId, scenarios)),
        map((block) =>
          update(
            'scenarios',
            mapValues((scenario) => {
              const hasPriorData = get('data', scenario);
              const newState = hasPriorData
                ? blockProcessingStates.PROCESSING_STALE
                : blockProcessingStates.PROCESSING_INITIAL;
              return pipe(
                set(['state', 'block'], newState),
                set(['state', 'query'], queryStates.INITIAL),
              )(scenario);
            }),
            block,
          ),
        ),
      )(state);
      return reduce(
        (blocks, dirtyBlock) => set(dirtyBlock.id, dirtyBlock, blocks),
        state,
        dirtyBlocks,
      );
    });

    builder.addCase(locationCreate.fulfilled, (state, action) => {
      const { scenarioId } = action.payload.scenario;
      const dirtyBlocks = pipe(
        filter(({ scenarios }) => has(scenarioId, scenarios)),
        map((block) =>
          update(
            'scenarios',
            mapValues((scenario) => {
              const hasPriorData = get('data', scenario);
              const newState = hasPriorData
                ? blockProcessingStates.PROCESSING_STALE
                : blockProcessingStates.PROCESSING_INITIAL;
              return pipe(
                set(['state', 'block'], newState),
                set(['state', 'query'], queryStates.INITIAL),
              )(scenario);
            }),
            block,
          ),
        ),
      )(state);
      return reduce(
        (blocks, dirtyBlock) => set(dirtyBlock.id, dirtyBlock, blocks),
        state,
        dirtyBlocks,
      );
    });

    builder.addCase(blockProgress.fulfilled.type, (state, action) => {
      const scenariosByBlock = pipe(
        groupBy('blockId'),
        toPairs,
      )(action.payload.scenarios);
      return reduce(
        (accumulator, [blockId, scenarios]) => {
          const block = get(blockId, accumulator);
          if (!block) return accumulator;
          const requiredTables = pipe(
            filter({ blockId: block.id }),
            flatMap('tables'),
          )(action.meta.arg.scenarios);
          const allTablesAvailable = pipe(
            flatMap('progress'),
            filter(({ resource }) =>
              some((table) => resource.startsWith(table), requiredTables),
            ),
            every('available'),
          )(scenarios);
          const newStateInitial = allTablesAvailable
            ? blockProcessingStates.LOADING_INITIAL
            : blockProcessingStates.PROCESSING_INITIAL;
          const newStateStale = allTablesAvailable
            ? blockProcessingStates.LOADING_STALE
            : blockProcessingStates.PROCESSING_STALE;
          const newBlock = reduce(
            (acc, s) => {
              const hasPriorData = !isNil(
                get(['scenarios', s.scenarioId, 'data'], block),
              );
              const newState = hasPriorData ? newStateStale : newStateInitial;
              return pipe(
                set(['scenarios', s.scenarioId, 'state', 'block'], newState),
                set(
                  ['scenarios', s.scenarioId, 'state', 'query'],
                  queryStates.INITIAL,
                ),
              )(acc);
            },
            block,
            scenarios,
          );
          return {
            ...accumulator,
            [blockId]: newBlock,
          };
        },
        state,
        scenariosByBlock,
      );
    });

    builder.addCase(dataFetch.pending.type, (state, action) => {
      const { blockId, scenarioId } = action.meta.arg;
      return pipe(
        set([blockId, 'scenarios', scenarioId, 'lastQueried'], Date.now()),
        set([blockId, 'status'], 'querying'),
      )(state);
    });

    builder.addCase(dataFetch.fulfilled.type, (state, action) => {
      const {
        requestId: dataId,
        arg: {
          capsule: { address: dataVersion },
          blockId,
          scenarioId,
          scenarios,
        },
      } = action.meta;
      const { type: fetchState } = action.payload;

      const blockState = get(blockId, state);
      const placeholderMatch =
        getActiveVersion(blockState) === get('requestVersion', blockState);

      return pipe(
        update(
          [blockId, 'scenarios', scenarioId],
          (currentBlockPerspective) => ({
            ...currentBlockPerspective,
            ...(fetchState === 'complete'
              ? {
                  dataVersion: placeholderMatch
                    ? dataVersion
                    : getActiveVersion(blockState),
                  scenarioKeys: scenarios,
                  dataId,
                }
              : {}),
            state: fetchState,
          }),
        ),
        set([blockId, 'status'], 'idle'),
      )(state);
    });

    builder.addCase(dataFetch.rejected.type, (state, action) => {
      const {
        blockId,
        scenarioId,
        scenarios,
        capsule: { address: dataVersion },
      } = action.meta.arg;
      const { requestId: dataId } = action.meta;
      const { status } = action.payload;

      const currentBlockPerspective = get(
        [blockId, 'scenarios', scenarioId],
        state,
      );

      const setIfError =
        status >= 400 && status < 500
          ? set([blockId, 'scenarios', scenarioId], {
              ...currentBlockPerspective,
              state: 'error',
              dataVersion,
              dataId,
              scenarioKeys: scenarios,
            })
          : identity;

      return pipe(setIfError, set([blockId, 'status'], 'idle'))(state);
    });

    builder.addCase(visualisationQuery.pending.type, (state, action) => {
      const { blockId, scenarios: requiredScenarios = [] } = action.meta.arg;
      return pipe(
        update([blockId, 'scenarios'], (scenarios) =>
          reduce(
            (accumulator, { scenarioId }) =>
              pipe(set([scenarioId, 'state', 'query'], queryStates.FETCHING))(
                accumulator,
              ),
            scenarios,
            filter({ blockId }, requiredScenarios),
          ),
        ),
        update(blockId, set('isFetching', true)),
      )(state);
    });

    builder.addCase(visualisationQuery.fulfilled.type, (state, action) => {
      const { blockId, isUpToDate, scenariosWithData, schema } = action.payload;
      if (action.error) {
        return state;
      }
      return update(
        blockId,
        (block) => ({
          ...block,
          hasInitialised: true,
          isFetching: false,
          isUpToDate,
          scenarios: pipe(
            mapValues(
              pipe(
                set('isFresh', true),
                set('hasInitialised', true),
                set(['state', 'query'], queryStates.FRESH),
                set(['state', 'block'], blockProcessingStates.FRESH),
                update('data', map(update('dataId', nanoid))),
              ),
            ),
            assign(block.scenarios),
          )(scenariosWithData),
          columns: map('key', schema),
          schema: keyBy('key', normalizeColumns(schema)),
        }),
        state,
      );
    });
  },
});

export { reducer, actions };
