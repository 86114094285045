const set = (redirectTo) => {
  sessionStorage.setItem('redirectTo', redirectTo);
  localStorage.setItem('kinesis:redirect', redirectTo);
};

const redirectPath = () => {
  const target = sessionStorage.getItem('redirectTo');
  sessionStorage.removeItem('redirectTo');
  return target;
};

export { set, redirectPath };
