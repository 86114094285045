import { zip } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { Content } from '@kinesis/bungle/legacy';
import {
  ThreeColumnGrid,
  TwoColumnGrid,
  GridItem,
} from './pathways-inputs-grid.styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  headerPlacement: PropTypes.oneOf(['left', 'top']),
};
const defaultProps = {
  headerPlacement: 'top',
};

const PathwaysInputsGrid = ({ children, headerPlacement }) => {
  const interleavedChildren = useMemo(
    () =>
      zip(
        children.slice(0, children.length / 2),
        children.slice(children.length / 2),
      ),
    [children],
  );

  return (
    <Content
      borderPlacement={['top', 'right', 'bottom', 'left']}
      borderRadiusSize='xsmall'
      padding='none'
    >
      {headerPlacement === 'top' && (
        <ThreeColumnGrid>
          {children.map((child, ix) => (
            <GridItem
              key={ix} // eslint-disable-line react/no-array-index-key
              $lastRow={ix >= children.length / 2}
              $lastColumn={
                ix === children.length - 1 || ix === children.length / 2 - 1
              }
            >
              {child}
            </GridItem>
          ))}
        </ThreeColumnGrid>
      )}
      {headerPlacement === 'left' && (
        <TwoColumnGrid>
          {interleavedChildren.map(([header, cell], ix) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={ix}>
              <GridItem $lastRow={ix === interleavedChildren.length - 1}>
                {header}
              </GridItem>
              <GridItem
                $lastRow={ix === interleavedChildren.length - 1}
                $lastColumn
              >
                {cell}
              </GridItem>
            </Fragment>
          ))}
        </TwoColumnGrid>
      )}
    </Content>
  );
};

PathwaysInputsGrid.propTypes = propTypes;
PathwaysInputsGrid.defaultProps = defaultProps;

export { PathwaysInputsGrid };
