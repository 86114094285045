import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns/fp';
import {
  Cards,
  Card,
  CardButton,
  CardContent,
  CardSubtitle,
  CardTitle,
} from '@kinesis/bungle';
import { useNavigate } from 'react-router';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { Breadcrumb, PublicAppHeader } from 'components/app-header';
import { ContentSection } from 'components/content-section';
import { GlobalLayout } from 'components/global-layout';
import { Separator } from 'components/separator';
import baselineScenarioSelector from 'selectors/baselineScenarioSelector';
import { Loading } from 'components/loading';
import { publicWorkspaceFetch } from 'actions/publicWorkspaceFetch';
import PublicCtaBanner from 'components/public-cta-banner/PublicCtaBanner';
import { PublicBoard } from 'components/public-board';
import { PublicScenario } from 'components/public-scenario';
import publicWorkspaceSelector from 'selectors/publicWorkspaceSelector';
import sortedBoardsSelector from 'selectors/sortedBoardsSelector';
import sortedScenariosSelector from 'selectors/sortedScenariosSelector';
import { PageLayout } from 'components/page-layout';
import { usePublicWorkspaceId, useSelectorWithProps } from 'hooks';
import PublicNamedScenarioItem from './PublicNamedScenarioItem';

const propTypes = {
  shouldFetchData: PropTypes.bool,
};

const defaultProps = {
  shouldFetchData: true,
};

const PublicWorkspace = ({ shouldFetchData }) => {
  const workspaceId = usePublicWorkspaceId();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (workspaceId && shouldFetchData) {
      dispatch(publicWorkspaceFetch({ key: workspaceId }))
        .unwrap()
        .catch(() => {
          navigate('/not-found');
        });
    }
  }, [dispatch, navigate, shouldFetchData, workspaceId]);

  const scenarios = useSelectorWithProps(sortedScenariosSelector, {
    public: true,
    workspaceId,
  });

  const boards = useSelectorWithProps(sortedBoardsSelector, {
    public: true,
    workspaceId,
  });

  const { id: baselineScenarioId } =
    useSelectorWithProps(baselineScenarioSelector, {
      public: true,
      workspaceId,
    }) ?? {};

  const {
    loaded,
    organisation,
    name: workspaceName,
    description,
  } = useSelectorWithProps(publicWorkspaceSelector, { workspaceId });

  if (!loaded) {
    return <Loading />;
  }

  return (
    <GlobalLayout direction='column'>
      <PageLayout>
        <PublicAppHeader
          avatarUrl={organisation.avatarUrl}
          avatarAlt={`${organisation.name} logo`}
        />
        <Breadcrumb to={`/public/${workspaceId}`} root>
          {workspaceName}
        </Breadcrumb>
        <Routes>
          <Route path='scenarios/:scenario'>
            <Route index element={<Navigate to='map' />} />
            <Route path=':view/*' element={<PublicScenario />} />
          </Route>
          <Route path='boards/:boardId/*' element={<PublicBoard />} />
          <Route
            path='*'
            element={
              <>
                {description && (
                  <>
                    <ContentSection>
                      <span>{description}</span>
                    </ContentSection>
                    <Separator />
                  </>
                )}
                <ContentSection title='Scenarios'>
                  <Cards view='list'>
                    {scenarios.map(({ id, name, isDefault, updatedAt }) => (
                      <PublicNamedScenarioItem
                        key={id}
                        id={id}
                        name={name}
                        workspaceId={workspaceId}
                        isDefault={isDefault}
                        updatedAt={parseISO(updatedAt)}
                      />
                    ))}
                  </Cards>
                </ContentSection>
                {boards.length > 0 && (
                  <>
                    <Separator />
                    <ContentSection title='Boards'>
                      <Cards grow view='grid'>
                        {boards.map((board) => (
                          <Card key={board.id} data-cy='board-item'>
                            <CardButton
                              as={Link}
                              to={`/public/${workspaceId}/boards/${board.id}?scenario=${baselineScenarioId}`}
                            >
                              <CardContent clipRight noWrap>
                                <CardTitle>{board.label}</CardTitle>
                                <CardSubtitle>{board.group}</CardSubtitle>
                              </CardContent>
                            </CardButton>
                          </Card>
                        ))}
                      </Cards>
                    </ContentSection>
                  </>
                )}
              </>
            }
          />
        </Routes>
      </PageLayout>
      <PublicCtaBanner />
    </GlobalLayout>
  );
};

PublicWorkspace.propTypes = propTypes;
PublicWorkspace.defaultProps = defaultProps;

export { PublicWorkspace };
