import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { filter, get, map, max, maxBy, pipe, isNil } from 'lodash/fp';
import { Icon } from '@kinesis/bungle';
import { Loading } from 'components/loading';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import baselineScenarioSelector from 'selectors/baselineScenarioSelector';
import Units from 'data/units';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import {
  ValueCard,
  ValueCardCaret,
  ValueCardNoData,
  ValueCardSubtitle,
  ValueCardValue,
  ValueCardWrapper,
} from 'components/value-card/value-card.styles';

const units = new Units();

const propTypes = {
  blockId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const changeFor = (baseline, comparison) => {
  if (baseline < comparison) return <Icon type='caret-up' magnitude='medium' />;
  if (baseline > comparison)
    return <Icon type='caret-down' magnitude='medium' />;
  return null;
};

const yearSelector = makeAggregationFilterSelector('year');

const BaselineComparison = ({ blockId, onClick, privacy, workspaceId }) => {
  const previewYear = useSelectorWithProps(yearSelector, {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });

  const scenarios = useSelectorWithProps(
    pipe(blockStateSelector, get('scenarios')),
    {
      blockId,
      public: privacy === 'public',
      workspaceId,
    },
  );
  const schema = useSelectorWithProps(pipe(blockStateSelector, get('schema')), {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });
  const chart = useSelectorWithProps(pipe(blockValueSelector, get('chart')), {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });

  const baselineScenarioId = useSelectorWithProps(
    pipe(baselineScenarioSelector, get('id')),
    {
      public: privacy === 'public',
      workspaceId,
    },
  );
  const currentScenarioId = useScenarioId();
  const title = get(['indicator', 'title'], chart);
  const indicatorColumn = get(['indicator', 'column'], chart);
  const timeColumn = get(['time', 'column'], chart);
  const { formatCell, symbol } = units.parseColumn(schema[indicatorColumn]);
  const time = useMemo(
    () =>
      pipe(
        get([baselineScenarioId, 'data']),
        map(timeColumn),
        filter((datum) => datum <= previewYear),
        max,
      )(scenarios),
    [baselineScenarioId, scenarios, previewYear, timeColumn],
  );
  const baselineData = useMemo(
    () => get([baselineScenarioId, 'data'], scenarios),
    [baselineScenarioId, scenarios],
  );
  const baseline = useMemo(
    () =>
      pipe(
        filter((d) => get(timeColumn, d) <= time),
        maxBy(timeColumn),
        get(indicatorColumn),
      )(baselineData),
    [baselineData, indicatorColumn, time, timeColumn],
  );
  const currentData = useMemo(
    () => get([currentScenarioId, 'data'], scenarios),
    [currentScenarioId, scenarios],
  );
  const current = useMemo(
    () =>
      pipe(
        filter((d) => get(timeColumn, d) <= time),
        maxBy(timeColumn),
        get(indicatorColumn),
      )(currentData),
    [currentData, indicatorColumn, time, timeColumn],
  );
  const caret = changeFor(baseline, current);
  const loading = isNil(baselineData) || isNil(currentData);
  const empty = !loading && (isNil(baseline) || isNil(current));

  return (
    <ValueCardWrapper onClick={onClick}>
      {loading && <Loading offset={16} delay={200} />}
      {empty && <ValueCardNoData>There is no data.</ValueCardNoData>}
      {!loading && !empty && (
        <ValueCard>
          <ValueCardValue>
            {caret && <ValueCardCaret>{caret}</ValueCardCaret>}
            {formatCell(Math.abs(current - baseline))}
          </ValueCardValue>
          <ValueCardSubtitle>{symbol || title}</ValueCardSubtitle>
        </ValueCard>
      )}
    </ValueCardWrapper>
  );
};

BaselineComparison.propTypes = propTypes;
BaselineComparison.defaultProps = defaultProps;

export default BaselineComparison;
