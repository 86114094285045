import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash/fp';
import { geographyFetch } from 'actions/geographyFetch';
import { geographyBundle } from 'actions/geographyBundle';

const { reducer, actions } = createSlice({
  name: 'geography',

  initialState: {
    dataIds: {},
    bundles: {},
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(geographyBundle.fulfilled.type, (state, action) =>
      set(['dataIds', action.meta.arg.dataId], action.payload.id, state),
    );

    builder.addCase(geographyFetch.fulfilled.type, (state, action) =>
      set(['bundles', action.meta.arg.bundle], action.payload, state),
    );
  },
});

export { reducer, actions };
