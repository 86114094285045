import styled from 'styled-components';

const Body = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  background: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 16px 20px;
  width: 100%;
`;

const Value = styled('div')`
  font-size: 38px;
  line-height: 46px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme, $placeholder }) =>
    $placeholder ? theme.color.gray6 : theme.color.gray9};
  opacity: ${({ isProcessing, $placeholder }) =>
    isProcessing && !$placeholder ? 0.5 : 1};
  font-variant-numeric: normal;
  font-feature-settings: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;

const Unit = styled('div')`
  flex: 0 0 auto;
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  line-height: ${({ theme }) => theme.lineHeight.lg};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.color.gray8};
  opacity: ${({ isProcessing }) => (isProcessing ? 0.5 : 1)};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MaxWidth = styled('div')`
  max-width: 100%;
`;

export { Body, Value, Unit, MaxWidth };
