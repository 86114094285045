import { get } from 'lodash/fp';
import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import { scenarioDiscard } from 'actions/scenarioDiscard';
import scenarioKeySelector from 'selectors/scenarioKeySelector';
import scenarioSelector from 'selectors/scenarioSelector';

const scenarioSaveAsNew = createAsyncThunk(
  'scenario/save-as-new',
  async ({ scenarioId, workspaceId }, { dispatch, getState }) => {
    const state = getState();
    const api = createApiFromState({ dispatch, getState });
    const scenario = scenarioSelector(state, { scenarioId, workspaceId });
    const scenarioKey = scenarioKeySelector(state, { scenarioId, workspaceId });
    const tagName = get(['draft', 'tagName'], scenario);

    const response = await api.post(`/api/scenario`, {
      scenarioKey,
      workspaceId: parseInt(workspaceId, 10),
      name: `${get('name', scenario)} (Modified)`,
      nameInitialized: true,
      description: get('description', scenario),
    });

    dispatch(
      scenarioDiscard({
        scenarioId,
        workspaceId,
        tagName,
      }),
    );

    return response.data;
  },
);

export { scenarioSaveAsNew };
