import { createSlice } from '@reduxjs/toolkit';
import {
  camelCase,
  clamp,
  find,
  flatMap,
  fromPairs,
  get,
  groupBy,
  identity,
  isNil,
  keyBy,
  map,
  mapValues,
  mapKeys,
  max,
  min,
  pipe,
  reduce,
  set,
  setWith,
  uniq,
  update,
  values,
  take,
} from 'lodash/fp';

import { publicWorkspaceFetch } from 'actions/publicWorkspaceFetch';
import { publicWorkspaceDataFetch } from 'actions/publicWorkspaceDataFetch';
import { normalise } from 'utils/resources';
import { normaliseScenario } from 'utils/scenarios';
import { pickToArray } from 'utils/functionalUtils';
import { nanoid } from 'nanoid';

const initialState = {
  preview: {
    year: undefined,
    constrainedBounds: {},
  },
  time: {
    blocks: {},
  },
  data: {
    lifecycle: {},
    entities: {},
  },
};

const { actions, reducer } = createSlice({
  name: 'publicWorkspaces',
  initialState: {},

  reducers: {
    clampTimeBounds: (state, action) => {
      const {
        arg: { workspaceId },
      } = action.meta || {};
      const { blockIds = [], yearMin, yearMax } = action.payload || {};

      if (!workspaceId || !blockIds || !yearMin || !yearMax) {
        return state;
      }

      return reduce(
        (accState, blockId) => {
          const currentYear = get(
            [workspaceId, 'time', 'blocks', blockId, 'year'],
            accState,
          );

          return set(
            [action.meta.arg.workspaceId, 'time', 'blocks', blockId, 'year'],
            clamp(yearMin, yearMax)(currentYear),
            accState,
          );
        },
        state,
        blockIds,
      );
    },
    setBlockYear: (state, action) =>
      set(
        [
          action.meta.arg.workspaceId,
          'time',
          'blocks',
          action.payload.blockId,
          'year',
        ],
        action.payload.year,
        state,
      ),
    setMultipleBlockYear: (state, action) =>
      reduce(
        (accState, blockId) => {
          const { year } = action.payload;
          if (!year) {
            return accState;
          }
          const { yearMin, yearMax } = get(
            [action.meta.arg.workspaceId, 'time', 'blocks', blockId],
            accState,
          );
          // expand local bounds
          let setBounds = identity;
          if (year < yearMin) {
            setBounds = set(
              [
                action.meta.arg.workspaceId,
                'time',
                'blocks',
                blockId,
                'yearMin',
              ],
              year,
            );
          } else if (year > yearMax) {
            setBounds = set(
              [
                action.meta.arg.workspaceId,
                'time',
                'blocks',
                blockId,
                'yearMax',
              ],
              year,
            );
          }
          return pipe(
            setBounds,
            set(
              [action.meta.arg.workspaceId, 'time', 'blocks', blockId, 'year'],
              year,
            ),
          )(accState);
        },
        state,
        action.payload.blockIds,
      ),
    setPreviewYear: (state, action) =>
      set(
        [action.meta.arg.workspaceId, 'preview', 'year'],
        action.payload,
        state,
      ),
    setScenarioYearMax: (state, action) =>
      setWith(
        Object,
        [
          action.meta.arg.workspaceId,
          'preview',
          'constrainedBounds',
          action.payload.scenarioId,
          'yearMax',
        ],
        action.payload.year,
        state,
      ),
    setScenarioYearMin: (state, action) =>
      setWith(
        Object,
        [
          action.meta.arg.workspaceId,
          'preview',
          'constrainedBounds',
          action.payload.scenarioId,
          'yearMin',
        ],
        action.payload.year,
        state,
      ),
    toggleLayer: (state, action) => {
      const { layerId, workspaceId } = action.payload;

      return update(
        [workspaceId, 'layers', 'entities', layerId, 'enabled'],
        (enabled) => !enabled,
        state,
      );
    },
    toggleVisualisationLayer: (state, action) => {
      const { workspaceId } = action.meta.arg;

      return update(
        [workspaceId, 'layers', 'visualisationLayerEnabled'],
        (enabled) => !enabled,
        state,
      );
    },
  },

  extraReducers: (builder) => {
    builder.addCase(publicWorkspaceDataFetch.pending, (state, action) =>
      update(
        [action.meta.arg.workspaceId, 'data', 'lifecycle'],
        (current) =>
          reduce(
            (accumulator, perspective) =>
              set(perspective, 'FETCHING', accumulator),
            current,
            action.meta.arg.perspectives,
          ),
        state,
      ),
    );

    builder.addCase(publicWorkspaceDataFetch.fulfilled, (state, action) =>
      pipe(
        update([action.meta.arg.workspaceId, 'data', 'lifecycle'], (current) =>
          reduce(
            (accumulator, data) => set(data.id, 'OK', accumulator),
            current,
            action.payload,
          ),
        ),
        update([action.meta.arg.workspaceId, 'data', 'entities'], (current) =>
          reduce(
            (accumulator, data) =>
              set(
                data.id,
                map(update('dataId', nanoid), data.data),
                accumulator,
              ),
            current,
            action.payload,
          ),
        ),
        update([action.meta.arg.workspaceId, 'time', 'blocks'], (current) =>
          reduce(
            (blockTimes, block) =>
              reduce(
                (blockTimesX, perspective) => {
                  if (isNil(find({ id: perspective.id }, block.perspectives))) {
                    return blockTimesX;
                  }
                  const timeValues = map('time', perspective.data);
                  const yearMin = min(timeValues);
                  const yearMax = max(timeValues);
                  const currentYear = get([block.id, 'year'], blockTimesX);
                  return set(
                    block.id,
                    {
                      year: currentYear
                        ? clamp(yearMin, yearMax)(currentYear)
                        : yearMax,
                      yearMax,
                      yearMin,
                    },
                    blockTimesX,
                  );
                },
                blockTimes,
                action.payload,
              ),
            current,
            pipe(get([action.meta.arg.workspaceId, 'blocks']), values)(state),
          ),
        ),
      )(state),
    );

    builder.addCase(publicWorkspaceFetch.pending, (state, action) =>
      set(
        action.meta.arg.key,
        {
          loaded: false,
          preview: initialState.preview,
          time: initialState.time,
          data: initialState.data,
        },
        state,
      ),
    );

    builder.addCase(publicWorkspaceFetch.fulfilled, (state, action) => ({
      ...state,

      [action.meta.arg.key]: pipe((patched) =>
        pipe(
          set('loaded', true),
          set('layerGroups', {
            entities: keyBy('id')(patched.layer_groups),
            keys: map('id')(patched.layer_groups),
          }),
          set(['organisation', 'avatarUrl'], patched.organisation.avatar),
          set(
            'scenarios',
            pipe(map(mapKeys(camelCase)), keyBy('id'))(patched.scenarios),
          ),
          set('resources', normalise(patched)),
          set(
            'attributes',
            pipe(map(normaliseScenario), keyBy('id'))(patched.attributes),
          ),
          set('layers', {
            visualisationLayerEnabled: true,
            entities: pipe(
              mapValues((v) => set('enabled', v.label === 'Locations', v)),
              keyBy('id'),
            )(patched.layers),
          }),
          set('geography', keyBy('id')(patched.geography)),
          set('instantiations', keyBy('attribute')(patched.instantiations)),
          set('locations', keyBy('id')(patched.locations)),
          set(
            'blocks',
            pipe(
              values,
              flatMap(get('blocks')),
              uniq,
              map((b) => pipe(set('id', b.permalink), set('filters', []))(b)),
              map(update('schema', keyBy('key'))),
              keyBy('permalink'),
            )(patched.boards),
          ),
          set(
            'boards',
            pipe(
              map((board) =>
                set('blocks', map('permalink', board.blocks), board),
              ),
              keyBy('id'),
            )(patched.boards),
          ),
          set(
            'statistics',
            pipe(
              groupBy('table'),
              mapKeys((key) => take(3, key.split(':')).join(':')),
              mapValues(
                pipe(
                  map((x) => pickToArray(['scenario', 'metadata'])(x)),
                  fromPairs,
                  mapValues(keyBy('column')),
                ),
              ),
            )(patched.statistics),
          ),
        )(patched),
      )(action.payload),
    }));
  },
});

export { actions, reducer };
