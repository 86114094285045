import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { get, pipe, isNil } from 'lodash/fp';
import { useBoardId, useScenarioId, useSelectorWithProps } from 'hooks';
import useActions from 'hooks/useActions';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import BaselineComparison from 'components/baseline-comparison/BaselineComparison';
import Chart from 'components/charts/highcharts/Chart';
import { StaticIndicator } from 'components/static-indicator';
import Layout from 'components/layout';
import { Loading } from 'components/loading';
import PrivateSpatialVisualisation from 'components/spatial-visualisation/PrivateSpatialVisualisation';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import { toolboxChartDetailIdSelector } from 'selectors/toolboxDetailSelectors';
import { requiresScenarios, blockProcessingStates } from 'data/block';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  viewMode: PropTypes.oneOf(['maximised', 'minimised', 'standalone'])
    .isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  isSelected: false,
};

const VisualisationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 0;
  height: 100%;
  justify-content: center;
  position: relative;
`;

const PlaceholderMessage = styled.p`
  color: rgba(0, 0, 0, 0.25);
  margin: 0 0 16px 0;
`;

const StaticVisualisation = ({
  blockId,
  isSelected,
  viewMode,
  workspaceId,
}) => {
  const key = useSelectorWithProps(pipe(blockStateSelector, get('key')), {
    blockId,
    workspaceId,
  });
  const scenarios = useSelectorWithProps(
    pipe(blockStateSelector, get('scenarios')),
    { blockId, workspaceId },
  );

  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId,
    workspaceId,
  });
  const chartType = get(['chart', 'type'], blockValue);
  const visualisation = get('visualisation', blockValue);
  const selectedDetailId = useSelector(toolboxChartDetailIdSelector);
  const boardId = useBoardId();
  const scenarioId = useScenarioId();
  const blockProcessingState =
    get([scenarioId, 'state', 'block'], scenarios) ||
    blockProcessingStates.INITIAL;

  const { select } = useActions(toolboxActions);
  const { toggleBlock, selectBlock } = useActions(blockSelectionsActions);

  const handleBlockSelect = useCallback(
    (event) => {
      if (get('shiftKey', event)) {
        toggleBlock(blockId);
      } else {
        selectBlock(blockId);
      }
    },
    [blockId, selectBlock, toggleBlock],
  );

  const handleChartSelect = useCallback(
    (value) => {
      selectBlock(blockId);

      if (!isNil(value)) {
        select({
          pane: 'detail',
          selection: { id: value, type: 'chart-detail' },
          label: 'details',
        });
      }
    },
    [blockId, select, selectBlock],
  );
  const handleBlockMultiSelect = useCallback(() => {
    toggleBlock(blockId);
  }, [blockId, toggleBlock]);

  const needsData = requiresScenarios(blockValue);

  const onWrapperClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  if (
    needsData &&
    [blockProcessingStates.PROCESSING_INITIAL].includes(blockProcessingState)
  ) {
    return (
      <Layout direction='column'>
        <VisualisationWrapper data-testid='block-processing-view'>
          <PlaceholderMessage>Data is being processed.</PlaceholderMessage>
        </VisualisationWrapper>
      </Layout>
    );
  }

  if (
    needsData &&
    ![
      blockProcessingStates.FRESH,
      blockProcessingStates.LOADING_STALE,
      blockProcessingStates.PROCESSING_STALE,
    ].includes(blockProcessingState)
  ) {
    return (
      <Layout direction='column'>
        <VisualisationWrapper data-testid='block-loading-view'>
          <Loading
            offset={
              viewMode === 'maximised' || visualisation === 'spatial' ? 0 : 16
            }
            delay={200}
          />
        </VisualisationWrapper>
      </Layout>
    );
  }

  return (
    <Layout direction='column' scroll={visualisation === 'table'}>
      <VisualisationWrapper
        onClick={onWrapperClick}
        data-testid='block-data-view'
      >
        {visualisation === 'spatial' && (
          <PrivateSpatialVisualisation
            allowScrollZoom={isSelected || viewMode === 'maximised'}
            blockId={key}
            boardId={boardId}
            scenarioId={scenarioId}
            viewMode={viewMode}
            workspaceId={workspaceId}
          />
        )}
        {visualisation === 'chart' && chartType === 'indicator' && (
          <StaticIndicator
            blockId={blockId}
            onClick={handleBlockSelect}
            workspaceId={workspaceId}
          />
        )}
        {visualisation === 'chart' && chartType === 'baseline-comparison' && (
          <BaselineComparison
            blockId={blockId}
            onClick={handleBlockSelect}
            workspaceId={workspaceId}
          />
        )}
        {visualisation === 'chart' &&
          !['indicator', 'baseline-comparison'].includes(chartType) && (
            <Chart
              blockId={key}
              boardId={boardId}
              clickable={viewMode !== 'standalone'}
              isSelected={isSelected || viewMode === 'maximised'}
              selectedDetailId={
                isSelected || viewMode === 'maximised'
                  ? selectedDetailId
                  : undefined
              }
              maxHeight={viewMode === 'maximised' ? 768 : undefined}
              maxWidth={viewMode === 'maximised' ? 1280 : undefined}
              onBlockMultiSelect={handleBlockMultiSelect}
              onSelect={handleChartSelect}
              showLegend={viewMode === 'maximised'}
              workspaceId={workspaceId}
            />
          )}
      </VisualisationWrapper>
    </Layout>
  );
};

StaticVisualisation.propTypes = propTypes;
StaticVisualisation.defaultProps = defaultProps;

export { StaticVisualisation };
