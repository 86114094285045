import { createSelector } from 'reselect';
import { defaultTo, pipe, get, first } from 'lodash/fp';
import { requiredTablesOf } from 'data/block';
import blockValueSelector from './blockValueSelector';
import workspaceSelector from './workspaceSelector';

const schemaSelector = createSelector(
  pipe(workspaceSelector, get('resources')),
  get('resources'),
  pipe(blockValueSelector, requiredTablesOf, first),
  (workspaceResources, resources, table) => {
    const resourceId = get(table, workspaceResources);
    return pipe(get([resourceId, 'schema']), defaultTo({}))(resources);
  },
);

export default schemaSelector;
