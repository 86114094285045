import { useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { first, get, map } from 'lodash/fp';
import { Stack, InPlaceInput, Select } from '@kinesis/bungle';
import Modal from 'components/modals/modal/Modal';
import { appCreate } from 'actions/appCreate';
import { ModalHeader, ModalHeading } from '../modal-header';
import { FormLayout } from './new-app.styles';

const propTypes = {
  animation: PropTypes.bool,
  organisations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      primary: PropTypes.bool,
      avatarUrl: PropTypes.string,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  primaryOrganisationId: PropTypes.number,
};

const defaultProps = {
  animation: true,
  primaryOrganisationId: undefined,
};

const licenceOptions = [
  { label: 'Marketplace', value: 'marketplace' },
  { label: 'Proprietary', value: 'proprietary' },
];

const NewAppModal = ({
  animation,
  onClose,
  organisations,
  primaryOrganisationId,
}) => {
  const dispatch = useDispatch();
  const ownerOptions = useMemo(
    () =>
      map(
        ({ id, name, avatarUrl }) => ({
          label: name,
          value: id,
          avatar: avatarUrl,
        }),
        organisations,
      ),
    [organisations],
  );
  const [name, setName] = useState('');
  const [licence, setLicence] = useState('marketplace');
  const defaultOwner =
    primaryOrganisationId || get('value', first(ownerOptions));
  const [owner, setOwner] = useState(defaultOwner);
  const headerLabel = 'New app';

  const onSave = useCallback(() => {
    dispatch(appCreate({ owner, name, licence }));
  }, [dispatch, owner, name, licence]);
  return (
    <Modal
      header={
        <ModalHeader>
          <ModalHeading>{headerLabel}</ModalHeading>
        </ModalHeader>
      }
      aria-label={headerLabel}
      animation={animation}
      onClose={onClose}
      onSave={onSave}
      magnitude='compact'
      minHeight={306}
    >
      <FormLayout>
        <Stack space='medium'>
          <InPlaceInput
            autoFocus
            onChange={setName}
            placeholder='App name'
            value={name}
            variant='title'
          />
          <Select
            label='Licence'
            onChange={setLicence}
            value={licence}
            options={licenceOptions}
          />
          <Select
            label='Owner organisation'
            onChange={setOwner}
            value={owner}
            options={ownerOptions}
          />
        </Stack>
      </FormLayout>
    </Modal>
  );
};

NewAppModal.defaultProps = defaultProps;
NewAppModal.propTypes = propTypes;

export { NewAppModal };
