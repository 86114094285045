import PropTypes from 'prop-types';
import { map } from 'lodash/fp';
import { Select } from '@kinesis/bungle';
import styled from 'styled-components';

const RelationalOperatorSelectContent = styled.div`
  margin-top: 4px;
`;

const relationalOperators = [
  {
    value: 'all',
    label: 'All values',
  },
  {
    value: 'eq',
    label: 'Equal to',
  },
  {
    value: 'ne',
    label: 'Not equal to',
  },
  {
    value: 'gt',
    label: 'Greater than',
  },
  {
    value: 'gte',
    label: 'Greater than or equal to',
  },
  {
    value: 'lt',
    label: 'Less than',
  },
  {
    value: 'lte',
    label: 'Less than or equal to',
  },
  {
    value: 'be',
    label: 'Between',
  },
  {
    value: 'nb',
    label: 'Not between',
  },
];

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  value: PropTypes.oneOf(map('value', relationalOperators)),
};

const defaultProps = {
  value: 'all',
  onFocus: undefined,
};

const RelationalOperatorSelect = ({ onChange, onFocus, value }) => (
  <Select
    menuHeight={300}
    onChange={onChange}
    onFocus={onFocus}
    options={relationalOperators}
    value={value}
  />
);

RelationalOperatorSelect.propTypes = propTypes;
RelationalOperatorSelect.defaultProps = defaultProps;

export { RelationalOperatorSelect, RelationalOperatorSelectContent };
