import { createSelector } from 'reselect';
import { filter, get, includes, pipe, placeholder, where } from 'lodash/fp';
import enabledLayersSelector from './enabledLayersSelector';
import workspaceSelector from './workspaceSelector';

const enabledLocationsSelector = createSelector(
  pipe(workspaceSelector, get('locations')),
  enabledLayersSelector,
  (locations, enabledLayers) =>
    filter(
      where({
        layer: includes(placeholder, enabledLayers),
      }),
      locations,
    ),
);

export default enabledLocationsSelector;
