import {
  concat,
  curry,
  flatten,
  flatMap,
  get,
  groupBy,
  identity,
  min,
  map,
  mapValues,
  max,
  pick,
  pipe,
  sum,
  values,
} from 'lodash/fp';

const immutableSort = curry((f, arr) => arr.slice().sort(f));

const calculateSimpleBounds = (data, col) =>
  pipe(map(get(col)), (d) => ({
    min: min(concat(0, d)),
    max: max(concat(0, d)),
  }))(data);

const calculateBounds = (data, timeCol, xCol, yCols, stacked) => {
  const xGroups = pipe(
    groupBy(timeCol),
    mapValues(pipe(groupBy(xCol), values)),
    values,
    flatten,
  )(data);
  const valuesForYColumns = pipe(pick(yCols), values);
  const sumToArray = pipe(sum, Array.of);

  return pipe(
    flatMap(pipe(flatMap(valuesForYColumns), stacked ? sumToArray : identity)),
    (d) => ({
      yMin: min(concat(0, d)),
      yMax: max(concat(0, d)),
    }),
  )(xGroups);
};

export { immutableSort, calculateBounds, calculateSimpleBounds };
