import { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { filter, find, first, get, isEmpty, isNil, map, pipe } from 'lodash/fp';
import {
  Badge,
  Menu,
  Tab,
  TabBar,
  TabList,
  TabPanel,
  TabPanels,
} from '@kinesis/bungle';
import appManagementSelector from 'selectors/appManagementSelector';
import appUpgradesSelector from 'selectors/appUpgradesSelector';
import Plural from 'components/plural/plural';
import Modal from 'components/modals/modal/Modal';
import Layout from 'components/layout';
import AppCard from 'components/app-mini-card/AppCard';
import { LegacyContent } from 'components/legacy-content';
import { useSelectorWithProps } from 'hooks';
import { ModalHeader, ModalHeading } from '../modal-header';
import {
  AppListPane,
  AppMenu,
  AppMenuItem,
  MenuSeparator,
  Paragraph,
  TabsFlex,
  UpgradeMenuBadge,
  UpgradeMenuItem,
} from './app-management.styles';
import AppManagementUpgrades from './app-management.upgrades';
import AppManagementHeader from './app-management.header';
import OverviewTabContent from './app-management.overview';
import BoardsTabContent from './app-management.boards';

const propTypes = {
  animation: PropTypes.bool,
  editor: PropTypes.bool,
  initialAppId: PropTypes.number,
  showUpgradePane: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  animation: true,
  editor: true,
  initialAppId: undefined,
  showUpgradePane: false,
};

const AppManagement = ({
  animation,
  editor,
  initialAppId,
  showUpgradePane,
  onClose,
  workspaceId,
}) => {
  const apps = useSelectorWithProps(appManagementSelector, { workspaceId });
  const [selectedAppKey, setSelectedAppKey] = useState(
    get('key', find({ id: initialAppId }, apps) || first(apps)),
  );
  const selectedApp = useMemo(
    () => find({ key: selectedAppKey }, apps),
    [apps, selectedAppKey],
  );

  const upgrades = useSelectorWithProps(appUpgradesSelector, { workspaceId });
  const [upgradePane, setUpgradePane] = useState(
    showUpgradePane || (!isEmpty(upgrades) && isNil(initialAppId) && editor),
  );
  const selectUpgradePane = useCallback(
    () => setUpgradePane(true),
    [setUpgradePane],
  );
  const selectFirstApp = useCallback(() => {
    setUpgradePane(false);
    setSelectedAppKey(get('key', first(apps)));
  }, [setUpgradePane, setSelectedAppKey, apps]);

  useEffect(() => {
    if (upgradePane && isEmpty(upgrades)) {
      selectFirstApp();
    }
  }, [upgrades, selectFirstApp, upgradePane]);

  const boards = useMemo(() => {
    const pathToResources = get('version', selectedApp)
      ? 'version'
      : 'available';
    return pipe(
      get([pathToResources, 'resources']),
      filter({ type: 'board' }),
    )(selectedApp);
  }, [selectedApp]);
  const hasUpgrades = editor && !isEmpty(upgrades);
  const headerLabel = 'Apps';

  return (
    <Modal
      header={
        <ModalHeader>
          <ModalHeading>{headerLabel}</ModalHeading>
        </ModalHeader>
      }
      aria-label={headerLabel}
      animation={animation}
      onClose={onClose}
      saveable={false}
    >
      <Layout direction='column'>
        <Layout direction='row'>
          <AppListPane>
            <LegacyContent>
              {hasUpgrades && (
                <Menu>
                  <Menu.Item
                    onClick={selectUpgradePane}
                    variant={upgradePane ? 'accent' : 'default'}
                    content={
                      <UpgradeMenuItem>
                        <UpgradeMenuBadge>
                          <Badge count={upgrades.length} />
                        </UpgradeMenuBadge>
                        <span>
                          <Plural count={upgrades.length} word='Updates' />{' '}
                          available
                        </span>
                      </UpgradeMenuItem>
                    }
                  />
                </Menu>
              )}
              {hasUpgrades && <MenuSeparator />}
              <AppMenu>
                {map((app) => (
                  <AppMenuItem
                    key={get('key', app)}
                    onClick={() => {
                      setSelectedAppKey(get('key', app));
                      setUpgradePane(false);
                    }}
                    variant={
                      selectedAppKey === get('key', app) && !upgradePane
                        ? 'accent'
                        : 'default'
                    }
                    content={
                      <AppCard
                        label={get('label', app)}
                        publisher={get('publisher', app)}
                        logo={get('image', app)}
                        status={get('version', app) ? 'success' : undefined}
                      />
                    }
                  />
                ))(apps)}
              </AppMenu>
              <Paragraph>
                <a
                  href='mailto:support@kinesis.org?subject=License%20additional%20apps'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Contact us
                </a>{' '}
                to license additional apps for your organisation.
              </Paragraph>
            </LegacyContent>
          </AppListPane>
          <Layout direction='column'>
            {!upgradePane && (
              <>
                <AppManagementHeader
                  editor={editor}
                  app={selectedApp}
                  appName={get('label', selectedApp)}
                  publisher={
                    get('publisher', selectedApp) ||
                    get(['owner', 'label'], selectedApp)
                  }
                  isLoaded={!!get('version', selectedApp)}
                  workspaceId={workspaceId}
                />
                <TabsFlex key={get('key', selectedApp)}>
                  <TabBar>
                    <TabList>
                      <Tab tabKey='overview'>Overview</Tab>
                      <Tab tabKey='boards'>Boards</Tab>
                    </TabList>
                  </TabBar>
                  <TabPanels>
                    <TabPanel tabKey='overview'>
                      <OverviewTabContent
                        description={get('description', selectedApp)}
                        version={get(['version', 'id'], selectedApp)}
                      />
                    </TabPanel>
                    <TabPanel tabKey='boards'>
                      <BoardsTabContent
                        boards={boards}
                        appName={get('label', selectedApp)}
                      />
                    </TabPanel>
                  </TabPanels>
                </TabsFlex>
              </>
            )}
            {upgradePane && (
              <AppManagementUpgrades
                upgrades={upgrades}
                workspaceId={workspaceId}
              />
            )}
          </Layout>
        </Layout>
      </Layout>
    </Modal>
  );
};

AppManagement.defaultProps = defaultProps;
AppManagement.propTypes = propTypes;

export default AppManagement;
