import styled from 'styled-components';

const LocationTableRowEmptyText = styled('div')(({ theme }) => ({
  color: theme.color.gray6,
  overflow: 'inherit',
  textOverflow: 'inherit',
}));

// eslint-disable-next-line import/prefer-default-export
export { LocationTableRowEmptyText };
