import styled from 'styled-components';
import { position } from 'polished';

const LocationsTableWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
});

const LocationsTableHead = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.monochrome2,
  borderBottom: `1px solid ${theme.color.gray4}`,
}));

const LocationsTableTitle = styled('div')({
  alignItems: 'center',
  display: 'flex',
});

const LocationsTableBody = styled('div')({
  flex: '1 1 0',
  position: 'relative',
});

const LocationsTableInner = styled('div')({
  overflow: 'hidden',
  ...position('absolute', 0),
});

const LocationsTableSubtitleText = styled('div')({
  paddingLeft: 8,
});

const LocationsTableTabBarWrapper = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  borderBottom: `1px solid ${theme.borderColor}`,
  paddingBottom: 8,
}));

export {
  LocationsTableWrapper,
  LocationsTableInner,
  LocationsTableTitle,
  LocationsTableHead,
  LocationsTableBody,
  LocationsTableSubtitleText,
  LocationsTableTabBarWrapper,
};
