import styled from 'styled-components';
import { position, rgba } from 'polished';

const AttributeValuesHeaderWrapper = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
`;

const BaselineIconWrapper = styled.span`
  padding-left: 4px;
  color: ${({ theme }) => theme.color.gray7};
`;

const DropdownButtonContent = styled.span`
  color: ${({ theme }) => theme.color.gray8};
`;

const Section = styled.section`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  :last-of-type {
    border-bottom: none;
  }
`;

const Description = styled.p`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.color.gray8};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
`;

const SidePane = styled.div`
  flex: 0 0 304px;
  width: 304px;
  overflow-y: auto;
`;

const Label = styled('label')(({ theme }) => ({
  color: theme.color.gray9,
  display: 'block',
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.default,
  lineHeight: theme.lineHeight.default,
  width: '100%',
  div: {
    paddingTop: 4,
  },
}));

const FormSection = styled('div')(() => ({
  paddingBottom: 8,
}));

const GridPane = styled.div(({ theme }) => ({
  height: '100%',
  paddingLeft: 1,
  paddingTop: 8,
  position: 'relative',
  '&:before': {
    backgroundColor: theme.borderColor,
    content: JSON.stringify(''),
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 8,
    width: 1,
  },
}));

const InputArea = styled('textarea').attrs(({ displayOnly }) => ({
  disabled: displayOnly,
}))(({ displayOnly, error, theme }) => ({
  border: displayOnly
    ? 'inherit'
    : `1px solid ${error ? theme.color.red6 : theme.controlBorderColor}`,
  borderRadius: 2,
  boxSizing: 'border-box',
  color: theme.color.default,
  '-webkit-text-fill-color': theme.color.default,
  display: 'flex',
  flexBasis: 'auto',
  flexGrow: 1,
  flexShrink: 1,
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.default,
  fontWeight: theme.fontWeight.normal,
  height: 'calc(100% - 32px)',
  lineHeight: theme.lineHeight.default,
  margin: '0 auto',
  opacity: 1,
  outline: 0,
  padding: displayOnly ? '5px 9px' : '5px 11px',
  resize: 'none',
  transition: '0.3s',
  width: 'calc(100% - 32px)',
  '&:disabled': {
    backgroundColor: displayOnly ? 'white' : theme.color.gray4,
  },
  '&:hover': displayOnly
    ? undefined
    : {
        borderColor: error ? theme.color.red6 : theme.color.blue6,
      },
  '&:focus': displayOnly
    ? undefined
    : {
        borderColor: error ? theme.color.red6 : theme.color.blue6,
        boxShadow: `0 0 0 2px ${rgba(
          error ? theme.color.red6 : theme.color.blue6,
          0.25,
        )}`,
      },
  '&::placeholder': {
    color: 'rgba(0, 0, 0, 0.25)',
  },
}));

const DateInputWrapper = styled.div({
  ...position('absolute', 0),
});

const Full = styled('div')`
  width: 100%;
  height: 100%;
`;

export {
  AttributeValuesHeaderWrapper,
  BaselineIconWrapper,
  DateInputWrapper,
  Description,
  DropdownButtonContent,
  FormSection,
  Label,
  Section,
  SidePane,
  GridPane,
  InputArea,
  Full,
};
