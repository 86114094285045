import { createSlice } from '@reduxjs/toolkit';
import { isEqual, first, get, pipe, reject, remove, set } from 'lodash/fp';
import { locationCreate } from 'actions/locationCreate';
import { actions as locationsActions } from 'reducers/locationsReducer';
import { scenarioDiscard } from 'actions/scenarioDiscard';

const { actions, reducer } = createSlice({
  name: 'toolbox',

  initialState: {
    panes: [],
    activePane: undefined,
    autoFocusTitle: undefined,
    isToolboxOpen: undefined,
  },

  reducers: {
    select: (state, action) => {
      const { label, selection, pane } = action.payload;
      let newPanes = [...state.panes];
      const existingPane = newPanes.find((p) => p.type === pane);
      const existingPaneIndex = existingPane && newPanes.indexOf(existingPane);
      if (
        existingPane &&
        selection &&
        isEqual(existingPane.selection, selection)
      ) {
        return state;
      }
      const newPane = existingPane ? { ...existingPane } : { type: pane };

      if (selection) {
        newPane.selection = selection;
      }

      newPane.label = label || get(['selection', 'type'], newPane);

      if (existingPane) {
        newPanes[existingPaneIndex] = newPane;
        if (existingPaneIndex > 0) {
          newPanes.splice(0, existingPaneIndex);
        }
      } else {
        newPanes = [newPane, ...newPanes];
      }

      return {
        ...state,
        panes: newPanes,
        activePane: get('type', first(newPanes)),
      };
    },

    selectPane: (state, action) => set('activePane', action.payload, state),

    removePane: (state, action) => {
      let newPanes = [...state.panes];
      const paneToRemove = newPanes.find((p) => p.type === action.payload);

      if (!paneToRemove) {
        return state;
      }

      newPanes = remove(paneToRemove, newPanes);
      return {
        ...state,
        panes: newPanes,
        activePane: get('type', first(newPanes)),
      };
    },

    resetTo: (state, action) => {
      const pivot = state.panes.find((p) => p.type === action.payload);
      if (!pivot) {
        return state;
      }
      const pivotIndex = state.panes.indexOf(pivot);
      const panes = state.panes.slice(pivotIndex);
      return {
        ...state,
        panes,
        activePane: get('type', pivot),
      };
    },

    setToolboxOpen(state, action) {
      return set('isToolboxOpen', action.payload, state);
    },

    unsetAutoFocusTitle: (state) => set('autoFocusTitle', false, state),

    setAutoFocusTitle: (state) => set('autoFocusTitle', true, state),
  },

  extraReducers: (builder) => {
    builder.addCase(locationCreate.fulfilled, (state, action) => {
      const { id } = action.payload.location;

      let newPanes = [...state.panes];

      const existingPane = newPanes.find(
        (p) => p.type === 'detail' && p.label === 'location',
      );
      const existingPaneIndex = existingPane && newPanes.indexOf(existingPane);

      const newPane = existingPane
        ? { ...existingPane }
        : { type: 'detail', label: 'location' };

      newPane.selection = { id, type: 'location' };

      if (existingPane) {
        newPanes[existingPaneIndex] = newPane;
        if (existingPaneIndex > 0) {
          newPanes.splice(0, existingPaneIndex);
        }
      } else {
        newPanes = [newPane, ...newPanes];
      }

      return {
        ...state,
        panes: newPanes,
        activePane: get('type', first(newPanes)),
      };
    });

    builder.addCase(locationsActions.removeLocation, (state) => {
      let newPanes = [...state.panes];
      const locationPane = newPanes.find(
        (p) => p.type === 'detail' && p.label === 'location',
      );
      if (locationPane) {
        newPanes = remove(locationPane, newPanes);
      }

      return {
        ...state,
        panes: newPanes,
        activePane: get('type', first(newPanes)),
      };
    });

    builder.addCase(scenarioDiscard.pending.type, (state) => {
      const newPanes = reject((p) => p.type === 'detail', [...state.panes]);

      return pipe(
        set('panes', newPanes),
        set('activePane', get('type', first(newPanes))),
      )(state);
    });
  },
});

export { actions, reducer };
