import { createSelector } from 'reselect';
import blockIdsSelector from 'selectors/blockIdsSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import { filter, get, has, isNil, pipe } from 'lodash/fp';
import { blockDataHasTime, getActiveVersion } from 'data/block';

const blockIdsWithTimeSelector = createSelector(
  blockIdsSelector,
  get('data'),
  get('blockValues'),
  pipe(workspaceSelector, get('blocks')),
  (state, props) => props.blockId,
  (state, props) => props.scenarioId,
  (
    blockIds,
    blockData,
    blockValues,
    blockStates,
    maxmisedBlockId,
    scenarioId,
  ) =>
    filter(
      (blockId) => {
        const blockValue = get(
          pipe(get(blockId), getActiveVersion)(blockStates),
          blockValues,
        );

        if (get('type', blockValue) === 'editable-visualisation') {
          const blockDataId = get(
            [blockId, 'scenarios', scenarioId, 'dataId'],
            blockStates,
          );
          return blockDataHasTime(get([blockDataId, 'data'], blockData));
        }
        return has([get('visualisation', blockValue), 'time'], blockValue);
      },
      isNil(maxmisedBlockId) ? blockIds : [maxmisedBlockId],
    ),
);

export default blockIdsWithTimeSelector;
