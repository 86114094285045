import { createAsyncThunk } from '@reduxjs/toolkit';
import createApi from 'api/createApi';

const userToken = createAsyncThunk('user/token', async () => {
  const api = createApi();

  const response = await api.post('/api/token');

  return response.data;
});

export { userToken };
