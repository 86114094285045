import {
  values,
  keyBy,
  find,
  curry,
  every,
  identity,
  some,
  map,
  flatMap,
  isNil,
  isNaN,
  update,
  isEqual,
  isEmpty,
  isString,
  isObject,
  toPairs,
  fromPairs,
  reduce,
  pipe,
  get,
  mapValues,
  sortBy,
} from 'lodash/fp';
import { BitVec } from 'data/bit-vec';
import Units from 'data/units';

const units = new Units();

const interventionAreaLabels = {
  'public-transit-electrification': 'Public transport electrification',
  'residential-building-standards': 'Residential building standards',
  'residential-vkt-change': 'Change in per-resident travel',
  'residential-retrofits': 'Residential retrofits',
  'non-residential-retrofits': 'Non-residential retrofits',
  'non-residential-building-standards': 'Non-residential building standards',
  'residential-mode-shift': 'Mode shift',
  'ev-takeup': 'Electric vehicle take-up',
  'waste-reduction': 'Per-person waste reduction',
  'recyclable-packaging': 'Recyclable packaging',
  'recyclable-material-management': 'Source separation',
  'recyclable-ewaste': 'E-waste management',
  organics: 'Organic waste separation',
  'waste-to-energy': 'Waste to energy',
};

const interventionAreaKeyOrder = [
  'residential-building-standards',
  'non-residential-building-standards',
  'residential-retrofits',
  'non-residential-retrofits',
  'residential-vkt-change',
  'residential-mode-shift',
  'public-transit-electrification',
  'ev-takeup',
  'waste-reduction',
  'recyclable-packaging',
  'recyclable-material-management',
  'recyclable-ewaste',
  'organics',
  'waste-to-energy',
];

const interventionAreaDescriptions = {
  'public-transit-electrification':
    'The rate at which public transport, particularly buses, will be electrified',
  'residential-building-standards':
    'Roll-out of higher sustainability requirements for new residential buildings',
  'residential-vkt-change':
    'Decreases due to higher job containment, increased walkability, etc., or increases due to urban sprawl and car dependence',
  'residential-retrofits':
    'Targets for impacting the existing building stock by upgrading appliances and/or switching gas appliances for their electrical counterparts',
  'non-residential-building-standards':
    'Roll-out of higher sustainability requirements for new non-residential buildings',
  'non-residential-retrofits':
    'Targets for impacting the existing building stock by upgrading appliances and/or switching gas appliances for their electrical counterparts',
  'residential-mode-shift':
    'The proportion of car trips, in km travelled, that will be replaced by public transport',
  'ev-takeup': 'The proportion of all cars that will be electric',
  'waste-reduction':
    'Due to less packaging, phasing out of single-use plastic, etc.',
  'recyclable-packaging':
    'The proportion of remaining packaging that is recyclable, reusable or compostable',
  'recyclable-material-management':
    'The proportion of recyclable packaging that is recovered and diverted from landfill',
  'recyclable-ewaste':
    'The proportion of e-waste that is recovered, recycled and/or diverted from landfill',
  organics: 'The proportion of organic waste that is recovered and composted',
  'waste-to-energy':
    'The proportion of remaining landfill waste that is processed in an energy recovery facility',
};

const interventionAreaCategories = {
  'residential-building-standards': 'energy',
  'residential-retrofits': 'energy',
  'non-residential-retrofits': 'energy',
  'non-residential-building-standards': 'energy',
  'public-transit-electrification': 'transport',
  'residential-vkt-change': 'transport',
  'residential-mode-shift': 'transport',
  'ev-takeup': 'transport',
  'waste-reduction': 'waste',
  'recyclable-packaging': 'waste',
  'recyclable-material-management': 'waste',
  'recyclable-ewaste': 'waste',
  organics: 'waste',
  'waste-to-energy': 'waste',
};

const interventionCategoryLabels = {
  energy: 'Stationary energy',
  transport: 'Transport',
  waste: 'Waste',
};

const locations = mapValues(sortBy('label'), {
  lga: [
    {
      value: 47,
      label: 'Bayside LGA',
    },
    {
      value: 84,
      label: 'Blacktown LGA',
    },
    {
      value: 92,
      label: 'Blue Mountains LGA',
    },
    {
      value: 119,
      label: 'Burwood LGA',
    },
    {
      value: 130,
      label: 'Camden LGA',
    },
    {
      value: 137,
      label: 'Campbelltown LGA',
    },
    {
      value: 141,
      label: 'Canada Bay LGA',
    },
    {
      value: 146,
      label: 'Canterbury-Bankstown LGA',
    },
    {
      value: 228,
      label: 'Cumberland LGA',
    },
    {
      value: 295,
      label: 'Fairfield LGA',
    },
    {
      value: 313,
      label: 'Georges River LGA',
    },
    {
      value: 359,
      label: 'Hawkesbury LGA',
    },
    {
      value: 379,
      label: 'Hornsby LGA',
    },
    {
      value: 384,
      label: 'Hunters Hill LGA',
    },
    {
      value: 396,
      label: 'Inner West LGA',
    },
    {
      value: 426,
      label: 'Ku-Ring-Gai LGA',
    },
    {
      value: 440,
      label: 'Lane Cove LGA',
    },
    {
      value: 470,
      label: 'Liverpool LGA',
    },
    {
      value: 542,
      label: 'Mosman LGA',
    },
    {
      value: 591,
      label: 'North Sydney LGA',
    },
    {
      value: 597,
      label: 'Northern Beaches LGA',
    },
    {
      value: 621,
      label: 'Parramatta LGA',
    },
    {
      value: 629,
      label: 'Penrith LGA',
    },
    {
      value: 660,
      label: 'Randwick LGA',
    },
    {
      value: 702,
      label: 'Ryde LGA',
    },
    {
      value: 751,
      label: 'Strathfield LGA',
    },
    {
      value: 759,
      label: 'Sutherland Shire LGA',
    },
    {
      value: 762,
      label: 'Sydney LGA',
    },
    {
      value: 777,
      label: 'The Hills Shire LGA',
    },
    {
      value: 819,
      label: 'Waverley LGA',
    },
    {
      value: 847,
      label: 'Willoughby LGA',
    },
    {
      value: 860,
      label: 'Wollondilly LGA',
    },
    {
      value: 866,
      label: 'Woollahra LGA',
    },
    {
      label: 'Upper Hunter LGA',
      value: 1634,
    },
    {
      label: 'Singleton LGA',
      value: 1635,
    },
    {
      label: 'Muswellbrook LGA',
      value: 1636,
    },
    {
      label: 'Dungog LGA',
      value: 1637,
    },
    {
      label: 'Mid Coast LGA',
      value: 1638,
    },
  ],
  suburb: [
    {
      value: 0,
      label: 'Abbotsbury',
    },
    {
      value: 1,
      label: 'Abbotsford',
    },
    {
      value: 2,
      label: 'Acacia Gardens',
    },
    {
      value: 3,
      label: 'Agnes Banks (Hawkesbury LGA)',
    },
    {
      value: 4,
      label: 'Agnes Banks (Penrith LGA)',
    },
    {
      value: 5,
      label: 'Airds',
    },
    {
      value: 6,
      label: 'Alexandria',
    },
    {
      value: 7,
      label: 'Alfords Point',
    },
    {
      value: 8,
      label: 'Allambie Heights',
    },
    {
      value: 9,
      label: 'Allawah',
    },
    {
      value: 10,
      label: 'Ambarvale',
    },
    {
      value: 11,
      label: 'Annandale (Inner West LGA)',
    },
    {
      value: 12,
      label: 'Annandale (Sydney LGA)',
    },
    {
      value: 13,
      label: 'Annangrove',
    },
    {
      value: 14,
      label: 'Appin',
    },
    {
      value: 15,
      label: 'Arcadia',
    },
    {
      value: 16,
      label: 'Arncliffe',
    },
    {
      value: 17,
      label: 'Arndell Park',
    },
    {
      value: 18,
      label: 'Artarmon',
    },
    {
      value: 19,
      label: 'Ashbury (Canterbury-Bankstown LGA)',
    },
    {
      value: 20,
      label: 'Ashbury (Inner West LGA)',
    },
    {
      value: 21,
      label: 'Ashcroft',
    },
    {
      value: 22,
      label: 'Ashfield',
    },
    {
      value: 23,
      label: 'Asquith',
    },
    {
      value: 24,
      label: 'Auburn',
    },
    {
      value: 25,
      label: 'Austral',
    },
    {
      value: 26,
      label: 'Avalon Beach',
    },
    {
      value: 27,
      label: 'Badgerys Creek (Liverpool LGA)',
    },
    {
      value: 28,
      label: 'Badgerys Creek (Penrith LGA)',
    },
    {
      value: 29,
      label: 'Balgowlah',
    },
    {
      value: 30,
      label: 'Balgowlah Heights',
    },
    {
      value: 31,
      label: 'Balmain',
    },
    {
      value: 32,
      label: 'Balmain East',
    },
    {
      value: 33,
      label: 'Bangor',
    },
    {
      value: 34,
      label: 'Banksia',
    },
    {
      value: 35,
      label: 'Banksmeadow',
    },
    {
      value: 36,
      label: 'Bankstown',
    },
    {
      value: 37,
      label: 'Bankstown Aerodrome',
    },
    {
      value: 38,
      label: 'Barangaroo',
    },
    {
      value: 39,
      label: 'Barden Ridge',
    },
    {
      value: 40,
      label: 'Bardia',
    },
    {
      value: 41,
      label: 'Bardwell Park',
    },
    {
      value: 42,
      label: 'Bardwell Valley',
    },
    {
      value: 43,
      label: 'Bargo',
    },
    {
      value: 44,
      label: 'Bass Hill',
    },
    {
      value: 45,
      label: 'Baulkham Hills (Parramatta LGA)',
    },
    {
      value: 46,
      label: 'Baulkham Hills (The Hills Shire LGA)',
    },
    {
      value: 48,
      label: 'Bayview',
    },
    {
      value: 49,
      label: 'Beacon Hill',
    },
    {
      value: 50,
      label: 'Beaconsfield',
    },
    {
      value: 51,
      label: 'Beaumont Hills',
    },
    {
      value: 52,
      label: 'Beecroft (Hornsby LGA)',
    },
    {
      value: 53,
      label: 'Beecroft (Parramatta LGA)',
    },
    {
      value: 54,
      label: 'Belfield (Canterbury-Bankstown LGA)',
    },
    {
      value: 55,
      label: 'Belfield (Strathfield LGA)',
    },
    {
      value: 56,
      label: 'Belimbla Park',
    },
    {
      value: 57,
      label: 'Bell',
    },
    {
      value: 58,
      label: 'Bella Vista',
    },
    {
      value: 59,
      label: 'Bellevue Hill',
    },
    {
      value: 60,
      label: 'Belmore',
    },
    {
      value: 61,
      label: 'Belrose',
    },
    {
      value: 62,
      label: 'Berala',
    },
    {
      value: 63,
      label: 'Berambing (Blue Mountains LGA)',
    },
    {
      value: 64,
      label: 'Berambing (Hawkesbury LGA)',
    },
    {
      value: 65,
      label: 'Berkshire Park',
    },
    {
      value: 66,
      label: 'Berowra',
    },
    {
      value: 67,
      label: 'Berowra Creek',
    },
    {
      value: 68,
      label: 'Berowra Heights',
    },
    {
      value: 69,
      label: 'Berowra Waters',
    },
    {
      value: 70,
      label: 'Berrilee',
    },
    {
      value: 71,
      label: 'Beverley Park',
    },
    {
      value: 72,
      label: 'Beverly Hills (Canterbury-Bankstown LGA)',
    },
    {
      value: 73,
      label: 'Beverly Hills (Georges River LGA)',
    },
    {
      value: 74,
      label: 'Bexley',
    },
    {
      value: 75,
      label: 'Bexley North',
    },
    {
      value: 76,
      label: 'Bidwill',
    },
    {
      value: 77,
      label: 'Bilgola Beach',
    },
    {
      value: 78,
      label: 'Bilgola Plateau',
    },
    {
      value: 79,
      label: 'Bilpin',
    },
    {
      value: 80,
      label: 'Birchgrove',
    },
    {
      value: 81,
      label: 'Birrong',
    },
    {
      value: 82,
      label: 'Blackett',
    },
    {
      value: 83,
      label: 'Blackheath',
    },
    {
      value: 85,
      label: 'Blacktown',
    },
    {
      value: 86,
      label: 'Blair Athol',
    },
    {
      value: 87,
      label: 'Blairmount',
    },
    {
      value: 88,
      label: 'Blakehurst',
    },
    {
      value: 89,
      label: 'Blaxland',
    },
    {
      value: 90,
      label: 'Blaxlands Ridge',
    },
    {
      value: 91,
      label: 'Bligh Park',
    },
    {
      value: 93,
      label: 'Blue Mountains National Park (Blue Mountains LGA)',
    },
    {
      value: 94,
      label: 'Blue Mountains National Park (Wollondilly LGA)',
    },
    {
      value: 95,
      label: 'Bondi',
    },
    {
      value: 96,
      label: 'Bondi Beach',
    },
    {
      value: 97,
      label: 'Bondi Junction',
    },
    {
      value: 98,
      label: 'Bonnet Bay',
    },
    {
      value: 99,
      label: 'Bonnyrigg',
    },
    {
      value: 100,
      label: 'Bonnyrigg Heights',
    },
    {
      value: 101,
      label: 'Bossley Park',
    },
    {
      value: 102,
      label: 'Botany',
    },
    {
      value: 103,
      label: 'Bow Bowing',
    },
    {
      value: 104,
      label: 'Bowen Mountain',
    },
    {
      value: 105,
      label: 'Box Hill',
    },
    {
      value: 106,
      label: 'Bradbury',
    },
    {
      value: 107,
      label: 'Breakfast Point',
    },
    {
      value: 108,
      label: 'Brighton-Le-Sands',
    },
    {
      value: 109,
      label: 'Bringelly (Camden LGA)',
    },
    {
      value: 110,
      label: 'Bringelly (Liverpool LGA)',
    },
    {
      value: 111,
      label: 'Bronte',
    },
    {
      value: 112,
      label: 'Brooklyn',
    },
    {
      value: 113,
      label: 'Brookvale',
    },
    {
      value: 114,
      label: 'Brownlow Hill',
    },
    {
      value: 115,
      label: 'Bullaburra',
    },
    {
      value: 116,
      label: 'Bundeena',
    },
    {
      value: 117,
      label: 'Bungarribee',
    },
    {
      value: 118,
      label: 'Burraneer',
    },
    {
      value: 120,
      label: 'Burwood',
    },
    {
      value: 121,
      label: 'Burwood Heights',
    },
    {
      value: 122,
      label: 'Busby',
    },
    {
      value: 123,
      label: 'Buxton',
    },
    {
      value: 124,
      label: 'Cabarita',
    },
    {
      value: 125,
      label: 'Cabramatta',
    },
    {
      value: 126,
      label: 'Cabramatta West',
    },
    {
      value: 127,
      label: 'Caddens',
    },
    {
      value: 128,
      label: 'Cambridge Gardens',
    },
    {
      value: 129,
      label: 'Cambridge Park',
    },
    {
      value: 131,
      label: 'Camden',
    },
    {
      value: 132,
      label: 'Camden Park',
    },
    {
      value: 133,
      label: 'Camden South',
    },
    {
      value: 134,
      label: 'Camellia',
    },
    {
      value: 135,
      label: 'Cammeray',
    },
    {
      value: 136,
      label: 'Campbelltown',
    },
    {
      value: 138,
      label: 'Camperdown (Inner West LGA)',
    },
    {
      value: 139,
      label: 'Camperdown (Sydney LGA)',
    },
    {
      value: 140,
      label: 'Campsie',
    },
    {
      value: 142,
      label: 'Canada Bay',
    },
    {
      value: 143,
      label: 'Canley Heights',
    },
    {
      value: 144,
      label: 'Canley Vale',
    },
    {
      value: 145,
      label: 'Canoelands',
    },
    {
      value: 147,
      label: 'Canterbury',
    },
    {
      value: 148,
      label: 'Caringbah',
    },
    {
      value: 149,
      label: 'Caringbah South',
    },
    {
      value: 150,
      label: 'Carlingford (Parramatta LGA)',
    },
    {
      value: 151,
      label: 'Carlingford (The Hills Shire LGA)',
    },
    {
      value: 152,
      label: 'Carlton (Georges River LGA)',
    },
    {
      value: 153,
      label: 'Carlton (Bayside LGA)',
    },
    {
      value: 154,
      label: 'Carnes Hill',
    },
    {
      value: 155,
      label: 'Carramar',
    },
    {
      value: 156,
      label: 'Carss Park',
    },
    {
      value: 157,
      label: 'Cartwright',
    },
    {
      value: 158,
      label: 'Castle Cove',
    },
    {
      value: 159,
      label: 'Castle Hill (Hornsby LGA)',
    },
    {
      value: 160,
      label: 'Castle Hill (The Hills Shire LGA)',
    },
    {
      value: 161,
      label: 'Castlecrag',
    },
    {
      value: 162,
      label: 'Castlereagh',
    },
    {
      value: 163,
      label: 'Casula',
    },
    {
      value: 164,
      label: 'Catherine Field',
    },
    {
      value: 165,
      label: 'Cattai (Hawkesbury LGA)',
    },
    {
      value: 166,
      label: 'Cattai (The Hills Shire LGA)',
    },
    {
      value: 167,
      label: 'Cawdor (Camden LGA)',
    },
    {
      value: 168,
      label: 'Cawdor (Wollondilly LGA)',
    },
    {
      value: 169,
      label: 'Cecil Hills',
    },
    {
      value: 170,
      label: 'Cecil Park (Fairfield LGA)',
    },
    {
      value: 171,
      label: 'Cecil Park (Liverpool LGA)',
    },
    {
      value: 172,
      label: 'Centennial Park (Randwick LGA)',
    },
    {
      value: 173,
      label: 'Centennial Park (Sydney LGA)',
    },
    {
      value: 174,
      label: 'Central Colo',
    },
    {
      value: 175,
      label: 'Central Macdonald',
    },
    {
      value: 176,
      label: 'Chatswood',
    },
    {
      value: 177,
      label: 'Chatswood West (Ryde LGA)',
    },
    {
      value: 178,
      label: 'Chatswood West (Willoughby LGA)',
    },
    {
      value: 179,
      label: 'Cheltenham (Hornsby LGA)',
    },
    {
      value: 180,
      label: 'Cheltenham (Parramatta LGA)',
    },
    {
      value: 181,
      label: 'Cherrybrook',
    },
    {
      value: 182,
      label: 'Chester Hill (Canterbury-Bankstown LGA)',
    },
    {
      value: 183,
      label: 'Chester Hill (Cumberland LGA)',
    },
    {
      value: 184,
      label: 'Chifley',
    },
    {
      value: 185,
      label: 'Chippendale',
    },
    {
      value: 186,
      label: 'Chipping Norton',
    },
    {
      value: 187,
      label: 'Chiswick',
    },
    {
      value: 188,
      label: 'Chullora',
    },
    {
      value: 189,
      label: 'Church Point',
    },
    {
      value: 190,
      label: 'Claremont Meadows',
    },
    {
      value: 191,
      label: 'Clarendon',
    },
    {
      value: 192,
      label: 'Clareville',
    },
    {
      value: 193,
      label: 'Claymore',
    },
    {
      value: 194,
      label: 'Clemton Park',
    },
    {
      value: 195,
      label: 'Clontarf',
    },
    {
      value: 196,
      label: 'Clovelly',
    },
    {
      value: 197,
      label: 'Clyde',
    },
    {
      value: 198,
      label: 'Coasters Retreat',
    },
    {
      value: 199,
      label: 'Cobbitty',
    },
    {
      value: 200,
      label: 'Colebee',
    },
    {
      value: 201,
      label: 'Collaroy',
    },
    {
      value: 202,
      label: 'Collaroy Plateau',
    },
    {
      value: 203,
      label: 'Colo',
    },
    {
      value: 204,
      label: 'Colo Heights',
    },
    {
      value: 205,
      label: 'Colyton',
    },
    {
      value: 206,
      label: 'Como',
    },
    {
      value: 207,
      label: 'Concord',
    },
    {
      value: 208,
      label: 'Concord West',
    },
    {
      value: 209,
      label: 'Condell Park',
    },
    {
      value: 210,
      label: 'Connells Point',
    },
    {
      value: 211,
      label: 'Constitution Hill',
    },
    {
      value: 212,
      label: 'Coogee',
    },
    {
      value: 213,
      label: 'Cornwallis',
    },
    {
      value: 214,
      label: 'Cottage Point',
    },
    {
      value: 215,
      label: 'Couridjah',
    },
    {
      value: 216,
      label: 'Cowan',
    },
    {
      value: 217,
      label: 'Cranebrook',
    },
    {
      value: 218,
      label: 'Cremorne',
    },
    {
      value: 219,
      label: 'Cremorne Point',
    },
    {
      value: 220,
      label: 'Cromer',
    },
    {
      value: 221,
      label: 'Cronulla',
    },
    {
      value: 222,
      label: 'Crows Nest',
    },
    {
      value: 223,
      label: 'Croydon (Burwood LGA)',
    },
    {
      value: 224,
      label: 'Croydon (Inner West LGA)',
    },
    {
      value: 225,
      label: 'Croydon Park (Burwood LGA)',
    },
    {
      value: 226,
      label: 'Croydon Park (Canterbury-Bankstown LGA)',
    },
    {
      value: 227,
      label: 'Croydon Park (Inner West LGA)',
    },
    {
      value: 229,
      label: 'Cumberland Reach',
    },
    {
      value: 230,
      label: 'Curl Curl',
    },
    {
      value: 231,
      label: 'Currans Hill',
    },
    {
      value: 232,
      label: 'Daceyville',
    },
    {
      value: 233,
      label: 'Dangar Island',
    },
    {
      value: 234,
      label: 'Darling Point',
    },
    {
      value: 235,
      label: 'Darlinghurst',
    },
    {
      value: 236,
      label: 'Darlington',
    },
    {
      value: 237,
      label: 'Davidson',
    },
    {
      value: 238,
      label: 'Dawes Point',
    },
    {
      value: 239,
      label: 'Dean Park',
    },
    {
      value: 240,
      label: 'Dee Why',
    },
    {
      value: 241,
      label: 'Denham Court (Campbelltown LGA)',
    },
    {
      value: 242,
      label: 'Denham Court (Liverpool LGA)',
    },
    {
      value: 243,
      label: 'Denistone',
    },
    {
      value: 244,
      label: 'Denistone East',
    },
    {
      value: 245,
      label: 'Denistone West',
    },
    {
      value: 246,
      label: 'Dharruk',
    },
    {
      value: 247,
      label: 'Dolans Bay',
    },
    {
      value: 248,
      label: 'Dolls Point',
    },
    {
      value: 249,
      label: 'Doonside',
    },
    {
      value: 250,
      label: 'Double Bay',
    },
    {
      value: 251,
      label: 'Douglas Park',
    },
    {
      value: 252,
      label: 'Dover Heights',
    },
    {
      value: 253,
      label: 'Drummoyne',
    },
    {
      value: 254,
      label: 'Duffys Forest',
    },
    {
      value: 255,
      label: 'Dulwich Hill',
    },
    {
      value: 256,
      label: 'Dundas',
    },
    {
      value: 257,
      label: 'Dundas Valley',
    },
    {
      value: 258,
      label: 'Dural (Hornsby LGA)',
    },
    {
      value: 259,
      label: 'Dural (The Hills Shire LGA)',
    },
    {
      value: 260,
      label: 'Eagle Vale',
    },
    {
      value: 261,
      label: 'Earlwood',
    },
    {
      value: 262,
      label: 'East Hills',
    },
    {
      value: 263,
      label: 'East Killara',
    },
    {
      value: 264,
      label: 'East Kurrajong',
    },
    {
      value: 265,
      label: 'East Lindfield',
    },
    {
      value: 266,
      label: 'East Ryde',
    },
    {
      value: 267,
      label: 'Eastern Creek',
    },
    {
      value: 268,
      label: 'Eastgardens',
    },
    {
      value: 269,
      label: 'Eastlakes',
    },
    {
      value: 270,
      label: 'Eastwood (Parramatta LGA)',
    },
    {
      value: 271,
      label: 'Eastwood (Ryde LGA)',
    },
    {
      value: 272,
      label: 'Ebenezer',
    },
    {
      value: 273,
      label: 'Edensor Park',
    },
    {
      value: 274,
      label: 'Edgecliff',
    },
    {
      value: 275,
      label: 'Edmondson Park',
    },
    {
      value: 276,
      label: 'Elanora Heights',
    },
    {
      value: 277,
      label: 'Elderslie',
    },
    {
      value: 278,
      label: 'Elizabeth Bay',
    },
    {
      value: 279,
      label: 'Elizabeth Hills',
    },
    {
      value: 280,
      label: 'Ellis Lane',
    },
    {
      value: 281,
      label: 'Elvina Bay',
    },
    {
      value: 282,
      label: 'Emerton',
    },
    {
      value: 283,
      label: 'Emu Heights',
    },
    {
      value: 284,
      label: 'Emu Plains',
    },
    {
      value: 285,
      label: 'Enfield',
    },
    {
      value: 286,
      label: 'Engadine',
    },
    {
      value: 287,
      label: 'Englorie Park',
    },
    {
      value: 288,
      label: 'Enmore',
    },
    {
      value: 289,
      label: 'Epping',
    },
    {
      value: 290,
      label: 'Ermington',
    },
    {
      value: 291,
      label: 'Erskine Park',
    },
    {
      value: 292,
      label: 'Erskineville',
    },
    {
      value: 293,
      label: 'Eschol Park',
    },
    {
      value: 294,
      label: 'Eveleigh',
    },
    {
      value: 296,
      label: 'Fairfield (Cumberland LGA)',
    },
    {
      value: 297,
      label: 'Fairfield East',
    },
    {
      value: 298,
      label: 'Fairfield (Fairfield LGA)',
    },
    {
      value: 299,
      label: 'Fairfield Heights',
    },
    {
      value: 300,
      label: 'Fairfield West',
    },
    {
      value: 301,
      label: 'Fairlight',
    },
    {
      value: 302,
      label: 'Faulconbridge',
    },
    {
      value: 303,
      label: 'Fiddletown',
    },
    {
      value: 304,
      label: 'Five Dock',
    },
    {
      value: 305,
      label: 'Forest Glen',
    },
    {
      value: 306,
      label: 'Forest Lodge',
    },
    {
      value: 307,
      label: 'Forestville',
    },
    {
      value: 308,
      label: 'Freemans Reach',
    },
    {
      value: 309,
      label: 'Frenchs Forest',
    },
    {
      value: 310,
      label: 'Freshwater',
    },
    {
      value: 311,
      label: 'Galston',
    },
    {
      value: 312,
      label: 'Georges Hall',
    },
    {
      value: 314,
      label: 'Gilead',
    },
    {
      value: 315,
      label: 'Girraween',
    },
    {
      value: 316,
      label: 'Gladesville (Hunters Hill LGA)',
    },
    {
      value: 317,
      label: 'Gladesville (Ryde LGA)',
    },
    {
      value: 318,
      label: 'Glebe',
    },
    {
      value: 319,
      label: 'Gledswood Hills',
    },
    {
      value: 320,
      label: 'Glen Alpine',
    },
    {
      value: 321,
      label: 'Glenbrook',
    },
    {
      value: 322,
      label: 'Glendenning',
    },
    {
      value: 323,
      label: 'Glenfield',
    },
    {
      value: 324,
      label: 'Glenhaven (Hornsby LGA)',
    },
    {
      value: 325,
      label: 'Glenhaven (The Hills Shire LGA)',
    },
    {
      value: 326,
      label: 'Glenmore Park',
    },
    {
      value: 327,
      label: 'Glenmore',
    },
    {
      value: 328,
      label: 'Glenorie (Hornsby LGA)',
    },
    {
      value: 329,
      label: 'Glenorie (The Hills Shire LGA)',
    },
    {
      value: 330,
      label: 'Glenwood',
    },
    {
      value: 331,
      label: 'Glossodia',
    },
    {
      value: 332,
      label: 'Gordon',
    },
    {
      value: 333,
      label: 'Granville (Cumberland LGA)',
    },
    {
      value: 334,
      label: 'Granville (Parramatta LGA)',
    },
    {
      value: 335,
      label: 'Grasmere',
    },
    {
      value: 336,
      label: 'Grays Point',
    },
    {
      value: 337,
      label: 'Great Mackerel Beach',
    },
    {
      value: 338,
      label: 'Green Valley',
    },
    {
      value: 339,
      label: 'Greenacre (Canterbury-Bankstown LGA)',
    },
    {
      value: 340,
      label: 'Greenacre (Strathfield LGA)',
    },
    {
      value: 341,
      label: 'Greendale',
    },
    {
      value: 342,
      label: 'Greenfield Park',
    },
    {
      value: 343,
      label: 'Greenhills Beach',
    },
    {
      value: 344,
      label: 'Greenwich',
    },
    {
      value: 345,
      label: 'Gregory Hills (Camden LGA)',
    },
    {
      value: 346,
      label: 'Gregory Hills (Campbelltown LGA)',
    },
    {
      value: 347,
      label: 'Greystanes',
    },
    {
      value: 348,
      label: 'Grose Vale',
    },
    {
      value: 349,
      label: 'Grose Wold',
    },
    {
      value: 350,
      label: 'Guildford',
    },
    {
      value: 351,
      label: 'Guildford West',
    },
    {
      value: 352,
      label: 'Gymea',
    },
    {
      value: 353,
      label: 'Gymea Bay',
    },
    {
      value: 354,
      label: 'Haberfield',
    },
    {
      value: 355,
      label: 'Hammondville',
    },
    {
      value: 356,
      label: 'Harrington Park',
    },
    {
      value: 357,
      label: 'Harris Park',
    },
    {
      value: 358,
      label: 'Hassall Grove',
    },
    {
      value: 360,
      label: 'Hawkesbury Heights',
    },
    {
      value: 361,
      label: 'Haymarket',
    },
    {
      value: 362,
      label: 'Hazelbrook',
    },
    {
      value: 363,
      label: 'Heathcote',
    },
    {
      value: 364,
      label: 'Hebersham',
    },
    {
      value: 365,
      label: 'Heckenberg',
    },
    {
      value: 366,
      label: 'Henley',
    },
    {
      value: 367,
      label: 'Hillsdale',
    },
    {
      value: 368,
      label: 'Hinchinbrook',
    },
    {
      value: 369,
      label: 'Hobartville',
    },
    {
      value: 370,
      label: 'Holroyd (Cumberland LGA)',
    },
    {
      value: 371,
      label: 'Holroyd (Parramatta LGA)',
    },
    {
      value: 372,
      label: 'Holsworthy (Campbelltown LGA)',
    },
    {
      value: 373,
      label: 'Holsworthy (Liverpool LGA)',
    },
    {
      value: 374,
      label: 'Holsworthy (Sutherland Shire LGA)',
    },
    {
      value: 375,
      label: 'Homebush (Canada Bay LGA)',
    },
    {
      value: 376,
      label: 'Homebush (Strathfield LGA)',
    },
    {
      value: 377,
      label: 'Homebush West',
    },
    {
      value: 378,
      label: 'Horningsea Park',
    },
    {
      value: 380,
      label: 'Hornsby Heights',
    },
    {
      value: 381,
      label: 'Hornsby',
    },
    {
      value: 382,
      label: 'Horsley Park',
    },
    {
      value: 383,
      label: 'Hoxton Park',
    },
    {
      value: 385,
      label: 'Hunters Hill',
    },
    {
      value: 386,
      label: 'Huntingwood',
    },
    {
      value: 387,
      label: 'Huntleys Cove',
    },
    {
      value: 388,
      label: 'Huntleys Point',
    },
    {
      value: 389,
      label: 'Hurlstone Park (Canterbury-Bankstown LGA)',
    },
    {
      value: 390,
      label: 'Hurlstone Park (Inner West LGA)',
    },
    {
      value: 391,
      label: 'Hurstville',
    },
    {
      value: 392,
      label: 'Hurstville Grove',
    },
    {
      value: 393,
      label: 'Illawong',
    },
    {
      value: 394,
      label: 'Ingleburn',
    },
    {
      value: 395,
      label: 'Ingleside',
    },
    {
      value: 397,
      label: 'Jamisontown',
    },
    {
      value: 398,
      label: 'Jannali',
    },
    {
      value: 399,
      label: 'Jordan Springs',
    },
    {
      value: 400,
      label: 'Kangaroo Point',
    },
    {
      value: 401,
      label: 'Kareela',
    },
    {
      value: 402,
      label: 'Katoomba',
    },
    {
      value: 403,
      label: 'Kearns',
    },
    {
      value: 404,
      label: 'Kellyville',
    },
    {
      value: 405,
      label: 'Kellyville Ridge',
    },
    {
      value: 406,
      label: 'Kemps Creek (Liverpool LGA)',
    },
    {
      value: 407,
      label: 'Kemps Creek (Penrith LGA)',
    },
    {
      value: 408,
      label: 'Kensington',
    },
    {
      value: 409,
      label: 'Kenthurst',
    },
    {
      value: 410,
      label: 'Kentlyn',
    },
    {
      value: 411,
      label: 'Killara',
    },
    {
      value: 412,
      label: 'Killarney Heights',
    },
    {
      value: 413,
      label: 'Kings Langley',
    },
    {
      value: 414,
      label: 'Kings Park',
    },
    {
      value: 415,
      label: 'Kingsford',
    },
    {
      value: 416,
      label: 'Kingsgrove (Canterbury-Bankstown LGA)',
    },
    {
      value: 417,
      label: 'Kingsgrove (Georges River LGA)',
    },
    {
      value: 418,
      label: 'Kingsgrove (Bayside LGA)',
    },
    {
      value: 419,
      label: 'Kingswood',
    },
    {
      value: 420,
      label: 'Kirkham',
    },
    {
      value: 421,
      label: 'Kirrawee',
    },
    {
      value: 422,
      label: 'Kirribilli',
    },
    {
      value: 423,
      label: 'Kogarah Bay',
    },
    {
      value: 424,
      label: 'Kogarah (Georges River LGA)',
    },
    {
      value: 425,
      label: 'Kogarah (Bayside LGA)',
    },
    {
      value: 427,
      label: 'Ku-Ring-Gai Chase',
    },
    {
      value: 428,
      label: 'Kurmond',
    },
    {
      value: 429,
      label: 'Kurnell',
    },
    {
      value: 430,
      label: 'Kurraba Point',
    },
    {
      value: 431,
      label: 'Kurrajong',
    },
    {
      value: 432,
      label: 'Kurrajong Heights',
    },
    {
      value: 433,
      label: 'Kurrajong Hills',
    },
    {
      value: 434,
      label: 'Kyeemagh',
    },
    {
      value: 435,
      label: 'Kyle Bay',
    },
    {
      value: 436,
      label: 'La Perouse',
    },
    {
      value: 437,
      label: 'Lakemba',
    },
    {
      value: 438,
      label: 'Lakesland',
    },
    {
      value: 439,
      label: 'Lalor Park',
    },
    {
      value: 441,
      label: 'Lane Cove',
    },
    {
      value: 442,
      label: 'Lane Cove North (Lane Cove LGA)',
    },
    {
      value: 443,
      label: 'Lane Cove North (Willoughby LGA)',
    },
    {
      value: 444,
      label: 'Lane Cove West',
    },
    {
      value: 445,
      label: 'Lansdowne',
    },
    {
      value: 446,
      label: 'Lansvale',
    },
    {
      value: 447,
      label: 'Lapstone',
    },
    {
      value: 448,
      label: 'Lavender Bay',
    },
    {
      value: 449,
      label: 'Lawson',
    },
    {
      value: 450,
      label: 'Leets Vale (Hawkesbury LGA)',
    },
    {
      value: 451,
      label: 'Leets Vale (The Hills Shire LGA)',
    },
    {
      value: 452,
      label: 'Leichhardt',
    },
    {
      value: 453,
      label: 'Len Waters Estate',
    },
    {
      value: 454,
      label: 'Leonay',
    },
    {
      value: 455,
      label: 'Leppington (Camden LGA)',
    },
    {
      value: 456,
      label: 'Leppington (Liverpool LGA)',
    },
    {
      value: 457,
      label: 'Lethbridge Park',
    },
    {
      value: 458,
      label: 'Leumeah',
    },
    {
      value: 459,
      label: 'Leura',
    },
    {
      value: 460,
      label: 'Lewisham',
    },
    {
      value: 461,
      label: 'Liberty Grove',
    },
    {
      value: 462,
      label: 'Lidcombe (Cumberland LGA)',
    },
    {
      value: 463,
      label: 'Lidcombe (Parramatta LGA)',
    },
    {
      value: 464,
      label: 'Lilli Pilli',
    },
    {
      value: 465,
      label: 'Lilyfield',
    },
    {
      value: 466,
      label: 'Linden',
    },
    {
      value: 467,
      label: 'Lindfield',
    },
    {
      value: 468,
      label: 'Linley Point',
    },
    {
      value: 469,
      label: 'Little Bay',
    },
    {
      value: 471,
      label: 'Liverpool',
    },
    {
      value: 472,
      label: 'Llandilo',
    },
    {
      value: 473,
      label: 'Loftus',
    },
    {
      value: 474,
      label: 'Londonderry',
    },
    {
      value: 475,
      label: 'Long Point',
    },
    {
      value: 476,
      label: 'Longueville',
    },
    {
      value: 477,
      label: 'Lovett Bay',
    },
    {
      value: 478,
      label: 'Lower Macdonald',
    },
    {
      value: 479,
      label: 'Lower Portland (Hawkesbury LGA)',
    },
    {
      value: 480,
      label: 'Lower Portland (The Hills Shire LGA)',
    },
    {
      value: 481,
      label: 'Lucas Heights',
    },
    {
      value: 482,
      label: 'Luddenham (Liverpool LGA)',
    },
    {
      value: 483,
      label: 'Luddenham (Penrith LGA)',
    },
    {
      value: 484,
      label: 'Lugarno',
    },
    {
      value: 485,
      label: 'Lurnea',
    },
    {
      value: 486,
      label: 'Macquarie Fields',
    },
    {
      value: 487,
      label: 'Macquarie Links',
    },
    {
      value: 488,
      label: 'Macquarie Park',
    },
    {
      value: 489,
      label: 'Maianbar',
    },
    {
      value: 490,
      label: 'Malabar',
    },
    {
      value: 491,
      label: 'Maldon',
    },
    {
      value: 492,
      label: 'Manly',
    },
    {
      value: 493,
      label: 'Manly Vale',
    },
    {
      value: 494,
      label: 'Maraylya (Hawkesbury LGA)',
    },
    {
      value: 495,
      label: 'Maraylya (The Hills Shire LGA)',
    },
    {
      value: 496,
      label: 'Marayong',
    },
    {
      value: 497,
      label: 'Maroota (Hornsby LGA)',
    },
    {
      value: 498,
      label: 'Maroota (The Hills Shire LGA)',
    },
    {
      value: 499,
      label: 'Maroubra',
    },
    {
      value: 500,
      label: 'Marrickville',
    },
    {
      value: 501,
      label: 'Marsden Park',
    },
    {
      value: 502,
      label: 'Marsfield',
    },
    {
      value: 503,
      label: 'Mascot (Bayside LGA, formerly Botany Bay LGA)',
    },
    {
      value: 504,
      label: 'Mascot (Inner West LGA)',
    },
    {
      value: 505,
      label: 'Mascot (Bayside LGA, formerly Rockdale LGA)',
    },
    {
      value: 506,
      label: 'Matraville',
    },
    {
      value: 507,
      label: 'Mays Hill (Cumberland LGA)',
    },
    {
      value: 508,
      label: 'Mays Hill (Parramatta LGA)',
    },
    {
      value: 509,
      label: 'Mcgraths Hill',
    },
    {
      value: 510,
      label: 'Mcmahons Point',
    },
    {
      value: 511,
      label: 'Meadowbank',
    },
    {
      value: 512,
      label: 'Medlow Bath',
    },
    {
      value: 513,
      label: 'Megalong Valley',
    },
    {
      value: 514,
      label: 'Melrose Park (Parramatta LGA)',
    },
    {
      value: 515,
      label: 'Melrose Park (Ryde LGA)',
    },
    {
      value: 516,
      label: 'Menai',
    },
    {
      value: 517,
      label: 'Menangle',
    },
    {
      value: 518,
      label: 'Menangle Park',
    },
    {
      value: 519,
      label: 'Merrylands (Cumberland LGA)',
    },
    {
      value: 520,
      label: 'Merrylands (Parramatta LGA)',
    },
    {
      value: 521,
      label: 'Merrylands West',
    },
    {
      value: 522,
      label: 'Middle Cove',
    },
    {
      value: 523,
      label: 'Middle Dural (Hornsby LGA)',
    },
    {
      value: 524,
      label: 'Middle Dural (The Hills Shire LGA)',
    },
    {
      value: 525,
      label: 'Middleton Grange',
    },
    {
      value: 526,
      label: 'Miller',
    },
    {
      value: 527,
      label: 'Millers Point',
    },
    {
      value: 528,
      label: 'Milperra',
    },
    {
      value: 529,
      label: 'Milsons Passage',
    },
    {
      value: 530,
      label: 'Milsons Point',
    },
    {
      value: 531,
      label: 'Minchinbury',
    },
    {
      value: 532,
      label: 'Minto',
    },
    {
      value: 533,
      label: 'Minto Heights',
    },
    {
      value: 534,
      label: 'Miranda',
    },
    {
      value: 535,
      label: 'Mona Vale',
    },
    {
      value: 536,
      label: 'Monterey',
    },
    {
      value: 537,
      label: 'Moore Park',
    },
    {
      value: 538,
      label: 'Moorebank',
    },
    {
      value: 539,
      label: 'Morning Bay',
    },
    {
      value: 540,
      label: 'Mortdale',
    },
    {
      value: 541,
      label: 'Mortlake',
    },
    {
      value: 543,
      label: 'Mosman',
    },
    {
      value: 544,
      label: 'Mount Annan (Camden LGA)',
    },
    {
      value: 545,
      label: 'Mount Annan (Campbelltown LGA)',
    },
    {
      value: 546,
      label: 'Mount Colah',
    },
    {
      value: 547,
      label: 'Mount Druitt',
    },
    {
      value: 548,
      label: 'Mount Hunter',
    },
    {
      value: 549,
      label: 'Mount Irvine',
    },
    {
      value: 550,
      label: 'Mount Kuring-Gai',
    },
    {
      value: 551,
      label: 'Mount Lewis',
    },
    {
      value: 552,
      label: 'Mount Pritchard (Fairfield LGA)',
    },
    {
      value: 553,
      label: 'Mount Pritchard (Liverpool LGA)',
    },
    {
      value: 554,
      label: 'Mount Riverview',
    },
    {
      value: 555,
      label: 'Mount Tomah',
    },
    {
      value: 556,
      label: 'Mount Vernon',
    },
    {
      value: 557,
      label: 'Mount Victoria',
    },
    {
      value: 558,
      label: 'Mount Wilson',
    },
    {
      value: 559,
      label: 'Mountain Lagoon',
    },
    {
      value: 560,
      label: 'Mowbray Park',
    },
    {
      value: 561,
      label: 'Mulgoa',
    },
    {
      value: 562,
      label: 'Mulgrave',
    },
    {
      value: 563,
      label: 'Narellan',
    },
    {
      value: 564,
      label: 'Narellan Vale',
    },
    {
      value: 565,
      label: 'Naremburn',
    },
    {
      value: 566,
      label: 'Narrabeen',
    },
    {
      value: 567,
      label: 'Narraweena',
    },
    {
      value: 568,
      label: 'Narwee (Canterbury-Bankstown LGA)',
    },
    {
      value: 569,
      label: 'Narwee (Georges River LGA)',
    },
    {
      value: 570,
      label: 'Nattai',
    },
    {
      value: 571,
      label: 'Nelson',
    },
    {
      value: 572,
      label: 'Neutral Bay',
    },
    {
      value: 573,
      label: 'Newington',
    },
    {
      value: 574,
      label: 'Newport',
    },
    {
      value: 575,
      label: 'Newtown (Inner West LGA)',
    },
    {
      value: 576,
      label: 'Newtown (Sydney LGA)',
    },
    {
      value: 577,
      label: 'Normanhurst',
    },
    {
      value: 578,
      label: 'North Balgowlah',
    },
    {
      value: 579,
      label: 'North Bondi',
    },
    {
      value: 580,
      label: 'North Curl Curl',
    },
    {
      value: 581,
      label: 'North Epping',
    },
    {
      value: 582,
      label: 'North Manly',
    },
    {
      value: 583,
      label: 'North Narrabeen',
    },
    {
      value: 584,
      label: 'North Parramatta',
    },
    {
      value: 585,
      label: 'North Richmond',
    },
    {
      value: 586,
      label: 'North Rocks (Parramatta LGA)',
    },
    {
      value: 587,
      label: 'North Rocks (The Hills Shire LGA)',
    },
    {
      value: 588,
      label: 'North Ryde',
    },
    {
      value: 589,
      label: 'North St Marys',
    },
    {
      value: 590,
      label: 'North Strathfield',
    },
    {
      value: 592,
      label: 'North Sydney',
    },
    {
      value: 593,
      label: 'North Turramurra',
    },
    {
      value: 594,
      label: 'North Wahroonga',
    },
    {
      value: 595,
      label: 'North Willoughby',
    },
    {
      value: 596,
      label: 'Northbridge',
    },
    {
      value: 598,
      label: 'Northmead',
    },
    {
      value: 599,
      label: 'Northwood',
    },
    {
      value: 600,
      label: 'Oakdale',
    },
    {
      value: 601,
      label: 'Oakhurst',
    },
    {
      value: 602,
      label: 'Oakville',
    },
    {
      value: 603,
      label: 'Oatlands',
    },
    {
      value: 604,
      label: 'Oatley',
    },
    {
      value: 605,
      label: 'Old Guildford',
    },
    {
      value: 606,
      label: 'Old Toongabbie',
    },
    {
      value: 607,
      label: 'Oran Park',
    },
    {
      value: 608,
      label: 'Orangeville',
    },
    {
      value: 609,
      label: 'Orchard Hills',
    },
    {
      value: 610,
      label: 'Oxford Falls',
    },
    {
      value: 611,
      label: 'Oxley Park',
    },
    {
      value: 612,
      label: 'Oyster Bay',
    },
    {
      value: 613,
      label: 'Paddington (Sydney LGA)',
    },
    {
      value: 614,
      label: 'Paddington (Woollahra LGA)',
    },
    {
      value: 615,
      label: 'Padstow',
    },
    {
      value: 616,
      label: 'Padstow Heights',
    },
    {
      value: 617,
      label: 'Pagewood',
    },
    {
      value: 618,
      label: 'Palm Beach',
    },
    {
      value: 619,
      label: 'Panania',
    },
    {
      value: 620,
      label: 'Parklea',
    },
    {
      value: 622,
      label: 'Parramatta',
    },
    {
      value: 623,
      label: 'Peakhurst',
    },
    {
      value: 624,
      label: 'Peakhurst Heights',
    },
    {
      value: 625,
      label: 'Pemulwuy',
    },
    {
      value: 626,
      label: 'Pendle Hill (Cumberland LGA)',
    },
    {
      value: 627,
      label: 'Pendle Hill (Parramatta LGA)',
    },
    {
      value: 628,
      label: 'Pennant Hills',
    },
    {
      value: 630,
      label: 'Penrith',
    },
    {
      value: 631,
      label: 'Penshurst',
    },
    {
      value: 632,
      label: 'Petersham',
    },
    {
      value: 633,
      label: 'Pheasants Nest',
    },
    {
      value: 634,
      label: 'Phillip Bay',
    },
    {
      value: 635,
      label: 'Picnic Point',
    },
    {
      value: 636,
      label: 'Picton',
    },
    {
      value: 637,
      label: 'Pitt Town',
    },
    {
      value: 638,
      label: 'Pitt Town Bottoms',
    },
    {
      value: 639,
      label: 'Pleasure Point',
    },
    {
      value: 640,
      label: 'Plumpton',
    },
    {
      value: 641,
      label: 'Point Piper',
    },
    {
      value: 642,
      label: 'Port Botany',
    },
    {
      value: 643,
      label: 'Port Hacking',
    },
    {
      value: 644,
      label: 'Potts Hill',
    },
    {
      value: 645,
      label: 'Potts Point',
    },
    {
      value: 646,
      label: 'Prairiewood',
    },
    {
      value: 647,
      label: 'Prestons',
    },
    {
      value: 648,
      label: 'Prospect',
    },
    {
      value: 649,
      label: 'Punchbowl',
    },
    {
      value: 650,
      label: 'Putney',
    },
    {
      value: 651,
      label: 'Pymble',
    },
    {
      value: 652,
      label: 'Pyrmont',
    },
    {
      value: 653,
      label: 'Quakers Hill',
    },
    {
      value: 654,
      label: 'Queens Park',
    },
    {
      value: 655,
      label: 'Queenscliff',
    },
    {
      value: 656,
      label: 'Raby',
    },
    {
      value: 657,
      label: 'Ramsgate Beach',
    },
    {
      value: 658,
      label: 'Ramsgate (Georges River LGA)',
    },
    {
      value: 659,
      label: 'Ramsgate (Bayside LGA)',
    },
    {
      value: 661,
      label: 'Randwick',
    },
    {
      value: 662,
      label: 'Razorback',
    },
    {
      value: 663,
      label: 'Redfern',
    },
    {
      value: 664,
      label: 'Regents Park (Canterbury-Bankstown LGA)',
    },
    {
      value: 665,
      label: 'Regents Park (Cumberland LGA)',
    },
    {
      value: 666,
      label: 'Regentville',
    },
    {
      value: 667,
      label: 'Revesby',
    },
    {
      value: 668,
      label: 'Revesby Heights',
    },
    {
      value: 669,
      label: 'Rhodes',
    },
    {
      value: 670,
      label: 'Richmond',
    },
    {
      value: 671,
      label: 'Richmond Lowlands',
    },
    {
      value: 672,
      label: 'Riverstone (Blacktown LGA)',
    },
    {
      value: 673,
      label: 'Riverstone (Hawkesbury LGA)',
    },
    {
      value: 674,
      label: 'Riverview',
    },
    {
      value: 675,
      label: 'Riverwood (Canterbury-Bankstown LGA)',
    },
    {
      value: 676,
      label: 'Riverwood (Georges River LGA)',
    },
    {
      value: 677,
      label: 'Rockdale',
    },
    {
      value: 678,
      label: 'Rodd Point',
    },
    {
      value: 679,
      label: 'Rookwood',
    },
    {
      value: 680,
      label: 'Rooty Hill',
    },
    {
      value: 681,
      label: 'Ropes Crossing',
    },
    {
      value: 682,
      label: 'Rose Bay (Waverley LGA)',
    },
    {
      value: 683,
      label: 'Rose Bay (Woollahra LGA)',
    },
    {
      value: 684,
      label: 'Rosebery (Bayside LGA)',
    },
    {
      value: 685,
      label: 'Rosebery (Sydney LGA)',
    },
    {
      value: 686,
      label: 'Rosehill',
    },
    {
      value: 687,
      label: 'Roselands',
    },
    {
      value: 688,
      label: 'Rosemeadow',
    },
    {
      value: 689,
      label: 'Roseville Chase',
    },
    {
      value: 690,
      label: 'Roseville (Ku-Ring-Gai LGA)',
    },
    {
      value: 691,
      label: 'Roseville (Willoughby LGA)',
    },
    {
      value: 692,
      label: 'Rossmore (Camden LGA)',
    },
    {
      value: 693,
      label: 'Rossmore (Liverpool LGA)',
    },
    {
      value: 694,
      label: 'Rouse Hill (Blacktown LGA)',
    },
    {
      value: 695,
      label: 'Rouse Hill (The Hills Shire LGA)',
    },
    {
      value: 696,
      label: 'Royal National Park',
    },
    {
      value: 697,
      label: 'Rozelle',
    },
    {
      value: 698,
      label: 'Ruse',
    },
    {
      value: 699,
      label: 'Rushcutters Bay',
    },
    {
      value: 700,
      label: 'Russell Lea',
    },
    {
      value: 701,
      label: 'Rydalmere',
    },
    {
      value: 703,
      label: 'Ryde',
    },
    {
      value: 704,
      label: 'Sackville',
    },
    {
      value: 705,
      label: 'Sackville North',
    },
    {
      value: 706,
      label: 'Sadleir',
    },
    {
      value: 707,
      label: 'Sandringham',
    },
    {
      value: 708,
      label: 'Sandy Point',
    },
    {
      value: 709,
      label: 'Sans Souci (Georges River LGA)',
    },
    {
      value: 710,
      label: 'Sans Souci (Bayside LGA)',
    },
    {
      value: 711,
      label: 'Scheyville',
    },
    {
      value: 712,
      label: 'Schofields',
    },
    {
      value: 713,
      label: 'Scotland Island',
    },
    {
      value: 714,
      label: 'Seaforth',
    },
    {
      value: 715,
      label: 'Sefton',
    },
    {
      value: 716,
      label: 'Seven Hills (Blacktown LGA)',
    },
    {
      value: 717,
      label: 'Seven Hills (Parramatta LGA)',
    },
    {
      value: 718,
      label: 'Shalvey',
    },
    {
      value: 719,
      label: 'Shanes Park',
    },
    {
      value: 720,
      label: 'Silverdale (Liverpool LGA)',
    },
    {
      value: 721,
      label: 'Silverdale (Wollondilly LGA)',
    },
    {
      value: 722,
      label: 'Silverwater',
    },
    {
      value: 723,
      label: 'Smeaton Grange',
    },
    {
      value: 724,
      label: 'Smithfield (Cumberland LGA)',
    },
    {
      value: 725,
      label: 'Smithfield (Fairfield LGA)',
    },
    {
      value: 726,
      label: 'South Coogee',
    },
    {
      value: 727,
      label: 'South Granville',
    },
    {
      value: 728,
      label: 'South Hurstville',
    },
    {
      value: 729,
      label: 'South Maroota',
    },
    {
      value: 730,
      label: 'South Penrith',
    },
    {
      value: 731,
      label: 'South Turramurra',
    },
    {
      value: 732,
      label: 'South Wentworthville',
    },
    {
      value: 733,
      label: 'South Windsor',
    },
    {
      value: 734,
      label: 'Spring Farm',
    },
    {
      value: 735,
      label: 'Springwood',
    },
    {
      value: 736,
      label: 'St Albans',
    },
    {
      value: 737,
      label: 'St Andrews',
    },
    {
      value: 738,
      label: 'St Clair',
    },
    {
      value: 739,
      label: 'St Helens Park',
    },
    {
      value: 740,
      label: 'St Ives Chase',
    },
    {
      value: 741,
      label: 'St Ives',
    },
    {
      value: 742,
      label: 'St Johns Park',
    },
    {
      value: 743,
      label: 'St Leonards (Lane Cove LGA)',
    },
    {
      value: 744,
      label: 'St Leonards (North Sydney LGA)',
    },
    {
      value: 745,
      label: 'St Leonards (Willoughby LGA)',
    },
    {
      value: 746,
      label: 'St Marys',
    },
    {
      value: 747,
      label: 'St Peters (Inner West LGA)',
    },
    {
      value: 748,
      label: 'St Peters (Sydney LGA)',
    },
    {
      value: 749,
      label: 'Stanhope Gardens',
    },
    {
      value: 750,
      label: 'Stanmore',
    },
    {
      value: 752,
      label: 'Strathfield (Burwood LGA)',
    },
    {
      value: 753,
      label: 'Strathfield (Canada Bay LGA)',
    },
    {
      value: 754,
      label: 'Strathfield South',
    },
    {
      value: 755,
      label: 'Strathfield (Strathfield LGA)',
    },
    {
      value: 756,
      label: 'Summer Hill',
    },
    {
      value: 757,
      label: 'Sun Valley',
    },
    {
      value: 758,
      label: 'Surry Hills',
    },
    {
      value: 760,
      label: 'Sutherland',
    },
    {
      value: 761,
      label: 'Sydenham',
    },
    {
      value: 763,
      label: 'Sydney Olympic Park (Parramatta LGA)',
    },
    {
      value: 764,
      label: 'Sydney',
    },
    {
      value: 765,
      label: 'Sylvania',
    },
    {
      value: 766,
      label: 'Sylvania Waters',
    },
    {
      value: 767,
      label: 'Tahmoor',
    },
    {
      value: 768,
      label: 'Tamarama',
    },
    {
      value: 769,
      label: 'Taren Point',
    },
    {
      value: 770,
      label: 'Telopea',
    },
    {
      value: 771,
      label: 'Tempe',
    },
    {
      value: 772,
      label: 'Ten Mile Hollow',
    },
    {
      value: 773,
      label: 'Tennyson',
    },
    {
      value: 774,
      label: 'Tennyson Point',
    },
    {
      value: 775,
      label: 'Terrey Hills',
    },
    {
      value: 776,
      label: 'The Devils Wilderness',
    },
    {
      value: 778,
      label: 'The Oaks',
    },
    {
      value: 779,
      label: 'The Ponds',
    },
    {
      value: 780,
      label: 'The Rocks',
    },
    {
      value: 781,
      label: 'The Slopes',
    },
    {
      value: 782,
      label: 'Theresa Park',
    },
    {
      value: 783,
      label: 'Thirlmere',
    },
    {
      value: 784,
      label: 'Thornleigh',
    },
    {
      value: 785,
      label: 'Toongabbie (Blacktown LGA)',
    },
    {
      value: 786,
      label: 'Toongabbie (Cumberland LGA)',
    },
    {
      value: 787,
      label: 'Toongabbie (Parramatta LGA)',
    },
    {
      value: 788,
      label: 'Tregear',
    },
    {
      value: 789,
      label: 'Turramurra',
    },
    {
      value: 790,
      label: 'Turrella',
    },
    {
      value: 791,
      label: 'Ultimo',
    },
    {
      value: 792,
      label: 'Upper Colo',
    },
    {
      value: 793,
      label: 'Valley Heights',
    },
    {
      value: 794,
      label: 'Varroville',
    },
    {
      value: 795,
      label: 'Vaucluse (Waverley LGA)',
    },
    {
      value: 796,
      label: 'Vaucluse (Woollahra LGA)',
    },
    {
      value: 797,
      label: 'Villawood (Canterbury-Bankstown LGA)',
    },
    {
      value: 798,
      label: 'Villawood (Fairfield LGA)',
    },
    {
      value: 799,
      label: 'Vineyard (Blacktown LGA)',
    },
    {
      value: 800,
      label: 'Vineyard (Hawkesbury LGA)',
    },
    {
      value: 801,
      label: 'Voyager Point',
    },
    {
      value: 802,
      label: 'Wahroonga (Hornsby LGA)',
    },
    {
      value: 803,
      label: 'Wahroonga (Ku-Ring-Gai LGA)',
    },
    {
      value: 804,
      label: 'Waitara',
    },
    {
      value: 805,
      label: 'Wakeley',
    },
    {
      value: 806,
      label: 'Wallacia (Liverpool LGA)',
    },
    {
      value: 807,
      label: 'Wallacia (Penrith LGA)',
    },
    {
      value: 808,
      label: 'Wallacia (Wollondilly LGA)',
    },
    {
      value: 809,
      label: 'Wareemba',
    },
    {
      value: 810,
      label: 'Warragamba',
    },
    {
      value: 811,
      label: 'Warrawee',
    },
    {
      value: 812,
      label: 'Warriewood',
    },
    {
      value: 813,
      label: 'Warrimoo',
    },
    {
      value: 814,
      label: 'Warwick Farm',
    },
    {
      value: 815,
      label: 'Waterfall',
    },
    {
      value: 816,
      label: 'Waterloo',
    },
    {
      value: 817,
      label: 'Watsons Bay',
    },
    {
      value: 818,
      label: 'Wattle Grove',
    },
    {
      value: 820,
      label: 'Waverley',
    },
    {
      value: 821,
      label: 'Waverton',
    },
    {
      value: 822,
      label: 'Wedderburn',
    },
    {
      value: 823,
      label: 'Wentworth Falls',
    },
    {
      value: 824,
      label: 'Wentworth Point',
    },
    {
      value: 825,
      label: 'Wentworthville (Cumberland LGA)',
    },
    {
      value: 826,
      label: 'Wentworthville (Parramatta LGA)',
    },
    {
      value: 827,
      label: 'Werombi',
    },
    {
      value: 828,
      label: 'Werrington',
    },
    {
      value: 829,
      label: 'Werrington County',
    },
    {
      value: 830,
      label: 'Werrington Downs',
    },
    {
      value: 831,
      label: 'West Hoxton',
    },
    {
      value: 832,
      label: 'West Pennant Hills (Hornsby LGA)',
    },
    {
      value: 833,
      label: 'West Pennant Hills (The Hills Shire LGA)',
    },
    {
      value: 834,
      label: 'West Pymble',
    },
    {
      value: 835,
      label: 'West Ryde',
    },
    {
      value: 836,
      label: 'Westleigh',
    },
    {
      value: 837,
      label: 'Westmead (Cumberland LGA)',
    },
    {
      value: 838,
      label: 'Westmead (Parramatta LGA)',
    },
    {
      value: 839,
      label: 'Wetherill Park',
    },
    {
      value: 840,
      label: 'Whalan',
    },
    {
      value: 841,
      label: 'Whale Beach',
    },
    {
      value: 842,
      label: 'Wheeler Heights',
    },
    {
      value: 843,
      label: 'Wheeny Creek',
    },
    {
      value: 844,
      label: 'Wilberforce',
    },
    {
      value: 845,
      label: 'Wiley Park',
    },
    {
      value: 846,
      label: 'Willmot',
    },
    {
      value: 848,
      label: 'Willoughby East',
    },
    {
      value: 849,
      label: 'Willoughby',
    },
    {
      value: 850,
      label: 'Wilton',
    },
    {
      value: 851,
      label: 'Windsor Downs',
    },
    {
      value: 852,
      label: 'Windsor',
    },
    {
      value: 853,
      label: 'Winmalee',
    },
    {
      value: 854,
      label: 'Winston Hills (Parramatta LGA)',
    },
    {
      value: 855,
      label: 'Winston Hills (The Hills Shire LGA)',
    },
    {
      value: 856,
      label: 'Wisemans Ferry (Hawkesbury LGA)',
    },
    {
      value: 857,
      label: 'Wisemans Ferry (Hornsby LGA)',
    },
    {
      value: 858,
      label: 'Wisemans Ferry (The Hills Shire LGA)',
    },
    {
      value: 859,
      label: 'Wolli Creek',
    },
    {
      value: 861,
      label: 'Wollstonecraft',
    },
    {
      value: 862,
      label: 'Woodbine',
    },
    {
      value: 863,
      label: 'Woodcroft',
    },
    {
      value: 864,
      label: 'Woodford',
    },
    {
      value: 865,
      label: 'Woodpark',
    },
    {
      value: 867,
      label: 'Woollahra',
    },
    {
      value: 868,
      label: 'Woolloomooloo',
    },
    {
      value: 869,
      label: 'Woolooware',
    },
    {
      value: 870,
      label: 'Woolwich',
    },
    {
      value: 871,
      label: 'Woronora',
    },
    {
      value: 872,
      label: 'Woronora Heights',
    },
    {
      value: 873,
      label: 'Wrights Creek',
    },
    {
      value: 874,
      label: 'Yagoona',
    },
    {
      value: 875,
      label: 'Yanderra',
    },
    {
      value: 876,
      label: 'Yarramundi',
    },
    {
      value: 877,
      label: 'Yarrawarrah',
    },
    {
      value: 878,
      label: 'Yellow Rock',
    },
    {
      value: 879,
      label: 'Yennora (Cumberland LGA)',
    },
    {
      value: 880,
      label: 'Yennora (Fairfield LGA)',
    },
    {
      value: 881,
      label: 'Yerranderie',
    },
    {
      value: 882,
      label: 'Yowie Bay',
    },
    {
      value: 883,
      label: 'Zetland',
    },
  ],
});

const updatedLocations = mapValues(sortBy('label'), {
  lga: [
    {
      label: 'Bayside LGA',
      value: 47,
    },
    {
      label: 'Blacktown LGA',
      value: 84,
    },
    {
      label: 'Blue Mountains LGA',
      value: 92,
    },
    {
      label: 'Burwood LGA',
      value: 119,
    },
    {
      label: 'Camden LGA',
      value: 130,
    },
    {
      label: 'Campbelltown LGA',
      value: 137,
    },
    {
      label: 'Canada Bay LGA',
      value: 141,
    },
    {
      label: 'Canterbury-Bankstown LGA',
      value: 146,
    },
    {
      label: 'Central Coast LGA',
      value: 1030,
    },
    {
      label: 'Cessnock LGA',
      value: 1032,
    },
    {
      label: 'Cumberland LGA',
      value: 228,
    },
    {
      label: 'Fairfield LGA',
      value: 295,
    },
    {
      label: 'Georges River LGA',
      value: 313,
    },
    {
      label: 'Hawkesbury LGA',
      value: 359,
    },
    {
      label: 'Hornsby LGA',
      value: 379,
    },
    {
      label: 'Hunters Hill LGA',
      value: 384,
    },
    {
      label: 'Inner West LGA',
      value: 396,
    },
    {
      label: 'Kiama LGA',
      value: 1220,
    },
    {
      label: 'Ku-ring-gai LGA',
      value: 426,
    },
    {
      label: 'Lake Macquarie LGA',
      value: 1250,
    },
    {
      label: 'Lane Cove LGA',
      value: 440,
    },
    {
      label: 'Liverpool LGA',
      value: 470,
    },
    {
      label: 'Maitland LGA',
      value: 1283,
    },
    {
      label: 'Mosman LGA',
      value: 542,
    },
    {
      label: 'Newcastle LGA',
      value: 1364,
    },
    {
      label: 'North Sydney LGA',
      value: 591,
    },
    {
      label: 'Northern Beaches LGA',
      value: 597,
    },
    {
      label: 'Parramatta LGA',
      value: 621,
    },
    {
      label: 'Penrith LGA',
      value: 629,
    },
    {
      label: 'Port Stephens LGA',
      value: 1422,
    },
    {
      label: 'Randwick LGA',
      value: 660,
    },
    {
      label: 'Ryde LGA',
      value: 702,
    },
    {
      label: 'Shellharbour LGA',
      value: 1462,
    },
    {
      label: 'Shoalhaven LGA',
      value: 1467,
    },
    {
      label: 'Strathfield LGA',
      value: 751,
    },
    {
      label: 'Sutherland LGA',
      value: 759,
    },
    {
      label: 'Sydney LGA',
      value: 762,
    },
    {
      label: 'The Hills LGA',
      value: 777,
    },
    {
      label: 'Waverley LGA',
      value: 819,
    },
    {
      label: 'Willoughby LGA',
      value: 847,
    },
    {
      label: 'Wollondilly LGA',
      value: 860,
    },
    {
      label: 'Wollongong LGA',
      value: 1590,
    },
    {
      label: 'Woollahra LGA',
      value: 866,
    },
    {
      label: 'Upper Hunter LGA',
      value: 1634,
    },
    {
      label: 'Singleton LGA',
      value: 1635,
    },
    {
      label: 'Muswellbrook LGA',
      value: 1636,
    },
    {
      label: 'Dungog LGA',
      value: 1637,
    },
    {
      label: 'Mid Coast LGA',
      value: 1638,
    },
  ],
  suburb: [
    {
      label: 'Abbotsbury',
      value: 0,
    },
    {
      label: 'Abbotsford',
      value: 1,
    },
    {
      label: 'Aberdare',
      value: 884,
    },
    {
      label: 'Aberglasslyn',
      value: 885,
    },
    {
      label: 'Abermain',
      value: 886,
    },
    {
      label: 'Abernethy',
      value: 887,
    },
    {
      label: 'Acacia Gardens',
      value: 2,
    },
    {
      label: 'Adamstown',
      value: 888,
    },
    {
      label: 'Adamstown Heights (Lake Macquarie LGA)',
      value: 889,
    },
    {
      label: 'Adamstown Heights (Newcastle LGA)',
      value: 890,
    },
    {
      label: 'Agnes Banks (Hawkesbury LGA)',
      value: 3,
    },
    {
      label: 'Agnes Banks (Penrith LGA)',
      value: 4,
    },
    {
      label: 'Airds',
      value: 5,
    },
    {
      label: 'Albion Park',
      value: 891,
    },
    {
      label: 'Albion Park Rail',
      value: 892,
    },
    {
      label: 'Alexandria',
      value: 6,
    },
    {
      label: 'Alfords Point',
      value: 7,
    },
    {
      label: 'Alison',
      value: 893,
    },
    {
      label: 'Allambie Heights',
      value: 8,
    },
    {
      label: 'Allandale (Cessnock LGA)',
      value: 894,
    },
    {
      label: 'Allandale (Maitland LGA)',
      value: 895,
    },
    {
      label: 'Allawah',
      value: 9,
    },
    {
      label: 'Ambarvale',
      value: 10,
    },
    {
      label: 'Anambah',
      value: 896,
    },
    {
      label: 'Angus',
      value: 897,
    },
    {
      label: 'Anna Bay',
      value: 898,
    },
    {
      label: 'Annandale (Inner West LGA)',
      value: 11,
    },
    {
      label: 'Annandale (Sydney LGA)',
      value: 12,
    },
    {
      label: 'Annangrove',
      value: 13,
    },
    {
      label: 'Appin',
      value: 14,
    },
    {
      label: 'Arcadia',
      value: 15,
    },
    {
      label: 'Arcadia Vale',
      value: 899,
    },
    {
      label: 'Argenton',
      value: 900,
    },
    {
      label: 'Arncliffe',
      value: 16,
    },
    {
      label: 'Arndell Park',
      value: 17,
    },
    {
      label: 'Artarmon',
      value: 18,
    },
    {
      label: 'Ashbury (Inner West LGA)',
      value: 20,
    },
    {
      label: 'Ashbury (Canterbury-Bankstown LGA)',
      value: 19,
    },
    {
      label: 'Ashcroft',
      value: 21,
    },
    {
      label: 'Ashfield',
      value: 22,
    },
    {
      label: 'Ashtonfield',
      value: 901,
    },
    {
      label: 'Asquith',
      value: 23,
    },
    {
      label: 'Auburn',
      value: 24,
    },
    {
      label: 'Austinmer',
      value: 902,
    },
    {
      label: 'Austral',
      value: 25,
    },
    {
      label: 'Avalon Beach',
      value: 26,
    },
    {
      label: 'Avoca Beach',
      value: 903,
    },
    {
      label: 'Avon',
      value: 904,
    },
    {
      label: 'Avondale',
      value: 905,
    },
    {
      label: 'Awaba',
      value: 906,
    },
    {
      label: 'Back Forest',
      value: 907,
    },
    {
      label: 'Badgerys Creek (Liverpool LGA)',
      value: 27,
    },
    {
      label: 'Badgerys Creek (Penrith LGA)',
      value: 28,
    },
    {
      label: 'Balcolyn',
      value: 908,
    },
    {
      label: 'Balgowlah',
      value: 29,
    },
    {
      label: 'Balgowlah Heights',
      value: 30,
    },
    {
      label: 'Balgownie',
      value: 909,
    },
    {
      label: 'Balickera',
      value: 910,
    },
    {
      label: 'Balmain',
      value: 31,
    },
    {
      label: 'Balmain East',
      value: 32,
    },
    {
      label: 'Balmoral',
      value: 911,
    },
    {
      label: 'Bamarang',
      value: 912,
    },
    {
      label: 'Bangalee',
      value: 913,
    },
    {
      label: 'Bangor',
      value: 33,
    },
    {
      label: 'Banksia',
      value: 34,
    },
    {
      label: 'Banksmeadow',
      value: 35,
    },
    {
      label: 'Bankstown',
      value: 36,
    },
    {
      label: 'Bankstown Aerodrome',
      value: 37,
    },
    {
      label: 'Bar Beach',
      value: 914,
    },
    {
      label: 'Bar Point',
      value: 915,
    },
    {
      label: 'Barangaroo',
      value: 38,
    },
    {
      label: 'Barden Ridge',
      value: 39,
    },
    {
      label: 'Bardia',
      value: 40,
    },
    {
      label: 'Bardwell Park',
      value: 41,
    },
    {
      label: 'Bardwell Valley',
      value: 42,
    },
    {
      label: 'Bargo',
      value: 43,
    },
    {
      label: 'Barnsley',
      value: 916,
    },
    {
      label: 'Barrack Heights',
      value: 917,
    },
    {
      label: 'Barrack Point',
      value: 918,
    },
    {
      label: 'Barren Grounds (Kiama LGA)',
      value: 919,
    },
    {
      label: 'Barren Grounds (Shoalhaven LGA)',
      value: 920,
    },
    {
      label: 'Barrengarry',
      value: 921,
    },
    {
      label: 'Barringella',
      value: 922,
    },
    {
      label: 'Basin View',
      value: 923,
    },
    {
      label: 'Bass Hill',
      value: 44,
    },
    {
      label: 'Bateau Bay',
      value: 924,
    },
    {
      label: 'Baulkham Hills (The Hills LGA)',
      value: 46,
    },
    {
      label: 'Baulkham Hills (Parramatta LGA)',
      value: 45,
    },
    {
      label: 'Bawley Point',
      value: 925,
    },
    {
      label: 'Bayview',
      value: 48,
    },
    {
      label: 'Beacon Hill',
      value: 49,
    },
    {
      label: 'Beaconsfield',
      value: 50,
    },
    {
      label: 'Beaumont',
      value: 926,
    },
    {
      label: 'Beaumont Hills',
      value: 51,
    },
    {
      label: 'Beecroft (Hornsby LGA)',
      value: 52,
    },
    {
      label: 'Beecroft (Parramatta LGA)',
      value: 53,
    },
    {
      label: 'Beecroft Peninsula',
      value: 927,
    },
    {
      label: 'Belfield (Canterbury-Bankstown LGA)',
      value: 54,
    },
    {
      label: 'Belfield (Strathfield LGA)',
      value: 55,
    },
    {
      label: 'Belimbla Park',
      value: 56,
    },
    {
      label: 'Bell',
      value: 57,
    },
    {
      label: 'Bella Vista',
      value: 58,
    },
    {
      label: 'Bellambi',
      value: 928,
    },
    {
      label: 'Bellawongarah',
      value: 929,
    },
    {
      label: 'Bellbird',
      value: 930,
    },
    {
      label: 'Bellbird Heights',
      value: 931,
    },
    {
      label: 'Bellevue Hill',
      value: 59,
    },
    {
      label: 'Belmont',
      value: 932,
    },
    {
      label: 'Belmont North',
      value: 933,
    },
    {
      label: 'Belmont South',
      value: 934,
    },
    {
      label: 'Belmore',
      value: 60,
    },
    {
      label: 'Belrose',
      value: 61,
    },
    {
      label: 'Bendalong',
      value: 935,
    },
    {
      label: 'Bennetts Green',
      value: 936,
    },
    {
      label: 'Bensville',
      value: 937,
    },
    {
      label: 'Berala',
      value: 62,
    },
    {
      label: 'Berambing (Blue Mountains LGA)',
      value: 63,
    },
    {
      label: 'Berambing (Hawkesbury LGA)',
      value: 64,
    },
    {
      label: 'Beresfield',
      value: 938,
    },
    {
      label: 'Berkeley',
      value: 939,
    },
    {
      label: 'Berkeley Vale',
      value: 940,
    },
    {
      label: 'Berkshire Park',
      value: 65,
    },
    {
      label: 'Berowra',
      value: 66,
    },
    {
      label: 'Berowra Heights',
      value: 68,
    },
    {
      label: 'Berowra Waters',
      value: 69,
    },
    {
      label: 'Berrara',
      value: 941,
    },
    {
      label: 'Berrilee',
      value: 70,
    },
    {
      label: 'Berringer Lake',
      value: 942,
    },
    {
      label: 'Berry',
      value: 943,
    },
    {
      label: 'Berry Mountain',
      value: 944,
    },
    {
      label: 'Berry Park',
      value: 945,
    },
    {
      label: 'Beverley Park',
      value: 71,
    },
    {
      label: 'Beverly Hills (Canterbury-Bankstown LGA)',
      value: 72,
    },
    {
      label: 'Beverly Hills (Georges River LGA)',
      value: 73,
    },
    {
      label: 'Bewong',
      value: 946,
    },
    {
      label: 'Bexley',
      value: 74,
    },
    {
      label: 'Bexley North',
      value: 75,
    },
    {
      label: 'Bidwill',
      value: 76,
    },
    {
      label: 'Bilgola Beach',
      value: 77,
    },
    {
      label: 'Bilgola Plateau',
      value: 78,
    },
    {
      label: 'Bilpin',
      value: 79,
    },
    {
      label: 'Birchgrove',
      value: 80,
    },
    {
      label: 'Birmingham Gardens',
      value: 947,
    },
    {
      label: 'Birrong',
      value: 81,
    },
    {
      label: 'Bishops Bridge (Cessnock LGA)',
      value: 948,
    },
    {
      label: 'Bishops Bridge (Maitland LGA)',
      value: 949,
    },
    {
      label: 'Black Hill (Cessnock LGA)',
      value: 950,
    },
    {
      label: 'Black Hill (Newcastle LGA)',
      value: 951,
    },
    {
      label: 'Blackalls Park',
      value: 952,
    },
    {
      label: 'Blackbutt',
      value: 953,
    },
    {
      label: 'Blackett',
      value: 82,
    },
    {
      label: 'Blackheath',
      value: 83,
    },
    {
      label: 'Blacksmiths',
      value: 954,
    },
    {
      label: 'Blacktown',
      value: 85,
    },
    {
      label: 'Blackwall',
      value: 955,
    },
    {
      label: 'Blair Athol',
      value: 86,
    },
    {
      label: 'Blairmount',
      value: 87,
    },
    {
      label: 'Blakehurst',
      value: 88,
    },
    {
      label: 'Blaxland',
      value: 89,
    },
    {
      label: 'Blaxlands Ridge',
      value: 90,
    },
    {
      label: 'Bligh Park',
      value: 91,
    },
    {
      label: 'Blue Bay',
      value: 956,
    },
    {
      label: 'Blue Haven',
      value: 957,
    },
    {
      label: 'Blue Mountains National Park (Blue Mountains LGA)',
      value: 93,
    },
    {
      label: 'Blue Mountains National Park (Wollondilly LGA)',
      value: 94,
    },
    {
      label: 'Boat Harbour',
      value: 958,
    },
    {
      label: 'Bobs Farm',
      value: 959,
    },
    {
      label: 'Bolong',
      value: 960,
    },
    {
      label: 'Bolton Point',
      value: 961,
    },
    {
      label: 'Bolwarra',
      value: 962,
    },
    {
      label: 'Bolwarra Heights',
      value: 963,
    },
    {
      label: 'Bomaderry',
      value: 964,
    },
    {
      label: 'Bombo',
      value: 965,
    },
    {
      label: 'Bondi',
      value: 95,
    },
    {
      label: 'Bondi Beach',
      value: 96,
    },
    {
      label: 'Bondi Junction',
      value: 97,
    },
    {
      label: 'Bonnells Bay',
      value: 966,
    },
    {
      label: 'Bonnet Bay',
      value: 98,
    },
    {
      label: 'Bonnyrigg',
      value: 99,
    },
    {
      label: 'Bonnyrigg Heights',
      value: 100,
    },
    {
      label: 'Booker Bay',
      value: 967,
    },
    {
      label: 'Boolaroo',
      value: 968,
    },
    {
      label: 'Boolijah',
      value: 969,
    },
    {
      label: 'Booragul',
      value: 970,
    },
    {
      label: 'Bossley Park',
      value: 101,
    },
    {
      label: 'Botany',
      value: 102,
    },
    {
      label: 'Bouddi',
      value: 971,
    },
    {
      label: 'Bow Bowing',
      value: 103,
    },
    {
      label: 'Bowen Mountain',
      value: 104,
    },
    {
      label: 'Box Head',
      value: 972,
    },
    {
      label: 'Box Hill',
      value: 105,
    },
    {
      label: 'Bradbury',
      value: 106,
    },
    {
      label: 'Brandy Hill',
      value: 973,
    },
    {
      label: 'Branxton',
      value: 974,
    },
    {
      label: 'Breakfast Point',
      value: 107,
    },
    {
      label: 'Bream Beach',
      value: 975,
    },
    {
      label: 'Brighton-Le-Sands',
      value: 108,
    },
    {
      label: 'Brightwaters',
      value: 976,
    },
    {
      label: 'Bringelly (Camden LGA)',
      value: 109,
    },
    {
      label: 'Bringelly (Liverpool LGA)',
      value: 110,
    },
    {
      label: 'Broadmeadow',
      value: 977,
    },
    {
      label: 'Brogers Creek',
      value: 978,
    },
    {
      label: 'Bronte',
      value: 111,
    },
    {
      label: 'Brooklyn',
      value: 112,
    },
    {
      label: 'Brookvale',
      value: 113,
    },
    {
      label: 'Brooman',
      value: 979,
    },
    {
      label: 'Broughton',
      value: 980,
    },
    {
      label: 'Broughton Vale',
      value: 981,
    },
    {
      label: 'Broughton Village',
      value: 982,
    },
    {
      label: 'Brownlow Hill',
      value: 114,
    },
    {
      label: 'Browns Mountain',
      value: 983,
    },
    {
      label: 'Brownsville',
      value: 984,
    },
    {
      label: 'Brundee',
      value: 985,
    },
    {
      label: 'Brunkerville',
      value: 986,
    },
    {
      label: 'Buangla',
      value: 987,
    },
    {
      label: 'Buchanan',
      value: 988,
    },
    {
      label: 'Bucketty (Cessnock LGA)',
      value: 989,
    },
    {
      label: 'Budawang',
      value: 991,
    },
    {
      label: 'Budderoo (Kiama LGA)',
      value: 992,
    },
    {
      label: 'Budderoo (Shoalhaven LGA)',
      value: 993,
    },
    {
      label: 'Budgewoi',
      value: 994,
    },
    {
      label: 'Budgewoi Peninsula',
      value: 995,
    },
    {
      label: 'Budgong',
      value: 996,
    },
    {
      label: 'Buff Point',
      value: 997,
    },
    {
      label: 'Bulee',
      value: 998,
    },
    {
      label: 'Bullaburra',
      value: 115,
    },
    {
      label: 'Bulli',
      value: 999,
    },
    {
      label: 'Bundeena',
      value: 116,
    },
    {
      label: 'Bundewallah',
      value: 1000,
    },
    {
      label: 'Bungarribee',
      value: 117,
    },
    {
      label: 'Burraneer',
      value: 118,
    },
    {
      label: 'Burrier',
      value: 1001,
    },
    {
      label: 'Burrill Lake',
      value: 1002,
    },
    {
      label: 'Burwood',
      value: 120,
    },
    {
      label: 'Burwood Heights',
      value: 121,
    },
    {
      label: 'Busby',
      value: 122,
    },
    {
      label: 'Bushells Ridge',
      value: 1003,
    },
    {
      label: 'Buttaba',
      value: 1004,
    },
    {
      label: 'Buttai',
      value: 1005,
    },
    {
      label: 'Butterwick',
      value: 1006,
    },
    {
      label: 'Buxton',
      value: 123,
    },
    {
      label: 'Cabarita',
      value: 124,
    },
    {
      label: 'Cabramatta',
      value: 125,
    },
    {
      label: 'Cabramatta West',
      value: 126,
    },
    {
      label: 'Caddens',
      value: 127,
    },
    {
      label: 'Calderwood',
      value: 1007,
    },
    {
      label: 'Calga',
      value: 1008,
    },
    {
      label: 'Callaghan',
      value: 1009,
    },
    {
      label: 'Callala Bay',
      value: 1010,
    },
    {
      label: 'Callala Beach',
      value: 1011,
    },
    {
      label: 'Cambewarra',
      value: 1012,
    },
    {
      label: 'Cambewarra Village',
      value: 1013,
    },
    {
      label: 'Cambridge Gardens',
      value: 128,
    },
    {
      label: 'Cambridge Park',
      value: 129,
    },
    {
      label: 'Camden',
      value: 131,
    },
    {
      label: 'Camden Park',
      value: 132,
    },
    {
      label: 'Camden South',
      value: 133,
    },
    {
      label: 'Camellia',
      value: 134,
    },
    {
      label: 'Cameron Park',
      value: 1014,
    },
    {
      label: 'Cammeray',
      value: 135,
    },
    {
      label: 'Campbelltown',
      value: 136,
    },
    {
      label: 'Camperdown (Inner West LGA)',
      value: 138,
    },
    {
      label: 'Camperdown (Sydney LGA)',
      value: 139,
    },
    {
      label: 'Campsie',
      value: 140,
    },
    {
      label: 'Campvale',
      value: 1015,
    },
    {
      label: 'Cams Wharf',
      value: 1016,
    },
    {
      label: 'Canada Bay',
      value: 142,
    },
    {
      label: 'Canley Heights',
      value: 143,
    },
    {
      label: 'Canley Vale',
      value: 144,
    },
    {
      label: 'Canoelands',
      value: 145,
    },
    {
      label: 'Canterbury',
      value: 147,
    },
    {
      label: 'Canton Beach',
      value: 1017,
    },
    {
      label: 'Cardiff',
      value: 1018,
    },
    {
      label: 'Cardiff Heights',
      value: 1019,
    },
    {
      label: 'Cardiff South',
      value: 1020,
    },
    {
      label: 'Carey Bay',
      value: 1021,
    },
    {
      label: 'Caringbah',
      value: 148,
    },
    {
      label: 'Caringbah South',
      value: 149,
    },
    {
      label: 'Carlingford (Parramatta LGA)',
      value: 150,
    },
    {
      label: 'Carlton (Georges River LGA)',
      value: 152,
    },
    {
      label: 'Carlton (Bayside LGA)',
      value: 153,
    },
    {
      label: 'Carnes Hill',
      value: 154,
    },
    {
      label: 'Carramar',
      value: 155,
    },
    {
      label: 'Carrington',
      value: 1022,
    },
    {
      label: 'Carrington Falls',
      value: 1023,
    },
    {
      label: 'Carss Park',
      value: 156,
    },
    {
      label: 'Cartwright',
      value: 157,
    },
    {
      label: 'Castle Cove',
      value: 158,
    },
    {
      label: 'Castle Hill (The Hills LGA)',
      value: 160,
    },
    {
      label: 'Castle Hill (Hornsby LGA)',
      value: 159,
    },
    {
      label: 'Castlecrag',
      value: 161,
    },
    {
      label: 'Castlereagh',
      value: 162,
    },
    {
      label: 'Casula',
      value: 163,
    },
    {
      label: 'Cataract (Wollondilly LGA)',
      value: 1024,
    },
    {
      label: 'Cataract (Wollongong LGA)',
      value: 1025,
    },
    {
      label: 'Catherine Field',
      value: 164,
    },
    {
      label: 'Catherine Hill Bay',
      value: 1026,
    },
    {
      label: 'Cattai (The Hills LGA)',
      value: 166,
    },
    {
      label: 'Cattai (Hawkesbury LGA)',
      value: 165,
    },
    {
      label: 'Caves Beach',
      value: 1027,
    },
    {
      label: 'Cawdor (Camden LGA)',
      value: 167,
    },
    {
      label: 'Cawdor (Wollondilly LGA)',
      value: 168,
    },
    {
      label: 'Cecil Hills',
      value: 169,
    },
    {
      label: 'Cecil Park (Fairfield LGA)',
      value: 170,
    },
    {
      label: 'Cedar Brush Creek',
      value: 1028,
    },
    {
      label: 'Cedar Creek',
      value: 1029,
    },
    {
      label: 'Centennial Park (Randwick LGA)',
      value: 172,
    },
    {
      label: 'Centennial Park (Sydney LGA)',
      value: 173,
    },
    {
      label: 'Central Colo',
      value: 174,
    },
    {
      label: 'Central Macdonald',
      value: 175,
    },
    {
      label: 'Central Mangrove',
      value: 1031,
    },
    {
      label: 'Cessnock',
      value: 1033,
    },
    {
      label: 'Chain Valley Bay',
      value: 1034,
    },
    {
      label: 'Charlestown',
      value: 1035,
    },
    {
      label: 'Charmhaven',
      value: 1036,
    },
    {
      label: 'Chatswood',
      value: 176,
    },
    {
      label: 'Chatswood West (Ryde LGA)',
      value: 177,
    },
    {
      label: 'Chatswood West (Willoughby LGA)',
      value: 178,
    },
    {
      label: 'Cheero Point',
      value: 1037,
    },
    {
      label: 'Cheltenham (Hornsby LGA)',
      value: 179,
    },
    {
      label: 'Cheltenham (Parramatta LGA)',
      value: 180,
    },
    {
      label: 'Cherrybrook',
      value: 181,
    },
    {
      label: 'Chester Hill (Canterbury-Bankstown LGA)',
      value: 182,
    },
    {
      label: 'Chester Hill (Cumberland LGA)',
      value: 183,
    },
    {
      label: 'Chifley',
      value: 184,
    },
    {
      label: 'Chippendale',
      value: 185,
    },
    {
      label: 'Chipping Norton',
      value: 186,
    },
    {
      label: 'Chisholm',
      value: 1038,
    },
    {
      label: 'Chiswick',
      value: 187,
    },
    {
      label: 'Chittaway Bay',
      value: 1039,
    },
    {
      label: 'Chittaway Point',
      value: 1040,
    },
    {
      label: 'Chullora',
      value: 188,
    },
    {
      label: 'Church Point',
      value: 189,
    },
    {
      label: 'Claremont Meadows',
      value: 190,
    },
    {
      label: 'Clarendon',
      value: 191,
    },
    {
      label: 'Clareville',
      value: 192,
    },
    {
      label: 'Claymore',
      value: 193,
    },
    {
      label: 'Clemton Park',
      value: 194,
    },
    {
      label: 'Cleveland',
      value: 1041,
    },
    {
      label: 'Cliftleigh (Cessnock LGA)',
      value: 1042,
    },
    {
      label: 'Cliftleigh (Maitland LGA)',
      value: 1043,
    },
    {
      label: 'Clifton',
      value: 1044,
    },
    {
      label: 'Clontarf',
      value: 195,
    },
    {
      label: 'Clovelly',
      value: 196,
    },
    {
      label: 'Clyde',
      value: 197,
    },
    {
      label: 'Coal Point',
      value: 1045,
    },
    {
      label: 'Coalcliff',
      value: 1046,
    },
    {
      label: 'Coasters Retreat',
      value: 198,
    },
    {
      label: 'Cobbitty',
      value: 199,
    },
    {
      label: 'Cockwhy',
      value: 1047,
    },
    {
      label: 'Cogra Bay',
      value: 1048,
    },
    {
      label: 'Colebee',
      value: 200,
    },
    {
      label: 'Coledale',
      value: 1049,
    },
    {
      label: 'Collaroy',
      value: 201,
    },
    {
      label: 'Collaroy Plateau',
      value: 202,
    },
    {
      label: 'Colo',
      value: 203,
    },
    {
      label: 'Colo Heights',
      value: 204,
    },
    {
      label: 'Colongra',
      value: 1050,
    },
    {
      label: 'Colyton',
      value: 205,
    },
    {
      label: 'Comberton',
      value: 1051,
    },
    {
      label: 'Comerong Island',
      value: 1052,
    },
    {
      label: 'Como',
      value: 206,
    },
    {
      label: 'Concord',
      value: 207,
    },
    {
      label: 'Concord West',
      value: 208,
    },
    {
      label: 'Condell Park',
      value: 209,
    },
    {
      label: 'Congewai',
      value: 1053,
    },
    {
      label: 'Coniston',
      value: 1054,
    },
    {
      label: 'Conjola',
      value: 1055,
    },
    {
      label: 'Conjola Park',
      value: 1056,
    },
    {
      label: 'Connells Point',
      value: 210,
    },
    {
      label: 'Constitution Hill',
      value: 211,
    },
    {
      label: 'Coogee',
      value: 212,
    },
    {
      label: 'Cooks Hill',
      value: 1057,
    },
    {
      label: 'Coolangatta',
      value: 1058,
    },
    {
      label: 'Coolumburra',
      value: 1059,
    },
    {
      label: 'Cooranbong',
      value: 1060,
    },
    {
      label: 'Copacabana',
      value: 1061,
    },
    {
      label: 'Cordeaux',
      value: 1062,
    },
    {
      label: 'Cordeaux Heights',
      value: 1063,
    },
    {
      label: 'Corlette',
      value: 1064,
    },
    {
      label: 'Cornwallis',
      value: 213,
    },
    {
      label: 'Corrabare',
      value: 1065,
    },
    {
      label: 'Corrimal',
      value: 1066,
    },
    {
      label: 'Cottage Point',
      value: 214,
    },
    {
      label: 'Couridjah',
      value: 215,
    },
    {
      label: 'Cowan',
      value: 216,
    },
    {
      label: 'Cranebrook',
      value: 217,
    },
    {
      label: 'Crangan Bay (Central Coast LGA)',
      value: 1067,
    },
    {
      label: 'Crangan Bay (Lake Macquarie LGA)',
      value: 1068,
    },
    {
      label: 'Cremorne',
      value: 218,
    },
    {
      label: 'Cremorne Point',
      value: 219,
    },
    {
      label: 'Cringila',
      value: 1069,
    },
    {
      label: 'Cromer',
      value: 220,
    },
    {
      label: 'Cronulla',
      value: 221,
    },
    {
      label: 'Croobyar',
      value: 1070,
    },
    {
      label: 'Croom (Kiama LGA)',
      value: 1071,
    },
    {
      label: 'Croom (Shellharbour LGA)',
      value: 1072,
    },
    {
      label: 'Croudace Bay',
      value: 1073,
    },
    {
      label: 'Crows Nest',
      value: 222,
    },
    {
      label: 'Croydon (Inner West LGA)',
      value: 224,
    },
    {
      label: 'Croydon (Burwood LGA)',
      value: 223,
    },
    {
      label: 'Croydon Park (Inner West LGA)',
      value: 227,
    },
    {
      label: 'Croydon Park (Burwood LGA)',
      value: 225,
    },
    {
      label: 'Croydon Park (Canterbury-Bankstown LGA)',
      value: 226,
    },
    {
      label: 'Cudmirrah',
      value: 1074,
    },
    {
      label: 'Culburra Beach',
      value: 1075,
    },
    {
      label: 'Cumberland Reach',
      value: 229,
    },
    {
      label: 'Cunjurong Point',
      value: 1076,
    },
    {
      label: 'Curl Curl',
      value: 230,
    },
    {
      label: 'Curramore',
      value: 1077,
    },
    {
      label: 'Currans Hill',
      value: 231,
    },
    {
      label: 'Currarong',
      value: 1078,
    },
    {
      label: 'Currowan',
      value: 1079,
    },
    {
      label: 'Daceyville',
      value: 232,
    },
    {
      label: 'Dairy Arm',
      value: 1080,
    },
    {
      label: 'Daleys Point',
      value: 1081,
    },
    {
      label: 'Dangar Island',
      value: 233,
    },
    {
      label: 'Dapto',
      value: 1082,
    },
    {
      label: 'Darkes Forest (Wollongong LGA)',
      value: 1083,
    },
    {
      label: 'Darkes Forest (Wollondilly LGA)',
      value: 1084,
    },
    {
      label: 'Darling Point',
      value: 234,
    },
    {
      label: 'Darlinghurst',
      value: 235,
    },
    {
      label: 'Darlington',
      value: 236,
    },
    {
      label: 'Davidson',
      value: 237,
    },
    {
      label: 'Davistown',
      value: 1085,
    },
    {
      label: 'Dawes Point',
      value: 238,
    },
    {
      label: 'Dean Park',
      value: 239,
    },
    {
      label: 'Dee Why',
      value: 240,
    },
    {
      label: 'Denham Court (Campbelltown LGA)',
      value: 241,
    },
    {
      label: 'Denham Court (Liverpool LGA)',
      value: 242,
    },
    {
      label: 'Denistone',
      value: 243,
    },
    {
      label: 'Denistone East',
      value: 244,
    },
    {
      label: 'Denistone West',
      value: 245,
    },
    {
      label: 'Depot Beach',
      value: 1086,
    },
    {
      label: 'Dharruk',
      value: 246,
    },
    {
      label: 'Dolans Bay',
      value: 247,
    },
    {
      label: 'Dolls Point',
      value: 248,
    },
    {
      label: 'Dolphin Point',
      value: 1087,
    },
    {
      label: 'Dombarton',
      value: 1088,
    },
    {
      label: 'Doonside',
      value: 249,
    },
    {
      label: 'Dooralong',
      value: 1089,
    },
    {
      label: 'Dora Creek',
      value: 1090,
    },
    {
      label: 'Double Bay',
      value: 250,
    },
    {
      label: 'Douglas Park',
      value: 251,
    },
    {
      label: 'Dover Heights',
      value: 252,
    },
    {
      label: 'Doyalson',
      value: 1091,
    },
    {
      label: 'Doyalson North',
      value: 1092,
    },
    {
      label: 'Drummoyne',
      value: 253,
    },
    {
      label: 'Duckenfield',
      value: 1093,
    },
    {
      label: 'Dudley',
      value: 1094,
    },
    {
      label: 'Duffys Forest',
      value: 254,
    },
    {
      label: 'Dulwich Hill',
      value: 255,
    },
    {
      label: 'Dundas',
      value: 256,
    },
    {
      label: 'Dundas Valley',
      value: 257,
    },
    {
      label: 'Dunmore',
      value: 1095,
    },
    {
      label: 'Duns Creek',
      value: 1096,
    },
    {
      label: 'Dural (The Hills LGA)',
      value: 259,
    },
    {
      label: 'Dural (Hornsby LGA)',
      value: 258,
    },
    {
      label: 'Durras North',
      value: 1097,
    },
    {
      label: 'Durren Durren',
      value: 1098,
    },
    {
      label: 'Eagle Vale',
      value: 260,
    },
    {
      label: 'Eagleton',
      value: 1099,
    },
    {
      label: 'Earlwood',
      value: 261,
    },
    {
      label: 'East Branxton',
      value: 1100,
    },
    {
      label: 'East Corrimal',
      value: 1101,
    },
    {
      label: 'East Gosford',
      value: 1102,
    },
    {
      label: 'East Hills',
      value: 262,
    },
    {
      label: 'East Killara',
      value: 263,
    },
    {
      label: 'East Kurrajong',
      value: 264,
    },
    {
      label: 'East Lindfield',
      value: 265,
    },
    {
      label: 'East Lynne',
      value: 1103,
    },
    {
      label: 'East Maitland',
      value: 1104,
    },
    {
      label: 'East Ryde',
      value: 266,
    },
    {
      label: 'East Seaham',
      value: 1105,
    },
    {
      label: 'Eastern Creek',
      value: 267,
    },
    {
      label: 'Eastgardens',
      value: 268,
    },
    {
      label: 'Eastlakes',
      value: 269,
    },
    {
      label: 'Eastwood (Parramatta LGA)',
      value: 270,
    },
    {
      label: 'Eastwood (Ryde LGA)',
      value: 271,
    },
    {
      label: 'Ebenezer',
      value: 272,
    },
    {
      label: 'Edensor Park',
      value: 273,
    },
    {
      label: 'Edgecliff',
      value: 274,
    },
    {
      label: 'Edgeworth',
      value: 1106,
    },
    {
      label: 'Edmondson Park',
      value: 275,
    },
    {
      label: 'Elanora Heights',
      value: 276,
    },
    {
      label: 'Elderslie',
      value: 277,
    },
    {
      label: 'Eleebana',
      value: 1107,
    },
    {
      label: 'Elermore Vale (Newcastle LGA)',
      value: 1108,
    },
    {
      label: 'Elermore Vale (Lake Macquarie LGA)',
      value: 1109,
    },
    {
      label: 'Elizabeth Bay',
      value: 278,
    },
    {
      label: 'Elizabeth Hills',
      value: 279,
    },
    {
      label: 'Ellalong',
      value: 1110,
    },
    {
      label: 'Ellis Lane',
      value: 280,
    },
    {
      label: 'Elrington',
      value: 1111,
    },
    {
      label: 'Elvina Bay',
      value: 281,
    },
    {
      label: 'Emerton',
      value: 282,
    },
    {
      label: 'Empire Bay',
      value: 1112,
    },
    {
      label: 'Emu Heights',
      value: 283,
    },
    {
      label: 'Emu Plains',
      value: 284,
    },
    {
      label: 'Endrick',
      value: 1113,
    },
    {
      label: 'Enfield',
      value: 285,
    },
    {
      label: 'Engadine',
      value: 286,
    },
    {
      label: 'Englorie Park',
      value: 287,
    },
    {
      label: 'Enmore',
      value: 288,
    },
    {
      label: 'Epping',
      value: 289,
    },
    {
      label: 'Eraring',
      value: 1114,
    },
    {
      label: 'Erina',
      value: 1115,
    },
    {
      label: 'Erina Heights',
      value: 1116,
    },
    {
      label: 'Ermington',
      value: 290,
    },
    {
      label: 'Erowal Bay',
      value: 1117,
    },
    {
      label: 'Erskine Park',
      value: 291,
    },
    {
      label: 'Erskineville',
      value: 292,
    },
    {
      label: 'Eschol Park',
      value: 293,
    },
    {
      label: 'Ettalong Beach',
      value: 1118,
    },
    {
      label: 'Ettrema',
      value: 1119,
    },
    {
      label: 'Eveleigh',
      value: 294,
    },
    {
      label: 'Fairfield (Fairfield LGA)',
      value: 298,
    },
    {
      label: 'Fairfield (Cumberland LGA)',
      value: 296,
    },
    {
      label: 'Fairfield East',
      value: 297,
    },
    {
      label: 'Fairfield Heights',
      value: 299,
    },
    {
      label: 'Fairfield West',
      value: 300,
    },
    {
      label: 'Fairlight',
      value: 301,
    },
    {
      label: 'Fairy Meadow',
      value: 1120,
    },
    {
      label: 'Falls Creek',
      value: 1121,
    },
    {
      label: 'Far Meadow',
      value: 1122,
    },
    {
      label: 'Farley',
      value: 1123,
    },
    {
      label: 'Farmborough Heights',
      value: 1124,
    },
    {
      label: 'Fassifern',
      value: 1125,
    },
    {
      label: 'Faulconbridge',
      value: 302,
    },
    {
      label: 'Fennell Bay',
      value: 1126,
    },
    {
      label: 'Fern Bay',
      value: 1127,
    },
    {
      label: 'Fernances',
      value: 1128,
    },
    {
      label: 'Fernances Crossing',
      value: 1129,
    },
    {
      label: 'Fernhill',
      value: 1130,
    },
    {
      label: 'Ferodale',
      value: 1131,
    },
    {
      label: 'Fiddletown',
      value: 303,
    },
    {
      label: 'Figtree',
      value: 1132,
    },
    {
      label: 'Fingal Bay',
      value: 1133,
    },
    {
      label: 'Fishermans Bay',
      value: 1134,
    },
    {
      label: 'Fishermans Paradise',
      value: 1135,
    },
    {
      label: 'Fishing Point',
      value: 1136,
    },
    {
      label: 'Five Dock',
      value: 304,
    },
    {
      label: 'Fletcher',
      value: 1137,
    },
    {
      label: 'Flinders',
      value: 1138,
    },
    {
      label: 'Floraville',
      value: 1139,
    },
    {
      label: 'Forest Glen',
      value: 305,
    },
    {
      label: 'Forest Lodge',
      value: 306,
    },
    {
      label: 'Forestville',
      value: 307,
    },
    {
      label: 'Forresters Beach',
      value: 1140,
    },
    {
      label: 'Fountaindale',
      value: 1141,
    },
    {
      label: 'Foxground',
      value: 1142,
    },
    {
      label: 'Frazer Park',
      value: 1143,
    },
    {
      label: 'Freemans',
      value: 1144,
    },
    {
      label: 'Freemans Reach',
      value: 308,
    },
    {
      label: 'Freemans Waterhole',
      value: 1145,
    },
    {
      label: 'Frenchs Forest',
      value: 309,
    },
    {
      label: 'Freshwater',
      value: 310,
    },
    {
      label: 'Fullerton Cove',
      value: 1146,
    },
    {
      label: 'Gables',
      value: 1147,
    },
    {
      label: 'Galston',
      value: 311,
    },
    {
      label: 'Garden Suburb',
      value: 1148,
    },
    {
      label: 'Gateshead',
      value: 1149,
    },
    {
      label: 'Georges Hall',
      value: 312,
    },
    {
      label: 'Georgetown',
      value: 1150,
    },
    {
      label: 'Gerringong',
      value: 1151,
    },
    {
      label: 'Gerroa',
      value: 1152,
    },
    {
      label: 'Gilead',
      value: 314,
    },
    {
      label: 'Gillieston Heights',
      value: 1153,
    },
    {
      label: 'Girraween',
      value: 315,
    },
    {
      label: 'Gladesville (Hunters Hill LGA)',
      value: 316,
    },
    {
      label: 'Gladesville (Ryde LGA)',
      value: 317,
    },
    {
      label: 'Glebe',
      value: 318,
    },
    {
      label: 'Gledswood Hills',
      value: 319,
    },
    {
      label: 'Glen Alpine',
      value: 320,
    },
    {
      label: 'Glen Oak',
      value: 1154,
    },
    {
      label: 'Glenbrook',
      value: 321,
    },
    {
      label: 'Glendale',
      value: 1155,
    },
    {
      label: 'Glendenning',
      value: 322,
    },
    {
      label: 'Glenfield',
      value: 323,
    },
    {
      label: 'Glenhaven (The Hills LGA)',
      value: 325,
    },
    {
      label: 'Glenhaven (Hornsby LGA)',
      value: 324,
    },
    {
      label: 'Glenmore',
      value: 327,
    },
    {
      label: 'Glenmore Park',
      value: 326,
    },
    {
      label: 'Glenning Valley',
      value: 1156,
    },
    {
      label: 'Glenorie (The Hills LGA)',
      value: 329,
    },
    {
      label: 'Glenorie (Hornsby LGA)',
      value: 328,
    },
    {
      label: 'Glenwood',
      value: 330,
    },
    {
      label: 'Glenworth Valley',
      value: 1157,
    },
    {
      label: 'Glossodia',
      value: 331,
    },
    {
      label: 'Gordon',
      value: 332,
    },
    {
      label: 'Gorokan',
      value: 1158,
    },
    {
      label: 'Gosford',
      value: 1159,
    },
    {
      label: 'Gosforth',
      value: 1160,
    },
    {
      label: 'Grantham Farm',
      value: 1161,
    },
    {
      label: 'Granville (Parramatta LGA)',
      value: 334,
    },
    {
      label: 'Granville (Cumberland LGA)',
      value: 333,
    },
    {
      label: 'Grasmere',
      value: 335,
    },
    {
      label: 'Grays Point',
      value: 336,
    },
    {
      label: 'Great Mackerel Beach',
      value: 337,
    },
    {
      label: 'Green Point',
      value: 1162,
    },
    {
      label: 'Green Valley',
      value: 338,
    },
    {
      label: 'Greenacre (Canterbury-Bankstown LGA)',
      value: 339,
    },
    {
      label: 'Greenacre (Strathfield LGA)',
      value: 340,
    },
    {
      label: 'Greendale',
      value: 341,
    },
    {
      label: 'Greenfield Park',
      value: 342,
    },
    {
      label: 'Greengrove',
      value: 1163,
    },
    {
      label: 'Greenhills Beach',
      value: 343,
    },
    {
      label: 'Greenwell Point',
      value: 1164,
    },
    {
      label: 'Greenwich',
      value: 344,
    },
    {
      label: 'Gregory Hills',
      value: 345,
    },
    {
      label: 'Greta (Cessnock LGA)',
      value: 1165,
    },
    {
      label: 'Greta (Maitland LGA)',
      value: 1166,
    },
    {
      label: 'Greta Main',
      value: 1167,
    },
    {
      label: 'Greystanes',
      value: 347,
    },
    {
      label: 'Grose Vale',
      value: 348,
    },
    {
      label: 'Grose Wold',
      value: 349,
    },
    {
      label: 'Guildford',
      value: 350,
    },
    {
      label: 'Guildford West',
      value: 351,
    },
    {
      label: 'Gunderman',
      value: 1168,
    },
    {
      label: 'Gwandalan',
      value: 1169,
    },
    {
      label: 'Gwynneville',
      value: 1170,
    },
    {
      label: 'Gymea',
      value: 352,
    },
    {
      label: 'Gymea Bay',
      value: 353,
    },
    {
      label: 'Haberfield (Inner West LGA)',
      value: 354,
    },
    {
      label: 'Halekulani',
      value: 1172,
    },
    {
      label: 'Halloran',
      value: 1173,
    },
    {
      label: 'Hamilton',
      value: 1174,
    },
    {
      label: 'Hamilton East',
      value: 1175,
    },
    {
      label: 'Hamilton North',
      value: 1176,
    },
    {
      label: 'Hamilton South',
      value: 1177,
    },
    {
      label: 'Hamlyn Terrace',
      value: 1178,
    },
    {
      label: 'Hammondville',
      value: 355,
    },
    {
      label: 'Hardys Bay',
      value: 1179,
    },
    {
      label: 'Harpers Hill',
      value: 1180,
    },
    {
      label: 'Harrington Park',
      value: 356,
    },
    {
      label: 'Harris Park',
      value: 357,
    },
    {
      label: 'Hassall Grove',
      value: 358,
    },
    {
      label: 'Hawkesbury Heights',
      value: 360,
    },
    {
      label: 'Haymarket',
      value: 361,
    },
    {
      label: 'Haywards Bay',
      value: 1181,
    },
    {
      label: 'Hazelbrook',
      value: 362,
    },
    {
      label: 'Heathcote',
      value: 363,
    },
    {
      label: 'Heatherbrae',
      value: 1182,
    },
    {
      label: 'Hebersham',
      value: 364,
    },
    {
      label: 'Heckenberg',
      value: 365,
    },
    {
      label: 'Heddon Greta',
      value: 1183,
    },
    {
      label: 'Helensburgh',
      value: 1184,
    },
    {
      label: 'Henley',
      value: 366,
    },
    {
      label: 'Hexham',
      value: 1185,
    },
    {
      label: 'Higher Macdonald',
      value: 1186,
    },
    {
      label: 'Highfields',
      value: 1187,
    },
    {
      label: 'Hillsborough (Lake Macquarie LGA)',
      value: 1188,
    },
    {
      label: 'Hillsborough (Maitland LGA)',
      value: 1189,
    },
    {
      label: 'Hillsdale',
      value: 367,
    },
    {
      label: 'Hinchinbrook',
      value: 368,
    },
    {
      label: 'Hinton',
      value: 1190,
    },
    {
      label: 'Hobartville',
      value: 369,
    },
    {
      label: 'Holgate',
      value: 1191,
    },
    {
      label: 'Holmesville',
      value: 1192,
    },
    {
      label: 'Holroyd (Cumberland LGA)',
      value: 370,
    },
    {
      label: 'Holroyd (Parramatta LGA)',
      value: 371,
    },
    {
      label: 'Holsworthy (Campbelltown LGA)',
      value: 372,
    },
    {
      label: 'Holsworthy (Liverpool LGA)',
      value: 373,
    },
    {
      label: 'Holsworthy (Sutherland LGA)',
      value: 374,
    },
    {
      label: 'Homebush (Strathfield LGA)',
      value: 376,
    },
    {
      label: 'Homebush West',
      value: 377,
    },
    {
      label: 'Horningsea Park',
      value: 378,
    },
    {
      label: 'Hornsby',
      value: 381,
    },
    {
      label: 'Hornsby Heights',
      value: 380,
    },
    {
      label: 'Horseshoe Bend',
      value: 1193,
    },
    {
      label: 'Horsfield Bay',
      value: 1194,
    },
    {
      label: 'Horsley',
      value: 1195,
    },
    {
      label: 'Horsley Park',
      value: 382,
    },
    {
      label: 'Hoxton Park',
      value: 383,
    },
    {
      label: 'Hunters Hill',
      value: 385,
    },
    {
      label: 'Huntingwood',
      value: 386,
    },
    {
      label: 'Huntley',
      value: 1196,
    },
    {
      label: 'Huntleys Cove',
      value: 387,
    },
    {
      label: 'Huntleys Point',
      value: 388,
    },
    {
      label: 'Hurlstone Park (Inner West LGA)',
      value: 390,
    },
    {
      label: 'Hurlstone Park (Canterbury-Bankstown LGA)',
      value: 389,
    },
    {
      label: 'Hurstville',
      value: 391,
    },
    {
      label: 'Hurstville Grove',
      value: 392,
    },
    {
      label: 'Huskisson',
      value: 1197,
    },
    {
      label: 'Hyams Beach',
      value: 1198,
    },
    {
      label: 'Illaroo',
      value: 1199,
    },
    {
      label: 'Illawong',
      value: 393,
    },
    {
      label: 'Ingleburn',
      value: 394,
    },
    {
      label: 'Ingleside',
      value: 395,
    },
    {
      label: 'Islington',
      value: 1200,
    },
    {
      label: 'Jamberoo',
      value: 1201,
    },
    {
      label: 'Jamisontown',
      value: 397,
    },
    {
      label: 'Jannali',
      value: 398,
    },
    {
      label: 'Jaspers Brush',
      value: 1202,
    },
    {
      label: 'Jerrara',
      value: 1203,
    },
    {
      label: 'Jerrawangala',
      value: 1204,
    },
    {
      label: 'Jesmond',
      value: 1205,
    },
    {
      label: 'Jewells',
      value: 1206,
    },
    {
      label: 'Jilliby',
      value: 1207,
    },
    {
      label: 'Jordan Springs',
      value: 399,
    },
    {
      label: 'Kahibah',
      value: 1208,
    },
    {
      label: 'Kanahooka',
      value: 1209,
    },
    {
      label: 'Kangaroo Point',
      value: 400,
    },
    {
      label: 'Kangaroo Valley',
      value: 1210,
    },
    {
      label: 'Kangy Angy',
      value: 1211,
    },
    {
      label: 'Kanwal',
      value: 1212,
    },
    {
      label: 'Kareela',
      value: 401,
    },
    {
      label: 'Kariong',
      value: 1213,
    },
    {
      label: 'Karuah',
      value: 1214,
    },
    {
      label: 'Katoomba',
      value: 402,
    },
    {
      label: 'Kearns',
      value: 403,
    },
    {
      label: 'Kearsley',
      value: 1215,
    },
    {
      label: 'Keinbah',
      value: 1216,
    },
    {
      label: 'Keiraville',
      value: 1217,
    },
    {
      label: 'Kellyville',
      value: 404,
    },
    {
      label: 'Kellyville Ridge',
      value: 405,
    },
    {
      label: 'Kembla Grange',
      value: 1218,
    },
    {
      label: 'Kembla Heights',
      value: 1219,
    },
    {
      label: 'Kemps Creek (Liverpool LGA)',
      value: 406,
    },
    {
      label: 'Kemps Creek (Penrith LGA)',
      value: 407,
    },
    {
      label: 'Kensington',
      value: 408,
    },
    {
      label: 'Kenthurst',
      value: 409,
    },
    {
      label: 'Kentlyn',
      value: 410,
    },
    {
      label: 'Kiama',
      value: 1221,
    },
    {
      label: 'Kiama Downs',
      value: 1222,
    },
    {
      label: 'Kiama Heights',
      value: 1223,
    },
    {
      label: 'Kiar',
      value: 1224,
    },
    {
      label: 'Kilaben Bay',
      value: 1225,
    },
    {
      label: 'Killara',
      value: 411,
    },
    {
      label: 'Killarney Heights',
      value: 412,
    },
    {
      label: 'Killarney Vale',
      value: 1226,
    },
    {
      label: 'Killcare',
      value: 1227,
    },
    {
      label: 'Killcare Heights',
      value: 1228,
    },
    {
      label: 'Killingworth',
      value: 1229,
    },
    {
      label: 'Kincumber',
      value: 1230,
    },
    {
      label: 'Kincumber South',
      value: 1231,
    },
    {
      label: 'Kingfisher Shores',
      value: 1232,
    },
    {
      label: 'Kinghorne',
      value: 1233,
    },
    {
      label: 'Kings Langley',
      value: 413,
    },
    {
      label: 'Kings Park',
      value: 414,
    },
    {
      label: 'Kings Point',
      value: 1234,
    },
    {
      label: 'Kingsford',
      value: 415,
    },
    {
      label: 'Kingsgrove (Canterbury-Bankstown LGA)',
      value: 416,
    },
    {
      label: 'Kingsgrove (Georges River LGA)',
      value: 417,
    },
    {
      label: 'Kingsgrove (Bayside LGA)',
      value: 418,
    },
    {
      label: 'Kingswood',
      value: 419,
    },
    {
      label: 'Kioloa',
      value: 1235,
    },
    {
      label: 'Kirkham',
      value: 420,
    },
    {
      label: 'Kirrawee',
      value: 421,
    },
    {
      label: 'Kirribilli',
      value: 422,
    },
    {
      label: 'Kitchener',
      value: 1236,
    },
    {
      label: 'Knights Hill',
      value: 1237,
    },
    {
      label: 'Kogarah (Georges River LGA)',
      value: 424,
    },
    {
      label: 'Kogarah (Bayside LGA)',
      value: 425,
    },
    {
      label: 'Kogarah Bay',
      value: 423,
    },
    {
      label: 'Koolewong',
      value: 1238,
    },
    {
      label: 'Koonawarra',
      value: 1239,
    },
    {
      label: 'Kooragang',
      value: 1240,
    },
    {
      label: 'Kotara',
      value: 1241,
    },
    {
      label: 'Kotara South',
      value: 1242,
    },
    {
      label: 'Ku-ring-gai Chase',
      value: 427,
    },
    {
      label: 'Kulnura',
      value: 1243,
    },
    {
      label: 'Kurmond',
      value: 428,
    },
    {
      label: 'Kurnell',
      value: 429,
    },
    {
      label: 'Kurraba Point',
      value: 430,
    },
    {
      label: 'Kurrajong',
      value: 431,
    },
    {
      label: 'Kurrajong Heights',
      value: 432,
    },
    {
      label: 'Kurrajong Hills',
      value: 433,
    },
    {
      label: 'Kurri Kurri',
      value: 1244,
    },
    {
      label: 'Kyeemagh',
      value: 434,
    },
    {
      label: 'Kyle Bay',
      value: 435,
    },
    {
      label: 'La Perouse',
      value: 436,
    },
    {
      label: 'Laguna',
      value: 1245,
    },
    {
      label: 'Lake Conjola',
      value: 1246,
    },
    {
      label: 'Lake Haven',
      value: 1247,
    },
    {
      label: 'Lake Heights',
      value: 1248,
    },
    {
      label: 'Lake Illawarra',
      value: 1249,
    },
    {
      label: 'Lake Macquarie',
      value: 1251,
    },
    {
      label: 'Lake Munmorah',
      value: 1252,
    },
    {
      label: 'Lake Tabourie',
      value: 1253,
    },
    {
      label: 'Lakelands',
      value: 1254,
    },
    {
      label: 'Lakemba',
      value: 437,
    },
    {
      label: 'Lakesland',
      value: 438,
    },
    {
      label: 'Lalor Park',
      value: 439,
    },
    {
      label: 'Lambs Valley',
      value: 1255,
    },
    {
      label: 'Lambton',
      value: 1256,
    },
    {
      label: 'Lane Cove',
      value: 441,
    },
    {
      label: 'Lane Cove North (Lane Cove LGA)',
      value: 442,
    },
    {
      label: 'Lane Cove North (Willoughby LGA)',
      value: 443,
    },
    {
      label: 'Lane Cove West',
      value: 444,
    },
    {
      label: 'Lansdowne',
      value: 445,
    },
    {
      label: 'Lansvale',
      value: 446,
    },
    {
      label: 'Lapstone',
      value: 447,
    },
    {
      label: 'Largs',
      value: 1257,
    },
    {
      label: 'Laughtondale',
      value: 1258,
    },
    {
      label: 'Lavender Bay',
      value: 448,
    },
    {
      label: 'Lawson',
      value: 449,
    },
    {
      label: 'Leets Vale (Hawkesbury LGA)',
      value: 450,
    },
    {
      label: 'Leets Vale (The Hills LGA)',
      value: 451,
    },
    {
      label: 'Leichhardt',
      value: 452,
    },
    {
      label: 'Lemon Tree',
      value: 1259,
    },
    {
      label: 'Lemon Tree Passage',
      value: 1260,
    },
    {
      label: 'Lenaghan',
      value: 1261,
    },
    {
      label: 'Leonay',
      value: 454,
    },
    {
      label: 'Leppington (Camden LGA)',
      value: 455,
    },
    {
      label: 'Leppington (Liverpool LGA)',
      value: 456,
    },
    {
      label: 'Lethbridge Park',
      value: 457,
    },
    {
      label: 'Leumeah',
      value: 458,
    },
    {
      label: 'Leura',
      value: 459,
    },
    {
      label: 'Lewisham',
      value: 460,
    },
    {
      label: 'Liberty Grove',
      value: 461,
    },
    {
      label: 'Lidcombe (Cumberland LGA)',
      value: 462,
    },
    {
      label: 'Lidcombe (Parramatta LGA)',
      value: 463,
    },
    {
      label: 'Lilli Pilli',
      value: 464,
    },
    {
      label: 'Lilyfield',
      value: 465,
    },
    {
      label: 'Lilyvale',
      value: 1262,
    },
    {
      label: 'Linden',
      value: 466,
    },
    {
      label: 'Lindfield',
      value: 467,
    },
    {
      label: 'Linley Point',
      value: 468,
    },
    {
      label: 'Lisarow',
      value: 1263,
    },
    {
      label: 'Little Bay',
      value: 469,
    },
    {
      label: 'Little Forest',
      value: 1264,
    },
    {
      label: 'Little Jilliby',
      value: 1265,
    },
    {
      label: 'Little Pelican',
      value: 1266,
    },
    {
      label: 'Little Wobby',
      value: 1267,
    },
    {
      label: 'Liverpool',
      value: 471,
    },
    {
      label: 'Llandilo',
      value: 472,
    },
    {
      label: 'Lochinvar (Maitland LGA)',
      value: 1268,
    },
    {
      label: 'Lochinvar (Cessnock LGA)',
      value: 1269,
    },
    {
      label: 'Loftus',
      value: 473,
    },
    {
      label: 'Londonderry',
      value: 474,
    },
    {
      label: 'Long Jetty',
      value: 1270,
    },
    {
      label: 'Long Point',
      value: 475,
    },
    {
      label: 'Longreach',
      value: 1271,
    },
    {
      label: 'Longueville',
      value: 476,
    },
    {
      label: 'Lorn',
      value: 1272,
    },
    {
      label: 'Louth Park',
      value: 1273,
    },
    {
      label: 'Lovedale',
      value: 1274,
    },
    {
      label: 'Lovett Bay',
      value: 477,
    },
    {
      label: 'Lower Macdonald',
      value: 478,
    },
    {
      label: 'Lower Mangrove',
      value: 1275,
    },
    {
      label: 'Lower Portland (The Hills LGA)',
      value: 480,
    },
    {
      label: 'Lower Portland (Hawkesbury LGA)',
      value: 479,
    },
    {
      label: 'Loxford',
      value: 1276,
    },
    {
      label: 'Luddenham (Liverpool LGA)',
      value: 482,
    },
    {
      label: 'Luddenham (Penrith LGA)',
      value: 483,
    },
    {
      label: 'Lugarno',
      value: 484,
    },
    {
      label: 'Lurnea',
      value: 485,
    },
    {
      label: 'Luskintyre',
      value: 1277,
    },
    {
      label: 'Macmasters Beach',
      value: 1278,
    },
    {
      label: 'Macquarie Fields',
      value: 486,
    },
    {
      label: 'Macquarie Hills',
      value: 1279,
    },
    {
      label: 'Macquarie Links',
      value: 487,
    },
    {
      label: 'Macquarie Park',
      value: 488,
    },
    {
      label: 'Macquarie Pass',
      value: 1280,
    },
    {
      label: 'Maddens Plains',
      value: 1281,
    },
    {
      label: 'Magenta',
      value: 1282,
    },
    {
      label: 'Maianbar',
      value: 489,
    },
    {
      label: 'Maitland',
      value: 1284,
    },
    {
      label: 'Maitland Vale',
      value: 1285,
    },
    {
      label: 'Malabar',
      value: 490,
    },
    {
      label: 'Maldon',
      value: 491,
    },
    {
      label: 'Mallabula',
      value: 1286,
    },
    {
      label: 'Mandalong',
      value: 1287,
    },
    {
      label: 'Mangerton',
      value: 1288,
    },
    {
      label: 'Mangrove Creek',
      value: 1289,
    },
    {
      label: 'Mangrove Mountain',
      value: 1290,
    },
    {
      label: 'Manly',
      value: 492,
    },
    {
      label: 'Manly Vale',
      value: 493,
    },
    {
      label: 'Mannering Park (Central Coast LGA)',
      value: 1291,
    },
    {
      label: 'Mannering Park (Lake Macquarie LGA)',
      value: 1292,
    },
    {
      label: 'Manyana',
      value: 1293,
    },
    {
      label: 'Maraylya (The Hills LGA)',
      value: 495,
    },
    {
      label: 'Maraylya (Hawkesbury LGA)',
      value: 494,
    },
    {
      label: 'Marayong',
      value: 496,
    },
    {
      label: 'Mardi',
      value: 1294,
    },
    {
      label: 'Marks Point',
      value: 1295,
    },
    {
      label: 'Marlow',
      value: 1296,
    },
    {
      label: 'Marmong Point',
      value: 1297,
    },
    {
      label: 'Maroota (The Hills LGA)',
      value: 498,
    },
    {
      label: 'Maroota (Hornsby LGA)',
      value: 497,
    },
    {
      label: 'Maroubra',
      value: 499,
    },
    {
      label: 'Marrickville',
      value: 500,
    },
    {
      label: 'Marsden Park',
      value: 501,
    },
    {
      label: 'Marsfield',
      value: 502,
    },
    {
      label: 'Marshall Mount',
      value: 1298,
    },
    {
      label: 'Martinsville',
      value: 1299,
    },
    {
      label: 'Maryland',
      value: 1300,
    },
    {
      label: 'Maryville',
      value: 1301,
    },
    {
      label: 'Mascot',
      value: 503,
    },
    {
      label: 'Matcham',
      value: 1302,
    },
    {
      label: 'Matraville',
      value: 506,
    },
    {
      label: 'Mayfield (Newcastle LGA)',
      value: 1303,
    },
    {
      label: 'Mayfield (Shoalhaven LGA)',
      value: 1304,
    },
    {
      label: 'Mayfield East',
      value: 1305,
    },
    {
      label: 'Mayfield North',
      value: 1306,
    },
    {
      label: 'Mayfield West',
      value: 1307,
    },
    {
      label: 'Mays Hill (Parramatta LGA)',
      value: 508,
    },
    {
      label: 'Mays Hill (Cumberland LGA)',
      value: 507,
    },
    {
      label: 'McGraths Hill',
      value: 509,
    },
    {
      label: 'McMahons Point',
      value: 510,
    },
    {
      label: 'Meadowbank',
      value: 511,
    },
    {
      label: 'Medlow Bath',
      value: 512,
    },
    {
      label: 'Medowie',
      value: 1308,
    },
    {
      label: 'Megalong Valley',
      value: 513,
    },
    {
      label: 'Melonba',
      value: 1310,
    },
    {
      label: 'Melrose Park (Parramatta LGA)',
      value: 514,
    },
    {
      label: 'Melrose Park (Ryde LGA)',
      value: 515,
    },
    {
      label: 'Melville',
      value: 1311,
    },
    {
      label: 'Menai',
      value: 516,
    },
    {
      label: 'Menangle',
      value: 517,
    },
    {
      label: 'Menangle Park',
      value: 518,
    },
    {
      label: 'Merewether',
      value: 1312,
    },
    {
      label: 'Merewether Heights',
      value: 1313,
    },
    {
      label: 'Meroo Meadow',
      value: 1314,
    },
    {
      label: 'Merrylands (Cumberland LGA)',
      value: 519,
    },
    {
      label: 'Merrylands (Parramatta LGA)',
      value: 520,
    },
    {
      label: 'Merrylands West',
      value: 521,
    },
    {
      label: 'Metford',
      value: 1315,
    },
    {
      label: 'Middle Cove',
      value: 522,
    },
    {
      label: 'Middle Dural (The Hills LGA)',
      value: 524,
    },
    {
      label: 'Middle Dural (Hornsby LGA)',
      value: 523,
    },
    {
      label: 'Middleton Grange',
      value: 525,
    },
    {
      label: 'Miller',
      value: 526,
    },
    {
      label: 'Millers Forest',
      value: 1316,
    },
    {
      label: 'Millers Point',
      value: 527,
    },
    {
      label: 'Millfield',
      value: 1317,
    },
    {
      label: 'Milperra',
      value: 528,
    },
    {
      label: 'Milsons Passage',
      value: 529,
    },
    {
      label: 'Milsons Point',
      value: 530,
    },
    {
      label: 'Milton',
      value: 1318,
    },
    {
      label: 'Minchinbury',
      value: 531,
    },
    {
      label: 'Mindaribba',
      value: 1319,
    },
    {
      label: 'Minmi',
      value: 1320,
    },
    {
      label: 'Minnamurra',
      value: 1321,
    },
    {
      label: 'Minto',
      value: 532,
    },
    {
      label: 'Minto Heights',
      value: 533,
    },
    {
      label: 'Miranda',
      value: 534,
    },
    {
      label: 'Mirrabooka',
      value: 1322,
    },
    {
      label: 'Mogood',
      value: 1324,
    },
    {
      label: 'Mollymook',
      value: 1325,
    },
    {
      label: 'Mollymook Beach',
      value: 1326,
    },
    {
      label: 'Mona Vale',
      value: 535,
    },
    {
      label: 'Mondayong',
      value: 1327,
    },
    {
      label: 'Monterey',
      value: 536,
    },
    {
      label: 'Moollattoo',
      value: 1328,
    },
    {
      label: 'Moonee (Lake Macquarie LGA)',
      value: 1329,
    },
    {
      label: 'Moonee (Central Coast LGA)',
      value: 1330,
    },
    {
      label: 'Mooney Mooney',
      value: 1331,
    },
    {
      label: 'Mooney Mooney Creek',
      value: 1332,
    },
    {
      label: 'Moore Park',
      value: 537,
    },
    {
      label: 'Moorebank',
      value: 538,
    },
    {
      label: 'Morisset',
      value: 1333,
    },
    {
      label: 'Morisset Park',
      value: 1334,
    },
    {
      label: 'Morning Bay',
      value: 539,
    },
    {
      label: 'Morpeth',
      value: 1335,
    },
    {
      label: 'Mortdale',
      value: 540,
    },
    {
      label: 'Mortlake',
      value: 541,
    },
    {
      label: 'Morton',
      value: 1336,
    },
    {
      label: 'Mosman',
      value: 543,
    },
    {
      label: 'Mount Annan (Camden LGA)',
      value: 544,
    },
    {
      label: 'Mount Colah',
      value: 546,
    },
    {
      label: 'Mount Dee',
      value: 1337,
    },
    {
      label: 'Mount Druitt',
      value: 547,
    },
    {
      label: 'Mount Elliot',
      value: 1338,
    },
    {
      label: 'Mount Hunter',
      value: 548,
    },
    {
      label: 'Mount Hutton',
      value: 1339,
    },
    {
      label: 'Mount Irvine',
      value: 549,
    },
    {
      label: 'Mount Keira',
      value: 1340,
    },
    {
      label: 'Mount Kembla',
      value: 1341,
    },
    {
      label: 'Mount Kingiman',
      value: 1342,
    },
    {
      label: 'Mount Kuring-Gai',
      value: 550,
    },
    {
      label: 'Mount Lewis',
      value: 551,
    },
    {
      label: 'Mount Ousley',
      value: 1343,
    },
    {
      label: 'Mount Pleasant',
      value: 1344,
    },
    {
      label: 'Mount Pritchard (Fairfield LGA)',
      value: 552,
    },
    {
      label: 'Mount Pritchard (Liverpool LGA)',
      value: 553,
    },
    {
      label: 'Mount Riverview',
      value: 554,
    },
    {
      label: 'Mount Saint Thomas',
      value: 1345,
    },
    {
      label: 'Mount Tomah',
      value: 555,
    },
    {
      label: 'Mount Vernon',
      value: 556,
    },
    {
      label: 'Mount Victoria',
      value: 557,
    },
    {
      label: 'Mount View',
      value: 1346,
    },
    {
      label: 'Mount Vincent',
      value: 1347,
    },
    {
      label: 'Mount Warrigal',
      value: 1348,
    },
    {
      label: 'Mount White',
      value: 1349,
    },
    {
      label: 'Mount Wilson',
      value: 558,
    },
    {
      label: 'Mountain Lagoon',
      value: 559,
    },
    {
      label: 'Mowbray Park',
      value: 560,
    },
    {
      label: 'Mulbring',
      value: 1350,
    },
    {
      label: 'Mulgoa',
      value: 561,
    },
    {
      label: 'Mulgrave',
      value: 562,
    },
    {
      label: 'Mundamia',
      value: 1351,
    },
    {
      label: 'Murrays Beach',
      value: 1352,
    },
    {
      label: 'Murrays Run',
      value: 1353,
    },
    {
      label: 'Myola',
      value: 1354,
    },
    {
      label: 'Myuna Bay',
      value: 1355,
    },
    {
      label: 'Narara',
      value: 1356,
    },
    {
      label: 'Narellan',
      value: 563,
    },
    {
      label: 'Narellan Vale',
      value: 564,
    },
    {
      label: 'Naremburn',
      value: 565,
    },
    {
      label: 'Narrabeen',
      value: 566,
    },
    {
      label: 'Narrawallee',
      value: 1357,
    },
    {
      label: 'Narraweena',
      value: 567,
    },
    {
      label: 'Narwee (Canterbury-Bankstown LGA)',
      value: 568,
    },
    {
      label: 'Narwee (Georges River LGA)',
      value: 569,
    },
    {
      label: 'Nattai',
      value: 570,
    },
    {
      label: 'Neath',
      value: 1358,
    },
    {
      label: 'Nelson',
      value: 571,
    },
    {
      label: 'Nelson Bay',
      value: 1359,
    },
    {
      label: 'Nelsons Plains',
      value: 1360,
    },
    {
      label: 'Neutral Bay',
      value: 572,
    },
    {
      label: 'New Lambton',
      value: 1361,
    },
    {
      label: 'New Lambton Heights (Lake Macquarie LGA)',
      value: 1362,
    },
    {
      label: 'New Lambton Heights (Newcastle LGA)',
      value: 1363,
    },
    {
      label: 'Newcastle',
      value: 1365,
    },
    {
      label: 'Newcastle East',
      value: 1366,
    },
    {
      label: 'Newcastle West',
      value: 1367,
    },
    {
      label: 'Newington',
      value: 573,
    },
    {
      label: 'Newport',
      value: 574,
    },
    {
      label: 'Newtown (Inner West LGA)',
      value: 575,
    },
    {
      label: 'Newtown (Sydney LGA)',
      value: 576,
    },
    {
      label: 'Niagara Park',
      value: 1368,
    },
    {
      label: 'Nirimba Fields',
      value: 1369,
    },
    {
      label: 'Norah Head',
      value: 1370,
    },
    {
      label: 'Noraville',
      value: 1371,
    },
    {
      label: 'Nords Wharf',
      value: 1372,
    },
    {
      label: 'Normanhurst',
      value: 577,
    },
    {
      label: 'North Avoca',
      value: 1373,
    },
    {
      label: 'North Balgowlah',
      value: 578,
    },
    {
      label: 'North Bondi',
      value: 579,
    },
    {
      label: 'North Curl Curl',
      value: 580,
    },
    {
      label: 'North Epping',
      value: 581,
    },
    {
      label: 'North Gosford',
      value: 1374,
    },
    {
      label: 'North Kellyville',
      value: 1375,
    },
    {
      label: 'North Lambton',
      value: 1376,
    },
    {
      label: 'North Manly',
      value: 582,
    },
    {
      label: 'North Narrabeen',
      value: 583,
    },
    {
      label: 'North Nowra',
      value: 1377,
    },
    {
      label: 'North Parramatta',
      value: 584,
    },
    {
      label: 'North Richmond',
      value: 585,
    },
    {
      label: 'North Rocks (Parramatta LGA)',
      value: 586,
    },
    {
      label: 'North Rocks (The Hills LGA)',
      value: 587,
    },
    {
      label: 'North Rothbury',
      value: 1378,
    },
    {
      label: 'North Ryde',
      value: 588,
    },
    {
      label: 'North St Marys',
      value: 589,
    },
    {
      label: 'North Strathfield',
      value: 590,
    },
    {
      label: 'North Sydney',
      value: 592,
    },
    {
      label: 'North Turramurra',
      value: 593,
    },
    {
      label: 'North Wahroonga',
      value: 594,
    },
    {
      label: 'North Willoughby',
      value: 595,
    },
    {
      label: 'North Wollongong',
      value: 1379,
    },
    {
      label: 'Northbridge',
      value: 596,
    },
    {
      label: 'Northmead',
      value: 598,
    },
    {
      label: 'Northwood',
      value: 599,
    },
    {
      label: 'Norwest',
      value: 1380,
    },
    {
      label: 'Nowra',
      value: 1381,
    },
    {
      label: 'Nowra Hill',
      value: 1382,
    },
    {
      label: 'Nulkaba',
      value: 1383,
    },
    {
      label: 'Numbaa',
      value: 1384,
    },
    {
      label: 'Oak Flats',
      value: 1385,
    },
    {
      label: 'Oakdale',
      value: 600,
    },
    {
      label: 'Oakhampton',
      value: 1386,
    },
    {
      label: 'Oakhampton Heights',
      value: 1387,
    },
    {
      label: 'Oakhurst',
      value: 601,
    },
    {
      label: 'Oakville',
      value: 602,
    },
    {
      label: 'Oatlands',
      value: 603,
    },
    {
      label: 'Oatley',
      value: 604,
    },
    {
      label: 'Old Erowal Bay',
      value: 1388,
    },
    {
      label: 'Old Guildford',
      value: 605,
    },
    {
      label: 'Old Toongabbie',
      value: 606,
    },
    {
      label: 'Olney',
      value: 1389,
    },
    {
      label: 'One Mile',
      value: 1390,
    },
    {
      label: 'Oran Park',
      value: 607,
    },
    {
      label: 'Orangeville',
      value: 608,
    },
    {
      label: 'Orchard Hills',
      value: 609,
    },
    {
      label: 'Orient Point',
      value: 1391,
    },
    {
      label: 'Osterley',
      value: 1392,
    },
    {
      label: 'Oswald',
      value: 1393,
    },
    {
      label: 'Otford',
      value: 1394,
    },
    {
      label: 'Ourimbah',
      value: 1395,
    },
    {
      label: 'Oxford Falls',
      value: 610,
    },
    {
      label: 'Oxley Park',
      value: 611,
    },
    {
      label: 'Oyster Bay',
      value: 612,
    },
    {
      label: 'Oyster Cove',
      value: 1396,
    },
    {
      label: 'Paddington (Sydney LGA)',
      value: 613,
    },
    {
      label: 'Paddington (Woollahra LGA)',
      value: 614,
    },
    {
      label: 'Padstow',
      value: 615,
    },
    {
      label: 'Padstow Heights',
      value: 616,
    },
    {
      label: 'Pagewood',
      value: 617,
    },
    {
      label: 'Palm Beach',
      value: 618,
    },
    {
      label: 'Palm Grove',
      value: 1397,
    },
    {
      label: 'Palmdale',
      value: 1398,
    },
    {
      label: 'Panania',
      value: 619,
    },
    {
      label: 'Parklea',
      value: 620,
    },
    {
      label: 'Parma',
      value: 1399,
    },
    {
      label: 'Parramatta',
      value: 622,
    },
    {
      label: 'Patonga',
      value: 1400,
    },
    {
      label: 'Paxton',
      value: 1401,
    },
    {
      label: 'Paynes Crossing',
      value: 1402,
    },
    {
      label: 'Peakhurst',
      value: 623,
    },
    {
      label: 'Peakhurst Heights',
      value: 624,
    },
    {
      label: 'Pearl Beach',
      value: 1403,
    },
    {
      label: 'Peats Ridge',
      value: 1404,
    },
    {
      label: 'Pebbly Beach',
      value: 1405,
    },
    {
      label: 'Pelaw Main',
      value: 1406,
    },
    {
      label: 'Pelican',
      value: 1407,
    },
    {
      label: 'Pelton',
      value: 1408,
    },
    {
      label: 'Pemulwuy (Blacktown LGA)',
      value: 1409,
    },
    {
      label: 'Pemulwuy (Cumberland LGA)',
      value: 625,
    },
    {
      label: 'Pendle Hill (Cumberland LGA)',
      value: 626,
    },
    {
      label: 'Pendle Hill (Parramatta LGA)',
      value: 627,
    },
    {
      label: 'Pennant Hills',
      value: 628,
    },
    {
      label: 'Penrith',
      value: 630,
    },
    {
      label: 'Penshurst',
      value: 631,
    },
    {
      label: 'Petersham',
      value: 632,
    },
    {
      label: 'Pheasants Nest',
      value: 633,
    },
    {
      label: 'Phegans Bay',
      value: 1411,
    },
    {
      label: 'Phillip Bay',
      value: 634,
    },
    {
      label: 'Phoenix Park',
      value: 1412,
    },
    {
      label: 'Picketts Valley',
      value: 1413,
    },
    {
      label: 'Picnic Point',
      value: 635,
    },
    {
      label: 'Picton',
      value: 636,
    },
    {
      label: 'Pinny Beach',
      value: 1414,
    },
    {
      label: 'Pitnacree',
      value: 1415,
    },
    {
      label: 'Pitt Town',
      value: 637,
    },
    {
      label: 'Pitt Town Bottoms',
      value: 638,
    },
    {
      label: 'Pleasure Point',
      value: 639,
    },
    {
      label: 'Plumpton',
      value: 640,
    },
    {
      label: 'Point Clare',
      value: 1416,
    },
    {
      label: 'Point Frederick',
      value: 1417,
    },
    {
      label: 'Point Piper',
      value: 641,
    },
    {
      label: 'Point Wolstoncroft',
      value: 1418,
    },
    {
      label: 'Pointer Mountain',
      value: 1419,
    },
    {
      label: 'Pokolbin',
      value: 1420,
    },
    {
      label: 'Port Botany',
      value: 642,
    },
    {
      label: 'Port Hacking',
      value: 643,
    },
    {
      label: 'Port Kembla',
      value: 1421,
    },
    {
      label: 'Port Stephens',
      value: 1423,
    },
    {
      label: 'Porters Creek',
      value: 1424,
    },
    {
      label: 'Potts Hill',
      value: 644,
    },
    {
      label: 'Potts Point',
      value: 645,
    },
    {
      label: 'Prairiewood',
      value: 646,
    },
    {
      label: 'Prestons',
      value: 647,
    },
    {
      label: 'Pretty Beach (Central Coast LGA)',
      value: 1425,
    },
    {
      label: 'Pretty Beach (Shoalhaven LGA)',
      value: 1426,
    },
    {
      label: 'Primbee',
      value: 1427,
    },
    {
      label: 'Prospect',
      value: 648,
    },
    {
      label: 'Punchbowl',
      value: 649,
    },
    {
      label: 'Putney',
      value: 650,
    },
    {
      label: 'Pymble',
      value: 651,
    },
    {
      label: 'Pyree',
      value: 1429,
    },
    {
      label: 'Pyrmont',
      value: 652,
    },
    {
      label: 'Quakers Hill',
      value: 653,
    },
    {
      label: 'Queens Park',
      value: 654,
    },
    {
      label: 'Queenscliff',
      value: 655,
    },
    {
      label: 'Quiera',
      value: 1430,
    },
    {
      label: 'Quorrobolong',
      value: 1431,
    },
    {
      label: 'Raby',
      value: 656,
    },
    {
      label: 'Ramsgate (Georges River LGA)',
      value: 658,
    },
    {
      label: 'Ramsgate (Bayside LGA)',
      value: 659,
    },
    {
      label: 'Ramsgate Beach',
      value: 657,
    },
    {
      label: 'Randwick',
      value: 661,
    },
    {
      label: 'Rankin Park (Lake Macquarie LGA)',
      value: 1432,
    },
    {
      label: 'Rankin Park (Newcastle LGA)',
      value: 1433,
    },
    {
      label: 'Rathmines',
      value: 1434,
    },
    {
      label: 'Ravensdale',
      value: 1435,
    },
    {
      label: 'Raworth',
      value: 1436,
    },
    {
      label: 'Raymond Terrace',
      value: 1437,
    },
    {
      label: 'Razorback',
      value: 662,
    },
    {
      label: 'Red Rocks',
      value: 1438,
    },
    {
      label: 'Redfern',
      value: 663,
    },
    {
      label: 'Redhead',
      value: 1439,
    },
    {
      label: 'Regents Park (Cumberland LGA)',
      value: 665,
    },
    {
      label: 'Regents Park (Canterbury-Bankstown LGA)',
      value: 664,
    },
    {
      label: 'Regentville',
      value: 666,
    },
    {
      label: 'Revesby',
      value: 667,
    },
    {
      label: 'Revesby Heights',
      value: 668,
    },
    {
      label: 'Rhodes',
      value: 669,
    },
    {
      label: 'Richards',
      value: 1440,
    },
    {
      label: 'Richmond',
      value: 670,
    },
    {
      label: 'Richmond Lowlands',
      value: 671,
    },
    {
      label: 'Richmond Vale',
      value: 1441,
    },
    {
      label: 'Riverstone',
      value: 672,
    },
    {
      label: 'Riverview',
      value: 674,
    },
    {
      label: 'Riverwood (Canterbury-Bankstown LGA)',
      value: 675,
    },
    {
      label: 'Riverwood (Georges River LGA)',
      value: 676,
    },
    {
      label: 'Rockdale',
      value: 677,
    },
    {
      label: 'Rocky Point',
      value: 1442,
    },
    {
      label: 'Rodd Point',
      value: 678,
    },
    {
      label: 'Rookwood',
      value: 679,
    },
    {
      label: 'Rooty Hill',
      value: 680,
    },
    {
      label: 'Ropes Crossing',
      value: 681,
    },
    {
      label: 'Rose Bay (Waverley LGA)',
      value: 682,
    },
    {
      label: 'Rose Bay (Woollahra LGA)',
      value: 683,
    },
    {
      label: 'Rose Valley',
      value: 1443,
    },
    {
      label: 'Rosebery (Bayside LGA)',
      value: 684,
    },
    {
      label: 'Rosebery (Sydney LGA)',
      value: 685,
    },
    {
      label: 'Rosebrook',
      value: 1444,
    },
    {
      label: 'Rosehill',
      value: 686,
    },
    {
      label: 'Roselands',
      value: 687,
    },
    {
      label: 'Rosemeadow',
      value: 688,
    },
    {
      label: 'Roseville (Ku-ring-gai LGA)',
      value: 690,
    },
    {
      label: 'Roseville (Willoughby LGA)',
      value: 691,
    },
    {
      label: 'Roseville Chase',
      value: 689,
    },
    {
      label: 'Rossmore (Camden LGA)',
      value: 692,
    },
    {
      label: 'Rossmore (Liverpool LGA)',
      value: 693,
    },
    {
      label: 'Rothbury',
      value: 1445,
    },
    {
      label: 'Rouse Hill (The Hills LGA)',
      value: 695,
    },
    {
      label: 'Rouse Hill (Blacktown LGA)',
      value: 694,
    },
    {
      label: 'Royal National Park',
      value: 696,
    },
    {
      label: 'Rozelle',
      value: 697,
    },
    {
      label: 'Ruse',
      value: 698,
    },
    {
      label: 'Rushcutters Bay',
      value: 699,
    },
    {
      label: 'Russell Lea',
      value: 700,
    },
    {
      label: 'Russell Vale',
      value: 1446,
    },
    {
      label: 'Rutherford',
      value: 1447,
    },
    {
      label: 'Rydalmere',
      value: 701,
    },
    {
      label: 'Ryde',
      value: 703,
    },
    {
      label: 'Ryhope',
      value: 1448,
    },
    {
      label: 'Sackville',
      value: 704,
    },
    {
      label: 'Sackville North',
      value: 705,
    },
    {
      label: 'Saddleback Mountain',
      value: 1449,
    },
    {
      label: 'Sadleir',
      value: 706,
    },
    {
      label: 'Salamander Bay',
      value: 1450,
    },
    {
      label: 'Salt Ash',
      value: 1451,
    },
    {
      label: 'San Remo',
      value: 1452,
    },
    {
      label: 'Sanctuary Point',
      value: 1453,
    },
    {
      label: 'Sandgate',
      value: 1454,
    },
    {
      label: 'Sandringham',
      value: 707,
    },
    {
      label: 'Sandy Point',
      value: 708,
    },
    {
      label: 'Sans Souci (Georges River LGA)',
      value: 709,
    },
    {
      label: 'Sans Souci (Bayside LGA)',
      value: 710,
    },
    {
      label: 'Saratoga',
      value: 1455,
    },
    {
      label: 'Sassafras',
      value: 1456,
    },
    {
      label: 'Sawyers Gully',
      value: 1457,
    },
    {
      label: 'Scarborough',
      value: 1458,
    },
    {
      label: 'Scheyville',
      value: 711,
    },
    {
      label: 'Schofields',
      value: 712,
    },
    {
      label: 'Scotland Island',
      value: 713,
    },
    {
      label: 'Seaforth',
      value: 714,
    },
    {
      label: 'Seaham',
      value: 1459,
    },
    {
      label: 'Seahampton',
      value: 1460,
    },
    {
      label: 'Sefton',
      value: 715,
    },
    {
      label: 'Seven Hills (Blacktown LGA)',
      value: 716,
    },
    {
      label: 'Seven Hills (Parramatta LGA)',
      value: 717,
    },
    {
      label: 'Shalvey',
      value: 718,
    },
    {
      label: 'Shanes Park',
      value: 719,
    },
    {
      label: 'Shell Cove',
      value: 1461,
    },
    {
      label: 'Shellharbour',
      value: 1463,
    },
    {
      label: 'Shellharbour City Centre',
      value: 1464,
    },
    {
      label: 'Shelly Beach',
      value: 1465,
    },
    {
      label: 'Shoal Bay',
      value: 1466,
    },
    {
      label: 'Shoalhaven Heads',
      value: 1468,
    },
    {
      label: 'Shortland',
      value: 1469,
    },
    {
      label: 'Silverdale (Wollondilly LGA)',
      value: 721,
    },
    {
      label: 'Silverwater (Lake Macquarie LGA)',
      value: 1470,
    },
    {
      label: 'Silverwater (Parramatta LGA)',
      value: 722,
    },
    {
      label: 'Smeaton Grange',
      value: 723,
    },
    {
      label: 'Smithfield (Fairfield LGA)',
      value: 725,
    },
    {
      label: 'Smithfield (Cumberland LGA)',
      value: 724,
    },
    {
      label: 'Soldiers Point',
      value: 1472,
    },
    {
      label: 'Somersby',
      value: 1473,
    },
    {
      label: 'South Coogee',
      value: 726,
    },
    {
      label: 'South Granville',
      value: 727,
    },
    {
      label: 'South Hurstville',
      value: 728,
    },
    {
      label: 'South Maitland',
      value: 1474,
    },
    {
      label: 'South Maroota',
      value: 729,
    },
    {
      label: 'South Nowra',
      value: 1475,
    },
    {
      label: 'South Penrith',
      value: 730,
    },
    {
      label: 'South Turramurra',
      value: 731,
    },
    {
      label: 'South Wentworthville',
      value: 732,
    },
    {
      label: 'South Windsor',
      value: 733,
    },
    {
      label: 'Speers Point',
      value: 1476,
    },
    {
      label: 'Spencer',
      value: 1477,
    },
    {
      label: 'Spring Farm',
      value: 734,
    },
    {
      label: 'Spring Hill',
      value: 1478,
    },
    {
      label: 'Springfield',
      value: 1479,
    },
    {
      label: 'Springwood',
      value: 735,
    },
    {
      label: 'St Albans',
      value: 736,
    },
    {
      label: 'St Andrews',
      value: 737,
    },
    {
      label: 'St Clair',
      value: 738,
    },
    {
      label: 'St George',
      value: 1480,
    },
    {
      label: 'St Georges Basin',
      value: 1481,
    },
    {
      label: 'St Helens Park',
      value: 739,
    },
    {
      label: 'St Huberts Island',
      value: 1482,
    },
    {
      label: 'St Ives',
      value: 741,
    },
    {
      label: 'St Ives Chase',
      value: 740,
    },
    {
      label: 'St Johns Park',
      value: 742,
    },
    {
      label: 'St Leonards (Lane Cove LGA)',
      value: 743,
    },
    {
      label: 'St Leonards (North Sydney LGA)',
      value: 744,
    },
    {
      label: 'St Leonards (Willoughby LGA)',
      value: 745,
    },
    {
      label: 'St Marys',
      value: 746,
    },
    {
      label: 'St Peters (Inner West LGA)',
      value: 747,
    },
    {
      label: 'St Peters (Sydney LGA)',
      value: 748,
    },
    {
      label: 'Stanford Merthyr',
      value: 1483,
    },
    {
      label: 'Stanhope Gardens',
      value: 749,
    },
    {
      label: 'Stanmore',
      value: 750,
    },
    {
      label: 'Stanwell Park',
      value: 1484,
    },
    {
      label: 'Stanwell Tops',
      value: 1485,
    },
    {
      label: 'Stockrington',
      value: 1486,
    },
    {
      label: 'Stockton',
      value: 1487,
    },
    {
      label: 'Strathfield (Burwood LGA)',
      value: 752,
    },
    {
      label: 'Strathfield (Canada Bay LGA)',
      value: 753,
    },
    {
      label: 'Strathfield (Strathfield LGA)',
      value: 755,
    },
    {
      label: 'Strathfield South',
      value: 754,
    },
    {
      label: 'Summer Hill',
      value: 756,
    },
    {
      label: 'Summerland Point',
      value: 1488,
    },
    {
      label: 'Sun Valley',
      value: 757,
    },
    {
      label: 'Sunshine',
      value: 1489,
    },
    {
      label: 'Surry Hills',
      value: 758,
    },
    {
      label: 'Sussex Inlet',
      value: 1490,
    },
    {
      label: 'Sutherland',
      value: 760,
    },
    {
      label: 'Swan Bay',
      value: 1491,
    },
    {
      label: 'Swanhaven',
      value: 1492,
    },
    {
      label: 'Swansea',
      value: 1493,
    },
    {
      label: 'Swansea Heads',
      value: 1494,
    },
    {
      label: 'Sweetmans Creek',
      value: 1495,
    },
    {
      label: 'Sydenham',
      value: 761,
    },
    {
      label: 'Sydney',
      value: 764,
    },
    {
      label: 'Sydney Olympic Park (Parramatta LGA)',
      value: 763,
    },
    {
      label: 'Sylvania',
      value: 765,
    },
    {
      label: 'Sylvania Waters',
      value: 766,
    },
    {
      label: 'Tacoma',
      value: 1497,
    },
    {
      label: 'Tacoma South',
      value: 1498,
    },
    {
      label: 'Tahmoor',
      value: 767,
    },
    {
      label: 'Tallawong',
      value: 1499,
    },
    {
      label: 'Tallowal',
      value: 1500,
    },
    {
      label: 'Tamarama',
      value: 768,
    },
    {
      label: 'Tanilba Bay',
      value: 1501,
    },
    {
      label: 'Tapitallee',
      value: 1502,
    },
    {
      label: 'Taren Point',
      value: 769,
    },
    {
      label: 'Tarrawanna',
      value: 1503,
    },
    {
      label: 'Tarro',
      value: 1504,
    },
    {
      label: 'Tascott',
      value: 1505,
    },
    {
      label: 'Taylors Beach',
      value: 1506,
    },
    {
      label: 'Telarah',
      value: 1507,
    },
    {
      label: 'Telopea',
      value: 770,
    },
    {
      label: 'Tempe',
      value: 771,
    },
    {
      label: 'Ten Mile Hollow (Central Coast LGA)',
      value: 1508,
    },
    {
      label: 'Tenambit',
      value: 1509,
    },
    {
      label: 'Tennyson',
      value: 773,
    },
    {
      label: 'Tennyson Point',
      value: 774,
    },
    {
      label: 'Teralba',
      value: 1510,
    },
    {
      label: 'Terara',
      value: 1511,
    },
    {
      label: 'Termeil',
      value: 1512,
    },
    {
      label: 'Terrey Hills',
      value: 775,
    },
    {
      label: 'Terrigal',
      value: 1513,
    },
    {
      label: 'The Devils Wilderness',
      value: 776,
    },
    {
      label: 'The Entrance',
      value: 1514,
    },
    {
      label: 'The Entrance North',
      value: 1515,
    },
    {
      label: 'The Hill',
      value: 1516,
    },
    {
      label: 'The Junction',
      value: 1517,
    },
    {
      label: 'The Oaks',
      value: 778,
    },
    {
      label: 'The Ponds',
      value: 779,
    },
    {
      label: 'The Rocks',
      value: 780,
    },
    {
      label: 'The Slopes',
      value: 781,
    },
    {
      label: 'Theresa Park',
      value: 782,
    },
    {
      label: 'Thirlmere',
      value: 783,
    },
    {
      label: 'Thirroul',
      value: 1518,
    },
    {
      label: 'Thornleigh',
      value: 784,
    },
    {
      label: 'Thornton',
      value: 1519,
    },
    {
      label: 'Tianjara',
      value: 1520,
    },
    {
      label: 'Tighes Hill',
      value: 1521,
    },
    {
      label: 'Tilligerry Creek',
      value: 1522,
    },
    {
      label: 'Tingira Heights',
      value: 1523,
    },
    {
      label: 'Tocal',
      value: 1524,
    },
    {
      label: 'Tolwong',
      value: 1525,
    },
    {
      label: 'Tomago',
      value: 1526,
    },
    {
      label: 'Tomerong',
      value: 1527,
    },
    {
      label: 'Tongarra',
      value: 1528,
    },
    {
      label: 'Toolijooa',
      value: 1529,
    },
    {
      label: 'Toongabbie (Blacktown LGA)',
      value: 785,
    },
    {
      label: 'Toongabbie (Cumberland LGA)',
      value: 786,
    },
    {
      label: 'Toongabbie (Parramatta LGA)',
      value: 787,
    },
    {
      label: 'Toowoon Bay',
      value: 1530,
    },
    {
      label: 'Toronto',
      value: 1531,
    },
    {
      label: 'Touga',
      value: 1532,
    },
    {
      label: 'Toukley',
      value: 1533,
    },
    {
      label: 'Towradgi',
      value: 1534,
    },
    {
      label: 'Tregear',
      value: 788,
    },
    {
      label: 'Tuggerah',
      value: 1535,
    },
    {
      label: 'Tuggerawong',
      value: 1536,
    },
    {
      label: 'Tullarwalla',
      value: 1537,
    },
    {
      label: 'Tullimbar',
      value: 1538,
    },
    {
      label: 'Tumbi Umbi',
      value: 1539,
    },
    {
      label: 'Turramurra',
      value: 789,
    },
    {
      label: 'Turrella',
      value: 790,
    },
    {
      label: 'Twelve Mile Creek',
      value: 1540,
    },
    {
      label: 'Twelve Mile Peg',
      value: 1541,
    },
    {
      label: 'Ulladulla',
      value: 1542,
    },
    {
      label: 'Ultimo',
      value: 791,
    },
    {
      label: 'Umina Beach',
      value: 1543,
    },
    {
      label: 'Unanderra',
      value: 1544,
    },
    {
      label: 'Upper Colo',
      value: 792,
    },
    {
      label: 'Upper Kangaroo River',
      value: 1545,
    },
    {
      label: 'Upper Kangaroo Valley',
      value: 1546,
    },
    {
      label: 'Upper Macdonald',
      value: 1547,
    },
    {
      label: 'Upper Mangrove',
      value: 1548,
    },
    {
      label: 'Valentine',
      value: 1549,
    },
    {
      label: 'Valley Heights',
      value: 793,
    },
    {
      label: 'Varroville',
      value: 794,
    },
    {
      label: 'Vaucluse (Waverley LGA)',
      value: 795,
    },
    {
      label: 'Vaucluse (Woollahra LGA)',
      value: 796,
    },
    {
      label: 'Villawood (Canterbury-Bankstown LGA)',
      value: 797,
    },
    {
      label: 'Villawood (Fairfield LGA)',
      value: 798,
    },
    {
      label: 'Vincentia',
      value: 1550,
    },
    {
      label: 'Vineyard (Hawkesbury LGA)',
      value: 800,
    },
    {
      label: 'Vineyard (Blacktown LGA)',
      value: 799,
    },
    {
      label: 'Voyager Point',
      value: 801,
    },
    {
      label: 'Wadalba',
      value: 1551,
    },
    {
      label: 'Wagstaffe',
      value: 1552,
    },
    {
      label: 'Wahroonga (Hornsby LGA)',
      value: 802,
    },
    {
      label: 'Wahroonga (Ku-ring-gai LGA)',
      value: 803,
    },
    {
      label: 'Waitara',
      value: 804,
    },
    {
      label: 'Wakefield',
      value: 1553,
    },
    {
      label: 'Wakeley',
      value: 805,
    },
    {
      label: 'Wallacia (Liverpool LGA)',
      value: 806,
    },
    {
      label: 'Wallacia (Penrith LGA)',
      value: 807,
    },
    {
      label: 'Wallacia (Wollondilly LGA)',
      value: 808,
    },
    {
      label: 'Wallalong',
      value: 1554,
    },
    {
      label: 'Wallarah',
      value: 1555,
    },
    {
      label: 'Wallsend',
      value: 1556,
    },
    {
      label: 'Wamberal',
      value: 1557,
    },
    {
      label: 'Wandandian',
      value: 1558,
    },
    {
      label: 'Wangi Wangi',
      value: 1559,
    },
    {
      label: 'Warabrook',
      value: 1560,
    },
    {
      label: 'Waratah',
      value: 1561,
    },
    {
      label: 'Waratah West',
      value: 1562,
    },
    {
      label: 'Wareemba',
      value: 809,
    },
    {
      label: 'Warilla',
      value: 1563,
    },
    {
      label: 'Warners Bay',
      value: 1564,
    },
    {
      label: 'Warnervale',
      value: 1565,
    },
    {
      label: 'Warragamba',
      value: 810,
    },
    {
      label: 'Warrawee',
      value: 811,
    },
    {
      label: 'Warrawong',
      value: 1566,
    },
    {
      label: 'Warriewood',
      value: 812,
    },
    {
      label: 'Warrimoo',
      value: 813,
    },
    {
      label: 'Warwick Farm',
      value: 814,
    },
    {
      label: 'Watagan',
      value: 1567,
    },
    {
      label: 'Watanobbi',
      value: 1568,
    },
    {
      label: 'Waterfall',
      value: 815,
    },
    {
      label: 'Waterloo',
      value: 816,
    },
    {
      label: 'Watersleigh',
      value: 1569,
    },
    {
      label: 'Watsons Bay',
      value: 817,
    },
    {
      label: 'Wattamolla',
      value: 1570,
    },
    {
      label: 'Wattle Grove',
      value: 818,
    },
    {
      label: 'Waverley',
      value: 820,
    },
    {
      label: 'Waverton',
      value: 821,
    },
    {
      label: 'Webbs Creek',
      value: 1571,
    },
    {
      label: 'Wedderburn',
      value: 822,
    },
    {
      label: 'Wendoree Park',
      value: 1572,
    },
    {
      label: 'Wentworth Falls',
      value: 823,
    },
    {
      label: 'Wentworth Point',
      value: 824,
    },
    {
      label: 'Wentworthville (Cumberland LGA)',
      value: 825,
    },
    {
      label: 'Wentworthville (Parramatta LGA)',
      value: 826,
    },
    {
      label: 'Werombi',
      value: 827,
    },
    {
      label: 'Werri Beach',
      value: 1573,
    },
    {
      label: 'Werrington',
      value: 828,
    },
    {
      label: 'Werrington County',
      value: 829,
    },
    {
      label: 'Werrington Downs',
      value: 830,
    },
    {
      label: 'West Gosford',
      value: 1574,
    },
    {
      label: 'West Hoxton',
      value: 831,
    },
    {
      label: 'West Nowra',
      value: 1575,
    },
    {
      label: 'West Pennant Hills (The Hills LGA)',
      value: 833,
    },
    {
      label: 'West Pennant Hills (Hornsby LGA)',
      value: 832,
    },
    {
      label: 'West Pymble',
      value: 834,
    },
    {
      label: 'West Ryde',
      value: 835,
    },
    {
      label: 'West Wallsend',
      value: 1576,
    },
    {
      label: 'West Wollongong',
      value: 1577,
    },
    {
      label: 'Westleigh',
      value: 836,
    },
    {
      label: 'Westmead (Cumberland LGA)',
      value: 837,
    },
    {
      label: 'Westmead (Parramatta LGA)',
      value: 838,
    },
    {
      label: 'Weston',
      value: 1578,
    },
    {
      label: 'Wetherill Park',
      value: 839,
    },
    {
      label: 'Whalan',
      value: 840,
    },
    {
      label: 'Whale Beach',
      value: 841,
    },
    {
      label: 'Wheeler Heights',
      value: 842,
    },
    {
      label: 'Wheeny Creek',
      value: 843,
    },
    {
      label: 'Whitebridge',
      value: 1579,
    },
    {
      label: 'Wickham',
      value: 1580,
    },
    {
      label: 'Wilberforce',
      value: 844,
    },
    {
      label: 'Wiley Park',
      value: 845,
    },
    {
      label: 'Williamtown',
      value: 1581,
    },
    {
      label: 'Willmot',
      value: 846,
    },
    {
      label: 'Willoughby',
      value: 849,
    },
    {
      label: 'Willoughby East',
      value: 848,
    },
    {
      label: 'Willow Vale',
      value: 1582,
    },
    {
      label: 'Wilton',
      value: 850,
    },
    {
      label: 'Windale',
      value: 1583,
    },
    {
      label: 'Windang',
      value: 1584,
    },
    {
      label: 'Windella',
      value: 1585,
    },
    {
      label: 'Windermere',
      value: 1586,
    },
    {
      label: 'Windermere Park',
      value: 1587,
    },
    {
      label: 'Windsor',
      value: 852,
    },
    {
      label: 'Windsor Downs',
      value: 851,
    },
    {
      label: 'Winmalee',
      value: 853,
    },
    {
      label: 'Winston Hills (The Hills LGA)',
      value: 855,
    },
    {
      label: 'Winston Hills (Parramatta LGA)',
      value: 854,
    },
    {
      label: 'Wisemans Ferry (The Hills LGA)',
      value: 858,
    },
    {
      label: 'Wisemans Ferry (Central Coast LGA)',
      value: 1588,
    },
    {
      label: 'Wisemans Ferry (Hawkesbury LGA)',
      value: 856,
    },
    {
      label: 'Wisemans Ferry (Hornsby LGA)',
      value: 857,
    },
    {
      label: 'Wolli Creek',
      value: 859,
    },
    {
      label: 'Wollombi',
      value: 1589,
    },
    {
      label: 'Wollongong',
      value: 1591,
    },
    {
      label: 'Wollstonecraft',
      value: 861,
    },
    {
      label: 'Wollumboola',
      value: 1592,
    },
    {
      label: 'Wombarra',
      value: 1593,
    },
    {
      label: 'Wondabyne',
      value: 1595,
    },
    {
      label: 'Wongawilli',
      value: 1596,
    },
    {
      label: 'Woodberry',
      value: 1597,
    },
    {
      label: 'Woodbine',
      value: 862,
    },
    {
      label: 'Woodburn',
      value: 1598,
    },
    {
      label: 'Woodcroft',
      value: 863,
    },
    {
      label: 'Woodford',
      value: 864,
    },
    {
      label: 'Woodhill',
      value: 1599,
    },
    {
      label: 'Woodpark',
      value: 865,
    },
    {
      label: 'Woodrising',
      value: 1600,
    },
    {
      label: 'Woodstock',
      value: 1601,
    },
    {
      label: 'Woodville (Maitland LGA)',
      value: 1602,
    },
    {
      label: 'Woodville (Port Stephens LGA)',
      value: 1603,
    },
    {
      label: 'Woollahra',
      value: 867,
    },
    {
      label: 'Woollamia',
      value: 1604,
    },
    {
      label: 'Woolloomooloo',
      value: 868,
    },
    {
      label: 'Woolooware',
      value: 869,
    },
    {
      label: 'Woolwich (Hunters Hill LGA)',
      value: 870,
    },
    {
      label: 'Woolwich (Inner West LGA)',
      value: 1605,
    },
    {
      label: 'Woongarrah',
      value: 1606,
    },
    {
      label: 'Woonona',
      value: 1607,
    },
    {
      label: 'Woronora',
      value: 871,
    },
    {
      label: 'Woronora Dam (Campbelltown LGA)',
      value: 1608,
    },
    {
      label: 'Woronora Dam (Sutherland LGA)',
      value: 1609,
    },
    {
      label: 'Woronora Dam (Wollongong LGA)',
      value: 1610,
    },
    {
      label: 'Woronora Heights',
      value: 872,
    },
    {
      label: 'Worrigee',
      value: 1611,
    },
    {
      label: 'Worrowing Heights',
      value: 1612,
    },
    {
      label: 'Woy Woy',
      value: 1613,
    },
    {
      label: 'Woy Woy Bay',
      value: 1614,
    },
    {
      label: 'Wrights Beach',
      value: 1615,
    },
    {
      label: 'Wrights Creek',
      value: 873,
    },
    {
      label: 'Wybung',
      value: 1616,
    },
    {
      label: 'Wyee',
      value: 1617,
    },
    {
      label: 'Wyee Point',
      value: 1618,
    },
    {
      label: 'Wyoming',
      value: 1619,
    },
    {
      label: 'Wyong',
      value: 1620,
    },
    {
      label: 'Wyong Creek',
      value: 1621,
    },
    {
      label: 'Wyongah',
      value: 1622,
    },
    {
      label: 'Yadboro',
      value: 1623,
    },
    {
      label: 'Yagoona',
      value: 874,
    },
    {
      label: 'Yallah',
      value: 1624,
    },
    {
      label: 'Yalwal',
      value: 1625,
    },
    {
      label: 'Yanderra',
      value: 875,
    },
    {
      label: 'Yarramalong',
      value: 1626,
    },
    {
      label: 'Yarramundi',
      value: 876,
    },
    {
      label: 'Yarrawarrah',
      value: 877,
    },
    {
      label: 'Yarrawonga Park',
      value: 1627,
    },
    {
      label: 'Yattalunga',
      value: 1628,
    },
    {
      label: 'Yatte Yattah',
      value: 1629,
    },
    {
      label: 'Yellow Rock (Blue Mountains LGA)',
      value: 878,
    },
    {
      label: 'Yellow Rock (Kiama LGA)',
      value: 1630,
    },
    {
      label: 'Yellow Rock (Shellharbour LGA)',
      value: 1631,
    },
    {
      label: 'Yengo National Park',
      value: 1632,
    },
    {
      label: 'Yennora (Fairfield LGA)',
      value: 880,
    },
    {
      label: 'Yennora (Cumberland LGA)',
      value: 879,
    },
    {
      label: 'Yerranderie',
      value: 881,
    },
    {
      label: 'Yerriyong',
      value: 1633,
    },
    {
      label: 'Yowie Bay',
      value: 882,
    },
    {
      label: 'Zetland',
      value: 883,
    },
  ],
});

const keyedLocations = pipe(
  values,
  flatMap(identity),
  keyBy('value'),
)(locations);
const updatedKeyedLocations = pipe(
  values,
  flatMap(identity),
  keyBy('value'),
)(locations);

const locationsToLocationTypes = pipe(
  toPairs,
  flatMap(([locationType, ls]) =>
    map((location) => [location.value, locationType], ls),
  ),
  fromPairs,
)(locations);
const updatedLocationsToLocationTypes = pipe(
  toPairs,
  flatMap(([locationType, ls]) =>
    map((location) => [location.value, locationType], ls),
  ),
  fromPairs,
)(updatedLocations);

const interventionAreaValueKeys = {
  'residential-building-standards': ['now', 'y2030', 'y2040'],
  'non-residential-building-standards': ['now', 'y2030', 'y2040'],
  'residential-retrofits': ['y2030', 'y2040', 'y2050', 'strategy'],
  'non-residential-retrofits': ['y2030', 'y2040', 'y2050', 'strategy'],
  'residential-vkt-change': ['y2030', 'y2040', 'y2050'],
  'residential-mode-shift': ['y2030', 'y2040', 'y2050'],
  'public-transit-electrification': [],
  'ev-takeup': ['y2030', 'y2040', 'y2050'],
  'waste-reduction': ['y2030', 'y2040', 'y2050'],
  'recyclable-packaging': ['y2030', 'y2040', 'y2050'],
  'recyclable-material-management': ['y2030', 'y2040', 'y2050'],
  'recyclable-ewaste': ['y2030', 'y2040', 'y2050'],
  organics: ['y2030', 'y2040', 'y2050'],
  'waste-to-energy': ['y2030', 'y2040', 'y2050'],
};

const interventionAreaValueLabels = {
  now: 'Now',
  y2030: '2030',
  y2040: '2040',
  y2050: '2050',
};

// NOTE: This isn't great, but it improves lookup performance
// and ergonomics.
const interventionAreaKeyToPathwayInputBlockKey = {
  'public-transit-electrification': 'public_transit_electrification',
  'residential-building-standards': 'residential_building_standards',
  'residential-vkt-change': 'residential_vkt_change',
  'residential-retrofits': 'residential_retrofits',
  'non-residential-retrofits': 'non_residential_retrofits',
  'non-residential-building-standards': 'non_residential_building_standards',
  'residential-mode-shift': 'residential_mode_shift',
  'ev-takeup': 'ev_takeup',
  'waste-reduction': 'waste_reduction',
  'recyclable-packaging': 'recyclable_packaging',
  'recyclable-material-management': 'recyclable_material_management',
  'recyclable-ewaste': 'recyclable_ewaste',
  organics: 'organics',
  'waste-to-energy': 'waste_to_energy',
};

const interventionAreaDefaults = {
  'residential-building-standards': {
    now: 'basix20-10',
    y2030: 'basix20-10',
    y2040: 'basix20-10',
  },
  'non-residential-building-standards': {
    now: 'nabers55-equivalent',
    y2030: 'nabers55-equivalent',
    y2040: 'nabers55-equivalent',
  },
  'residential-retrofits': {
    y2030: 10.0,
    y2040: 20.0,
    y2050: 30.0,
    strategy: 'gas-and-electricity-efficiency',
  },
  'non-residential-retrofits': {
    y2030: 10.0,
    y2040: 20.0,
    y2050: 30.0,
    strategy: 'gas-and-electricity-efficiency',
  },
  'residential-vkt-change': {
    y2030: 0.0,
    y2040: 0.0,
    y2050: 0.0,
  },
  'residential-mode-shift': {
    y2030: 0.0,
    y2040: 0.0,
    y2050: 0.0,
  },
  'public-transit-electrification': 'slow',
  'ev-takeup': {
    y2030: 11.0,
    y2040: 55.0,
    y2050: 88.0,
  },
  'waste-reduction': {
    y2030: 10.0,
    y2040: 10.0,
    y2050: 10.0,
  },
  'recyclable-packaging': {
    y2030: 80.0,
    y2040: 80.0,
    y2050: 80.0,
  },
  'recyclable-material-management': {
    y2030: 80.0,
    y2040: 80.0,
    y2050: 80.0,
  },
  'recyclable-ewaste': {
    y2030: 80.0,
    y2040: 80.0,
    y2050: 80.0,
  },
  organics: {
    y2030: 50.0,
    y2040: 50.0,
    y2050: 50.0,
  },
  'waste-to-energy': {
    y2030: 30.0,
    y2040: 30.0,
    y2050: 30.0,
  },
};

const interventionAreaKeysToFields = {
  'residential-building-standards': {
    now: 'rbs.n',
    y2030: 'rbs.2030',
    y2040: 'rbs.2040',
  },
  'non-residential-building-standards': {
    now: 'nrbs.n',
    y2030: 'nrbs.2030',
    y2040: 'nrbs.2040',
  },
  'residential-retrofits': {
    y2030: 'rr.2030',
    y2040: 'rr.2040',
    y2050: 'rr.2050',
    strategy: 'rr.s',
  },
  'non-residential-retrofits': {
    y2030: 'nrr.2030',
    y2040: 'nrr.2040',
    y2050: 'nrr.2050',
    strategy: 'nrr.s',
  },
  'residential-vkt-change': {
    y2030: 'rvc.2030',
    y2040: 'rvc.2040',
    y2050: 'rvc.2050',
  },
  'residential-mode-shift': {
    y2030: 'rms.2030',
    y2040: 'rms.2040',
    y2050: 'rms.2050',
  },
  'public-transit-electrification': 'pte',
  'ev-takeup': {
    y2030: 'et.2030',
    y2040: 'et.2040',
    y2050: 'et.2050',
  },
  'waste-reduction': {
    y2030: 'wr.2030',
    y2040: 'wr.2040',
    y2050: 'wr.2050',
  },
  'recyclable-packaging': {
    y2030: 'rp.2030',
    y2040: 'rp.2040',
    y2050: 'rp.2050',
  },
  'recyclable-material-management': {
    y2030: 'rmm.2030',
    y2040: 'rmm.2040',
    y2050: 'rmm.2050',
  },
  'recyclable-ewaste': {
    y2030: 're.2030',
    y2040: 're.2040',
    y2050: 're.2050',
  },
  organics: {
    y2030: 'o.2030',
    y2040: 'o.2040',
    y2050: 'o.2050',
  },
  'waste-to-energy': {
    y2030: 'wte.2030',
    y2040: 'wte.2040',
    y2050: 'wte.2050',
  },
};

const iterInterventionAreas = curry((f, interventionAreas) =>
  pipe(
    toPairs,
    reduce((acc, [k0, v0]) => {
      if (isString(v0)) {
        acc.push(f([k0, v0]));
      }
      if (isObject(v0)) {
        toPairs(v0).forEach(([k1, v1]) => {
          acc.push(f([[k0, k1], v1]));
        });
      }
      return acc;
    }, []),
  )(interventionAreas),
);

const flattenInterventionAreas = iterInterventionAreas(identity);

const mapInterventionAreas = curry((f, interventionAreas) =>
  pipe(iterInterventionAreas(f), fromPairs)(interventionAreas),
);

const interventionAreaFieldsToKeys = mapInterventionAreas(
  ([k, v]) => [v, k],
  interventionAreaKeysToFields,
);

const validPercentage = (value) => value >= 0 && value <= 100;

const validProportionalChange = (value) => value >= -100;

const residentialBuildingStandardsOptions = [
  { value: 'no-change', label: 'No change' },
  {
    value: 'basix20-10',
    label: 'Basix +20/+10',
  },
  {
    value: 'basix40-20',
    label: 'Basix +40/+20',
  },
  {
    value: 'net-zero',
    label: 'Net-zero buildings',
  },
];

const residentialBuildingStandardsOptionsDataUpdate2023 = [
  { value: 'no-change', label: 'No change' },
  {
    value: 'basix20-10',
    label: 'Basix +20/+10',
  },
  {
    value: 'basix40-20',
    label: 'Basix +40/+20',
  },
  {
    value: 'net-zero',
    label: 'Net-zero buildings',
  },
];

const nonResidentialBuildingStandardsOptions = [
  { value: 'no-change', label: 'No change' },
  {
    value: 'nabers55-equivalent',
    label: 'Standard',
  },
  {
    value: 'nabers60-equivalent',
    label: 'High-efficiency',
  },
  {
    value: 'net-zero',
    label: 'Net-zero buildings',
  },
];

const residentialRetrofitsStrategyOptions = [
  { value: 'electrification', label: 'Electrification' },
  {
    value: 'high-efficiency-electrification',
    label: 'Electrification with high-efficiency appliances',
  },
  {
    value: 'electricity-efficiency',
    label: 'Upgrading of electrical appliances',
  },
  {
    value: 'gas-efficiency',
    label: 'Upgrading of gas appliances',
  },
  {
    value: 'gas-and-electricity-efficiency',
    label: 'Upgrading of both electrical and gas appliances',
  },
];

const nonResidentialRetrofitsStrategyOptions =
  residentialRetrofitsStrategyOptions;

const publicTransitElectrificationOptions = [
  { value: 'no-change', label: 'No change' },
  { value: 'slow', label: 'Slow' },
  { value: 'medium', label: 'Medium' },
  { value: 'fast', label: 'Fast' },
];

const lookupLabel = curry((options, value) =>
  get(
    'label',
    find((o) => isEqual(o.value, value), options),
  ),
);

const percentageUnit = units.percentage();

const interventionAreaValueUnits = {
  'residential-building-standards': {
    now: {
      align: 'left',
      format: lookupLabel(residentialBuildingStandardsOptions),
    },
    y2030: {
      align: 'left',
      format: lookupLabel(residentialBuildingStandardsOptions),
    },
    y2040: {
      align: 'left',
      format: lookupLabel(residentialBuildingStandardsOptions),
    },
  },
  'non-residential-building-standards': {
    now: {
      align: 'left',
      format: lookupLabel(nonResidentialBuildingStandardsOptions),
    },
    y2030: {
      align: 'left',
      format: lookupLabel(nonResidentialBuildingStandardsOptions),
    },
    y2040: {
      align: 'left',
      format: lookupLabel(nonResidentialBuildingStandardsOptions),
    },
  },
  'residential-retrofits': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
    strategy: {
      align: 'left',
      format: lookupLabel(residentialRetrofitsStrategyOptions),
    },
  },
  'non-residential-retrofits': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
    strategy: {
      align: 'left',
      format: lookupLabel(nonResidentialRetrofitsStrategyOptions),
    },
  },
  'residential-vkt-change': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
  },
  'residential-mode-shift': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
  },
  'public-transit-electrification': {
    align: 'left',
    format: lookupLabel(publicTransitElectrificationOptions),
  },
  'ev-takeup': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
  },
  'waste-reduction': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
  },
  'recyclable-packaging': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
  },
  'recyclable-material-management': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
  },
  'recyclable-ewaste': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
  },
  organics: {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
  },
  'waste-to-energy': {
    y2030: percentageUnit,
    y2040: percentageUnit,
    y2050: percentageUnit,
  },
};

const interventionAreaConstraints = {
  'residential-building-standards': {
    now: (v) => map('value', residentialBuildingStandardsOptions).includes(v),
    y2030: (v) => map('value', residentialBuildingStandardsOptions).includes(v),
    y2040: (v) => map('value', residentialBuildingStandardsOptions).includes(v),
  },
  'non-residential-building-standards': {
    now: (v) =>
      map('value', nonResidentialBuildingStandardsOptions).includes(v),
    y2030: (v) =>
      map('value', nonResidentialBuildingStandardsOptions).includes(v),
    y2040: (v) =>
      map('value', nonResidentialBuildingStandardsOptions).includes(v),
  },
  'residential-retrofits': {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
    strategy: (v) =>
      map('value', residentialRetrofitsStrategyOptions).includes(v),
  },
  'non-residential-retrofits': {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
    strategy: (v) =>
      map('value', nonResidentialRetrofitsStrategyOptions).includes(v),
  },
  'residential-vkt-change': {
    y2030: validProportionalChange,
    y2040: validProportionalChange,
    y2050: validProportionalChange,
  },
  'residential-mode-shift': {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
  },
  'public-transit-electrification': (v) =>
    map('value', publicTransitElectrificationOptions).includes(v),
  'ev-takeup': {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
  },
  'waste-reduction': {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
  },
  'recyclable-packaging': {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
  },
  'recyclable-material-management': {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
  },
  'recyclable-ewaste': {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
  },
  organics: {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
  },
  'waste-to-energy': {
    y2030: validPercentage,
    y2040: validPercentage,
    y2050: validPercentage,
  },
};

const supportedLocationTypes = ['lga', 'suburb'];

const validateLocationType = (locationType) =>
  supportedLocationTypes.includes(locationType);

const validateLocations = (ls, locationType) =>
  !some((location) => locationsToLocationTypes[location] !== locationType, ls);
const updatedValidateLocations = (ls, locationType) =>
  !some(
    (location) => updatedLocationsToLocationTypes[location] !== locationType,
    ls,
  );

const validateInterventionArea = (key, value) => {
  const validator = get(key, interventionAreaConstraints);
  return !isNil(validator) && validator(value);
};

const validateInterventionAreas = pipe(
  flattenInterventionAreas,
  every(([key, value]) => validateInterventionArea(key, value)),
);

const pathwayUrlBuilder = (self) => ({
  hydrateLocationType: () => {
    const fieldValue = self.query.get('lt');
    if (isNil(fieldValue) || !validateLocationType(fieldValue)) {
      return 'lga';
    }
    return fieldValue;
  },

  hydrateLocations: () => {
    const urlLocations = BitVec.fromHexString(self.query.get('l')).toIntArray();
    const urlLocationType = pathwayUrlBuilder(self).hydrateLocationType();
    const isLocationTypeMismatch = self.useUpdatedLocations
      ? !updatedValidateLocations(urlLocations, urlLocationType)
      : !validateLocations(urlLocations, urlLocationType);
    return isLocationTypeMismatch ? [] : urlLocations;
  },

  hydrateInterventionAreas: () =>
    pipe(
      toPairs,
      reduce(
        (acc, [field, interventionAreaKey]) =>
          update(
            interventionAreaKey,
            (fallback) => {
              const fieldString = self.query.get(field);
              const fieldNumber = Number(fieldString);
              const fieldValue = isNaN(fieldNumber) ? fieldString : fieldNumber;
              if (
                isNil(fieldString) ||
                !validateInterventionArea(interventionAreaKey, fieldValue)
              ) {
                return fallback;
              }
              return fieldValue;
            },
            acc,
          ),
        interventionAreaDefaults,
      ),
    )(interventionAreaFieldsToKeys),

  withInterventionAreas: (interventionAreas) => {
    mapInterventionAreas(([k, v]) => {
      const field = get(k, interventionAreaKeysToFields);
      self.query.set(field, v);
      return [k, v];
    }, interventionAreas);
    return pathwayUrlBuilder({ query: self.query });
  },

  withInterventionArea: (k, v) => {
    const field = get(k, interventionAreaKeysToFields);
    if (field) {
      self.query.set(field, v);
    }
    return pathwayUrlBuilder({ query: self.query });
  },

  withLocationType: (locationType) => {
    self.query.set('lt', locationType);
    self.query.set('l', '');
    return pathwayUrlBuilder({ query: self.query });
  },

  withLocations: (ls) => {
    const mask = BitVec.fromIntArray(ls).toHexString();
    if (isEmpty(mask)) {
      self.query.set('l', '');
    } else {
      self.query.set('l', mask);
    }
    return pathwayUrlBuilder({ query: self.query });
  },

  get: (key) => self.query.get(key),
  set: (key, value) => self.query.set(key, value),
  toString: () => {
    self.query.sort();
    return self.query.toString();
  },
});

const PathwayUrl = {
  new: (search, useUpdatedLocations = false) =>
    pathwayUrlBuilder({
      query: new URLSearchParams(search),
      useUpdatedLocations,
    }),
};

const validateUpload = (json) => {
  const uploadLocations = get('locations', json);
  const uploadLocationType = get('location-type', json);
  const uploadInterventionAreas = get('intervention-areas', json);

  const validFormat = get('format', json) === 'pathway';
  const validVersion = get('version', json) === 1;
  const validLocationType = validateLocationType(uploadLocationType);
  const validLocations = validateLocations(uploadLocations, uploadLocationType);
  const validInterventionAreas = validateInterventionAreas(
    uploadInterventionAreas,
  );

  return (
    validFormat &&
    validVersion &&
    validLocations &&
    validLocationType &&
    validInterventionAreas
  );
};

const locationTypeLabels = {
  lga: 'LGA',
  suburb: 'Suburb',
};

const locationTypeLabel = (type) => get(type, locationTypeLabels);

const locationLabel = (type, location) =>
  pipe(
    get(type),
    find((l) => l.value === location),
    get('label'),
  )(locations);
const updatedLocationLabel = (type, location) =>
  pipe(
    get(type),
    find((l) => l.value === location),
    get('label'),
  )(updatedLocations);

export {
  interventionAreaLabels,
  interventionAreaDescriptions,
  interventionAreaCategories,
  interventionCategoryLabels,
  interventionAreaDefaults,
  interventionAreaKeysToFields,
  interventionAreaFieldsToKeys,
  interventionAreaValueKeys,
  interventionAreaValueLabels,
  interventionAreaValueUnits,
  interventionAreaKeyOrder,
  PathwayUrl,
  validPercentage,
  validProportionalChange,
  residentialBuildingStandardsOptions,
  residentialBuildingStandardsOptionsDataUpdate2023,
  nonResidentialBuildingStandardsOptions,
  residentialRetrofitsStrategyOptions,
  nonResidentialRetrofitsStrategyOptions,
  publicTransitElectrificationOptions,
  locations,
  updatedLocations,
  validateUpload,
  validateLocationType,
  validateLocations,
  validateInterventionArea,
  validateInterventionAreas,
  locationLabel,
  updatedLocationLabel,
  locationTypeLabel,
  keyedLocations,
  updatedKeyedLocations,
  locationsToLocationTypes,
  updatedLocationsToLocationTypes,
  locationTypeLabels,
  interventionAreaKeyToPathwayInputBlockKey,
};
