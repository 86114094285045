import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { get, isEmpty, pipe } from 'lodash/fp';
import { actions as workspaceMiscActions } from 'reducers/workspaceMiscReducer';
import { StackedColumnConfiguration } from 'components/toolbox/configurations/stacked-column-configuration';
import { LineConfiguration } from 'components/toolbox/configurations/line-configuration';
import { StackedAreaConfiguration } from 'components/toolbox/configurations/stacked-area-configuration';
import { ClusteredColumnConfiguration } from 'components/toolbox/configurations/clustered-column-configuration';
import { ChoroplethConfiguration } from 'components/toolbox/configurations/choropleth-configuration';
import { IndicatorConfiguration } from 'components/toolbox/configurations/indicator-configuration';
import AppManagement from 'components/modals/app-management/AppManagement';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import workspaceSelector from 'selectors/workspaceSelector';
import boardAppSelector from 'selectors/boardAppSelector';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import usePermission from 'hooks/usePermission';

import {
  AppNeedsUpgradeMessage,
  EmptyMessage,
  NoLinkedAppMessage,
  StaticNonIndicatorMessage,
} from './messages';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  boardId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const ConfigurationSection = ({ blockId, boardId, onChange, workspaceId }) => {
  const dispatch = useDispatch();

  const editor = usePermission('editor');
  const workspace = useSelectorWithProps(workspaceSelector, { workspaceId });
  const showAppsModal = get(['misc', 'showAppManagementModal'], workspace);
  const boardApp = useSelectorWithProps(boardAppSelector, {
    boardId,
    workspaceId,
  });
  const source = useSelectorWithProps(pipe(blockStateSelector, get('source')), {
    blockId,
    workspaceId,
  });
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId,
    workspaceId,
  });
  const { visualisation, type } = blockValue;
  const staticVisualisationType = get([visualisation, 'type'], blockValue);

  const openAppsModal = useCallback(() => {
    dispatch(workspaceMiscActions.setShowAppManagementModal({ workspaceId }));
  }, [dispatch, workspaceId]);

  const closeAppsModal = useCallback(() => {
    dispatch(workspaceMiscActions.clearShowAppManagementModal({ workspaceId }));
  }, [dispatch, workspaceId]);

  const content = () => {
    // Editable visualisations, extend with more cases
    if (type === 'editable-visualisation') {
      switch (visualisation) {
        case 'empty':
          return <EmptyMessage />;
        case 'indicator':
          return (
            <IndicatorConfiguration
              blockId={blockId}
              onChange={onChange}
              workspaceId={workspaceId}
            />
          );
        case 'choropleth':
          return (
            <ChoroplethConfiguration
              blockId={blockId}
              onChange={onChange}
              workspaceId={workspaceId}
            />
          );
        case 'stacked-column':
          return (
            <StackedColumnConfiguration
              blockId={blockId}
              onChange={onChange}
              workspaceId={workspaceId}
            />
          );
        case 'line':
          return (
            <LineConfiguration
              blockId={blockId}
              onChange={onChange}
              workspaceId={workspaceId}
            />
          );
        case 'stacked-area':
          return (
            <StackedAreaConfiguration
              blockId={blockId}
              onChange={onChange}
              workspaceId={workspaceId}
            />
          );
        case 'clustered-column':
          return (
            <ClusteredColumnConfiguration
              blockId={blockId}
              onChange={onChange}
              workspaceId={workspaceId}
            />
          );
        default:
          return null;
      }
    }

    if (staticVisualisationType !== 'indicator') {
      return <StaticNonIndicatorMessage />;
    }

    if (isEmpty(source) || isEmpty(boardApp)) {
      return <NoLinkedAppMessage />;
    }

    return (
      <AppNeedsUpgradeMessage
        appName={get('label', boardApp)}
        handleCheckForUpdate={openAppsModal}
      />
    );
  };

  return (
    <ToolboxSection collapsible title='configuration'>
      {content()}
      {showAppsModal && (
        <AppManagement
          initialAppId={get('id', boardApp)}
          onClose={closeAppsModal}
          editor={editor}
          workspaceId={workspaceId}
        />
      )}
    </ToolboxSection>
  );
};

ConfigurationSection.propTypes = propTypes;

export { ConfigurationSection };
