import { cellToLatLng } from 'h3-js';
import { isEmpty, isNil, max, min, reduce, map, first } from 'lodash/fp';

function h3Reducer(accumulator, coordinate) {
  const [lat, lon] = coordinate;
  const [[left, bottom], [right, top]] = accumulator;
  return [
    [Math.min(left, lon), Math.min(bottom, lat)],
    [Math.max(right, lon), Math.max(top, lat)],
  ];
}

function getH3Bounds(data) {
  if (isEmpty(data)) return;
  const hexagons = map('hexagon', data);
  const coordinates = map(cellToLatLng, hexagons);
  const [lat, lon] = first(coordinates);
  return reduce(
    h3Reducer,
    [
      [lon, lat],
      [lon, lat],
    ],
    coordinates,
  );
}

const getInnerAndOuterBounds = reduce(
  ({ inner, outer }, [blockMin, blockMax]) => {
    // First case
    if (!outer) {
      return {
        inner: [blockMin, blockMax],
        outer: [blockMin, blockMax],
      };
    }

    const newOuter = [min([outer[0], blockMin]), max([outer[1], blockMax])];

    // no inner bounds case
    if (isEmpty(inner)) {
      return {
        inner,
        outer: newOuter,
      };
    }

    // general case
    const innerMin = max([inner[0], blockMin]);
    const innerMax = min([inner[1], blockMax]);
    return {
      inner: innerMin < innerMax ? [innerMin, innerMax] : [],
      outer: newOuter,
    };
  },
  {
    inner: undefined,
    outer: undefined,
  },
);

const getBounds = reduce((bounds, [blockMin, blockMax]) => {
  const [currentMin, currentMax] = bounds;
  return [
    isNil(currentMin) ? blockMin : min([currentMin, blockMin]),
    isNil(currentMax) ? blockMax : max([currentMax, blockMax]),
  ];
}, []);

export { getBounds, getInnerAndOuterBounds, getH3Bounds };
