import { cloneElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: undefined,
};

const AutoFocus = ({ autoSelect, children }) => {
  const ref = useRef();

  useEffect(() => {
    const node = ref.current;

    if (node instanceof HTMLElement) {
      const focusableElem = node.querySelector('input, textarea');

      // NOTE: This checks for readOnly status to avoid issues with keyboard
      // shortcuts, but an alternative approach that might guide users of
      // AutoFocus to a less surprising behaviour might be to augment the query
      // selection to skip all read-only inputs and select the first non-read
      // only element, if any.
      if (focusableElem && !focusableElem.readOnly) {
        focusableElem.focus();
        if (autoSelect) {
          focusableElem.select();
        }
      }
    } else if (process.env.NODE_ENV === 'development') {
      console.error(
        'AutoFocus component cannot access DOM node from reference',
      );
    }
  }, [autoSelect]);

  return cloneElement(children, { ref });
};

AutoFocus.propTypes = propTypes;
AutoFocus.defaultProps = defaultProps;

export default AutoFocus;
