import PropTypes from 'prop-types';
import { Avatar } from '@kinesis/bungle';
import { Toolbar } from '@kinesis/bungle/legacy';
import styled from 'styled-components';
import AppToolbarPortal from './AppToolbarPortal';
import BreadcrumbPortal from './BreadcrumbPortal';
import { AppHeaderWrapper } from './app-header.styles';

// FIX This could be better, but the margins, seem pretty weird so trying
//     to disturb as little as possible for the moment.
const OrganisationAvatarWrapper = styled('div')({
  marginLeft: 8,
  marginRight: -6,
});

const propTypes = {
  avatarAlt: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
};

const defaultProps = {
  avatarUrl: undefined,
};

const PublicAppHeader = ({ avatarAlt, avatarUrl }) => (
  <AppHeaderWrapper>
    <Toolbar justify='end'>
      <OrganisationAvatarWrapper>
        <Avatar
          alt={avatarAlt}
          image={avatarUrl}
          magnitude='medium'
          variant='organisation'
        />
      </OrganisationAvatarWrapper>
      <BreadcrumbPortal />
      <AppToolbarPortal />
    </Toolbar>
  </AppHeaderWrapper>
);

PublicAppHeader.propTypes = propTypes;
PublicAppHeader.defaultProps = defaultProps;

export default PublicAppHeader;
