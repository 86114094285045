import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const resourceAvailableSelector = createSelector(
  workspaceSelector,
  (state, props) => props.scenarioKey,
  (state, props) => props.resource,
  (workspace, scenarioKey, resource) =>
    get(['availability', 'scenarios', scenarioKey, resource], workspace),
);

export default resourceAvailableSelector;
