import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { every, get, map, pipe } from 'lodash/fp';
import {
  AnimatedPopover,
  Icon,
  Listbox,
  Tooltip,
  UtilityButton,
} from '@kinesis/bungle';
import { labelOf } from 'data/attributes';
import {
  ModalHeader,
  ModalHeaderAction,
  ModalHeading,
} from 'components/modals/modal-header';
import workspaceInstantiationSelector from 'selectors/workspaceInstantiationSelector';
import scenarioSelector from 'selectors/scenarioSelector';
import sortedScenariosSelector from 'selectors/sortedScenariosSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { BaselineIconWrapper } from './attribute-settings.styles';

const propTypes = {
  attributeKey: PropTypes.string.isRequired,
  scenarioId: PropTypes.string.isRequired,
  scenarioIds: PropTypes.array.isRequired,
  setScenarioIds: PropTypes.func.isRequired,
  setToBaseline: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const AttributeSettingsHeader = ({
  attributeKey,
  scenarioId,
  scenarioIds,
  setScenarioIds,
  setToBaseline,
  workspaceId,
}) => {
  const scenarioListRef = useRef();
  const [scenarioListOpen, setScenarioListOpen] = useState(false);
  const closeScenarioList = useCallback(() => {
    setScenarioListOpen(false);
  }, [setScenarioListOpen]);

  const toggleScenarioListOpen = useCallback(
    () => setScenarioListOpen((current) => !current),
    [setScenarioListOpen],
  );

  const instance = useSelectorWithProps(workspaceInstantiationSelector, {
    key: attributeKey,
    workspaceId,
  });

  const scenarioOptions = useSelectorWithProps(
    pipe(
      sortedScenariosSelector,
      map(({ id, name }) => ({
        label: name,
        value: id,
        disabled: id === scenarioId,
      })),
    ),
    { workspaceId },
  );

  const isCurrentDefault = get(
    'isDefault',
    useSelectorWithProps(scenarioSelector, { scenarioId, workspaceId }),
  );

  const isSelectedDefault = useSelector(
    useCallback(
      (state) =>
        every(
          (id) =>
            get(
              'isDefault',
              scenarioSelector(state, { scenarioId: id, workspaceId }),
            ),
          scenarioIds,
        ),
      [scenarioIds, workspaceId],
    ),
  );

  const label = useSelector(
    useCallback(
      (state) => {
        if (scenarioIds.length === 1) {
          return get(
            'name',
            scenarioSelector(state, {
              scenarioId: scenarioIds[0],
              workspaceId,
            }),
          );
        }

        return scenarioOptions.length === scenarioIds.length
          ? 'All scenarios'
          : `${scenarioIds.length} scenarios`;
      },
      [scenarioIds, scenarioOptions.length, workspaceId],
    ),
  );

  return (
    <ModalHeader>
      <ModalHeading>{`${labelOf(instance)} settings`}</ModalHeading>
      <ModalHeaderAction>
        <UtilityButton
          ref={scenarioListRef}
          dropdown
          expanded={scenarioListOpen}
          onClick={toggleScenarioListOpen}
        >
          {label}
          {isSelectedDefault && (
            <BaselineIconWrapper>
              <Icon type='baseline' />
            </BaselineIconWrapper>
          )}
        </UtilityButton>
        <AnimatedPopover
          justify='end'
          offset={4}
          open={scenarioListOpen}
          placement='bottom'
          targetRef={scenarioListRef}
        >
          <Listbox
            autoFocus
            buttonRef={scenarioListRef}
            closeOnBlur
            closeOnClickOutside
            closeOnSelect
            onChange={setScenarioIds}
            onClose={closeScenarioList}
            options={scenarioOptions}
            values={scenarioIds}
            width={240}
            multi
          />
        </AnimatedPopover>
      </ModalHeaderAction>
      <ModalHeaderAction>
        <Tooltip title='Import items' placement='top'>
          <UtilityButton disabled icon='import' />
        </Tooltip>
        <Tooltip title='Set to baseline' placement='top'>
          <UtilityButton
            disabled={isCurrentDefault}
            icon='set-to-baseline'
            onClick={setToBaseline}
          />
        </Tooltip>
      </ModalHeaderAction>
    </ModalHeader>
  );
};

AttributeSettingsHeader.defaultProps = defaultProps;
AttributeSettingsHeader.propTypes = propTypes;

export default AttributeSettingsHeader;
