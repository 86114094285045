import PropTypes from 'prop-types';
import { Stack, UtilityButton, Select, LabelText } from '@kinesis/bungle';
import { groupKeyOf } from 'data/block/visualisation/configuration/series-select';
import { InputContainer } from './visualisation-series-select.styles';

const propTypes = {
  defaultExpanded: PropTypes.number,
  defaultQuery: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  tone: PropTypes.string.isRequired,
};

const defaultProps = {
  defaultExpanded: undefined,
  defaultQuery: undefined,
};

const Empty = ({
  defaultExpanded,
  defaultQuery,
  label,
  onChange,
  tone,
  options,
}) => (
  <Stack space='xsmall'>
    <LabelText>{label}</LabelText>
    <InputContainer>
      <Select
        defaultExpanded={defaultExpanded === 0}
        defaultQuery={defaultQuery}
        groupKey={groupKeyOf(options)}
        itemKey='key'
        value={undefined}
        onChange={onChange}
        options={options}
        search
        itemsLabel='measures'
        overrideEmptyText='Select an output to view available measures.'
        tone={tone}
        magnitude='large'
      />
    </InputContainer>
    <div>
      <UtilityButton magnitude='xsmall' disabled icon='plus'>
        Add series
      </UtilityButton>
    </div>
  </Stack>
);

Empty.propTypes = propTypes;
Empty.defaultProps = defaultProps;

export { Empty };
