import { isFunction, isNil, matches, toLower } from 'lodash/fp';
import Bowser from 'bowser';
const { getParser } = Bowser;

const parser = getParser(window.navigator.userAgent);
const osName = parser.getOSName();

function keyboardShortcutString(params) {
  if (!params) {
    throw new Error('keyboardShortcutString: missing argument');
  }

  if (isFunction(params)) {
    return keyboardShortcutString(params({ osName }));
  }

  const { key, primaryModifier, secondaryModifier, shift } = params;
  const keys = [];
  const isMacOS = osName === 'macOS';

  if (isNil(key) || key === '') {
    throw new Error('keyboardShortcutString: key is a required parameter');
  }

  if (primaryModifier) {
    keys.push(isMacOS ? '⌘' : 'Ctrl');
  }

  if (secondaryModifier) {
    keys.push(isMacOS ? '⌥' : 'Alt');
  }

  if (shift) {
    keys.push(isMacOS ? '⇧' : 'Shift');
  }

  keys.push(key);

  return keys.join('+');
}

function makeKeyboardShortcut(params = {}) {
  const expectedModifiers = {
    altKey: Boolean(params.secondaryModifier),
    ctrlKey: osName !== 'macOS' && Boolean(params.primaryModifier),
    metaKey: osName === 'macOS' && Boolean(params.primaryModifier),
    shiftKey: Boolean(params.shift),
  };

  const predicate = (event) => {
    if (!matches(expectedModifiers, event)) {
      return false;
    }

    if (/^[0-9]$/.test(params.key)) {
      return event.key === params.key || event.code === `Digit${params.key}`;
    }

    return toLower(event.key) === toLower(params.key);
  };

  predicate.toString = () => keyboardShortcutString(params);

  return predicate;
}

export { keyboardShortcutString, makeKeyboardShortcut };
