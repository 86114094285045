import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { concat, first, forEach, get, isEmpty, isNil, map } from 'lodash/fp';
import { Tooltip, UtilityButton } from '@kinesis/bungle';
import { ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { nanoid } from 'nanoid';
import { actions as datasetVersionActions } from 'reducers/datasetVersionsReducer';
import { datasetFetch } from 'actions/datasetFetch';
import { datasetUpload } from 'actions/datasetUpload';
import { datasetVersionCreate } from 'actions/datasetVersionCreate';
import { datasetVersionFetch } from 'actions/datasetVersionFetch';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { actions as miscActions } from 'reducers/miscReducer';
import datasetVersionsSelector from 'selectors/datasetVersionsSelector';
import { useDataset, useDatasetParams, useSelectorWithProps } from 'hooks';
import Layout from 'components/layout';
import { LoadableContent } from 'components/loadable-content';
import { Toolbox } from 'components/toolbox';
import { AppToolbar, BackButton, Breadcrumb } from 'components/app-header';
import useToolboxOpenState from 'hooks/useToolboxOpenState';
import useActions from 'hooks/useActions';
import { DatasetToolbar } from 'components/dataset-toolbar';
import { DatasetViewSelect } from 'components/dataset-view-select';
import { DatasetTableData } from './dataset.table.data';
import { DatasetTableProfile } from './dataset.table.profile';
import { DatasetLocationsMap } from './dataset.locations.map';
import { DatasetLocationsGrid } from './dataset.locations.grid';
import { DatasetLocationsProfile } from './dataset.locations.profile';
import { DatasetAttributesMap } from './dataset.attributes.map';
import { DatasetAttributesGrid } from './dataset.attributes.grid';
import { DatasetAttributesProfile } from './dataset.attributes.profile';

const views = {
  table: {
    data: DatasetTableData,
    profile: DatasetTableProfile,
  },
  locations: {
    map: DatasetLocationsMap,
    grid: DatasetLocationsGrid,
    profile: DatasetLocationsProfile,
  },
  attributes: {
    map: DatasetAttributesMap,
    grid: DatasetAttributesGrid,
    profile: DatasetAttributesProfile,
  },
};

const Dataset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isToolboxOpen, setToolboxOpenState] = useToolboxOpenState();
  const { select, removePane } = useActions(toolboxActions);
  const { clearNewDatasetVersion } = useActions(miscActions);
  const panes = useSelector(get(['toolbox', 'panes']));
  const dataset = useDataset();
  const { id: datasetId, mode } = useDatasetParams() ?? {};
  const newDatasetVersion = useSelector(get(['misc', 'newDatasetVersion']));
  const versions = useSelectorWithProps(datasetVersionsSelector, { datasetId });
  const type = get('type', dataset);
  const isFetchingDataset = get('lifecycle', dataset) === 'fetching';
  const hasFetchedDataset = get('lifecycle', dataset) === 'fetched';
  const loading = !hasFetchedDataset;
  const [versionId, setVersionId] = useState();
  const version = useSelector(get(['datasetVersions', versionId]));
  const isFetchingVersion = get('lifecycle', version) === 'fetching';
  const hasFetchedVersion = get('lifecycle', version) === 'fetched';
  const isDraft = hasFetchedVersion && isNil(get('publishedAs', version));
  const [, setUploadKeys] = useState([]);

  useEffect(() => {
    if (isNil(versionId) && !isEmpty(versions)) {
      setVersionId(get('id', first(versions)));
    }
  }, [versionId, setVersionId, versions]);

  useEffect(() => {
    if (newDatasetVersion) {
      setVersionId(newDatasetVersion);
      clearNewDatasetVersion();
    }
  }, [newDatasetVersion, clearNewDatasetVersion]);

  useEffect(() => {
    select({
      pane: 'dataset',
      selection: { id: datasetId, type: 'dataset' },
    });
  }, [datasetId, panes, select]);

  useEffect(() => {
    if (isDraft) {
      select({
        pane: 'dataset-draft',
        label: 'Draft',
        selection: { id: versionId, type: 'dataset-draft' },
      });
    } else {
      removePane('dataset-draft');
    }
  }, [isDraft, versionId, select, removePane]);

  useEffect(() => {
    if (datasetId && !hasFetchedDataset && !isFetchingDataset) {
      dispatch(datasetFetch({ datasetId }));
    }
  }, [dispatch, hasFetchedDataset, isFetchingDataset, datasetId]);

  useEffect(() => {
    if (versionId && !hasFetchedVersion && !isFetchingVersion) {
      dispatch(datasetVersionFetch({ versionId }));
    }
  }, [dispatch, hasFetchedVersion, isFetchingVersion, versionId]);

  useEffect(() => {
    if (!mode && !loading) {
      const defaultMode =
        {
          locations: 'map',
          attributes: 'map',
          table: 'profile',
        }[get('type', dataset)] ?? 'profile';
      navigate(`/datasets/${datasetId}/${defaultMode}`);
    }
  }, [mode, loading, dataset, datasetId, navigate]);

  const handleToolboxStateChange = useCallback(() => {
    setToolboxOpenState((isOpen) => !isOpen);
  }, [setToolboxOpenState]);

  const onUpload = useCallback(
    (files) => {
      const keyed = map((file) => ({ file, key: nanoid() }), files);
      const keys = map('key', keyed);
      setUploadKeys(concat(keys));
      if (versionId && isDraft) {
        dispatch(datasetVersionActions.trackUploads({ versionId, keys }));
      } else {
        dispatch(
          datasetVersionCreate({ datasetId, base: versionId, uploads: keys }),
        );
      }
      // NOTE: This may strike people as odd, but it is important
      // we don't batch uploads, they may be of vastly different
      // sizes, and some may get blocked if there are enough so
      // we need to dispatch discrete async actions for each upload
      // rather than one big indivisible one.
      forEach(
        ({ file, key }) =>
          dispatch(
            datasetUpload({
              datasetId,
              file,
              format: 'csv',
              key,
            }),
          ),
        keyed,
      );
    },
    [dispatch, datasetId, versionId, isDraft, setUploadKeys],
  );

  const View = get([type, mode], views);

  return (
    <>
      <BackButton to='/datasets' />
      <Breadcrumb to='/datasets' root>
        Datasets
      </Breadcrumb>
      {!loading && (
        <Breadcrumb to={`/datasets/${datasetId}`}>
          {get('name', dataset)}
        </Breadcrumb>
      )}
      <AppToolbar>
        <ToolbarGroup>
          <ToolbarItem>
            <DatasetViewSelect
              datasetId={datasetId}
              mode={mode}
              type={get('type', dataset)}
            />
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip
              justify='end'
              placement='bottom'
              title={isToolboxOpen ? 'Hide toolbox' : 'Show toolbox'}
            >
              <UtilityButton
                expanded={isToolboxOpen}
                icon='control'
                variant={isToolboxOpen ? 'accent' : 'default'}
                onClick={handleToolboxStateChange}
              />
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </AppToolbar>
      <Layout>
        <Layout direction='column'>
          <DatasetToolbar
            loading={loading}
            onVersionSelect={setVersionId}
            onUpload={onUpload}
            version={versionId}
            versions={versions}
          />
          <LoadableContent loading={loading}>
            {View && <View datasetId={datasetId} versionId={versionId} />}
          </LoadableContent>
        </Layout>
        {isToolboxOpen && <Toolbox />}
      </Layout>
    </>
  );
};

export { Dataset };
