import PropTypes from 'prop-types';
import { Button, Modal as BungleModal, ModalFooter } from '@kinesis/bungle';
import { Inline as OldInline } from '@kinesis/bungle/legacy';
import Layout from 'components/layout';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]).isRequired,
  closeOnOverlayClick: PropTypes.bool,
  header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]).isRequired,
  magnitude: PropTypes.oneOf(['xlarge', 'large', 'normal', 'small']),
  minHeight: PropTypes.number,
  onSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  saveable: PropTypes.bool,
  saveLabel: PropTypes.string,
  dismissLabel: PropTypes.string,
  valid: PropTypes.bool,
  loading: PropTypes.bool,
  'aria-label': PropTypes.string.isRequired,
};

const defaultProps = {
  closeOnOverlayClick: false,
  magnitude: 'normal',
  onSave: undefined,
  saveable: true,
  saveLabel: 'Save changes',
  dismissLabel: 'Done',
  valid: true,
  minHeight: undefined,
  loading: undefined,
};

const Modal = ({
  children,
  closeOnOverlayClick,
  header,
  magnitude,
  onClose,
  onSave,
  saveable,
  saveLabel,
  dismissLabel,
  valid,
  minHeight,
  loading,
  'aria-label': ariaLabel,
  ...restProps
}) => {
  const dimensions = {
    xlarge: { width: 1280, height: 800 },
    large: { width: 960, height: 720 },
    normal: { width: 840, height: 600 },
    small: { width: 496, height: 360 },
    compact: { width: 496 },
  }[magnitude];

  return (
    <BungleModal
      closeOnOverlayClick={closeOnOverlayClick}
      onClose={onClose}
      height={dimensions.height}
      minHeight={minHeight}
      width={dimensions.width}
      aria-label={ariaLabel}
      {...restProps}
    >
      {header}
      <Layout direction='column'>{children}</Layout>
      <ModalFooter>
        <div />
        <OldInline space='small' alignX='right'>
          {saveable && (
            <Button variant='secondary' onClick={onClose}>
              Cancel
            </Button>
          )}
          {saveable && (
            <Button
              variant='primary'
              loading={loading}
              disabled={!valid}
              onClick={onSave}
            >
              {saveLabel}
            </Button>
          )}
          {!saveable && (
            <Button variant='primary' disabled={!valid} onClick={onClose}>
              {dismissLabel}
            </Button>
          )}
        </OldInline>
      </ModalFooter>
    </BungleModal>
  );
};

Modal.defaultProps = defaultProps;
Modal.propTypes = propTypes;

export default Modal;
