import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { get, map, isEmpty, isNil } from 'lodash/fp';
import { workspaceCreate } from 'actions/workspaceCreate';
import { workspaceDelete } from 'actions/workspaceDelete';
import { workspaceDuplicate } from 'actions/workspaceDuplicate';
import sortedWorkspacesSelector from 'selectors/sortedWorkspacesSelector';
import { PageLayout } from 'components/page-layout';
import WorkspaceCreate from 'components/workspace-create/WorkspaceCreate';
import { ScrollableContent } from 'components/scrollable-content';
import { PlaceholderMessage } from 'components/placeholder-message';
import {
  Button,
  Cards,
  ConfirmationDialog,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  Anchor,
  UtilityButton,
  useToast,
  Text,
  Strong,
} from '@kinesis/bungle';
import {
  ToolbarGroup,
  ToolbarItem,
  ToolbarSeparator,
} from '@kinesis/bungle/legacy';
import { AppToolbar, BackButton, Breadcrumb } from 'components/app-header';
import WorkspaceCard from 'components/workspace-card/WorkspaceCard';
import { WorkspaceCardsWrapper } from 'components/workspace-card/workspace-card.styles';
import { actions as miscActions } from 'reducers/miscReducer';
import { LoadableContent } from 'components/loadable-content';
import usePreference from 'hooks/usePreference';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { makeKeyboardShortcut } from 'utils/keyboardUtils';

const { removeWorkspaceError, clearExpiredShare } = miscActions;

const propTypes = {};

const defaultProps = {};

const Workspaces = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast('globalTop');
  const [sortField, setSortField] = usePreference(
    'marathon:workspaces:sort-field',
    'organisationName',
  );
  const sortedWorkspaces = useSelectorWithProps(sortedWorkspacesSelector, {
    sortField,
  });
  const {
    hasFetchedWorkspaces,
    isCreatingWorkspace,
    workspaceErrors,
    expiredShare,
  } = useSelector(get('misc'));
  const isLoading = !hasFetchedWorkspaces;
  const workspacePreviews = useSelector(get('workspacePreview'));
  const [workspaceAction, setWorkspaceAction] = useState();
  const [selectedId, setSelectedId] = useState();
  const selectedWorkspace = selectedId
    ? sortedWorkspaces.find((w) => w.id === selectedId)
    : undefined;

  const handleCreate = useCallback(async () => {
    const { id } = await dispatch(workspaceCreate()).unwrap();

    if (id) {
      navigate(`/workspaces/${id}`, { state: { autoSelect: true } });
    }
  }, [dispatch, navigate]);

  const handleDelete = useCallback((id) => {
    setSelectedId(id);
    setWorkspaceAction('delete');
  }, []);

  const handleDuplicate = useCallback(
    async (workspaceId) => {
      setSelectedId(workspaceId);
      const { id } = await dispatch(
        workspaceDuplicate({ workspaceId }),
      ).unwrap();

      if (id) {
        navigate(`/workspaces/${id}`, { state: { autoSelect: true } });
      }
    },
    [dispatch, navigate],
  );

  const handleActionCancel = useCallback(() => {
    setWorkspaceAction(undefined);
    setSelectedId(undefined);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    dispatch(workspaceDelete({ workspaceId: selectedId }));
  }, [dispatch, selectedId]);

  useEffect(() => {
    setWorkspaceAction(undefined);
    setSelectedId(undefined);
  }, [sortedWorkspaces.length]);

  useEffect(() => {
    map(({ id, message }) => {
      toast(message, { variant: 'error' });
      dispatch(removeWorkspaceError({ id }));
    }, workspaceErrors);
  }, [dispatch, toast, workspaceErrors]);

  useEffect(() => {
    if (!isNil(expiredShare)) {
      const message = (
        <Text displayMode='dark'>
          Your link to <Strong>{get('workspaceName', expiredShare)}</Strong> is
          no longer valid. Ask the the person who shared it with you to resend
          it.
        </Text>
      );

      toast(message, { variant: 'error' });
      dispatch(clearExpiredShare());
    }
  }, [dispatch, expiredShare, toast]);

  const newWorkspaceShortcut = makeKeyboardShortcut({ key: 'n' });

  if (isCreatingWorkspace) {
    return <WorkspaceCreate />;
  }
  return (
    <>
      <BackButton />
      <Breadcrumb to='/workspaces' root>
        Workspaces
      </Breadcrumb>
      <AppToolbar>
        <ToolbarGroup>
          <ToolbarItem>
            <DropdownMenu justify='end'>
              <DropdownMenuButton
                as={UtilityButton}
                disabled={isLoading}
                icon='sort'
              />
              <DropdownMenuList>
                <DropdownMenuItem
                  onSelect={() => setSortField('organisationName')}
                  variant={
                    sortField === 'organisationName' ? 'accent' : undefined
                  }
                >
                  Organisation
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => setSortField('name')}
                  variant={sortField === 'name' ? 'accent' : undefined}
                >
                  Name
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => setSortField('touchedAt')}
                  variant={sortField === 'touchedAt' ? 'accent' : undefined}
                >
                  Last updated
                </DropdownMenuItem>
              </DropdownMenuList>
            </DropdownMenu>
          </ToolbarItem>
          <ToolbarSeparator />
          <ToolbarItem>
            <Button
              disabled={isLoading}
              keyboardShortcut={newWorkspaceShortcut}
              onClick={handleCreate}
              variant='primary'
            >
              New workspace
            </Button>
          </ToolbarItem>
        </ToolbarGroup>
      </AppToolbar>
      <PageLayout rounded>
        <LoadableContent loading={isLoading}>
          <PlaceholderMessage
            text='Create your first workspace to get started.'
            isEmpty={isEmpty(sortedWorkspaces)}
          >
            <ScrollableContent>
              <WorkspaceCardsWrapper>
                <Cards
                  footer={
                    <>
                      Map data ©{' '}
                      <Anchor
                        appearance='plain'
                        href='https://www.mapbox.com/about/maps/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Mapbox
                      </Anchor>
                      , ©{' '}
                      <Anchor
                        appearance='plain'
                        href='http://www.openstreetmap.org/copyright'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        OpenStreetMap
                      </Anchor>
                    </>
                  }
                  view='grid'
                >
                  {sortedWorkspaces.map((workspace) => (
                    <WorkspaceCard
                      key={workspace.id}
                      editor={workspace.editor}
                      id={workspace.id}
                      name={workspace.name}
                      onDelete={handleDelete}
                      onDuplicate={handleDuplicate}
                      organisationImageUrl={workspace.organisationImageUrl}
                      organisationName={workspace.organisationName}
                      previewImageUrl={get(
                        [workspace.id, 'previewImageUrl'],
                        workspacePreviews,
                      )}
                      to={`/workspaces/${workspace.id}`}
                      touchedAt={workspace.touchedAt}
                    />
                  ))}
                </Cards>
              </WorkspaceCardsWrapper>
            </ScrollableContent>
          </PlaceholderMessage>
        </LoadableContent>
      </PageLayout>
      {workspaceAction === 'delete' && selectedWorkspace && (
        <ConfirmationDialog
          cancelText='Don’t delete'
          confirmText='Delete workspace'
          onCancel={handleActionCancel}
          onConfirm={handleDeleteConfirm}
          title={`Delete “${selectedWorkspace.name}”?`}
          variant='danger'
          minWidth={320}
        >
          This workspace will be permanently deleted.
        </ConfirmationDialog>
      )}
    </>
  );
};

Workspaces.propTypes = propTypes;
Workspaces.defaultProps = defaultProps;

export default Workspaces;
