import { get, pipe, values, filter, negate, isEmpty } from 'lodash/fp';

const isMeasure = (column) => column.classification.includes('measure');
const isDimension = (column) => column.classification.includes('dimension');
// NOTE: We may support more types in future, currently we restrict to year.
const isTimeDimension = (column) =>
  ['year'].includes(get(['unit', 'type'], column));

const isGeographyDimension = (column) =>
  ['geography'].includes(get(['unit', 'type'], column));

const getMeasures = pipe(get('schema'), values, filter(isMeasure));
const getDimensions = pipe(get('schema'), values, filter(isDimension));
const getTimeDimensions = pipe(getDimensions, filter(isTimeDimension));

const hasClassification = (table, expected) =>
  pipe(
    get('schema'),
    values,
    filter(({ classification }) => classification.includes(expected)),
    negate(isEmpty),
  )(table);

const hasContext = (table) => hasClassification(table, 'context');

const hasMeasure = (table) => hasClassification(table, 'measure');

const hasTime = pipe(getDimensions, filter(isTimeDimension), negate(isEmpty));

const hasDimension = (table) => hasClassification(table, 'dimension');

const hasGeography = pipe(
  getDimensions,
  filter(isGeographyDimension),
  negate(isEmpty),
);

export {
  hasContext,
  hasMeasure,
  hasTime,
  hasGeography,
  hasDimension,
  isMeasure,
  isDimension,
  isTimeDimension,
  isGeographyDimension,
  getMeasures,
  getDimensions,
  getTimeDimensions,
};
