import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { Select } from '@kinesis/bungle';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import blockDataSelector from 'selectors/blockDataSelector';
import visualisationTimeOptionsSelector from 'selectors/visualisationTimeOptionsSelector';
import isCapsuleMismatchSelector from 'selectors/isCapsuleMismatchSelector';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.exact({
      type: PropTypes.oneOf(['column']).isRequired,
      column: PropTypes.string.isRequired,
    }),
    PropTypes.exact({
      type: PropTypes.oneOf(['none']).isRequired,
    }),
  ]),
  onChange: PropTypes.func.isRequired,
  table: PropTypes.string,
  tableRules: PropTypes.arrayOf(PropTypes.func),
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  defaultExpanded: false,
  value: { type: 'none' },
  table: undefined,
  tableRules: [],
};

const isError = (data) =>
  get('type', data) === 'error' &&
  get(['data', 'code'], data) === 'query-unsupported-time-dimension';

const VisualisationTimeSelect = ({
  blockId,
  defaultExpanded,
  value,
  onChange,
  table,
  tableRules,
  workspaceId,
}) => {
  const scenarioId = useScenarioId();
  const options = useSelectorWithProps(visualisationTimeOptionsSelector, {
    tableRules,
    table,
    blockId,
    workspaceId,
  });
  const blockData = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const capsuleMismatch = useSelectorWithProps(isCapsuleMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });

  return (
    <Select
      defaultExpanded={defaultExpanded}
      label='Timeline'
      itemKey='key'
      value={value}
      onChange={onChange}
      options={options}
      groupKey='category'
      groupVariant='divider'
      itemsLabel='columns'
      tone={isError(blockData) && !capsuleMismatch ? 'critical' : 'neutral'}
    />
  );
};

VisualisationTimeSelect.propTypes = propTypes;
VisualisationTimeSelect.defaultProps = defaultProps;

export { VisualisationTimeSelect };
