import { createCachedSelector } from 're-reselect';
import { map, get, entries, sortBy, pipe } from 'lodash/fp';
import { binWithBounds } from 'data/bin';
import { normalizeLevels } from 'data/levels';
import blockScenarioDataSelector from './blockScenarioDataSelector';
import blockStateSelector from './blockStateSelector';
import blockValueSelector from './blockValueSelector';

const privateColorDomainSelector = createCachedSelector(
  pipe(blockStateSelector, get('schema')),
  blockValueSelector,
  blockScenarioDataSelector,
  (schema, blockValue, data) => {
    const color = get([get('visualisation', blockValue), 'colour'], blockValue);

    if (!color) {
      return [];
    }

    // FUTURE: generalise this, it should be based purely on unit + data.
    // This currently assumes levels is set and complete.
    if (get([color.column, 'encoding'], schema) === 'string') {
      const levels = pipe(
        get([color.column, 'unit', 'levels']),
        normalizeLevels,
        entries,
        sortBy(([k, v]) => v), // eslint-disable-line
        map(([k, v]) => k), // eslint-disable-line
      )(schema);
      return levels || [];
    }
    return binWithBounds(map(color.column, data));
  },
)((state, props) => props.blockId);

export default privateColorDomainSelector;
