import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  filter,
  first,
  map,
  every,
  get,
  set,
  defaultTo,
  isNil,
  concat,
  update,
  unset,
  isEqual,
  reduce,
  keys,
} from 'lodash/fp';
import { nanoid } from 'nanoid';
import {
  Inline,
  InlineItem,
  Menu,
  Secondary,
  Select,
  Text,
  Tooltip,
  UtilityButton,
} from '@kinesis/bungle';
import {
  Content,
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
} from '@kinesis/bungle/legacy';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import { workspacePatch } from 'actions/workspacePatch';
import { populationDatasetUpload } from 'actions/populationDatasetUpload';
import { populationDatasetUpdate } from 'actions/populationDatasetUpdate';
import { populationDatasetDownload } from 'actions/populationDatasetDownload';
import { populationDatasetFetch } from 'actions/populationDatasetFetch';
import { actions as populationActions } from 'reducers/populationReducer';
import populationDatasetSelector from 'selectors/populationDatasetSelector';
import populationDatasetDraftSelector from 'selectors/populationDatasetDraftSelector';
import populationDatasetErrorSelector from 'selectors/populationDatasetErrorSelector';
import populationDatasetVersionSelector from 'selectors/populationDatasetVersionSelector';
import uploadSelector from 'selectors/uploadSelector';
import Modal from 'components/modals/modal/Modal';
import Layout from 'components/layout';
import { ScrollablePane } from 'components/styled/ScrollablePane';
import { LegacyContent } from 'components/legacy-content';
import { LoadingIcon } from 'components/loading-icon';
import { ToolbarBackground } from 'components/toolbar-background';
import { PopulationUploadControl } from 'components/population-upload-control';
import { ModalHeader, ModalHeading, ModalHeaderAction } from '../modal-header';
import { FileIcon } from './file-icon';
import { OwnerIcon } from './owner-icon';
import { Modifications, modificationsSchema } from './modifications';
import { SidePane } from './population-input-data.styles';
import { PopulationInputDataErrorModal } from './population-input-data.error';

const propTypes = {
  animation: PropTypes.bool,
  initialPane: PropTypes.oneOf([
    'table:dpie:tfr',
    'table:dpie:life-expectancy-at-birth',
    'table:dpie:net-overseas-migration',
    'table:dpie:net-interstate-migration',
    'table:dpie:net-intrastate-migration',
    'table:dpie:hum-dwelling-projection',
  ]),
  onClose: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  animation: true,
  initialPane: 'table:dpie:tfr',
};

const renderBaselineItem = (item) =>
  item.value === 'app-provided' ? (
    <Inline space='small'>
      <InlineItem>
        <OwnerIcon />
      </InlineItem>
      <InlineItem sizing='fill-container'>
        <Text>{item.label}</Text>
      </InlineItem>
    </Inline>
  ) : (
    <Inline space='small'>
      <InlineItem>
        <FileIcon magnitude='xsmall' />
      </InlineItem>
      <InlineItem sizing='fill-container'>
        <Text>{item.label || 'custom.dpie.population.zip'}</Text>
      </InlineItem>
    </Inline>
  );

const tables = [
  {
    resource: 'table:dpie:tfr',
    label: 'Total feritility rate',
    columns: [
      { key: 'region', title: 'Region', span: true, flex: '0 1 200px' },
      { key: 'year', title: 'Year', flex: '0 1 60px' },
      {
        key: 'fertility',
        title: 'Total fertility rate',
        flex: '0 0 220px',
        numeric: true,
      },
    ],
  },
  {
    resource: 'table:dpie:life-expectancy-at-birth',
    label: 'Life expectancy at birth',
    columns: [
      { key: 'region', title: 'Region', span: true, flex: '0 1 200px' },
      { key: 'sex', title: 'Sex', flex: '0 1 100px', span: true },
      { key: 'year', title: 'Year', flex: '0 1 60px' },
      {
        key: 'life',
        title: 'Life expectancy at birth',
        flex: '0 1 220px',
        numeric: true,
      },
    ],
  },
  {
    resource: 'table:dpie:net-overseas-migration',
    label: 'Net overseas migration',
    columns: [
      { key: 'region', title: 'Region', span: true, flex: '0 1 200px' },
      { key: 'year', title: 'Year', flex: '0 1 60px' },
      {
        key: 'migration',
        title: 'Net overseas migration',
        flex: '0 1 220px',
        numeric: true,
      },
    ],
  },
  {
    resource: 'table:dpie:net-interstate-migration',
    label: 'Net interstate migration',
    columns: [
      { key: 'region', title: 'Region', span: true, flex: '0 1 200px' },
      { key: 'year', title: 'Year', flex: '0 1 60px' },
      {
        key: 'migration',
        title: 'Net interstate migration',
        flex: '0 1 220px',
        numeric: true,
      },
    ],
  },
  {
    resource: 'table:dpie:net-intrastate-migration',
    label: 'Net intrastate migration',
    columns: [
      { key: 'region', title: 'Region', span: true, flex: '0 1 200px' },
      { key: 'year', title: 'Year', flex: '0 1 60px' },
      {
        key: 'migration',
        title: 'Net intrastate migration',
        flex: '0 1 220px',
        numeric: true,
      },
    ],
  },
  {
    resource: 'table:dpie:hum-dwelling-projection',
    label: 'Dwelling growth',
    columns: [
      { key: 'region', title: 'Region', span: true, flex: '0 1 200px' },
      { key: 'year', title: 'Year', flex: '0 1 60px' },
      {
        key: 'attached',
        title: 'Change in attached dwellings',
        flex: '0 1 220px',
        numeric: true,
      },
      {
        key: 'detached',
        title: 'Change in detached dwellings',
        flex: '0 1 220px',
        numeric: true,
      },
      {
        key: 'residents-npd',
        title: 'Change in residents in non-private dwellings',
        flex: '0 1 220px',
        numeric: true,
      },
    ],
  },
];

const filterValidModifications = (table) =>
  filter((modification) => {
    const modificationColumns = map(
      'key',
      get([table, 'modifications'], modificationsSchema),
    );
    return every(
      (column) => !isNil(get(column, modification)),
      modificationColumns,
    );
  });

const PopulationInputDataModal = ({
  animation,
  initialPane,
  onClose,
  workspaceId,
}) => {
  // NOTE: this will eventually come from service once implemented
  const [modifications, setModifications] = useState({});
  const completeModifications = useMemo(
    () =>
      reduce(
        (acc, key) => update(key, filterValidModifications(key), acc),
        unset('options', modifications),
        keys(modificationsSchema),
      ),
    [modifications],
  );
  const onChangeModification = useCallback(
    (table, index, modification) =>
      setModifications(update([table, index], modification)),
    [setModifications],
  );
  const onDeleteModification = useCallback(
    (table, index) =>
      setModifications((current) => {
        const result = [...defaultTo([], get(table, current))];
        result.splice(index, 1);
        return set(table, result, current);
      }),
    [setModifications],
  );

  const dispatch = useDispatch();
  const scenarioId = useScenarioId();
  const version = useSelectorWithProps(populationDatasetVersionSelector, {
    scenarioId,
    workspaceId,
  });
  const data = useSelectorWithProps(populationDatasetSelector, {
    scenarioId,
    workspaceId,
  });
  useEffect(() => {
    if (data) {
      setModifications(unset('options', get('modifications', data)));
    }
  }, [setModifications, data]);
  const draft = useSelector(populationDatasetDraftSelector);

  const error = useSelector(populationDatasetErrorSelector);

  const fetching = useSelector(get(['misc', 'isFetchingPopulationDataset']));
  const [table, setTable] = useState(initialPane);
  const [uploadKey, setUploadKey] = useState(undefined);
  const upload = useSelectorWithProps(uploadSelector, {
    upload: uploadKey,
  });

  useEffect(() => {
    if (!data && !fetching) {
      dispatch(populationDatasetFetch({ datasetId: version }));
    }
  }, [dispatch, data, fetching, version]);

  useEffect(() => {
    dispatch(populationActions.discardDraft());
  }, [dispatch]);

  const onDismissError = useCallback(() => {
    dispatch(populationActions.discardError());
  }, [dispatch]);

  const onDownload = useCallback(() => {
    dispatch(populationDatasetDownload({ datasetId: version }));
  }, [dispatch, version]);

  const onUpload = useCallback(
    (files) => {
      const key = nanoid();
      setUploadKey(key);
      dispatch(
        populationDatasetUpload({
          key,
          file: first(files),
        }),
      );
    },
    [dispatch, setUploadKey],
  );

  const isValid =
    !isNil(draft) ||
    !isEqual(
      unset('options', get('modifications', draft || data)),
      completeModifications,
    );

  const sourceLabel = get('label', draft || data);
  const [baseline, setBaseline] = useState('app-provided');
  useEffect(() => {
    setBaseline(sourceLabel ? 'label-provided' : 'app-provided');
  }, [sourceLabel, setBaseline]);
  const source =
    baseline === 'app-provided'
      ? 'baseline'
      : get('source', draft || data) || get('version', draft) || version;
  const onSave = useCallback(() => {
    if (!isValid) {
      onClose();
      return;
    }
    dispatch(
      populationDatasetUpdate({
        source,
        modifications: completeModifications,
        onSave: (result) => {
          const targets = [scenarioId];
          const operations = [
            {
              'save-selector': {
                selectors: [
                  {
                    resource: 'custom:dpie:population',
                    provider: `custom:dpie:population:${result.version}`,
                  },
                ],
              },
            },
          ];
          const actions = [
            {
              type: 'modify-population-input',
            },
          ];
          dispatch(
            workspacePatch({
              targetScenarioIds: targets,
              patchOps: operations,
              actions,
              optimisticState: {},
              workspaceId,
            }),
          );
          onClose();
        },
      }),
    );
  }, [
    completeModifications,
    dispatch,
    isValid,
    onClose,
    scenarioId,
    source,
    workspaceId,
  ]);

  const onDiscard = useCallback(() => {
    dispatch(populationActions.discardDraft());
    onClose();
  }, [dispatch, onClose]);

  const onAddVariant = useCallback(() => {
    setModifications((current) =>
      set(table, concat(defaultTo([], get(table, current)), [{}]), current),
    );
  }, [table, setModifications]);

  const layout = (
    <Layout direction='column'>
      <Layout direction='row'>
        <SidePane>
          <Layout direction='column' overflow='hidden'>
            <ScrollablePane>
              <LegacyContent>
                <Menu magnitude='large' spacing='none'>
                  {tables.map(({ resource, label }) => (
                    <Menu.Item
                      key={resource}
                      content={label}
                      onClick={() => setTable(resource)}
                      variant={resource === table ? 'accent' : undefined}
                    />
                  ))}
                </Menu>
              </LegacyContent>
            </ScrollablePane>
          </Layout>
        </SidePane>
        <Layout direction='column'>
          <Modifications
            table={table}
            options={get(['modifications', 'options', table], draft || data)}
            modifications={get(table, modifications)}
            onChangeModification={onChangeModification}
            onDeleteModification={onDeleteModification}
          />
        </Layout>
      </Layout>
    </Layout>
  );

  const headerLabel = 'Population input data';

  return (
    <Modal
      aria-label={headerLabel}
      header={
        <ModalHeader>
          <ModalHeading>{headerLabel}</ModalHeading>

          <ModalHeaderAction>
            <Inline space='xsmall'>
              <InlineItem>
                <PopulationUploadControl
                  onUpload={onUpload}
                  progress={!isNil(upload)}
                />
              </InlineItem>
              <InlineItem>
                <Tooltip title='Download population input data' placement='top'>
                  <UtilityButton
                    icon='download'
                    onClick={onDownload}
                    magnitude='small'
                  >
                    Download
                  </UtilityButton>
                </Tooltip>
              </InlineItem>
            </Inline>
          </ModalHeaderAction>
        </ModalHeader>
      }
      animation={animation}
      onClose={onDiscard}
      onSave={onSave}
      valid={isValid}
      saveable
      magnitude='xlarge'
    >
      <ToolbarBackground>
        <Toolbar justify='space-between'>
          <ToolbarGroup>
            <ToolbarItem>
              <Text>Reference</Text>
            </ToolbarItem>
            <ToolbarItem>
              <Select
                magnitude='small'
                width={240}
                options={[
                  {
                    label: 'CPA 2022',
                    value: 'app-provided',
                  },
                ].concat(
                  sourceLabel
                    ? [{ label: sourceLabel, value: 'label-provided' }]
                    : [],
                )}
                value={baseline}
                onChange={setBaseline}
                renderItem={renderBaselineItem}
              />
            </ToolbarItem>
          </ToolbarGroup>
          <ToolbarGroup justify='end'>
            <ToolbarItem>
              <UtilityButton
                icon='plus'
                magnitude='small'
                onClick={onAddVariant}
                disabled={
                  !get(['modifications', 'options', table], draft || data)
                }
              >
                Add variation
              </UtilityButton>
            </ToolbarItem>
          </ToolbarGroup>
        </Toolbar>
      </ToolbarBackground>
      {!upload && layout}
      {upload && (
        <Content alignX='center' alignY='center' height='100%'>
          <Inline space='small'>
            <InlineItem>
              <LoadingIcon />
            </InlineItem>
            {(upload.loaded < upload.total || isNil(upload.total)) && (
              <InlineItem>
                <Secondary>
                  Processing…{' '}
                  {upload.total
                    ? `${Math.round((upload.loaded * 100) / upload.total)}%`
                    : '0%'}
                </Secondary>
              </InlineItem>
            )}
            {upload.loaded === upload.total && !isNil(upload.total) && (
              <InlineItem>
                <Secondary>Validating…</Secondary>
              </InlineItem>
            )}
          </Inline>
        </Content>
      )}
      {error && (
        <PopulationInputDataErrorModal error={error} onClose={onDismissError} />
      )}
    </Modal>
  );
};

PopulationInputDataModal.defaultProps = defaultProps;
PopulationInputDataModal.propTypes = propTypes;

export { PopulationInputDataModal };
