import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, pipe, slice } from 'lodash/fp';
import { appsUpgrade } from 'actions/appsUpgrade';
import { Avatar, Button } from '@kinesis/bungle';
import { LegacyContent } from 'components/legacy-content';
import Plural from 'components/plural/plural';
import {
  UpgradeCard,
  UpgradeCardHeader,
  UpgradeCards,
  UpgradeName,
  UpgradeVersion,
} from './app-management.styles';

const propTypes = {
  upgrades: PropTypes.array.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const AppManagementUpgrades = ({ upgrades, workspaceId }) => {
  const dispatch = useDispatch();
  const onUpgrade = useCallback(() => {
    dispatch(appsUpgrade({ upgrades, workspaceId }));
  }, [dispatch, upgrades, workspaceId]);
  const isUpgrading = useSelector(get(['misc', 'isUpgradingApps']));

  return (
    <LegacyContent>
      <div style={{ paddingBottom: 16 }}>
        Update your apps to ensure that their outputs are based on the latest
        models.
      </div>
      <UpgradeCards>
        {upgrades.map((app) => (
          <UpgradeCard key={app.id}>
            <UpgradeCardHeader>
              <Avatar
                alt={`${app.publisher} logo`}
                image={`data:image/png;base64,${app.image}`}
                magnitude='xsmall'
                variant='organisation'
              >
                {pipe(get('publisher'), slice(0, 1))(app)}
              </Avatar>
              <UpgradeName>{app.label}</UpgradeName>
              <UpgradeVersion>Version {app.available.id}</UpgradeVersion>
            </UpgradeCardHeader>
          </UpgradeCard>
        ))}
      </UpgradeCards>
      <Button onClick={onUpgrade} loading={isUpgrading}>
        Update{' '}
        <Plural
          formatCount={(val) => (val === 0 ? 'no' : val)}
          count={upgrades.length}
          word='apps'
          inclusive
        />
      </Button>
    </LegacyContent>
  );
};

AppManagementUpgrades.defaultProps = defaultProps;
AppManagementUpgrades.propTypes = propTypes;

export default AppManagementUpgrades;
