import styled from 'styled-components';

const Title = styled.div`
  color: ${({ theme }) => theme.color.gray9};
`;

const Subtitle = styled.div`
  color: ${({ theme, variant }) =>
    variant === 'danger' ? theme.color.red6 : theme.color.gray7};
`;

const ListColumn = styled.div`
  flex-grow: ${(props) => (props.grow ? 1 : 0)};

  &:first-child {
    padding-right: 8px;
  }
  &:last-child {
    padding-left: 8px;
  }
`;

const RichList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
`;

const RichListItemLi = styled.li`
  align-items: center;
  margin-bottom: 16px;
  display: flex;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export { ListColumn, RichList, RichListItemLi, Subtitle, Title };
