import { createSelector } from 'reselect';
import { get, lowerCase, map, orderBy, pick, pipe, values } from 'lodash/fp';

const getToLowerCase = (key) => pipe(get(key), lowerCase);

const importLocationSourcesSelector = createSelector(
  get('workspaces'),
  (state, props) => props.workspaceId,
  (state, props) => props.scenarioId,
  (state, props) => props.currentLayerId,
  (workspaces, workspaceId, scenarioId, currentLayerId) =>
    pipe(
      map((workspace) => {
        const isCurrentWorkspace =
          workspaceId === get(['data', 'id'], workspace);

        return {
          ...pick(['id', 'touchedAt'], get('data', workspace)),
          name: isCurrentWorkspace
            ? 'Current workspace'
            : get(['data', 'name'], workspace) || 'New workspace',
          layers: pipe(
            get(['layers', 'entities']),
            values,
            map((layer) => ({
              ...layer,
              current:
                isCurrentWorkspace && get('id', layer) === currentLayerId,
            })),
          )(workspace),
          scenarios: pipe(
            get(['scenarios', 'named']),
            values,
            map((scenario) => ({
              ...scenario,
              name: get('name', scenario),
              current: scenarioId === get('id', scenario),
            })),
            orderBy(['isDefault', getToLowerCase('name')], ['desc', 'asc']),
          )(workspace),
          current: isCurrentWorkspace,
          collapseWorkspace: true,
          collapseScenario: true,
        };
      }),
      orderBy(['current', getToLowerCase('name')], ['desc', 'asc']),
    )(workspaces),
);

export default importLocationSourcesSelector;
