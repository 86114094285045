import { createSlice } from '@reduxjs/toolkit';
import { get, keyBy, pipe, set } from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { locationCreate } from 'actions/locationCreate';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { workspaceUpdateOwner } from 'actions/workspaceUpdateOwner';
import { geographyUpdate } from 'actions/geographyUpdate';

const key = (action) =>
  pipe(
    get(['payload', 'geographies', 'geographies']),
    keyBy('id'),
    set(0, get(['payload', 'geographies', 'boundary'], action)),
  )(action);

const { reducer, actions } = createSlice({
  name: 'geography',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(workspaceFetch.fulfilled.type, (state, action) =>
      key(action),
    );
    builder.addCase(workspacePatch.fulfilled.type, (state, action) =>
      key(action),
    );
    builder.addCase(geographyUpdate.fulfilled.type, (state, action) =>
      key(action),
    );
    builder.addCase(appAdd.fulfilled.type, (state, action) => key(action));
    builder.addCase(workspaceUpdateOwner.fulfilled.type, (state, action) =>
      key(action),
    );

    builder.addCase(locationCreate.fulfilled.type, (state, action) =>
      action.payload.geography
        ? set(
            action.payload.geography.id,
            action.payload.geography,
            key(action),
          )
        : key(action),
    );
  },
});

export { reducer, actions };
