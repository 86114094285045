import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo, get } from 'lodash/fp';
import { actions as preferencesActions } from 'reducers/preferencesReducer';

const { setPreference } = preferencesActions;

function usePreference(key, defaultValue) {
  const preference = defaultTo(
    defaultValue,
    useSelector(get(['preferences', key])),
  );
  const dispatch = useDispatch();

  const setPref = useCallback(
    (value) => {
      dispatch(setPreference({ key, value }));
    },
    [dispatch, key],
  );

  return [preference, setPref];
}

export default usePreference;
