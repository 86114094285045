import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get, isNil } from 'lodash/fp';
import { Tooltip, UtilityButton } from '@kinesis/bungle';
import { ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import useActions from 'hooks/useActions';
import useToolboxOpenState from 'hooks/useToolboxOpenState';
import { useAppId } from 'hooks';
import Layout from 'components/layout';
import { AppToolbar, BackButton, Breadcrumb } from 'components/app-header';
import { Toolbox } from 'components/toolbox';

const App = () => {
  const [isToolboxOpen, setToolboxOpenState] = useToolboxOpenState();
  const { select } = useActions(toolboxActions);
  const panes = useSelector(get(['toolbox', 'panes']));
  const appId = useAppId();
  const app = useSelector(get(['apps', 'entities', appId]));
  const loading = isNil(app);

  const handleToolboxStateChange = useCallback(() => {
    setToolboxOpenState((isOpen) => !isOpen);
  }, [setToolboxOpenState]);

  useEffect(() => {
    select({
      pane: 'app',
      selection: { id: appId, type: 'app' },
    });
  }, [appId, panes, select]);

  return (
    <>
      <BackButton to='/apps' />
      <Breadcrumb to='/apps' root>
        Apps
      </Breadcrumb>
      {!loading && (
        <Breadcrumb to={`/apps/${appId}`}>{get('label', app)}</Breadcrumb>
      )}
      <AppToolbar>
        <ToolbarGroup>
          <ToolbarItem>
            <Tooltip
              justify='end'
              placement='bottom'
              title={isToolboxOpen ? 'Hide toolbox' : 'Show toolbox'}
            >
              <UtilityButton
                expanded={isToolboxOpen}
                icon='control'
                variant={isToolboxOpen ? 'accent' : 'default'}
                onClick={handleToolboxStateChange}
              />
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </AppToolbar>
      <Layout>
        <Layout direction='column'>
          <div>implement me</div>
        </Layout>
        {isToolboxOpen && <Toolbox />}
      </Layout>
    </>
  );
};

export { App };
