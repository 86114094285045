import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const blocksRemove = createAsyncThunk(
  'blocks/remove',
  async ({ workspaceId, boardId, blocks }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.patch(
      `/api/workspace/${workspaceId}/board/${boardId}/remove-blocks`,
      { blocks },
    );
    return response.data;
  },
);

export { blocksRemove };
