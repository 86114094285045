import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import blockDataByScenarioSelector from 'selectors/blockDataByScenarioSelector';

const activeScenarioCapsuleDataSelector = createSelector(
  (state, props) => props.scenarioId,
  blockDataByScenarioSelector,
  get,
);

export default activeScenarioCapsuleDataSelector;
