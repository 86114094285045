import { createSlice } from '@reduxjs/toolkit';
import { publicTokensFetch } from 'actions/publicTokensFetch';

const { actions, reducer } = createSlice({
  name: 'publicTokens',

  initialState: {
    currentRequestId: undefined,
    entities: {},
    error: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(publicTokensFetch.pending, (state, action) =>
      !state.currentRequestId
        ? {
            ...state,
            currentRequestId: action.meta.requestId,
          }
        : state,
    );

    builder.addCase(publicTokensFetch.fulfilled, (state, action) =>
      state.currentRequestId === action.meta.requestId
        ? {
            ...state,
            currentRequestId: undefined,
            entities: action.payload,
          }
        : state,
    );

    builder.addCase(publicTokensFetch.rejected, (state, action) =>
      state.currentRequestId === action.meta.requestId
        ? {
            ...state,
            currentRequestId: undefined,
            error: action.meta.error,
          }
        : state,
    );
  },
});

export { actions, reducer };
