import styled from 'styled-components';

const Dropzone = styled('div')`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.45);
  z-index: 1000;
  color: rgba(255, 255, 255, 0.65);
  line-height: 100vh;
  text-align: center;
`;

const Hidden = styled.div`
  display: none;
`;

export { Dropzone, Hidden };
