/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  filter,
  find,
  get,
  identity,
  includes,
  map,
  pipe,
  reject,
  set,
  curry,
  isNil,
  update,
  defaultTo,
} from 'lodash/fp';
import { WebMercatorViewport } from '@math.gl/web-mercator';
import {
  H3,
  Inline,
  InlineItem,
  Input,
  Stack,
  Subheading,
} from '@kinesis/bungle';
import { withinBounds } from 'utils/spatialUtils';
import Layout from 'components/layout';
import Section from 'components/section';
import Map from 'components/map/Map';
import PositionHover from 'components/position-hover/PositionHover';
import MapContainer from 'components/map-container/MapContainer';
import layersSelector from 'selectors/layersSelector';
import layerBoundsSelector from 'selectors/layerBoundsSelector';
import layerColorSelector from 'selectors/layerColorSelector';
import makePublicTokenSelector from 'selectors/makePublicTokenSelector';
import scenarioLocationsSelector from 'selectors/scenarioLocationsSelector';
import { visualisationLocationLayers } from 'components/spatial-visualisation/location-layers';
import editLayers from 'components/spatial-visualisation/edit-layers';
import { useSelectorWithProps } from 'hooks';
import useTheme from 'hooks/useTheme';
import { normaliseLongitude } from 'utils/lngLatUtils';
import Units from 'data/units';
import { SidePane } from './attribute-values.styles';

const units = new Units();

const format = units.parseColumn({
  unit: { type: 'coordinate' },
}).formatCell;

const checkBoundsInclusive = curry(
  ([lowerBound, upperBound], value) =>
    value >= lowerBound && value <= upperBound,
);
const validateLatitude = checkBoundsInclusive([-85, 85]);
const validateLongitude = checkBoundsInclusive([-180, 180]);

const propTypes = {
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  locationId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['privacy', 'public']),
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  latitude: undefined,
  longitude: undefined,
  privacy: 'private',
};

const mapboxAccessTokenSelector = makePublicTokenSelector('mapboxAccessToken');

const determineBounds = (state) => {
  const viewport = new WebMercatorViewport(state);
  return [
    viewport.unproject([0, 0]),
    viewport.unproject([viewport.width, viewport.height]),
  ];
};

const GeographyAttributes = ({
  locationId,
  longitude,
  latitude,
  onChange,
  privacy,
  scenarioId,
  workspaceId,
}) => {
  const theme = useTheme();
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);
  const layers = useSelectorWithProps(layersSelector, {
    public: privacy === 'public',
    workspaceId,
  });
  const layer = useMemo(
    () =>
      find(
        pipe(
          get('locations'),
          map((l) => l.id),
          includes(locationId),
        ),
      )(layers),
    [layers, locationId],
  );
  const layerId = get('id', layer);
  const colorName = useSelectorWithProps(layerColorSelector, {
    layerId,
    public: privacy === 'public',
    workspaceId,
  });

  const layerColor = get(['color', 'layer', colorName], theme);
  const scenarioLocations = useSelectorWithProps(scenarioLocationsSelector, {
    layerId,
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });
  const locationIds = map(get('id'), get('locations', layer));
  const bounds = useSelectorWithProps(layerBoundsSelector, {
    layerId,
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });

  const [viewport, setViewport] = useState();
  const [target, setTarget] = useState(undefined);
  const [hover, setHoverDirect] = useState(undefined);
  const [hoverEdit, setHoverEdit] = useState(undefined);
  const [drag, setDrag] = useState(undefined);
  const [isDragging, setIsDragging] = useState(undefined);
  const setHover = useCallback(
    (object) =>
      isNil(object)
        ? setHoverDirect(object)
        : setHoverDirect(update('longitude', normaliseLongitude, object)),
    [setHoverDirect],
  );

  const [latitudeInputDraft, setLatitudeInputDraft] = useState(undefined);
  const [longitudeInputDraft, setLongitudeInputDraft] = useState(undefined);
  const [latitudeInput, setLatitudeInput] = useState(latitude.toFixed(5));
  const [longitudeInput, setLongitudeInput] = useState(longitude.toFixed(5));
  const setRoundedLatitudeInput = useCallback(
    (lat) => setLatitudeInput(lat.toFixed(5)),
    [setLatitudeInput],
  );
  const setRoundedLongitudeInput = useCallback(
    (lng) => setLongitudeInput(normaliseLongitude(lng).toFixed(5)),
    [setLongitudeInput],
  );
  const setRoundedCoordinate = useCallback(
    (coordinate) => {
      const rounded = {
        latitude: parseFloat(coordinate.latitude.toFixed(5)),
        longitude: normaliseLongitude(coordinate.longitude),
      };
      onChange({ point: rounded });
      const currentBounds = viewport ? determineBounds(viewport) : bounds;
      if (!withinBounds(currentBounds, rounded)) {
        return setTarget(rounded);
      }
    },
    [bounds, onChange, viewport],
  );

  const onHover = useCallback(
    ({ object }) => {
      setHoverEdit(!!object);
    },
    [setHoverEdit],
  );

  const onDrag = useCallback(
    ({ coordinate: dragCoordinate, x, y }) => {
      if (dragCoordinate) {
        setDrag({ latitude: dragCoordinate[1], longitude: dragCoordinate[0] });
        setHover({
          longitude: dragCoordinate[0],
          latitude: dragCoordinate[1],
          x,
          y,
        });
      }
      return true;
    },
    [setDrag, setHover],
  );

  const onDragStart = useCallback(() => {
    setIsDragging(true);
    return true;
  }, [setIsDragging]);

  const onDragEnd = useCallback(
    ({ coordinate: dragCoordinate }) => {
      setIsDragging(false);
      setDrag(undefined);
      setHover(undefined);
      if (dragCoordinate) {
        const lng = dragCoordinate[0];
        const lat = dragCoordinate[1];
        setRoundedCoordinate({
          longitude: lng,
          latitude: lat,
        });
        setRoundedLongitudeInput(lng);
        setRoundedLatitudeInput(lat);
      }
      return true;
    },
    [
      setHover,
      setRoundedCoordinate,
      setRoundedLatitudeInput,
      setRoundedLongitudeInput,
    ],
  );

  const getCursor = useCallback(() => {
    if (isDragging) return 'none';
    return 'grab';
  }, [isDragging]);

  const onBlurInput = useCallback(() => {
    // Set the canonical input fields from the drafts.
    const latitudeDraft = parseFloat(latitudeInputDraft);
    const longitudeDraft = parseFloat(longitudeInputDraft);
    const isValidLatitudeDraft = validateLatitude(latitudeDraft);
    const isValidLongitudeDraft = validateLongitude(longitudeDraft);
    if (isValidLatitudeDraft) {
      setRoundedLatitudeInput(latitudeDraft);
    }
    if (isValidLongitudeDraft) {
      setRoundedLongitudeInput(longitudeDraft);
    }

    // Set the coordinate from the canonical inputs.
    const lat = isValidLatitudeDraft
      ? latitudeDraft
      : parseFloat(latitudeInput);
    const lng = isValidLongitudeDraft
      ? longitudeDraft
      : parseFloat(longitudeInput);
    const isValidLatitude = validateLatitude(lat);
    const isValidLongitude = validateLongitude(lng);
    if (isValidLatitude && isValidLongitude) {
      setRoundedCoordinate({ longitude: lng, latitude: lat });
    }

    // Always unset drafts on blur.
    setLatitudeInputDraft(undefined);
    setLongitudeInputDraft(undefined);
  }, [
    setRoundedCoordinate,
    latitudeInput,
    longitudeInput,
    latitudeInputDraft,
    longitudeInputDraft,
    setRoundedLatitudeInput,
    setRoundedLongitudeInput,
    setLatitudeInputDraft,
    setLongitudeInputDraft,
  ]);

  const deckLocationLayers = useMemo(
    () =>
      visualisationLocationLayers({
        locations: pipe(
          privacy === 'public' ? identity : reject({ id: locationId }),
          filter((l) => includes(l.id, locationIds)),
          map(set('layerColor', layerColor)),
        )(scenarioLocations),
        focus: false,
        selectedId: privacy === 'public' ? locationId : undefined,
      }),
    [layerColor, locationId, locationIds, privacy, scenarioLocations],
  );
  const deckEditLayers = useMemo(
    () =>
      editLayers({
        hovering: hoverEdit,
        latitude,
        layerColor,
        longitude,
        onDrag,
        onDragEnd,
        onDragStart,
        onHover,
        ...(drag || {}),
      }),
    [
      drag,
      hoverEdit,
      latitude,
      layerColor,
      longitude,
      onDrag,
      onDragEnd,
      onDragStart,
      onHover,
    ],
  );

  return (
    <Layout direction='row'>
      <SidePane>
        <Section collapsible={false} heading='Location'>
          <Stack space='medium'>
            {privacy === 'public' ? (
              <Inline space='small'>
                <InlineItem sizing='fill-container'>
                  <H3>Latitude</H3>
                  <Subheading>{latitude}</Subheading>
                </InlineItem>
                <InlineItem sizing='fill-container'>
                  <H3>Longitude</H3>
                  <Subheading>{longitude}</Subheading>
                </InlineItem>
              </Inline>
            ) : (
              <Inline space='small'>
                <InlineItem sizing='fill-container'>
                  <Input
                    label='Latitude'
                    magnitude='large'
                    value={defaultTo(latitudeInput, latitudeInputDraft)}
                    onChange={(input) =>
                      setLatitudeInputDraft(defaultTo('', input))
                    }
                    onBlur={onBlurInput}
                    data-testid='latitude-values'
                  />
                </InlineItem>
                <InlineItem sizing='fill-container'>
                  <Input
                    label='Longitude'
                    magnitude='large'
                    value={defaultTo(longitudeInput, longitudeInputDraft)}
                    onChange={(input) =>
                      setLongitudeInputDraft(defaultTo('', input))
                    }
                    onBlur={onBlurInput}
                    data-testid='longitude-values'
                  />
                </InlineItem>
              </Inline>
            )}
          </Stack>
        </Section>
      </SidePane>
      <Layout direction='column'>
        <MapContainer>
          {hover && isDragging && (
            <PositionHover x={hover.x} y={hover.y}>
              {format(hover.latitude)}, {format(hover.longitude)}
            </PositionHover>
          )}
          <Map
            dragPan={!isDragging}
            mapboxAccessToken={mapboxAccessToken}
            mapStyle='light'
            layers={
              privacy === 'public'
                ? deckLocationLayers
                : [...deckLocationLayers, ...deckEditLayers]
            }
            getCursor={getCursor}
            bounds={target ? undefined : bounds}
            target={target || undefined}
            onViewportChange={setViewport}
          />
        </MapContainer>
      </Layout>
    </Layout>
  );
};

GeographyAttributes.defaultProps = defaultProps;
GeographyAttributes.propTypes = propTypes;

export default GeographyAttributes;
