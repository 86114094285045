import { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { map, get } from 'lodash/fp';
import {
  Button,
  Icon,
  SegmentedControl,
  SegmentedControlItem,
} from '@kinesis/bungle';
import { Inline as OldInline } from '@kinesis/bungle/legacy';
import { useSelectorWithProps } from 'hooks';
import layerSelector from 'selectors/layerSelector';
import scenarioSelector from 'selectors/scenarioSelector';
import {
  ModalHeader,
  ModalHeaderAction,
  ModalHeading,
} from 'components/modals/modal-header';
import Plural from 'components/plural/plural';
import Layout from 'components/layout';
import LayerCircle from 'components/layer-circle/LayerCircle';
import { LegacyContent } from 'components/legacy-content';
import { locationsDatasetImport } from 'actions/locationsDatasetImport';
import { locationsWorkspaceImport } from 'actions/locationsWorkspaceImport';
import { actions as miscActions } from 'reducers/miscReducer';
import ImportSourcesMenu from './ImportSourcesMenu';
import LocationsChooser from './LocationsChooser';

const FooterContent = styled(LegacyContent)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  flex: 0 0 49px;
  height: 49px;
`;

const Summary = styled.span`
  color: ${({ theme }) => theme.color.gray7};
`;

const ScenarioLabel = styled.span`
  display: inline-flex;
`;
const BaselineIconWrapper = styled.span`
  padding: 4px;
  color: ${({ theme }) => theme.color.gray7};
`;

const propTypes = {
  layerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const LocationsImporter = ({
  layerId,
  onCancel,
  scenarioId: currentScenarioId,
  workspaceId,
}) => {
  const { isDefault, name, id } = useSelectorWithProps(scenarioSelector, {
    scenarioId: currentScenarioId,
    workspaceId,
  });
  const { label } = useSelectorWithProps(layerSelector, {
    layerId,
    workspaceId,
  });

  const dispatch = useDispatch();
  const [view, setView] = useState('map');
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState();
  const [selectedScenarioId, setSelectedScenarioId] = useState();
  const [selectedLayer, setSelectedLayer] = useState();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const source = useSelectorWithProps(scenarioSelector, {
    scenarioId: selectedScenarioId,
    workspaceId: selectedWorkspaceId,
  });
  const selectedScenarioKey = useMemo(() => {
    if (selectedWorkspaceId && selectedScenarioId) {
      return (
        get(['draft', 'scenario'], source) ||
        get(['published', 'scenario'], source)
      );
    }
  }, [source, selectedWorkspaceId, selectedScenarioId]);

  useEffect(() => {
    dispatch(miscActions.setShowSelectAllLocationsPrompt());
  }, [dispatch]);

  const handleSelect = useCallback(
    ({ type, workspace, scenarioId, layer, key }) => {
      if (type === 'workspace') {
        setSelectedPlatform(undefined);
        setSelectedWorkspaceId(workspace);
        setSelectedScenarioId(scenarioId);
        setSelectedLayer(layer);
        setSelectedLocations([]);
      } else if (type === 'platform') {
        setSelectedPlatform(key);
        setSelectedWorkspaceId(undefined);
        setSelectedScenarioId(undefined);
        setSelectedLayer(undefined);
        setSelectedLocations([]);
      }
    },
    [
      setSelectedPlatform,
      setSelectedWorkspaceId,
      setSelectedScenarioId,
      setSelectedLayer,
      setSelectedLocations,
    ],
  );

  const handleSubmit = useCallback(() => {
    if (selectedPlatform) {
      const datasetId = {
        'nsw-lga': 1,
        'nsw-sa2': 2,
        'nsw-sa3': 3,
        'nsw-lhd': 4,
        'nsw-phn': 5,
        'nsw-ced-2018': 6,
        'nsw-sed-2020': 7,
        'nsw-sa4-2016': 8,
      }[selectedPlatform];
      dispatch(
        locationsDatasetImport({
          datasetId,
          keys: map('id', selectedLocations),
          layer: layerId,
          scenarioIds: [id],
          workspaceId,
        }),
      );
    } else {
      dispatch(
        locationsWorkspaceImport({
          currentWorkspaceId: workspaceId,
          layer: layerId,
          locations: map('id', selectedLocations),
          scenarioIds: [id],
          scenarioKey: selectedScenarioKey,
          selectedWorkspaceId,
        }),
      );
    }
    onCancel();
  }, [
    selectedPlatform,
    onCancel,
    dispatch,
    selectedLocations,
    layerId,
    id,
    workspaceId,
    selectedWorkspaceId,
    selectedScenarioKey,
  ]);

  return (
    <>
      <ModalHeader>
        <ModalHeading>Import locations</ModalHeading>
        <ModalHeaderAction>
          <span style={{ paddingRight: 8 }}>
            <LayerCircle layerId={layerId} workspaceId={workspaceId} />
          </span>
          <span>{label}</span>
        </ModalHeaderAction>
        <ModalHeaderAction>
          <ScenarioLabel>
            {name}
            {isDefault && (
              <BaselineIconWrapper>
                <Icon type='baseline' />
              </BaselineIconWrapper>
            )}
          </ScenarioLabel>
        </ModalHeaderAction>
        <ModalHeaderAction>
          <SegmentedControl value={view} onChange={setView}>
            <SegmentedControlItem
              icon='map-outline'
              title='Map view'
              value='map'
            />
            <SegmentedControlItem
              icon='list-view'
              title='List view'
              value='list'
            />
          </SegmentedControl>
        </ModalHeaderAction>
      </ModalHeader>
      <Layout direction='column'>
        <Layout direction='row'>
          <ImportSourcesMenu
            currentLayerId={layerId}
            layerId={selectedLayer}
            onSelect={handleSelect}
            platformKey={selectedPlatform}
            scenarioId={selectedScenarioId}
            scenarioKey={selectedScenarioKey}
            workspaceId={selectedWorkspaceId}
          />
          <LocationsChooser
            view={view}
            key={selectedLayer}
            onChange={setSelectedLocations}
            selectedLocations={selectedLocations}
            workspaceId={selectedWorkspaceId}
            scenarioId={selectedScenarioId}
            scenarioKey={selectedScenarioKey}
            layerId={selectedLayer}
            platformKey={selectedPlatform}
          />
        </Layout>
        <FooterContent spacing='small'>
          <Summary>
            <Plural
              count={selectedLocations.length}
              formatCount={(val) => (val === 0 ? 'No' : val)}
              inclusive
              word='locations'
            />{' '}
            selected
          </Summary>
          <OldInline space='small' justify='end'>
            <Button variant='secondary' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              disabled={selectedLocations.length === 0}
              variant='primary'
              onClick={handleSubmit}
            >
              Import locations
            </Button>
          </OldInline>
        </FooterContent>
      </Layout>
    </>
  );
};

LocationsImporter.propTypes = propTypes;
LocationsImporter.defaultProps = defaultProps;

export default LocationsImporter;
