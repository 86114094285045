import styled from 'styled-components';

const Container = styled('div')({
  display: 'flex',
  direction: 'row',
  flexWrap: 'wrap',
  columnGap: '8px',
});

export { Container };
