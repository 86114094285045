import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceCreate = createAsyncThunk(
  'workspace/create',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const api = createApiFromState({ dispatch, getState });

    try {
      const response = await api.post(`/api/workspace`);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        id: err.data.errorId,
        message: "We couldn't create the workspace. Please try again.",
      });
    }
  },
);

export { workspaceCreate };
