import styled from 'styled-components';
import PropTypes from 'prop-types';
import { get, pipe } from 'lodash/fp';
import { H2 } from '@kinesis/bungle';

import blockValueSelector from 'selectors/blockValueSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const Container = styled.div`
  position: relative;
  margin: 0 16px;
  grid-column: span 6;
`;

const BlockSection = ({ blockId, privacy, workspaceId }) => {
  const title = useSelectorWithProps(pipe(blockValueSelector, get('title')), {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });

  return (
    <Container>
      <H2>{title}</H2>
    </Container>
  );
};

BlockSection.propTypes = propTypes;
BlockSection.defaultProps = defaultProps;

export { BlockSection };
