import styled from 'styled-components';

const ClearFilterContainer = styled.div`
  min-width: 20px;
  flex-grow: 0;
  opacity: 0;
`;

const Card = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-sizing: border-box;
  border-radius: 2px;
  outline: 0;
  padding: 16px;
  margin-bottom: 16px;
  width: 272px;
  :last-of-type {
    margin-bottom: 0px;
  }

  &:hover {
    ${ClearFilterContainer} {
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

const Field = styled('span')(({ theme }) => ({
  fontWeight: theme.fontWeight.medium,
  color: theme.color.gray9,
  marginRight: 4,
}));

const Operator = styled.span`
  flex-grow: 1;
`;

export { Card, ClearFilterContainer, Field, Header, Operator };
