import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, identity, has, toPairs } from 'lodash/fp';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  DropdownMenuSeparator,
  DropdownMenuText,
  Placeholder,
  UtilityButton,
} from '@kinesis/bungle';

const propTypes = {
  editor: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  message: PropTypes.string,
  onSelect: PropTypes.func,
};
const defaultProps = {
  disabled: false,
  value: undefined,
  message: undefined,
  editor: true,
  onSelect: identity,
};

const labels = {
  layer: 'Locations',
  table: 'Table',
};

const DatasetTypeDropdown = ({
  disabled,
  editor,
  value,
  message,
  onSelect,
}) => {
  const selected = useMemo(
    () => (has(value, labels) ? value : undefined),
    [value],
  );
  return (
    <DropdownMenu>
      <DropdownMenuButton
        as={UtilityButton}
        disabled={disabled || !editor}
        dropdown
        inline
        magnitude='xsmall'
      >
        {selected ? (
          get(selected, labels)
        ) : (
          <Placeholder>Select data type</Placeholder>
        )}
      </DropdownMenuButton>
      <DropdownMenuList width={240}>
        {message && (
          <>
            <DropdownMenuText>{message}</DropdownMenuText>
            <DropdownMenuSeparator />
          </>
        )}

        {toPairs(labels).map(([key, label]) => (
          <DropdownMenuItem
            key={key}
            onSelect={() => onSelect(key)}
            variant={key === selected}
          >
            {label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuList>
    </DropdownMenu>
  );
};

DatasetTypeDropdown.defaultProps = defaultProps;
DatasetTypeDropdown.propTypes = propTypes;

export { DatasetTypeDropdown };
