import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const blockValueCreate = createAsyncThunk(
  'block-value/create',
  async ({ blockParams }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.post(`/api/block-values`, { blockParams });

    return response.data;
  },
);

export { blockValueCreate };
