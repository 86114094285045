import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PopoverContent from 'components/popover/PopoverContent';

const PopoverContentWrapper = styled.div`
  padding: 16px;
`;

class DataPopover extends PureComponent {
  static propTypes = {
    disablePointerEvents: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  };

  static defaultProps = {
    disablePointerEvents: undefined,
    title: undefined,
    content: undefined,
  };

  render() {
    const { disablePointerEvents, title, content } = this.props;

    return (
      <PopoverContentWrapper
        style={{ pointerEvents: disablePointerEvents ? 'none' : 'auto' }}
      >
        <PopoverContent
          title={title}
          colorColumn='color'
          keyColumn='label'
          valueColumn='value'
          data={content}
        />
      </PopoverContentWrapper>
    );
  }
}

export default DataPopover;
