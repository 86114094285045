import chroma, { mix } from 'chroma-js';
import { interpolateRgbBasis } from 'd3-interpolate';

const ramp = (scheme) => interpolateRgbBasis(scheme);

function pickQualitative(colors, amount) {
  if (amount <= colors.length) {
    return colors.slice(0, amount);
  }

  return [...colors, ...pickQualitative(colors, amount - colors.length)];
}

function pickSequential(colors, amount) {
  return chroma.scale(colors).mode('lch').colors(amount);
}

function opacify(color, opacity) {
  return chroma(color).alpha(opacity).css();
}

function shade(color) {
  return chroma(color).mix('black', 0.25, 'rgb').css();
}

// This was calculated with the science of guessing. If BK goes WTF?!, it was his guess.
function lighten(color) {
  return chroma(color).mix('white', 0.25, 'rgb').css();
}

function soften(color) {
  const alpha = chroma(color).alpha();
  const shadedColor = shade(opacify(color, 1));

  return mix('white', shadedColor, alpha, 'rgb').css();
}

export {
  pickQualitative,
  pickSequential,
  opacify,
  shade,
  soften,
  lighten,
  ramp,
};
