import styled from 'styled-components';

const ModificationsListComponent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: -4px;
`;

const ModificationsListBodyComponent = styled.ul`
  list-style: none;
  margin-bottom: 0;
  margin-right: -1px;
  max-height: 439px;
  padding: 0;
  color: ${({ theme }) => theme.color.gray8};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  overflow-x: hidden;

  &:first-of-type {
    margin-top: 0;
  }
`;

const ModificationsListActionsComponent = styled.div`
  margin-left: -8px;
  margin-bottom: 0;
  background: white;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
`;

const ModificationsListItemTooltip = styled.div`
  color: ${({ theme }) => theme.color.gray7};
`;

const ModificationsListItemLine = styled.div`
  line-height: 20px;

  /* ellipsis on body text */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const ModificationsListItemFrame = styled.div`
  flex-grow: 1;
  padding: 8px 12px;
  overflow: hidden;
`;

const ModificationsListItemComponent = styled('li')`
  display: flex;
  background: ${({ theme }) => theme.color.gray2};
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  overflow: hidden;

  &:last-of-type {
    border-bottom: none;
  }
`;

export {
  ModificationsListComponent,
  ModificationsListActionsComponent,
  ModificationsListItemComponent,
  ModificationsListItemLine,
  ModificationsListItemTooltip,
  ModificationsListItemFrame,
  ModificationsListBodyComponent,
};
