import PropTypes from 'prop-types';
import { Stack, Select, LabelText } from '@kinesis/bungle';
import { useCallback, useMemo } from 'react';
import { isEqual, pipe, filter, get, find } from 'lodash/fp';
import {
  itemsFilter,
  labelOfMeasureOrBreakdown,
  groupKeyOf,
} from 'data/block/visualisation/configuration/series-select';
import {
  Row,
  Separator,
  InputContainer,
} from './visualisation-series-select.styles';
import { BreakdownLabel } from './breakdown-label';
import { DeleteSeriesButton } from './delete-series-button';
import { AddSeriesButton } from './add-series-button';

const propTypes = {
  defaultExpanded: PropTypes.number,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  expression: PropTypes.object.isRequired,
  breakdown: PropTypes.object.isRequired,
};

const defaultProps = {
  defaultExpanded: undefined,
};

const itemsRender = (item) => {
  const label = labelOfMeasureOrBreakdown(item);
  switch (item.type) {
    case 'expression':
      return label;
    case 'breakdown':
      return <BreakdownLabel label={label} />;
    default:
      return undefined;
  }
};

const Breakdown = ({
  defaultExpanded,
  label,
  options,
  expression,
  breakdown,
  onChange,
  onDelete,
}) => {
  const expressions = useMemo(
    () => filter((o) => o.type === 'expression', options),
    [options],
  );
  const handleOnChangeAtField = useCallback(
    (field) => (v) => onChange({ field, value: v }),
    [onChange],
  );

  const breakdownLabel = useMemo(
    () =>
      pipe(
        find(
          (o) => o.type === 'breakdown' && o.value.column === breakdown.column,
        ),
        get('label'),
      )(options),
    [breakdown, options],
  );
  const optionsWithoutSelection = useMemo(
    () => filter((o) => !isEqual(o.value, expression), options),
    [options, expression],
  );

  return (
    <Stack space='xsmall'>
      <LabelText>{label}</LabelText>
      <Row>
        <InputContainer>
          <Select
            defaultExpanded={defaultExpanded}
            itemKey='key'
            groupKey={groupKeyOf(expressions)}
            value={expression}
            onChange={handleOnChangeAtField('expression')}
            options={expressions}
            search
            itemsLabel='measures'
            overrideEmptyText='Select an output to view available measures.'
            magnitude='large'
            renderSelected={itemsRender}
            itemsFilter={itemsFilter}
          />
        </InputContainer>
        <Separator />
        <DeleteSeriesButton onClick={onDelete(0)} />
      </Row>
      <Row>
        <InputContainer>
          <Select
            defaultExpanded={defaultExpanded}
            itemKey='key'
            groupKey={groupKeyOf(optionsWithoutSelection)}
            value={breakdown}
            onChange={handleOnChangeAtField('by')}
            options={optionsWithoutSelection}
            search
            itemsLabel='measure or breakdowns'
            overrideEmptyText='Select an output to view available measures or breakdowns.'
            magnitude='large'
            renderSelected={itemsRender}
            itemsFilter={itemsFilter}
          />
        </InputContainer>
        <Separator />
        <DeleteSeriesButton onClick={onDelete(1)} />
      </Row>
      <div>
        <AddSeriesButton
          tooltip={`Remove the breakdown by ${breakdownLabel} to add a series`}
          disabled
        />
      </div>
    </Stack>
  );
};

Breakdown.propTypes = propTypes;
Breakdown.defaultProps = defaultProps;

export { Breakdown };
