import styled from 'styled-components';
import { rgba } from 'polished';

const ToolboxSectionWrapper = styled('section')(({ closed, theme }) => ({
  '&:last-child': {
    marginBottom: closed ? 24 : 0,
    transition: 'margin-bottom 0s 0.1s',
    borderBottom: closed ? `1px solid ${theme.borderColor}` : undefined,
  },
}));

const ToolboxSectionInner = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.borderColor}`,
  paddingBottom: 8,
  [`${ToolboxSectionWrapper}:first-child &&`]: {
    borderTopWidth: 0,
  },
}));

const ToolboxSectionHeader = styled('header')(({ collapsible }) =>
  collapsible
    ? {
        padding: 8,
        paddingBottom: 0,
      }
    : {
        padding: 16,
        paddingBottom: 8,
      },
);

const ToolboxSectionHeaderInner = styled('div')({
  minWidth: 0,
  overflow: 'hidden',
  paddingLeft: 8,
  textOverflow: 'ellipsis',
});

const ToolboxSectionClickArea = styled('div').attrs({
  role: 'button',
})(({ theme }) => ({
  alignItems: 'center',
  borderRadius: 2,
  boxSizing: 'content-box',
  cursor: 'pointer',
  display: 'flex',
  font: 'inherit',
  padding: 8,
  position: 'relative',
  textTransform: 'inherit',
  '&:hover': {
    backgroundColor: theme.color.gray3,
  },
  '&:focus': {
    boxShadow: `0 0 0 2px ${rgba('black', 0.05)}`,
  },
  '&:active': {
    backgroundColor: theme.color.gray4,
    boxShadow: 'none',
  },
}));

const ToolboxSectionContent = styled('div')({
  padding: '8px 16px 16px',
  'p:last-child': {
    marginBottom: 0,
  },
});

export {
  ToolboxSectionWrapper,
  ToolboxSectionInner,
  ToolboxSectionHeader,
  ToolboxSectionHeaderInner,
  ToolboxSectionClickArea,
  ToolboxSectionContent,
};
