import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isEmpty, isNil } from 'lodash/fp';
import { UtilityButton } from '@kinesis/bungle';
import {
  Table,
  TableButton,
  TableCell,
  TableRow,
  TableText,
} from 'components/table';
import { datasetImportSchema } from 'actions/datasetImportSchema';
import appSchemaSourcesSelector from 'selectors/appSchemaSourcesSelector';
import { ImportSchemaModal } from 'components/modals/import-schema';

const propTypes = {
  datasetId: PropTypes.number.isRequired,
  versionId: PropTypes.number.isRequired,
};

const defaultProps = {};

const DatasetTableProfile = ({ datasetId, versionId }) => {
  const dispatch = useDispatch();
  const [importSchemaOpen, setImportSchemaOpen] = useState(false);
  const version = useSelector(get(['datasetVersions', versionId]));
  const isDraft = isNil(get('publishedAs', version));

  const openImportSchema = useCallback(
    () => setImportSchemaOpen(true),
    [setImportSchemaOpen],
  );
  const closeImportSchema = useCallback(
    () => setImportSchemaOpen(false),
    [setImportSchemaOpen],
  );
  const importSources = useSelector(appSchemaSourcesSelector);
  const onImport = useCallback(
    ({ schema }) => {
      dispatch(
        datasetImportSchema({
          datasetId,
          versionId: isDraft ? versionId : undefined,
          base: isDraft ? undefined : versionId,
          schema,
        }),
      );
    },
    [dispatch, datasetId, versionId, isDraft],
  );
  const columns = get(['schema', 'columns'], version);
  return (
    <>
      <Table rowHeight={80}>
        <TableRow>
          {isEmpty(columns) && (
            <TableCell>
              <TableText>Create fields or import a schema</TableText>
            </TableCell>
          )}
          {!isEmpty(columns) &&
            columns.map((column) => (
              <TableCell>
                <TableText>
                  {column.label} ({column.key})
                </TableText>
              </TableCell>
            ))}

          <TableCell>
            <TableButton>
              <UtilityButton icon='plus' />
            </TableButton>
          </TableCell>
          <TableCell>
            <TableButton onClick={openImportSchema}>
              <UtilityButton icon='import-schema' />
            </TableButton>
          </TableCell>
        </TableRow>
      </Table>
      {importSchemaOpen && (
        <ImportSchemaModal
          onClose={closeImportSchema}
          onImport={onImport}
          sources={importSources}
        />
      )}
    </>
  );
};

DatasetTableProfile.propTypes = propTypes;
DatasetTableProfile.defaultProps = defaultProps;

export { DatasetTableProfile };
