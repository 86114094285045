import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash/fp';
import {
  Pill,
  composeEventHandlers,
  useEditState,
  useUpdateEffect,
} from '@kinesis/bungle';
import useGlobalEditContext from 'hooks/use-global-edit-context';
import {
  DataEntryInput,
  DataEntrySuffix,
  DataEntryText,
} from './data-entry.styles';

const propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  columnIndex: PropTypes.number,
  disabled: PropTypes.bool,
  format: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onDecrement: PropTypes.func,
  onFocus: PropTypes.func,
  onIncrement: PropTypes.func,
  onKeyDown: PropTypes.func,
  readOnly: PropTypes.bool,
  units: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  align: 'left',
  columnIndex: undefined,
  disabled: false,
  format: undefined,
  onBlur: undefined,
  onDecrement: undefined,
  onFocus: undefined,
  onIncrement: undefined,
  onKeyDown: undefined,
  readOnly: false,
  units: undefined,
  value: undefined,
};

const DataEntry = ({
  align,
  columnIndex,
  disabled,
  format,
  onBlur,
  onChange,
  onDecrement,
  onFocus,
  onIncrement,
  onKeyDown,
  readOnly,
  units,
  value,
}) => {
  const buttonRef = useRef();
  const inputRef = useRef();
  const [globalEditMode, setGlobalEditMode] = useGlobalEditContext();
  const [editMode, setEditMode] = useState(false);
  const valueStr = !isNil(value) ? String(value) : '';
  const [inputValue, setInputValue] = useEditState(
    [valueStr, onChange],
    editMode,
  );
  const [shouldButtonHaveFocus, setShouldButtonHaveFocus] = useState(false);

  const enableEditMode = useCallback(() => {
    setEditMode(true);
    setGlobalEditMode(true);
  }, [setGlobalEditMode]);

  const handleBlur = useCallback(
    (event) => {
      if (inputValue !== valueStr) {
        onChange(inputValue);
      }
      setEditMode(false);
      if (onBlur) {
        onBlur(event);
      }
    },
    [inputValue, onBlur, onChange, valueStr],
  );

  const handleButtonFocus = useCallback(() => {
    if (globalEditMode) {
      setEditMode(true);
    }
  }, [globalEditMode, setEditMode]);

  const handleButtonKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        setEditMode(true);
        setGlobalEditMode(true);
      }

      event.stopPropagation();
    },
    [setEditMode, setGlobalEditMode],
  );

  const handleChange = useCallback(
    (event) => {
      setInputValue(event.currentTarget.value);
    },
    [setInputValue],
  );

  const handleInputKeyDown = useMemo(
    () =>
      composeEventHandlers(onKeyDown, (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
          event.preventDefault();
          setEditMode(false);
          setGlobalEditMode(false);
          setShouldButtonHaveFocus(true);
          onChange(inputValue);
        } else if (event.key === 'Escape') {
          event.preventDefault();
          setInputValue(valueStr);
          setEditMode(false);
          setGlobalEditMode(false);
          setShouldButtonHaveFocus(true);
        } else if (event.key === 'ArrowDown' && onDecrement) {
          event.preventDefault();
          setInputValue(onDecrement);
        } else if (event.key === 'ArrowUp' && onIncrement) {
          event.preventDefault();
          setInputValue(onIncrement);
        }

        event.stopPropagation();
      }),
    [
      inputValue,
      onChange,
      onDecrement,
      onIncrement,
      onKeyDown,
      setGlobalEditMode,
      setInputValue,
      valueStr,
    ],
  );

  useEffect(() => {
    if (shouldButtonHaveFocus) {
      buttonRef.current.focus();
      setShouldButtonHaveFocus(false);
    }
  }, [shouldButtonHaveFocus]);

  useUpdateEffect(() => {
    if (editMode) {
      inputRef.current.select();
    }
  }, [editMode]);

  return editMode && !disabled && !readOnly ? (
    <DataEntryInput
      autoFocus
      columnIndex={columnIndex}
      ref={inputRef}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      onKeyDown={handleInputKeyDown}
      value={inputValue}
    />
  ) : (
    <DataEntryText
      ref={buttonRef}
      align={align}
      autoFocus
      columnIndex={columnIndex}
      disabled={disabled}
      onClick={!disabled ? enableEditMode : undefined}
      onFocus={!disabled ? handleButtonFocus : undefined}
      onKeyDown={!disabled ? handleButtonKeyDown : undefined}
      readOnly={readOnly}
    >
      {format ? format(value) : value}
      {units &&
        (readOnly ? (
          ` ${units}`
        ) : (
          <DataEntrySuffix>
            <Pill>{units}</Pill>
          </DataEntrySuffix>
        ))}
    </DataEntryText>
  );
};

DataEntry.propTypes = propTypes;
DataEntry.defaultProps = defaultProps;

export { DataEntry };
