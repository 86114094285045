import styled from 'styled-components';

const FlagGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 16px;
  & > * {
    padding: 4px;
  }
  & > *:hover {
    padding: 4px;
    background: ${({ theme }) => theme.color.blue2};
  }
`;

const FlagPane = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 16px;
`;

const AllFlag = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

export { FlagGrid, FlagPane, AllFlag };
