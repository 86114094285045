import PropTypes from 'prop-types';

// FUTURE: Replace with bungle component, was suggested that an Icon was going to be made instead.

const propTypes = {
  enabled: PropTypes.bool,
  color: PropTypes.string.isRequired,
};

const defaultProps = {
  enabled: true,
};

const Circle = ({ enabled, color }) => (
  <div
    style={{
      background: enabled ? color : 'rgba(0, 0, 0, 0.15)',
      borderRadius: '50%',
      flex: '0 0 auto',
      margin: '1px',
      height: '14px',
      width: '14px',
    }}
  />
);

Circle.propTypes = propTypes;
Circle.defaultProps = defaultProps;

export default Circle;
