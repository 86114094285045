import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import { get } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const mapToInts = (things) => things.map((thing) => parseInt(thing, 10));

const generateTargetPairs = (scenarios, targetScenarioIds) => {
  const targetScenarioIdsInt = mapToInts(targetScenarioIds);
  return targetScenarioIdsInt.map((targetScenarioId) => {
    const targetScenario = get(['named', targetScenarioId], scenarios);
    const targetScenarioKey =
      get(['draft', 'scenario'], targetScenario) ||
      get(['published', 'scenario'], targetScenario);
    return {
      targetScenarioId,
      targetScenarioKey,
    };
  });
};

const geographyUpdate = createAsyncThunk(
  'geography/update',
  async (
    { area, locationId, onUpdate, point, scenarioIds, workspaceId },
    { dispatch, getState },
  ) => {
    const api = createApiFromState({ dispatch, getState });
    const state = getState();
    const { scenarios } = workspaceSelector(state, { workspaceId });
    const geographyCreateResponse = await api.post(`/api/geography`, {
      point,
      area,
    });
    const workspacePatchResponse = await api.patch(
      `/api/workspace/${workspaceId}`,
      {
        actions: [
          {
            type: 'modify-attribute-value',
            value: {
              name: 'geography',
              locations: [locationId],
            },
          },
        ],
        patchOps: [
          {
            'save-attribute': {
              location: locationId,
              attribute: {
                key: 'geography',
                time_series: [
                  {
                    date: null,
                    year: null,
                    encoding: 'int64',
                    value: geographyCreateResponse.data.id,
                  },
                ],
              },
            },
          },
        ],
        targetPairs: generateTargetPairs(scenarios, scenarioIds),
      },
    );
    if (onUpdate) {
      onUpdate(geographyCreateResponse.data.id);
    }
    return {
      ...workspacePatchResponse.data,
      scenarioIds: mapToInts(scenarioIds),
    };
  },
);

export { geographyUpdate };
