import { useContext, useLayoutEffect, useState } from 'react';
import { formatDistance } from 'utils/dateUtils';
import { DateContext } from 'contexts';

function useDateDistance(date) {
  const [distance, setDistance] = useState();
  const baseDate = useContext(DateContext);
  const formattedDistance = date
    ? formatDistance(baseDate || new Date(), date)
    : undefined;

  // NOTE: React components should be a pure function of props and state
  // and ought therefore to avoid any randomness.
  // Since the current date introduces an element of randomness, distance
  // is factored away in component state and updated prior to every render.
  useLayoutEffect(() => {
    setDistance(formattedDistance);
  }, [formattedDistance]);

  return distance;
}

export default useDateDistance;
