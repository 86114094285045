import { createSlice } from '@reduxjs/toolkit';
import {
  assign,
  defaultTo,
  get,
  has,
  mapValues,
  multiply,
  pickBy,
  pipe,
  placeholder,
  set,
  update,
} from 'lodash/fp';
import {
  actions as genericAttributesActions,
  reducer as genericAttributesReducer,
} from 'reducers/genericAttributesReducer';

const { actions, reducer } = createSlice({
  name: 'usageAttributes',

  initialState: {
    activeStageKey: undefined,
    categories: {},
    entries: {},
    fields: {},
    stages: {},
  },

  reducers: {
    setDwellingsByCategory(state, action) {
      const { activeStageKey, categories, entries, defaultDate } = state;
      const { categoryKey, value } = action.payload;
      const distributionOfDwellings = get(
        [categoryKey, 'distributionOfDwellings'],
        categories,
      );
      const amounts = pipe(
        pickBy({ category: categoryKey }),
        mapValues(
          (entry) =>
            value *
            defaultTo(0, get(entry.key, distributionOfDwellings)) *
            defaultTo(0, get([entry.key, 'spacePerDwelling'], entries)),
        ),
        pickBy((val, key) => val > 0 || entries[key].visible),
      )(entries);

      return pipe(
        update(
          'entries',
          mapValues((entry) =>
            set('visible', entry.visible || has(entry.key, amounts), entry),
          ),
        ),
        update(
          ['stages', activeStageKey],
          pipe(
            update('date', defaultTo(defaultDate)),
            set('key', activeStageKey),
            update(
              'values',
              assign(
                placeholder,
                mapValues((amount) => ({ amount }), amounts),
              ),
            ),
          ),
        ),
      )(state);
    },
    setSpaceByCategory(state, action) {
      const { activeStageKey, categories, defaultDate, entries } = state;
      const { categoryKey, value } = action.payload;
      const distributionOfSpace = get(
        [categoryKey, 'distributionOfSpace'],
        categories,
      );
      const amounts = pipe(
        pickBy({ category: categoryKey }),
        mapValues(
          (entry) => value * defaultTo(0, get(entry.key, distributionOfSpace)),
        ),
        pickBy((val, key) => val > 0 || entries[key].visible),
      )(entries);

      return pipe(
        update(
          'entries',
          mapValues((entry) =>
            set('visible', entry.visible || has(entry.key, amounts), entry),
          ),
        ),
        update(
          ['stages', activeStageKey],
          pipe(
            update('date', defaultTo(defaultDate)),
            set('key', activeStageKey),
            update(
              'values',
              assign(
                placeholder,
                mapValues((amount) => ({ amount }), amounts),
              ),
            ),
          ),
        ),
      )(state);
    },
    setDwellings(state, action) {
      const { entries } = state;
      const { entryKey, value } = action.payload;

      return genericAttributesReducer(
        state,
        genericAttributesActions.setValue({
          entryKey,
          value: {
            amount: multiply(
              value,
              get([entryKey, 'spacePerDwelling'], entries),
            ),
          },
        }),
      );
    },
    setSpace(state, action) {
      const { entryKey, value } = action.payload;

      return genericAttributesReducer(
        state,
        genericAttributesActions.setValue({
          entryKey,
          value: { amount: value },
        }),
      );
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      genericAttributesActions.addStage,
      genericAttributesReducer,
    );
    builder.addCase(
      genericAttributesActions.deleteStage,
      genericAttributesReducer,
    );
    builder.addCase(
      genericAttributesActions.resetActiveStage,
      genericAttributesReducer,
    );
    builder.addCase(
      genericAttributesActions.setActiveStageKey,
      genericAttributesReducer,
    );
    builder.addCase(genericAttributesActions.setDate, genericAttributesReducer);
    builder.addCase(
      genericAttributesActions.setStages,
      genericAttributesReducer,
    );
    builder.addCase(
      genericAttributesActions.showEntry,
      genericAttributesReducer,
    );
  },
});

export { actions, reducer };
