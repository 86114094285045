import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { compact, get, has, map, pipe, reject } from 'lodash/fp';
import {
  Avatar,
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  DropdownMenuSeparator,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UtilityButton,
  ConfirmationDialog,
  Text,
} from '@kinesis/bungle';
import { Inline as OldInline } from '@kinesis/bungle/legacy';
import styled from 'styled-components';
import { RichList, RichListItem } from 'components/rich-list';
import ShareForm from 'components/workspace-sharing/ShareForm';
import { sharingPermissionAdd } from 'actions/sharingPermissionAdd';
import { sharingPermissionChange } from 'actions/sharingPermissionChange';
import { sharingPermissionRevoke } from 'actions/sharingPermissionRevoke';
import { organisationMembersFetch } from 'actions/organisationMembersFetch';
import { workspaceShareChangePermission } from 'actions/workspaceShareChangePermission';
import { workspaceShareReissue } from 'actions/workspaceShareReissue';
import { workspaceShareRevoke } from 'actions/workspaceShareRevoke';
import activeMembershipsSelector from 'selectors/activeMembershipsSelector';
import potentialShareesSelector from 'selectors/potentialShareesSelector';
import sortedAccessListSelector from 'selectors/sortedAccessListSelector';
import workspaceSharesSelector from 'selectors/workspaceSharesSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import { useSelectorWithProps } from 'hooks';

const StyledModalBody = styled(ModalBody)`
  height: calc(100% - 53px);
  overflow: auto;
`;

const Separator = styled.div`
  margin: 16px -16px;
  height: 1px;
  background-color: ${({ theme }) => theme.borderColor};
`;

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const WorkspaceSharingModal = ({ handleClose, workspaceId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    removeAccessConfirmationDialogData,
    setRemoveAccessConfirmationDialogData,
  ] = useState();
  const [
    cannotRemoveLastEditorDialogData,
    setCannotRemoveLastEditorDialogData,
  ] = useState(false);

  const { name: workspaceName } = useSelectorWithProps(
    pipe(workspaceSelector, get('data')),
    { workspaceId },
  );

  const { userId, firstName, lastName } = useSelector(get('auth'));
  const { isSharing } = useSelector(get('misc'));

  const sortedAccessList = useSelectorWithProps(sortedAccessListSelector, {
    workspaceId,
  });
  const workspaceShares = useSelectorWithProps(workspaceSharesSelector, {
    workspaceId,
  });
  const potentialSharees = useSelectorWithProps(potentialShareesSelector, {
    workspaceId,
  });
  const activeMemberships = useSelector(activeMembershipsSelector);

  const accessList = useMemo(
    () => sortedAccessList.concat(workspaceShares),
    [sortedAccessList, workspaceShares],
  );

  const memberOrganisationIds = useMemo(
    () => activeMemberships.map((m) => m.organisation_id),
    [activeMemberships],
  );

  useEffect(() => {
    activeMemberships.forEach((membership) => {
      dispatch(
        organisationMembersFetch({
          organisationId: membership.organisation_id,
        }),
      );
    });
  }, [dispatch, activeMemberships]);

  const editorIdentities = useMemo(
    () =>
      pipe(
        map((accessor) =>
          accessor.permissions.editor ? accessor.identity : undefined,
        ),
        compact,
      )(sortedAccessList),
    [sortedAccessList],
  );

  const noOtherEditors = useCallback(
    (identity) =>
      reject(
        (editorIdentity) => editorIdentity === identity.identity,
        editorIdentities,
      ).length === 0,
    [editorIdentities],
  );

  const setPermission = async (identity, permissionLevel) => {
    const { permissions } = await dispatch(
      sharingPermissionChange({
        workspaceId,
        sourceIdentity: identity.identity,
        permissionLevel,
      }),
    ).unwrap();

    if (!permissions?.editor) {
      navigate('/workspaces');
    }
  };

  const addPermission = useCallback(
    (sourceIdentities, permissionLevel) => {
      const emailData = {
        sharer: { first_name: firstName, last_name: lastName },
        shared_item: {
          label: workspaceName || 'New workspace',
          type: 'workspace',
          url: `https://app.kinesis.org/workspaces/${workspaceId}`,
        },
      };

      dispatch(
        sharingPermissionAdd({
          workspaceId,
          sourceIdentities,
          permissionLevel,
          emailData,
          memberOrganisationIds,
          sharer: `user/${userId}`,
        }),
      );
    },
    [
      dispatch,
      firstName,
      lastName,
      workspaceName,
      workspaceId,
      memberOrganisationIds,
      userId,
    ],
  );

  const handleNoOtherEditors = (identity, attemptingToRemove = false) => {
    const isYou = identity.identity === `user/${userId}`;
    const isOrganisation = identity.identity.startsWith('organisation/');
    const title = isYou
      ? `You are the only person that can edit “${workspaceName}”.`
      : `“${identity.name}” is the only ${
          isOrganisation ? 'organisation' : 'person'
        } that can edit this workspace.`;

    let bottomText;

    if (attemptingToRemove) {
      bottomText = `To remove ${
        isYou ? 'your' : 'their'
      } access, make sure another organisation or person can edit this workspace.`;
    } else {
      bottomText = `To change ${
        isYou ? 'your' : 'their'
      } access to view only, make sure another organisation or person can edit this workspace.`;
    }

    setCannotRemoveLastEditorDialogData({
      bottomText,
      title,
    });
  };

  const handleSetPermission = (identity, permission) => () => {
    if (permission === 'viewer' && noOtherEditors(identity)) {
      handleNoOtherEditors(identity);
      return;
    }
    setPermission(identity, permission);
  };

  const handleClickRevokeShare = (identity) => () => {
    if (noOtherEditors(identity)) {
      handleNoOtherEditors(identity, true);
      return;
    }

    const isYou = identity.identity === `user/${userId}`;
    const isOrganisation = identity.identity.startsWith('organisation/');

    const title = isYou
      ? `Remove your access to “${workspaceName}”?`
      : `Remove access for “${identity.name}”?`;

    let bottomText;
    if (isYou) {
      bottomText = 'You may no longer be able to access this workspace.';
    } else {
      bottomText = isOrganisation
        ? 'The people in this organisation may no longer be able to access this workspace.'
        : 'This person may no longer be able to access this workspace.';
    }

    setRemoveAccessConfirmationDialogData({
      bottomText,
      identity: identity.identity,
      title,
    });
  };

  const handleConfirmRemoveAccessConfirmationDialog = async () => {
    const { permissions } = await dispatch(
      sharingPermissionRevoke({
        workspaceId,
        sourceIdentity: removeAccessConfirmationDialogData.identity,
      }),
    ).unwrap();

    if (!permissions?.editor) {
      navigate('/workspaces');
    } else {
      setRemoveAccessConfirmationDialogData(undefined);
    }
  };

  const handleRevokeShare = useCallback(
    (share) => () => {
      dispatch(workspaceShareRevoke({ token: share.token }));
    },
    [dispatch],
  );

  const handleChangeSharePermission = useCallback(
    (share, permission) => () => {
      dispatch(
        workspaceShareChangePermission({
          token: share.token,
          permission,
          workspaceId,
        }),
      );
    },
    [dispatch, workspaceId],
  );

  const handleReissueSharePermission = useCallback(
    (share) => () => {
      dispatch(workspaceShareReissue({ token: share.token, workspaceId }));
    },
    [dispatch, workspaceId],
  );

  const generateActions = (i) => {
    const isEditor = i.permissions.editor;

    return (
      <DropdownMenu justify='end'>
        <DropdownMenuButton as={UtilityButton} dropdown magnitude='xsmall'>
          {isEditor ? 'Can edit' : 'Can view'}
        </DropdownMenuButton>

        <DropdownMenuList data-testid='member-action-dropdown'>
          <DropdownMenuItem
            onSelect={handleSetPermission(i, 'viewer')}
            variant={!isEditor ? 'accent' : undefined}
          >
            Can view
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={handleSetPermission(i, 'editor')}
            variant={isEditor ? 'accent' : undefined}
          >
            Can edit
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          <DropdownMenuItem
            onSelect={handleClickRevokeShare(i)}
            variant='danger'
          >
            Remove access
          </DropdownMenuItem>
        </DropdownMenuList>
      </DropdownMenu>
    );
  };

  const generateWorkspaceShareActions = (share) => {
    const isEditor = share.permission_level === 'editor';

    return (
      <DropdownMenu justify='end'>
        <DropdownMenuButton as={UtilityButton} dropdown magnitude='xsmall'>
          {isEditor ? 'Can edit' : 'Can view'}
        </DropdownMenuButton>

        <DropdownMenuList data-testid='member-action-dropdown'>
          <DropdownMenuItem
            onSelect={handleChangeSharePermission(share, 'viewer')}
            variant={!isEditor ? 'accent' : undefined}
          >
            Can view
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={handleChangeSharePermission(share, 'editor')}
            variant={isEditor ? 'accent' : undefined}
          >
            Can edit
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          {share.share_expired && (
            <DropdownMenuItem onSelect={handleReissueSharePermission(share)}>
              Resend invitation
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onSelect={handleRevokeShare(share)}
            variant='danger'
          >
            Remove access
          </DropdownMenuItem>
        </DropdownMenuList>
      </DropdownMenu>
    );
  };

  const headerLabel = 'Share workspace';

  return (
    <>
      <Modal
        width={496}
        height={496}
        onClose={handleClose}
        aria-label={headerLabel}
      >
        <ModalHeader>{headerLabel}</ModalHeader>
        <StyledModalBody>
          <ShareForm
            isSharing={isSharing}
            onSubmit={addPermission}
            potentialSharees={potentialSharees}
            workspaceShares={workspaceShares}
          />
          <Separator />
          <RichList>
            {accessList.map((item) => {
              if (!has('identity', item)) {
                return (
                  <RichListItem
                    data-testid='share-list-item'
                    key={item.email}
                    title={item.email}
                    subtitle={
                      item.share_expired ? (
                        <Text tone='critical'>Invitation expired</Text>
                      ) : (
                        <Text tone='secondary'>Pending invitation</Text>
                      )
                    }
                    avatar={
                      <Avatar
                        magnitude='small'
                        variant='individual'
                        showPlaceholderIfEmpty
                      />
                    }
                    actions={generateWorkspaceShareActions(item)}
                  />
                );
              }

              if (item.identity.startsWith('user/')) {
                return (
                  <RichListItem
                    data-testid='share-list-item'
                    key={item.identity}
                    title={
                      item.id === userId ? `${item.name} (You)` : item.name
                    }
                    subtitle={item.email}
                    actions={generateActions(item)}
                    avatar={
                      <Avatar magnitude='small' variant='individual'>
                        {item.firstName.slice(0, 1).toUpperCase()}
                        {item.lastName.slice(0, 1).toUpperCase()}
                      </Avatar>
                    }
                  />
                );
              }

              return (
                <RichListItem
                  key={item.identity}
                  data-testid='share-list-item'
                  title={item.name}
                  subtitle={
                    item.activeMembersCount > 1
                      ? `${item.activeMembersCount} members`
                      : '1 member'
                  }
                  avatar={
                    <Avatar
                      alt={`${item.name} logo`}
                      image={item.avatarUrl}
                      magnitude='small'
                      variant='organisation'
                    >
                      {item.name.slice(0, 1)}
                    </Avatar>
                  }
                  actions={generateActions(item)}
                />
              );
            })}
          </RichList>
        </StyledModalBody>
        <ModalFooter>
          <OldInline space='small' alignX='right'>
            <Button onClick={handleClose}>Done</Button>
          </OldInline>
        </ModalFooter>
      </Modal>
      {removeAccessConfirmationDialogData && (
        <ConfirmationDialog
          cancelText='Don’t remove'
          confirmText='Remove access'
          icon='question'
          title={removeAccessConfirmationDialogData.title}
          onCancel={() => setRemoveAccessConfirmationDialogData(undefined)}
          onConfirm={handleConfirmRemoveAccessConfirmationDialog}
          variant='danger'
          minWidth={320}
        >
          {removeAccessConfirmationDialogData.bottomText}
        </ConfirmationDialog>
      )}
      {cannotRemoveLastEditorDialogData && (
        <ConfirmationDialog
          confirmText='OK'
          icon='exclamation'
          minWidth={320}
          variant='primary'
          onCancel={() => setCannotRemoveLastEditorDialogData(undefined)}
          onConfirm={() => setCannotRemoveLastEditorDialogData(undefined)}
          showCancelButton={false}
          title={cannotRemoveLastEditorDialogData.title}
        >
          {cannotRemoveLastEditorDialogData.bottomText}
        </ConfirmationDialog>
      )}
    </>
  );
};

WorkspaceSharingModal.propTypes = propTypes;
WorkspaceSharingModal.defaultProps = defaultProps;

export default WorkspaceSharingModal;
