import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  z-index: 3;
`;

const Panel = styled.div`
  height: 36px;
  padding: 8px;
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 10;
`;

const Text = styled.span`
  flex-grow: 0;
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: rgba(255, 255, 255, 0.85);
`;

const PlaceLocationPanel = () => (
  <Wrapper>
    <Panel>
      <Text>Click on the map to place your location</Text>
    </Panel>
  </Wrapper>
);

export default PlaceLocationPanel;
