import { createSelector } from 'reselect';
import { first, get, includes, keys, map, pipe, values } from 'lodash/fp';
import Units from 'data/units';
import { immutableSort } from 'utils/charts/series';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import schemaSelector from './schemaSelector';
import statisticsSelector from './statisticsSelector';
import locationsSelector from './locationsSelector';
import workspaceInstantiationsSelector from './workspaceInstantiationsSelector';

const units = new Units();

const isContext = pipe(get('classification'), includes('context'));

const visualisationContextsSelector = createSelector(
  pipe(blockStateSelector, get('filters')),
  pipe(blockValueSelector),
  statisticsSelector,
  schemaSelector,
  locationsSelector,
  workspaceInstantiationsSelector,
  (blockFilters, blockValue, statistics, schema, locations, instantiations) => {
    if (!blockValue) {
      return [];
    }

    const groups =
      pipe(
        get([get(['visualisation'], blockValue), 'query', 'group']),
        map('column'),
      )(blockValue) || [];

    return values(schema)
      .filter(isContext)
      .filter((column) => !groups.includes(column.key))
      .map((sch) => {
        const unit = units.parseColumn(sch, locations, instantiations);
        const itemData = get(sch.key, statistics);
        const options = immutableSort(
          unit.compare,
          keys(get('histogram', itemData)),
        );

        return {
          key: sch.key,
          format: unit.formatCell,
          label: sch.label,
          options,
          type: 'select',
          value: get(sch.key, blockFilters) || first(options),
        };
      });
  },
);

export default visualisationContextsSelector;
