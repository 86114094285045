import { useRef } from 'react';

function useOnce(value) {
  const ref = useRef(false);
  ref.current = ref.current || Boolean(value);

  return ref.current;
}

export default useOnce;
