import { get, keyBy, pipe, set, unset } from 'lodash/fp';
import { createSlice } from '@reduxjs/toolkit';
import { appAdd } from 'actions/appAdd';
import { locationCreate } from 'actions/locationCreate';
import { scenarioCreate } from 'actions/scenarioCreate';
import { scenarioDelete } from 'actions/scenarioDelete';
import { scenarioSaveAsNew } from 'actions/scenarioSaveAsNew';
import { scenarioDuplicate } from 'actions/scenarioDuplicate';
import { scenarioUpdate } from 'actions/scenarioUpdate';
import { scenarioSend } from 'actions/scenarioSend';
import { scenarioDiscard } from 'actions/scenarioDiscard';
import { scenarioPublish } from 'actions/scenarioPublish';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { geographyUpdate } from 'actions/geographyUpdate';
import { workspaceInitialise } from 'actions/workspaceInitialise';
import { pathwayPublish } from 'actions/pathwayPublish';
import { tagUpdate } from 'actions/tagUpdate';

const keyById = keyBy('id');

const updateNamedScenarios = (state, action) => {
  const { scenarios } = action.payload;

  return set('named', { ...state.named, ...keyById(scenarios) }, state);
};

const { actions, reducer } = createSlice({
  name: 'scenarios',

  initialState: {
    named: {},
    readyState: undefined,
  },

  extraReducers: (builder) => {
    builder.addCase(workspaceInitialise, (state, action) => {
      const { scenarios } = action.payload;
      return set('named', { ...keyById(scenarios), ...state.named }, state);
    });

    builder.addCase(appAdd.fulfilled, updateNamedScenarios);
    builder.addCase(workspaceFetch.fulfilled, updateNamedScenarios);
    builder.addCase(workspacePatch.fulfilled, updateNamedScenarios);
    builder.addCase(geographyUpdate.fulfilled, updateNamedScenarios);

    builder.addCase(scenarioCreate.fulfilled.type, (state, action) => {
      const { id } = action.payload;

      return pipe(
        set(['named', id], action.payload),
        set(['named', id, 'nameInitialized'], action.payload.name_initialized),
      )(state);
    });

    builder.addCase(scenarioSaveAsNew.fulfilled.type, (state, action) => {
      const { id } = action.payload;

      return pipe(
        set(['named', id], action.payload),
        set(['named', id, 'nameInitialized'], action.payload.name_initialized),
      )(state);
    });

    builder.addCase(scenarioDuplicate.fulfilled.type, (state, action) => {
      const { id } = action.payload;

      return pipe(
        set(['named', id], action.payload),
        set(['named', id, 'nameInitialized'], action.payload.name_initialized),
      )(state);
    });

    builder.addCase(scenarioUpdate.pending.type, (state, action) => {
      const { scenarioId, fields } = action.meta.arg;

      return {
        ...state,
        named: {
          ...state.named,
          [scenarioId]: {
            ...state.named[scenarioId],
            ...fields,
            nameInitialized: !!get('name', fields),
          },
        },
      };
    });

    builder.addCase(scenarioUpdate.fulfilled.type, (state, action) => {
      const { scenarioId, name, description, nameInitialized } = action.payload;

      return pipe(
        set(['named', scenarioId, 'name'], name),
        set(['named', scenarioId, 'nameInitialized'], nameInitialized),
        set(['named', scenarioId, 'description'], description),
      )(state);
    });

    builder.addCase(scenarioDelete.fulfilled, (state, action) => {
      const { scenarioId } = action.payload;

      return unset(['named', scenarioId], state);
    });

    builder.addCase(scenarioSend.fulfilled, (state, action) => {
      const { scenario } = action.payload;

      return set(['named', scenario.id, 'draft'], scenario.draft, state);
    });

    builder.addCase(locationCreate.fulfilled, (state, action) => {
      const { scenario } = action.payload.scenario;

      return set(['named', scenario.id, 'draft'], scenario.draft, state);
    });

    builder.addCase(tagUpdate.fulfilled, (state, action) => {
      const { referent, scenarioId, scenarioState, tagName } = action.payload;

      return pipe(
        set(['named', scenarioId, scenarioState, 'referent'], referent),
        set(['named', scenarioId, scenarioState, 'scenario'], referent),
        set(['named', scenarioId, scenarioState, 'tagName'], tagName),
      )(state);
    });

    builder.addCase(scenarioDiscard.pending, (state, action) => {
      const { scenarioId } = action.meta.arg;

      return unset(['named', scenarioId, 'draft'], state);
    });

    builder.addCase(scenarioPublish.fulfilled, (state, action) => {
      const { scenarioId } = action.meta.arg;
      const data = action.payload;

      if (data.conflict) {
        return state;
      }

      const newPublishedScenario = pipe(
        set('tag', data),
        set('count', get('count', data)),
        set('tagName', get('tag', data)),
        set('createdAt', get('created_at', data)),
        set('updatedAt', get('updated_at', data)),
        unset('basedOn'),
      )(get(['named', scenarioId, 'draft'], state));

      return pipe(
        set(['named', scenarioId, 'published'], newPublishedScenario),
        unset(['named', scenarioId, 'draft']),
      )(state);
    });

    builder.addCase(pathwayPublish.fulfilled.type, (state, action) => {
      const scenario = get('scenario', action.payload);

      return pipe(
        set(['named', scenario.id], scenario),
        set(['named', scenario.id, 'isDefault'], scenario.is_default),
      )(state);
    });

    builder.addCase(pathwayPublish.pending.type, (state, action) => {
      const scenario = get('scenario', action.meta.arg);
      const type = get('type', scenario);

      switch (type) {
        case 'existing':
          return unset(['named', scenario.id], state);
        case 'new':
        default:
          return state;
      }
    });
  },
});

export { actions, reducer };
