import { createSelector } from 'reselect';
import { defaultTo, curry, filter, get, identity, map, pipe } from 'lodash/fp';

import activeScenarioCapsuleDataSelector from 'selectors/activeScenarioCapsuleDataSelector';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import locationsSelector from 'selectors/locationsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import Units from 'data/units';
import { calculateBounds } from 'utils/charts/series';

const units = new Units();
const yearSelector = makeAggregationFilterSelector('year');
const immutableSort = curry((f, arr) => arr.slice().sort(f));
const defaultData = [];

const multiDimensionCapsuleDataSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  pipe(blockValueSelector, get('chart')),
  locationsSelector,
  yearSelector,
  workspaceInstantiationsSelector,
  pipe(activeScenarioCapsuleDataSelector, defaultTo(defaultData)),
  (schema, chart, locations, year, instantiations, data) => {
    const { x, y, time, type } = chart;

    const timeColumn = get('column', time);
    const xUnit = units.parseColumn(
      schema[x.column],
      locations,
      instantiations,
    );
    const unitFormatterPicker = (column) =>
      get(
        'format',
        units.parseColumn(schema[column], locations, instantiations),
      );

    const isStacked = ['column', 'stacked-area', 'stacked-column'].includes(
      type,
    );

    return {
      yBounds: calculateBounds(
        data,
        timeColumn,
        x.column,
        map('column', y.series),
        isStacked,
      ),
      data: pipe(
        map((series) => ({
          name: get([series.column, 'label'], schema),
          data: pipe(
            timeColumn && year ? filter({ [timeColumn]: year }) : identity,
            map((d) => ({ x: d[x.column], y: d[series.column] })),
            immutableSort((a, b) => xUnit.compare(a.x, b.x)),
          )(data),
          unitFormatter: unitFormatterPicker(series.column),
        })),
      )(y.series),
      xUnit,
    };
  },
);

export default multiDimensionCapsuleDataSelector;
