import styled from 'styled-components';

const Separator = styled.hr`
  background-clip: content-box;
  background-color: ${({ theme }) => theme.borderColor};
  border: 0;
  flex: 0 0 auto;
  height: 1px;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 16px;
  width: 656px;
`;

export { Separator };
