import PropTypes from 'prop-types';
import { get, pipe, uniq } from 'lodash/fp';
import Units from 'data/units';
import { returnColumnsToUnselectedState } from 'utils/charts/column';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import locationsSelector from 'selectors/locationsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import ChartImpl from './ChartImpl';

const units = new Units();

const propTypes = {
  blockId: PropTypes.string.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const DualStackedColumnChart = ({
  blockId,
  privacy,
  workspaceId,
  ...restProps
}) => {
  const locations = useSelectorWithProps(locationsSelector, {
    public: privacy === 'public',
    workspaceId,
  });
  const instantiations = useSelectorWithProps(workspaceInstantiationsSelector, {
    public: privacy === 'public',
    workspaceId,
  });
  const schema = useSelectorWithProps(pipe(blockStateSelector, get('schema')), {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });
  const visualisationType = get('visualisation', blockValue);
  const x = get([visualisationType, 'x'], blockValue);
  const y = get([visualisationType, 'y'], blockValue);

  // TODO: Convert this to new selectors when finishing dual stacked column chart
  const data = [];

  const xunit = units.parseColumn(schema[x.column], locations, instantiations);

  const options = {
    chart: { type: 'dual-stacked-column' },
    'dual-stacked-column': { stacking: 'normal' },
    yAxis: [
      { title: { text: y.left.title } },
      {
        title: { text: y.right.title },
        opposite: true,
      },
    ],
    series: [],
    xAxis: {
      categories: uniq(data.map((datum) => xunit.format(datum[x.column]))),
      crosshair: { color: '#e9f3f7', zIndex: -1 },
      title: { text: x.title },
    },
    tooltip: {
      shared: true,
    },
    plotOptions: {
      series: {
        marker: { enabled: false, symbol: 'circle' },
      },
    },
  };

  return (
    <ChartImpl
      formatLabel={xunit.format}
      onExternalClick={returnColumnsToUnselectedState}
      options={options}
      {...restProps}
    />
  );
};

DualStackedColumnChart.propTypes = propTypes;
DualStackedColumnChart.defaultProps = defaultProps;

export default DualStackedColumnChart;
