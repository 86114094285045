import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useEventListener, useUpdateEffect } from '@kinesis/bungle';
import {
  TableResizeBarImpl,
  TableResizeBarOverlay,
} from './table-resize-bar.styles';

const propTypes = {
  columnIndex: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  minOffset: PropTypes.number.isRequired,
  onReset: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
};

const TableResizeBar = ({
  columnIndex,
  left,
  minOffset,
  onReset,
  onResize,
}) => {
  const [isResizing, setResizing] = useState(false);
  const [beginX, setBeginX] = useState(0);
  const [endX, setEndX] = useState(0);
  const diffX = Math.max(minOffset, endX - beginX);

  const handleReset = useCallback(() => {
    onReset(columnIndex);
  }, [columnIndex, onReset]);

  const handleMouseDown = useCallback((event) => {
    setResizing(true);
    setBeginX(event.pageX);
    setEndX(event.pageX);
  }, []);

  useEventListener(
    'mousemove',
    useCallback(
      (event) => {
        if (isResizing) {
          setEndX(event.pageX);
        }
      },
      [isResizing],
    ),
  );

  useEventListener(
    'mouseup',
    useCallback(() => {
      setResizing(false);
    }, []),
  );

  useUpdateEffect(() => {
    if (!isResizing) {
      onResize(columnIndex, diffX);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResizing]);

  return (
    <>
      {isResizing && <TableResizeBarOverlay />}
      <TableResizeBarImpl
        left={left}
        onDoubleClick={handleReset}
        onMouseDown={handleMouseDown}
        highlight={isResizing}
        style={{
          transform: isResizing ? `translateX(${diffX}px)` : undefined,
        }}
      />
    </>
  );
};

TableResizeBar.propTypes = propTypes;

export { TableResizeBar };
