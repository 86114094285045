import styled from 'styled-components';

const DataGridWrapper = styled('div').attrs(({ label }) => ({
  'aria-label': label,
  role: 'grid',
}))(({ theme }) => ({
  borderColor: theme.borderColor,
  borderStyle: 'solid',
  borderWidth: '1px 0 0 1px',
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.default,
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.default,
  maxHeight: '100%',
  overflow: 'auto',
  position: 'relative',
  '&[data-grid-border-clip-top="true"]': {
    borderTopWidth: 0,
  },
  '&[data-grid-border-clip-left="true"]': {
    borderLeftWidth: 0,
  },
}));

export { DataGridWrapper };
