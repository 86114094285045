import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetFetch = createAsyncThunk(
  'dataset/fetch',
  async ({ datasetId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.get(`/api/datasets/${datasetId}`);
    return response.data;
  },
);

export { datasetFetch };
