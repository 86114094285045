import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash/fp';
import { PathwaysInputs } from 'components/pathways-inputs';
import { PathwaysToolbar } from 'components/pathways-toolbar';
import { PathwaysVisualisations } from 'components/pathways-visualisations';
import { PathwaysWrapper } from './pathways.styles';

const propTypes = {};
const defaultProps = {};

const Pathways = () => {
  const licences = useSelector(get('licences'));
  if (!licences.pathways) {
    return <Navigate to='/error/not-found' />;
  }

  return (
    <PathwaysWrapper>
      <PathwaysToolbar />
      <PathwaysInputs />
      <PathwaysVisualisations />
    </PathwaysWrapper>
  );
};

Pathways.propTypes = propTypes;
Pathways.defaultProps = defaultProps;

export { Pathways };
