import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import { get } from 'lodash/fp';

import workspaceSelector from 'selectors/workspaceSelector';

const scenarioDuplicate = createAsyncThunk(
  'scenario/duplicate',
  async ({ namedScenarioId, workspaceId }, { dispatch, getState }) => {
    const state = getState();
    const api = createApiFromState({ dispatch, getState });
    const workspace = workspaceSelector(state, { workspaceId });
    const sourceScenario =
      get(['scenarios', 'named', namedScenarioId], workspace) || {};
    const scenarioState = get('draft', sourceScenario) ? 'draft' : 'published';

    const response = await api.post(`/api/scenario`, {
      scenarioKey: get([scenarioState, 'scenario'], sourceScenario),
      workspaceId: get(['data', 'id'], workspace),
      name: `${sourceScenario.name} (Duplicated)`,
      nameInitialized: true,
      description: sourceScenario.description,
    });

    return response.data;
  },
);

export { scenarioDuplicate };
