import styled from 'styled-components';

const ToolboxPanelWrapper = styled('div')(
  {
    flex: '1 1 0',
    overflowY: 'auto',
    position: 'relative',
  },
  ({ scrolled }) =>
    scrolled
      ? {
          maskImage: `linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0,
            rgba(0, 0, 0, 0) 8px,
            black 40px
          )`,
        }
      : undefined,
);

export { ToolboxPanelWrapper };
