import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Icon,
  Inline,
  InlineItem,
  Select,
  TokenInput,
  TokenInputListItem,
} from '@kinesis/bungle';
import {
  filter,
  find,
  isEmpty,
  isNil,
  map,
  partition,
  pipe,
  sortBy,
  uniq,
} from 'lodash/fp';

const propTypes = {
  isSharing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  potentialSharees: PropTypes.array,
  workspaceShares: PropTypes.array,
};

const defaultProps = {
  potentialSharees: [],
  workspaceShares: [],
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ShareForm = ({
  isSharing,
  onSubmit,
  potentialSharees,
  workspaceShares,
}) => {
  const [permissionOption, setPermissionOption] = useState('viewer');
  const [selectedIds, setSelectedIds] = useState([]);

  const userAlreadyHasPendingShare = useCallback(
    (email) => !isNil(find({ email })(workspaceShares)),
    [workspaceShares],
  );

  // If the user types in an email that belongs to a potential sharee,
  // add the sharee token instead of free text token
  const handleChangeTokens = useCallback(
    (tokens) => {
      const fixedTokens = pipe(
        map((token) => {
          if (!emailRegex.test(token)) {
            return token;
          }

          const matchingUser = find({ email: token }, potentialSharees);
          return matchingUser ? matchingUser.identity : token;
        }),
        uniq,
      )(tokens);

      setSelectedIds(fixedTokens);
    },
    [potentialSharees],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (selectedIds.length) {
        // Do not submit any invalid tokens
        const validSelectedIds = filter((token) => {
          const isEmail = emailRegex.test(token);
          const isPrincipal =
            !isEmail &&
            (token.startsWith('user/') || token.startsWith('organisation/'));

          return (isEmail && !userAlreadyHasPendingShare(token)) || isPrincipal;
        })(selectedIds);
        if (!isEmpty(validSelectedIds)) {
          onSubmit(validSelectedIds, permissionOption);
        }
        setSelectedIds([]);
      }
    },
    [userAlreadyHasPendingShare, onSubmit, permissionOption, selectedIds],
  );

  const permissionOptions = [
    { label: 'Can view', value: 'viewer' },
    { label: 'Can edit', value: 'editor' },
  ];

  const options = useMemo(() => {
    const [orgs, users] = pipe(
      filter((s) => !!s.identity),
      partition((sharee) => sharee.identity.startsWith('organisation/')),
    )(potentialSharees);

    const orgOptions = pipe(
      sortBy((o) => o.name),
      map((o) => ({
        id: o.identity,
        label: o.name,
        tooltip: o.name,
        avatarUrl: o.avatarUrl,
      })),
    )(orgs);

    const userOptions = pipe(
      sortBy((u) => `${u.first_name} ${u.last_name}`),
      map((u) => ({
        id: u.identity,
        label: `${u.first_name} ${u.last_name}`,
        tooltip: u.email,
      })),
    )(users);

    return [...orgOptions, ...userOptions];
  }, [potentialSharees]);

  const validateTextInput = useCallback(
    (id) => {
      if (!emailRegex.test(id)) {
        return 'Not a valid email';
      }

      if (userAlreadyHasPendingShare(id)) {
        return 'Workspace has already been shared to this email address';
      }

      return false;
    },
    [userAlreadyHasPendingShare],
  );

  return (
    <Inline space='small'>
      <InlineItem sizing='fill-container'>
        <TokenInput
          allowFreeTextTokens
          autoFocus
          onChange={handleChangeTokens}
          options={options}
          placeholder='Invite person…'
          renderListItem={(item) => (
            <TokenInputListItem
              disabled={item.disabled}
              prefix={
                item.id.startsWith('organisation/') ? (
                  <Avatar
                    magnitude='xsmall'
                    variant='organisation'
                    alt={`${item.label} logo`}
                    image={item.avatarUrl}
                  >
                    {item.label.slice(0, 1)}
                  </Avatar>
                ) : (
                  <Avatar magnitude='xsmall' variant='individual'>
                    {item.label.split(' ')[0].slice(0, 1).toUpperCase()}
                    {item.label.split(' ')[1].slice(0, 1).toUpperCase()}
                  </Avatar>
                )
              }
              tooltip={item.tooltip}
              value={item.id}
            >
              {item.label}
            </TokenInputListItem>
          )}
          renderNewListItem={(text) => (
            <TokenInputListItem
              key={text}
              prefix={<Icon magnitude='small' type='mail' />}
              value={text}
            >
              {text}
            </TokenInputListItem>
          )}
          tooltipKey='tooltip'
          valueKey='id'
          values={selectedIds}
          validateInput={validateTextInput}
        />
      </InlineItem>
      <InlineItem>
        <Select
          onChange={(value) => setPermissionOption(value)}
          options={permissionOptions}
          value={permissionOption}
        />
      </InlineItem>
      <InlineItem>
        <Button
          loading={isSharing}
          onClick={handleSubmit}
          variant='action'
          display='block'
        >
          Share
        </Button>
      </InlineItem>
    </Inline>
  );
};

ShareForm.propTypes = propTypes;
ShareForm.defaultProps = defaultProps;

export default ShareForm;
