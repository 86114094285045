import { createSelector } from 'reselect';
import { get, lowerCase, map, values, pipe, sortBy } from 'lodash/fp';

const getToLowerCase = (key) => pipe(get(key), lowerCase);

const sortedAppsSelector = createSelector(
  get(['apps', 'entities']),
  pipe(
    values,
    map((app) => ({ ...app, publisher: get(['owner', 'label'], app) })),
    sortBy([getToLowerCase('publisher'), getToLowerCase('label')]),
  ),
);

export default sortedAppsSelector;
