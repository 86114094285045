import { datasetCreate } from 'actions/datasetCreate';
import { datasetFetch } from 'actions/datasetFetch';
import { datasetVersionCreate } from 'actions/datasetVersionCreate';
import { datasetVersionFetch } from 'actions/datasetVersionFetch';
import { datasetVersionPublish } from 'actions/datasetVersionPublish';
import { datasetVersionUpload } from 'actions/datasetVersionUpload';
import { datasetImportSchema } from 'actions/datasetImportSchema';

import { createSlice } from '@reduxjs/toolkit';
import { concat, get, reduce, update, set, unset, reject, eq } from 'lodash/fp';

const normaliseSummary = (version) => ({
  id: version.id,
  dataset: version.dataset,
  state: version.state,
  base: version.base,
  publishedAs: version.published_as,
  pubishedAt: version.published_at,
  createdAt: version.created_at,
  updatedAt: version.updated_at,
});

const normaliseDetail = (version) => ({
  ...normaliseSummary(version),
  changeset: version.changeset,
  schema: version.schema,
  type: version.type,
});

const mergeVersions = (state, versions) =>
  reduce(
    (accumulator, version) =>
      update(
        [version.id],
        (current) => ({
          ...current,
          ...normaliseSummary(version),
        }),
        accumulator,
      ),
    state,
    versions,
  );

const { reducer, actions } = createSlice({
  name: 'datasetVersions',
  initialState: {},

  reducers: {
    trackUploads: (state, action) =>
      update(
        [action.payload.versionId, 'uploads'],
        concat(action.payload.keys),
        state,
      ),
  },

  extraReducers: (builder) => {
    builder.addCase(datasetVersionFetch.pending, (state, action) =>
      set([action.meta.arg.versionId, 'lifecycle'], 'fetching', state),
    );

    builder.addCase(datasetVersionFetch.rejected, (state, action) =>
      unset([action.meta.arg.versionId, 'lifecycle'], state),
    );

    builder.addCase(datasetVersionFetch.fulfilled, (state, action) =>
      set(
        action.meta.arg.versionId,
        {
          lifecycle: 'fetched',
          uploads: [],
          ...normaliseDetail(action.payload),
        },
        state,
      ),
    );

    builder.addCase(datasetFetch.fulfilled.type, (state, action) =>
      mergeVersions(state, action.payload.versions),
    );

    builder.addCase(datasetCreate.fulfilled.type, (state, action) =>
      mergeVersions(state, action.payload.versions),
    );

    builder.addCase(datasetVersionCreate.fulfilled.type, (state, action) =>
      set(
        [action.payload.id],
        {
          lifecycle: 'fetched',
          ...normaliseDetail(action.payload),
          uploads: action.meta.arg.uploads || [],
        },
        state,
      ),
    );

    builder.addCase(datasetImportSchema.fulfilled.type, (state, action) =>
      set(
        [action.payload.id],
        {
          lifecycle: 'fetched',
          ...normaliseDetail(action.payload),
          uploads: [],
        },
        state,
      ),
    );

    builder.addCase(datasetVersionPublish.fulfilled.type, (state, action) =>
      set(
        [action.payload.id],
        {
          lifecycle: 'fetched',
          ...normaliseDetail(action.payload),
          uploads: [],
        },
        state,
      ),
    );

    builder.addCase(datasetVersionUpload.fulfilled.type, (state, action) =>
      set(
        [action.payload.id],
        {
          lifecycle: 'fetched',
          ...normaliseDetail(action.payload),
          uploads: reject(
            eq(action.meta.arg.key),
            get([action.payload.id, 'uploads'], state),
          ),
        },
        state,
      ),
    );
  },
});

export { reducer, actions };
