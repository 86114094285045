import { createSelector } from 'reselect';
import { pipe, get, unset, first, values, map } from 'lodash/fp';
import attributesSelector from './attributesSelector';
import workspaceGeographiesSelector from './workspaceGeographiesSelector';

const scenarioGeographySelector = createSelector(
  pipe(attributesSelector, get('values')),
  workspaceGeographiesSelector,
  (locations, geographies) =>
    pipe(
      unset('0'),
      values,
      map((location) => ({
        id: get('id', location),
        geography: get(
          pipe(
            get(['attributes', 'geography', 'time_series']),
            first,
            get('value'),
          )(location),
          geographies,
        ),
      })),
    )(locations),
);

export default scenarioGeographySelector;
