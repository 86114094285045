import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const locationRename = createAsyncThunk(
  'locations/locationRename',
  async ({ label, layer, locationId, workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    await api.post(`/api/workspace/${workspaceId}/locations/${locationId}`, {
      label,
      layer,
    });
  },
);

export { locationRename };
