import { createSlice } from '@reduxjs/toolkit';
import { update, filter, reduce, set, keyBy, pipe } from 'lodash/fp';
import { FETCHING, COMPLETE } from 'constants/status';
import { statisticsFetch } from 'actions/statisticsFetch';

const { reducer, actions } = createSlice({
  name: 'statistics',
  initialState: { status: {} },

  extraReducers: (builder) => {
    builder.addCase(statisticsFetch.pending.type, (state, action) =>
      update('status', (status) =>
        reduce(
          (accumulator, { scenarioKey, table }) =>
            set([table, scenarioKey], FETCHING, accumulator),
          status,
          action.meta.arg.scenarios,
        ),
      )(state),
    );

    builder.addCase(statisticsFetch.fulfilled.type, (state, action) =>
      pipe(
        filter({ result: 'fetched' }),
        reduce(
          (accumulator, { scenarioKey, table, statistics }) =>
            pipe(
              set(['status', table, scenarioKey], COMPLETE),
              set([table, scenarioKey], keyBy('column', statistics)),
            )(accumulator),
          state,
        ),
      )(action.payload),
    );
  },
});

export { reducer, actions };
