import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const serviceAccountKeyCreate = createAsyncThunk(
  'service-account-key/create',
  async ({ id }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/service-accounts/${id}/keys`, {});
    return response.data;
  },
);

export { serviceAccountKeyCreate };
