import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash/fp';
import { appsList } from 'actions/appsList';

const { actions, reducer } = createSlice({
  name: 'apps',

  initialState: {
    currentRequestId: undefined,
    entities: {},
    error: null,
    loading: 'idle',
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(appsList.pending, (state, action) =>
      !state.currentRequestId
        ? {
            ...state,
            currentRequestId: action.meta.requestId,
            loading: 'pending',
          }
        : state,
    );

    builder.addCase(appsList.fulfilled, (state, action) =>
      state.currentRequestId === action.meta.requestId
        ? {
            ...state,
            currentRequestId: undefined,
            entities: keyBy('id', action.payload),
            loading: 'idle',
          }
        : state,
    );

    builder.addCase(appsList.rejected, (state, action) =>
      state.currentRequestId === action.meta.requestId
        ? {
            ...state,
            currentRequestId: undefined,
            error: action.meta.error,
            loading: 'idle',
          }
        : state,
    );
  },
});

export { actions, reducer };
