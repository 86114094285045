import { createSelector } from 'reselect';
import {
  filter,
  get,
  groupBy,
  identity,
  isArray,
  map,
  pick,
  pipe,
  toPairs,
} from 'lodash/fp';
import {
  immutableSort,
  calculateBounds,
  calculateSimpleBounds,
} from 'utils/charts/series';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import locationsSelector from 'selectors/locationsSelector';
import publicBlockYearSelector from 'selectors/publicBlockYearSelector';
import scenarioKeySelector from 'selectors/scenarioKeySelector';
import Units from 'data/units';
import publicVisualisationDataSelector from 'selectors/publicVisualisationDataSelector';

const publicChartVisualisationDataSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  pipe(blockValueSelector, get('chart')),
  publicVisualisationDataSelector,
  locationsSelector,
  scenarioKeySelector,
  publicBlockYearSelector,
  workspaceInstantiationsSelector,
  (schema, chart, data, locations, scenario, time, instantiations) => {
    const { size, type, x, y } = chart;
    const filteredData = pipe(
      filter({ scenario }),
      time ? filter({ time }) : identity,
    )(data);
    const units = new Units();
    const xUnit = units.parseColumn(
      schema[x.column],
      locations,
      instantiations,
    );
    const isStacked = ['column', 'stacked-area', 'stacked-column'].includes(
      type,
    );

    if (isArray(y.series)) {
      const chartData = pipe(
        groupBy('series'),
        toPairs,
        map(([key, dataPoints]) => ({
          name: get([key, 'label'], schema),
          data: map(pick(['x', 'y']), dataPoints),
          unitFormatter: get(
            'format',
            units.parseColumn(schema[key], locations, instantiations),
          ),
        })),
      )(filteredData);

      return {
        data: chartData,
        xUnit,
        yBounds: calculateBounds(data, 'time', 'x', ['y'], isStacked),
      };
    }

    if (get(['series', 'secondary'], y)) {
      const yUnit = units.parseColumn(
        schema[y.series.column],
        locations,
        instantiations,
      );
      const ySecondaryUnit = units.parseColumn(
        schema[y.series.secondary],
        locations,
        instantiations,
      );
      const chartData = pipe(
        filter({ scenario }),
        groupBy('series'),
        toPairs,
        map(([name, dataPoints]) => ({
          name,
          data: map(pick(['x', 'y']), dataPoints),
          unitFormatter: get('format', yUnit),
          nameFormatter: get('format', ySecondaryUnit),
        })),
        immutableSort((a, b) => ySecondaryUnit.compare(a.name, b.name)),
      )(filteredData);

      return {
        data: chartData,
        xUnit,
        yBounds: calculateBounds(data, 'time', 'x', ['y'], isStacked),
      };
    }

    const yUnit = units.parseColumn(
      schema[y.column],
      locations,
      instantiations,
    );
    const sizeUnit = size
      ? units.parseColumn(schema[size.column], locations)
      : undefined;

    return {
      bounds: {
        x: calculateSimpleBounds(data, 'x'),
        y: calculateSimpleBounds(data, 'y'),
      },
      data: [
        {
          name: size ? get([size.column, 'label'], schema) : undefined,
          data: pipe(
            map((row) => ({
              x: row.x,
              y: row.y,
              z: row.size,
              name: row.label,
              label: row.label,
              dataId: row.dataId,
            })),
            immutableSort((a, b) => b.z - a.z),
          )(filteredData),
          unitFormatter: {
            x: xUnit.format,
            y: yUnit.format,
            z: get('format', sizeUnit),
          },
        },
      ],
    };
  },
);

export default publicChartVisualisationDataSelector;
