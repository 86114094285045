import styled from 'styled-components';

const TableCellWrapper = styled('div')(
  ({ theme }) => ({
    alignContent: 'center',
    borderColor: theme.borderColor,
    borderStyle: 'solid',
    borderWidth: '0 1px 1px 0',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    '&:hover': {
      zIndex: 2,
    },
    '&:focus-within': {
      zIndex: 3,
    },
    '*:last-child > &': {
      borderBottomWidth: 0,
    },
  }),
  ({ showEndBorder }) => ({
    '&:last-child': {
      borderRightWidth: showEndBorder ? 1 : 0,
    },
  }),
  ({ appearance, theme }) =>
    ({
      accent: { backgroundColor: theme.color.blue1 },
      dark: { backgroundColor: '#fafafa' },
      light: { backgroundColor: 'white' },
    })[appearance],
  ({ variant, theme }) =>
    ({
      heading: {
        color: theme.color.gray9,
        fontWeight: theme.fontWeight.medium,
      },
      default: {
        fontWeight: theme.fontWeight.normal,
      },
    })[variant],
  ({ width }) => ({ width }),
  ({ stickToX }) =>
    typeof stickToX === 'number'
      ? {
          left: stickToX,
          position: 'sticky',
          zIndex: 10,
          '&:hover': {
            zIndex: 12,
          },
          '&:focus-within': {
            zIndex: 13,
          },
        }
      : undefined,
);

const TableCellInner = styled('div')(
  {
    display: 'flex',
    height: 'calc(100% + 2px)',
    marginLeft: -1,
    marginTop: -1,
    width: 'calc(100% + 2px)',
  },
  ({ align }) =>
    ({
      left: {
        justifyContent: 'flex-start',
        textAlign: 'left',
      },
      center: {
        justifyContent: 'center',
        textAlign: 'center',
      },
      right: {
        justifyContent: 'flex-end',
        textAlign: 'right',
      },
    })[align],
);

export { TableCellWrapper, TableCellInner };
