import {
  assign,
  cond,
  defaultTo,
  entries,
  filter,
  find,
  flatMap,
  get,
  groupBy,
  has,
  identity,
  isEmpty,
  isNil,
  last,
  keyBy,
  map,
  mapValues,
  mergeAll,
  multiply,
  omit,
  omitBy,
  pipe,
  reject,
  size,
  slice,
  some,
  sortBy,
  stubTrue,
  sum,
  sumBy,
  values,
} from 'lodash/fp';
import { format, isAfter, parseISO } from 'date-fns/fp';
import { insertRandomKeys } from 'utils/dataUtils';
import { sortByInstance } from 'data/attributes';
import { valueFromField } from 'utils/settingsModal';

const mapValuesWithKey = mapValues.convert({ cap: false });
const mapWithIndex = map.convert({ cap: false });

function normalizeAttributeEntities(data) {
  // FIX: This is guessing at structure based on fields
  // rather then determining based on attribute metadata,
  // there will be cases where it can/will guess wrong and
  // needs to be updated to consult attributes details.

  return pipe(
    flatMap((item) =>
      flatMap(
        (attrItem) =>
          flatMap(
            cond([
              // Implication: quantified + structured
              [
                has('fields'),
                (seriesItem) => ({
                  date: get('date', seriesItem),
                  definition: get('key', attrItem),
                  entry: null,
                  locationId: item.id,
                  values: mapValues('value', get('fields', seriesItem)),
                }),
              ],
              // Implication: itemised wo/categories
              [
                has('entries'),
                (seriesItem) =>
                  map(
                    (entryItem) => ({
                      date: get('date', seriesItem),
                      definition: get('key', attrItem),
                      entry: get('key', entryItem),
                      locationId: item.id,
                      values: {
                        amount: get(['amount', 'value'], entryItem),
                      },
                    }),
                    get('entries', seriesItem),
                  ),
              ],
              // Implication: itemised w/categories
              [
                has('categories'),
                (seriesItem) =>
                  pipe(
                    get('categories'),
                    flatMap('entries'),
                    map((entryItem) => ({
                      date: get('date', seriesItem),
                      definition: get('key', attrItem),
                      entry: get('key', entryItem),
                      locationId: item.id,
                      values: {
                        amount: get(['amount', 'value'], entryItem),
                      },
                    })),
                  )(seriesItem),
              ],
              // Implication: quantified primitive
              [
                stubTrue,
                (seriesItem) => ({
                  date: get('date', seriesItem),
                  definition: get('key', attrItem),
                  entry: null,
                  locationId: item.id,
                  values: {
                    value: get('value', seriesItem),
                  },
                }),
              ],
            ]),
            get('time_series', attrItem),
          ),
        get('attributes', item),
      ),
    ),
    insertRandomKeys,
    keyBy('key'),
  )(data);
}

// FIX this should be addressed, but is a new rule, and don't
// want to bundle a large refactor so just leaving the fixes.
// eslint-disable-next-line default-param-last
function stageEntryValue(path, sortedStages = [], defaultValue) {
  if (size(sortedStages) === 0) {
    return defaultValue;
  }

  const value = pipe(last, get('values'), get(path))(sortedStages);

  return value !== undefined
    ? value
    : stageEntryValue(path, slice(0, -1, sortedStages), defaultValue);
}

const normalizeUsageAttributeCategoryDistributions = pipe(
  get('fields'),
  keyBy('key'),
  mapValues(get(['value', 'fields'])),
  mapValues(mapValues('value')),
);

const normalizeUsageAttributeCategories = (
  settingCategories,
  instanceCategories,
) =>
  pipe(
    map((cat) => {
      const distributions = normalizeUsageAttributeCategoryDistributions(cat);
      const instanceCat = find((ic) => ic.key === cat.key, instanceCategories);

      return {
        key: cat.key,
        label: get('label', instanceCat),
        distributionOfDwellings: get(
          'distribution-of-values-floor-space-and-dwellings',
          distributions,
        ),
        distributionOfSpace: assign(
          mapValues(
            multiply(
              get(
                ['distribution-of-types', 'floor-space-and-dwellings'],
                distributions,
              ),
            ),
            get(
              'distribution-of-values-floor-space-and-dwellings',
              distributions,
            ),
          ),
          mapValues(
            multiply(
              get(['distribution-of-types', 'floor-space'], distributions),
            ),
            get('distribution-of-values-floor-space', distributions),
          ),
        ),
      };
    }),
    keyBy('key'),
  )(settingCategories);

const normalizeUsageAttributeEntries = (
  settingCategories,
  instanceCategories,
) =>
  pipe(
    flatMap((cat) =>
      map(
        (entry) => ({
          key: entry.key,
          category: cat.key,
          hasDwellings:
            get(['unit', 'type'], entry) === 'floor-space-and-dwellings',
          label: pipe(
            find((ic) => ic.key === cat.key),
            get('entries'),
            find((ie) => ie.key === entry.key),
            get('label'),
          )(instanceCategories),
          spacePerDwelling: pipe(
            (e) => valueFromField(e, 'setting:kinesis:minimum-space-allocated'),
            defaultTo(
              get(['unit', 'type'], entry) === 'floor-space-and-dwellings'
                ? 100
                : undefined,
            ),
          )(entry),
        }),
        cat.entries,
      ),
    ),
    keyBy('key'),
  )(settingCategories);

const normalizeAttributeEntries = (settingEntries, instanceEntries) =>
  pipe(
    map((entry) => ({
      key: get('key', entry),
      encoding: 'float64',
      label: pipe(
        find((ie) => ie.key === entry.key),
        get('label'),
      )(instanceEntries),
      unitLabel: get(['unit', 'label'], entry),
      unitType: get(['unit', 'type'], entry),
    })),
    keyBy('key'),
  )(settingEntries);

function normalizeAttributeStages(data) {
  return map(
    (stage) => ({
      date: get('date', stage),
      values: pipe(
        get('entries'),
        keyBy('key'),
        mapValues(omit('key')),
        mapValues(mapValues('value')),
      )(stage),
    }),
    data,
  );
}

function normalizeUsageAttributeStages(data) {
  return map(
    (stage) => ({
      date: get('date', stage),
      values: pipe(
        get('categories'),
        flatMap('entries'),
        keyBy('key'),
        mapValues(omit('key')),
        mapValues(mapValues('value')),
      )(stage),
    }),
    data,
  );
}

function normalizeCompoundAttributeStages(data, { entryKey } = {}) {
  return map((stage) => {
    const fields = get('fields', stage);

    return {
      date: get('date', stage),
      values:
        size(fields) > 0
          ? {
              [entryKey]: mapValues('value', fields),
            }
          : {},
    };
  }, data);
}

function selectAvailableEntries(state, instance) {
  return pipe(
    map((entry) => ({
      key: get('key', entry),
      label: get('label', entry),
      visible: get('visible', entry),
    })),
    sortByInstance(instance),
  )(state.entries);
}

function selectAvailableCategories(state, instance) {
  return pipe(
    map((cat) => ({
      key: get('key', cat),
      label: get('label', cat),
      entries: pipe(
        filter({ category: get('key', cat) }),
        map((entry) => ({
          key: get('key', entry),
          label: get('label', entry),
          visible: Boolean(get('visible', entry)),
        })),
        sortByInstance(instance),
      )(state.entries),
    })),
    sortByInstance(instance),
  )(state.categories);
}

function selectCategoricalEntries(state, instance, options = {}) {
  const { includeEmptyCategories = false } = options;
  const activeDate = get([state.activeStageKey, 'date'], state.stages);
  const sortedStages = pipe(
    activeDate !== undefined
      ? reject((stage) => isAfter(parseISO(activeDate), parseISO(stage.date)))
      : identity,
    sortBy('date'),
  )(state.stages);

  return pipe(
    get('categories'),
    flatMap((category) => {
      const catEntries = filter({ category: category.key }, state.entries);
      const selectedEntries = pipe(
        filter('visible'),
        map((entry) => {
          const hasDwellings = get([entry.key, 'hasDwellings'], state.entries);
          const spacePerDwelling = get(
            [entry.key, 'spacePerDwelling'],
            state.entries,
          );
          const space = stageEntryValue([entry.key, 'amount'], sortedStages, 0);

          return {
            key: get('key', entry),
            hasDwellings: get('hasDwellings', entry),
            label: get('label', entry),
            dwellings: hasDwellings ? space / spacePerDwelling : 0,
            space,
          };
        }),
      )(catEntries);

      if (!includeEmptyCategories && selectedEntries.length === 0) {
        return [];
      }

      return [
        {
          key: category.key,
          hasDwellings: some('hasDwellings', catEntries),
          label: get([category.key, 'label'], state.categories),
          dwellings: sumBy('dwellings', selectedEntries),
          space: sumBy('space', selectedEntries),
          entries: sortByInstance(instance, selectedEntries),
        },
      ];
    }),
    sortByInstance(instance),
  )(state);
}

function selectAttributeStageDates(state) {
  if (isEmpty(state.stages)) {
    return [
      {
        key: state.activeStageKey,
        date: state.defaultDate,
      },
    ];
  }

  return pipe(
    get('stages'),
    sortBy('date'),
    map((stage) => ({
      key: get('key', stage),
      date: get('date', stage),
    })),
  )(state);
}

function selectAttributeStageEntries(state, instance) {
  const activeDate = get([state.activeStageKey, 'date'], state.stages);
  const sortedStages = pipe(
    activeDate !== undefined
      ? reject((stage) => isAfter(parseISO(activeDate), parseISO(stage.date)))
      : identity,
    sortBy('date'),
  )(state.stages);

  return pipe(
    get('entries'),
    filter('visible'),
    map((entry) => ({
      key: get('key', entry),
      encoding: get('encoding', entry),
      label: get('label', entry),
      unitLabel: get('unitLabel', entry),
      unitType: get('unitType', entry),
      value: stageEntryValue([entry.key, 'amount'], sortedStages, 0),
    })),
    sortByInstance(instance),
  )(state);
}

function selectAttributeStageEntriesWithFields(state, instance) {
  const activeDate = get([state.activeStageKey, 'date'], state.stages);
  const sortedStages = pipe(
    activeDate !== undefined
      ? reject((stage) => isAfter(parseISO(activeDate), parseISO(stage.date)))
      : identity,
    sortBy('date'),
  )(state.stages);

  return pipe(
    get('entries'),
    filter('visible'),
    map((entry) => {
      const entryValue = stageEntryValue(entry.key, sortedStages, {});

      return {
        key: get('key', entry),
        fields: pipe(
          filter({ entry: entry.key }),
          map((field) => ({
            encoding: get('encoding', field),
            key: get('key', field),
            label: get('label', field),
            unitLabel: get('unitLabel', field),
            unitType: get('unitType', field),
            value: get(field.key, entryValue) || 0,
          })),
        )(state.fields),
        label: get('label', entry),
      };
    }),
    sortByInstance(instance),
  )(state);
}

function selectAttributeStageHistory(state) {
  const sortedStages = sortBy('date', state.stages);

  return mapWithIndex(
    (stage, index) => ({
      key: get('key', stage),
      date: get('date', stage),
      label: pipe(get('date'), (date) =>
        date ? format('d MMM yyyy', parseISO(date)) : '',
      )(stage),
      value: pipe(
        map((entry) =>
          stageEntryValue(
            [entry.key, 'amount'],
            slice(0, index + 1, sortedStages),
          ),
        ),
        sum,
        defaultTo(0),
      )(state.entries),
    }),
    sortedStages,
  );
}

function serializeAttributeCategories(state) {
  return pipe(
    sortBy('date'),
    map((stage) => ({
      date: get('date', stage),
      year: parseISO(get('date', stage)).getFullYear(),
      categories: pipe(
        get('values'),
        omitBy(isNil),
        entries,
        groupBy(([entryKey]) => get([entryKey, 'category'], state.entries)),
        entries,
        map(([catKey, catEntries]) => ({
          key: catKey,
          entries: map(
            ([entryKey, entryValue]) => ({
              key: entryKey,
              amount: {
                encoding: 'float64',
                value: get('amount', entryValue),
              },
            }),
            catEntries,
          ),
        })),
      )(stage),
    })),
  )(state.stages);
}

function serializeAttributeEntries(state) {
  return pipe(
    sortBy('date'),
    map((stage) => ({
      date: get('date', stage),
      year: parseISO(get('date', stage)).getFullYear(),
      entries: pipe(
        get('values'),
        omitBy(isNil),
        entries,
        map(([entryKey, entryValue]) => ({
          key: entryKey,
          amount: {
            encoding: get([entryKey, 'encoding'], state.entries),
            value: get('amount', entryValue),
          },
        })),
      )(stage),
    })),
  )(state.stages);
}

function serializeAttributeFields(state) {
  return pipe(
    sortBy('date'),
    map((stage) => ({
      date: get('date', stage),
      year: parseISO(get('date', stage)).getFullYear(),
      fields: pipe(
        get('values'),
        values,
        mergeAll,
        mapValuesWithKey((value, fieldKey) => ({
          encoding: get([fieldKey, 'encoding'], state.fields),
          value,
        })),
      )(stage),
    })),
  )(state.stages);
}

export {
  normalizeAttributeEntries,
  normalizeAttributeEntities,
  normalizeAttributeStages,
  normalizeCompoundAttributeStages,
  normalizeUsageAttributeCategories,
  normalizeUsageAttributeEntries,
  normalizeUsageAttributeStages,
  selectAttributeStageDates,
  selectAttributeStageEntries,
  selectAttributeStageEntriesWithFields,
  selectAvailableCategories,
  selectAvailableEntries,
  selectAttributeStageHistory,
  selectCategoricalEntries,
  serializeAttributeCategories,
  serializeAttributeEntries,
  serializeAttributeFields,
};
