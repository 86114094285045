import { createCachedSelector } from 're-reselect';
import { curry, filter, get, identity, map, max, min, pipe } from 'lodash/fp';
import { scaleLinear } from 'd3-scale';
import {
  colorsFor,
  colorFor,
  baseColor,
  diffColor,
  selectedLineColor,
  deselectedLineColor,
} from 'components/spatial-visualisation/layer-colors';
import blockValueSelector from 'selectors/blockValueSelector';
import publicBlockYearSelector from 'selectors/publicBlockYearSelector';
import publicColorDomainSelector from 'selectors/publicColorDomainSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import currentAttributesSelector from 'selectors/currentAttributesSelector';
import scenarioKeySelector from 'selectors/scenarioKeySelector';
import publicVisualisationDataSelector from 'selectors/publicVisualisationDataSelector';

const scaleRadius = curry((minForDomain, maxForDomain, value) =>
  scaleLinear().domain([minForDomain, maxForDomain]).range([32, 96])(value),
);

const publicSpatialVisualisationDataSelector = createCachedSelector(
  pipe(workspaceSelector, get('locations')),
  pipe(currentAttributesSelector, get('values')),
  scenarioKeySelector,
  blockValueSelector,
  publicVisualisationDataSelector,
  publicBlockYearSelector,
  publicColorDomainSelector,
  (
    locations,
    attributesLookup,
    scenario,
    capsule,
    data,
    time,
    publicColorDomain,
  ) => {
    const filteredData = pipe(
      filter({ scenario }),
      time ? filter({ time }) : identity,
    )(data);

    const colors = colorsFor(publicColorDomain);
    const makeColors = (value) => {
      const color = colorFor(publicColorDomain, colors, value);
      return {
        base: baseColor(color),
        diff: diffColor(color),
        deselectedBorder: deselectedLineColor(color),
        selectedBorder: selectedLineColor(color),
        fixedDeselectedBorder: deselectedLineColor('#092D66'),
        fixedSelectedBorder: selectedLineColor('#092D66'),
      };
    };

    const sizeMin = pipe(map('size'), min)(data);
    const sizeMax = pipe(map('size'), max)(data);
    const makeRadius = scaleRadius(sizeMin, sizeMax);

    if (get(['spatial', 'type'], capsule) === 'hex') {
      return map(
        (datum) => ({
          label: datum.label,
          color: datum.color,
          hexagon: datum.position,
          id: datum.position,
          colors: makeColors(datum.color),
        }),
        filteredData,
      );
    }

    return map(
      (datum) => ({
        id: datum.position,
        label:
          datum.label ||
          get([datum.position, 'label'], locations) ||
          'New location',
        position: [
          get([datum.position, 'coordinate', 'longitude'], attributesLookup),
          get([datum.position, 'coordinate', 'latitude'], attributesLookup),
        ],
        color: datum.color,
        colors: makeColors(datum.color),
        size: datum.size,
        radius: makeRadius(datum.size),
      }),
      filteredData,
    );
  },
)((state, props) => props.blockId);

export default publicSpatialVisualisationDataSelector;
