import { createSelector } from 'reselect';
import { get, keys, map, pipe, sortBy } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const sortedAccessListSelector = createSelector(
  pipe(workspaceSelector, get(['data', 'access'])),
  get('identities'),
  get(['auth', 'userId']),
  (access, identities, userId) =>
    pipe(
      (obj) =>
        keys(obj).map((identity) => ({
          identity,
          permissions: obj[identity],
        })),
      map((obj) => ({ ...obj, ...identities[obj.identity] })),
      sortBy((obj) => [
        obj.identity.startsWith('user'),
        obj.identity !== `user/${userId}`,
        obj.name,
      ]),
    )(access),
);

export default sortedAccessListSelector;
