import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRect } from '@kinesis/bungle';
import {
  find,
  fromPairs,
  get,
  isNil,
  pipe,
  filter,
  map,
  reject,
} from 'lodash/fp';
import Layout from 'components/layout';
import Map from 'components/map/Map';
import { SpatialTools } from 'components/spatial-tools';
import { SpatialPopover } from 'components/spatial-popover/spatial-popover';
import { scenarioLocationLayers } from 'components/spatial-visualisation/location-layers';
import {
  baseColor,
  diffColor,
  selectedLineColor,
  deselectedLineColor,
} from 'components/spatial-visualisation/layer-colors';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { toolboxLocationIdSelector } from 'selectors/toolboxDetailSelectors';
import filteredScenarioLocationsSelector from 'selectors/filteredScenarioLocationsSelector';
import layersSelector from 'selectors/layersSelector';
import makePublicTokensSelector from 'selectors/makePublicTokenSelector';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import useTheme from 'hooks/useTheme';

const mapboxAccessTokenSelector = makePublicTokensSelector('mapboxAccessToken');

const propTypes = {
  bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const SpatialScenario = ({ bounds, privacy, workspaceId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [hover, setHover] = useState(undefined);
  const ref = useRef();
  const rect = useRect(ref);
  const getCursor = useCallback(() => (hover ? 'pointer' : 'grab'), [hover]);
  const scenarioId = useScenarioId();
  const locations = useSelectorWithProps(filteredScenarioLocationsSelector, {
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });
  const layers = useSelectorWithProps(layersSelector, {
    public: privacy === 'public',
    workspaceId,
  });
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);
  const selectedLocationId = useSelector(toolboxLocationIdSelector);

  const handleReset = useCallback(() => {
    dispatch(toolboxActions.resetTo('context'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      toolboxActions.select({
        pane: 'context',
        selection: { id: scenarioId, type: 'scenario' },
      }),
    );
  }, [dispatch, scenarioId]);

  const layerColors = useMemo(
    () =>
      fromPairs(
        layers.map((layer) => [layer.id, theme.color.layer[layer.color]]),
      ),
    [layers, theme.color.layer],
  );

  const locationColors = useMemo(
    () =>
      fromPairs(
        locations.map((location) => [location.id, layerColors[location.layer]]),
      ),
    [locations, layerColors],
  );

  const locationData = useMemo(
    () =>
      locations
        .map((location) => ({
          ...location,
          layerColor: locationColors[location.id],
        }))
        .filter((x) => x.layerColor),
    [locations, locationColors],
  );
  const hoverId = get('id', hover);
  const selectedId = selectedLocationId;
  const focus = isNil(selectedLocationId);
  const shapeLocations = useMemo(
    () =>
      pipe(
        filter((l) => l.shape),
        map((l) => {
          const color = get(['layerColor', 'fill'], l);
          return {
            type: 'Feature',
            geometry: get('shape', l),
            properties: {
              colors: {
                base: baseColor(color),
                diff: diffColor(color),
                deselectedBorder: deselectedLineColor(color),
                selectedBorder: selectedLineColor(color),
              },
              id: l.id,
            },
          };
        }),
      )(locationData),
    [locationData],
  );

  const pointLocations = useMemo(
    () => pipe(reject((l) => l.shape))(locationData),
    [locationData],
  );

  const deckLayers = useMemo(
    () =>
      scenarioLocationLayers({
        shapeLocations,
        pointLocations,
        hoverId,
        selectedId,
        focus,
        onClick({ object }) {
          dispatch(
            toolboxActions.select({
              pane: 'detail',
              selection: {
                id: object.id || object.properties.id,
                type: 'location',
              },
            }),
          );
          return true;
        },
        onHover({ object, x, y }) {
          const id = get('id', object);
          const shapeId = get(['properties', 'id'], object);
          if (isNil(object)) {
            setHover(undefined);
          } else if (get('id', hover) !== id || !isNil(shapeId)) {
            setHover({ id: id || shapeId, x, y });
          }
          return true;
        },
      }),
    [
      dispatch,
      hover,
      shapeLocations,
      pointLocations,
      focus,
      hoverId,
      selectedId,
    ],
  );

  return (
    <Layout display='block' ref={ref}>
      <Map
        dragPan
        mapboxAccessToken={mapboxAccessToken}
        mapStyle='light'
        bounds={bounds}
        layers={deckLayers}
        onClick={handleReset}
        getCursor={getCursor}
      />
      {hover && find({ id: hover.id }, locations) && (
        <SpatialPopover
          type={!isNil(get('id', hover)) ? 'location' : undefined}
          id={get('id', hover)}
          x={get('x', hover) + rect.x}
          y={get('y', hover) + rect.y}
          locations={locations}
        />
      )}
      <SpatialTools
        privacy={privacy}
        scenarioId={scenarioId}
        workspaceId={workspaceId}
      />
    </Layout>
  );
};

SpatialScenario.propTypes = propTypes;
SpatialScenario.defaultProps = defaultProps;

export default SpatialScenario;
