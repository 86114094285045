import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { defaultsDeep, flatMap } from 'lodash/fp';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import addMore from 'highcharts/highcharts-more';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { bubbleSeriesClickHandler } from 'utils/charts/bubble';
import { formatTooltip } from 'utils/charts/tooltips';
import Layout from 'components/layout';

addMore(Highcharts);
addNoDataModule(Highcharts);

const ChartContainer = styled.div({
  backgroundColor: 'white',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px 16px',
  width: '100%',
});

const ChartMain = styled.div(
  {
    display: 'flex',
    flex: '1 1 0',
    height: '100%',
  },
  ({ maxHeight }) => ({ maxHeight }),
  ({ maxWidth }) => ({ maxWidth }),
);

class ChartImpl extends PureComponent {
  constructor(props) {
    super(props);

    this.chartRef = createRef();
  }

  static propTypes = {
    clickable: PropTypes.bool,
    formatLabel: PropTypes.func,
    legend: PropTypes.node,
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    onCrosshairClick: PropTypes.func,
    onBlockMultiSelect: PropTypes.func,
    onExternalClick: PropTypes.func,
    onSelect: PropTypes.func,
    onSelectPoints: PropTypes.func,
    onSelectPointValue: PropTypes.func,
    options: PropTypes.object.isRequired,
    selectedDetailId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    clickable: false,
    legend: undefined,
    formatLabel: undefined,
    maxHeight: undefined,
    maxWidth: undefined,
    onBlockMultiSelect: undefined,
    onCrosshairClick: undefined,
    onExternalClick: undefined,
    onSelect: undefined,
    onSelectPoints: undefined,
    onSelectPointValue: undefined,
    selectedDetailId: undefined,
  };

  initialise(chart) {
    if (this.props.onSelectPointValue) {
      this.props.onSelectPointValue(chart, this.props.selectedDetailId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.chartRef.current) {
      this.chartRef.current.chart.reflow();
      if (prevProps.selectedDetailId !== this.props.selectedDetailId) {
        this.props.onSelectPointValue(
          this.chartRef.current.chart,
          this.props.selectedDetailId,
        );
      }
    }
  }

  changeSelectedPoints = (event) => {
    const { onBlockMultiSelect, onSelect, onSelectPoints } = this.props;
    const { chart } = this.chartRef.current;

    if (event.shiftKey) {
      if (onBlockMultiSelect) {
        onBlockMultiSelect();
        event.stopPropagation();
      }
      return;
    }

    if (onSelectPoints) {
      onSelectPoints(chart);
    }

    if (chart.hoverPoints && onSelect) {
      onSelect(chart.hoverPoint.category);
    }

    chart.redraw();
  };

  handleCrosshairClick = (event) => {
    const { onBlockMultiSelect, onCrosshairClick, onSelect } = this.props;
    const { chart } = this.chartRef.current;

    if (event.shiftKey) {
      if (onBlockMultiSelect) {
        onBlockMultiSelect();
        event.stopPropagation();
      }
      return;
    }

    if (onCrosshairClick) {
      // located in bubble.js but also used for scatters
      onCrosshairClick(chart);

      if (onSelect) {
        onSelect(null);
      }
    } else {
      this.changeSelectedPoints(event);
    }
  };

  // bubble and scatter charts
  handleDotSeriesClick = (event) => {
    const { onBlockMultiSelect, onSelect } = this.props;
    const { point } = event;

    if (event.shiftKey) {
      if (onBlockMultiSelect) {
        onBlockMultiSelect();
        event.stopPropagation();
      }
      return;
    }

    if (bubbleSeriesClickHandler(point) && onSelect) {
      event.stopPropagation();
      onSelect(point.dataId);
    }

    return false;
  };

  clickIsOutsidePlot = (event, chart) => {
    const { container } = chart;
    const { x: pageOffsetX, y: pageOffsetY } =
      container.getBoundingClientRect();

    const x = event.clientX - chart.plotLeft - pageOffsetX;
    const y = event.clientY - chart.plotTop - pageOffsetY;

    return !chart.isInsidePlot(x, y);
  };

  handleExternalClick = (event) => {
    const { onBlockMultiSelect, onExternalClick, onSelect } = this.props;
    const { chart } = this.chartRef.current;

    if (event.shiftKey) {
      if (onBlockMultiSelect) {
        onBlockMultiSelect();
        event.stopPropagation();
      }
      return;
    }

    const points = flatMap('points', chart.series);
    if (
      points.length === 0 ||
      chart.series.length === 0 ||
      this.clickIsOutsidePlot(event, chart)
    ) {
      if (onExternalClick) {
        onExternalClick(points, chart);
      }

      chart.redraw();

      if (onSelect) {
        onSelect(null);
      }
    }
  };

  defaultChartOptions() {
    const { clickable, formatLabel } = this.props;

    return {
      plotOptions: {
        series: {
          animation: false,
          cursor: clickable ? 'pointer' : undefined,
        },
        column: {
          borderWidth: 1,
          pointPadding: 0.1,
          groupPadding: 0.1,
          states: { hover: { enabled: false } },
          events: {
            click: clickable ? this.changeSelectedPoints : undefined,
          },
        },
        area: {
          stacking: 'normal',
          marker: {
            states: {
              hover: {
                radius: 5,
                lineWidth: 0,
              },
            },
          },
          states: {
            hover: {
              enabled: true,
              halo: null,
              lineWidthPlus: 0,
            },
          },
          events: {
            click: clickable ? this.changeSelectedPoints : undefined,
          },
        },
        line: {
          marker: {
            states: {
              hover: {
                radius: 4,
                lineWidth: 0,
              },
            },
          },
          states: {
            hover: {
              enabled: true,
              halo: null,
              lineWidthPlus: 0,
            },
          },
          events: {
            click: clickable ? this.changeSelectedPoints : undefined,
          },
        },
        bubble: {
          stickyTracking: false,
          allowPointSelect: false,
          states: {
            hover: {
              halo: null,
            },
          },
          events: {
            click: clickable ? this.handleDotSeriesClick : undefined,
          },
        },
        scatter: {
          stickyTracking: false,
          allowPointSelect: false,
          states: {
            hover: {
              halo: null,
            },
          },
          events: {
            click: clickable ? this.handleDotSeriesClick : undefined,
          },
          dataLabels: { enabled: true },
          marker: {
            radius: 8,
          },
        },
      },
      legend: { enabled: false },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: '#e9e9e9',
        lineColor: '#e9e9e9',
        tickColor: '#e9e9e9',
        labels: {
          formatter() {
            if (this.axis.categories) {
              return this.axis.defaultLabelFormatter.call(this);
            }
            return Highcharts.numberFormat(this.value, -1, '.', ',');
          },
          style: {
            color: 'rgba(0,0,0,0.65)',
            fontWeight: 400,
          },
        },
        title: {
          style: {
            color: 'rgba(0,0,0,0.85)',
            fontWeight: 500,
          },
          y: 8, // move title to edge
        },
      },
      yAxis: {
        gridLineColor: '#e9e9e9',
        lineColor: '#e9e9e9',
        tickColor: '#e9e9e9',
        labels: {
          formatter() {
            return Highcharts.numberFormat(this.value, -1, '.', ',');
          },
          style: {
            color: 'rgba(0,0,0,0.65)',
            fontWeight: 400,
          },
        },
        title: {
          style: {
            color: 'rgba(0,0,0,0.85)',
            fontWeight: 500,
          },
          x: -12, // move title to edge
        },
      },
      tooltip: {
        // need to set transparent bg and no shadow because even with useHTML, an SVG box is drawn behind
        useHTML: true,
        backgroundColor: 'rgba(255,255,255,0)',
        borderWidth: 0,
        shadow: false,
        animation: false,
        hideDelay: 0,
        outside: true,
        padding: 0,
        shared: true,
        formatter() {
          return formatTooltip(this, formatLabel);
        },
      },
      credits: { enabled: false },
      title: { text: undefined },
      lang: {
        noData: 'There is no data.',
      },
      noData: {
        style: {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          color: 'rgba(0,0,0,0.25)',
        },
      },
      chart: {
        animation: false,
        plotBorderWidth: 1,
        plotBorderColor: '#e9e9e9',
        plotBackgroundColor: `rgba(0,0,0,0)`, // dummy to create background for load() event
        events: {
          load: (event) => {
            if (
              clickable &&
              ['column', 'line'].includes(event.target.options.chart.type)
            ) {
              event.target.plotBackground.css({ cursor: 'pointer' });
            }
          },
          click: clickable ? this.handleCrosshairClick : undefined,
        },
        spacing: [12, 1, 12, 12],
        style: {
          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
          fontSize: '12px',
        },
      },
    };
  }

  render() {
    const { legend, maxHeight, maxWidth, options } = this.props;

    const highchartsOptions = defaultsDeep(this.defaultChartOptions(), options);

    return (
      <ChartContainer onClick={this.handleExternalClick}>
        <ChartMain maxHeight={maxHeight} maxWidth={maxWidth}>
          <Layout>
            <Layout display='block' overflow='hidden'>
              <AutoSizer>
                {({ height, width }) =>
                  width && (
                    <HighchartsReact
                      containerProps={{
                        style: {
                          height,
                          width,
                        },
                      }}
                      highcharts={Highcharts}
                      options={highchartsOptions}
                      ref={this.chartRef}
                      callback={(chart) => this.initialise(chart)}
                    />
                  )
                }
              </AutoSizer>
            </Layout>
            {legend}
          </Layout>
        </ChartMain>
      </ChartContainer>
    );
  }
}

export default ChartImpl;
