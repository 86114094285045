import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const appCreate = createAsyncThunk(
  'app/create',
  async ({ owner, name, licence }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/apps`, {
      owner,
      name,
      licence,
    });
    return response.data;
  },
);

export { appCreate };
