import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, identity, noop, pipe, trim } from 'lodash/fp';
import { InPlaceInput, Prompt } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { actions as miscActions } from 'reducers/miscReducer';
import { Frame } from 'components/frame';
import LayerMenu from 'components/layer-menu/LayerMenu';
import FiltersSection from 'components/toolbox/sections/FiltersSection';
import { ConfigurationSection } from 'components/toolbox/sections/configuration-section';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { VisualisationTypeChooser } from 'components/visualisation-type-chooser';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import AutoFocus from 'components/util/AutoFocus';
import { setVisualisationType, setTitle } from 'utils/visualisationConfigUtils';
import { staticToEditableType } from 'data/block';
import PropTypes from 'prop-types';
import { blockUpdateDetails } from 'actions/blockUpdateDetails';
import { blockValueCreate } from 'actions/blockValueCreate';
import { useScenarioId, useSelectorWithProps } from 'hooks';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  boardId: PropTypes.number.isRequired,
  editor: PropTypes.bool,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  editor: false,
};

const VisualisationPane = ({ blockId, boardId, editor, workspaceId }) => {
  const dispatch = useDispatch();

  const showBlockConfigurationPrompt = useSelector(
    get(['misc', 'showBlockConfigurationPrompt']),
  );
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId,
    workspaceId,
  });
  const { title, type, visualisation } = blockValue;
  const table = useSelectorWithProps(pipe(blockStateSelector, get('table')), {
    blockId,
    workspaceId,
  });
  const scenarioId = useScenarioId();

  const updateBlock = useCallback(
    (blockParams, options) => {
      if (editor) {
        dispatch(
          blockUpdateDetails({
            workspaceId,
            boardId,
            blockId,
            scenarioId,
            blockParams,
            clearPerspectives: get('clearPerspectives', options),
          }),
        );
      } else {
        dispatch(
          blockValueCreate({
            blockId,
            scenarioId,
            blockParams,
          }),
        );
      }
    },
    [blockId, dispatch, editor, scenarioId, workspaceId, boardId],
  );

  const handleTitleChange = useCallback(
    (value) => {
      const newTitle = trim(value);
      if (newTitle.length) {
        const blockParams = pipe(
          setTitle(newTitle),
          blockValue.visualisation === 'empty'
            ? setVisualisationType('indicator')
            : identity,
        )(blockValue);
        updateBlock(blockParams);
      }
    },
    [blockValue, updateBlock],
  );

  const handleTypeChange = useCallback(
    (newType) => {
      if (newType) {
        const blockParams = setVisualisationType(newType)(blockValue);
        updateBlock(blockParams, { clearPerspectives: true });
      }
    },
    [blockValue, updateBlock],
  );

  const typeChooserMode = editor ? 'editable' : 'static';

  const handleDismissPrompt = useCallback(() => {
    dispatch(miscActions.clearShowBlockConfigurationPrompt());
  }, [dispatch]);

  // Auto dismiss configuration prompt after a while
  useEffect(() => {
    if (showBlockConfigurationPrompt) {
      const timerId = setTimeout(handleDismissPrompt, 6000);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [handleDismissPrompt, showBlockConfigurationPrompt]);

  switch (type) {
    case 'editable-visualisation': {
      return (
        <ToolboxPanel fadeScroll>
          <Content as='header' overflow='visible' padding='medium'>
            <InPlaceInput
              disabled={!editor}
              onChange={handleTitleChange}
              placeholder='New visualisation'
              value={title}
              variant='title'
            />
          </Content>
          <Frame>
            <Prompt
              placement='left'
              title='Configure your visualisation'
              showPrompt={
                visualisation === 'empty' && showBlockConfigurationPrompt
              }
              onDismiss={handleDismissPrompt}
            >
              <div>
                <VisualisationTypeChooser
                  onChange={handleTypeChange}
                  mode={typeChooserMode}
                  value={visualisation}
                />
              </div>
            </Prompt>
          </Frame>
          <ConfigurationSection
            blockId={blockId}
            boardId={boardId}
            onChange={updateBlock}
            workspaceId={workspaceId}
          />
          <FiltersSection
            blockId={blockId}
            scenarioId={scenarioId}
            table={table}
            workspaceId={workspaceId}
          />
        </ToolboxPanel>
      );
    }
    default: {
      return (
        <AutoFocus>
          <ToolboxPanel fadeScroll title={title}>
            <Frame>
              <VisualisationTypeChooser
                onChange={handleTypeChange}
                mode={typeChooserMode}
                value={staticToEditableType(blockValue)}
              />
            </Frame>
            <ConfigurationSection
              blockId={blockId}
              boardId={boardId}
              onChange={noop}
              workspaceId={workspaceId}
            />
            {visualisation === 'spatial' && (
              <LayerMenu blockId={blockId} workspaceId={workspaceId} />
            )}
            <FiltersSection
              blockId={blockId}
              scenarioId={scenarioId}
              table={table}
              workspaceId={workspaceId}
            />
          </ToolboxPanel>
        </AutoFocus>
      );
    }
  }
};

VisualisationPane.propTypes = propTypes;
VisualisationPane.defaultProps = defaultProps;

export { VisualisationPane };
