import { isNil, map, zipObject } from 'lodash/fp';
import Units from 'data/units';

const units = new Units();

function encodeValue(value, encoding) {
  return {
    type: encoding,
    value: encoding === 'string' ? String(value) : Number(value),
  };
}

function constructFilterItem(column, value, locations, instantiations) {
  const unit = units.parseColumn(column, locations, instantiations);

  if (!value) {
    return null;
  }

  switch (unit.filter) {
    case 'select':
      return {
        column: column.key,
        operand: 'eq',
        value: encodeValue(value, column.encoding),
      };

    case 'multiselect':
      return {
        operand: 'or',
        filters: value.map((val) => ({
          column: column.key,
          operand: 'eq',
          value: encodeValue(val, column.encoding),
        })),
      };

    case 'month':
    case 'numeric':
    case 'year': {
      if (typeof value === 'number') {
        return {
          column: column.key,
          operand: 'eq',
          value: encodeValue(value, column.encoding),
        };
      }

      const { relationalOperator, firstOperand, secondOperand } = value;

      if (relationalOperator === 'be') {
        if (isNil(firstOperand) || isNil(secondOperand)) {
          return null;
        }

        return {
          operand: 'and',
          filters: [
            {
              column: column.key,
              operand: 'gte',
              value: encodeValue(firstOperand, column.encoding),
            },
            {
              column: column.key,
              operand: 'lte',
              value: encodeValue(secondOperand, column.encoding),
            },
          ],
        };
      }

      if (relationalOperator === 'nb') {
        if (isNil(firstOperand) || isNil(secondOperand)) {
          return null;
        }

        return {
          operand: 'or',
          filters: [
            {
              column: column.key,
              operand: 'lt',
              value: encodeValue(firstOperand, column.encoding),
            },
            {
              column: column.key,
              operand: 'gt',
              value: encodeValue(secondOperand, column.encoding),
            },
          ],
        };
      }

      if (isNil(firstOperand)) {
        return null;
      }

      return {
        column: column.key,
        operand: relationalOperator,
        value: encodeValue(firstOperand, column.encoding),
      };
    }

    default:
  }

  throw new Error(`Unknown filter type: ${unit.filter}`);
}

function zipToSchema(schema, data) {
  return map(zipObject(map('key', schema)), data);
}

// eslint-disable-next-line import/prefer-default-export
export { constructFilterItem, zipToSchema };
