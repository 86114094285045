import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash/fp';
import { Fieldset, useUpdateEffect } from '@kinesis/bungle';
import ResettableFormItem from 'components/form/ResettableFormItem';
import {
  RelationalOperatorSelect,
  RelationalOperatorSelectContent,
} from 'components/form/RelationalOperatorSelect';
import SliderWithInputNumber from 'components/form/SliderWithInputNumber';
import RangeSliderWithInputNumbers from 'components/form/RangeSliderWithInputNumbers';
import useValueRange from 'hooks/useValueRange';
import { numberOfOperands } from 'utils/numberUtils';

const propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  yearMax: PropTypes.number,
  yearMin: PropTypes.number,
};

const defaultProps = {
  value: undefined,
  yearMax: undefined,
  yearMin: undefined,
};

const YearFilter = ({ label, onChange, value, yearMax, yearMin }) => {
  const {
    firstOperand,
    relationalOperator,
    secondOperand,
    setFirstOperand,
    setRelationalOperator,
    setSecondOperand,
  } = useValueRange({
    firstOperand: value ? value.firstOperand : yearMin,
    relationalOperator: value ? value.relationalOperator : 'all',
    secondOperand: value ? value.secondOperand : yearMax,
  });

  const numOperands = numberOfOperands(relationalOperator);
  const isValid =
    relationalOperator === 'all' ||
    (numOperands === 1 && !isNil(firstOperand)) ||
    (numOperands === 2 &&
      !isNil(firstOperand) &&
      !isNil(secondOperand) &&
      secondOperand > firstOperand);

  const handleRangeChange = useCallback(
    (range) => {
      setFirstOperand(range[0]);
      setSecondOperand(range[1]);
    },
    [setFirstOperand, setSecondOperand],
  );

  const handleReset = useCallback(() => {
    setRelationalOperator('all');
    setFirstOperand(yearMin);
    setSecondOperand(yearMax);
  }, [
    setFirstOperand,
    setRelationalOperator,
    setSecondOperand,
    yearMax,
    yearMin,
  ]);

  useUpdateEffect(() => {
    if (!isValid) {
      return;
    }

    if (relationalOperator === 'all') {
      onChange(null);
    } else {
      onChange({
        relationalOperator,
        firstOperand,
        secondOperand,
      });
    }
  }, [isValid, onChange, relationalOperator, firstOperand, secondOperand]);

  return (
    <ResettableFormItem
      showReset={relationalOperator !== 'all'}
      onReset={handleReset}
    >
      <Fieldset legend={label}>
        <RelationalOperatorSelect
          onChange={setRelationalOperator}
          value={relationalOperator}
        />
        {numOperands === 1 && (
          <RelationalOperatorSelectContent>
            <SliderWithInputNumber
              min={yearMin}
              max={yearMax}
              value={firstOperand}
              onChange={setFirstOperand}
            />
          </RelationalOperatorSelectContent>
        )}
        {numOperands === 2 && (
          <RelationalOperatorSelectContent>
            <RangeSliderWithInputNumbers
              min={yearMin}
              max={yearMax}
              lowerValue={firstOperand}
              upperValue={secondOperand}
              onChange={handleRangeChange}
            />
          </RelationalOperatorSelectContent>
        )}
      </Fieldset>
    </ResettableFormItem>
  );
};

YearFilter.propTypes = propTypes;
YearFilter.defaultProps = defaultProps;

export default YearFilter;
