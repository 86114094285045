import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isUndefined, noop } from 'lodash/fp';
import {
  AnimatedPopover,
  Button,
  Dialog,
  Input,
  MultiSlider,
  NumberInput,
  Slider,
  Tooltip,
  UtilityButton,
  useOnClickOutside,
} from '@kinesis/bungle';
import { Toolbar, ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import {
  SliderOverlay,
  SliderStripedTrack,
  SliderStripedTrackWrapper,
  TimeSliderInput,
  TimeSliderTimeline,
  TimeSliderTimelineEntries,
  TimeSliderTimelineEntry,
  TimeSliderTimelineFinal,
  TimeSliderTimelineText,
  TimeSliderWrapper,
} from './time-slider.styles';

const propTypes = {
  basisBounds: PropTypes.arrayOf(PropTypes.number).isRequired,
  defaultYear: PropTypes.number,
  innerBounds: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string),
  onChangeBounds: PropTypes.func,
  onChangeYear: PropTypes.func.isRequired,
  onResetYear: PropTypes.func,
  year: PropTypes.number,
  yearMax: PropTypes.number.isRequired,
  yearMin: PropTypes.number.isRequired,
  years: PropTypes.arrayOf(PropTypes.number),
};

const defaultProps = {
  defaultYear: undefined,
  innerBounds: undefined,
  labels: [],
  onChangeBounds: undefined,
  onResetYear: undefined,
  year: undefined,
  years: [],
};

const TimeSlider = ({
  basisBounds,
  defaultYear,
  innerBounds,
  labels,
  onChangeBounds,
  onChangeYear,
  onResetYear,
  year,
  yearMax,
  yearMin,
  years,
}) => {
  const [isTimelineOpen, setTimelineOpen] = useState(false);
  const [inputMax, setInputMax] = useState(yearMax);
  const [inputMin, setInputMin] = useState(yearMin);
  const timelineButtonRef = useRef();
  const timelinePopoverRef = useRef();

  const handleResetYear = useCallback(() => {
    if (onResetYear) onResetYear();
    setTimelineOpen(false);
  }, [onResetYear]);

  const toggleTimeline = useCallback(() => {
    setInputMin(yearMin);
    setInputMax(yearMax);
    setTimelineOpen((state) => !state);
  }, [yearMax, yearMin]);

  const handleSubmitBounds = useCallback(() => {
    onChangeBounds([inputMin, inputMax]);
  }, [inputMax, inputMin, onChangeBounds]);

  const handleResetBounds = useCallback(() => {
    onChangeBounds(basisBounds);
  }, [basisBounds, onChangeBounds]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        setInputMin(yearMin);
        setInputMax(yearMax);
        setTimelineOpen(false);
      } else if (event.key === 'Enter') {
        onChangeBounds([inputMin, inputMax]);
      }
    },
    [inputMax, inputMin, onChangeBounds, yearMax, yearMin],
  );

  const isDefaultBounds =
    yearMin === basisBounds[0] && yearMax === basisBounds[1];

  const isSingleYearRange = yearMin && yearMax && yearMin === yearMax;

  const isMultiYear = years.length > 1;

  useOnClickOutside(
    [timelineButtonRef, timelinePopoverRef],
    useCallback(() => {
      setInputMin(yearMin);
      setInputMax(yearMax);
      setTimelineOpen(false);
    }, [yearMax, yearMin]),
  );

  useEffect(() => {
    setInputMin(yearMin);
    setInputMax(yearMax);
    setTimelineOpen(false);
  }, [yearMax, yearMin]);

  useEffect(() => {
    if (year && year < yearMin) {
      onChangeYear(yearMin);
    } else if (year && year > yearMax) {
      onChangeYear(yearMax);
    }
  }, [onChangeYear, year, yearMax, yearMin]);

  const stripedTracks = useMemo(() => {
    if (isUndefined(innerBounds)) {
      return [];
    }

    if (innerBounds && isEmpty(innerBounds)) {
      return [
        {
          key: 'full',
          left: '0%',
          width: '100%',
        },
      ];
    }

    const stripes = [];
    const length = yearMax - yearMin;
    if (yearMin < innerBounds[0]) {
      const width = ((innerBounds[0] - yearMin) / length) * 100;
      stripes.push({
        key: `left-${width}`,
        left: '0%',
        width: `${width}%`,
      });
    }
    if (yearMax > innerBounds[1]) {
      const width = ((yearMax - innerBounds[1]) / length) * 100;
      stripes.push({
        key: `right-${width}`,
        left: `${100 - width}%`,
        width: `${width}%`,
      });
    }
    return stripes;
  }, [innerBounds, yearMax, yearMin]);

  const onWrapperClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <TimeSliderWrapper onClick={onWrapperClick}>
      <Toolbar justify='space-between'>
        <ToolbarGroup expand>
          <ToolbarItem>
            <TimeSliderInput>
              {isMultiYear ? (
                <Input value='Multiple values' />
              ) : (
                <NumberInput
                  max={yearMax}
                  min={yearMin}
                  onChange={onChangeYear}
                  value={year}
                />
              )}
            </TimeSliderInput>
          </ToolbarItem>
          <ToolbarItem flex={1} expand>
            <>
              <SliderOverlay>
                <SliderStripedTrackWrapper>
                  {stripedTracks.map(({ key, left, width }) => (
                    <SliderStripedTrack key={key} left={left} width={width} />
                  ))}
                </SliderStripedTrackWrapper>
              </SliderOverlay>
              {isMultiYear && (
                <SliderOverlay>
                  <MultiSlider
                    disabled
                    enableTooltips
                    max={yearMax}
                    min={yearMin}
                    onChange={noop}
                    valueLabels={labels}
                    values={years}
                  />
                </SliderOverlay>
              )}
              <Slider
                disabled={isSingleYearRange}
                max={isSingleYearRange ? year + 1 : yearMax}
                min={isSingleYearRange ? year - 1 : yearMin}
                onChange={onChangeYear}
                hideHandle={isMultiYear}
                hideTrack={isMultiYear}
                value={year}
              />
            </>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip
              disabled={!onResetYear}
              placement='top'
              title='Return to set value'
            >
              <UtilityButton
                disabled={
                  !onResetYear ||
                  isSingleYearRange ||
                  (defaultYear && defaultYear === year)
                }
                icon='undo'
                magnitude='small'
                onClick={handleResetYear}
              />
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip placement='top' title='Constrain timeline'>
              <UtilityButton
                disabled={!onChangeBounds || isSingleYearRange}
                expanded={isTimelineOpen}
                hasPopup
                icon='constrain-timeline'
                magnitude='small'
                onClick={toggleTimeline}
                ref={timelineButtonRef}
                variant={isDefaultBounds ? 'default' : 'accent'}
              />
            </Tooltip>
          </ToolbarItem>
          <AnimatedPopover
            justify='end'
            offset={4}
            open={isTimelineOpen}
            placement='top'
            targetRef={timelineButtonRef}
          >
            <Dialog
              appearance='neat'
              onKeyDown={handleKeyDown}
              ref={timelinePopoverRef}
            >
              <TimeSliderTimeline>
                <TimeSliderTimelineText>
                  Constrain the time period to make it easier to scrub values.
                </TimeSliderTimelineText>
                <TimeSliderTimelineEntries>
                  <TimeSliderTimelineEntry>
                    <NumberInput
                      label='Start year'
                      magnitude='large'
                      min={basisBounds[0]}
                      max={inputMax - 1}
                      onChange={setInputMin}
                      value={inputMin}
                    />
                  </TimeSliderTimelineEntry>
                  <TimeSliderTimelineEntry>
                    <NumberInput
                      label='End year'
                      magnitude='large'
                      min={inputMin + 1}
                      max={basisBounds[1]}
                      onChange={setInputMax}
                      value={inputMax}
                    />
                  </TimeSliderTimelineEntry>
                </TimeSliderTimelineEntries>
                <TimeSliderTimelineFinal>
                  <UtilityButton
                    disabled={isDefaultBounds}
                    onClick={handleResetBounds}
                    variant='danger'
                  >
                    Reset range
                  </UtilityButton>
                  <Button
                    disabled={inputMin === yearMin && inputMax === yearMax}
                    onClick={handleSubmitBounds}
                    variant='primary'
                  >
                    Apply range
                  </Button>
                </TimeSliderTimelineFinal>
              </TimeSliderTimeline>
            </Dialog>
          </AnimatedPopover>
        </ToolbarGroup>
      </Toolbar>
    </TimeSliderWrapper>
  );
};

TimeSlider.propTypes = propTypes;
TimeSlider.defaultProps = defaultProps;

export { TimeSlider };
