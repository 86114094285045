import styled from 'styled-components';
import { ToolboxWrapper } from 'components/toolbox/toolbox-wrapper';

const AttributeSettingsToolbox = styled(ToolboxWrapper)`
  overflow-y: auto;
`;

const BaselineIconWrapper = styled.span`
  padding-left: 8px;
  color: ${({ theme }) => theme.color.gray8};
`;

const DropdownButtonContent = styled.span`
  color: ${({ theme }) => theme.color.gray8};
`;

const Section = styled.section`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  :last-of-type {
    border-bottom: none;
  }
`;

const Description = styled.p`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.color.gray8};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
`;

export {
  AttributeSettingsToolbox,
  BaselineIconWrapper,
  Description,
  DropdownButtonContent,
  Section,
};
