import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const scenarioPublish = createAsyncThunk(
  'scenario/publish',
  async ({ scenarioId, tagName, workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.post(
      `/api/workspace/${workspaceId}/publish/${tagName}`,
      { scenario_id: parseInt(scenarioId, 10) },
    );

    return response.data;
  },
);

export { scenarioPublish };
