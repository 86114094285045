import { createSlice } from '@reduxjs/toolkit';
import { populationDatasetUpload } from 'actions/populationDatasetUpload';
import { populationDatasetFetch } from 'actions/populationDatasetFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { set, unset, pipe, get, defaultTo } from 'lodash/fp';
import { nanoid } from 'nanoid';

const newError = (type, details = {}) => ({
  id: nanoid(),
  type,
  ...details,
});

const processFertilityErrors = (error) => {
  const missingRegions = (error.missing_regions || []).map((region) =>
    newError('missing-region', {
      dataset: 'fertility',
      region,
    }),
  );
  const duplicateRegions = (error.duplicate_regions || []).map((region) =>
    newError('duplicate-region', {
      dataset: 'fertility',
      region,
    }),
  );
  const regionErrors = (error.region_errors || []).flatMap((e) => {
    const unrecognised = get(['unrecognised_region_name', 'value'], e)
      ? newError('unrecognised-region', {
          dataset: 'fertility',
          region: e.region,
          value: get(['unrecognised_region_name', 'value'], e),
        })
      : [];
    const missing = get('missing_years', e)
      ? newError('missing-years', {
          dataset: 'fertility',
          region: e.region,
          years: get('missing_years', e),
        })
      : [];
    const duplicate = get('duplicate_years', e)
      ? newError('duplicate-years', {
          dataset: 'fertility',
          region: e.region,
          years: get('duplicate_years', e),
        })
      : [];
    const value = (e.value_errors || []).map((v) =>
      newError('value-error', {
        dataset: 'fertility',
        region: e.region,
        year: v.year,
        value: get(['value_error', 'value'], v),
        bounds: get(['value_error', 'bounds'], v),
      }),
    );
    return []
      .concat(missing)
      .concat(duplicate)
      .concat(value)
      .concat(unrecognised);
  });
  return regionErrors.concat(missingRegions).concat(duplicateRegions);
};

const processOverseasMigrationErrors = (error) => {
  const sumError = get('internal_sum_error', error)
    ? newError('sum-error', {
        dataset: 'overseas-migration',
        differences: get('internal_sum_error', error),
      })
    : [];
  const missingRegions = (error.missing_regions || []).map((region) =>
    newError('missing-region', {
      dataset: 'overseas-migration',
      region,
    }),
  );
  const duplicateRegions = (error.duplicate_regions || []).map((region) =>
    newError('duplicate-region', {
      dataset: 'overseas-migration',
      region,
    }),
  );
  const regionErrors = (error.region_errors || []).flatMap((e) => {
    const unrecognised = get(['unrecognised_region_name', 'value'], e)
      ? newError('unrecognised-region', {
          dataset: 'overseas-migration',
          region: e.region,
          value: get(['unrecognised_region_name', 'value'], e),
        })
      : [];
    const missing = get('missing_years', e)
      ? newError('missing-years', {
          dataset: 'overseas-migration',
          region: e.region,
          years: get('missing_years', e),
        })
      : [];
    const duplicate = get('duplicate_years', e)
      ? newError('duplicate-years', {
          dataset: 'overseas-migration',
          region: e.region,
          years: get('duplicate_years', e),
        })
      : [];
    return [].concat(missing).concat(duplicate).concat(unrecognised);
  });
  return []
    .concat(sumError)
    .concat(regionErrors)
    .concat(missingRegions)
    .concat(duplicateRegions);
};

const processInterstateMigrationErrors = (error) => {
  const sumError = get('internal_sum_error', error)
    ? newError('sum-error', {
        dataset: 'interstate-migration',
        differences: get('internal_sum_error', error),
      })
    : [];
  const missingRegions = (error.missing_regions || []).map((region) =>
    newError('missing-region', {
      dataset: 'interstate-migration',
      region,
    }),
  );
  const duplicateRegions = (error.duplicate_regions || []).map((region) =>
    newError('duplicate-region', {
      dataset: 'interstate-migration',
      region,
    }),
  );
  const regionErrors = (error.region_errors || []).flatMap((e) => {
    const unrecognised = get(['unrecognised_region_name', 'value'], e)
      ? newError('unrecognised-region', {
          dataset: 'interstate-migration',
          region: e.region,
          value: get(['unrecognised_region_name', 'value'], e),
        })
      : [];
    const missing = get('missing_years', e)
      ? newError('missing-years', {
          dataset: 'interstate-migration',
          region: e.region,
          years: get('missing_years', e),
        })
      : [];
    const duplicate = get('duplicate_years', e)
      ? newError('duplicate-years', {
          dataset: 'interstate-migration',
          region: e.region,
          years: get('duplicate_years', e),
        })
      : [];
    return [].concat(missing).concat(duplicate).concat(unrecognised);
  });
  return []
    .concat(sumError)
    .concat(regionErrors)
    .concat(missingRegions)
    .concat(duplicateRegions);
};

const processIntrastateMigrationErrors = (error) => {
  const sumError = get('internal_sum_error', error)
    ? newError('sum-total-error', {
        dataset: 'intrastate-migration',
        differences: get('internal_sum_error', error),
      })
    : [];
  const missingRegions = (error.missing_regions || []).map((region) =>
    newError('missing-region', {
      dataset: 'intrastate-migration',
      region,
    }),
  );
  const duplicateRegions = (error.duplicate_regions || []).map((region) =>
    newError('duplicate-region', {
      dataset: 'intrastate-migration',
      region,
    }),
  );
  const regionErrors = (error.region_errors || []).flatMap((e) => {
    const unrecognised = get(['unrecognised_region_name', 'value'], e)
      ? newError('unrecognised-region', {
          dataset: 'intrastate-migration',
          region: e.region,
          value: get(['unrecognised_region_name', 'value'], e),
        })
      : [];
    const missing = get('missing_years', e)
      ? newError('missing-years', {
          dataset: 'intrastate-migration',
          region: e.region,
          years: get('missing_years', e),
        })
      : [];
    const duplicate = get('duplicate_years', e)
      ? newError('duplicate-years', {
          dataset: 'intrastate-migration',
          region: e.region,
          years: get('duplicate_years', e),
        })
      : [];
    return [].concat(missing).concat(duplicate).concat(unrecognised);
  });
  return []
    .concat(sumError)
    .concat(regionErrors)
    .concat(missingRegions)
    .concat(duplicateRegions);
};

const processLifeExpectancyErrors = (error) => {
  const missingRegions = (error.missing_regions || []).map((region) =>
    newError('missing-region', {
      dataset: 'life-expectancy',
      region,
    }),
  );
  const duplicateRegions = (error.duplicate_regions || []).map((region) =>
    newError('duplicate-region', {
      dataset: 'life-expectancy',
      region,
    }),
  );
  const regionErrors = (error.region_errors || []).flatMap((e) => {
    const unrecognised = get(['unrecognised_region_name', 'value'], e)
      ? newError('unrecognised-region', {
          dataset: 'life-expectancy',
          region: e.region,
          value: get(['unrecognised_region_name', 'value'], e),
        })
      : [];
    const missingSexes = get('missing_sexes', e)
      ? newError('missing-sexes', {
          dataset: 'life-expectancy',
          region: e.region,
          sexes: get('missing_sexes', e),
        })
      : [];
    const duplicateSexes = get('duplicate_sexes', e)
      ? newError('duplicate-sexes', {
          dataset: 'life-expectancy',
          region: e.region,
          sexes: get('duplicate_sexes', e),
        })
      : [];
    const valueSexes = (e.value_errors || []).flatMap((s) => {
      const unrecognisedSex = get(['unrecognised_sex_name', 'value'], e)
        ? newError('unrecognised-sex', {
            dataset: 'life-expectancy',
            region: e.region,
            sex: s.sex,
            value: get(['unrecognised_sex_name', 'value'], e),
          })
        : [];
      const missing = get('missing_years', s)
        ? newError('missing-years', {
            dataset: 'life-expectancy',
            region: e.region,
            sex: s.sex,
            years: get('missing_years', s),
          })
        : [];
      const duplicate = get('duplicate_years', s)
        ? newError('duplicate-years', {
            dataset: 'life-expectancy',
            region: e.region,
            sex: s.sex,
            years: get('duplicate_years', s),
          })
        : [];
      const value = (s.value_errors || []).map((v) =>
        newError('value-error', {
          dataset: 'life-exepectancy',
          region: e.region,
          sex: s.region,
          year: v.year,
          value: get(['value_error', 'value'], v),
          bounds: get(['value_error', 'bounds'], v),
        }),
      );
      return []
        .concat(unrecognisedSex)
        .concat(missing)
        .concat(duplicate)
        .concat(value);
    });
    return []
      .concat(missingSexes)
      .concat(duplicateSexes)
      .concat(valueSexes)
      .concat(unrecognised);
  });
  return regionErrors.concat(missingRegions).concat(duplicateRegions);
};

const processDwellingProjectionErrors = (error) => {
  const missingRegions = (error.missing_regions || []).map((region) =>
    newError('missing-region', {
      dataset: 'dwelling-projection',
      region,
    }),
  );
  const duplicateRegions = (error.duplicate_regions || []).map((region) =>
    newError('duplicate-region', {
      dataset: 'dwelling-projection',
      region,
    }),
  );
  const regionErrors = (error.region_errors || []).flatMap((e) => {
    const unrecognised = get(['unrecognised_region_name', 'value'], e)
      ? newError('unrecognised-region', {
          dataset: 'dwelling-projection',
          region: e.region,
          value: get(['unrecognised_region_name', 'value'], e),
        })
      : [];
    const missing = get('missing_years', e)
      ? newError('missing-years', {
          dataset: 'dwelling-projection',
          region: e.region,
          years: get('missing_years', e),
        })
      : [];
    const duplicate = get('duplicate_years', e)
      ? newError('duplicate-years', {
          dataset: 'dwelling-projection',
          region: e.region,
          years: get('duplicate_years', e),
        })
      : [];
    return [].concat(missing).concat(duplicate).concat(unrecognised);
  });
  return regionErrors.concat(missingRegions).concat(duplicateRegions);
};

const errorify = (error) => {
  if (error.code === 'dpie-population-validation-errors') {
    return {
      code: error.code,
      id: error.errorId,
      errors: error.validation_errors.flatMap((err) => {
        if (err.dataset === 'fertility') return processFertilityErrors(err);
        if (err.dataset === 'overseas-migration')
          return processOverseasMigrationErrors(err);
        if (err.dataset === 'interstate-migration')
          return processInterstateMigrationErrors(err);
        if (err.dataset === 'intrastate-migration')
          return processIntrastateMigrationErrors(err);
        if (err.dataset === 'life-expectancy')
          return processLifeExpectancyErrors(err);
        if (err.dataset === 'dwelling-projection')
          return processDwellingProjectionErrors(err);
        return newError('unknown');
      }),
    };
  }
  if (error.code === 'upload-missing-file') {
    const dataset = {
      'table.dpie.tfr': 'fertility',
      'table.dpie.net-overseas-migration': 'overseas-migration',
      'table.dpie.net-interstate-migration': 'interstate-migration',
      'table.dpie.net-intrastate-migration': 'intrastate-migration',
      'table.dpie.life-expectancy-at-birth': 'life-expectancy',
      'table.dpie.hum-dwelling-projection': 'dwelling-projection',
    }[error.file];
    return {
      code: error.code,
      id: error.errorId,
      errors: [
        newError('upload-error', {
          dataset,
          file: error.file,
        }),
      ],
    };
  }
  if (error.code === 'dpie-population-csv-read-error') {
    const dataset = {
      'table.dpie.tfr': 'fertility',
      'table.dpie.net-overseas-migration': 'overseas-migration',
      'table.dpie.net-interstate-migration': 'interstate-migration',
      'table.dpie.net-intrastate-migration': 'intrastate-migration',
      'table.dpie.life-expectancy-at-birth': 'life-expectancy',
      'table.dpie.hum-dwelling-projection': 'dwelling-projection',
    }[error.dataset_name];
    return {
      code: error.code,
      id: error.errorId,
      errors: [
        newError('csv-read-error', {
          dataset,
          file: error.dataset_name,
        }),
      ],
    };
  }

  return {
    code: get(error, 'code', null),
    id: get(error, 'errorId', null),
    errors: [newError('unknown')],
  };
};

const { reducer, actions } = createSlice({
  name: 'population',
  initialState: {},

  reducers: {
    discardDraft: (state) => pipe(unset('draft'), unset('error'))(state),
    discardError: (state) => unset('error', state),
  },

  extraReducers: (builder) => {
    builder.addCase(populationDatasetFetch.fulfilled, (state, action) =>
      set(['datasets', action.payload.version], action.payload, state),
    );

    builder.addCase(populationDatasetUpload.pending, (state) =>
      unset('error', state),
    );

    builder.addCase(populationDatasetUpload.fulfilled, (state, action) =>
      pipe(
        set('draft', action.payload.version),
        set(['datasets', action.payload.version], action.payload),
      )(state),
    );

    builder.addCase(populationDatasetUpload.rejected, (state, action) =>
      set(
        'error',
        errorify(defaultTo({}, get(['payload', 'data'], action))),
        state,
      ),
    );

    builder.addCase(workspacePatch.pending, (state) => unset('draft', state));
  },
});

export { actions, reducer };
