import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash/fp';
import { dataFetch } from 'actions/dataFetch';

const { reducer, actions } = createSlice({
  name: 'data',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(dataFetch.rejected.type, (state, action) => {
      const { requestId } = action.meta;
      const { data } = action.payload;

      return set(requestId, { type: 'error', data }, state);
    });

    builder.addCase(dataFetch.fulfilled.type, (state, action) => {
      const { requestId } = action.meta;
      const query = action.meta.arg.capsule;
      const { type, data } = action.payload;

      return type === 'complete'
        ? set(requestId, { type, query, data }, state)
        : state;
    });
  },
});

export { reducer, actions };
