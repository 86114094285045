import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const sharingPermissionAdd = createAsyncThunk(
  'sharing-permission/add',
  async (
    {
      sourceIdentities,
      permissionLevel,
      emailData,
      memberOrganisationIds,
      sharer,
      workspaceId,
    },
    { dispatch, getState },
  ) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/sharing/add-permission`, {
      targetIdentity: `workspace/${workspaceId}`,
      sourceIdentities,
      permissionLevel,
      emailData,
      memberOrganisationIds,
      sharer,
    });

    return response.data;
  },
);

export { sharingPermissionAdd };
