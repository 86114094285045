import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { trim } from 'lodash/fp';
import { Content, H2, InPlaceInput, Stack, Text } from '@kinesis/bungle';
import scenarioSelector from 'selectors/scenarioSelector';
import { ScenarioLayerMenu } from 'components/toolbox/sections/scenario-layer-menu';
import AttributeSettingsSection from 'components/toolbox/sections/attribute-settings-section/AttributeSettingsSection';
import { ResourceSection } from 'components/toolbox/sections/resource-section';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { scenarioUpdate } from 'actions/scenarioUpdate';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import useAction from 'hooks/useAction';
import usePermission from 'hooks/usePermission';

const propTypes = {
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const ScenarioPane = ({ privacy, workspaceId }) => {
  const { view } = useParams();
  const layerMenuDisabled = view !== 'map';
  const editor = usePermission('editor');
  const scenarioId = useScenarioId();
  const { name, nameInitialized, description } =
    useSelectorWithProps(scenarioSelector, {
      public: privacy === 'public',
      scenarioId,
      workspaceId,
    }) ?? {};
  const location = useLocation();
  const { autoSelect = false } = location.state || {};
  const handleUpdate = useAction(scenarioUpdate);

  const handleNameChange = (value) => {
    const newName = trim(value);
    if (newName.length) {
      handleUpdate({ scenarioId, fields: { name: newName } });
    }
  };
  const handleDescriptionChange = (value) => {
    handleUpdate({ scenarioId, fields: { description: trim(value) } });
  };

  return (
    <ToolboxPanel fadeScroll>
      <Content
        as='header'
        padding='medium'
        paddingMode={privacy === 'public' && description ? 'extended' : 'equal'}
      >
        {privacy === 'public' ? (
          <Stack space='medium'>
            <H2>{name}</H2>
            {description && <Text>{description}</Text>}
          </Stack>
        ) : (
          <Stack space='medium'>
            <InPlaceInput
              autoFocus={autoSelect}
              disabled={!editor}
              onChange={handleNameChange}
              placeholder='New scenario'
              value={nameInitialized ? name : undefined}
              variant='title'
            />
            <InPlaceInput
              onChange={handleDescriptionChange}
              disabled={!editor}
              placeholder='Write a description'
              value={description || ''}
            />
          </Stack>
        )}
      </Content>
      <ScenarioLayerMenu
        disabled={layerMenuDisabled}
        privacy={privacy}
        workspaceId={workspaceId}
      />
      {privacy !== 'public' && (
        <AttributeSettingsSection
          editor={editor}
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      )}
      <ResourceSection
        editor={editor}
        privacy={privacy}
        workspaceId={workspaceId}
      />
    </ToolboxPanel>
  );
};

ScenarioPane.propTypes = propTypes;
ScenarioPane.defaultProps = defaultProps;

export { ScenarioPane };
