import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const layerSelector = createSelector(
  workspaceSelector,
  (state, props) => props.layerId,
  (workspace, layerId) => get(['layers', 'entities', layerId], workspace),
);

export default layerSelector;
