import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from 'lodash/fp';
import { Draggable } from 'react-beautiful-dnd';
import { Block } from 'components/block';
import {
  DraggableWrapper,
  RawDragHandle,
} from 'components/block-grid/block-grid.styles';
import GrabberSvg from 'utils/GrabberSvg';

const Container = styled.div``;

const propTypes = {
  id: PropTypes.number.isRequired,
  span: PropTypes.number,
  startColumn: PropTypes.number,
  blockIndex: PropTypes.number,
  removeBlocks: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  blockType: PropTypes.string,
};

const defaultProps = {
  span: 6,
  startColumn: 1,
  blockIndex: 0,
  removeBlocks: noop,
  isFirst: false,
  isLast: false,
  blockType: undefined,
};

const RawBlock = ({
  id,
  span,
  startColumn,
  blockIndex,
  removeBlocks,
  blockType,
  isFirst,
  isLast,
}) => {
  const [showDragHandle, setShowDragHandle] = useState(false);
  const [darkenHandle, setDarkenHandle] = useState(false);
  const [showHoverStyles, setShowHoverStyles] = useState(false);

  const onHandleMouseDown = () => setDarkenHandle(true);
  const onHandleMouseUp = () => setDarkenHandle(false);
  const onContainerMouseEnter = () => {
    setShowDragHandle(true);
    setShowHoverStyles(false);
  };
  const onContainerMouseLeave = () => {
    setShowDragHandle(false);
    setShowHoverStyles(false);
    setDarkenHandle(false);
  };
  const onHandleMouseEnter = () => setShowHoverStyles(true);
  const onHandleMouseLeave = () => setShowHoverStyles(false);

  return (
    <Draggable key={id} draggableId={id} index={blockIndex}>
      {(provided, snapshot) => (
        <DraggableWrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          span={span}
          startColumn={startColumn}
          isDragging={snapshot.isDragging}
        >
          <Container
            onMouseEnter={onContainerMouseEnter}
            onMouseLeave={onContainerMouseLeave}
            onMouseUp={onHandleMouseUp}
          >
            <RawDragHandle
              {...provided.dragHandleProps}
              onMouseDown={onHandleMouseDown}
              onMouseUp={onHandleMouseUp}
              onMouseEnter={onHandleMouseEnter}
              onMouseLeave={onHandleMouseLeave}
              darken={darkenHandle || snapshot.isDragging}
              visible={showDragHandle || snapshot.isDragging}
              showHoverStyles={showHoverStyles}
              blockType={blockType}
            >
              <GrabberSvg />
            </RawDragHandle>
            <Block
              key={id}
              id={id}
              removeBlocks={removeBlocks}
              isFirst={isFirst}
              isLast={isLast}
            />
          </Container>
        </DraggableWrapper>
      )}
    </Draggable>
  );
};

RawBlock.propTypes = propTypes;
RawBlock.defaultProps = defaultProps;

export default RawBlock;
