import styled from 'styled-components';
import { position } from 'polished';

const TableResizeBarImpl = styled('div')(
  ({ theme }) => ({
    backgroundClip: 'content-box',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: '0 2px',
    boxSizing: 'content-box',
    cursor: 'col-resize',
    marginLeft: -3,
    ...position('absolute', 0, null),
    width: 1,
    userSelect: 'none',
    zIndex: 25,
    '&:hover': {
      backgroundColor: theme.colors.gray5.hex,
    },
  }),
  ({ left }) => ({ left }),
  ({ highlight, theme }) =>
    highlight ? { backgroundColor: theme.colors.gray5.hex } : undefined,
);

const TableResizeBarOverlay = styled('div')({
  ...position('fixed', 0),
});

export { TableResizeBarImpl, TableResizeBarOverlay };
