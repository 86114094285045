import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, map, pipe } from 'lodash/fp';
import createCdnApi from 'api/createCdnApi';
import { normaliseVersion } from 'patches/publicWorkspaces';

const publicWorkspaceFetch = createAsyncThunk(
  'publicWorkspaceFetch',
  async ({ key }, { rejectWithValue }) => {
    try {
      const api = createCdnApi();
      const { data: version } = await api.get(`workspace/${key}`);
      const { data: workspaceData } = await api.get(
        `workspace/${version}/workspace.json`,
      );

      const scenarioKeys = pipe(
        get('scenarios'),
        map('scenario'),
      )(workspaceData);

      const scenarios = await Promise.all(
        scenarioKeys.map(async (scenario) => {
          const scenarioResponse = await api.get(
            `workspace/${version}/scenarios/${scenario}.json`,
          );
          return scenarioResponse.data;
        }),
      );

      // FIX this should happen on demand for a board, rather than upfront.
      const boardIds = pipe(get('boards'), map('id'))(workspaceData);

      const boards = await Promise.all(
        boardIds.map(async (boardId) => {
          const boardResponse = await api.get(
            `workspace/${version}/boards/${boardId}.json`,
          );
          return boardResponse.data;
        }),
      );

      const normalised = normaliseVersion({
        ...workspaceData,
        attributes: scenarios,
        boards,
      });
      return normalised;
    } catch (err) {
      return rejectWithValue('Something went wrong');
    }
  },
);

export { publicWorkspaceFetch };
