import PropTypes from 'prop-types';
import { isEmpty, noop, set, isNil, get, sortBy } from 'lodash/fp';
import {
  Pill,
  Placeholder,
  Select,
  Stack,
  UtilityButton,
} from '@kinesis/bungle';
import { Loading } from 'components/loading';
import { PlaceholderMessage } from 'components/placeholder-message';
import { Cell } from 'components/cell';
import { Row } from './modifications.styles';

const propTypes = {
  table: PropTypes.string.isRequired,
  modifications: PropTypes.array,
  options: PropTypes.object,
  onChangeModification: PropTypes.func,
  onDeleteModification: PropTypes.func,
};

const defaultProps = {
  modifications: undefined,
  options: {},
  onChangeModification: noop,
  onDeleteModification: noop,
};

const tables = {
  'table:dpie:tfr': {
    modifications: [
      { key: 'region', title: 'Region', items: 'Regions', type: 'select' },
      { key: 'year', title: 'Year', items: 'Years', type: 'select' },
      { key: 'percent_change', title: 'Percentage change', type: 'percent' },
    ],
  },
  'table:dpie:life-expectancy-at-birth': {
    modifications: [
      { key: 'region', title: 'Region', items: 'Regions', type: 'select' },
      { key: 'year', title: 'Year', items: 'Years', type: 'select' },
      { key: 'sex', title: 'Sex', items: 'Sexes', type: 'select' },
      { key: 'percent_change', title: 'Percentage change', type: 'percent' },
    ],
  },
  'table:dpie:net-overseas-migration': {
    modifications: [
      { key: 'region', title: 'Region', items: 'Regions', type: 'select' },
      { key: 'year', title: 'Year', items: 'Years', type: 'select' },
      { key: 'absolute_change', title: 'Change', type: 'absolute' },
    ],
  },
  'table:dpie:net-interstate-migration': {
    modifications: [
      { key: 'region', title: 'Region', items: 'Regions', type: 'select' },
      { key: 'year', title: 'Year', items: 'Years', type: 'select' },
      { key: 'absolute_change', title: 'Change', type: 'absolute' },
    ],
  },
  'table:dpie:net-intrastate-migration': {
    modifications: [
      { key: 'region', title: 'Region', items: 'Regions', type: 'select' },
      { key: 'year', title: 'Year', items: 'Years', type: 'select' },
      { key: 'absolute_change', title: 'Change', type: 'absolute' },
    ],
  },
  'table:dpie:hum-dwelling-projection': {
    modifications: [
      { key: 'region', title: 'Region', items: 'Regions', type: 'select' },
      { key: 'year', title: 'Year', items: 'Years', type: 'select' },
      {
        key: 'attached_percent_change',
        title: 'Attached',
        type: 'percent',
      },
      {
        key: 'detached_percent_change',
        title: 'Detatched',
        type: 'percent',
      },
      {
        key: 'residents_npds_percent_change',
        title: 'Residents of NPDs',
        type: 'percent',
      },
    ],
  },
};

const Modifications = ({
  table,
  options,
  modifications,
  onChangeModification,
  onDeleteModification,
}) => {
  if (!modifications) {
    return <Loading />;
  }
  const schema = get([table, 'modifications'], tables);
  return (
    <PlaceholderMessage
      text='There are no variations to this data'
      isEmpty={isEmpty(modifications)}
    >
      <Stack space='none'>
        <Row>
          {schema.map(({ key, title }) => (
            <Cell appearance='dark' header key={key}>
              {title}
            </Cell>
          ))}
          <Cell appearance='dark' header>
            {' '}
          </Cell>
        </Row>
        {modifications.map((modification, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={index}>
            {schema.map(({ key, type, items }) => {
              const value = modification[key];
              if (type === 'select') {
                return (
                  <Cell variant='bare' key={key}>
                    <Select
                      appearance='bare'
                      magnitude='small'
                      options={sortBy(
                        'value',
                        options[key].map((option) => ({
                          label: option,
                          value: option,
                        })),
                      )}
                      value={value}
                      search
                      itemsLabel={items}
                      onChange={(v) =>
                        onChangeModification(table, index, set(key, v))
                      }
                    />
                  </Cell>
                );
              }
              if (type === 'percent') {
                return (
                  <Cell
                    key={key}
                    numeric
                    editable
                    value={value || 0}
                    minimum={-100}
                    onSave={(v) =>
                      onChangeModification(table, index, set(key, v))
                    }
                  >
                    {isNil(value) && (
                      <Placeholder>Enter a variation (%)</Placeholder>
                    )}
                    {!isNil(value) && (
                      <>
                        {value} <Pill>%</Pill>
                      </>
                    )}
                  </Cell>
                );
              }
              if (type === 'absolute') {
                return (
                  <Cell
                    key={key}
                    numeric
                    editable
                    value={value || 0}
                    onSave={(v) =>
                      onChangeModification(table, index, set(key, v))
                    }
                  >
                    {isNil(value) && (
                      <Placeholder>Enter a variation</Placeholder>
                    )}
                    {!isNil(value) && value}
                  </Cell>
                );
              }
              return null;
            })}
            <Cell>
              <UtilityButton
                icon='delete'
                variant='danger'
                magnitude='small'
                onClick={() => onDeleteModification(table, index)}
              />
            </Cell>
          </Row>
        ))}
      </Stack>
    </PlaceholderMessage>
  );
};

Modifications.defaultProps = defaultProps;
Modifications.propTypes = propTypes;
const modificationsSchema = tables;

export { Modifications, modificationsSchema };
