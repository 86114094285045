import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

// NOTE: static block progress API only, hopefully superseded by new lifecycle
const blockProgress = createAsyncThunk(
  'block-progress/fetch',
  async ({ workspaceId, scenarios }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(
      `/api/workspace/${workspaceId}/block-progress`,
      {
        scenarios,
      },
    );
    return response.data;
  },
);

export { blockProgress };
