import { createSelector } from 'reselect';
import { get, pipe, identity } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const workspaceGeographiesSelector = createSelector(
  pipe(workspaceSelector, get('geography')),
  identity,
);

export default workspaceGeographiesSelector;
