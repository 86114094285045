import { createSlice } from '@reduxjs/toolkit';
import { get, keyBy, set, update, toPairs, reduce } from 'lodash/fp';
import { userLogin } from 'actions/userLogin';
import { userRelogin } from 'actions/userRelogin';

const { reducer, actions } = createSlice({
  name: 'featureFlags',
  initialState: {},

  reducers: {
    setFeatureFlag(state, action) {
      const { key, value } = action.payload;
      return set([key, 'enabled'], !!value, state);
    },
    setFeatureFlags(state, action) {
      return reduce(
        (acc, [key, value]) => set([key, 'enabled'], value, acc),
        state,
        toPairs(action.payload),
      );
    },
    toggleFeatureFlag(state, action) {
      const { key } = action.payload;
      return update([key, 'enabled'], (value) => !value, state);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) =>
      keyBy('name', get('flags', action.payload)),
    );

    builder.addCase(userRelogin.fulfilled, (state, action) =>
      keyBy('name', get('flags', action.payload)),
    );
  },
});

export { reducer, actions };
