import { createAsyncThunk } from '@reduxjs/toolkit';
import { concat } from 'lodash/fp';
import createApiFromState from 'api/createApiFromState';
import appUpgradesMissingDependencySelector from 'selectors/appUpgradesMissingDependencySelector';

const appsUpgrade = createAsyncThunk(
  'apps/upgrade',
  async ({ upgrades, workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const state = getState();
    const dependencies = appUpgradesMissingDependencySelector(state, {
      upgrades,
      workspaceId,
    });
    const response = await api.post(
      `/api/workspace/${workspaceId}/upgrade-apps`,
      { upgrades: concat(upgrades, dependencies) },
    );
    return response.data;
  },
);

export { appsUpgrade };
