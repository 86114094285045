import { createSelector } from 'reselect';
import { get, pipe, set, zipWith } from 'lodash/fp';

import blockValueSelector from 'selectors/blockValueSelector';
import highchartsDataTransformerSelector from 'selectors/highchartsDataTransformerSelector';
import { pickQualitative } from 'utils/colors';
import { qualitativeColors } from 'settings/colors';

const genericChartCapsuleSelector = createSelector(
  pipe(blockValueSelector, get('chart')),
  highchartsDataTransformerSelector,
  (
    chart,
    { data: highchartsData, categories, xUnit, yBounds: { yMin, yMax } },
  ) => {
    const { x, y, type: chartType } = chart;

    const chartDataWithColours = zipWith(
      (color, data) => set('color', color, data),
      pickQualitative(qualitativeColors, highchartsData.length),
      highchartsData,
    );

    let type;
    switch (chartType) {
      case 'line':
        type = 'line';
        break;
      case 'stacked-area':
        type = 'area';
        break;
      default:
        type = 'column';
    }

    const options = {
      chart: { type },
      yAxis: { title: { text: y.title }, min: yMin, max: yMax },
      xAxis: {
        categories,
        labels: {
          formatter: (o) => xUnit.format(o.value),
        },
        crosshair: { color: '#e9f3f7', zIndex: -1 },
        title: { text: x.title },
      },
      plotOptions: {
        column: {
          stacking:
            chartType === 'column' || chartType === 'stacked-column'
              ? 'normal'
              : undefined,
        },
        series: { marker: { symbol: 'circle' } },
      },
      series: chartDataWithColours,
    };

    return {
      categories,
      options,
      xUnit,
      series: chartDataWithColours,
    };
  },
);

export default genericChartCapsuleSelector;
