import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link, Navigate } from 'react-router-dom';
import {
  every,
  filter,
  find,
  findLast,
  get,
  isEmpty,
  last,
  lte,
  noop,
  orderBy,
  pipe,
  placeholder,
  some,
} from 'lodash/fp';
import { format, parseISO } from 'date-fns/fp';
import {
  AnimatedPopover,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  Input,
  Modal,
  Prompt,
  Tab,
  TabBar,
  TabList,
  TabMenu,
  TabMenuItem,
  TabPanelActive,
  TabPanels,
  Tabs,
  Tooltip,
  UtilityButton,
} from '@kinesis/bungle';
import {
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
  ToolbarSeparator,
} from '@kinesis/bungle/legacy';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import attributeKeysSelector from 'selectors/attributeKeysSelector';
import scenarioDatesSelector from 'selectors/scenarioDatesSelector';
import scenarioLayersSelector from 'selectors/scenarioLayersSelector';
import {
  Table,
  TableCell,
  TableRow,
  TableText,
  TableTitle,
} from 'components/table';
import AttributeValues from 'components/modals/attribute-values/AttributeValues';
import LayerCircle from 'components/layer-circle/LayerCircle';
import LayerSelect from 'components/layer-select/LayerSelect';
import LocationsImporter from 'components/locations-importer/LocationsImporter';
import NewLocation from 'components/modals/new-location/NewLocation';
import {
  GEOGRAPHY,
  LAND,
  RAINWATER,
  RECYCLED_WATER,
  SERVICE,
  SOLAR,
  USAGE,
} from 'constants/attributeTypes';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import useKeyedList from 'hooks/useKeyedList';
import usePermission from 'hooks/usePermission';
import { makeKeyboardShortcut } from 'utils/keyboardUtils';
import LocationsTableRow from './locations-table-row';
import {
  LocationsTableWrapper,
  LocationsTableInner,
  LocationsTableHead,
  LocationsTableTitle,
  LocationsTableBody,
  LocationsTableSubtitleText,
  LocationsTableTabBarWrapper,
} from './locations-table.styles';

const IMPORT_LOCATIONS_LIST = 'IMPORT_LOCATIONS_LIST';
const IMPORT_LOCATIONS_MODAL = 'IMPORT_LOCATIONS_MODAL';
const NEW_LOCATION_LIST = 'NEW_LOCATION_LIST';
const NEW_LOCATION_MODAL = 'NEW_LOCATION_MODAL';

const toggle = (name) => (value) => (value !== name ? name : undefined);

const propTypes = {
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const LocationsTable = ({ workspaceId }) => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const importButtonRef = useRef();
  const newButtonRef = useRef();
  const [query, setQuery] = useState('');
  const [sortField, setSortField] = useState('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [overlayType, setOverlayType] = useState();
  const [overlayContentId, setOverlayContentId] = useState();
  const editor = usePermission('editor');
  const { screen, tab } = useParams();
  const scenarioId = useScenarioId();
  const attributeKeys = useSelectorWithProps(attributeKeysSelector, {
    scenarioId,
    workspaceId,
  });
  const layers = useSelectorWithProps(scenarioLayersSelector, {
    scenarioId,
    workspaceId,
  });
  const scenarioDates = useSelectorWithProps(scenarioDatesSelector, {
    scenarioId,
    workspaceId,
  });
  const scenarioDatesList = useKeyedList(scenarioDates);

  const searchShortcut = useMemo(() => makeKeyboardShortcut({ key: '/' }), []);
  const importShortcut = useMemo(() => makeKeyboardShortcut({ key: 'i' }), []);
  const newLocationShortcut = useMemo(
    () => makeKeyboardShortcut({ key: 'n' }),
    [],
  );

  const activeDate = (() => {
    if (!tab) {
      return last(scenarioDates);
    }

    return findLast(lte(placeholder, tab), scenarioDates) ?? scenarioDates[0];
  })();

  useEffect(() => {
    if (scenarioId) {
      dispatch(
        toolboxActions.select({
          pane: 'context',
          selection: { id: scenarioId, type: 'scenario' },
        }),
      );
    }
  }, [dispatch, scenarioId]);

  const handleSortChange = useCallback(
    (fieldName) => {
      setSortField(fieldName);
      setSortDirection((sortDir) =>
        fieldName === sortField && sortDir === 'asc' ? 'desc' : 'asc',
      );
    },
    [sortField],
  );

  const handleAttributeEdit = useCallback((attributeType, locationId) => {
    setOverlayType(attributeType);
    setOverlayContentId(locationId);
  }, []);

  const handleOverlayClose = useCallback(() => {
    setOverlayType(undefined);
    setOverlayContentId(undefined);
  }, []);

  const modifiedLayers = useMemo(
    () =>
      layers.flatMap((layer) => ({
        ...layer,
        locations: pipe(
          filter((location) =>
            location.label.toLowerCase().includes(query.trim().toLowerCase()),
          ),
          orderBy(sortField, sortDirection),
        )(layer.locations),
      })),
    [layers, query, sortDirection, sortField],
  );

  const tableHasRows = useMemo(
    () => some((layer) => layer.locations.length > 0, modifiedLayers),
    [modifiedLayers],
  );

  const [showPrompts, setShowPrompts] = useState(
    every(pipe(get('locations'), isEmpty), modifiedLayers),
  );

  if (scenarioDates.length > 0 && tab !== activeDate) {
    return <Navigate to={`../locations-by-attribute/${activeDate}`} />;
  }

  return (
    <>
      <LocationsTableWrapper>
        <LocationsTableHead>
          <Toolbar justify='space-between'>
            <ToolbarGroup>
              <ToolbarItem>
                <DropdownMenu justify='start'>
                  <Tooltip title='Sort'>
                    <DropdownMenuButton
                      as={UtilityButton}
                      icon='sort'
                      magnitude='small'
                    />
                  </Tooltip>
                  <DropdownMenuList>
                    <DropdownMenuItem
                      onSelect={() => handleSortChange('id')}
                      variant={sortField === 'id' ? 'accent' : undefined}
                    >
                      ID
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onSelect={() => handleSortChange('label')}
                      variant={sortField === 'label' ? 'accent' : undefined}
                    >
                      Name
                    </DropdownMenuItem>
                  </DropdownMenuList>
                </DropdownMenu>
              </ToolbarItem>
              <ToolbarSeparator />
              <ToolbarItem>
                <Input
                  ref={inputRef}
                  maxWidth={272}
                  placeholder='Filter locations…'
                  keyboardShortcut={searchShortcut}
                  onChange={setQuery}
                  value={query}
                />
              </ToolbarItem>
            </ToolbarGroup>
            <ToolbarGroup justify='end'>
              <ToolbarItem>
                <Prompt
                  offset={4}
                  onDismiss={() => setShowPrompts(false)}
                  placement='bottom'
                  title={`Import locations… (${importShortcut})`}
                  showPrompt={showPrompts}
                >
                  <Tooltip
                    offset={0}
                    disabled={showPrompts}
                    placement='bottom'
                    title={`Import locations… (${importShortcut})`}
                  >
                    <UtilityButton
                      data-testid='import-locations'
                      ref={importButtonRef}
                      disabled={!editor}
                      expanded={overlayType === IMPORT_LOCATIONS_LIST}
                      hasPopup
                      icon='import'
                      keyboardShortcut={importShortcut}
                      magnitude='small'
                      onClick={() =>
                        setOverlayType(toggle(IMPORT_LOCATIONS_LIST))
                      }
                    />
                  </Tooltip>
                </Prompt>
              </ToolbarItem>
              <ToolbarItem ref={newButtonRef}>
                <Prompt
                  offset={4}
                  onDismiss={() => setShowPrompts(false)}
                  placement='top'
                  title={`New location… (${newLocationShortcut})`}
                  showPrompt={showPrompts}
                >
                  <UtilityButton
                    data-testid='new-location'
                    disabled={!editor}
                    expanded={overlayType === NEW_LOCATION_LIST}
                    hasPopup
                    icon='add-location'
                    keyboardShortcut={newLocationShortcut}
                    magnitude='small'
                    onClick={() => {
                      setShowPrompts(false);
                      setOverlayType(toggle(NEW_LOCATION_LIST));
                    }}
                  >
                    New location
                  </UtilityButton>
                </Prompt>
              </ToolbarItem>
            </ToolbarGroup>
          </Toolbar>
        </LocationsTableHead>
        <Tabs
          activeKey={get('key', find({ value: activeDate }, scenarioDatesList))}
          onChange={noop} // onChange required to make Tabs a controlled component
        >
          {scenarioDates.length > 0 && (
            <LocationsTableTabBarWrapper>
              <TabBar>
                <TabList>
                  {scenarioDatesList.map(({ key, value }) => (
                    <Tab
                      key={key}
                      as={Link}
                      to={`../${screen}/${value}`}
                      tabKey={key}
                      title={format('d MMM yyyy', parseISO(value))}
                    >
                      {false && ( // TODO
                        <TabMenu>
                          <TabMenuItem disabled>Change date</TabMenuItem>
                          <TabMenuItem disabled variant='danger'>
                            Remove
                          </TabMenuItem>
                        </TabMenu>
                      )}
                    </Tab>
                  ))}
                </TabList>
              </TabBar>
            </LocationsTableTabBarWrapper>
          )}
          <TabPanels>
            <TabPanelActive>
              <LocationsTableBody>
                <LocationsTableInner>
                  <Table
                    minColumnWidth={40}
                    resizable
                    stickyColumnCount={tableHasRows ? 2 : 0}
                    stickyRowCount={2}
                  >
                    {modifiedLayers.map((layer) => (
                      <Fragment key={layer.id}>
                        <TableRow head stickToRow={0}>
                          <TableTitle>
                            <LocationsTableTitle>
                              <LayerCircle
                                layerId={layer.id}
                                workspaceId={workspaceId}
                              />
                              <LocationsTableSubtitleText>
                                {layer.label}
                              </LocationsTableSubtitleText>
                            </LocationsTableTitle>
                          </TableTitle>
                        </TableRow>
                        {layer.locations.length > 0 && (
                          <TableRow stickToRow={1}>
                            <TableCell appearance='dark' variant='heading'>
                              <TableText>ID</TableText>
                            </TableCell>
                            <TableCell flex={2} variant='heading'>
                              <TableText>Location name</TableText>
                            </TableCell>
                            {attributeKeys.includes(GEOGRAPHY) && (
                              <TableCell variant='heading'>
                                <TableText>Position</TableText>
                              </TableCell>
                            )}
                            {attributeKeys.includes(LAND) && (
                              <TableCell align='right' variant='heading'>
                                <TableText>Land area</TableText>
                              </TableCell>
                            )}
                            {attributeKeys.includes(USAGE) && (
                              <TableCell align='right' variant='heading'>
                                <TableText>Usage</TableText>
                              </TableCell>
                            )}
                            {attributeKeys.includes(SERVICE) && (
                              <TableCell align='right' variant='heading'>
                                <TableText>Services</TableText>
                              </TableCell>
                            )}
                            {attributeKeys.includes(SOLAR) && (
                              <TableCell align='right' variant='heading'>
                                <TableText>Solar</TableText>
                              </TableCell>
                            )}
                            {attributeKeys.includes(RAINWATER) && (
                              <TableCell align='right' variant='heading'>
                                <TableText>Rainwater storage</TableText>
                              </TableCell>
                            )}
                            {attributeKeys.includes(RECYCLED_WATER) && (
                              <TableCell align='right' variant='heading'>
                                <TableText>Recycled water</TableText>
                              </TableCell>
                            )}
                            <TableCell align='right' variant='heading' />
                          </TableRow>
                        )}
                        {layer.locations.map((location) => (
                          <LocationsTableRow
                            key={location.id}
                            activeDate={activeDate}
                            attributeKeys={attributeKeys}
                            editor={editor && layer.type !== 'synthetic'}
                            id={location.id}
                            layerId={layer.id}
                            onAttributeEdit={handleAttributeEdit}
                            scenarioId={scenarioId}
                            workspaceId={workspaceId}
                          />
                        ))}
                      </Fragment>
                    ))}
                  </Table>
                </LocationsTableInner>
              </LocationsTableBody>
            </TabPanelActive>
          </TabPanels>
        </Tabs>
      </LocationsTableWrapper>
      <AnimatedPopover
        justify='start'
        offset={4}
        open={overlayType === NEW_LOCATION_LIST}
        placement='bottom'
        targetRef={newButtonRef}
      >
        <LayerSelect
          appearance='neat'
          buttonRef={newButtonRef}
          closeable={overlayType === NEW_LOCATION_LIST}
          onClose={() => {
            setOverlayType((type) =>
              type === NEW_LOCATION_LIST ? undefined : type,
            );
          }}
          onSelect={(lid) => {
            setOverlayType(NEW_LOCATION_MODAL);
            setOverlayContentId(lid);
          }}
          workspaceId={workspaceId}
        />
      </AnimatedPopover>
      <AnimatedPopover
        justify='start'
        offset={4}
        open={overlayType === IMPORT_LOCATIONS_LIST}
        placement='bottom'
        targetRef={importButtonRef}
      >
        <LayerSelect
          appearance='neat'
          buttonRef={importButtonRef}
          closeable={overlayType === IMPORT_LOCATIONS_LIST}
          onClose={handleOverlayClose}
          onSelect={(lid) => {
            setOverlayType(IMPORT_LOCATIONS_MODAL);
            setOverlayContentId(lid);
          }}
          workspaceId={workspaceId}
        />
      </AnimatedPopover>
      {overlayType === NEW_LOCATION_MODAL && (
        <Modal
          height={600}
          onClose={handleOverlayClose}
          width={840}
          aria-label='New location'
        >
          <NewLocation
            layerId={overlayContentId}
            onClose={handleOverlayClose}
            scenarioId={scenarioId}
            workspaceId={workspaceId}
          />
        </Modal>
      )}
      {overlayType === IMPORT_LOCATIONS_MODAL && (
        <Modal
          height={600}
          onClose={handleOverlayClose}
          width={840}
          aria-label='Import locations'
        >
          <LocationsImporter
            layerId={overlayContentId}
            onCancel={handleOverlayClose}
            scenarioId={scenarioId}
            workspaceId={workspaceId}
          />
        </Modal>
      )}
      {attributeKeys.includes(overlayType) && (
        <AttributeValues
          attributeKey={overlayType}
          initialDate={activeDate}
          locationId={overlayContentId}
          onClose={handleOverlayClose}
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      )}
    </>
  );
};

LocationsTable.propTypes = propTypes;
LocationsTable.defaultProps = defaultProps;

export { LocationsTable };
