import PropTypes from 'prop-types';
import { Stack } from '@kinesis/bungle';
import Filter from 'components/form/Filter';
import { ToolboxSection } from 'components/toolbox/toolbox-section';

const propTypes = {
  contexts: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      format: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.array.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      type: PropTypes.oneOf(['select']),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

const ContextsSection = ({ contexts, onChange }) => (
  <ToolboxSection collapsible title='Perspective'>
    <Stack space='medium'>
      {contexts.map((filter) => (
        <Filter
          key={filter.key}
          filterKey={filter.key}
          format={filter.format}
          label={filter.label}
          onChange={onChange}
          options={filter.options}
          type={filter.type}
          value={filter.value}
        />
      ))}
    </Stack>
  </ToolboxSection>
);

ContextsSection.propTypes = propTypes;

export default ContextsSection;
