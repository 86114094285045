import { useTheme } from 'styled-components';
import { Icon, Stack, Text } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { PlaceholderContainer } from './pathways-visualisations.styles';

const PathwaysVisualisationsGenerating = () => {
  const theme = useTheme();

  return (
    <PlaceholderContainer>
      <Content height='100%' alignY='center'>
        <Stack alignX='center' space='small'>
          <Icon
            color={theme.color.blue6}
            animation='rotate'
            type='spinner'
            magnitude='large'
          />
          <Text>Generating preview...</Text>
        </Stack>
      </Content>
    </PlaceholderContainer>
  );
};

export { PathwaysVisualisationsGenerating };
