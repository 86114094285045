import { flatten } from 'lodash/fp';
import chroma from 'chroma-js';

const mixWithWhite = (color) => chroma(color).mix('white', 0.55, 'rgb').css();

const selectLinePoint = (point) => {
  const {
    series,
    series: { color },
  } = point;

  point.update(
    {
      selected: true,
      marker: {
        enabled: true,
        states: {
          select: {
            radius: 4,
            lineWidth: 0,
            fillColor: color,
          },
        },
      },
    },
    false,
    false,
  );

  // lighten the lines
  series.update({ color: mixWithWhite(color) }, false);
};

const deselectLinePoint = (point) => {
  point.update(
    {
      selected: false,
      marker: {
        fillColor: mixWithWhite(point.series.color),
      },
    },
    false,
    false,
  );

  // no need to change line colours here because that happens
  // in the select function
};

const returnLinesToUnselectedState = (points, chart) => {
  points.forEach((point) => {
    point.update(
      {
        selected: false,
        marker: { fillColor: point.series.color },
      },
      false,
      false,
    );
  });

  // restore original line colour
  chart.series.forEach((s) => {
    s.update({ color: s.color }, false);
  });
};

const deselectLinePoints = (chart) => {
  const allPoints = flatten(chart.series.map((s) => s.points));
  allPoints.forEach((point) => {
    deselectLinePoint(point);
  });
};

const selectLinePoints = (chart, value) => {
  const allPoints = flatten(chart.series.map((s) => s.points));
  // NOTE: it is important to action all deselects, then all
  // selects in a different pass so that line opacity is sorted.
  allPoints.forEach((point) => {
    if (point.category !== value) {
      deselectLinePoint(point);
    }
  });
  allPoints.forEach((point) => {
    if (point.category === value) {
      selectLinePoint(point);
    }
  });
};

const selectHoveredLinePoints = (chart) => {
  if (chart.hoverPoints) {
    chart.hoverPoints.forEach((point) => {
      selectLinePoint(point);
    });
  }
};

export {
  selectLinePoint,
  deselectLinePoint,
  returnLinesToUnselectedState,
  selectLinePoints,
  deselectLinePoints,
  selectHoveredLinePoints,
};
