import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { GridPane, InputArea } from './attribute-values.styles';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  value: PropTypes.string.isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const CustomAttributeValue = ({ onChange, privacy, value }) => (
  <Layout direction='row'>
    <Layout direction='column'>
      <GridPane>
        <InputArea
          displayOnly={privacy === 'public'}
          onChange={(event) => {
            onChange('value', event.currentTarget.value);
          }}
          value={value}
          spellCheck
          wrap='soft'
          autoFocus
        />
      </GridPane>
    </Layout>
  </Layout>
);

CustomAttributeValue.propTypes = propTypes;
CustomAttributeValue.defaultProps = defaultProps;

export default CustomAttributeValue;
