import PropTypes from 'prop-types';
import { noop } from 'lodash/fp';
import { TableButtonImpl } from './table-button.styles';

const propTypes = {
  columnIndex: PropTypes.number,
  disabled: PropTypes.boolean,
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {
  columnIndex: undefined,
  disabled: false,
};

const TableButton = ({ columnIndex, disabled, onClick, ...restProps }) => (
  <TableButtonImpl
    columnIndex={columnIndex}
    disabled={disabled}
    onClick={disabled ? noop : onClick}
    {...restProps}
  />
);

TableButton.propTypes = propTypes;
TableButton.defaultProps = defaultProps;

export { TableButton };
