import { createSelector } from 'reselect';
import { get, find, pipe } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const baselineScenarioKeySelector = createSelector(
  pipe(workspaceSelector, get(['scenarios', 'named'])),
  (entities) => {
    const baseline = find('isDefault', entities) || {};
    const baselineEntry = baseline.draft || baseline.published;

    return baselineEntry ? baselineEntry.scenario : undefined;
  },
);

export default baselineScenarioKeySelector;
