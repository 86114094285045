import { get, isEmpty, map, flatMap, pipe, has, values, uniq } from 'lodash/fp';
import { dataFetch } from 'actions/dataFetch';
import { geographyFetch } from 'actions/geographyFetch';
import { geographyBundle } from 'actions/geographyBundle';

// NOTE: this API may look a little odd, the short version
// is that there is a _potential_ upside to making large
// sets of shape's cacheable gets, consider this an experiment.
// There may be ways to avoid the latency etc... but
// as a proof of concept it is worth playing with.
// So how does it work?
//  1. We fire off a request to create a bundle.
//     - store the bundle id with what ever needs it.
//     - the same set of geographies will resolve to
//       same bundle
//  2. We fire off a request fetch bundle.
//     - if and only if we don't already have a copy

function createGeographyMiddleware() {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (
        dataFetch.fulfilled.type === action.type &&
        action.payload.type === 'complete' &&
        action.payload.data.type === 'choropleth'
      ) {
        const geographies = pipe(
          get(['payload', 'data', 'data']),
          map('geography'),
        )(action);
        if (!isEmpty(geographies)) {
          dispatch(
            geographyBundle({
              dataId: action.meta.requestId,
              geographies,
            }),
          );
        }
      }
      if (
        dataFetch.fulfilled.type === action.type &&
        action.payload.type === 'complete' &&
        action.payload.data.type === 'choropleth-time-series'
      ) {
        const geographies = pipe(
          get(['payload', 'data', 'time_series']),
          values,
          flatMap(pipe(get('value'), map('geography'))),
          uniq,
        )(action);
        if (!isEmpty(geographies)) {
          dispatch(
            geographyBundle({
              dataId: action.meta.requestId,
              geographies,
            }),
          );
        }
      }
      if (geographyBundle.fulfilled.type === action.type) {
        const state = getState();
        const bundle = action.payload.id;
        if (!has(['geography', 'bundles', bundle], state)) {
          dispatch(
            geographyFetch({
              bundle,
            }),
          );
        }
      }
      next(action);
    };
}

export default createGeographyMiddleware;
