import styled from 'styled-components';

const DataGridRowGroupWrapper = styled('div').attrs({
  role: 'rowgroup',
})(({ theme }) => ({
  borderBottom: `1px solid ${theme.borderColor}`,
  borderRight: `1px solid ${theme.borderColor}`,
  '[data-grid-border-clip-bottom="true"] &:last-child': {
    borderBottomColor: 'transparent',
  },
  '[data-grid-border-clip-right="true"] &': {
    borderRightWidth: 0,
  },
}));

const DataGridRowGroupHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: '#fafafa',
  borderBottom: `1px solid ${theme.borderColor}`,
  boxSizing: 'border-box',
  color: theme.color.black9,
  display: 'flex',
  fontWeight: theme.fontWeight.medium,
  height: 31,
  overflow: 'hidden',
  padding: '0 12px',
  position: 'relative',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export { DataGridRowGroupWrapper, DataGridRowGroupHeader };
