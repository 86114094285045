import styled, { css } from 'styled-components';

const Card = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-sizing: border-box;
  border-radius: 2px;
  outline: 0;
  padding: 16px;
  margin-bottom: 16px;
  :last-of-type {
    margin-bottom: 8px;
  }

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      transition: box-shadow 0.3s;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);

      &:hover {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      }
      &:focus {
        box-shadow:
          0px 2px 8px rgba(0, 0, 0, 0.05),
          0 0 0 2px rgba(0, 0, 0, 0.05);
      }
      &:hover:focus {
        box-shadow:
          0px 2px 8px rgba(0, 0, 0, 0.1),
          0 0 0 2px rgba(0, 0, 0, 0.05);
      }
      &:active {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05) !important;
      }
    `}
`;
// 64px is 2 header icons and requisite padding.
const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ addMargin }) => (addMargin ? 16 : 0)}px;
  h4 {
    max-width: calc(100% - 64px);
    line-height: ${({ theme }) => theme.lineHeight.default};
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
  top: 14px;
`;

const ToolbarItem = styled.div`
  line-height: 1em;
  margin-left: 8px;
`;

const Separator = styled.div`
  line-height: 1em;
  display: flex;
  width: 1px;
  height: 24px;
  background: #e9e9e9;
  margin-right: 8px;
  margin-left: 16px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 4px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.span`
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.color.gray8};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Value = styled.span`
  margin-left: auto;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.gray9};
`;

export { Card, Header, Item, Label, Separator, Toolbar, ToolbarItem, Value };
