import styled from 'styled-components';

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export { Item, Label };
