import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns/fp';
import {
  Card,
  CardButton,
  CardContent,
  CardMenu,
  CardMenuItem,
  CardTitle,
  CardSubtitle,
} from '@kinesis/bungle';

import useDateDistance from 'hooks/useDateDistance';

const propTypes = {
  baselineScenarioId: PropTypes.number.isRequired,
  board: PropTypes.object.isRequired,
  editor: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const BoardItem = ({
  baselineScenarioId,
  board,
  editor,
  onDelete,
  workspaceId,
}) => {
  const distance = useDateDistance(parseISO(board.updatedAt));
  const subtitle = board.group || `Last updated ${distance}`;

  const handleDelete = useCallback(() => {
    onDelete(board);
  }, [onDelete, board]);

  return (
    <Card key={board.label} data-cy='board-item'>
      <CardButton
        as={Link}
        data-testid={board.id}
        to={`/workspaces/${workspaceId}/boards/${board.id}?scenario=${baselineScenarioId}`}
      >
        <CardContent clipRight noWrap>
          <CardTitle>{board.label}</CardTitle>
          <CardSubtitle>{subtitle}</CardSubtitle>
        </CardContent>
      </CardButton>

      <CardMenu disabled={!editor}>
        <CardMenuItem variant='danger' onSelect={handleDelete}>
          Delete
        </CardMenuItem>
      </CardMenu>
    </Card>
  );
};

BoardItem.propTypes = propTypes;
BoardItem.defaultProps = defaultProps;

export default BoardItem;
