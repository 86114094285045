import { createSelector } from 'reselect';
import { pipe, get, negate, any, has } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const workspacePublishedSelector = createSelector(
  pipe(workspaceSelector, get(['scenarios', 'named'])),
  negate(any(has('draft'))),
);

export default workspacePublishedSelector;
