import { createSelector } from 'reselect';
import { get, has, pipe, map, values, size, filter } from 'lodash/fp';
import progressSelector from './progressSelector';
import scenariosSelector from './scenariosSelector';

const scenarioKey = (scenario) =>
  get(['draft', 'scenario'], scenario) ||
  get(['published', 'scenario'], scenario);

const processingSelector = createSelector(
  pipe(progressSelector, get('scenarios')),
  scenariosSelector,
  (progress, scenarios) =>
    pipe(
      values,
      map((scenario) => ({
        id: scenario.id,
        key: scenarioKey(scenario),
        label: scenario.name,
        isBaseline: scenario.isDefault,
        complete: pipe(
          get(scenarioKey(scenario)),
          get('resources'),
          values,
          filter({ available: true }),
          size,
        )(progress),
      })),
      filter(({ key }) => has(key, progress)),
    )(scenarios),
);

export default processingSelector;
