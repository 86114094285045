import PropTypes from 'prop-types';
import { first, get } from 'lodash/fp';
import { useSelector } from 'react-redux';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import attributesSummarySelector from 'selectors/attributesSummarySelector';
import AttributeCard from 'components/attribute-card/AttributeCard';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import { useScenarioId, useSelectorWithProps } from 'hooks';

const propTypes = {
  editor: PropTypes.bool.isRequired,
  locationId: PropTypes.number.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const LocationAttributesSection = ({
  editor,
  locationId,
  privacy,
  workspaceId,
}) => {
  const scenarioId = useScenarioId();
  const selectedBlockIds = useSelector(blockSelectionsSelector);
  const attributes = useSelectorWithProps(attributesSummarySelector, {
    locationId,
    blockId: first(selectedBlockIds),
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });
  const isPatchingWorkspace = useSelector(get(['misc', 'isPatchingWorkspace']));

  return (
    <ToolboxSection collapsible title='Attributes'>
      {attributes.map((attribute) => (
        <AttributeCard
          attributeKey={attribute.attributeKey}
          editor={editor}
          entries={attribute.entries}
          isPatchingWorkspace={isPatchingWorkspace}
          isScalar={attribute.isScalar}
          key={attribute.title}
          locationId={locationId}
          modified={attribute.modified}
          privacy={privacy}
          scenarioId={scenarioId}
          title={attribute.title}
          workspaceId={workspaceId}
        />
      ))}
    </ToolboxSection>
  );
};

LocationAttributesSection.propTypes = propTypes;
LocationAttributesSection.defaultProps = defaultProps;

export default LocationAttributesSection;
