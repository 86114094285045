import { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash/fp';
import {
  Anchor,
  Avatar,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuList,
  H3,
  Icon,
  Inline,
  InlineItem,
  Subheading,
  Tooltip,
  UtilityButton,
} from '@kinesis/bungle';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { Flags } from 'components/modals/flags';
import KinesisLogo from 'components/primary-navigation/KinesisLogo';
import {
  AvatarContainer,
  HeadingWrapper,
  LogoWrapper,
  NavigationContainer,
  ToolbarLeft,
  ToolbarRight,
} from 'components/primary-navigation/primary-navigation.styles';
import { shortcuts } from '@/data/shortcuts';
import { SwitcherModal } from '@kinesis/spotlight-ui';
import {
  useSearchEntitiesQuery,
  useRecentEntitiesQuery,
  useEntityTypesQuery,
  getEntityUrl,
  getEntityTypeUrl,
} from '@/api/entities';

const propTypes = {
  firstName: PropTypes.string,
  items: PropTypes.array,
  lastName: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  organisationName: PropTypes.string,
  userPicture: PropTypes.string,
};

const defaultProps = {
  firstName: '',
  items: [],
  lastName: '',
  organisationName: null,
  userPicture: undefined,
};

const emptyList = [];

const PrimaryNavigation = ({
  firstName,
  items,
  lastName,
  onLogout,
  organisationName,
  userPicture,
}) => {
  const hasFlags = useFeatureFlag('app.flag-management');
  const { pathname } = useLocation();
  const selectedKeys = useMemo(
    () =>
      items
        .filter((item) => pathname.startsWith(item.path))
        .map((item) => item.key),
    [items, pathname],
  );
  const onClickHelp = () => {
    window.open('https://help.kinesis.org/', '_blank');
  };
  const [showFlags, setShowFlags] = useState(false);
  const toggleShowFlags = useCallback(
    () => setShowFlags((x) => !x),
    [setShowFlags],
  );
  const [entitySearch, setEntitySearch] = useState('');
  const { data: searchResponse } = useSearchEntitiesQuery(entitySearch, {
    skip: isEmpty(entitySearch),
  });
  const { data: entityTypesResponse } = useEntityTypesQuery();
  const { data: recentEntitiesResponse } = useRecentEntitiesQuery();
  const [switcherModal, setSwitcherModal] = useState(false);
  const onOpenSwitcherModal = useCallback(() => {
    setSwitcherModal(true);
  }, [setSwitcherModal]);

  const onCloseSwitcherModal = useCallback(() => {
    setSwitcherModal(false);
    setEntitySearch('');
  }, [setEntitySearch, setSwitcherModal]);

  const searchEntities = isEmpty(entitySearch)
    ? emptyList
    : searchResponse?.results;
  const recentEntities = recentEntitiesResponse || emptyList;
  const entityTypes = entityTypesResponse || emptyList;

  return (
    <NavigationContainer>
      <LogoWrapper>
        <Anchor onClick={onOpenSwitcherModal}>
          <KinesisLogo />
        </Anchor>
      </LogoWrapper>
      <ToolbarLeft>
        <Inline>
          {items.map((item) => (
            <InlineItem key={item.key}>
              <UtilityButton
                data-testid={`${item.key}-nav-button`}
                as={Link}
                to={item.path}
                displayMode='dark'
                variant={selectedKeys.includes(item.key) ? 'accent' : 'default'}
                icon={<Icon type={item.icon} magnitude='medium' />}
              >
                {item.tooltip}
              </UtilityButton>
            </InlineItem>
          ))}
        </Inline>
      </ToolbarLeft>
      <ToolbarRight>
        <Inline space='small'>
          <InlineItem>
            <Tooltip
              title={`Switch to… (${shortcuts.SWITCHER.mnemonic})`}
              placement='bottom'
              offset={8}
            >
              <UtilityButton
                icon='switcher'
                displayMode='dark'
                onClick={onOpenSwitcherModal}
                keyboardShortcut={shortcuts.SWITCHER.handler}
              >
                Switch to…
              </UtilityButton>
            </Tooltip>
          </InlineItem>
          {hasFlags && (
            <InlineItem>
              <Tooltip title='Feature flags' placement='bottom' offset={8}>
                <UtilityButton
                  displayMode='dark'
                  variant='default'
                  onClick={toggleShowFlags}
                  icon={<Icon type='flag' magnitude='medium' />}
                />
              </Tooltip>
            </InlineItem>
          )}
          <InlineItem>
            <Tooltip
              title='Settings'
              placement='bottom'
              mouseLeaveDelay={0.0}
              offset={8}
            >
              <UtilityButton
                as='a'
                href='/settings'
                target='_blank'
                displayMode='dark'
                variant='default'
                icon={<Icon type='setting-filled' magnitude='medium' />}
              />
            </Tooltip>
          </InlineItem>

          <InlineItem>
            <Tooltip
              title='Help and resources'
              placement='bottom'
              mouseLeaveDelay={0.0}
              offset={8}
            >
              <UtilityButton
                displayMode='dark'
                variant='default'
                onClick={onClickHelp}
                icon={<Icon type='question-circle' magnitude='medium' />}
              />
            </Tooltip>
          </InlineItem>
          <InlineItem>
            <AvatarContainer>
              <DropdownMenu justify='end' placement='bottom'>
                <DropdownMenuButton
                  as={Avatar}
                  displayMode='dark'
                  image={userPicture}
                  interactive
                  magnitude='small'
                  variant='individual'
                >
                  {firstName.slice(0, 1).toUpperCase()}
                  {lastName.slice(0, 1).toUpperCase()}
                </DropdownMenuButton>
                <DropdownMenuList offset={8}>
                  <HeadingWrapper>
                    <header>
                      <H3>
                        {firstName} {lastName}
                      </H3>
                      <Subheading>{organisationName}</Subheading>
                    </header>
                  </HeadingWrapper>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onSelect={onLogout}>
                    Log out
                  </DropdownMenuItem>
                </DropdownMenuList>
              </DropdownMenu>
            </AvatarContainer>
          </InlineItem>
        </Inline>
      </ToolbarRight>
      {showFlags && <Flags onClose={toggleShowFlags} />}
      {switcherModal && (
        <SwitcherModal
          width={496}
          height={600}
          minHeight={320}
          onClose={onCloseSwitcherModal}
          onSearch={setEntitySearch}
          entityTypeList={entityTypes}
          searchResultList={searchEntities}
          recentList={recentEntities}
          getEntityUrl={getEntityUrl}
          getEntityTypeUrl={getEntityTypeUrl}
        />
      )}
    </NavigationContainer>
  );
};

PrimaryNavigation.propTypes = propTypes;
PrimaryNavigation.defaultProps = defaultProps;

export default PrimaryNavigation;
