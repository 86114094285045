import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { find, get } from 'lodash/fp';
import { Stack } from '@kinesis/bungle';
import {
  setLineTable,
  setLineTime,
  setLineScenario,
  setLineX,
  setLineY,
} from 'utils/visualisationConfigUtils';
import {
  timeTableRulesOf,
  seriesSelectCriteriaOf,
  seriesModeOf,
} from 'data/xy-chart';
import { VisualisationScenarioSelect } from 'components/visualisation-scenario-select';
import { VisualisationTimeSelect } from 'components/visualisation-time-select';
import { VisualisationTableSelect } from 'components/visualisation-table-select';
import { VisualisationSeriesSelect } from 'components/visualisation-series-select';
import { VisualisationDimensionSelect } from 'components/visualisation-dimension-select';
import { useSelectorWithProps } from 'hooks';
import blockValueSelector from 'selectors/blockValueSelector';
import tablesSelector from 'selectors/tablesSelector';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const LineConfiguration = ({ blockId, onChange, workspaceId }) => {
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId,
    workspaceId,
  });

  const tables = useSelectorWithProps(tablesSelector, { workspaceId });
  const table = find({ family: get('table', blockValue) }, tables);
  const tableIdentifier = get('resource', table);

  const handleChangeTable = useCallback(
    (selectedTable) => {
      const selectedTableResource = find({ family: selectedTable }, tables);
      onChange(setLineTable(selectedTableResource, selectedTable, blockValue));
    },
    [blockValue, onChange, tables],
  );

  const handleChangeScenario = useCallback(
    (scenario) => onChange(setLineScenario(scenario, blockValue)),
    [blockValue, onChange],
  );

  const handleChangeX = useCallback(
    (x) => {
      const selectedTableResource = find(
        { family: get('table', blockValue) },
        tables,
      );
      onChange(setLineX(selectedTableResource, x, blockValue));
    },
    [blockValue, onChange, tables],
  );

  const handleChangeY = useCallback(
    (y, time) => {
      const selectedTableResource = find(
        { family: get('table', blockValue) },
        tables,
      );
      onChange(setLineY(selectedTableResource, y, time, blockValue));
    },
    [blockValue, onChange, tables],
  );

  const handleChangeTime = useCallback(
    (value) => onChange(setLineTime(value, blockValue)),
    [blockValue, onChange],
  );

  const seriesMode = useMemo(() => seriesModeOf(blockValue), [blockValue]);

  const seriesSelectCriteria = useMemo(() => {
    const selectedTableResource = find(
      { family: get('table', blockValue) },
      tables,
    );
    return seriesSelectCriteriaOf(selectedTableResource, blockValue);
  }, [blockValue, tables]);

  const timeTableRules = useMemo(
    () => timeTableRulesOf(blockValue),
    [blockValue],
  );

  return (
    <Stack space='medium'>
      <VisualisationScenarioSelect
        type='line'
        blockId={blockId}
        value={get('scenario', blockValue)}
        onChange={handleChangeScenario}
        workspaceId={workspaceId}
      />

      <VisualisationTableSelect
        type='line'
        blockId={blockId}
        value={get('table', blockValue)}
        onChange={handleChangeTable}
        workspaceId={workspaceId}
      />

      <VisualisationDimensionSelect
        blockId={blockId}
        label='X axis'
        table={tableIdentifier}
        value={get('x', blockValue)}
        onChange={handleChangeX}
        includeScenarioDimension={
          get(['scenario', 'type'], blockValue) === 'all'
        }
        workspaceId={workspaceId}
      />

      <VisualisationSeriesSelect
        blockId={blockId}
        label='Y axis'
        table={tableIdentifier}
        value={get('y', blockValue)}
        mode={seriesMode}
        onChange={handleChangeY}
        criteria={seriesSelectCriteria}
        workspaceId={workspaceId}
      />

      <VisualisationTimeSelect
        blockId={blockId}
        table={tableIdentifier}
        value={get('time', blockValue)}
        tableRules={timeTableRules}
        onChange={handleChangeTime}
        workspaceId={workspaceId}
      />
    </Stack>
  );
};

LineConfiguration.propTypes = propTypes;

export { LineConfiguration };
