import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';
import scenarioKeySelector from 'selectors/scenarioKeySelector';
import workspaceSelector from 'selectors/workspaceSelector';

const currentAttributesSelector = createSelector(
  pipe(workspaceSelector, get('attributes')),
  scenarioKeySelector,
  (attributes, scenarioKey) => get(scenarioKey, attributes),
);

export default currentAttributesSelector;
