import { createSelector } from 'reselect';
import { defaultTo, get, has, pipe } from 'lodash/fp';
import { compile } from 'data/filters';
import { geographyOf } from 'data/geography';
import currentAttributesSelector from './currentAttributesSelector';
import locationsSelector from './locationsSelector';
import workspaceSelector from './workspaceSelector';
import workspaceGeographiesSelector from './workspaceGeographiesSelector';
import { toolboxLocationIdSelector } from './toolboxDetailSelectors';

const defaultAttributesLookup = {};

const filteredScenarioLocationsSelector = createSelector(
  locationsSelector,
  pipe(workspaceSelector, get(['layers', 'entities'])),
  workspaceGeographiesSelector,
  toolboxLocationIdSelector,
  (state, props) => props?.public,
  pipe(workspaceSelector, get('locationFilters')),
  pipe(
    currentAttributesSelector,
    get('values'),
    defaultTo(defaultAttributesLookup),
  ),
  (
    locations,
    layers,
    geographies,
    selectedLocationId,
    isPublic,
    locationFilters,
    attributesLookup,
  ) => {
    const filter = !isPublic ? compile(locationFilters) : undefined;
    const filteredLocations = locations.filter((location) => {
      if (!has(location.id, attributesLookup) || !has(location.layer, layers)) {
        return false;
      }

      if (location.id === selectedLocationId) {
        return true;
      }

      const { enabled } = layers[location.layer];

      if (!enabled) {
        return false;
      }

      if (isPublic) {
        return true;
      }

      return filter(attributesLookup[location.id]);
    });

    return filteredLocations.map((location) => {
      const geography = geographyOf(location.id, attributesLookup, geographies);
      return {
        id: location.id,
        label: location.label,
        shape: get('area', geography),
        latitude: get(['point', 'latitude'], geography),
        layer: location.layer,
        longitude: get(['point', 'longitude'], geography),
      };
    });
  },
);

export default filteredScenarioLocationsSelector;
