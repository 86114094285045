import PropTypes from 'prop-types';
import { Icon } from '@kinesis/bungle';
import useTheme from 'hooks/useTheme';

const propTypes = {
  magnitude: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),
};

const defaultProps = {
  magnitude: 'small',
};

const LoadingIcon = ({ magnitude }) => {
  const theme = useTheme();
  return (
    <Icon
      magnitude={magnitude}
      type='spinner'
      color={theme.color.blue6}
      animation='rotate'
    />
  );
};

LoadingIcon.propTypes = propTypes;
LoadingIcon.defaultProps = defaultProps;

export { LoadingIcon };
