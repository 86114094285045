import PropTypes from 'prop-types';
import { Icon, UtilityButton } from '@kinesis/bungle';
import { useSelectorWithProps } from 'hooks';
import {
  ModalHeader,
  ModalHeaderAction,
  ModalHeading,
} from 'components/modals/modal-header';
import scenarioSelector from 'selectors/scenarioSelector';

import { BaselineIconWrapper } from './new-attribute-settings.styles';

const propTypes = {
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const NewAttributeSettingsHeader = ({ scenarioId, workspaceId }) => {
  const { name, isDefault } = useSelectorWithProps(scenarioSelector, {
    scenarioId,
    workspaceId,
  });

  return (
    <ModalHeader>
      <ModalHeading>New attribute settings</ModalHeading>
      <ModalHeaderAction>
        <UtilityButton disabled dropdown>
          {name}
          {isDefault && (
            <BaselineIconWrapper>
              <Icon type='baseline' />
            </BaselineIconWrapper>
          )}
        </UtilityButton>
      </ModalHeaderAction>
      <ModalHeaderAction>
        <UtilityButton disabled icon='import' />
        <UtilityButton disabled icon='set-to-baseline' />
      </ModalHeaderAction>
    </ModalHeader>
  );
};

NewAttributeSettingsHeader.defaultProps = defaultProps;
NewAttributeSettingsHeader.propTypes = propTypes;

export default NewAttributeSettingsHeader;
