import PropTypes from 'prop-types';
import { ListColumn, RichListItemLi, Subtitle, Title } from './RichList.styles';

const propTypes = {
  avatar: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  actions: PropTypes.node,
};

const defaultProps = {
  actions: null,
};

const RichListItem = ({ avatar, title, subtitle, actions, ...props }) => (
  <RichListItemLi {...props}>
    <ListColumn>{avatar}</ListColumn>
    <ListColumn grow>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </ListColumn>
    <ListColumn> {actions} </ListColumn>
  </RichListItemLi>
);

RichListItem.propTypes = propTypes;
RichListItem.defaultProps = defaultProps;

export default RichListItem;
