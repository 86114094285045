import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { every, get, map } from 'lodash/fp';
import progressSelector from 'selectors/progressSelector';
import tablesSelector from 'selectors/tablesSelector';
import scenariosSelector from 'selectors/scenariosSelector';
import { workspaceProgress } from 'actions/workspaceProgress';
import { useSelectorWithProps } from 'hooks';

const propTypes = {
  children: PropTypes.node.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const Progress = ({ children, workspaceId }) => {
  const dispatch = useDispatch();
  const scenarios = useSelectorWithProps(scenariosSelector, { workspaceId });
  const { polling, scenarios: status } = useSelectorWithProps(
    progressSelector,
    {
      workspaceId,
    },
  );
  const tables = useSelectorWithProps(tablesSelector, { workspaceId });

  useEffect(() => {
    if (polling === 'pending') return;
    const scenarioKeys = map(
      (s) => get(['draft', 'scenario'], s) || get(['published', 'scenario'], s),
      scenarios,
    );

    const isComplete = every(
      (scenarioKey) =>
        every(
          ({ resource }) =>
            get([scenarioKey, 'resources', resource, 'available'], status),
          tables,
        ),
      scenarioKeys,
    );
    if (!isComplete && polling === 'idle') {
      dispatch(workspaceProgress({ workspaceId, scenarios: scenarioKeys }));
    }
    if (!isComplete && polling === 'polling') {
      const timerId = setTimeout(() => {
        dispatch(workspaceProgress({ workspaceId, scenarios: scenarioKeys }));
      }, 6000);
      return () => clearTimeout(timerId);
    }
  }, [dispatch, tables, workspaceId, scenarios, status, polling]);

  return children;
};

Progress.propTypes = propTypes;
Progress.defaultProps = defaultProps;

export { Progress };
