import { combineReducers } from 'redux';
import { dirtyBlocksDiscard } from 'actions/dirtyBlocksDiscard';
import { workspacesReset } from 'actions/workspacesReset';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspaceCreate } from 'actions/workspaceCreate';
import { workspaceDuplicate } from 'actions/workspaceDuplicate';
import { workspaceDelete } from 'actions/workspaceDelete';
import { workspacesFetch } from 'actions/workspacesFetch';
import { workspaceInitialise } from 'actions/workspaceInitialise';
import { pathwayPublish } from 'actions/pathwayPublish';
import { values, update, get, keyBy, map, pipe, set, unset } from 'lodash/fp';
import { reducer as workspaceBlocksReducer } from 'reducers/workspaceBlocksReducer';
import { reducer as aggregationFiltersReducer } from './aggregationFiltersReducer';
import { reducer as attributesReducer } from './attributesReducer';
import { reducer as availabilityReducer } from './availabilityReducer';
import { reducer as boardsReducer } from './boardsReducer';
import { reducer as workspaceMiscReducer } from './workspaceMiscReducer';
import { reducer as workspaceResourcesReducer } from './workspaceResourcesReducer';
import { reducer as workspaceGeographyReducer } from './workspaceGeographyReducer';
import workspaceDataReducer from './workspaceDataReducer';
import { reducer as layersReducer } from './layersReducer';
import { reducer as locationsReducer } from './locationsReducer';
import { reducer as workspaceLayerGroupsReducer } from './workspaceLayerGroupsReducer';
import { reducer as workspaceShapesReducer } from './workspaceShapesReducer';
import { reducer as workspaceAppsReducer } from './workspaceAppsReducer';
import persistenceReducer from './persistenceReducer';
import { reducer as previewReducer } from './previewReducer';
import { reducer as progressReducer } from './progressReducer';
import { reducer as recentLocationsReducer } from './recentLocationsReducer';
import { reducer as scenariosReducer } from './scenariosReducer';
import { reducer as statisticsReducer } from './statisticsReducer';
import { reducer as instantiationsReducer } from './instantiationsReducer';
import { reducer as locationFiltersReducer } from './locationFiltersReducer';
import historyReducer from './historyReducer';

const workspaceReducer = combineReducers({
  aggregationFilters: aggregationFiltersReducer,
  attributes: attributesReducer,
  availability: availabilityReducer,
  apps: workspaceAppsReducer,
  boards: boardsReducer,
  blocks: workspaceBlocksReducer,
  data: workspaceDataReducer,
  geography: workspaceGeographyReducer,
  history: historyReducer,
  instantiations: instantiationsReducer,
  locations: locationsReducer,
  layers: layersReducer,
  layerGroups: workspaceLayerGroupsReducer,
  locationFilters: locationFiltersReducer,
  misc: workspaceMiscReducer,
  persistence: persistenceReducer,
  preview: previewReducer,
  progress: progressReducer,
  recentLocations: recentLocationsReducer,
  resources: workspaceResourcesReducer,
  scenarios: scenariosReducer,
  shapes: workspaceShapesReducer,
  statistics: statisticsReducer,
});

// FIX this should be addressed, but is a new rule, and don't
// want to bundle a large refactor so just leaving the fixes.
// I *think* this will need reducers/index to default to {}
// and not undefined on logout.
// eslint-disable-next-line default-param-last
function workspacesReducer(state = {}, action) {
  if (action.type === workspacesFetch.fulfilled.type) {
    const { workspaces } = action.payload;
    return pipe(
      map((data) =>
        workspaceReducer(
          state[data.id],
          workspaceInitialise({
            data: {
              description: data.description,
              id: data.id,
              name: data.name,
              owner: data.owner,
              permissions: data.permissions,
              touchedAt: data.touched_at,
            },
            scenarios: data.scenarios,
            layers: data.layers,
          }),
        ),
      ),
      keyBy(get(['data', 'id'])),
    )(workspaces);
  }

  if (action.type === workspaceFetch.rejected.type) {
    const { workspaceId } = action.meta.arg;
    return unset(workspaceId, state);
  }

  if (
    action.type === workspaceCreate.fulfilled.type ||
    action.type === workspaceDuplicate.fulfilled.type
  ) {
    if (!action.payload) {
      return state;
    }

    const { id } = action.payload;

    return set(
      id,
      workspaceReducer(
        state[id],
        workspaceInitialise({
          data: {
            ...action.payload,
            permissions: { editor: true },
          },
        }),
      ),
      state,
    );
  }

  if (action.type === pathwayPublish.pending.type) {
    const workspace = get(['workspace', 'id'], action.meta.arg);
    const type = get(['workspace', 'type'], action.meta.arg);
    switch (type) {
      case 'existing':
        return {
          ...state,
          [workspace]: workspaceReducer(state[workspace], action),
        };
      case 'new':
      default:
        return state;
    }
  }

  if (action.type === pathwayPublish.fulfilled.type) {
    const workspace = get(['workspace', 'id'], action.payload);
    const type = get(['workspace', 'type'], action.meta.arg);

    switch (type) {
      case 'new': {
        const listing = get('workspaceListing', action.payload);
        return {
          ...state,
          [workspace]: workspaceReducer(
            state[workspace],
            workspaceInitialise({
              data: {
                description: listing.description,
                id: listing.id,
                name: listing.name,
                owner: listing.owner,
                permissions: listing.permissions,
                touchedAt: listing.touched_at,
              },
              scenarios: listing.scenarios,
              layers: listing.layers,
            }),
          ),
        };
      }
      case 'existing':
        return {
          ...state,
          [workspace]: workspaceReducer(state[workspace], action),
        };
      default:
        return state;
    }
  }

  if (action.type === workspaceDelete.fulfilled.type) {
    return unset(action.meta.arg.workspaceId, state);
  }

  if (action.type === dirtyBlocksDiscard.type) {
    const { workspaceId } = action.payload;
    const discardDirtyBlocks = pipe(
      values,
      map(
        pipe(
          (block) =>
            get('draftVersion', block) ? set('scenarios', {}, block) : block,
          unset('draftVersion'),
        ),
      ),
      keyBy('id'),
    );
    return workspaceId
      ? update([workspaceId, 'blocks'], discardDirtyBlocks, state)
      : state;
  }

  if (action.type === workspacesReset.type) {
    return {};
  }

  const workspaceId =
    action.meta?.arg?.workspaceId ?? action.payload?.workspaceId;
  const isPublic = action.meta?.arg?.isPublic;

  return workspaceId && !isPublic
    ? {
        ...state,
        [workspaceId]: workspaceReducer(state[workspaceId], action),
      }
    : state;
}

export default workspacesReducer;
