import { get } from 'lodash/fp';
import { createSelector } from 'reselect';
import workspaceSelector from './workspaceSelector';

const scenariosSelector = createSelector(
  workspaceSelector,
  (state, props) => props?.public,
  (workspace, isPublic) => {
    const path = isPublic ? ['scenarios'] : ['scenarios', 'named'];
    return get(path, workspace);
  },
);

export default scenariosSelector;
