import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const locationCreate = createAsyncThunk(
  'locations/locationCreate',
  async (
    { layer, label = '', coordinate, scenarioId, scenarioKey, workspaceId },
    { dispatch, getState },
  ) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/workspace/${workspaceId}/locations`, {
      label,
      layer,
      coordinate,
      scenarioId,
      scenarioKey,
    });
    return response.data;
  },
);

export { locationCreate };
