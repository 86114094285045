import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetImportSchema = createAsyncThunk(
  'dataset/import-schema',
  async ({ datasetId, versionId, base, schema }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/datasets/${datasetId}/actions`, {
      type: 'set-schema',
      versionId,
      base,
      schema,
    });
    return response.data;
  },
);

export { datasetImportSchema };
