import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, map, pipe } from 'lodash/fp';
import { Button, Cards } from '@kinesis/bungle';
import { ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import activeMembershipsSelector from 'selectors/activeMembershipsSelector';
import primaryMembershipSelector from 'selectors/primaryMembershipSelector';
import { appsList } from 'actions/appsList';
import appsSelector from 'selectors/appsSelector';
import organisationsByCodeSelector from 'selectors/organisationsByCodeSelector';
import { LoadableContent } from 'components/loadable-content';
import { PlaceholderMessage } from 'components/placeholder-message';
import { AppToolbar, BackButton, Breadcrumb } from 'components/app-header';
import { AppCard } from 'components/app-card';
import { AppCreate } from 'components/app-create';
import { NewAppModal } from 'components/modals/new-app';
import { PageLayout } from 'components/page-layout';
import { WorkspaceCardsWrapper } from 'components/workspace-card/workspace-card.styles';
import { ScrollableContent } from 'components/scrollable-content';

const Apps = () => {
  const dispatch = useDispatch();
  const hasFetchedApps = useSelector(get(['misc', 'hasFetchedApps']));
  const isFetchingApps = useSelector(get(['misc', 'isFetchingApps']));
  const isCreatingApp = useSelector(get(['misc', 'isCreatingApps']));
  const apps = useSelector(appsSelector);
  const organisations = useSelector(organisationsByCodeSelector);
  const memberships = useSelector(activeMembershipsSelector);
  const primaryOrganisationId = useSelector(
    pipe(primaryMembershipSelector, get(['organisation', 'id'])),
  );
  const membershipOranisations = useMemo(
    () => map('organisation', memberships),
    [memberships],
  );

  useEffect(() => {
    if (!hasFetchedApps && !isFetchingApps) {
      dispatch(appsList());
    }
  }, [dispatch, hasFetchedApps, isFetchingApps]);

  const [showNewAppModal, setShowNewAppModal] = useState(false);

  const openNewApp = useCallback(() => {
    setShowNewAppModal(true);
  }, [setShowNewAppModal]);

  const closeNewApp = useCallback(() => {
    setShowNewAppModal(false);
  }, [setShowNewAppModal]);

  if (isCreatingApp) {
    return <AppCreate />;
  }
  return (
    <>
      <BackButton />
      <Breadcrumb to='/apps' root>
        Apps
      </Breadcrumb>
      <AppToolbar>
        <ToolbarGroup justify='end'>
          <ToolbarItem>
            <Button onClick={openNewApp} variant='primary'>
              New app
            </Button>
          </ToolbarItem>
        </ToolbarGroup>
      </AppToolbar>

      <PageLayout rounded>
        <LoadableContent loading={!hasFetchedApps}>
          <PlaceholderMessage text='Create a app.' isEmpty={isEmpty(apps)}>
            <ScrollableContent>
              <WorkspaceCardsWrapper>
                <Cards view='grid'>
                  {apps.map((app) => (
                    <AppCard
                      key={app.id}
                      id={app.id}
                      licence={app.licence}
                      label={app.label}
                      owner={get(app.owner.key, organisations)}
                      updated={app.updatedAt}
                    />
                  ))}
                </Cards>
              </WorkspaceCardsWrapper>
            </ScrollableContent>
          </PlaceholderMessage>
        </LoadableContent>
        {showNewAppModal && (
          <NewAppModal
            onClose={closeNewApp}
            primaryOrganisationId={primaryOrganisationId}
            organisations={membershipOranisations}
          />
        )}
      </PageLayout>
    </>
  );
};

export { Apps };
