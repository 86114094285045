import { createSlice } from '@reduxjs/toolkit';
import { keyBy, map, pipe, set } from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { geographyUpdate } from 'actions/geographyUpdate';

const updateLayers = (state, action) => {
  const { layerGroups } = action.payload;

  return pipe(
    set('entities', keyBy('id', layerGroups)),
    set('keys', map('id', layerGroups)),
  )(state);
};

const { reducer, actions } = createSlice({
  name: 'layerGroups',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(appAdd.fulfilled, updateLayers);
    builder.addCase(workspaceFetch.fulfilled, updateLayers);
    builder.addCase(workspacePatch.fulfilled, updateLayers);
    builder.addCase(geographyUpdate.fulfilled, updateLayers);
  },
});

export { reducer, actions };
