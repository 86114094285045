import { Pill, Secondary, Stack, LabelText, Select } from '@kinesis/bungle';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { PathwaysInputsGrid } from 'components/pathways-inputs-grid';
import { Cell } from 'components/cell';
import {
  interventionAreaLabels as labels,
  interventionAreaDescriptions as descriptions,
  validProportionalChange,
  validPercentage,
  residentialBuildingStandardsOptions,
  residentialBuildingStandardsOptionsDataUpdate2023,
  nonResidentialBuildingStandardsOptions,
  residentialRetrofitsStrategyOptions,
  nonResidentialRetrofitsStrategyOptions,
  publicTransitElectrificationOptions,
} from 'data/pathways';

const propTypes = {
  identifier: PropTypes.oneOf([
    'public-transit-electrification',
    'residential-building-standards',
    'residential-vkt-change',
    'residential-retrofits',
    'non-residential-retrofits',
    'non-residential-building-standards',
    'residential-mode-shift',
    'ev-takeup',
    'waste-reduction',
    'recyclable-packaging',
    'recyclable-material-management',
    'recyclable-ewaste',
    'organics',
    'waste-to-energy',
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
};
const defaultProps = {};

const formatProportional = (value) => {
  if (value > 0) {
    return `+${value.toFixed(0)}`;
  }
  return value.toFixed(0);
};

const PathwaysInterventionArea = ({ identifier, onChange, input }) => {
  const useDataUpdate = true;
  const flaggedResidentialBuildingStandardsOptions = useDataUpdate
    ? residentialBuildingStandardsOptionsDataUpdate2023
    : residentialBuildingStandardsOptions;

  return (
    <Stack space='xsmall'>
      <Stack space='none'>
        <LabelText as='label'>{labels[identifier]}</LabelText>
        <LabelText as='label'>
          <Secondary>{descriptions[identifier]}</Secondary>
        </LabelText>
      </Stack>
      {identifier === 'public-transit-electrification' && (
        <Select
          wrap
          onChange={(value) => onChange([identifier], value, input)}
          value={get([identifier], input)}
          options={publicTransitElectrificationOptions}
        />
      )}
      {identifier === 'residential-building-standards' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading>
            Now
          </Cell>
          <Cell appearance='dark' heading>
            2030
          </Cell>
          <Cell appearance='dark' heading>
            2040
          </Cell>
          <Select
            wrap
            appearance='bare'
            onChange={(value) => onChange([identifier, 'now'], value, input)}
            value={get([identifier, 'now'], input)}
            options={flaggedResidentialBuildingStandardsOptions}
          />
          <Select
            wrap
            appearance='bare'
            onChange={(value) => onChange([identifier, 'y2030'], value, input)}
            value={get([identifier, 'y2030'], input)}
            options={flaggedResidentialBuildingStandardsOptions}
          />
          <Select
            wrap
            appearance='bare'
            onChange={(value) => onChange([identifier, 'y2040'], value, input)}
            value={get([identifier, 'y2040'], input)}
            options={flaggedResidentialBuildingStandardsOptions}
          />
        </PathwaysInputsGrid>
      )}
      {identifier === 'residential-vkt-change' && (
        <PathwaysInputsGrid headerPlacement='left'>
          <Cell appearance='dark' heading>
            2030
          </Cell>
          <Cell appearance='dark' heading>
            2040
          </Cell>
          <Cell appearance='dark' heading>
            2050
          </Cell>

          <Cell
            numeric
            editable
            value={get([identifier, 'y2030'], input)}
            onSave={(value) => {
              if (validProportionalChange(value)) {
                onChange([identifier, 'y2030'], value, input);
              }
            }}
          >
            {formatProportional(get([identifier, 'y2030'], input))}
            <Pill>% change</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2040'], input)}
            onSave={(value) => {
              if (validProportionalChange(value)) {
                onChange([identifier, 'y2040'], value, input);
              }
            }}
          >
            {formatProportional(get([identifier, 'y2040'], input))}
            <Pill>% change</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2050'], input)}
            onSave={(value) => {
              if (validProportionalChange(value)) {
                onChange([identifier, 'y2050'], value, input);
              }
            }}
          >
            {formatProportional(get([identifier, 'y2050'], input))}
            <Pill>% change</Pill>
          </Cell>
        </PathwaysInputsGrid>
      )}
      {identifier === 'residential-retrofits' && (
        <Stack space='xsmall'>
          <Select
            wrap
            onChange={(value) =>
              onChange([identifier, 'strategy'], value, input)
            }
            options={residentialRetrofitsStrategyOptions}
            value={get([identifier, 'strategy'], input)}
          />
          <PathwaysInputsGrid headerPlacement='left'>
            <Cell appearance='dark' heading>
              2030
            </Cell>
            <Cell appearance='dark' heading>
              2040
            </Cell>
            <Cell appearance='dark' heading>
              2050
            </Cell>

            <Cell
              numeric
              editable
              value={get([identifier, 'y2030'], input)}
              onSave={(value) => {
                if (validPercentage(value)) {
                  onChange([identifier, 'y2030'], value, input);
                }
              }}
              data-testid='residential-retrofits-cell'
            >
              {get([identifier, 'y2030'], input).toFixed(0)}
              <Pill>% of buildings</Pill>
            </Cell>
            <Cell
              numeric
              editable
              value={get([identifier, 'y2040'], input)}
              onSave={(value) => {
                if (validPercentage(value)) {
                  onChange([identifier, 'y2040'], value, input);
                }
              }}
            >
              {get([identifier, 'y2040'], input).toFixed(0)}
              <Pill>% of buildings</Pill>
            </Cell>
            <Cell
              numeric
              editable
              value={get([identifier, 'y2050'], input)}
              onSave={(value) => {
                if (validPercentage(value)) {
                  onChange([identifier, 'y2050'], value, input);
                }
              }}
            >
              {get([identifier, 'y2050'], input).toFixed(0)}
              <Pill>% of buildings</Pill>
            </Cell>
          </PathwaysInputsGrid>
        </Stack>
      )}
      {identifier === 'non-residential-building-standards' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading>
            Now
          </Cell>
          <Cell appearance='dark' heading>
            2030
          </Cell>
          <Cell appearance='dark' heading>
            2040
          </Cell>
          <Select
            wrap
            appearance='bare'
            onChange={(value) => onChange([identifier, 'now'], value, input)}
            value={get([identifier, 'now'], input)}
            options={nonResidentialBuildingStandardsOptions}
          />
          <Select
            wrap
            appearance='bare'
            onChange={(value) => onChange([identifier, 'y2030'], value, input)}
            value={get([identifier, 'y2030'], input)}
            options={nonResidentialBuildingStandardsOptions}
          />
          <Select
            wrap
            appearance='bare'
            onChange={(value) => onChange([identifier, 'y2040'], value, input)}
            value={get([identifier, 'y2040'], input)}
            options={nonResidentialBuildingStandardsOptions}
          />
        </PathwaysInputsGrid>
      )}
      {identifier === 'non-residential-retrofits' && (
        <Stack space='xsmall'>
          <Select
            wrap
            onChange={(value) =>
              onChange([identifier, 'strategy'], value, input)
            }
            options={nonResidentialRetrofitsStrategyOptions}
            value={get([identifier, 'strategy'], input)}
          />
          <PathwaysInputsGrid headerPlacement='left'>
            <Cell appearance='dark' heading>
              2030
            </Cell>
            <Cell appearance='dark' heading>
              2040
            </Cell>
            <Cell appearance='dark' heading>
              2050
            </Cell>

            <Cell
              numeric
              editable
              value={get([identifier, 'y2030'], input)}
              onSave={(value) => {
                if (validPercentage(value)) {
                  onChange([identifier, 'y2030'], value, input);
                }
              }}
            >
              {get([identifier, 'y2030'], input).toFixed(0)}
              <Pill>% of buildings</Pill>
            </Cell>
            <Cell
              numeric
              editable
              value={get([identifier, 'y2040'], input)}
              onSave={(value) => {
                if (validPercentage(value)) {
                  onChange([identifier, 'y2040'], value, input);
                }
              }}
            >
              {get([identifier, 'y2040'], input).toFixed(0)}
              <Pill>% of buildings</Pill>
            </Cell>
            <Cell
              numeric
              editable
              value={get([identifier, 'y2050'], input)}
              onSave={(value) => {
                if (validPercentage(value)) {
                  onChange([identifier, 'y2050'], value, input);
                }
              }}
            >
              {get([identifier, 'y2050'], input).toFixed(0)}
              <Pill>% of buildings</Pill>
            </Cell>
          </PathwaysInputsGrid>
        </Stack>
      )}
      {identifier === 'residential-mode-shift' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading numeric>
            2030
          </Cell>
          <Cell appearance='dark' heading numeric>
            2040
          </Cell>
          <Cell appearance='dark' heading numeric>
            2050
          </Cell>

          <Cell
            numeric
            editable
            value={get([identifier, 'y2030'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2030'], value, input);
              }
            }}
          >
            {get([identifier, 'y2030'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2040'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2040'], value, input);
              }
            }}
          >
            {get([identifier, 'y2040'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2050'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2050'], value, input);
              }
            }}
          >
            {get([identifier, 'y2050'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
        </PathwaysInputsGrid>
      )}
      {identifier === 'ev-takeup' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading numeric>
            2030
          </Cell>
          <Cell appearance='dark' heading numeric>
            2040
          </Cell>
          <Cell appearance='dark' heading numeric>
            2050
          </Cell>

          <Cell
            numeric
            editable
            value={get([identifier, 'y2030'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2030'], value, input);
              }
            }}
          >
            {get([identifier, 'y2030'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2040'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2040'], value, input);
              }
            }}
          >
            {get([identifier, 'y2040'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2050'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2050'], value, input);
              }
            }}
          >
            {get([identifier, 'y2050'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
        </PathwaysInputsGrid>
      )}
      {identifier === 'waste-reduction' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading numeric>
            2030
          </Cell>
          <Cell appearance='dark' heading numeric>
            2040
          </Cell>
          <Cell appearance='dark' heading numeric>
            2050
          </Cell>

          <Cell
            numeric
            editable
            value={get([identifier, 'y2030'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2030'], value, input);
              }
            }}
          >
            {get([identifier, 'y2030'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2040'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2040'], value, input);
              }
            }}
          >
            {get([identifier, 'y2040'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2050'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2050'], value, input);
              }
            }}
          >
            {get([identifier, 'y2050'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
        </PathwaysInputsGrid>
      )}
      {identifier === 'recyclable-packaging' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading numeric>
            2030
          </Cell>
          <Cell appearance='dark' heading numeric>
            2040
          </Cell>
          <Cell appearance='dark' heading numeric>
            2050
          </Cell>

          <Cell
            numeric
            editable
            value={get([identifier, 'y2030'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2030'], value, input);
              }
            }}
          >
            {get([identifier, 'y2030'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2040'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2040'], value, input);
              }
            }}
          >
            {get([identifier, 'y2040'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2050'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2050'], value, input);
              }
            }}
          >
            {get([identifier, 'y2050'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
        </PathwaysInputsGrid>
      )}
      {identifier === 'recyclable-material-management' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading numeric>
            2030
          </Cell>
          <Cell appearance='dark' heading numeric>
            2040
          </Cell>
          <Cell appearance='dark' heading numeric>
            2050
          </Cell>

          <Cell
            numeric
            editable
            value={get([identifier, 'y2030'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2030'], value, input);
              }
            }}
          >
            {get([identifier, 'y2030'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2040'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2040'], value, input);
              }
            }}
          >
            {get([identifier, 'y2040'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2050'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2050'], value, input);
              }
            }}
          >
            {get([identifier, 'y2050'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
        </PathwaysInputsGrid>
      )}
      {identifier === 'recyclable-ewaste' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading numeric>
            2030
          </Cell>
          <Cell appearance='dark' heading numeric>
            2040
          </Cell>
          <Cell appearance='dark' heading numeric>
            2050
          </Cell>

          <Cell
            numeric
            editable
            value={get([identifier, 'y2030'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2030'], value, input);
              }
            }}
          >
            {get([identifier, 'y2030'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2040'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2040'], value, input);
              }
            }}
          >
            {get([identifier, 'y2040'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2050'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2050'], value, input);
              }
            }}
          >
            {get([identifier, 'y2050'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
        </PathwaysInputsGrid>
      )}
      {identifier === 'organics' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading numeric>
            2030
          </Cell>
          <Cell appearance='dark' heading numeric>
            2040
          </Cell>
          <Cell appearance='dark' heading numeric>
            2050
          </Cell>

          <Cell
            numeric
            editable
            value={get([identifier, 'y2030'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2030'], value, input);
              }
            }}
          >
            {get([identifier, 'y2030'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2040'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2040'], value, input);
              }
            }}
          >
            {get([identifier, 'y2040'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2050'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2050'], value, input);
              }
            }}
          >
            {get([identifier, 'y2050'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
        </PathwaysInputsGrid>
      )}
      {identifier === 'waste-to-energy' && (
        <PathwaysInputsGrid>
          <Cell appearance='dark' heading numeric>
            2030
          </Cell>
          <Cell appearance='dark' heading numeric>
            2040
          </Cell>
          <Cell appearance='dark' heading numeric>
            2050
          </Cell>

          <Cell
            numeric
            editable
            value={get([identifier, 'y2030'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2030'], value, input);
              }
            }}
          >
            {get([identifier, 'y2030'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2040'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2040'], value, input);
              }
            }}
          >
            {get([identifier, 'y2040'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
          <Cell
            numeric
            editable
            value={get([identifier, 'y2050'], input)}
            onSave={(value) => {
              if (validPercentage(value)) {
                onChange([identifier, 'y2050'], value, input);
              }
            }}
          >
            {get([identifier, 'y2050'], input).toFixed(0)}
            <Pill>%</Pill>
          </Cell>
        </PathwaysInputsGrid>
      )}
    </Stack>
  );
};

PathwaysInterventionArea.propTypes = propTypes;
PathwaysInterventionArea.defaultProps = defaultProps;

export { PathwaysInterventionArea };
