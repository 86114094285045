import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { hasIn, isNil } from 'lodash/fp';
import {
  Fieldset,
  InputGroup,
  NumberInput,
  useUpdateEffect,
} from '@kinesis/bungle';
import { numberOfOperands } from 'utils/numberUtils';
import ResettableFormItem from 'components/form/ResettableFormItem';
import {
  RelationalOperatorSelect,
  RelationalOperatorSelectContent,
} from 'components/form/RelationalOperatorSelect';
import useValueRange from 'hooks/useValueRange';

const propTypes = {
  format: PropTypes.func,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  symbol: PropTypes.string,
  type: PropTypes.oneOf(['decimal', 'integer']),
  value: PropTypes.object,
};

const defaultProps = {
  format: (val) =>
    hasIn('toLocaleString') ? val.toLocaleString() : String(val),
  symbol: undefined,
  type: 'decimal',
  value: undefined,
};

const NumberFilter = ({ format, label, onChange, symbol, type, value }) => {
  const {
    firstOperand,
    relationalOperator,
    secondOperand,
    setFirstOperand,
    setRelationalOperator,
    setSecondOperand,
  } = useValueRange(value);
  const [validatable, setValidatable] = useState(false);

  const numOperands = numberOfOperands(relationalOperator);
  const isValid =
    relationalOperator === 'all' ||
    (numOperands === 1 && !isNil(firstOperand)) ||
    (numOperands === 2 &&
      !isNil(firstOperand) &&
      !isNil(secondOperand) &&
      secondOperand > firstOperand);

  const handleReset = useCallback(() => {
    setRelationalOperator('all');
    setFirstOperand(undefined);
    setSecondOperand(undefined);
    setValidatable(false);
  }, [setFirstOperand, setRelationalOperator, setSecondOperand]);

  const handleFocus = useCallback(() => {
    setValidatable(false);
  }, []);

  const handleBlur = useCallback(() => {
    setValidatable(true);
  }, []);

  useUpdateEffect(() => {
    if (!isValid) {
      return;
    }

    if (relationalOperator === 'all') {
      onChange(null);
    } else {
      onChange({
        relationalOperator,
        firstOperand,
        secondOperand,
      });
    }
  }, [isValid, onChange, relationalOperator, firstOperand, secondOperand]);

  return (
    <ResettableFormItem
      validateStatus={validatable && !isValid ? 'error' : 'success'}
      showReset={!!value && relationalOperator !== 'all'}
      onReset={handleReset}
    >
      <Fieldset legend={label}>
        <RelationalOperatorSelect
          value={relationalOperator}
          onChange={setRelationalOperator}
          onFocus={handleFocus}
        />
        {numOperands > 0 && (
          <RelationalOperatorSelectContent>
            <InputGroup
              error={validatable && !isValid}
              magnitude='large'
              suffix={symbol}
            >
              <NumberInput
                autoFocus
                format={format}
                numberType={type}
                onChange={setFirstOperand}
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder='Enter value'
                value={firstOperand}
              />
              {numOperands > 1 && (
                <NumberInput
                  format={format}
                  numberType={type}
                  onChange={setSecondOperand}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder='Enter value'
                  value={secondOperand}
                />
              )}
            </InputGroup>
          </RelationalOperatorSelectContent>
        )}
      </Fieldset>
    </ResettableFormItem>
  );
};

NumberFilter.propTypes = propTypes;
NumberFilter.defaultProps = defaultProps;

export default NumberFilter;
