import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';
import scenarioKeySelector from './scenarioKeySelector';

const attributesSelector = createSelector(
  workspaceSelector,
  scenarioKeySelector,
  (workspace, scenarioKey) => get(['attributes', scenarioKey], workspace),
);

export default attributesSelector;
