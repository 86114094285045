import { createSelector } from 'reselect';
import { some, isNil, get, pipe } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const anyDirtyBlocksSelector = createSelector(
  pipe(workspaceSelector, get('blocks')),
  (blockStates) =>
    some((block) => !isNil(get('draftVersion', block)))(blockStates),
);

export default anyDirtyBlocksSelector;
