import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const blockStateSelector = createSelector(
  (state, props) => props.blockId,
  pipe(workspaceSelector, get('blocks')),
  get,
);

export default blockStateSelector;
