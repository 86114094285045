import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo, get, pipe } from 'lodash/fp';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import MultiCheck from 'components/multi-check/multi-check';
import { PlaceholderMessage } from 'components/placeholder-message';
import locationsChooserSelector from 'selectors/locationsChooserSelector';
import { workspaceFetch } from 'actions/workspaceFetch';
import { LocationsMap } from './LocationsMap';
import { PlatformLocationsMap } from './PlatformLocationsMap';
import { knownPlatformLocations } from './platform-locations';

const propTypes = {
  layerId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  scenarioId: PropTypes.number,
  selectedLocations: PropTypes.array.isRequired,
  workspaceId: PropTypes.number,
  platformKey: PropTypes.string,
  view: PropTypes.oneOf(['map', 'list']),
};

const defaultProps = {
  layerId: undefined,
  scenarioId: undefined,
  workspaceId: undefined,
  platformKey: undefined,
  view: 'list',
};

const LocationsChooser = ({
  layerId,
  onChange,
  scenarioId,
  selectedLocations,
  workspaceId,
  platformKey,
  view,
}) => {
  const dispatch = useDispatch();
  const workspaceLocations = useSelector(
    useCallback(
      (state) =>
        locationsChooserSelector(state, { layerId, scenarioId, workspaceId }),
      [layerId, scenarioId, workspaceId],
    ),
  );
  const platformLocations = useMemo(
    () => knownPlatformLocations[platformKey],
    [platformKey],
  );

  const filteredLocations = useMemo(
    () => platformLocations || workspaceLocations,
    [platformLocations, workspaceLocations],
  );

  const options = useMemo(
    () =>
      filteredLocations.map((location) => ({
        label: location.label || 'New location',
        value: location.id,
      })),
    [filteredLocations],
  );

  const { hasInitialised = false, isFetching = false } = useSelector(
    pipe(get(['workspaces', workspaceId, 'data']), defaultTo({})),
  );

  const [values, setValues] = useState(
    selectedLocations.map((location) => location.id),
  );
  useEffect(() => {
    if (!hasInitialised && !isFetching && workspaceId) {
      dispatch(workspaceFetch({ workspaceId }));
    }
  }, [dispatch, hasInitialised, isFetching, workspaceId]);

  useEffect(() => {
    onChange(
      filteredLocations.filter((location) => values.includes(location.id)),
    );
  }, [filteredLocations, onChange, values]);

  if (!platformKey && (!workspaceId || !scenarioId || !layerId)) {
    return (
      <PlaceholderMessage text='Choose a source and select locations to import.' />
    );
  }

  return (
    <Layout>
      {view === 'list' && (
        <MultiCheck
          emptyText='There are no locations in this layer.'
          loading={!hasInitialised && isFetching}
          onChange={setValues}
          options={options}
          placeholderText='Filter locations…'
          values={values}
          word='locations'
        />
      )}
      {view === 'map' && !platformKey && (
        <LocationsMap
          workspaceId={workspaceId}
          scenarioId={scenarioId}
          layerId={layerId}
          setValues={setValues}
          values={values}
          onChange={setValues}
          options={options}
        />
      )}
      {view === 'map' && platformKey && (
        <PlatformLocationsMap
          platformKey={platformKey}
          setValues={setValues}
          values={values}
          options={options}
        />
      )}
    </Layout>
  );
};

LocationsChooser.propTypes = propTypes;
LocationsChooser.defaultProps = defaultProps;

export default LocationsChooser;
