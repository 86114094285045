import PropTypes from 'prop-types';
import styled from 'styled-components';

const MapControlWrapper = styled.div`
  left: 16px;
  position: absolute;
  top: 16px;
`;

const MapControlGroup = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
`;

const MapControlSeparator = styled.div`
  border-top: 1px solid #e9e9e9;
  padding-bottom: 4px;
`;

const MapControlItem = styled.div`
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  &:first-child {
    padding-top: 4px;
  }
`;

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const MapControl = ({ children }) => (
  <MapControlWrapper>{children}</MapControlWrapper>
);

MapControl.propTypes = propTypes;

export { MapControl, MapControlGroup, MapControlItem, MapControlSeparator };
