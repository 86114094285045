import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toPairs, findIndex, pipe, get, orderBy, map } from 'lodash/fp';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useResponsive from 'hooks/useResponsive';
import { Stack, H2 } from '@kinesis/bungle';
import { formatEmissionsReductionTooltip } from 'utils/charts/tooltips';
import {
  interventionAreaLabels,
  interventionAreaCategories,
  interventionCategoryLabels,
} from 'data/pathways';
import { useTheme } from 'styled-components';
import Units from 'data/units';
import { EmissionsReductionsLegend } from './emissions-reductions-legend';
import {
  ChartContainer,
  HighchartsWrapper,
  interventionCategoryColors as seriesColors,
} from './chart.styles';

const units = new Units();

const EmissionsReductions = () => {
  const { isDesktopAndUp } = useResponsive();
  const theme = useTheme();
  const ref = useRef();
  const reductions = useSelector(get(['pathways', 'reductions']));
  const unit = units.parseType(reductions.unit);

  const [selectedPolicy, setSelectedPolicy] = useState('current');
  const selectedValues = useMemo(
    () => reductions[selectedPolicy],
    [reductions, selectedPolicy],
  );

  const orderedValues = useMemo(
    () =>
      pipe(
        toPairs,
        orderBy([1, 0], ['desc', 'asc']),
        map((o) => ({ label: o[0], value: o[1] })),
      )(selectedValues),
    [selectedValues],
  );

  const xAxisLabels = useMemo(
    () => map((item) => interventionAreaLabels[item.label], orderedValues),
    [orderedValues],
  );

  const valuesForSeries = useMemo(() => {
    const categories = {
      energy: {
        name: interventionCategoryLabels.energy,
        data: [],
        color: seriesColors.energy,
      },
      transport: {
        name: interventionCategoryLabels.transport,
        data: [],
        color: seriesColors.transport,
      },
      waste: {
        name: interventionCategoryLabels.waste,
        data: [],
        color: seriesColors.waste,
      },
    };

    return orderedValues.reduce((acc, area) => {
      const areaCategory = interventionAreaCategories[area.label];
      if (acc[areaCategory]) {
        acc[areaCategory].data.push({
          x: findIndex(
            (orderedValue) => area.label === orderedValue.label,
            orderedValues,
          ),
          y: area.value,
          name: area.label,
        });
      }
      return acc;
    }, categories);
  }, [orderedValues]);

  const options = useMemo(() => {
    const series = [
      valuesForSeries.energy,
      valuesForSeries.transport,
      valuesForSeries.waste,
    ];

    return {
      plotOptions: {
        series: {
          animation: false,
          states: {
            inactive: { opacity: 1 },
          },
        },
        bar: {
          centerInCategory: true,
          pointWidth: 32,
          states: {
            hover: { enabled: false },
          },
        },
      },
      legend: { enabled: false },
      xAxis: {
        crosshair: {
          color: theme.color.gray3,
          zIndex: -1,
        },
        categories: xAxisLabels,
        gridLineWidth: 1,
        gridLineColor: theme.colors.gray4.hex,
        lineColor: theme.colors.gray5.hex,
        title: { enabled: false },
        tickWidth: 1,
        tickLength: 9,
        tickColor: theme.colors.gray5.hex,
        labels: {
          x: -8,
          useHTML: true,
          style: {
            fontSize: '12px',
            lineHeight: '20px',
            textAlign: 'right',
          },
        },
      },
      yAxis: {
        plotLines: [
          { color: theme.colors.gray4.hex, width: 1, value: 0, zIndex: 20 }, // to stop borders of bars near 0 from obscuring the gridline
        ],
        gridLineColor: theme.colors.gray4.hex,
        gridLineWidth: 1,
        lineColor: theme.colors.gray5.hex,
        lineWidth: 1,
        labels: {
          useHTML: true,
          rotation: 0,
          y: 17,
          style: {
            color: 'rgba(0,0,0,0.65)',
            fontWeight: 400,
            lineHeight: '20px',
            fontSize: '12px',
          },
          formatter(o) {
            return unit.formatCell(o.value);
          },
        },
        title: {
          useHTML: true,
          text: `Emissions reduction (${unit.symbol})`,
          style: {
            color: 'rgba(0,0,0,0.85)',
            fontWeight: 500,
            lineHeight: '20px',
            fontSize: '12px',
          },
          y: 8, // move title to edge
        },
      },
      credits: { enabled: false },
      title: { text: undefined },
      lang: {
        noData: 'There is no data.',
      },
      noData: {
        style: {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          color: 'rgba(0,0,0,0.25)',
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: 'rgba(255,255,255,0)',
        borderWidth: 0,
        shadow: false,
        animation: false,
        hideDelay: 0,
        outside: true,
        padding: 0,
        formatter() {
          return formatEmissionsReductionTooltip(this, unit);
        },
      },
      chart: {
        height: 760,
        marginLeft: 120,
        type: 'bar',
        animation: false,
        plotBorderWidth: 0,
        plotBackgroundColor: `rgba(0,0,0,0)`,
        spacing: [1, 1, 10, 1],
        style: {
          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
          fontSize: '12px',
        },
      },
      series,
    };
  }, [theme, xAxisLabels, valuesForSeries, unit]);

  return (
    <Stack space='medium'>
      <H2>Cumulative emissions reductions by 2050</H2>
      <ChartContainer>
        <HighchartsWrapper>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={ref}
          />
        </HighchartsWrapper>
        {isDesktopAndUp && (
          <EmissionsReductionsLegend
            value={selectedPolicy}
            onChange={setSelectedPolicy}
          />
        )}
      </ChartContainer>
    </Stack>
  );
};

export { EmissionsReductions };
