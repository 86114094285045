import { forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Content, H2 } from '@kinesis/bungle';
import { ToolboxPanelWrapper } from './toolbox-panel.styles';

const propTypes = {
  children: PropTypes.node,
  fadeScroll: PropTypes.bool,
  title: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  fadeScroll: false,
  title: undefined,
};

const ToolboxPanel = forwardRef(({ children, fadeScroll, title }, ref) => {
  const [hasScrolled, setScrolled] = useState(false);

  const handleScroll = useCallback(
    (event) => {
      if (fadeScroll) {
        setScrolled(event.currentTarget.scrollTop > 0);
      }
    },
    [fadeScroll],
  );

  return (
    <ToolboxPanelWrapper
      ref={ref}
      onScroll={handleScroll}
      scrolled={hasScrolled}
    >
      {title && (
        <Content padding='medium' paddingMode='equal'>
          <H2>{title}</H2>
        </Content>
      )}
      {children}
    </ToolboxPanelWrapper>
  );
});

ToolboxPanel.propTypes = propTypes;
ToolboxPanel.defaultProps = defaultProps;

export { ToolboxPanel };
