import { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { noop, isEmpty, find } from 'lodash/fp';
import { format, parseISO } from 'date-fns/fp';
import { AnimatedPopover, Listbox, UtilityButton } from '@kinesis/bungle';

const propTypes = {
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  openOnLoad: PropTypes.bool,
  value: PropTypes.number,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      publishedAs: PropTypes.number,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    }),
  ),
};

const defaultProps = {
  disabled: false,
  onSelect: noop,
  openOnLoad: false,
  value: undefined,
  versions: [],
};

const DatasetVersionSelect = ({
  disabled,
  onSelect,
  openOnLoad,
  value,
  versions,
}) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(openOnLoad);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const selected = useMemo(
    () => find({ id: value }, versions),
    [value, versions],
  );
  return (
    <>
      <UtilityButton
        ref={ref}
        disabled={disabled || isEmpty(versions)}
        dropdown
        magnitude='small'
        onClick={open}
      >
        {selected ? (
          <span>
            {selected.publishedAs ? `Version ${selected.publishedAs}` : 'Draft'}
          </span>
        ) : (
          <span>{isEmpty(versions) ? 'New dataset' : 'Draft'}</span>
        )}
      </UtilityButton>
      <AnimatedPopover
        justify='start'
        placement='bottom'
        offset={4}
        open={isOpen}
        targetRef={ref}
      >
        <Listbox
          autoFocus
          buttonRef={ref}
          closeOnBlur
          closeOnClickOutside
          closeOnSelect
          onChange={onSelect}
          onClose={close}
          options={versions}
          value={value}
          valueKey='id'
          renderItem={(item) =>
            item.publishedAs
              ? `Version ${item.publishedAs}`
              : `${format('d MMM yyyy, h:mm a', parseISO(item.createdAt))}`
          }
          width={240}
        />
      </AnimatedPopover>
    </>
  );
};

DatasetVersionSelect.defaultProps = defaultProps;
DatasetVersionSelect.propTypes = propTypes;

export { DatasetVersionSelect };
