import PropTypes from 'prop-types';
import { LocationTableRowEmptyText } from './location-table-row.styles';

const propTypes = {
  children: PropTypes.node,
  showPlaceholder: PropTypes.bool,
};

const defaultProps = {
  children: undefined,
  showPlaceholder: false,
};

const LocationsTableNoValues = ({ children, showPlaceholder }) =>
  showPlaceholder ? (
    <LocationTableRowEmptyText>No values defined</LocationTableRowEmptyText>
  ) : (
    children
  );

LocationsTableNoValues.propTypes = propTypes;
LocationsTableNoValues.defaultProps = defaultProps;

export default LocationsTableNoValues;
