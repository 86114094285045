import { get, first } from 'lodash/fp';
import { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  AnimatedPopover,
  Icon,
  Inline,
  InlineItem,
  Portal,
  Strong,
  Text,
  Toast,
  UtilityButton,
  useEventListener,
  useOnClickOutside,
  useToast,
} from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { PathwayUpload } from 'components/pathway-upload';
import { pathwayInputsDownload } from 'actions/pathwayInputsDownload';
import { validateUpload } from 'data/pathways';
import usePathwayInputs from 'hooks/usePathwayInputs';
import { Dropzone, Hidden } from './pathways-file-loader.styles';

const propTypes = {};
const defaultProps = {};

const validFileTypes = '.pathway';

const PathwaysFileLoader = () => {
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [showDropzone, setShowDropzone] = useState(false);
  const toggleUploadPane = useCallback(
    () => setUploadIsOpen((o) => !o),
    [setUploadIsOpen],
  );
  const closePane = useCallback(
    () => setUploadIsOpen(false),
    [setUploadIsOpen],
  );

  const theme = useTheme();
  const dispatch = useDispatch();
  const toast = useToast('globalTop');

  const uploadButtonRef = useRef();
  const uploadPaneRef = useRef();
  const dropzoneRef = useRef();
  const fileInputRef = useRef();

  const [inputs, setInputs] = usePathwayInputs();

  const downloadInputsState = useSelector(
    get(['pathways', 'downloadInputsState']),
  );

  const handleDownload = useCallback(() => {
    dispatch(pathwayInputsDownload(inputs));
  }, [dispatch, inputs]);

  const showError = useCallback(() => {
    toast(
      'We couldn’t load inputs because there was a problem with the file.',
      { variant: 'error' },
    );
  }, [toast]);

  const showUploadSuccess = useCallback(
    (filename) => {
      toast(
        <Text>
          Inputs from <Strong>{filename}</Strong> applied.
        </Text>,
        { variant: 'success' },
      );
    },
    [toast],
  );

  const processUpload = useCallback(
    (json, filename) => {
      if (!validateUpload(json)) {
        showError();
      } else {
        showUploadSuccess(filename);
        setInputs({
          interventionAreas: get('intervention-areas', json),
          locations: get('locations', json),
          locationType: get('location-type', json),
        });
      }
    },
    [showError, showUploadSuccess, setInputs],
  );

  const onUpload = useCallback(
    (files) => {
      const file = first(files);
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const jsonObj = JSON.parse(event.target.result);
          processUpload(jsonObj, file.name);
        } catch (e) {
          showError();
        }
      };

      reader.readAsText(file);
    },
    [processUpload, showError],
  );

  const handleOnUpload = useCallback(
    (e) => {
      closePane();
      onUpload(e);
    },
    [closePane, onUpload],
  );

  const onFileInputSave = useCallback(
    (e) => handleOnUpload(e.target.files),
    [handleOnUpload],
  );

  const onFileInput = useCallback(() => {
    handleOnUpload(get(['current', 'files'], fileInputRef));
  }, [handleOnUpload, fileInputRef]);

  const handleUploadClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!showDropzone && dropzoneRef.current)
        dropzoneRef.current.style.display = 'none';
    }, 200);

    return () => {
      clearTimeout(timerId);
    };
  }, [showDropzone]);

  useOnClickOutside(
    useMemo(
      () => [uploadPaneRef, uploadButtonRef],
      [uploadPaneRef, uploadButtonRef],
    ),
    closePane,
  );

  useEventListener('dragenter', () => {
    setShowDropzone(true);
    dropzoneRef.current.style.display = 'block';
  });

  useEventListener('dragover', (e) => {
    setShowDropzone(true);
    e.preventDefault();
  });

  useEventListener('dragleave', () => {
    setShowDropzone(false);
  });

  useEventListener(
    'drop',
    useCallback(
      (e) => {
        e.preventDefault();
        setShowDropzone(false);
        dropzoneRef.current.style.display = 'none';

        const { files, items } = e.dataTransfer;
        if (items.length !== 1 || items[0].kind !== 'file') {
          showError();
        } else {
          fileInputRef.current.files = files;
          onFileInput();
        }
      },
      [onFileInput, showError],
    ),
  );

  return (
    <>
      <Content
        borderPlacement={['top', 'right']}
        height={49}
        width={468}
        paddingX='medium'
        paddingY='xsmall'
        alignX='left'
        alignY='center'
      >
        <Inline space='small'>
          <InlineItem>
            {downloadInputsState === 'downloading' ? (
              <Content
                paddingX='none'
                paddingY='small'
                alignX='center'
                width={103}
              >
                <Icon
                  color={theme.color.blue6}
                  animation='rotate'
                  type='spinner'
                  magnitude='small'
                />
              </Content>
            ) : (
              <UtilityButton icon='download' onClick={handleDownload}>
                Save inputs
              </UtilityButton>
            )}
          </InlineItem>
          <InlineItem>
            <UtilityButton
              ref={uploadButtonRef}
              icon='upload'
              onClick={toggleUploadPane}
            >
              Load inputs
            </UtilityButton>
          </InlineItem>
        </Inline>
        <AnimatedPopover
          justify='start'
          placement='bottom'
          offset={4}
          open={uploadIsOpen}
          targetRef={uploadButtonRef}
        >
          <PathwayUpload ref={uploadPaneRef} onClick={handleUploadClick} />
        </AnimatedPopover>
      </Content>
      <Portal>
        <Dropzone ref={dropzoneRef}>Drop file to upload.</Dropzone>
      </Portal>
      <Hidden>
        <input
          accept={validFileTypes}
          type='file'
          onChange={onFileInputSave}
          onClick={(e) => {
            e.target.value = ''; // without this, uploading the same file twice does not fire onChange
          }}
          ref={fileInputRef}
        />
      </Hidden>
      {downloadInputsState === 'error' && (
        <Toast toasterId='globalTop' variant='error'>
          We couldn&rsquo;t start your download. Please try again.
        </Toast>
      )}
    </>
  );
};

PathwaysFileLoader.propTypes = propTypes;
PathwaysFileLoader.defaultProps = defaultProps;

export { PathwaysFileLoader };
