import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns/fp';
import { Strong, Tooltip } from '@kinesis/bungle';
import { capitalize, get } from 'lodash/fp';
import { labelOf } from 'data/attributes';
import useDateDistance from 'hooks/useDateDistance';
import {
  ModificationsListItemFrame,
  ModificationsListItemLine,
  ModificationsListItemTooltip,
} from './modifications-list.styles';

const propTypes = {
  modification: PropTypes.object.isRequired,
};

const defaultProps = {};

const ModificationsListItem = ({ modification }) => {
  const dateDistance = useDateDistance(
    modification.time_created ? parseISO(modification.time_created) : undefined,
  );

  // originally added to support formatting > a certain number of changes.
  // however, only currently handles 'add-locations'.
  const buildChanges = () => {
    const changes = get('changes', modification);
    if (changes.some((change) => change.type === 'add-locations')) {
      return changes.filter((change) => change.type === 'add-locations');
    }
    return changes;
  };
  const changes = buildChanges();
  const instantiation = get(['value', 'instantiation']);

  const formatChange = (change, key) => {
    switch (change.type) {
      case 'add-attribute-value':
      case 'remove-attribute-value':
      case 'modify-attribute-value': {
        const formattedKind = labelOf(instantiation(change) || {});
        const locationLabel =
          get(['value', 'locations', 0, 'label'], change) || 'New Location';
        return (
          <ModificationsListItemLine key={key}>
            Modified <Strong>{formattedKind}</Strong> for{' '}
            <Strong>{locationLabel}</Strong>
          </ModificationsListItemLine>
        );
      }

      case 'add-attribute-setting':
      case 'remove-attribute-setting':
      case 'modify-attribute-setting': {
        const formattedKind = labelOf(instantiation(change) || {});
        return (
          <ModificationsListItemLine key={key}>
            Modified <Strong>{formattedKind}</Strong> settings
          </ModificationsListItemLine>
        );
      }

      case 'add-locations': {
        const locationLabel =
          get(['value', 'locations', 0, 'label'], change) || 'New Location';
        return change.value.locations.length > 1 ? (
          <ModificationsListItemLine key={key}>
            Added <Strong>{change.value.locations.length} locations</Strong>
          </ModificationsListItemLine>
        ) : (
          <ModificationsListItemLine key={key}>
            Added <Strong>{locationLabel}</Strong>
          </ModificationsListItemLine>
        );
      }

      case 'remove-locations': {
        const locationLabel =
          get(['value', 'locations', 0, 'label'], change) || 'New Location';
        return change.value.locations.length > 1 ? (
          <ModificationsListItemLine key={key}>
            Removed <Strong>{change.value.locations.length} locations</Strong>
          </ModificationsListItemLine>
        ) : (
          <ModificationsListItemLine key={key}>
            Removed <Strong>{locationLabel}</Strong>
          </ModificationsListItemLine>
        );
      }

      case 'clear-values': {
        const formattedKind = labelOf(instantiation(change) || {});
        return (
          <ModificationsListItemLine key={key}>
            Removed all values for <Strong>{formattedKind}</Strong>
          </ModificationsListItemLine>
        );
      }

      case 'modify-population-input': {
        return (
          <ModificationsListItemLine key={key}>
            Modified <Strong>population inputs dataset</Strong>
          </ModificationsListItemLine>
        );
      }

      case 'import-locations': {
        return (
          <ModificationsListItemLine key={key}>
            Imported <Strong>{change.value.count} locations</Strong>
          </ModificationsListItemLine>
        );
      }

      case 'gap': {
        return (
          <ModificationsListItemLine key={key}>
            Modified scenario
          </ModificationsListItemLine>
        );
      }

      default: {
        return null;
      }
    }
  };

  const tooltipTimeString = modification.time_created
    ? `${modification.time_prefix ? modification.time_prefix : ''}${format(
        'd MMM yyyy, h:mm a',
        parseISO(modification.time_created),
      )}`
    : modification.time_string;
  const itemTimeString = modification.time_created
    ? `${
        modification.time_prefix ? modification.time_prefix : ''
      }${dateDistance}`
    : modification.time_string;

  return (
    <ModificationsListItemFrame>
      {changes.map((change, index) => formatChange(change, index))}
      <ModificationsListItemTooltip>
        <Tooltip placement='bottom' title={tooltipTimeString}>
          <ModificationsListItemLine>
            {capitalize(itemTimeString)}
          </ModificationsListItemLine>
        </Tooltip>
      </ModificationsListItemTooltip>
    </ModificationsListItemFrame>
  );
};

ModificationsListItem.propTypes = propTypes;
ModificationsListItem.defaultProps = defaultProps;

export default ModificationsListItem;
