import PropTypes from 'prop-types';
import { TableTitleWrapper } from './table-title.styles';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: undefined,
};

const TableTitle = ({ children }) => (
  <TableTitleWrapper>{children}</TableTitleWrapper>
);

TableTitle.propTypes = propTypes;
TableTitle.defaultProps = defaultProps;

export { TableTitle };
