import { createSelector } from 'reselect';
import {
  defaultTo,
  map,
  filter,
  flatMap,
  concat,
  pipe,
  get,
  values,
} from 'lodash/fp';
import currentAttributesSelector from 'selectors/currentAttributesSelector';
import { labelOf, sortAttributes } from 'data/attributes';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';

const customSummary = (label, name, setting) => ({
  custom: true,
  label,
  name,
  attribute: 'custom',
  ...setting,
});

const geographySummary = (label, setting) => ({
  custom: false,
  label,
  attribute: 'geography',
  ...setting,
});

const recycledWaterSummary = (label, setting) => ({
  custom: false,
  label,
  attribute: 'recycled-water-system',
  ...setting,
});

const rainwaterStorageSummary = (label, setting) => ({
  custom: false,
  label,
  attribute: 'rainwater-tank',
  ...setting,
});

const fineAreaCorrespondenceSummary = (label, setting) => ({
  custom: false,
  label,
  attribute: 'fine-area-correspondence',
  ...setting,
});

const landSummary = (label, setting) => ({
  custom: false,
  label,
  attribute: 'land',
  ...setting,
});

const solarSummary = (label, setting) => ({
  custom: false,
  label,
  attribute: 'solar',
  ...setting,
});

const serviceSummary = (label, setting) => ({
  custom: false,
  label,
  attribute: 'service-v2',
  ...setting,
});

const usageSummary = (label, setting) => ({
  custom: false,
  label,
  attribute: 'usage-v2',
  ...setting,
});

const defaultSettings = {};
const defaultInstances = {};

const settingsSummarySelector = createSelector(
  pipe(currentAttributesSelector, get('settings'), defaultTo(defaultSettings)),
  pipe(workspaceInstantiationsSelector, defaultTo(defaultInstances)),
  (settings, instances) => {
    const nameOf = get('name');
    return pipe(
      flatMap((key) => {
        const setting = get(key, settings);
        if (!setting) {
          return [];
        }
        return [
          {
            attribute: key,
            setting,
            instance: get(key, instances),
          },
        ];
      }),
      concat(
        pipe(
          values,
          filter({ attribute: 'custom' }),
          map((instance) => ({
            attribute: 'custom',
            setting: get(instance.key, settings),
            instance,
          })),
        )(instances),
      ),
      flatMap(({ attribute, setting, instance }) => {
        switch (attribute) {
          case 'usage-v2':
            return [usageSummary(labelOf(instance), setting)];
          case 'service-v2':
            return [serviceSummary(labelOf(instance), setting)];
          case 'solar':
            return [solarSummary(labelOf(instance), setting)];
          case 'land':
            return [landSummary(labelOf(instance), setting)];
          case 'rainwater-tank':
            return [rainwaterStorageSummary(labelOf(instance), setting)];
          case 'recycled-water-system':
            return [recycledWaterSummary(labelOf(instance), setting)];
          case 'geography':
            return [geographySummary(labelOf(instance), setting)];
          case 'fine-area-correspondence':
            return [fineAreaCorrespondenceSummary(labelOf(instance), setting)];
          case 'custom':
            return [
              customSummary(labelOf(instance), nameOf(instance), setting),
            ];
          default:
            return [];
        }
      }),
      sortAttributes,
    )([
      'geography',
      'land',
      'usage-v2',
      'service-v2',
      'solar',
      'rainwater-tank',
      'recycled-water-system',
      'fine-area-correspondence',
    ]);
  },
);

export default settingsSummarySelector;
