import { createSelector } from 'reselect';
import { defaultTo, filter, find, get, isEqual, map, pipe } from 'lodash/fp';
import { filterLocationsByAttributes } from 'utils/locationUtils';
import currentAttributesSelector from './currentAttributesSelector';
import currentPublishedScenarioSelector from './currentPublishedScenarioSelector';
import workspaceSelector from './workspaceSelector';

const defaultAttributesLookup = {};
const defaultPublishedAttributesLookup = {};

const scenarioLayersSelector = createSelector(
  pipe(workspaceSelector, get(['layers', 'entities'])),
  pipe(workspaceSelector, get('locations')),
  pipe(currentAttributesSelector, get('entities')),
  pipe(
    currentAttributesSelector,
    get('values'),
    defaultTo(defaultAttributesLookup),
  ),
  pipe(
    currentPublishedScenarioSelector,
    get('values'),
    defaultTo(defaultPublishedAttributesLookup),
  ),
  (
    layers,
    locations,
    attributeEntities,
    attributesLookup,
    publishedAttributesLookup,
  ) =>
    map(
      (layer) => ({
        id: layer.id,
        label: layer.label,
        type: layer.type,
        locations: filter((location) => {
          const isLocationModified = isEqual(
            attributesLookup[location.id],
            publishedAttributesLookup[location.id],
          );
          return (
            location.layer === layer.id &&
            find({ locationId: location.id }, attributeEntities) &&
            layer.modificationState !==
              (isLocationModified ? 'notModified' : 'modified') &&
            filterLocationsByAttributes(
              layer.fields,
              get([location.id, 'attributes'], attributesLookup),
            )
          );
        }, locations),
      }),
      layers,
    ),
);

export default scenarioLayersSelector;
