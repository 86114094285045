import createApiFromState from 'api/createApiFromState';
import { actions as preferencesActions } from 'reducers/preferencesReducer';

const { initialisePreferences, setPreference } = preferencesActions;

function createPreferencesMiddleware() {
  let hasInitialised = false;

  return ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
      const state = getState();
      const api = createApiFromState({ dispatch, getState });

      if (!hasInitialised && state.auth.isLoggedIn) {
        hasInitialised = true;
        const response = await api.get('/api/preferences/list');
        dispatch(initialisePreferences(response.data));
      }

      next(action);

      if (action.type === setPreference.toString()) {
        await api.post('/api/preferences/set', {
          property: action.payload.key,
          preference: action.payload.value,
        });
      }
    };
}

export default createPreferencesMiddleware;
