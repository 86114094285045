import styled from 'styled-components';

const WorkspaceScreenPlaceholder = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray2.hex};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export { WorkspaceScreenPlaceholder };
