// Work around for: https://github.com/import-js/eslint-plugin-import/issues/2297
/* eslint-disable import/no-import-module-exports */
import { configureStore } from '@reduxjs/toolkit';
import createRootReducer from 'reducers';
import createAnalyticsMiddleware from 'middleware/analyticsMiddleware';
import createAuthMiddleware from 'middleware/authMiddleware';
import createLocationsMiddleware from 'middleware/locationsMiddleware';
import createPreferencesMiddleware from 'middleware/preferencesMiddleware';
import createRouteMiddleware from 'middleware/routeMiddleware';
import createWorkspaceMiddleware from 'middleware/workspaceMiddleware';
import createBlockSelectionsMiddleware from 'middleware/blockSelectionsMiddleware';
import createBlockAddMiddleware from 'middleware/blockAddMiddleware';
import createBlockTimeBoundsMiddleware from 'middleware/blockTimeBoundsMiddleware';
import createGeographyMiddleware from 'middleware/geographyMiddleware';
import createInvariantMiddleware from 'middleware/invariantMiddleware';
import { createWorkspacePreviewMiddleware } from 'middleware/workspacePreviewMiddleware';
import { blockPerspectiveInvariants } from 'middleware/invariants/blockPerspective';
import { blockSelectionsInvariants } from 'middleware/invariants/blockSelections';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { api } from '@/api/api';

/* eslint-enable import/no-import-module-exports */

function createStore({ initialState, location, navigate }) {
  const rootReducer = createRootReducer();
  const middleware = [
    api.middleware,
    createAuthMiddleware(),
    createWorkspaceMiddleware({ location }),
    createLocationsMiddleware(),
    createRouteMiddleware({ navigate }),
    createPreferencesMiddleware({ storageKey: 'marathonPreferences' }),
    createBlockSelectionsMiddleware(),
    createBlockAddMiddleware(),
    createGeographyMiddleware(),
    createAnalyticsMiddleware(),
    createBlockTimeBoundsMiddleware(),
    createWorkspacePreviewMiddleware(),
    ...(process.env.NODE_ENV !== 'production'
      ? [
          createInvariantMiddleware([
            ...blockPerspectiveInvariants,
            ...blockSelectionsInvariants,
          ]),
        ]
      : []),
  ];
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(...middleware),
    devTools: true,
  });
  setupListeners(store.dispatch);
  return store;
}

export { createStore };
