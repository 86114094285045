import PropTypes from 'prop-types';
import { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash/fp';
import { publicWorkspaceDataFetch } from 'actions/publicWorkspaceDataFetch';
import { usePerspectives, useSelectorWithProps } from 'hooks';
import fetchablePublicDataSelector from 'selectors/fetchablePublicDataSelector';

const propTypes = {
  boardId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  maximisedBlockId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired,
};

const defaultProps = {};

const LifecyclePublic = ({
  boardId,
  children,
  maximisedBlockId,
  workspaceId,
}) => {
  const dispatch = useDispatch();
  const perspectives = usePerspectives();
  const blockIds = useMemo(
    () => (maximisedBlockId ? [maximisedBlockId] : undefined),
    [maximisedBlockId],
  );
  const fetchable = useSelectorWithProps(fetchablePublicDataSelector, {
    blockIds,
    boardId,
    perspectives,
    public: true,
    workspaceId,
  });

  useEffect(() => {
    if (!isEmpty(fetchable)) {
      dispatch(
        publicWorkspaceDataFetch({
          workspaceId,
          perspectives: fetchable,
        }),
      );
    }
  }, [dispatch, workspaceId, fetchable]);

  return children;
};

LifecyclePublic.propTypes = propTypes;
LifecyclePublic.defaultProps = defaultProps;

export { LifecyclePublic };
