import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const availabilityFetch = createAsyncThunk(
  'availability/fetch',
  async ({ scenarioKey, workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.get(
      `/api/workspace/${workspaceId}/scenario/${scenarioKey}/availability`,
    );
    return response.data;
  },
);

export { availabilityFetch };
