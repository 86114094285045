import { createSelector } from 'reselect';
import { filter, get, map, negate, pipe, sortBy, identity } from 'lodash/fp';
import { hasDimension, hasMeasure, hasContext, hasGeography } from 'data/table';
import tablesSelector from 'selectors/tablesSelector';

const criteria = {
  indicator: pipe(filter(hasMeasure)),
  choropleth: pipe(filter(hasMeasure), filter(hasGeography)),
  'stacked-column': pipe(filter(hasMeasure), filter(hasDimension)),
  'clustered-column': pipe(filter(hasMeasure), filter(hasDimension)),
  'stacked-area': pipe(filter(hasMeasure), filter(hasDimension)),
  line: pipe(filter(hasMeasure), filter(hasDimension)),
};

const visualisationTableOptionsSelector = createSelector(
  (state, props) => props.type,
  tablesSelector,
  (type, tables) =>
    pipe(
      filter(negate(hasContext)),
      get(type, criteria) || identity,
      map(({ label, family }) => ({
        label,
        value: family,
      })),
      sortBy('label'),
    )(tables),
);

export default visualisationTableOptionsSelector;
