import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const pathwayInputsDownload = createAsyncThunk(
  'pathway/inputs-download',
  async ({ input, locations, locationType }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/pathways/inputs-download`, {
      input,
      locations,
      location_type: locationType,
    });

    window.location = response.data.url;
  },
);

export { pathwayInputsDownload };
