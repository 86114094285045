import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  compact,
  filter,
  get,
  isEmpty,
  map,
  pipe,
  size,
  sortBy,
} from 'lodash/fp';
import PropTypes from 'prop-types';
import { blockInitialiseStaticVis } from 'actions/blockInitialiseStaticVis';
import workspaceAppDetailsSelector from 'selectors/workspaceAppDetailsSelector';
import { BlockMenu } from 'components/block-menu';
import { Stack } from '@kinesis/bungle';
import { useBoardId, useSelectorWithProps } from 'hooks';
import { EmptyContainer } from './block-empty.styles';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const BlockEmpty = ({ blockId, workspaceId }) => {
  const dispatch = useDispatch();

  const boardId = useBoardId();
  const blocksByVersion = useSelector(get('blockValues'));
  const workspaceAppDetails = useSelectorWithProps(
    workspaceAppDetailsSelector,
    { workspaceId },
  );

  const capsules = useMemo(
    () =>
      workspaceAppDetails.reduce(
        (acc, app) => ({ ...get('capsules', app), ...acc }),
        {},
      ),
    [workspaceAppDetails],
  );

  const appResources = useMemo(() => {
    const result = workspaceAppDetails.map((app) => {
      const boardResources = filter((resource) => resource.type === 'board')(
        get('resources', app),
      );

      return { label: get('label', app), boardResources };
    });

    return compact(result);
  }, [workspaceAppDetails]);

  const appBoards = useMemo(
    () =>
      appResources.reduce((acc, appResource) => {
        appResource.boardResources.forEach((boardResource) => {
          const boardCapsules = capsules[boardResource.resource];

          const boardBlocks = pipe(
            map((version) => blocksByVersion[version]),
            compact,
            filter((block) => block.type === 'visualisation'),
          )(boardCapsules);

          if (size(boardBlocks)) {
            acc.push({
              title: `${boardResource.title} (${appResource.label})`,
              blocks: boardBlocks,
            });
          }
        });

        return sortBy(['title'])(acc);
      }, []),
    [appResources, blocksByVersion, capsules],
  );

  const onSelect = useCallback(
    ({ blockVersion, capsule }) => {
      dispatch(
        blockInitialiseStaticVis({
          workspaceId,
          boardId,
          blockId,
          blockVersion,
          capsule,
        }),
      );
    },
    [dispatch, workspaceId, boardId, blockId],
  );

  return (
    <EmptyContainer>
      <Stack space='large'>
        <div>
          {isEmpty(appBoards)
            ? 'Configure your visualisation using the toolbox controls.'
            : 'Configure your visualisation using the toolbox controls or select from the list below.'}
        </div>
        <BlockMenu
          appBoards={appBoards}
          blockId={blockId}
          onSelect={onSelect}
        />
      </Stack>
    </EmptyContainer>
  );
};

BlockEmpty.propTypes = propTypes;
BlockEmpty.defaultProps = defaultProps;

export { BlockEmpty };
