import { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  uniq,
  forEach,
  first,
  filter,
  flatMap,
  get,
  isEmpty,
  isNil,
  map,
  mapValues,
  pick,
  pipe,
  reject,
  take,
} from 'lodash/fp';
import progressSelector from 'selectors/progressSelector';
import statisticsStatusSelector from 'selectors/statisticsStatusSelector';
import requirementsSelector from 'selectors/requirementsSelector';
import blockIdsSelector from 'selectors/blockIdsSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import scenariosSelector from 'selectors/scenariosSelector';
import { blockProgress } from 'actions/blockProgress';
import { statisticsFetch } from 'actions/statisticsFetch';
import { visualisationQuery } from 'actions/visualisationQuery';
import { dataFetch } from 'actions/dataFetch';
import { useBlockId, useScenarioId, useSelectorWithProps } from 'hooks';
import {
  blockProcessingStates,
  getActiveVersion,
  queryStates,
} from 'data/block';
import { actions as miscActions } from 'reducers/miscReducer';
import {
  orderBlockIds,
  isBlockInView,
  isBlockFetchable,
  isBlockInNeedOfData,
} from 'data/lifecycle';

const propTypes = {
  boardId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const Lifecycle = ({ boardId, children, workspaceId }) => {
  const dispatch = useDispatch();
  const queryCount = useSelector(get(['misc', 'queryCount']));
  const currentScenarioId = useScenarioId();
  const statisticsStatus = useSelectorWithProps(statisticsStatusSelector, {
    workspaceId,
  });
  const maximisedBlockId = useBlockId();
  const blockIds = useSelectorWithProps(blockIdsSelector, {
    boardId,
    workspaceId,
  });
  const workspaceBlockStates = useSelectorWithProps(
    pipe(workspaceSelector, get('blocks')),
    { workspaceId },
  );
  const blockValues = useSelector(get('blockValues'));
  const data = useSelector(get('data'));
  const allScenarios = useSelectorWithProps(scenariosSelector, { workspaceId });
  const { blockPolling: polling } = useSelectorWithProps(progressSelector, {
    workspaceId,
  });
  const requirements = useSelectorWithProps(requirementsSelector, {
    blockIds: maximisedBlockId ? [maximisedBlockId] : undefined,
    boardId,
    currentScenarioId,
    workspaceId,
  });

  const scenariosWithKeys = useMemo(
    () =>
      mapValues(
        (s) =>
          get(['draft', 'scenario'], s) || get(['published', 'scenario'], s),
      )(allScenarios),
    [allScenarios],
  );

  // STEP 1: Make sure data is available.
  useEffect(() => {
    if (!currentScenarioId) return;
    if (polling === 'pending') return;
    const scenarios = pipe(
      flatMap('scenarios'),
      filter((s) =>
        [
          blockProcessingStates.INITIAL,
          blockProcessingStates.PROCESSING_INITIAL,
          blockProcessingStates.PROCESSING_STALE,
        ].includes(get(['state', 'block'], s)),
      ),
    )(requirements);
    if (!isEmpty(scenarios) && polling === 'idle') {
      dispatch(blockProgress({ workspaceId, scenarios }));
    }
    if (!isEmpty(scenarios) && polling === 'polling') {
      const timerId = setTimeout(() => {
        dispatch(blockProgress({ workspaceId, scenarios }));
      }, 6000);
      return () => clearTimeout(timerId);
    }
  }, [dispatch, workspaceId, requirements, polling, currentScenarioId]);

  // STEP 2: Make sure statistics are available.
  useEffect(() => {
    if (!currentScenarioId) return;
    const scenarios = pipe(
      flatMap('scenarios'),
      filter((s) =>
        [
          blockProcessingStates.LOADING_INITIAL,
          blockProcessingStates.LOADING_STALE,
        ].includes(get(['state', 'block'], s)),
      ),
      map(pick(['blockId', 'scenarioId', 'scenarioKey', 'tables'])),
      flatMap(({ blockId, scenarioId, scenarioKey, tables }) =>
        map((table) => ({
          blockId,
          scenarioId,
          scenarioKey,
          table,
        }))(tables),
      ),
      reject(({ scenarioKey, table }) =>
        ['FETCHING', 'COMPLETE'].includes(
          get([table, scenarioKey], statisticsStatus),
        ),
      ),
    )(requirements);
    if (!isEmpty(scenarios)) {
      dispatch(statisticsFetch({ workspaceId, scenarios }));
    }
  }, [
    dispatch,
    workspaceId,
    currentScenarioId,
    requirements,
    statisticsStatus,
  ]);

  // STEP 3: Make sure data is available.
  useEffect(() => {
    if (!currentScenarioId) return;
    const scenarios = pipe(
      flatMap('scenarios'),
      filter((s) =>
        [
          blockProcessingStates.LOADING_INITIAL,
          blockProcessingStates.LOADING_STALE,
        ].includes(get(['state', 'block'], s)),
      ),
      filter((s) => [queryStates.INITIAL].includes(get(['state', 'query'], s))),
      map(pick(['blockId', 'scenarioId', 'scenarioKey', 'tables'])),
      flatMap(({ blockId, scenarioId, scenarioKey, tables }) =>
        map((table) => ({
          blockId,
          scenarioId,
          scenarioKey,
          table,
        }))(tables),
      ),
      filter(({ scenarioKey, table }) =>
        ['COMPLETE'].includes(get([table, scenarioKey], statisticsStatus)),
      ),
    )(requirements);
    if (!isEmpty(scenarios)) {
      // FIX visualisationQuery to take scenarios.
      // FIX API, so it is a single call.
      const throttle = Math.max(0, 3 - queryCount);
      forEach((blockId) => {
        dispatch(
          visualisationQuery({
            blockId,
            currentScenarioId,
            scenarios,
            workspaceId,
          }),
        );
      })(pipe(map(get('blockId')), uniq, take(throttle))(scenarios));
    }
  }, [
    dispatch,
    queryCount,
    currentScenarioId,
    statisticsStatus,
    requirements,
    workspaceId,
  ]);

  // START NEW EDITABLE VISUALISATION BLOCK LIFECYCLE
  const triggerLifecycleHook = useCallback(() => {
    dispatch(miscActions.tickLifecycle());
  }, [dispatch]);

  const lifecycleTrigger = useSelector(get(['misc', 'lifecycleTrigger']));

  useEffect(() => {
    if (queryCount > 0) return;
    if (!currentScenarioId) return;

    const fetchableBlockIds = filter((blockId) => {
      const blockState = get(blockId, workspaceBlockStates);
      const blockPerspective = get(
        ['scenarios', currentScenarioId],
        blockState,
      );
      const blockValue = get(getActiveVersion(blockState), blockValues);

      const viewable = isBlockInView(blockId, maximisedBlockId);
      const fetchable = isBlockFetchable(blockValue, blockState);
      const needsData = isBlockInNeedOfData(
        blockState,
        blockPerspective,
        scenariosWithKeys,
      );
      return viewable && fetchable && needsData;
    }, blockIds);

    if (isEmpty(fetchableBlockIds)) {
      return;
    }

    const orderedBlockIds = orderBlockIds(
      fetchableBlockIds,
      workspaceBlockStates,
      currentScenarioId,
    );

    const blockToFetch = first(orderedBlockIds);
    const lastQueried = get(
      [blockToFetch, 'scenarios', currentScenarioId, 'lastQueried'],
      workspaceBlockStates,
    );
    const shouldFetch = isNil(lastQueried) || Date.now() - lastQueried > 3000;

    if (shouldFetch) {
      const blockState = get(blockToFetch, workspaceBlockStates);
      const blockValue = get(getActiveVersion(blockState), blockValues);

      dispatch(
        dataFetch({
          blockId: blockToFetch,
          capsule: blockValue,
          scenarioId: parseInt(currentScenarioId, 10),
          scenarios: scenariosWithKeys,
          workspaceId,
        }),
      );
    } else {
      const pollTime = 3000 - (Date.now() - lastQueried);
      const pollingTimer = setTimeout(triggerLifecycleHook, pollTime);
      return () => clearTimeout(pollingTimer);
    }
  }, [
    currentScenarioId,
    data,
    dispatch,
    blockIds,
    blockValues,
    scenariosWithKeys,
    workspaceBlockStates,
    lifecycleTrigger,
    queryCount,
    triggerLifecycleHook,
    maximisedBlockId,
    workspaceId,
  ]);

  // END NEW EDITABLE VISUALISATION BLOCK LIFECYCLE

  return children;
};

Lifecycle.propTypes = propTypes;
Lifecycle.defaultProps = defaultProps;

export { Lifecycle };
