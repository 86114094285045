import { get } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

function currentPublishedScenarioSelector(state, props = {}) {
  const workspace = workspaceSelector(state, props);
  const scenarioKey = get(
    ['scenarios', 'named', props.scenarioId, 'published', 'scenario'],
    workspace,
  );

  return get([scenarioKey, 'attributes'], workspace);
}

export default currentPublishedScenarioSelector;
