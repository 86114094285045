import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetSetType = createAsyncThunk(
  'dataset/set-type',
  async ({ id, type }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.put(`/api/datasets/${id}/type`, { type });
    return response.data;
  },
);

export { datasetSetType };
