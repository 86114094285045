import { createSlice } from '@reduxjs/toolkit';
import {
  find,
  get,
  has,
  keyBy,
  map,
  mapValues,
  pipe,
  set,
  stubTrue,
  update,
  values as _values,
  zipObject,
} from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { geographyUpdate } from 'actions/geographyUpdate';
import { workspaceInitialise } from 'actions/workspaceInitialise';

const mapValuesWithKey = mapValues.convert({ cap: false });

const setEntities = (state, action) =>
  set(
    'entities',
    pipe(
      map((item) =>
        state.entities[item.id]
          ? {
              ...state.entities[item.id],
              label: item.label,
            }
          : {
              enabled: item.type === 'data',
              fields: {
                service: {
                  operation: 'or',
                  values: {},
                },
                usage: {
                  operation: 'or',
                  values: {},
                },
              },
              id: item.id,
              label: item.label,
              color: item.color,
              group: item.group,
              type: item.type,
              modificationState: 'all',
            },
      ),
      keyBy('id'),
    )(action.payload.layers),
    state,
  );

const setFields = (state, action) => {
  const { attributes, scenarioKey } = action.payload;
  const settings = get([scenarioKey, 'settings'], attributes);

  const services = [
    ...map('key', get(['service-v2', 'entries'], settings)),
    'none',
  ];
  const usages = [
    ...map('key', get(['usage-v2', 'categories'], settings)),
    'none',
  ];

  return update(
    'entities',
    mapValues(
      pipe(
        update(['fields', 'service', 'values'], (prev) =>
          zipObject(
            services,
            map((key) => (has(key, prev) ? prev[key] : true), services),
          ),
        ),
        update(['fields', 'usage', 'values'], (prev) =>
          zipObject(
            usages,
            map((key) => (has(key, prev) ? prev[key] : true), usages),
          ),
        ),
      ),
    ),
    state,
  );
};

const updateLayers = (state, action) => {
  const baselineScenarioKey = pipe(
    get('scenarios'),
    _values,
    find({ isDefault: true }),
    (s) => s.draft || s.published,
    get('scenario'),
  )(action.payload);
  return setFields(
    setEntities(state, action),
    pipe(set(['payload', 'scenarioKey'], baselineScenarioKey))(action),
  );
};

const { reducer, actions } = createSlice({
  name: 'layers',

  initialState: {
    entities: {},
    visualisationLayerEnabled: true,
  },

  reducers: {
    resetAll: (state, action) => {
      const { layerId } = action.payload;

      return update(
        ['entities', layerId],
        pipe(
          set('modificationState', 'all'),
          update(
            'fields',
            mapValues((field) => ({
              ...field,
              operation: 'or',
              values: mapValuesWithKey(stubTrue, field.values),
            })),
          ),
        ),
        state,
      );
    },
    resetField: (state, action) => {
      const { fieldName, layerId } = action.payload;

      return update(
        ['entities', layerId, 'fields', fieldName],
        (field) => ({
          ...field,
          operation: 'or',
          values: mapValuesWithKey(stubTrue, field.values),
        }),
        state,
      );
    },
    setField: (state, action) => {
      const { fieldName, layerId, values } = action.payload;

      return update(
        ['entities', layerId, 'fields', fieldName, 'values'],
        mapValuesWithKey((val, key) => values.includes(key)),
        state,
      );
    },
    setLayerModificationState: (state, action) => {
      const { layerId, modificationState } = action.payload;

      return set(
        ['entities', layerId, 'modificationState'],
        modificationState,
        state,
      );
    },
    toggleLayer: (state, action) =>
      update(
        ['entities', action.payload.layerId, 'enabled'],
        (enabled) => !enabled,
        state,
      ),
    toggleFieldOperation: (state, action) => {
      const { layerId, fieldName } = action.payload;

      return update(
        ['entities', layerId, 'fields', fieldName, 'operation'],
        (value) => (value === 'or' ? 'and' : 'or'),
        state,
      );
    },
    toggleVisualisationLayer: update(
      'visualisationLayerEnabled',
      (enabled) => !enabled,
    ),
  },

  extraReducers: (builder) => {
    builder.addCase(workspaceInitialise, setEntities);
    builder.addCase(appAdd.fulfilled, updateLayers);
    builder.addCase(workspaceFetch.fulfilled, updateLayers);
    builder.addCase(workspacePatch.fulfilled, updateLayers);
    builder.addCase(geographyUpdate.fulfilled, updateLayers);
  },
});

export { reducer, actions };
