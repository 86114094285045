import { createSelector } from 'reselect';
import { pipe, get, values } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const locationsSelector = createSelector(
  pipe(workspaceSelector, get('locations')),
  values,
);

export default locationsSelector;
