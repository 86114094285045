import styled from 'styled-components';
import { Toolbar } from '@kinesis/bungle/legacy';

const AppHeaderWrapper = styled('header')(({ theme }) => ({
  backgroundColor: 'white',
  borderBottom: `1px solid ${theme.borderColor}`,
  borderRadius: '0px 0 0 0',
  boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.05)`,
  boxSizing: 'content-box',
  minHeight: 48,
  zIndex: 100,
}));

const InlineToolbar = styled(Toolbar).attrs({
  inline: true,
})({
  marginRight: -8,
});

export { AppHeaderWrapper, InlineToolbar };
