import { get } from 'lodash/fp';
import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const dataFetch = createAsyncThunk(
  'data/fetch',
  async (
    { capsule, scenarioId, scenarios, workspaceId },
    { dispatch, getState, rejectWithValue },
  ) => {
    const api = createApiFromState({ dispatch, getState });

    try {
      const response = await api.post(`/api/workspace/${workspaceId}/data`, {
        block: capsule,
        scenario: scenarioId,
        scenarios,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue({
        status: get(['response', 'status'], err),
        data: get(['response', 'data'], err),
      });
    }
  },
);

export { dataFetch };
