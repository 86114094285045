import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const blockInitialiseStaticVis = createAsyncThunk(
  'block/initialiseStaticVis',
  async (
    { workspaceId, boardId, blockId, blockVersion },
    { dispatch, getState },
  ) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(
      `/api/workspace/${workspaceId}/board/${boardId}/block/${blockId}`,
      { version: blockVersion },
    );
    return response.data;
  },
);

export { blockInitialiseStaticVis };
