import { get } from 'lodash/fp';
import scenarioKeySelector from './scenarioKeySelector';
import workspaceSelector from './workspaceSelector';

function locationAttributesSelector(state, props = {}) {
  const { locationId } = props;
  const scenarioKey = props.scenarioKey ?? scenarioKeySelector(state, props);
  const workspace = workspaceSelector(state, props);

  return get(['attributes', scenarioKey, 'values', locationId], workspace);
}

export default locationAttributesSelector;
