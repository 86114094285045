import fullStorySelector from 'selectors/fullStorySelector';
import FullStoryService from 'services/fullStoryService';
import { userLogout } from 'actions/userLogout';
import { userLogin } from 'actions/userLogin';
import { userRelogin } from 'actions/userRelogin';

function analyticsMiddleware() {
  return ({ getState }) =>
    (next) =>
    (action) => {
      next(action);

      switch (action.type) {
        case userLogin.fulfilled.type:
        case userRelogin.fulfilled.type:
          FullStoryService.initialise(fullStorySelector(getState()));
          break;
        case userLogout.fulfilled: {
          FullStoryService.shutdown();
          break;
        }
        default:
      }
    };
}

export default analyticsMiddleware;
