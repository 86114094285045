import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash/fp';
import { GlobalEditContext } from 'contexts';
import { DataGridWrapper } from './data-grid.styles';

const borderClipType = PropTypes.oneOf(['bottom', 'left', 'right', 'top']);

const GlobalEditProvider = GlobalEditContext.Provider;

const propTypes = {
  borderClip: PropTypes.oneOfType([
    borderClipType,
    PropTypes.arrayOf(borderClipType),
  ]),
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

const defaultProps = {
  borderClip: undefined,
  label: undefined,
};

const DataGrid = ({ borderClip, children, label }) => {
  const ref = useRef();
  const contextValue = useState(false);
  const [, setGlobalEdit] = contextValue;
  const borderClipTop = borderClip === 'top' || includes('top', borderClip);
  const borderClipBottom =
    borderClip === 'bottom' || includes('bottom', borderClip);
  const borderClipLeft = borderClip === 'left' || includes('left', borderClip);
  const borderClipRight =
    borderClip === 'right' || includes('right', borderClip);

  const handleBlur = useCallback(
    ({ currentTarget, relatedTarget }) => {
      if (!currentTarget.contains(relatedTarget)) {
        setGlobalEdit(false);
      }
    },
    [setGlobalEdit],
  );

  return (
    <GlobalEditProvider value={contextValue}>
      <DataGridWrapper
        ref={ref}
        data-grid-border-clip-top={borderClipTop}
        data-grid-border-clip-bottom={borderClipBottom}
        data-grid-border-clip-left={borderClipLeft}
        data-grid-border-clip-right={borderClipRight}
        label={label}
        onBlur={handleBlur}
      >
        {children}
      </DataGridWrapper>
    </GlobalEditProvider>
  );
};

DataGrid.propTypes = propTypes;
DataGrid.defaultProps = defaultProps;

export { DataGrid };
