import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { get, isNil } from 'lodash/fp';
import { Stack, InPlaceInput } from '@kinesis/bungle';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { VerticalPane, VerticalPanes } from 'components/vertical-panes';
import organisationsSelector from 'selectors/organisationsSelector';
import { datasetSetName } from 'actions/datasetSetName';
import { datasetSetDescription } from 'actions/datasetSetDescription';
import { datasetSetType } from 'actions/datasetSetType';
import { datasetSetOwner } from 'actions/datasetSetOwner';
import { useDataset, useSelectorWithProps } from 'hooks';
import { ContentPadded } from 'components/styled/ContentPadded';
import { IconMenuItem } from 'components/icon-menu-item';
import { DatasetTypeDropdown } from 'components/dataset-type-dropdown';
import { OrganisationDropdown } from 'components/organisation-dropdown';

const propTypes = {
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const DatasetPane = ({ privacy, workspaceId }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { autoSelect = false } = location.state ?? {};
  const dataset = useDataset();
  const datasetId = dataset?.id;
  const loading = isNil(dataset);
  const organisations = useSelectorWithProps(organisationsSelector, {
    public: privacy === 'public',
    workspaceId,
  });

  const onSaveName = useCallback(
    (name) => {
      dispatch(datasetSetName({ id: datasetId, name }));
    },
    [dispatch, datasetId],
  );

  const onSaveDescription = useCallback(
    (description) => {
      dispatch(datasetSetDescription({ id: datasetId, description }));
    },
    [dispatch, datasetId],
  );

  const onSaveType = useCallback(
    (type) => {
      dispatch(datasetSetType({ id: datasetId, type }));
    },
    [dispatch, datasetId],
  );

  const onSaveOwner = useCallback(
    (owner) => {
      dispatch(datasetSetOwner({ id: datasetId, owner }));
    },
    [dispatch, datasetId],
  );

  if (loading) return null;
  return (
    <ToolboxPanel>
      <VerticalPanes>
        <VerticalPane>
          <ContentPadded>
            <Stack space='medium'>
              <InPlaceInput
                autoFocus={autoSelect}
                onChange={onSaveName}
                placeholder='Dataset name'
                value={get('name', dataset)}
                variant='title'
              />
              <InPlaceInput
                onChange={onSaveDescription}
                placeholder='Write a description'
                value={get('description', dataset) || ''}
              />
              <div>
                <IconMenuItem icon='data-type'>
                  <DatasetTypeDropdown
                    disabled
                    value={get('type', dataset)}
                    onSelect={onSaveType}
                  />
                </IconMenuItem>
                <IconMenuItem icon='building-filled'>
                  <OrganisationDropdown
                    disabled
                    value={get('owner', dataset)}
                    organisations={organisations}
                    onSelect={onSaveOwner}
                  />
                </IconMenuItem>
              </div>
            </Stack>
          </ContentPadded>
        </VerticalPane>
      </VerticalPanes>
    </ToolboxPanel>
  );
};

DatasetPane.propTypes = propTypes;
DatasetPane.defaultProps = defaultProps;

export { DatasetPane };
