import { useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { first, get, map } from 'lodash/fp';
import { Stack, InPlaceInput, Select } from '@kinesis/bungle';
import Modal from 'components/modals/modal/Modal';
import { datasetCreate } from 'actions/datasetCreate';
import { ModalHeader, ModalHeading } from '../modal-header';
import { FormLayout } from './new-dataset.styles';

const propTypes = {
  animation: PropTypes.bool,
  organisations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      primary: PropTypes.bool,
      avatarUrl: PropTypes.string,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  primaryOrganisationId: PropTypes.number,
};

const defaultProps = {
  animation: true,
  primaryOrganisationId: undefined,
};

const typeOptions = [
  { label: 'Table', value: 'table' },
  { label: 'Locations', value: 'locations' },
  { label: 'Location attributes', value: 'attributes' },
];

const NewDatasetModal = ({
  animation,
  onClose,
  organisations,
  primaryOrganisationId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ownerOptions = useMemo(
    () =>
      map(
        ({ id, name, avatarUrl }) => ({
          label: name,
          value: id,
          avatar: avatarUrl,
        }),
        organisations,
      ),
    [organisations],
  );
  const [name, setName] = useState('');
  const [type, setType] = useState('table');
  const defaultOwner =
    primaryOrganisationId || get('value', first(ownerOptions));
  const [owner, setOwner] = useState(defaultOwner);
  const headerLabel = 'New dataset';

  const onSave = useCallback(async () => {
    const { id } = await dispatch(
      datasetCreate({ owner, name, type }),
    ).unwrap();

    if (id) {
      navigate(`/datasets/${id}`, { state: { autoSelect: true } });
    }
  }, [dispatch, name, navigate, owner, type]);

  return (
    <Modal
      header={
        <ModalHeader>
          <ModalHeading>{headerLabel}</ModalHeading>
        </ModalHeader>
      }
      animation={animation}
      onClose={onClose}
      onSave={onSave}
      magnitude='compact'
      minHeight={306}
      aria-label={headerLabel}
    >
      <FormLayout>
        <Stack space='medium'>
          <InPlaceInput
            autoFocus
            onChange={setName}
            placeholder='Dataset name'
            value={name}
            variant='title'
          />
          <Select
            label='Type'
            onChange={setType}
            value={type}
            options={typeOptions}
          />
          <Select
            label='Owner organisation'
            onChange={setOwner}
            value={owner}
            options={ownerOptions}
          />
        </Stack>
      </FormLayout>
    </Modal>
  );
};

NewDatasetModal.defaultProps = defaultProps;
NewDatasetModal.propTypes = propTypes;

export { NewDatasetModal };
