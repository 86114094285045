import { createSelector } from 'reselect';
import { pipe, get, filter, includes } from 'lodash/fp';
import locationsSelector from './locationsSelector';
import currentAttributesSelector from './currentAttributesSelector';
import workspaceSelector from './workspaceSelector';

const recentLocationsSelector = createSelector(
  locationsSelector,
  pipe(currentAttributesSelector, get('values')),
  pipe(workspaceSelector, get('recentLocations')),
  (locations, attributesLookup, recentLocations) =>
    pipe(
      filter((location) => includes(location.id, recentLocations)),
      filter((location) => get(location.id, attributesLookup)),
    )(locations),
);
export default recentLocationsSelector;
