import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import { empty, rowBreak } from 'utils/blockVersions';

const blockAdd = createAsyncThunk(
  'block/add',
  async ({ type, workspaceId, boardId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    let response;

    if (type === 'visualisation') {
      response = await api.post(
        `/api/workspace/${workspaceId}/board/${boardId}/block`,
        { versions: [rowBreak, empty] },
      );
    } else if (type === 'text') {
      response = await api.post(
        `/api/workspace/${workspaceId}/board/${boardId}/block-text`,
        {
          rowBreak,
          blockParams: {
            title: 'New text',
            text: '',
          },
        },
      );
    }

    return response.data;
  },
);

export { blockAdd };
