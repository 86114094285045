import toposort from 'toposort';
import {
  compact,
  curry,
  find,
  findIndex,
  uniq,
  last,
  filter,
  map,
  first,
  isEqual,
  toPairs,
  flatMap,
  concat,
  orderBy,
  pipe,
  split,
  get,
} from 'lodash/fp';

const mapping = {
  usage: 'usage-v2',
  service: 'service-v2',
};

const keyFor = (key) => mapping[key] || key;

const encodingOf = (specification) => {
  switch (get(['settingType', 'type'], specification)) {
    case 'string-select':
      return 'string';
    case 'float64-select':
    case 'float64-numeric':
    case 'float64-labelled-tensor':
      return 'float64';
    case 'int64-select':
    case 'int64-numeric':
    case 'int64-labelled-tensor':
      return 'int64';
    default:
      return undefined;
  }
};

const typeOf = (specification) => {
  switch (get(['settingType', 'type'], specification)) {
    case 'string-select':
    case 'float64-select':
    case 'int64-select':
      return 'select';
    case 'float64-numeric':
    case 'int64-numeric':
      return 'numeric';
    case 'float64-labelled-tensor':
    case 'int64-labelled-tensor':
      return 'tensor';
    default:
      return undefined;
  }
};

const supportedTypes = ['select', 'numeric', 'tensor'];

const isScalar = (specification) =>
  ['numeric', 'select'].includes(typeOf(specification));

const isTensor = (specification) => ['tensor'].includes(typeOf(specification));

const optionsOf = get(['settingType', 'options']);
const defaultOf = get(['settingType', 'default']);
const minimumOf = get(['settingType', 'minimum']);
const maximumOf = get(['settingType', 'maximum']);
const fillValueOf = get(['settingType', 'fill_value']);
const labelsOf = get(['settingType', 'labels']);
const dimensionLabelsOf = get(['settingType', 'dimension_labels']);
const unitType = get(['settingType', 'unit', 'type']);
const unitLabel = get(['settingType', 'unit', 'label']);

const numericDefaultOf = defaultOf;

const tensorDefaultOf = defaultOf;

const constraints = (specification) =>
  concat(
    toPairs(specification.constraints),
    flatMap(pipe(get('constraints'), toPairs), optionsOf(specification)),
  );

const settingConstraintFamilies = pipe(
  toPairs,
  first,
  ([type, value]) => {
    switch (type) {
      case 'setting-has-value':
        // NOTE: the `key` field is the referenced family.
        return [get('key', value)];
      case 'all':
      case 'any':
        return flatMap(settingConstraintFamilies, value);
      default:
        return [];
    }
  },
  uniq,
);

const isSettingConstraint = pipe(first, isEqual('setting-constraint'));

// (Family -> Family -> Edge(Family, Family)) -> [Specification] -> [Edge(Family -> Family)]
const withDependencies = curry((edge, specifications) =>
  pipe(
    flatMap((specification) =>
      pipe(
        constraints,
        filter(isSettingConstraint),
        flatMap(pipe(last, settingConstraintFamilies)),
        uniq,
        map((requiredFamily) => edge(requiredFamily, specification)),
        compact,
      )(specification),
    ),
    orderBy(
      [
        pipe(
          get(1),
          (family) => find({ family }, specifications),
          get('order'),
        ), // we want to sort by order first
        get(1),
        get(0),
      ],
      ['asc', 'asc', 'asc'],
    ),
  )(specifications),
);

const dependencyFamilies = withDependencies((requiredFamily, specification) => [
  requiredFamily,
  specification.family,
]);

const dependencyGroups = (specifications) =>
  withDependencies((requiredFamily, specification) => {
    const requirementGroup = get(
      'group',
      find({ family: requiredFamily }, specifications),
    );
    return requirementGroup !== specification.group
      ? [requirementGroup, specification.group]
      : undefined;
  }, specifications);

const sortedFamiliesByDependency = pipe(dependencyFamilies, toposort);
const sortedGroupsByDependency = pipe(dependencyGroups, toposort);

const orderSettings = (specifications) => {
  const sortedKeys = sortedFamiliesByDependency(specifications);
  const sortedGroups = sortedGroupsByDependency(specifications);
  return orderBy(
    [
      (specification) => {
        const ix = findIndex(isEqual(specification.group), sortedGroups);
        return ix < 0 ? specifications.length : ix;
      },
      (specification) => get('group', specification),
      (specification) => {
        const ix = findIndex(isEqual(specification.family), sortedKeys);
        return ix < 0 ? specifications.length : ix;
      },
      (specification) => get('order', specification) || 0,
      (specification) => get('family', specification),
    ],
    ['asc', 'asc'],
    specifications,
  );
};

const attributeKey = pipe(split(':'), get(2), keyFor);

const keyOwnerMap = {
  'live-curve': 'pwc',
  'play-curve': 'pwc',
  'work-curve': 'pwc',
  'benchmark-type': 'pwc',
  'requirement-per-100000': 'pwc',
  'applicable-cohort': 'pwc',
  'uplift-category': 'pwc',
  'uplift-curve': 'pwc',
  uplift: 'pwc',
};

const keyToSettingFamily = (keyOrFamily) =>
  keyOrFamily.split(':').length === 1
    ? `setting:${get(keyOrFamily, keyOwnerMap) || 'kinesis'}:${keyOrFamily}`
    : keyOrFamily;

const findByFamily = (matchFamily, fields) =>
  find((field) => keyToSettingFamily(field.key) === matchFamily, fields);

const valueFromField = (selectedEntry, family) =>
  get(['value', 'value'], findByFamily(family, selectedEntry.fields));

export {
  encodingOf,
  typeOf,
  optionsOf,
  minimumOf,
  maximumOf,
  supportedTypes,
  orderSettings,
  attributeKey,
  numericDefaultOf,
  tensorDefaultOf,
  fillValueOf,
  labelsOf,
  dimensionLabelsOf,
  isScalar,
  isTensor,
  constraints,
  dependencyFamilies,
  dependencyGroups,
  settingConstraintFamilies,
  sortedFamiliesByDependency,
  unitType,
  unitLabel,
  findByFamily,
  valueFromField,
  keyToSettingFamily,
};
