import styled from 'styled-components';

const DescriptionPane = styled.div``;

const InputBackground = styled.div`
  background-color: ${({ theme }) => theme.color.gray2};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  padding: 8px 16px;
`;

const SidePane = styled.div`
  flex: 0 0 304px;
  width: 304px;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  overflow: auto;
`;

const Metadata = styled.div`
  color: ${({ theme }) => theme.color.gray7};
`;

const Processing = styled.div`
  color: ${({ theme }) => theme.color.gray8};
`;

const ToolbarBackground = styled.div`
  background-color: ${({ theme }) => theme.color.gray2};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

const P = styled.p`
  :last-of-type {
    margin-bottom: 16px;
  }
`;

export {
  DescriptionPane,
  InputBackground,
  Metadata,
  Processing,
  SidePane,
  ToolbarBackground,
  P,
};
