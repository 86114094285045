import PropTypes from 'prop-types';
import ChartDetails from 'components/charts/chart-details';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import chartDetailsSelector from 'selectors/chartDetailsSelector';
import AutoFocus from 'components/util/AutoFocus';
import {
  useBoardId,
  usePerspectives,
  useScenarioId,
  useSelectorWithProps,
} from 'hooks';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const ChartDetailsPane = ({ blockId, privacy, value, workspaceId }) => {
  const boardId = useBoardId();
  const scenarioId = useScenarioId();
  const perspectives = usePerspectives();
  const { items, title } = useSelectorWithProps(chartDetailsSelector, {
    blockId,
    boardId,
    perspectives,
    public: privacy === 'public',
    scenarioId,
    value,
    workspaceId,
  });

  return (
    <AutoFocus>
      <ToolboxPanel fadeScroll>
        <ToolboxSection collapsible title='Value'>
          <ChartDetails items={items} title={title} />
        </ToolboxSection>
      </ToolboxPanel>
    </AutoFocus>
  );
};

ChartDetailsPane.propTypes = propTypes;
ChartDetailsPane.defaultProps = defaultProps;

export { ChartDetailsPane };
