import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@kinesis/bungle';
import { Header, Block, Collapse } from './section.styles';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]),
  collapsible: PropTypes.bool,
  titleOnly: PropTypes.bool,
  heading: PropTypes.string,
};

const defaultProps = {
  children: null,
  collapsible: true,
  heading: undefined,
  titleOnly: false,
};

const Section = ({ heading, children, collapsible, titleOnly }) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = useCallback(
    () => setCollapsed((current) => !current),
    [setCollapsed],
  );

  return (
    <Block collapsed={collapsed} titleOnly={titleOnly}>
      {heading && (
        <Header
          collapsible={collapsible}
          collapsed={collapsed}
          onClick={collapsible ? toggleCollapsed : undefined}
        >
          {collapsible && (
            <Collapse>
              <Icon type={collapsed ? 'right' : 'down'} />
            </Collapse>
          )}
          <h5>{heading}</h5>
        </Header>
      )}
      {!collapsed && children}
    </Block>
  );
};

Section.defaultProps = defaultProps;
Section.propTypes = propTypes;

export default Section;
