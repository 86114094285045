import { createSelector } from 'reselect';
import { get, isEqual } from 'lodash/fp';
import { requiresScenarios, blockProcessingStates } from 'data/block';
import blockPerspectiveStateSelector from './blockPerspectiveStateSelector';
import blockStateSelector from './blockStateSelector';

import blockValueSelector from './blockValueSelector';

const staticIsProcessing = (scenarioId, blockState, blockValue) => {
  const blockProcessingState =
    get(['scenarios', scenarioId, 'state', 'block'], blockState) ||
    blockProcessingStates.INITIAL;
  const needsData = requiresScenarios(blockValue);
  const processing = [
    blockProcessingStates.PROCESSING_INITIAL,
    blockProcessingStates.PROCESSING_STALE,
  ].includes(blockProcessingState);
  return needsData && processing;
};

const isProcessing = isEqual('processing');

const blockIsProcessingSelector = createSelector(
  blockPerspectiveStateSelector,
  blockStateSelector,
  blockValueSelector,
  (state, props) => props.scenarioId,
  (blockPerspectiveState, blockState, blockValue, scenarioId) =>
    staticIsProcessing(scenarioId, blockState, blockValue) ||
    isProcessing(blockPerspectiveState),
);

export default blockIsProcessingSelector;
