import styled from 'styled-components';

const SidePane = styled.div`
  flex: 0 0 304px;
  width: 304px;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  overflow: auto;
`;

const ErrorCard = styled.div``;

export { SidePane, ErrorCard };
