import { get } from 'lodash/fp';

function authorNameSelector(state, props = {}) {
  const { author } = props;
  const userId = get(['auth', 'userId'], state);
  const authorIdentity = get(['identities', author], state);

  return get('id', authorIdentity) === userId
    ? 'you'
    : get('name', authorIdentity);
}

export default authorNameSelector;
