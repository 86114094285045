import { createSlice } from '@reduxjs/toolkit';
import { always, map, pipe, unset, sortBy, reverse } from 'lodash/fp';
import { parseISO } from 'date-fns/fp';
import { getToken } from 'services/authService';
import { userLogout } from 'actions/userLogout';
import { userToken } from 'actions/userToken';
import { reauthRequired } from 'actions/reauthRequired';
import { userLogin } from 'actions/userLogin';
import { userRelogin } from 'actions/userRelogin';

const blankState = {
  accepted: undefined,
  analytics: undefined,
  emailAddress: undefined,
  hasExchanged: false,
  isBooted: false,
  isExchanging: false,
  isLoggedIn: false,
  isLoggingIn: false,
  firstName: undefined,
  lastName: undefined,
  loginWithSso: undefined,
  memberships: [],
  token: undefined,
  updateRequestId: undefined,
  userId: undefined,
  userPicture: undefined,
  identityServicePermissions: undefined,
  keys: [],
};

const convertResponse = (action) => ({
  accepted: action.payload.accepted,
  analytics: action.payload.me.analytics,
  emailAddress: action.payload.user.email,
  firstName: action.payload.user.first_name,
  isExchanging: false,
  isLoggedIn: true,
  isLoggingIn: false,
  isBooted: true,
  hasExchanged: true,
  jobTitle: action.payload.user.job_title,
  lastName: action.payload.user.last_name,
  loginWithSso: action.payload.loginWithSso,
  memberships: action.payload.me.memberships.map((m) => ({
    ...m,
    actionable: m.status === 'pending' && !m.invitation_expired,
    actionStatus: undefined,
  })),
  newUser: action.payload.newUser,
  token: action.payload.access_token,
  userId: action.payload.user.id,
  userPicture: action.payload.user.picture,
  services: (action.payload.services || [])
    .map(({ code }) => code)
    .filter((code) => ['city', 'integrated'].includes(code)),
  identityServicePermissions: action.payload.identityServicePermissions,
  keys: pipe(
    map((key) => ({
      id: key.id,
      created: key.created,
    })),
    sortBy(({ created }) => parseISO(created)),
    reverse,
  )(action.payload.me.keys || []),
});

const { reducer, actions } = createSlice({
  name: 'auth',

  initialState: {
    ...blankState,
    token: getToken(),
  },

  reducers: {
    resetActionableMemberships: (state) => ({
      ...state,
      memberships: state.memberships
        .filter((m) => m.actionStatus !== 'declined')
        .map((m) => ({
          ...m,
          actionable: m.actionable && m.actionStatus !== 'accepted',
          actionStatus: undefined,
        })),
    }),
    clearAccepted: (state) => unset('accepted', state),
  },

  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isLoggingIn = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) =>
      convertResponse(action),
    );

    builder.addCase(userRelogin.fulfilled, (state, action) =>
      convertResponse(action),
    );

    builder.addCase(userLogout.fulfilled, () =>
      always({ ...blankState, isBooted: true }),
    );

    builder.addCase(userLogout.rejected, () =>
      always({ ...blankState, isBooted: true }),
    );

    builder.addCase(reauthRequired.type, () =>
      always({ ...blankState, isBooted: true }),
    );

    builder.addCase(userToken.fulfilled, (state, action) => {
      const { token } = action.payload;

      if (token) {
        state.token = token;
        state.isBooted = true;
        state.isLoggingIn = false;
        state.isLoggedIn = true;
      }
    });

    builder.addMatcher(
      (action) =>
        action.type === userRelogin.rejected.type ||
        action.type === userLogin.rejected.type,
      (state, action) => {
        const { willCheckForLocalToken } = action.meta?.arg || {};

        state.isBooted = true;
        state.isLoggingIn = Boolean(willCheckForLocalToken);
        state.token = null;
      },
    );
  },
});

export { reducer, actions };
