import PropTypes from 'prop-types';
import { concat, includes, map, noop, keys } from 'lodash/fp';
import { Icon, Tooltip, UtilityButton } from '@kinesis/bungle';
import { blockIconMap } from 'data/block';
import { Container } from './visualisation-type-chooser.styles';

const propTypes = {
  onChange: PropTypes.func,
  mode: PropTypes.oneOf(['static', 'editable']).isRequired,
  value: PropTypes.string.isRequired,
};

const defaultProps = {
  onChange: noop,
};

const supportedVisualisationLabels = {
  indicator: 'Indicator',
  'clustered-column': 'Clustered column chart',
  'stacked-column': 'Stacked column chart',
  line: 'Line chart',
  'stacked-area': 'Area chart',
  scatter: 'Scatterplot',
  choropleth: 'Choropleth',
  'spatial-bubble': 'Map',
  table: 'Table',
};

const supportedVisualisationTypes = keys(supportedVisualisationLabels);

const supportedXYCharts = [
  'stacked-column',
  'line',
  'clustered-column',
  'stacked-area',
];

const editableVisualisationTypes = ['indicator', 'choropleth'];

const label = (type) => supportedVisualisationLabels[type];

const VisualisationTypeChooser = ({ onChange, value, mode }) => {
  const allowedVisualisationTypes =
    mode === 'editable'
      ? concat(editableVisualisationTypes, supportedXYCharts)
      : [];

  return (
    <Container>
      {map((type) => {
        const enabled =
          includes(type, allowedVisualisationTypes) || value === type;
        const icon = blockIconMap[type];
        return (
          <Tooltip key={`${type}-button`} title={label(type)} placement='top'>
            <UtilityButton
              disabled={!enabled}
              icon={
                <Icon
                  magnitude='medium'
                  type={icon}
                  opacity={enabled ? 1 : 0.45}
                />
              }
              onClick={value === type ? noop : () => onChange(type)}
              variant={value === type ? 'accent' : 'default'}
            />
          </Tooltip>
        );
      }, supportedVisualisationTypes)}
    </Container>
  );
};

VisualisationTypeChooser.propTypes = propTypes;
VisualisationTypeChooser.defaultProps = defaultProps;

export { VisualisationTypeChooser };
