import styled from 'styled-components';

const Panel = styled.div`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  height: 184px;
  width: 304px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
`;

const Info = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}px;
  line-height: ${({ theme }) => theme.lineHeight.md};
  text-align: center;
`;

const LightText = styled.span`
  color: ${({ theme }) => theme.color.gray7};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

export { Panel, Info, LightText };
