import styled from 'styled-components';

const BaselineIconWrapper = styled.span`
  padding-left: 8px;
  color: ${({ theme }) => theme.color.gray8};
`;

const SidePane = styled.div`
  flex: 0 0 304px;
  width: 304px;
  overflow-y: auto;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
`;

const Description = styled.p`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.color.gray8};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
`;

export { BaselineIconWrapper, SidePane, Description };
