import { first, uniq, sortBy, identity, pipe, map, get } from 'lodash/fp';
import {
  interventionCategoryLabels,
  interventionAreaCategories,
} from 'data/pathways';

const title = (blockValue) => {
  const categories = pipe(
    get('inputs'),
    map((input) => interventionAreaCategories[get('type', input)]),
    sortBy(identity),
    uniq,
  )(blockValue);
  if (categories.length === 1) {
    const category = first(categories);
    const categoryLabel = interventionCategoryLabels[category];
    return `${categoryLabel} inputs`;
  }
  return 'Pathway inputs';
};

export { title };
