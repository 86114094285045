import axios from 'axios';
import enabledFeatureFlagsSelector from 'selectors/enabledFeatureFlagsSelector';
import { reauthRequired } from 'actions/reauthRequired';
import { responseError } from 'actions/responseError';
import { server } from './server';

const deployment = import.meta.env.MODE;

const deploymentHeaders =
  deployment !== 'production' ? { 'Kinesis-Deployment': deployment } : {};

function createApiFromState({ dispatch, getState }) {
  const state = getState();

  const { token } = state.auth;
  const enabledFlags = enabledFeatureFlagsSelector(state);

  const api = axios.create({
    baseURL: server,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': 'application/json',
      'Kinesis-Feature-Flags': enabledFlags.join(','),
      ...deploymentHeaders,
    },
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;

      if (!response) {
        return Promise.reject(error);
      }

      if (response.status === 403) {
        dispatch(reauthRequired());
      } else {
        dispatch(responseError(error.response));
      }

      return Promise.reject(error.response);
    },
  );

  return api;
}

export default createApiFromState;
