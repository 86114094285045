import styled from 'styled-components';
import { Icon } from '@kinesis/bungle';

const MenuIcon = styled(Icon)`
  margin-right: 8px;
  color: ${({ theme }) => theme.color.gray7};
`;

const MenuItem = styled.div`
  position: relative;
  margin-bottom: 4px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

export { MenuIcon, MenuItem };
