import PropTypes from 'prop-types';
import { pipe, get, isNil } from 'lodash/fp';
import blockValueSelector from 'selectors/blockValueSelector';
import locationSelector from 'selectors/locationSelector';
import {
  useBoardId,
  usePerspectives,
  useScenarioId,
  useSelectorWithProps,
} from 'hooks';
import spatialDetailsSelector from 'selectors/spatialDetailsSelector';
import PopoverContent from 'components/popover/PopoverContent';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { ToolboxSection } from 'components/toolbox/toolbox-section';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const SpatialVisualisationDetailsPane = ({
  blockId,
  locationId,
  privacy,
  workspaceId,
}) => {
  const boardId = useBoardId();
  const scenarioId = useScenarioId();
  const perspectives = usePerspectives();
  const nonLocation = useSelectorWithProps(
    pipe(blockValueSelector, get(['spatial', 'location']), isNil),
    {
      blockId,
      public: privacy === 'public',
      workspaceId,
    },
  );
  const title = useSelectorWithProps(pipe(locationSelector, get('label')), {
    locationId,
    public: privacy === 'public',
    workspaceId,
  });
  const data = useSelectorWithProps(spatialDetailsSelector, {
    blockId,
    boardId,
    locationId,
    perspectives,
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });

  return (
    <ToolboxPanel fadeScroll>
      <ToolboxSection title='Value'>
        <PopoverContent
          keyColumn='label'
          valueColumn='value'
          colorColumn='color'
          data={data}
          title={nonLocation ? undefined : title || 'New location'}
        />
      </ToolboxSection>
    </ToolboxPanel>
  );
};

SpatialVisualisationDetailsPane.propTypes = propTypes;
SpatialVisualisationDetailsPane.defaultProps = defaultProps;

export { SpatialVisualisationDetailsPane };
