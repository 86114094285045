import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

// STRUCTURAL CONSOLE LOG, vite is not producing export in some cases
// not sure how to address yet, https://github.com/vitejs/vite/issues/8800
// shows a similar issue but on an older version, so not sure if it
// is the same thing?
console.log(MediaQuery, useMediaQuery);

function useResponsive() {
  const { breakpoint } = useTheme();

  const isTabletPortraitAndUp = useMediaQuery({
    minWidth: breakpoint.sm,
  });
  const isTabletLandscapeAndUp = useMediaQuery({
    minWidth: breakpoint.md,
  });
  const isDesktopAndUp = useMediaQuery({
    minWidth: breakpoint.lg,
  });

  const isFullSizeBoard = useMediaQuery({
    minWidth: 900,
  });

  return {
    isTabletPortraitAndUp,
    isTabletLandscapeAndUp,
    isDesktopAndUp,
    isFullSizeBoard,
  };
}

export default useResponsive;
