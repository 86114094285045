import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import Modal from 'components/modals/modal/Modal';
import {
  Input,
  SegmentedControl,
  SegmentedControlItem,
  Stack,
  ModalHeader,
  Text,
  Select,
  LabelText,
  useId,
} from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { useSelector, useDispatch } from 'react-redux';
import usePathwayInputs from 'hooks/usePathwayInputs';
import { pathwayPublish } from 'actions/pathwayPublish';
import {
  defaultTo,
  values,
  first,
  isEmpty,
  find,
  pipe,
  get,
  flatMap,
  map,
  keyBy,
  isNil,
  curry,
} from 'lodash/fp';
import workspaceLayersSelector from 'selectors/workspaceLayersSelector';
import sortedWorkspacesSelector from 'selectors/sortedWorkspacesSelector';
import scenariosSelector from 'selectors/scenariosSelector';
import {
  locationTypeLabel,
  locationLabel,
  updatedLocationLabel,
} from 'data/pathways';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import Units from 'data/units';
import {
  ScenarioContainer,
  ScenarioPublishTypeItem,
  ScenarioInputItem,
} from './pathways-publish-modal.styles';

const propTypes = {
  animation: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  defaultPublishType: PropTypes.oneOf([
    'new-workspace-new-scenario',
    'existing-workspace-new-scenario',
    'existing-workspace-existing-scenario',
  ]),
};

const defaultProps = {
  animation: true,
  defaultPublishType: 'new-workspace-new-scenario',
};

const stringCompare = new Units().parseType({ type: 'string' }).compare;

const sortBy = curry((field, xs) =>
  xs.slice().sort((x, y) => stringCompare(x[field], y[field])),
);

const locationsLabelWithPrefix = curry((prefix, inputs) => {
  const useUpdatedLocations = true;

  // NOTE: The location selection must be non-empty in order to open this
  // modal in the first place, hence we do not consider it as a case here
  // given this assumption, but it does mean this function is not total.
  if (inputs.locations.length === 1) {
    const location = first(inputs.locations);
    const name = useUpdatedLocations
      ? updatedLocationLabel(inputs.locationType, location)
      : locationLabel(inputs.locationType, location);
    return `${prefix} ${name}`;
  }
  if (inputs.locations.length > 1) {
    const count = get(['locations', 'length'], inputs);
    const type = locationTypeLabel(inputs.locationType);
    return `${prefix} ${count} ${type}s`;
  }
});

const workspaceLabelDefault = locationsLabelWithPrefix(
  'Pathways to net-zero for',
);

const scenarioLabelDefault = locationsLabelWithPrefix('Pathway for');

const workspaceLabel = (type, id, workspaceName, workspaces, inputs) => {
  switch (type) {
    case 'existing':
      return pipe(
        find((w) => w.value === id),
        get('label'),
      )(workspaces);
    case 'new': {
      if (isEmpty(workspaceName)) {
        return workspaceLabelDefault(inputs);
      }
      return workspaceName;
    }
    default:
      return undefined;
  }
};

const scenarioLabel = (type, id, scenarioName, scenarios, inputs) => {
  switch (type) {
    case 'existing':
      return pipe(
        find((s) => s.value === id),
        get('label'),
      )(scenarios);
    case 'new': {
      if (isEmpty(scenarioName)) {
        return scenarioLabelDefault(inputs);
      }
      return scenarioName;
    }
    default:
      return undefined;
  }
};

const isDefaultScenario = (scenarioId, scenarios) =>
  pipe(
    find((s) => s.value === scenarioId),
    get('isDefault'),
    defaultTo(false),
  )(scenarios);

const constructWorkspacePayload = ({ type, id, layer, label }) => {
  switch (type) {
    case 'new':
      return { type, label };
    case 'existing':
      return { type, id, layer };
    default:
      return undefined;
  }
};

const constructScenarioPayload = ({ type, label, id, isDefault }) => {
  switch (type) {
    case 'new':
      return { type, label };
    case 'existing':
      return { type, label, id, default: isDefault };
    default:
      return undefined;
  }
};

const initialPublishTypes = (defaultPublishType) => {
  switch (defaultPublishType) {
    case 'new-workspace-new-scenario':
      return { workspace: 'new', scenario: 'new' };
    case 'existing-workspace-new-scenario':
      return { workspace: 'existing', scenario: 'new' };
    case 'existing-workspace-existing-scenario':
      return { workspace: 'existing', scenario: 'existing' };
    default:
      return { workspace: 'new', scenario: 'new' };
  }
};

const PathwaysPublishModal = ({
  animation,
  onDismiss,
  onPublish,
  defaultPublishType,
}) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const id = useId();
  const [inputs] = usePathwayInputs();
  const [workspacePublishType, setWorkspacePublishType] = useState(
    initialPublishTypes(defaultPublishType).workspace,
  );
  const [workspaceId, setWorkspaceId] = useState();
  const [workspaceName, setWorkspaceName] = useState(
    workspaceLabelDefault(inputs),
  );
  const [isMissingWorkspace, setIsMissingWorkspace] = useState(false);
  const [scenarioPublishType, setScenarioPublishType] = useState(
    initialPublishTypes(defaultPublishType).scenario,
  );
  const [scenarioName, setScenarioName] = useState(
    scenarioLabelDefault(inputs),
  );
  const [scenarioId, setScenarioId] = useState();

  const publishState = useSelector(get(['pathways', 'publishState']));
  const workspaceLocationLayers = useSelector(
    pipe(
      workspaceLayersSelector,
      map((l) => ({
        workspace: l.workspace,
        layer: pipe(
          get('entities'),
          values,
          // NOTE: Checking a label here for identity isn't great, but it is
          // how we can presently determine the location layer uniquely.
          find((e) => e.label === 'Locations'),
          get('id'),
        )(l.layers),
      })),
      keyBy('workspace'),
    ),
  );
  const workspaces = useSelector(
    pipe(
      sortedWorkspacesSelector,
      flatMap((w) => (w.editor ? [{ value: w.id, label: w.name }] : [])),
      sortBy('label'),
    ),
  );
  const scenarios = useSelectorWithProps(
    pipe(
      scenariosSelector,
      map((s) => ({ value: s.id, label: s.name, isDefault: s.isDefault })),
      sortBy('label'),
    ),
    {
      workspaceId,
    },
  );

  const handlePublish = useCallback(() => {
    // NOTE: this is a workaround for loading states on buttons still being
    // interactive. cf. https://github.com/kinesisptyltd/bungle/issues/3611
    if (publishState === 'publishing') {
      return;
    }

    if (workspacePublishType === 'existing' && isNil(workspaceId)) {
      setIsMissingWorkspace(true);
      return;
    }

    const adjustedScenarioPublishType =
      workspacePublishType === 'new' ? 'new' : scenarioPublishType;
    const targetScenarioLabel = scenarioLabel(
      adjustedScenarioPublishType,
      scenarioId,
      scenarioName,
      scenarios,
      inputs,
    );
    const targetWorkspaceLabel = workspaceLabel(
      workspacePublishType,
      workspaceId,
      workspaceName,
      workspaces,
      inputs,
    );

    dispatch(
      pathwayPublish({
        ...inputs,
        workspace: constructWorkspacePayload({
          type: workspacePublishType,
          id: workspaceId,
          layer: get([workspaceId, 'layer'], workspaceLocationLayers),
          label: targetWorkspaceLabel,
        }),
        scenario: constructScenarioPayload({
          type: adjustedScenarioPublishType,
          label: targetScenarioLabel,
          id: scenarioId,
          isDefault: isDefaultScenario(scenarioId, scenarios),
        }),
        workspaceLabel: targetWorkspaceLabel,
        scenarioLabel: targetScenarioLabel,
        onPublish,
        pathwayUrlParams: search,
      }),
    );
  }, [
    dispatch,
    inputs,
    workspaceId,
    workspaceName,
    workspaces,
    workspacePublishType,
    workspaceLocationLayers,
    scenarioName,
    scenarioId,
    scenarioPublishType,
    scenarios,
    onPublish,
    publishState,
    search,
  ]);

  const handleWorkspacePublishTypeChange = useCallback(
    (value) => {
      setWorkspacePublishType(value);
    },
    [setWorkspacePublishType],
  );

  const handleWorkspaceIdChange = useCallback(
    (value) => {
      setWorkspaceId(value);
      setIsMissingWorkspace(false);
    },
    [setWorkspaceId, setIsMissingWorkspace],
  );

  const handleWorkspaceNameChange = useCallback(
    (value) => {
      setWorkspaceName(value);
    },
    [setWorkspaceName],
  );

  const handleScenarioNameChange = useCallback(
    (value) => {
      setScenarioName(value);
    },
    [setScenarioName],
  );

  const handleScenarioPublishTypeChange = useCallback(
    (value) => {
      setScenarioPublishType(value);
    },
    [setScenarioPublishType],
  );

  const handleScenarioSelect = useCallback(
    (value) => {
      setScenarioId(value);
    },
    [setScenarioId],
  );

  // NOTE: This is a workaround for selecting all text when Input is focused.
  // https://github.com/kinesisptyltd/bungle/issues/3857
  const handleInputSelectOnFocus = useCallback((event) => {
    event.target.select();
  }, []);

  const header = <ModalHeader>Publish to workspace</ModalHeader>;

  return (
    <Modal
      header={header}
      saveLabel='Publish outputs'
      dismissLabel='Cancel'
      onClose={onDismiss}
      onSave={handlePublish}
      magnitude='compact'
      minHeight={414}
      animation={animation}
      loading={publishState === 'publishing'}
    >
      <Content paddingY='medium'>
        <Stack space='medium'>
          <Text>
            Publish your pathway to a workspace to explore the outputs using our
            full data visualisation and analytical tools. You can also publish
            multiple pathways to a single workspace to compare outcomes.
          </Text>
          {/* workspace publish type */}
          <Stack space='xsmall'>
            <LabelText as='label'>Publish to</LabelText>
            <SegmentedControl
              id={`${id}-control`}
              onChange={handleWorkspacePublishTypeChange}
              value={workspacePublishType}
              widthMode='fill-container'
            >
              <SegmentedControlItem title='New workspace' value='new'>
                New workspace
              </SegmentedControlItem>
              <SegmentedControlItem title='Existing workspace' value='existing'>
                Existing workspace
              </SegmentedControlItem>
            </SegmentedControl>
          </Stack>
          {/* workspace selection */}
          {workspacePublishType === 'new' && (
            <Stack space='xsmall'>
              <LabelText as='label'>Workspace</LabelText>
              <Input
                autoFocus
                magnitude='large'
                placeholder='Enter scenario name'
                onChange={handleWorkspaceNameChange}
                onFocus={handleInputSelectOnFocus}
                value={workspaceName}
              />
            </Stack>
          )}
          {workspacePublishType === 'existing' && (
            <Stack space='xsmall'>
              <LabelText as='label'>Workspace</LabelText>
              <Select
                autoFocus
                placeholder='Select workspace'
                options={workspaces}
                search
                searchText='Filter workspaces...'
                onChange={handleWorkspaceIdChange}
                value={workspaceId}
                tone={isMissingWorkspace ? 'critical' : 'neutral'}
              />
            </Stack>
          )}
          {/* scenario selection */}
          {workspacePublishType === 'new' && (
            <Stack space='xsmall'>
              <LabelText as='label'>Scenario</LabelText>
              <Input
                magnitude='large'
                placeholder='Enter scenario name'
                onChange={handleScenarioNameChange}
                value={scenarioName}
              />
            </Stack>
          )}
          {workspacePublishType === 'existing' && (
            <Stack space='xsmall'>
              <LabelText as='label'>Scenario</LabelText>
              <ScenarioContainer>
                <ScenarioPublishTypeItem>
                  <Select
                    magnitude='large'
                    onChange={handleScenarioPublishTypeChange}
                    value={scenarioPublishType}
                    options={[
                      { value: 'new', label: 'New scenario' },
                      { value: 'existing', label: 'Replace scenario' },
                    ]}
                  />
                </ScenarioPublishTypeItem>
                {scenarioPublishType === 'new' && (
                  <ScenarioInputItem>
                    <Input
                      magnitude='large'
                      placeholder='Enter scenario name'
                      onChange={handleScenarioNameChange}
                      value={scenarioName}
                    />
                  </ScenarioInputItem>
                )}
                {scenarioPublishType === 'existing' && (
                  <ScenarioInputItem>
                    <Select
                      search
                      searchText='Filter scenarios...'
                      placeholder='Select scenario'
                      onChange={handleScenarioSelect}
                      options={scenarios}
                      value={scenarioId}
                      disabled={isNil(workspaceId)}
                    />
                  </ScenarioInputItem>
                )}
              </ScenarioContainer>
            </Stack>
          )}
        </Stack>
      </Content>
    </Modal>
  );
};

PathwaysPublishModal.propTypes = propTypes;
PathwaysPublishModal.defaultProps = defaultProps;

export { PathwaysPublishModal };
