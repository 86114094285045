import { createSlice } from '@reduxjs/toolkit';
import { pathwayCalculate } from 'actions/pathwayCalculate';
import { pathwayDownload } from 'actions/pathwayDownload';
import { pathwayPublish } from 'actions/pathwayPublish';
import { pathwayInputsDownload } from 'actions/pathwayInputsDownload';

const { reducer, actions } = createSlice({
  name: 'pathways',

  initialState: {
    state: 'placeholder',
    downloadState: undefined,
    downloadInputsState: undefined,
    publishState: undefined,
  },

  extraReducers: (builder) => {
    builder.addCase(pathwayCalculate.pending, (state) => {
      state.state =
        state.state === 'error' || state.state === 'placeholder'
          ? 'generating-fresh'
          : 'generating';
    });

    builder.addCase(pathwayCalculate.fulfilled, (state, action) => {
      const { budget, emissions, reductions } = action.payload;
      const {
        arg: { locations },
      } = action.meta;

      state.budget = budget;
      state.emissions = emissions;
      state.reductions = reductions;
      state.state = locations.length > 0 ? 'fulfilled' : 'placeholder';
    });

    builder.addCase(pathwayCalculate.rejected, (state) => {
      state.state = 'error';
    });

    builder.addCase(pathwayDownload.pending, (state) => {
      state.downloadState = 'downloading';
    });

    builder.addCase(pathwayDownload.fulfilled, (state) => {
      state.downloadState = undefined;
    });

    builder.addCase(pathwayDownload.rejected, (state) => {
      state.downloadState = 'error';
    });

    builder.addCase(pathwayInputsDownload.pending, (state) => {
      state.downloadInputsState = 'downloading';
    });

    builder.addCase(pathwayInputsDownload.fulfilled, (state) => {
      state.downloadInputsState = undefined;
    });

    builder.addCase(pathwayInputsDownload.rejected, (state) => {
      state.downloadInputsState = 'error';
    });

    builder.addCase(pathwayPublish.pending, (state) => {
      state.publishState = 'publishing';
    });

    builder.addCase(pathwayPublish.fulfilled, (state) => {
      state.publishState = undefined;
    });

    builder.addCase(pathwayPublish.rejected, (state) => {
      state.publishState = 'error';
    });
  },
});

export { reducer, actions };
