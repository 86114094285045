import { has, map, min, max } from 'lodash/fp';
import { dataFetch } from 'actions/dataFetch';
import { visualisationQuery } from 'actions/visualisationQuery';
import { actions as aggregationFiltersActions } from 'reducers/aggregationFiltersReducer';
import blockValueSelector from 'selectors/blockValueSelector';

const { initialiseBlockTimeBounds } = aggregationFiltersActions;

function createBlockTimeBoundsMiddleware() {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      next(action);

      switch (action.type) {
        case dataFetch.fulfilled.type: {
          const { data, type } = action.payload;
          const { blockId } = action.meta.arg;

          if (
            type === 'complete' &&
            [
              'indicator-time-series',
              'choropleth-time-series',
              'stacked-column-time-series',
              'clustered-column-time-series',
              'line-time-series',
              'stacked-area-time-series',
            ].includes(data.type)
          ) {
            dispatch(
              initialiseBlockTimeBounds({
                blockId,
                timeBounds: [
                  data.metadata?.time?.min,
                  data.metadata?.time?.max,
                ],
              }),
            );
          }

          break;
        }

        case visualisationQuery.fulfilled.type: {
          const state = getState();
          const { scenariosWithData } = action.payload;
          const { blockId, currentScenarioId, workspaceId } = action.meta.arg;
          const blockValue =
            blockValueSelector(state, {
              blockId,
              workspaceId,
            }) ?? {};
          const { visualisation } = blockValue;

          if (has('time', blockValue[visualisation])) {
            const years = map(
              'year',
              scenariosWithData[currentScenarioId]?.data,
            );

            dispatch(
              initialiseBlockTimeBounds({
                blockId,
                timeBounds: [min(years), max(years)],
              }),
            );
          }
          break;
        }
        default:
      }
    };
}

export default createBlockTimeBoundsMiddleware;
