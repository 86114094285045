import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

const platformLocationGeographySelector = createSelector(
  (state, props) =>
    get(
      ['geography', 'dataIds', `platform-location-${props.platformKey}`],
      state,
    ),
  get(['geography', 'bundles']),
  (bundleId, bundles) => get(bundleId, bundles),
);

export default platformLocationGeographySelector;
