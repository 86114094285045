import { createSelector } from 'reselect';
import {
  filter,
  get,
  includes,
  map,
  pipe,
  placeholder,
  reject,
  sortBy,
  take,
  values,
} from 'lodash/fp';
import { pointOf } from 'data/geography';
import currentAttributesSelector from 'selectors/currentAttributesSelector';
import layersSelector from 'selectors/layersSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import workspaceGeographiesSelector from 'selectors/workspaceGeographiesSelector';
import haversine from 'haversine';

const nearbyLocationsSelector = createSelector(
  pipe(workspaceSelector, get('locations')),
  currentAttributesSelector,
  workspaceGeographiesSelector,
  pipe(layersSelector, filter({ type: 'synthetic' }), map('id')),
  (state, { locationId }) => locationId,
  (locations, attributes, geographies, syntheticLayers, locationId) => {
    const target = get(['values', locationId], attributes);

    if (!target) {
      return [];
    }

    return pipe(
      get('values'),
      values,
      reject({ id: target.id }),
      reject({ id: 0 }),
      reject((l) =>
        pipe(
          get([l.id, 'layer']),
          includes(placeholder, syntheticLayers),
        )(locations),
      ),
      map((location) => ({
        ...location,
        label: get([location.id, 'label'], locations),
        layer: get([location.id, 'layer'], locations),
        distance: haversine(
          pointOf(target.id, attributes.values, geographies),
          pointOf(location.id, attributes.values, geographies),
          {
            unit: 'meter',
          },
        ),
      })),
      filter((location) => get('distance', location) <= 100),
      sortBy('distance'),
      take(10),
    )(attributes);
  },
);

export default nearbyLocationsSelector;
