import { defaultTo } from 'lodash/fp';
import styled from 'styled-components';

const HorizontalPane = styled('div')(
  ({ theme }) => ({
    borderLeft: `1px solid ${theme.borderColor}`,
    boxSizing: 'content-box',
    minHeight: 0,
    minWidth: 0,
    '&:first-child': {
      borderLeftWidth: 0,
    },
  }),
  ({ basis, flex }) =>
    flex
      ? {
          flexBasis: defaultTo(0, basis),
          flexGrow: flex,
          flexShrink: flex,
        }
      : {
          flexBasis: defaultTo('auto', basis),
          flexGrow: 0,
          flexShrink: 0,
        },
);

export { HorizontalPane };
