import { userLogout } from 'actions/userLogout';
import appReducer from './appReducer';

function createRootReducer() {
  const reducer = appReducer();

  return (state, action) => {
    if (
      action.type === userLogout.fulfilled.type ||
      action.type === userLogout.rejected.type
    ) {
      return reducer(undefined, action);
    }
    return reducer(state, action);
  };
}

export default createRootReducer;
