import { createSelector } from 'reselect';
import { settingByKey } from 'data/attributes';
import attributesSelector from './attributesSelector';

const attributeSettingSelector = createSelector(
  attributesSelector,
  (state, props) => props.key,
  (attributes, key) => settingByKey(key, attributes),
);

export default attributeSettingSelector;
