import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceDelete = createAsyncThunk(
  'workspace/delete',
  async ({ workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.delete(`/api/workspace/${workspaceId}`);

    return response.data;
  },
);

export { workspaceDelete };
