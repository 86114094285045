import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { get } from 'lodash/fp';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { AcceptShare } from 'components/accept-share';
import { App } from 'components/app';
import { Apps } from 'components/apps';
import { Dataset } from 'components/dataset';
import { Datasets } from 'components/datasets';
import { Error } from 'components/error';
import { GlobalLayout } from 'components/global-layout';
import PrimaryNavigation from 'components/primary-navigation/PrimaryNavigation';
import { AppContainer } from 'components/app-container';
import Workspaces from 'pages/Workspaces';
import Workspace from 'pages/Workspace';
import { Pathways } from 'components/pathways';
import { appsList } from 'actions/appsList';
import { licenceList } from 'actions/licenceList';
import { userLogout } from 'actions/userLogout';
import { workspacesFetch } from 'actions/workspacesFetch';

const Authenticated = () => {
  const dispatch = useDispatch();
  const datasetsFlag = useFeatureFlag('app.datasets');
  const appsFlag = useFeatureFlag('app.app-management');
  const { firstName, lastName, organisationName, userPicture } = useSelector(
    get('auth'),
  );
  const licences = useSelector(get('licences'));
  const hasFetchedWorkspaces = useSelector(
    get(['misc', 'hasFetchedWorkspaces']),
  );
  const handleLogout = useCallback(() => {
    dispatch(userLogout());
  }, [dispatch]);

  useEffect(() => {
    dispatch(appsList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(licenceList());
  }, [dispatch]);

  useEffect(() => {
    if (!hasFetchedWorkspaces) {
      dispatch(workspacesFetch());
    }
  }, [dispatch, hasFetchedWorkspaces]);

  const items = [
    {
      key: 'workspaces',
      icon: 'map-filled',
      tooltip: 'Workspaces',
      path: '/workspaces',
      enabled: true,
    },
    {
      key: 'pathways',
      icon: 'waterfall-filled',
      tooltip: 'Pathways',
      path: '/pathways',
      enabled: licences.pathways,
    },
    {
      key: 'datasets',
      icon: 'datasets',
      tooltip: 'Datasets',
      path: '/datasets',
      enabled: datasetsFlag,
    },
    {
      key: 'apps',
      icon: 'apps',
      tooltip: 'Apps',
      path: '/apps',
      enabled: appsFlag,
    },
  ];
  const accessibleItems = items.filter((item) => item.enabled);

  return (
    <GlobalLayout direction='column'>
      <PrimaryNavigation
        firstName={firstName}
        items={accessibleItems}
        lastName={lastName}
        onLogout={handleLogout}
        organisationName={organisationName}
        userPicture={userPicture}
      />
      <Routes>
        <Route
          path='/error/server'
          element={
            <AppContainer header={false} variant='light'>
              <Error variant='server' />
            </AppContainer>
          }
        />
        <Route
          path='/error/not-found'
          element={
            <AppContainer header={false} variant='light'>
              <Error variant='not-found' />
            </AppContainer>
          }
        />
        <Route
          path='/error/unknown'
          element={
            <AppContainer header={false} variant='light'>
              <Error variant='unknown' />
            </AppContainer>
          }
        />
        <Route
          path='*'
          element={
            <AppContainer>
              <Routes>
                <Route path='/workspaces' element={<Workspaces />} />
                <Route
                  path='/workspaces/shares/:token'
                  element={<AcceptShare />}
                />
                <Route path='/workspaces/:id/*' element={<Workspace />} />
                <Route path='/pathways/*' element={<Pathways />} />
                <Route path='/datasets' element={<Datasets />} />
                <Route path='/datasets/:id/*' element={<Dataset />} />
                <Route path='/apps' component={<Apps />} />
                <Route path='/apps/:id/*' component={<App />} />
                <Route path='/new-organisation' element={<Navigate to='/' />} />
                <Route path='*' element={<Navigate to='/error/unknown' />} />
              </Routes>
            </AppContainer>
          }
        />
      </Routes>
    </GlobalLayout>
  );
};

export { Authenticated };
