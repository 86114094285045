import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const boardCreate = createAsyncThunk(
  'board/create',
  async ({ workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/workspace/${workspaceId}/board`, {
      name: 'New board',
      workspaceId,
    });

    return response.data;
  },
);

export { boardCreate };
