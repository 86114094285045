import PropTypes from 'prop-types';
import { get, defaultTo, pipe } from 'lodash/fp';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import blockValueSelector from 'selectors/blockValueSelector';
import { Indicator } from 'components/indicator';
import { Choropleth } from 'components/choropleth';
import { StackedColumn } from 'components/stacked-column';
import { Line } from 'components/line';
import { StackedArea } from 'components/stacked-area';
import { ClusteredColumn } from 'components/clustered-column';
import { BlockEmpty } from 'components/block-empty';
import { BlockError } from 'components/block-error';
import Layout from 'components/layout';

const visualisations = {
  empty: BlockEmpty,
  indicator: Indicator,
  choropleth: Choropleth,
  'stacked-column': StackedColumn,
  line: Line,
  'stacked-area': StackedArea,
  'clustered-column': ClusteredColumn,
};

const propTypes = {
  blockId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  viewMode: PropTypes.oneOf(['maximised', 'minimised', 'standalone'])
    .isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  isSelected: false,
};

const Visualisation = ({ blockId, isSelected, viewMode, workspaceId }) => {
  const scenarioId = useScenarioId();
  const visualisation = useSelectorWithProps(
    pipe(blockValueSelector, get('visualisation')),
    { blockId, workspaceId },
  );

  const Implementation = defaultTo(
    BlockError,
    get(visualisation, visualisations),
  );

  return (
    <Layout direction='column'>
      <Implementation
        blockId={blockId}
        isSelected={isSelected}
        scenarioId={scenarioId}
        viewMode={viewMode}
        workspaceId={workspaceId}
      />
    </Layout>
  );
};

Visualisation.propTypes = propTypes;
Visualisation.defaultProps = defaultProps;

export { Visualisation };
