import styled from 'styled-components';

const propTypes = {};
const defaultProps = {};

const WhiteBackground = styled.div`
  background-color: white;
`;

WhiteBackground.propTypes = propTypes;
WhiteBackground.defaultProps = defaultProps;

export { WhiteBackground };
