import PropTypes from 'prop-types';
import { defaultTo, filter, get, pipe, values } from 'lodash/fp';
import { Menu, Tooltip } from '@kinesis/bungle';
import LayerCircle from 'components/layer-circle/LayerCircle';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import { useLayersActions, useSelectorWithProps } from 'hooks';
import workspaceSelector from 'selectors/workspaceSelector';

const propTypes = {
  disabled: PropTypes.bool,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  disabled: false,
  privacy: 'private',
};

const ScenarioLayerMenu = ({ disabled, privacy, workspaceId }) => {
  const { toggleLayer } = useLayersActions({ privacy });
  const layerGroups = useSelectorWithProps(
    pipe(workspaceSelector, get(['layerGroups', 'entities'])),
    {
      public: privacy === 'public',
      workspaceId,
    },
  );
  const layerGroupOrder = useSelectorWithProps(
    pipe(workspaceSelector, get(['layerGroups', 'keys']), defaultTo([])),
    {
      public: privacy === 'public',
      workspaceId,
    },
  );
  const layers = useSelectorWithProps(
    pipe(workspaceSelector, get(['layers', 'entities']), values),
    {
      public: privacy === 'public',
      workspaceId,
    },
  );

  return (
    <ToolboxSection collapsible title='Layers'>
      <Menu>
        {layerGroupOrder.map((layerGroupId) => (
          <Menu.Item
            header
            content={get([layerGroupId, 'label'], layerGroups)}
            key={layerGroupId}
          >
            {filter({ group: layerGroupId }, layers).map((layer) => (
              <Menu.Item
                key={layer.id}
                onClick={
                  !disabled
                    ? () => toggleLayer({ layerId: layer.id, workspaceId })
                    : undefined
                }
                icon={
                  <Tooltip
                    disabled={disabled}
                    placement='top'
                    justify='end'
                    title={layer.enabled ? 'Hide layer' : 'Show layer'}
                  >
                    <span>
                      <LayerCircle
                        enabled={disabled || layer.enabled}
                        layerId={layer.id}
                        privacy={privacy}
                        workspaceId={workspaceId}
                      />
                    </span>
                  </Tooltip>
                }
                content={layer.label}
              />
            ))}
          </Menu.Item>
        ))}
      </Menu>
    </ToolboxSection>
  );
};

ScenarioLayerMenu.propTypes = propTypes;
ScenarioLayerMenu.defaultProps = defaultProps;

export default ScenarioLayerMenu;
