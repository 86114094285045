import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createStore } from '@/store/createStore';

function useStore({ initialState } = {}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [store] = useState(() =>
    createStore({ initialState, location, navigate }),
  );

  useEffect(() => {
    if (window.Cypress) {
      window.store = store;
    }
  }, [store]);

  return store;
}

export { useStore };
