import { Inline, InlineItem, Text, Stack } from '@kinesis/bungle';
import { LegendContainer } from './chart.styles';
import { ColoredLine } from './annual-emissions-legend.styles';

const AnnualEmissionsLegend = () => (
  <LegendContainer>
    <Stack space='xsmall'>
      <Text>Policy context</Text>
      <Inline space='small'>
        <InlineItem>
          <ColoredLine variant='current' />
        </InlineItem>
        <InlineItem>
          <Text>Current settings</Text>
        </InlineItem>
      </Inline>
      <Inline space='small'>
        <InlineItem>
          <ColoredLine variant='intermediate' />
        </InlineItem>
        <InlineItem>
          <Text>Intermediate</Text>
        </InlineItem>
      </Inline>
      <Inline space='small'>
        <InlineItem>
          <ColoredLine variant='aggressive' />
        </InlineItem>
        <InlineItem>
          <Text>Aggressive</Text>
        </InlineItem>
      </Inline>
    </Stack>
  </LegendContainer>
);

export { AnnualEmissionsLegend };
