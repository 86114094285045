import { useLocation } from 'react-router';
import { parse } from 'qs';
import { useMemo } from 'react';

function usePerspectives() {
  const { search } = useLocation();

  // returns object so best to memoize
  return useMemo(() => {
    const params = parse(search, { ignoreQueryPrefix: true });

    return params?.perspectives;
  }, [search]);
}

export { usePerspectives };
