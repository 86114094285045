import styled from 'styled-components';
import { Menu } from '@kinesis/bungle';

const AppMenu = styled(Menu)`
  margin-bottom: 16px;
`;

const AppMenuItem = styled(Menu.Item)`
  height: auto;
`;

export { AppMenu, AppMenuItem };
