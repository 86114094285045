import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Legend } from '@kinesis/bungle';

const ChartLegendWrapper = styled.div`
  width: 304px;
  // TODO: Vertical padding set to 12px to line legend up with chart
  padding: 12px 16px;
`;

const propTypes = {
  icon: PropTypes.oneOf(['circle', 'line']),
  series: PropTypes.array.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  icon: 'circle',
  title: undefined,
};

const ChartLegend = ({ icon, series, title }) => {
  const legendItems = useMemo(
    () =>
      series.map((set) => ({
        color: set.color,
        key: set.name,
        label: set.nameFormatter ? set.nameFormatter(set.name) : set.name,
      })),
    [series],
  );

  return (
    <ChartLegendWrapper>
      <Legend heading={title} icon={icon} items={legendItems} />
    </ChartLegendWrapper>
  );
};

ChartLegend.propTypes = propTypes;
ChartLegend.defaultProps = defaultProps;

export default ChartLegend;
