import { createSelector } from 'reselect';
import workspaceSelector from 'selectors/workspaceSelector';
import { get, pipe } from 'lodash/fp';

const versionedResourceSelector = createSelector(
  (state, props = {}) => props.resource,
  pipe(workspaceSelector, get('resources')),
  get('resources'),
  (resourceFamily, workspaceResources, resources) =>
    get(get(resourceFamily, workspaceResources), resources),
);

export default versionedResourceSelector;
