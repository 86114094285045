import { createSlice } from '@reduxjs/toolkit';
import { pipe, reduce, unset, update, set } from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { workspacesFetch } from 'actions/workspacesFetch';
import { organisationMembersFetch } from 'actions/organisationMembersFetch';
import { userLogin } from 'actions/userLogin';
import { userRelogin } from 'actions/userRelogin';

const handleFetchMembers = (state, action) => {
  const { organisationId } = action.meta.arg;

  return pipe(
    set([organisationId, 'users'], action.payload),
    set([organisationId, 'isFetching'], false),
    set([organisationId, 'hasFetchedUsers'], true),
  )(state);
};

const mergeIdentities = (state, identities) =>
  reduce(
    (accumulator, identity) =>
      identity.identity.startsWith('organisation/')
        ? update(
            identity.id,
            (current) => ({
              ...current,
              id: identity.id,
              name: identity.name,
              code: identity.code,
              avatarUrl: identity.avatar_url,
            }),
            accumulator,
          )
        : state,
    state,
    identities,
  );

const { reducer, actions } = createSlice({
  name: 'organisations',
  initialState: {},

  reducers: {
    clearLastInvitation: unset('lastInvitation'),
  },

  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) =>
      mergeIdentities(state, action.payload.identities),
    );

    builder.addCase(userRelogin.fulfilled, (state, action) =>
      mergeIdentities(state, action.payload.identities),
    );

    builder.addCase(workspacesFetch.fulfilled, (state, action) =>
      mergeIdentities(state, action.payload.identities),
    );

    builder.addCase(appAdd.fulfilled, (state, action) =>
      mergeIdentities(state, action.payload.identities),
    );

    builder.addCase(workspaceFetch.fulfilled, (state, action) =>
      mergeIdentities(state, action.payload.identities),
    );

    builder.addCase(workspacePatch.fulfilled, (state, action) =>
      mergeIdentities(state, action.payload.identities),
    );

    builder.addCase(organisationMembersFetch.pending, (state, action) =>
      set([action.meta.arg.organisationId, 'isFetching'], true, state),
    );

    builder.addCase(organisationMembersFetch.fulfilled, handleFetchMembers);
  },
});

export { reducer, actions };
