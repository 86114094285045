import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import DeckGL from '@deck.gl/react';
import { StaticMap } from 'react-map-gl';
import {
  every,
  noop,
  concat,
  map,
  filter,
  reject,
  get,
  pipe,
  eq,
} from 'lodash/fp';
import { useRect } from '@kinesis/bungle';
import { Loading } from 'components/loading';
import { ImportLocationTooltip } from 'components/import-location-tooltip';
import { scenarioLocationLayers } from 'components/spatial-visualisation/location-layers';
import {
  baseColor,
  diffColor,
  selectedLineColor,
  deselectedLineColor,
} from 'components/spatial-visualisation/layer-colors';
import layerColorSelector from 'selectors/layerColorSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import scenarioGeographySelector from 'selectors/scenarioGeographySelector';
import makePublicTokensSelector from 'selectors/makePublicTokenSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { coordinatesToBounds, fitBounds } from 'utils/spatialUtils';
import LIGHT_STYLE from 'components/map/styles/light.json';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MultiSelectPrompt } from 'components/locations-importer/multi-select-prompt';
import { SelectAllButton } from 'components/select-all-button';

const Full = styled('div')`
  width: 100%;
  height: 100%;
`;

const mapboxAccessTokenSelector = makePublicTokensSelector('mapboxAccessToken');

const propTypes = {
  workspaceId: PropTypes.number,
  scenarioId: PropTypes.number,
  layerId: PropTypes.number,
  setValues: PropTypes.func,
  values: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
};

const defaultProps = {
  workspaceId: undefined,
  scenarioId: undefined,
  layerId: undefined,
  setValues: noop,
  values: [],
  options: [],
};

const buildHover = (object) => ({
  object,
  id: get(['properties', 'id'], object) || get(['id'], object),
  label: get(['properties', 'label'], object) || get(['label'], object),
});

const LocationsMap = ({
  workspaceId,
  scenarioId,
  layerId,
  setValues,
  values,
  options,
}) => {
  const theme = useTheme();
  const ref = useRef();
  const rect = useRect(ref);
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);
  const [hover, setHover] = useState(undefined);
  const [initialState, setInitialState] = useState();
  const [hoverPosition, setHoverPosition] = useState(undefined);
  const locationLabels = useSelectorWithProps(
    pipe(workspaceSelector, get('locations')),
    { workspaceId },
  );
  const geographies = useSelectorWithProps(scenarioGeographySelector, {
    workspaceId,
    scenarioId,
  });

  const selectAll = useCallback(() => {
    setValues(map(get('value'), options));
  }, [options, setValues]);
  const clearAll = useCallback(() => {
    setValues([]);
  }, [setValues]);

  const onHover = useCallback(
    ({ object, x, y }) => {
      if (object) {
        const id = get(['properties', 'id'], object) || get(['id'], object);
        setHoverPosition({ x, y });
        setHover((current) =>
          !current || !current.object || current.object.id !== id
            ? buildHover(object)
            : current,
        );
      } else {
        setHover(undefined);
      }
    },
    [setHover, setHoverPosition],
  );
  const onClick = useCallback(
    ({ object }, { srcEvent }) => {
      if (object) {
        const id = get(['properties', 'id'], object) || get(['id'], object);
        setValues((current) => {
          if (srcEvent.shiftKey) {
            if (current.includes(id)) {
              return reject(eq(id), current);
            }
            return concat(id, current);
          }
          return [id];
        });
        return true;
      }
    },
    [setValues],
  );

  const onMapClick = useCallback(() => {
    setValues([]);
  }, [setValues]);

  const colorId = useSelectorWithProps(layerColorSelector, {
    layerId,
    workspaceId,
  });
  const color = useMemo(() => theme.color.layer[colorId], [theme, colorId]);

  const colors = useMemo(
    () => ({
      base: baseColor(color.fill),
      diff: diffColor(color.fill),
      deselectedBorder: deselectedLineColor(color.fill),
      selectedBorder: selectedLineColor(color.fill),
    }),
    [color],
  );

  const locations = useMemo(
    () =>
      pipe(
        map((location) => ({
          id: location.id,
          layerColor: color,
          area: location.geography.area,
          label: get([location.id, 'label'], locationLabels) || 'New location',
          ...location.geography.point,
        })),
        filter(({ id }) => locationLabels[id].layer === layerId),
      )(geographies),
    [geographies, color, locationLabels, layerId],
  );

  const bounds = useMemo(
    () =>
      coordinatesToBounds(
        map(({ longitude, latitude }) => [longitude, latitude], locations),
      ),
    [locations],
  );

  useEffect(() => {
    setInitialState((current) =>
      current || !rect || !bounds
        ? current
        : fitBounds(bounds, rect.width, rect.height, 20),
    );
  }, [bounds, rect, setInitialState]);

  const pointLocations = useMemo(
    () => reject((l) => l.area, locations),
    [locations],
  );

  const shapeLocations = useMemo(
    () =>
      pipe(
        filter((l) => l.area),
        map((l) => ({
          type: 'Feature',
          geometry: get('area', l),
          properties: {
            colors,
            id: l.id,
            label: l.label,
          },
        })),
      )(locations),
    [locations, colors],
  );

  const allOptionsSelected = useMemo(
    () =>
      initialState && every((option) => values.includes(option.value), options),
    [values, options, initialState],
  );

  const layers = useMemo(
    () =>
      scenarioLocationLayers({
        shapeLocations,
        pointLocations,
        hoverId: get('id', hover),
        selectedId: values,
        onHover,
        onClick,
      }),
    [onHover, onClick, pointLocations, shapeLocations, hover, values],
  );
  return (
    <MultiSelectPrompt>
      <Full ref={ref}>
        {!initialState && <Loading />}
        {initialState && (
          <DeckGL
            layers={layers}
            controller
            initialViewState={initialState}
            onClick={onMapClick}
          >
            <StaticMap
              mapboxApiAccessToken={mapboxAccessToken}
              mapStyle={LIGHT_STYLE}
              preventStyleDiffing
            />
          </DeckGL>
        )}
        {hover && hoverPosition && (
          <ImportLocationTooltip
            x={hoverPosition.x + rect.x}
            y={hoverPosition.y + rect.y}
            label={hover.label}
          />
        )}
        <SelectAllButton
          allSelected={allOptionsSelected}
          onSelectAll={selectAll}
          onClearAll={clearAll}
        />
      </Full>
    </MultiSelectPrompt>
  );
};

LocationsMap.propTypes = propTypes;
LocationsMap.defaultProps = defaultProps;

export { LocationsMap };
