import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { set, size } from 'lodash/fp';
import { useSearchParams } from 'react-router-dom';
import { Frame } from 'components/frame';
import { VisualisationTypeChooser } from 'components/visualisation-type-chooser';
import LayerMenu from 'components/layer-menu/LayerMenu';
import blockValueSelector from 'selectors/blockValueSelector';
import publicVisualisationContextsSelector from 'selectors/publicVisualisationContextsSelector';
import ContextsSection from 'components/toolbox/sections/ContextsSection';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { staticToEditableType } from 'data/block';
import { usePerspectives, useSelectorWithProps } from 'hooks';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  boardId: PropTypes.number.isRequired,
  workspaceId: PropTypes.string.isRequired,
};

const PublicVisualisationPane = ({ blockId, boardId, workspaceId }) => {
  const [, setSearchParams] = useSearchParams();
  const perspectives = usePerspectives();
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId,
    public: true,
    workspaceId,
  });

  const contexts = useSelectorWithProps(publicVisualisationContextsSelector, {
    blockId,
    boardId,
    perspectives,
    public: true,
    workspaceId,
  });

  const onChange = useCallback(
    (attribute, value) => {
      setSearchParams(set(['perspectives', blockId, attribute], value));
    },
    [blockId, setSearchParams],
  );

  return (
    <ToolboxPanel fadeScroll title={blockValue.title}>
      <Frame>
        <VisualisationTypeChooser
          mode='static'
          value={staticToEditableType(blockValue)}
        />
      </Frame>

      {blockValue.visualisation === 'spatial' && (
        <LayerMenu
          blockId={blockId}
          privacy='public'
          workspaceId={workspaceId}
        />
      )}
      {size(contexts) > 0 && (
        <ContextsSection onChange={onChange} contexts={contexts} />
      )}
    </ToolboxPanel>
  );
};

PublicVisualisationPane.propTypes = propTypes;

export { PublicVisualisationPane };
