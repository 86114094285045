import styled from 'styled-components';

const TableTitleWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  color: theme.color.gray9,
  flex: '0 0 auto',
  fontWeight: theme.fontWeight.medium,
  left: 0,
  padding: '6px 15px',
  position: 'sticky',
  whiteSpace: 'nowrap',
}));

export { TableTitleWrapper };
