import PropTypes from 'prop-types';
import { Text, Strong } from '@kinesis/bungle';
import {
  flip,
  sortBy,
  indexOf,
  reject,
  isEqual,
  uniq,
  flatMap,
  values,
  pipe,
  get,
  map,
  isEmpty,
} from 'lodash/fp';
import { useMemo } from 'react';
import {
  interventionAreaValueKeys,
  interventionAreaLabels,
  interventionAreaValueLabels,
  interventionAreaValueUnits,
  interventionAreaKeyToPathwayInputBlockKey,
  interventionAreaKeyOrder,
} from 'data/pathways';
import { Row, RowItem } from './pathway-input-block.styles';

const mapWithIndex = map.convert({ cap: false });

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      intervention_areas: PropTypes.object.isRequired,
    }),
  ).isRequired,
  viewMode: PropTypes.oneOf(['maximised', 'minimised', 'standalone'])
    .isRequired,
};
const defaultProps = {};

const unitOf = (interventionAreaKey, valueKey) =>
  get(
    valueKey ? [interventionAreaKey, valueKey] : interventionAreaKey,
    interventionAreaValueUnits,
  );

const InterventionAreas = ({ data, viewMode }) => {
  const interventionAreaKeys = useMemo(
    () =>
      pipe(
        flatMap(pipe(get('intervention_areas'), values)),
        map(get('key')),
        sortBy(flip(indexOf)(interventionAreaKeyOrder)),
        uniq,
      )(data),
    [data],
  );
  return mapWithIndex((interventionAreaKey, iaIdx) => {
    const valueKeys = reject(
      isEqual('strategy'),
      interventionAreaValueKeys[interventionAreaKey],
    );
    const lastRowTop =
      interventionAreaKeys.length === iaIdx + 1 && isEmpty(valueKeys);
    return (
      <>
        <Row withBottomBorder={viewMode === 'maximised' && lastRowTop}>
          <RowItem lastRow={lastRowTop}>
            <Strong>{interventionAreaLabels[interventionAreaKey]}</Strong>
          </RowItem>
          {mapWithIndex((datum) => {
            switch (interventionAreaKey) {
              case 'residential-retrofits':
              case 'non-residential-retrofits': {
                const unit = unitOf(interventionAreaKey, 'strategy');
                const datumKey =
                  interventionAreaKeyToPathwayInputBlockKey[
                    interventionAreaKey
                  ];
                const value = get(
                  ['intervention_areas', datumKey, 'strategy'],
                  datum,
                );
                return (
                  <RowItem lastRow={lastRowTop}>
                    <Text>{unit.format(value)}</Text>
                  </RowItem>
                );
              }
              case 'public-transit-electrification': {
                const unit = unitOf(interventionAreaKey);
                const datumKey =
                  interventionAreaKeyToPathwayInputBlockKey[
                    interventionAreaKey
                  ];
                const value = get(
                  ['intervention_areas', datumKey, 'value'],
                  datum,
                );
                return (
                  <RowItem lastRow={lastRowTop}>
                    <Text>{unit.format(value)}</Text>
                  </RowItem>
                );
              }
              default:
                return <RowItem lastRow={lastRowTop} />;
            }
          }, data)}
        </Row>
        {mapWithIndex((valueKey, vIdx) => {
          const unit = unitOf(interventionAreaKey, valueKey);
          const lastRow =
            interventionAreaKeys.length === iaIdx + 1 &&
            valueKeys.length === vIdx + 1;
          return (
            <Row withBottomBorder={viewMode === 'maximised' && lastRow}>
              <RowItem lastRow={lastRow}>
                <Text>{interventionAreaValueLabels[valueKey]}</Text>
              </RowItem>
              {map((datum) => {
                const datumKey =
                  interventionAreaKeyToPathwayInputBlockKey[
                    interventionAreaKey
                  ];
                const value = get(
                  ['intervention_areas', datumKey, valueKey],
                  datum,
                );
                return (
                  <RowItem lastRow={lastRow} numeric={unit.align === 'right'}>
                    {unit.format(value)}
                  </RowItem>
                );
              }, data)}
            </Row>
          );
        }, valueKeys)}
      </>
    );
  }, interventionAreaKeys);
};

InterventionAreas.propTypes = propTypes;
InterventionAreas.defaultProps = defaultProps;

export { InterventionAreas };
