import { createSelector } from 'reselect';
import { defaultTo, get, pipe } from 'lodash/fp';
import currentAttributesSelector from 'selectors/currentAttributesSelector';

const defaultAttributeValues = {};

const scenarioLocationSelector = createSelector(
  (state, { locationId }) => locationId,
  pipe(
    currentAttributesSelector,
    get('values'),
    defaultTo(defaultAttributeValues),
  ),
  (locationId, attributeValues) => attributeValues[locationId],
);

export default scenarioLocationSelector;
