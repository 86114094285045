import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Legend } from '@kinesis/bungle';

const BubbleLegendWrapper = styled.div`
  width: 304px;
  // TODO: Vertical padding set to 12px to line legend up with chart
  padding: 12px 16px;
`;

const propTypes = {
  series: PropTypes.array.isRequired,
};

const BubbleLegend = ({ series }) => {
  const legendProps = useMemo(
    () =>
      series.map(({ data, name, unitFormatter }) => {
        if (!data.length) {
          return {
            subheading: 'Size',
            items: [],
          };
        }
        const zeds = data.map((datum) => datum.z);
        const sizeMin = Math.min(...zeds);
        const sizeMax = Math.max(...zeds);
        const items = [
          {
            size: 'regular',
            label: unitFormatter.z(sizeMax),
          },
        ];
        if (sizeMin < sizeMax) {
          items.unshift({
            size: 'small',
            label: unitFormatter.z(sizeMin),
          });
        }
        return {
          key: name,
          heading: name,
          subheading: 'Size',
          items,
          icon: 'circle',
        };
      }),
    [series],
  );

  return (
    legendProps &&
    legendProps.length && (
      <BubbleLegendWrapper>
        {legendProps.map((p) => (
          <Legend key={p.heading} {...p} />
        ))}
      </BubbleLegendWrapper>
    )
  );
};

BubbleLegend.propTypes = propTypes;

export default BubbleLegend;
