import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { Select } from '@kinesis/bungle';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import blockDataSelector from 'selectors/blockDataSelector';
import visualisationScenarioOptionsSelector from 'selectors/visualisationScenarioOptionsSelector';
import DecoratedScenarioName from 'components/workspace/DecoratedScenarioName';
import isCapsuleMismatchSelector from 'selectors/isCapsuleMismatchSelector';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.exact({
      type: PropTypes.oneOf(['by-id']).isRequired,
      id: PropTypes.number.isRequired,
    }),
    PropTypes.exact({
      type: PropTypes.oneOf(['selected']).isRequired,
    }),
  ]),
  type: PropTypes.oneOf([
    'indicator',
    'choropleth',
    'stacked-column',
    'stacked-area',
    'line',
    'clustered-column',
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  defaultExpanded: false,
  value: { type: 'selected' },
};

const renderItem = (item) =>
  item.category === 'list' ? (
    <DecoratedScenarioName name={item.label} isDefault={item.isDefault} />
  ) : (
    item.label
  );

const isError = (data) =>
  get('type', data) === 'error' &&
  get(['data', 'code'], data) === 'invalid-query-scenario-id';

const VisualisationScenarioSelect = ({
  blockId,
  type,
  defaultExpanded,
  value,
  onChange,
  workspaceId,
}) => {
  const scenarioId = useScenarioId();
  const options = useSelectorWithProps(visualisationScenarioOptionsSelector, {
    type,
    workspaceId,
  });
  const blockData = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const capsuleMismatch = useSelectorWithProps(isCapsuleMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });

  return (
    <Select
      defaultExpanded={defaultExpanded}
      label='Scenario'
      itemKey='key'
      value={value}
      onChange={onChange}
      options={options}
      groupKey='category'
      groupVariant='divider'
      itemsLabel='scenarios'
      renderItem={renderItem}
      tone={isError(blockData) && !capsuleMismatch ? 'critical' : 'neutral'}
    />
  );
};

VisualisationScenarioSelect.propTypes = propTypes;
VisualisationScenarioSelect.defaultProps = defaultProps;

export { VisualisationScenarioSelect };
