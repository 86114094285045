import { Icon, Inline, InlineItem, Select, Stack, Text } from '@kinesis/bungle';
import PropTypes from 'prop-types';
import {
  LegendContainer,
  interventionCategoryColors as colors,
} from './chart.styles';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
const defaultProps = {};

const EmissionsReductionsLegend = ({ onChange, value }) => (
  <LegendContainer>
    <Stack space='medium'>
      <Stack space='xsmall'>
        <Text>Policy context</Text>
        <Select
          wrap
          onChange={onChange}
          value={value}
          options={[
            { value: 'current', label: 'Current settings' },
            { value: 'intermediate', label: 'Intermediate' },
            { value: 'aggressive', label: 'Aggressive' },
          ]}
        />
      </Stack>
      <Stack space='xsmall'>
        <Inline space='small'>
          <InlineItem>
            <Icon color={colors.energy} magnitude='small' type='circle' />
          </InlineItem>
          <InlineItem>
            <Text>Energy</Text>
          </InlineItem>
        </Inline>
        <Inline space='small'>
          <InlineItem>
            <Icon color={colors.transport} magnitude='small' type='circle' />
          </InlineItem>
          <InlineItem>
            <Text>Transport</Text>
          </InlineItem>
        </Inline>
        <Inline space='small'>
          <InlineItem>
            <Icon color={colors.waste} magnitude='small' type='circle' />
          </InlineItem>
          <InlineItem>
            <Text>Waste</Text>
          </InlineItem>
        </Inline>
      </Stack>
    </Stack>
  </LegendContainer>
);

EmissionsReductionsLegend.propTypes = propTypes;
EmissionsReductionsLegend.defaultProps = defaultProps;

export { EmissionsReductionsLegend };
