import styled from 'styled-components';

const TableWrapper = styled('div')(({ theme }) => ({
  alignContent: 'start',
  background: 'white',
  borderColor: 'transparent',
  borderStyle: 'solid',
  borderWidth: '0 0 1px',
  boxSizing: 'border-box',
  color: theme.color.default,
  display: 'grid',
  gridTemplateColumns: '1fr',
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.default,
  height: '100%',
  lineHeight: theme.lineHeight.default,
  minWidth: '100%',
  overflow: 'auto',
  overscrollBehavior: 'contain',
  position: 'relative',
  transform: 'translateZ(0)',
}));

export { TableWrapper };
