import styled from 'styled-components';

const Summary = styled.div`
  margin-top: 16px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 4px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.span`
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.color.gray8};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Value = styled.span`
  margin-left: auto;
  font-variant-numeric: tabular-nums;
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.gray9};
`;

export { Item, Label, Value, Summary };
