import styled from 'styled-components';
import { policyContextColors as colors } from './chart.styles';

const ColoredLine = styled.div(({ variant }) => ({
  height: 0,
  width: '16px',
  borderBottom: `2px solid ${colors[variant]}`,
}));

export { ColoredLine };
