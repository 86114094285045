import { useMemo, useRef } from 'react';

const useScrollPosition = () => {
  const scrollRef = useRef({
    x: undefined,
    y: undefined,
  });

  return useMemo(
    () => [
      scrollRef.current,
      (event) => {
        scrollRef.current.x = event.target.scrollLeft;
        scrollRef.current.y = event.target.scrollTop;
      },
    ],
    [],
  );
};

export default useScrollPosition;
