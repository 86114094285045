import { get } from 'lodash/fp';
import scenariosSelector from './scenariosSelector';

function scenarioSelector(state, props = {}) {
  const { scenarioId } = props;

  return get(scenarioId, scenariosSelector(state, props));
}

export default scenarioSelector;
