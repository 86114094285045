import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceFetch = createAsyncThunk(
  'workspace/fetch',
  async ({ workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.get(`/api/workspace/${workspaceId}`);

    const { data } = response;

    return {
      access: data.access,
      boards: data.boards,
      blocks: data.blocks,
      description: data.description,
      startYear: data.start_year,
      endYear: data.end_year,
      id: data.id,
      identities: data.identities,
      locations: data.locations,
      layers: data.layers,
      layerGroups: data.layerGroups,
      shapes: data.shapes,
      name: data.name,
      scenarios: data.scenarios,
      attributes: data.attributes,
      geographies: data.geographies,
      geographyIds: data.geographyIds,
      instantiations: data.instantiations,
      apps: data.apps,
      organisationId: data.owner,
      owner: data.owner,
      topics: data.resources || data.topics,
      tables: data.tables,
      permissions: data.permissions,
      resources: data.resources || data.topics,
      touchedAt: data.touched_at,
      touchedBy: data.touched_by,
      appVersions: data.appVersions,
      history: data.history,
      licences: data.licences,
      workspaceShares: data.workspaceShares,
    };
  },
);

export { workspaceFetch };
