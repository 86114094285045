import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { Prompt } from '@kinesis/bungle';
import Layout from 'components/layout';
import { actions as miscActions } from 'reducers/miscReducer';
import { keyboardShortcutString } from 'utils/keyboardUtils';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

const MultiSelectPrompt = ({ children }) => {
  const dispatch = useDispatch();
  const showPrompt = useSelector(get(['misc', 'showSelectAllLocationsPrompt']));
  const clearPrompt = useCallback(() => {
    dispatch(miscActions.clearShowSelectAllLocationsPrompt());
  }, [dispatch]);
  const text = `Hold shift to select multiple locations (${keyboardShortcutString(
    {
      key: 'click',
      shift: true,
    },
  )})`;

  return (
    <Layout direction='column'>
      {children}
      <Prompt
        onDismiss={clearPrompt}
        dismissOnKeyPress={false}
        showPrompt={showPrompt}
        showTriangle={false}
        title={text}
        placement='top'
        offset={11}
      >
        <div />
      </Prompt>
    </Layout>
  );
};

MultiSelectPrompt.propTypes = propTypes;
MultiSelectPrompt.defaultProps = defaultProps;

export { MultiSelectPrompt };
