import { createSelector } from 'reselect';
import { get, map, placeholder } from 'lodash/fp';
import workspaceAppVersionsSelector from './workspaceAppVersionsSelector';

const loadedAppVersionsSelector = createSelector(
  workspaceAppVersionsSelector,
  get(['appVersions', 'entities']),
  (loaded, versions) => map(get(placeholder, versions), loaded),
);

export default loadedAppVersionsSelector;
