import styled from 'styled-components';

const VerticalPane = styled('div')(
  ({ theme }) => ({
    borderTop: `1px solid ${theme.borderColor}`,
    boxSizing: 'content-box',
    minHeight: 0,
    minWidth: 0,
    '&:first-child': {
      borderTopWidth: 0,
    },
  }),
  ({ basis, flex }) => ({
    flexBasis: basis,
    flexGrow: flex,
    flexShrink: flex,
  }),
  ({ overflow }) => ({ overflow }),
);

export { VerticalPane };
