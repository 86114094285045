import { createSelector } from 'reselect';
import { reject, isNil } from 'lodash/fp';
import datasetVersionsSelector from './datasetVersionsSelector';

const datasetVersionsPublishedSelector = createSelector(
  datasetVersionsSelector,
  reject(({ publishedAs }) => isNil(publishedAs)),
);

export default datasetVersionsPublishedSelector;
