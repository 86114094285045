import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const geographyBundle = createAsyncThunk(
  'geography/bundle',
  async ({ geographies }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/geography-bundle`, {
      geographies,
    });
    return response.data;
  },
);

export { geographyBundle };
