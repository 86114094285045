import { createSelector } from 'reselect';
import { filter, get, has } from 'lodash/fp';
import blocksSelector from 'selectors/blocksSelector';

const blocksWithTimeSelector = createSelector(
  blocksSelector,
  filter((b) =>
    has(['capsule', get(['capsule', 'visualisation'], b), 'time'], b),
  ),
);

export default blocksWithTimeSelector;
