import { createSelector } from 'reselect';
import { filter, get, map, placeholder, pipe, values } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const tablesSelector = createSelector(
  pipe(workspaceSelector, get('resources')),
  get('resources'),
  (workspaceResources, resources) =>
    pipe(
      values,
      map(get(placeholder, resources)),
      filter({ type: 'table' }),
    )(workspaceResources),
);

export default tablesSelector;
