import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const pathwayPublish = createAsyncThunk(
  'pathway/publish',
  async (
    {
      input,
      locations,
      locationType,
      workspace,
      workspaceLabel,
      scenario,
      scenarioLabel,
      onPublish,
      pathwayUrlParams,
    },
    { dispatch, getState },
  ) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/pathways/publish`, {
      inputs: {
        input,
        locations,
        location_type: locationType,
      },
      workspace,
      scenario,
      pathway_url_params: pathwayUrlParams,
    });

    if (onPublish) {
      onPublish({
        ...response.data,
        scenarioLabel,
        workspaceLabel,
      });
    }

    return response.data;
  },
);

export { pathwayPublish };
