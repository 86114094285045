import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import currentAttributesSelector from 'selectors/currentAttributesSelector';

const currentAttributeSettingsSelector = createSelector(
  currentAttributesSelector,
  (attributes) => get('settings', attributes),
);

export default currentAttributeSettingsSelector;
