import { get } from 'lodash/fp';

function workspaceSelector(state, props = {}) {
  const { public: isPublic, workspaceId } = props;
  const namespace = isPublic ? 'publicWorkspaces' : 'workspaces';

  return get([namespace, workspaceId], state);
}

export default workspaceSelector;
