import { useSelector } from 'react-redux';
import { get } from 'lodash/fp';
import { useDatasetParams } from './useDatasetParams';

function useDataset() {
  const { id } = useDatasetParams() ?? {};

  return useSelector(get(['datasets', id]));
}

export { useDataset };
