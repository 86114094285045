/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import { rowBreak } from 'utils/blockVersions';

const blocksReorder = createAsyncThunk(
  'blocks/reorder',
  async ({ workspaceId, boardId, blockIds }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.patch(
      `/api/workspace/${workspaceId}/board/${boardId}/reorder-blocks`,
      { blockIds, versions: { 'row-break': rowBreak } },
    );

    return response.data;
  },
);

export { blocksReorder };
