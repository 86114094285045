import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const organisationMembersFetch = createAsyncThunk(
  'organisation-members/fetch',
  async ({ organisationId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.get(`/api/members/${organisationId}`);

    return response.data;
  },
);

export { organisationMembersFetch };
