import { createSelector } from 'reselect';
import {
  defaultTo,
  flatMap,
  get,
  keyBy,
  map,
  pipe,
  uniq,
  update,
} from 'lodash/fp';
import { immutableSort } from 'utils/charts/series';

import capsuleDataSelector from 'selectors/capsuleDataSelector';

const highchartsDataTransformerSelector = createSelector(
  capsuleDataSelector,
  ({ yBounds, data, xUnit }) => {
    const allXs = pipe(
      flatMap('data'),
      map('x'),
      uniq,
      immutableSort(xUnit.compare),
    )(data);

    return {
      data: map(
        (d) =>
          update(
            'data',
            (seriesData) => {
              const keyed = keyBy('x', seriesData);
              return map((x) => defaultTo(null, get([x, 'y'], keyed)), allXs);
            },
            d,
          ),
        data,
      ),
      categories: allXs,
      xUnit,
      yBounds,
    };
  },
);

export default highchartsDataTransformerSelector;
