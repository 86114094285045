import { createSelector } from 'reselect';
import { get, pipe, first } from 'lodash/fp';
import { requiredTablesOf } from 'data/block';
import blockValueSelector from './blockValueSelector';
import scenarioKeySelector from './scenarioKeySelector';
import workspaceSelector from './workspaceSelector';

const statisticsSelector = createSelector(
  pipe(workspaceSelector, get('statistics')),
  pipe(blockValueSelector, requiredTablesOf, first),
  scenarioKeySelector,
  (statistics, table, scenarioKey) => get([table, scenarioKey], statistics),
);

export default statisticsSelector;
