import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

const uploadSelector = createSelector(
  (state, props = {}) => props.upload,
  get('uploads'),
  (key, uploads) => (key ? get(key, uploads) : undefined),
);

export default uploadSelector;
