import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const sharingPermissionRevoke = createAsyncThunk(
  'sharing-permission/revoke',
  async ({ sourceIdentity, workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.post(`/api/sharing/remove-permission`, {
      targetIdentity: `workspace/${workspaceId}`,
      sourceIdentity,
    });

    return response.data;
  },
);

export { sharingPermissionRevoke };
