import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { parse as parseQueryString } from 'qs';
import { get, pipe } from 'lodash/fp';
import { BackButton, Breadcrumb } from 'components/app-header';
import { Workspace as WorkspaceRoot } from 'components/workspace';
import { PageLayout } from 'components/page-layout';
import { Loading } from 'components/loading';
import { WorkspaceScreenPlaceholder } from 'components/workspace/workspace.styles';
import { BoardLegacyRedirect } from 'components/board-legacy-redirect';
import { Board } from 'components/board';
import { Scenario } from 'components/scenario';
import workspaceSelector from 'selectors/workspaceSelector';
import { workspaceFetch } from 'actions/workspaceFetch';
import { useSelectorWithProps, useWorkspaceId } from 'hooks';

const Workspace = () => {
  const location = useLocation();
  const workspaceId = useWorkspaceId();
  const { hasInitialised, name } =
    useSelectorWithProps(pipe(workspaceSelector, get('data')), {
      workspaceId,
    }) ?? {};
  const { visualisation: visualisationKey } = parseQueryString(
    location.search,
    { ignoreQueryPrefix: true },
  );
  const fetchedWorkspaces = useSelector(get(['misc', 'hasFetchedWorkspaces']));
  const dispatch = useDispatch();

  useEffect(() => {
    if (workspaceId) {
      dispatch(workspaceFetch({ workspaceId }));
    }
  }, [dispatch, workspaceId]);

  return (
    <PageLayout>
      <BackButton to='/workspaces' />
      <Breadcrumb to='/workspaces' root>
        Workspaces
      </Breadcrumb>
      {fetchedWorkspaces && (
        <Breadcrumb to={`/workspaces/${workspaceId}`}>
          {name || 'New workspace'}
        </Breadcrumb>
      )}
      {!hasInitialised || !fetchedWorkspaces ? (
        <WorkspaceScreenPlaceholder>
          <Loading spinning />
        </WorkspaceScreenPlaceholder>
      ) : (
        <Routes>
          <Route path='scenarios/:scenario'>
            <Route index element={<Navigate to='map' />} />
            <Route path=':view/*' element={<Scenario />} />
          </Route>
          <Route path='boards/:board'>
            <Route index element={<Board />} />
            <Route path='blocks/:block' element={<Board />} />
          </Route>
          <Route
            path='*'
            element={
              visualisationKey ? (
                <BoardLegacyRedirect
                  visualisationKey={visualisationKey}
                  workspaceId={workspaceId}
                />
              ) : (
                <WorkspaceRoot />
              )
            }
          />
        </Routes>
      )}
    </PageLayout>
  );
};

export default Workspace;
