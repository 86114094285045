import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { labelOf, entries } from 'data/attributes';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import { get, map, pipe, set } from 'lodash/fp';
import { MultiSelect, Tooltip, UtilityButton } from '@kinesis/bungle';
import { useActions, useSelectorWithProps } from 'hooks';
import { actions as locationFiltersActions } from 'reducers/locationFiltersReducer';
import {
  Card,
  ClearFilterContainer,
  Field,
  Header,
  Operator,
} from './locations-filtering-cards.styles';

const propTypes = {
  expression: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const ItemisedAttributeCard = ({ expression, workspaceId }) => {
  const instantiations = useSelectorWithProps(workspaceInstantiationsSelector, {
    workspaceId,
  });
  const categories = useMemo(
    () =>
      pipe(
        get(expression.attributeType),
        entries,
        map((c) => ({
          label: c.label,
          value: c.key,
        })),
      )(instantiations),
    [expression.attributeType, instantiations],
  );

  const { clearFilter, updateFilter } = useActions(locationFiltersActions);

  const clear = useCallback(() => {
    clearFilter(expression.key);
  }, [clearFilter, expression.key]);

  const updateOperand = useCallback(
    (values) => {
      updateFilter(set('operand', values, expression));
    },
    [expression, updateFilter],
  );

  return (
    <Card>
      <Header>
        <Field>{labelOf({ attribute: expression.attributeType })}</Field>
        <Operator>is any of</Operator>
        <ClearFilterContainer>
          <Tooltip placement='top' title='Clear filter'>
            <UtilityButton
              data-testid='clearFilter'
              icon='delete'
              magnitude='xsmall'
              onClick={clear}
              variant='danger'
            />
          </Tooltip>
        </ClearFilterContainer>
      </Header>
      <MultiSelect
        onChange={updateOperand}
        options={categories}
        variant='inline'
        values={expression.operand || map('value', categories)}
      />
    </Card>
  );
};

ItemisedAttributeCard.propTypes = propTypes;
ItemisedAttributeCard.defaultProps = defaultProps;

export default ItemisedAttributeCard;
