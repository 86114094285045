import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { PublicBlock } from 'components/public-block';
import { Loading } from 'components/loading';
import publicWorkspaceSelector from 'selectors/publicWorkspaceSelector';
import { publicWorkspaceFetch } from 'actions/publicWorkspaceFetch';
import { BlockWrapper } from 'components/block/block.styles';
import {
  useBlockId,
  useBoardId,
  usePublicWorkspaceId,
  useSelectorWithProps,
} from 'hooks';
import { LifecyclePublic } from '../board/lifecycle.public';

const EmbeddedBlock = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceId = usePublicWorkspaceId();
  const boardId = useBoardId();
  const blockId = useBlockId();
  const { search = '' } = useLocation();
  const { loaded } = useSelectorWithProps(publicWorkspaceSelector, {
    workspaceId,
  });

  useEffect(() => {
    if (!loaded && workspaceId) {
      dispatch(publicWorkspaceFetch({ key: workspaceId }))
        .unwrap()
        .catch(() => {
          navigate('/not-found');
        });
    }
  }, [dispatch, loaded, navigate, workspaceId]);

  if (!loaded) {
    return (
      <BlockWrapper>
        <Loading />
      </BlockWrapper>
    );
  }

  return (
    <LifecyclePublic
      boardId={boardId}
      maximisedBlockId={blockId}
      workspaceId={workspaceId}
    >
      <PublicBlock
        changeViewURL={`/public/${workspaceId}/boards/${boardId}${search}`}
        id={blockId}
        viewMode='standalone'
        workspaceId={workspaceId}
      />
    </LifecyclePublic>
  );
};

export { EmbeddedBlock };
