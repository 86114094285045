import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Legend } from '@kinesis/bungle';
import { Container } from './xy-chart-legend.styles';

const propTypes = {
  icon: PropTypes.oneOf(['circle', 'line']),
  series: PropTypes.array.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  icon: 'line',
  title: undefined,
};

const XYChartLegend = ({ icon, series, title }) => {
  const legendItems = useMemo(
    () =>
      series.map((set) => ({
        color: set.color,
        key: set.name,
        label: set.nameFormatter ? set.nameFormatter(set.name) : set.name,
      })),
    [series],
  );

  return (
    <Container>
      <Legend heading={title} icon={icon} items={legendItems} />
    </Container>
  );
};

XYChartLegend.propTypes = propTypes;
XYChartLegend.defaultProps = defaultProps;

export { XYChartLegend };
