import {
  concat,
  defaults,
  flatMap,
  find,
  first,
  get,
  keyBy,
  map,
  pipe,
  set,
  update,
} from 'lodash/fp';
import { attributeType } from 'data/attributes';

let idGenerator = 0;

const appendGeography = concat([
  {
    attribute: 'geography',
    key: 'geography',
    time_series: [{ encoding: 'int64', value: (idGenerator += 1) }],
  },
]);

const injectKey = (attributeOrDefinition) => ({
  ...attributeOrDefinition,
  key:
    attributeType(attributeOrDefinition) === 'custom'
      ? `custom:${attributeOrDefinition.name}`
      : attributeType(attributeOrDefinition),
});

const keyAttributeSettingsAndValues = update(
  'attributes',
  map(
    pipe(
      update('locations', map(update('attributes', map(injectKey)))),
      update('definitions', map(injectKey)),
    ),
  ),
);

const annotateGeography = update(
  'attributes',
  map(update('locations', map(update('attributes', appendGeography)))),
);

const generateGeographies = (workspace) =>
  set(
    'geography',
    pipe(
      flatMap('locations'),
      map((location) => ({
        id: pipe(
          get('attributes'),
          find({ attribute: 'geography' }),
          get('time_series'),
          first,
          get('value'),
        )(location),
        point: location.coordinate,
      })),
      keyBy('id'),
    )(workspace.attributes),
    workspace,
  );

export const version0 = (payload) => {
  const layer_groups = [
    { id: 0, label: 'Workspace' },
    { id: 1, label: 'Reference' },
  ];
  return pipe(
    set('layer_groups', layer_groups),
    update(
      'layers',
      map((layer) =>
        defaults(
          {
            group: layer.label === 'Locations' ? 0 : 1,
            color: layer.label === 'Locations' ? 'yellow' : 'purple',
          },
          layer,
        ),
      ),
    ),
    keyAttributeSettingsAndValues,
    annotateGeography,
    generateGeographies,
  )(payload);
};

export const version1 = pipe(
  keyAttributeSettingsAndValues,
  annotateGeography,
  generateGeographies,
);

export const normaliseVersion = (payload) => {
  const definedVersion = get('version', payload) || 0;

  if (definedVersion === 0) return version0(payload);
  if (definedVersion === 1) return version1(payload);

  throw new Error('Public workspace version unsupported');
};
