import { responseError } from 'actions/responseError';
import { reauthRequired } from 'actions/reauthRequired';

function routeMiddleware({ navigate }) {
  return () => (next) => (action) => {
    next(action);

    if (action.error) {
      return;
    }

    if (action.type === reauthRequired.type) {
      navigate('/');
    }

    if (action.type === responseError.type) {
      if (action.payload.status === 404) {
        navigate('/error/not-found');
      } else if (action.payload >= 500) {
        navigate('/error/server');
      }
    }
  };
}

export default routeMiddleware;
