import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Icon, Secondary, Stack } from '@kinesis/bungle';
import { Inline as OldInline } from '@kinesis/bungle/legacy';
import useTheme from 'hooks/useTheme';
import { Message, Panel } from './processing-status-pane.styles';

const propTypes = {
  processing: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      isBaseline: PropTypes.bool,
      complete: PropTypes.number.isRequired,
    }),
  ),
  total: PropTypes.number,
};
const defaultProps = {
  processing: [],
  total: 0,
};

const ProcessingStatusPane = ({ processing, total }) => {
  const theme = useTheme();
  return (
    <Panel>
      <Stack space='medium'>
        <Message>
          We’re processing app outputs for scenarios that have changed.
        </Message>
        <Stack space='xsmall'>
          {processing.map(({ id, label, isBaseline, complete }) =>
            complete < total ? (
              <OldInline key={id} alignX='space-between' noWrap space='small'>
                <OldInline space='xsmall' alignY='center' noWrap>
                  {label}
                  {isBaseline && (
                    <Icon
                      magnitude='xsmall'
                      type='baseline'
                      color={theme.color.gray7}
                    />
                  )}
                </OldInline>
                <Secondary>
                  {total - complete} {pluralize('outputs', total - complete)}{' '}
                  remaining
                </Secondary>
              </OldInline>
            ) : null,
          )}
        </Stack>
      </Stack>
    </Panel>
  );
};

ProcessingStatusPane.propTypes = propTypes;
ProcessingStatusPane.defaultProps = defaultProps;

export { ProcessingStatusPane };
