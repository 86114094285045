import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Anchor, Icon, Stack, Strong } from '@kinesis/bungle';
import { Panel, Info, LightText } from './dataset-upload.styles';

const propTypes = {
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: undefined,
};

const DatasetUpload = forwardRef(({ onClick }, ref) => {
  const theme = useTheme();

  return (
    <Panel ref={ref}>
      <Stack alignX='center' space='medium'>
        <Icon color={theme.color.gray6} magnitude='xxlarge' type='upload' />
        <Info>
          <Strong>Drag and drop</Strong>
          <br />
          <LightText>
            or&nbsp;
            <Anchor as='button' type='button' onClick={onClick}>
              browse
            </Anchor>
            &nbsp;to choose CSV files
          </LightText>
        </Info>
      </Stack>
    </Panel>
  );
});

DatasetUpload.defaultProps = defaultProps;
DatasetUpload.propTypes = propTypes;

export { DatasetUpload };
