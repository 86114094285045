import Units from 'data/units';
import { Select } from '@kinesis/bungle';
import PropTypes from 'prop-types';
import { get, pipe } from 'lodash/fp';
import { NumberEntry } from 'components/number-entry';
import {
  findScalarValue,
  validOptions,
  valueInBounds,
} from 'utils/settingsModal';
import {
  unitType,
  unitLabel,
  optionsOf,
  encodingOf,
  typeOf,
} from 'data/settings';
import {
  ensureSettingItem,
  updateSettingItem,
  replaceSettingItemKey,
} from 'data/attributes';

const propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  specification: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  setSetting: PropTypes.func.isRequired,
};

const defaultProps = {};

const units = new Units();

const formatter = (type) =>
  type ? units.parseColumn({ unit: { type } }).formatCell : undefined;

const symbol = (type) =>
  type ? units.parseColumn({ unit: { type } }).symbol || type : undefined;

const EditableCell = ({ specifications, specification, item, setSetting }) => {
  if (!specification) return null;
  const currentFamily = get('family', specification);
  const value = findScalarValue(specifications, currentFamily, item);
  const encoding = encodingOf(specification);
  const onSave = (v) => {
    if (!valueInBounds(specification, v)) {
      return;
    }
    setSetting((current) =>
      pipe(
        replaceSettingItemKey(
          item.key,
          specification.key,
          specification.family,
        ),
        ensureSettingItem(item.key, specification.family, {
          group: { label: specification.group },
          label: specification.label,
          key: specification.family,
          value: { encoding, value: v },
        }),
        updateSettingItem(item.key, specification.family, {
          encoding,
          value: v,
        }),
      )(current),
    );
  };
  switch (typeOf(specification)) {
    case 'numeric': {
      return (
        <NumberEntry
          numberType={encoding === 'int64' ? 'integer' : 'decimal'}
          onChange={onSave}
          value={value}
          format={formatter(unitType(specification))}
          units={unitLabel(specification) || symbol(unitType(specification))}
        >
          {value}
        </NumberEntry>
      );
    }
    case 'select': {
      return (
        <Select
          onChange={onSave}
          options={validOptions(item, specifications, optionsOf(specification))}
          value={value}
        />
      );
    }
    default:
      return null;
  }
};

EditableCell.propTypes = propTypes;
EditableCell.defaultProps = defaultProps;

export default EditableCell;
