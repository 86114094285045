import {
  find,
  fromPairs,
  pick,
  pipe,
  set,
  reject,
  unset,
  update,
} from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { appsUpgrade } from 'actions/appsUpgrade';
import { workspaceUpdate } from 'actions/workspaceUpdate';
import { workspaceUpdateOwner } from 'actions/workspaceUpdateOwner';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { geographyUpdate } from 'actions/geographyUpdate';
import { workspaceInitialise } from 'actions/workspaceInitialise';
import { sharingPermissionAdd } from 'actions/sharingPermissionAdd';
import { sharingPermissionChange } from 'actions/sharingPermissionChange';
import { sharingPermissionRevoke } from 'actions/sharingPermissionRevoke';
import { workspaceShareChangePermission } from 'actions/workspaceShareChangePermission';
import { workspaceShareReissue } from 'actions/workspaceShareReissue';
import { workspaceShareRevoke } from 'actions/workspaceShareRevoke';

const initialState = {
  description: undefined,
  errors: [],
  startYear: undefined,
  endYear: undefined,
  hasInitialised: false,
  id: undefined,
  isFetching: false,
  name: undefined,
  touchedAt: undefined,
  touchedBy: undefined,
  workspaceShares: [],
};

// FIX this should be addressed, but is a new rule, and don't
// want to bundle a large refactor so just leaving the fixes.
// This one should change to createSlice.
// eslint-disable-next-line default-param-last
function workspaceDataReducer(state = initialState, action) {
  switch (action.type) {
    case workspaceFetch.pending.type: {
      return set('isFetching', true, state);
    }

    case workspaceUpdateOwner.pending.type: {
      return {
        ...state,
        organisationId: action.meta.arg.owner,
      };
    }

    case workspaceUpdateOwner.fulfilled.type: {
      return {
        ...state,
        organisationId: action.payload.owner,
        touchedAt: action.payload.touched_at,
        touchedBy: action.payload.touched_by,
        licences: action.payload.licences,
      };
    }

    case appsUpgrade.fulfilled.type:
    case appAdd.fulfilled.type: {
      return {
        ...state,
        touchedAt: action.payload.touched_at,
        touchedBy: action.payload.touched_by,
      };
    }

    case sharingPermissionAdd.fulfilled.type: {
      return pipe(
        update('access', (a) => ({
          ...a,
          ...fromPairs(
            action.payload.identities.map((i) => [
              i.identity,
              { [action.meta.arg.permissionLevel]: true },
            ]),
          ),
        })),
        update('permissions', (p) => ({ ...p, ...action.payload.permissions })),
        set('workspaceShares', action.payload.workspace_shares),
      )(state);
    }

    case sharingPermissionChange.fulfilled.type: {
      const { sourceIdentity, permissionLevel } = action.meta.arg;
      const { permissions } = action.payload;

      return pipe(
        set(['access', sourceIdentity], { [permissionLevel]: true }),
        update('permissions', (p) => ({ ...p, ...permissions })),
      )(state);
    }

    case sharingPermissionRevoke.fulfilled.type: {
      const { sourceIdentity } = action.meta.arg;
      const { permissions } = action.payload;

      return pipe(
        unset(['access', sourceIdentity]),
        update('permissions', (p) => ({ ...p, ...permissions })),
      )(state);
    }

    case workspaceShareReissue.pending.type: {
      const { token } = action.meta.arg;
      return update('workspaceShares', (workspaceShares) => {
        const updatedShare = pipe(
          find({ token }),
          set('share_expired', false),
        )(workspaceShares);

        return [...reject({ token }, workspaceShares), updatedShare];
      })(state);
    }

    case workspaceShareRevoke.pending.type: {
      return update('workspaceShares', (workspaceShares) =>
        reject({ token: action.meta.arg.token }, workspaceShares),
      )(state);
    }

    case workspaceShareChangePermission.pending.type: {
      const { token, permission } = action.meta.arg;
      return update('workspaceShares', (workspaceShares) => {
        const updatedShare = pipe(
          find({ token }),
          set('permission_level', permission),
        )(workspaceShares);

        return [...reject({ token }, workspaceShares), updatedShare];
      })(state);
    }

    case workspaceShareReissue.fulfilled.type:
    case workspaceShareRevoke.fulfilled.type:
    case workspaceShareChangePermission.fulfilled.type: {
      return set('workspaceShares', action.payload, state);
    }

    case geographyUpdate.fulfilled.type:
    case workspacePatch.fulfilled.type:
    case workspaceFetch.fulfilled.type: {
      const {
        access,
        description,
        startYear,
        endYear,
        id,
        name,
        owner,
        touchedAt,
        touchedBy,
        licences,
        workspaceShares,
        permissions,
      } = action.payload;

      return pipe(
        set('access', access),
        set('description', description),
        set('startYear', startYear),
        set('endYear', endYear),
        set('id', id),
        set('name', name),
        set('owner', owner),
        // This is added to be consistent with the initialise workspace below
        // I am unsure what the correct field is, I suspect owner, but there
        // is a divergence where some places are using owner, some places
        // organisationId, so setting both here for now.
        set('organisationId', owner),
        set('touchedAt', touchedAt),
        set('touchedBy', touchedBy),
        set('licences', licences),
        set('permissions', permissions),
        set('workspaceShares', workspaceShares),
        set('errors', []),
        set('hasInitialised', true),
        set('isFetching', false),
      )(state);
    }

    case workspaceFetch.rejected.type: {
      return pipe(
        set('isFetching', false),
        set('errors', action.payload.errors),
      )(state);
    }

    case workspaceInitialise.type: {
      const {
        data: { description, id, name, owner, permissions, touchedAt },
      } = action.payload;
      return pipe(
        set('description', description),
        set('id', id),
        set('name', name),
        // Setting owner and organisationId here as well to keep in sync with workspaceFetch above
        set('organisationId', owner),
        set('owner', owner),
        set('permissions', permissions),
        set('touchedAt', touchedAt),
      )(state);
    }

    case workspaceUpdate.pending.type: {
      const { fields } = action.meta.arg;

      return {
        ...state,
        ...pick(
          ['name', 'description', 'organisationId', 'startYear', 'endYear'],
          fields,
        ),
      };
    }

    case workspaceUpdate.fulfilled.type: {
      const {
        access,
        description,
        startYear,
        endYear,
        name,
        touchedAt,
        touchedBy,
      } = action.payload;

      return pipe(
        set('access', access),
        set('description', description),
        set('startYear', startYear),
        set('endYear', endYear),
        set('name', name),
        set('touchedAt', touchedAt),
        set('touchedBy', touchedBy),
      )(state);
    }

    default:
  }

  return state;
}

export default workspaceDataReducer;
