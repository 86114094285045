import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { isEqual, isNil, first, get, reject, size } from 'lodash/fp';
import { Tooltip, UtilityButton } from '@kinesis/bungle';
import { ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { AppToolbar, Breadcrumb } from 'components/app-header';
import Layout from 'components/layout';
import { PublicBoardTimeSlider } from 'components/public-board-time-slider';
import { ScenarioBoardDropdown } from 'components/scenario-board-dropdown';
import { PageLayout } from 'components/page-layout';
import { ScenarioSwitcher } from 'components/scenario-switcher';
import { blocksToSpanBlocks, calculateBlockHeight } from 'utils/blocks';
import { Toolbox } from 'components/toolbox';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import {
  useBoardId,
  useBlockId,
  usePublicWorkspaceId,
  useScenarioId,
  useSelectorWithProps,
} from 'hooks';
import useActions from 'hooks/useActions';
import useResponsive from 'hooks/useResponsive';
import blocksForBlockGridSelector from 'selectors/blocksForBlockGridSelector';
import useScrollPosition from 'hooks/useScrollPosition';
import useToolboxOpenState from 'hooks/useToolboxOpenState';
import { PublicBlock } from 'components/public-block';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import selectedBlockIdsWithTimeSelector from 'selectors/selectedBlockIdsWithTimeSelector';
import { rawBlockTypes } from 'constants/block-types';
import {
  BlockCard,
  BlockGridWrapper,
} from 'components/block-grid/block-grid.styles';
import {
  ImmutableBlockGridGrid,
  RawBlock,
} from 'components/immutable-block-grid/immutable-block-grid.styles';
import { LifecyclePublic } from 'components/board/lifecycle.public';
import { EmptyBoard } from 'components/empty-board';

const propTypes = {};
const defaultProps = {};

const PublicBoard = () => {
  const navigate = useNavigate();
  const gridRef = useRef();
  const selectedRef = useRef();
  const boardId = useBoardId();
  const maximisedBlockId = useBlockId();
  const location = useLocation();
  const scenarioId = useScenarioId();
  const workspaceId = usePublicWorkspaceId();
  const blocks = useSelectorWithProps(blocksForBlockGridSelector, {
    boardId,
    public: true,
    workspaceId,
  });
  const selectedBlockIdsWithTime = useSelectorWithProps(
    selectedBlockIdsWithTimeSelector,
    {
      boardId,
      public: true,
      scenarioId,
      workspaceId,
    },
  );
  const needsTimeSlider =
    isNil(maximisedBlockId) ||
    selectedBlockIdsWithTime.includes(maximisedBlockId);
  const onScenarioSwitch = useCallback(
    (sid) => {
      navigate({ search: `?scenario=${sid}` });
    },
    [navigate],
  );
  const [scrollPosition, setScrollPosition] = useScrollPosition();
  const [isToolboxOpen, setToolboxOpenState] = useToolboxOpenState();

  const selectedBlockIds = useSelectorWithProps(blockSelectionsSelector, {
    workspaceId,
  });
  const { select } = useActions(toolboxActions);
  const { selectBlock, toggleBlock, selectBoard, resetBlocks } = useActions(
    blockSelectionsActions,
  );

  const handleReset = useCallback(() => {
    if (size(selectedBlockIds) > 0) {
      resetBlocks();
    }
  }, [selectedBlockIds, resetBlocks]);

  const handleToolboxStateChange = useCallback(() => {
    setToolboxOpenState((isOpen) => !isOpen);
  }, [setToolboxOpenState]);

  const shouldReselectBlock =
    maximisedBlockId && !isEqual([maximisedBlockId], selectedBlockIds);

  // NOTE: This must occur before the next useEffect.
  useEffect(() => {
    select({
      pane: 'context',
      selection: { id: boardId, type: 'public-board' },
      label: 'board',
    });
    selectBoard(boardId);
  }, [select, boardId, selectBoard, workspaceId]);

  useEffect(() => {
    if (shouldReselectBlock) {
      selectBlock(maximisedBlockId);
    }
  }, [maximisedBlockId, selectBlock, shouldReselectBlock]);

  useEffect(() => {
    if (maximisedBlockId) {
      return;
    }

    if (scrollPosition.y) {
      gridRef.current.scrollTo(0, scrollPosition.y);
    } else if (selectedRef.current) {
      const gridRect = gridRef.current.getBoundingClientRect();
      const blockRect = selectedRef.current.getBoundingClientRect();

      gridRef.current.scrollTo(0, blockRect.top - gridRect.top - 16);
    }
  }, [maximisedBlockId, scrollPosition]);

  const { isFullSizeBoard } = useResponsive();
  const spannedBlocks = useMemo(() => {
    const nonEmptyBlocks = reject({ visualisation: 'empty' }, blocks);
    return blocksToSpanBlocks(nonEmptyBlocks, isFullSizeBoard);
  }, [blocks, isFullSizeBoard]);

  const handleSelectedBlockRef = useCallback(
    (block) =>
      size(selectedBlockIds) === 1 && first(selectedBlockIds) === block.id
        ? selectedRef
        : undefined,
    [selectedBlockIds, selectedRef],
  );

  return (
    <LifecyclePublic
      boardId={boardId}
      maximisedBlockId={maximisedBlockId}
      workspaceId={workspaceId}
    >
      <PageLayout>
        <Breadcrumb>
          <ScenarioBoardDropdown privacy='public' workspaceId={workspaceId} />
        </Breadcrumb>
        <AppToolbar>
          <ToolbarGroup>
            <ToolbarItem>
              <ScenarioSwitcher
                onScenarioSwitch={onScenarioSwitch}
                privacy='public'
                workspaceId={workspaceId}
              />
            </ToolbarItem>
            <ToolbarItem>
              <Tooltip
                justify='end'
                placement='bottom'
                title={isToolboxOpen ? 'Hide toolbox' : 'Show toolbox'}
              >
                <UtilityButton
                  expanded={isToolboxOpen}
                  icon='control'
                  variant={isToolboxOpen ? 'accent' : 'default'}
                  onClick={handleToolboxStateChange}
                />
              </Tooltip>
            </ToolbarItem>
          </ToolbarGroup>
        </AppToolbar>
        <Layout direction='row'>
          <Layout direction='column' overflow='hidden'>
            {spannedBlocks.length === 0 && <EmptyBoard boardId={boardId} />}
            {spannedBlocks.length > 0 && (
              <Layout
                direction='column'
                onScroll={!maximisedBlockId ? setScrollPosition : undefined}
                ref={gridRef}
              >
                {maximisedBlockId ? (
                  <PublicBlock
                    changeViewURL={`/public/${workspaceId}/boards/${boardId}${location.search}`}
                    id={maximisedBlockId}
                    isSelected
                    workspaceId={workspaceId}
                    viewMode='maximised'
                  />
                ) : (
                  <BlockGridWrapper onClick={handleReset}>
                    <ImmutableBlockGridGrid>
                      {spannedBlocks.map((block) =>
                        rawBlockTypes.includes(block.blockType) ? (
                          <RawBlock span={block.span}>
                            <PublicBlock
                              key={block.id}
                              id={block.id}
                              isFirst={block.isFirst}
                              isLast={block.isLast}
                              workspaceId={workspaceId}
                            />
                          </RawBlock>
                        ) : (
                          <BlockCard
                            key={block.id}
                            height={calculateBlockHeight(block, spannedBlocks)}
                            onClick={(event) => {
                              event.stopPropagation();
                              const blockSelect = get('shiftKey', event)
                                ? toggleBlock
                                : selectBlock;
                              blockSelect(block.id);
                            }}
                            ref={handleSelectedBlockRef(block)}
                            selected={selectedBlockIds.includes(block.id)}
                            span={block.span}
                          >
                            <PublicBlock
                              capsule={block.capsule}
                              changeViewURL={`/public/${workspaceId}/boards/${boardId}/blocks/${block.id}${location.search}`}
                              id={block.id}
                              isSelected={selectedBlockIds.includes(block.id)}
                              viewMode='minimised'
                              workspaceId={workspaceId}
                            />
                          </BlockCard>
                        ),
                      )}
                    </ImmutableBlockGridGrid>
                  </BlockGridWrapper>
                )}
              </Layout>
            )}
            {needsTimeSlider && (
              <PublicBoardTimeSlider
                boardId={boardId}
                workspaceId={workspaceId}
              />
            )}
          </Layout>
          {isToolboxOpen && <Toolbox privacy='public' />}
        </Layout>
      </PageLayout>
    </LifecyclePublic>
  );
};

PublicBoard.propTypes = propTypes;
PublicBoard.defaultProps = defaultProps;

export { PublicBoard };
