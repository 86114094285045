import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  filter,
  get,
  isNil,
  map,
  max,
  min,
  pipe,
  reject,
  uniq,
  sortBy,
  identity,
} from 'lodash/fp';
import { ContinuousTimeSlider } from 'components/time-slider';
import { actions as aggregationFiltersActions } from 'reducers/aggregationFiltersReducer';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import workspaceSelector from 'selectors/workspaceSelector';
import selectedBlockIdsWithTimeSelector from 'selectors/selectedBlockIdsWithTimeSelector';
import selectedBlocksTimeBoundsSelector from 'selectors/selectedBlocksTimeBoundsSelector';
import blocksTimeBoundsSelector from 'selectors/blocksTimeBoundsSelector';
import { getActiveVersion } from 'data/block';

const propTypes = {
  boardId: PropTypes.number.isRequired,
  maximisedBlockId: PropTypes.string,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  maximisedBlockId: undefined,
};

const BoardTimeSlider = ({ boardId, maximisedBlockId, workspaceId }) => {
  const dispatch = useDispatch();
  const scenarioId = useScenarioId();
  const blockStates = useSelectorWithProps(
    pipe(workspaceSelector, get('blocks')),
    { workspaceId },
  );
  const blockValues = useSelector(get('blockValues'));
  const blockAggregationFilters = useSelectorWithProps(
    pipe(workspaceSelector, get(['aggregationFilters', 'blocks'])),
    { workspaceId },
  );

  const selectedBlockIdsWithTime = useSelectorWithProps(
    selectedBlockIdsWithTimeSelector,
    {
      blockId: maximisedBlockId,
      boardId,
      scenarioId,
      workspaceId,
    },
  );

  const getTimeOfBlock = useCallback(
    (blockId) => get([blockId, 'year'], blockAggregationFilters),
    [blockAggregationFilters],
  );

  const relevantBlocks = useMemo(
    () => reject((b) => isNil(getTimeOfBlock(b)), selectedBlockIdsWithTime),
    [getTimeOfBlock, selectedBlockIdsWithTime],
  );
  const times = useMemo(
    () => pipe(map(getTimeOfBlock), uniq, sortBy(identity))(relevantBlocks),
    [getTimeOfBlock, relevantBlocks],
  );
  const blockTimes = useMemo(
    () =>
      map((blockId) => ({ blockId, time: getTimeOfBlock(blockId) }))(
        relevantBlocks,
      ),
    [getTimeOfBlock, relevantBlocks],
  );
  const blockLabels = useMemo(
    () =>
      map((time) => {
        const arr = filter({ time }, blockTimes);
        if (arr.length > 1) return `${arr.length} visualisations`;

        const activeVersion = pipe(
          get(arr[0].blockId),
          getActiveVersion,
        )(blockStates);
        const z = get([activeVersion, 'title'], blockValues);
        return z;
      })(times),
    [times, blockTimes, blockStates, blockValues],
  );

  const setMultipleBlockYear = useCallback(
    (value) => {
      if (!isNil(value)) {
        dispatch(
          aggregationFiltersActions.setMultipleBlockYear({
            blockIds: relevantBlocks,
            year: value,
          }),
        );
      }
    },
    [dispatch, relevantBlocks],
  );

  const [innerMin, innerMax] = useSelectorWithProps(
    selectedBlocksTimeBoundsSelector,
    {
      blockIds: maximisedBlockId ? [maximisedBlockId] : undefined,
      boardId,
      scenarioId,
      workspaceId,
    },
  );

  const [outerMin, outerMax] = useSelectorWithProps(blocksTimeBoundsSelector, {
    blockIds: maximisedBlockId ? [maximisedBlockId] : undefined,
    boardId,
    scenarioId,
    workspaceId,
  });

  const [[localMin, localMax], setLocalBounds] = useState([outerMin, outerMax]);
  useEffect(() => {
    if (outerMax && outerMin) {
      setLocalBounds([outerMin, outerMax]);
    }
  }, [outerMin, outerMax]);

  const resetYear = useCallback(() => {
    setMultipleBlockYear(outerMax);

    // reset bounds
    if (localMax < outerMax) {
      setLocalBounds([outerMin, outerMax]);
    }
  }, [localMax, outerMax, outerMin, setMultipleBlockYear]);

  const changeBounds = useCallback(
    (newBounds) => {
      const [newMin, newMax] = newBounds;

      if (min(blockTimes) < newMin) {
        setMultipleBlockYear(newMin);
      } else if (max(blockTimes) > newMax) {
        setMultipleBlockYear(newMax);
      }

      setLocalBounds(newBounds);
    },
    [blockTimes, setMultipleBlockYear],
  );

  return (
    <ContinuousTimeSlider
      boundsDefaultMax={outerMax}
      boundsDefaultMin={outerMin}
      boundsInnerMax={innerMax}
      boundsInnerMin={innerMin}
      boundsMax={localMax}
      boundsMin={localMin}
      defaultYear={outerMax}
      labels={blockLabels}
      onChangeBounds={changeBounds}
      onChangeYear={setMultipleBlockYear}
      onResetYear={resetYear}
      showStripedTracks
      years={times}
    />
  );
};

BoardTimeSlider.propTypes = propTypes;
BoardTimeSlider.defaultProps = defaultProps;

export { BoardTimeSlider };
