import { createSelector } from 'reselect';
import { get, pipe, size } from 'lodash/fp';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';

const yearSelector = makeAggregationFilterSelector('year');

const paneYearSelector = createSelector(
  pipe(workspaceSelector, get(['preview', 'year'])),
  yearSelector,
  blockSelectionsSelector,
  (previewYear, blockYear, selectedBlockIds) =>
    size(selectedBlockIds) === 1 ? blockYear : previewYear,
);

export default paneYearSelector;
