import { createSelector } from 'reselect';
import { get, map, pipe, zipWith } from 'lodash/fp';

import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import locationsSelector from 'selectors/locationsSelector';
import { bubble as chartOpacities } from 'utils/charts/opacities';
import { formatDotTooltip } from 'utils/charts/tooltips';
import { pickQualitative, opacify, soften } from 'utils/colors';
import { qualitativeColors } from 'settings/colors';
import capsuleDataSelector from 'selectors/capsuleDataSelector';

const bubbleChartCapsuleSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  pipe(blockValueSelector, get('chart')),
  locationsSelector,
  capsuleDataSelector,
  (
    schema,
    chart,
    locations,
    { bounds: { x: xBounds, y: yBounds }, data: columnCapsuleData },
  ) => {
    const { x, y, size } = chart;
    const xLabel = get([x.column, 'label'], schema);
    const yLabel = get([y.column, 'label'], schema);
    const sizeLabel = get([size.column, 'label'], schema);
    const colors = pipe(
      (d) => pickQualitative(qualitativeColors, d.length),
      map((col) => opacify(col, chartOpacities.normal)),
    )(columnCapsuleData);

    const chartDataWithColours = zipWith(
      (color, data) => ({
        ...data,
        keys: ['x', 'y', 'z'],
        xLabel,
        yLabel,
        sizeLabel,
        marker: {
          fillOpacity: 1,
          fillColor: color,
          lineWidth: 0,
          states: {
            hover: {
              fillColor: color,
              lineColor: soften(color),
              lineWidth: 2,
            },
            select: {
              fillColor: color,
              lineWidth: 0,
            },
          },
        },
      }),
      colors,
      columnCapsuleData,
    );

    const options = {
      chart: { type: 'bubble' },
      yAxis: { title: { text: y.title }, endOnTick: true, ...yBounds },
      xAxis: { title: { text: x.title }, endOnTick: true, ...xBounds },
      tooltip: {
        shared: true,
        formatter() {
          return formatDotTooltip(this);
        },
      },
      series: chartDataWithColours,
    };

    return {
      options,
      xLabel,
      yLabel,
      sizeLabel,
      series: chartDataWithColours,
    };
  },
);

export default bubbleChartCapsuleSelector;
