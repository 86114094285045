import { BackButton, Breadcrumb } from 'components/app-header';
import Layout from 'components/layout';
import { LoadableContent } from 'components/loadable-content';

const AppCreate = () => (
  <>
    <BackButton />
    <Breadcrumb to='/' root>
      Apps
    </Breadcrumb>
    <Breadcrumb to='/'>New app</Breadcrumb>
    <LoadableContent loading>
      <Layout direction='row' />
    </LoadableContent>
  </>
);

export { AppCreate };
