import styled from 'styled-components';

const TableRowImpl = styled('div')(
  ({ theme }) => ({
    alignItems: 'stretch',
    backgroundColor: 'white',
    borderColor: theme.borderColor,
    borderStyle: 'solid',
    borderWidth: 0,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    transform: 'translateZ(0)',
  }),
  ({ rowHeight }) => ({ height: rowHeight }),
  ({ stickToX }) =>
    stickToX !== undefined
      ? {
          position: 'sticky',
          top: stickToX,
          zIndex: 20,
        }
      : undefined,
  ({ showBottomBorder }) =>
    showBottomBorder
      ? {
          '&:last-child': {
            borderBottomWidth: 1,
          },
        }
      : undefined,
  ({ head }) =>
    head
      ? {
          backgroundColor: '#fafafa',
          borderBottomWidth: 1,
          zIndex: 30,
        }
      : undefined,
);

export { TableRowImpl };
