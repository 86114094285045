import { createSelector } from 'reselect';
import { get, map, pick, pipe, values } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const organisationsSelector = createSelector(
  get('organisations'),
  get(['auth', 'memberships']),
  pipe(workspaceSelector, get(['data', 'organisationId'])),
  (organisations, memberships, organisationId) => {
    const idList = [organisationId, ...map('organisation_id', memberships)];

    return values(pick(idList, organisations));
  },
);

export default organisationsSelector;
