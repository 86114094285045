import PropTypes from 'prop-types';
import { Placeholder } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';

const propTypes = {
  children: PropTypes.node,
  isEmpty: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

const defaultProps = {
  children: undefined,
  isEmpty: true,
};

const PlaceholderMessage = ({ children, isEmpty, text }) =>
  isEmpty ? (
    <Content alignX='center' alignY='center' height='100%' padding='none'>
      <Placeholder>{text}</Placeholder>
    </Content>
  ) : (
    children
  );

PlaceholderMessage.propTypes = propTypes;
PlaceholderMessage.defaultProps = defaultProps;

export { PlaceholderMessage };
