import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 16px;
`;

const Main = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 0',
    height: '100%',
  },
  ({ maxHeight }) => ({ maxHeight }),
  ({ maxWidth }) => ({ maxWidth }),
);

const XAxis = styled.div`
  cursor: default;
  text-align: center;
  flex: 0 0 fit-content;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// NOTE: These margins are hardcoded based on making this fit relatively center
// with the empty box wireframe. There is more padding at the bottom as we
// assume the height of the x axis label is never more than 20px.
const YAxis = styled.p`
  text-align: center;
  flex: 1 1 auto;
  padding-top: 28px;
  margin-bottom: 0;
  writing-mode: vertical-rl;
  transform: scale(-1, -1);
`;

const Wireframe = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flex: '1 1 auto',
  },
  ({ borderColor }) =>
    borderColor ? { border: `1px solid ${borderColor}` } : {},
);

const WireframeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-left: 8px;
`;

export { Container, XAxis, YAxis, Wireframe, Main, WireframeContainer };
