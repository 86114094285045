import { useCallback } from 'react';
import {
  Content,
  Inline,
  InlineItem,
  Tooltip,
  UtilityButton,
} from '@kinesis/bungle';
import { blockAdd } from 'actions/blockAdd';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';

const propTypes = {
  boardId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const AddBlockMenu = ({ boardId, workspaceId }) => {
  const handleBlockAdd = useAction(blockAdd);

  const onAddVisualisation = useCallback(() => {
    handleBlockAdd({
      type: 'visualisation',
      workspaceId,
      boardId,
    });
  }, [boardId, handleBlockAdd, workspaceId]);

  const onAddText = useCallback(() => {
    handleBlockAdd({ type: 'text', workspaceId, boardId });
  }, [boardId, handleBlockAdd, workspaceId]);

  return (
    <Content border padding='xsmall' paddingMode='equal' sizing='fit-content'>
      <Inline space='xsmall'>
        <InlineItem>
          <UtilityButton
            icon='bulb'
            magnitude='small'
            onClick={onAddVisualisation}
          >
            New visualisation
          </UtilityButton>
        </InlineItem>
        <InlineItem>
          <Tooltip placement='top' title='New text block (t)'>
            <UtilityButton
              icon='list-view'
              data-testid='addText'
              magnitude='small'
              onClick={onAddText}
            />
          </Tooltip>
        </InlineItem>
      </Inline>
    </Content>
  );
};

AddBlockMenu.propTypes = propTypes;
AddBlockMenu.defaultProps = defaultProps;

export { AddBlockMenu };
