import { createSelector } from 'reselect';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import blockIdsWithTimeSelector from 'selectors/blockIdsWithTimeSelector';
import { filter, isEmpty } from 'lodash/fp';

const selectedBlockIdsWithTimeSelector = createSelector(
  blockIdsWithTimeSelector,
  blockSelectionsSelector,
  (blockIds, selectedBlockIds) =>
    filter(
      (blockId) =>
        isEmpty(selectedBlockIds) || selectedBlockIds.includes(blockId),
      blockIds,
    ),
);

export default selectedBlockIdsWithTimeSelector;
