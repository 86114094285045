import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isNil, pipe } from 'lodash/fp';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import boundsSelector from 'selectors/boundsSelector';
import colorFormatSelector from 'selectors/colorFormatSelector';
import colorStopsSelector from 'selectors/colorStopsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import dataVizSelector from 'selectors/dataVizSelector';
import layersSelector from 'selectors/layersSelector';
import filteredScenarioLocationsSelector from 'selectors/filteredScenarioLocationsSelector';
import makePublicTokenSelector from 'selectors/makePublicTokenSelector';
import sizeMaxSelector from 'selectors/sizeMaxSelector';
import sizeMinSelector from 'selectors/sizeMinSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import { getH3Bounds } from 'utils/boundsUtils';
import Units from 'data/units';
import useActions from 'hooks/useActions';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import {
  toolboxLocationIdSelector,
  toolboxSpatialDetailIdSelector,
} from 'selectors/toolboxDetailSelectors';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import SpatialVisualisation from './SpatialVisualisation';

const mapboxAccessTokenSelector = makePublicTokenSelector('mapboxAccessToken');

const units = new Units();

const isShiftKey = get(['srcEvent', 'shiftKey']);

const propTypes = {
  allowScrollZoom: PropTypes.bool,
  blockId: PropTypes.string.isRequired,
  boardId: PropTypes.number.isRequired,
  scenarioId: PropTypes.number.isRequired,
  viewMode: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  allowScrollZoom: undefined,
};

const PrivateSpatialVisualisation = ({
  allowScrollZoom,
  blockId,
  boardId,
  scenarioId,
  viewMode,
  workspaceId,
}) => {
  const bounds = useSelectorWithProps(boundsSelector, {
    scenarioId,
    workspaceId,
  });
  const colorFormat = useSelectorWithProps(colorFormatSelector, {
    blockId,
    workspaceId,
  });
  const colorStops = useSelectorWithProps(colorStopsSelector, {
    blockId,
    boardId,
    scenarioId,
    workspaceId,
  });
  const data = useSelectorWithProps(dataVizSelector, {
    blockId,
    boardId,
    scenarioId,
    workspaceId,
  });
  const layers = useSelectorWithProps(layersSelector, { workspaceId });
  const locations = useSelectorWithProps(filteredScenarioLocationsSelector, {
    scenarioId,
    workspaceId,
  });
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);
  const selectedBlockIds = useSelector(blockSelectionsSelector);
  const sizeMax = useSelectorWithProps(sizeMaxSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const sizeMin = useSelectorWithProps(sizeMinSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const visualisationLayerEnabled = useSelectorWithProps(
    pipe(workspaceSelector, get(['layers', 'visualisationLayerEnabled'])),
    { workspaceId },
  );
  const blockValueSpatial = useSelectorWithProps(
    pipe(blockValueSelector, get('spatial')),
    { blockId, workspaceId },
  );
  const schema = useSelectorWithProps(pipe(blockStateSelector, get('schema')), {
    blockId,
    workspaceId,
  });
  const hexBounds = useMemo(
    () =>
      isNil(get('hex', blockValueSpatial)) ? undefined : getH3Bounds(data),
    [blockValueSpatial, data],
  );
  const instantiations = useSelectorWithProps(workspaceInstantiationsSelector, {
    workspaceId,
  });

  const colorKey = get(['colour', 'column'], blockValueSpatial);
  const sizeKey = get(['size', 'column'], blockValueSpatial);

  const toolboxLocationId = useSelector(toolboxLocationIdSelector);
  const toolboxSpatialDetailId = useSelector(toolboxSpatialDetailIdSelector);
  const { select, removePane } = useActions(toolboxActions);
  const { selectBlock, toggleBlock } = useActions(blockSelectionsActions);

  const multiSelectCurrentBlock = useCallback(() => {
    toggleBlock(blockId);
  }, [blockId, toggleBlock]);

  const selectCurrentBlock = useCallback(() => {
    selectBlock(blockId);
  }, [blockId, selectBlock]);

  const selected = useMemo(
    () => selectedBlockIds.includes(blockId),
    [selectedBlockIds, blockId],
  );

  // Drag on a map. If map block is already selected, do nothing. If not already selected, select the block.
  const onDragStart = useCallback(
    (_info, deckEvent) => {
      if (!selected) {
        const selectFn = isShiftKey(deckEvent)
          ? multiSelectCurrentBlock
          : selectCurrentBlock;
        selectFn();
      }
    },
    [selectCurrentBlock, multiSelectCurrentBlock, selected],
  );

  const onDetailSelect = useCallback(
    (spatialDetailId, deckEvent) => {
      if (isShiftKey(deckEvent)) {
        multiSelectCurrentBlock();
      } else {
        selectCurrentBlock();
        select({
          pane: 'detail',
          selection: { id: spatialDetailId, type: 'spatial-detail' },
          label: 'details',
        });
      }
    },
    [selectCurrentBlock, multiSelectCurrentBlock, select],
  );

  const onLocationSelect = useCallback(
    (locationId, deckEvent) => {
      if (isShiftKey(deckEvent)) {
        multiSelectCurrentBlock();
      } else {
        selectCurrentBlock();
        select({
          pane: 'detail',
          selection: { id: locationId, type: 'location' },
        });
      }
    },
    [selectCurrentBlock, multiSelectCurrentBlock, select],
  );

  // Click the map background (not a location etc.)
  const onReset = useCallback(
    (_info, deckEvent) => {
      if (isShiftKey(deckEvent)) {
        multiSelectCurrentBlock();
        removePane('detail');
      } else {
        selectCurrentBlock();
      }
    },
    [multiSelectCurrentBlock, selectCurrentBlock, removePane],
  );

  const selectedLocationId =
    selectedBlockIds.length === 1 && blockId === selectedBlockIds[0]
      ? toolboxLocationId
      : undefined;
  const selectedSpatialDetailId =
    selectedBlockIds.length === 1 && blockId === selectedBlockIds[0]
      ? toolboxSpatialDetailId
      : undefined;
  const sizeFormat = useMemo(
    () =>
      sizeKey && schema[sizeKey]
        ? units.parseColumn(schema[sizeKey], locations, instantiations).format
        : undefined,
    [locations, instantiations, sizeKey, schema],
  );

  return (
    <SpatialVisualisation
      data-testId='spatial-visualisation'
      allowScrollZoom={allowScrollZoom}
      bounds={hexBounds || bounds}
      colorFormat={colorFormat}
      colorHeading={get([colorKey, 'label'], schema)}
      colorStops={colorStops}
      data={data}
      interactive
      layers={layers}
      locations={locations}
      mapboxAccessToken={mapboxAccessToken}
      onDragStart={onDragStart}
      onDetailSelect={onDetailSelect}
      onLocationSelect={onLocationSelect}
      onReset={onReset}
      selectedLocationId={selectedLocationId}
      selectedSpatialDetailId={selectedSpatialDetailId}
      showFixedOverlays={viewMode === 'maximised'}
      sizeFormat={sizeFormat}
      sizeHeading={get([sizeKey, 'label'], schema)}
      sizeMax={sizeMax}
      sizeMin={sizeMin}
      viewMode={viewMode}
      visualisationLayerEnabled={visualisationLayerEnabled}
      visualisationType={get('type', blockValueSpatial)}
      workspaceId={workspaceId}
    />
  );
};

PrivateSpatialVisualisation.propTypes = propTypes;
PrivateSpatialVisualisation.defaultProps = defaultProps;

export default PrivateSpatialVisualisation;
