import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Legend } from '@kinesis/bungle';

const propTypes = {
  disablePointerEvents: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  colorColumn: PropTypes.string.isRequired,
  keyColumn: PropTypes.string.isRequired,
  title: PropTypes.string,
  valueColumn: PropTypes.string.isRequired,
};

const defaultProps = {
  data: [],
  disablePointerEvents: false,
  title: undefined,
};

const PopoverContent = ({
  disablePointerEvents,
  colorColumn,
  data,
  keyColumn,
  title,
  valueColumn,
}) => {
  const legendItems = useMemo(
    () =>
      data.map((pair) => {
        const legendItem = {
          label: pair[keyColumn],
          value: pair[valueColumn],
        };

        if (pair[colorColumn]) {
          legendItem.color = pair[colorColumn];
        } else {
          legendItem.size = 'medium';
        }
        return legendItem;
      }),
    [colorColumn, data, keyColumn, valueColumn],
  );

  return (
    <Legend
      heading={title}
      items={legendItems}
      icon='circle'
      style={{
        pointerEvents: disablePointerEvents ? 'none' : 'auto',
        marginBottom: '-4px',
      }}
    />
  );
};

PopoverContent.propTypes = propTypes;
PopoverContent.defaultProps = defaultProps;

export default PopoverContent;
