import { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  Icon,
} from '@kinesis/bungle';
import { always, filter, find, get, isNil, pipe, size, sumBy } from 'lodash/fp';
import { TableButton, TableCell, TableRow, TableText } from 'components/table';
import { DataEntry } from 'components/data-entry';
import { geographyOf } from 'data/geography';
import currentAttributesSelector from 'selectors/currentAttributesSelector';
import workspaceGeographiesSelector from 'selectors/workspaceGeographiesSelector';
import { locationRename as locationRenameAction } from 'actions/locationRename';
import { actions as locationsActions } from 'reducers/locationsReducer';
import {
  formatCoordinates,
  formatLandArea,
  formatSolarCapacity,
  formatUsages,
  formatWater,
} from 'utils/formatUtils';
import { ifElse } from 'utils/functionalUtils';
import {
  GEOGRAPHY,
  LAND,
  RAINWATER,
  RECYCLED_WATER,
  SERVICE,
  SOLAR,
  USAGE,
} from 'constants/attributeTypes';
import useAction from 'hooks/useAction';
import useActions from 'hooks/useActions';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import attributeEntitiesSelector from 'selectors/attributeEntitiesSelector';
import locationSelector from 'selectors/locationSelector';
import LocationServiceLabel from './location-service-label';
import LocationsTableNoValues from './locations-table-no-values';

const sumByAmount = ifElse(
  size,
  sumBy(get(['values', 'amount'])),
  always(undefined),
);

const propTypes = {
  activeDate: PropTypes.string.isRequired,
  attributeKeys: PropTypes.arrayOf(PropTypes.string),
  editor: PropTypes.bool,
  id: PropTypes.number.isRequired,
  layerId: PropTypes.number.isRequired,
  onAttributeEdit: PropTypes.func.isRequired,
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  attributeKeys: [],
  editor: false,
};

const LocationsTableRow = ({
  activeDate,
  attributeKeys,
  editor,
  id,
  layerId,
  onAttributeEdit,
  scenarioId,
  workspaceId,
  ...restProps
}) => {
  const attributeValues = useSelectorWithProps(
    pipe(currentAttributesSelector, get('values')),
    { scenarioId, workspaceId },
  );
  const geographies = useSelectorWithProps(workspaceGeographiesSelector, {
    workspaceId,
  });
  const attributeEntities = useSelectorWithProps(attributeEntitiesSelector, {
    date: activeDate,
    locationId: id,
    scenarioId,
    workspaceId,
  });
  const { label } = useSelectorWithProps(locationSelector, {
    locationId: id,
    workspaceId,
  });
  const locationRename = useAction(locationRenameAction);
  const { removeLocation } = useActions(locationsActions);

  const geography = geographyOf(id, attributeValues, geographies);
  const landArea = get(
    ['values', 'area'],
    find({ definition: LAND }, attributeEntities),
  );
  const rainwaterStorage = sumByAmount(
    filter({ definition: RAINWATER }, attributeEntities),
  );
  const recycledWaterStorage = sumByAmount(
    filter({ definition: RECYCLED_WATER }, attributeEntities),
  );
  const services = filter({ definition: SERVICE }, attributeEntities);
  const solarCapacity = get(
    ['values', 'peak-capacity'],
    find({ definition: SOLAR }, attributeEntities),
  );
  const usages = filter({ definition: USAGE }, attributeEntities);

  const handleRemove = useCallback(() => {
    removeLocation({ locationId: id, scenarioId, workspaceId });
  }, [id, removeLocation, scenarioId, workspaceId]);

  const handleRename = useCallback(
    (value) => {
      locationRename({
        locationId: id,
        label: value,
        layer: layerId,
        workspaceId,
      });
    },
    [id, layerId, locationRename, workspaceId],
  );

  return (
    <TableRow {...restProps}>
      <TableCell appearance='dark'>
        <TableText>{id}</TableText>
      </TableCell>
      <TableCell>
        <DataEntry
          disabled={!editor}
          value={label || 'New location'}
          onChange={handleRename}
        />
      </TableCell>
      {attributeKeys.includes(GEOGRAPHY) && (
        <TableCell>
          <TableButton
            disabled={!editor}
            onClick={() => onAttributeEdit(GEOGRAPHY, id)}
          >
            <LocationsTableNoValues showPlaceholder={isNil(geography)}>
              {geography && formatCoordinates(geography)}
            </LocationsTableNoValues>
          </TableButton>
        </TableCell>
      )}
      {attributeKeys.includes(LAND) && (
        <TableCell align='right'>
          <TableButton
            disabled={!editor}
            onClick={() => onAttributeEdit(LAND, id)}
          >
            <LocationsTableNoValues showPlaceholder={isNil(landArea)}>
              {formatLandArea(landArea)}
            </LocationsTableNoValues>
          </TableButton>
        </TableCell>
      )}
      {attributeKeys.includes(USAGE) && (
        <TableCell align='right'>
          <TableButton
            disabled={!editor}
            onClick={() => onAttributeEdit(USAGE, id)}
          >
            <LocationsTableNoValues showPlaceholder={usages.length === 0}>
              {formatUsages(usages)}
            </LocationsTableNoValues>
          </TableButton>
        </TableCell>
      )}
      {attributeKeys.includes(SERVICE) && (
        <TableCell align='right'>
          <TableButton
            disabled={!editor}
            onClick={() => onAttributeEdit(SERVICE, id)}
          >
            <LocationsTableNoValues showPlaceholder={services.length === 0}>
              {services.length > 1 ? (
                <em>Multiple values</em>
              ) : (
                <LocationServiceLabel
                  amount={get(['values', 'amount'], services[0])}
                  scenarioId={scenarioId}
                  serviceKey={get('entry', services[0])}
                  workspaceId={workspaceId}
                />
              )}
            </LocationsTableNoValues>
          </TableButton>
        </TableCell>
      )}
      {attributeKeys.includes(SOLAR) && (
        <TableCell align='right'>
          <TableButton
            disabled={!editor}
            onClick={() => onAttributeEdit(SOLAR, id)}
          >
            <LocationsTableNoValues showPlaceholder={isNil(solarCapacity)}>
              {formatSolarCapacity(solarCapacity)}
            </LocationsTableNoValues>
          </TableButton>
        </TableCell>
      )}
      {attributeKeys.includes(RAINWATER) && (
        <TableCell align='right'>
          <TableButton
            disabled={!editor}
            onClick={() => onAttributeEdit(RAINWATER, id)}
          >
            <LocationsTableNoValues showPlaceholder={isNil(rainwaterStorage)}>
              {formatWater(rainwaterStorage)}
            </LocationsTableNoValues>
          </TableButton>
        </TableCell>
      )}
      {attributeKeys.includes(RECYCLED_WATER) && (
        <TableCell align='right'>
          <TableButton
            disabled={!editor}
            onClick={() => onAttributeEdit(RECYCLED_WATER, id)}
          >
            <LocationsTableNoValues
              showPlaceholder={isNil(recycledWaterStorage)}
            >
              {formatWater(recycledWaterStorage)}
            </LocationsTableNoValues>
          </TableButton>
        </TableCell>
      )}
      <TableCell align='center'>
        <DropdownMenu justify='end'>
          <TableButton disabled={!editor} as={DropdownMenuButton}>
            <Icon magnitude='medium' type='ellipsis' />
          </TableButton>
          <DropdownMenuList>
            <DropdownMenuItem onSelect={handleRemove}>
              Remove from scenario
            </DropdownMenuItem>
          </DropdownMenuList>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
};

LocationsTableRow.propTypes = propTypes;
LocationsTableRow.defaultProps = defaultProps;

export default LocationsTableRow;
