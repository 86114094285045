function numberOfOperands(operator) {
  switch (operator) {
    case 'eq':
    case 'ne':
    case 'gt':
    case 'gte':
    case 'lt':
    case 'lte':
      return 1;

    case 'be':
    case 'nb':
      return 2;
    default:
  }

  return 0;
}

// eslint-disable-next-line import/prefer-default-export
export { numberOfOperands };
