import { createSelector } from 'reselect';
import { defaultTo, curry, filter, get, identity, map, pipe } from 'lodash/fp';
import activeScenarioCapsuleDataSelector from 'selectors/activeScenarioCapsuleDataSelector';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import locationsSelector from 'selectors/locationsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import Units from 'data/units';
import { calculateSimpleBounds } from 'utils/charts/series';

const units = new Units();
const yearSelector = makeAggregationFilterSelector('year');
const immutableSort = curry((f, arr) => arr.slice().sort(f));

const defaultData = [];

const columnCapsuleDataSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  pipe(blockValueSelector, get('chart')),
  locationsSelector,
  yearSelector,
  workspaceInstantiationsSelector,
  pipe(activeScenarioCapsuleDataSelector, defaultTo(defaultData)),
  (schema, chart, locations, year, instantiations, data) => {
    const { label, x, y, size, time } = chart;
    const timeColumn = get('column', time);

    const xunit = units.parseColumn(
      schema[x.column],
      locations,
      instantiations,
    );
    const yunit = units.parseColumn(
      schema[y.column],
      locations,
      instantiations,
    );
    const sizeunit = size
      ? units.parseColumn(schema[size.column], locations, instantiations)
      : undefined;

    const labelUnit = label
      ? units.parseColumn(schema[label.column], locations, instantiations)
      : undefined;

    return {
      bounds: {
        x: calculateSimpleBounds(data, x.column),
        y: calculateSimpleBounds(data, y.column),
      },
      data: [
        {
          name: size ? get([size.column, 'label'], schema) : undefined,
          data: pipe(
            timeColumn && year ? filter({ [timeColumn]: year }) : identity,
            map((row) => ({
              x: row[x.column],
              y: row[y.column],
              z: row[get('column', size)],
              name: row[get('column', label)],
              label: labelUnit && labelUnit.format(row[get('column', label)]),
              dataId: row.dataId,
            })),
            immutableSort((a, b) => b.z - a.z),
          )(data),
          unitFormatter: {
            x: xunit.format,
            y: yunit.format,
            z: get('format', sizeunit),
          },
        },
      ],
    };
  },
);

export default columnCapsuleDataSelector;
