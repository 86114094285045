import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Tooltip, UtilityButton, Icon } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import useTheme from 'hooks/useTheme';
import {
  ButtonContainer,
  IconContainer,
  deleteIconColorOf,
} from './visualisation-series-select.styles';

const propTypes = {
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: noop,
};

const DeleteSeriesButton = ({ onClick }) => {
  const theme = useTheme();
  const deleteIconColor = useMemo(() => deleteIconColorOf(theme), [theme]);
  return (
    <Tooltip placement='top' justify='end' title='Remove series'>
      <ButtonContainer>
        <UtilityButton onClick={onClick}>
          <IconContainer>
            <Icon type='delete' color={deleteIconColor} magnitude='small' />
          </IconContainer>
        </UtilityButton>
      </ButtonContainer>
    </Tooltip>
  );
};

DeleteSeriesButton.propTypes = propTypes;
DeleteSeriesButton.defaultProps = defaultProps;

export { DeleteSeriesButton };
