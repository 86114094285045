import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { first, max, min, size } from 'lodash/fp';
import {
  AnimatedPopover,
  Button,
  ContinuousSlider,
  Dialog,
  Input,
  NumberInput,
  Tooltip,
  UtilityButton,
  useOnClickOutside,
} from '@kinesis/bungle';
import { Toolbar, ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import {
  SliderOverlay,
  SliderStripedTrack,
  SliderStripedTrackWrapper,
  TimeSliderInput,
  TimeSliderTimeline,
  TimeSliderTimelineEntries,
  TimeSliderTimelineEntry,
  TimeSliderTimelineFinal,
  TimeSliderTimelineText,
  TimeSliderWrapper,
} from './time-slider.styles';

const propTypes = {
  boundsDefaultMax: PropTypes.number.isRequired,
  boundsDefaultMin: PropTypes.number.isRequired,
  boundsInnerMax: PropTypes.number,
  boundsInnerMin: PropTypes.number,
  boundsMax: PropTypes.number.isRequired,
  boundsMin: PropTypes.number.isRequired,
  defaultYear: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  onChangeBounds: PropTypes.func,
  onChangeYear: PropTypes.func.isRequired,
  onResetYear: PropTypes.func,
  showStripedTracks: PropTypes.bool,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const defaultProps = {
  boundsInnerMax: undefined,
  boundsInnerMin: undefined,
  defaultYear: undefined,
  labels: [],
  onChangeBounds: undefined,
  onResetYear: undefined,
  showStripedTracks: false,
};

const ContinuousTimeSlider = ({
  boundsDefaultMax,
  boundsDefaultMin,
  boundsInnerMax,
  boundsInnerMin,
  boundsMax,
  boundsMin,
  defaultYear,
  labels,
  onChangeBounds,
  onChangeYear,
  onResetYear,
  showStripedTracks,
  years,
}) => {
  const timelineButtonRef = useRef();
  const timelinePopoverRef = useRef();

  const [isTimelineOpen, setTimelineOpen] = useState(false);
  const [inputMin, setInputMin] = useState(boundsMin);
  const [inputMax, setInputMax] = useState(boundsMax);

  const handleResetYear = useCallback(() => {
    if (onResetYear) onResetYear();
    setTimelineOpen(false);
  }, [onResetYear]);

  const toggleTimeline = useCallback(() => {
    setInputMin(boundsMin);
    setInputMax(boundsMax);
    setTimelineOpen((state) => !state);
  }, [boundsMin, boundsMax]);

  const handleSubmitBounds = useCallback(() => {
    onChangeBounds([inputMin, inputMax]);
  }, [inputMin, inputMax, onChangeBounds]);

  const handleResetBounds = useCallback(() => {
    onChangeBounds([boundsDefaultMin, boundsDefaultMax]);
  }, [boundsDefaultMax, boundsDefaultMin, onChangeBounds]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        setInputMin(boundsMin);
        setInputMax(boundsMax);
        setTimelineOpen(false);
      } else if (event.key === 'Enter') {
        onChangeBounds([inputMin, inputMax]);
      }
    },
    [boundsMax, boundsMin, inputMax, inputMin, onChangeBounds],
  );

  const onWrapperClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const onSliderChange = useCallback(
    ([sliderValue]) => onChangeYear(sliderValue),
    [onChangeYear],
  );

  const isDefaultBounds = useMemo(
    () => boundsMin === boundsDefaultMin && boundsMax === boundsDefaultMax,
    [boundsDefaultMax, boundsDefaultMin, boundsMax, boundsMin],
  );

  const stripedTracks = useMemo(() => {
    if (!showStripedTracks) {
      return [];
    }

    if (boundsInnerMin === undefined && boundsInnerMax === undefined) {
      return [
        {
          key: 'full',
          left: '0%',
          width: '100%',
        },
      ];
    }

    const stripes = [];
    const denominator = (boundsMax - boundsMin) * 2;

    if (boundsMin < boundsInnerMin) {
      const diff = (boundsInnerMin - boundsMin) * 2 - 1;
      const width = (diff / denominator) * 100;
      stripes.push({
        key: `left-${width}`,
        left: '0%',
        width: `${width}%`,
      });
    }
    if (boundsMax > boundsInnerMax) {
      const diff = (boundsMax - boundsInnerMax) * 2 - 1;
      const width = (diff / denominator) * 100;
      stripes.push({
        key: `right-${width}`,
        left: `${100 - width}%`,
        width: `${width}%`,
      });
    }
    return stripes;
  }, [boundsMin, boundsMax, boundsInnerMax, boundsInnerMin, showStripedTracks]);

  useOnClickOutside(
    [timelineButtonRef, timelinePopoverRef],
    useCallback(() => {
      setInputMin(boundsMin);
      setInputMax(boundsMax);
      setTimelineOpen(false);
    }, [boundsMax, boundsMin]),
  );

  useEffect(() => {
    setInputMin(boundsMin);
    setInputMax(boundsMax);
    setTimelineOpen(false);
  }, [boundsMax, boundsMin]);

  useEffect(() => {
    if (min(years) < boundsMin) {
      onChangeYear(boundsMin);
    } else if (max(years) > boundsMax) {
      onChangeYear(boundsMax);
    }
  }, [boundsMax, boundsMin, onChangeYear, years]);

  return (
    <TimeSliderWrapper
      data-testid='continuous-time-slider'
      onClick={onWrapperClick}
    >
      <Toolbar justify='space-between'>
        <ToolbarGroup expand>
          <ToolbarItem>
            <TimeSliderInput>
              {size(years) > 1 && <Input value='Multiple values' disabled />}
              {size(years) === 1 && (
                <NumberInput
                  max={boundsMax}
                  min={boundsMin}
                  onChange={onChangeYear}
                  value={years[0]}
                />
              )}
              {size(years) === 0 && <Input value='No data' disabled />}
            </TimeSliderInput>
          </ToolbarItem>
          <ToolbarItem flex={1} expand>
            <>
              <SliderOverlay>
                <SliderStripedTrackWrapper>
                  {stripedTracks.map(({ key, left, width }) => (
                    <SliderStripedTrack key={key} left={left} width={width} />
                  ))}
                </SliderStripedTrackWrapper>
              </SliderOverlay>
              <ContinuousSlider
                enableTooltips
                max={boundsMax}
                min={boundsMin}
                onChange={onSliderChange}
                singleValueMode
                valueLabels={labels}
                values={years}
              />
            </>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip
              disabled={!onResetYear}
              placement='top'
              title='Return to set value'
            >
              <UtilityButton
                disabled={
                  !onResetYear ||
                  boundsMin === boundsMax ||
                  (defaultYear && defaultYear === first(years))
                }
                icon='undo'
                magnitude='small'
                onClick={handleResetYear}
              />
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip placement='top' title='Constrain timeline'>
              <UtilityButton
                disabled={!onChangeBounds || boundsMin === boundsMax}
                expanded={isTimelineOpen}
                hasPopup
                icon='constrain-timeline'
                magnitude='small'
                onClick={toggleTimeline}
                ref={timelineButtonRef}
                variant={isDefaultBounds ? 'default' : 'accent'}
              />
            </Tooltip>
          </ToolbarItem>
          <AnimatedPopover
            justify='end'
            offset={4}
            open={isTimelineOpen}
            placement='top'
            targetRef={timelineButtonRef}
          >
            <Dialog
              appearance='neat'
              onKeyDown={handleKeyDown}
              ref={timelinePopoverRef}
            >
              <TimeSliderTimeline>
                <TimeSliderTimelineText>
                  Constrain the time period to make it easier to scrub values.
                </TimeSliderTimelineText>
                <TimeSliderTimelineEntries>
                  <TimeSliderTimelineEntry>
                    <NumberInput
                      label='Start year'
                      magnitude='large'
                      min={boundsDefaultMin}
                      max={inputMax - 1}
                      onChange={setInputMin}
                      value={inputMin}
                    />
                  </TimeSliderTimelineEntry>
                  <TimeSliderTimelineEntry>
                    <NumberInput
                      label='End year'
                      magnitude='large'
                      min={inputMin + 1}
                      max={boundsDefaultMax}
                      onChange={setInputMax}
                      value={inputMax}
                    />
                  </TimeSliderTimelineEntry>
                </TimeSliderTimelineEntries>
                <TimeSliderTimelineFinal>
                  <UtilityButton
                    disabled={isDefaultBounds}
                    onClick={handleResetBounds}
                    variant='danger'
                  >
                    Reset range
                  </UtilityButton>
                  <Button
                    disabled={inputMin === boundsMin && inputMax === boundsMax}
                    onClick={handleSubmitBounds}
                    variant='primary'
                  >
                    Apply range
                  </Button>
                </TimeSliderTimelineFinal>
              </TimeSliderTimeline>
            </Dialog>
          </AnimatedPopover>
        </ToolbarGroup>
      </Toolbar>
    </TimeSliderWrapper>
  );
};

ContinuousTimeSlider.propTypes = propTypes;
ContinuousTimeSlider.defaultProps = defaultProps;

export { ContinuousTimeSlider };
