import styled from 'styled-components';

const TableButtonImpl = styled('div').attrs({
  role: 'button',
  tabIndex: 0,
})(
  ({ disabled, theme }) => ({
    background: 'none',
    border: 0,
    boxSizing: 'border-box',
    color: disabled ? 'rgba(0, 0, 0, 0.25)' : 'inherit',
    cursor: disabled ? 'default' : 'pointer',
    fontFamily: theme.fontFamily.default,
    fontSize: theme.fontSize.default,
    fontWeight: theme.fontWeight.normal,
    height: '100%',
    lineHeight: theme.lineHeight.default,
    outline: 0,
    overflow: 'hidden',
    padding: '6px 12px',
    textAlign: 'inherit',
    textOverflow: 'ellipsis',
    transitionDuration: '0.3s',
    width: '100%',
    '&:hover': {
      backgroundColor: disabled ? 'none' : 'rgba(0, 0, 0, 0.05)',
    },
    '&:active': {
      backgroundColor: disabled ? 'none' : 'rgba(0, 0, 0, 0.1)',
    },
    '&:focus': {
      boxShadow: disabled
        ? 'none'
        : `
        0 0 0 2px rgba(0, 0, 0, 0.05),
        0 0 0 0 ${theme.color.gray4}
      `,
      zIndex: 2,
    },
  }),
  ({ columnIndex }) =>
    columnIndex === 0
      ? {
          paddingLeft: 16,
        }
      : undefined,
);

export { TableButtonImpl };
