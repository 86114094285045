import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const downloadCreate = createAsyncThunk(
  'download/create',
  async ({ workspace, scenario, resource }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(
      `/api/workspace/${workspace}/scenario/${scenario}/download`,
      { resource },
    );
    window.location = response.data.url;
    return response.data;
  },
);

export { downloadCreate };
