import { createSelector } from 'reselect';
import { pipe, flatMap, filter, uniq } from 'lodash/fp';
import loadedAppVersionsSelector from './loadedAppVersionsSelector';

const workspaceRequirementsSelector = createSelector(
  loadedAppVersionsSelector,
  pipe(
    flatMap('resources'),
    filter({ type: 'requirement' }),
    flatMap('attributes'),
    uniq,
  ),
);

export default workspaceRequirementsSelector;
