import { get } from 'lodash/fp';
import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import scenarioSelector from 'selectors/scenarioSelector';

const tagUpdate = createAsyncThunk(
  'tag/update',
  async (
    { actions, referent, scenarioId, workspaceId },
    { dispatch, getState },
  ) => {
    const state = getState();
    const scenario = scenarioSelector(state, { scenarioId, workspaceId });
    const api = createApiFromState({ dispatch, getState });

    if (!scenario || !workspaceId || !referent) {
      return;
    }

    const scenarioParent = get(['draft', 'referent'], scenario);

    const response = await api.post(`/api/workspace/${workspaceId}/tag`, {
      actions,
      scenario_parent: scenarioParent,
      tagChange: {
        referent,
        scenarioId: scenario.id,
        scenarioKey: referent,
      },
    });

    const { data } = response;

    return {
      attributes: data.attributes,
      history: data.history,
      referent,
      scenarioId: scenario.id,
      scenarioState: get('draft', scenario) ? 'draft' : 'published',
      tagName: data.tag,
    };
  },
);

export { tagUpdate };
