import { Toolbar } from '@kinesis/bungle/legacy';
import AppToolbarPortal from './AppToolbarPortal';
import BreadcrumbPortal from './BreadcrumbPortal';
import { AppHeaderWrapper } from './app-header.styles';

const AppHeader = () => (
  <AppHeaderWrapper>
    <Toolbar justify='end'>
      <BreadcrumbPortal />
      <AppToolbarPortal />
    </Toolbar>
  </AppHeaderWrapper>
);

export default AppHeader;
