import { Icon, Inline, InlineItem, Strong } from '@kinesis/bungle';
import PropTypes from 'prop-types';
import { interventionCategoryLabels as labels } from 'data/pathways';

const propTypes = {
  identifier: PropTypes.oneOf(['transport', 'waste', 'energy']).isRequired,
};
const defaultProps = {};

const icons = {
  energy: 'building-filled',
  transport: 'car-filled',
  waste: 'delete-filled',
};

const PathwaysInterventionCategoryHeader = ({ identifier }) => (
  <Inline space='small'>
    <InlineItem>
      <Icon type={icons[identifier]} color='rgba(0,0,0,0.45)' />
    </InlineItem>
    <InlineItem>
      <Strong>{labels[identifier]}</Strong>
    </InlineItem>
  </Inline>
);

PathwaysInterventionCategoryHeader.propTypes = propTypes;
PathwaysInterventionCategoryHeader.defaultProps = defaultProps;

export { PathwaysInterventionCategoryHeader };
