import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { isNil, isEmpty, defaultTo, pipe, filter, get, curry } from 'lodash/fp';
import Units from 'data/units';
import namedScenariosSelector from 'selectors/namedScenariosSelector';
import blockDataSelector from 'selectors/blockDataSelector';
import blockPerspectiveStateSelector from 'selectors/blockPerspectiveStateSelector';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import { Placeholder, Stack } from '@kinesis/bungle';
import { Loading } from 'components/loading';
import { Body, MaxWidth, Container, Table } from './pathway-input-block.styles';
import { Headers } from './pathway-input-block.headers';
import { Locations } from './pathway-input-block.locations';
import { InterventionAreas } from './pathway-input-block.intervention-areas';

const immutableSort = curry((f, arr) => arr.slice().sort(f));

const units = new Units();

const propTypes = {
  blockId: PropTypes.string.isRequired,
  viewMode: PropTypes.oneOf(['maximised', 'minimised', 'standalone'])
    .isRequired,
  workspaceId: PropTypes.number.isRequired,
};
const defaultProps = {};

const emptyArray = [];

const PathwayInputBlock = ({ blockId, viewMode, workspaceId }) => {
  const scenarioId = useScenarioId();
  const blockData = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const state = useSelectorWithProps(blockPerspectiveStateSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const namedScenarios = useSelectorWithProps(namedScenariosSelector, {
    workspaceId,
  });
  const loading = isNil(state);

  const scenarioUnit = useMemo(
    () =>
      units.parseColumn({ unit: { type: 'scenario' } }, {}, {}, namedScenarios),
    [namedScenarios],
  );

  const data = useMemo(
    () =>
      pipe(
        defaultTo(emptyArray),
        immutableSort((a, b) => scenarioUnit.compare(a.scenario, b.scenario)),
        filter((datum) => datum.type === 'complete'),
      )(get(['data', 'data'], blockData)),
    [scenarioUnit, blockData],
  );

  if (loading) {
    return (
      <Body>
        <MaxWidth>
          <Stack>
            <Loading offset={20} />
          </Stack>
        </MaxWidth>
      </Body>
    );
  }

  if (isEmpty(data)) {
    return (
      <Body>
        <MaxWidth>
          <Stack space='xsmall'>
            <Placeholder>There are no pathways in this workspace.</Placeholder>
          </Stack>
        </MaxWidth>
      </Body>
    );
  }

  return (
    <Container maximised={viewMode === 'maximised'}>
      <Table>
        <Headers data={data} scenarioFormat={scenarioUnit.format} />
        <Locations data={data} />
        <InterventionAreas data={data} viewMode={viewMode} />
      </Table>
    </Container>
  );
};

PathwayInputBlock.propTypes = propTypes;
PathwayInputBlock.defaultProps = defaultProps;

export { PathwayInputBlock };
