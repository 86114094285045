import { createSelector } from 'reselect';
import {
  defaultTo,
  filter,
  find,
  first,
  fromPairs,
  get,
  includes,
  isEqual,
  isNil,
  map,
  pipe,
  reject,
  uniq,
  values,
} from 'lodash/fp';
import Units from 'data/units';
import { requiredTablesOf } from 'data/block';
import { immutableSort } from 'utils/charts/series';
import blocksSelector from './blocksSelector';
import locationsSelector from './locationsSelector';
import workspaceInstantiationsSelector from './workspaceInstantiationsSelector';
import workspaceSelector from './workspaceSelector';

const units = new Units();

const isContext = pipe(get('classification'), includes('context'));

const blockPerspectiveSelector = createSelector(
  blocksSelector,
  locationsSelector,
  workspaceInstantiationsSelector,
  pipe(workspaceSelector, get('resources')),
  get('resources'),
  (state, props) => props.perspectives,
  (
    blocks,
    locations,
    instantiations,
    workspaceResources,
    resources,
    selectedPerspectives,
  ) =>
    pipe(
      map((block) => {
        const publicPerspectives = get(block.id, selectedPerspectives);

        const groups =
          pipe(
            get([
              'capsule',
              get(['capsule', 'visualisation'], block),
              'query',
              'group',
            ]),
            map('column'),
          )(block) || [];

        const table = first(requiredTablesOf(block.block));
        const resourceId = get(table, workspaceResources);
        const schema = pipe(
          get([resourceId, 'schema']),
          defaultTo({}),
        )(resources);

        const selection = pipe(
          values,
          filter(isContext),
          filter((column) => !groups.includes(column.key)),
          map((sch) => {
            const unit = units.parseColumn(sch, locations, instantiations);
            const itemData = pipe(
              get('perspectives'),
              map(get(['perspective', sch.key])),
              uniq,
            )(block);
            const options = immutableSort(unit.compare, itemData);

            return [
              sch.key,
              get(sch.key, publicPerspectives) ||
                get(['filters', sch.key], block) ||
                first(options),
            ];
          }),
          fromPairs,
        )(schema);
        return pipe(
          get('perspectives'),
          find(
            (p) => isNil(p.perspective) || isEqual(p.perspective, selection),
          ),
          get('id'),
        )(block);
      }),
      reject(isNil),
    )(blocks),
);

export default blockPerspectiveSelector;
