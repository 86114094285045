import { useMatch } from 'react-router-dom';

function usePublicWorkspaceId() {
  const match = [
    useMatch('/embed/:workspaceId/*'),
    useMatch('/public/:workspaceId/*'),
  ].find((matchObj) => matchObj);

  return match?.params?.workspaceId;
}

export { usePublicWorkspaceId };
