import { renderToStaticMarkup } from 'react-dom/server';
import styled, { css } from 'styled-components';
import ChartTooltip from 'components/charts/highcharts/ChartTooltip';
import ChartDetails from 'components/charts/chart-details';

const TooltipContainer = styled.div`
  // TODO: introduce theming solution
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  min-width: 304px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 16px;
  border-radius: 4px;

  ${({ chartType }) =>
    chartType === 'dot' &&
    css`
      margin-bottom: -40px;
      transform: translateY(100%);
    `}
`;

const EmissionsReductionTooltipContainer = styled.div`
  line-height: 20px;

  .kns-tooltip-item {
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    margin-top: 4px;

    .kns-tooltip-item-label {
      flex: 1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 8px;

      > span {
        vertical-align: middle;

        &.kns-tooltip-item-icon {
          margin-right: 8px;
          padding-right: 0;
        }
      }
    }

    .kns-tooltip-item-value {
      font-weight: 500;
      flex: 0 0 auto;
      text-align: right;
      color: rgba(0, 0, 0, 0.85);
    }
  }
`;

const generateDotItems = (context) => {
  const { point } = context;
  const { xLabel, yLabel, sizeLabel, unitFormatter } =
    context.series.userOptions;
  const items = [
    {
      label: xLabel,
      value: unitFormatter.x(point.x),
    },
    {
      label: yLabel,
      value: unitFormatter.y(point.y),
    },
  ];

  if (point.z) {
    items.push({
      label: sizeLabel,
      value: unitFormatter.z(point.z),
    });
  }

  return items;
};

const formatTooltip = (context, formatLabel) => {
  const chartType = context.points[0].series.chart.options.chart.type;

  return renderToStaticMarkup(
    <TooltipContainer>
      <ChartTooltip
        header={formatLabel ? formatLabel(context.x) : context.x}
        points={context.points}
        type={chartType}
      />
    </TooltipContainer>,
  );
};

const formatDotTooltip = (context) => {
  if (context.point) {
    return renderToStaticMarkup(
      <TooltipContainer chartType='dot'>
        <ChartDetails
          title={context.point.label}
          items={generateDotItems(context)}
        />
      </TooltipContainer>,
    );
  }
};

const formatAnnualEmissionsTooltip = (context) =>
  renderToStaticMarkup(
    <TooltipContainer>
      <ChartTooltip header={context.x} points={context.points} type='line' />
    </TooltipContainer>,
  );

const formatEmissionsReductionTooltip = (context, unit) => {
  const convertUnicode = (input) =>
    input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
      const charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });

  const circle = convertUnicode('\u25CF');
  const point = context.points[0];

  return renderToStaticMarkup(
    <TooltipContainer>
      <EmissionsReductionTooltipContainer>
        <div className='kns-tooltip-item'>
          <div className='kns-tooltip-item-label'>
            <span
              className='kns-tooltip-item-icon'
              style={{ color: point.color, fontSize: '20px' }}
            >
              {circle}
            </span>

            <span className='kns-tooltip-item-name'>{point.x}</span>
          </div>
          <div className='kns-tooltip-item-value'>{unit.format(point.y)}</div>
        </div>
      </EmissionsReductionTooltipContainer>
    </TooltipContainer>,
  );
};

export {
  formatAnnualEmissionsTooltip,
  formatEmissionsReductionTooltip,
  formatTooltip,
  formatDotTooltip,
};
