import { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash/fp';
import PropTypes from 'prop-types';
import { MultiSelect } from '@kinesis/bungle';
import ResettableFormItem from 'components/form/ResettableFormItem';

const propTypes = {
  format: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

const defaultProps = {
  options: [],
  value: undefined,
};

const CategoricalFilter = ({ format, label, onChange, options, value }) => {
  const handleReset = useCallback(() => {
    onChange(null);
  }, [onChange]);
  const multiSelectOptions = useMemo(
    () => options.map((opt) => ({ label: format(opt), value: opt })),
    [format, options],
  );
  const allSelected = useMemo(() => isEqual(options, value), [options, value]);

  return (
    <ResettableFormItem
      onReset={handleReset}
      showReset={!!value && !allSelected}
    >
      <MultiSelect
        label={label}
        options={multiSelectOptions}
        onChange={onChange}
        values={value || options} // Default case: all selected
      />
    </ResettableFormItem>
  );
};

CategoricalFilter.propTypes = propTypes;
CategoricalFilter.defaultProps = defaultProps;

export default CategoricalFilter;
