import { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash/fp';
import {
  MarkdownExtension,
  BoldExtension,
  StrikeExtension,
  PlaceholderExtension,
  ItalicExtension,
  UnderlineExtension,
  TrailingNodeExtension,
  BulletListExtension,
} from 'remirror/extensions';
import { Remirror, useRemirror, useRemirrorContext } from '@remirror/react';
import { UtilityButton } from '@kinesis/bungle';
import { Actions, Container, Editor } from './text-block.styles';

const extensions = () => [
  new PlaceholderExtension({ placeholder: 'Enter text' }),
  new BoldExtension(),
  new StrikeExtension(),
  new ItalicExtension(),
  new UnderlineExtension(),
  new TrailingNodeExtension(),
  new BulletListExtension(),
  new MarkdownExtension({ copyAsMarkdown: false }),
];

const propTypes = {
  autoFocus: PropTypes.bool,
  editable: PropTypes.bool,
  text: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};
const defaultProps = {
  autoFocus: false,
  editable: true,
  text: undefined,
  onChange: noop,
  onDelete: noop,
  isFirst: false,
  isLast: false,
};

const RemirrorAutoFocus = ({ autoFocus }) => {
  const context = useRemirrorContext();
  useEffect(() => {
    if (autoFocus) {
      context.focus('end');
    }
  }, [context, autoFocus]);
  return null;
};

RemirrorAutoFocus.propTypes = {
  autoFocus: PropTypes.bool.isRequired,
};

const TextBlock = ({
  autoFocus,
  editable,
  isFirst,
  isLast,
  text,
  onChange,
  onDelete,
}) => {
  const ref = useRef();
  const { manager, state: initialContent } = useRemirror({
    extensions,
    content: text ? text.trim() : undefined,
    stringHandler: 'markdown',
    selection: 'end',
  });

  const onChangeMarkdown = useCallback(
    ({ helpers, state }) => {
      const newText = helpers.getMarkdown(state);
      if (newText !== text) {
        onChange(newText);
      }
    },
    [onChange, text],
  );
  const [hover, setHover] = useState(false);

  const onMouseEnter = useCallback(() => {
    setHover(true);
  }, [setHover]);

  const onMouseLeave = useCallback(() => {
    setHover(false);
  }, [setHover]);

  const onKeyDown = useCallback((e) => {
    // prevent bubbling of typing to keyboard shortcuts
    e.stopPropagation();
  }, []);

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyDown={onKeyDown}
      ref={ref}
    >
      <Editor isFirst={isFirst} isLast={isLast}>
        <Remirror
          autoFocus={autoFocus}
          editable={editable}
          manager={manager}
          initialContent={initialContent}
          autoRender='end'
          onChange={onChangeMarkdown}
        >
          <RemirrorAutoFocus autoFocus={autoFocus} />
        </Remirror>
      </Editor>
      {hover && editable && (
        <Actions>
          <UtilityButton
            icon='delete'
            variant='danger'
            magnitude='xsmall'
            onClick={onDelete}
          />
        </Actions>
      )}
    </Container>
  );
};

TextBlock.propTypes = propTypes;
TextBlock.defaultProps = defaultProps;

export { TextBlock };
