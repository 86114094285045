import { useCallback, useEffect, useMemo, useState } from 'react';
import { get, isUndefined } from 'lodash/fp';
import { Tooltip, UtilityButton } from '@kinesis/bungle';
import { ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { AppToolbar, Breadcrumb } from 'components/app-header';
import Layout from 'components/layout';
import { ScenarioBoardDropdown } from 'components/scenario-board-dropdown';
import scenarioYearMinMaxSelector from 'selectors/scenarioYearMinMaxSelector';
import SpatialScenario from 'components/spatial-scenario/SpatialScenario';
import { ContinuousTimeSlider } from 'components/time-slider';
import { TimeSliderFooter } from 'components/time-slider/time-slider.styles';
import { Toolbox } from 'components/toolbox';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { actions as publicWorkspaceActions } from 'reducers/publicWorkspacesReducer';
import {
  usePublicWorkspaceId,
  useScenarioId,
  useSelectorWithProps,
} from 'hooks';
import useActions from 'hooks/useActions';
import useExpandableBounds from 'hooks/useExpandableBounds';
import useToolboxOpenState from 'hooks/useToolboxOpenState';
import publicWorkspaceSelector from 'selectors/publicWorkspaceSelector';

const PublicScenario = () => {
  const [isToolboxOpen, setToolboxOpenState] = useToolboxOpenState();
  const { select } = useActions(toolboxActions);
  const { setPreviewYear, setScenarioYearMax, setScenarioYearMin } = useActions(
    publicWorkspaceActions,
  );
  const workspaceId = usePublicWorkspaceId();
  const scenarioId = useScenarioId();
  const spatialBounds = useExpandableBounds({
    privacy: 'public',
    scenarioId,
    workspaceId,
  });
  const dataYearBounds = useSelectorWithProps(scenarioYearMinMaxSelector, {
    public: true,
    scenarioId,
    workspaceId,
  });
  const [[dataYearMin, dataYearMax]] = useState(dataYearBounds);
  const publicWorkspace = useSelectorWithProps(publicWorkspaceSelector, {
    workspaceId,
  });

  const year = get(['preview', 'year'], publicWorkspace);
  const { yearMax, yearMin } =
    get(['preview', 'constrainedBounds', scenarioId], publicWorkspace) || {};

  const handleTimeSliderReset = useCallback(() => {
    setPreviewYear(dataYearMax);
    setScenarioYearMax({ scenarioId, year: dataYearMax });
    setScenarioYearMin({ scenarioId, year: dataYearMin });
  }, [
    dataYearMax,
    dataYearMin,
    scenarioId,
    setPreviewYear,
    setScenarioYearMax,
    setScenarioYearMin,
  ]);

  const onChangeBounds = useCallback(
    ([newMin, newMax] = []) => {
      setScenarioYearMax({ scenarioId, year: newMax });
      setScenarioYearMin({ scenarioId, year: newMin });
    },
    [scenarioId, setScenarioYearMax, setScenarioYearMin],
  );

  const handleToolboxStateChange = useCallback(() => {
    setToolboxOpenState((isOpen) => !isOpen);
  }, [setToolboxOpenState]);

  useEffect(() => {
    select({
      pane: 'context',
      selection: { id: scenarioId, type: 'scenario' },
    });
  }, [scenarioId, select]);

  useEffect(() => {
    if (isUndefined(year) && dataYearMax) {
      setPreviewYear(dataYearMax);
    }
  }, [dataYearMax, setPreviewYear, year]);

  const years = useMemo(() => [year], [year]);

  return (
    <>
      <Breadcrumb>
        <ScenarioBoardDropdown privacy='public' workspaceId={workspaceId} />
      </Breadcrumb>
      <AppToolbar>
        <ToolbarGroup>
          <ToolbarItem>
            <Tooltip
              justify='end'
              placement='bottom'
              title={isToolboxOpen ? 'Hide toolbox' : 'Show toolbox'}
            >
              <UtilityButton
                expanded={isToolboxOpen}
                icon='control'
                variant={isToolboxOpen ? 'accent' : 'default'}
                onClick={handleToolboxStateChange}
              />
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </AppToolbar>
      <Layout direction='row'>
        <Layout display='flex' direction='column'>
          <SpatialScenario
            bounds={spatialBounds}
            privacy='public'
            workspaceId={workspaceId}
          />
          {year && dataYearMin && dataYearMax && (
            <TimeSliderFooter>
              <ContinuousTimeSlider
                boundsDefaultMax={dataYearMax}
                boundsDefaultMin={dataYearMin}
                boundsMax={yearMax || dataYearMax}
                boundsMin={yearMin || dataYearMin}
                onChangeBounds={onChangeBounds}
                onChangeYear={setPreviewYear}
                onResetYear={handleTimeSliderReset}
                years={years}
              />
            </TimeSliderFooter>
          )}
        </Layout>
        {isToolboxOpen && <Toolbox privacy='public' />}
      </Layout>
    </>
  );
};

export { PublicScenario };
