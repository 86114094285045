import styled from 'styled-components';

const Indicator = styled.div`
  background: white;
  height: 138px;
  padding: 24px 16px 16px 16px;
  text-align: center;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
`;

const IndicatorValue = styled.div`
  font-size: 28px;
  color: rgba(0, 0, 0, 0.85);
  margin-right: 4px;
  line-height: 36px;
`;

const PolicyType = styled.span`
  margin-right: 4px;
`;

export { Indicator, IndicatorValue, PolicyType };
