import PropTypes from 'prop-types';
import Popover from 'components/popover/Popover';
import { BasicLocationPopover } from 'components/spatial-popover/spatial-popover.styles';

const propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  label: PropTypes.string,
};
const defaultProps = {
  label: undefined,
};

const ImportLocationTooltip = ({ x, y, label }) => (
  <Popover pointerX={x} pointerY={y} disablePointerEvents>
    <BasicLocationPopover>{label}</BasicLocationPopover>
  </Popover>
);

ImportLocationTooltip.propTypes = propTypes;
ImportLocationTooltip.defaultProps = defaultProps;

export { ImportLocationTooltip };
