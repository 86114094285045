import { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get, pipe, debounce, noop } from 'lodash/fp';
import { actions as miscActions } from 'reducers/miscReducer';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import { TextBlock } from 'components/text-block';
import { blockUpdateDetails } from 'actions/blockUpdateDetails';
import { useBoardId, useSelectorWithProps } from 'hooks';
import useAction from 'hooks/useAction';
import usePermission from 'hooks/usePermission';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  removeBlocks: PropTypes.func,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  isFirst: false,
  isLast: false,
  removeBlocks: noop,
  privacy: 'private',
};

const BlockText = ({
  blockId,
  isFirst,
  isLast,
  removeBlocks,
  privacy,
  workspaceId,
}) => {
  const editor = usePermission('editor');
  const dispatch = useDispatch();
  const newTextBlockId = useSelector(get(['misc', 'newTextBlockId']));
  useEffect(() => {
    if (newTextBlockId) {
      dispatch(miscActions.clearNewTextBlockId());
    }
  }, [dispatch, newTextBlockId]);

  const boardId = useBoardId();
  const permalink = useSelectorWithProps(
    pipe(blockStateSelector, get('permalink')),
    {
      blockId,
      public: privacy === 'public',
      workspaceId,
    },
  );
  const text = useSelectorWithProps(pipe(blockValueSelector, get('text')), {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });

  const handleUpdate = useAction(blockUpdateDetails);

  const handleUpdateText = useCallback(
    (newText) => {
      handleUpdate({
        workspaceId,
        boardId,
        blockId: permalink,
        blockParams: {
          title: '',
          text: newText,
          type: 'text',
        },
      });
    },
    [handleUpdate, boardId, permalink, workspaceId],
  );

  const handleUpdateTextDebounce = useMemo(
    () => debounce(300, handleUpdateText),
    [handleUpdateText],
  );

  useEffect(
    () => () => {
      handleUpdateTextDebounce.flush();
    },
    [handleUpdateTextDebounce],
  );

  const handleDelete = useCallback(() => {
    removeBlocks([blockId]);
  }, [removeBlocks, blockId]);

  return (
    <TextBlock
      autoFocus={newTextBlockId === blockId}
      editable={editor}
      text={text}
      onChange={editor ? handleUpdateTextDebounce : noop}
      onDelete={handleDelete}
      isFirst={isFirst}
      isLast={isLast}
    />
  );
};

BlockText.propTypes = propTypes;
BlockText.defaultProps = defaultProps;

export { BlockText };
