import { useEffect } from 'react';
import { useHref } from 'react-router-dom';
import * as RedirectTo from 'services/redirect-to';

const Unauthenticated = () => {
  const href = useHref();

  useEffect(() => {
    RedirectTo.set(href);
    window.location.assign('/');
  }, [href]);

  return null;
};

export { Unauthenticated };
