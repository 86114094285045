import styled from 'styled-components';

const ActionsCard = styled.div`
  max-width: 640px;
  grid-column: 1 / -1;
`;

const TitleCard = styled.div`
  max-width: 640px;
  grid-column: 1 / -1;
  h5 {
    margin: 0;
    letter-spacing: 0.15px;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: ${({ theme }) => theme.fontSize.sm}px;
    line-height: ${({ theme }) => theme.lineHeight.sm};
    color: rgba(0, 0, 0, 0.45);
    text-transform: uppercase;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: ${({ equalSpacing }) =>
    equalSpacing ? '16px' : '16px 16px 24px 16px'};
  width: 656px;
`;

export { Container, ActionsCard, TitleCard };
