import { get, isNil, pipe, sumBy } from 'lodash/fp';
import Units from 'data/units';

const units = new Units();

const { format: formatCoordinate } = units.coordinate({
  maximumFractionDigits: 3,
  minimumFractionDigits: 3,
});
const { format: formatLandArea } = units.sqm();
const { format: formatSolarCapacity } = units.kw();
const { format: formatWater } = units.litres();

function formatCoordinates({ point, area } = {}) {
  const { latitude, longitude } = point;
  if (!latitude || !longitude) {
    return 'Position cannot be displayed';
  }

  const identifier = !isNil(area) ? 'Polygon' : 'Point';

  return `${identifier} (${formatCoordinate(latitude)}, ${formatCoordinate(
    longitude,
  )})`;
}

const formatUsages = pipe(sumBy(get(['values', 'amount'])), formatLandArea);

export {
  formatCoordinates,
  formatLandArea,
  formatSolarCapacity,
  formatUsages,
  formatWater,
};
