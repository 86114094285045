import styled from 'styled-components';

const LinkStyle = styled.span`
  color: #337ab7;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  flex-shrink: 0;

  &:hover {
    color: #5695c4;
  }
  &:active {
    color: #215991;
  }
  &:focus {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { LinkStyle };
