import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getTime, parseISO } from 'date-fns/fp';
import { every, find, map, pipe } from 'lodash/fp';
import { SparklineGraph } from './sparkline.styles';
import SparklineLabels from './SparklineLabels';

const BLUE = '#337AB7';
const LIGHTBLUE = 'rgba(51, 122, 183, 0.25)';
const DOTRADIUS = 5;

class Sparkline extends PureComponent {
  static propTypes = {
    continuous: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        dateLabel: PropTypes.string,
        valueLabel: PropTypes.string,
      }),
    ),
    height: PropTypes.number,
    width: PropTypes.number,
    leftRightMargin: PropTypes.number,
    selectedDate: PropTypes.string,
  };

  static defaultProps = {
    continuous: undefined,
    data: undefined,
    labels: undefined,
    height: undefined,
    width: undefined,
    leftRightMargin: undefined,
    selectedDate: undefined,
  };

  dateAsSecondsSinceEpoch = (date) =>
    pipe(parseISO, getTime, (ms) => Math.round(ms / 1000))(date);

  generateMultiPointSvg = () => {
    const { continuous, data, height, leftRightMargin, selectedDate, width } =
      this.props;

    const points = map((datum) => ({
      ...datum,
      date: this.dateAsSecondsSinceEpoch(datum.date),
    }))(data);

    const usableHeight = height - 2 * DOTRADIUS;
    const usableWidth = width - 2 * leftRightMargin;

    const seriesMin = Math.min(...points.map((e) => e.value));
    const seriesMax = Math.max(...points.map((e) => e.value));

    const yAxisScalingFactor = usableHeight / (seriesMax - seriesMin);
    const allPointsEqual = every(
      (datum) => datum.value === data[0].value,
      points,
    );

    const seriesXMin = Math.min(...points.map((p) => p.date));
    const seriesXMax = Math.max(...points.map((p) => p.date));
    const xAxisScalingFactor = usableWidth / (seriesXMax - seriesXMin);

    const sparkline = [];
    let holdValue;
    points.forEach((datum) => {
      const x = (datum.date - seriesXMin) * xAxisScalingFactor;
      const y = allPointsEqual
        ? usableHeight / 2
        : usableHeight - (datum.value - seriesMin) * yAxisScalingFactor;
      if (holdValue !== undefined && !continuous)
        sparkline.push({ x: x + leftRightMargin, y: holdValue + DOTRADIUS });
      sparkline.push({
        date: datum.date,
        x: x + leftRightMargin,
        y: y + DOTRADIUS,
      });
      holdValue = y;
    });

    const [first, ...rest] = sparkline;

    const path = [`M${first.x} ${first.y}`];
    rest.forEach((point) => path.push(`L${point.x} ${point.y}`));
    const last = rest.pop();
    path.push(`L${last.x} ${last.y}`);

    const selectedPoint = selectedDate
      ? find({ date: this.dateAsSecondsSinceEpoch(selectedDate) }, sparkline)
      : undefined;

    return (
      <svg width={width} height={height}>
        <path
          d={path.join(' ')}
          stroke={selectedPoint ? LIGHTBLUE : BLUE}
          strokeWidth='2'
          fill='none'
          strokeLinejoin='round'
        />
        {selectedPoint ? (
          <circle
            stroke='white'
            strokeWidth='1'
            cx={selectedPoint.x}
            cy={selectedPoint.y}
            r={DOTRADIUS}
            fill={BLUE}
          />
        ) : (
          <>
            <circle
              stroke='white'
              strokeWidth='1'
              cx={first.x}
              cy={first.y}
              r={DOTRADIUS}
              fill={BLUE}
            />
            <circle
              stroke='white'
              strokeWidth='1'
              cx={last.x}
              cy={last.y}
              r={DOTRADIUS}
              fill={BLUE}
            />
          </>
        )}
      </svg>
    );
  };

  generateSinglePointSvg = () => {
    const { height, leftRightMargin, width } = this.props;

    return (
      <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
        <line
          x1={width / 2}
          y1={height / 2}
          x2={width - leftRightMargin}
          y2={height / 2}
          stroke={BLUE}
          strokeOpacity='0.25'
          strokeWidth='2'
          fill='none'
          strokeLinejoin='round'
        />
        <circle
          stroke='white'
          strokeWidth='1'
          cx={width / 2}
          cy={height / 2}
          r={DOTRADIUS}
          fill={BLUE}
        />
      </svg>
    );
  };

  render() {
    const { data, labels, leftRightMargin, selectedDate, width } = this.props;

    return (
      <div>
        <SparklineGraph>
          {data.length > 1
            ? this.generateMultiPointSvg()
            : this.generateSinglePointSvg()}
          {labels && (
            <SparklineLabels
              data={labels}
              leftRightMargin={leftRightMargin}
              selectedDate={selectedDate}
              width={width}
            />
          )}
        </SparklineGraph>
      </div>
    );
  }
}

export default Sparkline;
