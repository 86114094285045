import PropTypes from 'prop-types';

const propTypes = {
  datasetId: PropTypes.number.isRequired,
};

const defaultProps = {};

const DatasetAttributesProfile = ({ datasetId }) => <div>todo {datasetId}</div>;

DatasetAttributesProfile.propTypes = propTypes;
DatasetAttributesProfile.defaultProps = defaultProps;

export { DatasetAttributesProfile };
