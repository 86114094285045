import { createSelector } from 'reselect';
import publicMultiBlockYearMinMaxSelector from 'selectors/publicMultiBlockYearMinMaxSelector';
import { getBounds } from 'utils/boundsUtils';

const publicMultiBlockYearBoundsSelector = createSelector(
  publicMultiBlockYearMinMaxSelector,
  getBounds,
);

export default publicMultiBlockYearBoundsSelector;
