import { createSelector } from 'reselect';
import { compact, flatMap, map, pipe, filter, get, uniqBy } from 'lodash/fp';
import makeFeatureFlagSelector from 'selectors/makeFeatureFlagSelector';

const enableLimitedReleaseAppsSelector = makeFeatureFlagSelector(
  'app.limited-release-apps',
);

// FIX include licence information...
const appSchemaSourcesSelector = createSelector(
  get(['apps', 'entities']),
  enableLimitedReleaseAppsSelector,
  (apps, enableLimitedReleaseApps) =>
    pipe(
      map((app) => (enableLimitedReleaseApps ? app.limited : app.available)),
      compact,
      flatMap('resources'),
      filter({ type: 'table' }),
      uniqBy('resource'),
      map(({ label, resource, schema }) => ({
        type: 'app',
        id: resource,
        label,
        schema,
      })),
    )(apps),
);

export default appSchemaSourcesSelector;
