import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceShareChangePermission = createAsyncThunk(
  'workspace-share/change-permission',
  async ({ token, permission }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.patch(
      `/api/workspace-shares/change-permission/${token}`,
      { permission },
    );

    return response.data;
  },
);

export { workspaceShareChangePermission };
