import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@kinesis/bungle';
import useTheme from 'hooks/useTheme';

const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  padding-bottom: ${({ offset }) => offset}px;
`;

const propTypes = {
  animation: PropTypes.string,
  delay: PropTypes.number,
  displayMode: PropTypes.oneOf(['light', 'dark']),
  magnitude: PropTypes.oneOf([
    'tiny',
    'extraSmall',
    'small',
    'medium',
    'large',
  ]),
  offset: PropTypes.number,
  type: PropTypes.string,
};

const defaultProps = {
  animation: 'rotate',
  delay: 0,
  displayMode: 'light',
  magnitude: 'large',
  offset: 0,
  type: 'spinner',
};

const Loading = ({
  animation,
  delay,
  displayMode,
  magnitude,
  offset,
  type,
  ...restProps
}) => {
  const [visible, setVisible] = useState(delay === 0);
  const theme = useTheme();

  useEffect(() => {
    if (visible) {
      return undefined;
    }

    const timerId = setTimeout(() => {
      setVisible(true);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [delay, visible]);

  return visible ? (
    <LoadingWrapper data-testid='loading-spinner' offset={offset}>
      <Icon
        animation={animation}
        color={
          displayMode === 'light'
            ? theme.color.blue6
            : 'rgba(255, 255, 255, 0.85)'
        }
        magnitude={magnitude}
        type={type}
        {...restProps}
      />
    </LoadingWrapper>
  ) : null;
};

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export { Loading };
