import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { set } from 'lodash/fp';
import { useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { PathwayUrl } from 'data/pathways';
import { pathwayCalculate } from 'actions/pathwayCalculate';

const usePathwayInterventionAreas = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = useMemo(() => PathwayUrl.new(search, true), [search]);
  const urlInterventionAreas = useMemo(
    () => query.hydrateInterventionAreas(),
    [query],
  );
  const calculate = useCallback(
    (key, value, input, locations, locationType) => {
      dispatch(
        pathwayCalculate({
          input: set(key, value, input),
          locations,
          locationType,
        }),
      );
    },
    [dispatch],
  );
  const urlUpdate = useCallback(
    (k, v, input, locations, locationType) => {
      navigate({ search: query.withInterventionArea(k, v).toString() });
      calculate(k, v, input, locations, locationType);
    },
    [query, navigate, calculate],
  );
  return [urlInterventionAreas, urlUpdate];
};

export default usePathwayInterventionAreas;
