import { get } from 'lodash/fp';
import { createSelector } from 'reselect';
import boardsSelector from './boardsSelector';

const boardSelector = createSelector(
  boardsSelector,
  (state, props = {}) => props.boardId,
  (boards, boardId) => get(boardId, boards),
);

export default boardSelector;
