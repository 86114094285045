import { scaleThreshold } from 'd3-scale';
import { sequentialColors } from 'settings/colors';
import { pickSequential, shade, lighten } from 'utils/colors';
import { memoize, isNil, isString, first } from 'lodash/fp';
import chroma from 'chroma-js';

const defaultColor = first(pickSequential(sequentialColors, 1));

// NOTE: these are carefully split out, so the minimum amount of work happens
// if you are changing this, be very careful not to introduce performance
// regressions, in big spatial visualisations (see ptal hex from stub).

const colorsFor = (colorDomain) => [
  'white',
  ...pickSequential(sequentialColors, colorDomain.length),
];

const colorFor = (colorDomain, colors, color) => {
  if (isNil(color)) {
    return defaultColor;
  }
  if (isString(color)) {
    const colorValue = colorDomain.indexOf(color);
    const scale = scaleThreshold()
      .domain(colorDomain.map((c, index) => index))
      .range(colors);
    return scale(colorValue || 0);
  }
  const scale = scaleThreshold().domain(colorDomain).range(colors);
  return scale(color);
};

const baseColor = memoize((color) => [...chroma(color).rgb(), 115 /* 45% */]);

const diffColor = memoize((color) => [...chroma(color).rgb(), 183 /* 72% */]);

const selectedLineColor = memoize((color) => chroma(shade(color)).rgb());

const deselectedLineColor = memoize((color) => chroma(lighten(color)).rgb());

export {
  colorFor,
  colorsFor,
  baseColor,
  diffColor,
  selectedLineColor,
  deselectedLineColor,
};
