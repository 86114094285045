import { find, flatten, isNil, map, pipe } from 'lodash/fp';
import { qualitativeColors } from 'settings/colors';
import { pickQualitative, opacify, soften } from 'utils/colors';
import { bubble as chartOpacities } from 'utils/charts/opacities';

const deselectBubblePoint = (point) => {
  const chartSeriesLength = point.series.chart.series.length;

  const colors = pickQualitative(qualitativeColors, chartSeriesLength);
  const notSelectedColors = colors.map((col) =>
    opacify(col, chartOpacities.notSelected),
  );
  const normalColors = colors.map((col) => opacify(col, chartOpacities.normal));

  const notSelectedFillColor = notSelectedColors[point.series.index]; // 25%
  const normalFillColor = normalColors[point.series.index]; // 65%
  const hoverLineColor = soften(normalFillColor);

  point.update(
    {
      selected: false,
      marker: {
        fillColor: notSelectedFillColor,
        lineWidth: 0,
        states: {
          hover: {
            fillColor: normalFillColor,
            lineColor: hoverLineColor,
            lineWidth: 2,
          },
        },
      },
    },
    false,
    false,
  );
};

const returnBubblesToUnselectedState = (points) => {
  if (points.length) {
    const chartSeriesLength = points[0].series.chart.series.length;
    const colors = pickQualitative(qualitativeColors, chartSeriesLength);
    const normalColors = colors.map((col) =>
      opacify(col, chartOpacities.normal),
    );

    points.forEach((point) => {
      const normalFillColor = normalColors[point.series.index];

      point.update(
        {
          selected: false,
          marker: {
            fillColor: normalFillColor,
            lineWidth: 0,
          },
        },
        false,
        false,
      );
    });
  }
};

const bubbleSeriesClickHandler = (point) => {
  const {
    series: { chart },
  } = point;

  if (point !== chart.getSelectedPoints()[0]) {
    point.update({ selected: true }, false, false);

    const nonSelectedPoints = flatten(
      chart.series.map((s) => s.points.filter((p) => p !== point)),
    );

    nonSelectedPoints.forEach((p) => {
      deselectBubblePoint(p);
    });

    chart.redraw();

    return true;
  }

  return false;
};

// for scatter and bubble charts
const handleBubbleSelectPointValue = (chart, value) => {
  if (!isNil(value)) {
    const valuePoint = pipe(
      map((s) => s.points),
      flatten,
      find({ dataId: value }),
    )(chart.series);

    if (valuePoint) {
      bubbleSeriesClickHandler(valuePoint);
    }
  } else {
    returnBubblesToUnselectedState(
      flatten(chart.series.map((s) => s.points)),
      chart,
    );
  }

  chart.redraw();
};

const dotPlotClickHandler = (chart) => {
  const points = chart.series.flatMap((s) => s.points);

  returnBubblesToUnselectedState(points);

  chart.redraw();
};

export {
  returnBubblesToUnselectedState,
  bubbleSeriesClickHandler,
  dotPlotClickHandler,
  handleBubbleSelectPointValue,
};
