import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceUpdateOwner = createAsyncThunk(
  'workspace/update-owner',
  async ({ owner, workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.put(`/api/workspace/${workspaceId}/owner`, {
      owner,
    });

    return response.data;
  },
);

export { workspaceUpdateOwner };
