import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { defaultTo } from 'lodash/fp';
import { Button, Icon, Stack } from '@kinesis/bungle';
import {
  ButtonSpacer,
  EmailText,
  Heading,
  IconSpacer,
  Panel,
  Text,
} from './error.styles';

const propTypes = {
  variant: PropTypes.oneOf(['server', 'not-found', 'unknown']),
};

const defaultProps = {
  variant: 'server',
};

const headings = {
  server: 'We couldn’t load this page',
  'not-found': 'We couldn’t find this page',
  unknown: 'We couldn’t find this page',
};
const texts = {
  server:
    'Please try again in a moment. If you continue to experience problems, get in touch with our support team.',
  'not-found':
    'The item you’re looking for may have been deleted or you might need permission to view it. Check that the URL you entered is correct or try to navigate to what you need from the home page.',
  unknown:
    'Check that the URL you entered is correct or try to navigate to what you need from the home page.',
};

const Error = ({ variant }) => (
  <Panel>
    <Stack space='small'>
      <IconSpacer variant={variant}>
        {variant === 'server' && (
          <Icon magnitude='xxlarge' type='close-circle' />
        )}
        {(variant === 'not-found' || variant === 'unknown') && (
          <Icon magnitude='xxlarge' type='exclamation-circle' />
        )}
      </IconSpacer>
      <Heading>{defaultTo(headings.server, headings[variant])}</Heading>
      <Text>{defaultTo(texts.server, texts[variant])}</Text>
      <ButtonSpacer>
        <Button as={Link} to='/workspaces'>
          Back home
        </Button>
        {variant === 'server' && (
          <EmailText>
            or{' '}
            <a
              href='mailto:support@kinesis.org?Subject=Problem%20with%20Kinesis'
              target='_blank'
              rel='noopener noreferrer'
            >
              email support
            </a>
          </EmailText>
        )}
      </ButtonSpacer>
    </Stack>
  </Panel>
);

Error.propTypes = propTypes;
Error.defaultProps = defaultProps;

export { Error };
