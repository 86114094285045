import { createSelector } from 'reselect';
import { filter, get, map, pipe, sortBy, update } from 'lodash/fp';
import locationsSelector from './locationsSelector';
import currentAttributesSelector from './currentAttributesSelector';

const selectableLocationsSelector = createSelector(
  locationsSelector,
  pipe(currentAttributesSelector, get('values')),
  (locations, attributeValues) =>
    pipe(
      filter((location) => get(location.id, attributeValues)),
      map(update('label', (label) => label || 'New location')),
      sortBy('label'),
    )(locations),
);
export default selectableLocationsSelector;
