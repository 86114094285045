import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useBoardId, useSelectorWithProps } from 'hooks';
import { find, first, get, includes, keyBy, pipe, where } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const propTypes = {
  visualisationKey: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const BoardLegacyRedirect = ({ visualisationKey, workspaceId }) => {
  const workspace = useSelectorWithProps(workspaceSelector, { workspaceId });
  const boardId = useBoardId();
  const firstBlockId = pipe(
    get('boards'),
    keyBy('id'),
    get([boardId, 'blocks']),
    first,
  )(workspace);
  const visualisationBlock = pipe(
    get('boards'),
    find(
      where({
        blocks: includes(visualisationKey),
      }),
    ),
    get('id'),
  )(workspace);

  // COMPATABILITY, DO NOT REMOVE: Handle old urls, at least for a while.
  if (visualisationBlock) {
    return (
      <Navigate
        to={`/workspaces/${workspaceId}/boards/${visualisationBlock}/blocks/${visualisationKey}`}
      />
    );
  }

  return (
    <Navigate
      to={
        boardId
          ? `/workspaces/${workspaceId}/boards/${boardId}/blocks/${firstBlockId}`
          : `/workspaces/${workspaceId}`
      }
    />
  );
};

BoardLegacyRedirect.propTypes = propTypes;

export { BoardLegacyRedirect };
