import { Provider as ReduxProvider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { GlobalStyle, ThemeProvider, ToastProvider } from '@kinesis/bungle';
import { AppHeaderProvider } from 'components/app-header';
import App from './App';
import { useStore } from '@/hooks/useStore';

const MarathonStyle = createGlobalStyle`
  body {
    overflow: hidden;
    -webkit-text-size-adjust: 100%;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
  }

  [data-reach-dialog-overlay] {
    z-index: 10;
  }

  [data-reach-popover] {
    z-index: 10;
  }

  [role="tooltip"] {
    z-index: 20;
  }

  [role="alert"] {
    z-index: 20;
  }
`;

const propTypes = {};

const Root = () => {
  const store = useStore();

  return (
    <ReduxProvider store={store}>
      <ThemeProvider>
        <ToastProvider>
          <GlobalStyle />
          <MarathonStyle />
          <AppHeaderProvider>
            <App />
          </AppHeaderProvider>
        </ToastProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};

Root.propTypes = propTypes;

export default Root;
