import { createSlice } from '@reduxjs/toolkit';
import { get, remove, set, unset, update, isNil } from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { appsList } from 'actions/appsList';
import { dataFetch } from 'actions/dataFetch';
import { datasetsList } from 'actions/datasetsList';
import { datasetCreate } from 'actions/datasetCreate';
import { datasetImportSchema } from 'actions/datasetImportSchema';
import { datasetVersionCreate } from 'actions/datasetVersionCreate';
import { geographyUpdate } from 'actions/geographyUpdate';
import { populationDatasetFetch } from 'actions/populationDatasetFetch';
import { scenarioCreate } from 'actions/scenarioCreate';
import { scenarioDelete } from 'actions/scenarioDelete';
import { scenarioDuplicate } from 'actions/scenarioDuplicate';
import { scenarioSaveAsNew } from 'actions/scenarioSaveAsNew';
import { serviceAccountKeyCreate } from 'actions/serviceAccountKeyCreate';
import { appsUpgrade } from 'actions/appsUpgrade';
import { visualisationQuery } from 'actions/visualisationQuery';
import { sharingPermissionAdd } from 'actions/sharingPermissionAdd';
import { workspacesReset } from 'actions/workspacesReset';
import { workspacesFetch } from 'actions/workspacesFetch';
import { workspaceCreate } from 'actions/workspaceCreate';
import { workspacePatch } from 'actions/workspacePatch';
import { workspaceDuplicate } from 'actions/workspaceDuplicate';
import { workspaceDelete } from 'actions/workspaceDelete';
import { workspaceShareAccept } from 'actions/workspaceShareAccept';
import { userRelogin } from 'actions/userRelogin';

const setCreating = (state) => {
  state.isCreatingWorkspace = true;
};
const unsetCreating = (state) => {
  state.isCreatingWorkspace = false;
};
const setDeleting = (state) => {
  state.isDeletingWorkspace = true;
};
const unsetDeleting = (state) => {
  state.isDeletingWorkspace = false;
};
const setCreatingNamedScenario = (state) => {
  state.isCreatingNamedScenario = true;
};
const unsetCreatingNamedScenario = (state) => {
  state.isCreatingNamedScenario = false;
};
const incrementQueryCount = (state) => {
  state.queryCount += 1;
};
const decrementQueryCount = (state) => {
  state.queryCount -= 1;
};

const handleWorkspaceRejected = (state, action) => {
  const { id, message } = action.payload;

  state.isCreatingWorkspace = false;
  state.workspaceErrors.push({ id, message });
};

const { reducer, actions } = createSlice({
  name: 'misc',

  initialState: {
    isAddingApp: false,
    isFetchingApps: false,
    hasFetchedApps: false,
    isCreatingApp: false,
    isFetchingDatasets: false,
    hasFetchedDatasets: false,
    isFetchingRegistrations: false,
    isFetchingScan: false,
    isFetchingPopulationDataset: false,
    isCreatingDataset: false,
    isCreatingOrganisation: false,
    isCreatingWorkspace: false,
    isDeletingWorkspace: false,
    isFetchingWorkspaces: false,
    isCreatingUserKey: false,
    hasFetchedWorkspaces: false,
    isCreatingNamedScenario: false,
    isDeletingScenario: false,
    isSharing: false,
    workspaceErrors: [],
    isInviteEmailMismatch: false,
    expiredShare: undefined,
    showMultiSelectPrompt: true,
    showBlockConfigurationPrompt: true,
    showSelectAllLocationsPrompt: true,
    showPendingInvitations: false,
    registrationError: false,
    newDatasetVersion: undefined,
    showSaveBlockConfirmationDialog: false,
    queryCount: 0,
    lifecycleTrigger: 0,
    isUpgradingApps: false,
    isPatchingWorkspace: false,
    isPathwayOutputDownloading: false,
    hasPathwayOutputDownloadFailed: false,
    newTextBlockId: undefined,
  },

  reducers: {
    createNamedScenarioRequest: setCreatingNamedScenario,
    createNamedScenarioResponse: unsetCreatingNamedScenario,
    duplicateNamedScenarioRequest: setCreatingNamedScenario,
    duplicateNamedScenarioResponse: unsetCreatingNamedScenario,
    removeWorkspaceError: ({ workspaceErrors, ...state }, action) => ({
      ...state,
      workspaceErrors: remove(
        (err) => get('id', err) === get(['payload', 'id'], action),
        workspaceErrors,
      ),
    }),
    clearIsInviteEmailMismatch: (state) => {
      state.isInviteEmailMismatch = false;
    },
    setShowSaveBlockConfirmationDialog: set(
      'showSaveBlockConfirmationDialog',
      true,
    ),
    clearShowSaveBlockConfirmationDialog: set(
      'showSaveBlockConfirmationDialog',
      false,
    ),
    setShowSelectAllLocationsPrompt: (state) => {
      state.showSelectAllLocationsPrompt = true;
    },
    clearShowSelectAllLocationsPrompt: set(
      'showSelectAllLocationsPrompt',
      false,
    ),
    clearShowPendingInvitations: (state) => {
      state.showPendingInvitations = false;
    },
    clearShowMultiSelectPrompt: (state) => {
      state.showMultiSelectPrompt = false;
    },
    clearShowBlockConfigurationPrompt: set(
      'showBlockConfigurationPrompt',
      false,
    ),
    clearRegistrationError: (state) => {
      state.registrationError = false;
    },
    clearNewDatasetVersion: unset('newDatasetVersion'),
    clearServiceAccountKey: unset('newServiceAccountKey'),
    clearUserKey: unset('newUserKey'),
    clearExpiredShare: unset('expiredShare'),
    tickLifecycle: update('lifecycleTrigger', (n) => n + 1),
    setNewTextBlockId: (state, action) =>
      set('newTextBlockId', action.payload, state),
    clearNewTextBlockId: unset('newTextBlockId'),
  },

  extraReducers: (builder) => {
    builder.addCase(appAdd.pending, (state) => {
      state.isAddingApp = true;
    });

    builder.addCase(appAdd.fulfilled, (state) => {
      state.isAddingApp = false;
    });

    builder.addCase(appsUpgrade.pending, (state) => {
      state.isUpgradingApps = true;
    });

    builder.addCase(appsUpgrade.fulfilled, (state) => {
      state.isUpgradingApps = false;
    });

    builder.addCase(userRelogin.fulfilled, (state, action) => {
      const { newLogin } = action.payload;

      if (newLogin) {
        state.showPendingInvitations = true;
      }
    });

    builder.addCase(appsList.pending, (state) => ({
      ...state,
      isFetchingApps: true,
    }));

    builder.addCase(appsList.fulfilled, (state) => ({
      ...state,
      isFetchingApps: false,
      hasFetchedApps: true,
    }));

    builder.addCase(datasetsList.pending, (state) => ({
      ...state,
      isFetchingDatasets: true,
    }));

    builder.addCase(datasetsList.fulfilled, (state) => ({
      ...state,
      isFetchingDatasets: false,
      hasFetchedDatasets: true,
    }));

    builder.addCase(datasetCreate.pending, (state) => {
      state.isCreatingDataset = true;
    });

    builder.addCase(datasetCreate.fulfilled, (state) => {
      state.isCreatingDataset = false;
    });

    builder.addCase(scenarioCreate.pending, setCreatingNamedScenario);
    builder.addCase(scenarioCreate.fulfilled, unsetCreatingNamedScenario);

    builder.addCase(scenarioDelete.pending, (state) => {
      state.isDeletingNamedScenario = true;
    });

    builder.addCase(scenarioDelete.fulfilled, (state) => {
      state.isDeletingNamedScenario = false;
    });

    builder.addCase(scenarioSaveAsNew.pending, setCreatingNamedScenario);
    builder.addCase(scenarioSaveAsNew.fulfilled, unsetCreatingNamedScenario);
    builder.addCase(scenarioDuplicate.pending, setCreatingNamedScenario);
    builder.addCase(scenarioDuplicate.fulfilled, unsetCreatingNamedScenario);

    builder.addCase(sharingPermissionAdd.pending, (state) => {
      state.isSharing = true;
    });

    builder.addCase(sharingPermissionAdd.fulfilled, (state) => {
      state.isSharing = false;
    });

    builder.addCase(workspacesFetch.pending, (state) => {
      state.isFetchingWorkspaces = true;
    });

    builder.addCase(workspacesFetch.fulfilled, (state) => {
      state.hasFetchedWorkspaces = true;
      state.isFetchingWorkspaces = false;
    });

    builder.addCase(workspacesReset, (state) => {
      state.hasFetchedWorkspaces = false;
    });

    builder.addCase(visualisationQuery.pending, incrementQueryCount);
    builder.addCase(visualisationQuery.fulfilled, decrementQueryCount);
    builder.addCase(visualisationQuery.rejected, decrementQueryCount);
    builder.addCase(dataFetch.pending, incrementQueryCount);
    builder.addCase(dataFetch.fulfilled, decrementQueryCount);
    builder.addCase(dataFetch.rejected, decrementQueryCount);

    builder.addCase(datasetImportSchema.fulfilled, (state, action) => {
      const { id } = action.payload;
      const {
        arg: { versionId },
      } = action.meta;

      if (isNil(versionId)) {
        state.newDatasetVersion = id;
      }
    });

    builder.addCase(datasetVersionCreate.fulfilled, (state, action) => {
      const { id } = action.payload;

      state.newDatasetVersion = id;
    });

    builder.addCase(serviceAccountKeyCreate.fulfilled, (state, action) => {
      state.newServiceAccountKey = action.payload;
    });

    builder.addCase(populationDatasetFetch.pending, (state) => {
      state.isFetchingPopulationDataset = true;
    });

    builder.addCase(populationDatasetFetch.fulfilled, (state) => {
      state.isFetchingPopulationDataset = false;
    });

    builder.addCase(populationDatasetFetch.rejected, (state) => {
      state.isFetchingPopulationDataset = false;
    });

    builder.addCase(workspaceCreate.pending, setCreating);
    builder.addCase(workspaceCreate.fulfilled, unsetCreating);
    builder.addCase(workspaceCreate.rejected, handleWorkspaceRejected);
    builder.addCase(workspaceDuplicate.pending, setCreating);
    builder.addCase(workspaceDuplicate.fulfilled, unsetCreating);
    builder.addCase(workspaceDuplicate.rejected, handleWorkspaceRejected);
    builder.addCase(workspaceDelete.pending, setDeleting);
    builder.addCase(workspaceDelete.fulfilled, unsetDeleting);

    builder.addCase(workspacePatch.pending, (state) => {
      state.isPatchingWorkspace = true;
    });

    builder.addCase(workspacePatch.fulfilled, (state) => {
      state.isPatchingWorkspace = false;
    });

    builder.addCase(geographyUpdate.pending, (state) => {
      state.isPatchingWorkspace = true;
    });

    builder.addCase(geographyUpdate.fulfilled, (state) => {
      state.isPatchingWorkspace = false;
    });

    builder.addCase(workspaceShareAccept.rejected, (state, action) => {
      state.expiredShare = action.payload;
    });
  },
});

export { reducer, actions };
