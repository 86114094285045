import styled from 'styled-components';
import { Dialog, UtilityButton } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 16px;
  transform: translateX(-50%);
  z-index: 3;
`;

const propTypes = {
  onSelectAll: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
};

const defaultProps = {};

const SelectAllButton = ({ onSelectAll, onClearAll, allSelected }) => {
  const currentState = useMemo(
    () => (allSelected ? 'clear-all' : 'select-all'),
    [allSelected],
  );
  const toggleState = useCallback(() => {
    switch (currentState) {
      case 'select-all': {
        onSelectAll();
        break;
      }
      case 'clear-all': {
        onClearAll();
        break;
      }
      default:
        break;
    }
  }, [currentState, onSelectAll, onClearAll]);
  const text = useMemo(() => {
    switch (currentState) {
      case 'select-all':
        return 'Select all';
      case 'clear-all':
        return 'Clear all';
      default:
        break;
    }
  }, [currentState]);

  return (
    <Wrapper>
      <Dialog>
        <Content padding='xsmall'>
          <UtilityButton magnitude='small' onClick={toggleState}>
            {text}
          </UtilityButton>
        </Content>
      </Dialog>
    </Wrapper>
  );
};

SelectAllButton.propTypes = propTypes;
SelectAllButton.defaultProps = defaultProps;

export { SelectAllButton };
