import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns/fp';
import {
  Avatar,
  Card,
  CardBanner,
  CardButton,
  CardContent,
  CardSubtitle,
  CardTitle,
  Tooltip,
} from '@kinesis/bungle';
import useDateDistance from 'hooks/useDateDistance';
import datasetTableImage from './dataset-table.png';

const propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
  }).isRequired,
  type: PropTypes.oneOf(['table', 'locations', 'attributes']).isRequired,
  updated: PropTypes.string.isRequired,
};

const defaultProps = {};

const alts = {
  table: 'Table',
  locations: 'Locations',
  attributes: 'Attributes',
};

const images = {
  table: datasetTableImage,
  // FUTURE: support dynamic maps..
  locations:
    'https://d2hg0qca760u9k.cloudfront.net/workspace-previews/134--25-1-604-144.png',
  attributes:
    'https://d2hg0qca760u9k.cloudfront.net/workspace-previews/134--25-1-604-144.png',
};

const DatasetCard = forwardRef(({ id, type, name, owner, updated }, ref) => {
  const updatedDate = useMemo(
    () => (updated ? parseISO(updated) : undefined),
    [updated],
  );
  const updatedDistance = useDateDistance(updatedDate);

  return (
    <Card ref={ref}>
      <CardButton as={Link} to={`/datasets/${id}`}>
        <CardBanner alt={alts[type]} image={images[type]}>
          <Tooltip placement='top' title={owner?.name}>
            <Avatar
              alt={owner?.name}
              appearance='outlined'
              image={owner?.avatarUrl}
              magnitude='large'
              variant='organisation'
            >
              {owner?.name?.slice(0, 1).toUpperCase()}
            </Avatar>
          </Tooltip>
        </CardBanner>
        <CardContent noWrap>
          <CardTitle>{name}</CardTitle>
          {updated && (
            <Tooltip title={format('d MMM yyyy, h:mm a', updatedDate)}>
              <CardSubtitle>Last updated {updatedDistance}</CardSubtitle>
            </Tooltip>
          )}
        </CardContent>
      </CardButton>
    </Card>
  );
});

DatasetCard.propTypes = propTypes;
DatasetCard.defaultProps = defaultProps;
export { DatasetCard };
