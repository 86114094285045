import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AppHeader } from 'components/app-header';
import Layout from 'components/layout';

const Rounded = styled(Layout)`
  border-radius: 0px 0 0 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.85);
  background-color: ${({ theme, variant }) =>
    variant === 'dark' ? theme.colors.gray2.hex : '#ffffff'};
  overflow: hidden;
`;

const propTypes = {
  header: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark']),
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  header: true,
  variant: 'dark',
};

const AppContainer = ({ children, header, variant }) => (
  <Rounded direction='column' variant={variant}>
    {header && <AppHeader />}
    {children}
  </Rounded>
);
AppContainer.propTypes = propTypes;
AppContainer.defaultProps = defaultProps;

export { AppContainer };
