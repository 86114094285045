import { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  filter,
  get,
  has,
  map,
  pipe,
  reduce,
  set,
  update,
  isEmpty,
  isNil,
  fromPairs,
} from 'lodash/fp';
import { Icon, Pill } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { Cell } from 'components/cell';
import { FineAreaCorrespondenceTableAreas } from './attribute-values.fine-area.table-areas';
import {
  fineareas,
  fineareasBySubregion,
  regions,
  subregions,
  geographyLabels,
} from './attribute-values.fine-area-correspondence.data';
import {
  Collapse,
  Row,
} from './attribute-values.fine-area-correspondence.styles';

const queryMatches = (query, v) => {
  if (isEmpty(query)) return true;
  const q = query.toLowerCase();
  return v.toLowerCase().includes(q);
};

const selectionMatches = (selection, value) => {
  if (selection === 'all') return true;
  if (selection === 'correspondence') return !isNil(value);
  if (selection === 'no-correspondence') return isNil(value);
  return true;
};

const subregionHasFineAreas = (selection, subregion, values) => {
  const candidates = fineareasBySubregion[subregion];
  const max = candidates.length;
  let candidate;
  let i;
  let value;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < max; ++i) {
    candidate = candidates[i];
    value = get(candidate.finearea, values);
    if (
      candidate.subregion === subregion &&
      selectionMatches(selection, value)
    ) {
      return true;
    }
  }
  return false;
};

const regionHasFineAreas = (subregionStates, region) => {
  const max = subregions.length;
  let candidate;
  let i;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < max; ++i) {
    candidate = subregions[i];
    if (candidate.region === region) {
      if (!get([candidate.subregion, 'hidden'], subregionStates)) {
        return true;
      }
    }
  }
  return false;
};

const propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  query: PropTypes.string,
  selection: PropTypes.oneOf(['all', 'correspondence', 'no-correspondence']),
  selected: PropTypes.array,
  granularity: PropTypes.oneOf(['regions', 'subregions', 'fineareas']),
};

const defaultProps = {
  query: '',
  selection: 'all',
  selected: [],
  granularity: 'regions',
};

const FineAreaCorrespondenceTable = ({
  values,
  setValues,
  query,
  selection,
  selected,
  granularity,
}) => {
  // These states are a mapping of key to a sparse boolean for open & hidden
  // open is the positive as the default is closed, hidden is the positive
  // as the default is visible, this allows the empty state a trivial representation
  // and allows us to avoid a build up of lots of values on start-up.
  const [regionState, setRegionState] = useState({});
  const [subregionState, setSubregionState] = useState({});

  const [selectedOpen, setSelectedOpen] = useState(selection);
  const toggleSelectedOpen = useCallback(
    () => setSelectedOpen((current) => !current),
    [setSelectedOpen],
  );

  const [activeSelection, setActiveSelection] = useState(selection);
  const [activeQuery, setActiveQuery] = useState(query);

  useEffect(() => {
    if (activeSelection === selection && activeQuery === query) return;
    setSubregionState((currentSubregions) => {
      const updatedSubregions = pipe(
        map(({ subregion }) => [
          subregion,
          {
            open: get([subregion, 'open'], currentSubregions),
            hidden:
              !queryMatches(query, subregion) ||
              !subregionHasFineAreas(selection, subregion, values),
          },
        ]),
        fromPairs,
      )(subregions);
      setRegionState((currentRegions) =>
        pipe(
          map(({ region }) => {
            const hidden = !regionHasFineAreas(updatedSubregions, region);
            return [
              region,
              {
                open:
                  get([region, 'open'], currentRegions) ||
                  (!hidden && !isEmpty(query) && activeQuery !== query),
                hidden,
              },
            ];
          }),

          fromPairs,
        )(regions),
      );
      return updatedSubregions;
    });
    setActiveSelection(selection);
    setActiveQuery(query);
  }, [selection, query, values, activeSelection, activeQuery]);

  const toggleRegion = useCallback(
    (region) => () => {
      setRegionState(update([region, 'open'], (open) => !open));
    },
    [setRegionState],
  );

  const toggleSubregion = useCallback(
    (subregion) => () => {
      setSubregionState(update([subregion, 'open'], (open) => !open));
    },
    [setSubregionState],
  );

  const onSave = useCallback(
    (finearea) => (value) => {
      if (value >= 0 && value <= 100) {
        setValues(set(finearea, value));
      }
    },
    [setValues],
  );

  const onSaveCascade = useCallback(
    (predicate) => (value) => {
      if (value >= 0 && value <= 100) {
        const targets = pipe(filter(predicate), map('finearea'))(fineareas);
        setValues((current) =>
          reduce((acc, el) => set(el, value, acc), current, targets),
        );
      }
    },
    [setValues],
  );

  const calculateFor = useCallback(
    (predicate) =>
      pipe(
        filter(predicate),
        reduce((acc, el) => {
          if (acc.multipleValues) {
            return acc;
          }
          const v = get(el.finearea, values);
          if (!has('singleValue', acc)) {
            return { singleValue: v };
          }
          if (v === acc.singleValue) {
            return acc;
          }
          return { multipleValues: true };
        }, {}),
      )(fineareas),
    [values],
  );

  const selectedLabels = useMemo(
    () => map((selectedId) => geographyLabels[selectedId], selected),
    [selected],
  );

  const isSelected = useCallback(
    (v) => {
      if (isEmpty(selectedLabels)) return false;
      if (granularity === 'regions') {
        return selectedLabels.includes(v.region);
      }
      if (granularity === 'subregions') {
        return selectedLabels.includes(v.subregion);
      }
      if (granularity === 'fineareas') {
        return selectedLabels.includes(v.finearea);
      }
      return false;
    },
    [selectedLabels, granularity],
  );

  const selectedValue = useMemo(
    () => (isEmpty(selected) ? {} : calculateFor(isSelected)),
    [calculateFor, selected, isSelected],
  );

  return (
    <Content height='calc(100% - 41px)' padding='none'>
      <FineAreaCorrespondenceTableAreas
        setValues={setValues}
        values={values}
        query={query}
        selection={selection}
        regionState={regionState}
        subregionState={subregionState}
        toggleRegion={toggleRegion}
        toggleSubregion={toggleSubregion}
        onSave={onSave}
        onSaveCascade={onSaveCascade}
      />
      {!isEmpty(selected) && (
        <>
          <Row>
            <Cell
              appearance='dark'
              onClick={toggleSelectedOpen}
              cursor='pointer'
              header
            >
              <Collapse>
                {!selectedOpen ? <Icon type='right' /> : <Icon type='down' />}
              </Collapse>
              {selected.length}
              {' selected '}
              {
                {
                  regions: 'regions',
                  subregions: 'sub-regions',
                  fineareas: 'fine areas',
                }[granularity]
              }
            </Cell>
            <Cell
              numeric
              editable
              multiple={!!selectedValue.multipleValues}
              value={selectedValue.singleValue || 0}
              onSave={onSaveCascade(isSelected)}
            >
              {selectedValue.singleValue || 0} <Pill>%</Pill>
            </Cell>
          </Row>
          {selectedOpen &&
            selected.map((selectedId) => {
              if (granularity === 'regions') {
                const region = geographyLabels[selectedId];
                const regionValue = calculateFor({ region });
                return (
                  <Row key={selectedId}>
                    <Cell indent='single'>{region}</Cell>
                    <Cell
                      numeric
                      editable
                      multiple={!!regionValue.multipleValues}
                      value={regionValue.singleValue || 0}
                      onSave={onSaveCascade({ region })}
                    >
                      {regionValue.singleValue || 0} <Pill>%</Pill>
                    </Cell>
                  </Row>
                );
              }

              if (granularity === 'subregions') {
                const subregion = geographyLabels[selectedId];
                const subregionValue = calculateFor({
                  subregion,
                });
                return (
                  <Row key={selectedId}>
                    <Cell indent='single'>{subregion}</Cell>
                    <Cell
                      numeric
                      editable
                      multiple={!!subregionValue.multipleValues}
                      value={subregionValue.singleValue || 0}
                      onSave={onSaveCascade({ subregion })}
                    >
                      {subregionValue.singleValue || 0} <Pill>%</Pill>
                    </Cell>
                  </Row>
                );
              }

              if (granularity === 'fineareas') {
                const finearea = geographyLabels[selectedId];
                const value = get(finearea, values);
                return (
                  <Row key={selectedId}>
                    <Cell indent='single'>{finearea}</Cell>
                    <Cell
                      numeric
                      editable
                      value={value || 0}
                      onSave={onSave(finearea)}
                    >
                      {value || 0} <Pill>%</Pill>
                    </Cell>
                  </Row>
                );
              }

              return null;
            })}
        </>
      )}
    </Content>
  );
};

FineAreaCorrespondenceTable.propTypes = propTypes;
FineAreaCorrespondenceTable.defaultProps = defaultProps;

export { FineAreaCorrespondenceTable };
