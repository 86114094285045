import styled from 'styled-components';

const SidePane = styled.div`
  flex: 0 0 304px;
  width: 304px;
  overflow-y: auto;
`;

const NameInput = styled.div`
  flex: 0 0 auto;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

export { NameInput, SidePane };
