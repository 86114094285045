import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { easeQuadOut } from 'd3-ease';
import { noop } from 'lodash/fp';
import { Tooltip, UtilityButton } from '@kinesis/bungle';
import { Toolbar, ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { keyboardShortcutString } from 'utils/keyboardUtils';
import {
  ModificationsListComponent,
  ModificationsListActionsComponent,
  ModificationsListItemComponent,
  ModificationsListBodyComponent,
} from './modifications-list.styles';
import ModificationsListItem from './ModificationsListItem';
import Bowser from 'bowser';
const { getParser } = Bowser;

const isMacOS = getParser(window.navigator.userAgent).getOSName() === 'macOS';

const AnimatedModificationsListItemComponent = motion(
  ModificationsListItemComponent,
);

const propTypes = {
  modificationItems: PropTypes.array.isRequired,
  onUndo: PropTypes.func,
  onRedo: PropTypes.func,
};

const defaultProps = {
  onUndo: noop,
  onRedo: noop,
};

const ModificationsList = ({ modificationItems, onUndo, onRedo }) => (
  <ModificationsListComponent>
    <ModificationsListActionsComponent>
      <Toolbar justify='space-between'>
        <ToolbarGroup>
          <ToolbarItem>
            <Tooltip
              title={`Undo (${keyboardShortcutString({
                key: 'z',
                primaryModifier: true,
              })})`}
              placement='top'
            >
              <UtilityButton
                magnitude='small'
                disabled={!onUndo}
                icon='undo'
                onClick={onUndo}
              />
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip
              title={`Redo (${
                isMacOS
                  ? keyboardShortcutString({
                      key: 'z',
                      primaryModifier: true,
                      shift: true,
                    })
                  : keyboardShortcutString({
                      key: 'y',
                      primaryModifier: true,
                    })
              })`}
              placement='top'
            >
              <UtilityButton
                magnitude='small'
                disabled={!onRedo}
                icon='redo'
                onClick={onRedo}
              />
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </Toolbar>
    </ModificationsListActionsComponent>
    <ModificationsListBodyComponent>
      <AnimatePresence initial={false}>
        {modificationItems.map((item) => (
          <AnimatedModificationsListItemComponent
            key={item.id}
            initial={{
              opacity: 0,
              height: 0,
            }}
            animate={{
              opacity: 1,
              height: 'auto',
            }}
            exit={{
              opacity: 0,
              height: 0,
            }}
            transition={{ ease: easeQuadOut, duration: 0.1 }}
          >
            <ModificationsListItem modification={item} key={item.id} />
          </AnimatedModificationsListItemComponent>
        ))}
      </AnimatePresence>
    </ModificationsListBodyComponent>
  </ModificationsListComponent>
);

ModificationsList.propTypes = propTypes;
ModificationsList.defaultProps = defaultProps;

export default ModificationsList;
