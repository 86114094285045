import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const scenarioDiscard = createAsyncThunk(
  'scenario/discard',
  async ({ workspaceId, tagName }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.delete(
      `/api/workspace/${workspaceId}/tag/${tagName}`,
    );

    return response;
  },
);

export { scenarioDiscard };
