import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get, pipe, isNil } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';
import resourceAvailableSelector from 'selectors/resourceAvailableSelector';
import { availabilityFetch } from 'actions/availabilityFetch';

import useSelectorWithProps from 'hooks/useSelectorWithProps';

const propTypes = {
  children: PropTypes.node.isRequired,
  scenarioId: PropTypes.number.isRequired,
  scenarioKey: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const ResourceAvailable = ({
  children,
  resource,
  scenarioKey,
  workspaceId,
}) => {
  const dispatch = useDispatch();
  const current = useSelectorWithProps(resourceAvailableSelector, {
    resource,
    scenarioKey,
    workspaceId,
  });
  const status = useSelectorWithProps(
    pipe(workspaceSelector, get(['availability', 'polling'])),
    { workspaceId },
  );

  useEffect(() => {
    if (scenarioKey && isNil(current) && status === 'idle') {
      dispatch(availabilityFetch({ scenarioKey, workspaceId }));
    } else if (scenarioKey && current === false && status === 'idle') {
      const timerId = setTimeout(() => {
        dispatch(availabilityFetch({ scenarioKey, workspaceId }));
      }, 3000);
      return () => clearTimeout(timerId);
    }
  }, [dispatch, current, scenarioKey, status, workspaceId]);

  return children;
};

ResourceAvailable.propTypes = propTypes;
ResourceAvailable.defaultProps = defaultProps;

export { ResourceAvailable };
