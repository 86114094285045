import { createSelector } from 'reselect';
import { get, isEqual, mapValues } from 'lodash/fp';
import blockScenarioKeysSelector from './blockScenarioKeysSelector';
import scenariosSelector from './scenariosSelector';

const isScenarioMismatchSelector = createSelector(
  blockScenarioKeysSelector,
  scenariosSelector,
  (blockScenarioKeys, allScenarios) => {
    const scenariosWithKeys = mapValues(
      (s) => get(['draft', 'scenario'], s) || get(['published', 'scenario'], s),
    )(allScenarios);
    return !isEqual(scenariosWithKeys, blockScenarioKeys);
  },
);

export default isScenarioMismatchSelector;
