import PropTypes from 'prop-types';
import { LegacyContent } from './legacy-content.styles';

const propTypes = {
  appearance: PropTypes.oneOf(['dark', 'light']),
  spacing: PropTypes.oneOf(['medium', 'small']),
};

const defaultProps = {
  appearance: 'light',
  spacing: 'medium',
};

LegacyContent.propTypes = propTypes;
LegacyContent.defaultProps = defaultProps;

export { LegacyContent };
