import { createSlice } from '@reduxjs/toolkit';
import { and, itemisedAttribute } from 'data/filters';
import { map, reject, set, update } from 'lodash/fp';

const { reducer, actions } = createSlice({
  name: 'locationFilters',
  initialState: and([]),
  reducers: {
    addFilter(state, action) {
      return update(
        'operand',
        (value) => [
          ...value,
          itemisedAttribute(
            'any',
            action.payload.operand,
            action.payload.attributeType,
          ),
        ],
        state,
      );
    },
    clearFilter(state, action) {
      return update('operand', reject({ key: action.payload }), state);
    },
    updateFilter(state, action) {
      return update(
        'operand',
        map((o) => (o.key === action.payload.key ? action.payload : o)),
        state,
      );
    },
    clearAllFilters(state) {
      return set('operand', [], state);
    },
  },
});

export { reducer, actions };
