import { createSelector } from 'reselect';
import { includes } from 'lodash/fp';
import workspaceRequirementsSelector from './workspaceRequirementsSelector';

const enablePopulationResourceSelector = createSelector(
  workspaceRequirementsSelector,
  includes('custom:dpie:population'),
);

export default enablePopulationResourceSelector;
