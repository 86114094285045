import { ScatterplotLayer } from '@deck.gl/layers';
import chroma from 'chroma-js';

const fillColor = (layerColor, hovering) => [
  ...chroma(layerColor.fill).rgb(),
  hovering ? 115 : 64,
];

const lineColor = (layerColor) => chroma(layerColor.outline).rgb();

const single = [{}];

const crosshairLayer = ({ layerColor, latitude, longitude }) =>
  new ScatterplotLayer({
    id: 'location-edit-focus-layer',
    data: single,
    pickable: false,
    stroked: false,
    filled: true,
    radiusMinPixels: 2,
    getRadius: 2,
    getPosition: [longitude, latitude],
    getFillColor: lineColor(layerColor),
    getLineWidth: 0,
    updateTriggers: {
      getPosition: [longitude, latitude],
    },
  });

const editLayer = ({
  hovering,
  latitude,
  longitude,
  layerColor,
  onClick,
  onDrag,
  onDragStart,
  onDragEnd,
  onHover,
}) =>
  new ScatterplotLayer({
    id: 'location-edit-layer',
    data: single,
    pickable: true,
    stroked: true,
    filled: true,
    radiusMinPixels: 12,
    lineWidthMinPixels: 2,
    getRadius: 24,
    getPosition: [longitude, latitude],
    getFillColor: fillColor(layerColor, hovering),
    getLineColor: lineColor(layerColor),
    getLineWidth: 4,
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onHover,
    updateTriggers: {
      getPosition: [longitude, latitude],
      getFillColor: [hovering],
    },
  });

const editLayers = ({
  latitude,
  longitude,
  layerColor,
  hovering,
  ...events
}) => {
  if (!latitude || !longitude) return [];
  const layers = [];
  layers.push(
    editLayer({ latitude, longitude, layerColor, hovering, ...events }),
  );
  layers.push(crosshairLayer({ latitude, longitude, layerColor }));
  return layers;
};

export default editLayers;
