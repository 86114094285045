import styled from 'styled-components';

const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: auto;
`;

export { ScrollableContent };
