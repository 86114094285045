import { createCachedSelector } from 're-reselect';
import { first, get, includes, map, pipe, values, uniq } from 'lodash/fp';
import Units from 'data/units';
import { immutableSort } from 'utils/charts/series';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from './blockValueSelector';
import schemaSelector from './schemaSelector';
import locationsSelector from './locationsSelector';
import workspaceInstantiationsSelector from './workspaceInstantiationsSelector';

const units = new Units();

const isContext = pipe(get('classification'), includes('context'));

const publicVisualisationContextsSelector = createCachedSelector(
  blockStateSelector,
  blockValueSelector,
  schemaSelector,
  locationsSelector,
  workspaceInstantiationsSelector,
  (state, props) => props.perspectives,
  (
    blockState,
    blockValue,
    schema,
    locations,
    instantiations,
    selectedPerspectives,
  ) => {
    if (!blockValue) {
      return [];
    }
    const publicPerspectives = get(blockState.id, selectedPerspectives);

    const groups =
      pipe(
        get([get('visualisation', blockValue), 'query', 'group']),
        map('column'),
      )(blockValue) || [];

    return values(schema)
      .filter(isContext)
      .filter((column) => !groups.includes(column.key))
      .map((sch) => {
        const unit = units.parseColumn(sch, locations, instantiations);
        const itemData = pipe(
          get('perspectives'),
          map(get(['perspective', sch.key])),
          uniq,
        )(blockState);
        const options = immutableSort(unit.compare, itemData);

        return {
          key: sch.key,
          format: unit.formatCell,
          label: sch.label,
          options,
          type: 'select',
          value:
            get(sch.key, publicPerspectives) ||
            get(['filters', sch.key], blockState) ||
            first(options),
        };
      });
  },
)((state, props) => props.blockId);

export default publicVisualisationContextsSelector;
