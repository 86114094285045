import styled from 'styled-components';

const DataGridRowWrapper = styled('div').attrs({
  role: 'row',
})(({ theme }) => ({
  backgroundColor: 'white',
  borderBottom: `1px solid ${theme.borderColor}`,
  boxSizing: 'border-box',
  '&:last-child': {
    borderBottomWidth: 0,
  },
}));

const DataGridRowInner = styled('div')({
  alignItems: 'stretch',
  boxSizing: 'border-box',
  display: 'flex',
  height: 30,
  justifyContent: 'flex-start',
  position: 'relative',
});

const DataGridRowHeader = styled('div').attrs({
  role: 'rowheader',
})(
  ({ theme }) => ({
    alignItems: 'center',
    backgroundColor: '#fafafa',
    borderRight: `1px solid ${theme.borderColor}`,
    boxSizing: 'border-box',
    display: 'flex',
    flex: '0 0 50%',
    minWidth: 0,
    padding: '0 12px',
    position: 'relative',
  }),
  ({ theme, variant }) =>
    ({
      primary: {
        color: theme.color.black9,
        fontWeight: theme.fontWeight.medium,
      },
      secondary: {
        paddingLeft: 24,
      },
    })[variant],
);

const DataGridRowHeaderTitle = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export {
  DataGridRowWrapper,
  DataGridRowInner,
  DataGridRowHeader,
  DataGridRowHeaderTitle,
};
