import { createSelector } from 'reselect';
import { get, has } from 'lodash/fp';

const hasFineareaGeographySelector = createSelector(
  (state, props) =>
    get(['geography', 'dataIds', `fine-area-${props.granularity}`], state),
  get(['geography', 'bundles']),
  (bundleId, bundles) => has(bundleId, bundles),
);

export default hasFineareaGeographySelector;
