import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const workspaceInstantiationsSelector = createSelector(
  workspaceSelector,
  get('instantiations'),
);

export default workspaceInstantiationsSelector;
