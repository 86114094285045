import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const populationDatasetDownload = createAsyncThunk(
  'population-dataset/download',
  async ({ datasetId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(
      `/api/resources/custom:dpie:population/datasets/${datasetId}/download`,
    );
    window.location = response.data.url;
    return response.data;
  },
);

export { populationDatasetDownload };
