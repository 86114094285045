import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceShareAccept = createAsyncThunk(
  'workspace-share/accept',
  async ({ token }, { dispatch, getState, rejectWithValue }) => {
    const api = createApiFromState({ dispatch, getState });

    try {
      const response = await api.post(`/api/workspace-shares/accept/${token}`);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        workspaceName: err.response.data.workspace_name,
      });
    }
  },
);

export { workspaceShareAccept };
