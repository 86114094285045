import { H3HexagonLayer } from '@deck.gl/geo-layers';
import { filter, get } from 'lodash/fp';

const hexLayers = ({
  data,
  focus,
  visible,
  hoverId,
  selectedId,
  onClick,
  onHover,
}) => [
  new H3HexagonLayer({
    id: 'hex-base-layer',
    visible,
    data,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: false,
    lineWidthMinPixels: 0.5,
    getFillColor: get(['colors', 'base']),
    getLineWidth: 0,
    onClick,
    onHover,
  }),
  new H3HexagonLayer({
    id: 'hex-light-hover-layer',
    visible: visible && hoverId && hoverId !== selectedId && !focus,
    data: filter({ hexagon: hoverId }, data),
    pickable: false,
    stroked: true,
    filled: false,
    extruded: false,
    lineWidthMinPixels: 2,
    getFillColor: [0, 0, 0, 0],
    getLineColor: get(['colors', 'deselectedBorder']),
    getLineWidth: 4,
  }),
  new H3HexagonLayer({
    id: 'hex-diff-base-layer',
    visible: visible && focus,
    data,
    pickable: false,
    stroked: false,
    filled: true,
    extruded: false,
    lineWidthMinPixels: 0.1,
    getFillColor: get(['colors', 'diff']),
    getLineWidth: 0,
  }),
  new H3HexagonLayer({
    id: 'hex-selected-layer',
    visible: visible && selectedId,
    data: filter({ hexagon: selectedId }, data),
    pickable: false,
    stroked: true,
    filled: true,
    extruded: false,
    lineWidthMinPixels: 2,
    getFillColor: get(['colors', 'diff']),
    getLineColor: get(['colors', 'selectedBorder']),
    getLineWidth: 4,
  }),
  new H3HexagonLayer({
    id: 'hex-dark-hover-layer',
    visible: visible && hoverId && focus && hoverId !== selectedId,
    data: filter({ hexagon: hoverId }, data),
    pickable: false,
    stroked: true,
    filled: false,
    extruded: false,
    lineWidthMinPixels: 2,
    getFillColor: [0, 0, 0, 0],
    getLineColor: get(['colors', 'selectedBorder']),
    getLineWidth: 4,
  }),
];

export default hexLayers;
