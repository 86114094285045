import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import PropTypes from 'prop-types';
import { every, get, has, map, pipe } from 'lodash/fp';
import {
  AnimatedPopover,
  H2,
  Icon,
  Listbox,
  Tooltip,
  UtilityButton,
} from '@kinesis/bungle';
import {
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
  ToolbarSeparator,
} from '@kinesis/bungle/legacy';
import { labelOf } from 'data/attributes';
import LayerCircle from 'components/layer-circle/LayerCircle';
import workspaceInstantiationSelector from 'selectors/workspaceInstantiationSelector';
import locationSelector from 'selectors/locationSelector';
import scenarioSelector from 'selectors/scenarioSelector';
import scenariosWithAttributesSelector from 'selectors/scenariosWithAttributesSelector';
import {
  AttributeValuesHeaderWrapper,
  BaselineIconWrapper,
} from './attribute-values.styles';

const propTypes = {
  locationId: PropTypes.number.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  scenarioId: PropTypes.number.isRequired,
  scenarioIds: PropTypes.array.isRequired,
  setScenarioIds: PropTypes.func.isRequired,
  setToBaseline: PropTypes.func.isRequired,
  attributeKey: PropTypes.string.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const AttributeValuesHeader = ({
  attributeKey,
  locationId,
  privacy,
  scenarioId,
  scenarioIds,
  setScenarioIds,
  setToBaseline,
  workspaceId,
}) => {
  const scenarioListRef = useRef();
  const [scenarioListOpen, setScenarioListOpen] = useState(false);
  const closeScenarioList = useCallback(() => {
    setScenarioListOpen(false);
  }, [setScenarioListOpen]);

  const toggleScenarioListOpen = useCallback(
    () => setScenarioListOpen((current) => !current),
    [setScenarioListOpen],
  );

  const location = useSelectorWithProps(locationSelector, {
    locationId,
    public: privacy === 'public',
    workspaceId,
  });

  const instantiation = useSelectorWithProps(workspaceInstantiationSelector, {
    key: attributeKey,
    public: privacy === 'public',
    workspaceId,
  });

  const scenariosWithAttributes = useSelectorWithProps(
    scenariosWithAttributesSelector,
    { public: privacy === 'public', workspaceId },
  );

  const scenario = useSelectorWithProps(scenarioSelector, {
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });

  const scenarioOptions = useMemo(
    () =>
      scenariosWithAttributes.map((s) => {
        const isAvailable = has(get(['values', locationId], s.attributes));
        return {
          disabled: s.id === scenarioId || !isAvailable,
          info: !isAvailable ? 'Location unavailable' : undefined,
          label: s.name,
          value: s.id,
        };
      }),
    [locationId, scenarioId, scenariosWithAttributes],
  );

  const isSelectedDefault = useSelector(
    useCallback(
      (state) =>
        pipe(
          map((id) =>
            scenarioSelector(state, {
              public: privacy === 'public',
              scenarioId: id,
              workspaceId,
            }),
          ),
          every('isDefault'),
        )(scenarioIds),
      [privacy, scenarioIds, workspaceId],
    ),
  );

  const label = useSelector(
    useCallback(
      (state) => {
        if (scenarioIds.length === 1) {
          return get(
            'name',
            scenarioSelector(state, {
              scenarioId: scenarioIds[0],
              workspaceId,
            }),
          );
        }

        return scenarioOptions.length === scenarioIds.length
          ? 'All scenarios'
          : `${scenarioIds.length} scenarios`;
      },
      [scenarioIds, scenarioOptions.length, workspaceId],
    ),
  );

  return (
    <AttributeValuesHeaderWrapper>
      <Toolbar justify='space-between'>
        <ToolbarGroup>
          <ToolbarItem>
            <H2 noWrap>{labelOf(instantiation)}</H2>
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup flex={1}>
          <ToolbarItem style={{ marginLeft: 'auto' }}>
            <LayerCircle layerId={location.layer} workspaceId={workspaceId} />
          </ToolbarItem>
          <ToolbarItem flex={1}>{location.label || 'New location'}</ToolbarItem>
          <ToolbarItem flex={1}>
            <UtilityButton
              ref={scenarioListRef}
              disabled={privacy === 'public'}
              dropdown
              expanded={scenarioListOpen}
              onClick={toggleScenarioListOpen}
            >
              {label}
              {isSelectedDefault && (
                <BaselineIconWrapper>
                  <Icon type='baseline' />
                </BaselineIconWrapper>
              )}
            </UtilityButton>
          </ToolbarItem>
          <AnimatedPopover
            justify='end'
            offset={4}
            open={scenarioListOpen}
            placement='bottom'
            targetRef={scenarioListRef}
          >
            <Listbox
              autoFocus
              buttonRef={scenarioListRef}
              closeOnBlur
              closeOnClickOutside
              closeOnSelect
              onChange={setScenarioIds}
              onClose={closeScenarioList}
              options={scenarioOptions}
              values={scenarioIds}
              width={240}
              multi
            />
          </AnimatedPopover>
          {privacy !== 'public' && (
            <>
              <ToolbarSeparator />
              <ToolbarItem>
                <Tooltip title='Set to baseline' placement='top'>
                  <UtilityButton
                    disabled={scenario.isDefault}
                    icon='set-to-baseline'
                    onClick={setToBaseline}
                  />
                </Tooltip>
              </ToolbarItem>
            </>
          )}
        </ToolbarGroup>
      </Toolbar>
    </AttributeValuesHeaderWrapper>
  );
};

AttributeValuesHeader.defaultProps = defaultProps;
AttributeValuesHeader.propTypes = propTypes;

export default AttributeValuesHeader;
