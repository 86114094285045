import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { Icon, Stack, Strong, Text, UtilityButton } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { pathwayCalculate } from 'actions/pathwayCalculate';
import usePathwayInputs from 'hooks/usePathwayInputs';
import { PlaceholderContainer } from './pathways-visualisations.styles';

// NOTE: This is used as a workaround for not being able to center Text/Strong text.
const AlignedText = styled('div')`
  text-align: center;
`;

const PathwaysVisualisationsError = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [inputs] = usePathwayInputs();

  const handleClick = useCallback(() => {
    dispatch(pathwayCalculate(inputs));
  }, [dispatch, inputs]);

  return (
    <PlaceholderContainer>
      <Content height='100%' alignY='center'>
        <Stack alignX='center' space='medium'>
          <Icon
            magnitude='large'
            color={theme.color.red4}
            type='close-circle'
          />
          <AlignedText>
            <Stack space='xsmall'>
              <Strong>We couldn’t generate a preview of this pathway</Strong>
              <Text>Please check your internet connection and try again.</Text>
            </Stack>
          </AlignedText>
          <UtilityButton icon='redo' onClick={handleClick}>
            Try again
          </UtilityButton>
        </Stack>
      </Content>
    </PlaceholderContainer>
  );
};

export { PathwaysVisualisationsError };
