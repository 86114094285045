import { createSelector } from 'reselect';
import { get, isEqual, negate, pipe } from 'lodash/fp';
import blockDataVersionSelector from './blockDataVersionSelector';
import blockValueSelector from './blockValueSelector';

const isCapsuleMismatchSelector = createSelector(
  blockDataVersionSelector,
  pipe(blockValueSelector, get('address')),
  (blockDataVersion, version) => negate(isEqual)(blockDataVersion, version),
);

export default isCapsuleMismatchSelector;
