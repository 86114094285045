import { createSlice } from '@reduxjs/toolkit';
import { merge, pipe, set, update, unset } from 'lodash/fp';
import { datasetVersionUpload } from 'actions/datasetVersionUpload';

const { reducer, actions } = createSlice({
  name: 'uploads',
  initialState: {},

  reducers: {
    progress: (state, action) =>
      update(
        action.payload.key,
        (current) => merge(current, action.payload),
        state,
      ),
    clear: (state, action) => unset(action.payload.key, state),
  },

  extraReducers: (builder) => {
    builder.addCase('dataset/upload/pending', (state, action) =>
      set(
        [action.meta.arg.key],
        {
          state: 'pending',
          key: action.meta.arg.key,
          format: action.meta.arg.format,
          dataset: action.meta.arg.datasetId,
          source: action.meta.arg.file.name,
        },
        state,
      ),
    );

    builder.addCase('dataset/upload/fulfilled', (state, action) =>
      pipe(
        set([action.meta.arg.key, 'state'], 'complete'),
        set([action.meta.arg.key, 'id'], action.payload.id),
      )(state),
    );

    builder.addCase('dataset/upload/rejected', (state, action) =>
      set([action.meta.arg.key, 'state'], 'failed', state),
    );

    builder.addCase(datasetVersionUpload.fulfilled, (state, action) =>
      unset(action.payload.key, state),
    );

    builder.addCase('population-dataset/upload/pending', (state, action) =>
      set(
        [action.meta.arg.key],
        {
          state: 'pending',
          key: action.meta.arg.key,
          format: action.meta.arg.format,
          dataset: action.meta.arg.datasetId,
          source: action.meta.arg.file.name,
        },
        state,
      ),
    );

    builder.addCase('population-dataset/upload/fulfilled', (state, action) =>
      unset(action.meta.arg.key, state),
    );

    builder.addCase('population-dataset/upload/rejected', (state, action) =>
      unset(action.meta.arg.key, state),
    );
  },
});

export { reducer, actions };
