import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const appsList = createAsyncThunk(
  'apps/list',
  async (arg, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.get('/api/apps');

    return response.data;
  },
);

export { appsList };
