import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const sharingPermissionChange = createAsyncThunk(
  'sharing-permission/change',
  async (
    { sourceIdentity, permissionLevel, workspaceId },
    { dispatch, getState },
  ) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.patch(`/api/sharing/change-permission`, {
      targetIdentity: `workspace/${workspaceId}`,
      sourceIdentity,
      permissionLevel,
    });

    return response.data;
  },
);

export { sharingPermissionChange };
