import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import PropTypes from 'prop-types';
import { apply, filter, map, flatMap, get, pipe, isEmpty } from 'lodash/fp';
import {
  distributionRows,
  settingByKey,
  newItemsPatch,
  editedLabelsPatch,
  labelOf,
} from 'data/attributes';
import { useToast, Toaster } from '@kinesis/bungle';
import { workspacePatch } from 'actions/workspacePatch';
import Modal from 'components/modals/modal/Modal';
import attributeSettingSelector from 'selectors/attributeSettingSelector';
import workspaceInstantiationSelector from 'selectors/workspaceInstantiationSelector';
import baselineScenarioKeySelector from 'selectors/baselineScenarioKeySelector';
import scenariosSelector from 'selectors/scenariosSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import AttributeSettingsHeader from './attribute-settings.header';
import UsageSettings from './attribute-settings.usage';
import ServiceSettings from './attribute-settings.service';
import RainwaterStorageSettings from './attribute-settings.rainwater-storage';
import RecycledWaterSettings from './attribute-settings.recycled-water';
import SpatialSettings from './attribute-settings.spatial';
import SolarSettings from './attribute-settings.solar';
import DemographySettings from './attribute-settings.demography';
import LandSettings from './attribute-settings.land';
import FineAreaCorrespondenceSettings from './attribute-settings.fine-area-correspondence';
import CustomSettings from './attribute-settings.custom';

const propTypes = {
  animation: PropTypes.bool,
  attributeKey: PropTypes.string.isRequired,
  scenarioId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  initialSelection: PropTypes.string,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  animation: true,
  initialSelection: undefined,
};

const settings = {
  'usage-v2': UsageSettings,
  usage: UsageSettings,
  'service-v2': ServiceSettings,
  service: ServiceSettings,
  spatial: SpatialSettings,
  'rainwater-tank': RainwaterStorageSettings,
  'recycled-water-system': RecycledWaterSettings,
  solar: SolarSettings,
  demography: DemographySettings,
  land: LandSettings,
  'fine-area-correspondence': FineAreaCorrespondenceSettings,
  custom: CustomSettings,
};

const AttributeSettings = ({
  animation,
  attributeKey,
  initialSelection,
  onClose,
  scenarioId,
  workspaceId,
}) => {
  const [persistScenarioIds, setPersistScenarioIds] = useState([scenarioId]);
  const [errors, setErrors] = useState([]);
  const toast = useToast('attributeSettingsPaneToaster');

  const scenarios = useSelectorWithProps(scenariosSelector, { workspaceId });
  const scenario = get(scenarioId, scenarios);
  const setting = useSelectorWithProps(attributeSettingSelector, {
    key: attributeKey,
    scenarioId,
    workspaceId,
  });
  const instantiation = useSelectorWithProps(workspaceInstantiationSelector, {
    key: attributeKey,
    workspaceId,
  });
  const type = instantiation.attribute;

  const [settingState, setSettingState] = useState(setting);
  const [instantiationState, setInstantiationState] = useState(instantiation);

  const Detail = get(type, settings) ?? get('custom', settings);

  const baselineScenarioKey = useSelectorWithProps(
    baselineScenarioKeySelector,
    { workspaceId },
  );
  const baselineAttributes = useSelectorWithProps(
    pipe(workspaceSelector, get(['attributes', baselineScenarioKey])),
    { workspaceId },
  );

  const onSetToBaseline = useCallback(() => {
    const replacement = settingByKey(attributeKey)(baselineAttributes);
    setSettingState(replacement);
  }, [baselineAttributes, attributeKey]);

  const dispatch = useDispatch();

  const saveable = [
    'usage-v2',
    'service-v2',
    'rainwater-tank',
    'recycled-water-system',
  ].includes(type);

  const itemAddition = ['usage-v2'].includes(type);

  useEffect(() => {
    const timer = setTimeout(() => {
      const es = itemAddition
        ? pipe(
            flatMap((category) =>
              distributionRows(category, instantiationState),
            ),
            filter((total) => total.error),
            map((total) => ({
              error: total.error,
              categoryKey: total.category.key,
            })),
          )(get('categories', settingState) || [])
        : undefined;
      setErrors(es);
    }, 125);
    return () => {
      clearTimeout(timer);
    };
  }, [itemAddition, settingState, instantiationState]);

  const onSave = useCallback(() => {
    const es = itemAddition
      ? pipe(
          flatMap((category) => distributionRows(category, instantiationState)),
          filter((total) => total.error),
          map((total) => ({
            error: total.error,
            categoryKey: total.category.key,
          })),
        )(get('categories', settingState) || [])
      : undefined;
    setErrors(es);
    if (!isEmpty(es)) {
      toast('Please enter valid values to continue.', { variant: 'error' });
      return;
    }

    // This looks/is dumb, it is to allow onBlur to fire before we pick up the values,
    // there must be a better way, but at the moments the components race, and this
    // ensures onBlur wins, and save happens after, it probably only needs to be 0,
    // to give up the thread, but there isn't a reliable test case that verifies this.
    setTimeout(() => {
      dispatch(
        workspacePatch({
          targetScenarioIds: persistScenarioIds,
          patchOps: [
            {
              'save-definition': {
                definition: settingState,
              },
            },
            ...pipe(
              map(newItemsPatch),
              apply((definition, instance) =>
                !isEmpty(instance) && itemAddition
                  ? [{ 'add-item': { definition, instantiation: instance } }]
                  : [],
              ),
            )([settingState, instantiationState]),
            ...pipe(editedLabelsPatch, (patch) =>
              !isEmpty(patch)
                ? [{ 'label-item': { instantiation: patch } }]
                : [],
            )(instantiationState),
          ],
          actions: [
            {
              type: 'modify-attribute-setting',
              value: {
                name: attributeKey,
              },
            },
          ],
          optimisticState: {
            settings: [settingState],
            instances: [instantiationState],
          },
          workspaceId,
        }),
      );
      onClose();
    }, 100);
  }, [
    itemAddition,
    settingState,
    instantiationState,
    toast,
    dispatch,
    persistScenarioIds,
    attributeKey,
    workspaceId,
    onClose,
  ]);

  const header = (
    <AttributeSettingsHeader
      attributeKey={attributeKey}
      scenarioId={scenarioId}
      setScenarioIds={setPersistScenarioIds}
      scenarioIds={persistScenarioIds}
      setToBaseline={onSetToBaseline}
      workspaceId={workspaceId}
    />
  );

  return (
    <>
      <Toaster id='attributeSettingsPaneToaster' placement='top' />
      <Modal
        animation={animation}
        aria-label={`${labelOf(instantiation)} settings`}
        header={header}
        magnitude='large'
        onClose={onClose}
        onSave={onSave}
        saveable={saveable}
      >
        <Detail
          errors={errors}
          initialSelection={initialSelection}
          instantiation={instantiationState}
          itemAddition={itemAddition}
          scenario={scenario}
          setErrors={setErrors}
          setInstantiation={setInstantiationState}
          setSetting={setSettingState}
          setting={settingState}
          workspaceId={workspaceId}
        />
      </Modal>
    </>
  );
};

AttributeSettings.defaultProps = defaultProps;
AttributeSettings.propTypes = propTypes;

export default AttributeSettings;
