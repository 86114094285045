import { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  dotPlotClickHandler,
  handleBubbleSelectPointValue,
  returnBubblesToUnselectedState,
} from 'utils/charts/bubble';
import bubbleChartCapsuleSelector from 'selectors/bubbleChartCapsuleSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import ChartImpl from './ChartImpl';
import BubbleLegend from './BubbleLegend';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  boardId: PropTypes.number.isRequired,
  perspectives: PropTypes.object,
  privacy: PropTypes.oneOf(['private', 'public']),
  scenarioId: PropTypes.number.isRequired,
  showLegend: PropTypes.bool,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  perspectives: undefined,
  privacy: 'private',
  showLegend: false,
};

const BubbleChart = ({
  blockId,
  boardId,
  perspectives,
  privacy,
  scenarioId,
  showLegend,
  workspaceId,
  ...restProps
}) => {
  const { options, series } = useSelectorWithProps(bubbleChartCapsuleSelector, {
    blockId,
    boardId,
    perspectives,
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });

  const handleCrosshairClick = useCallback((chart) => {
    dotPlotClickHandler(chart);
  }, []);

  const handleSelectPointValue = useCallback((chart, value) => {
    handleBubbleSelectPointValue(chart, value);
  }, []);

  return (
    <ChartImpl
      legend={showLegend ? <BubbleLegend series={series} /> : undefined}
      onCrosshairClick={handleCrosshairClick}
      onExternalClick={returnBubblesToUnselectedState}
      onSelectPointValue={handleSelectPointValue}
      options={options}
      {...restProps}
    />
  );
};

BubbleChart.propTypes = propTypes;
BubbleChart.defaultProps = defaultProps;

export default BubbleChart;
