import PropTypes from 'prop-types';
import { MenuItem, MenuIcon } from './icon-menu-item.styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
};

const IconMenuItem = ({ children, icon }) => (
  <MenuItem>
    <MenuIcon type={icon} magnitude='xsmall' />
    {children}
  </MenuItem>
);

IconMenuItem.propTypes = propTypes;

export { IconMenuItem };
