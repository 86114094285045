import styled from 'styled-components';
import { Card, CardsWrapper } from 'components/card/card.styles';
import { rgba } from 'polished';

const BlockGridWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: ${({ enableReorderBlocks }) =>
    enableReorderBlocks ? '10px 0px' : '0 0 8px 0'};
  position: relative;
`;

const BlockRowGrid = styled(CardsWrapper)(
  {
    gridTemplateColumns: 'repeat(6, 1fr)',
    maxWidth: '1024px',
    position: 'relative',
  },
  ({ enableReorderBlocks }) => ({
    padding: enableReorderBlocks ? '6px 16px' : '8px 16px',
  }),
  ({ enableReorderBlocks, blockIsSection, height }) => {
    if (blockIsSection) {
      return {
        height: enableReorderBlocks ? '34px' : '38px',
        maxHeight: enableReorderBlocks ? '34px' : '38px',
      };
    }
    return {
      height: height ? `${height}px` : 'fit-content',
    };
  },
);

const PlaceholderBlock = styled.div(
  ({ darken, height, span, theme, visible }) => ({
    height: height && `${height}px`,
    gridColumn: `span ${span}`,
    backgroundColor: darken ? theme.color.gray4 : theme.color.gray3,
    borderRadius: '2px',
    display: visible ? 'block' : 'none',
    transition: '0.3s',
  }),
);

const BlockCard = styled(Card)(
  ({ span, height }) => ({
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    gridColumn: `span ${span}`, // only for when reorderBlocks not enabled
    height: height || 'fit-content',
    outline: 'none',
    overflow: 'visible',
    position: 'relative',
    transition: '0.3s',
  }),
  ({ handleHover, selected, theme }) => {
    const styles = {
      borderColor: selected ? theme.color.blue6 : theme.borderColor,
    };

    const selectedBoxShadows = {
      default: `0px 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 2px ${rgba(
        theme.color.blue6,
        0.25,
      )}`,
      hoverFocus: `0px 2px 8px rgba(0, 0, 0, 0.1), 0 0 0 2px ${rgba(
        theme.color.blue6,
        0.25,
      )}`,
    };

    const unselectedBoxShadow = `0px 2px 8px rgba(0, 0, 0, 0.05)`;

    if (selected) {
      if (handleHover) {
        styles.boxShadow = 'none !important';
      } else {
        styles.boxShadow = selectedBoxShadows.default;
        styles['&:hover'] = { boxShadow: selectedBoxShadows.default };
        styles['&:focus'] = { boxShadow: selectedBoxShadows.default };
        styles['&:hover:focus'] = { boxShadow: selectedBoxShadows.hoverFocus };
      }
    } else {
      styles.boxShadow = unselectedBoxShadow;
      styles['&:hover'] = {};
      styles['&:focus'] = {};
      styles['&:hover:focus'] = {};
    }

    return styles;
  },
  ({ isDragging, theme }) => ({
    boxShadow:
      isDragging &&
      `0px -1px ${theme.color.gray3}, 0px 1px ${theme.color.gray3}`,
  }),
);

const DraggableWrapper = styled('div')(
  ({ height, isDragging, span, startColumn }) => ({
    transform: !isDragging && 'unset !important',
    gridColumn: `${startColumn} / span ${span}`,
    opacity: isDragging && '0.5',
    position: 'relative',
    height: height ? `${height}px` : 'fit-content',
    maxWidth: '100%',
  }),
);

const DragHandle = styled('div')(
  {
    transition: '0.3s',
    position: 'absolute',
    left: '-15px',
    top: '-3px',
    paddingTop: '16px',
    paddingLeft: '4px',
    borderRadius: '4px;',
    height: 'calc(100% + 6px)',
    width: 'calc(100% + 18px)',
    outline: 'none',
  },
  ({ visible }) => ({
    opacity: visible ? 1 : 0,
  }),
  ({ theme }) => ({
    color: theme.color.gray6,
  }),
  ({ darken, selected, showHoverStyles, theme }) => {
    if (darken) {
      return {
        color: selected ? theme.color.blue6 : theme.color.gray6,
        backgroundColor: selected
          ? rgba(51, 122, 183, 0.45)
          : theme.color.gray4,
      };
    }

    if (showHoverStyles) {
      return {
        '&:hover': {
          backgroundColor: selected
            ? `${rgba(theme.color.blue6, 0.25)}`
            : theme.color.gray3,
          color: selected ? theme.color.blue6 : theme.color.gray6,
        },
      };
    }
  },
);

const RawDragHandle = styled(DragHandle)(({ blockType }) => ({
  paddingTop:
    {
      text: '6px',
      section: '7px',
    }[blockType] || '7px',
}));

const DropDestinationBar = styled('div')(
  {
    position: 'absolute',
    borderRadius: '2px',
  },
  ({ direction }) => {
    if (direction === 'vertical') {
      return { width: '4px', height: 'calc(100% - 12px)', top: '6px' };
    }
    if (direction === 'horizontal') {
      return { height: '4px', width: 'calc(100% - 32px)', left: '16px' };
    }
  },
  ({ theme }) => ({
    color: theme.color.gray5,
    backgroundColor: theme.color.gray5,
  }),
  ({ location }) => {
    if (location === 'left') {
      return { left: 6 };
    }
    // Brian's clever algorithm for determining where to put the drop bar:
    //
    // left = ((100% - (widthOfGutters * numberOfGuttersInRow)) / numberOfBlocks) + (widthOfGutters * numberOfGuttersToTheLeftOfTheGutterWhereTheBarIs) + (widthOfGutters * 0.5) - halfWidthOfDropBar
    //
    // NOTE: `numberOfGuttersInRow` includes the two paddings on the far left and right (so 4 for a row of 3 blocks: far-left padding, far-right padding, and two gutters between blocks).
    // For 3-block rows, there are only 2 locations between blocks for a drop bar to go, so "numberOfGuttersToTheLeftOfTheGutterWhereTheBarIs" can always be 1 as long as you change it from `left` to `right` positioning.
    if (location === 'center-left') {
      return {
        left: 'calc(((100% - (16px * 4)) / 3) + (16px) + (16px * 0.5) - 2px)',
      };
    }
    if (location === 'center') {
      return { left: 'calc(50% - 2px)' };
    }
    if (location === 'center-right') {
      return {
        right: 'calc(((100% - (16px * 4)) / 3) + (16px) + (16px * 0.5) - 2px)',
      };
    }
    if (location === 'right') {
      return { right: 6 };
    }
  },
  ({ visible }) => ({
    opacity: visible ? 1 : 0,
  }),
);

const NewBlockRowGridTop = styled(BlockRowGrid)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 16px;
  max-height: 16px;
  min-height: 0px;
  padding: 0;
  top: 6px;
`;

const NewBlockRowGridUnder = styled(BlockRowGrid)`
  margin: 0px auto;
  height: 4px;
  max-height: 4px;
  min-height: 0px;
  padding: 0;
`;

export {
  BlockCard,
  BlockGridWrapper,
  BlockRowGrid,
  DraggableWrapper,
  DragHandle,
  DropDestinationBar,
  NewBlockRowGridTop,
  NewBlockRowGridUnder,
  PlaceholderBlock,
  RawDragHandle,
};
