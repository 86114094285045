import { useState, useMemo, useCallback } from 'react';
import {
  filter,
  first,
  flatMap,
  get,
  groupBy,
  pipe,
  sortBy,
  toPairs,
  uniqBy,
  keyBy,
} from 'lodash/fp';
import { Fieldset, InPlaceInput, Select, Stack } from '@kinesis/bungle';
import {
  DataGrid,
  DataGridCell,
  DataGridRow,
  DataGridRowGroup,
} from 'components/data-grid';
import { NumberEntry } from 'components/number-entry';
import { flatten, fields, items } from 'data/attributes';
import Section from 'components/section';
import PropTypes from 'prop-types';
import GenericSettings from './attribute-settings.generic';

const propTypes = {
  instantiation: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const description =
  'Demography specifies the population and family structures of a location.';

const settingTypes = {
  'seed-sa4': {
    type: 'select',
    options: [
      {
        value: 125,
        label: '125 - Sydney - Parramatta',
      },
    ],
  },
};
const constructEditableCell = (field) => {
  const value = get(['value', 0, 'value', 'value'], field);
  const options = get(field.key, settingTypes);
  if (!options) return null;
  if (options.type === 'numeric') {
    return (
      <NumberEntry
        disabled
        numeric={field.value.encoding === 'int64' ? 'integer' : 'decimal'}
        value={value}
      >
        {value}
      </NumberEntry>
    );
  }

  if (options.type === 'select') {
    return <Select disabled options={options.options} value={value} />;
  }

  return null;
};

const DemographySettings = ({ instantiation, setting, workspaceId }) => {
  const [settingState /* , setSettingState */] = useState(setting);
  const [selections, setSelections] = useState([]);

  const singleSelection = useMemo(
    () =>
      selections.length === 1
        ? pipe(flatten, filter({ key: first(selections) }), first)(settingState)
        : undefined,
    [selections, settingState],
  );

  const settings = useMemo(
    () =>
      pipe(
        filter(({ key }) => selections.includes(key)),
        flatMap(fields),
        groupBy('key'),
        toPairs,
        flatMap(([key, vs]) => {
          if (vs.length === 0) return [];
          const { label, group } = first(vs);
          const value = uniqBy(get(['value', 'value']), vs);
          return [
            { key, group: group.label, label, multi: value.length > 1, value },
          ];
        }),
        groupBy('group'),
        toPairs,
        sortBy(0),
      )(items(settingState)),
    [settingState, selections],
  );

  const itemHeading = useMemo(
    () =>
      pipe(
        items,
        keyBy('key'),
        get([get('key', singleSelection), 'label']),
      )(instantiation),
    [singleSelection, instantiation],
  );

  const onChangeItemLabel = useCallback((value) => console.log(value), []);

  return (
    <GenericSettings
      description={description}
      instantiation={instantiation}
      selections={selections}
      setSelections={setSelections}
      setting={settingState}
      workspaceId={workspaceId}
    >
      {itemHeading && (
        <Section
          titleOnly
          collapsible={false}
          key={get('key', singleSelection)}
        >
          <InPlaceInput
            onChange={onChangeItemLabel}
            placeholder='Item'
            value={itemHeading}
            variant='title'
          />
        </Section>
      )}
      <Section collapsible={false}>
        <Stack space='medium'>
          <Select
            disabled
            label='Item type'
            onChange={console.log}
            value='sa4'
            options={[{ label: 'Australian SA4', value: 'sa4' }]}
          />
          <Stack space='small'>
            <Fieldset legend='Settings'>
              <DataGrid>
                {settings.flatMap(([group, grouped]) => (
                  <DataGridRowGroup key={group} title={group}>
                    {grouped.map((field) => (
                      <DataGridRow key={field.key} title={field.label}>
                        <DataGridCell>
                          {constructEditableCell(field)}
                        </DataGridCell>
                      </DataGridRow>
                    ))}
                  </DataGridRowGroup>
                ))}
              </DataGrid>
            </Fieldset>
          </Stack>
        </Stack>
      </Section>
    </GenericSettings>
  );
};

DemographySettings.defaultProps = defaultProps;
DemographySettings.propTypes = propTypes;

export default DemographySettings;
