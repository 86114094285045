import { isNil, indexOf, orderBy, get, isEqual } from 'lodash/fp';
import { getActiveVersion } from 'data/block';

const orderBlockIds = (fetchableBlockIds, workspaceBlockStates, scenarioId) =>
  orderBy(
    [
      (blockId) =>
        get(
          [blockId, 'scenarios', scenarioId, 'lastQueried'],
          workspaceBlockStates,
        ) || 0,
      (blockId) => indexOf(blockId, fetchableBlockIds),
    ],
    ['asc', 'asc'],
    fetchableBlockIds,
  );

const isEditableVisualisationBlock = (blockValue) =>
  get('type', blockValue) === 'editable-visualisation';

const isPathwayInputBlock = (blockValue) =>
  get('type', blockValue) === 'pathway-input';

const isSupportedBlock = (blockValue) =>
  isEditableVisualisationBlock(blockValue) || isPathwayInputBlock(blockValue);

const isEmptyBlock = (blockValue) =>
  get('visualisation', blockValue) === 'empty';

const isBlockInFlight = (blockState) =>
  get('status', blockState) === 'querying';

const isBlockFetchable = (blockValue, blockState) =>
  isSupportedBlock(blockValue) &&
  !isEmptyBlock(blockValue) &&
  !isBlockInFlight(blockState);

const isCapsuleMismatch = (blockState, blockPerspective) =>
  getActiveVersion(blockState) !== get('dataVersion', blockPerspective);

const isScenarioMismatch = (blockPerspective, scenariosWithKeys) => {
  const blockScenarioKeys = get('scenarioKeys', blockPerspective);

  return !isEqual(scenariosWithKeys, blockScenarioKeys);
};

const isBlockInView = (blockId, maximisedBlockId) =>
  isNil(maximisedBlockId) || blockId === maximisedBlockId;

const isBlockInNeedOfData = (blockState, blockPerspective, scenariosWithKeys) =>
  isCapsuleMismatch(blockState, blockPerspective) ||
  isScenarioMismatch(blockPerspective, scenariosWithKeys);

export { orderBlockIds, isBlockInView, isBlockFetchable, isBlockInNeedOfData };
