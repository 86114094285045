import { pipe, set } from 'lodash/fp';

import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { geographyUpdate } from 'actions/geographyUpdate';
import { workspaceUpdate } from 'actions/workspaceUpdate';
import { scenarioPublish } from 'actions/scenarioPublish';

const initialState = {
  isSaving: false,
  publishFailed: false,
};

// FIX this should be addressed, but is a new rule, and don't
// want to bundle a large refactor so just leaving the fixes.
// This one should change to createSlice.
// eslint-disable-next-line default-param-last
function persistenceReducer(state = initialState, action) {
  switch (action.type) {
    case scenarioPublish.pending.type: {
      return pipe(set('isSaving', true), set('publishFailed', false))(state);
    }
    case scenarioPublish.fulfilled.type: {
      const { conflict } = action.payload;

      return pipe(
        set('isSaving', false),
        set('publishFailed', !!conflict),
      )(state);
    }
    case workspaceUpdate.fulfilled.type:
    case workspacePatch.fulfilled.type:
    case geographyUpdate.fulfilled.type:
    case workspaceFetch.fulfilled.type: {
      return set('isSaving', false, state);
    }

    case workspacePatch.pending.type:
    case geographyUpdate.pending.type:
    case workspaceUpdate.pending.type: {
      return set('isSaving', true, state);
    }

    default:
  }

  return state;
}

export default persistenceReducer;
