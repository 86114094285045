import PropTypes from 'prop-types';
import { useId } from '@kinesis/bungle';
import {
  DataGridRowGroupWrapper,
  DataGridRowGroupHeader,
} from './data-grid-row-group.styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: undefined,
};

const DataGridRowGroup = ({ children, title }) => {
  const headerId = `data-grid-row-group__${useId()}`;

  return (
    <DataGridRowGroupWrapper aria-labelledby={title ? headerId : undefined}>
      {title && (
        <DataGridRowGroupHeader id={headerId}>{title}</DataGridRowGroupHeader>
      )}
      {children}
    </DataGridRowGroupWrapper>
  );
};

DataGridRowGroup.propTypes = propTypes;
DataGridRowGroup.defaultProps = defaultProps;

export { DataGridRowGroup };
