import { createSlice } from '@reduxjs/toolkit';
import { appAdd } from 'actions/appAdd';
import { appsUpgrade } from 'actions/appsUpgrade';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { normalise } from 'utils/resources';

const { reducer, actions } = createSlice({
  name: 'resources',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(appAdd.fulfilled, (state, action) =>
      normalise(action.payload),
    );
    builder.addCase(appsUpgrade.fulfilled, (state, action) =>
      normalise(action.payload),
    );
    builder.addCase(workspaceFetch.fulfilled, (state, action) =>
      normalise(action.payload),
    );
    builder.addCase(workspacePatch.fulfilled, (state, action) =>
      normalise(action.payload),
    );
  },
});

export { reducer, actions };
