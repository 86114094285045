import PropTypes from 'prop-types';
import { Loading } from 'components/loading';

const propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  offset: PropTypes.number,
  delay: PropTypes.number,
};

const defaultProps = {
  loading: false,
  children: null,
  offset: 0,
  delay: 0,
};

const LoadableContent = ({ children, loading, offset, delay }) =>
  loading ? <Loading offset={offset} delay={delay} /> : children;

LoadableContent.propTypes = propTypes;
LoadableContent.defaultProps = defaultProps;

export default LoadableContent;
