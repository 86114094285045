import { createSelector } from 'reselect';
import {
  filter,
  flatten,
  get,
  keys,
  map,
  pipe,
  uniqBy,
  values,
} from 'lodash/fp';
import accessIdentitiesSelector from 'selectors/accessIdentitiesSelector';
import activeMembershipsSelector from 'selectors/activeMembershipsSelector';

const potentialShareesSelector = createSelector(
  accessIdentitiesSelector,
  activeMembershipsSelector,
  get('organisations'),
  (accessIdentities, memberships, stateOrganisations) => {
    let potentials = [];

    if (accessIdentities && memberships && stateOrganisations) {
      const memberOrgIds = memberships.map((m) => m.organisation_id);
      const memberOrgs = pipe(
        values,
        filter((org) => memberOrgIds.includes(org.id)),
        map((org) => ({ ...org, identity: `organisation/${org.id}` })),
      )(stateOrganisations);

      const memberOrgUsers = pipe(
        values,
        map('users'),
        flatten,
        uniqBy(get('email')),
        map((user) => ({ ...user, identity: `user/${get('id', user)}` })),
      )(memberOrgs);

      const accessIdentityKeys = keys(accessIdentities);

      potentials = [...memberOrgs, ...memberOrgUsers].filter(
        (p) => !accessIdentityKeys.includes(p.identity),
      );
    }

    return potentials;
  },
);

export default potentialShareesSelector;
