import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo, find, forEach, get, map, pipe } from 'lodash/fp';
import { Button, Select, Stack } from '@kinesis/bungle';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import uploadsSelector from 'selectors/uploadsSelector';
import datasetVersionsPublishedSelector from 'selectors/datasetVersionsPublishedSelector';
import { datasetVersionPublish } from 'actions/datasetVersionPublish';
import { datasetVersionUpload } from 'actions/datasetVersionUpload';
import useSelectorWithProps from 'hooks/useSelectorWithProps';

const propTypes = {
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const DatasetDraftPane = ({ workspaceId }) => {
  const dispatch = useDispatch();
  const versionId = useSelector(
    pipe(
      get(['toolbox', 'panes']),
      find({ type: 'dataset-draft' }),
      get(['selection', 'id']),
    ),
  );
  const version = useSelector(get(['datasetVersions', versionId]));
  const datasetId = get('dataset', version);
  const base = useSelector(get(['datasetVersions', get('base', version)]));
  const actions = defaultTo([], get(['changeset', 'actions'], version));
  const uploads = useSelectorWithProps(uploadsSelector, {
    uploads: get('uploads', version),
    workspaceId,
  });
  const versions = useSelectorWithProps(datasetVersionsPublishedSelector, {
    datasetId,
    workspaceId,
  });
  const versionOptions = useMemo(() => {
    if (!base) {
      return [{ label: 'New dataset', value: undefined }];
    }
    return map(
      ({ id, publishedAs }) => ({ value: id, label: `Version ${publishedAs}` }),
      versions,
    );
  }, [base, versions]);

  const onPublish = useCallback(() => {
    dispatch(datasetVersionPublish({ versionId }));
  }, [dispatch, versionId]);

  // MTH question for myself, I don't know that this is the
  // right spot, but doing it in middleware on upload complete
  // requires a lot of housekeeping to ensure we don't accidentally
  // complete something we shouldn't (after someone navigates away).
  // Requires more thought.
  useEffect(() => {
    // NOTE: see dataset.js for why this is multiple dispatches.
    forEach(({ key, id, state }) => {
      if (state === 'complete') {
        dispatch(
          datasetVersionUpload({
            datasetId,
            versionId,
            upload: id,
            key,
          }),
        );
      }
    }, uploads);
  }, [dispatch, datasetId, versionId, uploads]);
  return (
    <ToolboxPanel>
      <ToolboxSection>
        <Stack space='medium'>
          <Select
            disabled /* ={!base} FUTURE: when set-base action implemented */
            label='Base version'
            options={versionOptions}
            value={get('id', base)}
          />
        </Stack>
      </ToolboxSection>
      <ToolboxSection collapsible title='Modifications'>
        {actions.map((action) => (
          <div key={action.id}>{action.type.type}</div>
        ))}
        {(uploads || []).map(({ key, source, loaded, total }) => (
          <div key={key}>
            {source} {total ? `${Math.round((loaded * 100) / total)}%` : null}
          </div>
        ))}
      </ToolboxSection>
      <ToolboxSection>
        <Button onClick={onPublish}>Publish modifications</Button>
      </ToolboxSection>
    </ToolboxPanel>
  );
};

DatasetDraftPane.propTypes = propTypes;
DatasetDraftPane.defaultProps = defaultProps;

export { DatasetDraftPane };
