import { createCachedSelector } from 're-reselect';
import { curry, get, filter, map, pipe, stubTrue } from 'lodash/fp';
import { scaleLinear } from 'd3-scale';
import { pointOf } from 'data/geography';
import sizeMaxSelector from 'selectors/sizeMaxSelector';
import sizeMinSelector from 'selectors/sizeMinSelector';
import blockScenarioDataSelector from 'selectors/blockScenarioDataSelector';
import workspaceGeographiesSelector from 'selectors/workspaceGeographiesSelector';
import colorDomainSelector from 'selectors/colorDomainSelector';
import {
  colorsFor,
  colorFor,
  baseColor,
  diffColor,
  selectedLineColor,
  deselectedLineColor,
} from 'components/spatial-visualisation/layer-colors';
import blockValueSelector from './blockValueSelector';
import currentAttributesSelector from './currentAttributesSelector';
import makeAggregationFilterSelector from './makeAggregationFilterSelector';
import workspaceSelector from './workspaceSelector';

const scaleRadius = curry((min, max, value) =>
  scaleLinear().domain([min, max]).range([32, 96])(value),
);

const yearSelector = makeAggregationFilterSelector('year');

const dataVizSelector = createCachedSelector(
  pipe(workspaceSelector, get('locations')),
  workspaceGeographiesSelector,
  pipe(currentAttributesSelector, get('values')),
  blockValueSelector,
  blockScenarioDataSelector,
  yearSelector,
  sizeMinSelector,
  sizeMaxSelector,
  colorDomainSelector,
  (
    locations,
    geographies,
    attributesLookup,
    visualisation,
    data,
    year,
    sizeMin,
    sizeMax,
    colorDomain,
  ) => {
    if (!attributesLookup || !visualisation) {
      return [];
    }

    const colors = colorsFor(colorDomain);
    const makeColors = (value) => {
      const color = colorFor(colorDomain, colors, value);
      return {
        base: baseColor(color),
        diff: diffColor(color),
        deselectedBorder: deselectedLineColor(color),
        selectedBorder: selectedLineColor(color),
        fixedDeselectedBorder: deselectedLineColor('#092D66'),
        fixedSelectedBorder: selectedLineColor('#092D66'),
      };
    };
    const makeRadius = scaleRadius(sizeMin, sizeMax);

    const visualisationType = get('visualisation', visualisation);
    const hexColumn = get([visualisationType, 'hex', 'column'], visualisation);
    const locationColumn =
      get([visualisationType, 'location', 'column'], visualisation) ||
      'location-id';
    const labelColumn = get(
      [visualisationType, 'label', 'column'],
      visualisation,
    );
    const latitudeColumn = get(
      [visualisationType, 'latitude', 'column'],
      visualisation,
    );
    const longitudeColumn = get(
      [visualisationType, 'longitude', 'column'],
      visualisation,
    );
    const colorColumn = get(
      [visualisationType, 'colour', 'column'],
      visualisation,
    );
    const sizeColumn = get(
      [visualisationType, 'size', 'column'],
      visualisation,
    );
    const timeColumn = get(
      [visualisationType, 'time', 'column'],
      visualisation,
    );

    if (hexColumn) {
      return (data || []).map((datum) => ({
        id: get(hexColumn, datum),
        hexagon: get(hexColumn, datum),
        label: get(labelColumn, datum),
        color: get(colorColumn, datum),
        colors: makeColors(get(colorColumn, datum)),
      }));
    }

    return pipe(
      filter(timeColumn ? { [timeColumn]: year } : stubTrue),
      map((datum) => ({
        id: get(locationColumn, datum),
        label:
          get(labelColumn, datum) ||
          get([get(locationColumn, datum), 'label'], locations) ||
          'New location',
        position: [
          get(longitudeColumn, datum) ||
            get(
              'longitude',
              pointOf(
                get(locationColumn, datum),
                attributesLookup,
                geographies,
              ),
            ),
          get(latitudeColumn, datum) ||
            get(
              'latitude',
              pointOf(
                get(locationColumn, datum),
                attributesLookup,
                geographies,
              ),
            ),
        ],
        color: get(colorColumn, datum),
        colors: makeColors(get(colorColumn, datum)),
        size: get(sizeColumn, datum),
        radius: makeRadius(get(sizeColumn, datum)),
      })),
    )(data);
  },
)((state, props) => props.blockId);

export default dataVizSelector;
