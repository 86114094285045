import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash/fp';

const { reducer, actions } = createSlice({
  name: 'preferences',
  initialState: {},
  reducers: {
    initialisePreferences(state, action) {
      return action.payload;
    },
    setPreference(state, action) {
      return set(action.payload.key, action.payload.value, state);
    },
  },
});

export { reducer, actions };
