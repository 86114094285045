import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import Units from 'data/units';
import Layout from 'components/layout';
import { Placeholder } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import {
  DataGrid,
  DataGridCell,
  DataGridRow,
  DataGridRowGroup,
} from 'components/data-grid';
import { NumberEntry } from 'components/number-entry';
import { GridPane, InputArea } from './attribute-values.styles';

const units = new Units();

const propTypes = {
  entries: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
};

const defaultProps = {
  privacy: 'private',
};

const formatter = (type) =>
  type ? units.parseColumn({ unit: { type } }).formatCell : undefined;

const symbol = (type) => units.parseColumn({ unit: { type } }).symbol || type;

const ItemisedAttributeValues = ({ entries, onChange, privacy }) => {
  const handleChange = useCallback(
    (key, value) => {
      onChange(key, value);
    },
    [onChange],
  );

  if (isEmpty(entries)) {
    return (
      <Layout>
        <Content alignX='center' alignY='center'>
          <Placeholder>
            There are no items defined at this location.
          </Placeholder>
        </Content>
      </Layout>
    );
  }

  return (
    <Layout direction='row'>
      <Layout direction='column'>
        <GridPane>
          <DataGrid borderClip={['left', 'right']}>
            <DataGridRowGroup>
              {entries.map((entry) =>
                entry.encoding === 'float64' ? (
                  <DataGridRow key={entry.key} title={entry.label}>
                    <DataGridCell>
                      <NumberEntry
                        readOnly={privacy === 'public'}
                        format={formatter(entry.unitType)}
                        max={entry.units === 'proportion' ? 1 : undefined}
                        min={0}
                        onChange={(amount) => {
                          handleChange(entry.key, { amount });
                        }}
                        units={entry.unitLabel || symbol(entry.unitType)}
                        value={entry.value}
                      />
                    </DataGridCell>
                  </DataGridRow>
                ) : (
                  <InputArea
                    key={entry.key}
                    displayOnly={privacy === 'public'}
                    onChange={(event) => {
                      handleChange(entry.key, event.currentTarget.value);
                    }}
                    value={entry.value}
                    spellCheck
                    wrap='soft'
                    autoFocus
                  />
                ),
              )}
            </DataGridRowGroup>
          </DataGrid>
        </GridPane>
      </Layout>
    </Layout>
  );
};

ItemisedAttributeValues.defaultProps = defaultProps;
ItemisedAttributeValues.propTypes = propTypes;

export default ItemisedAttributeValues;
