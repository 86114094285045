import { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AnimatedPopover,
  Modal,
  Prompt,
  Tooltip,
  UtilityButton,
  useOnClickOutside,
} from '@kinesis/bungle';
import LayerSelect from 'components/layer-select/LayerSelect';
import LocationsImporter from 'components/locations-importer/LocationsImporter';
import { LocationsSearch } from 'components/locations-search';
import NewLocation from 'components/modals/new-location/NewLocation';
import { useSelectorWithProps } from 'hooks';
import usePermission from 'hooks/usePermission';
import filteredScenarioLocationsSelector from 'selectors/filteredScenarioLocationsSelector';
import { makeKeyboardShortcut } from 'utils/keyboardUtils';
import { LocationsFilteringDialog } from 'components/locations-filtering-dialog';
import { get, isEmpty, negate, pipe } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';
import { shortcuts } from 'data/shortcuts';
import {
  MapControl,
  MapControlGroup,
  MapControlSeparator,
  MapControlItem,
} from './spatial-tools.styles';

const IMPORT_LOCATIONS_MODAL = 'IMPORT_LOCATIONS_MODAL';
const NEW_LOCATION_MODAL = 'NEW_LOCATION_MODAL';

const propTypes = {
  privacy: PropTypes.oneOf(['private', 'public']),
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const SpatialTools = ({ privacy, scenarioId, workspaceId }) => {
  const newButtonRef = useRef();
  const importButtonRef = useRef();
  const searchButtonRef = useRef();
  const filterLocationsButtonRef = useRef();
  const [overlay, setOverlay] = useState();
  const [layerId, setLayerId] = useState();

  const filteredLocations = useSelectorWithProps(
    filteredScenarioLocationsSelector,
    {
      public: privacy === 'public',
      scenarioId,
      workspaceId,
    },
  );
  const [showPrompts, setShowPrompts] = useState(
    filteredLocations.length === 0,
  );

  const isEditor = usePermission('editor');

  const handleClose = useCallback(() => {
    setOverlay(undefined);
    setLayerId(undefined);
  }, []);

  const [showNewLocationsList, setShowNewLocationsList] = useState(false);
  const [showImportLocationsList, setShowImportLocationsList] = useState(false);
  const [showSearchLocationsList, setShowSearchLocationsList] = useState(false);
  const [showFilterLocationsList, setShowFilterLocationsList] = useState(false);

  useOnClickOutside(
    [filterLocationsButtonRef],
    useCallback(() => {
      setShowFilterLocationsList(false);
    }, []),
  );

  const searchShortcut = useMemo(() => makeKeyboardShortcut({ key: '/' }), []);

  const importShortcut = useMemo(() => makeKeyboardShortcut({ key: 'i' }), []);

  const newLocationShortcut = useMemo(
    () => makeKeyboardShortcut({ key: 'n' }),
    [],
  );

  const filtersActive = useSelectorWithProps(
    pipe(
      workspaceSelector,
      get(['locationFilters', 'operand']),
      negate(isEmpty),
    ),
    {
      public: privacy === 'public',
      workspaceId,
    },
  );

  return (
    <>
      <MapControl>
        <MapControlGroup>
          {isEditor && (
            <>
              <MapControlItem>
                <Prompt
                  offset={4}
                  onDismiss={() => setShowPrompts(false)}
                  placement='right'
                  title={`New location… (${newLocationShortcut})`}
                  showPrompt={showPrompts}
                >
                  <Tooltip
                    offset={4}
                    placement='right'
                    title={`New location… (${newLocationShortcut})`}
                    disabled={showPrompts}
                  >
                    <UtilityButton
                      ref={newButtonRef}
                      expanded={showNewLocationsList}
                      hasPopup
                      icon='add-location'
                      keyboardShortcut={newLocationShortcut}
                      magnitude='small'
                      onClick={() => {
                        setShowNewLocationsList(true);
                      }}
                    />
                  </Tooltip>
                </Prompt>
              </MapControlItem>
              <MapControlItem>
                <Prompt
                  offset={4}
                  onDismiss={() => setShowPrompts(false)}
                  placement='right'
                  title={`Import locations… (${importShortcut})`}
                  showPrompt={showPrompts}
                >
                  <Tooltip
                    offset={4}
                    placement='right'
                    title={`Import locations… (${importShortcut})`}
                    disabled={showPrompts}
                  >
                    <UtilityButton
                      ref={importButtonRef}
                      expanded={showNewLocationsList}
                      hasPopup
                      icon='import'
                      keyboardShortcut={importShortcut}
                      magnitude='small'
                      onClick={() => setShowImportLocationsList(true)}
                    />
                  </Tooltip>
                </Prompt>
              </MapControlItem>
              <MapControlSeparator />
            </>
          )}
          <MapControlItem>
            <Tooltip
              offset={4}
              placement='right'
              title={`Find location… (${searchShortcut})`}
            >
              <UtilityButton
                ref={searchButtonRef}
                expanded={showSearchLocationsList}
                hasPopup
                icon='search'
                keyboardShortcut={searchShortcut}
                magnitude='small'
                onClick={() => setShowSearchLocationsList(true)}
              />
            </Tooltip>
          </MapControlItem>
          {privacy !== 'public' && (
            <MapControlItem>
              <Tooltip
                offset={4}
                placement='right'
                title={`Filter locations… (${shortcuts.FILTER_LOCATIONS.mnemonic})`}
              >
                <UtilityButton
                  ref={filterLocationsButtonRef}
                  expanded={showFilterLocationsList}
                  hasPopup
                  icon='filter'
                  keyboardShortcut={shortcuts.FILTER_LOCATIONS.handler}
                  magnitude='small'
                  onClick={() => setShowFilterLocationsList(true)}
                  variant={filtersActive ? 'accent' : 'default'}
                />
              </Tooltip>
            </MapControlItem>
          )}
        </MapControlGroup>
      </MapControl>
      <AnimatedPopover
        justify='start'
        offset={8}
        open={showNewLocationsList}
        placement='right'
        targetRef={newButtonRef}
      >
        <LayerSelect
          buttonRef={newButtonRef}
          closeable
          onClose={() => setShowNewLocationsList(false)}
          onSelect={(lid) => {
            setOverlay(NEW_LOCATION_MODAL);
            setLayerId(lid);
          }}
          workspaceId={workspaceId}
        />
      </AnimatedPopover>
      <AnimatedPopover
        justify='start'
        offset={8}
        open={showImportLocationsList}
        placement='right'
        targetRef={importButtonRef}
      >
        <LayerSelect
          buttonRef={importButtonRef}
          closeable
          onClose={() => setShowImportLocationsList(false)}
          onSelect={(lid) => {
            setOverlay(IMPORT_LOCATIONS_MODAL);
            setLayerId(lid);
          }}
          workspaceId={workspaceId}
        />
      </AnimatedPopover>
      <AnimatedPopover
        justify='start'
        offset={8}
        open={showSearchLocationsList}
        placement='right'
        targetRef={searchButtonRef}
      >
        <LocationsSearch
          buttonRef={searchButtonRef}
          closeable
          onClose={() => setShowSearchLocationsList(false)}
          privacy={privacy}
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      </AnimatedPopover>
      <AnimatedPopover
        justify='start'
        offset={8}
        open={showFilterLocationsList}
        placement='right'
        targetRef={filterLocationsButtonRef}
      >
        <LocationsFilteringDialog
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      </AnimatedPopover>
      {overlay === NEW_LOCATION_MODAL && (
        <Modal
          height={600}
          onClose={handleClose}
          width={840}
          aria-label='New location'
        >
          <NewLocation
            layerId={layerId}
            onClose={handleClose}
            scenarioId={scenarioId}
            workspaceId={workspaceId}
          />
        </Modal>
      )}
      {overlay === IMPORT_LOCATIONS_MODAL && (
        <Modal
          height={600}
          onClose={handleClose}
          width={840}
          aria-label='Import locations'
        >
          <LocationsImporter
            layerId={layerId}
            onCancel={handleClose}
            scenarioId={scenarioId}
            workspaceId={workspaceId}
          />
        </Modal>
      )}
    </>
  );
};

SpatialTools.defaultProps = defaultProps;
SpatialTools.propTypes = propTypes;

export default SpatialTools;
