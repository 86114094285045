import { createSlice } from '@reduxjs/toolkit';
import { get, identity, inRange, pipe, reduce, set, isNil } from 'lodash/fp';

const { reducer, actions } = createSlice({
  name: 'aggregationFilters',
  initialState: {
    blocks: {},
  },
  reducers: {
    initialiseBlockTimeBounds(state, action) {
      const {
        blockId,
        timeBounds: [min, max],
      } = action.payload;

      if (!min || !max) {
        return state;
      }
      const current = get(['blocks', blockId, 'year'], state);

      return pipe(
        set(
          ['blocks', blockId, 'year'],
          inRange(min, max, current) ? current : max,
        ),
        set(['blocks', blockId, 'yearBounds'], [min, max]),
      )(state);
    },
    setBlockAggregationFilter(state, action) {
      const { blockId, key, value } = action.payload;
      return set(['blocks', blockId, key], value, state);
    },
    setMultipleBlockYear(state, action) {
      const { blockIds, year } = action.payload;
      return reduce(
        (accState, blockId) => {
          // expand local bounds
          const yearBounds =
            get(['blocks', blockId, 'yearBounds'], accState) || [];
          let setBounds = identity;
          if (isNil(year)) {
            setBounds = identity;
          } else if (isNil(yearBounds[0]) || year < yearBounds[0]) {
            setBounds = set(
              ['blocks', blockId, 'yearBounds'],
              [year, isNil(yearBounds[1]) ? year : yearBounds[1]],
            );
          } else if (isNil(yearBounds[1]) || year > yearBounds[1]) {
            setBounds = set(
              ['blocks', blockId, 'yearBounds'],
              [isNil(yearBounds[0]) ? year : yearBounds[0], year],
            );
          }

          return pipe(
            setBounds,
            set(['blocks', blockId, 'year'], year),
          )(accState);
        },
        state,
        blockIds,
      );
    },
  },
});

export { reducer, actions };
