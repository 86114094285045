import { createSelector } from 'reselect';
import { get, pipe, identity } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const workspaceSharesSelector = createSelector(
  pipe(workspaceSelector, get(['data', 'workspaceShares'])),
  identity,
);

export default workspaceSharesSelector;
