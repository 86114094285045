const set = (token) => {
  sessionStorage.setItem('workspaceShareToken', token);
};

const getToken = () => {
  const token = sessionStorage.getItem('workspaceShareToken');
  sessionStorage.removeItem('workspaceShareToken');
  return token;
};

export { set, getToken };
