import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from 'components/layout';

const LayoutImpl = styled(Layout)`
  background-color: ${({ theme }) => theme.colors.gray2.hex};
  border-radius: ${({ rounded }) => (rounded ? '0 0 0 4px' : '0 0 0 0')};
`;

const propTypes = {
  children: PropTypes.node.isRequired,
  rounded: PropTypes.bool,
};

const defaultProps = {
  rounded: false,
};

const PageLayout = ({ children, rounded }) => (
  <LayoutImpl direction='column' rounded={rounded}>
    {children}
  </LayoutImpl>
);

PageLayout.propTypes = propTypes;
PageLayout.defaultProps = defaultProps;

export { PageLayout };
