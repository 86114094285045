import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspacesFetch = createAsyncThunk(
  'workspaces/fetch',
  async (_, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.get(`/api/workspaces/list`);

    return response.data;
  },
);

export { workspacesFetch };
