import PropTypes from 'prop-types';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuLink,
  DropdownMenuList,
  DropdownMenuSection,
  UtilityButton,
} from '@kinesis/bungle';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { get, has, pipe, values } from 'lodash/fp';
import { useBoardId, useScenarioId, useSelectorWithProps } from 'hooks';
import boardSelector from 'selectors/boardSelector';
import sortedBoardsSelector from 'selectors/sortedBoardsSelector';
import sortedScenariosSelector from 'selectors/sortedScenariosSelector';
import DecoratedScenarioName from 'components/workspace/DecoratedScenarioName';
import scenarioSelector from 'selectors/scenarioSelector';
import baselineScenarioSelector from 'selectors/baselineScenarioSelector';

const StyledUtilityButton = styled(UtilityButton)(({ theme }) => ({
  flex: '0 0 auto',
  fontSize: theme.fontSize.lg,
  fontWeight: theme.fontWeight.medium,
  color: theme.color.gray9,
  whiteSpace: 'nowrap',
  '&:hover': {
    color: theme.color.gray9,
  },
}));

const propTypes = {
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const ScenarioBoardDropdown = ({ privacy, workspaceId }) => {
  const scenarios = useSelectorWithProps(sortedScenariosSelector, {
    public: privacy === 'public',
    workspaceId,
  });
  const boards = useSelectorWithProps(sortedBoardsSelector, {
    public: privacy === 'public',
    workspaceId,
  });

  const currentBoardId = useBoardId();
  const scenarioId = useScenarioId();
  const currentBoardLabel = useSelectorWithProps(
    pipe(boardSelector, get('label')),
    {
      boardId: currentBoardId,
      public: privacy === 'public',
      workspaceId,
    },
  );
  const currentScenario =
    useSelectorWithProps(scenarioSelector, {
      public: privacy === 'public',
      scenarioId,
      workspaceId,
    }) ?? {};
  const baselineScenario =
    useSelectorWithProps(baselineScenarioSelector, {
      public: privacy === 'public',
      workspaceId,
    }) ?? {};
  const workspaceNamespace = privacy === 'public' ? 'public' : 'workspaces';

  return (
    <DropdownMenu placement='bottom' justify='start'>
      <DropdownMenuButton
        as={StyledUtilityButton}
        data-testid='scenario-board-dropdown-button'
        dropdown
      >
        {!currentBoardId && currentScenario.id && (
          <DecoratedScenarioName
            isDefault={get('isDefault', currentScenario)}
            isDraft={has('draft', currentScenario)}
            name={get('name', currentScenario)}
          />
        )}
        {currentBoardLabel}
      </DropdownMenuButton>
      <DropdownMenuList>
        <DropdownMenuSection title='Scenarios'>
          {values(scenarios).map((scenario) => (
            <DropdownMenuLink
              as={Link}
              data-testid={`scenario-${scenario.id}`}
              key={scenario.id}
              to={`/${workspaceNamespace}/${workspaceId}/scenarios/${scenario.id}`}
              variant={
                !currentBoardId && scenario.id === currentScenario.id
                  ? 'accent'
                  : undefined
              }
            >
              <DecoratedScenarioName
                isDefault={scenario.isDefault}
                isDraft={has('draft', scenario)}
                name={scenario.name}
              />
            </DropdownMenuLink>
          ))}
        </DropdownMenuSection>
        {boards.length > 0 && (
          <DropdownMenuSection title='Boards'>
            {boards.map((board) => (
              <DropdownMenuLink
                as={Link}
                data-testid={`board-${board.id}`}
                key={board.id}
                to={`/${workspaceNamespace}/${workspaceId}/boards/${
                  board.id
                }?scenario=${currentScenario.id || baselineScenario.id}`}
                variant={board.id === currentBoardId ? 'accent' : undefined}
              >
                {board.label}
              </DropdownMenuLink>
            ))}
          </DropdownMenuSection>
        )}
      </DropdownMenuList>
    </DropdownMenu>
  );
};

ScenarioBoardDropdown.propTypes = propTypes;
ScenarioBoardDropdown.defaultProps = defaultProps;

export { ScenarioBoardDropdown };
