import { createSelector } from 'reselect';
import { get, pick, pipe, placeholder, values } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';
import dependenciesSelector from 'selectors/dependenciesSelector';

const resourcesSelector = createSelector(
  pipe(workspaceSelector, get('resources')),
  get('resources'),
  dependenciesSelector,
  (workspaceResources, resources, dependencies) =>
    pipe(
      pick(dependencies),
      values,
      pick(placeholder, resources),
      values,
    )(workspaceResources),
);

export default resourcesSelector;
