import styled from 'styled-components';

const Panel = styled.div`
  display: flex;
  margin: auto;
  padding: 16px;
`;

const IconSpacer = styled.div(
  {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 16, // 24 - 4 - 4 (for spacer)
  },
  ({ variant }) =>
    ({
      server: {
        color: '#fc8b8f',
      },
      'not-found': {
        color: '#FFCA61',
      },
      unknown: {
        color: '#FFCA61',
      },
    })[variant],
);

const ButtonSpacer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: 32, // 40 - 4 - 4 (for spacer)
});

const EmailText = styled.div`
  padding-left: 8px;
  color: ${({ theme }) => theme.color.gray8};
  font-size: ${({ theme }) => theme.fontSize.normal}px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.normal};
`;

const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.gray8};
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.lg};
  max-width: 396px;
`;

const Heading = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.gray9};
  font-size: ${({ theme }) => theme.fontSize.xl}px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: ${({ theme }) => theme.lineHeight.xl};
`;

export { ButtonSpacer, EmailText, IconSpacer, Heading, Panel, Text };
