import PropTypes from 'prop-types';
import resourcesSelector from 'selectors/resourcesSelector';
import boardSelector from 'selectors/boardSelector';
import { useBoardId, useSelectorWithProps } from 'hooks';
import { pipe, get } from 'lodash/fp';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import TableDefinitionsSection from '../../sections/TableDefinitionsSection';

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

const defaultProps = {};

const PublicBoardPane = ({ workspaceId }) => {
  const boardId = useBoardId();
  const label = useSelectorWithProps(pipe(boardSelector, get('label')), {
    boardId,
    public: true,
    workspaceId,
  });
  const definitions = useSelectorWithProps(resourcesSelector, {
    boardId,
    public: true,
    workspaceId,
  });

  if (!boardId) {
    return null;
  }

  return (
    <ToolboxPanel fadeScroll title={label}>
      <TableDefinitionsSection definitions={definitions} />
    </ToolboxPanel>
  );
};

PublicBoardPane.propTypes = propTypes;
PublicBoardPane.defaultProps = defaultProps;

export { PublicBoardPane };
