import styled from 'styled-components';
import { Toolbar } from '@kinesis/bungle/legacy';
import { borderRadius } from 'polished';

const BlockContent = styled('div')(
  {
    backgroundColor: 'white',
    ...borderRadius('bottom', 1),
    display: 'flex',
    overflowX: 'visible',
    zIndex: 0,
    minHeight: 0,
  },
  ({ maximised }) => ({
    height: maximised ? 'calc(100% - 41px)' : '100%',
  }),
  ({ theme, variant }) =>
    ({
      box: {
        border: `1px solid ${theme.borderColor}`,
        borderRadius: 2,
        margin: '8px 16px',
      },
      stretch: {
        borderRadius: 2,
        marginTop: -41,
      },
    })[variant],
);

const BlockToolbar = styled(Toolbar)(
  {
    alignItems: 'center',
    boxSizing: 'content-box',
    display: 'flex',
    flex: '0 0 auto',
    zIndex: 1,
  },
  ({ theme, variant }) =>
    ({
      dark: {
        backgroundColor: theme.colors.gray2.hex,
        borderBottom: `1px solid ${theme.borderColor}`,
      },
      light: {
        backgroundColor: 'white',
      },
      bordered: {
        backgroundColor: 'white',
        borderBottom: `1px solid ${theme.borderColor}`,
      },
      translucent: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderBottom: `1px solid ${theme.borderColor}`,
      },
    })[variant],
);

const BlockWrapper = styled('div')(
  {
    backgroundColor: 'white',
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'start',
    overflow: 'hidden',
    zIndex: 1,
    flex: '1 0 auto',
  },
  ({ isDragging }) => ({
    opacity: isDragging && '0.5',
  }),
  ({ variant }) =>
    ({
      embed: {
        padding: '8px 0',
      },
    })[variant],
);

const BlockError = styled('div')({
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export { BlockContent, BlockError, BlockToolbar, BlockWrapper };
