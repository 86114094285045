import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const publicTokensFetch = createAsyncThunk(
  'publicTokens/fetch',
  async (arg, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const { data } = await api.get('/api/public-token');

    return data;
  },
);

export { publicTokensFetch };
