import PropTypes from 'prop-types';
import { AddBlockMenu } from 'components/add-block-menu';
import { Stack } from '@kinesis/bungle';
import Layout from 'components/layout';
import { PlaceholderMessage } from 'components/placeholder-message';
import usePermission from 'hooks/usePermission';

const propTypes = {
  boardId: PropTypes.number.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {};

const EmptyBoard = ({ boardId, workspaceId }) => {
  const editor = usePermission('editor');

  return (
    <Layout direction='column' style={{ justifyContent: 'center' }}>
      {editor ? (
        <Stack alignX='center' space='medium'>
          <PlaceholderMessage text='Add a visualisation to start exploring your workspace.' />
          <AddBlockMenu workspaceId={workspaceId} boardId={boardId} />
        </Stack>
      ) : (
        <PlaceholderMessage text='There are no blocks in this board.' />
      )}
    </Layout>
  );
};

EmptyBoard.propTypes = propTypes;
EmptyBoard.defaultProps = defaultProps;

export { EmptyBoard };
