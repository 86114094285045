import styled from 'styled-components';

const Row = styled('div')`
  flex: 1 1 auto;
  display: flex;
  height: 32px;
  align-items: center;
  padding-right: ${({ type }) =>
    ['type', 'type-total'].includes(type) ? '8px' : '48px'};
  padding-left: ${({ type }) =>
    ['type', 'type-total'].includes(type) ? '8px' : '32px'};
  font-weight: ${({ theme, type }) =>
    type === 'type' ? theme.fontWeight.medium : theme.fontWeight.normal};
  color: ${({ theme, type }) =>
    type === 'type' ? theme.color.gray9 : theme.color.gray8};
`;

const Label = styled('div')(
  () => ({
    flex: '1 0 auto',
  }),
  ({ theme, error }) =>
    error
      ? {
          color: theme.color.red6,
          fontWeight: theme.fontWeight.normal,
        }
      : {},
);

const Container = styled('div')`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: ${({ theme }) => theme.fontSize.md}px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.md};
`;

const Amount = styled('div')`
  flex: 0 0 auto;
`;

const Arrow = styled('div')`
  flex: 0 0 auto;
  color: ${({ theme }) => theme.color.gray5};
  padding-right: 8px;
  height: 24px;
`;

export { Row, Label, Amount, Container, Arrow };
