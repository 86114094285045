import { useCallback } from 'react';
import { isFinite, toString } from 'lodash/fp';
import PropTypes from 'prop-types';
import { DataEntry } from '../data-entry';

const propTypes = {
  columnIndex: PropTypes.number,
  disabled: PropTypes.bool,
  format: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  numberType: PropTypes.oneOf(['decimal', 'integer']),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  readOnly: PropTypes.bool,
  step: PropTypes.number,
  units: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  columnIndex: undefined,
  disabled: false,
  format: (val) => (typeof val === 'number' ? val.toLocaleString() : val),
  max: undefined,
  min: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  numberType: 'decimal',
  readOnly: false,
  step: 1,
  units: undefined,
  value: undefined,
};

const NumberEntry = ({
  columnIndex,
  disabled,
  format,
  max,
  min,
  numberType,
  onBlur,
  onChange,
  onFocus,
  readOnly,
  step,
  units,
  value,
}) => {
  const handleDecrement = useCallback(
    (val) => {
      const numberValue =
        numberType === 'integer' ? parseInt(val, 10) : parseFloat(val);

      if (!isFinite(numberValue)) {
        return val;
      }

      const newValue = numberValue - step;

      return toString(min ? Math.min(min, newValue) : newValue);
    },
    [min, numberType, step],
  );

  const handleIncrement = useCallback(
    (val) => {
      const numberValue =
        numberType === 'integer' ? parseInt(val, 10) : parseFloat(val);

      if (!isFinite(numberValue)) {
        return val;
      }

      const newValue = numberValue + step;

      return toString(max ? Math.min(max, newValue) : newValue);
    },
    [max, numberType, step],
  );

  const handleChange = useCallback(
    (val) => {
      const numberValue =
        numberType === 'integer' ? parseInt(val, 10) : parseFloat(val);

      if (isFinite(numberValue)) {
        onChange(numberValue);
      }
    },
    [numberType, onChange],
  );

  return (
    <DataEntry
      align='right'
      autoFocus
      columnIndex={columnIndex}
      disabled={disabled}
      format={format}
      inputMode='decimal'
      onBlur={onBlur}
      onChange={handleChange}
      onDecrement={handleDecrement}
      onFocus={onFocus}
      onIncrement={handleIncrement}
      pattern={numberType === 'integer' ? `-?[0-9]*` : `-?[.0-9]*`}
      readOnly={readOnly}
      units={units}
      value={value}
    />
  );
};

NumberEntry.propTypes = propTypes;
NumberEntry.defaultProps = defaultProps;

export { NumberEntry };
