import { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { first, get, map, reject, size } from 'lodash/fp';
import { AnimatedPopover, Prompt, useEventListener } from '@kinesis/bungle';
import { actions as miscActions } from 'reducers/miscReducer';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import blocksForBlockGridSelector from 'selectors/blocksForBlockGridSelector';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import Layout from 'components/layout';
import { Block } from 'components/block';
import { keyboardShortcutString } from 'utils/keyboardUtils';
import { useBlockId, useSelectorWithProps } from 'hooks';
import useScrollPosition from 'hooks/useScrollPosition';
import useResponsive from 'hooks/useResponsive';
import { blocksToSpanBlocks, calculateBlockHeight } from 'utils/blocks';
import useActions from 'hooks/useActions';
import { BlockGridWrapper } from 'components/block-grid/block-grid.styles';
import BlockCard from 'components/block-grid/block-card';
import { rawBlockTypes } from 'constants/block-types';
import {
  ImmutableBlockGridGrid,
  RawBlock,
} from './immutable-block-grid.styles';
import Bowser from 'bowser';
const { getParser } = Bowser;

const isMacOS = getParser(window.navigator.userAgent).getOSName() === 'macOS';

const propTypes = {
  boardId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const ImmutableBlockGrid = ({ boardId, workspaceId }) => {
  const maximisedBlockId = useBlockId();
  const blocks = useSelectorWithProps(blocksForBlockGridSelector, {
    boardId,
    workspaceId,
  });
  const selectedBlockIds = useSelector(blockSelectionsSelector);

  const footerRef = useRef();
  const gridRef = useRef();
  const selectedRef = useRef();
  const dispatch = useDispatch();

  const showPrompt = useSelector(get(['misc', 'showMultiSelectPrompt']));
  const handleHidePrompt = useCallback(() => {
    if (showPrompt) {
      dispatch(miscActions.clearShowMultiSelectPrompt());
    }
  }, [dispatch, showPrompt]);
  const { selectBlocks, resetBlocks } = useActions(blockSelectionsActions);

  const handleReset = useCallback(() => {
    if (size(selectedBlockIds) > 0) {
      resetBlocks();
    }

    handleHidePrompt();
  }, [handleHidePrompt, selectedBlockIds, resetBlocks]);
  const [scrollPosition, setScrollPosition] = useScrollPosition();

  const { isFullSizeBoard } = useResponsive();
  const spannedBlocks = useMemo(() => {
    const nonEmptyBlocks = reject({ visualisation: 'empty' }, blocks);
    return blocksToSpanBlocks(nonEmptyBlocks, isFullSizeBoard);
  }, [blocks, isFullSizeBoard]);

  useEffect(() => {
    if (maximisedBlockId) {
      return;
    }

    if (scrollPosition.y) {
      gridRef.current.scrollTo(0, scrollPosition.y);
    } else if (selectedRef.current) {
      const gridRect = gridRef.current.getBoundingClientRect();
      const blockRect = selectedRef.current.getBoundingClientRect();

      gridRef.current.scrollTo(0, blockRect.top - gridRect.top - 16);
    }
  }, [maximisedBlockId, scrollPosition]);

  const promptText = useMemo(
    () =>
      'ontouchstart' in window
        ? 'Tap and hold to select more than one block'
        : `Hold shift to select more than one block (${keyboardShortcutString({
            key: 'click',
            shift: true,
          })})`,
    [],
  );

  // Auto dismiss after a while
  useEffect(() => {
    if (showPrompt && size(selectedBlockIds) === 1) {
      const timerId = setTimeout(handleHidePrompt, 6000);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [handleHidePrompt, selectedBlockIds, showPrompt]);

  useEventListener(
    'keydown',
    useCallback(
      (event) => {
        if (maximisedBlockId) {
          return;
        }

        const isCommandKey = isMacOS && event.metaKey;
        const isCtrlKey = !isMacOS && event.ctrlKey;
        const isCommandOrControlKey = isCommandKey || isCtrlKey;
        const isSelectAllCombo = isCommandOrControlKey && event.key === 'a';

        if (isSelectAllCombo) {
          // disables text selecting on select all
          event.preventDefault();
          const selections = reject({ blockType: 'row-break' }, blocks);
          selectBlocks(map('id', selections));
        }
      },
      [blocks, maximisedBlockId, selectBlocks],
    ),
  );

  const handleSelectedBlockRef = useCallback(
    (block) =>
      size(selectedBlockIds) === 1 && first(selectedBlockIds) === block.id
        ? selectedRef
        : undefined,
    [selectedBlockIds, selectedRef],
  );

  return (
    <Layout direction='column'>
      <Layout
        direction='column'
        onScroll={!maximisedBlockId ? setScrollPosition : undefined}
        ref={gridRef}
      >
        {maximisedBlockId ? (
          <Block key={maximisedBlockId} id={maximisedBlockId} />
        ) : (
          <>
            <BlockGridWrapper onClick={handleReset}>
              <ImmutableBlockGridGrid>
                {spannedBlocks.map((block) =>
                  rawBlockTypes.includes(block.blockType) ? (
                    <RawBlock key={block.id} span={block.span}>
                      <Block
                        id={block.id}
                        isFirst={block.isFirst}
                        isLast={block.isLast}
                      />
                    </RawBlock>
                  ) : (
                    <BlockCard
                      key={block.id}
                      height={calculateBlockHeight(block, spannedBlocks)}
                      id={block.id}
                      ref={handleSelectedBlockRef(block)}
                      span={block.span}
                    />
                  ),
                )}
              </ImmutableBlockGridGrid>
            </BlockGridWrapper>

            <AnimatedPopover
              justify='center'
              offset={49}
              open={showPrompt && size(selectedBlockIds) === 1}
              placement='top'
              preventOverflow={false}
              targetRef={footerRef}
            >
              <Prompt
                dismissOnKeyPress={false}
                showPrompt={showPrompt && size(selectedBlockIds) === 1}
                showTriangle={false}
                title={promptText}
              >
                <div />
              </Prompt>
            </AnimatedPopover>
          </>
        )}
      </Layout>

      <footer ref={footerRef} />
    </Layout>
  );
};

ImmutableBlockGrid.propTypes = propTypes;
ImmutableBlockGrid.defaultProps = defaultProps;

export { ImmutableBlockGrid };
