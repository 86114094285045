import { get, orderBy, pipe, lowerCase, defaultTo } from 'lodash/fp';
import { workspacePreview } from 'actions/workspacePreview';
import { workspacePreviews } from 'actions/workspacePreviews';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspaceDuplicate } from 'actions/workspaceDuplicate';
import { workspaceCreate } from 'actions/workspaceCreate';
import { workspaceUpdate } from 'actions/workspaceUpdate';
import { workspacePatch } from 'actions/workspacePatch';
import { workspacesFetch } from 'actions/workspacesFetch';

function createWorkspacePreviewMiddleware() {
  return ({ dispatch }) =>
    (next) =>
    async (action) => {
      next(action);

      switch (action.type) {
        case workspaceFetch.fulfilled.type:
        case workspacePatch.fulfilled.type:
        case workspaceUpdate.fulfilled.type:
        case workspaceCreate.fulfilled.type:
        case workspaceDuplicate.fulfilled.type:
          dispatch(
            workspacePreview({
              id: action.payload.id,
              geographies: action.payload.geographyIds,
            }),
          );
          break;
        case workspacesFetch.fulfilled.type:
          dispatch(
            workspacePreviews({
              workspaces: orderBy(
                pipe(get('name'), defaultTo('New workspace'), lowerCase),
                'asc',
                action.payload.workspaces,
              ).map(({ id, geographyIds }) => ({
                id,
                geographies: geographyIds,
              })),
            }),
          );
          break;
        default:
      }
    };
}

export { createWorkspacePreviewMiddleware };
