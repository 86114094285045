import PropTypes from 'prop-types';
import { Block } from './form-section.styles';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]),
};

const defaultProps = {
  children: null,
};

const FormSection = ({ children }) => <Block>{children}</Block>;

FormSection.defaultProps = defaultProps;
FormSection.propTypes = propTypes;

export default FormSection;
