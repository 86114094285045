import { createSelector } from 'reselect';
import { isNil, map, reject, pipe, get } from 'lodash/fp';
import { pointOf } from 'data/geography';
import { coordinatesToBounds } from 'utils/spatialUtils';
import workspaceGeographiesSelector from 'selectors/workspaceGeographiesSelector';
import currentAttributesSelector from './currentAttributesSelector';
import enabledLocationsSelector from './enabledLocationsSelector';

const boundsSelector = createSelector(
  workspaceGeographiesSelector,
  enabledLocationsSelector,
  pipe(currentAttributesSelector, get('values')),
  (geographies, locations, attributeValues) =>
    pipe(
      map('id'),
      map((id) => pointOf(id, attributeValues, geographies)),
      reject(isNil),
      map(({ latitude, longitude }) => [longitude, latitude]),
      coordinatesToBounds,
    )(locations),
);

export default boundsSelector;
