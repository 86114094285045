import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@kinesis/bungle';
import { isNil } from 'lodash/fp';
import CategoricalFilter from 'components/form/CategoricalFilter';
import MonthFilter from 'components/form/MonthFilter';
import NumberFilter from 'components/form/NumberFilter';
import YearFilter from 'components/form/YearFilter';

const propTypes = {
  encoding: PropTypes.string,
  filterKey: PropTypes.string.isRequired,
  format: PropTypes.func,
  label: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  symbol: PropTypes.string,
  type: PropTypes.oneOf(['month', 'multiselect', 'numeric', 'select', 'year'])
    .isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};

const defaultProps = {
  encoding: undefined,
  format: undefined,
  min: undefined,
  max: undefined,
  options: [],
  symbol: undefined,
  value: undefined,
};

const Filter = ({
  encoding,
  format,
  filterKey,
  label,
  onChange,
  options,
  symbol,
  type,
  value,
  min,
  max,
}) => {
  const handleChange = useCallback(
    (val) => {
      onChange(filterKey, val);
    },
    [filterKey, onChange],
  );

  switch (type) {
    case 'numeric':
      return (
        <NumberFilter
          key={isNil(value)} // force re-mount on reset
          format={format}
          label={label}
          onChange={handleChange}
          symbol={symbol}
          type={encoding === 'float64' ? 'decimal' : 'integer'}
          value={value}
        />
      );

    case 'multiselect':
      return (
        <CategoricalFilter
          format={format}
          label={label}
          onChange={handleChange}
          options={options}
          value={value}
        />
      );

    case 'month':
      return (
        <MonthFilter label={label} onChange={handleChange} value={value} />
      );

    case 'year':
      return (
        <YearFilter
          key={isNil(value)} // force re-mount on reset
          label={label}
          onChange={handleChange}
          value={value}
          yearMin={min}
          yearMax={max}
        />
      );

    case 'select':
      return (
        <Select
          label={label}
          value={value}
          onChange={handleChange}
          // TODO support useMemo here?
          options={options.map((opt) => ({ label: format(opt), value: opt }))}
          placeholder='Select items'
        />
      );

    default:
  }

  return null;
};

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

export default Filter;
