import { createSelector } from 'reselect';
import { get, map, pipe } from 'lodash/fp';
import sortedScenariosSelector from 'selectors/sortedScenariosSelector';
import workspaceSelector from 'selectors/workspaceSelector';

const scenariosWithAttributesSelector = createSelector(
  sortedScenariosSelector,
  pipe(workspaceSelector, get('attributes')),
  (scenarios, attributes) =>
    map(
      (scenario) => ({
        attributes: get(
          get('scenario', scenario.draft || scenario.published),
          attributes,
        ),
        id: scenario.id,
        name: scenario.name,
      }),
      scenarios,
    ),
);

export default scenariosWithAttributesSelector;
