import styled from 'styled-components';

const VisualisationContainer = styled.div`
  flex: 1 1 auto;
  min-width: 496px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`;

const PlaceholderContainer = styled.div`
  flex: 1 1 auto;
  width: 352px;
  min-width: 352px;
  margin-left: auto;
  margin-right: auto;
`;

const VisualisationLayout = styled.div`
  display: flex;
  align-items: stretch;
  padding: 16px 16px 24px 16px;
  width: 100%;
  overflow: auto;
  max-height: calc(100% - 49px);
`;

export { VisualisationLayout, VisualisationContainer, PlaceholderContainer };
