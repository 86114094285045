import styled from 'styled-components';
import { Error } from 'components/error';
import { GlobalLayout } from 'components/global-layout';

const ErrorLayout = styled(GlobalLayout)`
  background: white;
`;

const NotFound = () => (
  <ErrorLayout direction='column'>
    <Error variant='not-found' />
  </ErrorLayout>
);

export default NotFound;
