import { createSelector } from 'reselect';
import { find, get, pipe } from 'lodash/fp';

const toolboxDetailsSelector = createSelector(
  pipe(get(['toolbox', 'panes'])),
  (details) =>
    pipe(
      find((p) => p.type === 'detail'),
      get('selection'),
    )(details),
);

const toolboxLocationIdSelector = createSelector(
  toolboxDetailsSelector,
  (selection) =>
    get('type', selection) === 'location' ? selection.id : undefined,
);

const toolboxSpatialDetailIdSelector = createSelector(
  toolboxDetailsSelector,
  (selection) =>
    get('type', selection) === 'spatial-detail' ? selection.id : undefined,
);

const toolboxChartDetailIdSelector = createSelector(
  toolboxDetailsSelector,
  (selection) =>
    get('type', selection) === 'chart-detail' ? selection.id : undefined,
);

export {
  toolboxLocationIdSelector,
  toolboxSpatialDetailIdSelector,
  toolboxDetailsSelector,
  toolboxChartDetailIdSelector,
};
