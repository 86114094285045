import styled from 'styled-components';

const TableText = styled('div')(
  {
    overflow: 'hidden',
    padding: '6px 12px',
    textOverflow: 'ellipsis',
  },
  ({ columnIndex }) =>
    columnIndex === 0
      ? {
          paddingLeft: 16,
        }
      : undefined,
);

export { TableText };
