import { createSelector } from 'reselect';
import { get, pipe, first } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';
import blockPerspectiveSelector from './blockPerspectiveSelector';

const publicVisualisationLoadingSelector = createSelector(
  (state, props) => first(blockPerspectiveSelector(state, props)),
  pipe(workspaceSelector, get(['data', 'lifecycle'])),
  (perspectiveId, lifecycle) => get(perspectiveId, lifecycle) !== 'OK',
);

export default publicVisualisationLoadingSelector;
