import styled from 'styled-components';

const MapContainer = styled.section`
  height: 100%;
  margin-top: 8px;
  overflow: hidden;
  position: relative;
  border-left: 1px solid ${({ theme }) => theme.borderColor};
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  border-top-left-radius: 4px;
`;

export default MapContainer;
