import publicColorDomainSelector from 'selectors/publicColorDomainSelector';
import privateColorDomainSelector from 'selectors/privateColorDomainSelector';

function colorDomainSelector(state, props) {
  return props?.public
    ? publicColorDomainSelector(state, props)
    : privateColorDomainSelector(state, props);
}

export default colorDomainSelector;
