import { createSelector } from 'reselect';
import { compact, get, map, placeholder, pipe } from 'lodash/fp';

const uploadsSelector = createSelector(
  (state, props = {}) => props.uploads,
  get('uploads'),
  (keys, uploads) => pipe(map(get(placeholder, uploads)), compact)(keys),
);

export default uploadsSelector;
