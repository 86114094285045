import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  pipe,
  values,
  every,
  some,
  filter,
  fromPairs,
  map,
} from 'lodash/fp';
import { Checkbox, Fieldset } from '@kinesis/bungle';
import { actions as featureFlagsActions } from 'reducers/featureFlagsReducer';
import Modal from 'components/modals/modal/Modal';
import { ModalHeader, ModalHeading } from 'components/modals/modal-header';
import { FlagGrid, FlagPane, AllFlag } from './flags.styles';

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

const featureFlagsSelector = pipe(get('featureFlags'), values);

const Flags = ({ onClose }) => {
  const dispatch = useDispatch();
  const featureFlags = useSelector(featureFlagsSelector);
  const allOn = useMemo(
    () => pipe(filter('editable'), every('enabled'))(featureFlags),
    [featureFlags],
  );
  const someOn = useMemo(
    () => !allOn && pipe(filter('editable'), some('enabled'))(featureFlags),
    [featureFlags, allOn],
  );

  const toggleFeatureFlag = useCallback(
    (key) => {
      dispatch(featureFlagsActions.toggleFeatureFlag({ key }));
    },
    [dispatch],
  );

  const toggleAll = useCallback(() => {
    dispatch(
      featureFlagsActions.setFeatureFlags(
        pipe(
          filter({ editable: true }),
          map((f) => [f.name, !allOn]),
          fromPairs,
        )(featureFlags),
      ),
    );
  }, [dispatch, featureFlags, allOn]);
  const headerLabel = 'Feature flags';

  return (
    <Modal
      aria-label={headerLabel}
      magnitude='xlarge'
      onClose={onClose}
      header={
        <ModalHeader>
          <ModalHeading>{headerLabel}</ModalHeading>
        </ModalHeader>
      }
      saveable={false}
    >
      <FlagPane>
        <Fieldset hideLegend legend='Select feature flags'>
          <FlagGrid>
            <AllFlag>
              <Checkbox
                checked={allOn}
                indeterminate={someOn}
                disabled={false}
                onChange={toggleAll}
                title='Toggle them all'
              >
                Yes really, toggle them all.
              </Checkbox>
            </AllFlag>
            {featureFlags.map((flag) => (
              <Checkbox
                key={flag.id}
                checked={flag.enabled}
                disabled={!flag.editable}
                onChange={() => toggleFeatureFlag(flag.name)}
                title={flag.name}
              >
                {flag.description}
              </Checkbox>
            ))}
          </FlagGrid>
        </Fieldset>
      </FlagPane>
    </Modal>
  );
};

Flags.defaultProps = defaultProps;
Flags.propTypes = propTypes;

export { Flags };
