import PropTypes from 'prop-types';
import {
  DataGridRowWrapper,
  DataGridRowInner,
  DataGridRowHeader,
  DataGridRowHeaderTitle,
} from './data-grid-row.styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'primary', 'secondary']),
};

const defaultProps = {
  variant: 'default',
};

const DataGridRow = ({ children, title, variant }) => (
  <DataGridRowWrapper>
    <DataGridRowInner>
      <DataGridRowHeader variant={variant}>
        <DataGridRowHeaderTitle>{title}</DataGridRowHeaderTitle>
      </DataGridRowHeader>
      {children}
    </DataGridRowInner>
  </DataGridRowWrapper>
);

DataGridRow.propTypes = propTypes;
DataGridRow.defaultProps = defaultProps;

export { DataGridRow };
