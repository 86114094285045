import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Portal } from '@kinesis/bungle';
import styled from 'styled-components';
import { POPOVER_WIDTH, calculateX, calculateY } from 'utils/popoverUtils';

const StyledPopover = styled.div`
  background: white;
  border-radius: 4px;
  position: absolute;
  width: ${POPOVER_WIDTH}px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 99999;
`;

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]),
  pointerX: PropTypes.number.isRequired,
  pointerY: PropTypes.number.isRequired,
  disablePointerEvents: PropTypes.bool,
};

const defaultProps = {
  children: null,
  disablePointerEvents: false,
};

const Popover = ({ children, pointerX, pointerY, disablePointerEvents }) => {
  const ref = useRef();
  return (
    <Portal style={{ pointerEvents: disablePointerEvents ? 'none' : 'auto' }}>
      <StyledPopover
        ref={ref}
        style={{
          left: calculateX(pointerX),
          top: calculateY(pointerY, ref.current),
          pointerEvents: disablePointerEvents ? 'none' : 'auto',
        }}
      >
        {children}
      </StyledPopover>
    </Portal>
  );
};

Popover.propTypes = propTypes;
Popover.defaultProps = defaultProps;

export default Popover;
