import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import { actions as uploadActions } from 'reducers/uploadsReducer';

const datasetUpload = createAsyncThunk(
  'dataset/upload',
  async ({ datasetId, file, format, key }, { dispatch, getState }) => {
    const source = file.name;
    const api = createApiFromState({ dispatch, getState });
    const { data } = await api.post(`/api/datasets/${datasetId}/uploads`, {
      format,
      source,
    });
    const onUploadProgress = (e) => {
      dispatch(
        uploadActions.progress({
          key,
          loaded: e.loaded,
          total: e.total,
        }),
      );
    };
    await axios.put(data.url, file, {
      'Content-Type': file.type,
      onUploadProgress,
    });
    return { id: data.id };
  },
);

export { datasetUpload };
