import { createSelector } from 'reselect';
import {
  concat,
  contains,
  defaultTo,
  find,
  flatMap,
  get,
  map,
  pipe,
  reject,
  uniqWith,
} from 'lodash/fp';

import loadedAppVersionsSelector from 'selectors/loadedAppVersionsSelector';
import appManagementSelector from './appManagementSelector';

const dependenciesForApp = (apps, app, visitedIds) => {
  visitedIds.push(app);

  const appDependencies = pipe(
    find({ id: app }),
    get(['available', 'dependencies']),
    defaultTo([]),
    map((a) =>
      find({ key: get('key', a), owner: { key: get('owner', a) } }, apps),
    ),
    reject((dep) => contains(dep.id, visitedIds)),
  )(apps);

  return concat(
    appDependencies,
    flatMap(
      (a) => dependenciesForApp(apps, get('id', a), visitedIds),
      appDependencies,
    ),
  );
};

const dependentAppsSelector = createSelector(
  appManagementSelector,
  loadedAppVersionsSelector,
  (state, props) => props.app,
  (apps, loaded, app) => {
    const visitedIds = [];
    const dependencies = dependenciesForApp(apps, app, visitedIds);

    return pipe(
      uniqWith((a, b) => a.owner.key === b.owner.key && a.key === b.key),
      reject((a) => find({ app: a.id }, loaded)),
    )(dependencies);
  },
);

export default dependentAppsSelector;
