import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetVersionUpload = createAsyncThunk(
  'dataset-version/upload',
  async ({ datasetId, versionId, upload }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/datasets/${datasetId}/actions`, {
      type: 'upload',
      versionId,
      upload,
    });
    return response.data;
  },
);

export { datasetVersionUpload };
