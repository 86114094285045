import { createSelector } from 'reselect';
import { get, lowerCase, orderBy, pipe, values } from 'lodash/fp';
import boardsSelector from './boardsSelector';

const sortedBoardsSelector = createSelector(
  boardsSelector,
  (state, props = {}) => props.sortField || 'name',
  (boards, sortField) =>
    pipe(
      values,
      orderBy(
        [pipe(get(sortField), lowerCase)],
        [sortField === 'name' ? 'asc' : 'desc'],
      ),
    )(boards),
);

export default sortedBoardsSelector;
