import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { redirectPath } from 'services/redirect-to';
import { getToken } from 'services/share-token';

function useRedirectHandler() {
  const navigate = useNavigate();

  return useCallback(
    (payload) => {
      const path = redirectPath() ?? '/';
      const workspaceShareToken = getToken();

      if (payload.newLogin) {
        if (path.startsWith('/city') || path.startsWith('/integrated')) {
          window.location = `https://ccap.app.kinesis.org${path}`;
        } else if (workspaceShareToken) {
          navigate(`/workspaces/shares/${workspaceShareToken}`);
        } else {
          navigate(path);
        }
      }
    },
    [navigate],
  );
}

export { useRedirectHandler };
