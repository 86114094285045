import styled from 'styled-components';

const ThreeColumnGrid = styled('div')({
  gridTemplateColumns: 'repeat(3, 33.3%)',
  display: 'grid',
});

const TwoColumnGrid = styled('div')({
  gridTemplateColumns: '33% 67%',
  display: 'grid',
});

const GridItem = styled('div')(
  ({ theme }) => ({
    borderColor: theme.borderColor,
    borderStyle: 'solid',
    borderWidth: `0 1px 1px 0`,
  }),
  ({ $lastColumn }) =>
    $lastColumn
      ? {
          borderRightWidth: 0,
        }
      : undefined,
  ({ $lastRow }) =>
    $lastRow
      ? {
          borderBottomWidth: 0,
        }
      : undefined,
);

export { ThreeColumnGrid, TwoColumnGrid, GridItem };
