import styled from 'styled-components';
import { rgba } from 'polished';

const DataEntryText = styled('div').attrs({
  role: 'button',
  tabIndex: 0,
})(
  ({ theme }) => ({
    alignItems: 'center',
    border: '1px solid transparent',
    boxSizing: 'border-box',
    color: theme.color.default,
    cursor: 'text',
    display: 'flex',
    fontFamily: theme.fontFamily.default,
    fontSize: theme.fontSize.default,
    fontWeight: theme.fontWeight.normal,
    height: '100%',
    lineHeight: theme.lineHeight.default,
    outline: 0,
    overflow: 'hidden',
    padding: '6px 12px',
    position: 'relative',
    textOverflow: 'ellipsis',
    transitionDuration: '0.3s',
    transitionProperty: 'box-shadow',
    width: '100%',
    '&:active, &:hover': {
      borderColor: theme.color.blue6,
    },
    '&:focus': {
      borderColor: theme.color.gray4,
      boxShadow: `0 0 0 2px ${rgba(0, 0, 0, 0.05)}`,
    },
  }),
  ({ align }) => ({
    justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
  }),
  ({ columnIndex }) =>
    columnIndex === 0
      ? {
          paddingLeft: 16,
        }
      : undefined,
  ({ disabled, readOnly }) =>
    disabled || readOnly
      ? {
          pointerEvents: 'none',
        }
      : undefined,
  ({ disabled, theme }) =>
    disabled
      ? {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          color: theme.color.gray7,
        }
      : undefined,
);

const DataEntryInput = styled('input').attrs({
  type: 'text',
})(
  ({ theme }) => ({
    border: '1px solid transparent',
    boxSizing: 'border-box',
    color: theme.color.default,
    display: 'flex',
    fontFamily: theme.fontFamily.default,
    fontSize: theme.fontSize.default,
    fontWeight: theme.fontWeight.normal,
    height: '100%',
    lineHeight: theme.lineHeight.default,
    outline: 0,
    overflow: 'hidden',
    padding: '6px 12px',
    textOverflow: 'ellipsis',
    transitionDuration: '0.3s',
    transitionProperty: 'box-shadow',
    width: '100%',
    '&:active, &:hover': {
      borderColor: theme.color.blue6,
    },
    '&:focus': {
      borderColor: theme.color.blue6,
      boxShadow: `0 0 0 2px ${rgba(theme.color.blue6, 0.25)}`,
    },
  }),
  ({ columnIndex }) =>
    columnIndex === 0
      ? {
          paddingLeft: 16,
        }
      : undefined,
);

const DataEntrySuffix = styled('div')({
  paddingLeft: 8,
});

export { DataEntryText, DataEntryInput, DataEntrySuffix };
