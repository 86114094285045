import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import blockStateSelector from './blockStateSelector';

const blockDataVersionSelector = createSelector(
  blockStateSelector,
  (state, props) => props.scenarioId,
  (blockState, scenarioId) =>
    get(['scenarios', scenarioId, 'dataVersion'], blockState),
);

export default blockDataVersionSelector;
