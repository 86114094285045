import { mapKeys, pick, snakeCase } from 'lodash/fp';
import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceUpdate = createAsyncThunk(
  'workspace/update',
  async ({ fields = {}, workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const sanitizedFields = pick(
      ['name', 'description', 'organisationId', 'startYear', 'endYear'],
      fields,
    );
    const fieldsAsSnakeCase = mapKeys(snakeCase, sanitizedFields);

    const response = await api.put(
      `/api/workspace/${workspaceId}`,
      fieldsAsSnakeCase,
    );

    const { data } = response;

    return {
      access: data.access,
      description: data.description,
      startYear: data.start_year,
      endYear: data.end_year,
      name: data.name,
      organisationId: data.organisation_id,
      touchedAt: data.touched_at,
      touchedBy: data.touched_by,
    };
  },
);

export { workspaceUpdate };
