import styled from 'styled-components';
import { Icon } from '@kinesis/bungle';

const ViewOnlyWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.default}px;
  height: 100%;
  line-height: ${({ theme }) => theme.lineHeight.default};
  white-space: nowrap;
`;

const ViewOnlyIcon = styled.i`
  color: ${({ theme }) => theme.color.gray7};
  font-size: 20px;
  margin-right: 8px;
`;

const ViewOnlyIndicator = () => (
  <ViewOnlyWrapper>
    <ViewOnlyIcon>
      <Icon type='eye-filled' />
    </ViewOnlyIcon>
    <span>View only</span>
  </ViewOnlyWrapper>
);

export { ViewOnlyIndicator };
