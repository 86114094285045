import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty, reject } from 'lodash/fp';
import usePreference from 'hooks/usePreference';
import loadedAppsSelector from 'selectors/loadedAppsSelector';
import nonSyntheticLocationCountSelector from 'selectors/nonSyntheticLocationCountSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import { useSelectorWithProps } from 'hooks';

const PROMPTS = {
  empty: -1,
  name: 1,
  apps: 2,
  locations: 3,
};

function useWorkspacePrompts({ workspaceId }) {
  const [currentPrompt, setCurrentPrompt] = useState(0);
  const workspace = useSelectorWithProps(workspaceSelector, { workspaceId });
  const name = get(['data', 'name'], workspace);
  const editor = get(['data', 'permissions', 'editor'], workspace);
  const isAppsLoading = useSelector(get(['apps', 'loading'])) !== 'idle';
  const noOtherApps = isEmpty(
    reject({ key: 'core' })(
      useSelectorWithProps(loadedAppsSelector, { workspaceId }),
    ),
  );
  const locationsCount = useSelectorWithProps(
    nonSyntheticLocationCountSelector,
    {
      workspaceId,
    },
  );
  const showAppsModal = get(['misc', 'showAppManagementModal'], workspace);
  const [viewedAppsPrompt] = usePreference(
    `marathon:workspace:${workspaceId}:viewed-apps-prompt`,
    'f',
  );
  const hasViewedAppsPrompt = viewedAppsPrompt === 't';

  const isCurrentPrompt = useCallback(
    (key) => (showAppsModal ? false : currentPrompt === PROMPTS[key]),
    [currentPrompt, showAppsModal],
  );
  const showNextPrompt = useCallback(
    () => setCurrentPrompt((n) => n + 1),
    [setCurrentPrompt],
  );

  useEffect(() => {
    if (!editor) {
      setCurrentPrompt(PROMPTS.empty);
    } else if (!name) {
      setCurrentPrompt((n) => (n < PROMPTS.name ? PROMPTS.name : n));
    } else if (isAppsLoading) {
      setCurrentPrompt(PROMPTS.empty);
    } else if (noOtherApps && !hasViewedAppsPrompt) {
      setCurrentPrompt((n) => (n < PROMPTS.apps ? PROMPTS.apps : n));
    } else if (locationsCount === 0) {
      setCurrentPrompt((n) => (n < PROMPTS.locations ? PROMPTS.locations : n));
    } else {
      setCurrentPrompt(PROMPTS.empty);
    }
  }, [
    setCurrentPrompt,
    editor,
    hasViewedAppsPrompt,
    isAppsLoading,
    locationsCount,
    name,
    noOtherApps,
  ]);

  return [isCurrentPrompt, showNextPrompt];
}

export default useWorkspacePrompts;
