import axios from 'axios';

function createCdnApi() {
  return axios.create({
    baseURL: 'https://platform-assets.kinesis.org',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

export default createCdnApi;
