import { createSelector } from 'reselect';
import sortedScenariosSelector from 'selectors/sortedScenariosSelector';
import { concat, map, pipe } from 'lodash/fp';

const mapWithIndex = map.convert({ cap: false });

const selectedScenarioOption = {
  label: 'Selected scenario',
  value: { type: 'selected' },
  category: 'selected',
};

const syntheticOptions = (type) => {
  switch (type) {
    case 'line':
    case 'stacked-column':
    case 'stacked-area':
    case 'clustered-column':
      return [
        selectedScenarioOption,
        {
          label: 'Compare scenarios',
          value: { type: 'all' },
          category: 'comparison',
        },
      ];
    case 'indicator':
    case 'choropleth':
    default:
      return [selectedScenarioOption];
  }
};

const visualisationScenarioOptionsSelector = createSelector(
  (state, props) => props.type,
  sortedScenariosSelector,
  (type, scenarios) =>
    pipe(
      concat(syntheticOptions(type)),
      mapWithIndex((option, index) => ({ ...option, key: index })),
    )(
      map(
        (scenario) => ({
          label: scenario.name,
          value: { type: 'by-id', id: scenario.id },
          isDefault: scenario.isDefault,
          category: 'list',
        }),
        scenarios,
      ),
    ),
);

export default visualisationScenarioOptionsSelector;
