import { get, identity, isEmpty, pipe, reject } from 'lodash/fp';
import createDeepEqualSelector from 'utils/createDeepEqualSelector';
import blocksSelector from './blocksSelector';
import workspaceSelector from './workspaceSelector';

const isEmptyBoardSelector = createDeepEqualSelector(
  pipe(workspaceSelector, get(['data', 'permissions', 'editor'])),
  pipe(blocksSelector),
  (isEditor, blocks) =>
    pipe(
      !isEditor ? reject({ capsule: { visualisation: 'empty' } }) : identity,
      isEmpty,
    )(blocks),
);

export default isEmptyBoardSelector;
