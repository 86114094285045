import { createSelector } from 'reselect';
import { get, filter } from 'lodash/fp';
import appManagementSelector from 'selectors/appManagementSelector';

const appUpgradesSelector = createSelector(
  appManagementSelector,
  filter(
    (app) =>
      app.version &&
      get(['version', 'id'], app) !== get(['available', 'id'], app),
  ),
);

export default appUpgradesSelector;
