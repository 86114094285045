import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce, pipe, map, filter, flatMap } from 'lodash/fp';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  Input,
  UtilityButton,
} from '@kinesis/bungle';
import {
  Content,
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
  ToolbarSeparator,
} from '@kinesis/bungle/legacy';
import { HorizontalPanes, HorizontalPane } from 'components/horizontal-panes';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import hasFineareaGeographySelector from 'selectors/hasFineareaGeographySelector';
import { LoadingIcon } from 'components/loading-icon';
import { ToolbarBackground } from 'components/toolbar-background';
import { FineAreaGranularitySelect } from 'components/fine-area-granularity-select';
import {
  fineareas,
  subregions,
  geographyLabels,
} from './attribute-values.fine-area-correspondence.data';
import { FineAreaCorrespondenceTable } from './attribute-values.fine-area-correspondence.table';
import { FineAreaCorrespondenceMap } from './attribute-values.fine-area-correspondence.map';

const propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

const defaultProps = {};

const FineAreaCorrespondenceAttributeValues = ({ setValues, values }) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const setQueryWithDebounce = useMemo(
    () => debounce(300, setDebouncedQuery),
    [setDebouncedQuery],
  );
  const onQuery = useCallback(
    (v) => {
      setQuery(v);
      setQueryWithDebounce(v);
    },
    [setQueryWithDebounce, setQuery],
  );

  useEffect(
    () => () => {
      setQueryWithDebounce.cancel();
    },
    [setQueryWithDebounce],
  );

  const [selected, setSelected] = useState([]);
  const [selection, setSelection] = useState('all');
  const setSelectionAll = useCallback(
    () => setSelection('all'),
    [setSelection],
  );
  const setSelectionCorrespondence = useCallback(
    () => setSelection('correspondence'),
    [setSelection],
  );
  const setSelectionNoCorrespondence = useCallback(
    () => setSelection('no-correspondence'),
    [setSelection],
  );
  const [granularity, setGranularity] = useState('regions');
  const onGranularityChange = useCallback(
    (v) => {
      setGranularity((current) => {
        if (current === 'regions' && v === 'subregions') {
          setSelected(
            pipe(
              map((selectedId) => geographyLabels[selectedId]),
              flatMap((region) =>
                pipe(filter({ region }), map('geography'))(subregions),
              ),
            ),
          );
        } else if (current === 'regions' && v === 'fineareas') {
          setSelected(
            pipe(
              map((selectedId) => geographyLabels[selectedId]),
              flatMap((region) =>
                pipe(filter({ region }), map('geography'))(fineareas),
              ),
            ),
          );
        } else if (current === 'subregions' && v === 'fineareas') {
          setSelected(
            pipe(
              map((selectedId) => geographyLabels[selectedId]),
              flatMap((subregion) =>
                pipe(filter({ subregion }), map('geography'))(fineareas),
              ),
            ),
          );
        } else {
          setSelected([]);
        }
        return v;
      });
    },
    [setGranularity, setSelected],
  );

  const hasGeojson = useSelectorWithProps(hasFineareaGeographySelector, {
    granularity,
  });
  return (
    <HorizontalPanes>
      <HorizontalPane basis={438}>
        <ToolbarBackground>
          <Toolbar justify='space-between'>
            <ToolbarGroup>
              <ToolbarItem>
                <FineAreaGranularitySelect
                  onSelect={onGranularityChange}
                  value={granularity}
                />
              </ToolbarItem>
            </ToolbarGroup>
            {!hasGeojson && (
              <ToolbarGroup>
                <ToolbarItem>
                  <LoadingIcon magnitude='small' />
                </ToolbarItem>
              </ToolbarGroup>
            )}
          </Toolbar>
        </ToolbarBackground>
        <Content overflow='hidden' height='calc(100% - 41px)'>
          <FineAreaCorrespondenceMap
            granularity={granularity}
            selected={selected}
            setSelected={setSelected}
          />
        </Content>
      </HorizontalPane>
      <HorizontalPane flex={1}>
        <ToolbarBackground>
          <Toolbar justify='space-between'>
            <ToolbarGroup expand>
              <ToolbarItem>
                <DropdownMenu>
                  <DropdownMenuButton
                    as={UtilityButton}
                    magnitude='small'
                    icon='filter'
                    variant={selection !== 'all' ? 'accent' : undefined}
                  />
                  <DropdownMenuList width={240}>
                    <DropdownMenuItem
                      variant={selection === 'all' ? 'accent' : undefined}
                      onSelect={setSelectionAll}
                    >
                      All geographies
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      variant={
                        selection === 'correspondence' ? 'accent' : undefined
                      }
                      onSelect={setSelectionCorrespondence}
                    >
                      Geographies with correspondence
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      variant={
                        selection === 'no-correspondence' ? 'accent' : undefined
                      }
                      onSelect={setSelectionNoCorrespondence}
                    >
                      Geographies with no correspondence
                    </DropdownMenuItem>
                  </DropdownMenuList>
                </DropdownMenu>
              </ToolbarItem>
              <ToolbarSeparator />
              <ToolbarItem flex={1} expand>
                <Input
                  magnitude='small'
                  placeholder='Filter geographies…'
                  type='search'
                  value={query}
                  onChange={onQuery}
                />
              </ToolbarItem>
            </ToolbarGroup>
          </Toolbar>
        </ToolbarBackground>
        <FineAreaCorrespondenceTable
          setValues={setValues}
          values={values}
          query={debouncedQuery}
          selection={selection}
          selected={selected}
          granularity={granularity}
        />
      </HorizontalPane>
    </HorizontalPanes>
  );
};
FineAreaCorrespondenceAttributeValues.defaultProps = defaultProps;
FineAreaCorrespondenceAttributeValues.propTypes = propTypes;

export default FineAreaCorrespondenceAttributeValues;
