import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { Select } from '@kinesis/bungle';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import visualisationMeasureOptionsSelector from 'selectors/visualisationMeasureOptionsSelector';
import blockDataSelector from 'selectors/blockDataSelector';
import { labelOfMeasure } from 'data/block';
import isCapsuleMismatchSelector from 'selectors/isCapsuleMismatchSelector';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  defaultQuery: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.exact({
      type: PropTypes.oneOf(['measure']).isRequired,
      measure: PropTypes.string.isRequired,
    }),
    PropTypes.exact({
      type: PropTypes.oneOf(['aggregate']).isRequired,
      measure: PropTypes.string.isRequired,
      aggregate: PropTypes.oneOf(['sum', 'average', 'min', 'max']).isRequired,
    }),
  ]),
  table: PropTypes.string,
  tableRules: PropTypes.arrayOf(PropTypes.func),
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  defaultExpanded: false,
  defaultQuery: undefined,
  value: undefined,
  table: undefined,
  tableRules: [],
};

const itemsFilter = (query) => (item) =>
  labelOfMeasure(item).toLowerCase().includes(query.toLowerCase());

const isError = (data) =>
  get('type', data) === 'error' &&
  [
    'invalid-query-columns',
    'query-unsupported-aggregate-on-column',
    'query-incompatible-table-dimensions-present',
    'query-incompatible-table-dimensions-not-present',
  ].includes(get(['data', 'code'], data));

const VisualisationMeasureSelect = ({
  blockId,
  defaultExpanded,
  defaultQuery,
  label,
  table,
  tableRules,
  value,
  onChange,
  workspaceId,
}) => {
  const scenarioId = useScenarioId();
  const options = useSelectorWithProps(visualisationMeasureOptionsSelector, {
    tableRules,
    table,
    workspaceId,
  });
  const blockData = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const capsuleMismatch = useSelectorWithProps(isCapsuleMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });

  return (
    <Select
      defaultExpanded={defaultExpanded}
      defaultQuery={defaultQuery}
      groupKey='measure'
      itemKey='key'
      label={label}
      value={value}
      onChange={onChange}
      options={options}
      search
      itemsFilter={itemsFilter}
      itemsLabel='measures'
      overrideEmptyText='Select an output to view available measures'
      renderSelected={labelOfMeasure}
      wrap
      tone={isError(blockData) && !capsuleMismatch ? 'critical' : 'neutral'}
    />
  );
};

VisualisationMeasureSelect.propTypes = propTypes;
VisualisationMeasureSelect.defaultProps = defaultProps;

export { VisualisationMeasureSelect };
