import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { first, get, isNil, noop } from 'lodash/fp';
import { Menu } from '@kinesis/bungle';
import Modal from 'components/modals/modal/Modal';
import { ScrollablePane } from 'components/styled/ScrollablePane';
import Layout from 'components/layout';
import { ModalHeader, ModalHeading } from '../modal-header';
import { FormLayout } from './import-schema.styles';

const propTypes = {
  animation: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onImport: PropTypes.func,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      // FUTURE support: file/upload; dataset; workspace;
      type: PropTypes.oneOf(['app']).isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

const defaultProps = {
  animation: true,
  onImport: noop,
  sources: [],
};

const icons = {
  app: 'table',
};
const ImportSchemaModal = ({ animation, onClose, onImport, sources }) => {
  const [selected, setSelected] = useState(first(sources));
  const onSave = useCallback(() => {
    onImport(selected);
    onClose();
  }, [onClose, onImport, selected]);
  const headerLabel = 'Import schema';

  return (
    <Modal
      header={
        <ModalHeader>
          <ModalHeading>{headerLabel}</ModalHeading>
        </ModalHeader>
      }
      animation={animation}
      onClose={onClose}
      onSave={onSave}
      saveLabel='Import schema'
      valid={!isNil(selected)}
      magnitude='small'
      aria-label={headerLabel}
    >
      <Layout direction='column'>
        <ScrollablePane>
          <Layout direction='column'>
            <FormLayout>
              <Menu>
                {sources.map((source) => (
                  <Menu.Item
                    icon={icons[source.type]}
                    key={source.id}
                    content={source.label}
                    onClick={() => setSelected(source)}
                    variant={
                      source.id === get('id', selected) ? 'accent' : undefined
                    }
                  />
                ))}
              </Menu>
            </FormLayout>
          </Layout>
        </ScrollablePane>
      </Layout>
    </Modal>
  );
};

ImportSchemaModal.defaultProps = defaultProps;
ImportSchemaModal.propTypes = propTypes;

export { ImportSchemaModal };
