import { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { isEqual, min, max } from 'lodash/fp';
import {
  SegmentedControl,
  SegmentedControlItem,
  Tooltip,
  UtilityButton,
} from '@kinesis/bungle';
import {
  ToolbarGroup,
  ToolbarItem,
  ToolbarSeparator,
} from '@kinesis/bungle/legacy';
import { AppToolbar, BackButton, Breadcrumb } from 'components/app-header';
import Layout from 'components/layout';
import { LocationsTable } from 'components/locations-table';
import { PersistenceActions } from 'components/persistence-actions';
import { ScenarioBoardDropdown } from 'components/scenario-board-dropdown';
import SpatialScenario from 'components/spatial-scenario/SpatialScenario';
import { TimeSlider } from 'components/time-slider';
import { ViewOnlyIndicator } from 'components/view-only-indicator';
import { Toolbox } from 'components/toolbox';
import { useScenarioId, useSelectorWithProps, useWorkspaceId } from 'hooks';
import useExpandableBounds from 'hooks/useExpandableBounds';
import usePermission from 'hooks/usePermission';
import usePreviewYear from 'hooks/usePreviewYear';
import useToolboxOpenState from 'hooks/useToolboxOpenState';
import scenarioYearMinMaxSelector from 'selectors/scenarioYearMinMaxSelector';
import { makeKeyboardShortcut } from 'utils/keyboardUtils';
import { nobr } from 'utils/stringUtils';
import { TimeSliderFooter } from 'components/time-slider/time-slider.styles';

const StyledLink = styled(Link)`
  &:focus {
    box-shadow: none;
  }
`;

const Scenario = () => {
  const { view } = useParams();
  const workspaceId = useWorkspaceId();
  const scenarioId = useScenarioId();
  const spatialBounds = useExpandableBounds({ scenarioId, workspaceId });
  const dataYearBounds = useSelectorWithProps(scenarioYearMinMaxSelector, {
    scenarioId,
    workspaceId,
  });
  const [[yearMin, yearMax], setYearBounds] = useState(dataYearBounds);
  const [isToolboxOpen, setToolboxOpenState] = useToolboxOpenState();
  const editor = usePermission('editor');

  const showTimeSlider = yearMin && yearMax && view !== 'grid';

  const [
    previewYear,
    setPreviewYear,
    yearSetManually,
    constrainedBounds,
    constrainBounds,
  ] = usePreviewYear();

  const setPreviewYearManually = useCallback(
    (year) => {
      setPreviewYear(year, true);
    },
    [setPreviewYear],
  );

  useEffect(() => {
    const constrained = constrainedBounds[scenarioId];

    if (!isEqual([yearMin, yearMax], dataYearBounds)) {
      setYearBounds(dataYearBounds); // set time slider bounds to data bounds

      // if your data bounds change and you have constrained bounds
      // that are greater than your data bounds, reset the constrained bounds
      // to the data bounds.
      if (
        constrained &&
        constrained.length &&
        (constrained[0] < dataYearBounds[0] ||
          constrained[1] > dataYearBounds[1])
      ) {
        constrainBounds(scenarioId, dataYearBounds);
        setPreviewYear(dataYearBounds[1]);
      }
    }

    if (previewYear && !(dataYearBounds[0] || dataYearBounds[1])) {
      setPreviewYear(undefined);
    }

    if ((dataYearBounds || constrained) && (!previewYear || !yearSetManually)) {
      const maxStartYear = constrained
        ? max([dataYearBounds[0], constrained[0]])
        : dataYearBounds[0];
      const minEndYear = constrained
        ? min([dataYearBounds[1], constrained[1]])
        : dataYearBounds[1];
      if (previewYear < maxStartYear) {
        setPreviewYear(maxStartYear);
      } else {
        setPreviewYear(minEndYear);
      }
    }
  }, [
    constrainBounds,
    constrainedBounds,
    dataYearBounds,
    previewYear,
    scenarioId,
    setPreviewYear,
    yearMax,
    yearMin,
    yearSetManually,
  ]);

  const handleResetYear = useCallback(() => {
    setPreviewYearManually(yearMax);
  }, [setPreviewYearManually, yearMax]);

  const handleConstrainBounds = useCallback(
    (bounds) => {
      const boundsEqualDataBounds =
        bounds[0] === dataYearBounds[0] && bounds[1] === dataYearBounds[1];

      constrainBounds(scenarioId, boundsEqualDataBounds ? undefined : bounds);
    },
    [constrainBounds, dataYearBounds, scenarioId],
  );

  const handleToolboxStateChange = useCallback(() => {
    setToolboxOpenState((isOpen) => !isOpen);
  }, [setToolboxOpenState]);

  const mapViewShortcut = makeKeyboardShortcut({
    primaryModifier: true,
    shift: true,
    key: '1',
  });
  const gridViewShortcut = makeKeyboardShortcut({
    primaryModifier: true,
    shift: true,
    key: '2',
  });

  return (
    <>
      <BackButton to={`/workspaces/${workspaceId}`} />
      <Breadcrumb>
        <ScenarioBoardDropdown workspaceId={workspaceId} />
      </Breadcrumb>
      <AppToolbar>
        <ToolbarGroup>
          <ToolbarItem>
            {editor ? (
              <PersistenceActions
                scenarioId={scenarioId}
                workspaceId={workspaceId}
              />
            ) : (
              <ViewOnlyIndicator />
            )}
          </ToolbarItem>
          <ToolbarSeparator />
          <ToolbarItem>
            <SegmentedControl value={view}>
              <SegmentedControlItem
                as={view !== 'map' ? StyledLink : undefined}
                icon='map-outline'
                keyboardShortcut={mapViewShortcut}
                title={nobr(`Map view (${mapViewShortcut})`)}
                to={view !== 'map' ? '../map' : undefined}
                value='map'
              />
              <SegmentedControlItem
                as={view !== 'grid' ? StyledLink : undefined}
                icon='table'
                keyboardShortcut={gridViewShortcut}
                title={nobr(`Grid view (${gridViewShortcut})`)}
                to={view !== 'grid' ? '../grid' : undefined}
                value='grid'
              />
            </SegmentedControl>
          </ToolbarItem>
          <ToolbarItem data-testid='toggle-toolbox'>
            <Tooltip
              justify='end'
              placement='bottom'
              title={isToolboxOpen ? 'Hide toolbox' : 'Show toolbox'}
            >
              <UtilityButton
                expanded={isToolboxOpen}
                icon='control'
                variant={isToolboxOpen ? 'accent' : 'default'}
                onClick={handleToolboxStateChange}
              />
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </AppToolbar>
      <Layout direction='row'>
        <Layout display='flex' direction='column'>
          {view === 'map' && (
            <SpatialScenario bounds={spatialBounds} workspaceId={workspaceId} />
          )}
          {view === 'grid' && (
            <Routes>
              <Route
                path=':screen/:tab'
                element={<LocationsTable workspaceId={workspaceId} />}
              />
              <Route
                path='*'
                element={<LocationsTable workspaceId={workspaceId} />}
              />
            </Routes>
          )}
          {showTimeSlider && (
            <TimeSliderFooter>
              <TimeSlider
                basisBounds={[yearMin, yearMax]}
                onChangeBounds={handleConstrainBounds}
                onChangeYear={setPreviewYearManually}
                onResetYear={handleResetYear}
                year={previewYear || yearMax}
                yearMax={
                  constrainedBounds[scenarioId]
                    ? constrainedBounds[scenarioId][1]
                    : yearMax
                }
                yearMin={
                  constrainedBounds[scenarioId]
                    ? constrainedBounds[scenarioId][0]
                    : yearMin
                }
              />
            </TimeSliderFooter>
          )}
        </Layout>
        {isToolboxOpen && <Toolbox />}
      </Layout>
    </>
  );
};

export { Scenario };
