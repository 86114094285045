import PropTypes from 'prop-types';
import {
  DataGridCellWrapper,
  DataGridCellInner,
} from './data-grid-cell.styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default', 'heading', 'subheading']),
};

const defaultProps = {
  variant: 'default',
};

const DataGridCell = ({ children, variant }) => (
  <DataGridCellWrapper variant={variant}>
    <DataGridCellInner>{children}</DataGridCellInner>
  </DataGridCellWrapper>
);

DataGridCell.propTypes = propTypes;
DataGridCell.defaultProps = defaultProps;

export { DataGridCell };
