import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { pipe, get, max, values } from 'lodash/fp';
import { useSelectorWithProps } from 'hooks';
import { instantiationByKey } from 'data/attributes';
import { scenarioDiscard } from 'actions/scenarioDiscard';
import { scenarioPublish } from 'actions/scenarioPublish';
import { scenarioSaveAsNew } from 'actions/scenarioSaveAsNew';
import { tagUpdate } from 'actions/tagUpdate';
import authorNameSelector from 'selectors/authorNameSelector';
import scenarioSelector from 'selectors/scenarioSelector';
import workspaceSelector from 'selectors/workspaceSelector';

import { PersistenceActions as UnmanagedPersistenceActions } from './persistence-actions.unmanaged';

const propTypes = {
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const findChangeLocations = (change) => {
  switch (change.type) {
    case 'add-attribute-value':
    case 'remove-attribute-value':
    case 'modify-attribute-value': {
      return get(['value', 'locations'], change) || [];
    }

    case 'add-locations':
    case 'remove-locations': {
      return get(['value'], change) || [];
    }

    default: {
      return [];
    }
  }
};

const findChangeInstantiation = (workspace, change) => {
  const key = get(['value', 'name'], change);
  return instantiationByKey(key, workspace);
};

const PersistenceActions = ({ scenarioId, workspaceId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDelete = useCallback(
    (tagName) => {
      dispatch(scenarioDiscard({ scenarioId, workspaceId, tagName }));
    },
    [dispatch, scenarioId, workspaceId],
  );

  const onPublish = useCallback(
    (tagName) => {
      dispatch(scenarioPublish({ scenarioId, workspaceId, tagName }));
    },
    [dispatch, scenarioId, workspaceId],
  );

  const onSaveAsNewNamedScenario = useCallback(async () => {
    const { id } = await dispatch(
      scenarioSaveAsNew({ scenarioId, workspaceId }),
    ).unwrap();

    if (id) {
      navigate({
        pathname: `/workspaces/${workspaceId}/scenarios/${id}`,
        state: { autoSelect: true },
      });
    }
  }, [dispatch, navigate, scenarioId, workspaceId]);

  const onUpdateTag = useCallback(
    (scenarioKey, actions) => {
      dispatch(
        tagUpdate({ actions, referent: scenarioKey, scenarioId, workspaceId }),
      );
    },
    [dispatch, scenarioId, workspaceId],
  );

  const workspace = useSelectorWithProps(workspaceSelector, { workspaceId });
  const persistence = useMemo(() => get('persistence', workspace), [workspace]);
  const scenario = useSelectorWithProps(scenarioSelector, {
    scenarioId,
    workspaceId,
  });
  const draft = useMemo(() => get('draft', scenario), [scenario]);
  const published = useMemo(() => get('published', scenario), [scenario]);
  const active = useMemo(() => draft || published, [draft, published]);
  const apolloUpdatedAt = useMemo(() => get('updatedAt', active), [active]);
  const updatedAt = useMemo(
    () => max([get('updatedAt', scenario), apolloUpdatedAt]),
    [apolloUpdatedAt, scenario],
  );
  const tagName = useMemo(() => get('tagName', active), [active]);
  const allLocations = useMemo(
    () => pipe(get('locations'), values)(workspace) || [],
    [workspace],
  );

  const modifications = useMemo(
    () =>
      (get(['history', tagName], workspace) || []).map((modification) => {
        const changes = modification.changes.map((change) => {
          const instantiation = findChangeInstantiation(workspace, change);
          const changeLocations = findChangeLocations(change);
          const locations =
            changeLocations.length < 2
              ? changeLocations.map(
                  (locationId) =>
                    allLocations.find(
                      (location) => location.id === locationId,
                    ) || {},
                )
              : changeLocations;
          const rawValue = get('value', change);
          const value =
            get('type', change) === 'code'
              ? rawValue
              : {
                  ...rawValue,
                  locations,
                  instantiation,
                };
          return { ...change, value };
        });
        return { ...modification, changes };
      }),
    [workspace, allLocations, tagName],
  );
  const currentScenarioKey = useMemo(
    () =>
      get(['scenarios', 'named', scenarioId, 'draft', 'scenario'], workspace),
    [scenarioId, workspace],
  );

  const author = useSelectorWithProps(authorNameSelector, {
    author: get('author', published),
  });

  const conflict = useMemo(
    () => !!draft && get('basedOn', draft) !== get('scenario', published),
    [draft, published],
  );
  const count = useMemo(() => get('count', draft), [draft]);
  const isSaving = useMemo(() => persistence.isSaving, [persistence]);
  const publishFailed = useMemo(() => persistence.publishFailed, [persistence]);
  const isPublished = useMemo(() => !draft, [draft]);

  return (
    <UnmanagedPersistenceActions
      onDelete={onDelete}
      onPublish={onPublish}
      onSaveAsNewNamedScenario={onSaveAsNewNamedScenario}
      onUpdateTag={onUpdateTag}
      author={author}
      conflict={conflict}
      count={count}
      currentScenarioKey={currentScenarioKey}
      isSaving={isSaving}
      modifications={modifications}
      publishFailed={publishFailed}
      published={isPublished}
      tagName={tagName}
      updatedAt={updatedAt}
    />
  );
};

PersistenceActions.propTypes = propTypes;

export { PersistenceActions };
