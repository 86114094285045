import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import {
  negate,
  update,
  identity,
  isEqual,
  find,
  pipe,
  concat,
  set,
  map,
  first,
  get,
} from 'lodash/fp';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import visualisationMeasureOptionsSelector from 'selectors/visualisationMeasureOptionsSelector';
import visualisationDimensionOptionsSelector from 'selectors/visualisationDimensionOptionsSelector';
import blockDataSelector from 'selectors/blockDataSelector';
import isCapsuleMismatchSelector from 'selectors/isCapsuleMismatchSelector';
import { labelOfMeasure } from 'data/block';
import namedScenariosSelector from 'selectors/namedScenariosSelector';
import locationsSelector from 'selectors/locationsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import Units from 'data/units';
import { Empty } from './visualisation-series-select.empty';
import { SingleExpression } from './visualisation-series-select.single-expression';
import { MultiExpression } from './visualisation-series-select.multi-expression';
import { Breakdown } from './visualisation-series-select.breakdown';
import { BreakdownByScenario } from './visualisation-series-select.breakdown-by-scenario';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.number,
  defaultQuery: PropTypes.string,
  defaultAddSeries: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  table: PropTypes.string,
  mode: PropTypes.oneOf([
    'empty',
    'single-expression',
    'multi-expression',
    'breakdown',
    'breakdown-by-scenario',
  ]).isRequired,
  criteria: PropTypes.func,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  defaultExpanded: undefined,
  defaultQuery: undefined,
  defaultAddSeries: false,
  value: undefined,
  table: undefined,
  criteria: identity,
};

const units = new Units();

const isError = (data) =>
  get('type', data) === 'error' && [].includes(get(['data', 'code'], data));

const mapWithIndex = map.convert({ cap: false });

const addKeys = (options) =>
  mapWithIndex((option, index) => ({ ...option, key: index }))(options);

const buildOptions = (firstSelectedExpression, measures, dimensions) => {
  switch (get('type', firstSelectedExpression)) {
    case 'aggregate': {
      const measure = find(
        (m) => isEqual(get('value', m), firstSelectedExpression),
        measures,
      );
      const taggedMeasures = pipe(map(set('type', 'expression')))(measures);
      const taggedDimensions = map(
        pipe(
          set('type', 'breakdown'),
          set('measure', `Break down ${labelOfMeasure(measure)}`),
        ),
        dimensions,
      );
      return addKeys(concat(taggedDimensions, taggedMeasures));
    }
    case 'identity':
    default:
      return map(set('type', 'expression'), measures);
  }
};

const VisualisationSeriesSelect = ({
  blockId,
  defaultExpanded,
  defaultQuery,
  defaultAddSeries,
  label,
  table,
  value,
  onChange,
  mode,
  criteria,
  workspaceId,
}) => {
  const scenarioId = useScenarioId();
  const negatedCriteria = useMemo(() => negate(criteria), [criteria]);
  const firstSelectedExpression = useMemo(() => {
    switch (mode) {
      case 'single-expression':
      case 'multi-expression':
        return first(get('series', value));
      case 'breakdown':
        return get('expression', value);
      case 'breakdown-by-scenario':
        return get('y', value);
      case 'empty':
      default:
        return undefined;
    }
  }, [mode, value]);
  const measures = useSelectorWithProps(visualisationMeasureOptionsSelector, {
    tableRules: [negatedCriteria],
    table,
    allOfType: get('type', firstSelectedExpression),
  });
  const dimensions = useSelectorWithProps(
    visualisationDimensionOptionsSelector,
    {
      criteria,
      table,
    },
  );
  // NOTE: We build the full set of options here rather than in a selector as
  // the the breakdowns depend on knowing what the first selected expression is
  // as well as the mode we are in.
  const options = useMemo(
    () => buildOptions(firstSelectedExpression, measures, dimensions),
    [measures, dimensions, firstSelectedExpression],
  );
  const blockData = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const capsuleMismatch = useSelectorWithProps(isCapsuleMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const tone = useMemo(
    () => (isError(blockData) && !capsuleMismatch ? 'critical' : 'neutral'),
    [blockData, capsuleMismatch],
  );
  const locations = useSelectorWithProps(locationsSelector, { workspaceId });
  const instances = useSelectorWithProps(workspaceInstantiationsSelector, {
    workspaceId,
  });
  const namedScenarios = useSelectorWithProps(namedScenariosSelector, {
    workspaceId,
  });
  const unit = useMemo(() => {
    const metadata = get(['data', 'metadata'], blockData);
    const yUnit =
      metadata &&
      units.parseColumn(
        get(['y', 'column'], metadata),
        locations,
        instances,
        namedScenarios,
      );
    return get('symbol', yUnit);
  }, [blockData, locations, instances, namedScenarios]);

  const handleOnChange = useCallback(
    (v) => {
      switch (mode) {
        case 'single-expression':
        case 'multi-expression': {
          // NOTE: This isn't great, but allows us to switch by-expression to
          // by-dimension without extra noise. It would be preferable to know
          // explicitly adjacent to the value passed in rather than
          // introspecting on the value we're to set.
          if (v.value.type === 'column') {
            return onChange(
              {
                type: 'by-dimension',
                expression: get(['series', 0], value),
                by: v.value,
              },
              v.value.time,
            );
          }
          return onChange(
            set(['series', v.position], v.value, value),
            v.value.time,
          );
        }
        case 'breakdown': {
          // NOTE: This isn't great, but allows us to switch by-dimension to
          // by-expression without extra noise. It would be preferable to know
          // explicitly adjacent to the value passed in rather than
          // introspecting on the value we're to set.
          if (v.value.type !== 'column') {
            return onChange(
              {
                type: 'by-expression',
                series: [get('expression', value), v.value],
              },
              v.value.time,
            );
          }
          return onChange(set(v.field, v.value, value), v.value.time);
        }
        case 'breakdown-by-scenario':
          return onChange(v, v.time);
        case 'empty':
          return onChange(
            {
              type: 'by-expression',
              series: [v],
            },
            v.time,
          );
        default:
          // noop.
          break;
      }
    },
    [onChange, mode, value],
  );

  const handleDeleteSeries = useCallback(
    (position) => () => {
      switch (mode) {
        case 'single-expression': {
          if (position === 0) {
            return onChange(undefined);
          }
          break;
        }
        case 'multi-expression':
          return onChange(
            update(
              'series',
              (series) => series.filter((_, ix) => ix !== position),
              value,
            ),
          );
        case 'breakdown': {
          if (position === 0) {
            return onChange(undefined);
          }
          return onChange({
            type: 'by-expression',
            series: [get('expression', value)],
          });
        }
        case 'breakdown-by-scenario':
        case 'empty':
        default:
          // noop.
          break;
      }
    },
    [onChange, mode, value],
  );

  switch (mode) {
    case 'empty':
      return (
        <Empty
          onChange={handleOnChange}
          options={options}
          defaultExpanded={defaultExpanded}
          defaultQuery={defaultQuery}
          defaultAddSeries={defaultAddSeries}
          label={label}
          tone={tone}
        />
      );
    case 'single-expression':
      return (
        <SingleExpression
          onChange={handleOnChange}
          onDelete={handleDeleteSeries}
          options={options}
          defaultExpanded={defaultExpanded}
          defaultQuery={defaultQuery}
          defaultAddSeries={defaultAddSeries}
          label={label}
          expression={first(get('series', value))}
          unit={unit}
        />
      );
    case 'multi-expression':
      return (
        <MultiExpression
          onChange={handleOnChange}
          onDelete={handleDeleteSeries}
          options={options}
          defaultExpanded={defaultExpanded}
          defaultQuery={defaultQuery}
          defaultAddSeries={defaultAddSeries}
          label={label}
          series={get('series', value)}
          unit={unit}
        />
      );
    case 'breakdown':
      return (
        <Breakdown
          onChange={handleOnChange}
          onDelete={handleDeleteSeries}
          options={options}
          defaultExpanded={defaultExpanded}
          defaultQuery={defaultQuery}
          defaultAddSeries={defaultAddSeries}
          label={label}
          expression={get('expression', value)}
          breakdown={get('by', value)}
        />
      );
    case 'breakdown-by-scenario':
      return (
        <BreakdownByScenario
          onChange={handleOnChange}
          options={options}
          defaultExpanded={defaultExpanded}
          defaultQuery={defaultQuery}
          defaultAddSeries={defaultAddSeries}
          label={label}
          expression={value}
          tone={tone}
        />
      );
    default:
      return null;
  }
};

VisualisationSeriesSelect.propTypes = propTypes;
VisualisationSeriesSelect.defaultProps = defaultProps;

export { VisualisationSeriesSelect };
