import PropTypes from 'prop-types';
import { get, pipe } from 'lodash/fp';
import { Icon, Menu, Tooltip } from '@kinesis/bungle';
import LayerCircle from 'components/layer-circle/LayerCircle';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import blockValueSelector from 'selectors/blockValueSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import layersSelector from 'selectors/layersSelector';
import { useLayersActions, useSelectorWithProps } from 'hooks';
import useTheme from 'hooks/useTheme';

const propTypes = {
  blockId: PropTypes.string,
  disabled: PropTypes.bool,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  blockId: undefined,
  disabled: false,
  privacy: 'private',
};

const LayerMenu = ({ blockId, disabled, privacy, workspaceId }) => {
  const theme = useTheme();
  const { toggleLayer, toggleVisualisationLayer } = useLayersActions({
    privacy,
    workspaceId,
  });
  const layers = useSelectorWithProps(layersSelector, {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });
  const visualisationType = useSelectorWithProps(
    pipe(blockValueSelector, get(['spatial', 'type'])),
    {
      blockId,
      public: privacy === 'public',
      workspaceId,
    },
  );
  const visualisationLayerEnabled = useSelectorWithProps(
    pipe(workspaceSelector, get(['layers', 'visualisationLayerEnabled'])),
    {
      public: privacy === 'public',
      workspaceId,
    },
  );

  return (
    <ToolboxSection collapsible title='Layers'>
      <Menu>
        {layers.map((layer) => (
          <Menu.Item
            key={layer.id}
            onClick={
              !disabled
                ? () => toggleLayer({ layerId: layer.id, workspaceId })
                : undefined
            }
            icon={
              <Tooltip
                disabled={disabled}
                placement='top'
                justify='end'
                title={layer.enabled ? 'Hide layer' : 'Show layer'}
              >
                <span>
                  <LayerCircle
                    enabled={disabled || layer.enabled}
                    layerId={layer.id}
                    privacy={privacy}
                    workspaceId={workspaceId}
                  />
                </span>
              </Tooltip>
            }
            content={layer.label}
          />
        ))}
        {visualisationType === 'bubble' || visualisationType === 'hex' ? (
          <Menu.Item
            key='visualisation'
            onClick={!disabled ? toggleVisualisationLayer : undefined}
            icon={
              <Tooltip
                disabled={disabled}
                placement='top'
                justify='end'
                title={visualisationLayerEnabled ? 'Hide layer' : 'Show layer'}
              >
                <Icon
                  color={
                    disabled || !visualisationLayerEnabled
                      ? theme.color.gray6
                      : theme.color.gray7
                  }
                  magnitude='small'
                  type='map-filled'
                />
              </Tooltip>
            }
            content='Visualisation'
          />
        ) : null}
      </Menu>
    </ToolboxSection>
  );
};

LayerMenu.propTypes = propTypes;
LayerMenu.defaultProps = defaultProps;

export default LayerMenu;
