import { useState } from 'react';
import { defaultTo, get } from 'lodash/fp';

function useValueRange(config) {
  const [relationalOperator, setRelationalOperator] = useState(
    defaultTo('all', get('relationalOperator', config)),
  );
  const [firstOperand, setFirstOperand] = useState(get('firstOperand', config));
  const [secondOperand, setSecondOperand] = useState(
    get('secondOperand', config),
  );

  return {
    firstOperand,
    relationalOperator,
    secondOperand,
    setFirstOperand,
    setRelationalOperator,
    setSecondOperand,
  };
}

export default useValueRange;
