import { pick } from 'lodash/fp';
import { actions as layersActions } from 'reducers/layersReducer';
import { actions as publicWorkspacesActions } from 'reducers/publicWorkspacesReducer';
import useActions from './useActions';

const pickToggleActions = pick(['toggleLayer', 'toggleVisualisationLayer']);
const privateLayersActions = pickToggleActions(layersActions);
const publicLayersActions = pickToggleActions(publicWorkspacesActions);

function useLayersActions({ privacy }) {
  return useActions(
    privacy === 'public' ? publicLayersActions : privateLayersActions,
  );
}

export { useLayersActions };
