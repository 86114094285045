import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

const populationDatasetDraftSelector = createSelector(
  get(['custom', 'population', 'draft']),
  get(['custom', 'population', 'datasets']),
  get,
);

export default populationDatasetDraftSelector;
