/* eslint-disable import/prefer-default-export */
import { reduce } from 'lodash/fp';
import { WebMercatorViewport } from '@math.gl/web-mercator';

const fitBounds = (bounds, width, height, padding) => {
  const viewport = new WebMercatorViewport({ width, height });
  const fit = viewport.fitBounds(bounds, { padding });
  return {
    longitude: fit.longitude,
    latitude: fit.latitude,
    zoom: Math.min(fit.zoom, 15),
  };
};

const reduceCoordinatesToBounds = reduce((bounds, coordinates) => {
  const [[minX, minY], [maxX, maxY]] = bounds;
  const [x, y] = coordinates;

  return [
    [Math.min(minX, x), Math.min(minY, y)],
    [Math.max(maxX, x), Math.max(maxY, y)],
  ];
});

function coordinatesToBounds(coordinates = []) {
  if (coordinates.length === 0) {
    return undefined;
  }
  const [initialCoords, ...restCoords] = coordinates;
  return reduceCoordinatesToBounds([initialCoords, initialCoords], restCoords);
}

function withinBounds(bounds, { latitude, longitude }) {
  const [[leftX, leftY], [rightX, rightY]] = bounds;
  const minX = Math.min(leftX, rightX);
  const maxX = Math.max(leftX, rightX);
  const minY = Math.min(leftY, rightY);
  const maxY = Math.max(leftY, rightY);
  return (
    longitude > minX && longitude < maxX && latitude > minY && latitude < maxY
  );
}

export { coordinatesToBounds, withinBounds, fitBounds };
