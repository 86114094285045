import { createCachedSelector } from 're-reselect';
import { get, map, min } from 'lodash/fp';
import blockScenarioDataSelector from './blockScenarioDataSelector';
import blockValueSelector from './blockValueSelector';

const sizeMinSelector = createCachedSelector(
  blockScenarioDataSelector,
  blockValueSelector,
  (data, blockValue) => {
    const sizeColumn = get(
      [get('visualisation', blockValue), 'size', 'column'],
      blockValue,
    );

    if (!sizeColumn) {
      return undefined;
    }

    return min(map(sizeColumn, data));
  },
)((state, props) => props.blockId);

export default sizeMinSelector;
