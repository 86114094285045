import {
  map,
  defaults,
  isString,
  get,
  isEmpty,
  keyBy,
  pipe,
  flatMap,
  split,
  last,
  findIndex,
  isNil,
} from 'lodash/fp';
import { entries } from 'data/attributes';
import { normalizeLevels } from 'data/levels';

const keyById = keyBy('id');
const keyByKey = keyBy('key');

const alignments = {
  left: 'left',
  right: 'right',
};

const aggregations = {
  all: ['sum', 'average', 'min', 'max'],
  noSummation: ['average', 'min', 'max'],
  none: [],
};

const treatments = {
  numeric: 'numeric',
  currency: 'currency',
  string: 'string',
  categorical: 'categorical',
  month: 'month',
  location: 'location',
  item: 'item',
  year: 'year',
  day: 'day',
  hour: 'hour',
  scenario: 'scenario',
};

const systems = {
  year: {
    alignment: alignments.right,
    aggregation: aggregations.none,
    treatment: treatments.year,
  },
  month: {
    alignment: alignments.left,
    aggregation: aggregations.none,
    treatment: treatments.month,
  },
  'year-month-day': {
    alignment: alignments.left,
    aggregation: aggregations.none,
    treatment: treatments.string,
  },
  hour: {
    alignment: alignments.right,
    aggregation: aggregations.none,
    treatment: treatments.hour,
  },
  day: {
    alignment: alignments.right,
    aggregation: aggregations.none,
    treatment: treatments.day,
  },
  string: {
    alignment: alignments.left,
    aggregation: aggregations.none,
    treatment: treatments.string,
  },
  category: {
    alignment: alignments.left,
    aggregation: aggregations.none,
    treatment: treatments.categorical,
  },
  decimal: {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  percentage: {
    alignment: alignments.right,
    aggregation: aggregations.noSummation,
    treatment: treatments.numeric,
    symbol: '%',
    options: { maximumFractionDigits: 0 },
  },
  proportion: {
    alignment: alignments.right,
    aggregation: aggregations.noSummation,
    treatment: treatments.numeric,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  ratio: {
    alignment: alignments.right,
    aggregation: aggregations.noSummation,
    treatment: treatments.numeric,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 0 },
  },
  rating: {
    alignment: alignments.right,
    aggregation: aggregations.noSummation,
    treatment: treatments.numeric,
    options: { maximumFractionDigits: 1, minimumFractionDigits: 0 },
  },
  coordinate: {
    alignment: alignments.left,
    aggregation: aggregations.none,
    treatment: treatments.numeric,
    options: { minimumFractionDigits: 5, maximumFractionDigits: 5 },
  },
  score: {
    alignment: alignments.right,
    aggregation: aggregations.noSummation,
    treatment: treatments.numeric,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  count: {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    options: { maximumFractionDigits: 0 },
  },
  sqm: {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    symbol: 'm\u00B2',
    padded: true,
    options: { maximumFractionDigits: 0 },
  },
  area: {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    symbol: 'm\u00B2',
    padded: true,
    options: { maximumFractionDigits: 0 },
  },
  'area-sqm': {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    symbol: 'm\u00B2',
    padded: true,
    options: { maximumFractionDigits: 0 },
  },
  kwh: {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    symbol: 'kWh',
    padded: true,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  kw: {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    symbol: 'kW',
    padded: true,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  'kw-therm-per-kw': {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    symbol: 'kWtherm/kW',
    padded: true,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  mj: {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    symbol: 'MJ',
    padded: true,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  litres: {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    symbol: 'L',
    padded: true,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  'kg-co2e': {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    /* Ref for unit format - http://www.cleanenergyregulator.gov.au/NGER/About-the-National-Greenhouse-and-Energy-Reporting-scheme/Greenhouse-gases-and-energy */
    symbol: 'kg\u00A0CO\u2082‑e',
    padded: true,
    options: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  },
  't-co2e': {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    /* Ref for unit format - http://www.cleanenergyregulator.gov.au/NGER/About-the-National-Greenhouse-and-Energy-Reporting-scheme/Greenhouse-gases-and-energy */
    symbol: 't\u00A0CO\u2082‑e',
    padded: true,
    options: { maximumFractionDigits: 0, minimumFractionDigits: 0 },
  },
  'mt-co2e': {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.numeric,
    /* Ref for unit format - http://www.cleanenergyregulator.gov.au/NGER/About-the-National-Greenhouse-and-Energy-Reporting-scheme/Greenhouse-gases-and-energy */
    symbol: 'Mt\u00A0CO\u2082‑e',
    padded: true,
    options: { maximumFractionDigits: 1, minimumFractionDigits: 1 },
  },
  currency: {
    alignment: alignments.right,
    aggregation: aggregations.all,
    treatment: treatments.currency,
  },
  location: {
    alignment: alignments.left,
    aggregation: aggregations.none,
    treatment: treatments.location,
  },
  hex: {
    alignment: alignments.left,
    aggregation: aggregations.none,
    treatment: treatments.string,
  },
  item: {
    alignment: alignments.left,
    aggregation: aggregations.none,
    treatment: treatments.item,
  },
  geography: {
    alignment: alignments.right,
    aggregation: aggregations.none,
    treatment: treatments.numeric,
  },
  scenario: {
    alignment: alignments.left,
    aggregation: aggregations.none,
    treatment: treatments.scenario,
  },
};

const systemToRender = (
  system,
  options,
  locale,
  instantiations,
  locations,
  includeUnit,
  namedScenarios,
) => {
  switch (system.treatment) {
    case 'numeric': {
      const formatting = defaults(system.options, {
        minimumFractionDigits:
          get('minimum_fraction_digits', options) ||
          get('minimumFractionDigits', options),
        maximumFractionDigits:
          get('maximum_fraction_digits', options) ||
          get('maximumFractionDigits', options),
        minimumIntegerDigits:
          get('minimum_integer_digits', options) ||
          get('minimumIntegerDigits', options),
        minimumSignificantDigits:
          get('minimum_significant_digits', options) ||
          get('minimumSignificantDigits', options),
        maximumSignificantDigits:
          get('maximum_significant_digits', options) ||
          get('maximumSignificantDigits', options),
      });
      if (includeUnit) {
        const padding = system.padded || options.label ? ' ' : '';
        const symbol = system.symbol || options.label || '';
        return (value) =>
          !isNil(value)
            ? `${value.toLocaleString(locale, formatting)}${padding}${symbol}`
            : '';
      }
      return (value) =>
        !isNil(value) ? value.toLocaleString(locale, formatting) : '';
    }
    case 'year':
    case 'day':
    case 'hour':
      return (value) => (!isNil(value) ? value.toFixed(0).toString() : '');
    case 'currency': {
      const formatting = { style: 'currency', currency: options.currency };
      return (value) =>
        !isNil(value) ? value.toLocaleString(locale, formatting) : '';
    }
    case 'month': {
      return (value) =>
        value > 0 && value < 13
          ? new Date(Date.UTC(0, value, 0, 0, 0, 0)).toLocaleDateString(
              locale,
              { month: 'short' },
            )
          : '';
    }
    case 'string':
      return (value) => value || '';
    case 'categorical':
      return (value) => value || '';
    case 'location':
      return (value) =>
        !isEmpty(get([value, 'label'], locations))
          ? get([value, 'label'], locations)
          : 'New location';
    case 'item': {
      const kind = options.attribute || 'usage-v2';
      const items = pipe(pipe(get(kind), entries), (es) => [
        ...es,
        ...flatMap(entries, es),
      ])(instantiations);
      const stripPrefix = (v0) => last(split('/', v0));
      return (v0) => {
        const key = stripPrefix(v0);
        const keyedItems = keyByKey(items);
        const label = get([key, 'label'], keyedItems);
        return label || 'New item';
      };
    }
    case 'scenario':
      return (id) => get([id, 'name'], namedScenarios);
    default:
      // CONSIDER: rollbar instead of error.
      throw new Error(`Unknown system treatment: ${system.treatment}`);
  }
};

const systemToFilter = (system, classification) => {
  const isContext = classification.includes('context');
  switch (system.treatment) {
    case 'numeric':
    case 'currency':
      return 'numeric';
    case 'month':
      return 'month';
    case 'year':
      return 'year';
    case 'day':
      return 'day';
    case 'hour':
      return 'hour';
    case 'string':
    case 'categorical':
    case 'location':
    case 'item':
    case 'scenario':
      return isContext ? 'select' : 'multiselect';
    default:
      // CONSIDER: rollbar instead of error.
      throw new Error(`Unknown system treatment: ${system.treatment}`);
  }
};

const systemToComparison = (
  system,
  options,
  instantiations,
  locations,
  namedScenarios,
) => {
  switch (system.treatment) {
    case 'numeric':
    case 'currency':
    case 'month':
    case 'year':
    case 'day':
    case 'hour':
      return (a, b) => a - b;
    case 'string':
      return (a, b) => a.localeCompare(b);
    case 'categorical': {
      const levels = normalizeLevels(get('levels', options));
      return (a, b) => {
        const lvl1 = get(a, levels);
        const lvl2 = get(b, levels);
        if (!isNil(lvl1) && !isNil(lvl2)) {
          return lvl1 - lvl2;
        }
        if (isNil(lvl1) && isNil(lvl2)) {
          if (isString(a)) {
            return a.localeCompare(b);
          }
          return a - b;
        }
        return isNil(lvl1) ? 1 : -1;
      };
    }
    case 'location':
      return (a, b) =>
        get([a, 'label'], locations).localeCompare(
          get([b, 'label'], locations),
        );
    case 'item': {
      const kind = options.attribute || 'usage-v2';
      const items = pipe(pipe(get(kind), entries), (es) => [
        ...es,
        ...flatMap(entries, es),
      ])(instantiations);
      const stripPrefix = (v0) => last(split('/', v0));
      const keys = map((i) => stripPrefix(i.key), items);
      return (a0, b0) => {
        const a = stripPrefix(a0);
        const b = stripPrefix(b0);
        const o1 = findIndex((i) => i === a, keys);
        const o2 = findIndex((i) => i === b, keys);
        return o1 - o2;
      };
    }
    case 'scenario': {
      return (id1, id2) => {
        const isDefault1 = get([id1, 'isDefault'], namedScenarios);
        const isDefault2 = get([id2, 'isDefault'], namedScenarios);
        if (isDefault1) {
          return -1;
        }
        if (isDefault2) {
          return 1;
        }
        const name1 = get([id1, 'name'], namedScenarios);
        const name2 = get([id2, 'name'], namedScenarios);
        return name1?.localeCompare(name2);
      };
    }
    default:
      // CONSIDER: rollbar instead of error.
      throw new Error(`Unknown system treatment: ${system.treatment}`);
  }
};

const Units = class {
  /* locale undefined defaults to browser, which is what you want most of the time) */
  constructor(locale) {
    this.locale = locale;
  }

  parseColumn(
    column,
    locations = {},
    instantiations = {},
    namedScenarios = {},
  ) {
    const { unit = {}, classification = [] } = column;
    const system = get(unit.type, systems);
    if (!system) return;
    const keyedLocations = keyById(locations);
    return {
      align: system.alignment,
      aggregations: system.aggregation,
      filter: systemToFilter(system, classification),
      format: systemToRender(
        system,
        unit,
        this.locale,
        instantiations,
        keyedLocations,
        true,
        namedScenarios,
      ),
      formatCell: systemToRender(
        system,
        unit,
        this.locale,
        instantiations,
        keyedLocations,
        false,
        namedScenarios,
      ),
      symbol: system.symbol || unit.label,
      compare: systemToComparison(
        system,
        unit,
        instantiations,
        keyedLocations,
        namedScenarios,
      ),
    };
  }

  parseType(unit = {}) {
    const system = get(unit.type, systems);
    if (!system) return;
    return {
      align: system.alignment,
      aggregations: system.aggregation,
      filter: systemToFilter(system, []),
      format: systemToRender(system, unit, this.locale, {}, {}, true, {}),
      formatCell: systemToRender(system, unit, this.locale, {}, {}, false, {}),
      symbol: system.symbol || unit.label,
      compare: systemToComparison(system, unit, {}, {}, {}),
    };
  }

  // DEPRECATED: EVERYTHING BELOW HERE...

  string(classification) {
    return this.parseColumn({ unit: { type: 'string' }, classification });
  }

  hex(classification) {
    return this.parseColumn({ unit: { type: 'hex' }, classification });
  }

  location(classification, locations) {
    return this.parseColumn(
      { unit: { type: 'location' }, classification },
      locations,
    );
  }

  item(classification, instantiations, attributeKind) {
    return this.parseColumn(
      { unit: { type: 'item', attribute: attributeKind }, classification },
      {},
      instantiations,
    );
  }

  category(classification, levels) {
    return this.parseColumn({
      unit: { type: 'category', levels },
      classification,
    });
  }

  currency(currency) {
    return this.parseColumn({ unit: { type: 'currency', currency } });
  }

  year() {
    return this.parseColumn({ unit: { type: 'year' } });
  }

  month() {
    return this.parseColumn({ unit: { type: 'month' } });
  }

  day() {
    return this.parseColumn({ unit: { type: 'day' } });
  }

  yearMonthDay() {
    return this.parseColumn({ unit: { type: 'year-month-day' } });
  }

  hour() {
    return this.parseColumn({ unit: { type: 'hour' } });
  }

  decimal(options) {
    return this.parseColumn({ unit: { type: 'decimal', ...options } });
  }

  score() {
    return this.parseColumn({ unit: { type: 'score' } });
  }

  percentage() {
    return this.parseColumn({ unit: { type: 'percentage' } });
  }

  ratio() {
    return this.parseColumn({ unit: { type: 'ratio' } });
  }

  coordinate(options) {
    return this.parseColumn({ unit: { type: 'coordinate', ...options } });
  }

  count() {
    return this.parseColumn({ unit: { type: 'count' } });
  }

  countWithLabel(label = undefined) {
    return this.parseColumn({ unit: { type: 'count', label } });
  }

  sqm(options) {
    return this.parseColumn({ unit: { type: 'sqm', ...options } });
  }

  kwh() {
    return this.parseColumn({ unit: { type: 'kwh' } });
  }

  kw() {
    return this.parseColumn({ unit: { type: 'kw' } });
  }

  rating() {
    return this.parseColumn({ unit: { type: 'rating' } });
  }

  mj() {
    return this.parseColumn({ unit: { type: 'mj' } });
  }

  litres() {
    return this.parseColumn({ unit: { type: 'litres' } });
  }

  kgco2e() {
    return this.parseColumn({ unit: { type: 'kg-co2e' } });
  }
};

export default Units;
