import styled from 'styled-components';

const Block = styled.section`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
`;

// eslint-disable-next-line import/prefer-default-export
export { Block };
