import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash/fp';
import { Route, Routes } from 'react-router-dom';
import { publicTokensFetch } from 'actions/publicTokensFetch';
import { userLogin } from 'actions/userLogin';
import { userToken } from 'actions/userToken';
import { Authenticated } from 'components/authentication/Authenticated';
import { Unauthenticated } from 'components/authentication/Unauthenticated';
import { NotFound } from 'components/public-errors';
import { Loading } from 'components/loading';
import { RedirectReload } from 'components/redirect-reload';
import { PublicWorkspace } from 'components/public-workspace';
import { EmbeddedBlock } from 'components/embedded-block';
import { Toaster } from '@kinesis/bungle';
import { useRedirectHandler } from '@/hooks/useRedirectHandler';
import { WorkspacesSharesRoutes } from 'pages/WorkspacesSharesRoutes';

const propTypes = {};
const defaultProps = {};

const App = () => {
  const dispatch = useDispatch();
  const { isBooted, isLoggedIn, isLoggingIn, token } = useSelector(get('auth'));
  const handleRedirect = useRedirectHandler();

  useEffect(() => {
    dispatch(publicTokensFetch());
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (!isBooted && !isLoggedIn && !isLoggingIn) {
      const willCheckForLocalToken = import.meta.env.MODE === 'development';

      dispatch(userLogin({ token, willCheckForLocalToken }))
        .unwrap()
        .then(handleRedirect)
        .catch(() => {
          if (willCheckForLocalToken) {
            dispatch(userToken());
          }
        });
    }
  }, [dispatch, isBooted, isLoggedIn, isLoggingIn, handleRedirect, token]);

  if (!isBooted || isLoggingIn) {
    return <Loading displayMode='dark' />;
  }

  return (
    <>
      <Routes>
        <Route
          path='/workspaces/shares/:token/:action'
          element={<WorkspacesSharesRoutes />}
        />
        <Route path='/login' element={<RedirectReload to='/' />} />
        <Route path='/login/email' element={<RedirectReload to='/' />} />
        <Route path='/login/credentials' element={<RedirectReload to='/' />} />
        <Route path='/login/sso' element={<RedirectReload to='/' />} />
        <Route path='/public/:id/*' element={<PublicWorkspace />} />
        <Route path='/embed/:id/*' element={<EmbeddedBlock />} />
        <Route path='/not-found' element={<NotFound />} />
        <Route
          path='*'
          element={isLoggedIn ? <Authenticated /> : <Unauthenticated />}
        />
      </Routes>
      <Toaster id='globalTop' placement='top' />
      <Toaster id='globalBottom' placement='bottom' />
    </>
  );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
