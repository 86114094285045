import styled from 'styled-components';

const paddingForDefault = (indent) => `6px 12px 6px
    ${
      {
        none: '12px',
        single: '26px',
        double: '40px',
      }[indent || 'none']
    }`;

const paddingFor = (variant, indent) =>
  variant === 'bare' ? '0' : paddingForDefault(indent);

const StaticCell = styled('div')`
  ${({ cursor }) => (cursor ? 'cursor: pointer;' : '')}
  display: flex;
  align-items: center;
  font-variant-numeric: tabular-nums;
  justify-content: ${({ numeric }) => (numeric ? 'flex-end' : 'flex-start')};
  min-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${({ indent, variant }) => paddingFor(variant, indent)};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  font-weight: ${({ theme, header }) =>
    header ? theme.fontWeight.medium : theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.default};
  background-color: ${({ theme, appearance }) =>
    ({
      dark: theme.color.gray2,
      blue: theme.color.blue2,
      default: '#ffffff',
    })[appearance || 'default']};
  & > div:last-child {
    padding-left: 8px;
  }
`;

const EditableCell = styled('input')`
  display: flex;
  align-items: center;
  -moz-appearance: textfield;
  background-color: white;
  font-variant-numeric: tabular-nums;
  border: 1px solid ${({ theme }) => theme.color.blue6} !important;
  min-height: 32px;
  width: 100%;
  padding: 2px 8px;
  outline: 0;
  position: relative;
  transition: 0.3s;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  line-height: ${({ theme }) => theme.lineHeight.default};
  font-weight: ${({ theme, header }) =>
    header ? theme.fontWeight.medium : theme.fontWeight.normal};
`;

export { StaticCell, EditableCell };
