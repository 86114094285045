import { createSlice } from '@reduxjs/toolkit';
import { get, keyBy, isEmpty, mapValues } from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';

const { reducer, actions } = createSlice({
  name: 'instantiations',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(appAdd.fulfilled, (state, action) =>
      keyBy('key', action.payload.instantiations),
    );

    builder.addCase(workspaceFetch.fulfilled, (state, action) =>
      keyBy('key', action.payload.instantiations),
    );

    builder.addCase(workspacePatch.pending, (state, action) => {
      const optimisticInstances = get(
        ['meta', 'arg', 'optimisticState', 'instances'],
        action,
      );

      if (isEmpty(optimisticInstances)) {
        return state;
      }

      return mapValues((instance) => {
        const optimisticInstance = optimisticInstances.find(
          (oi) => oi.key === instance.key,
        );
        return optimisticInstance || instance;
      })(state);
    });

    builder.addCase(workspacePatch.fulfilled, (state, action) =>
      keyBy('key', action.payload.instantiations),
    );
  },
});

export { reducer, actions };
