import { useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Anchor, Divider, Stack, Text } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { WhiteBackground } from 'components/white-background';
import { PathwaysLocationType } from 'components/pathways-location-type';
import { PathwaysLocations } from 'components/pathways-locations';
import { pathwayCalculate } from 'actions/pathwayCalculate';
import usePathwayLocations from 'hooks/usePathwayLocations';
import usePathwayLocationType from 'hooks/usePathwayLocationType';
import usePathwayInterventionAreas from 'hooks/usePathwayInterventionAreas';
import { PathwaysInterventionCategoryHeader } from 'components/pathways-intervention-category-header';
import { PathwaysInterventionArea } from 'components/pathways-intervention-area';
import { PathwaysFileLoader } from 'components/pathways-file-loader';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';

const propTypes = {};
const defaultProps = {};

const PathwaysInputs = () => {
  const dispatch = useDispatch();
  const [interventionAreas, setInterventionArea] =
    usePathwayInterventionAreas();
  const [locationType, setLocationType] = usePathwayLocationType();
  const [locations, setLocations] = usePathwayLocations();
  const isMounted = useRef(false);
  const { search } = useLocation();

  const handleLocationsChange = useCallback(
    (v) => {
      setLocations(v, locationType, interventionAreas);
    },
    [interventionAreas, locationType, setLocations],
  );

  const handleLocationTypeChange = useCallback(
    (v) => {
      if (locationType !== v) {
        setLocationType(v, interventionAreas);
      }
    },
    [locationType, interventionAreas, setLocationType],
  );

  const handleInterventionAreaChange = useCallback(
    (k, v, input) => {
      setInterventionArea(k, v, input, locations, locationType);
    },
    [locations, locationType, setInterventionArea],
  );

  // NOTE: The following effects are to calculate on exactly two cases:
  //
  // * when loading the page for the first time (component mount)
  // * when navigating in-app to a pathways route with no query params
  //
  // In addition to these two cases, the only other time calculate will be
  // called is when the use directly changes inputs (locations, location type,
  // intervention areas).
  //
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // Clearing inputs, e.g. visiting pathway nav button.
    if (isMounted.current && isEmpty(search)) {
      dispatch(
        pathwayCalculate({ input: interventionAreas, locations, locationType }),
      );
    }
    if (!isMounted.current) {
      isMounted.current = true;
      dispatch(
        pathwayCalculate({ input: interventionAreas, locations, locationType }),
      );
    }
  }, [search]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <WhiteBackground>
      <Content
        padding='medium'
        paddingBottom='large'
        borderPlacement='right'
        width={468}
        height='calc(100% - 98px)'
      >
        <Stack space='medium'>
          <Text>
            Explore alternative emissions reductions pathways on your way to
            net-zero. Select locations you’re interested in and adjust the
            inputs for the intervention categories below.{' '}
            <Anchor
              target='_blank'
              href='https://help.kinesis.org/en/collections/7172951-net-zero-pathways'
            >
              Learn more about this model.
            </Anchor>
          </Text>
          <Divider />
          <Content overflow='visible' padding='none' paddingBottom='small'>
            <Stack space='medium'>
              <PathwaysLocationType
                value={locationType}
                onChange={handleLocationTypeChange}
              />
              <PathwaysLocations
                type={locationType}
                value={locations}
                onChange={handleLocationsChange}
              />
            </Stack>
          </Content>
          <Divider />
          <Content padding='none'>
            <Stack space='medium'>
              <PathwaysInterventionCategoryHeader identifier='energy' />
              <PathwaysInterventionArea
                identifier='residential-building-standards'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='non-residential-building-standards'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='residential-retrofits'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='non-residential-retrofits'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <Divider />

              <PathwaysInterventionCategoryHeader identifier='transport' />
              <PathwaysInterventionArea
                identifier='residential-vkt-change'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='residential-mode-shift'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='public-transit-electrification'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='ev-takeup'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <Divider />

              <PathwaysInterventionCategoryHeader identifier='waste' />
              <PathwaysInterventionArea
                identifier='waste-reduction'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='recyclable-packaging'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='recyclable-material-management'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='recyclable-ewaste'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='organics'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
              <PathwaysInterventionArea
                identifier='waste-to-energy'
                onChange={handleInterventionAreaChange}
                input={interventionAreas}
              />
            </Stack>
          </Content>
        </Stack>
      </Content>
      <PathwaysFileLoader />
    </WhiteBackground>
  );
};

PathwaysInputs.propTypes = propTypes;
PathwaysInputs.defaultProps = defaultProps;

export { PathwaysInputs };
