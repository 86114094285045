import { createSelector } from 'reselect';
import { find, pipe, values } from 'lodash/fp';
import scenariosSelector from './scenariosSelector';

const baselineScenarioSelector = createSelector(
  scenariosSelector,
  pipe(values, find({ isDefault: true })),
);

export default baselineScenarioSelector;
