import { createSelector } from 'reselect';
import {
  find,
  get,
  indexOf,
  isEmpty,
  isNil,
  map,
  pipe,
  reject,
} from 'lodash/fp';
import currentAttributesSelector from 'selectors/currentAttributesSelector';
import Units from 'data/units';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import chartDetailsDataSelector from 'selectors/chartDetailsDataSelector';
import xyChartSeriesSelector from 'selectors/xyChartSeriesSelector';
import xyChartOptionsSelector from 'selectors/xyChartOptionsSelector';
import locationsSelector from 'selectors/locationsSelector';

const units = new Units();

const chartDetailsSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  blockValueSelector,
  (state, props) => get('value', props),
  locationsSelector,
  pipe(currentAttributesSelector, get('settings')),
  chartDetailsDataSelector,
  xyChartSeriesSelector,
  xyChartOptionsSelector,
  (
    schema,
    blockValue,
    selectedValue,
    locations,
    settings,
    chartDetailsData,
    xySeries,
    xyChartOptions,
  ) => {
    switch (get('type', blockValue)) {
      case 'editable-visualisation': {
        const categories = get(['xAxis', 'categories'], xyChartOptions);
        const dataIdx = indexOf(selectedValue, categories);
        const items = pipe(
          reject(pipe(get('data'), get(dataIdx), isNil)),
          map((s) => ({
            key: s.name,
            label: s.nameFormatter ? s.nameFormatter(s.name) : s.name,
            color: s.color,
            value: s.unitFormatter(pipe(get('data'), get(dataIdx))(s)),
          })),
        )(xySeries);

        // NOTE: The selected value is actually already formatted.
        return { title: selectedValue, items };
      }
      default: {
        const chart = get('chart', blockValue);
        if (!chart) {
          return {};
        }

        const { type, x } = chart;

        if (isEmpty(schema) || isEmpty(chartDetailsData)) {
          return {};
        }

        switch (type) {
          case 'line':
          case 'stacked-area':
          case 'clustered-column':
          case 'column':
          case 'stacked-column': {
            const dataIdx = indexOf(selectedValue, chartDetailsData.categories);
            const column = get('column', x);
            const { format } = units.parseColumn(
              schema[column],
              locations,
              settings,
            );

            return {
              title: format(selectedValue),
              items: pipe(
                get('series'),
                reject(pipe(get('data'), get(dataIdx), isNil)),
                map((s) => ({
                  key: s.name,
                  label: s.nameFormatter ? s.nameFormatter(s.name) : s.name,
                  color: s.color,
                  value: s.unitFormatter(pipe(get('data'), get(dataIdx))(s)),
                })),
              )(chartDetailsData),
            };
          }

          case 'bubble': {
            const series = get(['series', 0], chartDetailsData);
            const { xLabel, yLabel, sizeLabel } = chartDetailsData;
            const selectedPoint = find({ dataId: selectedValue }, series.data);

            return {
              title: selectedPoint.label,
              items: [
                {
                  label: xLabel,
                  value: series.unitFormatter.x(selectedPoint.x),
                },
                {
                  label: yLabel,
                  value: series.unitFormatter.y(selectedPoint.y),
                },
                {
                  label: sizeLabel,
                  value: series.unitFormatter.z(selectedPoint.z),
                },
              ],
            };
          }

          case 'scatter': {
            const series = get(['series', 0], chartDetailsData);
            const { xLabel, yLabel } = chartDetailsData;
            const selectedPoint = find({ dataId: selectedValue }, series.data);

            return {
              title: selectedPoint.label,
              items: [
                {
                  label: xLabel,
                  value: series.unitFormatter.x(selectedPoint.x),
                },
                {
                  label: yLabel,
                  value: series.unitFormatter.y(selectedPoint.y),
                },
              ],
            };
          }

          default:
        }
      }
    }

    return {};
  },
);

export default chartDetailsSelector;
