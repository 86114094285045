import { TensorGrid } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import PropTypes from 'prop-types';

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
};

const defaultProps = {};

const EditableTensorGrid = ({ columns, data, onSave }) => (
  <Content
    paddingX='none'
    paddingY='xsmall'
    paddingBottom='small'
    // TODO: overflow='visible'
  >
    <Content
      borderPlacement={['top', 'right', 'bottom', 'left']}
      borderRadiusSize='xsmall'
      padding='none'
    >
      <TensorGrid columns={columns} data={data} onChange={onSave} />
    </Content>
  </Content>
);

EditableTensorGrid.propTypes = propTypes;
EditableTensorGrid.defaultProps = defaultProps;

export default EditableTensorGrid;
