import PropTypes from 'prop-types';
import { get, pipe } from 'lodash/fp';
import blockValueSelector from 'selectors/blockValueSelector';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import BubbleChart from './BubbleChart';
import DualStackedColumnChart from './DualStackedColumnChart';
import GenericChart from './GenericChart';
import LineChart from './LineChart';
import StackedAreaChart from './StackedAreaChart';
import ScatterChart from './ScatterChart';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  boardId: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  onBlockMultiSelect: PropTypes.func,
  onSelect: PropTypes.func,
  perspectives: PropTypes.object,
  privacy: PropTypes.oneOf(['private', 'public']),
  selectedDetailId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showLegend: PropTypes.bool,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  isSelected: false,
  onBlockMultiSelect: undefined,
  onSelect: undefined,
  perspectives: undefined,
  privacy: 'private',
  selectedDetailId: undefined,
  showLegend: false,
};

const Chart = ({
  blockId,
  boardId,
  onBlockMultiSelect,
  onSelect,
  perspectives,
  privacy,
  workspaceId,
  ...commonProps
}) => {
  const chartType = useSelectorWithProps(
    pipe(blockValueSelector, get(['chart', 'type'])),
    {
      blockId,
      public: privacy === 'public',
      workspaceId,
    },
  );
  const scenarioId = useScenarioId();

  const ChartType = ((type) => {
    switch (type) {
      case 'bubble':
        return BubbleChart;
      case 'line':
        return LineChart;
      case 'stacked-area':
        return StackedAreaChart;
      case 'scatter':
        return ScatterChart;
      case 'dual-stacked-column':
        return DualStackedColumnChart;
      default:
        return GenericChart;
    }
  })(chartType);

  return (
    <ChartType
      {...commonProps}
      blockId={blockId}
      boardId={boardId}
      onBlockMultiSelect={onBlockMultiSelect}
      onSelect={onSelect}
      perspectives={perspectives}
      privacy={privacy}
      scenarioId={scenarioId}
      workspaceId={workspaceId}
    />
  );
};

Chart.propTypes = propTypes;
Chart.defaultProps = defaultProps;

export default Chart;
