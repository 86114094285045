import { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { Icon, Input, Menu, useEventListener } from '@kinesis/bungle';
import { VerticalPane, VerticalPanes } from 'components/vertical-panes';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import importLocationSourcesSelector from 'selectors/importLocationSourcesSelector';
import AutoFocus from 'components/util/AutoFocus';
import { LoadableContent } from 'components/loadable-content';
import { LegacyContent } from 'components/legacy-content';
import LayerCircle from 'components/layer-circle/LayerCircle';

const ImportSourcesPane = styled(VerticalPane)`
  flex: 0 0 304px;
  width: 304px;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
`;

const ScenarioLabel = styled.span`
  display: inline-flex;
`;

const BaselineIconWrapper = styled.span`
  padding: 4px;
  color: ${({ theme }) => theme.color.gray7};
`;

const LayerLabel = styled.span`
  display: inline-flex;
  padding: 6px 0;
`;

const LayerCircleWrapper = styled.span`
  padding: 2px 8px 2px 0;
`;

const propTypes = {
  currentLayerId: PropTypes.number,
  layerId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  platformKey: PropTypes.string,
  scenarioId: PropTypes.number,
  workspaceId: PropTypes.number,
};

const defaultProps = {
  currentLayerId: undefined,
  layerId: undefined,
  platformKey: undefined,
  scenarioId: undefined,
  workspaceId: undefined,
};

const ImportSourcesMenu = ({
  currentLayerId,
  layerId,
  onSelect,
  platformKey,
  scenarioId,
  workspaceId,
}) => {
  const searchRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const sources = useSelectorWithProps(importLocationSourcesSelector, {
    currentLayerId,
    scenarioId,
    workspaceId,
  });

  const isLoadingWorkspaces = !useSelector((state) =>
    get(['misc', 'hasFetchedWorkspaces'], state),
  );

  const [workspacesDefaultCollapsed, setWorkspacesDefaultCollapsed] =
    useState(true);
  const filteredSources = useMemo(() => {
    const pattern = searchValue.toLowerCase();
    if (!pattern) return sources;
    setWorkspacesDefaultCollapsed(false);
    return sources.reduce((filtered, source) => {
      const workspaceMatched = source.name.toLowerCase().includes(pattern);
      const scenarioMatched = source.scenarios.some((s) =>
        s.name.toLowerCase().includes(pattern),
      );
      const layerMatched = source.layers.some((l) =>
        l.label.toLowerCase().includes(pattern),
      );

      if (workspaceMatched || scenarioMatched || layerMatched) {
        filtered.push({
          ...source,
          collapseWorkspace: !(scenarioMatched || layerMatched),
          collapseScenario: !layerMatched,
        });
      }
      return filtered;
    }, []);
  }, [sources, searchValue, setWorkspacesDefaultCollapsed]);

  useEventListener(
    'keydown',
    useCallback((event) => {
      const isFocused = document.activeElement === searchRef.current;
      if (event.key === 'Escape' && isFocused) {
        searchRef.current.blur();
        event.stopPropagation();
      }
      if (event.key === '/' && !isFocused) {
        searchRef.current.focus();
        event.preventDefault();
      }
    }, []),
    true,
  );

  const workspaceSources = (
    <LoadableContent loading={isLoadingWorkspaces}>
      {filteredSources.map((workspace) => (
        <Menu.Item
          key={workspace.id + searchValue}
          collapsible
          content={workspace.name}
          defaultCollapsed={workspace.collapseWorkspace}
          header
          id={workspace.id}
        >
          {workspace.scenarios.map((scenario) => (
            <Menu.Item
              key={scenario.id}
              collapsible
              content={
                <ScenarioLabel>
                  {scenario.name}
                  {scenario.isDefault && (
                    <BaselineIconWrapper>
                      <Icon type='baseline' />
                    </BaselineIconWrapper>
                  )}
                </ScenarioLabel>
              }
              defaultCollapsed={workspace.collapseScenario}
              disabled={scenario.current}
              header
            >
              {workspace.layers
                .filter((l) => l.type !== 'synthetic')
                .map((layer) => (
                  <Menu.Item
                    key={layer.id}
                    content={
                      <LayerLabel>
                        <LayerCircleWrapper>
                          <LayerCircle
                            enabled={
                              !scenario.current &&
                              (!workspace.current || layer.current)
                            }
                            layerId={layer.id}
                            workspaceId={workspace.id}
                          />
                        </LayerCircleWrapper>
                        {layer.label}
                      </LayerLabel>
                    }
                    disabled={
                      scenario.current || (workspace.current && !layer.current)
                    }
                    onClick={() => {
                      onSelect({
                        type: 'workspace',
                        workspace: workspace.id,
                        scenarioId: scenario.id,
                        layer: layer.id,
                      });
                    }}
                    variant={
                      workspaceId === workspace.id &&
                      scenarioId === scenario.id &&
                      layerId === layer.id
                        ? 'accent'
                        : 'default'
                    }
                  />
                ))}
            </Menu.Item>
          ))}
        </Menu.Item>
      ))}
    </LoadableContent>
  );

  const platformSources = (
    <LoadableContent loading={false}>
      <Menu.Item
        key='nsw-lga'
        content='NSW LGA'
        onClick={() => {
          onSelect({ type: 'platform', key: 'nsw-lga' });
        }}
        variant={platformKey === 'nsw-lga' ? 'accent' : 'default'}
      />
      <Menu.Item
        key='nsw-sa2'
        content='NSW SA2 (2016)'
        onClick={() => {
          onSelect({ type: 'platform', key: 'nsw-sa2' });
        }}
        variant={platformKey === 'nsw-sa2' ? 'accent' : 'default'}
      />
      <Menu.Item
        key='nsw-sa3'
        content='NSW SA3 (2016)'
        onClick={() => {
          onSelect({ type: 'platform', key: 'nsw-sa3' });
        }}
        variant={platformKey === 'nsw-sa3' ? 'accent' : 'default'}
      />
      <Menu.Item
        key='nsw-sa4-2016'
        content='NSW SA4 (2016)'
        onClick={() => {
          onSelect({ type: 'platform', key: 'nsw-sa4-2016' });
        }}
        variant={platformKey === 'nsw-sa4-2016' ? 'accent' : 'default'}
      />
      <Menu.Item
        key='nsw-lhd'
        content='NSW LHD'
        onClick={() => {
          onSelect({ type: 'platform', key: 'nsw-lhd' });
        }}
        variant={platformKey === 'nsw-lhd' ? 'accent' : 'default'}
      />
      <Menu.Item
        key='nsw-phn'
        content='NSW PHN'
        onClick={() => {
          onSelect({ type: 'platform', key: 'nsw-phn' });
        }}
        variant={platformKey === 'nsw-phn' ? 'accent' : 'default'}
      />
      <Menu.Item
        key='nsw-ced-2018'
        content='NSW CED (2018)'
        onClick={() => {
          onSelect({ type: 'platform', key: 'nsw-ced-2018' });
        }}
        variant={platformKey === 'nsw-ced-2018' ? 'accent' : 'default'}
      />
      <Menu.Item
        key='nsw-sed-2020'
        content='NSW SED (2020)'
        onClick={() => {
          onSelect({ type: 'platform', key: 'nsw-sed-2020' });
        }}
        variant={platformKey === 'nsw-sed-2020' ? 'accent' : 'default'}
      />
    </LoadableContent>
  );

  return (
    <AutoFocus>
      <ImportSourcesPane>
        <VerticalPanes>
          <VerticalPane flex={0}>
            <LegacyContent appearance='dark' spacing='small'>
              <Input
                disabled={sources.length === 0}
                maxWidth={271}
                onChange={setSearchValue}
                placeholder='Filter layers…'
                ref={searchRef}
                value={searchValue}
              />
            </LegacyContent>
          </VerticalPane>
          <VerticalPane flex={1} overflow='auto'>
            <LegacyContent>
              <Menu magnitude='large' spacing='none'>
                <Menu.Item
                  key={`workspaces${workspacesDefaultCollapsed}`}
                  collapsible
                  content='Workspaces'
                  defaultCollapsed={workspacesDefaultCollapsed}
                  header
                >
                  {workspaceSources}
                </Menu.Item>

                <Menu.Item
                  key={`platform${workspacesDefaultCollapsed}`}
                  collapsible
                  content='Platform'
                  defaultCollapsed={workspacesDefaultCollapsed}
                  header
                >
                  {platformSources}
                </Menu.Item>
              </Menu>
            </LegacyContent>
          </VerticalPane>
        </VerticalPanes>
      </ImportSourcesPane>
    </AutoFocus>
  );
};

ImportSourcesMenu.propTypes = propTypes;
ImportSourcesMenu.defaultProps = defaultProps;

export default ImportSourcesMenu;
