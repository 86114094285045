import PropTypes from 'prop-types';
import { get, isEmpty, map } from 'lodash/fp';
import { Icon } from '@kinesis/bungle';
import { LegacyContent } from 'components/legacy-content';
import {
  BoardsList,
  BoardsListItem,
  IconWrapper,
  NoData,
  Paragraph,
} from './app-management.styles';

const propTypes = {
  appName: PropTypes.string,
  boards: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  appName: 'This app',
  boards: [],
};

const BoardsTabContent = ({ appName, boards }) => {
  if (isEmpty(boards)) {
    return (
      <NoData>
        <Paragraph gray={6}>
          This app does not provide pre-defined boards.
        </Paragraph>
      </NoData>
    );
  }

  return (
    <LegacyContent>
      <Paragraph>
        {appName} provides these pre-defined boards to your workspace to help
        you explore and understand its outputs.
      </Paragraph>
      <BoardsList>
        {map((board) => (
          <BoardsListItem key={get('key', board)}>
            <IconWrapper>
              <Icon type='board' />
            </IconWrapper>
            {get('title', board)}
          </BoardsListItem>
        ))(boards)}
      </BoardsList>
    </LegacyContent>
  );
};

BoardsTabContent.defaultProps = defaultProps;
BoardsTabContent.propTypes = propTypes;

export default BoardsTabContent;
