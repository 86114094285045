import scenarioKeySelector from 'selectors/scenarioKeySelector';
import { actions as locationsActions } from 'reducers/locationsReducer';
import { scenarioSend } from 'actions/scenarioSend';

const { removeLocation } = locationsActions;

function createLocationsMiddleware() {
  return ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
      const state = getState();

      next(action);

      switch (action.type) {
        case removeLocation.type: {
          const { locationId, scenarioId, workspaceId } = action.payload;
          const scenarioKey = scenarioKeySelector(state, {
            scenarioId,
            workspaceId,
          });

          dispatch(
            scenarioSend({
              scenarioId,
              scenarioKey,
              payload: {
                locations: [],
                deletions: [locationId],
              },
              actions: [
                {
                  type: 'remove-locations',
                  value: [locationId],
                },
              ],
              workspaceId,
            }),
          );
          break;
        }

        default:
      }
    };
}

export default createLocationsMiddleware;
