import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const pathwayCalculate = createAsyncThunk(
  'pathway/calculate',
  async ({ input, locations, locationType }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/pathways`, {
      input,
      locations,
      location_type: locationType,
    });

    return response.data;
  },
);

export { pathwayCalculate };
