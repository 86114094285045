import PropTypes from 'prop-types';
import { noop, get } from 'lodash/fp';
import {
  UtilityButton,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  Strong,
} from '@kinesis/bungle';

const propTypes = {
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  value: PropTypes.oneOf(['fineareas', 'regions', 'subregions']).isRequired,
};

const defaultProps = {
  disabled: false,
  onSelect: noop,
};

const labels = {
  regions: 'Regions',
  subregions: 'Sub-regions',
  fineareas: 'Fine areas',
};

const FineAreaGranularitySelect = ({ disabled, onSelect, value }) => (
  <DropdownMenu>
    <DropdownMenuButton
      as={UtilityButton}
      disabled={disabled}
      dropdown
      inline
      magnitude='small'
    >
      <span>View&nbsp;</span>
      <Strong>{get(value, labels)}</Strong>
    </DropdownMenuButton>
    <DropdownMenuList width={240}>
      {['regions', 'subregions', 'fineareas'].map((type) => (
        <DropdownMenuItem
          key={type}
          onSelect={() => onSelect(type)}
          variant={type === value ? 'accent' : undefined}
        >
          {get(type, labels)}
        </DropdownMenuItem>
      ))}
    </DropdownMenuList>
  </DropdownMenu>
);

FineAreaGranularitySelect.defaultProps = defaultProps;
FineAreaGranularitySelect.propTypes = propTypes;

export { FineAreaGranularitySelect };
