// NOTE: Do not change key, this is a shared key, with
// other web-apps that authenticate via this interface.
const authKey = 'kinesis:session';

function deleteToken() {
  localStorage.removeItem(authKey);
}

function getToken() {
  return localStorage.getItem(authKey);
}

function setToken(token) {
  localStorage.setItem(authKey, token);
}

export { deleteToken, getToken, setToken };
