import { createSelector } from 'reselect';
import { identity, get, pipe } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const namedScenariosSelector = createSelector(
  pipe(workspaceSelector, get(['scenarios', 'named'])),
  identity,
);

export default namedScenariosSelector;
