import { createSlice } from '@reduxjs/toolkit';
import {
  find,
  isNil,
  get,
  unset,
  set,
  pipe,
  keyBy,
  mapValues,
  map,
  reject,
  values,
} from 'lodash/fp';
import { locationCreate } from 'actions/locationCreate';
import { normalizeAttributeEntities } from 'utils/attributesUtils';
import { appAdd } from 'actions/appAdd';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { scenarioSend } from 'actions/scenarioSend';
import { tagUpdate } from 'actions/tagUpdate';
import { geographyUpdate } from 'actions/geographyUpdate';

const keyByAttributeKey = keyBy('key');

// TEMPORARY: this is needed to help ween marathon off trusting attribute types
// and names from attribute values and settings.
const strip = pipe(unset('name'), unset('attribute'), unset('definition'));

const normaliseAttributes = pipe(map(strip), keyByAttributeKey);

const normaliseDefinitions = pipe(
  values,
  reject({ definition: 'service' }),
  reject({ definition: 'usage' }),
  map(strip),
  keyByAttributeKey,
);

const normaliseLocations = pipe(
  values,
  map((item) => ({
    id: item.id,
    attributes: normaliseAttributes(item.attributes),
  })),
  keyBy('id'),
);

const setAttributes = mapValues((data) => ({
  entities: normalizeAttributeEntities(data.values),
  scenario: data.scenario,
  settings: normaliseDefinitions(data.settings),
  values: normaliseLocations(data.values),
  stages: data.stages,
  selectors: data.selectors,
}));

const setOptimisticSettings = (state, action) =>
  mapValues((subState) =>
    action.meta.targets.find((t) => t.targetScenarioKey === subState.scenario)
      ? {
          ...subState,
          priorSettings: subState.settings,
          settings: mapValues((s) => {
            const matchingSetting = find(
              (os) => s.key === get('key', os),
              get(['meta', 'arg', 'optimisticState', 'settings'], action),
            );
            if (matchingSetting) {
              return matchingSetting;
            }
            return s;
          }, subState.settings),
        }
      : subState,
  )(state);

const resetOptimisticSettings = mapValues((subState) =>
  !isNil(subState.priorSettings)
    ? pipe(
        set('settings', subState.priorSettings),
        unset('priorSettings'),
      )(subState)
    : subState,
);

const { reducer, actions } = createSlice({
  name: 'attributes',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(appAdd.fulfilled, (state, action) =>
      setAttributes(action.payload.attributes),
    );

    builder.addCase(workspaceFetch.fulfilled, (state, action) =>
      setAttributes(action.payload.attributes),
    );

    builder.addCase(workspacePatch.fulfilled, (state, action) =>
      setAttributes(action.payload.attributes),
    );

    builder.addCase(workspacePatch.pending, (state, action) => ({
      ...state,
      ...setOptimisticSettings(state, action),
    }));

    builder.addCase(workspacePatch.rejected, (state) => ({
      ...state,
      ...resetOptimisticSettings(state),
    }));

    builder.addCase(tagUpdate.fulfilled, (state, action) => ({
      ...state,
      ...setAttributes(action.payload.attributes),
    }));

    builder.addCase(scenarioSend.fulfilled, (state, action) => ({
      ...state,
      ...setAttributes(action.payload.attributes),
    }));

    builder.addCase(locationCreate.fulfilled, (state, action) => ({
      ...state,
      ...setAttributes(action.payload.scenario.attributes),
    }));

    builder.addCase(geographyUpdate.fulfilled, (state, action) =>
      setAttributes(action.payload.attributes),
    );
  },
});

export { reducer, actions };
