import styled from 'styled-components';

const Layout = styled.div`
  display: ${(props) => props.display || 'flex'};
  flex-direction: ${(props) => props.direction};
  flex: 1 1 0;
  overflow: ${(props) => props.overflow || 'auto'};
  position: relative;
`;

export default Layout;
