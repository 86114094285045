import { createCachedSelector } from 're-reselect';
import { get } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const publicBlockYearSelector = createCachedSelector(
  (state, props) => props.blockId,
  workspaceSelector,
  (blockId, workspace) => get(['time', 'blocks', blockId, 'year'], workspace),
)((state, props) => props.blockId);

export default publicBlockYearSelector;
