import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetVersionCreate = createAsyncThunk(
  'dataset-version/create',
  async ({ datasetId, base }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/datasets/${datasetId}/versions`, {
      base,
    });
    return response.data;
  },
);

export { datasetVersionCreate };
