import { pipe } from 'lodash/fp';

const pos = (x) => {
  const result = ((x + 180) % 360) - 180;
  return result === -180 ? 180 : result;
};

const neg = (x) => ((x - 180) % 360) + 180;

export const wrapLongitude = (longitude) => {
  if (longitude > 180) return pos(longitude);
  if (longitude <= -180) return neg(longitude);
  return longitude;
};

export const normaliseLongitude = pipe(
  (longitude) => longitude.toFixed(5),
  parseFloat,
  wrapLongitude,
);
