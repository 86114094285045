import { api } from './api';

const entitiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchEntities: builder.query({
      query: (query) => ({
        url: 'api/entities/search',
        method: 'POST',
        body: {},
        params: { query },
      }),
    }),
    entityTypes: builder.query({
      query: () => ({
        url: 'api/entities/types',
        method: 'GET',
      }),
    }),
    recentEntities: builder.query({
      query: () => ({
        url: 'api/entities/recent',
        method: 'GET',
      }),
    }),
  }),
});

export const getEntityUrl = (namespace, id) => {
  switch (namespace) {
    case 'city':
      return `/city/${id}`;
    case 'integrated':
      return `/integrated/${id}`;
    case 'workspace':
      return `/workspaces/${id}`;
    case 'inventory':
      return `/inventories/${id}`;
    case 'dataset':
      return `/datasets/${id}`;
    case 'bill-management':
      return `/bill-management/${id}`;
    default:
      return '';
  }
};

export const getEntityTypeUrl = (namespace) => {
  switch (namespace) {
    case 'city':
      return '/city/';
    case 'integrated':
      return '/integrated/';
    case 'workspace':
      return '/workspaces/';
    case 'inventory':
      return '/inventories/';
    case 'dataset':
      return '/datasets/';
    case 'pathways':
      return '/pathways/';
    case 'settings':
      return '/settings/';
    case 'bill-management':
      return '/bill-management/';
    default:
      return '';
  }
};

export const {
  useSearchEntitiesQuery,
  useEntityTypesQuery,
  useRecentEntitiesQuery,
} = entitiesApi;
