import { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loading } from 'components/loading';
import Plural from 'components/plural/plural';
import { Checkbox, Input, useEventListener } from '@kinesis/bungle';
import { VerticalPane, VerticalPanes } from 'components/vertical-panes';
import { LegacyContent } from 'components/legacy-content';
import MultiCheckCheckbox from './multi-check-checkbox';

const propTypes = {
  emptyText: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  placeholderText: PropTypes.string,
  values: PropTypes.array.isRequired,
  word: PropTypes.string.isRequired,
};

const defaultProps = {
  emptyText: 'No items to choose',
  loading: false,
  placeholderText: 'Search…',
  options: [],
};

const PlaceholderText = styled.div`
  align-items: center;
  color: rgba(0, 0, 0, 0.25);
  display: flex;
  height: 100%;
  justify-content: center;
`;

const MultiCheck = ({
  emptyText,
  loading,
  onChange,
  options,
  placeholderText,
  values,
  word,
}) => {
  const searchRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const filteredOptions = useMemo(
    () =>
      options.filter((opt) =>
        opt.label.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [options, searchValue],
  );
  const allChecked = useMemo(
    () => filteredOptions.every((opt) => values.includes(opt.value)),
    [filteredOptions, values],
  );
  const noneChecked = useMemo(
    () => filteredOptions.every((opt) => !values.includes(opt.value)),
    [filteredOptions, values],
  );

  const handleChange = useCallback(
    (value) => {
      onChange(
        options
          .map((opt) => opt.value)
          .filter(
            (val) =>
              (val === value && !values.includes(val)) ||
              (val !== value && values.includes(val)),
          ),
      );
    },
    [onChange, options, values],
  );

  const handleCheckAll = useCallback(() => {
    onChange(
      options
        .filter(
          allChecked
            ? (opt) =>
                !filteredOptions.includes(opt) && values.includes(opt.value)
            : (opt) =>
                filteredOptions.includes(opt) || values.includes(opt.value),
        )
        .map((opt) => opt.value),
    );
  }, [allChecked, filteredOptions, onChange, options, values]);

  useEventListener(
    'keydown',
    useCallback((event) => {
      const isFocused = document.activeElement === searchRef.current;
      if (event.key === 'Escape' && isFocused) {
        searchRef.current.blur();
        event.stopPropagation();
      }
      if (
        ((event.key === '/' && event.altKey) || event.key === '÷') &&
        !isFocused
      ) {
        searchRef.current.focus();
        event.preventDefault();
      }
    }, []),
    true,
  );

  return (
    <VerticalPanes>
      <VerticalPane flex={0}>
        <LegacyContent appearance='dark' spacing='small'>
          <Input
            disabled={options.length === 0}
            maxWidth={271}
            onChange={setSearchValue}
            placeholder={placeholderText}
            ref={searchRef}
            value={searchValue}
          />
        </LegacyContent>
      </VerticalPane>
      <VerticalPane flex={1} overflow='auto'>
        {loading ? (
          <LegacyContent>
            <Loading delay={300} />
          </LegacyContent>
        ) : (
          <LegacyContent>
            {options.length === 0 && (
              <PlaceholderText>{emptyText}</PlaceholderText>
            )}
            {filteredOptions.map((opt) => (
              <MultiCheckCheckbox
                key={opt.value}
                checked={values.includes(opt.value)}
                onChange={handleChange}
                value={opt.value}
              >
                {opt.label}
              </MultiCheckCheckbox>
            ))}
          </LegacyContent>
        )}
      </VerticalPane>
      {options.length > 0 && (
        <VerticalPane flex={0}>
          <LegacyContent spacing='small'>
            <Checkbox
              checked={filteredOptions.length > 0 && allChecked}
              disabled={filteredOptions.length === 0}
              indeterminate={!allChecked && !noneChecked}
              onChange={handleCheckAll}
            >
              <Plural
                count={filteredOptions.length}
                formatCount={(val) => (val === 0 ? 'No' : val)}
                inclusive
                word={word}
              />
            </Checkbox>
          </LegacyContent>
        </VerticalPane>
      )}
    </VerticalPanes>
  );
};

MultiCheck.propTypes = propTypes;
MultiCheck.defaultProps = defaultProps;

export default MultiCheck;
