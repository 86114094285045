import { createSelector } from 'reselect';
import { get, pipe, identity } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const statisticsStatusSelector = createSelector(
  pipe(workspaceSelector, get(['statistics', 'status'])),
  identity,
);

export default statisticsStatusSelector;
