import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'preview',
  initialState: {
    year: undefined,
    yearSetManually: false,
    constrainedBounds: {},
  },
  reducers: {
    setPreviewYear: (state, action) => ({
      ...state,
      year: action.payload.year || new Date().getFullYear(),
      yearSetManually: action.payload.yearSetManually,
    }),

    constrainBounds: (state, action) => ({
      ...state,
      constrainedBounds: {
        ...state.constrainedBounds,
        [action.payload.scenarioId]: action.payload.bounds,
      },
    }),
  },
});

export { reducer, actions };
