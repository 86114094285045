import { useState, useMemo, useCallback } from 'react';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import PropTypes from 'prop-types';
import { find, pipe, filter, first, get, keyBy } from 'lodash/fp';
import { Stack, InPlaceInput, Select } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { items, flatten, relabelItem } from 'data/attributes';
import FormSection from 'components/form-section/FormSection';
import settingsSelector from 'selectors/settingsSelector';
import { isItem, validSettings } from 'utils/settingsModal';
import GenericSettings from './attribute-settings.generic';
import SettingsGrid from './settings-grid';

const propTypes = {
  instantiation: PropTypes.object.isRequired,
  setInstantiation: PropTypes.func.isRequired,
  setSetting: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const description =
  'Recycled water refers to systems used to reclaim and store water processed from sewage.';

const unitLabels = {
  litres: 'Capacity (L)',
};

const RecycledWaterSettings = ({
  setting,
  instantiation,
  setSetting,
  setInstantiation,
  workspaceId,
}) => {
  const [selections, setSelections] = useState([]);

  const settingsSpecifications = useSelectorWithProps(settingsSelector, {
    attribute: 'recycled-water-system',
    workspaceId,
  });

  const singleSelection = useMemo(
    () =>
      selections.length === 1
        ? pipe(flatten, filter({ key: first(selections) }), first)(setting)
        : undefined,
    [selections, setting],
  );

  const itemHeading = useMemo(
    () =>
      isItem(singleSelection)
        ? pipe(
            items,
            keyBy('key'),
            get([get('key', singleSelection), 'label']),
          )(instantiation)
        : undefined,
    [singleSelection, instantiation],
  );

  const onChangeItemLabel = useCallback(
    (value) => {
      if (singleSelection) {
        setInstantiation((current) =>
          relabelItem(singleSelection.key, value, current),
        );
      }
    },
    [setInstantiation, singleSelection],
  );

  const activeSettings = useMemo(
    () => validSettings(singleSelection, settingsSpecifications),
    [singleSelection, settingsSpecifications],
  );

  const withDivider = (children) => (
    <Content borderPlacement='bottom' padding='none' paddingBottom='large'>
      {children}
    </Content>
  );

  const itemType = useMemo(
    () =>
      pipe(
        flatten,
        find({ key: get('key', singleSelection) }),
        get('item_type'),
      )(instantiation),
    [instantiation, singleSelection],
  );

  return (
    <GenericSettings
      description={description}
      instantiation={instantiation}
      selections={selections}
      setSelections={setSelections}
      setting={setting}
      workspaceId={workspaceId}
    >
      <FormSection key={get('key', singleSelection)}>
        <Stack space='medium'>
          {itemHeading && (
            <InPlaceInput
              onChange={onChangeItemLabel}
              placeholder='Item'
              value={itemHeading}
              variant='title'
            />
          )}
          {isItem(singleSelection) &&
            withDivider(
              <Select
                label='Item type'
                onChange={console.log}
                disabled
                value={itemType}
                options={[
                  {
                    label: unitLabels[itemType],
                    value: singleSelection.unit.type,
                  },
                ]}
              />,
            )}

          {isItem(singleSelection) && (
            <SettingsGrid
              item={singleSelection}
              setSetting={setSetting}
              settings={activeSettings}
              specifications={settingsSpecifications}
              workspaceId={workspaceId}
            />
          )}
        </Stack>
      </FormSection>
    </GenericSettings>
  );
};

RecycledWaterSettings.defaultProps = defaultProps;
RecycledWaterSettings.propTypes = propTypes;

export default RecycledWaterSettings;
