import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  & > * {
    flex: 0 0 160px;
    border-right: 1px solid ${({ theme }) => theme.borderColor};
  }
  & > :first-child {
    flex: 1 1 auto;
    border-right: 1px solid ${({ theme }) => theme.borderColor};
  }
  & > :last-child {
    flex: 0 0 40px;
    align-items: center;
    justify-content: center;
    border-right: none;
  }
`;

export { Row };
