/*
 * This is an attempt to be really clear about what should be
 * data-driven in the attribute code, whilst not all attribute level
 * details are data-driven and self-service for app-builders right
 * now, we shouldn't use that as opportunity to bake specifics into
 * the code. To avoid that, this is a set of constant attribute
 * details that can be used to data-drive the attribute setting +
 * value modals, including their structure, validation and behaviour.
 *
 * Over time the expectation is that this type of data becomes
 * dynamic and made available as a part of the wider kinesis
 * platform. At the start, this will look odd/overly-detailed,
 * slowly but surely these things should become very general.
 */

export const USAGE_SPECIFICATIONS = [
  {
    key: 'space-type',
    family: 'settings:kinesis:space-type',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Space type',
    group: 'Space assumptions',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'accommodation',
          label: 'Accommodation',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'aged-care',
          label: 'Aged care',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'aquatic-centre',
          label: 'Aquatic centre',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'commercial',
          label: 'Commercial',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'common-area-high-rise',
          label: 'Common area - high-rise apartments',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'common-area-medium-rise',
          label: 'Common area - medium-rise apartments',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'common-area-low-rise',
          label: 'Common area - low-rise apartments',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'community-centre',
          label: 'Community centre',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'day-clinic',
          label: 'Day clinic',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'enclosed-carpark',
          label: 'Enclosed carpark',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'food-and-beverage',
          label: 'Food and beverage',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'hospital',
          label: 'Hospital',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'industrial',
          label: 'Industrial',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'library-and-community-centre',
          label: 'Library',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'media-and-entertainment',
          label: 'Media and entertainment',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'open-air-carpark',
          label: 'Open air carpark',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'public-garden',
          label: 'Public garden',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'public-plaza',
          label: 'Public plaza',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'residential-garden',
          label: 'Residential garden',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'retail',
          label: 'Retail',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'school',
          label: 'School',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'shopping-centre',
          label: 'Shopping centre',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'sports-field',
          label: 'Sports field',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'supermarket',
          label: 'Supermarket',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'theatre-and-cinema',
          label: 'Theatre and cinema',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'university-semester',
          label: 'University',
          constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
        },
        {
          value: 'attached',
          label: 'Attached house',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
          },
        },
        {
          value: 'detached',
          label: 'Detached house',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
          },
        },
        {
          value: 'high-rise',
          label: 'High-rise apartment',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
          },
        },
        {
          value: 'medium-rise',
          label: 'Medium-rise apartment',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
          },
        },
        {
          value: 'low-rise',
          label: 'Low-rise apartment',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
          },
        },
      ],
    },
  },
  {
    key: 'appliance-package',
    family: 'settings:kinesis:appliance-package',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Appliance package',
    group: 'Appliances',
    deprecated: true,
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'residential-standard',
          label: 'Residential (standard)',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
            'setting-constraint': {
              'setting-has-value': {
                key: 'setting:kinesis:space-type',
                condition: { 'string-neq': 'enclosed-car-park' },
              },
            },
          },
        },
        {
          value: 'residential-efficient',
          label: 'Residential (efficient)',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
            'setting-constraint': {
              'setting-has-value': {
                key: 'setting:kinesis:space-type',
                condition: { 'string-neq': 'enclosed-car-park' },
              },
            },
          },
        },
        {
          value: 'non-residential-standard',
          label: 'Non-residential (standard)',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space' },
            'setting-constraint': {
              'setting-has-value': {
                key: 'setting:kinesis:space-type',
                condition: { 'string-neq': 'enclosed-car-park' },
              },
            },
          },
        },
        {
          value: 'non-residential-efficient',
          label: 'Non-residential (efficient)',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space' },
            'setting-constraint': {
              'setting-has-value': {
                key: 'setting:kinesis:space-type',
                condition: { 'string-neq': 'enclosed-car-park' },
              },
            },
          },
        },
        {
          value: 'non-residential-car-park-standard',
          label: 'Non-residential car-park (standard)',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space' },
            'setting-constraint': {
              'setting-has-value': {
                key: 'setting:kinesis:space-type',
                condition: { 'string-eq': 'enclosed-carpark' },
              },
            },
          },
        },
        {
          value: 'non-residential-car-park-efficient',
          label: 'Non-residential car-park (efficient)',
          constraints: {
            'item-constraint': { 'item-type-is': 'floor-space' },
            'setting-constraint': {
              'setting-has-value': {
                key: 'setting:kinesis:space-type',
                condition: { 'string-eq': 'enclosed-carpark' },
              },
            },
          },
        },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'car-park-package',
    family: 'settings:kinesis:car-park-package',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Car park appliance package',
    group: 'Appliances',
    deprecated: true,
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'residential-car-park-standard',
          label: 'Residential car park (standard)',
        },
        {
          value: 'residential-car-park-efficient',
          label: 'Residential car park (efficient)',
        },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'minimum-space-allocated',
    family: 'settings:kinesis:minimum-space-allocated',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Average space per dwelling',
    group: 'Space assumptions',
    settingType: {
      type: 'float64-numeric',
      default: 100,
      minimum: 0,
      unit: { type: 'sqm' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'number-of-bedrooms',
    family: 'settings:kinesis:number-of-bedrooms',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Number of bedrooms',
    group: 'Space assumptions',
    settingType: {
      type: 'int64-numeric',
      default: 3,
      minimum: 0,
      unit: { type: 'count', label: 'bedrooms' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'car-park-spaces',
    family: 'settings:kinesis:car-park-spaces',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Car park spaces',
    group: 'Space assumptions',
    settingType: {
      type: 'float64-numeric',
      default: 2,
      minimum: 0,
      unit: { type: 'count', label: 'spaces' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'car-park-space-size',
    family: 'settings:kinesis:car-park-space-size',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Car park space size (m2)',
    group: 'Space assumptions',
    settingType: {
      type: 'float64-numeric',
      default: 30,
      minimum: 0,
      unit: { type: 'sqm' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'detached' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'attached' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'nathers',
    family: 'settings:kinesis:nathers',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'NatHERs',
    group: 'Standards',
    settingType: {
      type: 'float64-numeric',
      default: 2,
      minimum: 0,
      maximum: 10,
      unit: { type: 'rating', label: 'stars' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'garden-area-per-dwelling',
    family: 'settings:kinesis:garden-area-per-dwelling',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Garden area per dwelling',
    group: 'Space assumptions',
    settingType: {
      type: 'float64-numeric',
      default: 0,
      minimum: 0,
      unit: { type: 'sqm' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        any: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'detached' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'attached' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'fridge_star_rating',
    family: 'settings:kinesis:fridge_star_rating',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Fridge star rating',
    group: 'Whitegoods',
    settingType: {
      type: 'float64-select',
      options: [
        {
          value: 2.0,
          label: '2 star',
        },
        {
          value: 2.5,
          label: '2.5 star',
        },
        {
          value: 3.0,
          label: '3 star (Standard)',
          priority: 0,
        },
        {
          value: 3.5,
          label: '3.5 star',
        },
        {
          value: 4.0,
          label: '4 star',
        },
        {
          value: 4.5,
          label: '4.5 star (Efficient)',
        },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'dishwasher_meps_rating',
    family: 'settings:kinesis:dishwasher_meps_rating',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Dishwasher energy rating',
    group: 'Whitegoods',
    settingType: {
      type: 'float64-select',
      options: [
        {
          value: 3.0,
          label: '3 star',
        },
        {
          value: 3.5,
          label: '3.5 star (Standard)',
          priority: 0,
        },
        {
          value: 4.0,
          label: '4 star',
        },
        {
          value: 4.5,
          label: '4.5 star (Efficient)',
        },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'dishwasher_wels_rating',
    family: 'settings:kinesis:dishwasher_wels_rating',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Dishwasher water rating',
    group: 'Whitegoods',
    settingType: {
      type: 'float64-select',
      options: [
        {
          value: 3.5,
          label: '3.5 star',
        },
        {
          value: 4.0,
          label: '4 star',
        },
        {
          value: 4.5,
          label: '4.5 star (Standard)',
          priority: 0,
        },
        {
          value: 5.0,
          label: '5 star (Efficient)',
        },
        {
          value: 5.5,
          label: '5.5 star',
        },
        {
          value: 6.0,
          label: '6 star',
        },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'clothes_washer_energy_rating',
    family: 'settings:kinesis:clothes_washer_energy_rating',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Clotheswasher energy rating',
    group: 'Whitegoods',
    settingType: {
      type: 'float64-select',
      options: [
        {
          value: 2.0,
          label: '2 star',
        },
        {
          value: 2.5,
          label: '2.5 star',
        },
        {
          value: 3.0,
          label: '3 star',
        },
        {
          value: 3.5,
          label: '3.5 star',
        },
        {
          value: 4.0,
          label: '4 star (Standard)',
          priority: 0,
        },
        {
          value: 4.5,
          label: '4.5 star',
        },
        {
          value: 5.0,
          label: '5 star (Efficient)',
        },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'clothes_washer_water_rating',
    family: 'settings:kinesis:clothes_washer_water_rating',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Clotheswasher water rating',
    group: 'Whitegoods',
    settingType: {
      type: 'float64-select',
      options: [
        {
          value: 3.0,
          label: '3 star',
        },
        {
          value: 3.5,
          label: '3.5 star',
        },
        {
          value: 4.0,
          label: '4 star',
        },
        {
          value: 4.5,
          label: '4.5 star (Standard)',
          priority: 0,
        },
        {
          value: 5.0,
          label: '5 star (Efficient)',
        },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'clothes_dryer_star_rating',
    family: 'settings:kinesis:clothes_dryer_star_rating',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Clothesdryer star rating',
    group: 'Whitegoods',
    settingType: {
      type: 'float64-select',
      options: [
        {
          value: 2.0,
          label: '2 star (Standard)',
          priority: 0,
        },
        {
          value: 6.0,
          label: '6 star',
        },
        {
          value: 7.0,
          label: '7 star (Efficient)',
        },
        {
          value: 8.0,
          label: '8 star',
        },
        {
          value: 9.0,
          label: '9 star',
        },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'cooktop_type',
    family: 'settings:kinesis:cooktop_type',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Cooktop type',
    group: 'Whitegoods',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'electricity', label: 'Electric', priority: 0 },
        { value: 'gas', label: 'Gas' },
        { value: 'induction', label: 'Induction' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'oven_type',
    family: 'settings:kinesis:oven_type',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Oven type',
    group: 'Whitegoods',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'electricity', label: 'Electric', priority: 0 },
        { value: 'gas', label: 'Gas' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'ventilation',
    family: 'settings:kinesis:ventilation',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Ventilation',
    group: 'Fixtures',
    settingType: {
      type: 'string-select',
      options: [{ value: 'standard', label: 'Standard', priority: 0 }],
    },
    constraints: {
      'item-constraint': {
        any: [
          { 'item-type-is': 'floor-space-and-dwellings' },
          { 'item-type-is': 'floor-space' },
        ],
      },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'taps_star_rating',
    family: 'settings:kinesis:taps_star_rating',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Taps star rating',
    group: 'Fixtures',
    settingType: {
      type: 'float64-select',
      options: [
        { value: 3.0, label: '3 star' },
        { value: 4.0, label: '4 star (Standard)', priority: 0 },
        { value: 5.0, label: '5 star (Efficient)' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'shower_flow_rate',
    family: 'settings:kinesis:shower_flow_rate',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Shower flow rate',
    group: 'Fixtures',
    settingType: {
      type: 'float64-select',
      options: [
        { value: 9.0, label: '3 star (Standard)', priority: 0 },
        { value: 7.5, label: '4 star (Efficient)' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'toilet_star_rating',
    family: 'settings:kinesis:toilet_star_rating',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Toilet star rating',
    group: 'Fixtures',
    settingType: {
      type: 'float64-select',
      options: [
        { value: 3.0, label: '3 star WELS (Standard)', priority: 0 },
        { value: 4.0, label: '4 star WELS (Efficient)' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'lighting_kwh_per_sqm',
    family: 'settings:kinesis:lighting_kwh_per_sqm',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Lighting kwh per sqm',
    group: 'Fixtures',
    settingType: {
      type: 'float64-select',
      options: [
        {
          value: 2.82,
          label: 'Compact flourescent/halogen (Standard)',
          priority: 0,
        },
        { value: 1.41, label: 'LEDs (Efficient)' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  // Heating/Cooling
  {
    key: 'heating',
    family: 'settings:kinesis:heating',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Heating',
    group: 'Heating/Cooling',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'air-conditioning',
          label: 'Reverse cycle air conditioning',
          priority: 0,
        },
        { value: 'ducted-gas', label: 'Ducted gas heating' },
        { value: 'panel', label: 'Electric panel heating' },
        { value: 'heat-pump', label: 'Ground source heat pump' },
        { value: 'eer', label: 'Custom EER' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'cooling',
    family: 'settings:kinesis:cooling',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Cooling',
    group: 'Heating/Cooling',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'air-conditioning',
          label: 'Reverse cycle air conditioning',
          priority: 0,
        },
        { value: 'heat-pump', label: 'Ground source heat pump' },
        { value: 'cop', label: 'Custom CoP' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'heating-ac',
    family: 'settings:kinesis:heating-ac',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Heating star rating',
    group: 'Heating/Cooling',
    settingType: {
      type: 'float64-select',
      options: [
        { value: 2.5, label: '2.5 star (Standard)', priority: 0 },
        { value: 3.0, label: '3 star' },
        { value: 3.5, label: '3.5 star' },
        { value: 4.0, label: '4 star (Efficient)' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:heating',
          condition: { 'string-eq': 'air-conditioning' },
        },
      },
    },
  },
  {
    key: 'heating-eer',
    family: 'settings:kinesis:heating-eer',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Heating energy efficiency rating (EER)',
    group: 'Heating/Cooling',
    settingType: {
      type: 'float64-numeric',
      default: 3.0,
      minimum: 0,
      unit: { type: 'kw-therm-per-kw' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:heating',
          condition: { 'string-eq': 'eer' },
        },
      },
    },
  },
  {
    key: 'heat-pump-eer',
    family: 'settings:kinesis:heat-pump-eer',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Heat pump energy efficiency rating (EER)',
    group: 'Heating/Cooling',
    settingType: {
      type: 'float64-numeric',
      unit: { type: 'kw-therm-per-kw' },
      default: 4.0,
      minimum: 0,
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:heating',
          condition: { 'string-eq': 'heat-pump' },
        },
      },
    },
  },
  {
    key: 'cooling-ac',
    family: 'settings:kinesis:cooling-ac',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Cooling star rating',
    group: 'Heating/Cooling',
    settingType: {
      type: 'float64-select',
      options: [
        { value: 2.5, label: '2.5 star (Standard)', priority: 0 },
        { value: 3.0, label: '3 star' },
        { value: 3.5, label: '3.5 star' },
        { value: 4.0, label: '4 star (Efficient)' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:cooling',
          condition: { 'string-eq': 'air-conditioning' },
        },
      },
    },
  },
  {
    key: 'ac-with-ceiling-fans',
    family: 'settings:kinesis:ac-with-ceiling-fans',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Ceiling fans',
    group: 'Heating/Cooling',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No', priority: 0 },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:cooling',
          condition: { 'string-eq': 'air-conditioning' },
        },
      },
    },
  },
  {
    key: 'heat-pump-cop',
    family: 'settings:kinesis:heat-pump-cop',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Heat pump coefficient of performance (COP)',
    group: 'Heating/Cooling',
    settingType: {
      type: 'float64-numeric',
      default: 7.2,
      minimum: 0.8,
      maximum: 8,
      unit: { type: 'kw-therm-per-kw' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:cooling',
          condition: { 'string-eq': 'heat-pump' },
        },
      },
    },
  },
  {
    key: 'cooling-cop',
    family: 'settings:kinesis:cooling-cop',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Cooling coefficient of performance (COP)',
    group: 'Heating/Cooling',
    settingType: {
      type: 'float64-numeric',
      default: 5,
      minimum: 0,
      maximum: 8,
      unit: { type: 'kw-therm-per-kw' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:cooling',
          condition: { 'string-eq': 'cop' },
        },
      },
    },
  },
  {
    key: 'hot-water',
    family: 'settings:kinesis:hot-water',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Hot water',
    group: 'Heating/Cooling',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'gas-instant', label: 'Gas instantaneous', priority: 0 },
        { value: 'solar-gas-boost', label: 'Solar with gas boost' },
        { value: 'heat-pump', label: 'Electric heat pump' },
        { value: 'controlled-load', label: 'Controlled load hot water' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
    },
  },
  {
    key: 'solar-hot-water-contribution',
    family: 'settings:kinesis:solar-hot-water-contribution',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Solar hot water contribution',
    group: 'Heating/Cooling',
    settingType: {
      type: 'float64-numeric',
      default: 0.6,
      minimum: 0,
      maximum: 1,
      unit: { type: 'proportion' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:hot-water',
          condition: { 'string-eq': 'solar-gas-boost' },
        },
      },
    },
  },
  // Non-res
  {
    attribute: 'attribute:kinesis:usage-v2',
    key: 'equipment',
    family: 'settings:kinesis:equipment',
    label: 'Equipment',
    group: 'Appliances',
    settingType: {
      type: 'string-select',
      options: [{ value: 'standard', label: 'Standard', priority: 0 }],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'fabric',
    family: 'settings:kinesis:fabric',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Fabric',
    group: 'HVAC',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'standard', label: 'Standard', priority: 0 },
        { value: 'efficient', label: 'Efficient' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'aquatic-centre' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'cop',
    family: 'settings:kinesis:cop',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Coefficient of performance (COP)',
    group: 'HVAC',
    settingType: {
      type: 'float64-numeric',
      default: 5.0,
      minimum: 0.8,
      maximum: 8,
      unit: { type: 'kw-therm-per-kw' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'aquatic-centre' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'water-cooled-proportion-setting',
    family: 'settings:kinesis:water-cooled-proportion-setting',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Water cooled proportion setting',
    group: 'HVAC',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'default', label: 'Default', priority: 0 },
        { value: 'custom', label: 'Custom' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'aquatic-centre' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'water-cooled-proportion',
    family: 'settings:kinesis:water-cooled-proportion',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Water cooled proportion',
    group: 'HVAC',
    settingType: {
      type: 'float64-numeric',
      default: 0,
      minimum: 0,
      maximum: 1,
      unit: { type: 'proportion' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:water-cooled-proportion-setting',
          condition: { 'string-eq': 'custom' },
        },
      },
    },
  },
  {
    key: 'eer',
    family: 'settings:kinesis:eer',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Energy efficiency rating (EER)',
    group: 'HVAC',
    settingType: {
      type: 'float64-numeric',
      default: 3.0,
      minimum: 0.8,
      maximum: 8.0,
      unit: { type: 'kw-therm-per-kw' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'aquatic-centre' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'non-residential-hot-water',
    family: 'settings:kinesis:non-residential-hot-water',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Hot water',
    group: 'Heating/Cooling',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'non-residential-gas-instant',
          label: 'Gas instantaneous',
          priority: 0,
        },
        { value: 'non-residential-electric', label: 'Electric' },
        { value: 'non-residential-heat-pump', label: 'Electric heat pump' },
        {
          value: 'non-residential-geothermal-heat-pump',
          label: 'Geothermal heat pump',
        },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'geothermal-hot-water-contribution',
    family: 'settings:kinesis:geothermal-hot-water-contribution',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Geothermal hot water contribution',
    group: 'Heating/Cooling',
    settingType: {
      type: 'float64-numeric',
      default: 0.6,
      minimum: 0,
      maximum: 1,
      unit: { type: 'proportion' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:non-residential-hot-water',
          condition: { 'string-eq': 'non-residential-geothermal-heat-pump' },
        },
      },
    },
  },
  // Irrigation
  {
    attribute: 'attribute:kinesis:usage-v2',
    key: 'irrigation',
    family: 'settings:kinesis:irrigation',
    label: 'Irrigation',
    group: 'Appliances',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'standard', label: 'Standard', priority: 0 },
        { value: 'economic', label: 'Economic best practice' },
        { value: 'high', label: 'High efficiency' },
      ],
    },
    constraints: {
      'item-constraint': {
        any: [
          { 'item-type-is': 'floor-space-and-dwellings' },
          { 'item-type-is': 'floor-space' },
        ],
      },
      'setting-constraint': {
        any: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'residential-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'detached' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'attached' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'water_efficiency',
    family: 'settings:kinesis:water_efficiency',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Water efficiency',
    group: 'Appliances',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'standard', label: 'Standard', priority: 0 },
        { value: 'economic', label: 'Economic best practice' },
        { value: 'high', label: 'High efficiency' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'lighting_efficiency',
    family: 'settings:kinesis:lighting_efficiency',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Lighting efficiency',
    group: 'Appliances',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'standard', label: 'Standard', priority: 0 },
        { value: 'efficient', label: 'High efficiency' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'car_park_natural_lit_percentage',
    family: 'settings:kinesis:car_park_natural_lit_percentage',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Naturally lit proportion',
    group: 'Car park',
    settingType: {
      type: 'float64-numeric',
      default: 0,
      minimum: 0,
      maximum: 1,
      unit: { type: 'proportion' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'detached' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'attached' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'car_park_natural_ventilation_percentage',
    family: 'settings:kinesis:car_park_natural_ventilation_percentage',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Naturally ventilated proportion',
    group: 'Car park',
    settingType: {
      type: 'float64-numeric',
      default: 0,
      minimum: 0,
      maximum: 1,
      unit: { type: 'proportion' },
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'detached' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'attached' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'car_park_light_motion_sensor',
    family: 'settings:kinesis:car_park_light_motion_sensor',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Motion sensor',
    group: 'Car park',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No', priority: 0 },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'detached' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'attached' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'car_park_ventilation_co_sensor',
    family: 'settings:kinesis:car_park_ventilation_co_sensor',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Carbon monoxide sensor',
    group: 'Car park',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No', priority: 0 },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space-and-dwellings' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'detached' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'attached' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'has-jobs',
    family: 'settings:kinesis:has-jobs',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Provides jobs',
    group: 'Employment',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No', priority: 0 },
      ],
    },
    constraints: { 'item-constraint': { 'item-type-is': 'floor-space' } },
  },
  {
    key: 'sqm-per-worker',
    family: 'settings:kinesis:sqm-per-worker',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Square metres per worker',
    group: 'Employment',
    settingType: {
      type: 'float64-numeric',
      unit: { type: 'sqm' },
      default: 35,
      minimum: 1,
      maximum: 200,
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        'setting-has-value': {
          key: 'setting:kinesis:has-jobs',
          condition: { 'string-neq': 'no' },
        },
      },
    },
  },
  {
    key: 'toilet-connection',
    family: 'settings:kinesis:toilet-connection',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Toilet connection',
    group: 'End use connections',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'non-potable',
          label: 'Non-potable water connection',
          priority: 0,
        },
        { value: 'potable', label: 'Drinking water connection' },
      ],
    },
    constraints: {
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'clotheswasher-connection',
    family: 'settings:kinesis:clotheswasher-connection',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Clotheswasher connection',
    group: 'End use connections',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'non-potable',
          label: 'Non-potable water connection',
          priority: 0,
        },
        { value: 'potable', label: 'Drinking water connection' },
      ],
    },
    constraints: {
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'cooling-towers-connection',
    family: 'settings:kinesis:cooling-towers-connection',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Cooling towers connection',
    group: 'End use connections',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'non-potable',
          label: 'Non-potable water connection',
          priority: 0,
        },
        { value: 'potable', label: 'Drinking water connection' },
      ],
    },
    constraints: {
      'item-constraint': { 'item-type-is': 'floor-space' },
      'setting-constraint': {
        all: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'aquatic-centre' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'enclosed-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'open-air-carpark' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-neq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
  {
    key: 'irrigation-connection',
    family: 'settings:kinesis:irrigation-connection',
    attribute: 'attribute:kinesis:usage-v2',
    label: 'Irrigation connection',
    group: 'End use connections',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'non-potable',
          label: 'Non-potable water connection',
          priority: 0,
        },
        { value: 'potable', label: 'Drinking water connection' },
      ],
    },
    constraints: {
      'setting-constraint': {
        any: [
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'detached' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'attached' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'public-garden' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'sports-field' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'public-plaza' },
            },
          },
          {
            'setting-has-value': {
              key: 'setting:kinesis:space-type',
              condition: { 'string-eq': 'residential-garden' },
            },
          },
        ],
      },
    },
  },
];

export const SERVICE_SPECIFICATIONS = [
  {
    key: 'live-curve',
    family: 'settings:kinesis:live-curve',
    attribute: 'attribute:kinesis:service-v2',
    label: 'Live curve',
    group: 'Live/Work/Play',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'base',
          label: 'Camellia value uplift curve',
        },
        {
          value: 'attractions',
          label: 'Max value up to 30min drive',
        },
        {
          value: 'drive',
          label: 'Max value up to 5min drive',
        },
        {
          value: 'walk400',
          label: 'Max value up to 400m walk',
        },
        {
          value: 'walk800',
          label: 'Max value up to 800m walk',
        },
        {
          value: 'transit',
          label: 'Max value up to 15min on PT',
        },
      ],
    },
  },
  {
    key: 'play-curve',
    family: 'settings:kinesis:play-curve',
    attribute: 'attribute:kinesis:service-v2',
    label: 'Play curve',
    group: 'Live/Work/Play',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'base',
          label: 'Camellia value uplift curve',
        },
        {
          value: 'attractions',
          label: 'Max value up to 30min drive',
        },
        {
          value: 'drive',
          label: 'Max value up to 5min drive',
        },
        {
          value: 'walk400',
          label: 'Max value up to 400m walk',
        },
        {
          value: 'walk800',
          label: 'Max value up to 800m walk',
        },
        {
          value: 'transit',
          label: 'Max value up to 15min on PT',
        },
      ],
    },
  },
  {
    key: 'work-curve',
    family: 'settings:kinesis:work-curve',
    attribute: 'attribute:kinesis:service-v2',
    label: 'Work curve',
    group: 'Live/Work/Play',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'base',
          label: 'Camellia value uplift curve',
        },
        {
          value: 'attractions',
          label: 'Max value up to 30min drive',
        },
        {
          value: 'drive',
          label: 'Max value up to 5min drive',
        },
        {
          value: 'walk400',
          label: 'Max value up to 400m walk',
        },
        {
          value: 'walk800',
          label: 'Max value up to 800m walk',
        },
        {
          value: 'transit',
          label: 'Max value up to 15min on PT',
        },
      ],
    },
  },
  {
    key: 'benchmark-type',
    family: 'settings:kinesis:benchmark-type',
    attribute: 'attribute:kinesis:service-v2',
    label: 'Benchmark type',
    group: 'Live/Work/Play',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'building',
          label: 'Building',
        },
        {
          value: 'precinct',
          label: 'Precinct',
        },
      ],
    },
  },
  {
    key: 'requirement-per-100000',
    family: 'settings:kinesis:requirement-per-100000',
    attribute: 'attribute:kinesis:service-v2',
    label: 'Requirement per 100000',
    group: 'Live/Work/Play',
    settingType: {
      type: 'int64-numeric',
      default: 0,
      minimum: 0,
      unit: { type: 'ratio' },
    },
  },
  {
    key: 'applicable-cohort',
    family: 'settings:kinesis:applicable-cohort',
    attribute: 'attribute:kinesis:service-v2',
    label: 'Applicable cohort',
    group: 'Live/Work/Play',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'all-residents',
          label: 'All residents',
        },
        {
          value: 'childcare-aged-kids',
          label: 'Childcare-aged kids',
        },
        {
          value: 'primary-school-aged-kids',
          label: 'Primary school-aged kids',
        },
        {
          value: 'secondary-school-aged-kids',
          label: 'Secondary school-aged kids',
        },
        {
          value: 'adults',
          label: 'Adults',
        },
        {
          value: 'older-people',
          label: 'Older people',
        },
      ],
    },
  },
  {
    key: 'uplift-category',
    family: 'settings:kinesis:uplift-category',
    attribute: 'attribute:kinesis:service-v2',
    label: 'Uplift category',
    group: 'Live/Work/Play',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'community-facilities',
          label: 'Community facilities',
        },
        {
          value: 'educational-facilities',
          label: 'Educational facilities',
        },
        {
          value: 'entertainment-facilities',
          label: 'Entertainment facilities',
        },
        {
          value: 'health-facilities',
          label: 'Health facilities',
        },
        {
          value: 'outdoor-facilities',
          label: 'Outdoor facilities',
        },
        {
          value: 'public-transport',
          label: 'Public transport',
        },
        {
          value: 'retail',
          label: 'Retail',
        },
        {
          value: 'sports-and-recreation-facilities',
          label: 'Sports & recreation facilities',
        },
      ],
    },
  },
  {
    key: 'uplift-curve',
    family: 'settings:kinesis:uplift-curve',
    attribute: 'attribute:kinesis:service-v2',
    label: 'Uplift curve',
    group: 'Live/Work/Play',
    settingType: {
      type: 'string-select',
      options: [
        {
          value: 'base',
          label: 'Camellia value uplift curve',
        },
        {
          value: 'attractions',
          label: 'Max value up to 30min drive',
        },
        {
          value: 'drive',
          label: 'Max value up to 5min drive',
        },
        {
          value: 'walk400',
          label: 'Max value up to 400m walk',
        },
        {
          value: 'walk800',
          label: 'Max value up to 800m walk',
        },
        {
          value: 'transit',
          label: 'Max value up to 15min on PT',
        },
      ],
    },
  },
  {
    key: 'uplift',
    family: 'settings:kinesis:uplift',
    attribute: 'attribute:kinesis:service-v2',
    label: 'Uplift',
    group: 'Live/Work/Play',
    settingType: {
      type: 'int64-numeric',
      default: 0,
      minimum: 0,
      unit: { type: 'currency', currency: 'AUD' },
    },
  },
];

// NOTE: Unsure if these should go.
// They aren't being used, they seem to intermix the notion of
// an attribute specification with a setting specification (as was the
// case for the other SPECIFICATION definitions here that had settings).
export const GEOGRAPHY_SPECIFICATION = {
  key: 'geography',
  attribute: 'geography',
  label: 'Position',
};

export const LAND_AREA_SPECIFICATION = {
  key: 'land',
  attribute: 'land',
  label: 'Land area',
};

export const SOLAR_SPECIFICATION = {
  key: 'solar',
  attribute: 'solar',
  label: 'Solar',
};

export const DEMOGRAPHY_SPECIFICATION = {
  key: 'demography',
  attribute: 'demography',
  label: 'Demography',
};

export const COHORT_PREFERENCES_SPECIFICATION = {
  key: 'cohort-preferences',
  attribute: 'cohort-preferences',
  label: 'Cohort preferences',
};

export const RAINWATER_TANK_SPECIFICATIONS = [
  {
    key: 'grade',
    deprecated: true,
    family: 'settings:kinesis:grade',
    attribute: 'attribute:kinesis:rainwater-tank',
    label: 'Output grade',
    group: 'Rainwater settings',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'non-potable', label: 'Non-potable water', priority: 0 },
        { value: 'potable', label: 'Potable water' },
      ],
    },
  },
  // {
  //   key: 'rainwater-grade',
  //   family: 'settings:kinesis:rainwater-grade',
  //   attribute: 'attribute:kinesis:rainwater-tank',
  //   label: 'Output grade',
  //   group: 'Rainwater settings',
  //   settingType: {
  //     type: 'string-select',
  //     options: [
  //       { value: 'non-potable', label: 'Non-potable water', priority: 0 },
  //       { value: 'potable', label: 'Potable water' },
  //     ],
  //   },
  // },
];

export const RECYCLED_WATER_SYSTEM_SPECIFICATIONS = [
  {
    key: 'source-water',
    family: 'settings:kinesis:source-water',
    attribute: 'attribute:kinesis:recycled-water-system',
    label: 'Source water',
    group: 'Recycled water settings',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'sewage', label: 'Sewage', priority: 0 },
        // { value: 'storm-water', label: 'Storm water' },
      ],
    },
  },
  {
    key: 'grade',
    deprecated: true,
    family: 'settings:kinesis:grade',
    attribute: 'attribute:kinesis:recycled-water-system',
    label: 'Output grade',
    group: 'Recycled water settings',
    settingType: {
      type: 'string-select',
      options: [
        { value: 'non-potable', label: 'Non-potable water', priority: 0 },
        { value: 'potable', label: 'Potable water' },
      ],
    },
  },
  // {
  //   key: 'recycled-water-grade',
  //   family: 'settings:kinesis:recycled-water-grade',
  //   attribute: 'attribute:kinesis:recycled-water-system',
  //   label: 'Output grade',
  //   group: 'Recycled water settings',
  //   settingType: {
  //     type: 'string-select',
  //     options: [
  //       { value: 'non-potable', label: 'Non-potable water', priority: 0 },
  //       { value: 'potable', label: 'Potable water' },
  //     ],
  //   },
  // },
  {
    key: 'loss-factor-proportion',
    family: 'settings:kinesis:loss-factor-proportion',
    attribute: 'attribute:kinesis:recycled-water-system',
    label: 'Loss factor proportion',
    group: 'Recycled water settings',
    settingType: {
      type: 'float64-numeric',
      default: 0.02,
      minimum: 0,
      maximum: 1,
      unit: { type: 'proportion' },
    },
  },
];

export const ATTRIBUTE_TYPE_MAP = {
  land: 'quantified',
  solar: 'quantified',
  position: 'quantified',
  'usage-v2': 'quantified-itemised',
  'service-v2': 'quantified-itemised',
  'rainwater-tank': 'quantified-itemised',
  'recycled-water-system': 'quantified-itemised',
  demography: 'selectable-itemised',
  'cohort-preferences': 'selectable-itemised',
};
