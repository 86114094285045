import styled from 'styled-components';

const PositionHover = styled('div')(({ theme, crosshair, x, y }) => ({
  color: theme.color.gray8,
  borderRadius: 4,
  paddingLeft: 4,
  paddingRight: 4,
  backgroundColor: 'white',
  position: 'absolute',
  pointerEvents: 'none',
  top: y + (crosshair ? 14 : 24),
  left: x,
  transform: 'translateX(-50%)',
  zIndex: 1,
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.sm,
  lineHeight: theme.lineHeight.sm,
  fontWeight: theme.fontWeight.normal,
}));

export default PositionHover;
