import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const layerColorSelector = createSelector(
  pipe(workspaceSelector, get(['layers', 'entities'])),
  (state, props) => get('layerId', props),
  (layers, layerId) => get([layerId, 'color'], layers),
);

export default layerColorSelector;
