import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SegmentedControl, SegmentedControlItem } from '@kinesis/bungle';

const propTypes = {
  datasetId: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['table', 'locations', 'attributes']),
  mode: PropTypes.oneOf(['map', 'data', 'grid', 'profile']),
};

const defaultProps = {
  type: undefined,
  mode: undefined,
};

const DatasetViewSelect = ({ datasetId, type, mode }) => {
  const supportsSpatialView = ['locations', 'attributes'].includes(type);
  const supportsGridView = ['locations', 'attributes'].includes(type);
  const supportsDataView = ['table'].includes(type);

  if (!type || !mode) return null;
  return (
    <SegmentedControl value={mode}>
      {supportsSpatialView && (
        <SegmentedControlItem
          as={Link}
          icon='map-outline'
          title='Spatial view'
          to={`/datasets/${datasetId}/map`}
          value='map'
        />
      )}
      {supportsDataView && (
        <SegmentedControlItem
          as={Link}
          icon='table'
          title='Data view'
          to={`/datasets/${datasetId}/data`}
          value='data'
        />
      )}
      {supportsGridView && (
        <SegmentedControlItem
          as={Link}
          icon='table'
          title='Grid view'
          to={`/datasets/${datasetId}/grid`}
          value='grid'
        />
      )}
      <SegmentedControlItem
        as={Link}
        icon='profile-view'
        title='Profile view'
        to={`/datasets/${datasetId}/profile`}
        value='profile'
      />
    </SegmentedControl>
  );
};

DatasetViewSelect.defaultProps = defaultProps;
DatasetViewSelect.propTypes = propTypes;

export { DatasetViewSelect };
