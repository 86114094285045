import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { pathwayCalculate } from 'actions/pathwayCalculate';
import { PathwayUrl } from 'data/pathways';

const usePathwayLocations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const useUpdatedLocations = true;
  const query = useMemo(
    () => PathwayUrl.new(search, useUpdatedLocations),
    [useUpdatedLocations, search],
  );
  const urlLocations = useMemo(() => query.hydrateLocations(), [query]);
  const calculate = useCallback(
    (locations, locationType, input) => {
      dispatch(
        pathwayCalculate({
          input,
          locations,
          locationType,
        }),
      );
    },
    [dispatch],
  );
  const urlUpdate = useCallback(
    (locations, locationType, input) => {
      navigate({ search: query.withLocations(locations).toString() });
      calculate(locations, locationType, input);
    },
    [navigate, query, calculate],
  );
  return [urlLocations, urlUpdate];
};

export default usePathwayLocations;
