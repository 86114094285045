import {
  __,
  filter,
  pipe,
  update,
  get,
  omitBy,
  isNil,
  mapValues,
  every,
  map,
  first,
  isEqual,
  keyBy,
} from 'lodash/fp';

const blockProcessingStates = {
  // Do not have data available.
  INITIAL: 'INITIAL',
  // Fetching with no prior data.
  LOADING_INITIAL: 'LOADING_INITIAL',
  // Fetching with previously lodaded data.
  LOADING_STALE: 'LOADING_STALE',
  // Upstream changes are pending with no locally cached results.
  PROCESSING_INITIAL: 'PROCESSING_INITIAL',
  // Upstream changes are pending with locally cached results.
  PROCESSING_STALE: 'PROCESSING_STALE',
  // Block is up to date.
  FRESH: 'FRESH',
};

// NOTE: This is an interstitial state that makes no sense on blocks.
// It will eventually be deprecated in favor of a query-centric status
// managed by a reducer. Until then, however, we need to ensure that
// we track in-flight query requests.
const queryStates = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FRESH: 'FRESH',
};

// Fix this normalization step so that things don't have to default themselves.
const normalizeState = pipe(
  update(['block'], (s) => s || blockProcessingStates.INITIAL),
  update(['query'], (s) => s || queryStates.INITIAL),
);

// NOTE: This just keeps compatibility with previous decisions for now,
// ultimately we should be aiming to remove a lot of this transformations
// such that there is a canonical representation of blocks + block state.
const normalizeBlock = (blockState, block) =>
  omitBy(isNil)({
    ...pipe(update('scenarios', mapValues(update(['state'], normalizeState))))(
      blockState,
    ),
    block,
    // FIX decide on terminology, and shift to capsule, or stick with block.
    capsule: block,

    // FIX below here is compatibility layer, need to pick through each field
    chart: get(['chart', 'type'], block),
    label: get([get('visualisation', block), 'label'], block),
    location: get([get('visualisation', block), 'location'], block),
    type: get('visualisation', block),
    blockType: get('type', block),
    x: get([get('visualisation', block), 'x'], block),
    y: get([get('visualisation', block), 'y'], block),
  });

const requiresScenarios = (blockValue) =>
  !!get([get('visualisation', blockValue), 'query', 'from'], blockValue);

// Takes a normalized block as per above.
const requiredScenariosOf = (block, currentScenarioId, all) => {
  const visualisationType = get(
    ['block', get(['block', 'visualisation'], block), 'type'],
    block,
  );
  switch (visualisationType) {
    case 'baseline-comparison':
      return filter(
        ({ isDefault, scenarioId }) =>
          isDefault || scenarioId === currentScenarioId,
        all,
      );
    case 'clustered-column':
    case 'line':
    case 'stacked-area':
      return all;
    default:
      // FIX: These fall through cases should eventually be consumed into
      // explicit case analysis above, leaving only an empty array behind here.
      if (requiresScenarios(block.block))
        return filter({ scenarioId: currentScenarioId }, all);
      return [];
  }
};

const requiredTablesOf = (blockValue) => {
  const from = get(
    [get('visualisation', blockValue), 'query', 'from'],
    blockValue,
  );
  return from ? [from] : [];
};

export const blockIconMap = {
  'spatial-bubble': 'visualisation-spatial-scatterplot',
  'spatial-hex': 'visualisation-hexagon-choropleth',
  'spatial-location': 'visualisation-choropleth',
  'spatial-location-3d': 'visualisation-choropleth',
  table: 'visualisation-table',
  'stacked-column': 'visualisation-stacked-column-chart',
  'dual-axis-stacked-column': 'visualisation-stacked-column-chart',
  'clustered-column': 'visualisation-clustered-column-chart',
  line: 'visualisation-line-chart',
  'stacked-area': 'visualisation-stacked-area-chart',
  scatter: 'visualisation-scatterplot',
  bubble: 'visualisation-scatterplot',
  indicator: 'visualisation-indicator',
  'baseline-comparison': 'visualisation-indicator',
  choropleth: 'visualisation-choropleth',
};

const keyFor = (block) => {
  switch (block.type) {
    case 'visualisation': {
      const visualisation = get('visualisation', block);
      if (visualisation === 'spatial') return `spatial-${block.spatial.type}`;
      if (visualisation === 'table') return 'table';
      return get([visualisation, 'type'], block);
    }
    case 'editable-visualisation': {
      return get('visualisation', block);
    }
    default:
      return 'Unknown block';
  }
};
const iconOf = pipe(keyFor, get(__, blockIconMap));

const staticToEditableType = (capsule) => {
  if (capsule.type === 'editable-visualisation') {
    return capsule.visualisation;
  }

  if (capsule.visualisation === 'spatial') {
    return {
      bubble: 'spatial-bubble',
      hex: 'spatial-bubble',
      location: 'spatial-bubble',
      'location-3d': 'spatial-bubble',
    }[capsule.spatial.type];
  }
  if (capsule.visualisation === 'chart') {
    return {
      'stacked-column': 'stacked-column',
      'dual-axis-stacked-column': 'stacked-column',
      'clustered-column': 'stacked-column',
      line: 'line',
      'stacked-area': 'line',
      scatter: 'scatter',
      bubble: 'scatter',
      indicator: 'indicator',
      'baseline-comparison': 'indicator',
    }[capsule.chart.type];
  }
  return 'table';
};

const blockGroup = (block) => {
  const group = get('type', block);
  switch (group) {
    case 'visualisation':
    case 'editable-visualisation':
      return 'visualisation';
    default:
      return group;
  }
};

const allSameBlockGroup = (blocks) => {
  const referenceBlockGroup = blockGroup(first(blocks));
  return pipe(map(blockGroup), every(isEqual(referenceBlockGroup)))(blocks);
};

const aggregations = [
  { key: 'sum', label: 'Sum' },
  { key: 'average', label: 'Average' },
  { key: 'min', label: 'Minimum' },
  { key: 'max', label: 'Maximum' },
];

const getActiveVersion = (blockState) =>
  get('draftVersion', blockState) || get('version', blockState);

const aggregateLabels = pipe(
  keyBy('key'),
  mapValues((aggregate) => aggregate.label),
)(aggregations);

const labelOfIdentityMeasure = get('measure');

const labelOfAggregateMeasure = (item) =>
  `${get('label', item)} of ${get('measure', item)}`;

const labelOfAggregateExpression = (aggregate, measure) => {
  const aggregateLabel = aggregateLabels[aggregate];
  return labelOfAggregateMeasure({ label: aggregateLabel, measure });
};

const labelOfExpression = (expression, table) => {
  const type = get('type', expression);
  switch (type) {
    case 'identity': {
      const column = get('column', expression);
      const label = get(['schema', column, 'label'], table);
      return label;
    }
    case 'aggregate': {
      const aggregate = get('aggregate', expression);
      const measureKey = get('measure', expression);
      const measure = get(['schema', measureKey, 'label'], table);
      return labelOfAggregateExpression(aggregate, measure);
    }
    default:
      return undefined;
  }
};

const labelOfExpressionWithColumns = (expression, columns) =>
  labelOfExpression(expression, { schema: keyBy('key', columns) });

const expressionFrom = (block) => {
  switch (keyFor(block)) {
    case 'indicator':
      return get('value', block);
    default:
      return undefined;
  }
};

const blockDataHasTime = (blockData) =>
  [
    'indicator-time-series',
    'choropleth-time-series',
    'stacked-column-time-series',
    'clustered-column-time-series',
    'line-time-series',
    'stacked-area-time-series',
  ].includes(get('type', blockData));

const labelOfMeasure = (item) => {
  const measureType = get(['value', 'type'], item);

  switch (measureType) {
    case 'identity': {
      return labelOfIdentityMeasure(item);
    }
    case 'aggregate': {
      return labelOfAggregateMeasure(item);
    }
    default:
      return undefined;
  }
};

export {
  normalizeBlock,
  requiresScenarios,
  requiredScenariosOf,
  requiredTablesOf,
  staticToEditableType,
  blockProcessingStates,
  queryStates,
  normalizeState,
  keyFor,
  iconOf,
  allSameBlockGroup,
  expressionFrom,
  labelOfExpression,
  labelOfExpressionWithColumns,
  labelOfAggregateMeasure,
  labelOfIdentityMeasure,
  labelOfMeasure,
  aggregations,
  blockDataHasTime,
  getActiveVersion,
};
