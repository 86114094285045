import { groupBy, pipe, concat, keyBy, mapValues, map } from 'lodash/fp';
const fineareas = [
  { geography: 568637, finearea: '1151617 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568638, finearea: '1151618 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568639, finearea: '1151619 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568640, finearea: '1151620 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568641, finearea: '1151621 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568642, finearea: '1151622 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568643, finearea: '1151623 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568644, finearea: '1151624 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568645, finearea: '1151625 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568646, finearea: '1151626 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568647, finearea: '1151627 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568648, finearea: '1151628 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568649, finearea: '1151629 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568650, finearea: '1151630 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568651, finearea: '1151631 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568652, finearea: '1151632 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568653, finearea: '1151633 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568654, finearea: '1151634 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568655, finearea: '1151635 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568656, finearea: '1151636 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568657, finearea: '1151637 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568658, finearea: '1151638 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568659, finearea: '1151701 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568660, finearea: '1151702 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568661, finearea: '1151703 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568662, finearea: '1151704 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568663, finearea: '1151705 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568664, finearea: '1151706 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568665, finearea: '1151707 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568666, finearea: '1151708 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568667, finearea: '1151709 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568668, finearea: '1151710 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568669, finearea: '1151711 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568670, finearea: '1151712 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568671, finearea: '1151713 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568672, finearea: '1151714 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568673, finearea: '1151715 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568674, finearea: '1151716 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568675, finearea: '1151717 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568676, finearea: '1151718 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568677, finearea: '1151719 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568678, finearea: '1151720 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568679, finearea: '1151721 _ Fairfield (C)', subregion: 'Greenfield Park - Prairiewood', region: 'South and West Sydney' },
  { geography: 568680, finearea: '1151801 _ Fairfield (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 568681, finearea: '1151802 _ Fairfield (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 568682, finearea: '1151805 _ Fairfield (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 568683, finearea: '1151809 _ Fairfield (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 568684, finearea: '1151810 _ Fairfield (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 568685, finearea: '1151811 _ Fairfield (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 568686, finearea: '1151812 _ Fairfield (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 568687, finearea: '1151901 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568688, finearea: '1151902 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568689, finearea: '1151903 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568690, finearea: '1151904 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568691, finearea: '1151905 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568692, finearea: '1151906 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568693, finearea: '1151907 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568694, finearea: '1151908 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568695, finearea: '1151909 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568696, finearea: '1151910 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568697, finearea: '1151911 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568698, finearea: '1151912 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568699, finearea: '1151913 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568700, finearea: '1151914 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568701, finearea: '1151915 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568702, finearea: '1151916 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568703, finearea: '1151917 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568704, finearea: '1151918 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568705, finearea: '1151919 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568706, finearea: '1151920 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568707, finearea: '1151921 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568708, finearea: '1151922 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568709, finearea: '1151923 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568710, finearea: '1151924 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568711, finearea: '1151925 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568712, finearea: '1151926 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568713, finearea: '1151927 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568714, finearea: '1151928 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568715, finearea: '1151929 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568716, finearea: '1151930 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568717, finearea: '1151931 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568718, finearea: '1151932 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568719, finearea: '1151933 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568720, finearea: '1151934 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568721, finearea: '1151935 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568722, finearea: '1151936 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568723, finearea: '1151937 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568724, finearea: '1151938 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568725, finearea: '1151939 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568726, finearea: '1151940 _ Fairfield (C)', subregion: 'Smithfield - Wetherill Park', region: 'South and West Sydney' },
  { geography: 568727, finearea: '1152001 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568728, finearea: '1152002 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568729, finearea: '1152003 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568730, finearea: '1152004 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568731, finearea: '1152005 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568732, finearea: '1152006 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568733, finearea: '1152007 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568734, finearea: '1152008 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568735, finearea: '1152009 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568736, finearea: '1152010 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568737, finearea: '1152011 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568738, finearea: '1152012 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568739, finearea: '1152013 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568740, finearea: '1152014 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568741, finearea: '1152015 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568742, finearea: '1152016 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568743, finearea: '1152017 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568744, finearea: '1152018 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568745, finearea: '1152019 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568746, finearea: '1152020 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568747, finearea: '1152021 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568748, finearea: '1152022 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568749, finearea: '1152023 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568750, finearea: '1152024 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568751, finearea: '1152025 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568752, finearea: '1152026 _ Fairfield (C)', subregion: 'St Johns Park - Wakeley', region: 'South and West Sydney' },
  { geography: 568753, finearea: '1152101 _ Fairfield (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 568754, finearea: '1118001 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568755, finearea: '1118002 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568756, finearea: '1118003 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568757, finearea: '1118004 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568758, finearea: '1118005 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568759, finearea: '1118006 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568760, finearea: '1118007 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568761, finearea: '1118008 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568762, finearea: '1118009 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568763, finearea: '1118010 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568764, finearea: '1118011 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568765, finearea: '1118012 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568766, finearea: '1118013 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568767, finearea: '1118014 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568768, finearea: '1118015 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568769, finearea: '1118016 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568770, finearea: '1118017 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568771, finearea: '1118018 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568772, finearea: '1118019 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568773, finearea: '1118020 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568774, finearea: '1118021 _ Federation (A)', subregion: 'Corowa', region: 'Murray' },
  { geography: 568775, finearea: '1118101 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568776, finearea: '1118102 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568777, finearea: '1118103 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568778, finearea: '1118104 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568779, finearea: '1118106 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568780, finearea: '1118107 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568781, finearea: '1118108 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568782, finearea: '1118109 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568783, finearea: '1118110 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568784, finearea: '1118111 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568785, finearea: '1118112 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568786, finearea: '1118113 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568787, finearea: '1118114 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568788, finearea: '1118115 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568789, finearea: '1118116 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568790, finearea: '1118117 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568791, finearea: '1118118 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568792, finearea: '1118119 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568793, finearea: '1118120 _ Federation (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 568794, finearea: '1125915 _ Federation (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 568795, finearea: '1106216 _ Forbes (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 568796, finearea: '1106217 _ Forbes (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 568797, finearea: '1106501 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568798, finearea: '1106502 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568799, finearea: '1106503 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568800, finearea: '1106504 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568801, finearea: '1106505 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568802, finearea: '1106506 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568803, finearea: '1106507 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568804, finearea: '1106508 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568805, finearea: '1106509 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568806, finearea: '1106510 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568807, finearea: '1106511 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568808, finearea: '1106512 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568809, finearea: '1106513 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568810, finearea: '1106514 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568811, finearea: '1106517 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568812, finearea: '1106518 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568813, finearea: '1106519 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568814, finearea: '1106520 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568815, finearea: '1106521 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568816, finearea: '1106522 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568817, finearea: '1106523 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568818, finearea: '1106524 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568819, finearea: '1106525 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568820, finearea: '1106526 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568821, finearea: '1106527 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568822, finearea: '1106528 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568823, finearea: '1106529 _ Forbes (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 568824, finearea: '1106801 _ Forbes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 568825, finearea: '1106807 _ Forbes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 568826, finearea: '1136429 _ Georges River (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 568827, finearea: '1136801 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568828, finearea: '1136802 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568829, finearea: '1136803 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568830, finearea: '1136804 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568831, finearea: '1136805 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568832, finearea: '1136806 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568833, finearea: '1136807 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568834, finearea: '1136808 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568835, finearea: '1136809 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568836, finearea: '1136810 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 578128, finearea: '1128104 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 578129, finearea: '1128105 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 578130, finearea: '1128106 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 578131, finearea: '1128107 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 578132, finearea: '1128108 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 578133, finearea: '1128109 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 578134, finearea: '1128110 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 578135, finearea: '1128201 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578136, finearea: '1128202 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578137, finearea: '1128203 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578138, finearea: '1128204 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578139, finearea: '1128205 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578140, finearea: '1128206 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578141, finearea: '1128207 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578142, finearea: '1128208 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578143, finearea: '1128209 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578144, finearea: '1128210 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578145, finearea: '1128211 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578146, finearea: '1128212 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578147, finearea: '1128213 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578148, finearea: '1128214 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578149, finearea: '1128215 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578150, finearea: '1128216 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578151, finearea: '1128217 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578152, finearea: '1128218 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578153, finearea: '1128219 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578154, finearea: '1128220 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578155, finearea: '1128221 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578156, finearea: '1128222 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578157, finearea: '1128223 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578158, finearea: '1128224 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578159, finearea: '1128225 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578160, finearea: '1128226 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578161, finearea: '1128227 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578162, finearea: '1128228 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578163, finearea: '1128229 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578164, finearea: '1128230 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578165, finearea: '1128231 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578166, finearea: '1128232 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578167, finearea: '1128233 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578168, finearea: '1128234 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578169, finearea: '1128235 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578170, finearea: '1128236 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578171, finearea: '1128237 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578172, finearea: '1128238 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578173, finearea: '1128239 _ Shoalhaven (C)', subregion: 'Ulladulla', region: 'Illawarra-Shoalhaven' },
  { geography: 578174, finearea: '1128301 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578175, finearea: '1128302 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578176, finearea: '1128303 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578177, finearea: '1128304 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578178, finearea: '1128305 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578179, finearea: '1128306 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578180, finearea: '1128307 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578181, finearea: '1128308 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578182, finearea: '1128309 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578183, finearea: '1128310 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578184, finearea: '1128311 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578185, finearea: '1128312 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578186, finearea: '1128313 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578187, finearea: '1128314 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578188, finearea: '1128315 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578189, finearea: '1128316 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578190, finearea: '1128317 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 578191, finearea: '1107501 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578192, finearea: '1110704 _ Singleton (A)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 578193, finearea: '1110705 _ Singleton (A)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 578194, finearea: '1110716 _ Singleton (A)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 578195, finearea: '1110717 _ Singleton (A)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 578196, finearea: '1110718 _ Singleton (A)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 578197, finearea: '1110719 _ Singleton (A)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 578198, finearea: '1111201 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578199, finearea: '1111202 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578200, finearea: '1111203 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578201, finearea: '1111204 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578202, finearea: '1111205 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578203, finearea: '1111206 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578204, finearea: '1111207 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578205, finearea: '1111208 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578206, finearea: '1111209 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578207, finearea: '1111210 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578208, finearea: '1111211 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578209, finearea: '1111212 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578210, finearea: '1111213 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578211, finearea: '1111214 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578212, finearea: '1111215 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578213, finearea: '1111216 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578214, finearea: '1111217 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578215, finearea: '1111218 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578216, finearea: '1111219 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578217, finearea: '1111220 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578218, finearea: '1111221 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578219, finearea: '1111222 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578220, finearea: '1111223 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578221, finearea: '1111224 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578222, finearea: '1111225 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578223, finearea: '1111226 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578224, finearea: '1111227 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578225, finearea: '1111228 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578226, finearea: '1111229 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578227, finearea: '1111230 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578228, finearea: '1111231 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578229, finearea: '1111232 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578230, finearea: '1111233 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578231, finearea: '1111234 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578232, finearea: '1111235 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578233, finearea: '1111237 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578234, finearea: '1111238 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578235, finearea: '1111240 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578236, finearea: '1111241 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578237, finearea: '1111242 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578238, finearea: '1111244 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578239, finearea: '1111245 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578240, finearea: '1111246 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578241, finearea: '1111247 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578242, finearea: '1111248 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578243, finearea: '1111249 _ Singleton (A)', subregion: 'Singleton', region: 'Central Coast and Hunter' },
  { geography: 578244, finearea: '1111301 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578245, finearea: '1111302 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578246, finearea: '1111303 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578247, finearea: '1111304 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578248, finearea: '1111305 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578249, finearea: '1111306 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578250, finearea: '1111307 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578251, finearea: '1111308 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578252, finearea: '1111309 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578253, finearea: '1111310 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578254, finearea: '1111311 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578255, finearea: '1111312 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578256, finearea: '1111313 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578257, finearea: '1111314 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578258, finearea: '1111315 _ Singleton (A)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 578259, finearea: '1101109 _ Snowy Monaro Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 578260, finearea: '1101110 _ Snowy Monaro Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 578261, finearea: '1101116 _ Snowy Monaro Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 578262, finearea: '1101141 _ Snowy Monaro Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 578263, finearea: '1101301 _ Snowy Monaro Regional (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 578264, finearea: '1101302 _ Snowy Monaro Regional (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 578265, finearea: '1101303 _ Snowy Monaro Regional (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 578266, finearea: '1101304 _ Snowy Monaro Regional (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 578267, finearea: '1101305 _ Snowy Monaro Regional (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 578268, finearea: '1101306 _ Snowy Monaro Regional (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 578269, finearea: '1101307 _ Snowy Monaro Regional (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 578270, finearea: '1101308 _ Snowy Monaro Regional (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 578271, finearea: '1101401 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578272, finearea: '1101402 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578273, finearea: '1101403 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578274, finearea: '1101404 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578275, finearea: '1101405 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578276, finearea: '1101406 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578277, finearea: '1101407 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578278, finearea: '1101408 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578279, finearea: '1101409 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578280, finearea: '1101410 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578281, finearea: '1101411 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578282, finearea: '1101412 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578283, finearea: '1101413 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578284, finearea: '1101414 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578285, finearea: '1101415 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578286, finearea: '1101417 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578287, finearea: '1101418 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578288, finearea: '1101419 _ Snowy Monaro Regional (A)', subregion: 'Cooma', region: 'Capital Region and South Coast' },
  { geography: 578289, finearea: '1101501 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578290, finearea: '1101502 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578291, finearea: '1101503 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578292, finearea: '1101505 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578293, finearea: '1101511 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578294, finearea: '1101512 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578295, finearea: '1101514 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578296, finearea: '1101515 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578297, finearea: '1101516 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578298, finearea: '1101517 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578299, finearea: '1101518 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578300, finearea: '1101519 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578301, finearea: '1101520 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578302, finearea: '1101521 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578303, finearea: '1101522 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578304, finearea: '1101523 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578305, finearea: '1101524 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 578306, finearea: '1101601 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578307, finearea: '1101602 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578308, finearea: '1101603 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578309, finearea: '1101604 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578310, finearea: '1101605 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578311, finearea: '1101606 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578312, finearea: '1101607 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578313, finearea: '1101608 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578314, finearea: '1101609 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578315, finearea: '1101610 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578316, finearea: '1101611 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578317, finearea: '1101612 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578318, finearea: '1101613 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578319, finearea: '1101614 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578320, finearea: '1101615 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578321, finearea: '1101616 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578322, finearea: '1101617 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578323, finearea: '1101618 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578324, finearea: '1101619 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578325, finearea: '1101620 _ Snowy Monaro Regional (A)', subregion: 'Jindabyne - Berridale', region: 'Capital Region and South Coast' },
  { geography: 578326, finearea: '1102010 _ Snowy Monaro Regional (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 578327, finearea: '1102201 _ Snowy Monaro Regional (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 576928, finearea: '1134818 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576929, finearea: '1134819 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576930, finearea: '1134820 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576931, finearea: '1134821 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576932, finearea: '1134822 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576933, finearea: '1134823 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576934, finearea: '1134824 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576935, finearea: '1134825 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576936, finearea: '1134826 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576937, finearea: '1134827 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576938, finearea: '1134828 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576939, finearea: '1134829 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576940, finearea: '1134830 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576941, finearea: '1134831 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576942, finearea: '1134832 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576943, finearea: '1134833 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576944, finearea: '1134834 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576945, finearea: '1134835 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576946, finearea: '1134836 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576947, finearea: '1134837 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576948, finearea: '1134838 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576949, finearea: '1134839 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576950, finearea: '1134840 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576951, finearea: '1134841 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576952, finearea: '1134842 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576953, finearea: '1134843 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576954, finearea: '1134844 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576955, finearea: '1134845 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576956, finearea: '1134846 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576957, finearea: '1134847 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576958, finearea: '1134848 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576959, finearea: '1134849 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576960, finearea: '1134850 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576961, finearea: '1134851 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576962, finearea: '1134852 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576963, finearea: '1134853 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576964, finearea: '1134854 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576965, finearea: '1134855 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576966, finearea: '1134856 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576967, finearea: '1134857 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576968, finearea: '1134858 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576969, finearea: '1134859 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576970, finearea: '1134860 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576971, finearea: '1135001 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576972, finearea: '1135002 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576973, finearea: '1135003 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576974, finearea: '1135004 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576975, finearea: '1135006 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576976, finearea: '1135007 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576977, finearea: '1135008 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576978, finearea: '1135009 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576979, finearea: '1135010 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576980, finearea: '1135011 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576981, finearea: '1135012 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576982, finearea: '1135013 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576983, finearea: '1135014 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576984, finearea: '1135015 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576985, finearea: '1135017 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576986, finearea: '1135018 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576987, finearea: '1135019 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576988, finearea: '1135020 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576989, finearea: '1135021 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576990, finearea: '1135022 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576991, finearea: '1135023 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576992, finearea: '1135024 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576993, finearea: '1135025 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576994, finearea: '1135026 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576995, finearea: '1135027 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576996, finearea: '1135028 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576997, finearea: '1135029 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576998, finearea: '1135030 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 576999, finearea: '1135031 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577000, finearea: '1135032 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577001, finearea: '1135033 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577002, finearea: '1135034 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577003, finearea: '1135035 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577004, finearea: '1135036 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577005, finearea: '1135037 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577006, finearea: '1135038 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577007, finearea: '1135039 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577008, finearea: '1135040 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577009, finearea: '1135041 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577010, finearea: '1135042 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577011, finearea: '1135043 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577012, finearea: '1135044 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577013, finearea: '1135047 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577014, finearea: '1135048 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577015, finearea: '1135049 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577016, finearea: '1135050 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577017, finearea: '1135051 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577018, finearea: '1135052 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577019, finearea: '1135053 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577020, finearea: '1135054 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577021, finearea: '1135055 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577022, finearea: '1135056 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577023, finearea: '1135057 _ Randwick (C)', subregion: 'Malabar - La Perouse - Chifley', region: 'Inner Sydney' },
  { geography: 577024, finearea: '1156401 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577025, finearea: '1156402 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577026, finearea: '1156403 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577027, finearea: '1156404 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577028, finearea: '1156405 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577029, finearea: '1156406 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577030, finearea: '1156407 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577031, finearea: '1156408 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577032, finearea: '1156409 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577033, finearea: '1156410 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577034, finearea: '1156411 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577035, finearea: '1156412 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577036, finearea: '1156413 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577037, finearea: '1156414 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577038, finearea: '1156415 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577039, finearea: '1156416 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577040, finearea: '1156417 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577041, finearea: '1156418 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577042, finearea: '1156419 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577043, finearea: '1156420 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577044, finearea: '1156421 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577045, finearea: '1156422 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577046, finearea: '1156423 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577047, finearea: '1156424 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577048, finearea: '1156425 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577049, finearea: '1156426 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577050, finearea: '1156427 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577051, finearea: '1156428 _ Randwick (C)', subregion: 'Kensington (NSW)', region: 'Inner Sydney' },
  { geography: 577052, finearea: '1156501 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577053, finearea: '1156502 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577054, finearea: '1156503 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577055, finearea: '1156504 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577056, finearea: '1156505 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577057, finearea: '1156506 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577058, finearea: '1156507 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577059, finearea: '1156508 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577060, finearea: '1156509 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577061, finearea: '1156510 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577062, finearea: '1156511 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577063, finearea: '1156512 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577064, finearea: '1156513 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577065, finearea: '1156514 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577066, finearea: '1156515 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577067, finearea: '1156516 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577068, finearea: '1156517 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577069, finearea: '1156518 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577070, finearea: '1156519 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577071, finearea: '1156520 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577072, finearea: '1156521 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577073, finearea: '1156522 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577074, finearea: '1156523 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577075, finearea: '1156524 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577076, finearea: '1156525 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577077, finearea: '1156526 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577078, finearea: '1156527 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577079, finearea: '1156528 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577080, finearea: '1156529 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577081, finearea: '1156530 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577082, finearea: '1156531 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577083, finearea: '1156532 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577084, finearea: '1156533 _ Randwick (C)', subregion: 'Kingsford', region: 'Inner Sydney' },
  { geography: 577085, finearea: '1156601 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577086, finearea: '1156602 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577087, finearea: '1156603 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577088, finearea: '1156604 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577089, finearea: '1156605 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577090, finearea: '1156606 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577091, finearea: '1156607 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577092, finearea: '1156608 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577093, finearea: '1156609 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577094, finearea: '1156610 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577095, finearea: '1156611 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577096, finearea: '1156612 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577097, finearea: '1156613 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577098, finearea: '1156614 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577099, finearea: '1156615 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577100, finearea: '1156616 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577101, finearea: '1156617 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577102, finearea: '1156618 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577103, finearea: '1156619 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577104, finearea: '1156620 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577105, finearea: '1156621 _ Randwick (C)', subregion: 'Maroubra - North', region: 'Inner Sydney' },
  { geography: 577106, finearea: '1156701 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577107, finearea: '1156702 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577108, finearea: '1156703 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577109, finearea: '1156704 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577110, finearea: '1156705 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577111, finearea: '1156706 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577112, finearea: '1156707 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577113, finearea: '1156708 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577114, finearea: '1156709 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577115, finearea: '1156710 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577116, finearea: '1156711 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577117, finearea: '1156712 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577118, finearea: '1156713 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577119, finearea: '1156714 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577120, finearea: '1156715 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577121, finearea: '1156716 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577122, finearea: '1156717 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577123, finearea: '1156718 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577124, finearea: '1156719 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577125, finearea: '1156720 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577126, finearea: '1156721 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577127, finearea: '1156722 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 567637, finearea: '1158604 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567638, finearea: '1158605 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567639, finearea: '1158606 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567640, finearea: '1158607 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567641, finearea: '1158608 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567642, finearea: '1158609 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567643, finearea: '1158610 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567644, finearea: '1158611 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567645, finearea: '1158612 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567646, finearea: '1158613 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567647, finearea: '1158614 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567648, finearea: '1158615 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567649, finearea: '1158616 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567650, finearea: '1158617 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567651, finearea: '1158618 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567652, finearea: '1158619 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567653, finearea: '1158620 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567654, finearea: '1158621 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567655, finearea: '1158622 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567656, finearea: '1158623 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567657, finearea: '1158624 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567658, finearea: '1158625 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567659, finearea: '1158626 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567660, finearea: '1158627 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567661, finearea: '1158628 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567662, finearea: '1158629 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567663, finearea: '1158630 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567664, finearea: '1158631 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567665, finearea: '1158632 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567666, finearea: '1158633 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567667, finearea: '1158634 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567668, finearea: '1158635 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567669, finearea: '1158636 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567670, finearea: '1158637 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567671, finearea: '1158638 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567672, finearea: '1158639 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567673, finearea: '1158640 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567674, finearea: '1158641 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567675, finearea: '1158642 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567676, finearea: '1158643 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567677, finearea: '1158701 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567678, finearea: '1158702 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567679, finearea: '1158703 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567680, finearea: '1158704 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567681, finearea: '1158705 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567682, finearea: '1158706 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567683, finearea: '1158709 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567684, finearea: '1158710 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567685, finearea: '1158711 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567686, finearea: '1158712 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567687, finearea: '1158713 _ Cumberland (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 567688, finearea: '1148032 _ Cumberland (A)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 567689, finearea: '1148101 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567690, finearea: '1148102 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567691, finearea: '1148103 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567692, finearea: '1148104 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567693, finearea: '1148105 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567694, finearea: '1148106 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567695, finearea: '1148107 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567696, finearea: '1148108 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567697, finearea: '1148109 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567698, finearea: '1148110 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567699, finearea: '1148111 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567700, finearea: '1148112 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567701, finearea: '1148113 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567702, finearea: '1148114 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567703, finearea: '1148115 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567704, finearea: '1148116 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567705, finearea: '1148117 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567706, finearea: '1148118 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567707, finearea: '1148119 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567708, finearea: '1148120 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567709, finearea: '1148121 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567710, finearea: '1148122 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567711, finearea: '1148123 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567712, finearea: '1148124 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567713, finearea: '1148125 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567714, finearea: '1148126 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567715, finearea: '1148127 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567716, finearea: '1148128 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567717, finearea: '1148129 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567718, finearea: '1148130 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567719, finearea: '1148131 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567720, finearea: '1148132 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567721, finearea: '1148133 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567722, finearea: '1148138 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567723, finearea: '1148139 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567724, finearea: '1148140 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567725, finearea: '1148141 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567726, finearea: '1148142 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567727, finearea: '1148143 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567728, finearea: '1148145 _ Cumberland (A)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 567729, finearea: '1148202 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567730, finearea: '1148203 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567731, finearea: '1148205 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567732, finearea: '1148206 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567733, finearea: '1148207 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567734, finearea: '1148208 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567735, finearea: '1148209 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567736, finearea: '1148210 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567737, finearea: '1148211 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567738, finearea: '1148212 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567739, finearea: '1148213 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567740, finearea: '1148214 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567741, finearea: '1148215 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567742, finearea: '1148216 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567743, finearea: '1148217 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567744, finearea: '1148218 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567745, finearea: '1148219 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567746, finearea: '1148220 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567747, finearea: '1148221 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567748, finearea: '1148222 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567749, finearea: '1148223 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567750, finearea: '1148224 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567751, finearea: '1148225 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567752, finearea: '1148226 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567753, finearea: '1148227 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567754, finearea: '1148228 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567755, finearea: '1148229 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567756, finearea: '1148230 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567757, finearea: '1148231 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567758, finearea: '1148232 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567759, finearea: '1148233 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567760, finearea: '1148234 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567761, finearea: '1148235 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567762, finearea: '1148236 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567763, finearea: '1148237 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567764, finearea: '1148238 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567765, finearea: '1148239 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567766, finearea: '1148240 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567767, finearea: '1148241 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567768, finearea: '1148242 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567769, finearea: '1148243 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567770, finearea: '1148244 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567771, finearea: '1148245 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567772, finearea: '1148246 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567773, finearea: '1148247 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567774, finearea: '1148248 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567775, finearea: '1148249 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567776, finearea: '1148250 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567777, finearea: '1148251 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567778, finearea: '1148252 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567779, finearea: '1148253 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567780, finearea: '1148254 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567781, finearea: '1148255 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567782, finearea: '1148257 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567783, finearea: '1148258 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567784, finearea: '1148259 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567785, finearea: '1148260 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567786, finearea: '1148261 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567787, finearea: '1148262 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567788, finearea: '1148263 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567789, finearea: '1148264 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567790, finearea: '1148301 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567791, finearea: '1148302 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567792, finearea: '1148303 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567793, finearea: '1148304 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567794, finearea: '1148305 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567795, finearea: '1148306 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567796, finearea: '1148307 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567797, finearea: '1148308 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567798, finearea: '1148309 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567799, finearea: '1148310 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567800, finearea: '1148311 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567801, finearea: '1148312 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567802, finearea: '1148313 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567803, finearea: '1148314 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567804, finearea: '1148316 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567805, finearea: '1148317 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567806, finearea: '1148318 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567807, finearea: '1148319 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567808, finearea: '1148320 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567809, finearea: '1148321 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567810, finearea: '1148322 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567811, finearea: '1148323 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567812, finearea: '1148324 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567813, finearea: '1148325 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567814, finearea: '1148326 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567815, finearea: '1148327 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567816, finearea: '1148328 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567817, finearea: '1148329 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567818, finearea: '1148330 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567819, finearea: '1148331 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567820, finearea: '1148332 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567821, finearea: '1148333 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567822, finearea: '1148334 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567823, finearea: '1148335 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567824, finearea: '1148336 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567825, finearea: '1148337 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567826, finearea: '1148338 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567827, finearea: '1148339 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567828, finearea: '1148340 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567829, finearea: '1148341 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567830, finearea: '1148342 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567831, finearea: '1148343 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567832, finearea: '1148344 _ Cumberland (A)', subregion: 'Guildford - South Granville', region: 'South and West Sydney' },
  { geography: 567833, finearea: '1148401 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567834, finearea: '1148402 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567835, finearea: '1148403 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567836, finearea: '1148404 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 574128, finearea: '1122818 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574129, finearea: '1122819 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574130, finearea: '1122820 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574131, finearea: '1122821 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574132, finearea: '1122822 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574133, finearea: '1122823 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574134, finearea: '1122824 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574135, finearea: '1122825 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574136, finearea: '1122826 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574137, finearea: '1122827 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574138, finearea: '1122828 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574139, finearea: '1122829 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574140, finearea: '1122830 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574141, finearea: '1122831 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574142, finearea: '1122832 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574143, finearea: '1122901 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574144, finearea: '1122902 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574145, finearea: '1122903 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574146, finearea: '1122904 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574147, finearea: '1122905 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574148, finearea: '1122906 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574149, finearea: '1122907 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574150, finearea: '1122908 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574151, finearea: '1122909 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574152, finearea: '1122910 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574153, finearea: '1122911 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574154, finearea: '1122912 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574155, finearea: '1122913 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574156, finearea: '1122914 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574157, finearea: '1122915 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574158, finearea: '1122916 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574159, finearea: '1122917 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574160, finearea: '1122918 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574161, finearea: '1122919 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574162, finearea: '1122920 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574163, finearea: '1122921 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574164, finearea: '1122922 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574165, finearea: '1122923 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574166, finearea: '1122924 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574167, finearea: '1122925 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574168, finearea: '1122926 _ Newcastle (C)', subregion: 'Newcastle - Cooks Hill', region: 'Central Coast and Hunter' },
  { geography: 574169, finearea: '1123001 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574170, finearea: '1123101 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574171, finearea: '1123102 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574172, finearea: '1123103 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574173, finearea: '1123104 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574174, finearea: '1123105 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574175, finearea: '1123106 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574176, finearea: '1123107 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574177, finearea: '1123108 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574178, finearea: '1123109 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574179, finearea: '1123110 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574180, finearea: '1123111 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574181, finearea: '1123112 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574182, finearea: '1123113 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574183, finearea: '1123114 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574184, finearea: '1123115 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574185, finearea: '1123116 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574186, finearea: '1123117 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574187, finearea: '1123118 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574188, finearea: '1123119 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574189, finearea: '1123120 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574190, finearea: '1123121 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574191, finearea: '1123122 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574192, finearea: '1123123 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574193, finearea: '1123124 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574194, finearea: '1123125 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574195, finearea: '1123126 _ Newcastle (C)', subregion: 'Shortland - Jesmond', region: 'Central Coast and Hunter' },
  { geography: 574196, finearea: '1123201 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574197, finearea: '1123202 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574198, finearea: '1123203 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574199, finearea: '1123204 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574200, finearea: '1123205 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574201, finearea: '1123206 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574202, finearea: '1123207 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574203, finearea: '1123208 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574204, finearea: '1123209 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574205, finearea: '1123210 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574206, finearea: '1123211 _ Newcastle (C)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 574207, finearea: '1123301 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574208, finearea: '1123302 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574209, finearea: '1123303 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574210, finearea: '1123304 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574211, finearea: '1123305 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574212, finearea: '1123306 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574213, finearea: '1123307 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574214, finearea: '1123308 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574215, finearea: '1123309 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574216, finearea: '1123310 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574217, finearea: '1123311 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574218, finearea: '1123312 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574219, finearea: '1123313 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574220, finearea: '1123314 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574221, finearea: '1123315 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574222, finearea: '1123316 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574223, finearea: '1123317 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574224, finearea: '1123318 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574225, finearea: '1123319 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574226, finearea: '1123320 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574227, finearea: '1123321 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574228, finearea: '1123322 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574229, finearea: '1123323 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574230, finearea: '1123324 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574231, finearea: '1123325 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574232, finearea: '1123326 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574233, finearea: '1123327 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574234, finearea: '1123328 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574235, finearea: '1123329 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574236, finearea: '1123330 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574237, finearea: '1123331 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574238, finearea: '1123332 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574239, finearea: '1123333 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574240, finearea: '1123334 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574241, finearea: '1123335 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574242, finearea: '1123336 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574243, finearea: '1123337 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574244, finearea: '1123338 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574245, finearea: '1123339 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574246, finearea: '1123341 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574247, finearea: '1123342 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574248, finearea: '1123343 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574249, finearea: '1123344 _ Newcastle (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 574250, finearea: '1123401 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574251, finearea: '1123402 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574252, finearea: '1123403 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574253, finearea: '1123404 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574254, finearea: '1123405 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574255, finearea: '1123406 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574256, finearea: '1123407 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574257, finearea: '1123408 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574258, finearea: '1123409 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574259, finearea: '1123410 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574260, finearea: '1123411 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574261, finearea: '1123412 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574262, finearea: '1123413 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574263, finearea: '1123414 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574264, finearea: '1123415 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574265, finearea: '1123416 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574266, finearea: '1123417 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574267, finearea: '1123418 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574268, finearea: '1123419 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574269, finearea: '1123420 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574270, finearea: '1123421 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574271, finearea: '1123422 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574272, finearea: '1123423 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574273, finearea: '1123424 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574274, finearea: '1123425 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574275, finearea: '1123426 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574276, finearea: '1123427 _ Newcastle (C)', subregion: 'Waratah - North Lambton', region: 'Central Coast and Hunter' },
  { geography: 574277, finearea: '1123501 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574278, finearea: '1123502 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574279, finearea: '1123503 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574280, finearea: '1123504 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574281, finearea: '1123505 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574282, finearea: '1123506 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574283, finearea: '1123507 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574284, finearea: '1123508 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574285, finearea: '1123509 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574286, finearea: '1123510 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574287, finearea: '1123511 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574288, finearea: '1123512 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574289, finearea: '1123513 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574290, finearea: '1123514 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574291, finearea: '1123515 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574292, finearea: '1123516 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574293, finearea: '1123517 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574294, finearea: '1123518 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574295, finearea: '1123519 _ Newcastle (C)', subregion: 'Wickham - Carrington - Tighes Hill', region: 'Central Coast and Hunter' },
  { geography: 574296, finearea: '1140113 _ North Sydney (A)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 574297, finearea: '1140117 _ North Sydney (A)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 574298, finearea: '1140119 _ North Sydney (A)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 574299, finearea: '1140120 _ North Sydney (A)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 574300, finearea: '1140123 _ North Sydney (A)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 574301, finearea: '1140232 _ North Sydney (A)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 574302, finearea: '1141301 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574303, finearea: '1141302 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574304, finearea: '1141303 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574305, finearea: '1141304 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574306, finearea: '1141305 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574307, finearea: '1141306 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574308, finearea: '1141307 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574309, finearea: '1141308 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574310, finearea: '1141309 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574311, finearea: '1141310 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574312, finearea: '1141311 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574313, finearea: '1141312 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574314, finearea: '1141313 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574315, finearea: '1141314 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574316, finearea: '1141315 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574317, finearea: '1141316 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574318, finearea: '1141317 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574319, finearea: '1141318 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574320, finearea: '1141319 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574321, finearea: '1141320 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574322, finearea: '1141321 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574323, finearea: '1141322 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574324, finearea: '1141323 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574325, finearea: '1141324 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574326, finearea: '1141325 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574327, finearea: '1141326 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 566037, finearea: '1147913 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566038, finearea: '1147914 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566039, finearea: '1147915 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566040, finearea: '1147916 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566041, finearea: '1147917 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566042, finearea: '1147918 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566043, finearea: '1147919 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566044, finearea: '1147920 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566045, finearea: '1147921 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566046, finearea: '1147922 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566047, finearea: '1147923 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566048, finearea: '1147924 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566049, finearea: '1147925 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566050, finearea: '1147926 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566051, finearea: '1147927 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566052, finearea: '1147928 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566053, finearea: '1147929 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566054, finearea: '1147930 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566055, finearea: '1147931 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566056, finearea: '1147932 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566057, finearea: '1147933 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566058, finearea: '1147934 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566059, finearea: '1147935 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566060, finearea: '1147936 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566061, finearea: '1147937 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566062, finearea: '1147938 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566063, finearea: '1147939 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566064, finearea: '1147940 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566065, finearea: '1147941 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566066, finearea: '1147942 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566067, finearea: '1147943 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566068, finearea: '1117710 _ Carrathool (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 566069, finearea: '1125706 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566070, finearea: '1125707 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566071, finearea: '1125708 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566072, finearea: '1125709 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566073, finearea: '1125717 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566074, finearea: '1125718 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566075, finearea: '1125719 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566076, finearea: '1125720 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566077, finearea: '1125735 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566078, finearea: '1125736 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566079, finearea: '1125739 _ Carrathool (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 566080, finearea: '1102801 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566081, finearea: '1102802 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566082, finearea: '1102803 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566083, finearea: '1102804 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566084, finearea: '1102805 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566085, finearea: '1102806 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566086, finearea: '1102807 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566087, finearea: '1102808 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566088, finearea: '1102809 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566089, finearea: '1102810 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566090, finearea: '1102811 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566091, finearea: '1102812 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566092, finearea: '1102813 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566093, finearea: '1102814 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566094, finearea: '1102815 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566095, finearea: '1102816 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566096, finearea: '1102817 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566097, finearea: '1102818 _ Central Coast (C) (NSW)', subregion: 'Avoca Beach - Copacabana', region: 'Central Coast and Hunter' },
  { geography: 566098, finearea: '1102901 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566099, finearea: '1102902 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566100, finearea: '1102903 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566101, finearea: '1102904 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566102, finearea: '1102905 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566103, finearea: '1102906 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566104, finearea: '1102907 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566105, finearea: '1102908 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566106, finearea: '1102909 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566107, finearea: '1102910 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566108, finearea: '1102911 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566109, finearea: '1102912 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566110, finearea: '1102913 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566111, finearea: '1102914 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566112, finearea: '1102915 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566113, finearea: '1102916 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566114, finearea: '1102917 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566115, finearea: '1102918 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566116, finearea: '1102919 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566117, finearea: '1102920 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566118, finearea: '1102921 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566119, finearea: '1102922 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566120, finearea: '1102923 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566121, finearea: '1102924 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566122, finearea: '1102925 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566123, finearea: '1102926 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566124, finearea: '1102927 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566125, finearea: '1102928 _ Central Coast (C) (NSW)', subregion: 'Box Head - MacMasters Beach', region: 'Central Coast and Hunter' },
  { geography: 566126, finearea: '1103002 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566127, finearea: '1103003 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566128, finearea: '1103004 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566129, finearea: '1103005 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566130, finearea: '1103006 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566131, finearea: '1103007 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566132, finearea: '1103008 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566133, finearea: '1103009 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566134, finearea: '1103010 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566135, finearea: '1103011 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566136, finearea: '1103012 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566137, finearea: '1103013 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566138, finearea: '1103014 _ Central Coast (C) (NSW)', subregion: 'Calga - Kulnura', region: 'Central Coast and Hunter' },
  { geography: 566139, finearea: '1103101 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566140, finearea: '1103102 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566141, finearea: '1103103 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566142, finearea: '1103104 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566143, finearea: '1103105 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566144, finearea: '1103106 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566145, finearea: '1103107 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566146, finearea: '1103108 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566147, finearea: '1103109 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566148, finearea: '1103110 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566149, finearea: '1103111 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566150, finearea: '1103112 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566151, finearea: '1103113 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566152, finearea: '1103114 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566153, finearea: '1103115 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566154, finearea: '1103116 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566155, finearea: '1103117 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566156, finearea: '1103118 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566157, finearea: '1103119 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566158, finearea: '1103120 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566159, finearea: '1103121 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566160, finearea: '1103122 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566161, finearea: '1103123 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566162, finearea: '1103124 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566163, finearea: '1103125 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566164, finearea: '1103126 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566165, finearea: '1103127 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566166, finearea: '1103128 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566167, finearea: '1103129 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566168, finearea: '1103130 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566169, finearea: '1103131 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566170, finearea: '1103132 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566171, finearea: '1103133 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566172, finearea: '1103134 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566173, finearea: '1103135 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566174, finearea: '1103136 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566175, finearea: '1103137 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566176, finearea: '1103138 _ Central Coast (C) (NSW)', subregion: 'Erina - Green Point', region: 'Central Coast and Hunter' },
  { geography: 566177, finearea: '1103201 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566178, finearea: '1103202 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566179, finearea: '1103203 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566180, finearea: '1103204 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566181, finearea: '1103205 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566182, finearea: '1103206 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566183, finearea: '1103207 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566184, finearea: '1103208 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566185, finearea: '1103210 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566186, finearea: '1103211 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566187, finearea: '1103212 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566188, finearea: '1103213 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566189, finearea: '1103214 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566190, finearea: '1103215 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566191, finearea: '1103216 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566192, finearea: '1103217 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566193, finearea: '1103218 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566194, finearea: '1103219 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566195, finearea: '1103220 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566196, finearea: '1103221 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566197, finearea: '1103223 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566198, finearea: '1103224 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566199, finearea: '1103225 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566200, finearea: '1103226 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566201, finearea: '1103227 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566202, finearea: '1103228 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566203, finearea: '1103229 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566204, finearea: '1103230 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566205, finearea: '1103231 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566206, finearea: '1103232 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566207, finearea: '1103233 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566208, finearea: '1103234 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566209, finearea: '1103235 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566210, finearea: '1103236 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566211, finearea: '1103237 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566212, finearea: '1103238 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566213, finearea: '1103239 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566214, finearea: '1103240 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566215, finearea: '1103241 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566216, finearea: '1103242 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566217, finearea: '1103243 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566218, finearea: '1103244 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566219, finearea: '1103245 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566220, finearea: '1103246 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566221, finearea: '1103247 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566222, finearea: '1103248 _ Central Coast (C) (NSW)', subregion: 'Gosford - Springfield', region: 'Central Coast and Hunter' },
  { geography: 566223, finearea: '1103301 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566224, finearea: '1103302 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566225, finearea: '1103303 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566226, finearea: '1103304 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566227, finearea: '1103305 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566228, finearea: '1103306 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566229, finearea: '1103307 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566230, finearea: '1103308 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566231, finearea: '1103309 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566232, finearea: '1103310 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566233, finearea: '1103311 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566234, finearea: '1103312 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566235, finearea: '1103313 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566236, finearea: '1103314 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 565037, finearea: '1144430 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565038, finearea: '1144431 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565039, finearea: '1144432 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565040, finearea: '1144433 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565041, finearea: '1144434 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565042, finearea: '1144435 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565043, finearea: '1144436 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565044, finearea: '1144437 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565045, finearea: '1144438 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565046, finearea: '1144439 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565047, finearea: '1144440 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565048, finearea: '1144441 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565049, finearea: '1144442 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565050, finearea: '1144443 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565051, finearea: '1144444 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565052, finearea: '1144445 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565053, finearea: '1144446 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565054, finearea: '1144447 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565055, finearea: '1144448 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565056, finearea: '1144449 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565057, finearea: '1144450 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565058, finearea: '1144451 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565059, finearea: '1144452 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565060, finearea: '1144453 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565061, finearea: '1144454 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565062, finearea: '1144455 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565063, finearea: '1144456 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565064, finearea: '1138301 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565065, finearea: '1138302 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565066, finearea: '1138303 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565067, finearea: '1138304 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565068, finearea: '1138305 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565069, finearea: '1138306 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565070, finearea: '1138307 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565071, finearea: '1138308 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565072, finearea: '1138309 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565073, finearea: '1138310 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565074, finearea: '1138311 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565075, finearea: '1138312 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565076, finearea: '1138313 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565077, finearea: '1138314 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565078, finearea: '1138315 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565079, finearea: '1138316 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565080, finearea: '1138317 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565081, finearea: '1138318 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565082, finearea: '1138319 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565083, finearea: '1138320 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565084, finearea: '1138321 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565085, finearea: '1138322 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565086, finearea: '1138323 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565087, finearea: '1138324 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565088, finearea: '1138325 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565089, finearea: '1138326 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565090, finearea: '1138327 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565091, finearea: '1138328 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565092, finearea: '1138329 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565093, finearea: '1138330 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565094, finearea: '1138331 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565095, finearea: '1138332 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565096, finearea: '1138333 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565097, finearea: '1138334 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565098, finearea: '1138335 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565099, finearea: '1138336 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565100, finearea: '1138337 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565101, finearea: '1138338 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565102, finearea: '1138339 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565103, finearea: '1138340 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565104, finearea: '1138341 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565105, finearea: '1138342 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565106, finearea: '1138343 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565107, finearea: '1138345 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565108, finearea: '1138346 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565109, finearea: '1138347 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565110, finearea: '1138348 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565111, finearea: '1138349 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565112, finearea: '1138350 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565113, finearea: '1138351 _ Canada Bay (A)', subregion: 'Concord - Mortlake - Cabarita', region: 'South and West Sydney' },
  { geography: 565114, finearea: '1138401 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565115, finearea: '1138402 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565116, finearea: '1138403 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565117, finearea: '1138404 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565118, finearea: '1138405 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565119, finearea: '1138406 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565120, finearea: '1138407 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565121, finearea: '1138408 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565122, finearea: '1138409 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565123, finearea: '1138410 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565124, finearea: '1138411 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565125, finearea: '1138412 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565126, finearea: '1138413 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565127, finearea: '1138414 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565128, finearea: '1138415 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565129, finearea: '1138417 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565130, finearea: '1138418 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565131, finearea: '1138419 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565132, finearea: '1138420 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565133, finearea: '1138421 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565134, finearea: '1138422 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565135, finearea: '1138423 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565136, finearea: '1138426 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565137, finearea: '1138428 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565138, finearea: '1138429 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565139, finearea: '1138430 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565140, finearea: '1138431 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565141, finearea: '1138432 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565142, finearea: '1138433 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565143, finearea: '1138434 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565144, finearea: '1138435 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565145, finearea: '1138436 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565146, finearea: '1138437 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565147, finearea: '1138438 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565148, finearea: '1138440 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565149, finearea: '1138441 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565150, finearea: '1138442 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565151, finearea: '1138443 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565152, finearea: '1138444 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565153, finearea: '1138445 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565154, finearea: '1138446 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565155, finearea: '1138447 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565156, finearea: '1138448 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565157, finearea: '1138449 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565158, finearea: '1138450 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565159, finearea: '1138451 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565160, finearea: '1138452 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565161, finearea: '1138453 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565162, finearea: '1138454 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565163, finearea: '1138455 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565164, finearea: '1138456 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565165, finearea: '1138457 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565166, finearea: '1138458 _ Canada Bay (A)', subregion: 'Concord West - North Strathfield', region: 'South and West Sydney' },
  { geography: 565167, finearea: '1138501 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565168, finearea: '1138502 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565169, finearea: '1138503 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565170, finearea: '1138504 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565171, finearea: '1138505 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565172, finearea: '1138506 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565173, finearea: '1138507 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565174, finearea: '1138508 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565175, finearea: '1138509 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565176, finearea: '1138510 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565177, finearea: '1138511 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565178, finearea: '1138512 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565179, finearea: '1138513 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565180, finearea: '1138514 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565181, finearea: '1138515 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565182, finearea: '1138516 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565183, finearea: '1138517 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565184, finearea: '1138518 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565185, finearea: '1138519 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565186, finearea: '1138520 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565187, finearea: '1138521 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565188, finearea: '1138522 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565189, finearea: '1138523 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565190, finearea: '1138524 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565191, finearea: '1138525 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565192, finearea: '1138526 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565193, finearea: '1138527 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565194, finearea: '1138528 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565195, finearea: '1138529 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565196, finearea: '1138530 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565197, finearea: '1138531 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565198, finearea: '1138532 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565199, finearea: '1138533 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565200, finearea: '1138534 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565201, finearea: '1138535 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565202, finearea: '1138536 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565203, finearea: '1138537 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565204, finearea: '1138538 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565205, finearea: '1138539 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565206, finearea: '1138540 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565207, finearea: '1138541 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565208, finearea: '1138542 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565209, finearea: '1138543 _ Canada Bay (A)', subregion: 'Drummoyne - Rodd Point', region: 'South and West Sydney' },
  { geography: 565210, finearea: '1138601 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565211, finearea: '1138602 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565212, finearea: '1138603 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565213, finearea: '1138604 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565214, finearea: '1138605 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565215, finearea: '1138606 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565216, finearea: '1138607 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565217, finearea: '1138608 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565218, finearea: '1138609 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565219, finearea: '1138610 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565220, finearea: '1138611 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565221, finearea: '1138612 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565222, finearea: '1138613 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565223, finearea: '1138614 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565224, finearea: '1138615 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565225, finearea: '1138616 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565226, finearea: '1138617 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565227, finearea: '1138618 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565228, finearea: '1138619 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565229, finearea: '1138620 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565230, finearea: '1138621 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565231, finearea: '1138622 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565232, finearea: '1138623 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565233, finearea: '1138624 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565234, finearea: '1138625 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565235, finearea: '1138626 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565236, finearea: '1138627 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 567837, finearea: '1148405 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567838, finearea: '1148406 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567839, finearea: '1148407 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567840, finearea: '1148408 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567841, finearea: '1148409 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567842, finearea: '1148410 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567843, finearea: '1148411 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567844, finearea: '1148412 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567845, finearea: '1148413 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567846, finearea: '1148414 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567847, finearea: '1148415 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567848, finearea: '1148416 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567849, finearea: '1148417 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567850, finearea: '1148418 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567851, finearea: '1148419 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567852, finearea: '1148420 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567853, finearea: '1148421 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567854, finearea: '1148422 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567855, finearea: '1148423 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567856, finearea: '1148424 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567857, finearea: '1148425 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567858, finearea: '1148426 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567859, finearea: '1148427 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567860, finearea: '1148428 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567861, finearea: '1148429 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567862, finearea: '1148430 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567863, finearea: '1148431 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567864, finearea: '1148432 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567865, finearea: '1148433 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567866, finearea: '1148434 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567867, finearea: '1148435 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567868, finearea: '1148436 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567869, finearea: '1148437 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567870, finearea: '1148438 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567871, finearea: '1148439 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567872, finearea: '1148440 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567873, finearea: '1148441 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567874, finearea: '1148442 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567875, finearea: '1148501 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567876, finearea: '1148502 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567877, finearea: '1148503 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567878, finearea: '1148504 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567879, finearea: '1148505 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567880, finearea: '1148506 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567881, finearea: '1148507 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567882, finearea: '1148508 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567883, finearea: '1148509 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567884, finearea: '1148510 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567885, finearea: '1148511 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567886, finearea: '1148512 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567887, finearea: '1148513 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567888, finearea: '1148514 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567889, finearea: '1148515 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567890, finearea: '1148516 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567891, finearea: '1148517 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567892, finearea: '1148518 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567893, finearea: '1148519 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567894, finearea: '1148520 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567895, finearea: '1148521 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567896, finearea: '1148522 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567897, finearea: '1148523 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567898, finearea: '1148524 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567899, finearea: '1148525 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567900, finearea: '1148527 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567901, finearea: '1148528 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567902, finearea: '1148529 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567903, finearea: '1148530 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567904, finearea: '1148531 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567905, finearea: '1148532 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567906, finearea: '1148533 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567907, finearea: '1148534 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567908, finearea: '1148535 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567909, finearea: '1148536 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567910, finearea: '1148537 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567911, finearea: '1148538 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567912, finearea: '1148539 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567913, finearea: '1148540 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567914, finearea: '1148541 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567915, finearea: '1148542 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567916, finearea: '1148543 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567917, finearea: '1148544 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567918, finearea: '1148545 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567919, finearea: '1148546 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567920, finearea: '1148547 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567921, finearea: '1148548 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567922, finearea: '1148549 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567923, finearea: '1148550 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567924, finearea: '1148551 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567925, finearea: '1148552 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567926, finearea: '1148553 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567927, finearea: '1148554 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567928, finearea: '1148555 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567929, finearea: '1148556 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567930, finearea: '1148557 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567931, finearea: '1148558 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567932, finearea: '1148559 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567933, finearea: '1148560 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567934, finearea: '1148561 _ Cumberland (A)', subregion: 'Merrylands - Holroyd', region: 'South and West Sydney' },
  { geography: 567935, finearea: '1148601 _ Cumberland (A)', subregion: 'Greystanes - Pemulwuy - Smithfield Industrial', region: 'Northern Sydney' },
  { geography: 567936, finearea: '1148701 _ Cumberland (A)', subregion: 'Guildford West - Merrylands West - Yennora Industrial', region: 'South and West Sydney' },
  { geography: 567937, finearea: '1158801 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567938, finearea: '1158802 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567939, finearea: '1158803 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567940, finearea: '1158804 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567941, finearea: '1158805 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567942, finearea: '1158806 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567943, finearea: '1158807 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567944, finearea: '1158808 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567945, finearea: '1158809 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567946, finearea: '1158810 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567947, finearea: '1158811 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567948, finearea: '1158812 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567949, finearea: '1158813 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567950, finearea: '1158814 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567951, finearea: '1158815 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567952, finearea: '1158816 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567953, finearea: '1158817 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567954, finearea: '1158818 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567955, finearea: '1158819 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567956, finearea: '1158820 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567957, finearea: '1158821 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567958, finearea: '1158822 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567959, finearea: '1158823 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567960, finearea: '1158824 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567961, finearea: '1158825 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567962, finearea: '1158826 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567963, finearea: '1158827 _ Cumberland (A)', subregion: 'Pendle Hill - Girraween', region: 'Northern Sydney' },
  { geography: 567964, finearea: '1158901 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567965, finearea: '1158902 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567966, finearea: '1158903 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567967, finearea: '1158904 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567968, finearea: '1158905 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567969, finearea: '1158906 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567970, finearea: '1158907 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567971, finearea: '1158908 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567972, finearea: '1158909 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567973, finearea: '1158910 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567974, finearea: '1158911 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567975, finearea: '1158912 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567976, finearea: '1158913 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567977, finearea: '1158914 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567978, finearea: '1158915 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567979, finearea: '1158917 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567980, finearea: '1158918 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567981, finearea: '1158919 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567982, finearea: '1158920 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567983, finearea: '1158921 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567984, finearea: '1158922 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567985, finearea: '1158923 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567986, finearea: '1158924 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567987, finearea: '1158925 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567988, finearea: '1158926 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567989, finearea: '1158927 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567990, finearea: '1158928 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567991, finearea: '1158929 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567992, finearea: '1158930 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567993, finearea: '1158931 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567994, finearea: '1158932 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567995, finearea: '1158933 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567996, finearea: '1158934 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567997, finearea: '1158935 _ Cumberland (A)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 567998, finearea: '1107422 _ Dubbo Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 567999, finearea: '1107423 _ Dubbo Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 568000, finearea: '1107925 _ Dubbo Regional (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 568001, finearea: '1110001 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568002, finearea: '1110002 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568003, finearea: '1110003 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568004, finearea: '1110004 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568005, finearea: '1110005 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568006, finearea: '1110006 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568007, finearea: '1110007 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568008, finearea: '1110008 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568009, finearea: '1110009 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568010, finearea: '1110010 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568011, finearea: '1110011 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568012, finearea: '1110012 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568013, finearea: '1110013 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568014, finearea: '1110014 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568015, finearea: '1110016 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568016, finearea: '1110017 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568017, finearea: '1110018 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568018, finearea: '1110019 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568019, finearea: '1110020 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568020, finearea: '1110021 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568021, finearea: '1110022 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568022, finearea: '1110023 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568023, finearea: '1110024 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568024, finearea: '1110025 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568025, finearea: '1110026 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568026, finearea: '1110027 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568027, finearea: '1110028 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568028, finearea: '1110029 _ Dubbo Regional (A)', subregion: 'Dubbo - East', region: 'Regional NSW - Remainder' },
  { geography: 568029, finearea: '1110101 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568030, finearea: '1110102 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568031, finearea: '1110103 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568032, finearea: '1110105 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568033, finearea: '1110106 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568034, finearea: '1110107 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568035, finearea: '1110108 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568036, finearea: '1110109 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 579528, finearea: '1133634 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579529, finearea: '1133635 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579530, finearea: '1133636 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579531, finearea: '1133637 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579532, finearea: '1133638 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579533, finearea: '1133701 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579534, finearea: '1133702 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579535, finearea: '1133703 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579536, finearea: '1133704 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579537, finearea: '1133705 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579538, finearea: '1133706 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579539, finearea: '1133707 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579540, finearea: '1133708 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579541, finearea: '1133709 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579542, finearea: '1133710 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579543, finearea: '1133711 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579544, finearea: '1133713 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579545, finearea: '1133714 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579546, finearea: '1133716 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579547, finearea: '1133717 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579548, finearea: '1133718 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579549, finearea: '1133719 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579550, finearea: '1133720 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579551, finearea: '1133722 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579552, finearea: '1133723 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579553, finearea: '1133724 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579554, finearea: '1133725 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579555, finearea: '1133726 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579556, finearea: '1133727 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579557, finearea: '1133728 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579558, finearea: '1133729 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579559, finearea: '1133730 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579560, finearea: '1133731 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579561, finearea: '1133732 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579562, finearea: '1133733 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579563, finearea: '1133734 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579564, finearea: '1133735 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579565, finearea: '1133736 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579566, finearea: '1133737 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579567, finearea: '1133738 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579568, finearea: '1133739 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579569, finearea: '1133740 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579570, finearea: '1133741 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579571, finearea: '1133743 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579572, finearea: '1133744 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579573, finearea: '1133745 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579574, finearea: '1133746 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579575, finearea: '1133747 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579576, finearea: '1133748 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579577, finearea: '1133749 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579578, finearea: '1133750 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579579, finearea: '1133751 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579580, finearea: '1133752 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579581, finearea: '1133753 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579582, finearea: '1133754 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579583, finearea: '1133755 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579584, finearea: '1133756 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579585, finearea: '1133757 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579586, finearea: '1133758 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579587, finearea: '1133759 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579588, finearea: '1133760 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579589, finearea: '1133761 _ Sydney (C)', subregion: 'Sydney - Haymarket - The Rocks', region: 'Inner Sydney' },
  { geography: 579590, finearea: '1133801 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579591, finearea: '1133802 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579592, finearea: '1133803 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579593, finearea: '1133804 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579594, finearea: '1133805 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579595, finearea: '1133806 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579596, finearea: '1133808 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579597, finearea: '1133809 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579598, finearea: '1133810 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579599, finearea: '1133811 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579600, finearea: '1133812 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579601, finearea: '1133813 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579602, finearea: '1133814 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579603, finearea: '1133815 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579604, finearea: '1133816 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579605, finearea: '1133817 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579606, finearea: '1133818 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579607, finearea: '1133819 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579608, finearea: '1133821 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579609, finearea: '1133822 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579610, finearea: '1133823 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579611, finearea: '1133824 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579612, finearea: '1133825 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579613, finearea: '1133826 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579614, finearea: '1133827 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579615, finearea: '1133828 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579616, finearea: '1133829 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579617, finearea: '1133830 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579618, finearea: '1133831 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579619, finearea: '1133832 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579620, finearea: '1133833 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579621, finearea: '1133834 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579622, finearea: '1133835 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579623, finearea: '1133836 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579624, finearea: '1133837 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579625, finearea: '1133838 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579626, finearea: '1133839 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579627, finearea: '1133840 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579628, finearea: '1133841 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579629, finearea: '1133842 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579630, finearea: '1133843 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579631, finearea: '1133844 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579632, finearea: '1133845 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579633, finearea: '1133846 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579634, finearea: '1133847 _ Sydney (C)', subregion: 'Waterloo - Beaconsfield', region: 'Inner Sydney' },
  { geography: 579635, finearea: '1134514 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579636, finearea: '1134515 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579637, finearea: '1134522 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579638, finearea: '1134523 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579639, finearea: '1134524 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579640, finearea: '1134525 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579641, finearea: '1134526 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579642, finearea: '1134527 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579643, finearea: '1134528 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579644, finearea: '1134529 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579645, finearea: '1134532 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579646, finearea: '1134534 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579647, finearea: '1134535 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579648, finearea: '1134536 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579649, finearea: '1134537 _ Sydney (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 579650, finearea: '1138848 _ Sydney (C)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 579651, finearea: '1118814 _ Tamworth Regional (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 579652, finearea: '1118906 _ Tamworth Regional (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 579653, finearea: '1118909 _ Tamworth Regional (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 579654, finearea: '1119214 _ Tamworth Regional (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 579655, finearea: '1119312 _ Tamworth Regional (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 579656, finearea: '1120201 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579657, finearea: '1120202 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579658, finearea: '1120203 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579659, finearea: '1120205 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579660, finearea: '1120206 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579661, finearea: '1120207 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579662, finearea: '1120208 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579663, finearea: '1120209 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579664, finearea: '1120210 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579665, finearea: '1120211 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579666, finearea: '1120212 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579667, finearea: '1120213 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579668, finearea: '1120214 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579669, finearea: '1120215 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579670, finearea: '1120216 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579671, finearea: '1120217 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579672, finearea: '1120218 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579673, finearea: '1120219 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579674, finearea: '1120220 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579675, finearea: '1120221 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579676, finearea: '1120222 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579677, finearea: '1120223 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579678, finearea: '1120224 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579679, finearea: '1120225 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579680, finearea: '1120226 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579681, finearea: '1120227 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579682, finearea: '1120228 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579683, finearea: '1120229 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579684, finearea: '1120230 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579685, finearea: '1120231 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579686, finearea: '1120232 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579687, finearea: '1120233 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579688, finearea: '1120234 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579689, finearea: '1120235 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579690, finearea: '1120236 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579691, finearea: '1120237 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579692, finearea: '1120238 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579693, finearea: '1120239 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579694, finearea: '1120240 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579695, finearea: '1120241 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579696, finearea: '1120242 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579697, finearea: '1120243 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579698, finearea: '1120244 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579699, finearea: '1120245 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579700, finearea: '1120246 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579701, finearea: '1120247 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579702, finearea: '1120248 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579703, finearea: '1120249 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579704, finearea: '1120250 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579705, finearea: '1120251 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579706, finearea: '1120252 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579707, finearea: '1120253 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579708, finearea: '1120254 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579709, finearea: '1120255 _ Tamworth Regional (A)', subregion: 'Tamworth - East', region: 'Regional NSW - Remainder' },
  { geography: 579710, finearea: '1120301 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579711, finearea: '1120302 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579712, finearea: '1120303 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579713, finearea: '1120304 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579714, finearea: '1120305 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579715, finearea: '1120306 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579716, finearea: '1120307 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579717, finearea: '1120308 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579718, finearea: '1120309 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579719, finearea: '1120310 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579720, finearea: '1120311 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579721, finearea: '1120312 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579722, finearea: '1120313 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579723, finearea: '1120314 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579724, finearea: '1120315 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579725, finearea: '1120316 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579726, finearea: '1120317 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579727, finearea: '1120318 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 577928, finearea: '1127215 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577929, finearea: '1127216 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577930, finearea: '1127217 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577931, finearea: '1127218 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577932, finearea: '1127219 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577933, finearea: '1127220 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577934, finearea: '1127221 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577935, finearea: '1127222 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577936, finearea: '1127223 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577937, finearea: '1127224 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577938, finearea: '1127225 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577939, finearea: '1127226 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577940, finearea: '1127301 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577941, finearea: '1127302 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577942, finearea: '1127303 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577943, finearea: '1127304 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577944, finearea: '1127305 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577945, finearea: '1127306 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577946, finearea: '1127307 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577947, finearea: '1127308 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577948, finearea: '1127309 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577949, finearea: '1127310 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577950, finearea: '1127311 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577951, finearea: '1127312 _ Shoalhaven (C)', subregion: 'Callala Bay - Currarong', region: 'Illawarra-Shoalhaven' },
  { geography: 577952, finearea: '1127401 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577953, finearea: '1127402 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577954, finearea: '1127403 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577955, finearea: '1127404 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577956, finearea: '1127405 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577957, finearea: '1127406 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577958, finearea: '1127407 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577959, finearea: '1127408 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577960, finearea: '1127409 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577961, finearea: '1127410 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577962, finearea: '1127411 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577963, finearea: '1127412 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577964, finearea: '1127413 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577965, finearea: '1127414 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577966, finearea: '1127415 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577967, finearea: '1127416 _ Shoalhaven (C)', subregion: 'Culburra Beach', region: 'Illawarra-Shoalhaven' },
  { geography: 577968, finearea: '1127501 _ Shoalhaven (C)', subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang', region: 'Illawarra-Shoalhaven' },
  { geography: 577969, finearea: '1127601 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577970, finearea: '1127602 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577971, finearea: '1127603 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577972, finearea: '1127604 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577973, finearea: '1127605 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577974, finearea: '1127606 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577975, finearea: '1127607 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577976, finearea: '1127608 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577977, finearea: '1127609 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577978, finearea: '1127610 _ Shoalhaven (C)', subregion: 'Huskisson - Vincentia', region: 'Illawarra-Shoalhaven' },
  { geography: 577979, finearea: '1127701 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577980, finearea: '1127702 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577981, finearea: '1127703 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577982, finearea: '1127704 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577983, finearea: '1127705 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577984, finearea: '1127706 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577985, finearea: '1127707 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577986, finearea: '1127708 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577987, finearea: '1127709 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577988, finearea: '1127710 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577989, finearea: '1127711 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577990, finearea: '1127712 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577991, finearea: '1127713 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577992, finearea: '1127714 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577993, finearea: '1127715 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577994, finearea: '1127716 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577995, finearea: '1127717 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577996, finearea: '1127718 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577997, finearea: '1127719 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577998, finearea: '1127720 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 577999, finearea: '1127721 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578000, finearea: '1127722 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578001, finearea: '1127723 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578002, finearea: '1127724 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578003, finearea: '1127725 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578004, finearea: '1127726 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578005, finearea: '1127727 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578006, finearea: '1127728 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578007, finearea: '1127729 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578008, finearea: '1127730 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578009, finearea: '1127731 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578010, finearea: '1127732 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578011, finearea: '1127733 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578012, finearea: '1127734 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578013, finearea: '1127735 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578014, finearea: '1127736 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578015, finearea: '1127737 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578016, finearea: '1127738 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578017, finearea: '1127739 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578018, finearea: '1127740 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578019, finearea: '1127741 _ Shoalhaven (C)', subregion: 'North Nowra - Bomaderry', region: 'Illawarra-Shoalhaven' },
  { geography: 578020, finearea: '1127801 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578021, finearea: '1127802 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578022, finearea: '1127803 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578023, finearea: '1127804 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578024, finearea: '1127805 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578025, finearea: '1127806 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578026, finearea: '1127808 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578027, finearea: '1127809 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578028, finearea: '1127810 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578029, finearea: '1127811 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578030, finearea: '1127812 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578031, finearea: '1127813 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578032, finearea: '1127815 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578033, finearea: '1127816 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578034, finearea: '1127817 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578035, finearea: '1127818 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578036, finearea: '1127819 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578037, finearea: '1127820 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578038, finearea: '1127821 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578039, finearea: '1127822 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578040, finearea: '1127823 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578041, finearea: '1127824 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578042, finearea: '1127825 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578043, finearea: '1127827 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578044, finearea: '1127828 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578045, finearea: '1127829 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578046, finearea: '1127830 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578047, finearea: '1127831 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578048, finearea: '1127832 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578049, finearea: '1127833 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578050, finearea: '1127834 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578051, finearea: '1127835 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578052, finearea: '1127836 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578053, finearea: '1127837 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578054, finearea: '1127838 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578055, finearea: '1127839 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578056, finearea: '1127840 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578057, finearea: '1127841 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578058, finearea: '1127842 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578059, finearea: '1127843 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578060, finearea: '1127844 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578061, finearea: '1127845 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578062, finearea: '1127846 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578063, finearea: '1127847 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578064, finearea: '1127848 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578065, finearea: '1127849 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578066, finearea: '1127850 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578067, finearea: '1127851 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578068, finearea: '1127852 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578069, finearea: '1127853 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578070, finearea: '1127854 _ Shoalhaven (C)', subregion: 'Nowra', region: 'Illawarra-Shoalhaven' },
  { geography: 578071, finearea: '1127901 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578072, finearea: '1127902 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578073, finearea: '1127903 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578074, finearea: '1127905 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578075, finearea: '1127906 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578076, finearea: '1127907 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578077, finearea: '1127908 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578078, finearea: '1127909 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578079, finearea: '1127910 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578080, finearea: '1127912 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578081, finearea: '1127913 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578082, finearea: '1127914 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578083, finearea: '1127915 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578084, finearea: '1127916 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578085, finearea: '1127917 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578086, finearea: '1127918 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578087, finearea: '1127919 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578088, finearea: '1127920 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578089, finearea: '1127921 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578090, finearea: '1127922 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578091, finearea: '1127923 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578092, finearea: '1127924 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578093, finearea: '1127925 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578094, finearea: '1127926 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578095, finearea: '1127927 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578096, finearea: '1127928 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578097, finearea: '1127929 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578098, finearea: '1127930 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578099, finearea: '1127931 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578100, finearea: '1127932 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578101, finearea: '1127933 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578102, finearea: '1127934 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578103, finearea: '1127935 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578104, finearea: '1127936 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578105, finearea: '1127937 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578106, finearea: '1127938 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578107, finearea: '1127939 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578108, finearea: '1127940 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578109, finearea: '1127941 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578110, finearea: '1127942 _ Shoalhaven (C)', subregion: 'St Georges Basin - Erowal Bay', region: 'Illawarra-Shoalhaven' },
  { geography: 578111, finearea: '1128001 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578112, finearea: '1128002 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578113, finearea: '1128003 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578114, finearea: '1128004 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578115, finearea: '1128005 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578116, finearea: '1128006 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578117, finearea: '1128007 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578118, finearea: '1128008 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578119, finearea: '1128009 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578120, finearea: '1128010 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578121, finearea: '1128011 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578122, finearea: '1128012 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578123, finearea: '1128013 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578124, finearea: '1128014 _ Shoalhaven (C)', subregion: 'Sussex Inlet - Berrara', region: 'Illawarra-Shoalhaven' },
  { geography: 578125, finearea: '1128101 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 578126, finearea: '1128102 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 578127, finearea: '1128103 _ Shoalhaven (C)', subregion: 'Tomerong - Wandandian - Woollamia', region: 'Illawarra-Shoalhaven' },
  { geography: 579928, finearea: '1129123 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579929, finearea: '1129124 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579930, finearea: '1129125 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579931, finearea: '1129127 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579932, finearea: '1129128 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579933, finearea: '1129129 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579934, finearea: '1129130 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579935, finearea: '1129131 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579936, finearea: '1129132 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579937, finearea: '1129133 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579938, finearea: '1129134 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579939, finearea: '1129135 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579940, finearea: '1129136 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579941, finearea: '1129137 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579942, finearea: '1129138 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579943, finearea: '1129139 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579944, finearea: '1129140 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579945, finearea: '1129141 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579946, finearea: '1129142 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579947, finearea: '1129143 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579948, finearea: '1129144 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579949, finearea: '1129145 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579950, finearea: '1129146 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579951, finearea: '1129147 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579952, finearea: '1129148 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579953, finearea: '1129149 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579954, finearea: '1129401 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579955, finearea: '1129402 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579956, finearea: '1129404 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579957, finearea: '1129405 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579958, finearea: '1129406 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579959, finearea: '1129407 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579960, finearea: '1129408 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579961, finearea: '1129409 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579962, finearea: '1129410 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579963, finearea: '1129411 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579964, finearea: '1129412 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579965, finearea: '1129413 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579966, finearea: '1129414 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579967, finearea: '1129415 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579968, finearea: '1129416 _ The Hills Shire (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 579969, finearea: '1129601 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579970, finearea: '1129602 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579971, finearea: '1129603 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579972, finearea: '1129604 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579973, finearea: '1129605 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579974, finearea: '1129606 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579975, finearea: '1129607 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579976, finearea: '1129608 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579977, finearea: '1129609 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579978, finearea: '1129610 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579979, finearea: '1129611 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579980, finearea: '1129612 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579981, finearea: '1129613 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579982, finearea: '1129614 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579983, finearea: '1129615 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579984, finearea: '1129616 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579985, finearea: '1129617 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579986, finearea: '1129618 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579987, finearea: '1129619 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579988, finearea: '1129620 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579989, finearea: '1129621 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579990, finearea: '1129622 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579991, finearea: '1129623 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579992, finearea: '1129624 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579993, finearea: '1129625 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579994, finearea: '1129626 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579995, finearea: '1129627 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579996, finearea: '1129628 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579997, finearea: '1129629 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579998, finearea: '1129630 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 579999, finearea: '1129631 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 580000, finearea: '1129632 _ The Hills Shire (A)', subregion: 'West Pennant Hills', region: 'Northern Sydney' },
  { geography: 580001, finearea: '1155301 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580002, finearea: '1155302 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580003, finearea: '1155303 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580004, finearea: '1155304 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580005, finearea: '1155305 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580006, finearea: '1155306 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580007, finearea: '1155307 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580008, finearea: '1155308 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580009, finearea: '1155309 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580010, finearea: '1155310 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580011, finearea: '1155311 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580012, finearea: '1155312 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580013, finearea: '1155313 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580014, finearea: '1155314 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580015, finearea: '1155315 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580016, finearea: '1155316 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580017, finearea: '1155317 _ The Hills Shire (A)', subregion: 'Castle Hill - Central', region: 'Northern Sydney' },
  { geography: 580018, finearea: '1155501 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580019, finearea: '1155502 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580020, finearea: '1155503 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580021, finearea: '1155504 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580022, finearea: '1155505 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580023, finearea: '1155506 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580024, finearea: '1155507 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580025, finearea: '1155508 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580026, finearea: '1155509 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580027, finearea: '1155510 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580028, finearea: '1155511 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580029, finearea: '1155512 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580030, finearea: '1155513 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580031, finearea: '1155514 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580032, finearea: '1155515 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580033, finearea: '1155516 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580034, finearea: '1155517 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580035, finearea: '1155518 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580036, finearea: '1155519 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580037, finearea: '1155520 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580038, finearea: '1155521 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580039, finearea: '1155522 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580040, finearea: '1155523 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580041, finearea: '1155524 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580042, finearea: '1155525 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580043, finearea: '1155526 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580044, finearea: '1155527 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580045, finearea: '1155528 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580046, finearea: '1155529 _ The Hills Shire (A)', subregion: 'Castle Hill - North', region: 'Northern Sydney' },
  { geography: 580047, finearea: '1155601 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580048, finearea: '1155602 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580049, finearea: '1155603 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580050, finearea: '1155604 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580051, finearea: '1155605 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580052, finearea: '1155606 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580053, finearea: '1155607 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580054, finearea: '1155608 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580055, finearea: '1155609 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580056, finearea: '1155610 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580057, finearea: '1155611 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580058, finearea: '1155612 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580059, finearea: '1155613 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580060, finearea: '1155614 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580061, finearea: '1155615 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580062, finearea: '1155616 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580063, finearea: '1155617 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580064, finearea: '1155618 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580065, finearea: '1155619 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580066, finearea: '1155620 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580067, finearea: '1155621 _ The Hills Shire (A)', subregion: 'Castle Hill - South', region: 'Northern Sydney' },
  { geography: 580068, finearea: '1155701 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580069, finearea: '1155702 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580070, finearea: '1155703 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580071, finearea: '1155704 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580072, finearea: '1155705 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580073, finearea: '1155706 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580074, finearea: '1155707 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580075, finearea: '1155708 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580076, finearea: '1155709 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580077, finearea: '1155710 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580078, finearea: '1155711 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580079, finearea: '1155712 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580080, finearea: '1155713 _ The Hills Shire (A)', subregion: 'Castle Hill - West', region: 'Northern Sydney' },
  { geography: 580081, finearea: '1155901 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580082, finearea: '1155902 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580083, finearea: '1155903 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580084, finearea: '1155904 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580085, finearea: '1155905 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580086, finearea: '1155906 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580087, finearea: '1155907 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580088, finearea: '1155908 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580089, finearea: '1155909 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580090, finearea: '1155910 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580091, finearea: '1155911 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580092, finearea: '1155912 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580093, finearea: '1155913 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580094, finearea: '1155914 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580095, finearea: '1155915 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580096, finearea: '1155916 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580097, finearea: '1155917 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580098, finearea: '1155918 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580099, finearea: '1155919 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580100, finearea: '1155920 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580101, finearea: '1155921 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580102, finearea: '1155922 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580103, finearea: '1155923 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580104, finearea: '1155924 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580105, finearea: '1155925 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580106, finearea: '1155926 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580107, finearea: '1155927 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580108, finearea: '1155928 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580109, finearea: '1155929 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580110, finearea: '1155930 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580111, finearea: '1155931 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580112, finearea: '1155932 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580113, finearea: '1155933 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580114, finearea: '1155934 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580115, finearea: '1155935 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580116, finearea: '1155936 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580117, finearea: '1155937 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580118, finearea: '1155938 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580119, finearea: '1155939 _ The Hills Shire (A)', subregion: 'Kellyville', region: 'Northern Sydney' },
  { geography: 580120, finearea: '1129701 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580121, finearea: '1129702 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580122, finearea: '1129703 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580123, finearea: '1129704 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580124, finearea: '1129706 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580125, finearea: '1129707 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580126, finearea: '1129708 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580127, finearea: '1129709 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 576128, finearea: '1146205 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576129, finearea: '1146206 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576130, finearea: '1146207 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576131, finearea: '1146208 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576132, finearea: '1146209 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576133, finearea: '1146210 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576134, finearea: '1146211 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576135, finearea: '1146212 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576136, finearea: '1146213 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576137, finearea: '1146214 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576138, finearea: '1146215 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576139, finearea: '1146217 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576140, finearea: '1146218 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576141, finearea: '1146219 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576142, finearea: '1146220 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576143, finearea: '1146221 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576144, finearea: '1146223 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576145, finearea: '1146224 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576146, finearea: '1146225 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576147, finearea: '1146226 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576148, finearea: '1146227 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576149, finearea: '1146228 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576150, finearea: '1146229 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576151, finearea: '1146230 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576152, finearea: '1146231 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576153, finearea: '1146232 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576154, finearea: '1146233 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576155, finearea: '1146234 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576156, finearea: '1146235 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576157, finearea: '1146236 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576158, finearea: '1146237 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576159, finearea: '1146238 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576160, finearea: '1146239 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576161, finearea: '1146240 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576162, finearea: '1146241 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576163, finearea: '1146242 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576164, finearea: '1146243 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576165, finearea: '1146244 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576166, finearea: '1146245 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576167, finearea: '1146246 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576168, finearea: '1146301 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576169, finearea: '1146302 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576170, finearea: '1146303 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576171, finearea: '1146304 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576172, finearea: '1146305 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576173, finearea: '1146306 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576174, finearea: '1146307 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576175, finearea: '1146308 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576176, finearea: '1146310 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576177, finearea: '1146311 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576178, finearea: '1146312 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576179, finearea: '1146313 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576180, finearea: '1146314 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576181, finearea: '1146315 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576182, finearea: '1146316 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576183, finearea: '1146317 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576184, finearea: '1146318 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576185, finearea: '1146319 _ Penrith (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 576186, finearea: '1146401 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576187, finearea: '1146402 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576188, finearea: '1146403 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576189, finearea: '1146404 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576190, finearea: '1146405 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576191, finearea: '1146406 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576192, finearea: '1146407 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576193, finearea: '1146408 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576194, finearea: '1146409 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576195, finearea: '1146410 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576196, finearea: '1146411 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576197, finearea: '1146412 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576198, finearea: '1146413 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576199, finearea: '1146414 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576200, finearea: '1146415 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576201, finearea: '1146416 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576202, finearea: '1146417 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576203, finearea: '1146418 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576204, finearea: '1146419 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576205, finearea: '1146420 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576206, finearea: '1146421 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576207, finearea: '1146422 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576208, finearea: '1146423 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576209, finearea: '1146424 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576210, finearea: '1146425 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576211, finearea: '1146426 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576212, finearea: '1146427 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576213, finearea: '1146428 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576214, finearea: '1146429 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576215, finearea: '1146430 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576216, finearea: '1146431 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576217, finearea: '1146432 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576218, finearea: '1146433 _ Penrith (C)', subregion: 'Penrith', region: 'Northern Sydney' },
  { geography: 576219, finearea: '1146801 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576220, finearea: '1146802 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576221, finearea: '1146803 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576222, finearea: '1146804 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576223, finearea: '1146805 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576224, finearea: '1146806 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576225, finearea: '1146807 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576226, finearea: '1146808 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576227, finearea: '1146809 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576228, finearea: '1146810 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576229, finearea: '1146811 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576230, finearea: '1146812 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576231, finearea: '1146813 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576232, finearea: '1146814 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576233, finearea: '1146815 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576234, finearea: '1146816 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576235, finearea: '1146819 _ Penrith (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 576236, finearea: '1146901 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576237, finearea: '1146902 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576238, finearea: '1146903 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576239, finearea: '1146904 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576240, finearea: '1146905 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576241, finearea: '1146906 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576242, finearea: '1146907 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576243, finearea: '1146908 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576244, finearea: '1146909 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576245, finearea: '1146910 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576246, finearea: '1146911 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576247, finearea: '1146912 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576248, finearea: '1146913 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576249, finearea: '1146914 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576250, finearea: '1146915 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576251, finearea: '1146916 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576252, finearea: '1146917 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576253, finearea: '1146918 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576254, finearea: '1146919 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576255, finearea: '1146920 _ Penrith (C)', subregion: 'Erskine Park', region: 'Northern Sydney' },
  { geography: 576256, finearea: '1147001 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576257, finearea: '1147002 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576258, finearea: '1147003 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576259, finearea: '1147004 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576260, finearea: '1147005 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576261, finearea: '1147006 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576262, finearea: '1147007 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576263, finearea: '1147008 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576264, finearea: '1147009 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576265, finearea: '1147010 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576266, finearea: '1147011 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576267, finearea: '1147012 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576268, finearea: '1147013 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576269, finearea: '1147014 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576270, finearea: '1147015 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576271, finearea: '1147016 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576272, finearea: '1147017 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576273, finearea: '1147018 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576274, finearea: '1147019 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576275, finearea: '1147020 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576276, finearea: '1147021 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576277, finearea: '1147022 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576278, finearea: '1147023 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576279, finearea: '1147024 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576280, finearea: '1147026 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576281, finearea: '1147027 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576282, finearea: '1147028 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576283, finearea: '1147029 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576284, finearea: '1147030 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576285, finearea: '1147031 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576286, finearea: '1147032 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576287, finearea: '1147033 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576288, finearea: '1147034 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576289, finearea: '1147035 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576290, finearea: '1147036 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576291, finearea: '1147037 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576292, finearea: '1147038 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576293, finearea: '1147039 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576294, finearea: '1147040 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576295, finearea: '1147042 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576296, finearea: '1147043 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576297, finearea: '1147044 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576298, finearea: '1147045 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576299, finearea: '1147046 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576300, finearea: '1147047 _ Penrith (C)', subregion: 'St Clair', region: 'Northern Sydney' },
  { geography: 576301, finearea: '1158001 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576302, finearea: '1158002 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576303, finearea: '1158003 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576304, finearea: '1158004 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576305, finearea: '1158005 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576306, finearea: '1158006 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576307, finearea: '1158007 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576308, finearea: '1158008 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576309, finearea: '1158009 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576310, finearea: '1158010 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576311, finearea: '1158011 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576312, finearea: '1158012 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576313, finearea: '1158013 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576314, finearea: '1158014 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576315, finearea: '1158015 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576316, finearea: '1158016 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576317, finearea: '1158017 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576318, finearea: '1158018 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576319, finearea: '1158019 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576320, finearea: '1158020 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576321, finearea: '1158021 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576322, finearea: '1158022 _ Penrith (C)', subregion: 'Colyton - Oxley Park', region: 'Northern Sydney' },
  { geography: 576323, finearea: '1158101 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576324, finearea: '1158102 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576325, finearea: '1158103 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576326, finearea: '1158104 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576327, finearea: '1158105 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 571837, finearea: '1107029 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571838, finearea: '1107030 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571839, finearea: '1107031 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571840, finearea: '1107032 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571841, finearea: '1107101 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571842, finearea: '1107102 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571843, finearea: '1107103 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571844, finearea: '1107104 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571845, finearea: '1107105 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571846, finearea: '1107106 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571847, finearea: '1107107 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571848, finearea: '1107108 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571849, finearea: '1107109 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571850, finearea: '1107110 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571851, finearea: '1107111 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571852, finearea: '1107112 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571853, finearea: '1107113 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571854, finearea: '1107114 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571855, finearea: '1107115 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571856, finearea: '1107116 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571857, finearea: '1107117 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571858, finearea: '1107118 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571859, finearea: '1107119 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571860, finearea: '1107120 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571861, finearea: '1107121 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571862, finearea: '1107122 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571863, finearea: '1107123 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571864, finearea: '1107124 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571865, finearea: '1107125 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571866, finearea: '1107126 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571867, finearea: '1107127 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571868, finearea: '1107128 _ Lithgow (C)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 571869, finearea: '1107311 _ Lithgow (C)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 571870, finearea: '1107501 _ Lithgow (C)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 571871, finearea: '1144031 _ Liverpool (C)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 571872, finearea: '1144032 _ Liverpool (C)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 571873, finearea: '1144041 _ Liverpool (C)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 571874, finearea: '1144042 _ Liverpool (C)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 571875, finearea: '1146303 _ Liverpool (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 571876, finearea: '1146310 _ Liverpool (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 571877, finearea: '1146320 _ Liverpool (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 571878, finearea: '1146321 _ Liverpool (C)', subregion: 'Mulgoa - Luddenham - Orchard Hills', region: 'Northern Sydney' },
  { geography: 571879, finearea: '1146513 _ Liverpool (C)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 571880, finearea: '1150401 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571881, finearea: '1150402 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571882, finearea: '1150403 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571883, finearea: '1150404 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571884, finearea: '1150405 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571885, finearea: '1150406 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571886, finearea: '1150407 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571887, finearea: '1150408 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571888, finearea: '1150409 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571889, finearea: '1150410 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571890, finearea: '1150411 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571891, finearea: '1150412 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571892, finearea: '1150413 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571893, finearea: '1150414 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571894, finearea: '1150415 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571895, finearea: '1150416 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571896, finearea: '1150417 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571897, finearea: '1150418 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571898, finearea: '1150419 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571899, finearea: '1150420 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571900, finearea: '1150421 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571901, finearea: '1150422 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571902, finearea: '1150423 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571903, finearea: '1150424 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571904, finearea: '1150425 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571905, finearea: '1150426 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571906, finearea: '1150427 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571907, finearea: '1150428 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571908, finearea: '1150429 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571909, finearea: '1150430 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571910, finearea: '1150431 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571911, finearea: '1150432 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571912, finearea: '1150433 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571913, finearea: '1150434 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571914, finearea: '1150435 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571915, finearea: '1150436 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571916, finearea: '1150437 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571917, finearea: '1150438 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571918, finearea: '1150439 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571919, finearea: '1150440 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571920, finearea: '1150441 _ Liverpool (C)', subregion: 'Ashcroft - Busby - Miller', region: 'South and West Sydney' },
  { geography: 571921, finearea: '1150501 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571922, finearea: '1150502 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571923, finearea: '1150503 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571924, finearea: '1150504 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571925, finearea: '1150505 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571926, finearea: '1150506 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571927, finearea: '1150507 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571928, finearea: '1150508 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571929, finearea: '1150509 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571930, finearea: '1150510 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571931, finearea: '1150511 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571932, finearea: '1150512 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571933, finearea: '1150513 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571934, finearea: '1150514 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571935, finearea: '1150516 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571936, finearea: '1150517 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571937, finearea: '1150518 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571938, finearea: '1150519 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571939, finearea: '1150521 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571940, finearea: '1150522 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571941, finearea: '1150523 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571942, finearea: '1150524 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571943, finearea: '1150608 _ Liverpool (C)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 571944, finearea: '1159201 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571945, finearea: '1159202 _ Liverpool (C)', subregion: 'Austral - Greendale - Badgerys Creek', region: 'South and West Sydney' },
  { geography: 571946, finearea: '1159301 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571947, finearea: '1159302 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571948, finearea: '1159303 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571949, finearea: '1159304 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571950, finearea: '1159305 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571951, finearea: '1159306 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571952, finearea: '1159307 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571953, finearea: '1159308 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571954, finearea: '1159309 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571955, finearea: '1159310 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571956, finearea: '1159311 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571957, finearea: '1159312 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571958, finearea: '1159313 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571959, finearea: '1159314 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571960, finearea: '1159315 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571961, finearea: '1159316 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571962, finearea: '1159317 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571963, finearea: '1159318 _ Liverpool (C)', subregion: 'Cecil Hills', region: 'South and West Sydney' },
  { geography: 571964, finearea: '1159401 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571965, finearea: '1159402 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571966, finearea: '1159403 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571967, finearea: '1159404 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571968, finearea: '1159405 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571969, finearea: '1159406 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571970, finearea: '1159407 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571971, finearea: '1159408 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571972, finearea: '1159409 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571973, finearea: '1159410 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571974, finearea: '1159411 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571975, finearea: '1159412 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571976, finearea: '1159413 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571977, finearea: '1159414 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571978, finearea: '1159415 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571979, finearea: '1159416 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571980, finearea: '1159417 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571981, finearea: '1159418 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571982, finearea: '1159419 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571983, finearea: '1159420 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571984, finearea: '1159421 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571985, finearea: '1159422 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571986, finearea: '1159423 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571987, finearea: '1159424 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571988, finearea: '1159425 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571989, finearea: '1159426 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571990, finearea: '1159427 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571991, finearea: '1159428 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571992, finearea: '1159429 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571993, finearea: '1159430 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571994, finearea: '1159431 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571995, finearea: '1159432 _ Liverpool (C)', subregion: 'Green Valley', region: 'South and West Sydney' },
  { geography: 571996, finearea: '1159501 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 571997, finearea: '1159502 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 571998, finearea: '1159503 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 571999, finearea: '1159504 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572000, finearea: '1159505 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572001, finearea: '1159506 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572002, finearea: '1159507 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572003, finearea: '1159508 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572004, finearea: '1159509 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572005, finearea: '1159510 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572006, finearea: '1159511 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572007, finearea: '1159512 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572008, finearea: '1159513 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572009, finearea: '1159514 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572010, finearea: '1159515 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572011, finearea: '1159516 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572012, finearea: '1159517 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572013, finearea: '1159518 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572014, finearea: '1159519 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572015, finearea: '1159520 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572016, finearea: '1159521 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572017, finearea: '1159522 _ Liverpool (C)', subregion: 'Hinchinbrook', region: 'South and West Sydney' },
  { geography: 572018, finearea: '1159601 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572019, finearea: '1159602 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572020, finearea: '1159603 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572021, finearea: '1159604 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572022, finearea: '1159605 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572023, finearea: '1159606 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572024, finearea: '1159607 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572025, finearea: '1159608 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572026, finearea: '1159609 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572027, finearea: '1159610 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572028, finearea: '1159611 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572029, finearea: '1159612 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572030, finearea: '1159613 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572031, finearea: '1159614 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572032, finearea: '1159615 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572033, finearea: '1159616 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572034, finearea: '1159617 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572035, finearea: '1159618 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572036, finearea: '1159619 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 575728, finearea: '1149205 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575729, finearea: '1149206 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575730, finearea: '1149207 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575731, finearea: '1149208 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575732, finearea: '1149209 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575733, finearea: '1149210 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575734, finearea: '1149212 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575735, finearea: '1149213 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575736, finearea: '1149214 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575737, finearea: '1149215 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575738, finearea: '1149216 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575739, finearea: '1149217 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575740, finearea: '1149218 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575741, finearea: '1149219 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575742, finearea: '1149220 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575743, finearea: '1149221 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575744, finearea: '1149222 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575745, finearea: '1149223 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575746, finearea: '1149224 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575747, finearea: '1149225 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575748, finearea: '1149226 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575749, finearea: '1149227 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575750, finearea: '1149228 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575751, finearea: '1149229 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575752, finearea: '1149230 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575753, finearea: '1149231 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575754, finearea: '1149232 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575755, finearea: '1149233 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575756, finearea: '1149234 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575757, finearea: '1149236 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575758, finearea: '1149237 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575759, finearea: '1149238 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575760, finearea: '1149239 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575761, finearea: '1149240 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575762, finearea: '1149241 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575763, finearea: '1149242 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575764, finearea: '1149243 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575765, finearea: '1149244 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575766, finearea: '1149245 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575767, finearea: '1149246 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575768, finearea: '1149247 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575769, finearea: '1149248 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575770, finearea: '1149249 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575771, finearea: '1149250 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575772, finearea: '1149251 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575773, finearea: '1149252 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575774, finearea: '1149253 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575775, finearea: '1149254 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575776, finearea: '1149255 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575777, finearea: '1149256 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575778, finearea: '1149257 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575779, finearea: '1149258 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575780, finearea: '1149259 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575781, finearea: '1149301 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575782, finearea: '1149302 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575783, finearea: '1149303 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575784, finearea: '1149304 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575785, finearea: '1149305 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575786, finearea: '1149306 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575787, finearea: '1149307 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575788, finearea: '1149308 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575789, finearea: '1149309 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575790, finearea: '1149310 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575791, finearea: '1149311 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575792, finearea: '1149312 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575793, finearea: '1149313 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575794, finearea: '1149314 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575795, finearea: '1149315 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575796, finearea: '1149316 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575797, finearea: '1149317 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575798, finearea: '1149318 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575799, finearea: '1149319 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575800, finearea: '1149320 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575801, finearea: '1149321 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575802, finearea: '1149322 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575803, finearea: '1149323 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575804, finearea: '1149324 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575805, finearea: '1149325 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575806, finearea: '1149326 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575807, finearea: '1149327 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575808, finearea: '1149328 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575809, finearea: '1149329 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575810, finearea: '1149330 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575811, finearea: '1149331 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575812, finearea: '1149332 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575813, finearea: '1149333 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575814, finearea: '1149334 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575815, finearea: '1149335 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575816, finearea: '1149336 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575817, finearea: '1149337 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575818, finearea: '1149338 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575819, finearea: '1149339 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575820, finearea: '1149340 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575821, finearea: '1149341 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575822, finearea: '1149342 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575823, finearea: '1149343 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575824, finearea: '1149344 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575825, finearea: '1149345 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575826, finearea: '1149346 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575827, finearea: '1149347 _ Parramatta (C)', subregion: 'Toongabbie - Constitution Hill', region: 'Northern Sydney' },
  { geography: 575828, finearea: '1149401 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575829, finearea: '1149402 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575830, finearea: '1149403 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575831, finearea: '1149404 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575832, finearea: '1149405 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575833, finearea: '1149406 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575834, finearea: '1149407 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575835, finearea: '1149408 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575836, finearea: '1149409 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575837, finearea: '1149410 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575838, finearea: '1149411 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575839, finearea: '1149412 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575840, finearea: '1149413 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575841, finearea: '1149414 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575842, finearea: '1149415 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575843, finearea: '1149416 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575844, finearea: '1149417 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575845, finearea: '1149418 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575846, finearea: '1149419 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575847, finearea: '1149420 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575848, finearea: '1149421 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575849, finearea: '1149422 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575850, finearea: '1149423 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575851, finearea: '1149424 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575852, finearea: '1149425 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575853, finearea: '1149426 _ Parramatta (C)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 575854, finearea: '1158916 _ Parramatta (C)', subregion: 'Wentworthville - Westmead', region: 'South and West Sydney' },
  { geography: 575855, finearea: '1149501 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575856, finearea: '1149502 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575857, finearea: '1149503 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575858, finearea: '1149504 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575859, finearea: '1149505 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575860, finearea: '1149506 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575861, finearea: '1149507 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575862, finearea: '1149508 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575863, finearea: '1149509 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575864, finearea: '1149510 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575865, finearea: '1149511 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575866, finearea: '1149512 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575867, finearea: '1149513 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575868, finearea: '1149514 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575869, finearea: '1149515 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575870, finearea: '1149516 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575871, finearea: '1149517 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575872, finearea: '1149518 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575873, finearea: '1149519 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575874, finearea: '1149520 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575875, finearea: '1149521 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575876, finearea: '1149522 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575877, finearea: '1149523 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575878, finearea: '1149524 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575879, finearea: '1149525 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575880, finearea: '1149526 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575881, finearea: '1149528 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575882, finearea: '1149529 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575883, finearea: '1149530 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575884, finearea: '1149531 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575885, finearea: '1149532 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575886, finearea: '1149533 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575887, finearea: '1149534 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575888, finearea: '1149535 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575889, finearea: '1149536 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575890, finearea: '1149537 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575891, finearea: '1149547 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575892, finearea: '1149548 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575893, finearea: '1149549 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575894, finearea: '1149550 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575895, finearea: '1149551 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575896, finearea: '1149552 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575897, finearea: '1149553 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575898, finearea: '1149554 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575899, finearea: '1149555 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575900, finearea: '1149557 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575901, finearea: '1149558 _ Parramatta (C)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 575902, finearea: '1149705 _ Parramatta (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 575903, finearea: '1149713 _ Parramatta (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 575904, finearea: '1149714 _ Parramatta (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 575905, finearea: '1149715 _ Parramatta (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 575906, finearea: '1149716 _ Parramatta (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 575907, finearea: '1149717 _ Parramatta (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 575908, finearea: '1149751 _ Parramatta (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 575909, finearea: '1149752 _ Parramatta (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 575910, finearea: '1149753 _ Parramatta (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 575911, finearea: '1145701 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575912, finearea: '1145702 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575913, finearea: '1145703 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575914, finearea: '1145704 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575915, finearea: '1145705 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575916, finearea: '1145706 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575917, finearea: '1145707 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575918, finearea: '1145708 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575919, finearea: '1145709 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575920, finearea: '1145710 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575921, finearea: '1145711 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575922, finearea: '1145712 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575923, finearea: '1145713 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575924, finearea: '1145714 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575925, finearea: '1145715 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575926, finearea: '1145716 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575927, finearea: '1145717 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 572037, finearea: '1159620 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572038, finearea: '1159621 _ Liverpool (C)', subregion: 'Hoxton Park - Carnes Hill - Horningsea Park', region: 'South and West Sydney' },
  { geography: 572039, finearea: '1159701 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572040, finearea: '1159702 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572041, finearea: '1159703 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572042, finearea: '1159704 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572043, finearea: '1159705 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572044, finearea: '1159706 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572045, finearea: '1159707 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572046, finearea: '1159708 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572047, finearea: '1159709 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572048, finearea: '1159710 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572049, finearea: '1159711 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572050, finearea: '1159712 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572051, finearea: '1159713 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572052, finearea: '1159714 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572053, finearea: '1159715 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572054, finearea: '1159716 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572055, finearea: '1159717 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572056, finearea: '1159718 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572057, finearea: '1159719 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572058, finearea: '1159720 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572059, finearea: '1159721 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572060, finearea: '1159722 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572061, finearea: '1159723 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572062, finearea: '1159724 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572063, finearea: '1159725 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572064, finearea: '1159726 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572065, finearea: '1159727 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572066, finearea: '1159728 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572067, finearea: '1159729 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572068, finearea: '1159730 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572069, finearea: '1159731 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572070, finearea: '1159732 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572071, finearea: '1159733 _ Liverpool (C)', subregion: 'West Hoxton - Middleton Grange', region: 'South and West Sydney' },
  { geography: 572072, finearea: '1151221 _ Liverpool (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 572073, finearea: '1152201 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572074, finearea: '1152202 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572075, finearea: '1152203 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572076, finearea: '1152204 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572077, finearea: '1152205 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572078, finearea: '1152206 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572079, finearea: '1152207 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572080, finearea: '1152208 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572081, finearea: '1152209 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572082, finearea: '1152210 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572083, finearea: '1152211 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572084, finearea: '1152212 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572085, finearea: '1152213 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572086, finearea: '1152214 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572087, finearea: '1152215 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572088, finearea: '1152216 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572089, finearea: '1152217 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572090, finearea: '1152218 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572091, finearea: '1152219 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572092, finearea: '1152220 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572093, finearea: '1152221 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572094, finearea: '1152222 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572095, finearea: '1152223 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572096, finearea: '1152224 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572097, finearea: '1152225 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572098, finearea: '1152226 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572099, finearea: '1152227 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572100, finearea: '1152228 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572101, finearea: '1152229 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572102, finearea: '1152230 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572103, finearea: '1152231 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572104, finearea: '1152232 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572105, finearea: '1152233 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572106, finearea: '1152234 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572107, finearea: '1152235 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572108, finearea: '1152236 _ Liverpool (C)', subregion: 'Casula', region: 'South and West Sydney' },
  { geography: 572109, finearea: '1152301 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572110, finearea: '1152302 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572111, finearea: '1152304 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572112, finearea: '1152305 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572113, finearea: '1152306 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572114, finearea: '1152307 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572115, finearea: '1152308 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572116, finearea: '1152309 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572117, finearea: '1152310 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572118, finearea: '1152311 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572119, finearea: '1152312 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572120, finearea: '1152313 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572121, finearea: '1152314 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572122, finearea: '1152315 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572123, finearea: '1152316 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572124, finearea: '1152317 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572125, finearea: '1152318 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572126, finearea: '1152319 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572127, finearea: '1152320 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572128, finearea: '1152321 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572129, finearea: '1152322 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572130, finearea: '1152323 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572131, finearea: '1152324 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572132, finearea: '1152325 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572133, finearea: '1152326 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572134, finearea: '1152327 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572135, finearea: '1152328 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572136, finearea: '1152329 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572137, finearea: '1152330 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572138, finearea: '1152331 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572139, finearea: '1152332 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572140, finearea: '1152333 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572141, finearea: '1152334 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572142, finearea: '1152335 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572143, finearea: '1152336 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572144, finearea: '1152337 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572145, finearea: '1152338 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572146, finearea: '1152339 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572147, finearea: '1152340 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572148, finearea: '1152341 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572149, finearea: '1152342 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572150, finearea: '1152343 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572151, finearea: '1152344 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572152, finearea: '1152345 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572153, finearea: '1152346 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572154, finearea: '1152347 _ Liverpool (C)', subregion: 'Chipping Norton - Moorebank', region: 'South and West Sydney' },
  { geography: 572155, finearea: '1152401 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572156, finearea: '1152402 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572157, finearea: '1152403 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572158, finearea: '1152404 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572159, finearea: '1152405 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572160, finearea: '1152406 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572161, finearea: '1152407 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572162, finearea: '1152408 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572163, finearea: '1152409 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572164, finearea: '1152410 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572165, finearea: '1152411 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572166, finearea: '1152412 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572167, finearea: '1152413 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572168, finearea: '1152414 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572169, finearea: '1152415 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572170, finearea: '1152416 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572171, finearea: '1152417 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572172, finearea: '1152418 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572173, finearea: '1152419 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572174, finearea: '1152420 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572175, finearea: '1152421 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572176, finearea: '1152422 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572177, finearea: '1152423 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572178, finearea: '1152424 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572179, finearea: '1152425 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572180, finearea: '1152426 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572181, finearea: '1152427 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572182, finearea: '1152428 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572183, finearea: '1152429 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572184, finearea: '1152430 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572185, finearea: '1152431 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572186, finearea: '1152432 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572187, finearea: '1152433 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572188, finearea: '1152434 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572189, finearea: '1152436 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572190, finearea: '1152437 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572191, finearea: '1152438 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572192, finearea: '1152439 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572193, finearea: '1152440 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572194, finearea: '1152441 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572195, finearea: '1152442 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572196, finearea: '1152443 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572197, finearea: '1152444 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572198, finearea: '1152445 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572199, finearea: '1152446 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572200, finearea: '1152447 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572201, finearea: '1152448 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572202, finearea: '1152449 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572203, finearea: '1152450 _ Liverpool (C)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 572204, finearea: '1159801 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572205, finearea: '1159802 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572206, finearea: '1159803 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572207, finearea: '1159804 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572208, finearea: '1159805 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572209, finearea: '1159806 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572210, finearea: '1159807 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572211, finearea: '1159808 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572212, finearea: '1159809 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572213, finearea: '1159810 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572214, finearea: '1159811 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572215, finearea: '1159812 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572216, finearea: '1159813 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572217, finearea: '1159814 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572218, finearea: '1159815 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572219, finearea: '1159816 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572220, finearea: '1159817 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572221, finearea: '1159818 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572222, finearea: '1159819 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572223, finearea: '1159820 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572224, finearea: '1159821 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572225, finearea: '1159822 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572226, finearea: '1159823 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572227, finearea: '1159824 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572228, finearea: '1159825 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572229, finearea: '1159826 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572230, finearea: '1159827 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572231, finearea: '1159828 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572232, finearea: '1159829 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572233, finearea: '1159830 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572234, finearea: '1159831 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572235, finearea: '1159832 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572236, finearea: '1159833 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 574528, finearea: '1141927 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574529, finearea: '1141928 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574530, finearea: '1141929 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574531, finearea: '1141930 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574532, finearea: '1141931 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574533, finearea: '1141932 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574534, finearea: '1141933 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574535, finearea: '1141934 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574536, finearea: '1141935 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574537, finearea: '1141936 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574538, finearea: '1141937 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574539, finearea: '1141938 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574540, finearea: '1141939 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574541, finearea: '1141940 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574542, finearea: '1141941 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574543, finearea: '1141942 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574544, finearea: '1141943 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574545, finearea: '1141944 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574546, finearea: '1141945 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574547, finearea: '1141946 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574548, finearea: '1141947 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574549, finearea: '1141948 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574550, finearea: '1142001 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574551, finearea: '1142002 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574552, finearea: '1142003 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574553, finearea: '1142004 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574554, finearea: '1142005 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574555, finearea: '1142006 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574556, finearea: '1142007 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574557, finearea: '1142008 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574558, finearea: '1142009 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574559, finearea: '1142010 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574560, finearea: '1142011 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574561, finearea: '1142012 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574562, finearea: '1142013 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574563, finearea: '1142014 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574564, finearea: '1142015 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574565, finearea: '1142016 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574566, finearea: '1142017 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574567, finearea: '1142018 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574568, finearea: '1142019 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574569, finearea: '1142020 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574570, finearea: '1142021 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574571, finearea: '1142022 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574572, finearea: '1142023 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574573, finearea: '1142024 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574574, finearea: '1142025 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574575, finearea: '1142026 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574576, finearea: '1142027 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574577, finearea: '1142028 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574578, finearea: '1142029 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574579, finearea: '1142030 _ Northern Beaches (A)', subregion: 'Avalon - Palm Beach', region: 'Northern Sydney' },
  { geography: 574580, finearea: '1142101 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574581, finearea: '1142102 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574582, finearea: '1142103 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574583, finearea: '1142104 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574584, finearea: '1142105 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574585, finearea: '1142106 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574586, finearea: '1142107 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574587, finearea: '1142108 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574588, finearea: '1142109 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574589, finearea: '1142110 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574590, finearea: '1142111 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574591, finearea: '1142112 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574592, finearea: '1142113 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574593, finearea: '1142114 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574594, finearea: '1142115 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574595, finearea: '1142116 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574596, finearea: '1142117 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574597, finearea: '1142118 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574598, finearea: '1142119 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574599, finearea: '1142120 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574600, finearea: '1142121 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574601, finearea: '1142122 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574602, finearea: '1142123 _ Northern Beaches (A)', subregion: 'Bayview - Elanora Heights', region: 'Northern Sydney' },
  { geography: 574603, finearea: '1142201 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574604, finearea: '1142202 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574605, finearea: '1142203 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574606, finearea: '1142204 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574607, finearea: '1142205 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574608, finearea: '1142206 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574609, finearea: '1142207 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574610, finearea: '1142208 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574611, finearea: '1142209 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574612, finearea: '1142210 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574613, finearea: '1142211 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574614, finearea: '1142212 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574615, finearea: '1142213 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574616, finearea: '1142214 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574617, finearea: '1142215 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574618, finearea: '1142216 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574619, finearea: '1142217 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574620, finearea: '1142218 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574621, finearea: '1142219 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574622, finearea: '1142220 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574623, finearea: '1142221 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574624, finearea: '1142222 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574625, finearea: '1142223 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574626, finearea: '1142224 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574627, finearea: '1142225 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574628, finearea: '1142226 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574629, finearea: '1142227 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574630, finearea: '1142228 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574631, finearea: '1142229 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574632, finearea: '1142230 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574633, finearea: '1142231 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574634, finearea: '1142232 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574635, finearea: '1142233 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574636, finearea: '1142234 _ Northern Beaches (A)', subregion: 'Newport - Bilgola', region: 'Northern Sydney' },
  { geography: 574637, finearea: '1142301 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574638, finearea: '1142302 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574639, finearea: '1142303 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574640, finearea: '1142304 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574641, finearea: '1142305 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574642, finearea: '1142306 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574643, finearea: '1142307 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574644, finearea: '1142308 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574645, finearea: '1142309 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574646, finearea: '1142310 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574647, finearea: '1142311 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574648, finearea: '1142312 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574649, finearea: '1142313 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574650, finearea: '1142314 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574651, finearea: '1142315 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574652, finearea: '1142316 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574653, finearea: '1142317 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574654, finearea: '1142320 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574655, finearea: '1142321 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574656, finearea: '1142322 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574657, finearea: '1142323 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574658, finearea: '1142324 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574659, finearea: '1142325 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574660, finearea: '1142326 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574661, finearea: '1142327 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574662, finearea: '1142329 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574663, finearea: '1142330 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574664, finearea: '1142331 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574665, finearea: '1142332 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574666, finearea: '1142333 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574667, finearea: '1142334 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574668, finearea: '1142335 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574669, finearea: '1142336 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574670, finearea: '1142337 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574671, finearea: '1142338 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574672, finearea: '1142339 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574673, finearea: '1142340 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574674, finearea: '1142341 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574675, finearea: '1142342 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574676, finearea: '1142343 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574677, finearea: '1142344 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574678, finearea: '1142345 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574679, finearea: '1142346 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574680, finearea: '1142347 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574681, finearea: '1142348 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574682, finearea: '1142349 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574683, finearea: '1142350 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574684, finearea: '1142351 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574685, finearea: '1142352 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574686, finearea: '1142353 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574687, finearea: '1142354 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574688, finearea: '1142355 _ Northern Beaches (A)', subregion: 'Warriewood - Mona Vale', region: 'Northern Sydney' },
  { geography: 574689, finearea: '1142401 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574690, finearea: '1142402 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574691, finearea: '1142403 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574692, finearea: '1142404 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574693, finearea: '1142405 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574694, finearea: '1142406 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574695, finearea: '1142407 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574696, finearea: '1142408 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574697, finearea: '1142409 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574698, finearea: '1142410 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574699, finearea: '1142411 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574700, finearea: '1142412 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574701, finearea: '1142413 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574702, finearea: '1142414 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574703, finearea: '1142415 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574704, finearea: '1142416 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574705, finearea: '1142417 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574706, finearea: '1142418 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574707, finearea: '1142419 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574708, finearea: '1142420 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574709, finearea: '1142421 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574710, finearea: '1142422 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574711, finearea: '1142423 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574712, finearea: '1142424 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574713, finearea: '1142425 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574714, finearea: '1142426 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574715, finearea: '1142427 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574716, finearea: '1142428 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574717, finearea: '1142429 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574718, finearea: '1142430 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574719, finearea: '1142431 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574720, finearea: '1142432 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574721, finearea: '1142433 _ Northern Beaches (A)', subregion: 'Beacon Hill - Narraweena', region: 'Northern Sydney' },
  { geography: 574722, finearea: '1142501 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574723, finearea: '1142502 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574724, finearea: '1142503 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574725, finearea: '1142504 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574726, finearea: '1142505 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574727, finearea: '1142506 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 562837, finearea: '1137627 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562838, finearea: '1137628 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562839, finearea: '1137629 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562840, finearea: '1137630 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562841, finearea: '1137631 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562842, finearea: '1137632 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562843, finearea: '1137633 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562844, finearea: '1137634 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562845, finearea: '1137635 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562846, finearea: '1137636 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562847, finearea: '1137637 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562848, finearea: '1137638 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562849, finearea: '1137639 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562850, finearea: '1137640 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562851, finearea: '1137641 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562852, finearea: '1137642 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562853, finearea: '1137643 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562854, finearea: '1137644 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562855, finearea: '1137645 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562856, finearea: '1137646 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562857, finearea: '1137647 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562858, finearea: '1137648 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562859, finearea: '1137649 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562860, finearea: '1137650 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562861, finearea: '1137651 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562862, finearea: '1137652 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562863, finearea: '1137653 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562864, finearea: '1137654 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562865, finearea: '1137655 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562866, finearea: '1137656 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562867, finearea: '1137657 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562868, finearea: '1137701 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562869, finearea: '1137703 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562870, finearea: '1137704 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562871, finearea: '1137705 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562872, finearea: '1137706 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562873, finearea: '1137707 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562874, finearea: '1137708 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562875, finearea: '1137709 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562876, finearea: '1137710 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562877, finearea: '1137711 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562878, finearea: '1137712 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562879, finearea: '1137721 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562880, finearea: '1137722 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562881, finearea: '1137723 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562882, finearea: '1137724 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562883, finearea: '1137725 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562884, finearea: '1137726 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562885, finearea: '1137727 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562886, finearea: '1137728 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562887, finearea: '1137729 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562888, finearea: '1137730 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562889, finearea: '1137731 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562890, finearea: '1137732 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562891, finearea: '1137733 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562892, finearea: '1137734 _ Bayside (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 562893, finearea: '1137801 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562894, finearea: '1137802 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562895, finearea: '1137803 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562896, finearea: '1137804 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562897, finearea: '1137805 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562898, finearea: '1137806 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562899, finearea: '1137807 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562900, finearea: '1137808 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562901, finearea: '1137809 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562902, finearea: '1137810 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562903, finearea: '1137811 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562904, finearea: '1137812 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562905, finearea: '1137813 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562906, finearea: '1137814 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562907, finearea: '1137815 _ Bayside (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 562908, finearea: '1138001 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562909, finearea: '1138002 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562910, finearea: '1138003 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562911, finearea: '1138004 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562912, finearea: '1138005 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562913, finearea: '1138006 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562914, finearea: '1138007 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562915, finearea: '1138008 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562916, finearea: '1138009 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562917, finearea: '1138010 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562918, finearea: '1138011 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562919, finearea: '1138012 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562920, finearea: '1138013 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562921, finearea: '1138014 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562922, finearea: '1138015 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562923, finearea: '1138016 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562924, finearea: '1138017 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562925, finearea: '1138018 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562926, finearea: '1138019 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562927, finearea: '1138020 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562928, finearea: '1138021 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562929, finearea: '1138022 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562930, finearea: '1138023 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562931, finearea: '1138024 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562932, finearea: '1138025 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562933, finearea: '1138026 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562934, finearea: '1138027 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562935, finearea: '1138028 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562936, finearea: '1138029 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562937, finearea: '1138030 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562938, finearea: '1138031 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562939, finearea: '1138032 _ Bayside (A)', subregion: 'Monterey - Brighton-le-Sands - Kyeemagh', region: 'Inner Sydney' },
  { geography: 562940, finearea: '1138101 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562941, finearea: '1138102 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562942, finearea: '1138103 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562943, finearea: '1138104 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562944, finearea: '1138106 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562945, finearea: '1138107 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562946, finearea: '1138108 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562947, finearea: '1138109 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562948, finearea: '1138110 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562949, finearea: '1138111 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562950, finearea: '1138112 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562951, finearea: '1138113 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562952, finearea: '1138114 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562953, finearea: '1138115 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562954, finearea: '1138116 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562955, finearea: '1138117 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562956, finearea: '1138118 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562957, finearea: '1138120 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562958, finearea: '1138121 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562959, finearea: '1138122 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562960, finearea: '1138124 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562961, finearea: '1138125 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562962, finearea: '1138126 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562963, finearea: '1138127 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562964, finearea: '1138128 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562965, finearea: '1138129 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562966, finearea: '1138130 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562967, finearea: '1138131 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562968, finearea: '1138132 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562969, finearea: '1138133 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562970, finearea: '1138135 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562971, finearea: '1138136 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562972, finearea: '1138138 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562973, finearea: '1138139 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562974, finearea: '1138140 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562975, finearea: '1138141 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562976, finearea: '1138142 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562977, finearea: '1138143 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562978, finearea: '1138144 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562979, finearea: '1138145 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562980, finearea: '1138146 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562981, finearea: '1138147 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562982, finearea: '1138148 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562983, finearea: '1138149 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562984, finearea: '1138150 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562985, finearea: '1138151 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562986, finearea: '1138152 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562987, finearea: '1138153 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562988, finearea: '1138154 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562989, finearea: '1138155 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562990, finearea: '1138156 _ Bayside (A)', subregion: 'Rockdale - Banksia', region: 'Inner Sydney' },
  { geography: 562991, finearea: '1138201 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 562992, finearea: '1138202 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 562993, finearea: '1138203 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 562994, finearea: '1138204 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 562995, finearea: '1138205 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 562996, finearea: '1138206 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 562997, finearea: '1138207 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 562998, finearea: '1138208 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 562999, finearea: '1138212 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563000, finearea: '1138213 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563001, finearea: '1138214 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563002, finearea: '1138215 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563003, finearea: '1138216 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563004, finearea: '1138217 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563005, finearea: '1138218 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563006, finearea: '1138219 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563007, finearea: '1138222 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563008, finearea: '1138223 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563009, finearea: '1138224 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563010, finearea: '1138225 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563011, finearea: '1138226 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563012, finearea: '1138227 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563013, finearea: '1138228 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563014, finearea: '1138229 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563015, finearea: '1138230 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563016, finearea: '1138231 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563017, finearea: '1138232 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563018, finearea: '1138234 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563019, finearea: '1138236 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563020, finearea: '1138237 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563021, finearea: '1138238 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563022, finearea: '1138239 _ Bayside (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 563023, finearea: '1101307 _ Bega Valley (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 563024, finearea: '1101308 _ Bega Valley (A)', subregion: 'Bombala', region: 'Capital Region and South Coast' },
  { geography: 563025, finearea: '1101517 _ Bega Valley (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 563026, finearea: '1101901 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563027, finearea: '1101902 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563028, finearea: '1101903 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563029, finearea: '1101904 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563030, finearea: '1101905 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563031, finearea: '1101906 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563032, finearea: '1101907 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563033, finearea: '1101909 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563034, finearea: '1101911 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563035, finearea: '1101912 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563036, finearea: '1101913 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 578328, finearea: '1126205 _ Snowy Monaro Regional (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578329, finearea: '1154201 _ Snowy Valleys (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 578330, finearea: '1126001 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578331, finearea: '1126002 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578332, finearea: '1126003 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578333, finearea: '1126004 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578334, finearea: '1126005 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578335, finearea: '1126006 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578336, finearea: '1126007 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578337, finearea: '1126009 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578338, finearea: '1126012 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578339, finearea: '1126013 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578340, finearea: '1126014 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578341, finearea: '1126015 _ Snowy Valleys (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 578342, finearea: '1126101 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578343, finearea: '1126102 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578344, finearea: '1126103 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578345, finearea: '1126104 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578346, finearea: '1126105 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578347, finearea: '1126106 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578348, finearea: '1126107 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578349, finearea: '1126108 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578350, finearea: '1126109 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578351, finearea: '1126110 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578352, finearea: '1126111 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578353, finearea: '1126112 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578354, finearea: '1126113 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578355, finearea: '1126114 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578356, finearea: '1126115 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578357, finearea: '1126116 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578358, finearea: '1126117 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578359, finearea: '1126118 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578360, finearea: '1126119 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578361, finearea: '1126120 _ Snowy Valleys (A)', subregion: 'Tumut', region: 'Riverina-Goulburn Belt' },
  { geography: 578362, finearea: '1126201 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578363, finearea: '1126202 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578364, finearea: '1126203 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578365, finearea: '1126204 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578366, finearea: '1126205 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578367, finearea: '1126206 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578368, finearea: '1126207 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578369, finearea: '1126208 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578370, finearea: '1126209 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578371, finearea: '1126210 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578372, finearea: '1126211 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578373, finearea: '1126212 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578374, finearea: '1126213 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578375, finearea: '1126214 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578376, finearea: '1126215 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578377, finearea: '1126216 _ Snowy Valleys (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 578378, finearea: '1135501 _ Strathfield (A)', subregion: 'Chullora', region: 'South and West Sydney' },
  { geography: 578379, finearea: '1135703 _ Strathfield (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 578380, finearea: '1135704 _ Strathfield (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 578381, finearea: '1135705 _ Strathfield (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 578382, finearea: '1135748 _ Strathfield (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 578383, finearea: '1135749 _ Strathfield (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 578384, finearea: '1136212 _ Strathfield (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 578385, finearea: '1136213 _ Strathfield (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 578386, finearea: '1136214 _ Strathfield (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 578387, finearea: '1139602 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578388, finearea: '1139603 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578389, finearea: '1139605 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578390, finearea: '1139606 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578391, finearea: '1139607 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578392, finearea: '1139608 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578393, finearea: '1139609 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578394, finearea: '1139610 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578395, finearea: '1139611 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578396, finearea: '1139612 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578397, finearea: '1139613 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578398, finearea: '1139614 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578399, finearea: '1139615 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578400, finearea: '1139616 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578401, finearea: '1139617 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578402, finearea: '1139618 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578403, finearea: '1139619 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578404, finearea: '1139620 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578405, finearea: '1139621 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578406, finearea: '1139622 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578407, finearea: '1139623 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578408, finearea: '1139624 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578409, finearea: '1139627 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578410, finearea: '1139628 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578411, finearea: '1139629 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578412, finearea: '1139630 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578413, finearea: '1139631 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578414, finearea: '1139632 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578415, finearea: '1139633 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578416, finearea: '1139634 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578417, finearea: '1139635 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578418, finearea: '1139636 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578419, finearea: '1139638 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578420, finearea: '1139639 _ Strathfield (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 578421, finearea: '1157501 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578422, finearea: '1157508 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578423, finearea: '1157511 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578424, finearea: '1157512 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578425, finearea: '1157514 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578426, finearea: '1157515 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578427, finearea: '1157516 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578428, finearea: '1157517 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578429, finearea: '1157518 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578430, finearea: '1157521 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578431, finearea: '1157522 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578432, finearea: '1157523 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578433, finearea: '1157524 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578434, finearea: '1157525 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578435, finearea: '1157526 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578436, finearea: '1157527 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578437, finearea: '1157528 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578438, finearea: '1157529 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578439, finearea: '1157530 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578440, finearea: '1157531 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578441, finearea: '1157532 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578442, finearea: '1157533 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578443, finearea: '1157535 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578444, finearea: '1157536 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578445, finearea: '1157538 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578446, finearea: '1157539 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578447, finearea: '1157540 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578448, finearea: '1157541 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578449, finearea: '1157542 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578450, finearea: '1157543 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578451, finearea: '1157544 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578452, finearea: '1157545 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578453, finearea: '1157546 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578454, finearea: '1157547 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578455, finearea: '1157548 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578456, finearea: '1157549 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578457, finearea: '1157550 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578458, finearea: '1157551 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578459, finearea: '1157552 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578460, finearea: '1157553 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578461, finearea: '1157554 _ Strathfield (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 578462, finearea: '1157601 _ Strathfield (A)', subregion: 'Strathfield South', region: 'South and West Sydney' },
  { geography: 578463, finearea: '1157602 _ Strathfield (A)', subregion: 'Strathfield South', region: 'South and West Sydney' },
  { geography: 578464, finearea: '1157603 _ Strathfield (A)', subregion: 'Strathfield South', region: 'South and West Sydney' },
  { geography: 578465, finearea: '1157604 _ Strathfield (A)', subregion: 'Strathfield South', region: 'South and West Sydney' },
  { geography: 578466, finearea: '1157605 _ Strathfield (A)', subregion: 'Strathfield South', region: 'South and West Sydney' },
  { geography: 578467, finearea: '1157606 _ Strathfield (A)', subregion: 'Strathfield South', region: 'South and West Sydney' },
  { geography: 578468, finearea: '1157607 _ Strathfield (A)', subregion: 'Strathfield South', region: 'South and West Sydney' },
  { geography: 578469, finearea: '1157608 _ Strathfield (A)', subregion: 'Strathfield South', region: 'South and West Sydney' },
  { geography: 578470, finearea: '1114716 _ Sutherland Shire (A)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 578471, finearea: '1143901 _ Sutherland Shire (A)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 578472, finearea: '1152434 _ Sutherland Shire (A)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 578473, finearea: '1152435 _ Sutherland Shire (A)', subregion: 'Holsworthy - Wattle Grove', region: 'South and West Sydney' },
  { geography: 578474, finearea: '1152901 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578475, finearea: '1152902 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578476, finearea: '1152903 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578477, finearea: '1152904 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578478, finearea: '1152905 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578479, finearea: '1152906 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578480, finearea: '1152907 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578481, finearea: '1152908 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578482, finearea: '1152909 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578483, finearea: '1152910 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578484, finearea: '1152911 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578485, finearea: '1152912 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578486, finearea: '1152913 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578487, finearea: '1152914 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578488, finearea: '1152915 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578489, finearea: '1152916 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578490, finearea: '1152917 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578491, finearea: '1152918 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578492, finearea: '1152919 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578493, finearea: '1152920 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578494, finearea: '1152921 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578495, finearea: '1152922 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578496, finearea: '1152923 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578497, finearea: '1152924 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578498, finearea: '1152925 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578499, finearea: '1152926 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578500, finearea: '1152927 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578501, finearea: '1152928 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578502, finearea: '1152929 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578503, finearea: '1152930 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578504, finearea: '1152931 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578505, finearea: '1152932 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578506, finearea: '1152933 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578507, finearea: '1152934 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578508, finearea: '1152935 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578509, finearea: '1152936 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578510, finearea: '1152937 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578511, finearea: '1152938 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578512, finearea: '1152939 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578513, finearea: '1152940 _ Sutherland Shire (A)', subregion: 'Gymea - Grays Point', region: 'South and West Sydney' },
  { geography: 578514, finearea: '1153001 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578515, finearea: '1153002 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578516, finearea: '1153003 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578517, finearea: '1153004 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578518, finearea: '1153005 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578519, finearea: '1153006 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578520, finearea: '1153007 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578521, finearea: '1153008 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578522, finearea: '1153009 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578523, finearea: '1153010 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578524, finearea: '1153011 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578525, finearea: '1153012 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578526, finearea: '1153013 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578527, finearea: '1153014 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 574728, finearea: '1142507 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574729, finearea: '1142508 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574730, finearea: '1142509 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574731, finearea: '1142510 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574732, finearea: '1142511 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574733, finearea: '1142512 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574734, finearea: '1142513 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574735, finearea: '1142514 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574736, finearea: '1142515 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574737, finearea: '1142516 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574738, finearea: '1142517 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574739, finearea: '1142518 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574740, finearea: '1142519 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574741, finearea: '1142520 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574742, finearea: '1142521 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574743, finearea: '1142522 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574744, finearea: '1142523 _ Northern Beaches (A)', subregion: 'Cromer', region: 'Northern Sydney' },
  { geography: 574745, finearea: '1142601 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574746, finearea: '1142602 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574747, finearea: '1142603 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574748, finearea: '1142604 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574749, finearea: '1142605 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574750, finearea: '1142606 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574751, finearea: '1142607 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574752, finearea: '1142608 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574753, finearea: '1142609 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574754, finearea: '1142610 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574755, finearea: '1142611 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574756, finearea: '1142612 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574757, finearea: '1142613 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574758, finearea: '1142614 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574759, finearea: '1142615 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574760, finearea: '1142616 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574761, finearea: '1142617 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574762, finearea: '1142618 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574763, finearea: '1142619 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574764, finearea: '1142620 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574765, finearea: '1142621 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574766, finearea: '1142622 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574767, finearea: '1142623 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574768, finearea: '1142624 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574769, finearea: '1142625 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574770, finearea: '1142626 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574771, finearea: '1142627 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574772, finearea: '1142628 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574773, finearea: '1142629 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574774, finearea: '1142630 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574775, finearea: '1142631 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574776, finearea: '1142632 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574777, finearea: '1142633 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574778, finearea: '1142634 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574779, finearea: '1142635 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574780, finearea: '1142636 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574781, finearea: '1142637 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574782, finearea: '1142638 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574783, finearea: '1142639 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574784, finearea: '1142640 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574785, finearea: '1142641 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574786, finearea: '1142642 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574787, finearea: '1142643 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574788, finearea: '1142644 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574789, finearea: '1142645 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574790, finearea: '1142646 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574791, finearea: '1142647 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574792, finearea: '1142648 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574793, finearea: '1142649 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574794, finearea: '1142650 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574795, finearea: '1142651 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574796, finearea: '1142652 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574797, finearea: '1142653 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574798, finearea: '1142654 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574799, finearea: '1142655 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574800, finearea: '1142656 _ Northern Beaches (A)', subregion: 'Dee Why - North Curl Curl', region: 'Northern Sydney' },
  { geography: 574801, finearea: '1142701 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574802, finearea: '1142702 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574803, finearea: '1142703 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574804, finearea: '1142704 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574805, finearea: '1142705 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574806, finearea: '1142706 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574807, finearea: '1142707 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574808, finearea: '1142708 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574809, finearea: '1142709 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574810, finearea: '1142710 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574811, finearea: '1142711 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574812, finearea: '1142712 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574813, finearea: '1142713 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574814, finearea: '1142714 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574815, finearea: '1142715 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574816, finearea: '1142716 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574817, finearea: '1142717 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574818, finearea: '1142718 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574819, finearea: '1142719 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574820, finearea: '1142720 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574821, finearea: '1142721 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574822, finearea: '1142722 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574823, finearea: '1142723 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574824, finearea: '1142724 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574825, finearea: '1142725 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574826, finearea: '1142726 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574827, finearea: '1142727 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574828, finearea: '1142728 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574829, finearea: '1142729 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574830, finearea: '1142730 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574831, finearea: '1142731 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574832, finearea: '1142732 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574833, finearea: '1142733 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574834, finearea: '1142734 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574835, finearea: '1142735 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574836, finearea: '1142736 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574837, finearea: '1142737 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574838, finearea: '1142738 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574839, finearea: '1142739 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574840, finearea: '1142740 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574841, finearea: '1142741 _ Northern Beaches (A)', subregion: 'Forestville - Killarney Heights', region: 'Northern Sydney' },
  { geography: 574842, finearea: '1142801 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574843, finearea: '1142802 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574844, finearea: '1142803 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574845, finearea: '1142804 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574846, finearea: '1142805 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574847, finearea: '1142806 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574848, finearea: '1142807 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574849, finearea: '1142808 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574850, finearea: '1142809 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574851, finearea: '1142810 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574852, finearea: '1142811 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574853, finearea: '1142812 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574854, finearea: '1142813 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574855, finearea: '1142814 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574856, finearea: '1142815 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574857, finearea: '1142816 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574858, finearea: '1142817 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574859, finearea: '1142818 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574860, finearea: '1142819 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574861, finearea: '1142820 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574862, finearea: '1142821 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574863, finearea: '1142822 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574864, finearea: '1142823 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574865, finearea: '1142824 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574866, finearea: '1142825 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574867, finearea: '1142826 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574868, finearea: '1142827 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574869, finearea: '1142828 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574870, finearea: '1142829 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574871, finearea: '1142830 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574872, finearea: '1142831 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574873, finearea: '1142832 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574874, finearea: '1142833 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574875, finearea: '1142834 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574876, finearea: '1142835 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574877, finearea: '1142836 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574878, finearea: '1142837 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574879, finearea: '1142838 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574880, finearea: '1142839 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574881, finearea: '1142840 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574882, finearea: '1142841 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574883, finearea: '1142842 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574884, finearea: '1142843 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574885, finearea: '1142844 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574886, finearea: '1142845 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574887, finearea: '1142846 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574888, finearea: '1142847 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574889, finearea: '1142848 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574890, finearea: '1142849 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574891, finearea: '1142850 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574892, finearea: '1142851 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574893, finearea: '1142852 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574894, finearea: '1142853 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574895, finearea: '1142854 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574896, finearea: '1142855 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574897, finearea: '1142856 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574898, finearea: '1142857 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574899, finearea: '1142858 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574900, finearea: '1142859 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574901, finearea: '1142860 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574902, finearea: '1142861 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574903, finearea: '1142862 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574904, finearea: '1142863 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574905, finearea: '1142864 _ Northern Beaches (A)', subregion: 'Frenchs Forest - Belrose', region: 'Northern Sydney' },
  { geography: 574906, finearea: '1142901 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574907, finearea: '1142902 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574908, finearea: '1142903 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574909, finearea: '1142904 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574910, finearea: '1142905 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574911, finearea: '1142906 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574912, finearea: '1142907 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574913, finearea: '1142908 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574914, finearea: '1142909 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574915, finearea: '1142910 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574916, finearea: '1142911 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574917, finearea: '1142912 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574918, finearea: '1142913 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574919, finearea: '1142914 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574920, finearea: '1142915 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574921, finearea: '1142916 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574922, finearea: '1142917 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574923, finearea: '1142918 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574924, finearea: '1142919 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574925, finearea: '1142920 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574926, finearea: '1142921 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 574927, finearea: '1142922 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 578928, finearea: '1153833 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578929, finearea: '1153834 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578930, finearea: '1153835 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578931, finearea: '1153836 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578932, finearea: '1153837 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578933, finearea: '1153838 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578934, finearea: '1153839 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578935, finearea: '1153840 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578936, finearea: '1153841 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578937, finearea: '1153842 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578938, finearea: '1153843 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578939, finearea: '1153844 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578940, finearea: '1153845 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578941, finearea: '1153846 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578942, finearea: '1153847 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578943, finearea: '1153848 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578944, finearea: '1153849 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578945, finearea: '1153850 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578946, finearea: '1153851 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578947, finearea: '1153852 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578948, finearea: '1153853 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578949, finearea: '1160701 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578950, finearea: '1160702 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578951, finearea: '1160703 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578952, finearea: '1160704 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578953, finearea: '1160705 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578954, finearea: '1160706 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578955, finearea: '1160707 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578956, finearea: '1160708 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578957, finearea: '1160709 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578958, finearea: '1160710 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578959, finearea: '1160711 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578960, finearea: '1160712 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578961, finearea: '1160713 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578962, finearea: '1160714 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578963, finearea: '1160715 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578964, finearea: '1160716 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578965, finearea: '1160717 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578966, finearea: '1160718 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578967, finearea: '1160719 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578968, finearea: '1160720 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578969, finearea: '1160721 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578970, finearea: '1160722 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578971, finearea: '1160723 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578972, finearea: '1160724 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578973, finearea: '1160725 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578974, finearea: '1160726 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578975, finearea: '1160727 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578976, finearea: '1160728 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578977, finearea: '1160729 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578978, finearea: '1160730 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578979, finearea: '1160731 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578980, finearea: '1160732 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578981, finearea: '1160733 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578982, finearea: '1160734 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578983, finearea: '1160735 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578984, finearea: '1160736 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578985, finearea: '1160737 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578986, finearea: '1160738 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578987, finearea: '1160739 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578988, finearea: '1160740 _ Sutherland Shire (A)', subregion: 'Engadine', region: 'South and West Sydney' },
  { geography: 578989, finearea: '1160801 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578990, finearea: '1160802 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578991, finearea: '1160803 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578992, finearea: '1160804 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578993, finearea: '1160805 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578994, finearea: '1160806 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578995, finearea: '1160807 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578996, finearea: '1160808 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578997, finearea: '1160809 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578998, finearea: '1160810 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 578999, finearea: '1160811 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 579000, finearea: '1160812 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 579001, finearea: '1160813 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 579002, finearea: '1160814 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 579003, finearea: '1160815 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 579004, finearea: '1160816 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 579005, finearea: '1160817 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 579006, finearea: '1160818 _ Sutherland Shire (A)', subregion: 'Loftus - Yarrawarrah', region: 'South and West Sydney' },
  { geography: 579007, finearea: '1160901 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579008, finearea: '1160902 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579009, finearea: '1160903 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579010, finearea: '1160904 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579011, finearea: '1160905 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579012, finearea: '1160906 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579013, finearea: '1160907 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579014, finearea: '1160908 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579015, finearea: '1160909 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579016, finearea: '1160910 _ Sutherland Shire (A)', subregion: 'Woronora Heights', region: 'South and West Sydney' },
  { geography: 579017, finearea: '1132805 _ Sydney (C)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 579018, finearea: '1132901 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579019, finearea: '1132902 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579020, finearea: '1132903 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579021, finearea: '1132904 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579022, finearea: '1132905 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579023, finearea: '1132906 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579024, finearea: '1132907 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579025, finearea: '1132908 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579026, finearea: '1132909 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579027, finearea: '1132910 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579028, finearea: '1132911 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579029, finearea: '1132912 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579030, finearea: '1132913 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579031, finearea: '1132914 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579032, finearea: '1132915 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579033, finearea: '1132916 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579034, finearea: '1132917 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579035, finearea: '1132918 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579036, finearea: '1132919 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579037, finearea: '1132920 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579038, finearea: '1132921 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579039, finearea: '1132922 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579040, finearea: '1132923 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579041, finearea: '1132924 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579042, finearea: '1132925 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579043, finearea: '1132926 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579044, finearea: '1132927 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579045, finearea: '1132928 _ Sydney (C)', subregion: 'Darlinghurst', region: 'Inner Sydney' },
  { geography: 579046, finearea: '1133001 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579047, finearea: '1133003 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579048, finearea: '1133004 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579049, finearea: '1133005 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579050, finearea: '1133006 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579051, finearea: '1133007 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579052, finearea: '1133008 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579053, finearea: '1133009 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579054, finearea: '1133010 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579055, finearea: '1133011 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579056, finearea: '1133013 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579057, finearea: '1133014 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579058, finearea: '1133015 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579059, finearea: '1133016 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579060, finearea: '1133017 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579061, finearea: '1133018 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579062, finearea: '1133020 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579063, finearea: '1133021 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579064, finearea: '1133022 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579065, finearea: '1133023 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579066, finearea: '1133026 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579067, finearea: '1133027 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579068, finearea: '1133028 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579069, finearea: '1133029 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579070, finearea: '1133030 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579071, finearea: '1133031 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579072, finearea: '1133032 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579073, finearea: '1133033 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579074, finearea: '1133034 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579075, finearea: '1133035 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579076, finearea: '1133036 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579077, finearea: '1133037 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579078, finearea: '1133038 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579079, finearea: '1133039 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579080, finearea: '1133040 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579081, finearea: '1133041 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579082, finearea: '1133042 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579083, finearea: '1133043 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579084, finearea: '1133044 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579085, finearea: '1133045 _ Sydney (C)', subregion: 'Erskineville - Alexandria', region: 'Inner Sydney' },
  { geography: 579086, finearea: '1133101 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579087, finearea: '1133102 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579088, finearea: '1133103 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579089, finearea: '1133105 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579090, finearea: '1133106 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579091, finearea: '1133107 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579092, finearea: '1133108 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579093, finearea: '1133109 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579094, finearea: '1133110 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579095, finearea: '1133111 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579096, finearea: '1133112 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579097, finearea: '1133113 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579098, finearea: '1133114 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579099, finearea: '1133115 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579100, finearea: '1133116 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579101, finearea: '1133117 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579102, finearea: '1133118 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579103, finearea: '1133119 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579104, finearea: '1133120 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579105, finearea: '1133121 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579106, finearea: '1133122 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579107, finearea: '1133123 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579108, finearea: '1133124 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579109, finearea: '1133125 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579110, finearea: '1133126 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579111, finearea: '1133127 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579112, finearea: '1133128 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579113, finearea: '1133129 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579114, finearea: '1133130 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579115, finearea: '1133131 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579116, finearea: '1133132 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579117, finearea: '1133133 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579118, finearea: '1133134 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579119, finearea: '1133135 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579120, finearea: '1133136 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579121, finearea: '1133137 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579122, finearea: '1133138 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579123, finearea: '1133139 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579124, finearea: '1133140 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579125, finearea: '1133141 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579126, finearea: '1133142 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579127, finearea: '1133143 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 572437, finearea: '1111557 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572438, finearea: '1111558 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572439, finearea: '1111559 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572440, finearea: '1111561 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572441, finearea: '1111562 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572442, finearea: '1111563 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572443, finearea: '1111564 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572444, finearea: '1111565 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572445, finearea: '1111566 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572446, finearea: '1111567 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572447, finearea: '1111568 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572448, finearea: '1111569 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572449, finearea: '1111570 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572450, finearea: '1111571 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572451, finearea: '1111572 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572452, finearea: '1111573 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572453, finearea: '1111601 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572454, finearea: '1111602 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572455, finearea: '1111603 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572456, finearea: '1111604 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572457, finearea: '1111605 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572458, finearea: '1111606 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572459, finearea: '1111607 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572460, finearea: '1111608 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572461, finearea: '1111609 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572462, finearea: '1111610 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572463, finearea: '1111611 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572464, finearea: '1111612 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572465, finearea: '1111613 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572466, finearea: '1111614 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572467, finearea: '1111615 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572468, finearea: '1111616 _ Maitland (C)', subregion: 'Maitland - North', region: 'Central Coast and Hunter' },
  { geography: 572469, finearea: '1111701 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572470, finearea: '1111702 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572471, finearea: '1111704 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572472, finearea: '1111705 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572473, finearea: '1111706 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572474, finearea: '1111707 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572475, finearea: '1111708 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572476, finearea: '1111709 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572477, finearea: '1111710 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572478, finearea: '1111711 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572479, finearea: '1111712 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572480, finearea: '1111713 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572481, finearea: '1111715 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572482, finearea: '1111716 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572483, finearea: '1111717 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572484, finearea: '1111718 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572485, finearea: '1111719 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572486, finearea: '1111720 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572487, finearea: '1111721 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572488, finearea: '1111722 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572489, finearea: '1111723 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572490, finearea: '1111724 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572491, finearea: '1111725 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572492, finearea: '1111726 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572493, finearea: '1111727 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572494, finearea: '1111728 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572495, finearea: '1111730 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572496, finearea: '1111731 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572497, finearea: '1111732 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572498, finearea: '1111733 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572499, finearea: '1111734 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572500, finearea: '1111735 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572501, finearea: '1111736 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572502, finearea: '1111737 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572503, finearea: '1111738 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572504, finearea: '1111739 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572505, finearea: '1111740 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572506, finearea: '1111741 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572507, finearea: '1111742 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572508, finearea: '1111743 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572509, finearea: '1111744 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572510, finearea: '1111745 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572511, finearea: '1111746 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572512, finearea: '1111747 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572513, finearea: '1111748 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572514, finearea: '1111749 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572515, finearea: '1111750 _ Maitland (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 572516, finearea: '1111801 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 572517, finearea: '1111802 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 572518, finearea: '1111803 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 572519, finearea: '1111804 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 572520, finearea: '1111805 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 572521, finearea: '1111806 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 572522, finearea: '1111807 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 572523, finearea: '1111808 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 572524, finearea: '1111809 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 572525, finearea: '1111810 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573217, finearea: '1111811 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573218, finearea: '1111812 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573219, finearea: '1111813 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573220, finearea: '1111814 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573221, finearea: '1111815 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573222, finearea: '1111816 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573223, finearea: '1111817 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573224, finearea: '1111819 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573225, finearea: '1111820 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573226, finearea: '1111821 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573227, finearea: '1111822 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573228, finearea: '1111823 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573229, finearea: '1111824 _ Maitland (C)', subregion: 'Thornton - Millers Forest', region: 'Central Coast and Hunter' },
  { geography: 573230, finearea: '1122301 _ Maitland (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573231, finearea: '1122315 _ Maitland (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573232, finearea: '1122317 _ Maitland (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573233, finearea: '1122318 _ Maitland (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573234, finearea: '1122319 _ Maitland (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573235, finearea: '1122320 _ Maitland (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573236, finearea: '1122321 _ Maitland (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573237, finearea: '1122322 _ Maitland (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573238, finearea: '1122323 _ Maitland (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573239, finearea: '1112401 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573240, finearea: '1112402 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573241, finearea: '1112403 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573242, finearea: '1112404 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573243, finearea: '1112405 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573244, finearea: '1112406 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573245, finearea: '1112407 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573246, finearea: '1112408 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573247, finearea: '1112409 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573248, finearea: '1112410 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573249, finearea: '1112411 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573250, finearea: '1112412 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573251, finearea: '1112413 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573252, finearea: '1112414 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573253, finearea: '1112415 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573254, finearea: '1112416 _ Mid-Coast (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 573255, finearea: '1112510 _ Mid-Coast (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 573256, finearea: '1115101 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573257, finearea: '1115102 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573258, finearea: '1115103 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573259, finearea: '1115104 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573260, finearea: '1115105 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573261, finearea: '1115106 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573262, finearea: '1115107 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573263, finearea: '1115108 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573264, finearea: '1115109 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573265, finearea: '1115110 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573266, finearea: '1115111 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573267, finearea: '1115112 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573268, finearea: '1115113 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573269, finearea: '1115114 _ Mid-Coast (A)', subregion: 'Bulahdelah - Stroud', region: 'Central Coast and Hunter' },
  { geography: 573270, finearea: '1115201 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573271, finearea: '1115202 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573272, finearea: '1115203 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573273, finearea: '1115204 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573274, finearea: '1115205 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573275, finearea: '1115206 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573276, finearea: '1115207 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573277, finearea: '1115208 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573278, finearea: '1115209 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573279, finearea: '1115210 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573280, finearea: '1115211 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573281, finearea: '1115212 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573282, finearea: '1115213 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573283, finearea: '1115214 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573284, finearea: '1115215 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573285, finearea: '1115216 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573286, finearea: '1115217 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573287, finearea: '1115218 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573288, finearea: '1115219 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573289, finearea: '1115220 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573290, finearea: '1115221 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573291, finearea: '1115222 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573292, finearea: '1115223 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573293, finearea: '1115224 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573294, finearea: '1115225 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573295, finearea: '1115226 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573296, finearea: '1115227 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573297, finearea: '1115228 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573298, finearea: '1115229 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573299, finearea: '1115230 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573300, finearea: '1115231 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573301, finearea: '1115232 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573302, finearea: '1115233 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573303, finearea: '1115234 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573304, finearea: '1115235 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573305, finearea: '1115236 _ Mid-Coast (A)', subregion: 'Forster', region: 'Regional NSW - Remainder' },
  { geography: 573306, finearea: '1115301 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573307, finearea: '1115302 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573308, finearea: '1115303 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573309, finearea: '1115304 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573310, finearea: '1115305 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573311, finearea: '1115306 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573312, finearea: '1115307 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573313, finearea: '1115308 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573314, finearea: '1115309 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573315, finearea: '1115310 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573316, finearea: '1115311 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573317, finearea: '1115312 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573318, finearea: '1115313 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573319, finearea: '1115314 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573320, finearea: '1115315 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573321, finearea: '1115316 _ Mid-Coast (A)', subregion: 'Forster-Tuncurry Region', region: 'Regional NSW - Remainder' },
  { geography: 573322, finearea: '1115401 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573323, finearea: '1115402 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573324, finearea: '1115403 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573325, finearea: '1115404 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573326, finearea: '1115405 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573327, finearea: '1115406 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 575528, finearea: '1147713 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575529, finearea: '1147714 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575530, finearea: '1147715 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575531, finearea: '1147716 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575532, finearea: '1147717 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575533, finearea: '1147718 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575534, finearea: '1147719 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575535, finearea: '1147720 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575536, finearea: '1147721 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575537, finearea: '1147722 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575538, finearea: '1147723 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575539, finearea: '1147724 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575540, finearea: '1147725 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575541, finearea: '1147726 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575542, finearea: '1147727 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575543, finearea: '1147728 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575544, finearea: '1147729 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575545, finearea: '1147730 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575546, finearea: '1147731 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575547, finearea: '1147732 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575548, finearea: '1147733 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575549, finearea: '1147734 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575550, finearea: '1147735 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575551, finearea: '1147736 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575552, finearea: '1147737 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575553, finearea: '1147738 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575554, finearea: '1147739 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575555, finearea: '1147740 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575556, finearea: '1147741 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575557, finearea: '1147742 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575558, finearea: '1147743 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575559, finearea: '1147744 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575560, finearea: '1147745 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575561, finearea: '1147746 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575562, finearea: '1147747 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575563, finearea: '1147748 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575564, finearea: '1147749 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575565, finearea: '1147750 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575566, finearea: '1147751 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575567, finearea: '1147752 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575568, finearea: '1147801 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575569, finearea: '1147802 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575570, finearea: '1147803 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575571, finearea: '1147804 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575572, finearea: '1147805 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575573, finearea: '1147806 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575574, finearea: '1147807 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575575, finearea: '1147808 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575576, finearea: '1147809 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575577, finearea: '1147810 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575578, finearea: '1147811 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575579, finearea: '1147812 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575580, finearea: '1147813 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575581, finearea: '1147814 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575582, finearea: '1147815 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575583, finearea: '1147816 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575584, finearea: '1147817 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575585, finearea: '1147818 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575586, finearea: '1147819 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575587, finearea: '1147820 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575588, finearea: '1147821 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575589, finearea: '1147822 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575590, finearea: '1147823 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575591, finearea: '1147824 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575592, finearea: '1147825 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575593, finearea: '1147826 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575594, finearea: '1147827 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575595, finearea: '1147828 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575596, finearea: '1147829 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575597, finearea: '1147830 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575598, finearea: '1147831 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575599, finearea: '1147832 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575600, finearea: '1147833 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575601, finearea: '1147834 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575602, finearea: '1147835 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575603, finearea: '1147836 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575604, finearea: '1147837 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575605, finearea: '1147838 _ Parramatta (C)', subregion: 'Oatlands - Dundas Valley', region: 'Northern Sydney' },
  { geography: 575606, finearea: '1148134 _ Parramatta (C)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 575607, finearea: '1148135 _ Parramatta (C)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 575608, finearea: '1148136 _ Parramatta (C)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 575609, finearea: '1148137 _ Parramatta (C)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 575610, finearea: '1148144 _ Parramatta (C)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 575611, finearea: '1148146 _ Parramatta (C)', subregion: 'Granville - Clyde', region: 'South and West Sydney' },
  { geography: 575612, finearea: '1148901 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575613, finearea: '1148902 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575614, finearea: '1148903 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575615, finearea: '1148904 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575616, finearea: '1148905 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575617, finearea: '1148906 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575618, finearea: '1148907 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575619, finearea: '1148908 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575620, finearea: '1148909 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575621, finearea: '1148910 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575622, finearea: '1148911 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575623, finearea: '1148912 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575624, finearea: '1148913 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575625, finearea: '1148914 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575626, finearea: '1148915 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575627, finearea: '1148916 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575628, finearea: '1148917 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575629, finearea: '1148918 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575630, finearea: '1148919 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575631, finearea: '1148920 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575632, finearea: '1148921 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575633, finearea: '1148922 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575634, finearea: '1148923 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575635, finearea: '1148924 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575636, finearea: '1148925 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575637, finearea: '1148926 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575638, finearea: '1148927 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575639, finearea: '1148928 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575640, finearea: '1148929 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575641, finearea: '1148930 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575642, finearea: '1148931 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575643, finearea: '1148932 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575644, finearea: '1148933 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575645, finearea: '1148934 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575646, finearea: '1148935 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575647, finearea: '1148936 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575648, finearea: '1148937 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575649, finearea: '1148938 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575650, finearea: '1148939 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575651, finearea: '1148940 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575652, finearea: '1148941 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575653, finearea: '1148942 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575654, finearea: '1148943 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575655, finearea: '1148944 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575656, finearea: '1148945 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575657, finearea: '1148946 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575658, finearea: '1148947 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575659, finearea: '1148948 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575660, finearea: '1148949 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575661, finearea: '1148950 _ Parramatta (C)', subregion: 'North Parramatta', region: 'Northern Sydney' },
  { geography: 575662, finearea: '1149001 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575663, finearea: '1149002 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575664, finearea: '1149003 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575665, finearea: '1149004 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575666, finearea: '1149005 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575667, finearea: '1149006 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575668, finearea: '1149007 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575669, finearea: '1149008 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575670, finearea: '1149009 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575671, finearea: '1149010 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575672, finearea: '1149011 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575673, finearea: '1149012 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575674, finearea: '1149013 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575675, finearea: '1149014 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575676, finearea: '1149015 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575677, finearea: '1149016 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575678, finearea: '1149017 _ Parramatta (C)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 575679, finearea: '1149101 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575680, finearea: '1149102 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575681, finearea: '1149103 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575682, finearea: '1149104 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575683, finearea: '1149105 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575684, finearea: '1149110 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575685, finearea: '1149111 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575686, finearea: '1149112 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575687, finearea: '1149113 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575688, finearea: '1149114 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575689, finearea: '1149115 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575690, finearea: '1149116 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575691, finearea: '1149117 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575692, finearea: '1149118 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575693, finearea: '1149119 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575694, finearea: '1149120 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575695, finearea: '1149121 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575696, finearea: '1149122 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575697, finearea: '1149123 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575698, finearea: '1149124 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575699, finearea: '1149125 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575700, finearea: '1149126 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575701, finearea: '1149127 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575702, finearea: '1149128 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575703, finearea: '1149130 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575704, finearea: '1149133 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575705, finearea: '1149134 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575706, finearea: '1149136 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575707, finearea: '1149137 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575708, finearea: '1149138 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575709, finearea: '1149139 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575710, finearea: '1149140 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575711, finearea: '1149141 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575712, finearea: '1149142 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575713, finearea: '1149143 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575714, finearea: '1149144 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575715, finearea: '1149145 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575716, finearea: '1149146 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575717, finearea: '1149147 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575718, finearea: '1149148 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575719, finearea: '1149149 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575720, finearea: '1149150 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575721, finearea: '1149151 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575722, finearea: '1149152 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575723, finearea: '1149153 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575724, finearea: '1149154 _ Parramatta (C)', subregion: 'Northmead', region: 'Northern Sydney' },
  { geography: 575725, finearea: '1149201 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575726, finearea: '1149202 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 575727, finearea: '1149203 _ Parramatta (C)', subregion: 'Parramatta - Rosehill', region: 'Northern Sydney' },
  { geography: 568437, finearea: '1151123 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568438, finearea: '1151124 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568439, finearea: '1151125 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568440, finearea: '1151126 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568441, finearea: '1151127 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568442, finearea: '1151128 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568443, finearea: '1151129 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568444, finearea: '1151130 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568445, finearea: '1151131 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568446, finearea: '1151132 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568447, finearea: '1151133 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568448, finearea: '1151134 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568449, finearea: '1151135 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568450, finearea: '1151136 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568451, finearea: '1151137 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568452, finearea: '1151138 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568453, finearea: '1151139 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568454, finearea: '1151140 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568455, finearea: '1151141 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568456, finearea: '1151142 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568457, finearea: '1151143 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568458, finearea: '1151144 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568459, finearea: '1151145 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568460, finearea: '1151146 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568461, finearea: '1151147 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568462, finearea: '1151148 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568463, finearea: '1151149 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568464, finearea: '1151150 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568465, finearea: '1151151 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568466, finearea: '1151152 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568467, finearea: '1151153 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568468, finearea: '1151154 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568469, finearea: '1151155 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568470, finearea: '1151156 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568471, finearea: '1151201 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568472, finearea: '1151202 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568473, finearea: '1151203 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568474, finearea: '1151204 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568475, finearea: '1151205 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568476, finearea: '1151206 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568477, finearea: '1151207 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568478, finearea: '1151208 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568479, finearea: '1151209 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568480, finearea: '1151210 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568481, finearea: '1151211 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568482, finearea: '1151212 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568483, finearea: '1151213 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568484, finearea: '1151214 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568485, finearea: '1151215 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568486, finearea: '1151216 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568487, finearea: '1151217 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568488, finearea: '1151218 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568489, finearea: '1151219 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568490, finearea: '1151220 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568491, finearea: '1151222 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568492, finearea: '1151223 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568493, finearea: '1151224 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568494, finearea: '1151225 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568495, finearea: '1151226 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568496, finearea: '1151227 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568497, finearea: '1151228 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568498, finearea: '1151229 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568499, finearea: '1151230 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568500, finearea: '1151231 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568501, finearea: '1151232 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568502, finearea: '1151233 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568503, finearea: '1151234 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568504, finearea: '1151235 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568505, finearea: '1151236 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568506, finearea: '1151237 _ Fairfield (C)', subregion: 'Cabramatta West - Mount Pritchard', region: 'South and West Sydney' },
  { geography: 568507, finearea: '1151301 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568508, finearea: '1151302 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568509, finearea: '1151303 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568510, finearea: '1151304 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568511, finearea: '1151305 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568512, finearea: '1151306 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568513, finearea: '1151307 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568514, finearea: '1151308 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568515, finearea: '1151309 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568516, finearea: '1151310 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568517, finearea: '1151311 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568518, finearea: '1151312 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568519, finearea: '1151313 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568520, finearea: '1151314 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568521, finearea: '1151315 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568522, finearea: '1151316 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568523, finearea: '1151317 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568524, finearea: '1151318 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568525, finearea: '1151319 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568526, finearea: '1151320 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568527, finearea: '1151321 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568528, finearea: '1151322 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568529, finearea: '1151323 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568530, finearea: '1151324 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568531, finearea: '1151325 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568532, finearea: '1151326 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568533, finearea: '1151327 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568534, finearea: '1151328 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568535, finearea: '1151329 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568536, finearea: '1151330 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568537, finearea: '1151331 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568538, finearea: '1151332 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568539, finearea: '1151333 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568540, finearea: '1151334 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568541, finearea: '1151335 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568542, finearea: '1151336 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568543, finearea: '1151337 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568544, finearea: '1151338 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568545, finearea: '1151339 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568546, finearea: '1151340 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568547, finearea: '1151341 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568548, finearea: '1151342 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568549, finearea: '1151343 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568550, finearea: '1151344 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568551, finearea: '1151345 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568552, finearea: '1151346 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568553, finearea: '1151347 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568554, finearea: '1151348 _ Fairfield (C)', subregion: 'Canley Vale - Canley Heights', region: 'South and West Sydney' },
  { geography: 568555, finearea: '1151401 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568556, finearea: '1151402 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568557, finearea: '1151403 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568558, finearea: '1151404 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568559, finearea: '1151405 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568560, finearea: '1151406 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568561, finearea: '1151407 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568562, finearea: '1151408 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568563, finearea: '1151409 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568564, finearea: '1151410 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568565, finearea: '1151411 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568566, finearea: '1151412 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568567, finearea: '1151413 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568568, finearea: '1151414 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568569, finearea: '1151415 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568570, finearea: '1151416 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568571, finearea: '1151417 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568572, finearea: '1151418 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568573, finearea: '1151419 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568574, finearea: '1151420 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568575, finearea: '1151421 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568576, finearea: '1151422 _ Fairfield (C)', subregion: 'Edensor Park', region: 'South and West Sydney' },
  { geography: 568577, finearea: '1151501 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568578, finearea: '1151502 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568579, finearea: '1151503 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568580, finearea: '1151504 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568581, finearea: '1151505 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568582, finearea: '1151506 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568583, finearea: '1151507 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568584, finearea: '1151508 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568585, finearea: '1151509 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568586, finearea: '1151510 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568587, finearea: '1151511 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568588, finearea: '1151512 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568589, finearea: '1151513 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568590, finearea: '1151514 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568591, finearea: '1151515 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568592, finearea: '1151516 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568593, finearea: '1151517 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568594, finearea: '1151518 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568595, finearea: '1151519 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568596, finearea: '1151520 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568597, finearea: '1151521 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568598, finearea: '1151522 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568599, finearea: '1151523 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568600, finearea: '1151524 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568601, finearea: '1151525 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568602, finearea: '1151526 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568603, finearea: '1151527 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568604, finearea: '1151528 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568605, finearea: '1151529 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568606, finearea: '1151530 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568607, finearea: '1151531 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568608, finearea: '1151532 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568609, finearea: '1151533 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568610, finearea: '1151534 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568611, finearea: '1151535 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568612, finearea: '1151536 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568613, finearea: '1151537 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568614, finearea: '1151538 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568615, finearea: '1151539 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568616, finearea: '1151540 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568617, finearea: '1151541 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568618, finearea: '1151542 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568619, finearea: '1151543 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568620, finearea: '1151544 _ Fairfield (C)', subregion: 'Fairfield', region: 'South and West Sydney' },
  { geography: 568621, finearea: '1151601 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568622, finearea: '1151602 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568623, finearea: '1151603 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568624, finearea: '1151604 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568625, finearea: '1151605 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568626, finearea: '1151606 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568627, finearea: '1151607 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568628, finearea: '1151608 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568629, finearea: '1151609 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568630, finearea: '1151610 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568631, finearea: '1151611 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568632, finearea: '1151612 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568633, finearea: '1151613 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568634, finearea: '1151614 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568635, finearea: '1151615 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 568636, finearea: '1151616 _ Fairfield (C)', subregion: 'Fairfield - West', region: 'South and West Sydney' },
  { geography: 563637, finearea: '1156303 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563638, finearea: '1156304 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563639, finearea: '1156305 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563640, finearea: '1156306 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563641, finearea: '1156307 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563642, finearea: '1156308 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563643, finearea: '1156309 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563644, finearea: '1156310 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563645, finearea: '1156311 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563646, finearea: '1156312 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563647, finearea: '1156313 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563648, finearea: '1156314 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563649, finearea: '1156315 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563650, finearea: '1156316 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563651, finearea: '1156317 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563652, finearea: '1156318 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563653, finearea: '1156319 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563654, finearea: '1156320 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563655, finearea: '1156321 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563656, finearea: '1156322 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563657, finearea: '1156323 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563658, finearea: '1156324 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563659, finearea: '1156325 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563660, finearea: '1156326 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563661, finearea: '1156327 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563662, finearea: '1156328 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563663, finearea: '1156329 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563664, finearea: '1156330 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563665, finearea: '1156331 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563666, finearea: '1156332 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563667, finearea: '1156333 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563668, finearea: '1156334 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563669, finearea: '1156335 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563670, finearea: '1156336 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563671, finearea: '1156337 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563672, finearea: '1156338 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563673, finearea: '1156339 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563674, finearea: '1156340 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563675, finearea: '1156341 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563676, finearea: '1156342 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563677, finearea: '1156343 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563678, finearea: '1156344 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563679, finearea: '1156345 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563680, finearea: '1156346 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563681, finearea: '1156347 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563682, finearea: '1156348 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563683, finearea: '1156349 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563684, finearea: '1156350 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563685, finearea: '1156351 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563686, finearea: '1156352 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563687, finearea: '1156353 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563688, finearea: '1156354 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563689, finearea: '1156355 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563690, finearea: '1156356 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563691, finearea: '1156357 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563692, finearea: '1131301 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563693, finearea: '1131302 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563694, finearea: '1131303 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563695, finearea: '1131304 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563696, finearea: '1131305 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563697, finearea: '1131306 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563698, finearea: '1131307 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563699, finearea: '1131308 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563700, finearea: '1131309 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563701, finearea: '1131310 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563702, finearea: '1131311 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563703, finearea: '1131312 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563704, finearea: '1131313 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563705, finearea: '1131314 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563706, finearea: '1131315 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563707, finearea: '1131316 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563708, finearea: '1131317 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563709, finearea: '1131318 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563710, finearea: '1131319 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563711, finearea: '1131320 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563712, finearea: '1131321 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563713, finearea: '1131322 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563714, finearea: '1131323 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563715, finearea: '1131324 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563716, finearea: '1131325 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563717, finearea: '1131326 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563718, finearea: '1131327 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563719, finearea: '1131328 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563720, finearea: '1131329 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563721, finearea: '1131330 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563722, finearea: '1131331 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563723, finearea: '1131332 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563724, finearea: '1131333 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563725, finearea: '1131334 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563726, finearea: '1131335 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563727, finearea: '1131336 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563728, finearea: '1131337 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563729, finearea: '1131338 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563730, finearea: '1131339 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563731, finearea: '1131340 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563732, finearea: '1131341 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563733, finearea: '1131342 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563734, finearea: '1131343 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563735, finearea: '1131344 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563736, finearea: '1131345 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563737, finearea: '1131346 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563738, finearea: '1131347 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563739, finearea: '1131348 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563740, finearea: '1131349 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563741, finearea: '1131350 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563742, finearea: '1131351 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563743, finearea: '1131352 _ Blacktown (C)', subregion: 'Bidwill - Hebersham - Emerton', region: 'Northern Sydney' },
  { geography: 563744, finearea: '1131401 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563745, finearea: '1131402 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563746, finearea: '1131403 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563747, finearea: '1131404 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563748, finearea: '1131405 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563749, finearea: '1131406 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563750, finearea: '1131407 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563751, finearea: '1131408 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563752, finearea: '1131409 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563753, finearea: '1131410 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563754, finearea: '1131411 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563755, finearea: '1131412 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563756, finearea: '1131413 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563757, finearea: '1131414 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563758, finearea: '1131415 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563759, finearea: '1131416 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563760, finearea: '1131417 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563761, finearea: '1131418 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563762, finearea: '1131419 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563763, finearea: '1131420 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563764, finearea: '1131421 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563765, finearea: '1131422 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563766, finearea: '1131423 _ Blacktown (C)', subregion: 'Glendenning - Dean Park', region: 'Northern Sydney' },
  { geography: 563767, finearea: '1131501 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563768, finearea: '1131502 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563769, finearea: '1131503 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563770, finearea: '1131504 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563771, finearea: '1131505 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563772, finearea: '1131506 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563773, finearea: '1131507 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563774, finearea: '1131508 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563775, finearea: '1131509 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563776, finearea: '1131510 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563777, finearea: '1131511 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563778, finearea: '1131512 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563779, finearea: '1131513 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563780, finearea: '1131514 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563781, finearea: '1131515 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563782, finearea: '1131516 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563783, finearea: '1131517 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563784, finearea: '1131518 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563785, finearea: '1131519 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563786, finearea: '1131520 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563787, finearea: '1131521 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563788, finearea: '1131522 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563789, finearea: '1131523 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563790, finearea: '1131524 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563791, finearea: '1131525 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563792, finearea: '1131526 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563793, finearea: '1131527 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563794, finearea: '1131528 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563795, finearea: '1131529 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563796, finearea: '1131530 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563797, finearea: '1131531 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563798, finearea: '1131532 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563799, finearea: '1131533 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563800, finearea: '1131534 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563801, finearea: '1131535 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563802, finearea: '1131536 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563803, finearea: '1131537 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563804, finearea: '1131538 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563805, finearea: '1131539 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563806, finearea: '1131540 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563807, finearea: '1131541 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563808, finearea: '1131542 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563809, finearea: '1131543 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563810, finearea: '1131544 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563811, finearea: '1131545 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563812, finearea: '1131546 _ Blacktown (C)', subregion: 'Hassall Grove - Plumpton', region: 'Northern Sydney' },
  { geography: 563813, finearea: '1131601 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563814, finearea: '1131602 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563815, finearea: '1131603 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563816, finearea: '1131605 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563817, finearea: '1131606 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563818, finearea: '1131607 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563819, finearea: '1131608 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563820, finearea: '1131609 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563821, finearea: '1131610 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563822, finearea: '1131611 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563823, finearea: '1131612 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563824, finearea: '1131613 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563825, finearea: '1131614 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563826, finearea: '1131615 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563827, finearea: '1131616 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563828, finearea: '1131617 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563829, finearea: '1131618 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563830, finearea: '1131619 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563831, finearea: '1131620 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563832, finearea: '1131621 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563833, finearea: '1131622 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563834, finearea: '1131623 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563835, finearea: '1131624 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563836, finearea: '1131625 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 578728, finearea: '1160610 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578729, finearea: '1160611 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578730, finearea: '1160612 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578731, finearea: '1160613 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578732, finearea: '1160614 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578733, finearea: '1160615 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578734, finearea: '1160616 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578735, finearea: '1160617 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578736, finearea: '1160618 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578737, finearea: '1160619 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578738, finearea: '1160620 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578739, finearea: '1153301 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578740, finearea: '1153302 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578741, finearea: '1153303 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578742, finearea: '1153304 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578743, finearea: '1153305 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578744, finearea: '1153306 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578745, finearea: '1153307 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578746, finearea: '1153308 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578747, finearea: '1153309 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578748, finearea: '1153310 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578749, finearea: '1153311 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578750, finearea: '1153312 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578751, finearea: '1153313 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578752, finearea: '1153314 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578753, finearea: '1153315 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578754, finearea: '1153316 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578755, finearea: '1153317 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578756, finearea: '1153318 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578757, finearea: '1153401 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578758, finearea: '1153402 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578759, finearea: '1153403 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578760, finearea: '1153404 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578761, finearea: '1153405 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578762, finearea: '1153406 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578763, finearea: '1153407 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578764, finearea: '1153408 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578765, finearea: '1153409 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578766, finearea: '1153410 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578767, finearea: '1153411 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578768, finearea: '1153412 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578769, finearea: '1153413 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578770, finearea: '1153414 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578771, finearea: '1153415 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578772, finearea: '1153416 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578773, finearea: '1153417 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578774, finearea: '1153418 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578775, finearea: '1153419 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578776, finearea: '1153420 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578777, finearea: '1153421 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578778, finearea: '1153422 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578779, finearea: '1153423 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578780, finearea: '1153424 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578781, finearea: '1153425 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578782, finearea: '1153426 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578783, finearea: '1153427 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578784, finearea: '1153428 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578785, finearea: '1153429 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578786, finearea: '1153430 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578787, finearea: '1153431 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578788, finearea: '1153432 _ Sutherland Shire (A)', subregion: 'Illawong - Alfords Point', region: 'South and West Sydney' },
  { geography: 578789, finearea: '1153501 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578790, finearea: '1153502 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578791, finearea: '1153503 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578792, finearea: '1153504 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578793, finearea: '1153505 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578794, finearea: '1153506 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578795, finearea: '1153507 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578796, finearea: '1153508 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578797, finearea: '1153509 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578798, finearea: '1153510 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578799, finearea: '1153511 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578800, finearea: '1153512 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578801, finearea: '1153513 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578802, finearea: '1153514 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578803, finearea: '1153515 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578804, finearea: '1153516 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578805, finearea: '1153517 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578806, finearea: '1153518 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578807, finearea: '1153519 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578808, finearea: '1153520 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578809, finearea: '1153521 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578810, finearea: '1153522 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578811, finearea: '1153523 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578812, finearea: '1153524 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578813, finearea: '1153525 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578814, finearea: '1153526 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578815, finearea: '1153527 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578816, finearea: '1153528 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578817, finearea: '1153529 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578818, finearea: '1153530 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578819, finearea: '1153531 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578820, finearea: '1153532 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578821, finearea: '1153533 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578822, finearea: '1153534 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578823, finearea: '1153535 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578824, finearea: '1153536 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578825, finearea: '1153537 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578826, finearea: '1153538 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578827, finearea: '1153539 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578828, finearea: '1153540 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578829, finearea: '1153541 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578830, finearea: '1153542 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578831, finearea: '1153543 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578832, finearea: '1153544 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578833, finearea: '1153545 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578834, finearea: '1153546 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578835, finearea: '1153547 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578836, finearea: '1153548 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578837, finearea: '1153549 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578838, finearea: '1153550 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578839, finearea: '1153551 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578840, finearea: '1153552 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578841, finearea: '1153553 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578842, finearea: '1153554 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578843, finearea: '1153555 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578844, finearea: '1153556 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578845, finearea: '1153557 _ Sutherland Shire (A)', subregion: 'Menai - Lucas Heights - Woronora', region: 'South and West Sydney' },
  { geography: 578846, finearea: '1153601 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578847, finearea: '1153602 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578848, finearea: '1153603 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578849, finearea: '1153604 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578850, finearea: '1153605 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578851, finearea: '1153606 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578852, finearea: '1153607 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578853, finearea: '1153608 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578854, finearea: '1153609 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578855, finearea: '1153610 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578856, finearea: '1153611 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578857, finearea: '1153612 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578858, finearea: '1153613 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578859, finearea: '1153614 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578860, finearea: '1153615 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578861, finearea: '1153616 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578862, finearea: '1153617 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578863, finearea: '1153618 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578864, finearea: '1153619 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578865, finearea: '1153620 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578866, finearea: '1153621 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578867, finearea: '1153622 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578868, finearea: '1153623 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578869, finearea: '1153624 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578870, finearea: '1153625 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578871, finearea: '1153626 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578872, finearea: '1153627 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578873, finearea: '1153628 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578874, finearea: '1153629 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578875, finearea: '1153630 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578876, finearea: '1153631 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578877, finearea: '1153632 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578878, finearea: '1153633 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578879, finearea: '1153634 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578880, finearea: '1153635 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578881, finearea: '1153636 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578882, finearea: '1153637 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578883, finearea: '1153638 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578884, finearea: '1153639 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578885, finearea: '1153640 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578886, finearea: '1153641 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578887, finearea: '1153642 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578888, finearea: '1153643 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578889, finearea: '1153644 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578890, finearea: '1153645 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578891, finearea: '1153646 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578892, finearea: '1153647 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578893, finearea: '1153648 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578894, finearea: '1153649 _ Sutherland Shire (A)', subregion: 'Oyster Bay - Como - Jannali', region: 'South and West Sydney' },
  { geography: 578895, finearea: '1153701 _ Sutherland Shire (A)', subregion: 'Heathcote - Waterfall - Royal National Park', region: 'South and West Sydney' },
  { geography: 578896, finearea: '1153801 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578897, finearea: '1153802 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578898, finearea: '1153803 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578899, finearea: '1153804 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578900, finearea: '1153805 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578901, finearea: '1153806 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578902, finearea: '1153807 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578903, finearea: '1153808 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578904, finearea: '1153809 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578905, finearea: '1153810 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578906, finearea: '1153811 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578907, finearea: '1153812 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578908, finearea: '1153813 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578909, finearea: '1153814 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578910, finearea: '1153815 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578911, finearea: '1153816 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578912, finearea: '1153817 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578913, finearea: '1153818 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578914, finearea: '1153819 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578915, finearea: '1153820 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578916, finearea: '1153821 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578917, finearea: '1153822 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578918, finearea: '1153823 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578919, finearea: '1153824 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578920, finearea: '1153825 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578921, finearea: '1153826 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578922, finearea: '1153827 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578923, finearea: '1153828 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578924, finearea: '1153829 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578925, finearea: '1153830 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578926, finearea: '1153831 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 578927, finearea: '1153832 _ Sutherland Shire (A)', subregion: 'Sutherland - Kirrawee', region: 'South and West Sydney' },
  { geography: 573528, finearea: '1107301 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573529, finearea: '1107302 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573530, finearea: '1107303 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573531, finearea: '1107304 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573532, finearea: '1107305 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573533, finearea: '1107306 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573534, finearea: '1107307 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573535, finearea: '1107308 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573536, finearea: '1107309 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573537, finearea: '1107310 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573538, finearea: '1107311 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573539, finearea: '1107312 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573540, finearea: '1107313 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573541, finearea: '1107314 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - East', region: 'Regional NSW - Remainder' },
  { geography: 573542, finearea: '1107401 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573543, finearea: '1107402 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573544, finearea: '1107403 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573545, finearea: '1107407 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573546, finearea: '1107408 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573547, finearea: '1107409 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573548, finearea: '1107410 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573549, finearea: '1107411 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573550, finearea: '1107412 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573551, finearea: '1107413 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573552, finearea: '1107414 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573553, finearea: '1107415 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573554, finearea: '1107416 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573555, finearea: '1107417 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573556, finearea: '1107418 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573557, finearea: '1107419 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573558, finearea: '1107420 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573559, finearea: '1107421 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573560, finearea: '1107422 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573561, finearea: '1107423 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573562, finearea: '1107425 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573563, finearea: '1107427 _ Mid-Western Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 573564, finearea: '1110616 _ Mid-Western Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 573565, finearea: '1119501 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573566, finearea: '1119502 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573567, finearea: '1119503 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573568, finearea: '1119504 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573569, finearea: '1119505 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573570, finearea: '1119506 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573571, finearea: '1119507 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573572, finearea: '1119508 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573573, finearea: '1119509 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573574, finearea: '1119510 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573575, finearea: '1119511 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573576, finearea: '1119512 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573577, finearea: '1119513 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573578, finearea: '1119514 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573579, finearea: '1119515 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573580, finearea: '1119516 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573581, finearea: '1119517 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573582, finearea: '1119518 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573583, finearea: '1119519 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573584, finearea: '1119520 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573585, finearea: '1119521 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573586, finearea: '1119522 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573587, finearea: '1119523 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573588, finearea: '1119524 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573589, finearea: '1119525 _ Moree Plains (A)', subregion: 'Moree', region: 'North East NSW' },
  { geography: 573590, finearea: '1119601 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573591, finearea: '1119602 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573592, finearea: '1119603 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573593, finearea: '1119604 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573594, finearea: '1119605 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573595, finearea: '1119606 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573596, finearea: '1119607 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573597, finearea: '1119608 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573598, finearea: '1119609 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573599, finearea: '1119610 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573600, finearea: '1119611 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573601, finearea: '1119613 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573602, finearea: '1119614 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573603, finearea: '1119615 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573604, finearea: '1119616 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573605, finearea: '1119617 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573606, finearea: '1119618 _ Moree Plains (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 573607, finearea: '1141501 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573608, finearea: '1141502 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573609, finearea: '1141503 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573610, finearea: '1141504 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573611, finearea: '1141505 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573612, finearea: '1141506 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573613, finearea: '1141507 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573614, finearea: '1141508 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573615, finearea: '1141509 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573616, finearea: '1141510 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573617, finearea: '1141511 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573618, finearea: '1141512 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573619, finearea: '1141513 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573620, finearea: '1141514 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573621, finearea: '1141515 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573622, finearea: '1141516 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573623, finearea: '1141517 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573624, finearea: '1141518 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573625, finearea: '1141519 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573626, finearea: '1141520 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573627, finearea: '1141521 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573628, finearea: '1141522 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573629, finearea: '1141523 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573630, finearea: '1141524 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573631, finearea: '1141525 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573632, finearea: '1141526 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573633, finearea: '1141527 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573634, finearea: '1141528 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573635, finearea: '1141529 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573636, finearea: '1141530 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573637, finearea: '1141531 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573638, finearea: '1141532 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573639, finearea: '1141533 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573640, finearea: '1141534 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573641, finearea: '1141535 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573642, finearea: '1141536 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573643, finearea: '1141537 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573644, finearea: '1141538 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573645, finearea: '1141539 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573646, finearea: '1141540 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573647, finearea: '1141541 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573648, finearea: '1141542 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573649, finearea: '1141543 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573650, finearea: '1141544 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573651, finearea: '1141545 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573652, finearea: '1141546 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573653, finearea: '1141547 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573654, finearea: '1141548 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573655, finearea: '1141549 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573656, finearea: '1141550 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573657, finearea: '1141551 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573658, finearea: '1141552 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573659, finearea: '1141553 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573660, finearea: '1141554 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573661, finearea: '1141555 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573662, finearea: '1141556 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573663, finearea: '1141557 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573664, finearea: '1141558 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573665, finearea: '1141559 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573666, finearea: '1141560 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573667, finearea: '1141561 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573668, finearea: '1141562 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573669, finearea: '1141563 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573670, finearea: '1141564 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573671, finearea: '1141565 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573672, finearea: '1141566 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573673, finearea: '1141567 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573674, finearea: '1141568 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573675, finearea: '1141569 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573676, finearea: '1141570 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573677, finearea: '1141571 _ Mosman (A)', subregion: 'Mosman', region: 'Northern Sydney' },
  { geography: 573678, finearea: '1117905 _ Murray River (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 573679, finearea: '1117907 _ Murray River (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 573680, finearea: '1117911 _ Murray River (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 573681, finearea: '1118302 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573682, finearea: '1118303 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573683, finearea: '1118304 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573684, finearea: '1118305 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573685, finearea: '1118306 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573686, finearea: '1118307 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573687, finearea: '1118308 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573688, finearea: '1118309 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573689, finearea: '1118310 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573690, finearea: '1118312 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573691, finearea: '1118313 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573692, finearea: '1118314 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573693, finearea: '1118315 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573694, finearea: '1118316 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573695, finearea: '1118317 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573696, finearea: '1118321 _ Murray River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 573697, finearea: '1118401 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573698, finearea: '1118402 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573699, finearea: '1118403 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573700, finearea: '1118404 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573701, finearea: '1118405 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573702, finearea: '1118406 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573703, finearea: '1118407 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573704, finearea: '1118408 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573705, finearea: '1118409 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573706, finearea: '1118410 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573707, finearea: '1118411 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573708, finearea: '1118413 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573709, finearea: '1118414 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573710, finearea: '1118415 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573711, finearea: '1118416 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573712, finearea: '1118417 _ Murray River (A)', subregion: 'Moama', region: 'Murray' },
  { geography: 573713, finearea: '1118514 _ Murrumbidgee (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 573714, finearea: '1118523 _ Murrumbidgee (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 573715, finearea: '1118524 _ Murrumbidgee (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 573716, finearea: '1118528 _ Murrumbidgee (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 573717, finearea: '1118529 _ Murrumbidgee (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 573718, finearea: '1118530 _ Murrumbidgee (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 573719, finearea: '1125713 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573720, finearea: '1125714 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573721, finearea: '1125715 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573722, finearea: '1125716 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573723, finearea: '1125731 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573724, finearea: '1125732 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573725, finearea: '1125733 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573726, finearea: '1125734 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573727, finearea: '1125741 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 577728, finearea: '1159138 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577729, finearea: '1159139 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577730, finearea: '1159140 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577731, finearea: '1159141 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577732, finearea: '1159142 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577733, finearea: '1159143 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577734, finearea: '1159144 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577735, finearea: '1159145 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577736, finearea: '1159146 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577737, finearea: '1159147 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577738, finearea: '1159148 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577739, finearea: '1159149 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577740, finearea: '1159150 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577741, finearea: '1159151 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577742, finearea: '1159152 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577743, finearea: '1159153 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577744, finearea: '1159154 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577745, finearea: '1113601 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577746, finearea: '1113602 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577747, finearea: '1113603 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577748, finearea: '1113604 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577749, finearea: '1113605 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577750, finearea: '1113606 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577751, finearea: '1113607 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577752, finearea: '1113608 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577753, finearea: '1113609 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577754, finearea: '1113610 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577755, finearea: '1113611 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577756, finearea: '1113612 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577757, finearea: '1113613 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577758, finearea: '1113614 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577759, finearea: '1113615 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577760, finearea: '1113616 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577761, finearea: '1113617 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577762, finearea: '1113618 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577763, finearea: '1113619 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577764, finearea: '1113620 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577765, finearea: '1113621 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577766, finearea: '1113622 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577767, finearea: '1113623 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577768, finearea: '1113624 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577769, finearea: '1113625 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577770, finearea: '1113626 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577771, finearea: '1113627 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577772, finearea: '1113628 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577773, finearea: '1113629 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577774, finearea: '1113630 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577775, finearea: '1113631 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577776, finearea: '1113632 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577777, finearea: '1113633 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577778, finearea: '1113634 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577779, finearea: '1113635 _ Shellharbour (C)', subregion: 'Albion Park - Macquarie Pass', region: 'Illawarra-Shoalhaven' },
  { geography: 577780, finearea: '1113701 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577781, finearea: '1113702 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577782, finearea: '1113703 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577783, finearea: '1113704 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577784, finearea: '1113705 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577785, finearea: '1113706 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577786, finearea: '1113707 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577787, finearea: '1113708 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577788, finearea: '1113709 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577789, finearea: '1113710 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577790, finearea: '1113711 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577791, finearea: '1113712 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577792, finearea: '1113713 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577793, finearea: '1113714 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577794, finearea: '1113715 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577795, finearea: '1113716 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577796, finearea: '1113717 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577797, finearea: '1113718 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577798, finearea: '1113719 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577799, finearea: '1113720 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577800, finearea: '1113721 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577801, finearea: '1113722 _ Shellharbour (C)', subregion: 'Albion Park Rail', region: 'Illawarra-Shoalhaven' },
  { geography: 577802, finearea: '1114101 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577803, finearea: '1114102 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577804, finearea: '1114103 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577805, finearea: '1114104 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577806, finearea: '1114105 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577807, finearea: '1114106 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577808, finearea: '1114107 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577809, finearea: '1114108 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577810, finearea: '1114110 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577811, finearea: '1114111 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577812, finearea: '1114112 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577813, finearea: '1114113 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577814, finearea: '1114114 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577815, finearea: '1114115 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577816, finearea: '1114116 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577817, finearea: '1114117 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577818, finearea: '1114118 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577819, finearea: '1114119 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577820, finearea: '1114120 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577821, finearea: '1114121 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577822, finearea: '1114122 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577823, finearea: '1114123 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577824, finearea: '1114124 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577825, finearea: '1114126 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577826, finearea: '1114127 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577827, finearea: '1114128 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577828, finearea: '1114129 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577829, finearea: '1114130 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577830, finearea: '1114131 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577831, finearea: '1114133 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577832, finearea: '1114134 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577833, finearea: '1114135 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577834, finearea: '1114136 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577835, finearea: '1114137 _ Shellharbour (C)', subregion: 'Shellharbour - Flinders', region: 'Illawarra-Shoalhaven' },
  { geography: 577836, finearea: '1114201 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577837, finearea: '1114202 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577838, finearea: '1114203 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577839, finearea: '1114204 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577840, finearea: '1114205 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577841, finearea: '1114206 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577842, finearea: '1114207 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577843, finearea: '1114208 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577844, finearea: '1114209 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577845, finearea: '1114210 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577846, finearea: '1114211 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577847, finearea: '1114212 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577848, finearea: '1114213 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577849, finearea: '1114214 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577850, finearea: '1114215 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577851, finearea: '1114216 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577852, finearea: '1114217 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577853, finearea: '1114218 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577854, finearea: '1114219 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577855, finearea: '1114220 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577856, finearea: '1114221 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577857, finearea: '1114222 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577858, finearea: '1114223 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577859, finearea: '1114224 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577860, finearea: '1114225 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577861, finearea: '1114226 _ Shellharbour (C)', subregion: 'Shellharbour - Oak Flats', region: 'Illawarra-Shoalhaven' },
  { geography: 577862, finearea: '1114301 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577863, finearea: '1114302 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577864, finearea: '1114303 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577865, finearea: '1114304 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577866, finearea: '1114305 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577867, finearea: '1114306 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577868, finearea: '1114307 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577869, finearea: '1114308 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577870, finearea: '1114309 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577871, finearea: '1114310 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577872, finearea: '1114311 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577873, finearea: '1114312 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577874, finearea: '1114313 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577875, finearea: '1114314 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577876, finearea: '1114315 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577877, finearea: '1114316 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577878, finearea: '1114317 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577879, finearea: '1114318 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577880, finearea: '1114319 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577881, finearea: '1114320 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577882, finearea: '1114321 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577883, finearea: '1114322 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577884, finearea: '1114323 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577885, finearea: '1114324 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577886, finearea: '1114325 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577887, finearea: '1114326 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577888, finearea: '1114327 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577889, finearea: '1114328 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577890, finearea: '1114329 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577891, finearea: '1114330 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577892, finearea: '1114331 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577893, finearea: '1114332 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577894, finearea: '1114333 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577895, finearea: '1114334 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577896, finearea: '1114335 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577897, finearea: '1114336 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577898, finearea: '1114337 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577899, finearea: '1114338 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577900, finearea: '1114339 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577901, finearea: '1114340 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577902, finearea: '1114341 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577903, finearea: '1114342 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577904, finearea: '1114343 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577905, finearea: '1114344 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577906, finearea: '1114345 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577907, finearea: '1114346 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577908, finearea: '1114347 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577909, finearea: '1114348 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577910, finearea: '1114349 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577911, finearea: '1114350 _ Shellharbour (C)', subregion: 'Warilla', region: 'Illawarra-Shoalhaven' },
  { geography: 577912, finearea: '1102401 _ Shoalhaven (C)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 577913, finearea: '1102402 _ Shoalhaven (C)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 577914, finearea: '1114016 _ Shoalhaven (C)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 577915, finearea: '1127201 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577916, finearea: '1127202 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577917, finearea: '1127203 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577918, finearea: '1127204 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577919, finearea: '1127205 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577920, finearea: '1127206 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577921, finearea: '1127207 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577922, finearea: '1127208 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577923, finearea: '1127209 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577924, finearea: '1127210 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577925, finearea: '1127211 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577926, finearea: '1127213 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 577927, finearea: '1127214 _ Shoalhaven (C)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 571437, finearea: '1121734 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571438, finearea: '1121735 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571439, finearea: '1121736 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571440, finearea: '1121801 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571441, finearea: '1121802 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571442, finearea: '1121803 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571443, finearea: '1121804 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571444, finearea: '1121805 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571445, finearea: '1121806 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571446, finearea: '1121807 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571447, finearea: '1121808 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571448, finearea: '1121809 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571449, finearea: '1121810 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571450, finearea: '1121811 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571451, finearea: '1121812 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571452, finearea: '1121813 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571453, finearea: '1121814 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571454, finearea: '1121815 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571455, finearea: '1121816 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571456, finearea: '1121817 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571457, finearea: '1121818 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571458, finearea: '1121819 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571459, finearea: '1121820 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571460, finearea: '1121821 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571461, finearea: '1121822 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571462, finearea: '1121823 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571463, finearea: '1121824 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571464, finearea: '1121825 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571465, finearea: '1121826 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571466, finearea: '1121827 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571467, finearea: '1121828 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571468, finearea: '1121829 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571469, finearea: '1121830 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571470, finearea: '1121831 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571471, finearea: '1121832 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571472, finearea: '1121833 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571473, finearea: '1121834 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571474, finearea: '1121835 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571475, finearea: '1121836 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571476, finearea: '1121837 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571477, finearea: '1121838 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571478, finearea: '1121839 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571479, finearea: '1121840 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571480, finearea: '1121841 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571481, finearea: '1121842 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571482, finearea: '1121843 _ Lake Macquarie (C)', subregion: 'Morisset - Cooranbong', region: 'Central Coast and Hunter' },
  { geography: 571483, finearea: '1121901 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571484, finearea: '1121902 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571485, finearea: '1121903 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571486, finearea: '1121904 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571487, finearea: '1121905 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571488, finearea: '1121906 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571489, finearea: '1121907 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571490, finearea: '1121908 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571491, finearea: '1121909 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571492, finearea: '1121910 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571493, finearea: '1121911 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571494, finearea: '1121912 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571495, finearea: '1121913 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571496, finearea: '1121914 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571497, finearea: '1121915 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571498, finearea: '1121916 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571499, finearea: '1121917 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571500, finearea: '1121918 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571501, finearea: '1121919 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571502, finearea: '1121921 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571503, finearea: '1121922 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571504, finearea: '1121923 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571505, finearea: '1121924 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571506, finearea: '1121925 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571507, finearea: '1121926 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571508, finearea: '1121927 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571509, finearea: '1121929 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571510, finearea: '1121930 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571511, finearea: '1121931 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571512, finearea: '1121933 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571513, finearea: '1121934 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571514, finearea: '1121935 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571515, finearea: '1121936 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571516, finearea: '1121937 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571517, finearea: '1121938 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571518, finearea: '1121939 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571519, finearea: '1121940 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571520, finearea: '1121941 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571521, finearea: '1121942 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571522, finearea: '1121943 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571523, finearea: '1121944 _ Lake Macquarie (C)', subregion: 'Toronto - Awaba', region: 'Central Coast and Hunter' },
  { geography: 571524, finearea: '1122001 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571525, finearea: '1122002 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571526, finearea: '1122003 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571527, finearea: '1122004 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571528, finearea: '1122005 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571529, finearea: '1122006 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571530, finearea: '1122007 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571531, finearea: '1122009 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571532, finearea: '1122010 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571533, finearea: '1122011 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571534, finearea: '1122012 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571535, finearea: '1122013 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571536, finearea: '1122014 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571537, finearea: '1122015 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571538, finearea: '1122016 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571539, finearea: '1122017 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571540, finearea: '1122018 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571541, finearea: '1122019 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571542, finearea: '1122020 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571543, finearea: '1122021 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571544, finearea: '1122022 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571545, finearea: '1122023 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571546, finearea: '1122024 _ Lake Macquarie (C)', subregion: 'Wangi Wangi - Rathmines', region: 'Central Coast and Hunter' },
  { geography: 571547, finearea: '1122101 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571548, finearea: '1122102 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571549, finearea: '1122103 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571550, finearea: '1122104 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571551, finearea: '1122105 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571552, finearea: '1122106 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571553, finearea: '1122107 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571554, finearea: '1122108 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571555, finearea: '1122109 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571556, finearea: '1122110 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571557, finearea: '1122111 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571558, finearea: '1122112 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571559, finearea: '1122113 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571560, finearea: '1122114 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571561, finearea: '1122115 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571562, finearea: '1122116 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571563, finearea: '1122117 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571564, finearea: '1122118 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571565, finearea: '1122119 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571566, finearea: '1122120 _ Lake Macquarie (C)', subregion: 'West Wallsend - Barnsley - Killingworth', region: 'Central Coast and Hunter' },
  { geography: 571567, finearea: '1122226 _ Lake Macquarie (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 571568, finearea: '1122227 _ Lake Macquarie (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 571569, finearea: '1122228 _ Lake Macquarie (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 571570, finearea: '1122229 _ Lake Macquarie (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 571571, finearea: '1122230 _ Lake Macquarie (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 571572, finearea: '1122231 _ Lake Macquarie (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 571573, finearea: '1122624 _ Lake Macquarie (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 571574, finearea: '1123308 _ Lake Macquarie (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 571575, finearea: '1123340 _ Lake Macquarie (C)', subregion: 'Wallsend - Elermore Vale', region: 'Central Coast and Hunter' },
  { geography: 571576, finearea: '1139901 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571577, finearea: '1139902 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571578, finearea: '1139903 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571579, finearea: '1139904 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571580, finearea: '1139905 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571581, finearea: '1139910 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571582, finearea: '1139911 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571583, finearea: '1139912 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571584, finearea: '1139932 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571585, finearea: '1139933 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571586, finearea: '1139934 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571587, finearea: '1139935 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571588, finearea: '1139937 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571589, finearea: '1139938 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571590, finearea: '1139941 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571591, finearea: '1139942 _ Lane Cove (A)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 571592, finearea: '1140001 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571593, finearea: '1140002 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571594, finearea: '1140003 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571595, finearea: '1140004 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571596, finearea: '1140005 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571597, finearea: '1140006 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571598, finearea: '1140007 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571599, finearea: '1140008 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571600, finearea: '1140009 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571601, finearea: '1140010 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571602, finearea: '1140011 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571603, finearea: '1140012 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571604, finearea: '1140013 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571605, finearea: '1140014 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571606, finearea: '1140015 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571607, finearea: '1140016 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571608, finearea: '1140017 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571609, finearea: '1140018 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571610, finearea: '1140019 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571611, finearea: '1140020 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571612, finearea: '1140021 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571613, finearea: '1140022 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571614, finearea: '1140023 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571615, finearea: '1140024 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571616, finearea: '1140025 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571617, finearea: '1140026 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571618, finearea: '1140027 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571619, finearea: '1140028 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571620, finearea: '1140029 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571621, finearea: '1140030 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571622, finearea: '1140031 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571623, finearea: '1140032 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571624, finearea: '1140033 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571625, finearea: '1140034 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571626, finearea: '1140035 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571627, finearea: '1140036 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571628, finearea: '1140037 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571629, finearea: '1140038 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571630, finearea: '1140040 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571631, finearea: '1140041 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571632, finearea: '1140042 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571633, finearea: '1140043 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571634, finearea: '1140044 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571635, finearea: '1140045 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571636, finearea: '1140047 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 580728, finearea: '1109624 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580729, finearea: '1109625 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580730, finearea: '1109626 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580731, finearea: '1109627 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580732, finearea: '1109408 _ Warren (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 580733, finearea: '1109502 _ Warren (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 580734, finearea: '1109505 _ Warren (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 580735, finearea: '1109511 _ Warren (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 580736, finearea: '1109512 _ Warren (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 580737, finearea: '1109513 _ Warren (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 580738, finearea: '1109514 _ Warren (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 580739, finearea: '1109515 _ Warren (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 580740, finearea: '1109604 _ Warren (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580741, finearea: '1110404 _ Warren (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 580742, finearea: '1110517 _ Warren (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 580743, finearea: '1110518 _ Warren (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 580744, finearea: '1107404 _ Warrumbungle Shire (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 580745, finearea: '1107405 _ Warrumbungle Shire (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 580746, finearea: '1107406 _ Warrumbungle Shire (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 580747, finearea: '1107426 _ Warrumbungle Shire (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 580748, finearea: '1109901 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580749, finearea: '1109902 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580750, finearea: '1109903 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580751, finearea: '1109904 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580752, finearea: '1109905 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580753, finearea: '1109906 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580754, finearea: '1109907 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580755, finearea: '1109908 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580756, finearea: '1109909 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580757, finearea: '1109910 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580758, finearea: '1109911 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580759, finearea: '1109912 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580760, finearea: '1109913 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580761, finearea: '1109914 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580762, finearea: '1109915 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580763, finearea: '1109916 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580764, finearea: '1109917 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580765, finearea: '1109918 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580766, finearea: '1109919 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580767, finearea: '1109920 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580768, finearea: '1109921 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580769, finearea: '1109922 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580770, finearea: '1109923 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580771, finearea: '1109924 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580772, finearea: '1109925 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580773, finearea: '1109926 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580774, finearea: '1109927 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580775, finearea: '1109928 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580776, finearea: '1109929 _ Warrumbungle Shire (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 580777, finearea: '1133901 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580778, finearea: '1133902 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580779, finearea: '1133903 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580780, finearea: '1133904 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580781, finearea: '1133905 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580782, finearea: '1133906 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580783, finearea: '1133907 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580784, finearea: '1133908 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580785, finearea: '1133909 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580786, finearea: '1133910 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580787, finearea: '1133911 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580788, finearea: '1133912 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580789, finearea: '1133913 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580790, finearea: '1133914 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580791, finearea: '1133915 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580792, finearea: '1133916 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580793, finearea: '1133917 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580794, finearea: '1133918 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580795, finearea: '1133919 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580796, finearea: '1133920 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580797, finearea: '1133921 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580798, finearea: '1133922 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580799, finearea: '1133923 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580800, finearea: '1133924 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580801, finearea: '1133925 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580802, finearea: '1133926 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580803, finearea: '1133927 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580804, finearea: '1133928 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580805, finearea: '1133929 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580806, finearea: '1133930 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580807, finearea: '1133931 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580808, finearea: '1133932 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580809, finearea: '1133933 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580810, finearea: '1133934 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580811, finearea: '1133935 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580812, finearea: '1133936 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580813, finearea: '1133937 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580814, finearea: '1133938 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580815, finearea: '1133939 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580816, finearea: '1133940 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580817, finearea: '1133941 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580818, finearea: '1133942 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580819, finearea: '1133943 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580820, finearea: '1133944 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580821, finearea: '1133945 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580822, finearea: '1133946 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580823, finearea: '1133947 _ Waverley (A)', subregion: 'Bondi - Tamarama - Bronte', region: 'Inner Sydney' },
  { geography: 580824, finearea: '1134001 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580825, finearea: '1134002 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580826, finearea: '1134003 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580827, finearea: '1134004 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580828, finearea: '1134005 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580829, finearea: '1134006 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580830, finearea: '1134007 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580831, finearea: '1134008 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580832, finearea: '1134009 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580833, finearea: '1134010 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580834, finearea: '1134011 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580835, finearea: '1134012 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580836, finearea: '1134013 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580837, finearea: '1134014 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580838, finearea: '1134015 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580839, finearea: '1134016 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580840, finearea: '1134017 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580841, finearea: '1134018 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580842, finearea: '1134019 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580843, finearea: '1134020 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580844, finearea: '1134021 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580845, finearea: '1134022 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580846, finearea: '1134023 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580847, finearea: '1134024 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580848, finearea: '1134025 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580849, finearea: '1134026 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580850, finearea: '1134027 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580851, finearea: '1134028 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580852, finearea: '1134029 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580853, finearea: '1134030 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580854, finearea: '1134031 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580855, finearea: '1134032 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580856, finearea: '1134033 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580857, finearea: '1134034 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580858, finearea: '1134035 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580859, finearea: '1134036 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580860, finearea: '1134037 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580861, finearea: '1134038 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580862, finearea: '1134039 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580863, finearea: '1134040 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580864, finearea: '1134041 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580865, finearea: '1134042 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580866, finearea: '1134043 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580867, finearea: '1134044 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580868, finearea: '1134045 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580869, finearea: '1134046 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580870, finearea: '1134047 _ Waverley (A)', subregion: 'Bondi Beach - North Bondi', region: 'Inner Sydney' },
  { geography: 580871, finearea: '1134101 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580872, finearea: '1134102 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580873, finearea: '1134103 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580874, finearea: '1134104 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580875, finearea: '1134105 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580876, finearea: '1134106 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580877, finearea: '1134107 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580878, finearea: '1134108 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580879, finearea: '1134109 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580880, finearea: '1134110 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580881, finearea: '1134111 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580882, finearea: '1134112 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580883, finearea: '1134113 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580884, finearea: '1134114 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580885, finearea: '1134115 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580886, finearea: '1134116 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580887, finearea: '1134117 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580888, finearea: '1134118 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580889, finearea: '1134119 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580890, finearea: '1134120 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580891, finearea: '1134121 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580892, finearea: '1134122 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580893, finearea: '1134123 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580894, finearea: '1134124 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580895, finearea: '1134125 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580896, finearea: '1134126 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580897, finearea: '1134127 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580898, finearea: '1134128 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580899, finearea: '1134129 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580900, finearea: '1134130 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580901, finearea: '1134131 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580902, finearea: '1134132 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580903, finearea: '1134133 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580904, finearea: '1134134 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580905, finearea: '1134135 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580906, finearea: '1134136 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580907, finearea: '1134137 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580908, finearea: '1134138 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580909, finearea: '1134139 _ Waverley (A)', subregion: 'Bondi Junction - Waverly', region: 'Inner Sydney' },
  { geography: 580910, finearea: '1134401 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580911, finearea: '1134402 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580912, finearea: '1134403 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580913, finearea: '1134404 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580914, finearea: '1134405 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580915, finearea: '1134406 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580916, finearea: '1134407 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580917, finearea: '1134408 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580918, finearea: '1134409 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580919, finearea: '1134410 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580920, finearea: '1134411 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580921, finearea: '1134412 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580922, finearea: '1134413 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580923, finearea: '1134414 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580924, finearea: '1134415 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580925, finearea: '1134416 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580926, finearea: '1134417 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580927, finearea: '1134418 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 563037, finearea: '1101914 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563038, finearea: '1101915 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563039, finearea: '1101916 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563040, finearea: '1101917 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563041, finearea: '1101918 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563042, finearea: '1101919 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563043, finearea: '1101920 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563044, finearea: '1101921 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563045, finearea: '1101922 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563046, finearea: '1101923 _ Bega Valley (A)', subregion: 'Bega - Tathra', region: 'Capital Region and South Coast' },
  { geography: 563047, finearea: '1102001 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563048, finearea: '1102002 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563049, finearea: '1102003 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563050, finearea: '1102004 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563051, finearea: '1102005 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563052, finearea: '1102006 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563053, finearea: '1102007 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563054, finearea: '1102008 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563055, finearea: '1102009 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563056, finearea: '1102010 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563057, finearea: '1102011 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563058, finearea: '1102012 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563059, finearea: '1102013 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563060, finearea: '1102014 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563061, finearea: '1102015 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563062, finearea: '1102016 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563063, finearea: '1102017 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563064, finearea: '1102018 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563065, finearea: '1102019 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563066, finearea: '1102020 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563067, finearea: '1102021 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563068, finearea: '1102022 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563069, finearea: '1102023 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563070, finearea: '1102024 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563071, finearea: '1102025 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563072, finearea: '1102026 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563073, finearea: '1102027 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563074, finearea: '1102028 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563075, finearea: '1102029 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563076, finearea: '1102030 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563077, finearea: '1102031 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563078, finearea: '1102032 _ Bega Valley (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 563079, finearea: '1102201 _ Bega Valley (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 563080, finearea: '1102301 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563081, finearea: '1102302 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563082, finearea: '1102303 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563083, finearea: '1102304 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563084, finearea: '1102305 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563085, finearea: '1102306 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563086, finearea: '1102307 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563087, finearea: '1102308 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563088, finearea: '1102309 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563089, finearea: '1102310 _ Bega Valley (A)', subregion: 'Eden', region: 'Capital Region and South Coast' },
  { geography: 563090, finearea: '1102501 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563091, finearea: '1102502 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563092, finearea: '1102503 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563093, finearea: '1102504 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563094, finearea: '1102505 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563095, finearea: '1102506 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563096, finearea: '1102507 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563097, finearea: '1102508 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563098, finearea: '1102509 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563099, finearea: '1102510 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563100, finearea: '1102511 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563101, finearea: '1102512 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563102, finearea: '1102513 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563103, finearea: '1102514 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563104, finearea: '1102516 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563105, finearea: '1102517 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563106, finearea: '1102518 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563107, finearea: '1102519 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563108, finearea: '1102520 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563109, finearea: '1102521 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563110, finearea: '1102522 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563111, finearea: '1102523 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563112, finearea: '1102524 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563113, finearea: '1102525 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563114, finearea: '1102526 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563115, finearea: '1102527 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563116, finearea: '1102528 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563117, finearea: '1102529 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563118, finearea: '1102530 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563119, finearea: '1102531 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563120, finearea: '1102532 _ Bega Valley (A)', subregion: 'Merimbula - Tura Beach', region: 'Capital Region and South Coast' },
  { geography: 563121, finearea: '1102701 _ Bega Valley (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 563122, finearea: '1102710 _ Bega Valley (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 563123, finearea: '1102711 _ Bega Valley (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 563124, finearea: '1102717 _ Bega Valley (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 563125, finearea: '1102718 _ Bega Valley (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 563126, finearea: '1102719 _ Bega Valley (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 563127, finearea: '1102720 _ Bega Valley (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 563128, finearea: '1102721 _ Bega Valley (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 563129, finearea: '1108301 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563130, finearea: '1108302 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563131, finearea: '1108303 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563132, finearea: '1108304 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563133, finearea: '1108305 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563134, finearea: '1108306 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563135, finearea: '1108307 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563136, finearea: '1108308 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563137, finearea: '1108309 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563138, finearea: '1108310 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563139, finearea: '1108311 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563140, finearea: '1108312 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563141, finearea: '1108313 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563142, finearea: '1108314 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563143, finearea: '1108315 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563144, finearea: '1108316 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563145, finearea: '1108317 _ Bellingen (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 563146, finearea: '1108701 _ Bellingen (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 563147, finearea: '1108704 _ Bellingen (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 563148, finearea: '1108705 _ Bellingen (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 563149, finearea: '1108706 _ Bellingen (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 563150, finearea: '1108707 _ Bellingen (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 563151, finearea: '1108708 _ Bellingen (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 563152, finearea: '1108709 _ Bellingen (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 563153, finearea: '1108710 _ Bellingen (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 563154, finearea: '1108713 _ Bellingen (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 563155, finearea: '1109001 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563156, finearea: '1109002 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563157, finearea: '1109003 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563158, finearea: '1109004 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563159, finearea: '1109005 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563160, finearea: '1109006 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563161, finearea: '1109007 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563162, finearea: '1109008 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563163, finearea: '1109009 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563164, finearea: '1109010 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563165, finearea: '1109011 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563166, finearea: '1109012 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563167, finearea: '1109013 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563168, finearea: '1109014 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563169, finearea: '1109015 _ Bellingen (A)', subregion: 'Urunga', region: 'North East NSW' },
  { geography: 563170, finearea: '1115923 _ Bellingen (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 563171, finearea: '1118501 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563172, finearea: '1118502 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563173, finearea: '1118503 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563174, finearea: '1118504 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563175, finearea: '1118505 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563176, finearea: '1118506 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563177, finearea: '1118507 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563178, finearea: '1118508 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563179, finearea: '1118509 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563180, finearea: '1118510 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563181, finearea: '1118511 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563182, finearea: '1118512 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563183, finearea: '1118515 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563184, finearea: '1118516 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563185, finearea: '1118517 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563186, finearea: '1118518 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563187, finearea: '1118519 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563188, finearea: '1118520 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563189, finearea: '1118521 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563190, finearea: '1118522 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563191, finearea: '1118525 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563192, finearea: '1118526 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563193, finearea: '1118527 _ Berrigan (A)', subregion: 'Tocumwal - Finley - Jerilderie', region: 'Murray' },
  { geography: 563194, finearea: '1130301 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563195, finearea: '1130302 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563196, finearea: '1130303 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563197, finearea: '1130304 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563198, finearea: '1130305 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563199, finearea: '1130306 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563200, finearea: '1130307 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563201, finearea: '1130308 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563202, finearea: '1130309 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563203, finearea: '1130310 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563204, finearea: '1130311 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563205, finearea: '1130312 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563206, finearea: '1130313 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563207, finearea: '1130314 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563208, finearea: '1130315 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563209, finearea: '1130316 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563210, finearea: '1130317 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563211, finearea: '1130318 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563212, finearea: '1130319 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563213, finearea: '1130320 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563214, finearea: '1130321 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563215, finearea: '1130322 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563216, finearea: '1130323 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563217, finearea: '1130324 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563218, finearea: '1130325 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563219, finearea: '1130326 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563220, finearea: '1130327 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563221, finearea: '1130328 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563222, finearea: '1130329 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563223, finearea: '1130330 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563224, finearea: '1130332 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563225, finearea: '1130333 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563226, finearea: '1130334 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563227, finearea: '1130335 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563228, finearea: '1130336 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563229, finearea: '1130337 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563230, finearea: '1130338 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563231, finearea: '1130339 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563232, finearea: '1130340 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563233, finearea: '1130341 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563234, finearea: '1130342 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563235, finearea: '1130343 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 563236, finearea: '1130344 _ Blacktown (C)', subregion: 'Blacktown (East) - Kings Park', region: 'Northern Sydney' },
  { geography: 571237, finearea: '1120952 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571238, finearea: '1120953 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571239, finearea: '1120954 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571240, finearea: '1120955 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571241, finearea: '1121001 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571242, finearea: '1121002 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571243, finearea: '1121003 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571244, finearea: '1121004 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571245, finearea: '1121005 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571246, finearea: '1121006 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571247, finearea: '1121007 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571248, finearea: '1121008 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571249, finearea: '1121010 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571250, finearea: '1121011 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571251, finearea: '1121012 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571252, finearea: '1121013 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571253, finearea: '1121014 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571254, finearea: '1121015 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571255, finearea: '1121016 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571256, finearea: '1121017 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571257, finearea: '1121018 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571258, finearea: '1121019 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571259, finearea: '1121020 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571260, finearea: '1121021 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571261, finearea: '1121022 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571262, finearea: '1121023 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571263, finearea: '1121024 _ Lake Macquarie (C)', subregion: 'Mount Hutton - Windale', region: 'Central Coast and Hunter' },
  { geography: 571264, finearea: '1121101 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571265, finearea: '1121102 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571266, finearea: '1121103 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571267, finearea: '1121104 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571268, finearea: '1121105 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571269, finearea: '1121106 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571270, finearea: '1121107 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571271, finearea: '1121108 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571272, finearea: '1121109 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571273, finearea: '1121110 _ Lake Macquarie (C)', subregion: 'Redhead', region: 'Central Coast and Hunter' },
  { geography: 571274, finearea: '1121201 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571275, finearea: '1121202 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571276, finearea: '1121203 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571277, finearea: '1121204 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571278, finearea: '1121205 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571279, finearea: '1121206 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571280, finearea: '1121207 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571281, finearea: '1121208 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571282, finearea: '1121209 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571283, finearea: '1121210 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571284, finearea: '1121211 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571285, finearea: '1121212 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571286, finearea: '1121213 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571287, finearea: '1121214 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571288, finearea: '1121215 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571289, finearea: '1121216 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571290, finearea: '1121217 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571291, finearea: '1121218 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571292, finearea: '1121219 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571293, finearea: '1121220 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571294, finearea: '1121221 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571295, finearea: '1121222 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571296, finearea: '1121223 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571297, finearea: '1121224 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571298, finearea: '1121225 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571299, finearea: '1121226 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571300, finearea: '1121227 _ Lake Macquarie (C)', subregion: 'Swansea - Caves Beach', region: 'Central Coast and Hunter' },
  { geography: 571301, finearea: '1121301 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571302, finearea: '1121302 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571303, finearea: '1121303 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571304, finearea: '1121304 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571305, finearea: '1121305 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571306, finearea: '1121306 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571307, finearea: '1121307 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571308, finearea: '1121308 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571309, finearea: '1121309 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571310, finearea: '1121310 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571311, finearea: '1121311 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571312, finearea: '1121312 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571313, finearea: '1121313 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571314, finearea: '1121314 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571315, finearea: '1121315 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571316, finearea: '1121316 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571317, finearea: '1121317 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571318, finearea: '1121318 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571319, finearea: '1121319 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571320, finearea: '1121320 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571321, finearea: '1121321 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571322, finearea: '1121322 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571323, finearea: '1121323 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571324, finearea: '1121324 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571325, finearea: '1121325 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571326, finearea: '1121326 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571327, finearea: '1121327 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571328, finearea: '1121328 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571329, finearea: '1121329 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571330, finearea: '1121330 _ Lake Macquarie (C)', subregion: 'Valentine - Eleebana', region: 'Central Coast and Hunter' },
  { geography: 571331, finearea: '1121401 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571332, finearea: '1121402 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571333, finearea: '1121403 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571334, finearea: '1121404 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571335, finearea: '1121405 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571336, finearea: '1121406 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571337, finearea: '1121407 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571338, finearea: '1121408 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571339, finearea: '1121409 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571340, finearea: '1121410 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571341, finearea: '1121411 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571342, finearea: '1121412 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571343, finearea: '1121413 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571344, finearea: '1121414 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571345, finearea: '1121415 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571346, finearea: '1121416 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571347, finearea: '1121417 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571348, finearea: '1121418 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571349, finearea: '1121419 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571350, finearea: '1121420 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571351, finearea: '1121421 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571352, finearea: '1121422 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571353, finearea: '1121423 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571354, finearea: '1121424 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571355, finearea: '1121425 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571356, finearea: '1121426 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571357, finearea: '1121427 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571358, finearea: '1121428 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571359, finearea: '1121429 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571360, finearea: '1121430 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571361, finearea: '1121431 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571362, finearea: '1121432 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571363, finearea: '1121433 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571364, finearea: '1121434 _ Lake Macquarie (C)', subregion: 'Warners Bay - Boolaroo', region: 'Central Coast and Hunter' },
  { geography: 571365, finearea: '1121501 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571366, finearea: '1121502 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571367, finearea: '1121503 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571368, finearea: '1121504 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571369, finearea: '1121505 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571370, finearea: '1121506 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571371, finearea: '1121507 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571372, finearea: '1121508 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571373, finearea: '1121509 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571374, finearea: '1121510 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571375, finearea: '1121511 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571376, finearea: '1121512 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571377, finearea: '1121513 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571378, finearea: '1121514 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571379, finearea: '1121515 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571380, finearea: '1121516 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571381, finearea: '1121517 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571382, finearea: '1121518 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571383, finearea: '1121519 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571384, finearea: '1121520 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571385, finearea: '1121521 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571386, finearea: '1121522 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571387, finearea: '1121523 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571388, finearea: '1121524 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571389, finearea: '1121525 _ Lake Macquarie (C)', subregion: 'Bolton Point - Teralba', region: 'Central Coast and Hunter' },
  { geography: 571390, finearea: '1121601 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571391, finearea: '1121602 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571392, finearea: '1121603 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571393, finearea: '1121604 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571394, finearea: '1121605 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571395, finearea: '1121606 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571396, finearea: '1121607 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571397, finearea: '1121608 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571398, finearea: '1121609 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571399, finearea: '1121610 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571400, finearea: '1121611 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571401, finearea: '1121612 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571402, finearea: '1121613 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571403, finearea: '1121614 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571404, finearea: '1121615 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571405, finearea: '1121616 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571406, finearea: '1121617 _ Lake Macquarie (C)', subregion: 'Bonnells Bay - Silverwater', region: 'Central Coast and Hunter' },
  { geography: 571407, finearea: '1121701 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571408, finearea: '1121702 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571409, finearea: '1121703 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571410, finearea: '1121704 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571411, finearea: '1121705 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571412, finearea: '1121706 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571413, finearea: '1121707 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571414, finearea: '1121708 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571415, finearea: '1121709 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571416, finearea: '1121710 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571417, finearea: '1121711 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571418, finearea: '1121712 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571419, finearea: '1121713 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571420, finearea: '1121714 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571421, finearea: '1121716 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571422, finearea: '1121718 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571423, finearea: '1121719 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571424, finearea: '1121720 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571425, finearea: '1121721 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571426, finearea: '1121722 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571427, finearea: '1121723 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571428, finearea: '1121724 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571429, finearea: '1121725 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571430, finearea: '1121726 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571431, finearea: '1121727 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571432, finearea: '1121729 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571433, finearea: '1121730 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571434, finearea: '1121731 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571435, finearea: '1121732 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 571436, finearea: '1121733 _ Lake Macquarie (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 567437, finearea: '1109405 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567438, finearea: '1109406 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567439, finearea: '1109407 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567440, finearea: '1109409 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567441, finearea: '1109410 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567442, finearea: '1109411 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567443, finearea: '1109412 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567444, finearea: '1109413 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567445, finearea: '1109414 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567446, finearea: '1109604 _ Coonamble (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 567447, finearea: '1109605 _ Coonamble (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 567448, finearea: '1109915 _ Coonamble (A)', subregion: 'Coonabarabran', region: 'Regional NSW - Remainder' },
  { geography: 567449, finearea: '1126301 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567450, finearea: '1126302 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567451, finearea: '1126303 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567452, finearea: '1126304 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567453, finearea: '1126305 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567454, finearea: '1126306 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567455, finearea: '1126307 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567456, finearea: '1126308 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567457, finearea: '1126309 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567458, finearea: '1126310 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567459, finearea: '1126311 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567460, finearea: '1126312 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567461, finearea: '1126313 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567462, finearea: '1126314 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567463, finearea: '1126315 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567464, finearea: '1126316 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567465, finearea: '1126317 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567466, finearea: '1126318 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567467, finearea: '1126319 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567468, finearea: '1126320 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567469, finearea: '1126321 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567470, finearea: '1126322 _ Cootamundra-Gundagai Regional (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 567471, finearea: '1126401 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567472, finearea: '1126402 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567473, finearea: '1126403 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567474, finearea: '1126404 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567475, finearea: '1126405 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567476, finearea: '1126406 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567477, finearea: '1126407 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567478, finearea: '1126408 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567479, finearea: '1126409 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567480, finearea: '1126410 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567481, finearea: '1126411 _ Cootamundra-Gundagai Regional (A)', subregion: 'Gundagai', region: 'Riverina-Goulburn Belt' },
  { geography: 567482, finearea: '1106301 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567483, finearea: '1106302 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567484, finearea: '1106303 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567485, finearea: '1106304 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567486, finearea: '1106305 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567487, finearea: '1106306 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567488, finearea: '1106307 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567489, finearea: '1106308 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567490, finearea: '1106309 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567491, finearea: '1106310 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567492, finearea: '1106311 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567493, finearea: '1106312 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567494, finearea: '1106313 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567495, finearea: '1106314 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567496, finearea: '1106315 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567497, finearea: '1106316 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567498, finearea: '1106317 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567499, finearea: '1106318 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567500, finearea: '1106319 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567501, finearea: '1106320 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567502, finearea: '1106321 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567503, finearea: '1106322 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567504, finearea: '1106323 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567505, finearea: '1106324 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567506, finearea: '1106325 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567507, finearea: '1106326 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567508, finearea: '1106327 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567509, finearea: '1106328 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567510, finearea: '1106329 _ Cowra (A)', subregion: 'Cowra', region: 'Regional NSW - Remainder' },
  { geography: 567511, finearea: '1106405 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567512, finearea: '1106408 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567513, finearea: '1106409 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567514, finearea: '1106410 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567515, finearea: '1106411 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567516, finearea: '1106412 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567517, finearea: '1106413 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567518, finearea: '1106414 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567519, finearea: '1106415 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567520, finearea: '1106416 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567521, finearea: '1106417 _ Cowra (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 567522, finearea: '1107617 _ Cowra (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 567523, finearea: '1107618 _ Cowra (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 567524, finearea: '1130828 _ Cumberland (A)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 567525, finearea: '1130830 _ Cumberland (A)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 567526, finearea: '1130841 _ Cumberland (A)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 567527, finearea: '1130842 _ Cumberland (A)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 567528, finearea: '1147501 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567529, finearea: '1158201 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567530, finearea: '1158202 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567531, finearea: '1158203 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567532, finearea: '1158204 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567533, finearea: '1158205 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567534, finearea: '1158206 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567535, finearea: '1158207 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567536, finearea: '1158208 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567537, finearea: '1158209 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567538, finearea: '1158210 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567539, finearea: '1158211 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567540, finearea: '1158212 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567541, finearea: '1158213 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567542, finearea: '1158214 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567543, finearea: '1158215 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567544, finearea: '1158216 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567545, finearea: '1158217 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567546, finearea: '1158218 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567547, finearea: '1158219 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567548, finearea: '1158220 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567549, finearea: '1158221 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567550, finearea: '1158222 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567551, finearea: '1158223 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567552, finearea: '1158224 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567553, finearea: '1158225 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567554, finearea: '1158226 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567555, finearea: '1158227 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567556, finearea: '1158228 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567557, finearea: '1158229 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567558, finearea: '1158230 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567559, finearea: '1158231 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567560, finearea: '1158232 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567561, finearea: '1158233 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567562, finearea: '1158234 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567563, finearea: '1158235 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567564, finearea: '1158236 _ Cumberland (A)', subregion: 'Auburn - Central', region: 'South and West Sydney' },
  { geography: 567565, finearea: '1158301 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567566, finearea: '1158302 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567567, finearea: '1158303 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567568, finearea: '1158304 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567569, finearea: '1158305 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567570, finearea: '1158306 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567571, finearea: '1158307 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567572, finearea: '1158308 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567573, finearea: '1158309 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567574, finearea: '1158310 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567575, finearea: '1158311 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567576, finearea: '1158312 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567577, finearea: '1158313 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567578, finearea: '1158314 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567579, finearea: '1158315 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567580, finearea: '1158316 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567581, finearea: '1158317 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567582, finearea: '1158318 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567583, finearea: '1158319 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567584, finearea: '1158320 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567585, finearea: '1158321 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567586, finearea: '1158322 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567587, finearea: '1158323 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567588, finearea: '1158324 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567589, finearea: '1158325 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567590, finearea: '1158326 _ Cumberland (A)', subregion: 'Auburn - North', region: 'South and West Sydney' },
  { geography: 567591, finearea: '1158401 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567592, finearea: '1158402 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567593, finearea: '1158403 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567594, finearea: '1158404 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567595, finearea: '1158405 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567596, finearea: '1158406 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567597, finearea: '1158407 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567598, finearea: '1158408 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567599, finearea: '1158409 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567600, finearea: '1158410 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567601, finearea: '1158411 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567602, finearea: '1158412 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567603, finearea: '1158413 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567604, finearea: '1158414 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567605, finearea: '1158415 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567606, finearea: '1158416 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567607, finearea: '1158417 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567608, finearea: '1158418 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567609, finearea: '1158419 _ Cumberland (A)', subregion: 'Auburn - South', region: 'South and West Sydney' },
  { geography: 567610, finearea: '1158501 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567611, finearea: '1158502 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567612, finearea: '1158503 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567613, finearea: '1158504 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567614, finearea: '1158505 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567615, finearea: '1158506 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567616, finearea: '1158507 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567617, finearea: '1158508 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567618, finearea: '1158509 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567619, finearea: '1158510 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567620, finearea: '1158511 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567621, finearea: '1158512 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567622, finearea: '1158513 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567623, finearea: '1158514 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567624, finearea: '1158515 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567625, finearea: '1158516 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567626, finearea: '1158517 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567627, finearea: '1158518 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567628, finearea: '1158519 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567629, finearea: '1158520 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567630, finearea: '1158521 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567631, finearea: '1158522 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567632, finearea: '1158523 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567633, finearea: '1158524 _ Cumberland (A)', subregion: 'Berala', region: 'South and West Sydney' },
  { geography: 567634, finearea: '1158601 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567635, finearea: '1158602 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 567636, finearea: '1158603 _ Cumberland (A)', subregion: 'Lidcombe - Rookwood Cemetery', region: 'South and West Sydney' },
  { geography: 563437, finearea: '1156005 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563438, finearea: '1156006 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563439, finearea: '1156007 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563440, finearea: '1156008 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563441, finearea: '1156009 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563442, finearea: '1156010 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563443, finearea: '1156011 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563444, finearea: '1156012 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563445, finearea: '1156013 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563446, finearea: '1156014 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563447, finearea: '1156015 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563448, finearea: '1156016 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563449, finearea: '1156017 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563450, finearea: '1156018 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563451, finearea: '1156019 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563452, finearea: '1156020 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563453, finearea: '1156021 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563454, finearea: '1156022 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563455, finearea: '1156023 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563456, finearea: '1156024 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563457, finearea: '1156025 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563458, finearea: '1156026 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563459, finearea: '1156027 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563460, finearea: '1156028 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563461, finearea: '1156101 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563462, finearea: '1156102 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563463, finearea: '1156103 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563464, finearea: '1156104 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563465, finearea: '1156105 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563466, finearea: '1156106 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563467, finearea: '1156107 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563468, finearea: '1156108 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563469, finearea: '1156109 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563470, finearea: '1156110 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563471, finearea: '1156111 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563472, finearea: '1156112 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563473, finearea: '1156113 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563474, finearea: '1156114 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563475, finearea: '1156115 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563476, finearea: '1156116 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563477, finearea: '1156117 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563478, finearea: '1156118 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563479, finearea: '1156119 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563480, finearea: '1156120 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563481, finearea: '1156121 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563482, finearea: '1156122 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563483, finearea: '1156123 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563484, finearea: '1156124 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563485, finearea: '1156125 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563486, finearea: '1156126 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563487, finearea: '1156127 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563488, finearea: '1156128 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563489, finearea: '1156129 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563490, finearea: '1156130 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563491, finearea: '1156131 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563492, finearea: '1156132 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563493, finearea: '1156133 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563494, finearea: '1156134 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563495, finearea: '1156135 _ Blacktown (C)', subregion: 'Blacktown (West)', region: 'Northern Sydney' },
  { geography: 563496, finearea: '1130901 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563497, finearea: '1130902 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563498, finearea: '1130903 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563499, finearea: '1130904 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563500, finearea: '1130905 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563501, finearea: '1130906 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563502, finearea: '1130907 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563503, finearea: '1130908 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563504, finearea: '1130909 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563505, finearea: '1130910 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563506, finearea: '1130911 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563507, finearea: '1130912 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563508, finearea: '1130913 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563509, finearea: '1130914 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563510, finearea: '1130915 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563511, finearea: '1130916 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563512, finearea: '1130917 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563513, finearea: '1130918 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563514, finearea: '1130919 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563515, finearea: '1130920 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563516, finearea: '1130921 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563517, finearea: '1130922 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563518, finearea: '1130923 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563519, finearea: '1130924 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563520, finearea: '1130925 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563521, finearea: '1130926 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563522, finearea: '1130927 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563523, finearea: '1130928 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563524, finearea: '1130929 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563525, finearea: '1130930 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563526, finearea: '1130931 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563527, finearea: '1130932 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563528, finearea: '1130933 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563529, finearea: '1130934 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563530, finearea: '1130935 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563531, finearea: '1130936 _ Blacktown (C)', subregion: 'Glenwood', region: 'Northern Sydney' },
  { geography: 563532, finearea: '1131004 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563533, finearea: '1131005 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563534, finearea: '1131006 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563535, finearea: '1131007 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563536, finearea: '1131008 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563537, finearea: '1131011 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563538, finearea: '1131012 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563539, finearea: '1131013 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563540, finearea: '1131014 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563541, finearea: '1131015 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563542, finearea: '1131017 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563543, finearea: '1131018 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563544, finearea: '1131019 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563545, finearea: '1131020 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563546, finearea: '1131021 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563547, finearea: '1131022 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563548, finearea: '1131024 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563549, finearea: '1131025 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563550, finearea: '1131027 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563551, finearea: '1131030 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563552, finearea: '1131032 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563553, finearea: '1131033 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563554, finearea: '1131034 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563555, finearea: '1131035 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563556, finearea: '1131036 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563557, finearea: '1131037 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563558, finearea: '1131038 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563559, finearea: '1131039 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563560, finearea: '1131040 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563561, finearea: '1131041 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563562, finearea: '1131042 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563563, finearea: '1131043 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563564, finearea: '1131044 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563565, finearea: '1131045 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563566, finearea: '1131046 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563567, finearea: '1131047 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563568, finearea: '1131048 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563569, finearea: '1131049 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563570, finearea: '1131050 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563571, finearea: '1131051 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563572, finearea: '1131052 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563573, finearea: '1131053 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563574, finearea: '1131054 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563575, finearea: '1131055 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563576, finearea: '1131056 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563577, finearea: '1131057 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563578, finearea: '1131058 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563579, finearea: '1131059 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563580, finearea: '1131060 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563581, finearea: '1131061 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563582, finearea: '1131062 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563583, finearea: '1131063 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563584, finearea: '1131064 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563585, finearea: '1131065 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563586, finearea: '1131066 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563587, finearea: '1131067 _ Blacktown (C)', subregion: 'Parklea - Kellyville Ridge', region: 'Northern Sydney' },
  { geography: 563588, finearea: '1131201 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563589, finearea: '1131203 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563590, finearea: '1131204 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563591, finearea: '1131205 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563592, finearea: '1131206 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563593, finearea: '1131207 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563594, finearea: '1131208 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563595, finearea: '1131209 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563596, finearea: '1131210 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563597, finearea: '1131211 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563598, finearea: '1131213 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563599, finearea: '1131214 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563600, finearea: '1131215 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563601, finearea: '1131216 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563602, finearea: '1131217 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563603, finearea: '1131218 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563604, finearea: '1131219 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563605, finearea: '1131220 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563606, finearea: '1131221 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563607, finearea: '1131222 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563608, finearea: '1131223 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563609, finearea: '1131225 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563610, finearea: '1131226 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563611, finearea: '1131227 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563612, finearea: '1131228 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563613, finearea: '1131229 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563614, finearea: '1131230 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563615, finearea: '1131231 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563616, finearea: '1131232 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563617, finearea: '1131233 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563618, finearea: '1131234 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563619, finearea: '1131235 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563620, finearea: '1131236 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563621, finearea: '1131237 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563622, finearea: '1131238 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563623, finearea: '1131239 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563624, finearea: '1131240 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563625, finearea: '1131241 _ Blacktown (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 563626, finearea: '1156201 _ Blacktown (C)', subregion: 'Acacia Gardens', region: 'Northern Sydney' },
  { geography: 563627, finearea: '1156202 _ Blacktown (C)', subregion: 'Acacia Gardens', region: 'Northern Sydney' },
  { geography: 563628, finearea: '1156203 _ Blacktown (C)', subregion: 'Acacia Gardens', region: 'Northern Sydney' },
  { geography: 563629, finearea: '1156204 _ Blacktown (C)', subregion: 'Acacia Gardens', region: 'Northern Sydney' },
  { geography: 563630, finearea: '1156205 _ Blacktown (C)', subregion: 'Acacia Gardens', region: 'Northern Sydney' },
  { geography: 563631, finearea: '1156206 _ Blacktown (C)', subregion: 'Acacia Gardens', region: 'Northern Sydney' },
  { geography: 563632, finearea: '1156207 _ Blacktown (C)', subregion: 'Acacia Gardens', region: 'Northern Sydney' },
  { geography: 563633, finearea: '1156208 _ Blacktown (C)', subregion: 'Acacia Gardens', region: 'Northern Sydney' },
  { geography: 563634, finearea: '1156209 _ Blacktown (C)', subregion: 'Acacia Gardens', region: 'Northern Sydney' },
  { geography: 563635, finearea: '1156301 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 563636, finearea: '1156302 _ Blacktown (C)', subregion: 'Quakers Hill', region: 'Northern Sydney' },
  { geography: 564037, finearea: '1145012 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564038, finearea: '1145013 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564039, finearea: '1145014 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564040, finearea: '1145015 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564041, finearea: '1145016 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564042, finearea: '1145017 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564043, finearea: '1145018 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564044, finearea: '1145019 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564045, finearea: '1145020 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564046, finearea: '1145021 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564047, finearea: '1145022 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564048, finearea: '1145023 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564049, finearea: '1145024 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564050, finearea: '1145025 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564051, finearea: '1145026 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564052, finearea: '1145027 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564053, finearea: '1145028 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564054, finearea: '1145029 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564055, finearea: '1145030 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564056, finearea: '1145031 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564057, finearea: '1145032 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564058, finearea: '1145033 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564059, finearea: '1145034 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564060, finearea: '1145035 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564061, finearea: '1145036 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564062, finearea: '1145037 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564063, finearea: '1145038 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564064, finearea: '1145039 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564065, finearea: '1145040 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564066, finearea: '1145041 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564067, finearea: '1145042 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564068, finearea: '1145043 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564069, finearea: '1145044 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564070, finearea: '1145045 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564071, finearea: '1145046 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564072, finearea: '1145047 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564073, finearea: '1145048 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564074, finearea: '1145049 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564075, finearea: '1145050 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564076, finearea: '1145051 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564077, finearea: '1145101 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564078, finearea: '1145201 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564079, finearea: '1145202 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564080, finearea: '1145203 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564081, finearea: '1145204 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564082, finearea: '1145205 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564083, finearea: '1145206 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564084, finearea: '1145207 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564085, finearea: '1145208 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564086, finearea: '1145209 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564087, finearea: '1145210 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564088, finearea: '1145211 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564089, finearea: '1145212 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564090, finearea: '1145213 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564091, finearea: '1145214 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564092, finearea: '1145215 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564093, finearea: '1145216 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564094, finearea: '1145217 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564095, finearea: '1145218 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564096, finearea: '1145219 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564097, finearea: '1145220 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564098, finearea: '1145221 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564099, finearea: '1145222 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564100, finearea: '1145223 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564101, finearea: '1145224 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564102, finearea: '1145225 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564103, finearea: '1145226 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564104, finearea: '1145227 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564105, finearea: '1145228 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564106, finearea: '1145229 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564107, finearea: '1145230 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564108, finearea: '1145231 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564109, finearea: '1145232 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564110, finearea: '1145233 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564111, finearea: '1145234 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564112, finearea: '1145235 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564113, finearea: '1145236 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564114, finearea: '1145237 _ Blue Mountains (C)', subregion: 'Katoomba - Leura', region: 'Northern Sydney' },
  { geography: 564115, finearea: '1145301 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564116, finearea: '1145302 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564117, finearea: '1145303 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564118, finearea: '1145304 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564119, finearea: '1145305 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564120, finearea: '1145306 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564121, finearea: '1145307 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564122, finearea: '1145308 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564123, finearea: '1145309 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564124, finearea: '1145310 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564125, finearea: '1145311 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564126, finearea: '1145312 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564127, finearea: '1145313 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564128, finearea: '1145314 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564129, finearea: '1145315 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564130, finearea: '1145316 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564131, finearea: '1145317 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564132, finearea: '1145318 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564133, finearea: '1145319 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564134, finearea: '1145320 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564135, finearea: '1145321 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564136, finearea: '1145322 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564137, finearea: '1145323 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564138, finearea: '1145324 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564139, finearea: '1145325 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564140, finearea: '1145326 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564141, finearea: '1145327 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564142, finearea: '1145328 _ Blue Mountains (C)', subregion: 'Lawson - Hazelbrook - Linden', region: 'Northern Sydney' },
  { geography: 564143, finearea: '1145401 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564144, finearea: '1145402 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564145, finearea: '1145403 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564146, finearea: '1145404 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564147, finearea: '1145405 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564148, finearea: '1145406 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564149, finearea: '1145407 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564150, finearea: '1145408 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564151, finearea: '1145409 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564152, finearea: '1145410 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564153, finearea: '1145411 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564154, finearea: '1145412 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564155, finearea: '1145413 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564156, finearea: '1145414 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564157, finearea: '1145415 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564158, finearea: '1145416 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564159, finearea: '1145417 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564160, finearea: '1145418 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564161, finearea: '1145419 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564162, finearea: '1145420 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564163, finearea: '1145421 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564164, finearea: '1145422 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564165, finearea: '1145423 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564166, finearea: '1145424 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564167, finearea: '1145425 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564168, finearea: '1145426 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564169, finearea: '1145427 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564170, finearea: '1145428 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564171, finearea: '1145429 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564172, finearea: '1145430 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564173, finearea: '1145431 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564174, finearea: '1145432 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564175, finearea: '1145433 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564176, finearea: '1145434 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564177, finearea: '1145435 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564178, finearea: '1145436 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564179, finearea: '1145437 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564180, finearea: '1145438 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564181, finearea: '1145439 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564182, finearea: '1145440 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564183, finearea: '1145441 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564184, finearea: '1145442 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564185, finearea: '1145443 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564186, finearea: '1145444 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564187, finearea: '1145445 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564188, finearea: '1145446 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564189, finearea: '1145447 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564190, finearea: '1145448 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564191, finearea: '1145449 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564192, finearea: '1145450 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564193, finearea: '1145451 _ Blue Mountains (C)', subregion: 'Springwood - Winmalee', region: 'Northern Sydney' },
  { geography: 564194, finearea: '1145501 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564195, finearea: '1145502 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564196, finearea: '1145503 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564197, finearea: '1145504 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564198, finearea: '1145505 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564199, finearea: '1145506 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564200, finearea: '1145507 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564201, finearea: '1145508 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564202, finearea: '1145509 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564203, finearea: '1145510 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564204, finearea: '1145511 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564205, finearea: '1145512 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564206, finearea: '1145513 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564207, finearea: '1145514 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564208, finearea: '1145515 _ Blue Mountains (C)', subregion: 'Wentworth Falls', region: 'Northern Sydney' },
  { geography: 564209, finearea: '1145601 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564210, finearea: '1109209 _ Bogan (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564211, finearea: '1109317 _ Bogan (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 564212, finearea: '1109501 _ Bogan (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 564213, finearea: '1109503 _ Bogan (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 564214, finearea: '1109504 _ Bogan (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 564215, finearea: '1109506 _ Bogan (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 564216, finearea: '1109507 _ Bogan (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 564217, finearea: '1109508 _ Bogan (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 564218, finearea: '1109509 _ Bogan (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 564219, finearea: '1109510 _ Bogan (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 564220, finearea: '1109201 _ Bourke (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564221, finearea: '1109202 _ Bourke (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564222, finearea: '1109203 _ Bourke (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564223, finearea: '1109204 _ Bourke (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564224, finearea: '1109205 _ Bourke (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564225, finearea: '1109211 _ Bourke (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564226, finearea: '1109212 _ Bourke (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564227, finearea: '1109213 _ Bourke (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564228, finearea: '1109207 _ Brewarrina (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564229, finearea: '1109208 _ Brewarrina (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564230, finearea: '1109209 _ Brewarrina (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564231, finearea: '1109210 _ Brewarrina (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 564232, finearea: '1109506 _ Brewarrina (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 564233, finearea: '1109603 _ Brewarrina (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 564234, finearea: '1109617 _ Brewarrina (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 564235, finearea: '1109701 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564236, finearea: '1109702 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564637, finearea: '1143568 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564638, finearea: '1143569 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564639, finearea: '1143570 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564640, finearea: '1143571 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564641, finearea: '1143572 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564642, finearea: '1143573 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564643, finearea: '1143574 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564644, finearea: '1143575 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564645, finearea: '1143576 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564646, finearea: '1143859 _ Camden (A)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564647, finearea: '1150601 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564648, finearea: '1150604 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564649, finearea: '1150606 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564650, finearea: '1150607 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564651, finearea: '1150609 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564652, finearea: '1150610 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564653, finearea: '1150612 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564654, finearea: '1150613 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564655, finearea: '1150614 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564656, finearea: '1150615 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564657, finearea: '1150616 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564658, finearea: '1150617 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564659, finearea: '1150618 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564660, finearea: '1150619 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564661, finearea: '1150620 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564662, finearea: '1150621 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564663, finearea: '1150622 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564664, finearea: '1150623 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564665, finearea: '1150624 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564666, finearea: '1150625 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564667, finearea: '1150626 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564668, finearea: '1150627 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564669, finearea: '1150628 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564670, finearea: '1150629 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564671, finearea: '1150630 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564672, finearea: '1150631 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564673, finearea: '1150632 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564674, finearea: '1150633 _ Camden (A)', subregion: 'Cobbitty - Leppington', region: 'South and West Sydney' },
  { geography: 564675, finearea: '1114716 _ Campbelltown (C) (NSW)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 564676, finearea: '1143558 _ Campbelltown (C) (NSW)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564677, finearea: '1143601 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564678, finearea: '1143602 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564679, finearea: '1143603 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564680, finearea: '1143604 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564681, finearea: '1143605 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564682, finearea: '1143606 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564683, finearea: '1143607 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564684, finearea: '1143608 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564685, finearea: '1143609 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564686, finearea: '1143610 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564687, finearea: '1143611 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564688, finearea: '1143612 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564689, finearea: '1143613 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564690, finearea: '1143614 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564691, finearea: '1143615 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564692, finearea: '1143616 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564693, finearea: '1143617 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564694, finearea: '1143618 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564695, finearea: '1143619 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564696, finearea: '1143620 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564697, finearea: '1143621 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564698, finearea: '1143622 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564699, finearea: '1143623 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564700, finearea: '1143624 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564701, finearea: '1143625 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564702, finearea: '1143626 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564703, finearea: '1143627 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564704, finearea: '1143628 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564705, finearea: '1143629 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564706, finearea: '1143630 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564707, finearea: '1143631 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564708, finearea: '1143632 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564709, finearea: '1143633 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564710, finearea: '1143634 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564711, finearea: '1143635 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564712, finearea: '1143636 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564713, finearea: '1143637 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564714, finearea: '1143638 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564715, finearea: '1143639 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564716, finearea: '1143640 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564717, finearea: '1143641 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564718, finearea: '1143642 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564719, finearea: '1143643 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564720, finearea: '1143644 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564721, finearea: '1143645 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564722, finearea: '1143646 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564723, finearea: '1143701 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564724, finearea: '1143702 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564725, finearea: '1143703 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564726, finearea: '1143705 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564727, finearea: '1143706 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564728, finearea: '1143707 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564729, finearea: '1143708 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564730, finearea: '1143709 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564731, finearea: '1143710 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564732, finearea: '1143711 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564733, finearea: '1143712 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564734, finearea: '1143713 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564735, finearea: '1143714 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564736, finearea: '1143715 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564737, finearea: '1143716 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564738, finearea: '1143717 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564739, finearea: '1143718 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564740, finearea: '1143719 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564741, finearea: '1143720 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564742, finearea: '1143721 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564743, finearea: '1143722 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564744, finearea: '1143723 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564745, finearea: '1143724 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564746, finearea: '1143725 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564747, finearea: '1143726 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564748, finearea: '1143727 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564749, finearea: '1143728 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564750, finearea: '1143729 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564751, finearea: '1143730 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564752, finearea: '1143731 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564753, finearea: '1143732 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564754, finearea: '1143733 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564755, finearea: '1143735 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564756, finearea: '1143736 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564757, finearea: '1143737 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564758, finearea: '1143738 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564759, finearea: '1143739 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564760, finearea: '1143740 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564761, finearea: '1143741 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564762, finearea: '1143742 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564763, finearea: '1143743 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564764, finearea: '1143744 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564765, finearea: '1143745 _ Campbelltown (C) (NSW)', subregion: 'Campbelltown - Woodbine', region: 'South and West Sydney' },
  { geography: 564766, finearea: '1143801 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564767, finearea: '1143802 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564768, finearea: '1143803 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564769, finearea: '1143804 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564770, finearea: '1143805 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564771, finearea: '1143806 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564772, finearea: '1143807 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564773, finearea: '1143808 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564774, finearea: '1143809 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564775, finearea: '1143810 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564776, finearea: '1143811 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564777, finearea: '1143812 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564778, finearea: '1143813 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564779, finearea: '1143814 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564780, finearea: '1143815 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564781, finearea: '1143816 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564782, finearea: '1143817 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564783, finearea: '1143818 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564784, finearea: '1143819 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564785, finearea: '1143820 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564786, finearea: '1143821 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564787, finearea: '1143822 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564788, finearea: '1143823 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564789, finearea: '1143824 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564790, finearea: '1143825 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564791, finearea: '1143826 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564792, finearea: '1143827 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564793, finearea: '1143828 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564794, finearea: '1143829 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564795, finearea: '1143830 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564796, finearea: '1143831 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564797, finearea: '1143833 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564798, finearea: '1143834 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564799, finearea: '1143835 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564800, finearea: '1143836 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564801, finearea: '1143837 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564802, finearea: '1143838 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564803, finearea: '1143839 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564804, finearea: '1143840 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564805, finearea: '1143841 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564806, finearea: '1143842 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564807, finearea: '1143843 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564808, finearea: '1143844 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564809, finearea: '1143845 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564810, finearea: '1143846 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564811, finearea: '1143847 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564812, finearea: '1143848 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564813, finearea: '1143849 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564814, finearea: '1143850 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564815, finearea: '1143851 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564816, finearea: '1143852 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564817, finearea: '1143853 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564818, finearea: '1143855 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564819, finearea: '1143856 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564820, finearea: '1143857 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564821, finearea: '1143858 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564822, finearea: '1143859 _ Campbelltown (C) (NSW)', subregion: 'Claymore - Eagle Vale - Raby', region: 'South and West Sydney' },
  { geography: 564823, finearea: '1143901 _ Campbelltown (C) (NSW)', subregion: 'Bradbury - Wedderburn - Holsworthy Military Area', region: 'South and West Sydney' },
  { geography: 564824, finearea: '1144001 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564825, finearea: '1144002 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564826, finearea: '1144003 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564827, finearea: '1144005 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564828, finearea: '1144006 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564829, finearea: '1144007 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564830, finearea: '1144008 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564831, finearea: '1144009 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564832, finearea: '1144010 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564833, finearea: '1144011 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564834, finearea: '1144012 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564835, finearea: '1144013 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564836, finearea: '1144014 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 570037, finearea: '1149638 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570038, finearea: '1149639 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570039, finearea: '1149640 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570040, finearea: '1149641 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570041, finearea: '1149642 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570042, finearea: '1149643 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570043, finearea: '1149644 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570044, finearea: '1149645 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570045, finearea: '1149646 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570046, finearea: '1149647 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570047, finearea: '1149648 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570048, finearea: '1149649 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570049, finearea: '1149650 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570050, finearea: '1149808 _ Hunters Hill (A)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 570051, finearea: '1149809 _ Hunters Hill (A)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 570052, finearea: '1149810 _ Hunters Hill (A)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 570053, finearea: '1149811 _ Hunters Hill (A)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 570054, finearea: '1149812 _ Hunters Hill (A)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 570055, finearea: '1149813 _ Hunters Hill (A)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 570056, finearea: '1149814 _ Hunters Hill (A)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 570057, finearea: '1149815 _ Hunters Hill (A)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 570058, finearea: '1149824 _ Hunters Hill (A)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 570059, finearea: '1149901 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570060, finearea: '1149902 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570061, finearea: '1149903 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570062, finearea: '1149904 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570063, finearea: '1149905 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570064, finearea: '1149906 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570065, finearea: '1149907 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570066, finearea: '1149908 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570067, finearea: '1149909 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570068, finearea: '1149910 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570069, finearea: '1149911 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570070, finearea: '1149912 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570071, finearea: '1149913 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570072, finearea: '1149914 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570073, finearea: '1149915 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570074, finearea: '1149916 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570075, finearea: '1149917 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570076, finearea: '1149918 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570077, finearea: '1149919 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570078, finearea: '1149920 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570079, finearea: '1149921 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570080, finearea: '1149922 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570081, finearea: '1149923 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570082, finearea: '1149924 _ Hunters Hill (A)', subregion: 'Hunters Hill - Woolwich', region: 'Northern Sydney' },
  { geography: 570083, finearea: '1132501 _ Inner West (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 570084, finearea: '1132601 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570085, finearea: '1132602 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570086, finearea: '1132603 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570087, finearea: '1132604 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570088, finearea: '1132605 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570089, finearea: '1132606 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570090, finearea: '1132607 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570091, finearea: '1132608 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570092, finearea: '1132609 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570093, finearea: '1132610 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570094, finearea: '1132611 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570095, finearea: '1132612 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570096, finearea: '1132613 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570097, finearea: '1132614 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570098, finearea: '1132615 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570099, finearea: '1132616 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570100, finearea: '1132617 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570101, finearea: '1132618 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570102, finearea: '1132619 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570103, finearea: '1132620 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570104, finearea: '1132621 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570105, finearea: '1132622 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570106, finearea: '1132624 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570107, finearea: '1132625 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570108, finearea: '1132626 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570109, finearea: '1132627 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570110, finearea: '1132628 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570111, finearea: '1132629 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570112, finearea: '1132630 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570113, finearea: '1132631 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570114, finearea: '1132632 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570115, finearea: '1132633 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570116, finearea: '1132634 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570117, finearea: '1132635 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570118, finearea: '1132636 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570119, finearea: '1132637 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570120, finearea: '1132638 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570121, finearea: '1132639 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570122, finearea: '1132640 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570123, finearea: '1132641 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570124, finearea: '1132642 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570125, finearea: '1132643 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570126, finearea: '1132644 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570127, finearea: '1132645 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570128, finearea: '1132646 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570129, finearea: '1132647 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570130, finearea: '1132648 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570131, finearea: '1132649 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570132, finearea: '1132650 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570133, finearea: '1132651 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570134, finearea: '1132652 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570135, finearea: '1132653 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570136, finearea: '1132654 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570137, finearea: '1132655 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570138, finearea: '1132656 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570139, finearea: '1132657 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570140, finearea: '1132658 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570141, finearea: '1132659 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570142, finearea: '1132660 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570143, finearea: '1132661 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570144, finearea: '1132662 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570145, finearea: '1132663 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570146, finearea: '1132664 _ Inner West (A)', subregion: 'Marrickville', region: 'Inner Sydney' },
  { geography: 570147, finearea: '1132701 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570148, finearea: '1132702 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570149, finearea: '1132703 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570150, finearea: '1132704 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570151, finearea: '1132705 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570152, finearea: '1132706 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570153, finearea: '1132707 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570154, finearea: '1132708 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570155, finearea: '1132709 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570156, finearea: '1132710 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570157, finearea: '1132711 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570158, finearea: '1132712 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570159, finearea: '1132713 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570160, finearea: '1132714 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570161, finearea: '1132715 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570162, finearea: '1132716 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570163, finearea: '1132717 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570164, finearea: '1132718 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570165, finearea: '1132719 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570166, finearea: '1132720 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570167, finearea: '1132721 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570168, finearea: '1132722 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570169, finearea: '1132723 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570170, finearea: '1132724 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570171, finearea: '1132725 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570172, finearea: '1132726 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570173, finearea: '1132727 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570174, finearea: '1132728 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570175, finearea: '1132729 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570176, finearea: '1132730 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570177, finearea: '1132731 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570178, finearea: '1132732 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570179, finearea: '1132733 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570180, finearea: '1132734 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570181, finearea: '1132735 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570182, finearea: '1132736 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570183, finearea: '1132737 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570184, finearea: '1132738 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570185, finearea: '1132739 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570186, finearea: '1132740 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570187, finearea: '1132741 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570188, finearea: '1132742 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570189, finearea: '1132743 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570190, finearea: '1132744 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570191, finearea: '1132745 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570192, finearea: '1132746 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570193, finearea: '1132747 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570194, finearea: '1132748 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570195, finearea: '1132749 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570196, finearea: '1132750 _ Inner West (A)', subregion: 'Petersham - Stanmore', region: 'Inner Sydney' },
  { geography: 570197, finearea: '1132801 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570198, finearea: '1132802 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570199, finearea: '1132803 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570200, finearea: '1132804 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570201, finearea: '1132805 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570202, finearea: '1132806 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570203, finearea: '1132807 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570204, finearea: '1132808 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570205, finearea: '1132809 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570206, finearea: '1132810 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570207, finearea: '1132811 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570208, finearea: '1132812 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570209, finearea: '1132813 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570210, finearea: '1132814 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570211, finearea: '1132815 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570212, finearea: '1132816 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570213, finearea: '1132817 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570214, finearea: '1132818 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570215, finearea: '1132819 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570216, finearea: '1132820 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570217, finearea: '1132821 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570218, finearea: '1132822 _ Inner West (A)', subregion: 'Sydenham - Tempe - St Peters', region: 'Inner Sydney' },
  { geography: 570219, finearea: '1133206 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570220, finearea: '1133207 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570221, finearea: '1133208 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570222, finearea: '1133218 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570223, finearea: '1133219 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570224, finearea: '1133220 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570225, finearea: '1133221 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570226, finearea: '1133222 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570227, finearea: '1133224 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570228, finearea: '1133225 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570229, finearea: '1133226 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570230, finearea: '1133227 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570231, finearea: '1133228 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570232, finearea: '1133229 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570233, finearea: '1133231 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570234, finearea: '1133233 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570235, finearea: '1133234 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570236, finearea: '1133235 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 581328, finearea: '1144715 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581329, finearea: '1144716 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581330, finearea: '1144717 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581331, finearea: '1144718 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581332, finearea: '1144719 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581333, finearea: '1144720 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581334, finearea: '1144721 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581335, finearea: '1144722 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581336, finearea: '1144723 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581337, finearea: '1144724 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581338, finearea: '1144725 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581339, finearea: '1144726 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581340, finearea: '1144727 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581341, finearea: '1144728 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581342, finearea: '1144729 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581343, finearea: '1144730 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581344, finearea: '1144731 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581345, finearea: '1144732 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581346, finearea: '1144733 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581347, finearea: '1144734 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581348, finearea: '1144735 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581349, finearea: '1144736 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581350, finearea: '1144737 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581351, finearea: '1144738 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581352, finearea: '1144739 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581353, finearea: '1144740 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581354, finearea: '1144741 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581355, finearea: '1144742 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581356, finearea: '1144743 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581357, finearea: '1144801 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581358, finearea: '1144802 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581359, finearea: '1144803 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581360, finearea: '1144804 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581361, finearea: '1144805 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581362, finearea: '1144806 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581363, finearea: '1144807 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581364, finearea: '1144808 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581365, finearea: '1144809 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581366, finearea: '1144810 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581367, finearea: '1144811 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581368, finearea: '1144812 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581369, finearea: '1144813 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581370, finearea: '1144814 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581371, finearea: '1144815 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581372, finearea: '1144816 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581373, finearea: '1144817 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581374, finearea: '1144818 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581375, finearea: '1144819 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581376, finearea: '1144820 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581377, finearea: '1144821 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581378, finearea: '1144822 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581379, finearea: '1144823 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581380, finearea: '1144824 _ Wollondilly (A)', subregion: 'The Oaks - Oakdale', region: 'South and West Sydney' },
  { geography: 581381, finearea: '1145601 _ Wollondilly (A)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 581382, finearea: '1146501 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581383, finearea: '1146502 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581384, finearea: '1146503 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581385, finearea: '1146504 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581386, finearea: '1146505 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581387, finearea: '1146507 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581388, finearea: '1146508 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581389, finearea: '1146509 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581390, finearea: '1146510 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581391, finearea: '1146511 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581392, finearea: '1146512 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581393, finearea: '1146513 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581394, finearea: '1146514 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581395, finearea: '1146515 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581396, finearea: '1146516 _ Wollondilly (A)', subregion: 'Warragamba - Silverdale', region: 'Northern Sydney' },
  { geography: 581397, finearea: '1113101 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581398, finearea: '1113102 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581399, finearea: '1113103 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581400, finearea: '1113104 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581401, finearea: '1113105 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581402, finearea: '1113106 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581403, finearea: '1113107 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581404, finearea: '1113108 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581405, finearea: '1113109 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581406, finearea: '1113110 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581407, finearea: '1113111 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581408, finearea: '1113112 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581409, finearea: '1113113 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581410, finearea: '1113114 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581411, finearea: '1113115 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581412, finearea: '1113116 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581413, finearea: '1113117 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581414, finearea: '1113118 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581415, finearea: '1113119 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581416, finearea: '1113120 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581417, finearea: '1113121 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581418, finearea: '1113122 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581419, finearea: '1113123 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581420, finearea: '1113124 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581421, finearea: '1113125 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581422, finearea: '1113126 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581423, finearea: '1113127 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581424, finearea: '1113128 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581425, finearea: '1113129 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581426, finearea: '1113130 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581427, finearea: '1113131 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581428, finearea: '1113132 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581429, finearea: '1113133 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581430, finearea: '1113134 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581431, finearea: '1113135 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581432, finearea: '1113136 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581433, finearea: '1113137 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581434, finearea: '1113138 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581435, finearea: '1113139 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581436, finearea: '1113140 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581437, finearea: '1113141 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581438, finearea: '1113142 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581439, finearea: '1113143 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581440, finearea: '1113144 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581441, finearea: '1113145 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581442, finearea: '1113146 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581443, finearea: '1113147 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581444, finearea: '1113148 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581445, finearea: '1113149 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581446, finearea: '1113150 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581447, finearea: '1113151 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581448, finearea: '1113152 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581449, finearea: '1113153 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581450, finearea: '1113154 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581451, finearea: '1113155 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581452, finearea: '1113156 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581453, finearea: '1113157 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581454, finearea: '1113158 _ Wollongong (C)', subregion: 'Dapto - Avondale', region: 'Illawarra-Shoalhaven' },
  { geography: 581455, finearea: '1113201 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581456, finearea: '1113202 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581457, finearea: '1113203 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581458, finearea: '1113204 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581459, finearea: '1113205 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581460, finearea: '1113206 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581461, finearea: '1113207 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581462, finearea: '1113208 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581463, finearea: '1113209 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581464, finearea: '1113210 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581465, finearea: '1113211 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581466, finearea: '1113212 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581467, finearea: '1113213 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581468, finearea: '1113214 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581469, finearea: '1113215 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581470, finearea: '1113216 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581471, finearea: '1113217 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581472, finearea: '1113218 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581473, finearea: '1113219 _ Wollongong (C)', subregion: 'Horsley - Kembla Grange', region: 'Illawarra-Shoalhaven' },
  { geography: 581474, finearea: '1113301 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581475, finearea: '1113401 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581476, finearea: '1113402 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581477, finearea: '1113403 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581478, finearea: '1113404 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581479, finearea: '1113405 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581480, finearea: '1113406 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581481, finearea: '1113407 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581482, finearea: '1113409 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581483, finearea: '1113410 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581484, finearea: '1113411 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581485, finearea: '1113412 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581486, finearea: '1113413 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581487, finearea: '1113414 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581488, finearea: '1113415 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581489, finearea: '1113416 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581490, finearea: '1113417 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581491, finearea: '1113418 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581492, finearea: '1113419 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581493, finearea: '1113421 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581494, finearea: '1113422 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581495, finearea: '1113423 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581496, finearea: '1113424 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581497, finearea: '1113425 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581498, finearea: '1113426 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581499, finearea: '1113427 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581500, finearea: '1113428 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581501, finearea: '1113429 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581502, finearea: '1113431 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581503, finearea: '1113432 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581504, finearea: '1113433 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581505, finearea: '1113434 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581506, finearea: '1113435 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581507, finearea: '1113436 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581508, finearea: '1113437 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581509, finearea: '1113438 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581510, finearea: '1113439 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581511, finearea: '1113440 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581512, finearea: '1113441 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581513, finearea: '1113442 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581514, finearea: '1113443 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581515, finearea: '1113444 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581516, finearea: '1113445 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581517, finearea: '1113446 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581518, finearea: '1113447 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581519, finearea: '1113448 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581520, finearea: '1113449 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581521, finearea: '1113450 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581522, finearea: '1113451 _ Wollongong (C)', subregion: 'Unanderra - Mount Kembla', region: 'Illawarra-Shoalhaven' },
  { geography: 581523, finearea: '1154501 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581524, finearea: '1154502 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581525, finearea: '1154503 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581526, finearea: '1154504 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581527, finearea: '1154505 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 576728, finearea: '1112516 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576729, finearea: '1112517 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576730, finearea: '1112518 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576731, finearea: '1112519 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576732, finearea: '1112520 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576733, finearea: '1112521 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576734, finearea: '1112522 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576735, finearea: '1112523 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576736, finearea: '1112524 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576737, finearea: '1112525 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576738, finearea: '1112526 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576739, finearea: '1112527 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576740, finearea: '1112528 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576741, finearea: '1112529 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576742, finearea: '1112530 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576743, finearea: '1112531 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576744, finearea: '1112532 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576745, finearea: '1112533 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576746, finearea: '1112534 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576747, finearea: '1112535 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576748, finearea: '1112536 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576749, finearea: '1112537 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576750, finearea: '1123212 _ Port Stephens (A)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 576751, finearea: '1123213 _ Port Stephens (A)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 576752, finearea: '1123214 _ Port Stephens (A)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 576753, finearea: '1123215 _ Port Stephens (A)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 576754, finearea: '1123216 _ Port Stephens (A)', subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang', region: 'Central Coast and Hunter' },
  { geography: 576755, finearea: '1100702 _ Queanbeyan-Palerang Regional (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 576756, finearea: '1100703 _ Queanbeyan-Palerang Regional (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 576757, finearea: '1100704 _ Queanbeyan-Palerang Regional (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 576758, finearea: '1100705 _ Queanbeyan-Palerang Regional (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 576759, finearea: '1100706 _ Queanbeyan-Palerang Regional (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 576760, finearea: '1100707 _ Queanbeyan-Palerang Regional (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 576761, finearea: '1100708 _ Queanbeyan-Palerang Regional (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 576762, finearea: '1100709 _ Queanbeyan-Palerang Regional (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 576763, finearea: '1100710 _ Queanbeyan-Palerang Regional (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 576764, finearea: '1100801 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576765, finearea: '1100802 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576766, finearea: '1100803 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576767, finearea: '1100804 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576768, finearea: '1100805 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576769, finearea: '1100806 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576770, finearea: '1100807 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576771, finearea: '1100808 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576772, finearea: '1100809 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576773, finearea: '1100810 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576774, finearea: '1100811 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576775, finearea: '1100812 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576776, finearea: '1100813 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576777, finearea: '1100814 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576778, finearea: '1100815 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576779, finearea: '1100816 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576780, finearea: '1100817 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576781, finearea: '1100818 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576782, finearea: '1100819 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576783, finearea: '1100820 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576784, finearea: '1100821 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576785, finearea: '1100822 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576786, finearea: '1100823 _ Queanbeyan-Palerang Regional (A)', subregion: 'Karabar', region: 'Capital Region and South Coast' },
  { geography: 576787, finearea: '1100901 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576788, finearea: '1100902 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576789, finearea: '1100904 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576790, finearea: '1100905 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576791, finearea: '1100907 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576792, finearea: '1100908 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576793, finearea: '1100909 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576794, finearea: '1100910 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576795, finearea: '1100911 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576796, finearea: '1100912 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576797, finearea: '1100913 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576798, finearea: '1100914 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576799, finearea: '1100915 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576800, finearea: '1100916 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576801, finearea: '1100917 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576802, finearea: '1100918 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576803, finearea: '1100919 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576804, finearea: '1100920 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576805, finearea: '1100921 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576806, finearea: '1100923 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576807, finearea: '1100924 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576808, finearea: '1100925 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576809, finearea: '1100926 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576810, finearea: '1100927 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576811, finearea: '1100928 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576812, finearea: '1100929 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan', region: 'Capital Region and South Coast' },
  { geography: 576813, finearea: '1101001 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576814, finearea: '1101002 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576815, finearea: '1101003 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576816, finearea: '1101004 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576817, finearea: '1101006 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576818, finearea: '1101007 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576819, finearea: '1101008 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576820, finearea: '1101009 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576821, finearea: '1101010 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576822, finearea: '1101011 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576823, finearea: '1101012 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576824, finearea: '1101013 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576825, finearea: '1101014 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576826, finearea: '1101015 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576827, finearea: '1101016 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576828, finearea: '1101017 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan - East', region: 'Capital Region and South Coast' },
  { geography: 576829, finearea: '1101101 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576830, finearea: '1101102 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576831, finearea: '1101103 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576832, finearea: '1101104 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576833, finearea: '1101105 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576834, finearea: '1101106 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576835, finearea: '1101107 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576836, finearea: '1101108 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576837, finearea: '1101109 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576838, finearea: '1101110 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576839, finearea: '1101111 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576840, finearea: '1101112 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576841, finearea: '1101113 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576842, finearea: '1101115 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576843, finearea: '1101116 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576844, finearea: '1101117 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576845, finearea: '1101118 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576846, finearea: '1101119 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576847, finearea: '1101120 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576848, finearea: '1101122 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576849, finearea: '1101123 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576850, finearea: '1101125 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576851, finearea: '1101126 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576852, finearea: '1101127 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576853, finearea: '1101128 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576854, finearea: '1101129 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576855, finearea: '1101130 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576856, finearea: '1101131 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576857, finearea: '1101132 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576858, finearea: '1101133 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576859, finearea: '1101134 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576860, finearea: '1101135 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576861, finearea: '1101136 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576862, finearea: '1101137 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576863, finearea: '1101139 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576864, finearea: '1101140 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576865, finearea: '1101142 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576866, finearea: '1101143 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576867, finearea: '1101144 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576868, finearea: '1101145 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576869, finearea: '1101146 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576870, finearea: '1101147 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576871, finearea: '1101148 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576872, finearea: '1101149 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576873, finearea: '1101150 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576874, finearea: '1101151 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576875, finearea: '1101152 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 576876, finearea: '1101201 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576877, finearea: '1101202 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576878, finearea: '1101203 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576879, finearea: '1101204 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576880, finearea: '1101205 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576881, finearea: '1101206 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576882, finearea: '1101207 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576883, finearea: '1101208 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576884, finearea: '1101209 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576885, finearea: '1101210 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576886, finearea: '1101211 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576887, finearea: '1101212 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576888, finearea: '1101213 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576889, finearea: '1101214 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576890, finearea: '1101215 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576891, finearea: '1101216 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576892, finearea: '1101217 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576893, finearea: '1101218 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576894, finearea: '1101219 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576895, finearea: '1101220 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576896, finearea: '1101221 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576897, finearea: '1101222 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576898, finearea: '1101223 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576899, finearea: '1101224 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576900, finearea: '1101225 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576901, finearea: '1101226 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576902, finearea: '1101227 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576903, finearea: '1101228 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576904, finearea: '1101229 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576905, finearea: '1101230 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576906, finearea: '1101231 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576907, finearea: '1101232 _ Queanbeyan-Palerang Regional (A)', subregion: 'Queanbeyan West - Jerrabomberra', region: 'Capital Region and South Coast' },
  { geography: 576908, finearea: '1154013 _ Queanbeyan-Palerang Regional (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 576909, finearea: '1132401 _ Randwick (C)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 576910, finearea: '1134201 _ Randwick (C)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 576911, finearea: '1134801 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576912, finearea: '1134802 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576913, finearea: '1134803 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576914, finearea: '1134804 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576915, finearea: '1134805 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576916, finearea: '1134806 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576917, finearea: '1134807 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576918, finearea: '1134808 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576919, finearea: '1134809 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576920, finearea: '1134810 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576921, finearea: '1134811 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576922, finearea: '1134812 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576923, finearea: '1134813 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576924, finearea: '1134814 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576925, finearea: '1134815 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576926, finearea: '1134816 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 576927, finearea: '1134817 _ Randwick (C)', subregion: 'Coogee - Clovelly', region: 'Inner Sydney' },
  { geography: 570237, finearea: '1133236 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570238, finearea: '1133237 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570239, finearea: '1133238 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570240, finearea: '1133239 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570241, finearea: '1133241 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570242, finearea: '1133242 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570243, finearea: '1133244 _ Inner West (A)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 570244, finearea: '1138701 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570245, finearea: '1138702 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570246, finearea: '1138703 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570247, finearea: '1138704 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570248, finearea: '1138705 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570249, finearea: '1138706 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570250, finearea: '1138707 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570251, finearea: '1138708 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570252, finearea: '1138709 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570253, finearea: '1138710 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570254, finearea: '1138711 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570255, finearea: '1138712 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570256, finearea: '1138713 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570257, finearea: '1138714 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570258, finearea: '1138715 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570259, finearea: '1138716 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570260, finearea: '1138717 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570261, finearea: '1138718 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570262, finearea: '1138719 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570263, finearea: '1138720 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570264, finearea: '1138721 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570265, finearea: '1138722 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570266, finearea: '1138723 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570267, finearea: '1138724 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570268, finearea: '1138725 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570269, finearea: '1138726 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570270, finearea: '1138727 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570271, finearea: '1138728 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570272, finearea: '1138729 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570273, finearea: '1138730 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570274, finearea: '1138731 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570275, finearea: '1138732 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570276, finearea: '1138733 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570277, finearea: '1138734 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570278, finearea: '1138735 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570279, finearea: '1138736 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570280, finearea: '1138737 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570281, finearea: '1138738 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570282, finearea: '1138739 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570283, finearea: '1138740 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570284, finearea: '1138741 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570285, finearea: '1138742 _ Inner West (A)', subregion: 'Balmain', region: 'Inner Sydney' },
  { geography: 570286, finearea: '1138801 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570287, finearea: '1138802 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570288, finearea: '1138803 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570289, finearea: '1138804 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570290, finearea: '1138805 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570291, finearea: '1138806 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570292, finearea: '1138807 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570293, finearea: '1138808 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570294, finearea: '1138809 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570295, finearea: '1138810 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570296, finearea: '1138811 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570297, finearea: '1138812 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570298, finearea: '1138813 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570299, finearea: '1138814 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570300, finearea: '1138815 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570301, finearea: '1138816 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570302, finearea: '1138817 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570303, finearea: '1138818 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570304, finearea: '1138819 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570305, finearea: '1138820 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570306, finearea: '1138821 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570307, finearea: '1138822 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570308, finearea: '1138823 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570309, finearea: '1138824 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570310, finearea: '1138825 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570311, finearea: '1138826 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570312, finearea: '1138827 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570313, finearea: '1138828 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570314, finearea: '1138829 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570315, finearea: '1138830 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570316, finearea: '1138831 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570317, finearea: '1138832 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570318, finearea: '1138833 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570319, finearea: '1138834 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570320, finearea: '1138835 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570321, finearea: '1138836 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570322, finearea: '1138837 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570323, finearea: '1138838 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570324, finearea: '1138839 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570325, finearea: '1138840 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570326, finearea: '1138841 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570327, finearea: '1138842 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570328, finearea: '1138843 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570329, finearea: '1138844 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570330, finearea: '1138845 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570331, finearea: '1138846 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570332, finearea: '1138847 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570333, finearea: '1138848 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570334, finearea: '1138849 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570335, finearea: '1138850 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570336, finearea: '1138851 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570337, finearea: '1138852 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570338, finearea: '1138853 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570339, finearea: '1138854 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570340, finearea: '1138855 _ Inner West (A)', subregion: 'Leichhardt - Annandale', region: 'Inner Sydney' },
  { geography: 570341, finearea: '1138901 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570342, finearea: '1138902 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570343, finearea: '1138903 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570344, finearea: '1138904 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570345, finearea: '1138905 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570346, finearea: '1138906 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570347, finearea: '1138907 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570348, finearea: '1138908 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570349, finearea: '1138909 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570350, finearea: '1138910 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570351, finearea: '1138911 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570352, finearea: '1138912 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570353, finearea: '1138913 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570354, finearea: '1138914 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570355, finearea: '1138915 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570356, finearea: '1138916 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570357, finearea: '1138917 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570358, finearea: '1138918 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570359, finearea: '1138919 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570360, finearea: '1138920 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570361, finearea: '1138921 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570362, finearea: '1138922 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570363, finearea: '1138923 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570364, finearea: '1138924 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570365, finearea: '1138925 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570366, finearea: '1138926 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570367, finearea: '1138927 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570368, finearea: '1138928 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570369, finearea: '1138929 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570370, finearea: '1138930 _ Inner West (A)', subregion: 'Lilyfield - Rozelle', region: 'Inner Sydney' },
  { geography: 570371, finearea: '1139001 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570372, finearea: '1139002 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570373, finearea: '1139003 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570374, finearea: '1139004 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570375, finearea: '1139005 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570376, finearea: '1139006 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570377, finearea: '1139007 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570378, finearea: '1139008 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570379, finearea: '1139009 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570380, finearea: '1139010 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570381, finearea: '1139011 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570382, finearea: '1139012 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570383, finearea: '1139013 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570384, finearea: '1139014 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570385, finearea: '1139015 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570386, finearea: '1139016 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570387, finearea: '1139017 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570388, finearea: '1139018 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570389, finearea: '1139019 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570390, finearea: '1139020 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570391, finearea: '1139021 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570392, finearea: '1139022 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570393, finearea: '1139023 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570394, finearea: '1139024 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570395, finearea: '1139025 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570396, finearea: '1139026 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570397, finearea: '1139027 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570398, finearea: '1139028 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570399, finearea: '1139029 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570400, finearea: '1139030 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570401, finearea: '1139031 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570402, finearea: '1139032 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570403, finearea: '1139033 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570404, finearea: '1139034 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570405, finearea: '1139035 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570406, finearea: '1139036 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570407, finearea: '1139037 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570408, finearea: '1139038 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570409, finearea: '1139039 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570410, finearea: '1139040 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570411, finearea: '1139041 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570412, finearea: '1139042 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570413, finearea: '1139043 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570414, finearea: '1139044 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570415, finearea: '1139045 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570416, finearea: '1139046 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570417, finearea: '1139047 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570418, finearea: '1139048 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570419, finearea: '1139049 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570420, finearea: '1139050 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570421, finearea: '1139051 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570422, finearea: '1139052 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570423, finearea: '1139053 _ Inner West (A)', subregion: 'Ashfield', region: 'Inner Sydney' },
  { geography: 570424, finearea: '1139110 _ Inner West (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 570425, finearea: '1139133 _ Inner West (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 570426, finearea: '1139134 _ Inner West (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 570427, finearea: '1139135 _ Inner West (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 570428, finearea: '1139144 _ Inner West (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 570429, finearea: '1139145 _ Inner West (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 570430, finearea: '1139146 _ Inner West (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 570431, finearea: '1139147 _ Inner West (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 570432, finearea: '1139335 _ Inner West (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 570433, finearea: '1139336 _ Inner West (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 570434, finearea: '1139337 _ Inner West (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 570435, finearea: '1139338 _ Inner West (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 570436, finearea: '1139401 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 566237, finearea: '1103315 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566238, finearea: '1103316 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566239, finearea: '1103317 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566240, finearea: '1103318 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566241, finearea: '1103319 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566242, finearea: '1103320 _ Central Coast (C) (NSW)', subregion: 'Kariong', region: 'Central Coast and Hunter' },
  { geography: 566243, finearea: '1103401 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566244, finearea: '1103402 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566245, finearea: '1103403 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566246, finearea: '1103404 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566247, finearea: '1103405 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566248, finearea: '1103406 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566249, finearea: '1103407 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566250, finearea: '1103408 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566251, finearea: '1103409 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566252, finearea: '1103410 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566253, finearea: '1103411 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566254, finearea: '1103412 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566255, finearea: '1103413 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566256, finearea: '1103414 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566257, finearea: '1103415 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566258, finearea: '1103416 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566259, finearea: '1103417 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566260, finearea: '1103418 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566261, finearea: '1103419 _ Central Coast (C) (NSW)', subregion: 'Kincumber - Picketts Valley', region: 'Central Coast and Hunter' },
  { geography: 566262, finearea: '1103501 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566263, finearea: '1103502 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566264, finearea: '1103503 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566265, finearea: '1103504 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566266, finearea: '1103505 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566267, finearea: '1103506 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566268, finearea: '1103507 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566269, finearea: '1103508 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566270, finearea: '1103509 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566271, finearea: '1103510 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566272, finearea: '1103511 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566273, finearea: '1103512 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566274, finearea: '1103513 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566275, finearea: '1103514 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566276, finearea: '1103515 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566277, finearea: '1103516 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566278, finearea: '1103517 _ Central Coast (C) (NSW)', subregion: 'Narara', region: 'Central Coast and Hunter' },
  { geography: 566279, finearea: '1103601 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566280, finearea: '1103602 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566281, finearea: '1103603 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566282, finearea: '1103604 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566283, finearea: '1103605 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566284, finearea: '1103606 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566285, finearea: '1103607 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566286, finearea: '1103608 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566287, finearea: '1103609 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566288, finearea: '1103610 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566289, finearea: '1103611 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566290, finearea: '1103612 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566291, finearea: '1103613 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566292, finearea: '1103614 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566293, finearea: '1103615 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566294, finearea: '1103616 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566295, finearea: '1103617 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566296, finearea: '1103618 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566297, finearea: '1103619 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566298, finearea: '1103620 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566299, finearea: '1103621 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566300, finearea: '1103622 _ Central Coast (C) (NSW)', subregion: 'Niagara Park - Lisarow', region: 'Central Coast and Hunter' },
  { geography: 566301, finearea: '1103701 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566302, finearea: '1103702 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566303, finearea: '1103703 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566304, finearea: '1103704 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566305, finearea: '1103705 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566306, finearea: '1103706 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566307, finearea: '1103707 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566308, finearea: '1103708 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566309, finearea: '1103709 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566310, finearea: '1103710 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566311, finearea: '1103711 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566312, finearea: '1103712 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566313, finearea: '1103713 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566314, finearea: '1103714 _ Central Coast (C) (NSW)', subregion: 'Point Clare - Koolewong', region: 'Central Coast and Hunter' },
  { geography: 566315, finearea: '1103801 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566316, finearea: '1103802 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566317, finearea: '1103803 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566318, finearea: '1103804 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566319, finearea: '1103805 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566320, finearea: '1103806 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566321, finearea: '1103807 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566322, finearea: '1103808 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566323, finearea: '1103809 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566324, finearea: '1103810 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566325, finearea: '1103811 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566326, finearea: '1103812 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566327, finearea: '1103813 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566328, finearea: '1103814 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566329, finearea: '1103815 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566330, finearea: '1103816 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566331, finearea: '1103817 _ Central Coast (C) (NSW)', subregion: 'Saratoga - Davistown', region: 'Central Coast and Hunter' },
  { geography: 566332, finearea: '1103901 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566333, finearea: '1103902 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566334, finearea: '1103903 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566335, finearea: '1103904 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566336, finearea: '1103905 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566337, finearea: '1103906 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566338, finearea: '1103908 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566339, finearea: '1103909 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566340, finearea: '1103911 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566341, finearea: '1103912 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566342, finearea: '1103913 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566343, finearea: '1103914 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566344, finearea: '1103915 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566345, finearea: '1103916 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566346, finearea: '1103917 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566347, finearea: '1103918 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566348, finearea: '1103919 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566349, finearea: '1103920 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566350, finearea: '1103921 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566351, finearea: '1103922 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566352, finearea: '1103923 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566353, finearea: '1103924 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566354, finearea: '1103925 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566355, finearea: '1103926 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566356, finearea: '1103927 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566357, finearea: '1103928 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566358, finearea: '1103929 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566359, finearea: '1103930 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566360, finearea: '1103931 _ Central Coast (C) (NSW)', subregion: 'Terrigal - North Avoca', region: 'Central Coast and Hunter' },
  { geography: 566361, finearea: '1104001 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566362, finearea: '1104002 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566363, finearea: '1104003 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566364, finearea: '1104004 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566365, finearea: '1104005 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566366, finearea: '1104006 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566367, finearea: '1104007 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566368, finearea: '1104008 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566369, finearea: '1104009 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566370, finearea: '1104010 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566371, finearea: '1104011 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566372, finearea: '1104012 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566373, finearea: '1104013 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566374, finearea: '1104014 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566375, finearea: '1104015 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566376, finearea: '1104016 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566377, finearea: '1104017 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566378, finearea: '1104018 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566379, finearea: '1104019 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566380, finearea: '1104020 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566381, finearea: '1104021 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566382, finearea: '1104022 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566383, finearea: '1104023 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566384, finearea: '1104024 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566385, finearea: '1104025 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566386, finearea: '1104026 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566387, finearea: '1104027 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566388, finearea: '1104028 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566389, finearea: '1104029 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566390, finearea: '1104030 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566391, finearea: '1104031 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566392, finearea: '1104032 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566393, finearea: '1104033 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566394, finearea: '1104034 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566395, finearea: '1104035 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566396, finearea: '1104036 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566397, finearea: '1104037 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566398, finearea: '1104038 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566399, finearea: '1104039 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566400, finearea: '1104040 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566401, finearea: '1104041 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566402, finearea: '1104042 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566403, finearea: '1104043 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566404, finearea: '1104044 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566405, finearea: '1104045 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566406, finearea: '1104046 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566407, finearea: '1104047 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566408, finearea: '1104048 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566409, finearea: '1104049 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566410, finearea: '1104050 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566411, finearea: '1104051 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566412, finearea: '1104052 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566413, finearea: '1104053 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566414, finearea: '1104054 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566415, finearea: '1104055 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566416, finearea: '1104056 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566417, finearea: '1104057 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566418, finearea: '1104058 _ Central Coast (C) (NSW)', subregion: 'Umina - Booker Bay - Patonga', region: 'Central Coast and Hunter' },
  { geography: 566419, finearea: '1104101 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566420, finearea: '1104102 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566421, finearea: '1104103 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566422, finearea: '1104104 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566423, finearea: '1104105 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566424, finearea: '1104106 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566425, finearea: '1104107 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566426, finearea: '1104108 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566427, finearea: '1104109 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566428, finearea: '1104110 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566429, finearea: '1104111 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566430, finearea: '1104112 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566431, finearea: '1104113 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566432, finearea: '1104114 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566433, finearea: '1104115 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566434, finearea: '1104116 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566435, finearea: '1104117 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566436, finearea: '1104118 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 570837, finearea: '1140913 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570838, finearea: '1140914 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570839, finearea: '1140915 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570840, finearea: '1140916 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570841, finearea: '1140917 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570842, finearea: '1140918 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570843, finearea: '1140919 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570844, finearea: '1140921 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570845, finearea: '1140922 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570846, finearea: '1140923 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570847, finearea: '1140926 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570848, finearea: '1140927 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570849, finearea: '1140928 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570850, finearea: '1140929 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570851, finearea: '1140930 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570852, finearea: '1140931 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570853, finearea: '1140932 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570854, finearea: '1140933 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570855, finearea: '1140934 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570856, finearea: '1140935 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570857, finearea: '1140936 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570858, finearea: '1140937 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570859, finearea: '1140938 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570860, finearea: '1140939 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570861, finearea: '1140940 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570862, finearea: '1140941 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570863, finearea: '1140942 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570864, finearea: '1140943 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570865, finearea: '1140944 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570866, finearea: '1141001 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570867, finearea: '1141002 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570868, finearea: '1141003 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570869, finearea: '1141004 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570870, finearea: '1141005 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570871, finearea: '1141006 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570872, finearea: '1141007 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570873, finearea: '1141008 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570874, finearea: '1141009 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570875, finearea: '1141010 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570876, finearea: '1141012 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570877, finearea: '1141013 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570878, finearea: '1141014 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570879, finearea: '1141015 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570880, finearea: '1141016 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570881, finearea: '1141018 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570882, finearea: '1141019 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570883, finearea: '1141020 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570884, finearea: '1141021 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570885, finearea: '1141022 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570886, finearea: '1141023 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570887, finearea: '1141024 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570888, finearea: '1141025 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570889, finearea: '1141026 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570890, finearea: '1141027 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570891, finearea: '1141028 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570892, finearea: '1141029 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570893, finearea: '1141030 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570894, finearea: '1141031 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570895, finearea: '1141032 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570896, finearea: '1141033 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570897, finearea: '1141035 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570898, finearea: '1141037 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570899, finearea: '1141038 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570900, finearea: '1141039 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570901, finearea: '1141040 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570902, finearea: '1141041 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570903, finearea: '1141042 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570904, finearea: '1141044 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570905, finearea: '1141045 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570906, finearea: '1141046 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570907, finearea: '1141047 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570908, finearea: '1141048 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570909, finearea: '1141049 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570910, finearea: '1141050 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570911, finearea: '1141051 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570912, finearea: '1141052 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570913, finearea: '1141053 _ Ku-ring-gai (A)', subregion: 'St Ives', region: 'Northern Sydney' },
  { geography: 570914, finearea: '1141101 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570915, finearea: '1141102 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570916, finearea: '1141103 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570917, finearea: '1141104 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570918, finearea: '1141105 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570919, finearea: '1141106 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570920, finearea: '1141107 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570921, finearea: '1141108 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570922, finearea: '1141109 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570923, finearea: '1141110 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570924, finearea: '1141111 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570925, finearea: '1141112 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570926, finearea: '1141113 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570927, finearea: '1141114 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570928, finearea: '1141115 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570929, finearea: '1141116 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570930, finearea: '1141117 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570931, finearea: '1141118 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570932, finearea: '1141119 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570933, finearea: '1141120 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570934, finearea: '1141121 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570935, finearea: '1141122 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570936, finearea: '1141123 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570937, finearea: '1141124 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570938, finearea: '1141125 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570939, finearea: '1141126 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570940, finearea: '1141127 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570941, finearea: '1141128 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570942, finearea: '1141129 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570943, finearea: '1141130 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570944, finearea: '1141131 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570945, finearea: '1141132 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570946, finearea: '1141133 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570947, finearea: '1141134 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570948, finearea: '1141135 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570949, finearea: '1141136 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570950, finearea: '1141137 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570951, finearea: '1141138 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570952, finearea: '1141139 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570953, finearea: '1141140 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570954, finearea: '1141141 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570955, finearea: '1141142 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570956, finearea: '1141143 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570957, finearea: '1141144 _ Ku-ring-gai (A)', subregion: 'Turramurra', region: 'Northern Sydney' },
  { geography: 570958, finearea: '1141201 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570959, finearea: '1141202 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570960, finearea: '1141203 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570961, finearea: '1141204 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570962, finearea: '1141205 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570963, finearea: '1141206 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570964, finearea: '1141207 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570965, finearea: '1141208 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570966, finearea: '1141209 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570967, finearea: '1141210 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570968, finearea: '1141211 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570969, finearea: '1141212 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570970, finearea: '1141214 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570971, finearea: '1141215 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570972, finearea: '1141217 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570973, finearea: '1141218 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570974, finearea: '1141219 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570975, finearea: '1141220 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570976, finearea: '1141221 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570977, finearea: '1141222 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570978, finearea: '1141223 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570979, finearea: '1141224 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570980, finearea: '1141225 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570981, finearea: '1141226 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570982, finearea: '1141227 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570983, finearea: '1141228 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570984, finearea: '1141229 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570985, finearea: '1141230 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570986, finearea: '1141231 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570987, finearea: '1141232 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570988, finearea: '1141233 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570989, finearea: '1141234 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570990, finearea: '1141235 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570991, finearea: '1141236 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570992, finearea: '1141237 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570993, finearea: '1141238 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570994, finearea: '1141239 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570995, finearea: '1141240 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570996, finearea: '1141241 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570997, finearea: '1141242 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570998, finearea: '1141243 _ Ku-ring-gai (A)', subregion: 'Wahroonga (East) - Warrawee', region: 'Northern Sydney' },
  { geography: 570999, finearea: '1124501 _ Kyogle (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 571000, finearea: '1124507 _ Kyogle (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 571001, finearea: '1124508 _ Kyogle (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 571002, finearea: '1124509 _ Kyogle (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 571003, finearea: '1124510 _ Kyogle (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 571004, finearea: '1124511 _ Kyogle (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 571005, finearea: '1124525 _ Kyogle (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 571006, finearea: '1124528 _ Kyogle (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 571007, finearea: '1124701 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571008, finearea: '1124702 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571009, finearea: '1124703 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571010, finearea: '1124704 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571011, finearea: '1124705 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571012, finearea: '1124706 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571013, finearea: '1124707 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571014, finearea: '1124708 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571015, finearea: '1124709 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571016, finearea: '1124710 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571017, finearea: '1124711 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571018, finearea: '1124712 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571019, finearea: '1124713 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571020, finearea: '1124714 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571021, finearea: '1124715 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571022, finearea: '1124716 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571023, finearea: '1124717 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571024, finearea: '1124718 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571025, finearea: '1124719 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571026, finearea: '1124720 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571027, finearea: '1124721 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571028, finearea: '1124722 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571029, finearea: '1124723 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571030, finearea: '1124724 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571031, finearea: '1124725 _ Kyogle (A)', subregion: 'Kyogle', region: 'North East NSW' },
  { geography: 571032, finearea: '1124931 _ Kyogle (A)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571033, finearea: '1124937 _ Kyogle (A)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571034, finearea: '1106201 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571035, finearea: '1106202 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571036, finearea: '1106203 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 580928, finearea: '1134419 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580929, finearea: '1134420 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580930, finearea: '1134421 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580931, finearea: '1134422 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580932, finearea: '1134423 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580933, finearea: '1134424 _ Waverley (A)', subregion: 'Dover Heights', region: 'Inner Sydney' },
  { geography: 580934, finearea: '1106519 _ Weddin (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 580935, finearea: '1106601 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580936, finearea: '1106602 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580937, finearea: '1106603 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580938, finearea: '1106604 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580939, finearea: '1106605 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580940, finearea: '1106606 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580941, finearea: '1106607 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580942, finearea: '1106608 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580943, finearea: '1106609 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580944, finearea: '1106610 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580945, finearea: '1106611 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580946, finearea: '1106612 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580947, finearea: '1106613 _ Weddin (A)', subregion: 'Grenfell', region: 'Regional NSW - Remainder' },
  { geography: 580948, finearea: '1117801 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580949, finearea: '1117802 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580950, finearea: '1117803 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580951, finearea: '1117804 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580952, finearea: '1117805 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580953, finearea: '1117806 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580954, finearea: '1117807 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580955, finearea: '1117808 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580956, finearea: '1117809 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580957, finearea: '1117810 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580958, finearea: '1117811 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580959, finearea: '1117812 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580960, finearea: '1117813 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580961, finearea: '1117814 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580962, finearea: '1117815 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580963, finearea: '1117816 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580964, finearea: '1117817 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580965, finearea: '1117818 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580966, finearea: '1117819 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580967, finearea: '1117820 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580968, finearea: '1117821 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580969, finearea: '1117822 _ Wentworth (A)', subregion: 'Wentworth - Buronga', region: 'Regional NSW - Remainder' },
  { geography: 580970, finearea: '1117902 _ Wentworth (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 580971, finearea: '1117904 _ Wentworth (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 580972, finearea: '1117906 _ Wentworth (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 580973, finearea: '1139801 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580974, finearea: '1139802 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580975, finearea: '1139803 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580976, finearea: '1139804 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580977, finearea: '1139805 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580978, finearea: '1139806 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580979, finearea: '1139807 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580980, finearea: '1139808 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580981, finearea: '1139809 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580982, finearea: '1139810 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580983, finearea: '1139811 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580984, finearea: '1139812 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580985, finearea: '1139813 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580986, finearea: '1139814 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580987, finearea: '1139815 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580988, finearea: '1139816 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580989, finearea: '1139817 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580990, finearea: '1139818 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580991, finearea: '1139819 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580992, finearea: '1139820 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580993, finearea: '1139821 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580994, finearea: '1139822 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580995, finearea: '1139823 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580996, finearea: '1139824 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580997, finearea: '1139825 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580998, finearea: '1139826 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 580999, finearea: '1139827 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581000, finearea: '1139828 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581001, finearea: '1139829 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581002, finearea: '1139830 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581003, finearea: '1139831 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581004, finearea: '1139832 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581005, finearea: '1139833 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581006, finearea: '1139834 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581007, finearea: '1139835 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581008, finearea: '1139836 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581009, finearea: '1139837 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581010, finearea: '1139838 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581011, finearea: '1139839 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581012, finearea: '1139841 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581013, finearea: '1139842 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581014, finearea: '1139843 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581015, finearea: '1139845 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581016, finearea: '1139846 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581017, finearea: '1139847 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581018, finearea: '1139848 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581019, finearea: '1139849 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581020, finearea: '1139850 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581021, finearea: '1139851 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581022, finearea: '1139852 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581023, finearea: '1139853 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581024, finearea: '1139855 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581025, finearea: '1139856 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581026, finearea: '1139857 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581027, finearea: '1139858 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581028, finearea: '1139859 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581029, finearea: '1139860 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581030, finearea: '1139861 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581031, finearea: '1139862 _ Willoughby (C)', subregion: 'Chatswood (East) - Artarmon', region: 'Northern Sydney' },
  { geography: 581032, finearea: '1139906 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581033, finearea: '1139907 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581034, finearea: '1139908 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581035, finearea: '1139909 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581036, finearea: '1139913 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581037, finearea: '1139914 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581038, finearea: '1139916 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581039, finearea: '1139917 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581040, finearea: '1139918 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581041, finearea: '1139919 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581042, finearea: '1139920 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581043, finearea: '1139921 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581044, finearea: '1139922 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581045, finearea: '1139923 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581046, finearea: '1139924 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581047, finearea: '1139925 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581048, finearea: '1139926 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581049, finearea: '1139927 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581050, finearea: '1139928 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581051, finearea: '1139929 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581052, finearea: '1139930 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581053, finearea: '1139931 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581054, finearea: '1139936 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581055, finearea: '1139939 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581056, finearea: '1139940 _ Willoughby (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 581057, finearea: '1140101 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581058, finearea: '1140102 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581059, finearea: '1140103 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581060, finearea: '1140104 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581061, finearea: '1140105 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581062, finearea: '1140106 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581063, finearea: '1140107 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581064, finearea: '1140108 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581065, finearea: '1140109 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581066, finearea: '1140111 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581067, finearea: '1140112 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581068, finearea: '1140113 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581069, finearea: '1140114 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581070, finearea: '1140115 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581071, finearea: '1140116 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581072, finearea: '1140117 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581073, finearea: '1140118 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581074, finearea: '1140120 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581075, finearea: '1140122 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581076, finearea: '1140123 _ Willoughby (C)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 581077, finearea: '1140201 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581078, finearea: '1140202 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581079, finearea: '1140204 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581080, finearea: '1140205 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581081, finearea: '1140208 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581082, finearea: '1140209 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581083, finearea: '1140210 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581084, finearea: '1140212 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581085, finearea: '1140213 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581086, finearea: '1140214 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581087, finearea: '1140215 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581088, finearea: '1140216 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581089, finearea: '1140217 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581090, finearea: '1140218 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581091, finearea: '1140219 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581092, finearea: '1140220 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581093, finearea: '1140221 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581094, finearea: '1140222 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581095, finearea: '1140223 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581096, finearea: '1140224 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581097, finearea: '1140225 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581098, finearea: '1140226 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581099, finearea: '1140227 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581100, finearea: '1140228 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581101, finearea: '1140229 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581102, finearea: '1140230 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581103, finearea: '1140231 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581104, finearea: '1140232 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581105, finearea: '1140233 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581106, finearea: '1140234 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581107, finearea: '1140235 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581108, finearea: '1140236 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581109, finearea: '1140237 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581110, finearea: '1140238 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581111, finearea: '1140239 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581112, finearea: '1140240 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581113, finearea: '1140242 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581114, finearea: '1140244 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581115, finearea: '1140245 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581116, finearea: '1140246 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581117, finearea: '1140247 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581118, finearea: '1140248 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581119, finearea: '1140249 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581120, finearea: '1140250 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581121, finearea: '1140251 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581122, finearea: '1140252 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581123, finearea: '1140253 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581124, finearea: '1140254 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581125, finearea: '1140255 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581126, finearea: '1140256 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581127, finearea: '1140257 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 566437, finearea: '1104119 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566438, finearea: '1104120 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566439, finearea: '1104121 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566440, finearea: '1104122 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566441, finearea: '1104123 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566442, finearea: '1104124 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566443, finearea: '1104125 _ Central Coast (C) (NSW)', subregion: 'Wamberal - Forresters Beach', region: 'Central Coast and Hunter' },
  { geography: 566444, finearea: '1104201 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566445, finearea: '1104202 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566446, finearea: '1104203 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566447, finearea: '1104204 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566448, finearea: '1104205 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566449, finearea: '1104206 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566450, finearea: '1104207 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566451, finearea: '1104208 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566452, finearea: '1104209 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566453, finearea: '1104210 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566454, finearea: '1104211 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566455, finearea: '1104212 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566456, finearea: '1104213 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566457, finearea: '1104214 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566458, finearea: '1104215 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566459, finearea: '1104216 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566460, finearea: '1104217 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566461, finearea: '1104218 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566462, finearea: '1104219 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566463, finearea: '1104220 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566464, finearea: '1104221 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566465, finearea: '1104222 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566466, finearea: '1104223 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566467, finearea: '1104224 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566468, finearea: '1104225 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566469, finearea: '1104226 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566470, finearea: '1104227 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566471, finearea: '1104228 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566472, finearea: '1104229 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566473, finearea: '1104230 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566474, finearea: '1104231 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566475, finearea: '1104232 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566476, finearea: '1104233 _ Central Coast (C) (NSW)', subregion: 'Woy Woy - Blackwall', region: 'Central Coast and Hunter' },
  { geography: 566477, finearea: '1104301 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566478, finearea: '1104302 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566479, finearea: '1104303 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566480, finearea: '1104304 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566481, finearea: '1104305 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566482, finearea: '1104306 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566483, finearea: '1104307 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566484, finearea: '1104308 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566485, finearea: '1104309 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566486, finearea: '1104310 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566487, finearea: '1104311 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566488, finearea: '1104312 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566489, finearea: '1104313 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566490, finearea: '1104314 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566491, finearea: '1104315 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566492, finearea: '1104316 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566493, finearea: '1104317 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566494, finearea: '1104318 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566495, finearea: '1104319 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566496, finearea: '1104320 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566497, finearea: '1104321 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566498, finearea: '1104322 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566499, finearea: '1104323 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566500, finearea: '1104324 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566501, finearea: '1104325 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566502, finearea: '1104326 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566503, finearea: '1104327 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566504, finearea: '1104328 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566505, finearea: '1104329 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566506, finearea: '1104330 _ Central Coast (C) (NSW)', subregion: 'Wyoming', region: 'Central Coast and Hunter' },
  { geography: 566507, finearea: '1104401 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566508, finearea: '1104402 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566509, finearea: '1104403 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566510, finearea: '1104404 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566511, finearea: '1104405 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566512, finearea: '1104406 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566513, finearea: '1104407 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566514, finearea: '1104408 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566515, finearea: '1104409 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566516, finearea: '1104410 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566517, finearea: '1104411 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566518, finearea: '1104412 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566519, finearea: '1104413 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566520, finearea: '1104414 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566521, finearea: '1104415 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566522, finearea: '1104416 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566523, finearea: '1104417 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566524, finearea: '1104418 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566525, finearea: '1104419 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566526, finearea: '1104420 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566527, finearea: '1104421 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566528, finearea: '1104422 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566529, finearea: '1104423 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566530, finearea: '1104424 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566531, finearea: '1104425 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566532, finearea: '1104426 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566533, finearea: '1104427 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566534, finearea: '1104428 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566535, finearea: '1104429 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566536, finearea: '1104430 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566537, finearea: '1104431 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566538, finearea: '1104432 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566539, finearea: '1104433 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566540, finearea: '1104434 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566541, finearea: '1104435 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566542, finearea: '1104436 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566543, finearea: '1104437 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566544, finearea: '1104438 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566545, finearea: '1104439 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566546, finearea: '1104440 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566547, finearea: '1104441 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566548, finearea: '1104442 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566549, finearea: '1104443 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566550, finearea: '1104444 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566551, finearea: '1104445 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566552, finearea: '1104446 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566553, finearea: '1104447 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566554, finearea: '1104448 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566555, finearea: '1104449 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566556, finearea: '1104450 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566557, finearea: '1104451 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566558, finearea: '1104452 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566559, finearea: '1104453 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566560, finearea: '1104454 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566561, finearea: '1104455 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566562, finearea: '1104456 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566563, finearea: '1104457 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566564, finearea: '1104458 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566565, finearea: '1104459 _ Central Coast (C) (NSW)', subregion: 'Bateau Bay - Killarney Vale', region: 'Central Coast and Hunter' },
  { geography: 566566, finearea: '1104501 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566567, finearea: '1104502 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566568, finearea: '1104503 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566569, finearea: '1104504 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566570, finearea: '1104505 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566571, finearea: '1104506 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566572, finearea: '1104507 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566573, finearea: '1104508 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566574, finearea: '1104509 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566575, finearea: '1104510 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566576, finearea: '1104511 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566577, finearea: '1104512 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566578, finearea: '1104513 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566579, finearea: '1104514 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566580, finearea: '1104515 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566581, finearea: '1104516 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566582, finearea: '1104517 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566583, finearea: '1104518 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566584, finearea: '1104519 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566585, finearea: '1104520 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566586, finearea: '1104521 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566587, finearea: '1104522 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566588, finearea: '1104523 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566589, finearea: '1104524 _ Central Coast (C) (NSW)', subregion: 'Blue Haven - San Remo', region: 'Central Coast and Hunter' },
  { geography: 566590, finearea: '1104601 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566591, finearea: '1104602 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566592, finearea: '1104603 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566593, finearea: '1104604 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566594, finearea: '1104605 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566595, finearea: '1104606 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566596, finearea: '1104607 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566597, finearea: '1104608 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566598, finearea: '1104609 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566599, finearea: '1104610 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566600, finearea: '1104611 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566601, finearea: '1104612 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566602, finearea: '1104613 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566603, finearea: '1104614 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566604, finearea: '1104615 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566605, finearea: '1104616 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566606, finearea: '1104617 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566607, finearea: '1104618 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566608, finearea: '1104619 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566609, finearea: '1104620 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566610, finearea: '1104621 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566611, finearea: '1104622 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566612, finearea: '1104623 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566613, finearea: '1104624 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566614, finearea: '1104625 _ Central Coast (C) (NSW)', subregion: 'Budgewoi - Buff Point - Halekulani', region: 'Central Coast and Hunter' },
  { geography: 566615, finearea: '1104701 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566616, finearea: '1104702 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566617, finearea: '1104703 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566618, finearea: '1104704 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566619, finearea: '1104705 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566620, finearea: '1104706 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566621, finearea: '1104707 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566622, finearea: '1104708 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566623, finearea: '1104709 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566624, finearea: '1104710 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566625, finearea: '1104711 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566626, finearea: '1104712 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566627, finearea: '1104713 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566628, finearea: '1104714 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566629, finearea: '1104715 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566630, finearea: '1104716 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566631, finearea: '1104717 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566632, finearea: '1104718 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566633, finearea: '1104719 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566634, finearea: '1104720 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566635, finearea: '1104721 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566636, finearea: '1104722 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 564437, finearea: '1124301 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564438, finearea: '1124302 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564439, finearea: '1124303 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564440, finearea: '1124304 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564441, finearea: '1124305 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564442, finearea: '1124306 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564443, finearea: '1124307 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564444, finearea: '1124308 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564445, finearea: '1124309 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564446, finearea: '1124310 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564447, finearea: '1124311 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564448, finearea: '1124312 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564449, finearea: '1124313 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564450, finearea: '1124314 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564451, finearea: '1124315 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564452, finearea: '1124316 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564453, finearea: '1124317 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564454, finearea: '1124318 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564455, finearea: '1124319 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564456, finearea: '1124320 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564457, finearea: '1124321 _ Byron (A)', subregion: 'Mullumbimby', region: 'North East NSW' },
  { geography: 564458, finearea: '1124923 _ Byron (A)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 564459, finearea: '1106401 _ Cabonne (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 564460, finearea: '1106402 _ Cabonne (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 564461, finearea: '1106403 _ Cabonne (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 564462, finearea: '1106404 _ Cabonne (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 564463, finearea: '1106406 _ Cabonne (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 564464, finearea: '1106407 _ Cabonne (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 564465, finearea: '1106408 _ Cabonne (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 564466, finearea: '1106418 _ Cabonne (A)', subregion: 'Cowra Region', region: 'Regional NSW - Remainder' },
  { geography: 564467, finearea: '1106515 _ Cabonne (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 564468, finearea: '1106516 _ Cabonne (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 564469, finearea: '1106801 _ Cabonne (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 564470, finearea: '1107809 _ Cabonne (A)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 564471, finearea: '1107901 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564472, finearea: '1107902 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564473, finearea: '1107903 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564474, finearea: '1107904 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564475, finearea: '1107905 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564476, finearea: '1107906 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564477, finearea: '1107907 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564478, finearea: '1107908 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564479, finearea: '1107909 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564480, finearea: '1107910 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564481, finearea: '1107911 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564482, finearea: '1107913 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564483, finearea: '1107917 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564484, finearea: '1107919 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564485, finearea: '1107920 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564486, finearea: '1107921 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564487, finearea: '1107923 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564488, finearea: '1107924 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564489, finearea: '1107925 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564490, finearea: '1107926 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564491, finearea: '1107927 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564492, finearea: '1107928 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564493, finearea: '1107929 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564494, finearea: '1107930 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564495, finearea: '1107931 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564496, finearea: '1107932 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564497, finearea: '1107933 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564498, finearea: '1107934 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564499, finearea: '1107935 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564500, finearea: '1107936 _ Cabonne (A)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 564501, finearea: '1110303 _ Cabonne (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 564502, finearea: '1110611 _ Cabonne (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 564503, finearea: '1110612 _ Cabonne (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 564504, finearea: '1110613 _ Cabonne (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 564505, finearea: '1143301 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564506, finearea: '1143302 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564507, finearea: '1143303 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564508, finearea: '1143304 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564509, finearea: '1143307 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564510, finearea: '1143308 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564511, finearea: '1143309 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564512, finearea: '1143310 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564513, finearea: '1143311 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564514, finearea: '1143312 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564515, finearea: '1143313 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564516, finearea: '1143314 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564517, finearea: '1143315 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564518, finearea: '1143317 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564519, finearea: '1143318 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564520, finearea: '1143319 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564521, finearea: '1143320 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564522, finearea: '1143321 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564523, finearea: '1143322 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564524, finearea: '1143323 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564525, finearea: '1143324 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564526, finearea: '1143325 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564527, finearea: '1143326 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564528, finearea: '1143327 _ Camden (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 564529, finearea: '1143401 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564530, finearea: '1143402 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564531, finearea: '1143403 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564532, finearea: '1143404 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564533, finearea: '1143405 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564534, finearea: '1143406 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564535, finearea: '1143407 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564536, finearea: '1143408 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564537, finearea: '1143409 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564538, finearea: '1143410 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564539, finearea: '1143411 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564540, finearea: '1143412 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564541, finearea: '1143413 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564542, finearea: '1143414 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564543, finearea: '1143415 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564544, finearea: '1143416 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564545, finearea: '1143417 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564546, finearea: '1143418 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564547, finearea: '1143419 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564548, finearea: '1143420 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564549, finearea: '1143421 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564550, finearea: '1143422 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564551, finearea: '1143423 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564552, finearea: '1143424 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564553, finearea: '1143425 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564554, finearea: '1143426 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564555, finearea: '1143428 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564556, finearea: '1143429 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564557, finearea: '1143430 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564558, finearea: '1143431 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564559, finearea: '1143432 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564560, finearea: '1143433 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564561, finearea: '1143434 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564562, finearea: '1143435 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564563, finearea: '1143436 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564564, finearea: '1143437 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564565, finearea: '1143438 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564566, finearea: '1143440 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564567, finearea: '1143441 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564568, finearea: '1143442 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564569, finearea: '1143443 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564570, finearea: '1143444 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564571, finearea: '1143445 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564572, finearea: '1143446 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564573, finearea: '1143447 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564574, finearea: '1143448 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564575, finearea: '1143449 _ Camden (A)', subregion: 'Elderslie - Harrington Park', region: 'South and West Sydney' },
  { geography: 564576, finearea: '1143501 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564577, finearea: '1143502 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564578, finearea: '1143503 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564579, finearea: '1143504 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564580, finearea: '1143505 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564581, finearea: '1143506 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564582, finearea: '1143507 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564583, finearea: '1143508 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564584, finearea: '1143510 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564585, finearea: '1143511 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564586, finearea: '1143512 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564587, finearea: '1143513 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564588, finearea: '1143515 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564589, finearea: '1143516 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564590, finearea: '1143517 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564591, finearea: '1143518 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564592, finearea: '1143519 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564593, finearea: '1143520 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564594, finearea: '1143521 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564595, finearea: '1143522 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564596, finearea: '1143523 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564597, finearea: '1143524 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564598, finearea: '1143525 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564599, finearea: '1143527 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564600, finearea: '1143528 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564601, finearea: '1143529 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564602, finearea: '1143530 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564603, finearea: '1143531 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564604, finearea: '1143532 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564605, finearea: '1143533 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564606, finearea: '1143534 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564607, finearea: '1143535 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564608, finearea: '1143536 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564609, finearea: '1143537 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564610, finearea: '1143538 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564611, finearea: '1143539 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564612, finearea: '1143540 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564613, finearea: '1143541 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564614, finearea: '1143542 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564615, finearea: '1143544 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564616, finearea: '1143545 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564617, finearea: '1143546 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564618, finearea: '1143547 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564619, finearea: '1143548 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564620, finearea: '1143549 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564621, finearea: '1143550 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564622, finearea: '1143551 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564623, finearea: '1143552 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564624, finearea: '1143553 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564625, finearea: '1143555 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564626, finearea: '1143557 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564627, finearea: '1143558 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564628, finearea: '1143559 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564629, finearea: '1143560 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564630, finearea: '1143561 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564631, finearea: '1143562 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564632, finearea: '1143563 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564633, finearea: '1143564 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564634, finearea: '1143565 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564635, finearea: '1143566 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 564636, finearea: '1143567 _ Camden (A)', subregion: 'Mount Annan - Currans Hill', region: 'South and West Sydney' },
  { geography: 566637, finearea: '1104723 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566638, finearea: '1104724 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566639, finearea: '1104725 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566640, finearea: '1104726 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566641, finearea: '1104727 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566642, finearea: '1104728 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566643, finearea: '1104729 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566644, finearea: '1104730 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566645, finearea: '1104731 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566646, finearea: '1104732 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566647, finearea: '1104733 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566648, finearea: '1104734 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566649, finearea: '1104735 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566650, finearea: '1104736 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566651, finearea: '1104737 _ Central Coast (C) (NSW)', subregion: 'Chittaway Bay - Tumbi Umbi', region: 'Central Coast and Hunter' },
  { geography: 566652, finearea: '1104801 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566653, finearea: '1104802 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566654, finearea: '1104803 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566655, finearea: '1104804 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566656, finearea: '1104805 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566657, finearea: '1104806 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566658, finearea: '1104807 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566659, finearea: '1104808 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566660, finearea: '1104809 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566661, finearea: '1104810 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566662, finearea: '1104811 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566663, finearea: '1104812 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566664, finearea: '1104813 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566665, finearea: '1104814 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566666, finearea: '1104815 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566667, finearea: '1104816 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566668, finearea: '1104817 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566669, finearea: '1104818 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566670, finearea: '1104819 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566671, finearea: '1104820 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566672, finearea: '1104821 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566673, finearea: '1104822 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566674, finearea: '1104823 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566675, finearea: '1104824 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566676, finearea: '1104825 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566677, finearea: '1104826 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566678, finearea: '1104827 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566679, finearea: '1104828 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566680, finearea: '1104829 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566681, finearea: '1104830 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566682, finearea: '1104831 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566683, finearea: '1104832 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566684, finearea: '1104833 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566685, finearea: '1104834 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566686, finearea: '1104835 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566687, finearea: '1104836 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566688, finearea: '1104837 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566689, finearea: '1104838 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566690, finearea: '1104840 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566691, finearea: '1104841 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566692, finearea: '1104842 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566693, finearea: '1104843 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566694, finearea: '1104844 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566695, finearea: '1104845 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566696, finearea: '1104846 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566697, finearea: '1104847 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566698, finearea: '1104848 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566699, finearea: '1104849 _ Central Coast (C) (NSW)', subregion: 'Gorokan - Kanwal - Charmhaven', region: 'Central Coast and Hunter' },
  { geography: 566700, finearea: '1104901 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566701, finearea: '1104902 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566702, finearea: '1104903 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566703, finearea: '1104904 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566704, finearea: '1104905 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566705, finearea: '1104906 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566706, finearea: '1104907 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566707, finearea: '1104908 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566708, finearea: '1104909 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566709, finearea: '1104910 _ Central Coast (C) (NSW)', subregion: 'Jilliby - Yarramalong', region: 'Central Coast and Hunter' },
  { geography: 566710, finearea: '1105001 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566711, finearea: '1105002 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566712, finearea: '1105003 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566713, finearea: '1105004 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566714, finearea: '1105005 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566715, finearea: '1105006 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566716, finearea: '1105007 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566717, finearea: '1105008 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566718, finearea: '1105009 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566719, finearea: '1105010 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566720, finearea: '1105011 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566721, finearea: '1105012 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566722, finearea: '1105013 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566723, finearea: '1105014 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566724, finearea: '1105015 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566725, finearea: '1105016 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566726, finearea: '1105017 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566727, finearea: '1105018 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566728, finearea: '1105019 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566729, finearea: '1105020 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566730, finearea: '1105021 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566731, finearea: '1105022 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566732, finearea: '1105024 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566733, finearea: '1105025 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566734, finearea: '1105026 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566735, finearea: '1105027 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566736, finearea: '1105028 _ Central Coast (C) (NSW)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 566737, finearea: '1105101 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566738, finearea: '1105102 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566739, finearea: '1105103 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566740, finearea: '1105104 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566741, finearea: '1105105 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566742, finearea: '1105106 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566743, finearea: '1105107 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566744, finearea: '1105108 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566745, finearea: '1105109 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566746, finearea: '1105110 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566747, finearea: '1105111 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566748, finearea: '1105112 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566749, finearea: '1105113 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566750, finearea: '1105114 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566751, finearea: '1105115 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566752, finearea: '1105116 _ Central Coast (C) (NSW)', subregion: 'Ourimbah - Fountaindale', region: 'Central Coast and Hunter' },
  { geography: 566753, finearea: '1105201 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566754, finearea: '1105202 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566755, finearea: '1105203 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566756, finearea: '1105204 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566757, finearea: '1105205 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566758, finearea: '1105206 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566759, finearea: '1105207 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566760, finearea: '1105208 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566761, finearea: '1105209 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566762, finearea: '1105210 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566763, finearea: '1105211 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566764, finearea: '1105213 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566765, finearea: '1105214 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566766, finearea: '1105215 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566767, finearea: '1105216 _ Central Coast (C) (NSW)', subregion: 'Summerland Point - Gwandalan', region: 'Central Coast and Hunter' },
  { geography: 566768, finearea: '1105301 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566769, finearea: '1105302 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566770, finearea: '1105303 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566771, finearea: '1105304 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566772, finearea: '1105305 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566773, finearea: '1105306 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566774, finearea: '1105307 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566775, finearea: '1105308 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566776, finearea: '1105309 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566777, finearea: '1105310 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566778, finearea: '1105311 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566779, finearea: '1105312 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566780, finearea: '1105313 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566781, finearea: '1105314 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566782, finearea: '1105315 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566783, finearea: '1105316 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566784, finearea: '1105317 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566785, finearea: '1105318 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566786, finearea: '1105319 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566787, finearea: '1105320 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566788, finearea: '1105321 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566789, finearea: '1105322 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566790, finearea: '1105323 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566791, finearea: '1105324 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566792, finearea: '1105325 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566793, finearea: '1105326 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566794, finearea: '1105327 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566795, finearea: '1105328 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566796, finearea: '1105329 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566797, finearea: '1105330 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566798, finearea: '1105331 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566799, finearea: '1105332 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566800, finearea: '1105333 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566801, finearea: '1105334 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566802, finearea: '1105335 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566803, finearea: '1105336 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566804, finearea: '1105337 _ Central Coast (C) (NSW)', subregion: 'The Entrance', region: 'Central Coast and Hunter' },
  { geography: 566805, finearea: '1105401 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566806, finearea: '1105402 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566807, finearea: '1105403 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566808, finearea: '1105404 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566809, finearea: '1105405 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566810, finearea: '1105406 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566811, finearea: '1105407 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566812, finearea: '1105408 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566813, finearea: '1105409 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566814, finearea: '1105410 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566815, finearea: '1105411 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566816, finearea: '1105412 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566817, finearea: '1105413 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566818, finearea: '1105414 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566819, finearea: '1105415 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566820, finearea: '1105416 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566821, finearea: '1105417 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566822, finearea: '1105418 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566823, finearea: '1105419 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566824, finearea: '1105420 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566825, finearea: '1105421 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566826, finearea: '1105422 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566827, finearea: '1105423 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566828, finearea: '1105424 _ Central Coast (C) (NSW)', subregion: 'Toukley - Norah Head', region: 'Central Coast and Hunter' },
  { geography: 566829, finearea: '1105501 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566830, finearea: '1105502 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566831, finearea: '1105503 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566832, finearea: '1105504 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566833, finearea: '1105505 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566834, finearea: '1105506 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566835, finearea: '1105507 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566836, finearea: '1105508 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 563837, finearea: '1131626 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563838, finearea: '1131627 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563839, finearea: '1131628 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563840, finearea: '1131629 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563841, finearea: '1131630 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563842, finearea: '1131631 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563843, finearea: '1131632 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563844, finearea: '1131633 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563845, finearea: '1131634 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563846, finearea: '1131635 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563847, finearea: '1131636 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563848, finearea: '1131637 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563849, finearea: '1131638 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563850, finearea: '1131639 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563851, finearea: '1131640 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563852, finearea: '1131641 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563853, finearea: '1131642 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563854, finearea: '1131643 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563855, finearea: '1131644 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563856, finearea: '1131645 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563857, finearea: '1131646 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563858, finearea: '1131647 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563859, finearea: '1131648 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563860, finearea: '1131649 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563861, finearea: '1131650 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563862, finearea: '1131651 _ Blacktown (C)', subregion: 'Lethbridge Park - Tregear', region: 'Northern Sydney' },
  { geography: 563863, finearea: '1131701 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563864, finearea: '1131702 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563865, finearea: '1131703 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563866, finearea: '1131705 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563867, finearea: '1131706 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563868, finearea: '1131707 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563869, finearea: '1131708 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563870, finearea: '1131709 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563871, finearea: '1131710 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563872, finearea: '1131711 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563873, finearea: '1131712 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563874, finearea: '1131713 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563875, finearea: '1131714 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563876, finearea: '1131715 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563877, finearea: '1131716 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563878, finearea: '1131718 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563879, finearea: '1131719 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563880, finearea: '1131720 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563881, finearea: '1131721 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563882, finearea: '1131723 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563883, finearea: '1131724 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563884, finearea: '1131725 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563885, finearea: '1131726 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563886, finearea: '1131727 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563887, finearea: '1131728 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563888, finearea: '1131729 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563889, finearea: '1131730 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563890, finearea: '1131731 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563891, finearea: '1131732 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563892, finearea: '1131733 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563893, finearea: '1131734 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563894, finearea: '1131736 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563895, finearea: '1131737 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563896, finearea: '1131738 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563897, finearea: '1131739 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563898, finearea: '1131742 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563899, finearea: '1131744 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563900, finearea: '1131746 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563901, finearea: '1131747 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563902, finearea: '1131748 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563903, finearea: '1131749 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563904, finearea: '1131750 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563905, finearea: '1131751 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563906, finearea: '1131752 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563907, finearea: '1131753 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563908, finearea: '1131754 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563909, finearea: '1131755 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563910, finearea: '1131756 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563911, finearea: '1131757 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563912, finearea: '1131758 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563913, finearea: '1131759 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563914, finearea: '1131760 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563915, finearea: '1131761 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563916, finearea: '1131762 _ Blacktown (C)', subregion: 'Mount Druitt - Whalan', region: 'Northern Sydney' },
  { geography: 563917, finearea: '1131801 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563918, finearea: '1131802 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563919, finearea: '1131803 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563920, finearea: '1131901 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563921, finearea: '1131902 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563922, finearea: '1131903 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563923, finearea: '1131904 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563924, finearea: '1131905 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563925, finearea: '1131906 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563926, finearea: '1131907 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563927, finearea: '1131908 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563928, finearea: '1131909 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563929, finearea: '1131910 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563930, finearea: '1131911 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563931, finearea: '1131912 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563932, finearea: '1131913 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563933, finearea: '1131914 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563934, finearea: '1131915 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563935, finearea: '1131916 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563936, finearea: '1131917 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563937, finearea: '1131918 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563938, finearea: '1131919 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563939, finearea: '1131920 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563940, finearea: '1131921 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563941, finearea: '1131922 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563942, finearea: '1131923 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563943, finearea: '1131924 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563944, finearea: '1131925 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563945, finearea: '1131926 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563946, finearea: '1131927 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563947, finearea: '1131928 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563948, finearea: '1131929 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563949, finearea: '1131930 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563950, finearea: '1131931 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563951, finearea: '1131932 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563952, finearea: '1131933 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563953, finearea: '1131934 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563954, finearea: '1131935 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563955, finearea: '1131936 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563956, finearea: '1131937 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563957, finearea: '1131938 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563958, finearea: '1131939 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563959, finearea: '1131940 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563960, finearea: '1131941 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563961, finearea: '1131942 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563962, finearea: '1131943 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563963, finearea: '1131944 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563964, finearea: '1131945 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563965, finearea: '1131946 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563966, finearea: '1131947 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563967, finearea: '1131948 _ Blacktown (C)', subregion: 'Rooty Hill - Minchinbury', region: 'Northern Sydney' },
  { geography: 563968, finearea: '1106901 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563969, finearea: '1106902 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563970, finearea: '1106903 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563971, finearea: '1106904 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563972, finearea: '1106905 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563973, finearea: '1106906 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563974, finearea: '1106907 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563975, finearea: '1106908 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563976, finearea: '1106909 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563977, finearea: '1106910 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563978, finearea: '1106911 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563979, finearea: '1106912 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563980, finearea: '1106913 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563981, finearea: '1106914 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563982, finearea: '1106915 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563983, finearea: '1106916 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563984, finearea: '1106917 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563985, finearea: '1106918 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563986, finearea: '1106919 _ Bland (A)', subregion: 'West Wyalong', region: 'Riverina-Goulburn Belt' },
  { geography: 563987, finearea: '1125708 _ Bland (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 563988, finearea: '1107601 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563989, finearea: '1107602 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563990, finearea: '1107603 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563991, finearea: '1107604 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563992, finearea: '1107605 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563993, finearea: '1107606 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563994, finearea: '1107607 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563995, finearea: '1107608 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563996, finearea: '1107609 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563997, finearea: '1107610 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563998, finearea: '1107611 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 563999, finearea: '1107612 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564000, finearea: '1107613 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564001, finearea: '1107614 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564002, finearea: '1107615 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564003, finearea: '1107616 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564004, finearea: '1107617 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564005, finearea: '1107618 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564006, finearea: '1107619 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564007, finearea: '1107620 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564008, finearea: '1107621 _ Blayney (A)', subregion: 'Blayney', region: 'Regional NSW - Remainder' },
  { geography: 564009, finearea: '1107501 _ Blue Mountains (C)', subregion: 'Singelton region - Wollangambe - Wollemi', region: 'Central Coast and Hunter' },
  { geography: 564010, finearea: '1129902 _ Blue Mountains (C)', subregion: 'Bilpin - Colo - St Albans', region: 'Northern Sydney' },
  { geography: 564011, finearea: '1144901 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564012, finearea: '1144902 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564013, finearea: '1144903 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564014, finearea: '1144904 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564015, finearea: '1144905 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564016, finearea: '1144906 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564017, finearea: '1144907 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564018, finearea: '1144908 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564019, finearea: '1144909 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564020, finearea: '1144910 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564021, finearea: '1144911 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564022, finearea: '1144912 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564023, finearea: '1144913 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564024, finearea: '1144914 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564025, finearea: '1144915 _ Blue Mountains (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 564026, finearea: '1145001 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564027, finearea: '1145002 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564028, finearea: '1145003 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564029, finearea: '1145004 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564030, finearea: '1145005 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564031, finearea: '1145006 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564032, finearea: '1145007 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564033, finearea: '1145008 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564034, finearea: '1145009 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564035, finearea: '1145010 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 564036, finearea: '1145011 _ Blue Mountains (C)', subregion: 'Blaxland - Warrimoo - Lapstone', region: 'Northern Sydney' },
  { geography: 581728, finearea: '1154506 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581729, finearea: '1154507 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581730, finearea: '1154508 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581731, finearea: '1154509 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581732, finearea: '1154510 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581733, finearea: '1154511 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581734, finearea: '1154512 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581735, finearea: '1154513 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581736, finearea: '1154514 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581737, finearea: '1154515 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581738, finearea: '1154516 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581739, finearea: '1154517 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581740, finearea: '1154518 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581741, finearea: '1154519 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581742, finearea: '1154520 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581743, finearea: '1154521 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581744, finearea: '1154522 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581745, finearea: '1154523 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581746, finearea: '1154524 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581747, finearea: '1154525 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581748, finearea: '1154526 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581749, finearea: '1154527 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581750, finearea: '1154528 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581751, finearea: '1154529 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581752, finearea: '1154530 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581753, finearea: '1154531 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581754, finearea: '1154532 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581755, finearea: '1154533 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581756, finearea: '1154534 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581757, finearea: '1154535 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581758, finearea: '1154536 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581759, finearea: '1154537 _ Wollongong (C)', subregion: 'Berkeley - Lake Heights - Cringila', region: 'Illawarra-Shoalhaven' },
  { geography: 581760, finearea: '1154601 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581761, finearea: '1154602 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581762, finearea: '1154603 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581763, finearea: '1154604 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581764, finearea: '1154605 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581765, finearea: '1154606 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581766, finearea: '1154607 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581767, finearea: '1154608 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581768, finearea: '1154609 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581769, finearea: '1154610 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581770, finearea: '1154611 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581771, finearea: '1154612 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581772, finearea: '1154613 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581773, finearea: '1154614 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581774, finearea: '1154615 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581775, finearea: '1154616 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581776, finearea: '1154617 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581777, finearea: '1154618 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581778, finearea: '1154619 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581779, finearea: '1154620 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581780, finearea: '1154621 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581781, finearea: '1154622 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581782, finearea: '1154623 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581783, finearea: '1154624 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581784, finearea: '1154625 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581785, finearea: '1154626 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581786, finearea: '1154627 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581787, finearea: '1154628 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581788, finearea: '1154629 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581789, finearea: '1154630 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581790, finearea: '1154631 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581791, finearea: '1154632 _ Wollongong (C)', subregion: 'Port Kembla - Warrawong - Port Kembla Industrial', region: 'Illawarra-Shoalhaven' },
  { geography: 581792, finearea: '1154701 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581793, finearea: '1154702 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581794, finearea: '1154703 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581795, finearea: '1154704 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581796, finearea: '1154705 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581797, finearea: '1154706 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581798, finearea: '1154707 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581799, finearea: '1154708 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581800, finearea: '1154709 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581801, finearea: '1154710 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581802, finearea: '1154711 _ Wollongong (C)', subregion: 'Windang - Primbee', region: 'Illawarra-Shoalhaven' },
  { geography: 581803, finearea: '1113501 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581804, finearea: '1114401 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581805, finearea: '1114402 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581806, finearea: '1114403 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581807, finearea: '1114404 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581808, finearea: '1114405 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581809, finearea: '1114406 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581810, finearea: '1114407 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581811, finearea: '1114408 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581812, finearea: '1114409 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581813, finearea: '1114410 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581814, finearea: '1114411 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581815, finearea: '1114412 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581816, finearea: '1114413 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581817, finearea: '1114414 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581818, finearea: '1114415 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581819, finearea: '1114416 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581820, finearea: '1114417 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581821, finearea: '1114418 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581822, finearea: '1114419 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581823, finearea: '1114420 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581824, finearea: '1114421 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581825, finearea: '1114422 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581826, finearea: '1114423 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581827, finearea: '1114424 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581828, finearea: '1114425 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581829, finearea: '1114426 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581830, finearea: '1114427 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581831, finearea: '1114428 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581832, finearea: '1114429 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581833, finearea: '1114430 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581834, finearea: '1114431 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581835, finearea: '1114432 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581836, finearea: '1114433 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581837, finearea: '1114434 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581838, finearea: '1114435 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581839, finearea: '1114436 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581840, finearea: '1114437 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581841, finearea: '1114438 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581842, finearea: '1114439 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581843, finearea: '1114440 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581844, finearea: '1114441 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581845, finearea: '1114442 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581846, finearea: '1114443 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581847, finearea: '1114444 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581848, finearea: '1114445 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581849, finearea: '1114446 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581850, finearea: '1114447 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581851, finearea: '1114448 _ Wollongong (C)', subregion: 'Balgownie - Fairy Meadow', region: 'Illawarra-Shoalhaven' },
  { geography: 581852, finearea: '1114501 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581853, finearea: '1114502 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581854, finearea: '1114503 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581855, finearea: '1114504 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581856, finearea: '1114505 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581857, finearea: '1114506 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581858, finearea: '1114507 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581859, finearea: '1114508 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581860, finearea: '1114509 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581861, finearea: '1114510 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581862, finearea: '1114511 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581863, finearea: '1114512 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581864, finearea: '1114513 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581865, finearea: '1114514 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581866, finearea: '1114515 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581867, finearea: '1114517 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581868, finearea: '1114518 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581869, finearea: '1114519 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581870, finearea: '1114520 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581871, finearea: '1114521 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581872, finearea: '1114522 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581873, finearea: '1114523 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581874, finearea: '1114524 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581875, finearea: '1114525 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581876, finearea: '1114526 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581877, finearea: '1114527 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581878, finearea: '1114528 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581879, finearea: '1114531 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581880, finearea: '1114532 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581881, finearea: '1114533 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581882, finearea: '1114534 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581883, finearea: '1114535 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581884, finearea: '1114536 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581885, finearea: '1114537 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581886, finearea: '1114538 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581887, finearea: '1114539 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581888, finearea: '1114540 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581889, finearea: '1114541 _ Wollongong (C)', subregion: 'Corrimal - Tarrawanna - Bellambi', region: 'Illawarra-Shoalhaven' },
  { geography: 581890, finearea: '1114601 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581891, finearea: '1114602 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581892, finearea: '1114603 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581893, finearea: '1114604 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581894, finearea: '1114605 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581895, finearea: '1114606 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581896, finearea: '1114607 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581897, finearea: '1114608 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581898, finearea: '1114610 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581899, finearea: '1114611 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581900, finearea: '1114612 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581901, finearea: '1114613 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581902, finearea: '1114615 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581903, finearea: '1114616 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581904, finearea: '1114617 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581905, finearea: '1114618 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581906, finearea: '1114619 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581907, finearea: '1114620 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581908, finearea: '1114621 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581909, finearea: '1114622 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581910, finearea: '1114623 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581911, finearea: '1114624 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581912, finearea: '1114625 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581913, finearea: '1114627 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581914, finearea: '1114628 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581915, finearea: '1114629 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581916, finearea: '1114631 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581917, finearea: '1114632 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581918, finearea: '1114633 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581919, finearea: '1114634 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581920, finearea: '1114635 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581921, finearea: '1114636 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581922, finearea: '1114637 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581923, finearea: '1114639 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581924, finearea: '1114640 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581925, finearea: '1114641 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581926, finearea: '1114642 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581927, finearea: '1114643 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 567237, finearea: '1108420 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567238, finearea: '1108421 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567239, finearea: '1108422 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567240, finearea: '1108423 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567241, finearea: '1108424 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567242, finearea: '1108425 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567243, finearea: '1108426 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567244, finearea: '1108427 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567245, finearea: '1108428 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567246, finearea: '1108429 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567247, finearea: '1108430 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567248, finearea: '1108431 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567249, finearea: '1108432 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567250, finearea: '1108433 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567251, finearea: '1108434 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567252, finearea: '1108435 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567253, finearea: '1108436 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567254, finearea: '1108438 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567255, finearea: '1108439 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567256, finearea: '1108440 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567257, finearea: '1108441 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567258, finearea: '1108442 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567259, finearea: '1108443 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567260, finearea: '1108444 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567261, finearea: '1108445 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567262, finearea: '1108446 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567263, finearea: '1108501 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567264, finearea: '1108502 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567265, finearea: '1108503 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567266, finearea: '1108504 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567267, finearea: '1108505 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567268, finearea: '1108506 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567269, finearea: '1108507 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567270, finearea: '1108508 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567271, finearea: '1108509 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567272, finearea: '1108510 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567273, finearea: '1108511 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567274, finearea: '1108512 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567275, finearea: '1108513 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567276, finearea: '1108514 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567277, finearea: '1108515 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567278, finearea: '1108516 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567279, finearea: '1108517 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567280, finearea: '1108518 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567281, finearea: '1108519 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567282, finearea: '1108520 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567283, finearea: '1108521 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567284, finearea: '1108522 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567285, finearea: '1108523 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567286, finearea: '1108524 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567287, finearea: '1108525 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567288, finearea: '1108526 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567289, finearea: '1108527 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567290, finearea: '1108528 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567291, finearea: '1108529 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567292, finearea: '1108530 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567293, finearea: '1108531 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - South', region: 'North East NSW' },
  { geography: 567294, finearea: '1108601 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567295, finearea: '1108602 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567296, finearea: '1108603 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567297, finearea: '1108604 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567298, finearea: '1108605 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567299, finearea: '1108606 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567300, finearea: '1108607 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567301, finearea: '1108608 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567302, finearea: '1108609 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567303, finearea: '1108610 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567304, finearea: '1108611 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567305, finearea: '1108612 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567306, finearea: '1108613 _ Coffs Harbour (C)', subregion: 'Coramba - Nana Glen - Bucca', region: 'North East NSW' },
  { geography: 567307, finearea: '1108702 _ Coffs Harbour (C)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 567308, finearea: '1108703 _ Coffs Harbour (C)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 567309, finearea: '1108801 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567310, finearea: '1108802 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567311, finearea: '1108803 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567312, finearea: '1108804 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567313, finearea: '1108805 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567314, finearea: '1108806 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567315, finearea: '1108807 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567316, finearea: '1108808 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567317, finearea: '1108809 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567318, finearea: '1108810 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567319, finearea: '1108811 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567320, finearea: '1108812 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567321, finearea: '1108813 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567322, finearea: '1108814 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567323, finearea: '1108815 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567324, finearea: '1108816 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567325, finearea: '1108817 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567326, finearea: '1108818 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567327, finearea: '1108819 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567328, finearea: '1108820 _ Coffs Harbour (C)', subregion: 'Korora - Emerald Beach', region: 'North East NSW' },
  { geography: 567329, finearea: '1108901 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567330, finearea: '1108902 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567331, finearea: '1108903 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567332, finearea: '1108904 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567333, finearea: '1108905 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567334, finearea: '1108906 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567335, finearea: '1108907 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567336, finearea: '1108908 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567337, finearea: '1108909 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567338, finearea: '1108910 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567339, finearea: '1108911 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567340, finearea: '1108912 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567341, finearea: '1108913 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567342, finearea: '1108914 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567343, finearea: '1108915 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567344, finearea: '1108916 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567345, finearea: '1108917 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567346, finearea: '1108918 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567347, finearea: '1108919 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567348, finearea: '1108920 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567349, finearea: '1108921 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567350, finearea: '1108922 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567351, finearea: '1108923 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567352, finearea: '1108924 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567353, finearea: '1108925 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567354, finearea: '1108926 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567355, finearea: '1108927 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567356, finearea: '1108928 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567357, finearea: '1108929 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567358, finearea: '1108930 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567359, finearea: '1108931 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567360, finearea: '1108932 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567361, finearea: '1108933 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567362, finearea: '1108934 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567363, finearea: '1108935 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567364, finearea: '1108936 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567365, finearea: '1108937 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567366, finearea: '1108938 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567367, finearea: '1108939 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567368, finearea: '1108940 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567369, finearea: '1108941 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567370, finearea: '1108942 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567371, finearea: '1108943 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567372, finearea: '1108944 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567373, finearea: '1108945 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567374, finearea: '1108946 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567375, finearea: '1108947 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567376, finearea: '1108948 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567377, finearea: '1108949 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567378, finearea: '1108950 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567379, finearea: '1108951 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567380, finearea: '1108952 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567381, finearea: '1108953 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567382, finearea: '1108954 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567383, finearea: '1108955 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567384, finearea: '1108956 _ Coffs Harbour (C)', subregion: 'Sawtell - Boambee', region: 'North East NSW' },
  { geography: 567385, finearea: '1109101 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567386, finearea: '1109102 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567387, finearea: '1109103 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567388, finearea: '1109105 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567389, finearea: '1109106 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567390, finearea: '1109107 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567391, finearea: '1109108 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567392, finearea: '1109109 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567393, finearea: '1109110 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567394, finearea: '1109111 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567395, finearea: '1109113 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567396, finearea: '1109115 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567397, finearea: '1109116 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567398, finearea: '1109117 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567399, finearea: '1109118 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567400, finearea: '1109121 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567401, finearea: '1109122 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567402, finearea: '1109123 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567403, finearea: '1109124 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567404, finearea: '1109125 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567405, finearea: '1109126 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567406, finearea: '1109127 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567407, finearea: '1109128 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567408, finearea: '1109129 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567409, finearea: '1109130 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567410, finearea: '1109131 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567411, finearea: '1109132 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567412, finearea: '1109133 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567413, finearea: '1109134 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567414, finearea: '1109135 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567415, finearea: '1109136 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567416, finearea: '1109137 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567417, finearea: '1109138 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567418, finearea: '1109139 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567419, finearea: '1109140 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567420, finearea: '1109141 _ Coffs Harbour (C)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567421, finearea: '1126620 _ Coolamon (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 567422, finearea: '1126621 _ Coolamon (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 567423, finearea: '1127110 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567424, finearea: '1127125 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567425, finearea: '1127126 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567426, finearea: '1127127 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567427, finearea: '1127128 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567428, finearea: '1127129 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567429, finearea: '1127139 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567430, finearea: '1127140 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567431, finearea: '1127141 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567432, finearea: '1127142 _ Coolamon (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 567433, finearea: '1109401 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567434, finearea: '1109402 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567435, finearea: '1109403 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 567436, finearea: '1109404 _ Coonamble (A)', subregion: 'Coonamble', region: 'Regional NSW - Remainder' },
  { geography: 562437, finearea: '1123604 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562438, finearea: '1123605 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562439, finearea: '1123606 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562440, finearea: '1123607 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562441, finearea: '1123608 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562442, finearea: '1123609 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562443, finearea: '1123610 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562444, finearea: '1123611 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562445, finearea: '1123612 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562446, finearea: '1123613 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562447, finearea: '1123614 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562448, finearea: '1123615 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562449, finearea: '1123616 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562450, finearea: '1123617 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562451, finearea: '1123619 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562452, finearea: '1123620 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562453, finearea: '1123621 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562454, finearea: '1123622 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562455, finearea: '1123623 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562456, finearea: '1123624 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562457, finearea: '1123625 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562458, finearea: '1123626 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562459, finearea: '1123627 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562460, finearea: '1123628 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562461, finearea: '1123629 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562462, finearea: '1123630 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562463, finearea: '1123631 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562464, finearea: '1123632 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562465, finearea: '1123633 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562466, finearea: '1123634 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562467, finearea: '1123635 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562468, finearea: '1123636 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562469, finearea: '1123637 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562470, finearea: '1123638 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562471, finearea: '1123639 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562472, finearea: '1123640 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562473, finearea: '1123641 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562474, finearea: '1123642 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562475, finearea: '1123643 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562476, finearea: '1123644 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562477, finearea: '1123645 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562478, finearea: '1123646 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562479, finearea: '1123647 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562480, finearea: '1123648 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562481, finearea: '1123649 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562482, finearea: '1123701 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562483, finearea: '1123702 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562484, finearea: '1123703 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562485, finearea: '1123704 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562486, finearea: '1123705 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562487, finearea: '1123706 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562488, finearea: '1123707 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562489, finearea: '1123708 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562490, finearea: '1123709 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562491, finearea: '1123710 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562492, finearea: '1123711 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562493, finearea: '1123712 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562494, finearea: '1123713 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562495, finearea: '1123714 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562496, finearea: '1123715 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562497, finearea: '1123716 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562498, finearea: '1123719 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562499, finearea: '1123720 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562500, finearea: '1123721 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562501, finearea: '1123722 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562502, finearea: '1123723 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562503, finearea: '1123724 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562504, finearea: '1123725 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562505, finearea: '1123726 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562506, finearea: '1123727 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562507, finearea: '1123728 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562508, finearea: '1123729 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562509, finearea: '1123730 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562510, finearea: '1123731 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562511, finearea: '1123732 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562512, finearea: '1123733 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562513, finearea: '1123734 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562514, finearea: '1123735 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562515, finearea: '1123736 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562516, finearea: '1123737 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562517, finearea: '1123738 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562518, finearea: '1123739 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562519, finearea: '1123740 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562520, finearea: '1123741 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562521, finearea: '1123742 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562522, finearea: '1123743 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562523, finearea: '1123744 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562524, finearea: '1123745 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562525, finearea: '1123746 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562526, finearea: '1123747 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562527, finearea: '1123748 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562528, finearea: '1123749 _ Ballina (A)', subregion: 'Ballina Region', region: 'North East NSW' },
  { geography: 562529, finearea: '1124201 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562530, finearea: '1124202 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562531, finearea: '1124203 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562532, finearea: '1124204 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562533, finearea: '1124205 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562534, finearea: '1124206 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562535, finearea: '1124207 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562536, finearea: '1124208 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562537, finearea: '1124209 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562538, finearea: '1124210 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562539, finearea: '1124211 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562540, finearea: '1124212 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562541, finearea: '1124213 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562542, finearea: '1124214 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562543, finearea: '1124215 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562544, finearea: '1124216 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562545, finearea: '1124217 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562546, finearea: '1124218 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562547, finearea: '1124219 _ Ballina (A)', subregion: 'Lennox Head - Skennars Head', region: 'North East NSW' },
  { geography: 562548, finearea: '1124925 _ Ballina (A)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 562549, finearea: '1117901 _ Balranald (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 562550, finearea: '1117903 _ Balranald (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 562551, finearea: '1117907 _ Balranald (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 562552, finearea: '1117908 _ Balranald (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 562553, finearea: '1117909 _ Balranald (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 562554, finearea: '1117910 _ Balranald (A)', subregion: 'Wentworth-Balranald Region', region: 'Regional NSW - Remainder' },
  { geography: 562555, finearea: '1105801 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562556, finearea: '1105802 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562557, finearea: '1105804 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562558, finearea: '1105805 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562559, finearea: '1105806 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562560, finearea: '1105807 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562561, finearea: '1105808 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562562, finearea: '1105809 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562563, finearea: '1105811 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562564, finearea: '1105813 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562565, finearea: '1105814 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562566, finearea: '1105815 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562567, finearea: '1105816 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562568, finearea: '1105817 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562569, finearea: '1105818 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562570, finearea: '1105819 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562571, finearea: '1105820 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562572, finearea: '1105821 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562573, finearea: '1105822 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562574, finearea: '1105823 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562575, finearea: '1105824 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562576, finearea: '1105825 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562577, finearea: '1105826 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562578, finearea: '1105827 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562579, finearea: '1105828 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562580, finearea: '1105829 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562581, finearea: '1105830 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562582, finearea: '1105831 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562583, finearea: '1105832 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562584, finearea: '1105833 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562585, finearea: '1105834 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562586, finearea: '1105835 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562587, finearea: '1105836 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562588, finearea: '1105837 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562589, finearea: '1105838 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562590, finearea: '1105839 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562591, finearea: '1105840 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562592, finearea: '1105841 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562593, finearea: '1105842 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562594, finearea: '1105843 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562595, finearea: '1105844 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562596, finearea: '1105845 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562597, finearea: '1105846 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562598, finearea: '1105847 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562599, finearea: '1105848 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562600, finearea: '1105849 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562601, finearea: '1105850 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562602, finearea: '1105851 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562603, finearea: '1105852 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562604, finearea: '1105853 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562605, finearea: '1105854 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562606, finearea: '1105855 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562607, finearea: '1105856 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562608, finearea: '1105857 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562609, finearea: '1105858 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562610, finearea: '1105859 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562611, finearea: '1105860 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562612, finearea: '1105861 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562613, finearea: '1105862 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562614, finearea: '1105863 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562615, finearea: '1105864 _ Bathurst Regional (A)', subregion: 'Bathurst', region: 'Regional NSW - Remainder' },
  { geography: 562616, finearea: '1105901 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562617, finearea: '1105902 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562618, finearea: '1105903 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562619, finearea: '1105904 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562620, finearea: '1105905 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562621, finearea: '1105906 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562622, finearea: '1105907 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562623, finearea: '1105908 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562624, finearea: '1105910 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562625, finearea: '1105912 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562626, finearea: '1105913 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562627, finearea: '1105914 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562628, finearea: '1105915 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562629, finearea: '1105916 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562630, finearea: '1105917 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562631, finearea: '1105918 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562632, finearea: '1105919 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562633, finearea: '1105920 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562634, finearea: '1105921 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562635, finearea: '1105922 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562636, finearea: '1105923 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 579128, finearea: '1133144 _ Sydney (C)', subregion: 'Glebe - Forest Lodge', region: 'Inner Sydney' },
  { geography: 579129, finearea: '1133201 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579130, finearea: '1133203 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579131, finearea: '1133204 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579132, finearea: '1133205 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579133, finearea: '1133209 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579134, finearea: '1133210 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579135, finearea: '1133211 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579136, finearea: '1133212 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579137, finearea: '1133213 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579138, finearea: '1133214 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579139, finearea: '1133215 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579140, finearea: '1133216 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579141, finearea: '1133217 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579142, finearea: '1133223 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579143, finearea: '1133230 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579144, finearea: '1133232 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579145, finearea: '1133240 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579146, finearea: '1133243 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579147, finearea: '1133245 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579148, finearea: '1133246 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579149, finearea: '1133247 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579150, finearea: '1133248 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579151, finearea: '1133249 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579152, finearea: '1133250 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579153, finearea: '1133251 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579154, finearea: '1133252 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579155, finearea: '1133253 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579156, finearea: '1133254 _ Sydney (C)', subregion: 'Newtown - Camperdown - Darlington', region: 'Inner Sydney' },
  { geography: 579157, finearea: '1133301 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579158, finearea: '1133302 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579159, finearea: '1133303 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579160, finearea: '1133304 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579161, finearea: '1133305 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579162, finearea: '1133306 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579163, finearea: '1133307 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579164, finearea: '1133308 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579165, finearea: '1133309 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579166, finearea: '1133310 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579167, finearea: '1133311 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579168, finearea: '1133312 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579169, finearea: '1133313 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579170, finearea: '1133314 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579171, finearea: '1133315 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579172, finearea: '1133316 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579173, finearea: '1133317 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579174, finearea: '1133318 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579175, finearea: '1133319 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579176, finearea: '1133320 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579177, finearea: '1133321 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579178, finearea: '1133322 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579179, finearea: '1133323 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579180, finearea: '1133324 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579181, finearea: '1133325 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579182, finearea: '1133326 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579183, finearea: '1133327 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579184, finearea: '1133328 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579185, finearea: '1133329 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579186, finearea: '1133330 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579187, finearea: '1133331 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579188, finearea: '1133332 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579189, finearea: '1133333 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579190, finearea: '1133334 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579191, finearea: '1133335 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579192, finearea: '1133336 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579193, finearea: '1133337 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579194, finearea: '1133338 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579195, finearea: '1133339 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579196, finearea: '1133340 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579197, finearea: '1133341 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579198, finearea: '1133342 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579199, finearea: '1133343 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579200, finearea: '1133344 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579201, finearea: '1133345 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579202, finearea: '1133346 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579203, finearea: '1133347 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579204, finearea: '1133348 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579205, finearea: '1133349 _ Sydney (C)', subregion: 'Potts Point - Woolloomooloo', region: 'Inner Sydney' },
  { geography: 579206, finearea: '1133401 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579207, finearea: '1133402 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579208, finearea: '1133403 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579209, finearea: '1133404 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579210, finearea: '1133405 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579211, finearea: '1133406 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579212, finearea: '1133408 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579213, finearea: '1133409 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579214, finearea: '1133410 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579215, finearea: '1133411 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579216, finearea: '1133413 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579217, finearea: '1133414 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579218, finearea: '1133416 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579219, finearea: '1133418 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579220, finearea: '1133419 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579221, finearea: '1133420 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579222, finearea: '1133421 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579223, finearea: '1133422 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579224, finearea: '1133423 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579225, finearea: '1133424 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579226, finearea: '1133425 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579227, finearea: '1133428 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579228, finearea: '1133429 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579229, finearea: '1133430 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579230, finearea: '1133431 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579231, finearea: '1133432 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579232, finearea: '1133433 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579233, finearea: '1133436 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579234, finearea: '1133437 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579235, finearea: '1133438 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579236, finearea: '1133439 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579237, finearea: '1133440 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579238, finearea: '1133441 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579239, finearea: '1133442 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579240, finearea: '1133443 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579241, finearea: '1133444 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579242, finearea: '1133445 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579243, finearea: '1133446 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579244, finearea: '1133447 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579245, finearea: '1133448 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579246, finearea: '1133449 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579247, finearea: '1133450 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579248, finearea: '1133451 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579249, finearea: '1133452 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579250, finearea: '1133453 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579251, finearea: '1133454 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579252, finearea: '1133455 _ Sydney (C)', subregion: 'Pyrmont - Ultimo', region: 'Inner Sydney' },
  { geography: 579253, finearea: '1133501 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579254, finearea: '1133502 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579255, finearea: '1133503 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579256, finearea: '1133504 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579257, finearea: '1133505 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579258, finearea: '1133506 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579259, finearea: '1133507 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579260, finearea: '1133508 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579261, finearea: '1133509 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579262, finearea: '1133510 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579263, finearea: '1133511 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579264, finearea: '1133512 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579265, finearea: '1133513 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579266, finearea: '1133514 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579267, finearea: '1133515 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579268, finearea: '1133516 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579269, finearea: '1133517 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579270, finearea: '1133518 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579271, finearea: '1133519 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579272, finearea: '1133520 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579273, finearea: '1133521 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579274, finearea: '1133522 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579275, finearea: '1133523 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579276, finearea: '1133524 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579277, finearea: '1133525 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579278, finearea: '1133526 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579279, finearea: '1133527 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579280, finearea: '1133528 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579281, finearea: '1133529 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579282, finearea: '1133530 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579283, finearea: '1133531 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579284, finearea: '1133532 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579285, finearea: '1133533 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579286, finearea: '1133534 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579287, finearea: '1133536 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579288, finearea: '1133537 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579289, finearea: '1133538 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579290, finearea: '1133539 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579291, finearea: '1133540 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579292, finearea: '1133541 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579293, finearea: '1133542 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579294, finearea: '1133543 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579295, finearea: '1133544 _ Sydney (C)', subregion: 'Redfern - Chippendale', region: 'Inner Sydney' },
  { geography: 579296, finearea: '1133601 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579297, finearea: '1133602 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579298, finearea: '1133603 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579299, finearea: '1133604 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579300, finearea: '1133605 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579301, finearea: '1133606 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579302, finearea: '1133607 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579303, finearea: '1133608 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579304, finearea: '1133609 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579305, finearea: '1133610 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579306, finearea: '1133611 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579307, finearea: '1133612 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579308, finearea: '1133613 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579309, finearea: '1133614 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579310, finearea: '1133615 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579311, finearea: '1133616 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579312, finearea: '1133617 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579313, finearea: '1133618 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579314, finearea: '1133619 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579315, finearea: '1133620 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579316, finearea: '1133621 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579317, finearea: '1133622 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579318, finearea: '1133623 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579319, finearea: '1133624 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579320, finearea: '1133625 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579321, finearea: '1133626 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579322, finearea: '1133628 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579323, finearea: '1133629 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579324, finearea: '1133630 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579325, finearea: '1133631 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579326, finearea: '1133632 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 579327, finearea: '1133633 _ Sydney (C)', subregion: 'Surry Hills', region: 'Inner Sydney' },
  { geography: 573928, finearea: '1122211 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573929, finearea: '1122212 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573930, finearea: '1122213 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573931, finearea: '1122214 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573932, finearea: '1122215 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573933, finearea: '1122216 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573934, finearea: '1122217 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573935, finearea: '1122218 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573936, finearea: '1122219 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573937, finearea: '1122220 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573938, finearea: '1122221 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573939, finearea: '1122222 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573940, finearea: '1122223 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573941, finearea: '1122224 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573942, finearea: '1122225 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573943, finearea: '1122232 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573944, finearea: '1122233 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573945, finearea: '1122234 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573946, finearea: '1122235 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573947, finearea: '1122236 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573948, finearea: '1122237 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573949, finearea: '1122238 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573950, finearea: '1122239 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573951, finearea: '1122240 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573952, finearea: '1122301 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573953, finearea: '1122302 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573954, finearea: '1122303 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573955, finearea: '1122304 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573956, finearea: '1122306 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573957, finearea: '1122307 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573958, finearea: '1122308 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573959, finearea: '1122309 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573960, finearea: '1122310 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573961, finearea: '1122311 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573962, finearea: '1122312 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573963, finearea: '1122313 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573964, finearea: '1122314 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573965, finearea: '1122316 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573966, finearea: '1122324 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573967, finearea: '1122325 _ Newcastle (C)', subregion: 'Beresfield - Hexham', region: 'Central Coast and Hunter' },
  { geography: 573968, finearea: '1122401 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573969, finearea: '1122402 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573970, finearea: '1122403 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573971, finearea: '1122404 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573972, finearea: '1122405 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573973, finearea: '1122406 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573974, finearea: '1122407 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573975, finearea: '1122408 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573976, finearea: '1122409 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573977, finearea: '1122410 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573978, finearea: '1122411 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573979, finearea: '1122412 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573980, finearea: '1122413 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573981, finearea: '1122414 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573982, finearea: '1122415 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573983, finearea: '1122416 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573984, finearea: '1122417 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573985, finearea: '1122418 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573986, finearea: '1122419 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573987, finearea: '1122420 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573988, finearea: '1122421 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573989, finearea: '1122422 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573990, finearea: '1122423 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573991, finearea: '1122424 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573992, finearea: '1122425 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573993, finearea: '1122426 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573994, finearea: '1122427 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573995, finearea: '1122428 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573996, finearea: '1122429 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573997, finearea: '1122430 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573998, finearea: '1122431 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 573999, finearea: '1122432 _ Newcastle (C)', subregion: 'Hamilton - Broadmeadow', region: 'Central Coast and Hunter' },
  { geography: 574000, finearea: '1122501 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574001, finearea: '1122502 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574002, finearea: '1122503 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574003, finearea: '1122504 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574004, finearea: '1122505 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574005, finearea: '1122506 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574006, finearea: '1122507 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574007, finearea: '1122508 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574008, finearea: '1122509 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574009, finearea: '1122510 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574010, finearea: '1122511 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574011, finearea: '1122512 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574012, finearea: '1122513 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574013, finearea: '1122514 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574014, finearea: '1122515 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574015, finearea: '1122516 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574016, finearea: '1122517 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574017, finearea: '1122518 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574018, finearea: '1122519 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574019, finearea: '1122520 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574020, finearea: '1122521 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574021, finearea: '1122522 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574022, finearea: '1122523 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574023, finearea: '1122524 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574024, finearea: '1122525 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574025, finearea: '1122526 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574026, finearea: '1122527 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574027, finearea: '1122528 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574028, finearea: '1122529 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574029, finearea: '1122530 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574030, finearea: '1122531 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574031, finearea: '1122532 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574032, finearea: '1122533 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574033, finearea: '1122534 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574034, finearea: '1122535 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574035, finearea: '1122536 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574036, finearea: '1122537 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574037, finearea: '1122538 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574038, finearea: '1122539 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574039, finearea: '1122540 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574040, finearea: '1122541 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574041, finearea: '1122542 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574042, finearea: '1122543 _ Newcastle (C)', subregion: 'Lambton - New Lambton', region: 'Central Coast and Hunter' },
  { geography: 574043, finearea: '1122601 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574044, finearea: '1122602 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574045, finearea: '1122603 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574046, finearea: '1122604 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574047, finearea: '1122605 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574048, finearea: '1122606 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574049, finearea: '1122607 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574050, finearea: '1122608 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574051, finearea: '1122609 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574052, finearea: '1122610 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574053, finearea: '1122611 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574054, finearea: '1122612 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574055, finearea: '1122613 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574056, finearea: '1122614 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574057, finearea: '1122615 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574058, finearea: '1122616 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574059, finearea: '1122617 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574060, finearea: '1122618 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574061, finearea: '1122619 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574062, finearea: '1122620 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574063, finearea: '1122621 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574064, finearea: '1122622 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574065, finearea: '1122623 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574066, finearea: '1122624 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574067, finearea: '1122625 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574068, finearea: '1122626 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574069, finearea: '1122627 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574070, finearea: '1122628 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574071, finearea: '1122629 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574072, finearea: '1122630 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574073, finearea: '1122631 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574074, finearea: '1122632 _ Newcastle (C)', subregion: 'Maryland - Fletcher - Minmi', region: 'Central Coast and Hunter' },
  { geography: 574075, finearea: '1122701 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574076, finearea: '1122702 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574077, finearea: '1122703 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574078, finearea: '1122704 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574079, finearea: '1122705 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574080, finearea: '1122706 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574081, finearea: '1122707 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574082, finearea: '1122708 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574083, finearea: '1122709 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574084, finearea: '1122710 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574085, finearea: '1122711 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574086, finearea: '1122712 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574087, finearea: '1122713 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574088, finearea: '1122714 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574089, finearea: '1122715 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574090, finearea: '1122716 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574091, finearea: '1122717 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574092, finearea: '1122718 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574093, finearea: '1122719 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574094, finearea: '1122720 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574095, finearea: '1122721 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574096, finearea: '1122722 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574097, finearea: '1122723 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574098, finearea: '1122724 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574099, finearea: '1122725 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574100, finearea: '1122726 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574101, finearea: '1122727 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574102, finearea: '1122728 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574103, finearea: '1122729 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574104, finearea: '1122730 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574105, finearea: '1122731 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574106, finearea: '1122732 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574107, finearea: '1122733 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574108, finearea: '1122734 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574109, finearea: '1122735 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574110, finearea: '1122736 _ Newcastle (C)', subregion: 'Mayfield - Warabrook', region: 'Central Coast and Hunter' },
  { geography: 574111, finearea: '1122801 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574112, finearea: '1122802 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574113, finearea: '1122803 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574114, finearea: '1122804 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574115, finearea: '1122805 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574116, finearea: '1122806 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574117, finearea: '1122807 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574118, finearea: '1122808 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574119, finearea: '1122809 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574120, finearea: '1122810 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574121, finearea: '1122811 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574122, finearea: '1122812 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574123, finearea: '1122813 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574124, finearea: '1122814 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574125, finearea: '1122815 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574126, finearea: '1122816 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 574127, finearea: '1122817 _ Newcastle (C)', subregion: 'Merewether - The Junction', region: 'Central Coast and Hunter' },
  { geography: 565837, finearea: '1136602 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565838, finearea: '1136603 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565839, finearea: '1136604 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565840, finearea: '1136605 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565841, finearea: '1136606 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565842, finearea: '1136607 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565843, finearea: '1136608 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565844, finearea: '1136609 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565845, finearea: '1136610 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565846, finearea: '1136611 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565847, finearea: '1136612 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565848, finearea: '1136613 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565849, finearea: '1136614 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565850, finearea: '1136615 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565851, finearea: '1136616 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565852, finearea: '1136617 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565853, finearea: '1136618 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565854, finearea: '1136619 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565855, finearea: '1136620 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565856, finearea: '1136621 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565857, finearea: '1136622 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565858, finearea: '1136623 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565859, finearea: '1136624 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565860, finearea: '1136625 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565861, finearea: '1136626 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565862, finearea: '1136627 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565863, finearea: '1136628 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565864, finearea: '1136629 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565865, finearea: '1136630 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565866, finearea: '1136631 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565867, finearea: '1136632 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565868, finearea: '1136633 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565869, finearea: '1136634 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565870, finearea: '1136635 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565871, finearea: '1136636 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565872, finearea: '1136637 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565873, finearea: '1136638 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565874, finearea: '1136639 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565875, finearea: '1136640 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565876, finearea: '1136641 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565877, finearea: '1136642 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565878, finearea: '1136643 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565879, finearea: '1136644 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565880, finearea: '1136645 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565881, finearea: '1136646 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565882, finearea: '1136647 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565883, finearea: '1136648 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 565884, finearea: '1136701 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565885, finearea: '1136702 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565886, finearea: '1136703 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565887, finearea: '1136704 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565888, finearea: '1136705 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565889, finearea: '1136706 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565890, finearea: '1136707 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565891, finearea: '1136708 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565892, finearea: '1136709 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565893, finearea: '1136710 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565894, finearea: '1136711 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565895, finearea: '1136712 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565896, finearea: '1136713 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565897, finearea: '1136714 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565898, finearea: '1136715 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565899, finearea: '1136716 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565900, finearea: '1136717 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565901, finearea: '1136718 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565902, finearea: '1136719 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565903, finearea: '1136720 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565904, finearea: '1136721 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565905, finearea: '1136722 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565906, finearea: '1136723 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565907, finearea: '1136724 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565908, finearea: '1136725 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565909, finearea: '1136726 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565910, finearea: '1136727 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565911, finearea: '1136728 _ Canterbury-Bankstown (A)', subregion: 'Roselands', region: 'South and West Sydney' },
  { geography: 565912, finearea: '1157301 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565913, finearea: '1157302 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565914, finearea: '1157303 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565915, finearea: '1157304 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565916, finearea: '1157305 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565917, finearea: '1157306 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565918, finearea: '1157307 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565919, finearea: '1157308 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565920, finearea: '1157309 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565921, finearea: '1157310 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565922, finearea: '1157311 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565923, finearea: '1157312 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565924, finearea: '1157313 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565925, finearea: '1157314 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565926, finearea: '1157315 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565927, finearea: '1157316 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565928, finearea: '1157317 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565929, finearea: '1157318 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565930, finearea: '1157319 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565931, finearea: '1157320 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565932, finearea: '1157321 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565933, finearea: '1157322 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565934, finearea: '1157323 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565935, finearea: '1157324 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565936, finearea: '1157325 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565937, finearea: '1157326 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565938, finearea: '1157327 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565939, finearea: '1157328 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565940, finearea: '1157329 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565941, finearea: '1157330 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565942, finearea: '1157331 _ Canterbury-Bankstown (A)', subregion: 'Lakemba', region: 'South and West Sydney' },
  { geography: 565943, finearea: '1157401 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565944, finearea: '1157402 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565945, finearea: '1157403 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565946, finearea: '1157404 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565947, finearea: '1157405 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565948, finearea: '1157406 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565949, finearea: '1157407 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565950, finearea: '1157408 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565951, finearea: '1157409 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565952, finearea: '1157410 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565953, finearea: '1157411 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565954, finearea: '1157412 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565955, finearea: '1157413 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565956, finearea: '1157414 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565957, finearea: '1157415 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565958, finearea: '1157416 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565959, finearea: '1157417 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565960, finearea: '1157418 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565961, finearea: '1157419 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565962, finearea: '1157420 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565963, finearea: '1157421 _ Canterbury-Bankstown (A)', subregion: 'Wiley Park', region: 'South and West Sydney' },
  { geography: 565964, finearea: '1137001 _ Canterbury-Bankstown (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 565965, finearea: '1137011 _ Canterbury-Bankstown (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 565966, finearea: '1137012 _ Canterbury-Bankstown (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 565967, finearea: '1137013 _ Canterbury-Bankstown (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 565968, finearea: '1137014 _ Canterbury-Bankstown (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 565969, finearea: '1137015 _ Canterbury-Bankstown (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 565970, finearea: '1137016 _ Canterbury-Bankstown (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 565971, finearea: '1137030 _ Canterbury-Bankstown (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 565972, finearea: '1137302 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565973, finearea: '1137307 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565974, finearea: '1137308 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565975, finearea: '1137309 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565976, finearea: '1137310 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565977, finearea: '1137311 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565978, finearea: '1137312 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565979, finearea: '1137313 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565980, finearea: '1137314 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565981, finearea: '1137320 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565982, finearea: '1137321 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565983, finearea: '1137322 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565984, finearea: '1137323 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565985, finearea: '1137324 _ Canterbury-Bankstown (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 565986, finearea: '1139201 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565987, finearea: '1139202 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565988, finearea: '1139203 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565989, finearea: '1139204 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565990, finearea: '1139205 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565991, finearea: '1139206 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565992, finearea: '1139207 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565993, finearea: '1139208 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565994, finearea: '1139209 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565995, finearea: '1139210 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565996, finearea: '1139211 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565997, finearea: '1139212 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565998, finearea: '1139213 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 565999, finearea: '1139214 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566000, finearea: '1139215 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566001, finearea: '1139216 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566002, finearea: '1139217 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566003, finearea: '1139218 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566004, finearea: '1139219 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566005, finearea: '1139220 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566006, finearea: '1139221 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566007, finearea: '1139222 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566008, finearea: '1139223 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566009, finearea: '1139224 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566010, finearea: '1139225 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566011, finearea: '1139226 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (North) - Ashbury', region: 'Inner Sydney' },
  { geography: 566012, finearea: '1139304 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566013, finearea: '1139307 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566014, finearea: '1139308 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566015, finearea: '1139309 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566016, finearea: '1139310 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566017, finearea: '1139311 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566018, finearea: '1139312 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566019, finearea: '1139313 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566020, finearea: '1139314 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566021, finearea: '1139332 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566022, finearea: '1139339 _ Canterbury-Bankstown (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 566023, finearea: '1158707 _ Canterbury-Bankstown (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 566024, finearea: '1158708 _ Canterbury-Bankstown (A)', subregion: 'Regents Park', region: 'South and West Sydney' },
  { geography: 566025, finearea: '1147901 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566026, finearea: '1147902 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566027, finearea: '1147903 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566028, finearea: '1147904 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566029, finearea: '1147905 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566030, finearea: '1147906 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566031, finearea: '1147907 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566032, finearea: '1147908 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566033, finearea: '1147909 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566034, finearea: '1147910 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566035, finearea: '1147911 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 566036, finearea: '1147912 _ Canterbury-Bankstown (A)', subregion: 'Chester Hill - Sefton', region: 'South and West Sydney' },
  { geography: 577528, finearea: '1149740 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577529, finearea: '1149741 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577530, finearea: '1149742 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577531, finearea: '1149743 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577532, finearea: '1149744 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577533, finearea: '1149745 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577534, finearea: '1149746 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577535, finearea: '1149747 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577536, finearea: '1149748 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577537, finearea: '1149749 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577538, finearea: '1149750 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577539, finearea: '1149754 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577540, finearea: '1149801 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577541, finearea: '1149802 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577542, finearea: '1149803 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577543, finearea: '1149804 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577544, finearea: '1149805 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577545, finearea: '1149806 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577546, finearea: '1149807 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577547, finearea: '1149816 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577548, finearea: '1149817 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577549, finearea: '1149818 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577550, finearea: '1149819 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577551, finearea: '1149820 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577552, finearea: '1149821 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577553, finearea: '1149822 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577554, finearea: '1149823 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577555, finearea: '1149825 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577556, finearea: '1149826 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577557, finearea: '1149827 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577558, finearea: '1149828 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577559, finearea: '1149829 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577560, finearea: '1149830 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577561, finearea: '1149831 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577562, finearea: '1149832 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577563, finearea: '1149833 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577564, finearea: '1149834 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577565, finearea: '1149835 _ Ryde (C)', subregion: 'Gladesville - Huntleys Point', region: 'Northern Sydney' },
  { geography: 577566, finearea: '1150001 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577567, finearea: '1150002 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577568, finearea: '1150003 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577569, finearea: '1150004 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577570, finearea: '1150005 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577571, finearea: '1150006 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577572, finearea: '1150007 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577573, finearea: '1150008 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577574, finearea: '1150009 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577575, finearea: '1150010 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577576, finearea: '1150011 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577577, finearea: '1150012 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577578, finearea: '1150013 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577579, finearea: '1150014 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577580, finearea: '1150015 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577581, finearea: '1150016 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577582, finearea: '1150017 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577583, finearea: '1150018 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577584, finearea: '1150019 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577585, finearea: '1150020 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577586, finearea: '1150021 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577587, finearea: '1150022 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577588, finearea: '1150023 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577589, finearea: '1150024 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577590, finearea: '1150025 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577591, finearea: '1150026 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577592, finearea: '1150027 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577593, finearea: '1150028 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577594, finearea: '1150029 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577595, finearea: '1150030 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577596, finearea: '1150031 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577597, finearea: '1150032 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577598, finearea: '1150033 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577599, finearea: '1150034 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577600, finearea: '1150035 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577601, finearea: '1150036 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577602, finearea: '1150037 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577603, finearea: '1150038 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577604, finearea: '1150039 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577605, finearea: '1150040 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577606, finearea: '1150041 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577607, finearea: '1150042 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577608, finearea: '1150043 _ Ryde (C)', subregion: 'Macquarie Park - Marsfield', region: 'Northern Sydney' },
  { geography: 577609, finearea: '1150101 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577610, finearea: '1150102 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577611, finearea: '1150103 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577612, finearea: '1150104 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577613, finearea: '1150105 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577614, finearea: '1150106 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577615, finearea: '1150107 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577616, finearea: '1150108 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577617, finearea: '1150109 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577618, finearea: '1150110 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577619, finearea: '1150111 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577620, finearea: '1150112 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577621, finearea: '1150113 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577622, finearea: '1150114 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577623, finearea: '1150115 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577624, finearea: '1150116 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577625, finearea: '1150117 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577626, finearea: '1150118 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577627, finearea: '1150119 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577628, finearea: '1150120 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577629, finearea: '1150121 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577630, finearea: '1150122 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577631, finearea: '1150123 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577632, finearea: '1150124 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577633, finearea: '1150125 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577634, finearea: '1150126 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577635, finearea: '1150127 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577636, finearea: '1150128 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577637, finearea: '1150129 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577638, finearea: '1150130 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577639, finearea: '1150131 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577640, finearea: '1150132 _ Ryde (C)', subregion: 'North Ryde - East Ryde', region: 'Northern Sydney' },
  { geography: 577641, finearea: '1150301 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577642, finearea: '1150302 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577643, finearea: '1150303 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577644, finearea: '1150304 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577645, finearea: '1150305 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577646, finearea: '1150306 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577647, finearea: '1150308 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577648, finearea: '1150309 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577649, finearea: '1150310 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577650, finearea: '1150311 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577651, finearea: '1150312 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577652, finearea: '1150313 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577653, finearea: '1150314 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577654, finearea: '1150315 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577655, finearea: '1150316 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577656, finearea: '1150317 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577657, finearea: '1150318 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577658, finearea: '1150320 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577659, finearea: '1150321 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577660, finearea: '1150322 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577661, finearea: '1150323 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577662, finearea: '1150324 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577663, finearea: '1150325 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577664, finearea: '1150326 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577665, finearea: '1150327 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577666, finearea: '1150328 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577667, finearea: '1150329 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577668, finearea: '1150330 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577669, finearea: '1150331 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577670, finearea: '1150333 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577671, finearea: '1150334 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577672, finearea: '1150335 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577673, finearea: '1150336 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577674, finearea: '1150337 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577675, finearea: '1150338 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577676, finearea: '1150339 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577677, finearea: '1150340 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577678, finearea: '1150341 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577679, finearea: '1150342 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577680, finearea: '1150343 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577681, finearea: '1150344 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577682, finearea: '1150345 _ Ryde (C)', subregion: 'West Ryde - Meadowbank', region: 'Northern Sydney' },
  { geography: 577683, finearea: '1159001 _ Ryde (C)', subregion: 'Putney', region: 'Northern Sydney' },
  { geography: 577684, finearea: '1159002 _ Ryde (C)', subregion: 'Putney', region: 'Northern Sydney' },
  { geography: 577685, finearea: '1159003 _ Ryde (C)', subregion: 'Putney', region: 'Northern Sydney' },
  { geography: 577686, finearea: '1159004 _ Ryde (C)', subregion: 'Putney', region: 'Northern Sydney' },
  { geography: 577687, finearea: '1159005 _ Ryde (C)', subregion: 'Putney', region: 'Northern Sydney' },
  { geography: 577688, finearea: '1159006 _ Ryde (C)', subregion: 'Putney', region: 'Northern Sydney' },
  { geography: 577689, finearea: '1159007 _ Ryde (C)', subregion: 'Putney', region: 'Northern Sydney' },
  { geography: 577690, finearea: '1159008 _ Ryde (C)', subregion: 'Putney', region: 'Northern Sydney' },
  { geography: 577691, finearea: '1159101 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577692, finearea: '1159102 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577693, finearea: '1159103 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577694, finearea: '1159104 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577695, finearea: '1159105 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577696, finearea: '1159106 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577697, finearea: '1159107 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577698, finearea: '1159108 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577699, finearea: '1159109 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577700, finearea: '1159110 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577701, finearea: '1159111 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577702, finearea: '1159112 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577703, finearea: '1159113 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577704, finearea: '1159114 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577705, finearea: '1159115 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577706, finearea: '1159116 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577707, finearea: '1159117 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577708, finearea: '1159118 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577709, finearea: '1159119 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577710, finearea: '1159120 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577711, finearea: '1159121 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577712, finearea: '1159122 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577713, finearea: '1159123 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577714, finearea: '1159124 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577715, finearea: '1159125 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577716, finearea: '1159126 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577717, finearea: '1159127 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577718, finearea: '1159128 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577719, finearea: '1159129 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577720, finearea: '1159130 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577721, finearea: '1159131 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577722, finearea: '1159132 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577723, finearea: '1159133 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577724, finearea: '1159134 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577725, finearea: '1159135 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577726, finearea: '1159136 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 577727, finearea: '1159137 _ Ryde (C)', subregion: 'Ryde', region: 'Northern Sydney' },
  { geography: 579728, finearea: '1120319 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579729, finearea: '1120320 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579730, finearea: '1120321 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579731, finearea: '1120322 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579732, finearea: '1120323 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579733, finearea: '1120324 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579734, finearea: '1120326 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579735, finearea: '1120327 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579736, finearea: '1120328 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579737, finearea: '1120329 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579738, finearea: '1120330 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579739, finearea: '1120331 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579740, finearea: '1120332 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579741, finearea: '1120333 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579742, finearea: '1120334 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579743, finearea: '1120335 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579744, finearea: '1120336 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579745, finearea: '1120337 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579746, finearea: '1120338 _ Tamworth Regional (A)', subregion: 'Tamworth - North', region: 'Regional NSW - Remainder' },
  { geography: 579747, finearea: '1120401 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579748, finearea: '1120402 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579749, finearea: '1120403 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579750, finearea: '1120404 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579751, finearea: '1120405 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579752, finearea: '1120406 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579753, finearea: '1120408 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579754, finearea: '1120409 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579755, finearea: '1120410 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579756, finearea: '1120411 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579757, finearea: '1120412 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579758, finearea: '1120413 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579759, finearea: '1120414 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579760, finearea: '1120415 _ Tamworth Regional (A)', subregion: 'Tamworth - West', region: 'Regional NSW - Remainder' },
  { geography: 579761, finearea: '1120501 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579762, finearea: '1120502 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579763, finearea: '1120503 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579764, finearea: '1120504 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579765, finearea: '1120505 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579766, finearea: '1120506 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579767, finearea: '1120507 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579768, finearea: '1120508 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579769, finearea: '1120509 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579770, finearea: '1120510 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579771, finearea: '1120511 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579772, finearea: '1120513 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579773, finearea: '1120514 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579774, finearea: '1120515 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579775, finearea: '1120516 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579776, finearea: '1120517 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579777, finearea: '1120518 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579778, finearea: '1120519 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579779, finearea: '1120520 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579780, finearea: '1120521 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579781, finearea: '1120522 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579782, finearea: '1120523 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579783, finearea: '1120524 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579784, finearea: '1120525 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579785, finearea: '1120526 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579786, finearea: '1120527 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579787, finearea: '1120528 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579788, finearea: '1120529 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579789, finearea: '1120530 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579790, finearea: '1120531 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579791, finearea: '1120532 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579792, finearea: '1120533 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579793, finearea: '1120534 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579794, finearea: '1120535 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579795, finearea: '1120536 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579796, finearea: '1120537 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579797, finearea: '1120538 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579798, finearea: '1120539 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579799, finearea: '1120540 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579800, finearea: '1120541 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579801, finearea: '1120542 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579802, finearea: '1120543 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579803, finearea: '1120544 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579804, finearea: '1120545 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579805, finearea: '1120546 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579806, finearea: '1120547 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579807, finearea: '1120548 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579808, finearea: '1120549 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579809, finearea: '1120550 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579810, finearea: '1120551 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579811, finearea: '1120552 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579812, finearea: '1120553 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579813, finearea: '1120554 _ Tamworth Regional (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 579814, finearea: '1126310 _ Temora (A)', subregion: 'Cootamundra', region: 'Riverina-Goulburn Belt' },
  { geography: 579815, finearea: '1126601 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579816, finearea: '1126602 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579817, finearea: '1126603 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579818, finearea: '1126604 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579819, finearea: '1126605 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579820, finearea: '1126606 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579821, finearea: '1126607 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579822, finearea: '1126608 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579823, finearea: '1126609 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579824, finearea: '1126610 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579825, finearea: '1126611 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579826, finearea: '1126612 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579827, finearea: '1126613 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579828, finearea: '1126614 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579829, finearea: '1126615 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579830, finearea: '1126616 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579831, finearea: '1126617 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579832, finearea: '1126618 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579833, finearea: '1126619 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579834, finearea: '1126622 _ Temora (A)', subregion: 'Temora', region: 'Riverina-Goulburn Belt' },
  { geography: 579835, finearea: '1119401 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579836, finearea: '1119402 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579837, finearea: '1119403 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579838, finearea: '1119404 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579839, finearea: '1119405 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579840, finearea: '1119406 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579841, finearea: '1119407 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579842, finearea: '1119408 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579843, finearea: '1119409 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579844, finearea: '1119410 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579845, finearea: '1119411 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579846, finearea: '1119412 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579847, finearea: '1119413 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579848, finearea: '1119414 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579849, finearea: '1119415 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579850, finearea: '1119416 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579851, finearea: '1119417 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579852, finearea: '1119418 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579853, finearea: '1119419 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579854, finearea: '1119420 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579855, finearea: '1119421 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579856, finearea: '1119422 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579857, finearea: '1119423 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579858, finearea: '1119424 _ Tenterfield (A)', subregion: 'Tenterfield', region: 'North East NSW' },
  { geography: 579859, finearea: '1129001 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579860, finearea: '1129002 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579861, finearea: '1129003 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579862, finearea: '1129004 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579863, finearea: '1129005 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579864, finearea: '1129006 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579865, finearea: '1129007 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579866, finearea: '1129008 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579867, finearea: '1129009 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579868, finearea: '1129010 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579869, finearea: '1129011 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579870, finearea: '1129012 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579871, finearea: '1129013 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579872, finearea: '1129014 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579873, finearea: '1129015 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579874, finearea: '1129016 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579875, finearea: '1129017 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579876, finearea: '1129018 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579877, finearea: '1129019 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579878, finearea: '1129020 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579879, finearea: '1129021 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579880, finearea: '1129022 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579881, finearea: '1129023 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579882, finearea: '1129024 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579883, finearea: '1129025 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579884, finearea: '1129026 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579885, finearea: '1129027 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579886, finearea: '1129028 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579887, finearea: '1129029 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579888, finearea: '1129030 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579889, finearea: '1129031 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579890, finearea: '1129032 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579891, finearea: '1129033 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579892, finearea: '1129034 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579893, finearea: '1129035 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579894, finearea: '1129036 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579895, finearea: '1129037 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579896, finearea: '1129038 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579897, finearea: '1129039 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579898, finearea: '1129040 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579899, finearea: '1129041 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579900, finearea: '1129042 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579901, finearea: '1129043 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579902, finearea: '1129044 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579903, finearea: '1129045 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579904, finearea: '1129046 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579905, finearea: '1129047 _ The Hills Shire (A)', subregion: 'Baulkham Hills (East)', region: 'Northern Sydney' },
  { geography: 579906, finearea: '1129101 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579907, finearea: '1129102 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579908, finearea: '1129103 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579909, finearea: '1129104 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579910, finearea: '1129105 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579911, finearea: '1129106 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579912, finearea: '1129107 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579913, finearea: '1129108 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579914, finearea: '1129109 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579915, finearea: '1129110 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579916, finearea: '1129111 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579917, finearea: '1129112 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579918, finearea: '1129113 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579919, finearea: '1129114 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579920, finearea: '1129115 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579921, finearea: '1129116 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579922, finearea: '1129117 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579923, finearea: '1129118 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579924, finearea: '1129119 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579925, finearea: '1129120 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579926, finearea: '1129121 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 579927, finearea: '1129122 _ The Hills Shire (A)', subregion: 'Baulkham Hills (West) - Bella Vista', region: 'Northern Sydney' },
  { geography: 569037, finearea: '1137407 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569038, finearea: '1137408 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569039, finearea: '1137409 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569040, finearea: '1137410 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569041, finearea: '1137411 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569042, finearea: '1137412 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569043, finearea: '1137413 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569044, finearea: '1137414 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569045, finearea: '1137415 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569046, finearea: '1137416 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569047, finearea: '1137417 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569048, finearea: '1137418 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569049, finearea: '1137419 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569050, finearea: '1137420 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569051, finearea: '1137421 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569052, finearea: '1137422 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569053, finearea: '1137423 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569054, finearea: '1137424 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569055, finearea: '1137425 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569056, finearea: '1137426 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569057, finearea: '1137427 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569058, finearea: '1137428 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569059, finearea: '1137429 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569060, finearea: '1137430 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569061, finearea: '1137431 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569062, finearea: '1137432 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569063, finearea: '1137433 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569064, finearea: '1137434 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569065, finearea: '1137435 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569066, finearea: '1137436 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569067, finearea: '1137437 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569068, finearea: '1137438 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569069, finearea: '1137439 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569070, finearea: '1137440 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569071, finearea: '1137441 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569072, finearea: '1137442 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569073, finearea: '1137443 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569074, finearea: '1137444 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569075, finearea: '1137445 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569076, finearea: '1137446 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569077, finearea: '1137447 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569078, finearea: '1137448 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569079, finearea: '1137449 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569080, finearea: '1137450 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569081, finearea: '1137451 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569082, finearea: '1137452 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569083, finearea: '1137453 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569084, finearea: '1137454 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569085, finearea: '1137455 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569086, finearea: '1137456 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569087, finearea: '1137457 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569088, finearea: '1137458 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569089, finearea: '1137702 _ Georges River (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 569090, finearea: '1137713 _ Georges River (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 569091, finearea: '1137714 _ Georges River (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 569092, finearea: '1137715 _ Georges River (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 569093, finearea: '1137716 _ Georges River (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 569094, finearea: '1137717 _ Georges River (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 569095, finearea: '1137718 _ Georges River (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 569096, finearea: '1137719 _ Georges River (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 569097, finearea: '1137720 _ Georges River (A)', subregion: 'Kingsgrove (South) - Bardwell Park', region: 'Inner Sydney' },
  { geography: 569098, finearea: '1137816 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569099, finearea: '1137817 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569100, finearea: '1137818 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569101, finearea: '1137819 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569102, finearea: '1137820 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569103, finearea: '1137821 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569104, finearea: '1137822 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569105, finearea: '1137823 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569106, finearea: '1137824 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569107, finearea: '1137825 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569108, finearea: '1137826 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569109, finearea: '1137827 _ Georges River (A)', subregion: 'Kogarah', region: 'Inner Sydney' },
  { geography: 569110, finearea: '1137901 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569111, finearea: '1137902 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569112, finearea: '1137903 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569113, finearea: '1137904 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569114, finearea: '1137905 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569115, finearea: '1137906 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569116, finearea: '1137907 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569117, finearea: '1137908 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569118, finearea: '1137909 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569119, finearea: '1137910 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569120, finearea: '1137911 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569121, finearea: '1137912 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569122, finearea: '1137913 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569123, finearea: '1137914 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569124, finearea: '1137915 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569125, finearea: '1137916 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569126, finearea: '1137917 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569127, finearea: '1137918 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569128, finearea: '1137919 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569129, finearea: '1137920 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569130, finearea: '1137921 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569131, finearea: '1137922 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569132, finearea: '1137923 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569133, finearea: '1137924 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569134, finearea: '1137925 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569135, finearea: '1137926 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569136, finearea: '1137927 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569137, finearea: '1137928 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569138, finearea: '1137929 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569139, finearea: '1137930 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569140, finearea: '1137931 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569141, finearea: '1137932 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569142, finearea: '1137933 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569143, finearea: '1137934 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569144, finearea: '1137935 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569145, finearea: '1137936 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569146, finearea: '1137937 _ Georges River (A)', subregion: 'Kogarah Bay - Carlton - Allawah', region: 'Inner Sydney' },
  { geography: 569147, finearea: '1138209 _ Georges River (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 569148, finearea: '1138210 _ Georges River (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 569149, finearea: '1138211 _ Georges River (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 569150, finearea: '1138220 _ Georges River (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 569151, finearea: '1138221 _ Georges River (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 569152, finearea: '1138233 _ Georges River (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 569153, finearea: '1138235 _ Georges River (A)', subregion: 'Sans Souci - Ramsgate', region: 'Inner Sydney' },
  { geography: 569154, finearea: '1110401 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569155, finearea: '1110402 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569156, finearea: '1110403 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569157, finearea: '1110404 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569158, finearea: '1110405 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569159, finearea: '1110406 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569160, finearea: '1110407 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569161, finearea: '1110408 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569162, finearea: '1110409 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569163, finearea: '1110410 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569164, finearea: '1110411 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569165, finearea: '1110412 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569166, finearea: '1110413 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569167, finearea: '1110414 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569168, finearea: '1110415 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569169, finearea: '1110416 _ Gilgandra (A)', subregion: 'Gilgandra', region: 'Regional NSW - Remainder' },
  { geography: 569170, finearea: '1119001 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569171, finearea: '1119002 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569172, finearea: '1119003 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569173, finearea: '1119004 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569174, finearea: '1119005 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569175, finearea: '1119006 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569176, finearea: '1119007 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569177, finearea: '1119008 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569178, finearea: '1119009 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569179, finearea: '1119010 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569180, finearea: '1119011 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569181, finearea: '1119012 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569182, finearea: '1119013 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569183, finearea: '1119014 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569184, finearea: '1119015 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569185, finearea: '1119016 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569186, finearea: '1119017 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569187, finearea: '1119018 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569188, finearea: '1119019 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569189, finearea: '1119020 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569190, finearea: '1119021 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569191, finearea: '1119022 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569192, finearea: '1119023 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569193, finearea: '1119024 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569194, finearea: '1119025 _ Glen Innes Severn (A)', subregion: 'Glen Innes', region: 'North East NSW' },
  { geography: 569195, finearea: '1100701 _ Goulburn Mulwaree (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 569196, finearea: '1100710 _ Goulburn Mulwaree (A)', subregion: 'Braidwood', region: 'Capital Region and South Coast' },
  { geography: 569197, finearea: '1101118 _ Goulburn Mulwaree (A)', subregion: 'Queanbeyan Region', region: 'Capital Region and South Coast' },
  { geography: 569198, finearea: '1153901 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569199, finearea: '1153902 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569200, finearea: '1153903 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569201, finearea: '1153904 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569202, finearea: '1153905 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569203, finearea: '1153906 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569204, finearea: '1153907 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569205, finearea: '1153908 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569206, finearea: '1153909 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569207, finearea: '1153910 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569208, finearea: '1153911 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569209, finearea: '1153912 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569210, finearea: '1153913 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569211, finearea: '1153914 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569212, finearea: '1153915 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569213, finearea: '1153916 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569214, finearea: '1153917 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569215, finearea: '1153918 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569216, finearea: '1153919 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569217, finearea: '1153920 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569218, finearea: '1153921 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569219, finearea: '1153922 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569220, finearea: '1153923 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569221, finearea: '1153924 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569222, finearea: '1153925 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569223, finearea: '1153926 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569224, finearea: '1153927 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569225, finearea: '1153928 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569226, finearea: '1153929 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569227, finearea: '1153930 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569228, finearea: '1153931 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569229, finearea: '1153932 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569230, finearea: '1153933 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569231, finearea: '1153934 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569232, finearea: '1153935 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569233, finearea: '1153936 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569234, finearea: '1153937 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569235, finearea: '1153938 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569236, finearea: '1153939 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 576528, finearea: '1116506 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576529, finearea: '1116507 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576530, finearea: '1116508 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576531, finearea: '1116509 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576532, finearea: '1116510 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576533, finearea: '1116511 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576534, finearea: '1116512 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576535, finearea: '1116513 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576536, finearea: '1116514 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576537, finearea: '1116515 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576538, finearea: '1116516 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576539, finearea: '1116601 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576540, finearea: '1116602 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576541, finearea: '1116603 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576542, finearea: '1116604 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576543, finearea: '1116605 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576544, finearea: '1116606 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576545, finearea: '1116607 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576546, finearea: '1116608 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576547, finearea: '1116609 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576548, finearea: '1116610 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576549, finearea: '1116611 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576550, finearea: '1116612 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576551, finearea: '1116613 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576552, finearea: '1116614 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576553, finearea: '1116615 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576554, finearea: '1116616 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576555, finearea: '1116617 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576556, finearea: '1116618 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576557, finearea: '1116619 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576558, finearea: '1116620 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576559, finearea: '1116621 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576560, finearea: '1116622 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576561, finearea: '1116623 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576562, finearea: '1116624 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576563, finearea: '1116625 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576564, finearea: '1116626 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576565, finearea: '1116627 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576566, finearea: '1116628 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576567, finearea: '1116629 _ Port Macquarie-Hastings (A)', subregion: 'Wauchope', region: 'Regional NSW - Remainder' },
  { geography: 576568, finearea: '1117022 _ Port Macquarie-Hastings (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 576569, finearea: '1111901 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576570, finearea: '1111902 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576571, finearea: '1111903 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576572, finearea: '1111904 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576573, finearea: '1111906 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576574, finearea: '1111907 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576575, finearea: '1111908 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576576, finearea: '1111909 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576577, finearea: '1111910 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576578, finearea: '1111911 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576579, finearea: '1111912 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576580, finearea: '1111913 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576581, finearea: '1111914 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576582, finearea: '1111915 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576583, finearea: '1111916 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576584, finearea: '1111917 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576585, finearea: '1111918 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576586, finearea: '1111919 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576587, finearea: '1111920 _ Port Stephens (A)', subregion: 'Anna Bay', region: 'Central Coast and Hunter' },
  { geography: 576588, finearea: '1112001 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576589, finearea: '1112002 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576590, finearea: '1112003 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576591, finearea: '1112004 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576592, finearea: '1112005 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576593, finearea: '1112006 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576594, finearea: '1112007 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576595, finearea: '1112008 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576596, finearea: '1112009 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576597, finearea: '1112010 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576598, finearea: '1112011 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576599, finearea: '1112012 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576600, finearea: '1112013 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576601, finearea: '1112014 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576602, finearea: '1112015 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576603, finearea: '1112016 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576604, finearea: '1112017 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576605, finearea: '1112018 _ Port Stephens (A)', subregion: 'Lemon Tree Passage - Tanilba Bay', region: 'Central Coast and Hunter' },
  { geography: 576606, finearea: '1112101 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576607, finearea: '1112102 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576608, finearea: '1112103 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576609, finearea: '1112104 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576610, finearea: '1112105 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576611, finearea: '1112106 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576612, finearea: '1112107 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576613, finearea: '1112108 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576614, finearea: '1112109 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576615, finearea: '1112110 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576616, finearea: '1112111 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576617, finearea: '1112112 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576618, finearea: '1112113 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576619, finearea: '1112114 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576620, finearea: '1112115 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576621, finearea: '1112116 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576622, finearea: '1112117 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576623, finearea: '1112118 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576624, finearea: '1112119 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576625, finearea: '1112120 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576626, finearea: '1112121 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576627, finearea: '1112122 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576628, finearea: '1112123 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576629, finearea: '1112124 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576630, finearea: '1112125 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576631, finearea: '1112126 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576632, finearea: '1112127 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576633, finearea: '1112128 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576634, finearea: '1112129 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576635, finearea: '1112130 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576636, finearea: '1112131 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576637, finearea: '1112133 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576638, finearea: '1112134 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576639, finearea: '1112135 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576640, finearea: '1112136 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576641, finearea: '1112137 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576642, finearea: '1112138 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576643, finearea: '1112139 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576644, finearea: '1112140 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576645, finearea: '1112141 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576646, finearea: '1112142 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576647, finearea: '1112143 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576648, finearea: '1112144 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576649, finearea: '1112145 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576650, finearea: '1112146 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576651, finearea: '1112147 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576652, finearea: '1112148 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576653, finearea: '1112149 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576654, finearea: '1112150 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576655, finearea: '1112151 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576656, finearea: '1112152 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576657, finearea: '1112153 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576658, finearea: '1112154 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576659, finearea: '1112155 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576660, finearea: '1112156 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576661, finearea: '1112157 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576662, finearea: '1112158 _ Port Stephens (A)', subregion: 'Nelson Bay Peninsula', region: 'Central Coast and Hunter' },
  { geography: 576663, finearea: '1112201 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576664, finearea: '1112202 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576665, finearea: '1112203 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576666, finearea: '1112204 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576667, finearea: '1112205 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576668, finearea: '1112206 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576669, finearea: '1112207 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576670, finearea: '1112208 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576671, finearea: '1112209 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576672, finearea: '1112210 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576673, finearea: '1112211 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576674, finearea: '1112212 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576675, finearea: '1112213 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576676, finearea: '1112214 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576677, finearea: '1112215 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576678, finearea: '1112216 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576679, finearea: '1112217 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576680, finearea: '1112218 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576681, finearea: '1112219 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576682, finearea: '1112220 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576683, finearea: '1112221 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576684, finearea: '1112222 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576685, finearea: '1112223 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576686, finearea: '1112224 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576687, finearea: '1112225 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576688, finearea: '1112226 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576689, finearea: '1112227 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576690, finearea: '1112228 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576691, finearea: '1112229 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576692, finearea: '1112230 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576693, finearea: '1112231 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576694, finearea: '1112232 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576695, finearea: '1112233 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576696, finearea: '1112234 _ Port Stephens (A)', subregion: 'Raymond Terrace', region: 'Central Coast and Hunter' },
  { geography: 576697, finearea: '1112301 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576698, finearea: '1112302 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576699, finearea: '1112303 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576700, finearea: '1112304 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576701, finearea: '1112305 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576702, finearea: '1112306 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576703, finearea: '1112307 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576704, finearea: '1112308 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576705, finearea: '1112309 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576706, finearea: '1112310 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576707, finearea: '1112311 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576708, finearea: '1112312 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576709, finearea: '1112313 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576710, finearea: '1112314 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576711, finearea: '1112315 _ Port Stephens (A)', subregion: 'Seaham - Woodville', region: 'Central Coast and Hunter' },
  { geography: 576712, finearea: '1112402 _ Port Stephens (A)', subregion: 'Tea Gardens - Hawks Nest', region: 'Central Coast and Hunter' },
  { geography: 576713, finearea: '1112501 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576714, finearea: '1112502 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576715, finearea: '1112503 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576716, finearea: '1112504 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576717, finearea: '1112505 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576718, finearea: '1112506 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576719, finearea: '1112507 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576720, finearea: '1112508 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576721, finearea: '1112509 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576722, finearea: '1112510 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576723, finearea: '1112511 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576724, finearea: '1112512 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576725, finearea: '1112513 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576726, finearea: '1112514 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 576727, finearea: '1112515 _ Port Stephens (A)', subregion: 'Williamtown - Medowie - Karuah', region: 'Central Coast and Hunter' },
  { geography: 564837, finearea: '1144015 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564838, finearea: '1144016 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564839, finearea: '1144017 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564840, finearea: '1144018 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564841, finearea: '1144019 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564842, finearea: '1144020 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564843, finearea: '1144021 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564844, finearea: '1144022 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564845, finearea: '1144023 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564846, finearea: '1144024 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564847, finearea: '1144025 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564848, finearea: '1144026 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564849, finearea: '1144027 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564850, finearea: '1144028 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564851, finearea: '1144029 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564852, finearea: '1144030 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564853, finearea: '1144031 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564854, finearea: '1144033 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564855, finearea: '1144034 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564856, finearea: '1144035 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564857, finearea: '1144036 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564858, finearea: '1144037 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564859, finearea: '1144038 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564860, finearea: '1144039 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564861, finearea: '1144040 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564862, finearea: '1144043 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564863, finearea: '1144044 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564864, finearea: '1144045 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564865, finearea: '1144046 _ Campbelltown (C) (NSW)', subregion: 'Ingleburn - Denham Court', region: 'South and West Sydney' },
  { geography: 564866, finearea: '1144101 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564867, finearea: '1144102 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564868, finearea: '1144103 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564869, finearea: '1144104 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564870, finearea: '1144105 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564871, finearea: '1144106 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564872, finearea: '1144107 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564873, finearea: '1144108 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564874, finearea: '1144109 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564875, finearea: '1144110 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564876, finearea: '1144111 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564877, finearea: '1144112 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564878, finearea: '1144113 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564879, finearea: '1144114 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564880, finearea: '1144115 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564881, finearea: '1144116 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564882, finearea: '1144117 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564883, finearea: '1144118 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564884, finearea: '1144119 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564885, finearea: '1144120 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564886, finearea: '1144122 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564887, finearea: '1144123 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564888, finearea: '1144124 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564889, finearea: '1144125 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564890, finearea: '1144126 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564891, finearea: '1144127 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564892, finearea: '1144128 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564893, finearea: '1144129 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564894, finearea: '1144130 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564895, finearea: '1144131 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564896, finearea: '1144132 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564897, finearea: '1144133 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564898, finearea: '1144134 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564899, finearea: '1144135 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564900, finearea: '1144136 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564901, finearea: '1144137 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564902, finearea: '1144138 _ Campbelltown (C) (NSW)', subregion: 'Leumeah - Minto Heights', region: 'South and West Sydney' },
  { geography: 564903, finearea: '1144201 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564904, finearea: '1144203 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564905, finearea: '1144204 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564906, finearea: '1144205 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564907, finearea: '1144206 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564908, finearea: '1144207 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564909, finearea: '1144208 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564910, finearea: '1144209 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564911, finearea: '1144210 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564912, finearea: '1144211 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564913, finearea: '1144212 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564914, finearea: '1144213 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564915, finearea: '1144214 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564916, finearea: '1144215 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564917, finearea: '1144216 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564918, finearea: '1144217 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564919, finearea: '1144218 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564920, finearea: '1144219 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564921, finearea: '1144220 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564922, finearea: '1144221 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564923, finearea: '1144222 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564924, finearea: '1144223 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564925, finearea: '1144224 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564926, finearea: '1144225 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564927, finearea: '1144226 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564928, finearea: '1144227 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564929, finearea: '1144228 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564930, finearea: '1144229 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564931, finearea: '1144230 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564932, finearea: '1144231 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564933, finearea: '1144232 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564934, finearea: '1144233 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564935, finearea: '1144234 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564936, finearea: '1144235 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564937, finearea: '1144236 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564938, finearea: '1144237 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564939, finearea: '1144238 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564940, finearea: '1144239 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564941, finearea: '1144240 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564942, finearea: '1144241 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564943, finearea: '1144242 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564944, finearea: '1144243 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564945, finearea: '1144244 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564946, finearea: '1144245 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564947, finearea: '1144246 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564948, finearea: '1144247 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564949, finearea: '1144248 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564950, finearea: '1144249 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564951, finearea: '1144250 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564952, finearea: '1144251 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564953, finearea: '1144252 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564954, finearea: '1144253 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564955, finearea: '1144254 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564956, finearea: '1144255 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564957, finearea: '1144256 _ Campbelltown (C) (NSW)', subregion: 'Macquarie Fields - Glenfield', region: 'South and West Sydney' },
  { geography: 564958, finearea: '1144301 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564959, finearea: '1144302 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564960, finearea: '1144303 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564961, finearea: '1144304 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564962, finearea: '1144305 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564963, finearea: '1144306 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564964, finearea: '1144307 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564965, finearea: '1144308 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564966, finearea: '1144309 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564967, finearea: '1144310 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564968, finearea: '1144311 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564969, finearea: '1144312 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564970, finearea: '1144313 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564971, finearea: '1144314 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564972, finearea: '1144315 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564973, finearea: '1144316 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564974, finearea: '1144317 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564975, finearea: '1144318 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564976, finearea: '1144319 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564977, finearea: '1144320 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564978, finearea: '1144321 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564979, finearea: '1144322 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564980, finearea: '1144323 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564981, finearea: '1144324 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564982, finearea: '1144325 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564983, finearea: '1144326 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564984, finearea: '1144327 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564985, finearea: '1144328 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564986, finearea: '1144329 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564987, finearea: '1144330 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564988, finearea: '1144331 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564989, finearea: '1144332 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564990, finearea: '1144333 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564991, finearea: '1144334 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564992, finearea: '1144335 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564993, finearea: '1144336 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564994, finearea: '1144337 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564995, finearea: '1144338 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564996, finearea: '1144339 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564997, finearea: '1144340 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564998, finearea: '1144341 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 564999, finearea: '1144342 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 565000, finearea: '1144343 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 565001, finearea: '1144344 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 565002, finearea: '1144345 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 565003, finearea: '1144346 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 565004, finearea: '1144347 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 565005, finearea: '1144348 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 565006, finearea: '1144349 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 565007, finearea: '1144350 _ Campbelltown (C) (NSW)', subregion: 'Minto - St Andrews', region: 'South and West Sydney' },
  { geography: 565008, finearea: '1144401 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565009, finearea: '1144402 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565010, finearea: '1144403 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565011, finearea: '1144404 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565012, finearea: '1144405 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565013, finearea: '1144406 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565014, finearea: '1144407 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565015, finearea: '1144408 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565016, finearea: '1144409 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565017, finearea: '1144410 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565018, finearea: '1144411 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565019, finearea: '1144412 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565020, finearea: '1144413 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565021, finearea: '1144414 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565022, finearea: '1144415 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565023, finearea: '1144416 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565024, finearea: '1144417 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565025, finearea: '1144418 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565026, finearea: '1144419 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565027, finearea: '1144420 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565028, finearea: '1144421 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565029, finearea: '1144422 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565030, finearea: '1144423 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565031, finearea: '1144424 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565032, finearea: '1144425 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565033, finearea: '1144426 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565034, finearea: '1144427 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565035, finearea: '1144428 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 565036, finearea: '1144429 _ Campbelltown (C) (NSW)', subregion: 'Rosemeadow - Glen Alpine', region: 'South and West Sydney' },
  { geography: 580328, finearea: '1125401 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580329, finearea: '1125402 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580330, finearea: '1125403 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580331, finearea: '1125404 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580332, finearea: '1125405 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580333, finearea: '1125406 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580334, finearea: '1125407 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580335, finearea: '1125408 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580336, finearea: '1125409 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580337, finearea: '1125410 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580338, finearea: '1125411 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580339, finearea: '1125412 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580340, finearea: '1125413 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580341, finearea: '1125414 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580342, finearea: '1125415 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580343, finearea: '1125416 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580344, finearea: '1125417 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580345, finearea: '1125418 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580346, finearea: '1125419 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580347, finearea: '1125420 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580348, finearea: '1125421 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580349, finearea: '1125422 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580350, finearea: '1125423 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580351, finearea: '1125424 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580352, finearea: '1125425 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580353, finearea: '1125426 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580354, finearea: '1125427 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580355, finearea: '1125428 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580356, finearea: '1125429 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580357, finearea: '1125430 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580358, finearea: '1125431 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580359, finearea: '1125432 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580360, finearea: '1125434 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580361, finearea: '1125436 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580362, finearea: '1125437 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580363, finearea: '1125438 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580364, finearea: '1125439 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580365, finearea: '1125441 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580366, finearea: '1125442 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580367, finearea: '1125443 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580368, finearea: '1125444 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580369, finearea: '1125445 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580370, finearea: '1125446 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580371, finearea: '1125447 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580372, finearea: '1125448 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580373, finearea: '1125449 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580374, finearea: '1125450 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580375, finearea: '1125451 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580376, finearea: '1125452 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580377, finearea: '1125453 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580378, finearea: '1125454 _ Tweed (A)', subregion: 'Tweed Heads', region: 'North East NSW' },
  { geography: 580379, finearea: '1155001 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580380, finearea: '1155002 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580381, finearea: '1155003 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580382, finearea: '1155004 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580383, finearea: '1155005 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580384, finearea: '1155006 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580385, finearea: '1155007 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580386, finearea: '1155008 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580387, finearea: '1155009 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580388, finearea: '1155010 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580389, finearea: '1155011 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580390, finearea: '1155012 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580391, finearea: '1155013 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580392, finearea: '1155014 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580393, finearea: '1155015 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580394, finearea: '1155016 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580395, finearea: '1155017 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580396, finearea: '1155018 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580397, finearea: '1155019 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580398, finearea: '1155020 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580399, finearea: '1155021 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580400, finearea: '1155022 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580401, finearea: '1155023 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580402, finearea: '1155024 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580403, finearea: '1155025 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580404, finearea: '1155026 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580405, finearea: '1155027 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580406, finearea: '1155028 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580407, finearea: '1155029 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580408, finearea: '1155030 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580409, finearea: '1155031 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580410, finearea: '1155032 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580411, finearea: '1155033 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580412, finearea: '1155034 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580413, finearea: '1155035 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580414, finearea: '1155036 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580415, finearea: '1155037 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580416, finearea: '1155038 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580417, finearea: '1155039 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580418, finearea: '1155040 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580419, finearea: '1155041 _ Tweed (A)', subregion: 'Banora Point', region: 'North East NSW' },
  { geography: 580420, finearea: '1155101 _ Tweed (A)', subregion: 'Terranora - North Tumblegum', region: 'North East NSW' },
  { geography: 580421, finearea: '1155102 _ Tweed (A)', subregion: 'Terranora - North Tumblegum', region: 'North East NSW' },
  { geography: 580422, finearea: '1155103 _ Tweed (A)', subregion: 'Terranora - North Tumblegum', region: 'North East NSW' },
  { geography: 580423, finearea: '1155104 _ Tweed (A)', subregion: 'Terranora - North Tumblegum', region: 'North East NSW' },
  { geography: 580424, finearea: '1155105 _ Tweed (A)', subregion: 'Terranora - North Tumblegum', region: 'North East NSW' },
  { geography: 580425, finearea: '1155106 _ Tweed (A)', subregion: 'Terranora - North Tumblegum', region: 'North East NSW' },
  { geography: 580426, finearea: '1155107 _ Tweed (A)', subregion: 'Terranora - North Tumblegum', region: 'North East NSW' },
  { geography: 580427, finearea: '1155108 _ Tweed (A)', subregion: 'Terranora - North Tumblegum', region: 'North East NSW' },
  { geography: 580428, finearea: '1155201 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580429, finearea: '1155202 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580430, finearea: '1155203 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580431, finearea: '1155204 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580432, finearea: '1155205 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580433, finearea: '1155206 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580434, finearea: '1155207 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580435, finearea: '1155208 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580436, finearea: '1155209 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580437, finearea: '1155210 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580438, finearea: '1155211 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580439, finearea: '1155212 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580440, finearea: '1155213 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580441, finearea: '1155214 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580442, finearea: '1155215 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580443, finearea: '1155216 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580444, finearea: '1155217 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580445, finearea: '1155218 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580446, finearea: '1155219 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580447, finearea: '1155220 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580448, finearea: '1155221 _ Tweed (A)', subregion: 'Tweed Heads South', region: 'North East NSW' },
  { geography: 580449, finearea: '1107410 _ Upper Hunter Shire (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 580450, finearea: '1112801 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580451, finearea: '1112802 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580452, finearea: '1112803 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580453, finearea: '1112804 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580454, finearea: '1112805 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580455, finearea: '1112806 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580456, finearea: '1112807 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580457, finearea: '1112808 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580458, finearea: '1112809 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580459, finearea: '1112810 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580460, finearea: '1112811 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580461, finearea: '1112812 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580462, finearea: '1112813 _ Upper Hunter Shire (A)', subregion: 'Scone', region: 'Central Coast and Hunter' },
  { geography: 580463, finearea: '1112901 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580464, finearea: '1112902 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580465, finearea: '1112903 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580466, finearea: '1112904 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580467, finearea: '1112905 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580468, finearea: '1112906 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580469, finearea: '1112907 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580470, finearea: '1112908 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580471, finearea: '1112909 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580472, finearea: '1112910 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580473, finearea: '1112911 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580474, finearea: '1112912 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580475, finearea: '1112913 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580476, finearea: '1112914 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580477, finearea: '1112915 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580478, finearea: '1112916 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580479, finearea: '1112917 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580480, finearea: '1112918 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580481, finearea: '1112919 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580482, finearea: '1112920 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580483, finearea: '1112921 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580484, finearea: '1112922 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580485, finearea: '1112923 _ Upper Hunter Shire (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 580486, finearea: '1154003 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580487, finearea: '1154005 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580488, finearea: '1154006 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580489, finearea: '1154007 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580490, finearea: '1154008 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580491, finearea: '1154009 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580492, finearea: '1154010 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580493, finearea: '1154011 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580494, finearea: '1154013 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580495, finearea: '1154014 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580496, finearea: '1154015 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580497, finearea: '1154017 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580498, finearea: '1154018 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580499, finearea: '1154019 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580500, finearea: '1154021 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580501, finearea: '1154023 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580502, finearea: '1154024 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580503, finearea: '1154026 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580504, finearea: '1154028 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580505, finearea: '1154030 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580506, finearea: '1154031 _ Upper Lachlan Shire (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580507, finearea: '1154207 _ Upper Lachlan Shire (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 580508, finearea: '1154214 _ Upper Lachlan Shire (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 580509, finearea: '1154218 _ Upper Lachlan Shire (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 580510, finearea: '1154220 _ Upper Lachlan Shire (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 580511, finearea: '1154229 _ Upper Lachlan Shire (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 580512, finearea: '1118705 _ Uralla (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 580513, finearea: '1118801 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580514, finearea: '1118802 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580515, finearea: '1118803 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580516, finearea: '1118804 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580517, finearea: '1118805 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580518, finearea: '1118806 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580519, finearea: '1118807 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580520, finearea: '1118808 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580521, finearea: '1118809 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580522, finearea: '1118810 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580523, finearea: '1118811 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580524, finearea: '1118812 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580525, finearea: '1118813 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580526, finearea: '1118814 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580527, finearea: '1118815 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 564237, finearea: '1109703 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564238, finearea: '1109704 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564239, finearea: '1109705 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564240, finearea: '1109706 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564241, finearea: '1109707 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564242, finearea: '1109708 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564243, finearea: '1109709 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564244, finearea: '1109710 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564245, finearea: '1109711 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564246, finearea: '1109712 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564247, finearea: '1109713 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564248, finearea: '1109714 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564249, finearea: '1109715 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564250, finearea: '1109716 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564251, finearea: '1109717 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564252, finearea: '1109718 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564253, finearea: '1109719 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564254, finearea: '1109720 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564255, finearea: '1109721 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564256, finearea: '1109722 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564257, finearea: '1109723 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564258, finearea: '1109724 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564259, finearea: '1109725 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564260, finearea: '1109726 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564261, finearea: '1109727 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564262, finearea: '1109728 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564263, finearea: '1109729 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564264, finearea: '1109730 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564265, finearea: '1109731 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564266, finearea: '1109732 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564267, finearea: '1109733 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564268, finearea: '1109734 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564269, finearea: '1109735 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564270, finearea: '1109736 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564271, finearea: '1109737 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564272, finearea: '1109738 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564273, finearea: '1109739 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564274, finearea: '1109740 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564275, finearea: '1109741 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564276, finearea: '1109742 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564277, finearea: '1109743 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564278, finearea: '1109744 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564279, finearea: '1109745 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564280, finearea: '1109746 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564281, finearea: '1109747 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564282, finearea: '1109748 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564283, finearea: '1109749 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564284, finearea: '1109750 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564285, finearea: '1109751 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564286, finearea: '1109752 _ Broken Hill (C)', subregion: 'Broken Hill', region: 'Regional NSW - Remainder' },
  { geography: 564287, finearea: '1139101 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564288, finearea: '1139102 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564289, finearea: '1139103 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564290, finearea: '1139104 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564291, finearea: '1139105 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564292, finearea: '1139106 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564293, finearea: '1139107 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564294, finearea: '1139108 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564295, finearea: '1139109 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564296, finearea: '1139111 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564297, finearea: '1139112 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564298, finearea: '1139113 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564299, finearea: '1139114 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564300, finearea: '1139115 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564301, finearea: '1139116 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564302, finearea: '1139117 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564303, finearea: '1139118 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564304, finearea: '1139119 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564305, finearea: '1139120 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564306, finearea: '1139121 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564307, finearea: '1139122 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564308, finearea: '1139124 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564309, finearea: '1139125 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564310, finearea: '1139127 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564311, finearea: '1139128 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564312, finearea: '1139130 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564313, finearea: '1139132 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564314, finearea: '1139136 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564315, finearea: '1139137 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564316, finearea: '1139138 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564317, finearea: '1139139 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564318, finearea: '1139140 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564319, finearea: '1139141 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564320, finearea: '1139142 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564321, finearea: '1139143 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564322, finearea: '1139148 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564323, finearea: '1139149 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564324, finearea: '1139150 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564325, finearea: '1139151 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564326, finearea: '1139152 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564327, finearea: '1139153 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564328, finearea: '1139154 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564329, finearea: '1139155 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564330, finearea: '1139156 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564331, finearea: '1139157 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564332, finearea: '1139158 _ Burwood (A)', subregion: 'Burwood - Croydon', region: 'South and West Sydney' },
  { geography: 564333, finearea: '1139301 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564334, finearea: '1139302 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564335, finearea: '1139303 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564336, finearea: '1139305 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564337, finearea: '1139306 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564338, finearea: '1139315 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564339, finearea: '1139316 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564340, finearea: '1139317 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564341, finearea: '1139318 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564342, finearea: '1139319 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564343, finearea: '1139320 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564344, finearea: '1139321 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564345, finearea: '1139322 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564346, finearea: '1139323 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564347, finearea: '1139324 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564348, finearea: '1139325 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564349, finearea: '1139326 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564350, finearea: '1139327 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564351, finearea: '1139328 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564352, finearea: '1139329 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564353, finearea: '1139330 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564354, finearea: '1139331 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564355, finearea: '1139333 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564356, finearea: '1139334 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564357, finearea: '1139340 _ Burwood (A)', subregion: 'Croydon Park - Enfield', region: 'South and West Sydney' },
  { geography: 564358, finearea: '1157502 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564359, finearea: '1157503 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564360, finearea: '1157504 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564361, finearea: '1157506 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564362, finearea: '1157507 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564363, finearea: '1157510 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564364, finearea: '1157513 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564365, finearea: '1157520 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564366, finearea: '1157534 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564367, finearea: '1157537 _ Burwood (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 564368, finearea: '1123801 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564369, finearea: '1123802 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564370, finearea: '1123803 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564371, finearea: '1123804 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564372, finearea: '1123805 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564373, finearea: '1123806 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564374, finearea: '1123807 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564375, finearea: '1123808 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564376, finearea: '1123809 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564377, finearea: '1123810 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564378, finearea: '1123811 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564379, finearea: '1123812 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564380, finearea: '1123813 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564381, finearea: '1123814 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564382, finearea: '1123815 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564383, finearea: '1123816 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564384, finearea: '1123817 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564385, finearea: '1123818 _ Byron (A)', subregion: 'Bangalow', region: 'North East NSW' },
  { geography: 564386, finearea: '1123901 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564387, finearea: '1123902 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564388, finearea: '1123903 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564389, finearea: '1123905 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564390, finearea: '1123906 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564391, finearea: '1123907 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564392, finearea: '1123908 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564393, finearea: '1123909 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564394, finearea: '1123910 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564395, finearea: '1123911 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564396, finearea: '1123912 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564397, finearea: '1123913 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564398, finearea: '1123914 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564399, finearea: '1123915 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564400, finearea: '1123916 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564401, finearea: '1123917 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564402, finearea: '1123918 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564403, finearea: '1123919 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564404, finearea: '1123920 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564405, finearea: '1123921 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564406, finearea: '1123922 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564407, finearea: '1123923 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564408, finearea: '1123924 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564409, finearea: '1123925 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564410, finearea: '1123926 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564411, finearea: '1123927 _ Byron (A)', subregion: 'Brunswick Heads - Ocean Shores', region: 'North East NSW' },
  { geography: 564412, finearea: '1124001 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564413, finearea: '1124002 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564414, finearea: '1124003 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564415, finearea: '1124004 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564416, finearea: '1124005 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564417, finearea: '1124006 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564418, finearea: '1124007 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564419, finearea: '1124008 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564420, finearea: '1124009 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564421, finearea: '1124010 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564422, finearea: '1124011 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564423, finearea: '1124012 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564424, finearea: '1124013 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564425, finearea: '1124014 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564426, finearea: '1124015 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564427, finearea: '1124016 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564428, finearea: '1124017 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564429, finearea: '1124018 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564430, finearea: '1124019 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564431, finearea: '1124020 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564432, finearea: '1124021 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564433, finearea: '1124022 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564434, finearea: '1124023 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564435, finearea: '1124024 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 564436, finearea: '1124025 _ Byron (A)', subregion: 'Byron Bay', region: 'North East NSW' },
  { geography: 581128, finearea: '1140258 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581129, finearea: '1140259 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581130, finearea: '1140260 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581131, finearea: '1140261 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581132, finearea: '1140262 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581133, finearea: '1140263 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581134, finearea: '1140264 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581135, finearea: '1140265 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581136, finearea: '1140266 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581137, finearea: '1140267 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581138, finearea: '1140268 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581139, finearea: '1140269 _ Willoughby (C)', subregion: 'Willoughby - Castle Cove - Northbridge', region: 'Northern Sydney' },
  { geography: 581140, finearea: '1140840 _ Willoughby (C)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 581141, finearea: '1154018 _ Wingecarribee (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 581142, finearea: '1154022 _ Wingecarribee (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 581143, finearea: '1113501 _ Wingecarribee (A)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581144, finearea: '1127206 _ Wingecarribee (A)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 581145, finearea: '1128401 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581146, finearea: '1128402 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581147, finearea: '1128403 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581148, finearea: '1128404 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581149, finearea: '1128405 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581150, finearea: '1128406 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581151, finearea: '1128407 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581152, finearea: '1128408 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581153, finearea: '1128409 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581154, finearea: '1128410 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581155, finearea: '1128411 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581156, finearea: '1128412 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581157, finearea: '1128413 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581158, finearea: '1128414 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581159, finearea: '1128415 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581160, finearea: '1128416 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581161, finearea: '1128417 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581162, finearea: '1128418 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581163, finearea: '1128419 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581164, finearea: '1128420 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581165, finearea: '1128421 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581166, finearea: '1128422 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581167, finearea: '1128423 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581168, finearea: '1128424 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581169, finearea: '1128425 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581170, finearea: '1128426 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581171, finearea: '1128427 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581172, finearea: '1128428 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581173, finearea: '1128430 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581174, finearea: '1128431 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581175, finearea: '1128432 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581176, finearea: '1128433 _ Wingecarribee (A)', subregion: 'Bowral', region: 'Illawarra-Shoalhaven' },
  { geography: 581177, finearea: '1128501 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581178, finearea: '1128502 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581179, finearea: '1128503 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581180, finearea: '1128504 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581181, finearea: '1128505 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581182, finearea: '1128506 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581183, finearea: '1128507 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581184, finearea: '1128508 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581185, finearea: '1128509 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581186, finearea: '1128510 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581187, finearea: '1128511 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581188, finearea: '1128512 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581189, finearea: '1128513 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581190, finearea: '1128514 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581191, finearea: '1128515 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581192, finearea: '1128516 _ Wingecarribee (A)', subregion: 'Hill Top - Colo Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 581193, finearea: '1128601 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581194, finearea: '1128602 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581195, finearea: '1128603 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581196, finearea: '1128604 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581197, finearea: '1128605 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581198, finearea: '1128606 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581199, finearea: '1128608 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581200, finearea: '1128609 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581201, finearea: '1128610 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581202, finearea: '1128611 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581203, finearea: '1128612 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581204, finearea: '1128613 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581205, finearea: '1128615 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581206, finearea: '1128616 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581207, finearea: '1128617 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581208, finearea: '1128618 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581209, finearea: '1128619 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581210, finearea: '1128620 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581211, finearea: '1128621 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581212, finearea: '1128622 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581213, finearea: '1128623 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581214, finearea: '1128624 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581215, finearea: '1128625 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581216, finearea: '1128626 _ Wingecarribee (A)', subregion: 'Mittagong', region: 'Illawarra-Shoalhaven' },
  { geography: 581217, finearea: '1128701 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581218, finearea: '1128702 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581219, finearea: '1128703 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581220, finearea: '1128704 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581221, finearea: '1128705 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581222, finearea: '1128706 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581223, finearea: '1128707 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581224, finearea: '1128708 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581225, finearea: '1128709 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581226, finearea: '1128710 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581227, finearea: '1128711 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581228, finearea: '1128712 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581229, finearea: '1128713 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581230, finearea: '1128714 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581231, finearea: '1128715 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581232, finearea: '1128716 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581233, finearea: '1128717 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581234, finearea: '1128718 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581235, finearea: '1128719 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581236, finearea: '1128720 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581237, finearea: '1128721 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581238, finearea: '1128722 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581239, finearea: '1128723 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581240, finearea: '1128724 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581241, finearea: '1128725 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581242, finearea: '1128726 _ Wingecarribee (A)', subregion: 'Moss Vale - Berrima', region: 'Illawarra-Shoalhaven' },
  { geography: 581243, finearea: '1128801 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581244, finearea: '1128802 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581245, finearea: '1128803 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581246, finearea: '1128804 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581247, finearea: '1128805 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581248, finearea: '1128806 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581249, finearea: '1128807 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581250, finearea: '1128808 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581251, finearea: '1128809 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581252, finearea: '1128810 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581253, finearea: '1128811 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581254, finearea: '1128812 _ Wingecarribee (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 581255, finearea: '1128901 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581256, finearea: '1128902 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581257, finearea: '1128903 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581258, finearea: '1128904 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581259, finearea: '1128905 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581260, finearea: '1128906 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581261, finearea: '1128907 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581262, finearea: '1128908 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581263, finearea: '1128909 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581264, finearea: '1128910 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581265, finearea: '1128911 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581266, finearea: '1128912 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581267, finearea: '1128913 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581268, finearea: '1128914 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581269, finearea: '1128915 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581270, finearea: '1128916 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581271, finearea: '1128917 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581272, finearea: '1128918 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581273, finearea: '1128919 _ Wingecarribee (A)', subregion: 'Southern Highlands', region: 'Illawarra-Shoalhaven' },
  { geography: 581274, finearea: '1144714 _ Wingecarribee (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581275, finearea: '1113501 _ Wollondilly (A)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581276, finearea: '1114716 _ Wollondilly (A)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581277, finearea: '1143305 _ Wollondilly (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 581278, finearea: '1143306 _ Wollondilly (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 581279, finearea: '1143316 _ Wollondilly (A)', subregion: 'Camden - Ellis Lane', region: 'South and West Sydney' },
  { geography: 581280, finearea: '1144501 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581281, finearea: '1144502 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581282, finearea: '1144503 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581283, finearea: '1144504 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581284, finearea: '1144505 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581285, finearea: '1144506 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581286, finearea: '1144507 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581287, finearea: '1144508 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581288, finearea: '1144509 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581289, finearea: '1144510 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581290, finearea: '1144511 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581291, finearea: '1144512 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581292, finearea: '1144513 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581293, finearea: '1144514 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581294, finearea: '1144515 _ Wollondilly (A)', subregion: 'Bargo', region: 'South and West Sydney' },
  { geography: 581295, finearea: '1144601 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581296, finearea: '1144602 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581297, finearea: '1144603 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581298, finearea: '1144604 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581299, finearea: '1144605 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581300, finearea: '1144606 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581301, finearea: '1144607 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581302, finearea: '1144608 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581303, finearea: '1144609 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581304, finearea: '1144610 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581305, finearea: '1144611 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581306, finearea: '1144612 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581307, finearea: '1144613 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581308, finearea: '1144614 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581309, finearea: '1144615 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581310, finearea: '1144616 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581311, finearea: '1144617 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581312, finearea: '1144618 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581313, finearea: '1144619 _ Wollondilly (A)', subregion: 'Douglas Park - Appin', region: 'South and West Sydney' },
  { geography: 581314, finearea: '1144701 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581315, finearea: '1144702 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581316, finearea: '1144703 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581317, finearea: '1144704 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581318, finearea: '1144705 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581319, finearea: '1144706 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581320, finearea: '1144707 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581321, finearea: '1144708 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581322, finearea: '1144709 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581323, finearea: '1144710 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581324, finearea: '1144711 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581325, finearea: '1144712 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581326, finearea: '1144713 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 581327, finearea: '1144714 _ Wollondilly (A)', subregion: 'Picton - Tahmoor - Buxton', region: 'South and West Sydney' },
  { geography: 565637, finearea: '1157210 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565638, finearea: '1157211 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565639, finearea: '1157212 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565640, finearea: '1157213 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565641, finearea: '1157214 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565642, finearea: '1157215 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565643, finearea: '1157216 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565644, finearea: '1157217 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565645, finearea: '1157218 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565646, finearea: '1157219 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565647, finearea: '1157220 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565648, finearea: '1157221 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565649, finearea: '1157222 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565650, finearea: '1157223 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565651, finearea: '1157224 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565652, finearea: '1157225 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565653, finearea: '1157226 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565654, finearea: '1157227 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565655, finearea: '1157228 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565656, finearea: '1157229 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565657, finearea: '1157230 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565658, finearea: '1157231 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565659, finearea: '1157232 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565660, finearea: '1157233 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565661, finearea: '1157234 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565662, finearea: '1157235 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565663, finearea: '1157236 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565664, finearea: '1157237 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565665, finearea: '1157238 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565666, finearea: '1157239 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565667, finearea: '1157240 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565668, finearea: '1157241 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565669, finearea: '1157242 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565670, finearea: '1157243 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565671, finearea: '1157244 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565672, finearea: '1136201 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565673, finearea: '1136202 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565674, finearea: '1136203 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565675, finearea: '1136204 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565676, finearea: '1136205 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565677, finearea: '1136206 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565678, finearea: '1136207 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565679, finearea: '1136208 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565680, finearea: '1136209 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565681, finearea: '1136210 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565682, finearea: '1136211 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565683, finearea: '1136215 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565684, finearea: '1136216 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565685, finearea: '1136217 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565686, finearea: '1136218 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565687, finearea: '1136219 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565688, finearea: '1136220 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565689, finearea: '1136221 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565690, finearea: '1136222 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565691, finearea: '1136223 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565692, finearea: '1136224 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565693, finearea: '1136225 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565694, finearea: '1136226 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565695, finearea: '1136227 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565696, finearea: '1136228 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565697, finearea: '1136229 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565698, finearea: '1136230 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565699, finearea: '1136231 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565700, finearea: '1136232 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565701, finearea: '1136233 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565702, finearea: '1136234 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565703, finearea: '1136235 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565704, finearea: '1136236 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565705, finearea: '1136237 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565706, finearea: '1136238 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565707, finearea: '1136239 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565708, finearea: '1136240 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565709, finearea: '1136241 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565710, finearea: '1136242 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565711, finearea: '1136243 _ Canterbury-Bankstown (A)', subregion: 'Belmore - Belfield', region: 'South and West Sydney' },
  { geography: 565712, finearea: '1136301 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565713, finearea: '1136302 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565714, finearea: '1136303 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565715, finearea: '1136304 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565716, finearea: '1136305 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565717, finearea: '1136306 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565718, finearea: '1136307 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565719, finearea: '1136308 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565720, finearea: '1136309 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565721, finearea: '1136310 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565722, finearea: '1136311 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565723, finearea: '1136312 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565724, finearea: '1136313 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565725, finearea: '1136314 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565726, finearea: '1136315 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565727, finearea: '1136316 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565728, finearea: '1136317 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565729, finearea: '1136318 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565730, finearea: '1136319 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565731, finearea: '1136320 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565732, finearea: '1136321 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565733, finearea: '1136322 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565734, finearea: '1136323 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565735, finearea: '1136324 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565736, finearea: '1136325 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565737, finearea: '1136326 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565738, finearea: '1136327 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565739, finearea: '1136328 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565740, finearea: '1136329 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565741, finearea: '1136330 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565742, finearea: '1136331 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565743, finearea: '1136332 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565744, finearea: '1136333 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565745, finearea: '1136334 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565746, finearea: '1136335 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565747, finearea: '1136336 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565748, finearea: '1136337 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565749, finearea: '1136338 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565750, finearea: '1136339 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565751, finearea: '1136340 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565752, finearea: '1136341 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565753, finearea: '1136342 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565754, finearea: '1136343 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565755, finearea: '1136344 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565756, finearea: '1136345 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565757, finearea: '1136346 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565758, finearea: '1136347 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565759, finearea: '1136348 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565760, finearea: '1136349 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565761, finearea: '1136350 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565762, finearea: '1136351 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565763, finearea: '1136352 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565764, finearea: '1136353 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565765, finearea: '1136354 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565766, finearea: '1136355 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565767, finearea: '1136356 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565768, finearea: '1136357 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565769, finearea: '1136358 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565770, finearea: '1136359 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565771, finearea: '1136360 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565772, finearea: '1136361 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565773, finearea: '1136362 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565774, finearea: '1136363 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565775, finearea: '1136364 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565776, finearea: '1136365 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565777, finearea: '1136366 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565778, finearea: '1136367 _ Canterbury-Bankstown (A)', subregion: 'Canterbury (South) - Campsie', region: 'South and West Sydney' },
  { geography: 565779, finearea: '1136401 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565780, finearea: '1136402 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565781, finearea: '1136403 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565782, finearea: '1136404 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565783, finearea: '1136405 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565784, finearea: '1136406 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565785, finearea: '1136407 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565786, finearea: '1136408 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565787, finearea: '1136409 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565788, finearea: '1136410 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565789, finearea: '1136411 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565790, finearea: '1136412 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565791, finearea: '1136413 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565792, finearea: '1136414 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565793, finearea: '1136415 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565794, finearea: '1136416 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565795, finearea: '1136417 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565796, finearea: '1136418 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565797, finearea: '1136419 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565798, finearea: '1136420 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565799, finearea: '1136421 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565800, finearea: '1136422 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565801, finearea: '1136423 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565802, finearea: '1136424 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565803, finearea: '1136425 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565804, finearea: '1136426 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565805, finearea: '1136427 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565806, finearea: '1136428 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565807, finearea: '1136430 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565808, finearea: '1136431 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565809, finearea: '1136432 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565810, finearea: '1136433 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565811, finearea: '1136434 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565812, finearea: '1136435 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565813, finearea: '1136436 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565814, finearea: '1136437 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565815, finearea: '1136438 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565816, finearea: '1136439 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565817, finearea: '1136440 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565818, finearea: '1136441 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565819, finearea: '1136442 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565820, finearea: '1136443 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565821, finearea: '1136444 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565822, finearea: '1136445 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565823, finearea: '1136446 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565824, finearea: '1136447 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565825, finearea: '1136448 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565826, finearea: '1136449 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565827, finearea: '1136450 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565828, finearea: '1136451 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565829, finearea: '1136452 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565830, finearea: '1136453 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565831, finearea: '1136454 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565832, finearea: '1136455 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565833, finearea: '1136456 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565834, finearea: '1136457 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565835, finearea: '1136458 _ Canterbury-Bankstown (A)', subregion: 'Kingsgrove (North) - Earlwood', region: 'Inner Sydney' },
  { geography: 565836, finearea: '1136601 _ Canterbury-Bankstown (A)', subregion: 'Punchbowl', region: 'South and West Sydney' },
  { geography: 580128, finearea: '1129710 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580129, finearea: '1129711 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580130, finearea: '1129712 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580131, finearea: '1129713 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580132, finearea: '1129714 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580133, finearea: '1129716 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580134, finearea: '1129720 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580135, finearea: '1129721 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580136, finearea: '1129722 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580137, finearea: '1129724 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580138, finearea: '1129725 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580139, finearea: '1129726 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580140, finearea: '1129727 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580141, finearea: '1129728 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580142, finearea: '1129729 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580143, finearea: '1129731 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580144, finearea: '1129733 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580145, finearea: '1129734 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580146, finearea: '1129735 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580147, finearea: '1129738 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580148, finearea: '1129739 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580149, finearea: '1129740 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580150, finearea: '1129741 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580151, finearea: '1129742 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580152, finearea: '1129751 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580153, finearea: '1129752 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580154, finearea: '1129753 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580155, finearea: '1129754 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580156, finearea: '1129755 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580157, finearea: '1129758 _ The Hills Shire (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 580158, finearea: '1130108 _ The Hills Shire (A)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 580159, finearea: '1130201 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580160, finearea: '1130202 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580161, finearea: '1130203 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580162, finearea: '1130205 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580163, finearea: '1130206 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580164, finearea: '1130207 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580165, finearea: '1130208 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580166, finearea: '1130209 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580167, finearea: '1130210 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580168, finearea: '1130211 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580169, finearea: '1130212 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580170, finearea: '1130213 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580171, finearea: '1130214 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580172, finearea: '1130215 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580173, finearea: '1130216 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580174, finearea: '1130217 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580175, finearea: '1130218 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580176, finearea: '1130219 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580177, finearea: '1130220 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580178, finearea: '1130221 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580179, finearea: '1130222 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580180, finearea: '1130223 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580181, finearea: '1130224 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580182, finearea: '1130225 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580183, finearea: '1130226 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580184, finearea: '1130227 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580185, finearea: '1130228 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580186, finearea: '1130229 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580187, finearea: '1130230 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580188, finearea: '1130231 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580189, finearea: '1130232 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580190, finearea: '1130233 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580191, finearea: '1130234 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580192, finearea: '1130235 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580193, finearea: '1130236 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580194, finearea: '1130237 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580195, finearea: '1130238 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580196, finearea: '1130239 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580197, finearea: '1130240 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580198, finearea: '1130241 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580199, finearea: '1130242 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580200, finearea: '1130243 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580201, finearea: '1130244 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580202, finearea: '1130245 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580203, finearea: '1130246 _ The Hills Shire (A)', subregion: 'Rouse Hill - Beaumont Hills', region: 'Northern Sydney' },
  { geography: 580204, finearea: '1130702 _ The Hills Shire (A)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 580205, finearea: '1130703 _ The Hills Shire (A)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 580206, finearea: '1147655 _ The Hills Shire (A)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 580207, finearea: '1149018 _ The Hills Shire (A)', subregion: 'North Rocks', region: 'Northern Sydney' },
  { geography: 580208, finearea: '1149403 _ The Hills Shire (A)', subregion: 'Winston Hills', region: 'Northern Sydney' },
  { geography: 580209, finearea: '1125001 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580210, finearea: '1125003 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580211, finearea: '1125008 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580212, finearea: '1125009 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580213, finearea: '1125010 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580214, finearea: '1125011 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580215, finearea: '1125012 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580216, finearea: '1125013 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580217, finearea: '1125014 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580218, finearea: '1125015 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580219, finearea: '1125016 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580220, finearea: '1125017 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580221, finearea: '1125018 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580222, finearea: '1125019 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580223, finearea: '1125020 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580224, finearea: '1125021 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580225, finearea: '1125022 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580226, finearea: '1125023 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580227, finearea: '1125024 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580228, finearea: '1125025 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580229, finearea: '1125026 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580230, finearea: '1125027 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580231, finearea: '1125028 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580232, finearea: '1125029 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580233, finearea: '1125030 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580234, finearea: '1125031 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580235, finearea: '1125032 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580236, finearea: '1125033 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580237, finearea: '1125034 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580238, finearea: '1125035 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580239, finearea: '1125036 _ Tweed (A)', subregion: 'Kingscliff - Fingal Head', region: 'North East NSW' },
  { geography: 580240, finearea: '1125101 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580241, finearea: '1125102 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580242, finearea: '1125103 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580243, finearea: '1125104 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580244, finearea: '1125105 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580245, finearea: '1125106 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580246, finearea: '1125107 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580247, finearea: '1125108 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580248, finearea: '1125109 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580249, finearea: '1125110 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580250, finearea: '1125111 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580251, finearea: '1125112 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580252, finearea: '1125113 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580253, finearea: '1125114 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580254, finearea: '1125115 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580255, finearea: '1125116 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580256, finearea: '1125117 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580257, finearea: '1125118 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580258, finearea: '1125119 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580259, finearea: '1125120 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580260, finearea: '1125121 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580261, finearea: '1125122 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580262, finearea: '1125123 _ Tweed (A)', subregion: 'Murwillumbah', region: 'North East NSW' },
  { geography: 580263, finearea: '1125201 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580264, finearea: '1125202 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580265, finearea: '1125203 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580266, finearea: '1125204 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580267, finearea: '1125205 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580268, finearea: '1125206 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580269, finearea: '1125207 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580270, finearea: '1125208 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580271, finearea: '1125209 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580272, finearea: '1125210 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580273, finearea: '1125211 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580274, finearea: '1125212 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580275, finearea: '1125213 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580276, finearea: '1125214 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580277, finearea: '1125215 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580278, finearea: '1125216 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580279, finearea: '1125217 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580280, finearea: '1125218 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580281, finearea: '1125219 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580282, finearea: '1125220 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580283, finearea: '1125221 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580284, finearea: '1125222 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580285, finearea: '1125223 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580286, finearea: '1125224 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580287, finearea: '1125225 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580288, finearea: '1125226 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580289, finearea: '1125227 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580290, finearea: '1125228 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580291, finearea: '1125229 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580292, finearea: '1125230 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580293, finearea: '1125231 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580294, finearea: '1125232 _ Tweed (A)', subregion: 'Murwillumbah Region', region: 'North East NSW' },
  { geography: 580295, finearea: '1125301 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580296, finearea: '1125302 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580297, finearea: '1125303 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580298, finearea: '1125304 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580299, finearea: '1125305 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580300, finearea: '1125306 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580301, finearea: '1125307 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580302, finearea: '1125310 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580303, finearea: '1125311 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580304, finearea: '1125312 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580305, finearea: '1125313 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580306, finearea: '1125314 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580307, finearea: '1125315 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580308, finearea: '1125316 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580309, finearea: '1125317 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580310, finearea: '1125319 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580311, finearea: '1125320 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580312, finearea: '1125321 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580313, finearea: '1125322 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580314, finearea: '1125324 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580315, finearea: '1125325 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580316, finearea: '1125326 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580317, finearea: '1125327 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580318, finearea: '1125328 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580319, finearea: '1125330 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580320, finearea: '1125331 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580321, finearea: '1125332 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580322, finearea: '1125333 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580323, finearea: '1125334 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580324, finearea: '1125335 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580325, finearea: '1125336 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580326, finearea: '1125337 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 580327, finearea: '1125338 _ Tweed (A)', subregion: 'Pottsville', region: 'North East NSW' },
  { geography: 581928, finearea: '1114644 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581929, finearea: '1114646 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581930, finearea: '1114647 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581931, finearea: '1114648 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581932, finearea: '1114649 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581933, finearea: '1114650 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581934, finearea: '1114651 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581935, finearea: '1114652 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581936, finearea: '1114653 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581937, finearea: '1114654 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581938, finearea: '1114655 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581939, finearea: '1114656 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581940, finearea: '1114657 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581941, finearea: '1114658 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581942, finearea: '1114659 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581943, finearea: '1114660 _ Wollongong (C)', subregion: 'Figtree - Keiraville', region: 'Illawarra-Shoalhaven' },
  { geography: 581944, finearea: '1114701 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581945, finearea: '1114702 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581946, finearea: '1114703 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581947, finearea: '1114704 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581948, finearea: '1114705 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581949, finearea: '1114706 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581950, finearea: '1114707 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581951, finearea: '1114708 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581952, finearea: '1114709 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581953, finearea: '1114710 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581954, finearea: '1114711 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581955, finearea: '1114712 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581956, finearea: '1114713 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581957, finearea: '1114714 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581958, finearea: '1114715 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581959, finearea: '1114716 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581960, finearea: '1114717 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581961, finearea: '1114718 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581962, finearea: '1114719 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581963, finearea: '1114720 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581964, finearea: '1114721 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581965, finearea: '1114722 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581966, finearea: '1114723 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581967, finearea: '1114724 _ Wollongong (C)', subregion: 'Helensburgh - Illawarra Catchment Reserve', region: 'Illawarra-Shoalhaven' },
  { geography: 581968, finearea: '1114801 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581969, finearea: '1114802 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581970, finearea: '1114803 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581971, finearea: '1114804 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581972, finearea: '1114805 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581973, finearea: '1114806 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581974, finearea: '1114807 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581975, finearea: '1114808 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581976, finearea: '1114809 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581977, finearea: '1114810 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581978, finearea: '1114811 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581979, finearea: '1114812 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581980, finearea: '1114813 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581981, finearea: '1114814 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581982, finearea: '1114815 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581983, finearea: '1114816 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581984, finearea: '1114817 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581985, finearea: '1114818 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581986, finearea: '1114819 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581987, finearea: '1114820 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581988, finearea: '1114821 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581989, finearea: '1114822 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581990, finearea: '1114823 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581991, finearea: '1114824 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581992, finearea: '1114825 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581993, finearea: '1114826 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581994, finearea: '1114827 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581995, finearea: '1114828 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581996, finearea: '1114829 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581997, finearea: '1114830 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581998, finearea: '1114831 _ Wollongong (C)', subregion: 'Thirroul - Austinmer - Coalcliff', region: 'Illawarra-Shoalhaven' },
  { geography: 581999, finearea: '1115001 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582000, finearea: '1115002 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582001, finearea: '1115003 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582002, finearea: '1115004 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582003, finearea: '1115005 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582004, finearea: '1115006 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582005, finearea: '1115007 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582006, finearea: '1115008 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582007, finearea: '1115009 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582008, finearea: '1115010 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582009, finearea: '1115011 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582010, finearea: '1115012 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582011, finearea: '1115013 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582012, finearea: '1115015 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582013, finearea: '1115016 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582014, finearea: '1115017 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582015, finearea: '1115018 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582016, finearea: '1115019 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582017, finearea: '1115020 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582018, finearea: '1115021 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582019, finearea: '1115023 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582020, finearea: '1115024 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582021, finearea: '1115025 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582022, finearea: '1115026 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582023, finearea: '1115028 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582024, finearea: '1115029 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582025, finearea: '1115030 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582026, finearea: '1115031 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582027, finearea: '1115032 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582028, finearea: '1115033 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582029, finearea: '1115034 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582030, finearea: '1115035 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582031, finearea: '1115037 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582032, finearea: '1115038 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582033, finearea: '1115039 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582034, finearea: '1115041 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582035, finearea: '1115042 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582036, finearea: '1115043 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582037, finearea: '1115044 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582038, finearea: '1115045 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582039, finearea: '1115046 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582040, finearea: '1115047 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582041, finearea: '1115048 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582042, finearea: '1115049 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582043, finearea: '1115050 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582044, finearea: '1115051 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582045, finearea: '1115052 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582046, finearea: '1115053 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582047, finearea: '1115054 _ Wollongong (C)', subregion: 'Woonona - Bulli - Russell Vale', region: 'Illawarra-Shoalhaven' },
  { geography: 582048, finearea: '1154801 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582049, finearea: '1154802 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582050, finearea: '1154803 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582051, finearea: '1154804 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582052, finearea: '1154805 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582053, finearea: '1154806 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582054, finearea: '1154807 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582055, finearea: '1154808 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582056, finearea: '1154809 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582057, finearea: '1154810 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582058, finearea: '1154811 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582059, finearea: '1154812 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582060, finearea: '1154813 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582061, finearea: '1154814 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582062, finearea: '1154815 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582063, finearea: '1154816 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582064, finearea: '1154817 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582065, finearea: '1154818 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582066, finearea: '1154819 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582067, finearea: '1154820 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582068, finearea: '1154821 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582069, finearea: '1154822 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582070, finearea: '1154823 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582071, finearea: '1154824 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582072, finearea: '1154825 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582073, finearea: '1154826 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582074, finearea: '1154827 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582075, finearea: '1154828 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582076, finearea: '1154829 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582077, finearea: '1154830 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582078, finearea: '1154831 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582079, finearea: '1154832 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582080, finearea: '1154833 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582081, finearea: '1154834 _ Wollongong (C)', subregion: 'Wollongong - East', region: 'Illawarra-Shoalhaven' },
  { geography: 582082, finearea: '1154901 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582083, finearea: '1154902 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582084, finearea: '1154903 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582085, finearea: '1154904 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582086, finearea: '1154905 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582087, finearea: '1154906 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582088, finearea: '1154907 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582089, finearea: '1154908 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582090, finearea: '1154909 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582091, finearea: '1154910 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582092, finearea: '1154911 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582093, finearea: '1154912 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582094, finearea: '1154913 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582095, finearea: '1154914 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582096, finearea: '1154915 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582097, finearea: '1154916 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582098, finearea: '1154917 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582099, finearea: '1154918 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582100, finearea: '1154919 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582101, finearea: '1154920 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582102, finearea: '1154921 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582103, finearea: '1154922 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582104, finearea: '1154923 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582105, finearea: '1154924 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582106, finearea: '1154925 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582107, finearea: '1154926 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582108, finearea: '1154927 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582109, finearea: '1154928 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582110, finearea: '1154929 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582111, finearea: '1154930 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582112, finearea: '1154931 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582113, finearea: '1154932 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582114, finearea: '1154933 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582115, finearea: '1154934 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582116, finearea: '1154935 _ Wollongong (C)', subregion: 'Wollongong - West', region: 'Illawarra-Shoalhaven' },
  { geography: 582117, finearea: '1134301 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582118, finearea: '1134302 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582119, finearea: '1134303 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582120, finearea: '1134304 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582121, finearea: '1134305 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582122, finearea: '1134306 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582123, finearea: '1134307 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582124, finearea: '1134308 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582125, finearea: '1134309 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582126, finearea: '1134310 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582127, finearea: '1134311 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582128, finearea: '1134312 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582129, finearea: '1134313 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582130, finearea: '1134314 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582131, finearea: '1134315 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582132, finearea: '1134316 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582133, finearea: '1134317 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582134, finearea: '1134318 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582135, finearea: '1134319 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582136, finearea: '1134320 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582137, finearea: '1134321 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582138, finearea: '1134322 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582139, finearea: '1134323 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582140, finearea: '1134324 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582141, finearea: '1134325 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582142, finearea: '1134326 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582143, finearea: '1134327 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582144, finearea: '1134328 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582145, finearea: '1134329 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582146, finearea: '1134330 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582147, finearea: '1134331 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582148, finearea: '1134332 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582149, finearea: '1134333 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582150, finearea: '1134334 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582151, finearea: '1134335 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582152, finearea: '1134336 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582153, finearea: '1134337 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582154, finearea: '1134338 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582155, finearea: '1134339 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582156, finearea: '1134340 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582157, finearea: '1134341 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582158, finearea: '1134342 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582159, finearea: '1134343 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582160, finearea: '1134344 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582161, finearea: '1134345 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582162, finearea: '1134346 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582163, finearea: '1134347 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582164, finearea: '1134348 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582165, finearea: '1134349 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582166, finearea: '1134350 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582167, finearea: '1134351 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582168, finearea: '1134352 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582169, finearea: '1134353 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582170, finearea: '1134354 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582171, finearea: '1134355 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582172, finearea: '1134356 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582173, finearea: '1134357 _ Woollahra (A)', subregion: 'Double Bay - Bellevue Hill', region: 'Inner Sydney' },
  { geography: 582174, finearea: '1134501 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582175, finearea: '1134502 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582176, finearea: '1134503 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582177, finearea: '1134504 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582178, finearea: '1134505 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582179, finearea: '1134506 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582180, finearea: '1134507 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582181, finearea: '1134508 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582182, finearea: '1134509 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582183, finearea: '1134510 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582184, finearea: '1134511 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582185, finearea: '1134512 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582186, finearea: '1134513 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582187, finearea: '1134516 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582188, finearea: '1134517 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582189, finearea: '1134518 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582190, finearea: '1134519 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582191, finearea: '1134520 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582192, finearea: '1134521 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582193, finearea: '1134530 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582194, finearea: '1134531 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582195, finearea: '1134533 _ Woollahra (A)', subregion: 'Paddington - Moore Park - Centennial Park', region: 'Inner Sydney' },
  { geography: 582196, finearea: '1134601 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582197, finearea: '1134602 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582198, finearea: '1134603 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582199, finearea: '1134604 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582200, finearea: '1134605 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582201, finearea: '1134606 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582202, finearea: '1134607 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582203, finearea: '1134608 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582204, finearea: '1134609 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582205, finearea: '1134610 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582206, finearea: '1134611 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582207, finearea: '1134612 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582208, finearea: '1134613 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582209, finearea: '1134614 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582210, finearea: '1134615 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582211, finearea: '1134616 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582212, finearea: '1134617 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582213, finearea: '1134618 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582214, finearea: '1134619 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582215, finearea: '1134620 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582216, finearea: '1134621 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582217, finearea: '1134622 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582218, finearea: '1134623 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582219, finearea: '1134624 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582220, finearea: '1134625 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582221, finearea: '1134626 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582222, finearea: '1134627 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582223, finearea: '1134628 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582224, finearea: '1134629 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582225, finearea: '1134630 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582226, finearea: '1134631 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582227, finearea: '1134632 _ Woollahra (A)', subregion: 'Rose Bay - Vaucluse - Watsons Bay', region: 'Inner Sydney' },
  { geography: 582228, finearea: '1134701 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582229, finearea: '1134702 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582230, finearea: '1134703 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582231, finearea: '1134704 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582232, finearea: '1134705 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582233, finearea: '1134706 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582234, finearea: '1134707 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582235, finearea: '1134708 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582236, finearea: '1134709 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582237, finearea: '1134710 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582238, finearea: '1134711 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582239, finearea: '1134712 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582240, finearea: '1134713 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582241, finearea: '1134714 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582242, finearea: '1134715 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582243, finearea: '1134716 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582244, finearea: '1134717 _ Woollahra (A)', subregion: 'Woollahra', region: 'Inner Sydney' },
  { geography: 582245, finearea: '1154013 _ Yass Valley (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 582246, finearea: '1154101 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582247, finearea: '1154102 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582248, finearea: '1154103 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582249, finearea: '1154104 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582250, finearea: '1154105 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582251, finearea: '1154106 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582252, finearea: '1154107 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582253, finearea: '1154108 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582254, finearea: '1154109 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582255, finearea: '1154110 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582256, finearea: '1154111 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582257, finearea: '1154112 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582258, finearea: '1154113 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582259, finearea: '1154114 _ Yass Valley (A)', subregion: 'Yass', region: 'Capital Region and South Coast' },
  { geography: 582260, finearea: '1154201 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582261, finearea: '1154202 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582262, finearea: '1154203 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582263, finearea: '1154205 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582264, finearea: '1154208 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582265, finearea: '1154209 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582266, finearea: '1154210 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582267, finearea: '1154211 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582268, finearea: '1154212 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582269, finearea: '1154213 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582270, finearea: '1154214 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582271, finearea: '1154215 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582272, finearea: '1154216 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582273, finearea: '1154217 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582274, finearea: '1154219 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582275, finearea: '1154221 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582276, finearea: '1154222 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582277, finearea: '1154223 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582278, finearea: '1154224 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582279, finearea: '1154225 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582280, finearea: '1154226 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582281, finearea: '1154227 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582282, finearea: '1154228 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582283, finearea: '1154230 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582284, finearea: '1154231 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582285, finearea: '1154232 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582286, finearea: '1154233 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582287, finearea: '1154234 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582288, finearea: '1154235 _ Yass Valley (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 582289, finearea: '1126205 _ Yass Valley (A)', subregion: 'Tumut Region', region: 'Riverina-Goulburn Belt' },
  { geography: 582290, finearea: '1109804 _ Unincorporated NSW', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 582291, finearea: '1109805 _ Unincorporated NSW', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 582292, finearea: '1109806 _ Unincorporated NSW', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 582293, finearea: '1116101 _ Unincorporated NSW', subregion: 'Lord Howe Island', region: 'North East NSW' },
  { geography: 562637, finearea: '1105924 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562638, finearea: '1105925 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562639, finearea: '1105926 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562640, finearea: '1105927 _ Bathurst Regional (A)', subregion: 'Bathurst - East', region: 'Regional NSW - Remainder' },
  { geography: 562641, finearea: '1106001 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562642, finearea: '1106002 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562643, finearea: '1106003 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562644, finearea: '1106004 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562645, finearea: '1106005 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562646, finearea: '1106006 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562647, finearea: '1106007 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562648, finearea: '1106008 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562649, finearea: '1106009 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562650, finearea: '1106010 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562651, finearea: '1106011 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562652, finearea: '1106012 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562653, finearea: '1106013 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562654, finearea: '1106014 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562655, finearea: '1106015 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562656, finearea: '1106016 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562657, finearea: '1106017 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562658, finearea: '1106018 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562659, finearea: '1106019 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562660, finearea: '1106020 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562661, finearea: '1106021 _ Bathurst Regional (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 562662, finearea: '1107116 _ Bathurst Regional (A)', subregion: 'Lithgow Region', region: 'Regional NSW - Remainder' },
  { geography: 562663, finearea: '1107423 _ Bathurst Regional (A)', subregion: 'Mudgee Region - West', region: 'Regional NSW - Remainder' },
  { geography: 562664, finearea: '1132001 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562665, finearea: '1132002 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562666, finearea: '1132003 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562667, finearea: '1132102 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562668, finearea: '1132103 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562669, finearea: '1132104 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562670, finearea: '1132105 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562671, finearea: '1132106 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562672, finearea: '1132108 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562673, finearea: '1132109 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562674, finearea: '1132111 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562675, finearea: '1132112 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562676, finearea: '1132113 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562677, finearea: '1132114 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562678, finearea: '1132115 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562679, finearea: '1132116 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562680, finearea: '1132117 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562681, finearea: '1132118 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562682, finearea: '1132119 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562683, finearea: '1132120 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562684, finearea: '1132121 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562685, finearea: '1132122 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562686, finearea: '1132123 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562687, finearea: '1132124 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562688, finearea: '1132125 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562689, finearea: '1132126 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562690, finearea: '1132127 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562691, finearea: '1132128 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562692, finearea: '1132129 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562693, finearea: '1132130 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562694, finearea: '1132201 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562695, finearea: '1132202 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562696, finearea: '1132203 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562697, finearea: '1132204 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562698, finearea: '1132205 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562699, finearea: '1132206 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562700, finearea: '1132207 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562701, finearea: '1132208 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562702, finearea: '1132209 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562703, finearea: '1132210 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562704, finearea: '1132211 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562705, finearea: '1132212 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562706, finearea: '1132213 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562707, finearea: '1132214 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562708, finearea: '1132215 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562709, finearea: '1132216 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562710, finearea: '1132217 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562711, finearea: '1132218 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562712, finearea: '1132220 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562713, finearea: '1132221 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562714, finearea: '1132222 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562715, finearea: '1132223 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562716, finearea: '1132224 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562717, finearea: '1132225 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562718, finearea: '1132226 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562719, finearea: '1132227 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562720, finearea: '1132228 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562721, finearea: '1132229 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562722, finearea: '1132230 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562723, finearea: '1132231 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562724, finearea: '1132232 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562725, finearea: '1132233 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562726, finearea: '1132234 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562727, finearea: '1132235 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562728, finearea: '1132236 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562729, finearea: '1132237 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562730, finearea: '1132238 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562731, finearea: '1132239 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562732, finearea: '1132240 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562733, finearea: '1132241 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562734, finearea: '1132242 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562735, finearea: '1132243 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562736, finearea: '1132244 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562737, finearea: '1132245 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562738, finearea: '1132246 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562739, finearea: '1132247 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562740, finearea: '1132248 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562741, finearea: '1132249 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562742, finearea: '1132250 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562743, finearea: '1132251 _ Bayside (A)', subregion: 'Mascot - Eastlakes', region: 'Inner Sydney' },
  { geography: 562744, finearea: '1132301 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562745, finearea: '1132302 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562746, finearea: '1132303 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562747, finearea: '1132304 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562748, finearea: '1132305 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562749, finearea: '1132306 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562750, finearea: '1132307 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562751, finearea: '1132308 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562752, finearea: '1132309 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562753, finearea: '1132310 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562754, finearea: '1132311 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562755, finearea: '1132312 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562756, finearea: '1132313 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562757, finearea: '1132314 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562758, finearea: '1132315 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562759, finearea: '1132316 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562760, finearea: '1132317 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562761, finearea: '1132318 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562762, finearea: '1132319 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562763, finearea: '1132320 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562764, finearea: '1132321 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562765, finearea: '1132322 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562766, finearea: '1132323 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562767, finearea: '1132324 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562768, finearea: '1132325 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562769, finearea: '1132326 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562770, finearea: '1132327 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562771, finearea: '1132328 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562772, finearea: '1132329 _ Bayside (A)', subregion: 'Pagewood - Hillsdale - Daceyville', region: 'Inner Sydney' },
  { geography: 562773, finearea: '1132501 _ Bayside (A)', subregion: 'Botany - Airport - Industrial', region: 'Inner Sydney' },
  { geography: 562774, finearea: '1137501 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562775, finearea: '1137502 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562776, finearea: '1137503 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562777, finearea: '1137504 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562778, finearea: '1137505 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562779, finearea: '1137506 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562780, finearea: '1137507 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562781, finearea: '1137508 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562782, finearea: '1137509 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562783, finearea: '1137510 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562784, finearea: '1137511 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562785, finearea: '1137512 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562786, finearea: '1137513 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562787, finearea: '1137514 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562788, finearea: '1137515 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562789, finearea: '1137516 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562790, finearea: '1137517 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562791, finearea: '1137518 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562792, finearea: '1137521 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562793, finearea: '1137522 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562794, finearea: '1137523 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562795, finearea: '1137524 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562796, finearea: '1137525 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562797, finearea: '1137526 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562798, finearea: '1137527 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562799, finearea: '1137528 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562800, finearea: '1137529 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562801, finearea: '1137530 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562802, finearea: '1137531 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562803, finearea: '1137532 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562804, finearea: '1137533 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562805, finearea: '1137534 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562806, finearea: '1137535 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562807, finearea: '1137536 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562808, finearea: '1137537 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562809, finearea: '1137538 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562810, finearea: '1137539 _ Bayside (A)', subregion: 'Arncliffe - Bardwell Valley', region: 'Inner Sydney' },
  { geography: 562811, finearea: '1137601 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562812, finearea: '1137602 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562813, finearea: '1137603 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562814, finearea: '1137604 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562815, finearea: '1137605 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562816, finearea: '1137606 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562817, finearea: '1137607 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562818, finearea: '1137608 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562819, finearea: '1137609 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562820, finearea: '1137610 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562821, finearea: '1137611 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562822, finearea: '1137612 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562823, finearea: '1137613 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562824, finearea: '1137614 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562825, finearea: '1137615 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562826, finearea: '1137616 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562827, finearea: '1137617 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562828, finearea: '1137618 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562829, finearea: '1137619 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562830, finearea: '1137620 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562831, finearea: '1137621 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562832, finearea: '1137622 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562833, finearea: '1137623 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562834, finearea: '1137624 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562835, finearea: '1137625 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 562836, finearea: '1137626 _ Bayside (A)', subregion: 'Bexley', region: 'Inner Sydney' },
  { geography: 565237, finearea: '1138628 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565238, finearea: '1138629 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565239, finearea: '1138630 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565240, finearea: '1138631 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565241, finearea: '1138632 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565242, finearea: '1138633 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565243, finearea: '1138634 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565244, finearea: '1138635 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565245, finearea: '1138636 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565246, finearea: '1138637 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565247, finearea: '1138638 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565248, finearea: '1138639 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565249, finearea: '1138640 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565250, finearea: '1138641 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565251, finearea: '1138642 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565252, finearea: '1138643 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565253, finearea: '1138644 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565254, finearea: '1138645 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565255, finearea: '1138646 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565256, finearea: '1138647 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565257, finearea: '1138648 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565258, finearea: '1138649 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565259, finearea: '1138650 _ Canada Bay (A)', subregion: 'Five Dock - Abbotsford', region: 'South and West Sydney' },
  { geography: 565260, finearea: '1139634 _ Canada Bay (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 565261, finearea: '1139637 _ Canada Bay (A)', subregion: 'Homebush', region: 'South and West Sydney' },
  { geography: 565262, finearea: '1157505 _ Canada Bay (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 565263, finearea: '1157509 _ Canada Bay (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 565264, finearea: '1157519 _ Canada Bay (A)', subregion: 'Strathfield', region: 'South and West Sydney' },
  { geography: 565265, finearea: '1135401 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565266, finearea: '1135402 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565267, finearea: '1135403 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565268, finearea: '1135404 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565269, finearea: '1135405 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565270, finearea: '1135406 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565271, finearea: '1135407 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565272, finearea: '1135408 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565273, finearea: '1135409 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565274, finearea: '1135410 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565275, finearea: '1135411 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565276, finearea: '1135412 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565277, finearea: '1135413 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565278, finearea: '1135414 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565279, finearea: '1135415 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565280, finearea: '1135416 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565281, finearea: '1135417 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565282, finearea: '1135418 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565283, finearea: '1135419 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565284, finearea: '1135420 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565285, finearea: '1135421 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565286, finearea: '1135422 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565287, finearea: '1135423 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565288, finearea: '1135424 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565289, finearea: '1135425 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565290, finearea: '1135426 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565291, finearea: '1135427 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565292, finearea: '1135428 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565293, finearea: '1135429 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565294, finearea: '1135430 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565295, finearea: '1135431 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565296, finearea: '1135432 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565297, finearea: '1135433 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565298, finearea: '1135434 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565299, finearea: '1135435 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565300, finearea: '1135436 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565301, finearea: '1135437 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565302, finearea: '1135438 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565303, finearea: '1135439 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565304, finearea: '1135440 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565305, finearea: '1135441 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565306, finearea: '1135442 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565307, finearea: '1135443 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565308, finearea: '1135444 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565309, finearea: '1135445 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565310, finearea: '1135446 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565311, finearea: '1135447 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565312, finearea: '1135448 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565313, finearea: '1135449 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565314, finearea: '1135450 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565315, finearea: '1135451 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565316, finearea: '1135452 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565317, finearea: '1135453 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565318, finearea: '1135454 _ Canterbury-Bankstown (A)', subregion: 'Bass Hill - Georges Hall', region: 'South and West Sydney' },
  { geography: 565319, finearea: '1135501 _ Canterbury-Bankstown (A)', subregion: 'Chullora', region: 'South and West Sydney' },
  { geography: 565320, finearea: '1135502 _ Canterbury-Bankstown (A)', subregion: 'Chullora', region: 'South and West Sydney' },
  { geography: 565321, finearea: '1135601 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565322, finearea: '1135602 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565323, finearea: '1135603 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565324, finearea: '1135604 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565325, finearea: '1135605 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565326, finearea: '1135606 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565327, finearea: '1135607 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565328, finearea: '1135608 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565329, finearea: '1135609 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565330, finearea: '1135610 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565331, finearea: '1135611 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565332, finearea: '1135612 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565333, finearea: '1135613 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565334, finearea: '1135614 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565335, finearea: '1135615 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565336, finearea: '1135616 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565337, finearea: '1135617 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565338, finearea: '1135618 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565339, finearea: '1135619 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565340, finearea: '1135620 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565341, finearea: '1135621 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565342, finearea: '1135622 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565343, finearea: '1135623 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565344, finearea: '1135624 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565345, finearea: '1135625 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565346, finearea: '1135626 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565347, finearea: '1135627 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565348, finearea: '1135628 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565349, finearea: '1135629 _ Canterbury-Bankstown (A)', subregion: 'Condell Park', region: 'South and West Sydney' },
  { geography: 565350, finearea: '1135701 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565351, finearea: '1135702 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565352, finearea: '1135703 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565353, finearea: '1135706 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565354, finearea: '1135707 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565355, finearea: '1135708 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565356, finearea: '1135709 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565357, finearea: '1135710 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565358, finearea: '1135711 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565359, finearea: '1135712 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565360, finearea: '1135713 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565361, finearea: '1135714 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565362, finearea: '1135715 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565363, finearea: '1135716 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565364, finearea: '1135717 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565365, finearea: '1135718 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565366, finearea: '1135719 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565367, finearea: '1135720 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565368, finearea: '1135721 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565369, finearea: '1135722 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565370, finearea: '1135723 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565371, finearea: '1135724 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565372, finearea: '1135725 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565373, finearea: '1135726 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565374, finearea: '1135727 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565375, finearea: '1135728 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565376, finearea: '1135729 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565377, finearea: '1135730 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565378, finearea: '1135731 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565379, finearea: '1135732 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565380, finearea: '1135733 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565381, finearea: '1135734 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565382, finearea: '1135735 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565383, finearea: '1135736 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565384, finearea: '1135737 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565385, finearea: '1135738 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565386, finearea: '1135739 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565387, finearea: '1135740 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565388, finearea: '1135741 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565389, finearea: '1135742 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565390, finearea: '1135743 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565391, finearea: '1135744 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565392, finearea: '1135745 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565393, finearea: '1135746 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565394, finearea: '1135747 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565395, finearea: '1135750 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565396, finearea: '1135751 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565397, finearea: '1135752 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565398, finearea: '1135753 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565399, finearea: '1135754 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565400, finearea: '1135755 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565401, finearea: '1135756 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565402, finearea: '1135757 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565403, finearea: '1135758 _ Canterbury-Bankstown (A)', subregion: 'Greenacre - Mount Lewis', region: 'South and West Sydney' },
  { geography: 565404, finearea: '1135801 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565405, finearea: '1135802 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565406, finearea: '1135803 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565407, finearea: '1135804 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565408, finearea: '1135805 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565409, finearea: '1135806 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565410, finearea: '1135807 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565411, finearea: '1135808 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565412, finearea: '1135809 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565413, finearea: '1135810 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565414, finearea: '1135811 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565415, finearea: '1135812 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565416, finearea: '1135813 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565417, finearea: '1135814 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565418, finearea: '1135815 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565419, finearea: '1135816 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565420, finearea: '1135817 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565421, finearea: '1135818 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565422, finearea: '1135819 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565423, finearea: '1135820 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565424, finearea: '1135821 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565425, finearea: '1135822 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565426, finearea: '1135823 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565427, finearea: '1135824 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565428, finearea: '1135825 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565429, finearea: '1135826 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565430, finearea: '1135827 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565431, finearea: '1135828 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565432, finearea: '1135829 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565433, finearea: '1135830 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565434, finearea: '1135831 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565435, finearea: '1135832 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565436, finearea: '1135833 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 572237, finearea: '1159834 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572238, finearea: '1159835 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572239, finearea: '1159836 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572240, finearea: '1159837 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572241, finearea: '1159838 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572242, finearea: '1159839 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572243, finearea: '1159840 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572244, finearea: '1159841 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572245, finearea: '1159842 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572246, finearea: '1159843 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572247, finearea: '1159844 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572248, finearea: '1159845 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572249, finearea: '1159846 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572250, finearea: '1159847 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572251, finearea: '1159848 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572252, finearea: '1159849 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572253, finearea: '1159850 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572254, finearea: '1159851 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572255, finearea: '1159852 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572256, finearea: '1159853 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572257, finearea: '1159854 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572258, finearea: '1159855 _ Liverpool (C)', subregion: 'Liverpool', region: 'South and West Sydney' },
  { geography: 572259, finearea: '1159901 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572260, finearea: '1159902 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572261, finearea: '1159903 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572262, finearea: '1159904 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572263, finearea: '1159905 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572264, finearea: '1159906 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572265, finearea: '1159907 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572266, finearea: '1159908 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572267, finearea: '1159909 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572268, finearea: '1159910 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572269, finearea: '1159911 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572270, finearea: '1159912 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572271, finearea: '1159913 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572272, finearea: '1159914 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572273, finearea: '1159915 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572274, finearea: '1159916 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572275, finearea: '1159917 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572276, finearea: '1159918 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572277, finearea: '1159919 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572278, finearea: '1159920 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572279, finearea: '1159921 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572280, finearea: '1159922 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572281, finearea: '1159923 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572282, finearea: '1159924 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572283, finearea: '1159925 _ Liverpool (C)', subregion: 'Lurnea - Cartwright', region: 'South and West Sydney' },
  { geography: 572284, finearea: '1160001 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572285, finearea: '1160002 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572286, finearea: '1160003 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572287, finearea: '1160004 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572288, finearea: '1160005 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572289, finearea: '1160006 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572290, finearea: '1160007 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572291, finearea: '1160008 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572292, finearea: '1160009 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572293, finearea: '1160010 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572294, finearea: '1160011 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572295, finearea: '1160012 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572296, finearea: '1160013 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572297, finearea: '1160014 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572298, finearea: '1160015 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572299, finearea: '1160016 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572300, finearea: '1160017 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572301, finearea: '1160018 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572302, finearea: '1160019 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572303, finearea: '1160020 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572304, finearea: '1160021 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572305, finearea: '1160022 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572306, finearea: '1160023 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572307, finearea: '1160024 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572308, finearea: '1160025 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572309, finearea: '1160026 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572310, finearea: '1160027 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572311, finearea: '1160028 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572312, finearea: '1160029 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572313, finearea: '1160030 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572314, finearea: '1160031 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572315, finearea: '1160032 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572316, finearea: '1160033 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572317, finearea: '1160034 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572318, finearea: '1160035 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572319, finearea: '1160036 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572320, finearea: '1160037 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572321, finearea: '1160038 _ Liverpool (C)', subregion: 'Prestons - Edmondson Park', region: 'South and West Sydney' },
  { geography: 572322, finearea: '1160101 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572323, finearea: '1160102 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572324, finearea: '1160103 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572325, finearea: '1160104 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572326, finearea: '1160105 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572327, finearea: '1160106 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572328, finearea: '1160107 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572329, finearea: '1160108 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572330, finearea: '1160109 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572331, finearea: '1160110 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572332, finearea: '1160111 _ Liverpool (C)', subregion: 'Warwick Farm', region: 'South and West Sydney' },
  { geography: 572333, finearea: '1112908 _ Liverpool Plains (A)', subregion: 'Scone Region', region: 'Central Coast and Hunter' },
  { geography: 572334, finearea: '1120101 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572335, finearea: '1120102 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572336, finearea: '1120103 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572337, finearea: '1120104 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572338, finearea: '1120105 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572339, finearea: '1120106 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572340, finearea: '1120107 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572341, finearea: '1120108 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572342, finearea: '1120109 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572343, finearea: '1120110 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572344, finearea: '1120111 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572345, finearea: '1120112 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572346, finearea: '1120113 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572347, finearea: '1120114 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572348, finearea: '1120115 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572349, finearea: '1120116 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572350, finearea: '1120117 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572351, finearea: '1120118 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572352, finearea: '1120119 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572353, finearea: '1120120 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572354, finearea: '1120121 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572355, finearea: '1120122 _ Liverpool Plains (A)', subregion: 'Quirindi', region: 'Regional NSW - Remainder' },
  { geography: 572356, finearea: '1117520 _ Lockhart (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 572357, finearea: '1118111 _ Lockhart (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 572358, finearea: '1127102 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572359, finearea: '1127103 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572360, finearea: '1127106 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572361, finearea: '1127107 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572362, finearea: '1127108 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572363, finearea: '1127109 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572364, finearea: '1127111 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572365, finearea: '1127113 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572366, finearea: '1127114 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572367, finearea: '1127116 _ Lockhart (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 572368, finearea: '1110703 _ Maitland (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 572369, finearea: '1110706 _ Maitland (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 572370, finearea: '1111145 _ Maitland (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 572371, finearea: '1111401 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572372, finearea: '1111402 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572373, finearea: '1111403 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572374, finearea: '1111404 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572375, finearea: '1111405 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572376, finearea: '1111406 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572377, finearea: '1111408 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572378, finearea: '1111409 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572379, finearea: '1111410 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572380, finearea: '1111411 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572381, finearea: '1111412 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572382, finearea: '1111413 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572383, finearea: '1111414 _ Maitland (C)', subregion: 'Maitland', region: 'Central Coast and Hunter' },
  { geography: 572384, finearea: '1111501 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572385, finearea: '1111502 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572386, finearea: '1111503 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572387, finearea: '1111504 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572388, finearea: '1111505 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572389, finearea: '1111506 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572390, finearea: '1111507 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572391, finearea: '1111508 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572392, finearea: '1111509 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572393, finearea: '1111510 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572394, finearea: '1111511 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572395, finearea: '1111512 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572396, finearea: '1111513 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572397, finearea: '1111514 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572398, finearea: '1111515 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572399, finearea: '1111516 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572400, finearea: '1111517 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572401, finearea: '1111518 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572402, finearea: '1111519 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572403, finearea: '1111520 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572404, finearea: '1111521 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572405, finearea: '1111524 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572406, finearea: '1111525 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572407, finearea: '1111526 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572408, finearea: '1111527 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572409, finearea: '1111528 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572410, finearea: '1111529 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572411, finearea: '1111530 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572412, finearea: '1111531 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572413, finearea: '1111532 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572414, finearea: '1111533 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572415, finearea: '1111534 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572416, finearea: '1111535 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572417, finearea: '1111536 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572418, finearea: '1111537 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572419, finearea: '1111538 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572420, finearea: '1111539 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572421, finearea: '1111541 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572422, finearea: '1111542 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572423, finearea: '1111543 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572424, finearea: '1111544 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572425, finearea: '1111545 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572426, finearea: '1111546 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572427, finearea: '1111547 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572428, finearea: '1111548 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572429, finearea: '1111549 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572430, finearea: '1111550 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572431, finearea: '1111551 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572432, finearea: '1111552 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572433, finearea: '1111553 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572434, finearea: '1111554 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572435, finearea: '1111555 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 572436, finearea: '1111556 _ Maitland (C)', subregion: 'Maitland - East', region: 'Central Coast and Hunter' },
  { geography: 568237, finearea: '1102107 _ Eurobodalla (A)', subregion: 'Broulee - Tomakin', region: 'Capital Region and South Coast' },
  { geography: 568238, finearea: '1102108 _ Eurobodalla (A)', subregion: 'Broulee - Tomakin', region: 'Capital Region and South Coast' },
  { geography: 568239, finearea: '1102109 _ Eurobodalla (A)', subregion: 'Broulee - Tomakin', region: 'Capital Region and South Coast' },
  { geography: 568240, finearea: '1102201 _ Eurobodalla (A)', subregion: 'Cooma Region - Deua - Wadbilliga', region: 'Capital Region and South Coast' },
  { geography: 568241, finearea: '1102401 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568242, finearea: '1102402 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568243, finearea: '1102403 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568244, finearea: '1102404 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568245, finearea: '1102405 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568246, finearea: '1102406 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568247, finearea: '1102407 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568248, finearea: '1102408 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568249, finearea: '1102409 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568250, finearea: '1102410 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568251, finearea: '1102411 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568252, finearea: '1102412 _ Eurobodalla (A)', subregion: 'Eurobodalla Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568253, finearea: '1102601 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568254, finearea: '1102602 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568255, finearea: '1102603 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568256, finearea: '1102604 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568257, finearea: '1102605 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568258, finearea: '1102606 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568259, finearea: '1102607 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568260, finearea: '1102608 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568261, finearea: '1102609 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568262, finearea: '1102610 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568263, finearea: '1102611 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568264, finearea: '1102612 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568265, finearea: '1102613 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568266, finearea: '1102614 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568267, finearea: '1102615 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568268, finearea: '1102616 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568269, finearea: '1102617 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568270, finearea: '1102618 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568271, finearea: '1102619 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568272, finearea: '1102620 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568273, finearea: '1102621 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568274, finearea: '1102622 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568275, finearea: '1102623 _ Eurobodalla (A)', subregion: 'Moruya - Tuross Head', region: 'Capital Region and South Coast' },
  { geography: 568276, finearea: '1102702 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568277, finearea: '1102703 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568278, finearea: '1102704 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568279, finearea: '1102705 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568280, finearea: '1102706 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568281, finearea: '1102707 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568282, finearea: '1102708 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568283, finearea: '1102709 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568284, finearea: '1102712 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568285, finearea: '1102713 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568286, finearea: '1102714 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568287, finearea: '1102715 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568288, finearea: '1102716 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568289, finearea: '1102721 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568290, finearea: '1102722 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568291, finearea: '1102723 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568292, finearea: '1102724 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568293, finearea: '1102725 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568294, finearea: '1102726 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568295, finearea: '1102727 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568296, finearea: '1102728 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568297, finearea: '1102729 _ Eurobodalla (A)', subregion: 'Narooma - Bermagui', region: 'Capital Region and South Coast' },
  { geography: 568298, finearea: '1148001 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568299, finearea: '1148002 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568300, finearea: '1148003 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568301, finearea: '1148004 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568302, finearea: '1148005 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568303, finearea: '1148006 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568304, finearea: '1148007 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568305, finearea: '1148008 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568306, finearea: '1148009 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568307, finearea: '1148010 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568308, finearea: '1148011 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568309, finearea: '1148012 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568310, finearea: '1148013 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568311, finearea: '1148014 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568312, finearea: '1148015 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568313, finearea: '1148016 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568314, finearea: '1148017 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568315, finearea: '1148018 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568316, finearea: '1148019 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568317, finearea: '1148020 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568318, finearea: '1148021 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568319, finearea: '1148022 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568320, finearea: '1148023 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568321, finearea: '1148024 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568322, finearea: '1148025 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568323, finearea: '1148026 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568324, finearea: '1148027 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568325, finearea: '1148028 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568326, finearea: '1148029 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568327, finearea: '1148030 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568328, finearea: '1148031 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568329, finearea: '1148032 _ Fairfield (C)', subregion: 'Fairfield - East', region: 'South and West Sydney' },
  { geography: 568330, finearea: '1150901 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568331, finearea: '1150902 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568332, finearea: '1150903 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568333, finearea: '1150904 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568334, finearea: '1150905 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568335, finearea: '1150906 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568336, finearea: '1150907 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568337, finearea: '1150908 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568338, finearea: '1150909 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568339, finearea: '1150910 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568340, finearea: '1150911 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568341, finearea: '1150912 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568342, finearea: '1150913 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568343, finearea: '1150914 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568344, finearea: '1150915 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568345, finearea: '1150916 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568346, finearea: '1150917 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568347, finearea: '1150918 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568348, finearea: '1150919 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568349, finearea: '1150920 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568350, finearea: '1150921 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568351, finearea: '1150922 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568352, finearea: '1150923 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568353, finearea: '1150924 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568354, finearea: '1150925 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568355, finearea: '1150926 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568356, finearea: '1150927 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568357, finearea: '1150928 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568358, finearea: '1150929 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568359, finearea: '1150930 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568360, finearea: '1150931 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568361, finearea: '1150932 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568362, finearea: '1150933 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568363, finearea: '1150934 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568364, finearea: '1150935 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568365, finearea: '1150936 _ Fairfield (C)', subregion: 'Bonnyrigg Heights - Bonnyrigg', region: 'South and West Sydney' },
  { geography: 568366, finearea: '1151001 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568367, finearea: '1151002 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568368, finearea: '1151003 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568369, finearea: '1151004 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568370, finearea: '1151005 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568371, finearea: '1151006 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568372, finearea: '1151007 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568373, finearea: '1151008 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568374, finearea: '1151009 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568375, finearea: '1151010 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568376, finearea: '1151011 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568377, finearea: '1151012 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568378, finearea: '1151013 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568379, finearea: '1151014 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568380, finearea: '1151015 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568381, finearea: '1151016 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568382, finearea: '1151017 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568383, finearea: '1151018 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568384, finearea: '1151019 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568385, finearea: '1151020 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568386, finearea: '1151021 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568387, finearea: '1151022 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568388, finearea: '1151023 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568389, finearea: '1151024 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568390, finearea: '1151025 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568391, finearea: '1151026 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568392, finearea: '1151027 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568393, finearea: '1151028 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568394, finearea: '1151029 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568395, finearea: '1151030 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568396, finearea: '1151031 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568397, finearea: '1151032 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568398, finearea: '1151033 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568399, finearea: '1151034 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568400, finearea: '1151035 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568401, finearea: '1151036 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568402, finearea: '1151037 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568403, finearea: '1151038 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568404, finearea: '1151039 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568405, finearea: '1151040 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568406, finearea: '1151041 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568407, finearea: '1151042 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568408, finearea: '1151043 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568409, finearea: '1151044 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568410, finearea: '1151045 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568411, finearea: '1151046 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568412, finearea: '1151047 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568413, finearea: '1151048 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568414, finearea: '1151049 _ Fairfield (C)', subregion: 'Bossley Park - Abbotsbury', region: 'South and West Sydney' },
  { geography: 568415, finearea: '1151101 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568416, finearea: '1151102 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568417, finearea: '1151103 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568418, finearea: '1151104 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568419, finearea: '1151105 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568420, finearea: '1151106 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568421, finearea: '1151107 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568422, finearea: '1151108 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568423, finearea: '1151109 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568424, finearea: '1151110 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568425, finearea: '1151111 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568426, finearea: '1151112 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568427, finearea: '1151113 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568428, finearea: '1151114 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568429, finearea: '1151115 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568430, finearea: '1151116 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568431, finearea: '1151117 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568432, finearea: '1151118 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568433, finearea: '1151119 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568434, finearea: '1151120 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568435, finearea: '1151121 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 568436, finearea: '1151122 _ Fairfield (C)', subregion: 'Cabramatta - Lansvale', region: 'South and West Sydney' },
  { geography: 565437, finearea: '1135834 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565438, finearea: '1135835 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565439, finearea: '1135836 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565440, finearea: '1135837 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565441, finearea: '1135838 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565442, finearea: '1135839 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565443, finearea: '1135840 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565444, finearea: '1135841 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565445, finearea: '1135842 _ Canterbury-Bankstown (A)', subregion: 'Padstow', region: 'South and West Sydney' },
  { geography: 565446, finearea: '1135901 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565447, finearea: '1135902 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565448, finearea: '1135903 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565449, finearea: '1135904 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565450, finearea: '1135905 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565451, finearea: '1135906 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565452, finearea: '1135907 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565453, finearea: '1135908 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565454, finearea: '1135909 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565455, finearea: '1135910 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565456, finearea: '1135911 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565457, finearea: '1135912 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565458, finearea: '1135913 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565459, finearea: '1135914 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565460, finearea: '1135915 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565461, finearea: '1135916 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565462, finearea: '1135917 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565463, finearea: '1135918 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565464, finearea: '1135919 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565465, finearea: '1135920 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565466, finearea: '1135921 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565467, finearea: '1135922 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565468, finearea: '1135923 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565469, finearea: '1135924 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565470, finearea: '1135925 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565471, finearea: '1135926 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565472, finearea: '1135927 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565473, finearea: '1135928 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565474, finearea: '1135929 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565475, finearea: '1135930 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565476, finearea: '1135931 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565477, finearea: '1135932 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565478, finearea: '1135933 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565479, finearea: '1135934 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565480, finearea: '1135935 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565481, finearea: '1135936 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565482, finearea: '1135937 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565483, finearea: '1135938 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565484, finearea: '1135939 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565485, finearea: '1135940 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565486, finearea: '1135941 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565487, finearea: '1135942 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565488, finearea: '1135943 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565489, finearea: '1135944 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565490, finearea: '1135945 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565491, finearea: '1135946 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565492, finearea: '1135947 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565493, finearea: '1135948 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565494, finearea: '1135949 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565495, finearea: '1135950 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565496, finearea: '1135951 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565497, finearea: '1135952 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565498, finearea: '1135953 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565499, finearea: '1135954 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565500, finearea: '1135955 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565501, finearea: '1135956 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565502, finearea: '1135957 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565503, finearea: '1135958 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565504, finearea: '1135959 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565505, finearea: '1135960 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565506, finearea: '1135961 _ Canterbury-Bankstown (A)', subregion: 'Panania - Milperra - Picnic Point', region: 'South and West Sydney' },
  { geography: 565507, finearea: '1136001 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565508, finearea: '1136002 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565509, finearea: '1136003 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565510, finearea: '1136004 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565511, finearea: '1136005 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565512, finearea: '1136006 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565513, finearea: '1136007 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565514, finearea: '1136008 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565515, finearea: '1136009 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565516, finearea: '1136010 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565517, finearea: '1136011 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565518, finearea: '1136012 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565519, finearea: '1136013 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565520, finearea: '1136014 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565521, finearea: '1136015 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565522, finearea: '1136016 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565523, finearea: '1136017 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565524, finearea: '1136018 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565525, finearea: '1136019 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565526, finearea: '1136020 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565527, finearea: '1136021 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565528, finearea: '1136022 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565529, finearea: '1136023 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565530, finearea: '1136024 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565531, finearea: '1136025 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565532, finearea: '1136026 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565533, finearea: '1136027 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565534, finearea: '1136028 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565535, finearea: '1136029 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565536, finearea: '1136030 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565537, finearea: '1136031 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565538, finearea: '1136032 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565539, finearea: '1136033 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565540, finearea: '1136034 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565541, finearea: '1136035 _ Canterbury-Bankstown (A)', subregion: 'Revesby', region: 'South and West Sydney' },
  { geography: 565542, finearea: '1136101 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565543, finearea: '1136102 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565544, finearea: '1136103 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565545, finearea: '1136104 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565546, finearea: '1136105 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565547, finearea: '1136106 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565548, finearea: '1136107 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565549, finearea: '1136108 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565550, finearea: '1136109 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565551, finearea: '1136110 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565552, finearea: '1136111 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565553, finearea: '1136112 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565554, finearea: '1136113 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565555, finearea: '1136114 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565556, finearea: '1136115 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565557, finearea: '1136116 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565558, finearea: '1136117 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565559, finearea: '1136118 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565560, finearea: '1136119 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565561, finearea: '1136120 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565562, finearea: '1136121 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565563, finearea: '1136122 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565564, finearea: '1136123 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565565, finearea: '1136124 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565566, finearea: '1136125 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565567, finearea: '1136126 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565568, finearea: '1136127 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565569, finearea: '1136128 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565570, finearea: '1136129 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565571, finearea: '1136130 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565572, finearea: '1136131 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565573, finearea: '1136132 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565574, finearea: '1136133 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565575, finearea: '1136134 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565576, finearea: '1136135 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565577, finearea: '1136136 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565578, finearea: '1136137 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565579, finearea: '1136138 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565580, finearea: '1136139 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565581, finearea: '1136140 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565582, finearea: '1136141 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565583, finearea: '1136142 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565584, finearea: '1136143 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565585, finearea: '1136144 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565586, finearea: '1136145 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565587, finearea: '1136146 _ Canterbury-Bankstown (A)', subregion: 'Yagoona - Birrong', region: 'South and West Sydney' },
  { geography: 565588, finearea: '1157101 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565589, finearea: '1157102 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565590, finearea: '1157103 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565591, finearea: '1157104 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565592, finearea: '1157105 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565593, finearea: '1157106 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565594, finearea: '1157107 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565595, finearea: '1157108 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565596, finearea: '1157109 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565597, finearea: '1157110 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565598, finearea: '1157111 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565599, finearea: '1157112 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565600, finearea: '1157113 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565601, finearea: '1157114 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565602, finearea: '1157115 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565603, finearea: '1157116 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565604, finearea: '1157117 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565605, finearea: '1157118 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565606, finearea: '1157119 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565607, finearea: '1157120 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565608, finearea: '1157121 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565609, finearea: '1157122 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565610, finearea: '1157123 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565611, finearea: '1157124 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565612, finearea: '1157125 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565613, finearea: '1157126 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565614, finearea: '1157127 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565615, finearea: '1157128 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565616, finearea: '1157129 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565617, finearea: '1157130 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565618, finearea: '1157131 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565619, finearea: '1157132 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565620, finearea: '1157133 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565621, finearea: '1157134 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565622, finearea: '1157135 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565623, finearea: '1157136 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565624, finearea: '1157137 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565625, finearea: '1157138 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565626, finearea: '1157139 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565627, finearea: '1157140 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - North', region: 'South and West Sydney' },
  { geography: 565628, finearea: '1157201 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565629, finearea: '1157202 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565630, finearea: '1157203 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565631, finearea: '1157204 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565632, finearea: '1157205 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565633, finearea: '1157206 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565634, finearea: '1157207 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565635, finearea: '1157208 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 565636, finearea: '1157209 _ Canterbury-Bankstown (A)', subregion: 'Bankstown - South', region: 'South and West Sydney' },
  { geography: 574328, finearea: '1141327 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574329, finearea: '1141328 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574330, finearea: '1141329 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574331, finearea: '1141330 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574332, finearea: '1141331 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574333, finearea: '1141332 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574334, finearea: '1141333 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574335, finearea: '1141334 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574336, finearea: '1141335 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574337, finearea: '1141336 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574338, finearea: '1141337 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574339, finearea: '1141338 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574340, finearea: '1141339 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574341, finearea: '1141340 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574342, finearea: '1141341 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574343, finearea: '1141342 _ North Sydney (A)', subregion: 'Cremorne - Cammeray', region: 'Northern Sydney' },
  { geography: 574344, finearea: '1141401 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574345, finearea: '1141402 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574346, finearea: '1141403 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574347, finearea: '1141404 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574348, finearea: '1141405 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574349, finearea: '1141406 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574350, finearea: '1141407 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574351, finearea: '1141408 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574352, finearea: '1141409 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574353, finearea: '1141410 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574354, finearea: '1141411 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574355, finearea: '1141412 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574356, finearea: '1141413 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574357, finearea: '1141414 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574358, finearea: '1141415 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574359, finearea: '1141416 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574360, finearea: '1141417 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574361, finearea: '1141418 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574362, finearea: '1141419 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574363, finearea: '1141420 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574364, finearea: '1141421 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574365, finearea: '1141422 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574366, finearea: '1141423 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574367, finearea: '1141424 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574368, finearea: '1141425 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574369, finearea: '1141426 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574370, finearea: '1141427 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574371, finearea: '1141428 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574372, finearea: '1141429 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574373, finearea: '1141430 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574374, finearea: '1141431 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574375, finearea: '1141432 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574376, finearea: '1141433 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574377, finearea: '1141434 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574378, finearea: '1141435 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574379, finearea: '1141436 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574380, finearea: '1141437 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574381, finearea: '1141438 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574382, finearea: '1141439 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574383, finearea: '1141440 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574384, finearea: '1141441 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574385, finearea: '1141442 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574386, finearea: '1141443 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574387, finearea: '1141444 _ North Sydney (A)', subregion: 'Crows Nest - Waverton', region: 'Northern Sydney' },
  { geography: 574388, finearea: '1141601 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574389, finearea: '1141602 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574390, finearea: '1141603 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574391, finearea: '1141604 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574392, finearea: '1141605 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574393, finearea: '1141606 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574394, finearea: '1141607 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574395, finearea: '1141608 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574396, finearea: '1141609 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574397, finearea: '1141610 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574398, finearea: '1141611 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574399, finearea: '1141612 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574400, finearea: '1141613 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574401, finearea: '1141614 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574402, finearea: '1141615 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574403, finearea: '1141616 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574404, finearea: '1141617 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574405, finearea: '1141618 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574406, finearea: '1141619 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574407, finearea: '1141620 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574408, finearea: '1141621 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574409, finearea: '1141622 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574410, finearea: '1141623 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574411, finearea: '1141624 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574412, finearea: '1141625 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574413, finearea: '1141626 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574414, finearea: '1141627 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574415, finearea: '1141628 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574416, finearea: '1141629 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574417, finearea: '1141630 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574418, finearea: '1141631 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574419, finearea: '1141632 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574420, finearea: '1141633 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574421, finearea: '1141634 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574422, finearea: '1141635 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574423, finearea: '1141636 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574424, finearea: '1141637 _ North Sydney (A)', subregion: 'Neutral Bay - Kirribilli', region: 'Northern Sydney' },
  { geography: 574425, finearea: '1141701 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574426, finearea: '1141702 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574427, finearea: '1141703 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574428, finearea: '1141704 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574429, finearea: '1141705 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574430, finearea: '1141706 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574431, finearea: '1141707 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574432, finearea: '1141708 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574433, finearea: '1141709 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574434, finearea: '1141710 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574435, finearea: '1141711 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574436, finearea: '1141712 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574437, finearea: '1141713 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574438, finearea: '1141714 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574439, finearea: '1141716 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574440, finearea: '1141717 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574441, finearea: '1141718 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574442, finearea: '1141719 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574443, finearea: '1141721 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574444, finearea: '1141722 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574445, finearea: '1141723 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574446, finearea: '1141724 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574447, finearea: '1141726 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574448, finearea: '1141727 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574449, finearea: '1141728 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574450, finearea: '1141729 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574451, finearea: '1141730 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574452, finearea: '1141731 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574453, finearea: '1141732 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574454, finearea: '1141733 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574455, finearea: '1141734 _ North Sydney (A)', subregion: 'North Sydney - Lavender Bay', region: 'Northern Sydney' },
  { geography: 574456, finearea: '1141801 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574457, finearea: '1141802 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574458, finearea: '1141803 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574459, finearea: '1141804 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574460, finearea: '1141805 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574461, finearea: '1141806 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574462, finearea: '1141808 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574463, finearea: '1141809 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574464, finearea: '1141810 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574465, finearea: '1141811 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574466, finearea: '1141812 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574467, finearea: '1141813 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574468, finearea: '1141814 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574469, finearea: '1141815 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574470, finearea: '1141816 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574471, finearea: '1141817 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574472, finearea: '1141818 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574473, finearea: '1141819 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574474, finearea: '1141820 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574475, finearea: '1141821 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574476, finearea: '1141822 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574477, finearea: '1141823 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574478, finearea: '1141827 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574479, finearea: '1141828 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574480, finearea: '1141829 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574481, finearea: '1141830 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574482, finearea: '1141831 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574483, finearea: '1141832 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574484, finearea: '1141833 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574485, finearea: '1141834 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574486, finearea: '1141835 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574487, finearea: '1141836 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574488, finearea: '1141837 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574489, finearea: '1141838 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574490, finearea: '1141839 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574491, finearea: '1141840 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574492, finearea: '1141841 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574493, finearea: '1141842 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574494, finearea: '1141843 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574495, finearea: '1141844 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574496, finearea: '1141845 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574497, finearea: '1141846 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574498, finearea: '1141847 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574499, finearea: '1141848 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574500, finearea: '1141849 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574501, finearea: '1141850 _ Northern Beaches (A)', subregion: 'Balgowlah - Clontarf - Seaforth', region: 'Northern Sydney' },
  { geography: 574502, finearea: '1141901 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574503, finearea: '1141902 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574504, finearea: '1141903 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574505, finearea: '1141904 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574506, finearea: '1141905 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574507, finearea: '1141906 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574508, finearea: '1141907 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574509, finearea: '1141908 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574510, finearea: '1141909 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574511, finearea: '1141910 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574512, finearea: '1141911 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574513, finearea: '1141912 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574514, finearea: '1141913 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574515, finearea: '1141914 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574516, finearea: '1141915 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574517, finearea: '1141916 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574518, finearea: '1141917 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574519, finearea: '1141918 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574520, finearea: '1141919 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574521, finearea: '1141920 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574522, finearea: '1141921 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574523, finearea: '1141922 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574524, finearea: '1141923 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574525, finearea: '1141924 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574526, finearea: '1141925 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 574527, finearea: '1141926 _ Northern Beaches (A)', subregion: 'Manly - Fairlight', region: 'Northern Sydney' },
  { geography: 568037, finearea: '1110110 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568038, finearea: '1110111 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568039, finearea: '1110112 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568040, finearea: '1110113 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568041, finearea: '1110114 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568042, finearea: '1110115 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568043, finearea: '1110116 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568044, finearea: '1110117 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568045, finearea: '1110118 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568046, finearea: '1110119 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568047, finearea: '1110120 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568048, finearea: '1110121 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568049, finearea: '1110122 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568050, finearea: '1110123 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568051, finearea: '1110124 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568052, finearea: '1110125 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568053, finearea: '1110126 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568054, finearea: '1110127 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568055, finearea: '1110128 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568056, finearea: '1110129 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568057, finearea: '1110130 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568058, finearea: '1110132 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568059, finearea: '1110133 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568060, finearea: '1110134 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568061, finearea: '1110135 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568062, finearea: '1110136 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568063, finearea: '1110137 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568064, finearea: '1110138 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568065, finearea: '1110139 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568066, finearea: '1110140 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568067, finearea: '1110141 _ Dubbo Regional (A)', subregion: 'Dubbo - South', region: 'Regional NSW - Remainder' },
  { geography: 568068, finearea: '1110201 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568069, finearea: '1110202 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568070, finearea: '1110203 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568071, finearea: '1110204 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568072, finearea: '1110205 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568073, finearea: '1110206 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568074, finearea: '1110208 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568075, finearea: '1110209 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568076, finearea: '1110210 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568077, finearea: '1110212 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568078, finearea: '1110213 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568079, finearea: '1110214 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568080, finearea: '1110215 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568081, finearea: '1110216 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568082, finearea: '1110217 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568083, finearea: '1110219 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568084, finearea: '1110220 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568085, finearea: '1110221 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568086, finearea: '1110222 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568087, finearea: '1110223 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568088, finearea: '1110224 _ Dubbo Regional (A)', subregion: 'Dubbo - West', region: 'Regional NSW - Remainder' },
  { geography: 568089, finearea: '1110301 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568090, finearea: '1110302 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568091, finearea: '1110303 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568092, finearea: '1110304 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568093, finearea: '1110305 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568094, finearea: '1110306 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568095, finearea: '1110307 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568096, finearea: '1110308 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568097, finearea: '1110309 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568098, finearea: '1110310 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568099, finearea: '1110311 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568100, finearea: '1110312 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568101, finearea: '1110313 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568102, finearea: '1110314 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568103, finearea: '1110315 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568104, finearea: '1110316 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568105, finearea: '1110317 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568106, finearea: '1110318 _ Dubbo Regional (A)', subregion: 'Dubbo Region', region: 'Regional NSW - Remainder' },
  { geography: 568107, finearea: '1110601 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568108, finearea: '1110602 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568109, finearea: '1110603 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568110, finearea: '1110604 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568111, finearea: '1110605 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568112, finearea: '1110606 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568113, finearea: '1110607 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568114, finearea: '1110608 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568115, finearea: '1110609 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568116, finearea: '1110610 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568117, finearea: '1110611 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568118, finearea: '1110614 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568119, finearea: '1110615 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568120, finearea: '1110616 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568121, finearea: '1110617 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568122, finearea: '1110618 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568123, finearea: '1110619 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568124, finearea: '1110620 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568125, finearea: '1110621 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568126, finearea: '1110622 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568127, finearea: '1110623 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568128, finearea: '1110624 _ Dubbo Regional (A)', subregion: 'Wellington', region: 'Regional NSW - Remainder' },
  { geography: 568129, finearea: '1111001 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568130, finearea: '1111002 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568131, finearea: '1111003 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568132, finearea: '1111004 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568133, finearea: '1111005 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568134, finearea: '1111006 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568135, finearea: '1111007 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568136, finearea: '1111008 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568137, finearea: '1111009 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568138, finearea: '1111010 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568139, finearea: '1111011 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568140, finearea: '1111012 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568141, finearea: '1111013 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568142, finearea: '1111014 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568143, finearea: '1111015 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568144, finearea: '1111016 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568145, finearea: '1111017 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568146, finearea: '1111018 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568147, finearea: '1111019 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568148, finearea: '1111020 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568149, finearea: '1111021 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568150, finearea: '1111022 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568151, finearea: '1111023 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568152, finearea: '1111024 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568153, finearea: '1111025 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568154, finearea: '1111026 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568155, finearea: '1111027 _ Dungog (A)', subregion: 'Dungog', region: 'Central Coast and Hunter' },
  { geography: 568156, finearea: '1118201 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568157, finearea: '1118202 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568158, finearea: '1118203 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568159, finearea: '1118204 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568160, finearea: '1118205 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568161, finearea: '1118206 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568162, finearea: '1118207 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568163, finearea: '1118208 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568164, finearea: '1118209 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568165, finearea: '1118210 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568166, finearea: '1118211 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568167, finearea: '1118212 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568168, finearea: '1118213 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568169, finearea: '1118214 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568170, finearea: '1118215 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568171, finearea: '1118216 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568172, finearea: '1118217 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568173, finearea: '1118218 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568174, finearea: '1118219 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568175, finearea: '1118220 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568176, finearea: '1118221 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568177, finearea: '1118222 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568178, finearea: '1118223 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568179, finearea: '1118224 _ Edward River (A)', subregion: 'Deniliquin', region: 'Murray' },
  { geography: 568180, finearea: '1118301 _ Edward River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 568181, finearea: '1118311 _ Edward River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 568182, finearea: '1118318 _ Edward River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 568183, finearea: '1118319 _ Edward River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 568184, finearea: '1118320 _ Edward River (A)', subregion: 'Deniliquin Region', region: 'Murray' },
  { geography: 568185, finearea: '1101701 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568186, finearea: '1101702 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568187, finearea: '1101703 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568188, finearea: '1101704 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568189, finearea: '1101705 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568190, finearea: '1101706 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568191, finearea: '1101707 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568192, finearea: '1101708 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568193, finearea: '1101709 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568194, finearea: '1101710 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568195, finearea: '1101711 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568196, finearea: '1101712 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568197, finearea: '1101713 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568198, finearea: '1101714 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568199, finearea: '1101715 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568200, finearea: '1101716 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568201, finearea: '1101717 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568202, finearea: '1101718 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568203, finearea: '1101719 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568204, finearea: '1101720 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568205, finearea: '1101721 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568206, finearea: '1101722 _ Eurobodalla (A)', subregion: 'Batemans Bay', region: 'Capital Region and South Coast' },
  { geography: 568207, finearea: '1101801 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568208, finearea: '1101802 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568209, finearea: '1101803 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568210, finearea: '1101804 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568211, finearea: '1101805 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568212, finearea: '1101806 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568213, finearea: '1101807 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568214, finearea: '1101808 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568215, finearea: '1101809 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568216, finearea: '1101810 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568217, finearea: '1101811 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568218, finearea: '1101812 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568219, finearea: '1101813 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568220, finearea: '1101814 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568221, finearea: '1101815 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568222, finearea: '1101816 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568223, finearea: '1101817 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568224, finearea: '1101818 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568225, finearea: '1101819 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568226, finearea: '1101820 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568227, finearea: '1101821 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568228, finearea: '1101822 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568229, finearea: '1101823 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568230, finearea: '1101824 _ Eurobodalla (A)', subregion: 'Batemans Bay - South', region: 'Capital Region and South Coast' },
  { geography: 568231, finearea: '1102015 _ Eurobodalla (A)', subregion: 'Bega-Eden Hinterland', region: 'Capital Region and South Coast' },
  { geography: 568232, finearea: '1102101 _ Eurobodalla (A)', subregion: 'Broulee - Tomakin', region: 'Capital Region and South Coast' },
  { geography: 568233, finearea: '1102102 _ Eurobodalla (A)', subregion: 'Broulee - Tomakin', region: 'Capital Region and South Coast' },
  { geography: 568234, finearea: '1102104 _ Eurobodalla (A)', subregion: 'Broulee - Tomakin', region: 'Capital Region and South Coast' },
  { geography: 568235, finearea: '1102105 _ Eurobodalla (A)', subregion: 'Broulee - Tomakin', region: 'Capital Region and South Coast' },
  { geography: 568236, finearea: '1102106 _ Eurobodalla (A)', subregion: 'Broulee - Tomakin', region: 'Capital Region and South Coast' },
  { geography: 573728, finearea: '1125742 _ Murrumbidgee (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573729, finearea: '1112601 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573730, finearea: '1112602 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573731, finearea: '1112603 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573732, finearea: '1112604 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573733, finearea: '1112605 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573734, finearea: '1112606 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573735, finearea: '1112607 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573736, finearea: '1112608 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573737, finearea: '1112609 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573738, finearea: '1112610 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573739, finearea: '1112611 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573740, finearea: '1112612 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573741, finearea: '1112613 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573742, finearea: '1112614 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573743, finearea: '1112615 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573744, finearea: '1112616 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573745, finearea: '1112617 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573746, finearea: '1112618 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573747, finearea: '1112619 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573748, finearea: '1112620 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573749, finearea: '1112621 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573750, finearea: '1112622 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573751, finearea: '1112623 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573752, finearea: '1112624 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573753, finearea: '1112625 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573754, finearea: '1112626 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573755, finearea: '1112627 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573756, finearea: '1112628 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573757, finearea: '1112629 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573758, finearea: '1112630 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573759, finearea: '1112631 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573760, finearea: '1112632 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573761, finearea: '1112633 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573762, finearea: '1112634 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573763, finearea: '1112635 _ Muswellbrook (A)', subregion: 'Muswellbrook', region: 'Central Coast and Hunter' },
  { geography: 573764, finearea: '1112701 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573765, finearea: '1112702 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573766, finearea: '1112703 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573767, finearea: '1112704 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573768, finearea: '1112705 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573769, finearea: '1112706 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573770, finearea: '1112707 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573771, finearea: '1112708 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573772, finearea: '1112709 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573773, finearea: '1112710 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573774, finearea: '1112711 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573775, finearea: '1112712 _ Muswellbrook (A)', subregion: 'Muswellbrook Region', region: 'Central Coast and Hunter' },
  { geography: 573776, finearea: '1108309 _ Nambucca Valley (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 573777, finearea: '1108311 _ Nambucca Valley (A)', subregion: 'Bellingen', region: 'North East NSW' },
  { geography: 573778, finearea: '1115701 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573779, finearea: '1115702 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573780, finearea: '1115703 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573781, finearea: '1115704 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573782, finearea: '1115705 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573783, finearea: '1115707 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573784, finearea: '1115708 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573785, finearea: '1115709 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573786, finearea: '1115710 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573787, finearea: '1115711 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573788, finearea: '1115712 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573789, finearea: '1115713 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573790, finearea: '1115714 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573791, finearea: '1115715 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573792, finearea: '1115716 _ Nambucca Valley (A)', subregion: 'Macksville - Scotts Head', region: 'North East NSW' },
  { geography: 573793, finearea: '1115801 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573794, finearea: '1115802 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573795, finearea: '1115803 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573796, finearea: '1115804 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573797, finearea: '1115805 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573798, finearea: '1115806 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573799, finearea: '1115807 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573800, finearea: '1115808 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573801, finearea: '1115809 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573802, finearea: '1115810 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573803, finearea: '1115811 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573804, finearea: '1115812 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573805, finearea: '1115813 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573806, finearea: '1115814 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573807, finearea: '1115815 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573808, finearea: '1115816 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573809, finearea: '1115817 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573810, finearea: '1115818 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573811, finearea: '1115819 _ Nambucca Valley (A)', subregion: 'Nambucca Heads', region: 'North East NSW' },
  { geography: 573812, finearea: '1115901 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573813, finearea: '1115902 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573814, finearea: '1115903 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573815, finearea: '1115904 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573816, finearea: '1115906 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573817, finearea: '1115907 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573818, finearea: '1115908 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573819, finearea: '1115909 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573820, finearea: '1115910 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573821, finearea: '1115911 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573822, finearea: '1115912 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573823, finearea: '1115913 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573824, finearea: '1115914 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573825, finearea: '1115915 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573826, finearea: '1115916 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573827, finearea: '1115917 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573828, finearea: '1115918 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573829, finearea: '1115919 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573830, finearea: '1115920 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573831, finearea: '1115921 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573832, finearea: '1115922 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573833, finearea: '1115923 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573834, finearea: '1115924 _ Nambucca Valley (A)', subregion: 'Nambucca Heads Region', region: 'North East NSW' },
  { geography: 573835, finearea: '1119701 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573836, finearea: '1119702 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573837, finearea: '1119703 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573838, finearea: '1119704 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573839, finearea: '1119705 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573840, finearea: '1119706 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573841, finearea: '1119707 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573842, finearea: '1119708 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573843, finearea: '1119709 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573844, finearea: '1119710 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573845, finearea: '1119711 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573846, finearea: '1119712 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573847, finearea: '1119713 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573848, finearea: '1119714 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573849, finearea: '1119715 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573850, finearea: '1119716 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573851, finearea: '1119717 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573852, finearea: '1119718 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573853, finearea: '1119719 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573854, finearea: '1119720 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573855, finearea: '1119721 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573856, finearea: '1119722 _ Narrabri (A)', subregion: 'Narrabri', region: 'Regional NSW - Remainder' },
  { geography: 573857, finearea: '1119801 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573858, finearea: '1119802 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573859, finearea: '1119803 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573860, finearea: '1119804 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573861, finearea: '1119805 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573862, finearea: '1119806 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573863, finearea: '1119807 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573864, finearea: '1119808 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573865, finearea: '1119809 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573866, finearea: '1119810 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573867, finearea: '1119811 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573868, finearea: '1119812 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573869, finearea: '1119813 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573870, finearea: '1119814 _ Narrabri (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 573871, finearea: '1120002 _ Narrabri (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 573872, finearea: '1120003 _ Narrabri (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 573873, finearea: '1120007 _ Narrabri (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 573874, finearea: '1125706 _ Narrandera (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 573875, finearea: '1125901 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573876, finearea: '1125902 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573877, finearea: '1125903 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573878, finearea: '1125904 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573879, finearea: '1125905 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573880, finearea: '1125906 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573881, finearea: '1125907 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573882, finearea: '1125908 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573883, finearea: '1125909 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573884, finearea: '1125910 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573885, finearea: '1125911 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573886, finearea: '1125912 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573887, finearea: '1125913 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573888, finearea: '1125914 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573889, finearea: '1125916 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573890, finearea: '1125917 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573891, finearea: '1125918 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573892, finearea: '1125919 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573893, finearea: '1125920 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573894, finearea: '1125921 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573895, finearea: '1125922 _ Narrandera (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 573896, finearea: '1110501 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573897, finearea: '1110502 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573898, finearea: '1110503 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573899, finearea: '1110504 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573900, finearea: '1110506 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573901, finearea: '1110507 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573902, finearea: '1110508 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573903, finearea: '1110509 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573904, finearea: '1110510 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573905, finearea: '1110511 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573906, finearea: '1110512 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573907, finearea: '1110513 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573908, finearea: '1110514 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573909, finearea: '1110515 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573910, finearea: '1110516 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573911, finearea: '1110517 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573912, finearea: '1110518 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573913, finearea: '1110519 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573914, finearea: '1110520 _ Narromine (A)', subregion: 'Narromine', region: 'Regional NSW - Remainder' },
  { geography: 573915, finearea: '1120918 _ Newcastle (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 573916, finearea: '1120956 _ Newcastle (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 573917, finearea: '1121727 _ Newcastle (C)', subregion: 'Edgeworth - Cameron Park', region: 'Central Coast and Hunter' },
  { geography: 573918, finearea: '1122201 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573919, finearea: '1122202 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573920, finearea: '1122203 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573921, finearea: '1122204 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573922, finearea: '1122205 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573923, finearea: '1122206 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573924, finearea: '1122207 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573925, finearea: '1122208 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573926, finearea: '1122209 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 573927, finearea: '1122210 _ Newcastle (C)', subregion: 'Adamstown - Kotara', region: 'Central Coast and Hunter' },
  { geography: 577328, finearea: '1156723 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577329, finearea: '1156724 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577330, finearea: '1156725 _ Randwick (C)', subregion: 'Maroubra - South', region: 'Inner Sydney' },
  { geography: 577331, finearea: '1156801 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577332, finearea: '1156802 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577333, finearea: '1156803 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577334, finearea: '1156804 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577335, finearea: '1156805 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577336, finearea: '1156806 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577337, finearea: '1156807 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577338, finearea: '1156808 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577339, finearea: '1156809 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577340, finearea: '1156810 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577341, finearea: '1156811 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577342, finearea: '1156812 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577343, finearea: '1156813 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577344, finearea: '1156814 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577345, finearea: '1156815 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577346, finearea: '1156816 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577347, finearea: '1156817 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577348, finearea: '1156818 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577349, finearea: '1156819 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577350, finearea: '1156820 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577351, finearea: '1156821 _ Randwick (C)', subregion: 'Maroubra - West', region: 'Inner Sydney' },
  { geography: 577352, finearea: '1156901 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577353, finearea: '1156902 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577354, finearea: '1156903 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577355, finearea: '1156904 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577356, finearea: '1156905 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577357, finearea: '1156906 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577358, finearea: '1156907 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577359, finearea: '1156908 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577360, finearea: '1156909 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577361, finearea: '1156910 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577362, finearea: '1156911 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577363, finearea: '1156912 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577364, finearea: '1156913 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577365, finearea: '1156914 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577366, finearea: '1156915 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577367, finearea: '1156916 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577368, finearea: '1156917 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577369, finearea: '1156918 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577370, finearea: '1156919 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577371, finearea: '1156920 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577372, finearea: '1156921 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577373, finearea: '1156922 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577374, finearea: '1156923 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577375, finearea: '1156924 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577376, finearea: '1156925 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577377, finearea: '1156926 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577378, finearea: '1156927 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577379, finearea: '1156928 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577380, finearea: '1156929 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577381, finearea: '1156930 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577382, finearea: '1156931 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577383, finearea: '1156932 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577384, finearea: '1156933 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577385, finearea: '1156934 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577386, finearea: '1156935 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577387, finearea: '1156936 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577388, finearea: '1156937 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577389, finearea: '1156938 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577390, finearea: '1156939 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577391, finearea: '1156940 _ Randwick (C)', subregion: 'Randwick - North', region: 'Inner Sydney' },
  { geography: 577392, finearea: '1157001 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577393, finearea: '1157002 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577394, finearea: '1157003 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577395, finearea: '1157004 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577396, finearea: '1157005 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577397, finearea: '1157006 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577398, finearea: '1157007 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577399, finearea: '1157008 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577400, finearea: '1157009 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577401, finearea: '1157010 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577402, finearea: '1157011 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577403, finearea: '1157012 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577404, finearea: '1157013 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577405, finearea: '1157014 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577406, finearea: '1157015 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577407, finearea: '1157016 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577408, finearea: '1157017 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577409, finearea: '1157018 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577410, finearea: '1157019 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577411, finearea: '1157020 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577412, finearea: '1157021 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577413, finearea: '1157022 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577414, finearea: '1157023 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577415, finearea: '1157024 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577416, finearea: '1157025 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577417, finearea: '1157026 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577418, finearea: '1157027 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577419, finearea: '1157028 _ Randwick (C)', subregion: 'Randwick - South', region: 'Inner Sydney' },
  { geography: 577420, finearea: '1108122 _ Richmond Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 577421, finearea: '1108223 _ Richmond Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 577422, finearea: '1124101 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577423, finearea: '1124102 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577424, finearea: '1124103 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577425, finearea: '1124104 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577426, finearea: '1124105 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577427, finearea: '1124106 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577428, finearea: '1124107 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577429, finearea: '1124108 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577430, finearea: '1124109 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577431, finearea: '1124110 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577432, finearea: '1124111 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577433, finearea: '1124112 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577434, finearea: '1124113 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577435, finearea: '1124114 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577436, finearea: '1124115 _ Richmond Valley (A)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 577437, finearea: '1124401 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577438, finearea: '1124402 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577439, finearea: '1124403 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577440, finearea: '1124404 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577441, finearea: '1124405 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577442, finearea: '1124406 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577443, finearea: '1124407 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577444, finearea: '1124408 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577445, finearea: '1124409 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577446, finearea: '1124410 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577447, finearea: '1124411 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577448, finearea: '1124412 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577449, finearea: '1124413 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577450, finearea: '1124414 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577451, finearea: '1124415 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577452, finearea: '1124416 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577453, finearea: '1124417 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577454, finearea: '1124418 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577455, finearea: '1124419 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577456, finearea: '1124420 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577457, finearea: '1124421 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577458, finearea: '1124422 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577459, finearea: '1124423 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577460, finearea: '1124424 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577461, finearea: '1124425 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577462, finearea: '1124426 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577463, finearea: '1124427 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577464, finearea: '1124428 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577465, finearea: '1124429 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577466, finearea: '1124430 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577467, finearea: '1124431 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577468, finearea: '1124432 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577469, finearea: '1124433 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577470, finearea: '1124434 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577471, finearea: '1124435 _ Richmond Valley (A)', subregion: 'Casino', region: 'North East NSW' },
  { geography: 577472, finearea: '1124502 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577473, finearea: '1124503 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577474, finearea: '1124504 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577475, finearea: '1124505 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577476, finearea: '1124506 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577477, finearea: '1124512 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577478, finearea: '1124513 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577479, finearea: '1124514 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577480, finearea: '1124515 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577481, finearea: '1124516 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577482, finearea: '1124517 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577483, finearea: '1124518 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577484, finearea: '1124519 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577485, finearea: '1124520 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577486, finearea: '1124521 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577487, finearea: '1124522 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577488, finearea: '1124523 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577489, finearea: '1124524 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577490, finearea: '1124525 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577491, finearea: '1124526 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577492, finearea: '1124527 _ Richmond Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 577493, finearea: '1124909 _ Richmond Valley (A)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 577494, finearea: '1139915 _ Ryde (C)', subregion: 'Chatswood (West) - Lane Cove North', region: 'Northern Sydney' },
  { geography: 577495, finearea: '1149701 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577496, finearea: '1149702 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577497, finearea: '1149703 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577498, finearea: '1149704 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577499, finearea: '1149706 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577500, finearea: '1149707 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577501, finearea: '1149708 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577502, finearea: '1149709 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577503, finearea: '1149710 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577504, finearea: '1149711 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577505, finearea: '1149712 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577506, finearea: '1149718 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577507, finearea: '1149719 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577508, finearea: '1149720 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577509, finearea: '1149721 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577510, finearea: '1149722 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577511, finearea: '1149723 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577512, finearea: '1149724 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577513, finearea: '1149725 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577514, finearea: '1149726 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577515, finearea: '1149727 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577516, finearea: '1149728 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577517, finearea: '1149729 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577518, finearea: '1149730 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577519, finearea: '1149731 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577520, finearea: '1149732 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577521, finearea: '1149733 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577522, finearea: '1149734 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577523, finearea: '1149735 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577524, finearea: '1149736 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577525, finearea: '1149737 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577526, finearea: '1149738 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 577527, finearea: '1149739 _ Ryde (C)', subregion: 'Eastwood - Denistone', region: 'Northern Sydney' },
  { geography: 568837, finearea: '1136811 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568838, finearea: '1136812 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568839, finearea: '1136813 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568840, finearea: '1136814 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568841, finearea: '1136815 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568842, finearea: '1136816 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568843, finearea: '1136817 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568844, finearea: '1136818 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568845, finearea: '1136819 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568846, finearea: '1136820 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568847, finearea: '1136821 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568848, finearea: '1136822 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568849, finearea: '1136823 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568850, finearea: '1136824 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568851, finearea: '1136825 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568852, finearea: '1136826 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568853, finearea: '1136827 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568854, finearea: '1136828 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568855, finearea: '1136829 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568856, finearea: '1136830 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568857, finearea: '1136831 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568858, finearea: '1136832 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568859, finearea: '1136833 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568860, finearea: '1136834 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568861, finearea: '1136835 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568862, finearea: '1136836 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568863, finearea: '1136837 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568864, finearea: '1136838 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568865, finearea: '1136839 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568866, finearea: '1136840 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568867, finearea: '1136841 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568868, finearea: '1136842 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568869, finearea: '1136843 _ Georges River (A)', subregion: 'Hurstville', region: 'Inner Sydney' },
  { geography: 568870, finearea: '1136901 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568871, finearea: '1136902 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568872, finearea: '1136903 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568873, finearea: '1136904 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568874, finearea: '1136905 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568875, finearea: '1136906 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568876, finearea: '1136907 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568877, finearea: '1136908 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568878, finearea: '1136909 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568879, finearea: '1136910 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568880, finearea: '1136911 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568881, finearea: '1136912 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568882, finearea: '1136913 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568883, finearea: '1136914 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568884, finearea: '1136915 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568885, finearea: '1136916 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568886, finearea: '1136917 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568887, finearea: '1136918 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568888, finearea: '1136919 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568889, finearea: '1136920 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568890, finearea: '1136921 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568891, finearea: '1136922 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568892, finearea: '1136923 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568893, finearea: '1136924 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568894, finearea: '1136925 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568895, finearea: '1136926 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568896, finearea: '1136927 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568897, finearea: '1136928 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568898, finearea: '1136929 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568899, finearea: '1136930 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568900, finearea: '1136931 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568901, finearea: '1136932 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568902, finearea: '1136933 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568903, finearea: '1136934 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568904, finearea: '1136935 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568905, finearea: '1136936 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568906, finearea: '1136937 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568907, finearea: '1136938 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568908, finearea: '1136939 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568909, finearea: '1136940 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568910, finearea: '1136941 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568911, finearea: '1136942 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568912, finearea: '1136943 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568913, finearea: '1136944 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568914, finearea: '1136945 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568915, finearea: '1136946 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568916, finearea: '1136947 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568917, finearea: '1136948 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568918, finearea: '1136949 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568919, finearea: '1136950 _ Georges River (A)', subregion: 'Mortdale - Penshurst', region: 'Inner Sydney' },
  { geography: 568920, finearea: '1137001 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568921, finearea: '1137002 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568922, finearea: '1137003 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568923, finearea: '1137004 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568924, finearea: '1137005 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568925, finearea: '1137006 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568926, finearea: '1137007 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568927, finearea: '1137008 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568928, finearea: '1137009 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568929, finearea: '1137010 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568930, finearea: '1137017 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568931, finearea: '1137018 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568932, finearea: '1137019 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568933, finearea: '1137020 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568934, finearea: '1137021 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568935, finearea: '1137022 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568936, finearea: '1137023 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568937, finearea: '1137024 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568938, finearea: '1137025 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568939, finearea: '1137026 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568940, finearea: '1137027 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568941, finearea: '1137028 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568942, finearea: '1137029 _ Georges River (A)', subregion: 'Narwee - Beverly Hills', region: 'Inner Sydney' },
  { geography: 568943, finearea: '1137101 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568944, finearea: '1137102 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568945, finearea: '1137103 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568946, finearea: '1137104 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568947, finearea: '1137105 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568948, finearea: '1137106 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568949, finearea: '1137107 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568950, finearea: '1137108 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568951, finearea: '1137109 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568952, finearea: '1137110 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568953, finearea: '1137111 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568954, finearea: '1137112 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568955, finearea: '1137113 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568956, finearea: '1137114 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568957, finearea: '1137115 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568958, finearea: '1137116 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568959, finearea: '1137117 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568960, finearea: '1137118 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568961, finearea: '1137119 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568962, finearea: '1137120 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568963, finearea: '1137121 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568964, finearea: '1137122 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568965, finearea: '1137123 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568966, finearea: '1137124 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568967, finearea: '1137125 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568968, finearea: '1137126 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568969, finearea: '1137127 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568970, finearea: '1137128 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568971, finearea: '1137129 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568972, finearea: '1137130 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568973, finearea: '1137131 _ Georges River (A)', subregion: 'Oatley - Hurstville Grove', region: 'Inner Sydney' },
  { geography: 568974, finearea: '1137201 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568975, finearea: '1137202 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568976, finearea: '1137203 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568977, finearea: '1137204 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568978, finearea: '1137205 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568979, finearea: '1137206 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568980, finearea: '1137207 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568981, finearea: '1137208 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568982, finearea: '1137209 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568983, finearea: '1137210 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568984, finearea: '1137211 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568985, finearea: '1137212 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568986, finearea: '1137213 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568987, finearea: '1137214 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568988, finearea: '1137215 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568989, finearea: '1137216 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568990, finearea: '1137217 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568991, finearea: '1137218 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568992, finearea: '1137219 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568993, finearea: '1137220 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568994, finearea: '1137221 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568995, finearea: '1137222 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568996, finearea: '1137223 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568997, finearea: '1137224 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568998, finearea: '1137225 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 568999, finearea: '1137226 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569000, finearea: '1137227 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569001, finearea: '1137228 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569002, finearea: '1137229 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569003, finearea: '1137230 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569004, finearea: '1137231 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569005, finearea: '1137232 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569006, finearea: '1137233 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569007, finearea: '1137234 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569008, finearea: '1137235 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569009, finearea: '1137236 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569010, finearea: '1137237 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569011, finearea: '1137238 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569012, finearea: '1137239 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569013, finearea: '1137240 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569014, finearea: '1137241 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569015, finearea: '1137242 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569016, finearea: '1137243 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569017, finearea: '1137244 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569018, finearea: '1137245 _ Georges River (A)', subregion: 'Peakhurst - Lugarno', region: 'Inner Sydney' },
  { geography: 569019, finearea: '1137301 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569020, finearea: '1137302 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569021, finearea: '1137303 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569022, finearea: '1137304 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569023, finearea: '1137305 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569024, finearea: '1137306 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569025, finearea: '1137315 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569026, finearea: '1137316 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569027, finearea: '1137317 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569028, finearea: '1137318 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569029, finearea: '1137319 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569030, finearea: '1137325 _ Georges River (A)', subregion: 'Riverwood', region: 'South and West Sydney' },
  { geography: 569031, finearea: '1137401 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569032, finearea: '1137402 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569033, finearea: '1137403 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569034, finearea: '1137404 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569035, finearea: '1137405 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 569036, finearea: '1137406 _ Georges River (A)', subregion: 'South Hurstville - Blakehurst', region: 'Inner Sydney' },
  { geography: 573328, finearea: '1115407 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573329, finearea: '1115408 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573330, finearea: '1115409 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573331, finearea: '1115410 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573332, finearea: '1115411 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573333, finearea: '1115412 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573334, finearea: '1115413 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573335, finearea: '1115414 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573336, finearea: '1115415 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573337, finearea: '1115416 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573338, finearea: '1115417 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573339, finearea: '1115418 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573340, finearea: '1115419 _ Mid-Coast (A)', subregion: 'Tuncurry', region: 'Regional NSW - Remainder' },
  { geography: 573341, finearea: '1116226 _ Mid-Coast (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 573342, finearea: '1116516 _ Mid-Coast (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 573343, finearea: '1116701 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573344, finearea: '1116702 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573345, finearea: '1116703 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573346, finearea: '1116704 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573347, finearea: '1116705 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573348, finearea: '1116706 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573349, finearea: '1116707 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573350, finearea: '1116708 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573351, finearea: '1116709 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573352, finearea: '1116710 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573353, finearea: '1116711 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573354, finearea: '1116712 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573355, finearea: '1116713 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573356, finearea: '1116714 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573357, finearea: '1116715 _ Mid-Coast (A)', subregion: 'Gloucester', region: 'Regional NSW - Remainder' },
  { geography: 573358, finearea: '1116801 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573359, finearea: '1116802 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573360, finearea: '1116803 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573361, finearea: '1116804 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573362, finearea: '1116805 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573363, finearea: '1116806 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573364, finearea: '1116807 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573365, finearea: '1116808 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573366, finearea: '1116809 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573367, finearea: '1116810 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573368, finearea: '1116811 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573369, finearea: '1116812 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573370, finearea: '1116813 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573371, finearea: '1116814 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573372, finearea: '1116815 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573373, finearea: '1116816 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573374, finearea: '1116817 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573375, finearea: '1116818 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573376, finearea: '1116819 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573377, finearea: '1116820 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573378, finearea: '1116821 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573379, finearea: '1116822 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573380, finearea: '1116823 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573381, finearea: '1116824 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573382, finearea: '1116825 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573383, finearea: '1116826 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573384, finearea: '1116827 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573385, finearea: '1116828 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573386, finearea: '1116829 _ Mid-Coast (A)', subregion: 'Old Bar - Manning Point - Red Head', region: 'Regional NSW - Remainder' },
  { geography: 573387, finearea: '1116901 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573388, finearea: '1116902 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573389, finearea: '1116903 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573390, finearea: '1116904 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573391, finearea: '1116905 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573392, finearea: '1116906 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573393, finearea: '1116907 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573394, finearea: '1116908 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573395, finearea: '1116909 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573396, finearea: '1116910 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573397, finearea: '1116911 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573398, finearea: '1116912 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573399, finearea: '1116913 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573400, finearea: '1116914 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573401, finearea: '1116915 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573402, finearea: '1116916 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573403, finearea: '1116917 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573404, finearea: '1116918 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573405, finearea: '1116919 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573406, finearea: '1116920 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573407, finearea: '1116921 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573408, finearea: '1116922 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573409, finearea: '1116923 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573410, finearea: '1116924 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573411, finearea: '1116925 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573412, finearea: '1116926 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573413, finearea: '1116927 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573414, finearea: '1116928 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573415, finearea: '1116929 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573416, finearea: '1116930 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573417, finearea: '1116931 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573418, finearea: '1116932 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573419, finearea: '1116933 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573420, finearea: '1116934 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573421, finearea: '1116935 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573422, finearea: '1116936 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573423, finearea: '1116937 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573424, finearea: '1116938 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573425, finearea: '1116939 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573426, finearea: '1116940 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573427, finearea: '1116941 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573428, finearea: '1116942 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573429, finearea: '1116943 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573430, finearea: '1116944 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573431, finearea: '1116945 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573432, finearea: '1116946 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573433, finearea: '1116947 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573434, finearea: '1116948 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573435, finearea: '1116949 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573436, finearea: '1116950 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573437, finearea: '1116951 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573438, finearea: '1116952 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573439, finearea: '1116953 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573440, finearea: '1116954 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573441, finearea: '1116955 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573442, finearea: '1116956 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573443, finearea: '1116957 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573444, finearea: '1116958 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573445, finearea: '1116959 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573446, finearea: '1116961 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573447, finearea: '1116962 _ Mid-Coast (A)', subregion: 'Taree', region: 'Regional NSW - Remainder' },
  { geography: 573448, finearea: '1117001 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573449, finearea: '1117002 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573450, finearea: '1117003 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573451, finearea: '1117004 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573452, finearea: '1117005 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573453, finearea: '1117006 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573454, finearea: '1117007 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573455, finearea: '1117008 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573456, finearea: '1117009 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573457, finearea: '1117010 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573458, finearea: '1117011 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573459, finearea: '1117012 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573460, finearea: '1117013 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573461, finearea: '1117014 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573462, finearea: '1117015 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573463, finearea: '1117016 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573464, finearea: '1117017 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573465, finearea: '1117018 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573466, finearea: '1117019 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573467, finearea: '1117020 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573468, finearea: '1117021 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573469, finearea: '1117022 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573470, finearea: '1117023 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573471, finearea: '1117024 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573472, finearea: '1117025 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573473, finearea: '1117026 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573474, finearea: '1117027 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573475, finearea: '1117028 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573476, finearea: '1117029 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573477, finearea: '1117030 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573478, finearea: '1117031 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573479, finearea: '1117032 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573480, finearea: '1117033 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573481, finearea: '1117034 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573482, finearea: '1117035 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573483, finearea: '1117036 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573484, finearea: '1117037 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573485, finearea: '1117038 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573486, finearea: '1117039 _ Mid-Coast (A)', subregion: 'Taree Region', region: 'Regional NSW - Remainder' },
  { geography: 573487, finearea: '1117101 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573488, finearea: '1117102 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573489, finearea: '1117103 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573490, finearea: '1117104 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573491, finearea: '1117105 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573492, finearea: '1117106 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573493, finearea: '1117107 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573494, finearea: '1117108 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573495, finearea: '1117109 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573496, finearea: '1117110 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573497, finearea: '1117111 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573498, finearea: '1117112 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573499, finearea: '1117113 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573500, finearea: '1117114 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573501, finearea: '1117115 _ Mid-Coast (A)', subregion: 'Wingham', region: 'Regional NSW - Remainder' },
  { geography: 573502, finearea: '1107201 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573503, finearea: '1107202 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573504, finearea: '1107203 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573505, finearea: '1107204 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573506, finearea: '1107205 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573507, finearea: '1107206 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573508, finearea: '1107207 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573509, finearea: '1107208 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573510, finearea: '1107209 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573511, finearea: '1107210 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573512, finearea: '1107211 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573513, finearea: '1107212 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573514, finearea: '1107214 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573515, finearea: '1107215 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573516, finearea: '1107216 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573517, finearea: '1107217 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573518, finearea: '1107218 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573519, finearea: '1107219 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573520, finearea: '1107220 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573521, finearea: '1107221 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573522, finearea: '1107222 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573523, finearea: '1107224 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573524, finearea: '1107225 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573525, finearea: '1107226 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573526, finearea: '1107227 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 573527, finearea: '1107228 _ Mid-Western Regional (A)', subregion: 'Mudgee', region: 'Regional NSW - Remainder' },
  { geography: 575928, finearea: '1145718 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575929, finearea: '1145719 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575930, finearea: '1145720 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575931, finearea: '1145721 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575932, finearea: '1145722 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575933, finearea: '1145723 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575934, finearea: '1145724 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575935, finearea: '1145725 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575936, finearea: '1145726 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575937, finearea: '1145727 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575938, finearea: '1145728 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575939, finearea: '1145729 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575940, finearea: '1145730 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575941, finearea: '1145731 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575942, finearea: '1145732 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575943, finearea: '1145733 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575944, finearea: '1145734 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575945, finearea: '1145735 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575946, finearea: '1145736 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575947, finearea: '1145737 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575948, finearea: '1145738 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575949, finearea: '1145739 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575950, finearea: '1145740 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575951, finearea: '1145741 _ Penrith (C)', subregion: 'Cambridge Park', region: 'Northern Sydney' },
  { geography: 575952, finearea: '1145801 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575953, finearea: '1145802 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575954, finearea: '1145803 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575955, finearea: '1145804 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575956, finearea: '1145805 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575957, finearea: '1145806 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575958, finearea: '1145807 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575959, finearea: '1145808 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575960, finearea: '1145809 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575961, finearea: '1145810 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575962, finearea: '1145811 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575963, finearea: '1145812 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575964, finearea: '1145813 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575965, finearea: '1145814 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575966, finearea: '1145815 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575967, finearea: '1145816 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575968, finearea: '1145817 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575969, finearea: '1145818 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575970, finearea: '1145819 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575971, finearea: '1145820 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575972, finearea: '1145821 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575973, finearea: '1145822 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575974, finearea: '1145823 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575975, finearea: '1145824 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575976, finearea: '1145825 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575977, finearea: '1145826 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575978, finearea: '1145827 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575979, finearea: '1145828 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575980, finearea: '1145829 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575981, finearea: '1145830 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575982, finearea: '1145831 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575983, finearea: '1145832 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575984, finearea: '1145833 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575985, finearea: '1145834 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575986, finearea: '1145835 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575987, finearea: '1145836 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575988, finearea: '1145837 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575989, finearea: '1145838 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575990, finearea: '1145839 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575991, finearea: '1145840 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575992, finearea: '1145841 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575993, finearea: '1145842 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575994, finearea: '1145843 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575995, finearea: '1145844 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575996, finearea: '1145845 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575997, finearea: '1145846 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575998, finearea: '1145847 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 575999, finearea: '1145848 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 576000, finearea: '1145849 _ Penrith (C)', subregion: 'Castlereagh - Cranebrook', region: 'Northern Sydney' },
  { geography: 576001, finearea: '1145901 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576002, finearea: '1145902 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576003, finearea: '1145903 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576004, finearea: '1145904 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576005, finearea: '1145905 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576006, finearea: '1145906 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576007, finearea: '1145907 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576008, finearea: '1145908 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576009, finearea: '1145909 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576010, finearea: '1145910 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576011, finearea: '1145911 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576012, finearea: '1145912 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576013, finearea: '1145913 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576014, finearea: '1145914 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576015, finearea: '1145915 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576016, finearea: '1145916 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576017, finearea: '1145917 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576018, finearea: '1145918 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576019, finearea: '1145919 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576020, finearea: '1145920 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576021, finearea: '1145921 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576022, finearea: '1145922 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576023, finearea: '1145923 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576024, finearea: '1145924 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576025, finearea: '1145925 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576026, finearea: '1145926 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576027, finearea: '1145927 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576028, finearea: '1145928 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576029, finearea: '1145929 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576030, finearea: '1145930 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576031, finearea: '1145931 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576032, finearea: '1145932 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576033, finearea: '1145933 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576034, finearea: '1145934 _ Penrith (C)', subregion: 'Emu Plains - Leonay', region: 'Northern Sydney' },
  { geography: 576035, finearea: '1146001 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576036, finearea: '1146002 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576037, finearea: '1146003 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576038, finearea: '1146004 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576039, finearea: '1146005 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576040, finearea: '1146006 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576041, finearea: '1146007 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576042, finearea: '1146008 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576043, finearea: '1146009 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576044, finearea: '1146010 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576045, finearea: '1146011 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576046, finearea: '1146012 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576047, finearea: '1146013 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576048, finearea: '1146014 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576049, finearea: '1146015 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576050, finearea: '1146016 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576051, finearea: '1146017 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576052, finearea: '1146018 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576053, finearea: '1146019 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576054, finearea: '1146020 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576055, finearea: '1146021 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576056, finearea: '1146022 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576057, finearea: '1146023 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576058, finearea: '1146024 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576059, finearea: '1146025 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576060, finearea: '1146026 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576061, finearea: '1146027 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576062, finearea: '1146028 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576063, finearea: '1146029 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576064, finearea: '1146030 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576065, finearea: '1146031 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576066, finearea: '1146032 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576067, finearea: '1146033 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576068, finearea: '1146034 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576069, finearea: '1146035 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576070, finearea: '1146036 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576071, finearea: '1146037 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576072, finearea: '1146038 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576073, finearea: '1146039 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576074, finearea: '1146040 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576075, finearea: '1146041 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576076, finearea: '1146042 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576077, finearea: '1146043 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576078, finearea: '1146044 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576079, finearea: '1146045 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576080, finearea: '1146046 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576081, finearea: '1146047 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576082, finearea: '1146048 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576083, finearea: '1146049 _ Penrith (C)', subregion: 'Glenmore Park - Regentville', region: 'Northern Sydney' },
  { geography: 576084, finearea: '1146101 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576085, finearea: '1146102 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576086, finearea: '1146103 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576087, finearea: '1146104 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576088, finearea: '1146105 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576089, finearea: '1146106 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576090, finearea: '1146107 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576091, finearea: '1146108 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576092, finearea: '1146109 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576093, finearea: '1146110 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576094, finearea: '1146111 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576095, finearea: '1146112 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576096, finearea: '1146113 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576097, finearea: '1146114 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576098, finearea: '1146115 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576099, finearea: '1146116 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576100, finearea: '1146117 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576101, finearea: '1146118 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576102, finearea: '1146119 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576103, finearea: '1146120 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576104, finearea: '1146121 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576105, finearea: '1146122 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576106, finearea: '1146123 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576107, finearea: '1146124 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576108, finearea: '1146125 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576109, finearea: '1146126 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576110, finearea: '1146127 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576111, finearea: '1146128 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576112, finearea: '1146129 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576113, finearea: '1146130 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576114, finearea: '1146131 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576115, finearea: '1146132 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576116, finearea: '1146133 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576117, finearea: '1146134 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576118, finearea: '1146135 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576119, finearea: '1146136 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576120, finearea: '1146137 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576121, finearea: '1146138 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576122, finearea: '1146139 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576123, finearea: '1146140 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576124, finearea: '1146141 _ Penrith (C)', subregion: 'Jamisontown - South Penrith', region: 'Northern Sydney' },
  { geography: 576125, finearea: '1146202 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576126, finearea: '1146203 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 576127, finearea: '1146204 _ Penrith (C)', subregion: 'Kingswood - Werrington', region: 'Northern Sydney' },
  { geography: 575128, finearea: '1142923 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575129, finearea: '1142924 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575130, finearea: '1142925 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575131, finearea: '1142926 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575132, finearea: '1142927 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575133, finearea: '1142928 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575134, finearea: '1142929 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575135, finearea: '1142930 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575136, finearea: '1142931 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575137, finearea: '1142932 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575138, finearea: '1142933 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575139, finearea: '1142934 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575140, finearea: '1142935 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575141, finearea: '1142936 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575142, finearea: '1142937 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575143, finearea: '1142938 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575144, finearea: '1142939 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575145, finearea: '1142940 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575146, finearea: '1142941 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575147, finearea: '1142942 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575148, finearea: '1142943 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575149, finearea: '1142944 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575150, finearea: '1142945 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575151, finearea: '1142946 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575152, finearea: '1142947 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575153, finearea: '1142948 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575154, finearea: '1142949 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575155, finearea: '1142950 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575156, finearea: '1142951 _ Northern Beaches (A)', subregion: 'Freshwater - Brookvale', region: 'Northern Sydney' },
  { geography: 575157, finearea: '1143001 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575158, finearea: '1143002 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575159, finearea: '1143003 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575160, finearea: '1143004 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575161, finearea: '1143005 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575162, finearea: '1143006 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575163, finearea: '1143007 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575164, finearea: '1143008 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575165, finearea: '1143009 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575166, finearea: '1143010 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575167, finearea: '1143011 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575168, finearea: '1143012 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575169, finearea: '1143013 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575170, finearea: '1143014 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575171, finearea: '1143015 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575172, finearea: '1143016 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575173, finearea: '1143017 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575174, finearea: '1143018 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575175, finearea: '1143019 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575176, finearea: '1143020 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575177, finearea: '1143021 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575178, finearea: '1143022 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575179, finearea: '1143023 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575180, finearea: '1143024 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575181, finearea: '1143025 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575182, finearea: '1143026 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575183, finearea: '1143027 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575184, finearea: '1143028 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575185, finearea: '1143029 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575186, finearea: '1143030 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575187, finearea: '1143031 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575188, finearea: '1143032 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575189, finearea: '1143033 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575190, finearea: '1143034 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575191, finearea: '1143035 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575192, finearea: '1143036 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575193, finearea: '1143037 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575194, finearea: '1143038 _ Northern Beaches (A)', subregion: 'Manly Vale - Allambie Heights', region: 'Northern Sydney' },
  { geography: 575195, finearea: '1143101 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575196, finearea: '1143102 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575197, finearea: '1143103 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575198, finearea: '1143104 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575199, finearea: '1143105 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575200, finearea: '1143106 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575201, finearea: '1143107 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575202, finearea: '1143108 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575203, finearea: '1143109 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575204, finearea: '1143110 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575205, finearea: '1143111 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575206, finearea: '1143112 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575207, finearea: '1143113 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575208, finearea: '1143114 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575209, finearea: '1143115 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575210, finearea: '1143116 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575211, finearea: '1143117 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575212, finearea: '1143118 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575213, finearea: '1143119 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575214, finearea: '1143120 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575215, finearea: '1143121 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575216, finearea: '1143123 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575217, finearea: '1143124 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575218, finearea: '1143125 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575219, finearea: '1143126 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575220, finearea: '1143127 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575221, finearea: '1143128 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575222, finearea: '1143129 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575223, finearea: '1143130 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575224, finearea: '1143131 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575225, finearea: '1143132 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575226, finearea: '1143133 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575227, finearea: '1143134 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575228, finearea: '1143135 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575229, finearea: '1143136 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575230, finearea: '1143137 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575231, finearea: '1143139 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575232, finearea: '1143140 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575233, finearea: '1143141 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575234, finearea: '1143142 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575235, finearea: '1143143 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575236, finearea: '1143144 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575237, finearea: '1143145 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575238, finearea: '1143146 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575239, finearea: '1143147 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575240, finearea: '1143148 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575241, finearea: '1143149 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575242, finearea: '1143150 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575243, finearea: '1143151 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575244, finearea: '1143152 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575245, finearea: '1143153 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575246, finearea: '1143154 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575247, finearea: '1143155 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575248, finearea: '1143156 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575249, finearea: '1143157 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575250, finearea: '1143158 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575251, finearea: '1143159 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575252, finearea: '1143160 _ Northern Beaches (A)', subregion: 'Narrabeen - Collaroy', region: 'Northern Sydney' },
  { geography: 575253, finearea: '1143201 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575254, finearea: '1143202 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575255, finearea: '1143203 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575256, finearea: '1143204 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575257, finearea: '1143205 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575258, finearea: '1143206 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575259, finearea: '1143207 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575260, finearea: '1143208 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575261, finearea: '1143209 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575262, finearea: '1143210 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575263, finearea: '1143211 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575264, finearea: '1143212 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575265, finearea: '1143213 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575266, finearea: '1143214 _ Northern Beaches (A)', subregion: 'Terrey Hills - Duffys Forest', region: 'Northern Sydney' },
  { geography: 575267, finearea: '1106015 _ Oberon (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 575268, finearea: '1106016 _ Oberon (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 575269, finearea: '1106017 _ Oberon (A)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 575270, finearea: '1106101 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575271, finearea: '1106102 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575272, finearea: '1106103 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575273, finearea: '1106104 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575274, finearea: '1106105 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575275, finearea: '1106106 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575276, finearea: '1106107 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575277, finearea: '1106108 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575278, finearea: '1106109 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575279, finearea: '1106110 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575280, finearea: '1106111 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575281, finearea: '1106112 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575282, finearea: '1106113 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575283, finearea: '1106114 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575284, finearea: '1106115 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575285, finearea: '1106116 _ Oberon (A)', subregion: 'Oberon', region: 'Regional NSW - Remainder' },
  { geography: 575286, finearea: '1145601 _ Oberon (A)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 575287, finearea: '1107701 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575288, finearea: '1107702 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575289, finearea: '1107703 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575290, finearea: '1107704 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575291, finearea: '1107705 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575292, finearea: '1107706 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575293, finearea: '1107707 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575294, finearea: '1107708 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575295, finearea: '1107709 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575296, finearea: '1107710 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575297, finearea: '1107711 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575298, finearea: '1107712 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575299, finearea: '1107713 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575300, finearea: '1107714 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575301, finearea: '1107715 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575302, finearea: '1107716 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575303, finearea: '1107717 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575304, finearea: '1107718 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575305, finearea: '1107719 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575306, finearea: '1107720 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575307, finearea: '1107721 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575308, finearea: '1107722 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575309, finearea: '1107723 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575310, finearea: '1107724 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575311, finearea: '1107725 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575312, finearea: '1107726 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575313, finearea: '1107727 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575314, finearea: '1107728 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575315, finearea: '1107729 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575316, finearea: '1107730 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575317, finearea: '1107731 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575318, finearea: '1107732 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575319, finearea: '1107733 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575320, finearea: '1107734 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575321, finearea: '1107735 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575322, finearea: '1107736 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575323, finearea: '1107737 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575324, finearea: '1107738 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575325, finearea: '1107739 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575326, finearea: '1107740 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575327, finearea: '1107741 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 563237, finearea: '1130401 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563238, finearea: '1130402 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563239, finearea: '1130403 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563240, finearea: '1130404 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563241, finearea: '1130405 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563242, finearea: '1130406 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563243, finearea: '1130407 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563244, finearea: '1130408 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563245, finearea: '1130409 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563246, finearea: '1130410 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563247, finearea: '1130411 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563248, finearea: '1130412 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563249, finearea: '1130413 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563250, finearea: '1130414 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563251, finearea: '1130415 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563252, finearea: '1130416 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563253, finearea: '1130417 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563254, finearea: '1130418 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563255, finearea: '1130419 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563256, finearea: '1130420 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563257, finearea: '1130421 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563258, finearea: '1130422 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563259, finearea: '1130423 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563260, finearea: '1130424 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563261, finearea: '1130425 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563262, finearea: '1130426 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563263, finearea: '1130427 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563264, finearea: '1130428 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563265, finearea: '1130429 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563266, finearea: '1130430 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563267, finearea: '1130431 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563268, finearea: '1130432 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563269, finearea: '1130433 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563270, finearea: '1130434 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563271, finearea: '1130435 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563272, finearea: '1130436 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563273, finearea: '1130437 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563274, finearea: '1130438 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563275, finearea: '1130439 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563276, finearea: '1130440 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563277, finearea: '1130441 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563278, finearea: '1130442 _ Blacktown (C)', subregion: 'Blacktown (North) - Marayong', region: 'Northern Sydney' },
  { geography: 563279, finearea: '1130601 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563280, finearea: '1130602 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563281, finearea: '1130604 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563282, finearea: '1130605 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563283, finearea: '1130606 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563284, finearea: '1130607 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563285, finearea: '1130608 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563286, finearea: '1130609 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563287, finearea: '1130610 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563288, finearea: '1130611 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563289, finearea: '1130612 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563290, finearea: '1130613 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563291, finearea: '1130614 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563292, finearea: '1130615 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563293, finearea: '1130616 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563294, finearea: '1130617 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563295, finearea: '1130618 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563296, finearea: '1130619 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563297, finearea: '1130620 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563298, finearea: '1130621 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563299, finearea: '1130622 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563300, finearea: '1130623 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563301, finearea: '1130624 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563302, finearea: '1130625 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563303, finearea: '1130626 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563304, finearea: '1130627 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563305, finearea: '1130628 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563306, finearea: '1130629 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563307, finearea: '1130630 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563308, finearea: '1130631 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563309, finearea: '1130632 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563310, finearea: '1130633 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563311, finearea: '1130634 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563312, finearea: '1130635 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563313, finearea: '1130636 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563314, finearea: '1130637 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563315, finearea: '1130638 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563316, finearea: '1130639 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563317, finearea: '1130640 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563318, finearea: '1130641 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563319, finearea: '1130642 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563320, finearea: '1130643 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563321, finearea: '1130644 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563322, finearea: '1130645 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563323, finearea: '1130646 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563324, finearea: '1130647 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563325, finearea: '1130648 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563326, finearea: '1130649 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563327, finearea: '1130650 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563328, finearea: '1130651 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563329, finearea: '1130652 _ Blacktown (C)', subregion: 'Doonside - Woodcroft', region: 'Northern Sydney' },
  { geography: 563330, finearea: '1130701 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563331, finearea: '1130704 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563332, finearea: '1130705 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563333, finearea: '1130706 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563334, finearea: '1130707 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563335, finearea: '1130708 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563336, finearea: '1130709 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563337, finearea: '1130710 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563338, finearea: '1130711 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563339, finearea: '1130712 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563340, finearea: '1130713 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563341, finearea: '1130714 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563342, finearea: '1130715 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563343, finearea: '1130716 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563344, finearea: '1130717 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563345, finearea: '1130718 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563346, finearea: '1130719 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563347, finearea: '1130720 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563348, finearea: '1130721 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563349, finearea: '1130722 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563350, finearea: '1130723 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563351, finearea: '1130724 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563352, finearea: '1130725 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563353, finearea: '1130726 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563354, finearea: '1130727 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563355, finearea: '1130728 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563356, finearea: '1130729 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563357, finearea: '1130730 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563358, finearea: '1130731 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563359, finearea: '1130732 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563360, finearea: '1130733 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563361, finearea: '1130734 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563362, finearea: '1130735 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563363, finearea: '1130736 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563364, finearea: '1130737 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563365, finearea: '1130738 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563366, finearea: '1130739 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563367, finearea: '1130740 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563368, finearea: '1130741 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563369, finearea: '1130742 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563370, finearea: '1130743 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563371, finearea: '1130744 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563372, finearea: '1130745 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563373, finearea: '1130746 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563374, finearea: '1130747 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563375, finearea: '1130748 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563376, finearea: '1130749 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563377, finearea: '1130750 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563378, finearea: '1130751 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563379, finearea: '1130752 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563380, finearea: '1130753 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563381, finearea: '1130754 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563382, finearea: '1130755 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563383, finearea: '1130756 _ Blacktown (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 563384, finearea: '1130801 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563385, finearea: '1130802 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563386, finearea: '1130803 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563387, finearea: '1130804 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563388, finearea: '1130805 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563389, finearea: '1130806 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563390, finearea: '1130807 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563391, finearea: '1130808 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563392, finearea: '1130809 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563393, finearea: '1130810 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563394, finearea: '1130811 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563395, finearea: '1130812 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563396, finearea: '1130813 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563397, finearea: '1130814 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563398, finearea: '1130815 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563399, finearea: '1130816 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563400, finearea: '1130817 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563401, finearea: '1130818 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563402, finearea: '1130819 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563403, finearea: '1130820 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563404, finearea: '1130821 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563405, finearea: '1130822 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563406, finearea: '1130823 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563407, finearea: '1130824 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563408, finearea: '1130825 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563409, finearea: '1130826 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563410, finearea: '1130827 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563411, finearea: '1130829 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563412, finearea: '1130831 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563413, finearea: '1130832 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563414, finearea: '1130833 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563415, finearea: '1130834 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563416, finearea: '1130835 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563417, finearea: '1130836 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563418, finearea: '1130837 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563419, finearea: '1130838 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563420, finearea: '1130839 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563421, finearea: '1130840 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563422, finearea: '1130841 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563423, finearea: '1130842 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563424, finearea: '1130843 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563425, finearea: '1130844 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563426, finearea: '1130845 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563427, finearea: '1130846 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563428, finearea: '1130847 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563429, finearea: '1130848 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563430, finearea: '1130849 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563431, finearea: '1130850 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563432, finearea: '1130851 _ Blacktown (C)', subregion: 'Seven Hills - Toongabbie - Prospect Reservoir', region: 'Northern Sydney' },
  { geography: 563433, finearea: '1156001 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563434, finearea: '1156002 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563435, finearea: '1156003 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 563436, finearea: '1156004 _ Blacktown (C)', subregion: 'Blacktown (South)', region: 'Northern Sydney' },
  { geography: 569837, finearea: '1140408 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569838, finearea: '1140409 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569839, finearea: '1140410 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569840, finearea: '1140411 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569841, finearea: '1140412 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569842, finearea: '1140413 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569843, finearea: '1140414 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569844, finearea: '1140415 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569845, finearea: '1140416 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569846, finearea: '1140417 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569847, finearea: '1140418 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569848, finearea: '1140419 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569849, finearea: '1140420 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569850, finearea: '1140421 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569851, finearea: '1140422 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569852, finearea: '1140423 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569853, finearea: '1140424 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569854, finearea: '1140425 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569855, finearea: '1140426 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569856, finearea: '1140427 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569857, finearea: '1140428 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569858, finearea: '1140429 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569859, finearea: '1140430 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569860, finearea: '1140431 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569861, finearea: '1140432 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569862, finearea: '1140601 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569863, finearea: '1140602 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569864, finearea: '1140603 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569865, finearea: '1140604 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569866, finearea: '1140605 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569867, finearea: '1140606 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569868, finearea: '1140607 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569869, finearea: '1140608 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569870, finearea: '1140609 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569871, finearea: '1140610 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569872, finearea: '1140611 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569873, finearea: '1140612 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569874, finearea: '1140613 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569875, finearea: '1140614 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569876, finearea: '1140615 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569877, finearea: '1140616 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569878, finearea: '1140617 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569879, finearea: '1140618 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569880, finearea: '1140619 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569881, finearea: '1140620 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569882, finearea: '1140621 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569883, finearea: '1140622 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569884, finearea: '1140623 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569885, finearea: '1140624 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569886, finearea: '1140625 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569887, finearea: '1140626 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569888, finearea: '1140627 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569889, finearea: '1140628 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569890, finearea: '1140629 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569891, finearea: '1140630 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569892, finearea: '1140631 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569893, finearea: '1140632 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569894, finearea: '1140633 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569895, finearea: '1140634 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569896, finearea: '1140635 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569897, finearea: '1140636 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569898, finearea: '1140637 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569899, finearea: '1140638 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569900, finearea: '1140639 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569901, finearea: '1140640 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569902, finearea: '1140641 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569903, finearea: '1140642 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569904, finearea: '1140643 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569905, finearea: '1140644 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569906, finearea: '1140645 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569907, finearea: '1140646 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569908, finearea: '1140647 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569909, finearea: '1140648 _ Hornsby (A)', subregion: 'Normanhurst - Thornleigh - Westleigh', region: 'Northern Sydney' },
  { geography: 569910, finearea: '1157701 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569911, finearea: '1157702 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569912, finearea: '1157703 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569913, finearea: '1157704 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569914, finearea: '1157705 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569915, finearea: '1157706 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569916, finearea: '1157707 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569917, finearea: '1157708 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569918, finearea: '1157709 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569919, finearea: '1157710 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569920, finearea: '1157711 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569921, finearea: '1157712 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569922, finearea: '1157713 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569923, finearea: '1157714 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569924, finearea: '1157715 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569925, finearea: '1157716 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569926, finearea: '1157717 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569927, finearea: '1157718 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569928, finearea: '1157719 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569929, finearea: '1157720 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569930, finearea: '1157721 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569931, finearea: '1157722 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569932, finearea: '1157723 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569933, finearea: '1157724 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569934, finearea: '1157725 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569935, finearea: '1157726 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569936, finearea: '1157727 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569937, finearea: '1157728 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569938, finearea: '1157729 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569939, finearea: '1157730 _ Hornsby (A)', subregion: 'Hornsby - East', region: 'Northern Sydney' },
  { geography: 569940, finearea: '1157801 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569941, finearea: '1157802 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569942, finearea: '1157803 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569943, finearea: '1157804 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569944, finearea: '1157805 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569945, finearea: '1157806 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569946, finearea: '1157807 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569947, finearea: '1157808 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569948, finearea: '1157809 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569949, finearea: '1157810 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569950, finearea: '1157811 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569951, finearea: '1157812 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569952, finearea: '1157813 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569953, finearea: '1157814 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569954, finearea: '1157815 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569955, finearea: '1157816 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569956, finearea: '1157817 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569957, finearea: '1157818 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569958, finearea: '1157819 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569959, finearea: '1157820 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569960, finearea: '1157821 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569961, finearea: '1157822 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569962, finearea: '1157823 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569963, finearea: '1157824 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569964, finearea: '1157825 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569965, finearea: '1157826 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569966, finearea: '1157827 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569967, finearea: '1157828 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569968, finearea: '1157829 _ Hornsby (A)', subregion: 'Hornsby - West', region: 'Northern Sydney' },
  { geography: 569969, finearea: '1157901 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569970, finearea: '1157902 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569971, finearea: '1157903 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569972, finearea: '1157904 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569973, finearea: '1157905 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569974, finearea: '1157906 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569975, finearea: '1157907 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569976, finearea: '1157908 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569977, finearea: '1157909 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569978, finearea: '1157910 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569979, finearea: '1157911 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569980, finearea: '1157912 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569981, finearea: '1157913 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569982, finearea: '1157914 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569983, finearea: '1157915 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569984, finearea: '1157916 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569985, finearea: '1157917 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569986, finearea: '1157918 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569987, finearea: '1157919 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569988, finearea: '1157920 _ Hornsby (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 569989, finearea: '1149527 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569990, finearea: '1149538 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569991, finearea: '1149539 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569992, finearea: '1149540 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569993, finearea: '1149541 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569994, finearea: '1149542 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569995, finearea: '1149543 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569996, finearea: '1149544 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569997, finearea: '1149545 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569998, finearea: '1149546 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 569999, finearea: '1149556 _ Hornsby (A)', subregion: 'Epping - North Epping', region: 'Northern Sydney' },
  { geography: 570000, finearea: '1149601 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570001, finearea: '1149602 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570002, finearea: '1149603 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570003, finearea: '1149604 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570004, finearea: '1149605 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570005, finearea: '1149606 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570006, finearea: '1149607 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570007, finearea: '1149608 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570008, finearea: '1149609 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570009, finearea: '1149610 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570010, finearea: '1149611 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570011, finearea: '1149612 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570012, finearea: '1149613 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570013, finearea: '1149614 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570014, finearea: '1149615 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570015, finearea: '1149616 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570016, finearea: '1149617 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570017, finearea: '1149618 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570018, finearea: '1149619 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570019, finearea: '1149620 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570020, finearea: '1149621 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570021, finearea: '1149622 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570022, finearea: '1149623 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570023, finearea: '1149624 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570024, finearea: '1149625 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570025, finearea: '1149626 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570026, finearea: '1149627 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570027, finearea: '1149628 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570028, finearea: '1149629 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570029, finearea: '1149630 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570030, finearea: '1149631 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570031, finearea: '1149632 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570032, finearea: '1149633 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570033, finearea: '1149634 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570034, finearea: '1149635 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570035, finearea: '1149636 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 570036, finearea: '1149637 _ Hornsby (A)', subregion: 'Pennant Hills - Cheltenham', region: 'Northern Sydney' },
  { geography: 571637, finearea: '1140049 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571638, finearea: '1140050 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571639, finearea: '1140051 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571640, finearea: '1140052 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571641, finearea: '1140053 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571642, finearea: '1140054 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571643, finearea: '1140056 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571644, finearea: '1140057 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571645, finearea: '1140058 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571646, finearea: '1140059 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571647, finearea: '1140060 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571648, finearea: '1140061 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571649, finearea: '1140062 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571650, finearea: '1140063 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571651, finearea: '1140064 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571652, finearea: '1140065 _ Lane Cove (A)', subregion: 'Lane Cove - Greenwich', region: 'Northern Sydney' },
  { geography: 571653, finearea: '1140110 _ Lane Cove (A)', subregion: 'St Leonards - Naremburn', region: 'Northern Sydney' },
  { geography: 571654, finearea: '1125730 _ Leeton (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 571655, finearea: '1125740 _ Leeton (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 571656, finearea: '1125741 _ Leeton (A)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 571657, finearea: '1125801 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571658, finearea: '1125802 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571659, finearea: '1125803 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571660, finearea: '1125804 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571661, finearea: '1125805 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571662, finearea: '1125806 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571663, finearea: '1125807 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571664, finearea: '1125808 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571665, finearea: '1125809 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571666, finearea: '1125810 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571667, finearea: '1125811 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571668, finearea: '1125812 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571669, finearea: '1125813 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571670, finearea: '1125814 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571671, finearea: '1125815 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571672, finearea: '1125816 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571673, finearea: '1125817 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571674, finearea: '1125818 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571675, finearea: '1125819 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571676, finearea: '1125820 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571677, finearea: '1125821 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571678, finearea: '1125822 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571679, finearea: '1125823 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571680, finearea: '1125824 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571681, finearea: '1125825 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571682, finearea: '1125826 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571683, finearea: '1125827 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571684, finearea: '1125828 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571685, finearea: '1125829 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571686, finearea: '1125830 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571687, finearea: '1125831 _ Leeton (A)', subregion: 'Leeton', region: 'Riverina-Goulburn Belt' },
  { geography: 571688, finearea: '1125911 _ Leeton (A)', subregion: 'Narrandera', region: 'Riverina-Goulburn Belt' },
  { geography: 571689, finearea: '1124102 _ Lismore (C)', subregion: 'Evans Head', region: 'North East NSW' },
  { geography: 571690, finearea: '1124601 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571691, finearea: '1124602 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571692, finearea: '1124603 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571693, finearea: '1124604 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571694, finearea: '1124605 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571695, finearea: '1124606 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571696, finearea: '1124607 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571697, finearea: '1124608 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571698, finearea: '1124609 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571699, finearea: '1124610 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571700, finearea: '1124611 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571701, finearea: '1124612 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571702, finearea: '1124613 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571703, finearea: '1124614 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571704, finearea: '1124615 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571705, finearea: '1124616 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571706, finearea: '1124617 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571707, finearea: '1124618 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571708, finearea: '1124619 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571709, finearea: '1124620 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571710, finearea: '1124621 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571711, finearea: '1124622 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571712, finearea: '1124623 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571713, finearea: '1124624 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571714, finearea: '1124625 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571715, finearea: '1124626 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571716, finearea: '1124627 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571717, finearea: '1124628 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571718, finearea: '1124629 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571719, finearea: '1124630 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571720, finearea: '1124631 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571721, finearea: '1124632 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571722, finearea: '1124633 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571723, finearea: '1124634 _ Lismore (C)', subregion: 'Goonellabah', region: 'North East NSW' },
  { geography: 571724, finearea: '1124801 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571725, finearea: '1124802 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571726, finearea: '1124803 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571727, finearea: '1124804 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571728, finearea: '1124805 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571729, finearea: '1124806 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571730, finearea: '1124807 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571731, finearea: '1124808 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571732, finearea: '1124809 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571733, finearea: '1124810 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571734, finearea: '1124811 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571735, finearea: '1124812 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571736, finearea: '1124813 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571737, finearea: '1124814 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571738, finearea: '1124815 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571739, finearea: '1124816 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571740, finearea: '1124817 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571741, finearea: '1124818 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571742, finearea: '1124819 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571743, finearea: '1124820 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571744, finearea: '1124821 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571745, finearea: '1124822 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571746, finearea: '1124823 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571747, finearea: '1124824 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571748, finearea: '1124825 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571749, finearea: '1124826 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571750, finearea: '1124827 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571751, finearea: '1124828 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571752, finearea: '1124829 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571753, finearea: '1124830 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571754, finearea: '1124831 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571755, finearea: '1124832 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571756, finearea: '1124833 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571757, finearea: '1124834 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571758, finearea: '1124835 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571759, finearea: '1124836 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571760, finearea: '1124837 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571761, finearea: '1124838 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571762, finearea: '1124839 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571763, finearea: '1124840 _ Lismore (C)', subregion: 'Lismore', region: 'North East NSW' },
  { geography: 571764, finearea: '1124901 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571765, finearea: '1124902 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571766, finearea: '1124903 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571767, finearea: '1124904 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571768, finearea: '1124905 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571769, finearea: '1124906 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571770, finearea: '1124907 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571771, finearea: '1124908 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571772, finearea: '1124909 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571773, finearea: '1124910 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571774, finearea: '1124911 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571775, finearea: '1124912 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571776, finearea: '1124913 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571777, finearea: '1124914 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571778, finearea: '1124915 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571779, finearea: '1124916 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571780, finearea: '1124917 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571781, finearea: '1124918 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571782, finearea: '1124919 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571783, finearea: '1124920 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571784, finearea: '1124921 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571785, finearea: '1124922 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571786, finearea: '1124923 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571787, finearea: '1124924 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571788, finearea: '1124925 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571789, finearea: '1124926 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571790, finearea: '1124927 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571791, finearea: '1124928 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571792, finearea: '1124929 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571793, finearea: '1124930 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571794, finearea: '1124931 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571795, finearea: '1124932 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571796, finearea: '1124933 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571797, finearea: '1124934 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571798, finearea: '1124935 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571799, finearea: '1124936 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571800, finearea: '1124937 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571801, finearea: '1124938 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571802, finearea: '1124939 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571803, finearea: '1124940 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571804, finearea: '1124941 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571805, finearea: '1124942 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571806, finearea: '1124943 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571807, finearea: '1124944 _ Lismore (C)', subregion: 'Lismore Region', region: 'North East NSW' },
  { geography: 571808, finearea: '1106018 _ Lithgow (C)', subregion: 'Bathurst Region', region: 'Regional NSW - Remainder' },
  { geography: 571809, finearea: '1107001 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571810, finearea: '1107002 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571811, finearea: '1107003 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571812, finearea: '1107004 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571813, finearea: '1107005 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571814, finearea: '1107006 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571815, finearea: '1107007 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571816, finearea: '1107008 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571817, finearea: '1107009 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571818, finearea: '1107010 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571819, finearea: '1107011 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571820, finearea: '1107012 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571821, finearea: '1107013 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571822, finearea: '1107014 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571823, finearea: '1107015 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571824, finearea: '1107016 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571825, finearea: '1107017 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571826, finearea: '1107018 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571827, finearea: '1107019 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571828, finearea: '1107020 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571829, finearea: '1107021 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571830, finearea: '1107022 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571831, finearea: '1107023 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571832, finearea: '1107024 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571833, finearea: '1107025 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571834, finearea: '1107026 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571835, finearea: '1107027 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 571836, finearea: '1107028 _ Lithgow (C)', subregion: 'Lithgow', region: 'Regional NSW - Remainder' },
  { geography: 570637, finearea: '1115627 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570638, finearea: '1115628 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570639, finearea: '1116001 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570640, finearea: '1116002 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570641, finearea: '1116003 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570642, finearea: '1116004 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570643, finearea: '1116005 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570644, finearea: '1116006 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570645, finearea: '1116007 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570646, finearea: '1116008 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570647, finearea: '1116009 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570648, finearea: '1116010 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570649, finearea: '1116011 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570650, finearea: '1116012 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570651, finearea: '1116013 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570652, finearea: '1116014 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570653, finearea: '1116015 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570654, finearea: '1116016 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570655, finearea: '1116017 _ Kempsey (A)', subregion: 'South West Rocks', region: 'Regional NSW - Remainder' },
  { geography: 570656, finearea: '1113801 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570657, finearea: '1113802 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570658, finearea: '1113803 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570659, finearea: '1113804 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570660, finearea: '1113805 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570661, finearea: '1113806 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570662, finearea: '1113807 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570663, finearea: '1113808 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570664, finearea: '1113809 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570665, finearea: '1113810 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570666, finearea: '1113811 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570667, finearea: '1113812 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570668, finearea: '1113813 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570669, finearea: '1113814 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570670, finearea: '1113815 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570671, finearea: '1113816 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570672, finearea: '1113817 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570673, finearea: '1113818 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570674, finearea: '1113819 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570675, finearea: '1113820 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570676, finearea: '1113821 _ Kiama (A)', subregion: 'Kiama', region: 'Illawarra-Shoalhaven' },
  { geography: 570677, finearea: '1113901 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570678, finearea: '1113902 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570679, finearea: '1113903 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570680, finearea: '1113904 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570681, finearea: '1113905 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570682, finearea: '1113906 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570683, finearea: '1113907 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570684, finearea: '1113908 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570685, finearea: '1113909 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570686, finearea: '1113910 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570687, finearea: '1113911 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570688, finearea: '1113912 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570689, finearea: '1113913 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570690, finearea: '1113914 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570691, finearea: '1113915 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570692, finearea: '1113916 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570693, finearea: '1113917 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570694, finearea: '1113918 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570695, finearea: '1113919 _ Kiama (A)', subregion: 'Kiama Downs - Minnamurra', region: 'Illawarra-Shoalhaven' },
  { geography: 570696, finearea: '1114001 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570697, finearea: '1114002 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570698, finearea: '1114003 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570699, finearea: '1114004 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570700, finearea: '1114005 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570701, finearea: '1114006 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570702, finearea: '1114007 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570703, finearea: '1114008 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570704, finearea: '1114009 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570705, finearea: '1114010 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570706, finearea: '1114011 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570707, finearea: '1114012 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570708, finearea: '1114013 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570709, finearea: '1114014 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570710, finearea: '1114015 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570711, finearea: '1114016 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570712, finearea: '1114017 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570713, finearea: '1114018 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570714, finearea: '1114019 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570715, finearea: '1114020 _ Kiama (A)', subregion: 'Kiama Hinterland - Gerringong', region: 'Illawarra-Shoalhaven' },
  { geography: 570716, finearea: '1127206 _ Kiama (A)', subregion: 'Berry - Kangaroo Valley', region: 'Illawarra-Shoalhaven' },
  { geography: 570717, finearea: '1128804 _ Kiama (A)', subregion: 'Robertson - Fitzroy Falls', region: 'Illawarra-Shoalhaven' },
  { geography: 570718, finearea: '1157914 _ Ku-ring-gai (A)', subregion: 'Waitara - Wahroonga (West)', region: 'Northern Sydney' },
  { geography: 570719, finearea: '1140701 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570720, finearea: '1140702 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570721, finearea: '1140703 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570722, finearea: '1140704 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570723, finearea: '1140705 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570724, finearea: '1140706 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570725, finearea: '1140707 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570726, finearea: '1140708 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570727, finearea: '1140709 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570728, finearea: '1140710 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570729, finearea: '1140711 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570730, finearea: '1140712 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570731, finearea: '1140713 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570732, finearea: '1140714 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570733, finearea: '1140716 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570734, finearea: '1140718 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570735, finearea: '1140719 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570736, finearea: '1140720 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570737, finearea: '1140721 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570738, finearea: '1140722 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570739, finearea: '1140723 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570740, finearea: '1140724 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570741, finearea: '1140725 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570742, finearea: '1140726 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570743, finearea: '1140727 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570744, finearea: '1140728 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570745, finearea: '1140729 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570746, finearea: '1140730 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570747, finearea: '1140731 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570748, finearea: '1140732 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570749, finearea: '1140733 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570750, finearea: '1140734 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570751, finearea: '1140735 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570752, finearea: '1140736 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570753, finearea: '1140737 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570754, finearea: '1140738 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570755, finearea: '1140739 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570756, finearea: '1140741 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570757, finearea: '1140742 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570758, finearea: '1140743 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570759, finearea: '1140744 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570760, finearea: '1140745 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570761, finearea: '1140746 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570762, finearea: '1140747 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570763, finearea: '1140748 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570764, finearea: '1140749 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570765, finearea: '1140750 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570766, finearea: '1140751 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570767, finearea: '1140752 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570768, finearea: '1140753 _ Ku-ring-gai (A)', subregion: 'Gordon - Killara', region: 'Northern Sydney' },
  { geography: 570769, finearea: '1140801 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570770, finearea: '1140802 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570771, finearea: '1140803 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570772, finearea: '1140804 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570773, finearea: '1140805 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570774, finearea: '1140806 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570775, finearea: '1140807 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570776, finearea: '1140808 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570777, finearea: '1140809 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570778, finearea: '1140810 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570779, finearea: '1140811 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570780, finearea: '1140812 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570781, finearea: '1140813 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570782, finearea: '1140814 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570783, finearea: '1140815 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570784, finearea: '1140816 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570785, finearea: '1140817 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570786, finearea: '1140818 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570787, finearea: '1140819 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570788, finearea: '1140820 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570789, finearea: '1140821 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570790, finearea: '1140822 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570791, finearea: '1140823 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570792, finearea: '1140824 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570793, finearea: '1140825 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570794, finearea: '1140826 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570795, finearea: '1140827 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570796, finearea: '1140829 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570797, finearea: '1140830 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570798, finearea: '1140831 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570799, finearea: '1140832 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570800, finearea: '1140833 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570801, finearea: '1140834 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570802, finearea: '1140835 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570803, finearea: '1140836 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570804, finearea: '1140838 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570805, finearea: '1140839 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570806, finearea: '1140841 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570807, finearea: '1140842 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570808, finearea: '1140843 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570809, finearea: '1140844 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570810, finearea: '1140845 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570811, finearea: '1140846 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570812, finearea: '1140847 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570813, finearea: '1140848 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570814, finearea: '1140849 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570815, finearea: '1140850 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570816, finearea: '1140851 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570817, finearea: '1140852 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570818, finearea: '1140854 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570819, finearea: '1140855 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570820, finearea: '1140856 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570821, finearea: '1140857 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570822, finearea: '1140858 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570823, finearea: '1140859 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570824, finearea: '1140860 _ Ku-ring-gai (A)', subregion: 'Lindfield - Roseville', region: 'Northern Sydney' },
  { geography: 570825, finearea: '1140901 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570826, finearea: '1140902 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570827, finearea: '1140903 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570828, finearea: '1140904 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570829, finearea: '1140905 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570830, finearea: '1140906 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570831, finearea: '1140907 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570832, finearea: '1140908 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570833, finearea: '1140909 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570834, finearea: '1140910 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570835, finearea: '1140911 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 570836, finearea: '1140912 _ Ku-ring-gai (A)', subregion: 'Pymble', region: 'Northern Sydney' },
  { geography: 575328, finearea: '1107742 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575329, finearea: '1107743 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575330, finearea: '1107744 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575331, finearea: '1107745 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575332, finearea: '1107746 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575333, finearea: '1107747 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575334, finearea: '1107748 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575335, finearea: '1107749 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575336, finearea: '1107750 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575337, finearea: '1107751 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575338, finearea: '1107752 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575339, finearea: '1107753 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575340, finearea: '1107754 _ Orange (C)', subregion: 'Orange', region: 'Regional NSW - Remainder' },
  { geography: 575341, finearea: '1107801 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575342, finearea: '1107802 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575343, finearea: '1107804 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575344, finearea: '1107805 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575345, finearea: '1107806 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575346, finearea: '1107807 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575347, finearea: '1107808 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575348, finearea: '1107810 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575349, finearea: '1107811 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575350, finearea: '1107812 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575351, finearea: '1107813 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575352, finearea: '1107814 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575353, finearea: '1107815 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575354, finearea: '1107816 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575355, finearea: '1107817 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575356, finearea: '1107818 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575357, finearea: '1107819 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575358, finearea: '1107820 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575359, finearea: '1107821 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575360, finearea: '1107822 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575361, finearea: '1107823 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575362, finearea: '1107824 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575363, finearea: '1107825 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575364, finearea: '1107826 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575365, finearea: '1107827 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575366, finearea: '1107828 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575367, finearea: '1107829 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575368, finearea: '1107830 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575369, finearea: '1107831 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575370, finearea: '1107833 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575371, finearea: '1107834 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575372, finearea: '1107835 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575373, finearea: '1107836 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575374, finearea: '1107837 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575375, finearea: '1107838 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575376, finearea: '1107839 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575377, finearea: '1107840 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575378, finearea: '1107841 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575379, finearea: '1107842 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575380, finearea: '1107843 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575381, finearea: '1107844 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575382, finearea: '1107845 _ Orange (C)', subregion: 'Orange - North', region: 'Regional NSW - Remainder' },
  { geography: 575383, finearea: '1107912 _ Orange (C)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 575384, finearea: '1107914 _ Orange (C)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 575385, finearea: '1107915 _ Orange (C)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 575386, finearea: '1107916 _ Orange (C)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 575387, finearea: '1107918 _ Orange (C)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 575388, finearea: '1107922 _ Orange (C)', subregion: 'Orange Region', region: 'Regional NSW - Remainder' },
  { geography: 575389, finearea: '1106208 _ Parkes (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 575390, finearea: '1106221 _ Parkes (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 575391, finearea: '1106701 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575392, finearea: '1106702 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575393, finearea: '1106703 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575394, finearea: '1106704 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575395, finearea: '1106705 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575396, finearea: '1106706 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575397, finearea: '1106707 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575398, finearea: '1106708 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575399, finearea: '1106709 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575400, finearea: '1106710 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575401, finearea: '1106711 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575402, finearea: '1106712 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575403, finearea: '1106713 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575404, finearea: '1106714 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575405, finearea: '1106715 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575406, finearea: '1106716 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575407, finearea: '1106717 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575408, finearea: '1106718 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575409, finearea: '1106719 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575410, finearea: '1106720 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575411, finearea: '1106721 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575412, finearea: '1106722 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575413, finearea: '1106723 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575414, finearea: '1106724 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575415, finearea: '1106725 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575416, finearea: '1106726 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575417, finearea: '1106727 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575418, finearea: '1106728 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575419, finearea: '1106729 _ Parkes (A)', subregion: 'Parkes (NSW)', region: 'Regional NSW - Remainder' },
  { geography: 575420, finearea: '1106801 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575421, finearea: '1106802 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575422, finearea: '1106803 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575423, finearea: '1106804 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575424, finearea: '1106805 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575425, finearea: '1106806 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575426, finearea: '1106807 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575427, finearea: '1106808 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575428, finearea: '1106809 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575429, finearea: '1106810 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575430, finearea: '1106811 _ Parkes (A)', subregion: 'Parkes Region', region: 'Regional NSW - Remainder' },
  { geography: 575431, finearea: '1130721 _ Parramatta (C)', subregion: 'Lalor Park - Kings Langley', region: 'Northern Sydney' },
  { geography: 575432, finearea: '1147301 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575433, finearea: '1147302 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575434, finearea: '1147303 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575435, finearea: '1147304 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575436, finearea: '1147305 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575437, finearea: '1147306 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575438, finearea: '1147307 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575439, finearea: '1147308 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575440, finearea: '1147309 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575441, finearea: '1147310 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575442, finearea: '1147311 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575443, finearea: '1147312 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575444, finearea: '1147313 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575445, finearea: '1147316 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575446, finearea: '1147317 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575447, finearea: '1147318 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575448, finearea: '1147319 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575449, finearea: '1147320 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575450, finearea: '1147321 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575451, finearea: '1147322 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575452, finearea: '1147323 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575453, finearea: '1147324 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575454, finearea: '1147325 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575455, finearea: '1147326 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575456, finearea: '1147327 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575457, finearea: '1147328 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575458, finearea: '1147329 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575459, finearea: '1147330 _ Parramatta (C)', subregion: 'Homebush Bay - Silverwater', region: 'South and West Sydney' },
  { geography: 575460, finearea: '1147601 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575461, finearea: '1147602 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575462, finearea: '1147603 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575463, finearea: '1147604 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575464, finearea: '1147605 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575465, finearea: '1147606 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575466, finearea: '1147607 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575467, finearea: '1147608 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575468, finearea: '1147609 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575469, finearea: '1147610 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575470, finearea: '1147611 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575471, finearea: '1147612 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575472, finearea: '1147613 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575473, finearea: '1147614 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575474, finearea: '1147615 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575475, finearea: '1147616 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575476, finearea: '1147617 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575477, finearea: '1147618 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575478, finearea: '1147619 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575479, finearea: '1147620 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575480, finearea: '1147621 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575481, finearea: '1147622 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575482, finearea: '1147623 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575483, finearea: '1147624 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575484, finearea: '1147625 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575485, finearea: '1147626 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575486, finearea: '1147627 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575487, finearea: '1147628 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575488, finearea: '1147629 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575489, finearea: '1147630 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575490, finearea: '1147631 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575491, finearea: '1147632 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575492, finearea: '1147633 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575493, finearea: '1147634 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575494, finearea: '1147635 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575495, finearea: '1147636 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575496, finearea: '1147637 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575497, finearea: '1147638 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575498, finearea: '1147639 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575499, finearea: '1147640 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575500, finearea: '1147641 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575501, finearea: '1147642 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575502, finearea: '1147643 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575503, finearea: '1147644 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575504, finearea: '1147645 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575505, finearea: '1147646 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575506, finearea: '1147647 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575507, finearea: '1147648 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575508, finearea: '1147649 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575509, finearea: '1147650 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575510, finearea: '1147651 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575511, finearea: '1147652 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575512, finearea: '1147653 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575513, finearea: '1147654 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575514, finearea: '1147655 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575515, finearea: '1147656 _ Parramatta (C)', subregion: 'Carlingford', region: 'Northern Sydney' },
  { geography: 575516, finearea: '1147701 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575517, finearea: '1147702 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575518, finearea: '1147703 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575519, finearea: '1147704 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575520, finearea: '1147705 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575521, finearea: '1147706 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575522, finearea: '1147707 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575523, finearea: '1147708 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575524, finearea: '1147709 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575525, finearea: '1147710 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575526, finearea: '1147711 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 575527, finearea: '1147712 _ Parramatta (C)', subregion: 'Ermington - Rydalmere', region: 'Northern Sydney' },
  { geography: 566837, finearea: '1105509 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566838, finearea: '1105510 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566839, finearea: '1105511 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566840, finearea: '1105512 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566841, finearea: '1105513 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566842, finearea: '1105514 _ Central Coast (C) (NSW)', subregion: 'Tuggerah - Kangy Angy', region: 'Central Coast and Hunter' },
  { geography: 566843, finearea: '1105601 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566844, finearea: '1105602 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566845, finearea: '1105603 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566846, finearea: '1105604 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566847, finearea: '1105605 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566848, finearea: '1105606 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566849, finearea: '1105607 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566850, finearea: '1105608 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566851, finearea: '1105609 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566852, finearea: '1105612 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566853, finearea: '1105613 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566854, finearea: '1105614 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566855, finearea: '1105615 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566856, finearea: '1105616 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566857, finearea: '1105617 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566858, finearea: '1105618 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566859, finearea: '1105620 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566860, finearea: '1105621 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566861, finearea: '1105622 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566862, finearea: '1105623 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566863, finearea: '1105624 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566864, finearea: '1105625 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566865, finearea: '1105626 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566866, finearea: '1105627 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566867, finearea: '1105628 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566868, finearea: '1105629 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566869, finearea: '1105630 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566870, finearea: '1105631 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566871, finearea: '1105632 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566872, finearea: '1105633 _ Central Coast (C) (NSW)', subregion: 'Warnervale - Wadalba', region: 'Central Coast and Hunter' },
  { geography: 566873, finearea: '1105701 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566874, finearea: '1105702 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566875, finearea: '1105703 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566876, finearea: '1105704 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566877, finearea: '1105705 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566878, finearea: '1105706 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566879, finearea: '1105707 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566880, finearea: '1105708 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566881, finearea: '1105709 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566882, finearea: '1105710 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566883, finearea: '1105711 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566884, finearea: '1105712 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566885, finearea: '1105713 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566886, finearea: '1105714 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566887, finearea: '1105715 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566888, finearea: '1105716 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566889, finearea: '1105717 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566890, finearea: '1105718 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566891, finearea: '1105719 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566892, finearea: '1105720 _ Central Coast (C) (NSW)', subregion: 'Wyong', region: 'Central Coast and Hunter' },
  { geography: 566893, finearea: '1109801 _ Central Darling (A)', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 566894, finearea: '1109802 _ Central Darling (A)', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 566895, finearea: '1109803 _ Central Darling (A)', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 566896, finearea: '1109807 _ Central Darling (A)', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 566897, finearea: '1109808 _ Central Darling (A)', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 566898, finearea: '1109809 _ Central Darling (A)', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 566899, finearea: '1109810 _ Central Darling (A)', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 566900, finearea: '1109811 _ Central Darling (A)', subregion: 'Far West', region: 'Regional NSW - Remainder' },
  { geography: 566901, finearea: '1110701 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566902, finearea: '1110702 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566903, finearea: '1110703 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566904, finearea: '1110706 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566905, finearea: '1110707 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566906, finearea: '1110708 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566907, finearea: '1110709 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566908, finearea: '1110710 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566909, finearea: '1110712 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566910, finearea: '1110713 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566911, finearea: '1110714 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566912, finearea: '1110715 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566913, finearea: '1110719 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566914, finearea: '1110720 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566915, finearea: '1110722 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566916, finearea: '1110723 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566917, finearea: '1110724 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566918, finearea: '1110725 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566919, finearea: '1110726 _ Cessnock (C)', subregion: 'Branxton - Greta - Pokolbin', region: 'Central Coast and Hunter' },
  { geography: 566920, finearea: '1110801 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566921, finearea: '1110803 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566922, finearea: '1110804 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566923, finearea: '1110805 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566924, finearea: '1110806 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566925, finearea: '1110807 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566926, finearea: '1110808 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566927, finearea: '1110809 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566928, finearea: '1110810 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566929, finearea: '1110811 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566930, finearea: '1110812 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566931, finearea: '1110813 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566932, finearea: '1110814 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566933, finearea: '1110815 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566934, finearea: '1110816 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566935, finearea: '1110817 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566936, finearea: '1110818 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566937, finearea: '1110819 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566938, finearea: '1110820 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566939, finearea: '1110821 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566940, finearea: '1110822 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566941, finearea: '1110823 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566942, finearea: '1110824 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566943, finearea: '1110825 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566944, finearea: '1110826 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566945, finearea: '1110827 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566946, finearea: '1110828 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566947, finearea: '1110829 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566948, finearea: '1110830 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566949, finearea: '1110831 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566950, finearea: '1110832 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566951, finearea: '1110833 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566952, finearea: '1110834 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566953, finearea: '1110835 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566954, finearea: '1110836 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566955, finearea: '1110837 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566956, finearea: '1110838 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566957, finearea: '1110839 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566958, finearea: '1110840 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566959, finearea: '1110841 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566960, finearea: '1110842 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566961, finearea: '1110843 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566962, finearea: '1110844 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566963, finearea: '1110845 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566964, finearea: '1110847 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566965, finearea: '1110848 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566966, finearea: '1110849 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566967, finearea: '1110850 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566968, finearea: '1110851 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566969, finearea: '1110852 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566970, finearea: '1110853 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566971, finearea: '1110854 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566972, finearea: '1110855 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566973, finearea: '1110856 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566974, finearea: '1110857 _ Cessnock (C)', subregion: 'Cessnock', region: 'Central Coast and Hunter' },
  { geography: 566975, finearea: '1110901 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566976, finearea: '1110902 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566977, finearea: '1110903 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566978, finearea: '1110904 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566979, finearea: '1110905 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566980, finearea: '1110906 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566981, finearea: '1110907 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566982, finearea: '1110908 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566983, finearea: '1110909 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566984, finearea: '1110910 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566985, finearea: '1110911 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566986, finearea: '1110912 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566987, finearea: '1110913 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566988, finearea: '1110914 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566989, finearea: '1110915 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566990, finearea: '1110916 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566991, finearea: '1110917 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566992, finearea: '1110918 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566993, finearea: '1110919 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566994, finearea: '1110920 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566995, finearea: '1110921 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566996, finearea: '1110922 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566997, finearea: '1110923 _ Cessnock (C)', subregion: 'Cessnock Region', region: 'Central Coast and Hunter' },
  { geography: 566998, finearea: '1111101 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 566999, finearea: '1111102 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567000, finearea: '1111103 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567001, finearea: '1111104 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567002, finearea: '1111105 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567003, finearea: '1111106 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567004, finearea: '1111107 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567005, finearea: '1111108 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567006, finearea: '1111109 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567007, finearea: '1111110 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567008, finearea: '1111111 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567009, finearea: '1111112 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567010, finearea: '1111113 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567011, finearea: '1111114 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567012, finearea: '1111115 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567013, finearea: '1111116 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567014, finearea: '1111117 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567015, finearea: '1111118 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567016, finearea: '1111119 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567017, finearea: '1111121 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567018, finearea: '1111122 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567019, finearea: '1111123 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567020, finearea: '1111124 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567021, finearea: '1111125 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567022, finearea: '1111126 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567023, finearea: '1111127 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567024, finearea: '1111128 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567025, finearea: '1111129 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567026, finearea: '1111130 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567027, finearea: '1111131 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567028, finearea: '1111132 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567029, finearea: '1111133 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567030, finearea: '1111134 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567031, finearea: '1111135 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567032, finearea: '1111136 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567033, finearea: '1111137 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567034, finearea: '1111138 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567035, finearea: '1111139 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567036, finearea: '1111140 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 578528, finearea: '1153015 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578529, finearea: '1153016 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578530, finearea: '1153017 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578531, finearea: '1153018 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578532, finearea: '1153019 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578533, finearea: '1153020 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578534, finearea: '1153021 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578535, finearea: '1153022 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578536, finearea: '1153023 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578537, finearea: '1153024 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578538, finearea: '1153025 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578539, finearea: '1153026 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578540, finearea: '1153027 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578541, finearea: '1153028 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578542, finearea: '1153029 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578543, finearea: '1153030 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578544, finearea: '1153031 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578545, finearea: '1153032 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578546, finearea: '1153033 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578547, finearea: '1153034 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578548, finearea: '1153035 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578549, finearea: '1153036 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578550, finearea: '1153037 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578551, finearea: '1153038 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578552, finearea: '1153039 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578553, finearea: '1153040 _ Sutherland Shire (A)', subregion: 'Miranda - Yowie Bay', region: 'South and West Sydney' },
  { geography: 578554, finearea: '1153101 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578555, finearea: '1153102 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578556, finearea: '1153103 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578557, finearea: '1153104 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578558, finearea: '1153105 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578559, finearea: '1153106 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578560, finearea: '1153107 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578561, finearea: '1153108 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578562, finearea: '1153109 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578563, finearea: '1153110 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578564, finearea: '1153111 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578565, finearea: '1153112 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578566, finearea: '1153113 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578567, finearea: '1153114 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578568, finearea: '1153115 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578569, finearea: '1153116 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578570, finearea: '1153117 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578571, finearea: '1153118 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578572, finearea: '1153119 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578573, finearea: '1153120 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578574, finearea: '1153121 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578575, finearea: '1153122 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578576, finearea: '1153123 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578577, finearea: '1153124 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578578, finearea: '1153125 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578579, finearea: '1153126 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578580, finearea: '1153127 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578581, finearea: '1153128 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578582, finearea: '1153129 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578583, finearea: '1153130 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578584, finearea: '1153131 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578585, finearea: '1153132 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578586, finearea: '1153133 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578587, finearea: '1153134 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578588, finearea: '1153135 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578589, finearea: '1153136 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578590, finearea: '1153137 _ Sutherland Shire (A)', subregion: 'Sylvania - Taren Point', region: 'South and West Sydney' },
  { geography: 578591, finearea: '1160201 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578592, finearea: '1160202 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578593, finearea: '1160203 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578594, finearea: '1160204 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578595, finearea: '1160205 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578596, finearea: '1160206 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578597, finearea: '1160207 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578598, finearea: '1160208 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578599, finearea: '1160209 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578600, finearea: '1160210 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578601, finearea: '1160211 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578602, finearea: '1160212 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578603, finearea: '1160213 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578604, finearea: '1160214 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578605, finearea: '1160215 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578606, finearea: '1160216 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578607, finearea: '1160217 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578608, finearea: '1160218 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578609, finearea: '1160219 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578610, finearea: '1160220 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578611, finearea: '1160221 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578612, finearea: '1160222 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578613, finearea: '1160223 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578614, finearea: '1160224 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578615, finearea: '1160225 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578616, finearea: '1160226 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578617, finearea: '1160227 _ Sutherland Shire (A)', subregion: 'Caringbah', region: 'South and West Sydney' },
  { geography: 578618, finearea: '1160301 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578619, finearea: '1160302 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578620, finearea: '1160303 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578621, finearea: '1160304 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578622, finearea: '1160305 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578623, finearea: '1160306 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578624, finearea: '1160307 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578625, finearea: '1160308 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578626, finearea: '1160309 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578627, finearea: '1160310 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578628, finearea: '1160311 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578629, finearea: '1160312 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578630, finearea: '1160313 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578631, finearea: '1160314 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578632, finearea: '1160315 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578633, finearea: '1160316 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578634, finearea: '1160317 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578635, finearea: '1160318 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578636, finearea: '1160319 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578637, finearea: '1160320 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578638, finearea: '1160321 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578639, finearea: '1160322 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578640, finearea: '1160323 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578641, finearea: '1160324 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578642, finearea: '1160325 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578643, finearea: '1160326 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578644, finearea: '1160327 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578645, finearea: '1160328 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578646, finearea: '1160329 _ Sutherland Shire (A)', subregion: 'Caringbah South', region: 'South and West Sydney' },
  { geography: 578647, finearea: '1160401 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578648, finearea: '1160402 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578649, finearea: '1160403 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578650, finearea: '1160404 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578651, finearea: '1160405 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578652, finearea: '1160406 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578653, finearea: '1160407 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578654, finearea: '1160408 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578655, finearea: '1160409 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578656, finearea: '1160410 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578657, finearea: '1160411 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578658, finearea: '1160412 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578659, finearea: '1160413 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578660, finearea: '1160414 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578661, finearea: '1160415 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578662, finearea: '1160416 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578663, finearea: '1160417 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578664, finearea: '1160418 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578665, finearea: '1160419 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578666, finearea: '1160420 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578667, finearea: '1160421 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578668, finearea: '1160422 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578669, finearea: '1160423 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578670, finearea: '1160424 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578671, finearea: '1160425 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578672, finearea: '1160426 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578673, finearea: '1160427 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578674, finearea: '1160428 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578675, finearea: '1160429 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578676, finearea: '1160430 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578677, finearea: '1160431 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578678, finearea: '1160432 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578679, finearea: '1160433 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578680, finearea: '1160434 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578681, finearea: '1160435 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578682, finearea: '1160436 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578683, finearea: '1160437 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578684, finearea: '1160438 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578685, finearea: '1160439 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578686, finearea: '1160440 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578687, finearea: '1160441 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578688, finearea: '1160442 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578689, finearea: '1160443 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578690, finearea: '1160444 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578691, finearea: '1160445 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578692, finearea: '1160446 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578693, finearea: '1160447 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578694, finearea: '1160448 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578695, finearea: '1160449 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578696, finearea: '1160450 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578697, finearea: '1160451 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578698, finearea: '1160452 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578699, finearea: '1160453 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578700, finearea: '1160454 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578701, finearea: '1160455 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578702, finearea: '1160456 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578703, finearea: '1160457 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578704, finearea: '1160458 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578705, finearea: '1160459 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578706, finearea: '1160460 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578707, finearea: '1160461 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578708, finearea: '1160462 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578709, finearea: '1160463 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578710, finearea: '1160464 _ Sutherland Shire (A)', subregion: 'Cronulla - Kurnell - Bundeena', region: 'South and West Sydney' },
  { geography: 578711, finearea: '1160501 _ Sutherland Shire (A)', subregion: 'Lilli Pilli - Port Hacking - Dolans Bay', region: 'South and West Sydney' },
  { geography: 578712, finearea: '1160502 _ Sutherland Shire (A)', subregion: 'Lilli Pilli - Port Hacking - Dolans Bay', region: 'South and West Sydney' },
  { geography: 578713, finearea: '1160503 _ Sutherland Shire (A)', subregion: 'Lilli Pilli - Port Hacking - Dolans Bay', region: 'South and West Sydney' },
  { geography: 578714, finearea: '1160504 _ Sutherland Shire (A)', subregion: 'Lilli Pilli - Port Hacking - Dolans Bay', region: 'South and West Sydney' },
  { geography: 578715, finearea: '1160505 _ Sutherland Shire (A)', subregion: 'Lilli Pilli - Port Hacking - Dolans Bay', region: 'South and West Sydney' },
  { geography: 578716, finearea: '1160506 _ Sutherland Shire (A)', subregion: 'Lilli Pilli - Port Hacking - Dolans Bay', region: 'South and West Sydney' },
  { geography: 578717, finearea: '1160507 _ Sutherland Shire (A)', subregion: 'Lilli Pilli - Port Hacking - Dolans Bay', region: 'South and West Sydney' },
  { geography: 578718, finearea: '1160508 _ Sutherland Shire (A)', subregion: 'Lilli Pilli - Port Hacking - Dolans Bay', region: 'South and West Sydney' },
  { geography: 578719, finearea: '1160601 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578720, finearea: '1160602 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578721, finearea: '1160603 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578722, finearea: '1160604 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578723, finearea: '1160605 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578724, finearea: '1160606 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578725, finearea: '1160607 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578726, finearea: '1160608 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 578727, finearea: '1160609 _ Sutherland Shire (A)', subregion: 'Woolaware - Burraneer', region: 'South and West Sydney' },
  { geography: 580528, finearea: '1118816 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580529, finearea: '1118817 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580530, finearea: '1118818 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580531, finearea: '1118819 _ Uralla (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 580532, finearea: '1119206 _ Uralla (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 580533, finearea: '1119214 _ Uralla (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 580534, finearea: '1126701 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580535, finearea: '1126702 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580536, finearea: '1126703 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580537, finearea: '1126704 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580538, finearea: '1126705 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580539, finearea: '1126706 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580540, finearea: '1126707 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580541, finearea: '1126708 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580542, finearea: '1126709 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580543, finearea: '1126710 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580544, finearea: '1126711 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580545, finearea: '1126712 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580546, finearea: '1126713 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580547, finearea: '1126714 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580548, finearea: '1126715 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580549, finearea: '1126716 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580550, finearea: '1126717 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580551, finearea: '1126718 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580552, finearea: '1126719 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580553, finearea: '1126720 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580554, finearea: '1126721 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580555, finearea: '1126722 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580556, finearea: '1126723 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580557, finearea: '1126724 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580558, finearea: '1126725 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580559, finearea: '1126726 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580560, finearea: '1126727 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580561, finearea: '1126728 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580562, finearea: '1126729 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580563, finearea: '1126730 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580564, finearea: '1126731 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580565, finearea: '1126732 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580566, finearea: '1126733 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580567, finearea: '1126734 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580568, finearea: '1126735 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580569, finearea: '1126736 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580570, finearea: '1126737 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580571, finearea: '1126738 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580572, finearea: '1126739 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580573, finearea: '1126740 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580574, finearea: '1126741 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580575, finearea: '1126742 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580576, finearea: '1126743 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - East', region: 'Riverina-Goulburn Belt' },
  { geography: 580577, finearea: '1126801 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580578, finearea: '1126802 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580579, finearea: '1126803 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580580, finearea: '1126804 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580581, finearea: '1126805 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580582, finearea: '1126806 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580583, finearea: '1126807 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580584, finearea: '1126808 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580585, finearea: '1126809 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580586, finearea: '1126810 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580587, finearea: '1126811 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580588, finearea: '1126812 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - North', region: 'Riverina-Goulburn Belt' },
  { geography: 580589, finearea: '1126901 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580590, finearea: '1126902 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580591, finearea: '1126903 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580592, finearea: '1126904 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580593, finearea: '1126906 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580594, finearea: '1126907 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580595, finearea: '1126908 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580596, finearea: '1126909 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580597, finearea: '1126910 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580598, finearea: '1126911 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580599, finearea: '1126912 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580600, finearea: '1126913 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580601, finearea: '1126914 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580602, finearea: '1126915 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580603, finearea: '1126916 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580604, finearea: '1126917 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580605, finearea: '1126920 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580606, finearea: '1126921 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580607, finearea: '1126922 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580608, finearea: '1126923 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580609, finearea: '1126924 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580610, finearea: '1126925 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580611, finearea: '1126926 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580612, finearea: '1126927 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580613, finearea: '1126928 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580614, finearea: '1126929 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580615, finearea: '1126930 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580616, finearea: '1126931 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580617, finearea: '1126932 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580618, finearea: '1126933 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580619, finearea: '1126934 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580620, finearea: '1126935 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580621, finearea: '1126936 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580622, finearea: '1126937 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580623, finearea: '1126938 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580624, finearea: '1126939 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580625, finearea: '1126940 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580626, finearea: '1126941 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580627, finearea: '1126942 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580628, finearea: '1126943 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580629, finearea: '1126944 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580630, finearea: '1126945 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580631, finearea: '1126946 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580632, finearea: '1126947 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580633, finearea: '1126948 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580634, finearea: '1126949 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580635, finearea: '1126950 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580636, finearea: '1126951 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580637, finearea: '1126952 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580638, finearea: '1126953 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580639, finearea: '1126954 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580640, finearea: '1126955 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - South', region: 'Riverina-Goulburn Belt' },
  { geography: 580641, finearea: '1127001 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580642, finearea: '1127003 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580643, finearea: '1127004 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580644, finearea: '1127005 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580645, finearea: '1127006 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580646, finearea: '1127007 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580647, finearea: '1127008 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580648, finearea: '1127009 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580649, finearea: '1127010 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580650, finearea: '1127011 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580651, finearea: '1127012 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580652, finearea: '1127013 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580653, finearea: '1127014 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580654, finearea: '1127015 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580655, finearea: '1127016 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580656, finearea: '1127017 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580657, finearea: '1127018 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580658, finearea: '1127019 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580659, finearea: '1127020 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580660, finearea: '1127021 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580661, finearea: '1127022 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580662, finearea: '1127023 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580663, finearea: '1127024 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580664, finearea: '1127025 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580665, finearea: '1127026 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580666, finearea: '1127027 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580667, finearea: '1127028 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580668, finearea: '1127029 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580669, finearea: '1127030 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580670, finearea: '1127031 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580671, finearea: '1127032 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580672, finearea: '1127033 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580673, finearea: '1127034 _ Wagga Wagga (C)', subregion: 'Wagga Wagga - West', region: 'Riverina-Goulburn Belt' },
  { geography: 580674, finearea: '1127101 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580675, finearea: '1127104 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580676, finearea: '1127105 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580677, finearea: '1127112 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580678, finearea: '1127117 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580679, finearea: '1127118 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580680, finearea: '1127119 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580681, finearea: '1127120 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580682, finearea: '1127121 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580683, finearea: '1127122 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580684, finearea: '1127123 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580685, finearea: '1127124 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580686, finearea: '1127130 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580687, finearea: '1127131 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580688, finearea: '1127132 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580689, finearea: '1127133 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580690, finearea: '1127134 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580691, finearea: '1127135 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580692, finearea: '1127136 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580693, finearea: '1127137 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580694, finearea: '1127138 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580695, finearea: '1127143 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580696, finearea: '1127144 _ Wagga Wagga (C)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 580697, finearea: '1118901 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580698, finearea: '1118902 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580699, finearea: '1118903 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580700, finearea: '1118904 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580701, finearea: '1118905 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580702, finearea: '1118906 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580703, finearea: '1118907 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580704, finearea: '1118908 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580705, finearea: '1118909 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580706, finearea: '1118910 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580707, finearea: '1118911 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580708, finearea: '1118912 _ Walcha (A)', subregion: 'Walcha', region: 'Regional NSW - Remainder' },
  { geography: 580709, finearea: '1109601 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580710, finearea: '1109604 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580711, finearea: '1109605 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580712, finearea: '1109606 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580713, finearea: '1109607 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580714, finearea: '1109608 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580715, finearea: '1109610 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580716, finearea: '1109611 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580717, finearea: '1109612 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580718, finearea: '1109613 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580719, finearea: '1109614 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580720, finearea: '1109615 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580721, finearea: '1109616 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580722, finearea: '1109618 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580723, finearea: '1109619 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580724, finearea: '1109620 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580725, finearea: '1109621 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580726, finearea: '1109622 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 580727, finearea: '1109623 _ Walgett (A)', subregion: 'Walgett - Lightning Ridge', region: 'Regional NSW - Remainder' },
  { geography: 569237, finearea: '1153940 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569238, finearea: '1153941 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569239, finearea: '1153942 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569240, finearea: '1153943 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569241, finearea: '1153944 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569242, finearea: '1153945 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569243, finearea: '1153946 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569244, finearea: '1153947 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569245, finearea: '1153948 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569246, finearea: '1153949 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569247, finearea: '1153950 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569248, finearea: '1153951 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569249, finearea: '1153952 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569250, finearea: '1153953 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569251, finearea: '1153954 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569252, finearea: '1153955 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569253, finearea: '1153956 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569254, finearea: '1153957 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569255, finearea: '1153958 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569256, finearea: '1153959 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569257, finearea: '1153960 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569258, finearea: '1153961 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569259, finearea: '1153962 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569260, finearea: '1153963 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569261, finearea: '1153964 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569262, finearea: '1153965 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569263, finearea: '1153966 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569264, finearea: '1153967 _ Goulburn Mulwaree (A)', subregion: 'Goulburn', region: 'Riverina-Goulburn Belt' },
  { geography: 569265, finearea: '1154001 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569266, finearea: '1154002 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569267, finearea: '1154004 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569268, finearea: '1154007 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569269, finearea: '1154012 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569270, finearea: '1154016 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569271, finearea: '1154020 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569272, finearea: '1154022 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569273, finearea: '1154023 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569274, finearea: '1154025 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569275, finearea: '1154027 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569276, finearea: '1154028 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569277, finearea: '1154029 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569278, finearea: '1154032 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569279, finearea: '1154033 _ Goulburn Mulwaree (A)', subregion: 'Goulburn Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569280, finearea: '1117501 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569281, finearea: '1117502 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569282, finearea: '1117503 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569283, finearea: '1117504 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569284, finearea: '1117505 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569285, finearea: '1117509 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569286, finearea: '1117511 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569287, finearea: '1117512 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569288, finearea: '1117513 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569289, finearea: '1117514 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569290, finearea: '1117515 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569291, finearea: '1117516 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569292, finearea: '1117517 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569293, finearea: '1117518 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569294, finearea: '1117519 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569295, finearea: '1117520 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569296, finearea: '1117521 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569297, finearea: '1117522 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569298, finearea: '1117523 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569299, finearea: '1117524 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569300, finearea: '1117525 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569301, finearea: '1117526 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569302, finearea: '1117527 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569303, finearea: '1117528 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569304, finearea: '1117529 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569305, finearea: '1117530 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569306, finearea: '1117531 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569307, finearea: '1117532 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569308, finearea: '1117533 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569309, finearea: '1117534 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569310, finearea: '1117535 _ Greater Hume Shire (A)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 569311, finearea: '1118105 _ Greater Hume Shire (A)', subregion: 'Corowa Region', region: 'Murray' },
  { geography: 569312, finearea: '1126008 _ Greater Hume Shire (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 569313, finearea: '1126011 _ Greater Hume Shire (A)', subregion: 'Tumbarumba', region: 'Riverina-Goulburn Belt' },
  { geography: 569314, finearea: '1127136 _ Greater Hume Shire (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569315, finearea: '1125601 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569316, finearea: '1125602 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569317, finearea: '1125603 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569318, finearea: '1125604 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569319, finearea: '1125605 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569320, finearea: '1125606 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569321, finearea: '1125608 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569322, finearea: '1125609 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569323, finearea: '1125610 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569324, finearea: '1125611 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569325, finearea: '1125612 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569326, finearea: '1125613 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569327, finearea: '1125615 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569328, finearea: '1125616 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569329, finearea: '1125617 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569330, finearea: '1125618 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569331, finearea: '1125619 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569332, finearea: '1125620 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569333, finearea: '1125621 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569334, finearea: '1125622 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569335, finearea: '1125623 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569336, finearea: '1125624 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569337, finearea: '1125625 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569338, finearea: '1125626 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569339, finearea: '1125627 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569340, finearea: '1125628 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569341, finearea: '1125630 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569342, finearea: '1125631 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569343, finearea: '1125632 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569344, finearea: '1125633 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569345, finearea: '1125634 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569346, finearea: '1125635 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569347, finearea: '1125636 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569348, finearea: '1125637 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569349, finearea: '1125638 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569350, finearea: '1125639 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569351, finearea: '1125640 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569352, finearea: '1125641 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569353, finearea: '1125642 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569354, finearea: '1125643 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569355, finearea: '1125644 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569356, finearea: '1125645 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569357, finearea: '1125646 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569358, finearea: '1125647 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569359, finearea: '1125648 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569360, finearea: '1125649 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569361, finearea: '1125650 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569362, finearea: '1125651 _ Griffith (C)', subregion: 'Griffith (NSW)', region: 'Riverina-Goulburn Belt' },
  { geography: 569363, finearea: '1125701 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569364, finearea: '1125702 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569365, finearea: '1125703 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569366, finearea: '1125704 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569367, finearea: '1125705 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569368, finearea: '1125706 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569369, finearea: '1125709 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569370, finearea: '1125710 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569371, finearea: '1125711 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569372, finearea: '1125712 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569373, finearea: '1125721 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569374, finearea: '1125722 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569375, finearea: '1125723 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569376, finearea: '1125724 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569377, finearea: '1125725 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569378, finearea: '1125726 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569379, finearea: '1125727 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569380, finearea: '1125728 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569381, finearea: '1125729 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569382, finearea: '1125737 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569383, finearea: '1125738 _ Griffith (C)', subregion: 'Griffith Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569384, finearea: '1119809 _ Gunnedah (A)', subregion: 'Narrabri Region', region: 'Regional NSW - Remainder' },
  { geography: 569385, finearea: '1119901 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569386, finearea: '1119902 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569387, finearea: '1119903 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569388, finearea: '1119904 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569389, finearea: '1119905 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569390, finearea: '1119906 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569391, finearea: '1119907 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569392, finearea: '1119908 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569393, finearea: '1119909 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569394, finearea: '1119910 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569395, finearea: '1119911 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569396, finearea: '1119912 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569397, finearea: '1119913 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569398, finearea: '1119914 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569399, finearea: '1119915 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569400, finearea: '1119916 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569401, finearea: '1119917 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569402, finearea: '1119918 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569403, finearea: '1119919 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569404, finearea: '1119920 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569405, finearea: '1119921 _ Gunnedah (A)', subregion: 'Gunnedah', region: 'Regional NSW - Remainder' },
  { geography: 569406, finearea: '1120001 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569407, finearea: '1120004 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569408, finearea: '1120005 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569409, finearea: '1120006 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569410, finearea: '1120008 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569411, finearea: '1120009 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569412, finearea: '1120010 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569413, finearea: '1120011 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569414, finearea: '1120012 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569415, finearea: '1120013 _ Gunnedah (A)', subregion: 'Gunnedah Region', region: 'Regional NSW - Remainder' },
  { geography: 569416, finearea: '1119209 _ Gwydir (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 569417, finearea: '1119301 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569418, finearea: '1119302 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569419, finearea: '1119303 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569420, finearea: '1119304 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569421, finearea: '1119305 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569422, finearea: '1119309 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569423, finearea: '1119310 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569424, finearea: '1119311 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569425, finearea: '1119312 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569426, finearea: '1119313 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569427, finearea: '1119314 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569428, finearea: '1119315 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569429, finearea: '1119316 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569430, finearea: '1119318 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569431, finearea: '1119319 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569432, finearea: '1119320 _ Gwydir (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 569433, finearea: '1119612 _ Gwydir (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 569434, finearea: '1119613 _ Gwydir (A)', subregion: 'Moree Region', region: 'North East NSW' },
  { geography: 569435, finearea: '1120517 _ Gwydir (A)', subregion: 'Tamworth Region', region: 'Regional NSW - Remainder' },
  { geography: 569436, finearea: '1129727 _ Hawkesbury (C)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569637, finearea: '1154313 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569638, finearea: '1154314 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569639, finearea: '1154315 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569640, finearea: '1154316 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569641, finearea: '1154317 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569642, finearea: '1154318 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569643, finearea: '1154319 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569644, finearea: '1154320 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569645, finearea: '1154321 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569646, finearea: '1154322 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569647, finearea: '1154323 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569648, finearea: '1154324 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569649, finearea: '1154325 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569650, finearea: '1154326 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569651, finearea: '1154327 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569652, finearea: '1154328 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569653, finearea: '1154329 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569654, finearea: '1154330 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569655, finearea: '1154401 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569656, finearea: '1154402 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569657, finearea: '1154403 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569658, finearea: '1154404 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569659, finearea: '1154405 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569660, finearea: '1154406 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569661, finearea: '1154407 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569662, finearea: '1154408 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569663, finearea: '1154409 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569664, finearea: '1154410 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569665, finearea: '1154411 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569666, finearea: '1154412 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569667, finearea: '1154413 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569668, finearea: '1154414 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569669, finearea: '1154415 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569670, finearea: '1154416 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569671, finearea: '1154417 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569672, finearea: '1154418 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569673, finearea: '1154419 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569674, finearea: '1154420 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569675, finearea: '1154421 _ Hilltops (A)', subregion: 'Young Region', region: 'Riverina-Goulburn Belt' },
  { geography: 569676, finearea: '1129403 _ Hornsby (A)', subregion: 'Glenhaven', region: 'Northern Sydney' },
  { geography: 569677, finearea: '1155401 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569678, finearea: '1155402 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569679, finearea: '1155403 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569680, finearea: '1155404 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569681, finearea: '1155405 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569682, finearea: '1155406 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569683, finearea: '1155407 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569684, finearea: '1155408 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569685, finearea: '1155409 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569686, finearea: '1155410 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569687, finearea: '1155411 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569688, finearea: '1155412 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569689, finearea: '1155413 _ Hornsby (A)', subregion: 'Castle Hill - East', region: 'Northern Sydney' },
  { geography: 569690, finearea: '1155801 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569691, finearea: '1155802 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569692, finearea: '1155803 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569693, finearea: '1155804 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569694, finearea: '1155805 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569695, finearea: '1155806 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569696, finearea: '1155807 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569697, finearea: '1155808 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569698, finearea: '1155809 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569699, finearea: '1155810 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569700, finearea: '1155811 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569701, finearea: '1155812 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569702, finearea: '1155813 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569703, finearea: '1155814 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569704, finearea: '1155815 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569705, finearea: '1155816 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569706, finearea: '1155817 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569707, finearea: '1155818 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569708, finearea: '1155819 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569709, finearea: '1155820 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569710, finearea: '1155821 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569711, finearea: '1155822 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569712, finearea: '1155823 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569713, finearea: '1155824 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569714, finearea: '1155825 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569715, finearea: '1155826 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569716, finearea: '1155827 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569717, finearea: '1155828 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569718, finearea: '1155829 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569719, finearea: '1155830 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569720, finearea: '1155831 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569721, finearea: '1155832 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569722, finearea: '1155833 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569723, finearea: '1155834 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569724, finearea: '1155835 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569725, finearea: '1155836 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569726, finearea: '1155837 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569727, finearea: '1155838 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569728, finearea: '1155839 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569729, finearea: '1155840 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569730, finearea: '1155841 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569731, finearea: '1155842 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569732, finearea: '1155843 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569733, finearea: '1155844 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569734, finearea: '1155845 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569735, finearea: '1155846 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569736, finearea: '1155847 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569737, finearea: '1155848 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569738, finearea: '1155849 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569739, finearea: '1155850 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569740, finearea: '1155851 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569741, finearea: '1155852 _ Hornsby (A)', subregion: 'Cherrybrook', region: 'Northern Sydney' },
  { geography: 569742, finearea: '1129705 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569743, finearea: '1129715 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569744, finearea: '1129717 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569745, finearea: '1129718 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569746, finearea: '1129719 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569747, finearea: '1129723 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569748, finearea: '1129727 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569749, finearea: '1129730 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569750, finearea: '1129732 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569751, finearea: '1129736 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569752, finearea: '1129737 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569753, finearea: '1129743 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569754, finearea: '1129744 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569755, finearea: '1129745 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569756, finearea: '1129746 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569757, finearea: '1129747 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569758, finearea: '1129748 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569759, finearea: '1129749 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569760, finearea: '1129750 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569761, finearea: '1129756 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569762, finearea: '1129757 _ Hornsby (A)', subregion: 'Dural - Kenthurst - Wisemans Ferry', region: 'Northern Sydney' },
  { geography: 569763, finearea: '1129801 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569764, finearea: '1129802 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569765, finearea: '1129803 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569766, finearea: '1129804 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569767, finearea: '1129805 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569768, finearea: '1129806 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569769, finearea: '1129807 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569770, finearea: '1129808 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569771, finearea: '1129809 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569772, finearea: '1129810 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569773, finearea: '1129811 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569774, finearea: '1129812 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569775, finearea: '1129813 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569776, finearea: '1129814 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569777, finearea: '1129815 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569778, finearea: '1129816 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569779, finearea: '1129817 _ Hornsby (A)', subregion: 'Galston - Laughtondale', region: 'Northern Sydney' },
  { geography: 569780, finearea: '1140301 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569781, finearea: '1140302 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569782, finearea: '1140303 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569783, finearea: '1140304 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569784, finearea: '1140305 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569785, finearea: '1140306 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569786, finearea: '1140307 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569787, finearea: '1140308 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569788, finearea: '1140309 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569789, finearea: '1140310 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569790, finearea: '1140311 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569791, finearea: '1140312 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569792, finearea: '1140313 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569793, finearea: '1140314 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569794, finearea: '1140315 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569795, finearea: '1140316 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569796, finearea: '1140317 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569797, finearea: '1140318 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569798, finearea: '1140319 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569799, finearea: '1140320 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569800, finearea: '1140321 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569801, finearea: '1140322 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569802, finearea: '1140323 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569803, finearea: '1140324 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569804, finearea: '1140325 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569805, finearea: '1140326 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569806, finearea: '1140327 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569807, finearea: '1140328 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569808, finearea: '1140329 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569809, finearea: '1140330 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569810, finearea: '1140331 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569811, finearea: '1140332 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569812, finearea: '1140333 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569813, finearea: '1140334 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569814, finearea: '1140335 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569815, finearea: '1140336 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569816, finearea: '1140337 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569817, finearea: '1140338 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569818, finearea: '1140339 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569819, finearea: '1140340 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569820, finearea: '1140341 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569821, finearea: '1140342 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569822, finearea: '1140343 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569823, finearea: '1140344 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569824, finearea: '1140345 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569825, finearea: '1140346 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569826, finearea: '1140347 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569827, finearea: '1140348 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569828, finearea: '1140349 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569829, finearea: '1140350 _ Hornsby (A)', subregion: 'Asquith - Mount Colah', region: 'Northern Sydney' },
  { geography: 569830, finearea: '1140401 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569831, finearea: '1140402 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569832, finearea: '1140403 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569833, finearea: '1140404 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569834, finearea: '1140405 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569835, finearea: '1140406 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 569836, finearea: '1140407 _ Hornsby (A)', subregion: 'Berowra - Brooklyn - Cowan', region: 'Northern Sydney' },
  { geography: 562237, finearea: '1117201 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562238, finearea: '1117202 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562239, finearea: '1117203 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562240, finearea: '1117204 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562241, finearea: '1117205 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562242, finearea: '1117206 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562243, finearea: '1117207 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562244, finearea: '1117208 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562245, finearea: '1117209 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562246, finearea: '1117210 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562247, finearea: '1117211 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562248, finearea: '1117212 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562249, finearea: '1117213 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562250, finearea: '1117214 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562251, finearea: '1117215 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562252, finearea: '1117216 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562253, finearea: '1117217 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562254, finearea: '1117218 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562255, finearea: '1117219 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562256, finearea: '1117220 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562257, finearea: '1117222 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562258, finearea: '1117224 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562259, finearea: '1117225 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562260, finearea: '1117226 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562261, finearea: '1117227 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562262, finearea: '1117228 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562263, finearea: '1117229 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562264, finearea: '1117230 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562265, finearea: '1117231 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562266, finearea: '1117232 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562267, finearea: '1117233 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562268, finearea: '1117234 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562269, finearea: '1117235 _ Albury (C)', subregion: 'Albury - East', region: 'Murray' },
  { geography: 562270, finearea: '1117301 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562271, finearea: '1117302 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562272, finearea: '1117303 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562273, finearea: '1117304 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562274, finearea: '1117305 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562275, finearea: '1117306 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562276, finearea: '1117307 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562277, finearea: '1117308 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562278, finearea: '1117309 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562279, finearea: '1117310 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562280, finearea: '1117311 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562281, finearea: '1117312 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562282, finearea: '1117313 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562283, finearea: '1117314 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562284, finearea: '1117315 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562285, finearea: '1117316 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562286, finearea: '1117317 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562287, finearea: '1117318 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562288, finearea: '1117319 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562289, finearea: '1117320 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562290, finearea: '1117321 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562291, finearea: '1117322 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562292, finearea: '1117323 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562293, finearea: '1117324 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562294, finearea: '1117325 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562295, finearea: '1117326 _ Albury (C)', subregion: 'Albury - North', region: 'Murray' },
  { geography: 562296, finearea: '1117401 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562297, finearea: '1117402 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562298, finearea: '1117403 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562299, finearea: '1117404 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562300, finearea: '1117405 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562301, finearea: '1117406 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562302, finearea: '1117407 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562303, finearea: '1117408 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562304, finearea: '1117409 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562305, finearea: '1117410 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562306, finearea: '1117411 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562307, finearea: '1117412 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562308, finearea: '1117413 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562309, finearea: '1117414 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562310, finearea: '1117415 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562311, finearea: '1117416 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562312, finearea: '1117417 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562313, finearea: '1117418 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562314, finearea: '1117419 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562315, finearea: '1117420 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562316, finearea: '1117421 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562317, finearea: '1117422 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562318, finearea: '1117423 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562319, finearea: '1117424 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562320, finearea: '1117425 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562321, finearea: '1117426 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562322, finearea: '1117427 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562323, finearea: '1117428 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562324, finearea: '1117429 _ Albury (C)', subregion: 'Albury - South', region: 'Murray' },
  { geography: 562325, finearea: '1117506 _ Albury (C)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 562326, finearea: '1117507 _ Albury (C)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 562327, finearea: '1117508 _ Albury (C)', subregion: 'Albury Region', region: 'Murray' },
  { geography: 562328, finearea: '1117601 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562329, finearea: '1117602 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562330, finearea: '1117603 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562331, finearea: '1117604 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562332, finearea: '1117605 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562333, finearea: '1117606 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562334, finearea: '1117607 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562335, finearea: '1117608 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562336, finearea: '1117609 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562337, finearea: '1117610 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562338, finearea: '1117611 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562339, finearea: '1117612 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562340, finearea: '1117613 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562341, finearea: '1117614 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562342, finearea: '1117615 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562343, finearea: '1117616 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562344, finearea: '1117617 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562345, finearea: '1117618 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562346, finearea: '1117619 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562347, finearea: '1117620 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562348, finearea: '1117621 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562349, finearea: '1117622 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562350, finearea: '1117623 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562351, finearea: '1117624 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562352, finearea: '1117625 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562353, finearea: '1117626 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562354, finearea: '1117627 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562355, finearea: '1117628 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562356, finearea: '1117629 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562357, finearea: '1117630 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562358, finearea: '1117631 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562359, finearea: '1117632 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562360, finearea: '1117633 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562361, finearea: '1117634 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562362, finearea: '1117635 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562363, finearea: '1117636 _ Albury (C)', subregion: 'Lavington', region: 'Murray' },
  { geography: 562364, finearea: '1115628 _ Armidale Regional (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 562365, finearea: '1118601 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562366, finearea: '1118602 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562367, finearea: '1118603 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562368, finearea: '1118604 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562369, finearea: '1118605 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562370, finearea: '1118606 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562371, finearea: '1118607 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562372, finearea: '1118608 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562373, finearea: '1118609 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562374, finearea: '1118610 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562375, finearea: '1118611 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562376, finearea: '1118612 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562377, finearea: '1118613 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562378, finearea: '1118614 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562379, finearea: '1118615 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562380, finearea: '1118616 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562381, finearea: '1118617 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562382, finearea: '1118618 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562383, finearea: '1118619 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562384, finearea: '1118620 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562385, finearea: '1118621 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562386, finearea: '1118622 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562387, finearea: '1118623 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562388, finearea: '1118624 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562389, finearea: '1118625 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562390, finearea: '1118626 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562391, finearea: '1118627 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562392, finearea: '1118628 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562393, finearea: '1118629 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562394, finearea: '1118630 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562395, finearea: '1118631 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562396, finearea: '1118632 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562397, finearea: '1118633 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562398, finearea: '1118634 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562399, finearea: '1118635 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562400, finearea: '1118636 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562401, finearea: '1118637 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562402, finearea: '1118638 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562403, finearea: '1118639 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562404, finearea: '1118640 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562405, finearea: '1118641 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562406, finearea: '1118642 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562407, finearea: '1118643 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562408, finearea: '1118644 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562409, finearea: '1118645 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562410, finearea: '1118646 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562411, finearea: '1118647 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562412, finearea: '1118648 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562413, finearea: '1118649 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562414, finearea: '1118650 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562415, finearea: '1118651 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562416, finearea: '1118652 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562417, finearea: '1118653 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562418, finearea: '1118654 _ Armidale Regional (A)', subregion: 'Armidale', region: 'North East NSW' },
  { geography: 562419, finearea: '1118701 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562420, finearea: '1118702 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562421, finearea: '1118703 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562422, finearea: '1118704 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562423, finearea: '1118705 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562424, finearea: '1118706 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562425, finearea: '1118707 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562426, finearea: '1118708 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562427, finearea: '1118709 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562428, finearea: '1118710 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562429, finearea: '1118711 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562430, finearea: '1118712 _ Armidale Regional (A)', subregion: 'Armidale Region - North', region: 'North East NSW' },
  { geography: 562431, finearea: '1118810 _ Armidale Regional (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 562432, finearea: '1118811 _ Armidale Regional (A)', subregion: 'Armidale Region - South', region: 'North East NSW' },
  { geography: 562433, finearea: '1119215 _ Armidale Regional (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 562434, finearea: '1123601 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562435, finearea: '1123602 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 562436, finearea: '1123603 _ Ballina (A)', subregion: 'Ballina', region: 'North East NSW' },
  { geography: 571037, finearea: '1106204 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571038, finearea: '1106205 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571039, finearea: '1106206 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571040, finearea: '1106207 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571041, finearea: '1106209 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571042, finearea: '1106210 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571043, finearea: '1106211 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571044, finearea: '1106212 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571045, finearea: '1106213 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571046, finearea: '1106214 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571047, finearea: '1106215 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571048, finearea: '1106216 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571049, finearea: '1106217 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571050, finearea: '1106218 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571051, finearea: '1106219 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571052, finearea: '1106220 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571053, finearea: '1106221 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571054, finearea: '1106222 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571055, finearea: '1106223 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571056, finearea: '1106224 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571057, finearea: '1106225 _ Lachlan (A)', subregion: 'Condobolin', region: 'Regional NSW - Remainder' },
  { geography: 571058, finearea: '1106520 _ Lachlan (A)', subregion: 'Forbes', region: 'Regional NSW - Remainder' },
  { geography: 571059, finearea: '1109301 _ Lachlan (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 571060, finearea: '1109507 _ Lachlan (A)', subregion: 'Nyngan - Warren', region: 'Regional NSW - Remainder' },
  { geography: 571061, finearea: '1105014 _ Lake Macquarie (C)', subregion: 'Lake Munmorah - Mannering Park', region: 'Central Coast and Hunter' },
  { geography: 571062, finearea: '1120601 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571063, finearea: '1120602 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571064, finearea: '1120603 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571065, finearea: '1120604 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571066, finearea: '1120605 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571067, finearea: '1120606 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571068, finearea: '1120607 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571069, finearea: '1120608 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571070, finearea: '1120609 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571071, finearea: '1120610 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571072, finearea: '1120611 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571073, finearea: '1120612 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571074, finearea: '1120613 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571075, finearea: '1120614 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571076, finearea: '1120615 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571077, finearea: '1120616 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571078, finearea: '1120617 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571079, finearea: '1120618 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571080, finearea: '1120619 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571081, finearea: '1120620 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571082, finearea: '1120621 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571083, finearea: '1120622 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571084, finearea: '1120623 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571085, finearea: '1120624 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571086, finearea: '1120625 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571087, finearea: '1120626 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571088, finearea: '1120627 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571089, finearea: '1120628 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571090, finearea: '1120629 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571091, finearea: '1120630 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571092, finearea: '1120631 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571093, finearea: '1120632 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571094, finearea: '1120633 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571095, finearea: '1120634 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571096, finearea: '1120635 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571097, finearea: '1120636 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571098, finearea: '1120637 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571099, finearea: '1120638 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571100, finearea: '1120639 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571101, finearea: '1120640 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571102, finearea: '1120641 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571103, finearea: '1120642 _ Lake Macquarie (C)', subregion: 'Belmont - Bennetts Green', region: 'Central Coast and Hunter' },
  { geography: 571104, finearea: '1120701 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571105, finearea: '1120702 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571106, finearea: '1120703 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571107, finearea: '1120704 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571108, finearea: '1120705 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571109, finearea: '1120706 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571110, finearea: '1120707 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571111, finearea: '1120708 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571112, finearea: '1120709 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571113, finearea: '1120710 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571114, finearea: '1120711 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571115, finearea: '1120712 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571116, finearea: '1120713 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571117, finearea: '1120714 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571118, finearea: '1120715 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571119, finearea: '1120716 _ Lake Macquarie (C)', subregion: 'Belmont South - Blacksmiths', region: 'Central Coast and Hunter' },
  { geography: 571120, finearea: '1120801 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571121, finearea: '1120802 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571122, finearea: '1120803 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571123, finearea: '1120804 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571124, finearea: '1120805 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571125, finearea: '1120806 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571126, finearea: '1120807 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571127, finearea: '1120808 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571128, finearea: '1120809 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571129, finearea: '1120810 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571130, finearea: '1120811 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571131, finearea: '1120812 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571132, finearea: '1120813 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571133, finearea: '1120814 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571134, finearea: '1120815 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571135, finearea: '1120816 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571136, finearea: '1120817 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571137, finearea: '1120818 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571138, finearea: '1120819 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571139, finearea: '1120820 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571140, finearea: '1120821 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571141, finearea: '1120822 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571142, finearea: '1120823 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571143, finearea: '1120824 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571144, finearea: '1120825 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571145, finearea: '1120826 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571146, finearea: '1120827 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571147, finearea: '1120828 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571148, finearea: '1120829 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571149, finearea: '1120830 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571150, finearea: '1120831 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571151, finearea: '1120832 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571152, finearea: '1120833 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571153, finearea: '1120834 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571154, finearea: '1120835 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571155, finearea: '1120836 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571156, finearea: '1120837 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571157, finearea: '1120838 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571158, finearea: '1120839 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571159, finearea: '1120840 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571160, finearea: '1120841 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571161, finearea: '1120842 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571162, finearea: '1120843 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571163, finearea: '1120844 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571164, finearea: '1120845 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571165, finearea: '1120846 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571166, finearea: '1120847 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571167, finearea: '1120848 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571168, finearea: '1120849 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571169, finearea: '1120850 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571170, finearea: '1120851 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571171, finearea: '1120852 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571172, finearea: '1120853 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571173, finearea: '1120854 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571174, finearea: '1120855 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571175, finearea: '1120856 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571176, finearea: '1120857 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571177, finearea: '1120858 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571178, finearea: '1120859 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571179, finearea: '1120860 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571180, finearea: '1120861 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571181, finearea: '1120862 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571182, finearea: '1120863 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571183, finearea: '1120864 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571184, finearea: '1120865 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571185, finearea: '1120866 _ Lake Macquarie (C)', subregion: 'Charlestown - Dudley', region: 'Central Coast and Hunter' },
  { geography: 571186, finearea: '1120901 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571187, finearea: '1120902 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571188, finearea: '1120903 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571189, finearea: '1120904 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571190, finearea: '1120905 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571191, finearea: '1120906 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571192, finearea: '1120907 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571193, finearea: '1120908 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571194, finearea: '1120909 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571195, finearea: '1120910 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571196, finearea: '1120911 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571197, finearea: '1120912 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571198, finearea: '1120913 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571199, finearea: '1120914 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571200, finearea: '1120915 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571201, finearea: '1120916 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571202, finearea: '1120917 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571203, finearea: '1120918 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571204, finearea: '1120919 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571205, finearea: '1120920 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571206, finearea: '1120921 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571207, finearea: '1120922 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571208, finearea: '1120923 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571209, finearea: '1120924 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571210, finearea: '1120925 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571211, finearea: '1120926 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571212, finearea: '1120927 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571213, finearea: '1120928 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571214, finearea: '1120929 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571215, finearea: '1120930 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571216, finearea: '1120931 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571217, finearea: '1120932 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571218, finearea: '1120933 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571219, finearea: '1120934 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571220, finearea: '1120935 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571221, finearea: '1120936 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571222, finearea: '1120937 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571223, finearea: '1120938 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571224, finearea: '1120939 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571225, finearea: '1120940 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571226, finearea: '1120941 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571227, finearea: '1120942 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571228, finearea: '1120943 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571229, finearea: '1120944 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571230, finearea: '1120945 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571231, finearea: '1120946 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571232, finearea: '1120947 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571233, finearea: '1120948 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571234, finearea: '1120949 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571235, finearea: '1120950 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 571236, finearea: '1120951 _ Lake Macquarie (C)', subregion: 'Glendale - Cardiff - Hillsborough', region: 'Central Coast and Hunter' },
  { geography: 569437, finearea: '1129901 _ Hawkesbury (C)', subregion: 'Bilpin - Colo - St Albans', region: 'Northern Sydney' },
  { geography: 569438, finearea: '1129902 _ Hawkesbury (C)', subregion: 'Bilpin - Colo - St Albans', region: 'Northern Sydney' },
  { geography: 569439, finearea: '1129903 _ Hawkesbury (C)', subregion: 'Bilpin - Colo - St Albans', region: 'Northern Sydney' },
  { geography: 569440, finearea: '1129904 _ Hawkesbury (C)', subregion: 'Bilpin - Colo - St Albans', region: 'Northern Sydney' },
  { geography: 569441, finearea: '1129905 _ Hawkesbury (C)', subregion: 'Bilpin - Colo - St Albans', region: 'Northern Sydney' },
  { geography: 569442, finearea: '1129906 _ Hawkesbury (C)', subregion: 'Bilpin - Colo - St Albans', region: 'Northern Sydney' },
  { geography: 569443, finearea: '1129907 _ Hawkesbury (C)', subregion: 'Bilpin - Colo - St Albans', region: 'Northern Sydney' },
  { geography: 569444, finearea: '1129908 _ Hawkesbury (C)', subregion: 'Bilpin - Colo - St Albans', region: 'Northern Sydney' },
  { geography: 569445, finearea: '1130001 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569446, finearea: '1130002 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569447, finearea: '1130003 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569448, finearea: '1130004 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569449, finearea: '1130005 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569450, finearea: '1130006 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569451, finearea: '1130007 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569452, finearea: '1130008 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569453, finearea: '1130009 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569454, finearea: '1130010 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569455, finearea: '1130011 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569456, finearea: '1130012 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569457, finearea: '1130013 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569458, finearea: '1130014 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569459, finearea: '1130015 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569460, finearea: '1130016 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569461, finearea: '1130017 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569462, finearea: '1130018 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569463, finearea: '1130019 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569464, finearea: '1130020 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569465, finearea: '1130021 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569466, finearea: '1130022 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569467, finearea: '1130023 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569468, finearea: '1130024 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569469, finearea: '1130025 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569470, finearea: '1130026 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569471, finearea: '1130027 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569472, finearea: '1130028 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569473, finearea: '1130029 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569474, finearea: '1130030 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569475, finearea: '1130031 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569476, finearea: '1130032 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569477, finearea: '1130033 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569478, finearea: '1130034 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569479, finearea: '1130035 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569480, finearea: '1130036 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569481, finearea: '1130037 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569482, finearea: '1130038 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569483, finearea: '1130039 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569484, finearea: '1130040 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569485, finearea: '1130041 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569486, finearea: '1130042 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569487, finearea: '1130043 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569488, finearea: '1130044 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569489, finearea: '1130045 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569490, finearea: '1130046 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569491, finearea: '1130047 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569492, finearea: '1130048 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569493, finearea: '1130049 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569494, finearea: '1130050 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569495, finearea: '1130051 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569496, finearea: '1130052 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569497, finearea: '1130053 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569498, finearea: '1130054 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569499, finearea: '1130055 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569500, finearea: '1130056 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569501, finearea: '1130057 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569502, finearea: '1130058 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569503, finearea: '1130059 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569504, finearea: '1130060 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569505, finearea: '1130061 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569506, finearea: '1130062 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569507, finearea: '1130063 _ Hawkesbury (C)', subregion: 'Kurrajong Heights - Ebenezer', region: 'Northern Sydney' },
  { geography: 569508, finearea: '1130101 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569509, finearea: '1130102 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569510, finearea: '1130103 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569511, finearea: '1130104 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569512, finearea: '1130105 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569513, finearea: '1130106 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569514, finearea: '1130107 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569515, finearea: '1130109 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569516, finearea: '1130110 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569517, finearea: '1130111 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569518, finearea: '1130112 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569519, finearea: '1130113 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569520, finearea: '1130114 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569521, finearea: '1130115 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569522, finearea: '1130116 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569523, finearea: '1130117 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569524, finearea: '1130118 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569525, finearea: '1130119 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569526, finearea: '1130120 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569527, finearea: '1130121 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569528, finearea: '1130122 _ Hawkesbury (C)', subregion: 'Pitt Town - McGraths Hill', region: 'Northern Sydney' },
  { geography: 569529, finearea: '1131234 _ Hawkesbury (C)', subregion: 'Riverstone - Marsden Park', region: 'Northern Sydney' },
  { geography: 569530, finearea: '1145101 _ Hawkesbury (C)', subregion: 'Blackheath - Megalong Valley - Blue Mountains', region: 'Northern Sydney' },
  { geography: 569531, finearea: '1146601 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569532, finearea: '1146602 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569533, finearea: '1146603 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569534, finearea: '1146604 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569535, finearea: '1146605 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569536, finearea: '1146606 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569537, finearea: '1146607 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569538, finearea: '1146608 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569539, finearea: '1146609 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569540, finearea: '1146610 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569541, finearea: '1146611 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569542, finearea: '1146612 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569543, finearea: '1146613 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569544, finearea: '1146614 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569545, finearea: '1146615 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569546, finearea: '1146616 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569547, finearea: '1146617 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569548, finearea: '1146618 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569549, finearea: '1146619 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569550, finearea: '1146620 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569551, finearea: '1146621 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569552, finearea: '1146622 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569553, finearea: '1146623 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569554, finearea: '1146624 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569555, finearea: '1146625 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569556, finearea: '1146626 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569557, finearea: '1146627 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569558, finearea: '1146628 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569559, finearea: '1146629 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569560, finearea: '1146630 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569561, finearea: '1146631 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569562, finearea: '1146632 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569563, finearea: '1146633 _ Hawkesbury (C)', subregion: 'Richmond - Clarendon', region: 'Northern Sydney' },
  { geography: 569564, finearea: '1146701 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569565, finearea: '1146702 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569566, finearea: '1146703 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569567, finearea: '1146704 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569568, finearea: '1146705 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569569, finearea: '1146706 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569570, finearea: '1146707 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569571, finearea: '1146708 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569572, finearea: '1146709 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569573, finearea: '1146710 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569574, finearea: '1146711 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569575, finearea: '1146712 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569576, finearea: '1146713 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569577, finearea: '1146714 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569578, finearea: '1146715 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569579, finearea: '1146716 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569580, finearea: '1146717 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569581, finearea: '1146718 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569582, finearea: '1146719 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569583, finearea: '1146720 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569584, finearea: '1146721 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569585, finearea: '1146722 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569586, finearea: '1146723 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569587, finearea: '1146724 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569588, finearea: '1146725 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569589, finearea: '1146726 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569590, finearea: '1146727 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569591, finearea: '1146728 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569592, finearea: '1146729 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569593, finearea: '1146730 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569594, finearea: '1146731 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569595, finearea: '1146732 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569596, finearea: '1146733 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569597, finearea: '1146734 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569598, finearea: '1146735 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569599, finearea: '1146736 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569600, finearea: '1146737 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569601, finearea: '1146738 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569602, finearea: '1146739 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569603, finearea: '1146740 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569604, finearea: '1146741 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569605, finearea: '1146742 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569606, finearea: '1146743 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569607, finearea: '1146744 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569608, finearea: '1146745 _ Hawkesbury (C)', subregion: 'Windsor - Bligh Park', region: 'Northern Sydney' },
  { geography: 569609, finearea: '1146817 _ Hawkesbury (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 569610, finearea: '1146818 _ Hawkesbury (C)', subregion: 'Yarramundi - Londonderry', region: 'Northern Sydney' },
  { geography: 569611, finearea: '1117701 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569612, finearea: '1117702 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569613, finearea: '1117703 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569614, finearea: '1117704 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569615, finearea: '1117705 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569616, finearea: '1117706 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569617, finearea: '1117707 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569618, finearea: '1117708 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569619, finearea: '1117709 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569620, finearea: '1117710 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569621, finearea: '1117711 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569622, finearea: '1117712 _ Hay (A)', subregion: 'Hay', region: 'Riverina-Goulburn Belt' },
  { geography: 569623, finearea: '1154204 _ Hilltops (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 569624, finearea: '1154206 _ Hilltops (A)', subregion: 'Yass Region', region: 'Capital Region and South Coast' },
  { geography: 569625, finearea: '1154301 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569626, finearea: '1154302 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569627, finearea: '1154303 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569628, finearea: '1154304 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569629, finearea: '1154305 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569630, finearea: '1154306 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569631, finearea: '1154307 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569632, finearea: '1154308 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569633, finearea: '1154309 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569634, finearea: '1154310 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569635, finearea: '1154311 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 569636, finearea: '1154312 _ Hilltops (A)', subregion: 'Young', region: 'Riverina-Goulburn Belt' },
  { geography: 567037, finearea: '1111141 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567038, finearea: '1111142 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567039, finearea: '1111143 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567040, finearea: '1111144 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567041, finearea: '1111145 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567042, finearea: '1111146 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567043, finearea: '1111147 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567044, finearea: '1111148 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567045, finearea: '1111149 _ Cessnock (C)', subregion: 'Kurri Kurri - Abermain', region: 'Central Coast and Hunter' },
  { geography: 567046, finearea: '1111727 _ Cessnock (C)', subregion: 'Maitland - West', region: 'Central Coast and Hunter' },
  { geography: 567047, finearea: '1108001 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567048, finearea: '1108002 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567049, finearea: '1108003 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567050, finearea: '1108004 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567051, finearea: '1108005 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567052, finearea: '1108006 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567053, finearea: '1108007 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567054, finearea: '1108008 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567055, finearea: '1108009 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567056, finearea: '1108010 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567057, finearea: '1108011 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567058, finearea: '1108012 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567059, finearea: '1108013 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567060, finearea: '1108014 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567061, finearea: '1108015 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567062, finearea: '1108016 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567063, finearea: '1108017 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567064, finearea: '1108018 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567065, finearea: '1108019 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567066, finearea: '1108020 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567067, finearea: '1108021 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567068, finearea: '1108022 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567069, finearea: '1108023 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567070, finearea: '1108024 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567071, finearea: '1108025 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567072, finearea: '1108026 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567073, finearea: '1108027 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567074, finearea: '1108028 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567075, finearea: '1108029 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567076, finearea: '1108030 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567077, finearea: '1108031 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567078, finearea: '1108032 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567079, finearea: '1108033 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567080, finearea: '1108034 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567081, finearea: '1108036 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567082, finearea: '1108037 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567083, finearea: '1108038 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567084, finearea: '1108039 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567085, finearea: '1108040 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567086, finearea: '1108041 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567087, finearea: '1108042 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567088, finearea: '1108043 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567089, finearea: '1108044 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567090, finearea: '1108046 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567091, finearea: '1108047 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567092, finearea: '1108048 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567093, finearea: '1108049 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567094, finearea: '1108050 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567095, finearea: '1108051 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567096, finearea: '1108052 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567097, finearea: '1108053 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567098, finearea: '1108054 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567099, finearea: '1108055 _ Clarence Valley (A)', subregion: 'Grafton', region: 'North East NSW' },
  { geography: 567100, finearea: '1108101 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567101, finearea: '1108102 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567102, finearea: '1108103 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567103, finearea: '1108104 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567104, finearea: '1108105 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567105, finearea: '1108106 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567106, finearea: '1108107 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567107, finearea: '1108108 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567108, finearea: '1108109 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567109, finearea: '1108110 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567110, finearea: '1108111 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567111, finearea: '1108112 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567112, finearea: '1108113 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567113, finearea: '1108114 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567114, finearea: '1108115 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567115, finearea: '1108116 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567116, finearea: '1108117 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567117, finearea: '1108118 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567118, finearea: '1108119 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567119, finearea: '1108120 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567120, finearea: '1108121 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567121, finearea: '1108122 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567122, finearea: '1108123 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567123, finearea: '1108124 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567124, finearea: '1108125 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567125, finearea: '1108126 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567126, finearea: '1108127 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567127, finearea: '1108128 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567128, finearea: '1108129 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567129, finearea: '1108130 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567130, finearea: '1108131 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567131, finearea: '1108132 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567132, finearea: '1108133 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567133, finearea: '1108134 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567134, finearea: '1108135 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567135, finearea: '1108136 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567136, finearea: '1108137 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567137, finearea: '1108138 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567138, finearea: '1108139 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567139, finearea: '1108140 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567140, finearea: '1108141 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567141, finearea: '1108143 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567142, finearea: '1108144 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567143, finearea: '1108145 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567144, finearea: '1108146 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567145, finearea: '1108147 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567146, finearea: '1108148 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567147, finearea: '1108149 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567148, finearea: '1108150 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567149, finearea: '1108151 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567150, finearea: '1108152 _ Clarence Valley (A)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567151, finearea: '1108201 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567152, finearea: '1108202 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567153, finearea: '1108203 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567154, finearea: '1108204 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567155, finearea: '1108205 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567156, finearea: '1108206 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567157, finearea: '1108207 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567158, finearea: '1108208 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567159, finearea: '1108209 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567160, finearea: '1108210 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567161, finearea: '1108211 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567162, finearea: '1108212 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567163, finearea: '1108213 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567164, finearea: '1108214 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567165, finearea: '1108215 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567166, finearea: '1108216 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567167, finearea: '1108217 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567168, finearea: '1108218 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567169, finearea: '1108219 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567170, finearea: '1108220 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567171, finearea: '1108221 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567172, finearea: '1108222 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567173, finearea: '1108223 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567174, finearea: '1108224 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567175, finearea: '1108225 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567176, finearea: '1108226 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567177, finearea: '1108227 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567178, finearea: '1108228 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567179, finearea: '1108229 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567180, finearea: '1108230 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567181, finearea: '1108231 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567182, finearea: '1108232 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567183, finearea: '1108233 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567184, finearea: '1108234 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567185, finearea: '1108235 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567186, finearea: '1108236 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567187, finearea: '1108237 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567188, finearea: '1108238 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567189, finearea: '1108239 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567190, finearea: '1108240 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567191, finearea: '1108241 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567192, finearea: '1108242 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567193, finearea: '1108243 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567194, finearea: '1108244 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567195, finearea: '1108245 _ Clarence Valley (A)', subregion: 'Maclean - Yamba - Iluka', region: 'North East NSW' },
  { geography: 567196, finearea: '1108711 _ Clarence Valley (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 567197, finearea: '1108712 _ Clarence Valley (A)', subregion: 'Dorrigo', region: 'North East NSW' },
  { geography: 567198, finearea: '1109117 _ Clarence Valley (A)', subregion: 'Woolgoolga - Arrawarra', region: 'North East NSW' },
  { geography: 567199, finearea: '1124526 _ Clarence Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 567200, finearea: '1124527 _ Clarence Valley (A)', subregion: 'Casino Region', region: 'North East NSW' },
  { geography: 567201, finearea: '1109204 _ Cobar (A)', subregion: 'Bourke - Brewarrina', region: 'Regional NSW - Remainder' },
  { geography: 567202, finearea: '1109302 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567203, finearea: '1109303 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567204, finearea: '1109304 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567205, finearea: '1109305 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567206, finearea: '1109306 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567207, finearea: '1109307 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567208, finearea: '1109308 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567209, finearea: '1109309 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567210, finearea: '1109310 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567211, finearea: '1109311 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567212, finearea: '1109312 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567213, finearea: '1109313 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567214, finearea: '1109315 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567215, finearea: '1109316 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567216, finearea: '1109317 _ Cobar (A)', subregion: 'Cobar', region: 'Regional NSW - Remainder' },
  { geography: 567217, finearea: '1108108 _ Coffs Harbour (C)', subregion: 'Grafton Region', region: 'North East NSW' },
  { geography: 567218, finearea: '1108401 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567219, finearea: '1108402 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567220, finearea: '1108403 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567221, finearea: '1108404 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567222, finearea: '1108405 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567223, finearea: '1108406 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567224, finearea: '1108407 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567225, finearea: '1108408 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567226, finearea: '1108409 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567227, finearea: '1108410 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567228, finearea: '1108411 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567229, finearea: '1108412 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567230, finearea: '1108413 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567231, finearea: '1108414 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567232, finearea: '1108415 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567233, finearea: '1108416 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567234, finearea: '1108417 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567235, finearea: '1108418 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 567236, finearea: '1108419 _ Coffs Harbour (C)', subregion: 'Coffs Harbour - North', region: 'North East NSW' },
  { geography: 570437, finearea: '1139402 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570438, finearea: '1139403 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570439, finearea: '1139404 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570440, finearea: '1139405 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570441, finearea: '1139406 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570442, finearea: '1139407 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570443, finearea: '1139408 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570444, finearea: '1139409 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570445, finearea: '1139410 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570446, finearea: '1139411 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570447, finearea: '1139412 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570448, finearea: '1139413 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570449, finearea: '1139414 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570450, finearea: '1139415 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570451, finearea: '1139416 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570452, finearea: '1139417 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570453, finearea: '1139418 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570454, finearea: '1139419 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570455, finearea: '1139420 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570456, finearea: '1139421 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570457, finearea: '1139422 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570458, finearea: '1139423 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570459, finearea: '1139424 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570460, finearea: '1139425 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570461, finearea: '1139426 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570462, finearea: '1139427 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570463, finearea: '1139428 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570464, finearea: '1139429 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570465, finearea: '1139430 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570466, finearea: '1139431 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570467, finearea: '1139432 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570468, finearea: '1139433 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570469, finearea: '1139434 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570470, finearea: '1139435 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570471, finearea: '1139436 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570472, finearea: '1139437 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570473, finearea: '1139438 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570474, finearea: '1139439 _ Inner West (A)', subregion: 'Dulwich Hill - Lewisham', region: 'Inner Sydney' },
  { geography: 570475, finearea: '1139501 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570476, finearea: '1139502 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570477, finearea: '1139503 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570478, finearea: '1139504 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570479, finearea: '1139505 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570480, finearea: '1139506 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570481, finearea: '1139507 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570482, finearea: '1139508 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570483, finearea: '1139509 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570484, finearea: '1139510 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570485, finearea: '1139511 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570486, finearea: '1139512 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570487, finearea: '1139513 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570488, finearea: '1139514 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570489, finearea: '1139515 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570490, finearea: '1139516 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570491, finearea: '1139517 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570492, finearea: '1139518 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570493, finearea: '1139519 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570494, finearea: '1139520 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570495, finearea: '1139521 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570496, finearea: '1139522 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570497, finearea: '1139523 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570498, finearea: '1139524 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570499, finearea: '1139525 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570500, finearea: '1139526 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570501, finearea: '1139527 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570502, finearea: '1139528 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570503, finearea: '1139529 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570504, finearea: '1139530 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570505, finearea: '1139531 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570506, finearea: '1139532 _ Inner West (A)', subregion: 'Haberfield - Summer Hill', region: 'Inner Sydney' },
  { geography: 570507, finearea: '1119101 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570508, finearea: '1119102 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570509, finearea: '1119103 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570510, finearea: '1119104 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570511, finearea: '1119105 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570512, finearea: '1119106 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570513, finearea: '1119107 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570514, finearea: '1119108 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570515, finearea: '1119109 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570516, finearea: '1119110 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570517, finearea: '1119111 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570518, finearea: '1119112 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570519, finearea: '1119113 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570520, finearea: '1119114 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570521, finearea: '1119115 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570522, finearea: '1119116 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570523, finearea: '1119117 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570524, finearea: '1119118 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570525, finearea: '1119119 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570526, finearea: '1119120 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570527, finearea: '1119121 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570528, finearea: '1119122 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570529, finearea: '1119123 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570530, finearea: '1119124 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570531, finearea: '1119125 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570532, finearea: '1119126 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570533, finearea: '1119127 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570534, finearea: '1119128 _ Inverell (A)', subregion: 'Inverell', region: 'North East NSW' },
  { geography: 570535, finearea: '1119201 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570536, finearea: '1119202 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570537, finearea: '1119203 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570538, finearea: '1119204 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570539, finearea: '1119205 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570540, finearea: '1119207 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570541, finearea: '1119208 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570542, finearea: '1119209 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570543, finearea: '1119210 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570544, finearea: '1119211 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570545, finearea: '1119212 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570546, finearea: '1119213 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570547, finearea: '1119214 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570548, finearea: '1119215 _ Inverell (A)', subregion: 'Inverell Region - East', region: 'North East NSW' },
  { geography: 570549, finearea: '1119306 _ Inverell (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 570550, finearea: '1119307 _ Inverell (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 570551, finearea: '1119308 _ Inverell (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 570552, finearea: '1119317 _ Inverell (A)', subregion: 'Inverell Region - West', region: 'North East NSW' },
  { geography: 570553, finearea: '1126501 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570554, finearea: '1126502 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570555, finearea: '1126503 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570556, finearea: '1126504 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570557, finearea: '1126505 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570558, finearea: '1126506 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570559, finearea: '1126507 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570560, finearea: '1126508 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570561, finearea: '1126509 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570562, finearea: '1126510 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570563, finearea: '1126511 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570564, finearea: '1126512 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570565, finearea: '1126513 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570566, finearea: '1126514 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570567, finearea: '1126515 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570568, finearea: '1126516 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570569, finearea: '1126517 _ Junee (A)', subregion: 'Junee', region: 'Riverina-Goulburn Belt' },
  { geography: 570570, finearea: '1127123 _ Junee (A)', subregion: 'Wagga Wagga Region', region: 'Riverina-Goulburn Belt' },
  { geography: 570571, finearea: '1115501 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570572, finearea: '1115502 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570573, finearea: '1115503 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570574, finearea: '1115504 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570575, finearea: '1115505 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570576, finearea: '1115506 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570577, finearea: '1115507 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570578, finearea: '1115508 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570579, finearea: '1115509 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570580, finearea: '1115510 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570581, finearea: '1115511 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570582, finearea: '1115512 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570583, finearea: '1115513 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570584, finearea: '1115514 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570585, finearea: '1115515 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570586, finearea: '1115516 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570587, finearea: '1115517 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570588, finearea: '1115518 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570589, finearea: '1115519 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570590, finearea: '1115520 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570591, finearea: '1115521 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570592, finearea: '1115522 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570593, finearea: '1115523 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570594, finearea: '1115524 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570595, finearea: '1115525 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570596, finearea: '1115526 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570597, finearea: '1115527 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570598, finearea: '1115528 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570599, finearea: '1115529 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570600, finearea: '1115530 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570601, finearea: '1115531 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570602, finearea: '1115532 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570603, finearea: '1115533 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570604, finearea: '1115534 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570605, finearea: '1115535 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570606, finearea: '1115536 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570607, finearea: '1115537 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570608, finearea: '1115538 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570609, finearea: '1115539 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570610, finearea: '1115540 _ Kempsey (A)', subregion: 'Kempsey', region: 'Regional NSW - Remainder' },
  { geography: 570611, finearea: '1115601 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570612, finearea: '1115602 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570613, finearea: '1115603 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570614, finearea: '1115604 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570615, finearea: '1115605 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570616, finearea: '1115606 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570617, finearea: '1115607 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570618, finearea: '1115608 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570619, finearea: '1115609 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570620, finearea: '1115610 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570621, finearea: '1115611 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570622, finearea: '1115612 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570623, finearea: '1115613 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570624, finearea: '1115614 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570625, finearea: '1115615 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570626, finearea: '1115616 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570627, finearea: '1115617 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570628, finearea: '1115618 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570629, finearea: '1115619 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570630, finearea: '1115620 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570631, finearea: '1115621 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570632, finearea: '1115622 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570633, finearea: '1115623 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570634, finearea: '1115624 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570635, finearea: '1115625 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 570636, finearea: '1115626 _ Kempsey (A)', subregion: 'Kempsey Region', region: 'Regional NSW - Remainder' },
  { geography: 576328, finearea: '1158106 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576329, finearea: '1158107 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576330, finearea: '1158108 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576331, finearea: '1158109 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576332, finearea: '1158110 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576333, finearea: '1158111 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576334, finearea: '1158112 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576335, finearea: '1158113 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576336, finearea: '1158114 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576337, finearea: '1158115 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576338, finearea: '1158116 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576339, finearea: '1158117 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576340, finearea: '1158118 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576341, finearea: '1158119 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576342, finearea: '1158120 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576343, finearea: '1158121 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576344, finearea: '1158122 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576345, finearea: '1158123 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576346, finearea: '1158124 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576347, finearea: '1158125 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576348, finearea: '1158126 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576349, finearea: '1158127 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576350, finearea: '1158128 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576351, finearea: '1158129 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576352, finearea: '1158130 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576353, finearea: '1158131 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576354, finearea: '1158132 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576355, finearea: '1158133 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576356, finearea: '1158134 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576357, finearea: '1158135 _ Penrith (C)', subregion: 'St Marys - North St Marys', region: 'Northern Sydney' },
  { geography: 576358, finearea: '1151803 _ Penrith (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 576359, finearea: '1151804 _ Penrith (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 576360, finearea: '1151806 _ Penrith (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 576361, finearea: '1151807 _ Penrith (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 576362, finearea: '1151808 _ Penrith (C)', subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial', region: 'Northern Sydney' },
  { geography: 576363, finearea: '1116201 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576364, finearea: '1116202 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576365, finearea: '1116203 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576366, finearea: '1116204 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576367, finearea: '1116205 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576368, finearea: '1116206 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576369, finearea: '1116207 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576370, finearea: '1116208 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576371, finearea: '1116209 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576372, finearea: '1116210 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576373, finearea: '1116211 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576374, finearea: '1116212 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576375, finearea: '1116213 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576376, finearea: '1116214 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576377, finearea: '1116215 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576378, finearea: '1116216 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576379, finearea: '1116217 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576380, finearea: '1116218 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576381, finearea: '1116219 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576382, finearea: '1116220 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576383, finearea: '1116221 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576384, finearea: '1116222 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576385, finearea: '1116223 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576386, finearea: '1116224 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576387, finearea: '1116225 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576388, finearea: '1116226 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576389, finearea: '1116227 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576390, finearea: '1116228 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576391, finearea: '1116229 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576392, finearea: '1116230 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576393, finearea: '1116231 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576394, finearea: '1116232 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576395, finearea: '1116233 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576396, finearea: '1116234 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576397, finearea: '1116235 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576398, finearea: '1116236 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576399, finearea: '1116237 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576400, finearea: '1116238 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576401, finearea: '1116239 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576402, finearea: '1116240 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576403, finearea: '1116241 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576404, finearea: '1116242 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576405, finearea: '1116243 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576406, finearea: '1116244 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576407, finearea: '1116245 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576408, finearea: '1116246 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576409, finearea: '1116247 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576410, finearea: '1116248 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576411, finearea: '1116249 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576412, finearea: '1116250 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576413, finearea: '1116251 _ Port Macquarie-Hastings (A)', subregion: 'Laurieton - Bonny Hills', region: 'Regional NSW - Remainder' },
  { geography: 576414, finearea: '1116301 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576415, finearea: '1116302 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576416, finearea: '1116303 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576417, finearea: '1116304 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576418, finearea: '1116305 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576419, finearea: '1116306 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576420, finearea: '1116308 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576421, finearea: '1116309 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576422, finearea: '1116310 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576423, finearea: '1116311 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576424, finearea: '1116312 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576425, finearea: '1116313 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576426, finearea: '1116314 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576427, finearea: '1116315 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576428, finearea: '1116316 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576429, finearea: '1116317 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576430, finearea: '1116318 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576431, finearea: '1116319 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576432, finearea: '1116320 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576433, finearea: '1116321 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576434, finearea: '1116322 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576435, finearea: '1116323 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576436, finearea: '1116324 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576437, finearea: '1116325 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576438, finearea: '1116326 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576439, finearea: '1116327 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576440, finearea: '1116328 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576441, finearea: '1116329 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576442, finearea: '1116330 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576443, finearea: '1116331 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576444, finearea: '1116332 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576445, finearea: '1116333 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576446, finearea: '1116334 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576447, finearea: '1116335 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576448, finearea: '1116336 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576449, finearea: '1116337 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576450, finearea: '1116338 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576451, finearea: '1116339 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576452, finearea: '1116340 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576453, finearea: '1116341 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576454, finearea: '1116342 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576455, finearea: '1116343 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576456, finearea: '1116344 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576457, finearea: '1116345 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576458, finearea: '1116346 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576459, finearea: '1116347 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576460, finearea: '1116348 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576461, finearea: '1116349 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576462, finearea: '1116350 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576463, finearea: '1116351 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576464, finearea: '1116352 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576465, finearea: '1116353 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576466, finearea: '1116354 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576467, finearea: '1116355 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576468, finearea: '1116356 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576469, finearea: '1116357 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576470, finearea: '1116358 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576471, finearea: '1116359 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576472, finearea: '1116360 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576473, finearea: '1116361 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576474, finearea: '1116362 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576475, finearea: '1116364 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576476, finearea: '1116365 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576477, finearea: '1116366 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576478, finearea: '1116368 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576479, finearea: '1116369 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576480, finearea: '1116370 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576481, finearea: '1116371 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576482, finearea: '1116372 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576483, finearea: '1116373 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576484, finearea: '1116374 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - East', region: 'Regional NSW - Remainder' },
  { geography: 576485, finearea: '1116401 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576486, finearea: '1116402 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576487, finearea: '1116403 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576488, finearea: '1116404 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576489, finearea: '1116405 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576490, finearea: '1116406 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576491, finearea: '1116407 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576492, finearea: '1116408 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576493, finearea: '1116409 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576494, finearea: '1116410 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576495, finearea: '1116411 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576496, finearea: '1116412 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576497, finearea: '1116413 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576498, finearea: '1116414 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576499, finearea: '1116415 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576500, finearea: '1116416 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576501, finearea: '1116417 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576502, finearea: '1116418 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576503, finearea: '1116419 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576504, finearea: '1116420 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576505, finearea: '1116421 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576506, finearea: '1116422 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576507, finearea: '1116423 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576508, finearea: '1116424 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576509, finearea: '1116425 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576510, finearea: '1116426 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576511, finearea: '1116427 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576512, finearea: '1116428 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576513, finearea: '1116429 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576514, finearea: '1116430 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576515, finearea: '1116431 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576516, finearea: '1116432 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576517, finearea: '1116433 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576518, finearea: '1116434 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576519, finearea: '1116435 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576520, finearea: '1116436 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576521, finearea: '1116437 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576522, finearea: '1116438 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie - West', region: 'Regional NSW - Remainder' },
  { geography: 576523, finearea: '1116501 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576524, finearea: '1116502 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576525, finearea: '1116503 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576526, finearea: '1116504 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
  { geography: 576527, finearea: '1116505 _ Port Macquarie-Hastings (A)', subregion: 'Port Macquarie Region', region: 'Regional NSW - Remainder' },
];

const regions = [
  {
    region: 'Capital Region and South Coast',
    state: 'New South Wales',
    geography: 1977,
  },
  {
    region: 'Central Coast and Hunter',
    state: 'New South Wales',
    geography: 1978,
  },
  {
    region: 'Illawarra-Shoalhaven',
    state: 'New South Wales',
    geography: 1979,
  },
  {
    region: 'Inner Sydney',
    state: 'New South Wales',
    geography: 1980,
  },
  {
    region: 'Murray',
    state: 'New South Wales',
    geography: 1981,
  },
  {
    region: 'North East NSW',
    state: 'New South Wales',
    geography: 1982,
  },
  {
    region: 'Northern Sydney',
    state: 'New South Wales',
    geography: 1983,
  },
  {
    region: 'Regional NSW - Remainder',
    state: 'New South Wales',
    geography: 1984,
  },
  {
    region: 'Riverina-Goulburn Belt',
    state: 'New South Wales',
    geography: 1985,
  },
  {
    region: 'South and West Sydney',
    state: 'New South Wales',
    geography: 1986,
  },
];

const subregions = [
  {
    subregion: 'Acacia Gardens',
    region: 'Northern Sydney',
    geography: 1997,
  },
  {
    subregion: 'Adamstown - Kotara',
    region: 'Central Coast and Hunter',
    geography: 1998,
  },
  {
    subregion: 'Albion Park - Macquarie Pass',
    region: 'Illawarra-Shoalhaven',
    geography: 1999,
  },
  {
    subregion: 'Albion Park Rail',
    region: 'Illawarra-Shoalhaven',
    geography: 2000,
  },
  {
    subregion: 'Albury - East',
    region: 'Murray',
    geography: 2001,
  },
  {
    subregion: 'Albury - North',
    region: 'Murray',
    geography: 2002,
  },
  {
    subregion: 'Albury - South',
    region: 'Murray',
    geography: 2003,
  },
  {
    subregion: 'Albury Region',
    region: 'Murray',
    geography: 2004,
  },
  {
    subregion: 'Anna Bay',
    region: 'Central Coast and Hunter',
    geography: 2005,
  },
  {
    subregion: 'Armidale',
    region: 'North East NSW',
    geography: 2006,
  },
  {
    subregion: 'Armidale Region - North',
    region: 'North East NSW',
    geography: 2007,
  },
  {
    subregion: 'Armidale Region - South',
    region: 'North East NSW',
    geography: 2008,
  },
  {
    subregion: 'Arncliffe - Bardwell Valley',
    region: 'Inner Sydney',
    geography: 2009,
  },
  {
    subregion: 'Ashcroft - Busby - Miller',
    region: 'South and West Sydney',
    geography: 2010,
  },
  {
    subregion: 'Ashfield',
    region: 'Inner Sydney',
    geography: 2011,
  },
  {
    subregion: 'Asquith - Mount Colah',
    region: 'Northern Sydney',
    geography: 2012,
  },
  {
    subregion: 'Auburn - Central',
    region: 'South and West Sydney',
    geography: 2013,
  },
  {
    subregion: 'Auburn - North',
    region: 'South and West Sydney',
    geography: 2014,
  },
  {
    subregion: 'Auburn - South',
    region: 'South and West Sydney',
    geography: 2015,
  },
  {
    subregion: 'Austral - Greendale - Badgerys Creek',
    region: 'South and West Sydney',
    geography: 2016,
  },
  {
    subregion: 'Avalon - Palm Beach',
    region: 'Northern Sydney',
    geography: 2017,
  },
  {
    subregion: 'Avoca Beach - Copacabana',
    region: 'Central Coast and Hunter',
    geography: 2018,
  },
  {
    subregion: 'Balgowlah - Clontarf - Seaforth',
    region: 'Northern Sydney',
    geography: 2019,
  },
  {
    subregion: 'Balgownie - Fairy Meadow',
    region: 'Illawarra-Shoalhaven',
    geography: 2020,
  },
  {
    subregion: 'Ballina',
    region: 'North East NSW',
    geography: 2021,
  },
  {
    subregion: 'Ballina Region',
    region: 'North East NSW',
    geography: 2022,
  },
  {
    subregion: 'Balmain',
    region: 'Inner Sydney',
    geography: 2023,
  },
  {
    subregion: 'Bangalow',
    region: 'North East NSW',
    geography: 2024,
  },
  {
    subregion: 'Bankstown - North',
    region: 'South and West Sydney',
    geography: 2025,
  },
  {
    subregion: 'Bankstown - South',
    region: 'South and West Sydney',
    geography: 2026,
  },
  {
    subregion: 'Banora Point',
    region: 'North East NSW',
    geography: 2027,
  },
  {
    subregion: 'Bargo',
    region: 'South and West Sydney',
    geography: 2028,
  },
  {
    subregion: 'Bass Hill - Georges Hall',
    region: 'South and West Sydney',
    geography: 2029,
  },
  {
    subregion: 'Bateau Bay - Killarney Vale',
    region: 'Central Coast and Hunter',
    geography: 2030,
  },
  {
    subregion: 'Batemans Bay',
    region: 'Capital Region and South Coast',
    geography: 2031,
  },
  {
    subregion: 'Batemans Bay - South',
    region: 'Capital Region and South Coast',
    geography: 2032,
  },
  {
    subregion: 'Bathurst',
    region: 'Regional NSW - Remainder',
    geography: 2033,
  },
  {
    subregion: 'Bathurst - East',
    region: 'Regional NSW - Remainder',
    geography: 2034,
  },
  {
    subregion: 'Bathurst Region',
    region: 'Regional NSW - Remainder',
    geography: 2035,
  },
  {
    subregion: 'Baulkham Hills (East)',
    region: 'Northern Sydney',
    geography: 2036,
  },
  {
    subregion: 'Baulkham Hills (West) - Bella Vista',
    region: 'Northern Sydney',
    geography: 2037,
  },
  {
    subregion: 'Bayview - Elanora Heights',
    region: 'Northern Sydney',
    geography: 2038,
  },
  {
    subregion: 'Beacon Hill - Narraweena',
    region: 'Northern Sydney',
    geography: 2039,
  },
  {
    subregion: 'Bega - Tathra',
    region: 'Capital Region and South Coast',
    geography: 2040,
  },
  {
    subregion: 'Bega-Eden Hinterland',
    region: 'Capital Region and South Coast',
    geography: 2041,
  },
  {
    subregion: 'Bellingen',
    region: 'North East NSW',
    geography: 2042,
  },
  {
    subregion: 'Belmont - Bennetts Green',
    region: 'Central Coast and Hunter',
    geography: 2043,
  },
  {
    subregion: 'Belmont South - Blacksmiths',
    region: 'Central Coast and Hunter',
    geography: 2044,
  },
  {
    subregion: 'Belmore - Belfield',
    region: 'South and West Sydney',
    geography: 2045,
  },
  {
    subregion: 'Berala',
    region: 'South and West Sydney',
    geography: 2046,
  },
  {
    subregion: 'Beresfield - Hexham',
    region: 'Central Coast and Hunter',
    geography: 2047,
  },
  {
    subregion: 'Berkeley - Lake Heights - Cringila',
    region: 'Illawarra-Shoalhaven',
    geography: 2048,
  },
  {
    subregion: 'Berowra - Brooklyn - Cowan',
    region: 'Northern Sydney',
    geography: 2049,
  },
  {
    subregion: 'Berry - Kangaroo Valley',
    region: 'Illawarra-Shoalhaven',
    geography: 2050,
  },
  {
    subregion: 'Bexley',
    region: 'Inner Sydney',
    geography: 2051,
  },
  {
    subregion: 'Bidwill - Hebersham - Emerton',
    region: 'Northern Sydney',
    geography: 2052,
  },
  {
    subregion: 'Bilpin - Colo - St Albans',
    region: 'Northern Sydney',
    geography: 2053,
  },
  {
    subregion: 'Blackheath - Megalong Valley - Blue Mountains',
    region: 'Northern Sydney',
    geography: 2054,
  },
  {
    subregion: 'Blacktown (East) - Kings Park',
    region: 'Northern Sydney',
    geography: 2055,
  },
  {
    subregion: 'Blacktown (North) - Marayong',
    region: 'Northern Sydney',
    geography: 2056,
  },
  {
    subregion: 'Blacktown (South)',
    region: 'Northern Sydney',
    geography: 2057,
  },
  {
    subregion: 'Blacktown (West)',
    region: 'Northern Sydney',
    geography: 2058,
  },
  {
    subregion: 'Blaxland - Warrimoo - Lapstone',
    region: 'Northern Sydney',
    geography: 2059,
  },
  {
    subregion: 'Blayney',
    region: 'Regional NSW - Remainder',
    geography: 2060,
  },
  {
    subregion: 'Blue Haven - San Remo',
    region: 'Central Coast and Hunter',
    geography: 2061,
  },
  {
    subregion: 'Bolton Point - Teralba',
    region: 'Central Coast and Hunter',
    geography: 2062,
  },
  {
    subregion: 'Bombala',
    region: 'Capital Region and South Coast',
    geography: 2063,
  },
  {
    subregion: 'Bondi - Tamarama - Bronte',
    region: 'Inner Sydney',
    geography: 2064,
  },
  {
    subregion: 'Bondi Beach - North Bondi',
    region: 'Inner Sydney',
    geography: 2065,
  },
  {
    subregion: 'Bondi Junction - Waverly',
    region: 'Inner Sydney',
    geography: 2066,
  },
  {
    subregion: 'Bonnells Bay - Silverwater',
    region: 'Central Coast and Hunter',
    geography: 2067,
  },
  {
    subregion: 'Bonnyrigg Heights - Bonnyrigg',
    region: 'South and West Sydney',
    geography: 2068,
  },
  {
    subregion: 'Bossley Park - Abbotsbury',
    region: 'South and West Sydney',
    geography: 2069,
  },
  {
    subregion: 'Botany - Airport - Industrial',
    region: 'Inner Sydney',
    geography: 2070,
  },
  {
    subregion: 'Bourke - Brewarrina',
    region: 'Regional NSW - Remainder',
    geography: 2071,
  },
  {
    subregion: 'Bowral',
    region: 'Illawarra-Shoalhaven',
    geography: 2072,
  },
  {
    subregion: 'Box Head - MacMasters Beach',
    region: 'Central Coast and Hunter',
    geography: 2073,
  },
  {
    subregion: 'Bradbury - Wedderburn - Holsworthy Military Area',
    region: 'South and West Sydney',
    geography: 2074,
  },
  {
    subregion: 'Braidwood',
    region: 'Capital Region and South Coast',
    geography: 2075,
  },
  {
    subregion: 'Branxton - Greta - Pokolbin',
    region: 'Central Coast and Hunter',
    geography: 2076,
  },
  {
    subregion: 'Broken Hill',
    region: 'Regional NSW - Remainder',
    geography: 2077,
  },
  {
    subregion: 'Broulee - Tomakin',
    region: 'Capital Region and South Coast',
    geography: 2078,
  },
  {
    subregion: 'Brunswick Heads - Ocean Shores',
    region: 'North East NSW',
    geography: 2079,
  },
  {
    subregion: 'Budgewoi - Buff Point - Halekulani',
    region: 'Central Coast and Hunter',
    geography: 2080,
  },
  {
    subregion: 'Bulahdelah - Stroud',
    region: 'Central Coast and Hunter',
    geography: 2081,
  },
  {
    subregion: 'Burwood - Croydon',
    region: 'South and West Sydney',
    geography: 2082,
  },
  {
    subregion: 'Byron Bay',
    region: 'North East NSW',
    geography: 2083,
  },
  {
    subregion: 'Cabramatta - Lansvale',
    region: 'South and West Sydney',
    geography: 2084,
  },
  {
    subregion: 'Cabramatta West - Mount Pritchard',
    region: 'South and West Sydney',
    geography: 2085,
  },
  {
    subregion: 'Calga - Kulnura',
    region: 'Central Coast and Hunter',
    geography: 2086,
  },
  {
    subregion: 'Callala Bay - Currarong',
    region: 'Illawarra-Shoalhaven',
    geography: 2087,
  },
  {
    subregion: 'Cambridge Park',
    region: 'Northern Sydney',
    geography: 2088,
  },
  {
    subregion: 'Camden - Ellis Lane',
    region: 'South and West Sydney',
    geography: 2089,
  },
  {
    subregion: 'Campbelltown - Woodbine',
    region: 'South and West Sydney',
    geography: 2090,
  },
  {
    subregion: 'Canley Vale - Canley Heights',
    region: 'South and West Sydney',
    geography: 2091,
  },
  {
    subregion: 'Canterbury (North) - Ashbury',
    region: 'Inner Sydney',
    geography: 2092,
  },
  {
    subregion: 'Canterbury (South) - Campsie',
    region: 'South and West Sydney',
    geography: 2093,
  },
  {
    subregion: 'Caringbah',
    region: 'South and West Sydney',
    geography: 2094,
  },
  {
    subregion: 'Caringbah South',
    region: 'South and West Sydney',
    geography: 2095,
  },
  {
    subregion: 'Carlingford',
    region: 'Northern Sydney',
    geography: 2096,
  },
  {
    subregion: 'Casino',
    region: 'North East NSW',
    geography: 2097,
  },
  {
    subregion: 'Casino Region',
    region: 'North East NSW',
    geography: 2098,
  },
  {
    subregion: 'Castle Hill - Central',
    region: 'Northern Sydney',
    geography: 2099,
  },
  {
    subregion: 'Castle Hill - East',
    region: 'Northern Sydney',
    geography: 2100,
  },
  {
    subregion: 'Castle Hill - North',
    region: 'Northern Sydney',
    geography: 2101,
  },
  {
    subregion: 'Castle Hill - South',
    region: 'Northern Sydney',
    geography: 2102,
  },
  {
    subregion: 'Castle Hill - West',
    region: 'Northern Sydney',
    geography: 2103,
  },
  {
    subregion: 'Castlereagh - Cranebrook',
    region: 'Northern Sydney',
    geography: 2104,
  },
  {
    subregion: 'Casula',
    region: 'South and West Sydney',
    geography: 2105,
  },
  {
    subregion: 'Cecil Hills',
    region: 'South and West Sydney',
    geography: 2106,
  },
  {
    subregion: 'Cessnock',
    region: 'Central Coast and Hunter',
    geography: 2107,
  },
  {
    subregion: 'Cessnock Region',
    region: 'Central Coast and Hunter',
    geography: 2108,
  },
  {
    subregion: 'Charlestown - Dudley',
    region: 'Central Coast and Hunter',
    geography: 2109,
  },
  {
    subregion: 'Chatswood (East) - Artarmon',
    region: 'Northern Sydney',
    geography: 2110,
  },
  {
    subregion: 'Chatswood (West) - Lane Cove North',
    region: 'Northern Sydney',
    geography: 2111,
  },
  {
    subregion: 'Cherrybrook',
    region: 'Northern Sydney',
    geography: 2112,
  },
  {
    subregion: 'Chester Hill - Sefton',
    region: 'South and West Sydney',
    geography: 2113,
  },
  {
    subregion: 'Chipping Norton - Moorebank',
    region: 'South and West Sydney',
    geography: 2114,
  },
  {
    subregion: 'Chittaway Bay - Tumbi Umbi',
    region: 'Central Coast and Hunter',
    geography: 2115,
  },
  {
    subregion: 'Chullora',
    region: 'South and West Sydney',
    geography: 2116,
  },
  {
    subregion: 'Claymore - Eagle Vale - Raby',
    region: 'South and West Sydney',
    geography: 2117,
  },
  {
    subregion: 'Cobar',
    region: 'Regional NSW - Remainder',
    geography: 2118,
  },
  {
    subregion: 'Cobbitty - Leppington',
    region: 'South and West Sydney',
    geography: 2119,
  },
  {
    subregion: 'Coffs Harbour - North',
    region: 'North East NSW',
    geography: 2120,
  },
  {
    subregion: 'Coffs Harbour - South',
    region: 'North East NSW',
    geography: 2121,
  },
  {
    subregion: 'Colyton - Oxley Park',
    region: 'Northern Sydney',
    geography: 2122,
  },
  {
    subregion: 'Concord - Mortlake - Cabarita',
    region: 'South and West Sydney',
    geography: 2123,
  },
  {
    subregion: 'Concord West - North Strathfield',
    region: 'South and West Sydney',
    geography: 2124,
  },
  {
    subregion: 'Condell Park',
    region: 'South and West Sydney',
    geography: 2125,
  },
  {
    subregion: 'Condobolin',
    region: 'Regional NSW - Remainder',
    geography: 2126,
  },
  {
    subregion: 'Coogee - Clovelly',
    region: 'Inner Sydney',
    geography: 2127,
  },
  {
    subregion: 'Cooma',
    region: 'Capital Region and South Coast',
    geography: 2128,
  },
  {
    subregion: 'Cooma Region - Deua - Wadbilliga',
    region: 'Capital Region and South Coast',
    geography: 2129,
  },
  {
    subregion: 'Coonabarabran',
    region: 'Regional NSW - Remainder',
    geography: 2130,
  },
  {
    subregion: 'Coonamble',
    region: 'Regional NSW - Remainder',
    geography: 2131,
  },
  {
    subregion: 'Cootamundra',
    region: 'Riverina-Goulburn Belt',
    geography: 2132,
  },
  {
    subregion: 'Coramba - Nana Glen - Bucca',
    region: 'North East NSW',
    geography: 2133,
  },
  {
    subregion: 'Corowa',
    region: 'Murray',
    geography: 2134,
  },
  {
    subregion: 'Corowa Region',
    region: 'Murray',
    geography: 2135,
  },
  {
    subregion: 'Corrimal - Tarrawanna - Bellambi',
    region: 'Illawarra-Shoalhaven',
    geography: 2136,
  },
  {
    subregion: 'Cowra',
    region: 'Regional NSW - Remainder',
    geography: 2137,
  },
  {
    subregion: 'Cowra Region',
    region: 'Regional NSW - Remainder',
    geography: 2138,
  },
  {
    subregion: 'Cremorne - Cammeray',
    region: 'Northern Sydney',
    geography: 2139,
  },
  {
    subregion: 'Cromer',
    region: 'Northern Sydney',
    geography: 2140,
  },
  {
    subregion: 'Cronulla - Kurnell - Bundeena',
    region: 'South and West Sydney',
    geography: 2141,
  },
  {
    subregion: 'Crows Nest - Waverton',
    region: 'Northern Sydney',
    geography: 2142,
  },
  {
    subregion: 'Croydon Park - Enfield',
    region: 'South and West Sydney',
    geography: 2143,
  },
  {
    subregion: 'Culburra Beach',
    region: 'Illawarra-Shoalhaven',
    geography: 2144,
  },
  {
    subregion: 'Dapto - Avondale',
    region: 'Illawarra-Shoalhaven',
    geography: 2145,
  },
  {
    subregion: 'Darlinghurst',
    region: 'Inner Sydney',
    geography: 2146,
  },
  {
    subregion: 'Dee Why - North Curl Curl',
    region: 'Northern Sydney',
    geography: 2147,
  },
  {
    subregion: 'Deniliquin',
    region: 'Murray',
    geography: 2148,
  },
  {
    subregion: 'Deniliquin Region',
    region: 'Murray',
    geography: 2149,
  },
  {
    subregion: 'Doonside - Woodcroft',
    region: 'Northern Sydney',
    geography: 2150,
  },
  {
    subregion: 'Dorrigo',
    region: 'North East NSW',
    geography: 2151,
  },
  {
    subregion: 'Double Bay - Bellevue Hill',
    region: 'Inner Sydney',
    geography: 2152,
  },
  {
    subregion: 'Douglas Park - Appin',
    region: 'South and West Sydney',
    geography: 2153,
  },
  {
    subregion: 'Dover Heights',
    region: 'Inner Sydney',
    geography: 2154,
  },
  {
    subregion: 'Drummoyne - Rodd Point',
    region: 'South and West Sydney',
    geography: 2155,
  },
  {
    subregion: 'Dubbo - East',
    region: 'Regional NSW - Remainder',
    geography: 2156,
  },
  {
    subregion: 'Dubbo - South',
    region: 'Regional NSW - Remainder',
    geography: 2157,
  },
  {
    subregion: 'Dubbo - West',
    region: 'Regional NSW - Remainder',
    geography: 2158,
  },
  {
    subregion: 'Dubbo Region',
    region: 'Regional NSW - Remainder',
    geography: 2159,
  },
  {
    subregion: 'Dulwich Hill - Lewisham',
    region: 'Inner Sydney',
    geography: 2160,
  },
  {
    subregion: 'Dungog',
    region: 'Central Coast and Hunter',
    geography: 2161,
  },
  {
    subregion: 'Dural - Kenthurst - Wisemans Ferry',
    region: 'Northern Sydney',
    geography: 2162,
  },
  {
    subregion: 'Eastwood - Denistone',
    region: 'Northern Sydney',
    geography: 2163,
  },
  {
    subregion: 'Eden',
    region: 'Capital Region and South Coast',
    geography: 2164,
  },
  {
    subregion: 'Edensor Park',
    region: 'South and West Sydney',
    geography: 2165,
  },
  {
    subregion: 'Edgeworth - Cameron Park',
    region: 'Central Coast and Hunter',
    geography: 2166,
  },
  {
    subregion: 'Elderslie - Harrington Park',
    region: 'South and West Sydney',
    geography: 2167,
  },
  {
    subregion: 'Emu Plains - Leonay',
    region: 'Northern Sydney',
    geography: 2168,
  },
  {
    subregion: 'Engadine',
    region: 'South and West Sydney',
    geography: 2169,
  },
  {
    subregion: 'Epping - North Epping',
    region: 'Northern Sydney',
    geography: 2170,
  },
  {
    subregion: 'Erina - Green Point',
    region: 'Central Coast and Hunter',
    geography: 2171,
  },
  {
    subregion: 'Ermington - Rydalmere',
    region: 'Northern Sydney',
    geography: 2172,
  },
  {
    subregion: 'Erskine Park',
    region: 'Northern Sydney',
    geography: 2173,
  },
  {
    subregion: 'Erskineville - Alexandria',
    region: 'Inner Sydney',
    geography: 2174,
  },
  {
    subregion: 'Eurobodalla Hinterland',
    region: 'Capital Region and South Coast',
    geography: 2175,
  },
  {
    subregion: 'Evans Head',
    region: 'North East NSW',
    geography: 2176,
  },
  {
    subregion: 'Fairfield',
    region: 'South and West Sydney',
    geography: 2177,
  },
  {
    subregion: 'Fairfield - East',
    region: 'South and West Sydney',
    geography: 2178,
  },
  {
    subregion: 'Fairfield - West',
    region: 'South and West Sydney',
    geography: 2179,
  },
  {
    subregion: 'Far West',
    region: 'Regional NSW - Remainder',
    geography: 2180,
  },
  {
    subregion: 'Figtree - Keiraville',
    region: 'Illawarra-Shoalhaven',
    geography: 2181,
  },
  {
    subregion: 'Five Dock - Abbotsford',
    region: 'South and West Sydney',
    geography: 2182,
  },
  {
    subregion: 'Forbes',
    region: 'Regional NSW - Remainder',
    geography: 2183,
  },
  {
    subregion: 'Forestville - Killarney Heights',
    region: 'Northern Sydney',
    geography: 2184,
  },
  {
    subregion: 'Forster',
    region: 'Regional NSW - Remainder',
    geography: 2185,
  },
  {
    subregion: 'Forster-Tuncurry Region',
    region: 'Regional NSW - Remainder',
    geography: 2186,
  },
  {
    subregion: 'Frenchs Forest - Belrose',
    region: 'Northern Sydney',
    geography: 2187,
  },
  {
    subregion: 'Freshwater - Brookvale',
    region: 'Northern Sydney',
    geography: 2188,
  },
  {
    subregion: 'Galston - Laughtondale',
    region: 'Northern Sydney',
    geography: 2189,
  },
  {
    subregion: 'Gilgandra',
    region: 'Regional NSW - Remainder',
    geography: 2190,
  },
  {
    subregion: 'Gladesville - Huntleys Point',
    region: 'Northern Sydney',
    geography: 2191,
  },
  {
    subregion: 'Glebe - Forest Lodge',
    region: 'Inner Sydney',
    geography: 2192,
  },
  {
    subregion: 'Glen Innes',
    region: 'North East NSW',
    geography: 2193,
  },
  {
    subregion: 'Glendale - Cardiff - Hillsborough',
    region: 'Central Coast and Hunter',
    geography: 2194,
  },
  {
    subregion: 'Glendenning - Dean Park',
    region: 'Northern Sydney',
    geography: 2195,
  },
  {
    subregion: 'Glenhaven',
    region: 'Northern Sydney',
    geography: 2196,
  },
  {
    subregion: 'Glenmore Park - Regentville',
    region: 'Northern Sydney',
    geography: 2197,
  },
  {
    subregion: 'Glenwood',
    region: 'Northern Sydney',
    geography: 2198,
  },
  {
    subregion: 'Gloucester',
    region: 'Regional NSW - Remainder',
    geography: 2199,
  },
  {
    subregion: 'Goonellabah',
    region: 'North East NSW',
    geography: 2200,
  },
  {
    subregion: 'Gordon - Killara',
    region: 'Northern Sydney',
    geography: 2201,
  },
  {
    subregion: 'Gorokan - Kanwal - Charmhaven',
    region: 'Central Coast and Hunter',
    geography: 2202,
  },
  {
    subregion: 'Gosford - Springfield',
    region: 'Central Coast and Hunter',
    geography: 2203,
  },
  {
    subregion: 'Goulburn',
    region: 'Riverina-Goulburn Belt',
    geography: 2204,
  },
  {
    subregion: 'Goulburn Region',
    region: 'Riverina-Goulburn Belt',
    geography: 2205,
  },
  {
    subregion: 'Grafton',
    region: 'North East NSW',
    geography: 2206,
  },
  {
    subregion: 'Grafton Region',
    region: 'North East NSW',
    geography: 2207,
  },
  {
    subregion: 'Granville - Clyde',
    region: 'South and West Sydney',
    geography: 2208,
  },
  {
    subregion: 'Green Valley',
    region: 'South and West Sydney',
    geography: 2209,
  },
  {
    subregion: 'Greenacre - Mount Lewis',
    region: 'South and West Sydney',
    geography: 2210,
  },
  {
    subregion: 'Greenfield Park - Prairiewood',
    region: 'South and West Sydney',
    geography: 2211,
  },
  {
    subregion: 'Grenfell',
    region: 'Regional NSW - Remainder',
    geography: 2212,
  },
  {
    subregion: 'Greystanes - Pemulwuy - Smithfield Industrial',
    region: 'Northern Sydney',
    geography: 2213,
  },
  {
    subregion: 'Griffith (NSW)',
    region: 'Riverina-Goulburn Belt',
    geography: 2214,
  },
  {
    subregion: 'Griffith Region',
    region: 'Riverina-Goulburn Belt',
    geography: 2215,
  },
  {
    subregion: 'Guildford - South Granville',
    region: 'South and West Sydney',
    geography: 2216,
  },
  {
    subregion: 'Guildford West - Merrylands West - Yennora Industrial',
    region: 'South and West Sydney',
    geography: 2217,
  },
  {
    subregion: 'Gundagai',
    region: 'Riverina-Goulburn Belt',
    geography: 2218,
  },
  {
    subregion: 'Gunnedah',
    region: 'Regional NSW - Remainder',
    geography: 2219,
  },
  {
    subregion: 'Gunnedah Region',
    region: 'Regional NSW - Remainder',
    geography: 2220,
  },
  {
    subregion: 'Gymea - Grays Point',
    region: 'South and West Sydney',
    geography: 2221,
  },
  {
    subregion: 'Haberfield - Summer Hill',
    region: 'Inner Sydney',
    geography: 2222,
  },
  {
    subregion: 'Hamilton - Broadmeadow',
    region: 'Central Coast and Hunter',
    geography: 2223,
  },
  {
    subregion: 'Hassall Grove - Plumpton',
    region: 'Northern Sydney',
    geography: 2224,
  },
  {
    subregion: 'Hay',
    region: 'Riverina-Goulburn Belt',
    geography: 2225,
  },
  {
    subregion: 'Heathcote - Waterfall - Royal National Park',
    region: 'South and West Sydney',
    geography: 2226,
  },
  {
    subregion: 'Helensburgh - Illawarra Catchment Reserve',
    region: 'Illawarra-Shoalhaven',
    geography: 2227,
  },
  {
    subregion: 'Hill Top - Colo Vale',
    region: 'Illawarra-Shoalhaven',
    geography: 2228,
  },
  {
    subregion: 'Hinchinbrook',
    region: 'South and West Sydney',
    geography: 2229,
  },
  {
    subregion: 'Holsworthy - Wattle Grove',
    region: 'South and West Sydney',
    geography: 2230,
  },
  {
    subregion: 'Homebush',
    region: 'South and West Sydney',
    geography: 2231,
  },
  {
    subregion: 'Homebush Bay - Silverwater',
    region: 'South and West Sydney',
    geography: 2232,
  },
  {
    subregion: 'Hornsby - East',
    region: 'Northern Sydney',
    geography: 2233,
  },
  {
    subregion: 'Hornsby - West',
    region: 'Northern Sydney',
    geography: 2234,
  },
  {
    subregion: 'Horsley - Kembla Grange',
    region: 'Illawarra-Shoalhaven',
    geography: 2235,
  },
  {
    subregion: 'Horsley Park - Kemps Creek - Wetherill Park Industrial',
    region: 'Northern Sydney',
    geography: 2236,
  },
  {
    subregion: 'Hoxton Park - Carnes Hill - Horningsea Park',
    region: 'South and West Sydney',
    geography: 2237,
  },
  {
    subregion: 'Hunters Hill - Woolwich',
    region: 'Northern Sydney',
    geography: 2238,
  },
  {
    subregion: 'Hurstville',
    region: 'Inner Sydney',
    geography: 2239,
  },
  {
    subregion: 'Huskisson - Vincentia',
    region: 'Illawarra-Shoalhaven',
    geography: 2240,
  },
  {
    subregion: 'Illawong - Alfords Point',
    region: 'South and West Sydney',
    geography: 2241,
  },
  {
    subregion: 'Ingleburn - Denham Court',
    region: 'South and West Sydney',
    geography: 2242,
  },
  {
    subregion: 'Inverell',
    region: 'North East NSW',
    geography: 2243,
  },
  {
    subregion: 'Inverell Region - East',
    region: 'North East NSW',
    geography: 2244,
  },
  {
    subregion: 'Inverell Region - West',
    region: 'North East NSW',
    geography: 2245,
  },
  {
    subregion: 'Jamisontown - South Penrith',
    region: 'Northern Sydney',
    geography: 2246,
  },
  {
    subregion: 'Jilliby - Yarramalong',
    region: 'Central Coast and Hunter',
    geography: 2247,
  },
  {
    subregion: 'Jindabyne - Berridale',
    region: 'Capital Region and South Coast',
    geography: 2248,
  },
  {
    subregion: 'Junee',
    region: 'Riverina-Goulburn Belt',
    geography: 2249,
  },
  {
    subregion: 'Karabar',
    region: 'Capital Region and South Coast',
    geography: 2250,
  },
  {
    subregion: 'Kariong',
    region: 'Central Coast and Hunter',
    geography: 2251,
  },
  {
    subregion: 'Katoomba - Leura',
    region: 'Northern Sydney',
    geography: 2252,
  },
  {
    subregion: 'Kellyville',
    region: 'Northern Sydney',
    geography: 2253,
  },
  {
    subregion: 'Kempsey',
    region: 'Regional NSW - Remainder',
    geography: 2254,
  },
  {
    subregion: 'Kempsey Region',
    region: 'Regional NSW - Remainder',
    geography: 2255,
  },
  {
    subregion: 'Kensington (NSW)',
    region: 'Inner Sydney',
    geography: 2256,
  },
  {
    subregion: 'Kiama',
    region: 'Illawarra-Shoalhaven',
    geography: 2257,
  },
  {
    subregion: 'Kiama Downs - Minnamurra',
    region: 'Illawarra-Shoalhaven',
    geography: 2258,
  },
  {
    subregion: 'Kiama Hinterland - Gerringong',
    region: 'Illawarra-Shoalhaven',
    geography: 2259,
  },
  {
    subregion: 'Kincumber - Picketts Valley',
    region: 'Central Coast and Hunter',
    geography: 2260,
  },
  {
    subregion: 'Kingscliff - Fingal Head',
    region: 'North East NSW',
    geography: 2261,
  },
  {
    subregion: 'Kingsford',
    region: 'Inner Sydney',
    geography: 2262,
  },
  {
    subregion: 'Kingsgrove (North) - Earlwood',
    region: 'Inner Sydney',
    geography: 2263,
  },
  {
    subregion: 'Kingsgrove (South) - Bardwell Park',
    region: 'Inner Sydney',
    geography: 2264,
  },
  {
    subregion: 'Kingswood - Werrington',
    region: 'Northern Sydney',
    geography: 2265,
  },
  {
    subregion: 'Kogarah',
    region: 'Inner Sydney',
    geography: 2266,
  },
  {
    subregion: 'Kogarah Bay - Carlton - Allawah',
    region: 'Inner Sydney',
    geography: 2267,
  },
  {
    subregion: 'Korora - Emerald Beach',
    region: 'North East NSW',
    geography: 2268,
  },
  {
    subregion: 'Kurrajong Heights - Ebenezer',
    region: 'Northern Sydney',
    geography: 2269,
  },
  {
    subregion: 'Kurri Kurri - Abermain',
    region: 'Central Coast and Hunter',
    geography: 2270,
  },
  {
    subregion: 'Kyogle',
    region: 'North East NSW',
    geography: 2271,
  },
  {
    subregion: 'Lake Munmorah - Mannering Park',
    region: 'Central Coast and Hunter',
    geography: 2272,
  },
  {
    subregion: 'Lakemba',
    region: 'South and West Sydney',
    geography: 2273,
  },
  {
    subregion: 'Lalor Park - Kings Langley',
    region: 'Northern Sydney',
    geography: 2274,
  },
  {
    subregion: 'Lambton - New Lambton',
    region: 'Central Coast and Hunter',
    geography: 2275,
  },
  {
    subregion: 'Lane Cove - Greenwich',
    region: 'Northern Sydney',
    geography: 2276,
  },
  {
    subregion: 'Laurieton - Bonny Hills',
    region: 'Regional NSW - Remainder',
    geography: 2277,
  },
  {
    subregion: 'Lavington',
    region: 'Murray',
    geography: 2278,
  },
  {
    subregion: 'Lawson - Hazelbrook - Linden',
    region: 'Northern Sydney',
    geography: 2279,
  },
  {
    subregion: 'Leeton',
    region: 'Riverina-Goulburn Belt',
    geography: 2280,
  },
  {
    subregion: 'Leichhardt - Annandale',
    region: 'Inner Sydney',
    geography: 2281,
  },
  {
    subregion: 'Lemon Tree Passage - Tanilba Bay',
    region: 'Central Coast and Hunter',
    geography: 2282,
  },
  {
    subregion: 'Lennox Head - Skennars Head',
    region: 'North East NSW',
    geography: 2283,
  },
  {
    subregion: 'Lethbridge Park - Tregear',
    region: 'Northern Sydney',
    geography: 2284,
  },
  {
    subregion: 'Leumeah - Minto Heights',
    region: 'South and West Sydney',
    geography: 2285,
  },
  {
    subregion: 'Lidcombe - Rookwood Cemetery',
    region: 'South and West Sydney',
    geography: 2286,
  },
  {
    subregion: 'Lilli Pilli - Port Hacking - Dolans Bay',
    region: 'South and West Sydney',
    geography: 2287,
  },
  {
    subregion: 'Lilyfield - Rozelle',
    region: 'Inner Sydney',
    geography: 2288,
  },
  {
    subregion: 'Lindfield - Roseville',
    region: 'Northern Sydney',
    geography: 2289,
  },
  {
    subregion: 'Lismore',
    region: 'North East NSW',
    geography: 2290,
  },
  {
    subregion: 'Lismore Region',
    region: 'North East NSW',
    geography: 2291,
  },
  {
    subregion: 'Lithgow',
    region: 'Regional NSW - Remainder',
    geography: 2292,
  },
  {
    subregion: 'Lithgow Region',
    region: 'Regional NSW - Remainder',
    geography: 2293,
  },
  {
    subregion: 'Liverpool',
    region: 'South and West Sydney',
    geography: 2294,
  },
  {
    subregion: 'Loftus - Yarrawarrah',
    region: 'South and West Sydney',
    geography: 2295,
  },
  {
    subregion: 'Lord Howe Island',
    region: 'North East NSW',
    geography: 2296,
  },
  {
    subregion: 'Lurnea - Cartwright',
    region: 'South and West Sydney',
    geography: 2297,
  },
  {
    subregion: 'Macksville - Scotts Head',
    region: 'North East NSW',
    geography: 2298,
  },
  {
    subregion: 'Maclean - Yamba - Iluka',
    region: 'North East NSW',
    geography: 2299,
  },
  {
    subregion: 'Macquarie Fields - Glenfield',
    region: 'South and West Sydney',
    geography: 2300,
  },
  {
    subregion: 'Macquarie Park - Marsfield',
    region: 'Northern Sydney',
    geography: 2301,
  },
  {
    subregion: 'Maitland',
    region: 'Central Coast and Hunter',
    geography: 2302,
  },
  {
    subregion: 'Maitland - East',
    region: 'Central Coast and Hunter',
    geography: 2303,
  },
  {
    subregion: 'Maitland - North',
    region: 'Central Coast and Hunter',
    geography: 2304,
  },
  {
    subregion: 'Maitland - West',
    region: 'Central Coast and Hunter',
    geography: 2305,
  },
  {
    subregion: 'Malabar - La Perouse - Chifley',
    region: 'Inner Sydney',
    geography: 2306,
  },
  {
    subregion: 'Manly - Fairlight',
    region: 'Northern Sydney',
    geography: 2307,
  },
  {
    subregion: 'Manly Vale - Allambie Heights',
    region: 'Northern Sydney',
    geography: 2308,
  },
  {
    subregion: 'Maroubra - North',
    region: 'Inner Sydney',
    geography: 2309,
  },
  {
    subregion: 'Maroubra - South',
    region: 'Inner Sydney',
    geography: 2310,
  },
  {
    subregion: 'Maroubra - West',
    region: 'Inner Sydney',
    geography: 2311,
  },
  {
    subregion: 'Marrickville',
    region: 'Inner Sydney',
    geography: 2312,
  },
  {
    subregion: 'Maryland - Fletcher - Minmi',
    region: 'Central Coast and Hunter',
    geography: 2313,
  },
  {
    subregion: 'Mascot - Eastlakes',
    region: 'Inner Sydney',
    geography: 2314,
  },
  {
    subregion: 'Mayfield - Warabrook',
    region: 'Central Coast and Hunter',
    geography: 2315,
  },
  {
    subregion: 'Menai - Lucas Heights - Woronora',
    region: 'South and West Sydney',
    geography: 2316,
  },
  {
    subregion: 'Merewether - The Junction',
    region: 'Central Coast and Hunter',
    geography: 2317,
  },
  {
    subregion: 'Merimbula - Tura Beach',
    region: 'Capital Region and South Coast',
    geography: 2318,
  },
  {
    subregion: 'Merrylands - Holroyd',
    region: 'South and West Sydney',
    geography: 2319,
  },
  {
    subregion: 'Minto - St Andrews',
    region: 'South and West Sydney',
    geography: 2320,
  },
  {
    subregion: 'Miranda - Yowie Bay',
    region: 'South and West Sydney',
    geography: 2321,
  },
  {
    subregion: 'Mittagong',
    region: 'Illawarra-Shoalhaven',
    geography: 2322,
  },
  {
    subregion: 'Moama',
    region: 'Murray',
    geography: 2323,
  },
  {
    subregion: 'Monterey - Brighton-le-Sands - Kyeemagh',
    region: 'Inner Sydney',
    geography: 2324,
  },
  {
    subregion: 'Moree',
    region: 'North East NSW',
    geography: 2325,
  },
  {
    subregion: 'Moree Region',
    region: 'North East NSW',
    geography: 2326,
  },
  {
    subregion: 'Morisset - Cooranbong',
    region: 'Central Coast and Hunter',
    geography: 2327,
  },
  {
    subregion: 'Mortdale - Penshurst',
    region: 'Inner Sydney',
    geography: 2328,
  },
  {
    subregion: 'Moruya - Tuross Head',
    region: 'Capital Region and South Coast',
    geography: 2329,
  },
  {
    subregion: 'Mosman',
    region: 'Northern Sydney',
    geography: 2330,
  },
  {
    subregion: 'Moss Vale - Berrima',
    region: 'Illawarra-Shoalhaven',
    geography: 2331,
  },
  {
    subregion: 'Mount Annan - Currans Hill',
    region: 'South and West Sydney',
    geography: 2332,
  },
  {
    subregion: 'Mount Druitt - Whalan',
    region: 'Northern Sydney',
    geography: 2333,
  },
  {
    subregion: 'Mount Hutton - Windale',
    region: 'Central Coast and Hunter',
    geography: 2334,
  },
  {
    subregion: 'Mudgee',
    region: 'Regional NSW - Remainder',
    geography: 2335,
  },
  {
    subregion: 'Mudgee Region - East',
    region: 'Regional NSW - Remainder',
    geography: 2336,
  },
  {
    subregion: 'Mudgee Region - West',
    region: 'Regional NSW - Remainder',
    geography: 2337,
  },
  {
    subregion: 'Mulgoa - Luddenham - Orchard Hills',
    region: 'Northern Sydney',
    geography: 2338,
  },
  {
    subregion: 'Mullumbimby',
    region: 'North East NSW',
    geography: 2339,
  },
  {
    subregion: 'Murwillumbah',
    region: 'North East NSW',
    geography: 2340,
  },
  {
    subregion: 'Murwillumbah Region',
    region: 'North East NSW',
    geography: 2341,
  },
  {
    subregion: 'Muswellbrook',
    region: 'Central Coast and Hunter',
    geography: 2342,
  },
  {
    subregion: 'Muswellbrook Region',
    region: 'Central Coast and Hunter',
    geography: 2343,
  },
  {
    subregion: 'Nambucca Heads',
    region: 'North East NSW',
    geography: 2344,
  },
  {
    subregion: 'Nambucca Heads Region',
    region: 'North East NSW',
    geography: 2345,
  },
  {
    subregion: 'Narara',
    region: 'Central Coast and Hunter',
    geography: 2346,
  },
  {
    subregion: 'Narooma - Bermagui',
    region: 'Capital Region and South Coast',
    geography: 2347,
  },
  {
    subregion: 'Narrabeen - Collaroy',
    region: 'Northern Sydney',
    geography: 2348,
  },
  {
    subregion: 'Narrabri',
    region: 'Regional NSW - Remainder',
    geography: 2349,
  },
  {
    subregion: 'Narrabri Region',
    region: 'Regional NSW - Remainder',
    geography: 2350,
  },
  {
    subregion: 'Narrandera',
    region: 'Riverina-Goulburn Belt',
    geography: 2351,
  },
  {
    subregion: 'Narromine',
    region: 'Regional NSW - Remainder',
    geography: 2352,
  },
  {
    subregion: 'Narwee - Beverly Hills',
    region: 'Inner Sydney',
    geography: 2353,
  },
  {
    subregion: 'Nelson Bay Peninsula',
    region: 'Central Coast and Hunter',
    geography: 2354,
  },
  {
    subregion: 'Neutral Bay - Kirribilli',
    region: 'Northern Sydney',
    geography: 2355,
  },
  {
    subregion: 'Newcastle - Cooks Hill',
    region: 'Central Coast and Hunter',
    geography: 2356,
  },
  {
    subregion: 'Newport - Bilgola',
    region: 'Northern Sydney',
    geography: 2357,
  },
  {
    subregion: 'Newtown - Camperdown - Darlington',
    region: 'Inner Sydney',
    geography: 2358,
  },
  {
    subregion: 'Niagara Park - Lisarow',
    region: 'Central Coast and Hunter',
    geography: 2359,
  },
  {
    subregion: 'Normanhurst - Thornleigh - Westleigh',
    region: 'Northern Sydney',
    geography: 2360,
  },
  {
    subregion: 'North Nowra - Bomaderry',
    region: 'Illawarra-Shoalhaven',
    geography: 2361,
  },
  {
    subregion: 'North Parramatta',
    region: 'Northern Sydney',
    geography: 2362,
  },
  {
    subregion: 'North Rocks',
    region: 'Northern Sydney',
    geography: 2363,
  },
  {
    subregion: 'North Ryde - East Ryde',
    region: 'Northern Sydney',
    geography: 2364,
  },
  {
    subregion: 'North Sydney - Lavender Bay',
    region: 'Northern Sydney',
    geography: 2365,
  },
  {
    subregion: 'Northmead',
    region: 'Northern Sydney',
    geography: 2366,
  },
  {
    subregion: 'Nowra',
    region: 'Illawarra-Shoalhaven',
    geography: 2367,
  },
  {
    subregion: 'Nyngan - Warren',
    region: 'Regional NSW - Remainder',
    geography: 2368,
  },
  {
    subregion: 'Oatlands - Dundas Valley',
    region: 'Northern Sydney',
    geography: 2369,
  },
  {
    subregion: 'Oatley - Hurstville Grove',
    region: 'Inner Sydney',
    geography: 2370,
  },
  {
    subregion: 'Oberon',
    region: 'Regional NSW - Remainder',
    geography: 2371,
  },
  {
    subregion: 'Old Bar - Manning Point - Red Head',
    region: 'Regional NSW - Remainder',
    geography: 2372,
  },
  {
    subregion: 'Orange',
    region: 'Regional NSW - Remainder',
    geography: 2373,
  },
  {
    subregion: 'Orange - North',
    region: 'Regional NSW - Remainder',
    geography: 2374,
  },
  {
    subregion: 'Orange Region',
    region: 'Regional NSW - Remainder',
    geography: 2375,
  },
  {
    subregion: 'Ourimbah - Fountaindale',
    region: 'Central Coast and Hunter',
    geography: 2376,
  },
  {
    subregion: 'Oyster Bay - Como - Jannali',
    region: 'South and West Sydney',
    geography: 2377,
  },
  {
    subregion: 'Paddington - Moore Park - Centennial Park',
    region: 'Inner Sydney',
    geography: 2378,
  },
  {
    subregion: 'Padstow',
    region: 'South and West Sydney',
    geography: 2379,
  },
  {
    subregion: 'Pagewood - Hillsdale - Daceyville',
    region: 'Inner Sydney',
    geography: 2380,
  },
  {
    subregion: 'Panania - Milperra - Picnic Point',
    region: 'South and West Sydney',
    geography: 2381,
  },
  {
    subregion: 'Parkes (NSW)',
    region: 'Regional NSW - Remainder',
    geography: 2382,
  },
  {
    subregion: 'Parkes Region',
    region: 'Regional NSW - Remainder',
    geography: 2383,
  },
  {
    subregion: 'Parklea - Kellyville Ridge',
    region: 'Northern Sydney',
    geography: 2384,
  },
  {
    subregion: 'Parramatta - Rosehill',
    region: 'Northern Sydney',
    geography: 2385,
  },
  {
    subregion: 'Peakhurst - Lugarno',
    region: 'Inner Sydney',
    geography: 2386,
  },
  {
    subregion: 'Pendle Hill - Girraween',
    region: 'Northern Sydney',
    geography: 2387,
  },
  {
    subregion: 'Pennant Hills - Cheltenham',
    region: 'Northern Sydney',
    geography: 2388,
  },
  {
    subregion: 'Penrith',
    region: 'Northern Sydney',
    geography: 2389,
  },
  {
    subregion: 'Petersham - Stanmore',
    region: 'Inner Sydney',
    geography: 2390,
  },
  {
    subregion: 'Picton - Tahmoor - Buxton',
    region: 'South and West Sydney',
    geography: 2391,
  },
  {
    subregion: 'Pitt Town - McGraths Hill',
    region: 'Northern Sydney',
    geography: 2392,
  },
  {
    subregion: 'Point Clare - Koolewong',
    region: 'Central Coast and Hunter',
    geography: 2393,
  },
  {
    subregion: 'Port Kembla - Warrawong - Port Kembla Industrial',
    region: 'Illawarra-Shoalhaven',
    geography: 2394,
  },
  {
    subregion: 'Port Macquarie - East',
    region: 'Regional NSW - Remainder',
    geography: 2395,
  },
  {
    subregion: 'Port Macquarie - West',
    region: 'Regional NSW - Remainder',
    geography: 2396,
  },
  {
    subregion: 'Port Macquarie Region',
    region: 'Regional NSW - Remainder',
    geography: 2397,
  },
  {
    subregion: 'Potts Point - Woolloomooloo',
    region: 'Inner Sydney',
    geography: 2398,
  },
  {
    subregion: 'Pottsville',
    region: 'North East NSW',
    geography: 2399,
  },
  {
    subregion: 'Prestons - Edmondson Park',
    region: 'South and West Sydney',
    geography: 2400,
  },
  {
    subregion: 'Punchbowl',
    region: 'South and West Sydney',
    geography: 2401,
  },
  {
    subregion: 'Putney',
    region: 'Northern Sydney',
    geography: 2402,
  },
  {
    subregion: 'Pymble',
    region: 'Northern Sydney',
    geography: 2403,
  },
  {
    subregion: 'Pyrmont - Ultimo',
    region: 'Inner Sydney',
    geography: 2404,
  },
  {
    subregion: 'Quakers Hill',
    region: 'Northern Sydney',
    geography: 2405,
  },
  {
    subregion: 'Queanbeyan',
    region: 'Capital Region and South Coast',
    geography: 2406,
  },
  {
    subregion: 'Queanbeyan - East',
    region: 'Capital Region and South Coast',
    geography: 2407,
  },
  {
    subregion: 'Queanbeyan Region',
    region: 'Capital Region and South Coast',
    geography: 2408,
  },
  {
    subregion: 'Queanbeyan West - Jerrabomberra',
    region: 'Capital Region and South Coast',
    geography: 2409,
  },
  {
    subregion: 'Quirindi',
    region: 'Regional NSW - Remainder',
    geography: 2410,
  },
  {
    subregion: 'Randwick - North',
    region: 'Inner Sydney',
    geography: 2411,
  },
  {
    subregion: 'Randwick - South',
    region: 'Inner Sydney',
    geography: 2412,
  },
  {
    subregion: 'Raymond Terrace',
    region: 'Central Coast and Hunter',
    geography: 2413,
  },
  {
    subregion: 'Redfern - Chippendale',
    region: 'Inner Sydney',
    geography: 2414,
  },
  {
    subregion: 'Redhead',
    region: 'Central Coast and Hunter',
    geography: 2415,
  },
  {
    subregion: 'Regents Park',
    region: 'South and West Sydney',
    geography: 2416,
  },
  {
    subregion: 'Revesby',
    region: 'South and West Sydney',
    geography: 2417,
  },
  {
    subregion: 'Richmond - Clarendon',
    region: 'Northern Sydney',
    geography: 2418,
  },
  {
    subregion: 'Riverstone - Marsden Park',
    region: 'Northern Sydney',
    geography: 2419,
  },
  {
    subregion: 'Riverwood',
    region: 'South and West Sydney',
    geography: 2420,
  },
  {
    subregion: 'Robertson - Fitzroy Falls',
    region: 'Illawarra-Shoalhaven',
    geography: 2421,
  },
  {
    subregion: 'Rockdale - Banksia',
    region: 'Inner Sydney',
    geography: 2422,
  },
  {
    subregion: 'Rooty Hill - Minchinbury',
    region: 'Northern Sydney',
    geography: 2423,
  },
  {
    subregion: 'Rose Bay - Vaucluse - Watsons Bay',
    region: 'Inner Sydney',
    geography: 2424,
  },
  {
    subregion: 'Roselands',
    region: 'South and West Sydney',
    geography: 2425,
  },
  {
    subregion: 'Rosemeadow - Glen Alpine',
    region: 'South and West Sydney',
    geography: 2426,
  },
  {
    subregion: 'Rouse Hill - Beaumont Hills',
    region: 'Northern Sydney',
    geography: 2427,
  },
  {
    subregion: 'Ryde',
    region: 'Northern Sydney',
    geography: 2428,
  },
  {
    subregion: 'Sans Souci - Ramsgate',
    region: 'Inner Sydney',
    geography: 2429,
  },
  {
    subregion: 'Saratoga - Davistown',
    region: 'Central Coast and Hunter',
    geography: 2430,
  },
  {
    subregion: 'Sawtell - Boambee',
    region: 'North East NSW',
    geography: 2431,
  },
  {
    subregion: 'Scone',
    region: 'Central Coast and Hunter',
    geography: 2432,
  },
  {
    subregion: 'Scone Region',
    region: 'Central Coast and Hunter',
    geography: 2433,
  },
  {
    subregion: 'Seaham - Woodville',
    region: 'Central Coast and Hunter',
    geography: 2434,
  },
  {
    subregion: 'Seven Hills - Toongabbie - Prospect Reservoir',
    region: 'Northern Sydney',
    geography: 2435,
  },
  {
    subregion: 'Shellharbour - Flinders',
    region: 'Illawarra-Shoalhaven',
    geography: 2436,
  },
  {
    subregion: 'Shellharbour - Oak Flats',
    region: 'Illawarra-Shoalhaven',
    geography: 2437,
  },
  {
    subregion: 'Shortland - Jesmond',
    region: 'Central Coast and Hunter',
    geography: 2438,
  },
  {
    subregion: 'Singelton region - Wollangambe - Wollemi',
    region: 'Central Coast and Hunter',
    geography: 2439,
  },
  {
    subregion: 'Singleton',
    region: 'Central Coast and Hunter',
    geography: 2440,
  },
  {
    subregion: 'Smithfield - Wetherill Park',
    region: 'South and West Sydney',
    geography: 2441,
  },
  {
    subregion: 'South Hurstville - Blakehurst',
    region: 'Inner Sydney',
    geography: 2442,
  },
  {
    subregion: 'South West Rocks',
    region: 'Regional NSW - Remainder',
    geography: 2443,
  },
  {
    subregion: 'Southern Highlands',
    region: 'Illawarra-Shoalhaven',
    geography: 2444,
  },
  {
    subregion: 'Springwood - Winmalee',
    region: 'Northern Sydney',
    geography: 2445,
  },
  {
    subregion: 'St Clair',
    region: 'Northern Sydney',
    geography: 2446,
  },
  {
    subregion: 'St Georges Basin - Erowal Bay',
    region: 'Illawarra-Shoalhaven',
    geography: 2447,
  },
  {
    subregion: 'St Ives',
    region: 'Northern Sydney',
    geography: 2448,
  },
  {
    subregion: 'St Johns Park - Wakeley',
    region: 'South and West Sydney',
    geography: 2449,
  },
  {
    subregion: 'St Leonards - Naremburn',
    region: 'Northern Sydney',
    geography: 2450,
  },
  {
    subregion: 'St Marys - North St Marys',
    region: 'Northern Sydney',
    geography: 2451,
  },
  {
    subregion: 'Stockton - Fullerton Cove - Newcastle Port - Kooragang',
    region: 'Central Coast and Hunter',
    geography: 2452,
  },
  {
    subregion: 'Strathfield',
    region: 'South and West Sydney',
    geography: 2453,
  },
  {
    subregion: 'Strathfield South',
    region: 'South and West Sydney',
    geography: 2454,
  },
  {
    subregion: 'Summerland Point - Gwandalan',
    region: 'Central Coast and Hunter',
    geography: 2455,
  },
  {
    subregion: 'Surry Hills',
    region: 'Inner Sydney',
    geography: 2456,
  },
  {
    subregion: 'Sussex Inlet - Berrara',
    region: 'Illawarra-Shoalhaven',
    geography: 2457,
  },
  {
    subregion: 'Sutherland - Kirrawee',
    region: 'South and West Sydney',
    geography: 2458,
  },
  {
    subregion: 'Swansea - Caves Beach',
    region: 'Central Coast and Hunter',
    geography: 2459,
  },
  {
    subregion: 'Sydenham - Tempe - St Peters',
    region: 'Inner Sydney',
    geography: 2460,
  },
  {
    subregion: 'Sydney - Haymarket - The Rocks',
    region: 'Inner Sydney',
    geography: 2461,
  },
  {
    subregion: 'Sylvania - Taren Point',
    region: 'South and West Sydney',
    geography: 2462,
  },
  {
    subregion: 'Tamworth - East',
    region: 'Regional NSW - Remainder',
    geography: 2463,
  },
  {
    subregion: 'Tamworth - North',
    region: 'Regional NSW - Remainder',
    geography: 2464,
  },
  {
    subregion: 'Tamworth - West',
    region: 'Regional NSW - Remainder',
    geography: 2465,
  },
  {
    subregion: 'Tamworth Region',
    region: 'Regional NSW - Remainder',
    geography: 2466,
  },
  {
    subregion: 'Taree',
    region: 'Regional NSW - Remainder',
    geography: 2467,
  },
  {
    subregion: 'Taree Region',
    region: 'Regional NSW - Remainder',
    geography: 2468,
  },
  {
    subregion: 'Tea Gardens - Hawks Nest',
    region: 'Central Coast and Hunter',
    geography: 2469,
  },
  {
    subregion: 'Temora',
    region: 'Riverina-Goulburn Belt',
    geography: 2470,
  },
  {
    subregion: 'Tenterfield',
    region: 'North East NSW',
    geography: 2471,
  },
  {
    subregion: 'Terranora - North Tumblegum',
    region: 'North East NSW',
    geography: 2472,
  },
  {
    subregion: 'Terrey Hills - Duffys Forest',
    region: 'Northern Sydney',
    geography: 2473,
  },
  {
    subregion: 'Terrigal - North Avoca',
    region: 'Central Coast and Hunter',
    geography: 2474,
  },
  {
    subregion: 'The Entrance',
    region: 'Central Coast and Hunter',
    geography: 2475,
  },
  {
    subregion: 'The Oaks - Oakdale',
    region: 'South and West Sydney',
    geography: 2476,
  },
  {
    subregion: 'Thirroul - Austinmer - Coalcliff',
    region: 'Illawarra-Shoalhaven',
    geography: 2477,
  },
  {
    subregion: 'Thornton - Millers Forest',
    region: 'Central Coast and Hunter',
    geography: 2478,
  },
  {
    subregion: 'Tocumwal - Finley - Jerilderie',
    region: 'Murray',
    geography: 2479,
  },
  {
    subregion: 'Tomerong - Wandandian - Woollamia',
    region: 'Illawarra-Shoalhaven',
    geography: 2480,
  },
  {
    subregion: 'Toongabbie - Constitution Hill',
    region: 'Northern Sydney',
    geography: 2481,
  },
  {
    subregion: 'Toronto - Awaba',
    region: 'Central Coast and Hunter',
    geography: 2482,
  },
  {
    subregion: 'Toukley - Norah Head',
    region: 'Central Coast and Hunter',
    geography: 2483,
  },
  {
    subregion: 'Tuggerah - Kangy Angy',
    region: 'Central Coast and Hunter',
    geography: 2484,
  },
  {
    subregion: 'Tumbarumba',
    region: 'Riverina-Goulburn Belt',
    geography: 2485,
  },
  {
    subregion: 'Tumut',
    region: 'Riverina-Goulburn Belt',
    geography: 2486,
  },
  {
    subregion: 'Tumut Region',
    region: 'Riverina-Goulburn Belt',
    geography: 2487,
  },
  {
    subregion: 'Tuncurry',
    region: 'Regional NSW - Remainder',
    geography: 2488,
  },
  {
    subregion: 'Turramurra',
    region: 'Northern Sydney',
    geography: 2489,
  },
  {
    subregion: 'Tweed Heads',
    region: 'North East NSW',
    geography: 2490,
  },
  {
    subregion: 'Tweed Heads South',
    region: 'North East NSW',
    geography: 2491,
  },
  {
    subregion: 'Ulladulla',
    region: 'Illawarra-Shoalhaven',
    geography: 2492,
  },
  {
    subregion: 'Ulladulla region - Ettrema - Sassafras - Budawang',
    region: 'Illawarra-Shoalhaven',
    geography: 2493,
  },
  {
    subregion: 'Umina - Booker Bay - Patonga',
    region: 'Central Coast and Hunter',
    geography: 2494,
  },
  {
    subregion: 'Unanderra - Mount Kembla',
    region: 'Illawarra-Shoalhaven',
    geography: 2495,
  },
  {
    subregion: 'Urunga',
    region: 'North East NSW',
    geography: 2496,
  },
  {
    subregion: 'Valentine - Eleebana',
    region: 'Central Coast and Hunter',
    geography: 2497,
  },
  {
    subregion: 'Wagga Wagga - East',
    region: 'Riverina-Goulburn Belt',
    geography: 2498,
  },
  {
    subregion: 'Wagga Wagga - North',
    region: 'Riverina-Goulburn Belt',
    geography: 2499,
  },
  {
    subregion: 'Wagga Wagga - South',
    region: 'Riverina-Goulburn Belt',
    geography: 2500,
  },
  {
    subregion: 'Wagga Wagga - West',
    region: 'Riverina-Goulburn Belt',
    geography: 2501,
  },
  {
    subregion: 'Wagga Wagga Region',
    region: 'Riverina-Goulburn Belt',
    geography: 2502,
  },
  {
    subregion: 'Wahroonga (East) - Warrawee',
    region: 'Northern Sydney',
    geography: 2503,
  },
  {
    subregion: 'Waitara - Wahroonga (West)',
    region: 'Northern Sydney',
    geography: 2504,
  },
  {
    subregion: 'Walcha',
    region: 'Regional NSW - Remainder',
    geography: 2505,
  },
  {
    subregion: 'Walgett - Lightning Ridge',
    region: 'Regional NSW - Remainder',
    geography: 2506,
  },
  {
    subregion: 'Wallsend - Elermore Vale',
    region: 'Central Coast and Hunter',
    geography: 2507,
  },
  {
    subregion: 'Wamberal - Forresters Beach',
    region: 'Central Coast and Hunter',
    geography: 2508,
  },
  {
    subregion: 'Wangi Wangi - Rathmines',
    region: 'Central Coast and Hunter',
    geography: 2509,
  },
  {
    subregion: 'Waratah - North Lambton',
    region: 'Central Coast and Hunter',
    geography: 2510,
  },
  {
    subregion: 'Warilla',
    region: 'Illawarra-Shoalhaven',
    geography: 2511,
  },
  {
    subregion: 'Warners Bay - Boolaroo',
    region: 'Central Coast and Hunter',
    geography: 2512,
  },
  {
    subregion: 'Warnervale - Wadalba',
    region: 'Central Coast and Hunter',
    geography: 2513,
  },
  {
    subregion: 'Warragamba - Silverdale',
    region: 'Northern Sydney',
    geography: 2514,
  },
  {
    subregion: 'Warriewood - Mona Vale',
    region: 'Northern Sydney',
    geography: 2515,
  },
  {
    subregion: 'Warwick Farm',
    region: 'South and West Sydney',
    geography: 2516,
  },
  {
    subregion: 'Waterloo - Beaconsfield',
    region: 'Inner Sydney',
    geography: 2517,
  },
  {
    subregion: 'Wauchope',
    region: 'Regional NSW - Remainder',
    geography: 2518,
  },
  {
    subregion: 'Wellington',
    region: 'Regional NSW - Remainder',
    geography: 2519,
  },
  {
    subregion: 'Wentworth - Buronga',
    region: 'Regional NSW - Remainder',
    geography: 2520,
  },
  {
    subregion: 'Wentworth Falls',
    region: 'Northern Sydney',
    geography: 2521,
  },
  {
    subregion: 'Wentworth-Balranald Region',
    region: 'Regional NSW - Remainder',
    geography: 2522,
  },
  {
    subregion: 'Wentworthville - Westmead',
    region: 'South and West Sydney',
    geography: 2523,
  },
  {
    subregion: 'West Hoxton - Middleton Grange',
    region: 'South and West Sydney',
    geography: 2524,
  },
  {
    subregion: 'West Pennant Hills',
    region: 'Northern Sydney',
    geography: 2525,
  },
  {
    subregion: 'West Ryde - Meadowbank',
    region: 'Northern Sydney',
    geography: 2526,
  },
  {
    subregion: 'West Wallsend - Barnsley - Killingworth',
    region: 'Central Coast and Hunter',
    geography: 2527,
  },
  {
    subregion: 'West Wyalong',
    region: 'Riverina-Goulburn Belt',
    geography: 2528,
  },
  {
    subregion: 'Wickham - Carrington - Tighes Hill',
    region: 'Central Coast and Hunter',
    geography: 2529,
  },
  {
    subregion: 'Wiley Park',
    region: 'South and West Sydney',
    geography: 2530,
  },
  {
    subregion: 'Williamtown - Medowie - Karuah',
    region: 'Central Coast and Hunter',
    geography: 2531,
  },
  {
    subregion: 'Willoughby - Castle Cove - Northbridge',
    region: 'Northern Sydney',
    geography: 2532,
  },
  {
    subregion: 'Windang - Primbee',
    region: 'Illawarra-Shoalhaven',
    geography: 2533,
  },
  {
    subregion: 'Windsor - Bligh Park',
    region: 'Northern Sydney',
    geography: 2534,
  },
  {
    subregion: 'Wingham',
    region: 'Regional NSW - Remainder',
    geography: 2535,
  },
  {
    subregion: 'Winston Hills',
    region: 'Northern Sydney',
    geography: 2536,
  },
  {
    subregion: 'Wollongong - East',
    region: 'Illawarra-Shoalhaven',
    geography: 2537,
  },
  {
    subregion: 'Wollongong - West',
    region: 'Illawarra-Shoalhaven',
    geography: 2538,
  },
  {
    subregion: 'Woolaware - Burraneer',
    region: 'South and West Sydney',
    geography: 2539,
  },
  {
    subregion: 'Woolgoolga - Arrawarra',
    region: 'North East NSW',
    geography: 2540,
  },
  {
    subregion: 'Woollahra',
    region: 'Inner Sydney',
    geography: 2541,
  },
  {
    subregion: 'Woonona - Bulli - Russell Vale',
    region: 'Illawarra-Shoalhaven',
    geography: 2542,
  },
  {
    subregion: 'Woronora Heights',
    region: 'South and West Sydney',
    geography: 2543,
  },
  {
    subregion: 'Woy Woy - Blackwall',
    region: 'Central Coast and Hunter',
    geography: 2544,
  },
  {
    subregion: 'Wyoming',
    region: 'Central Coast and Hunter',
    geography: 2545,
  },
  {
    subregion: 'Wyong',
    region: 'Central Coast and Hunter',
    geography: 2546,
  },
  {
    subregion: 'Yagoona - Birrong',
    region: 'South and West Sydney',
    geography: 2547,
  },
  {
    subregion: 'Yarramundi - Londonderry',
    region: 'Northern Sydney',
    geography: 2548,
  },
  {
    subregion: 'Yass',
    region: 'Capital Region and South Coast',
    geography: 2549,
  },
  {
    subregion: 'Yass Region',
    region: 'Capital Region and South Coast',
    geography: 2550,
  },
  {
    subregion: 'Young',
    region: 'Riverina-Goulburn Belt',
    geography: 2551,
  },
  {
    subregion: 'Young Region',
    region: 'Riverina-Goulburn Belt',
    geography: 2552,
  },
];

const fineareasBySubregion = groupBy('subregion', fineareas);

const geographyLabels =
  pipe(
    concat(map(({ finearea, geography }) => ({ label: finearea, geography }), fineareas)),
    concat(map(({ subregion, geography }) => ({ label: subregion, geography }), subregions)),
    concat(map(({ region, geography }) => ({ label: region, geography }), regions)),
    keyBy('geography'),
    mapValues('label')
  )([]);

export { fineareas, fineareasBySubregion, subregions, regions, geographyLabels };
