import dataVizSelector from 'selectors/dataVizSelector';
import publicSpatialVisualisationDataSelector from 'selectors/publicSpatialVisualisationDataSelector';

function spatialVisualisationDataSelector(state, props) {
  return props?.public
    ? publicSpatialVisualisationDataSelector(state, props)
    : dataVizSelector(state, props);
}

export default spatialVisualisationDataSelector;
