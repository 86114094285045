import { useMemo, useCallback, useRef, useState } from 'react';
import { scaleSequential } from 'd3-scale';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  isNil,
  pipe,
  map,
  flatMap,
  flatten,
  fromPairs,
  last,
  filter,
  defaultTo,
} from 'lodash/fp';
import DeckGL from '@deck.gl/react';

import { StaticMap } from 'react-map-gl';
import { GeoJsonLayer } from '@deck.gl/layers';
import { Placeholder, Stack, useRect } from '@kinesis/bungle';
import Units from 'data/units';
import { ramp } from 'utils/colors';
import { divergingChoroplethColors } from 'settings/colors';
import { coordinatesToBounds, fitBounds } from 'utils/spatialUtils';
import { Loading } from 'components/loading';
import colourExpressionLabelSelector from 'selectors/colourExpressionLabelSelector';
import blockDataSelector from 'selectors/blockDataSelector';
import blockGeographySelector from 'selectors/blockGeographySelector';
import blockPerspectiveStateSelector from 'selectors/blockPerspectiveStateSelector';
import isScenarioMismatchSelector from 'selectors/isScenarioMismatchSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import makePublicTokensSelector from 'selectors/makePublicTokenSelector';
import { useSelectorWithProps } from 'hooks';
import { BlockError } from 'components/block-error';
import isCapsuleMismatchSelector from 'selectors/isCapsuleMismatchSelector';
import { ChoroplethTooltip } from 'components/choropleth-tooltip';
import { ChoroplethLegend } from 'components/choropleth-legend';
import chroma from 'chroma-js';

import LIGHT_STYLE from 'components/map/styles/light.json';
import { Full, Body, MaxWidth } from './choropleth.styles';
import 'mapbox-gl/dist/mapbox-gl.css';

const mapboxAccessTokenSelector = makePublicTokensSelector('mapboxAccessToken');
const units = new Units();
const NSW = {
  longitude: 146,
  latitude: -32,
  zoom: 4,
};

const propTypes = {
  blockId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  scenarioId: PropTypes.number.isRequired,
  viewMode: PropTypes.oneOf(['maximised', 'minimised', 'standalone'])
    .isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const buildHover = (object, data, labels, colors) => {
  const value = data[object.id];
  const title = labels[object.id];
  const color = colors(value);
  return { object, value, title, color, opacity: 115 };
};

const isError = (data) => get('type', data) === 'error';

const isPlaceholderError = (data) =>
  [
    'query-missing-table',
    'query-missing-colour',
    'query-missing-geography',
  ].includes(get(['data', 'code'], data));

const yearSelector = makeAggregationFilterSelector('year');

const pickYear = (year) => (data) =>
  pipe(
    get(['data', 'time_series']),
    filter(({ time }) => isNil(time) || isNil(year) || time <= year),
    last,
    get('value'),
  )(data);

const Choropleth = ({
  blockId,
  isSelected,
  scenarioId,
  viewMode,
  workspaceId,
}) => {
  const allowScrollZoom = isSelected || viewMode === 'maximised';
  const ref = useRef();
  const rect = useRect(ref);
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);
  const data = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const colourLabel = useSelectorWithProps(colourExpressionLabelSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const geojson = useSelectorWithProps(blockGeographySelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const state = useSelectorWithProps(blockPerspectiveStateSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const selectedYear = useSelectorWithProps(yearSelector, {
    blockId,
    workspaceId,
  });
  const scenarioMismatch = useSelectorWithProps(isScenarioMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const capsuleMismatch = useSelectorWithProps(isCapsuleMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const indexedData = useMemo(
    () =>
      pipe(
        get(['data', 'type'], data) === 'choropleth'
          ? get(['data', 'data'])
          : pickYear(selectedYear),
        map(({ geography, colour }) => [geography, colour]),
        fromPairs,
      )(data),
    [data, selectedYear],
  );
  const [hover, setHover] = useState(undefined);
  const bounds = useMemo(() => {
    if (!geojson || !rect) {
      return NSW;
    }
    const coordinates = pipe(
      get('features'),
      flatMap(({ geometry }) => {
        if (geometry.type === 'Polygon') {
          return flatten(geometry.coordinates);
        }
        if (geometry.type === 'MultiPolygon') {
          return flatten(flatten(geometry.coordinates));
        }
        return [];
      }),
    )(geojson);
    const outer = coordinatesToBounds(coordinates);
    return fitBounds(outer, rect.width, rect.height, 40);
  }, [geojson, rect]);
  const [hoverPosition, setHoverPosition] = useState(undefined);
  const min = useMemo(
    () => get(['data', 'metadata', 'colour', 'min'], data),
    [data],
  );
  const max = useMemo(
    () => get(['data', 'metadata', 'colour', 'max'], data),
    [data],
  );

  const colors = useMemo(
    () => scaleSequential(ramp(divergingChoroplethColors)).domain([min, max]),
    [min, max],
  );

  const labels = useMemo(
    () => defaultTo({}, get(['data', 'metadata', 'labels'], data)),
    [data],
  );

  const onHover = useCallback(
    ({ object, x, y }) => {
      if (object) {
        setHoverPosition({ x, y });
        setHover((current) =>
          !current || !current.object || current.object.id !== object.id
            ? buildHover(object, indexedData, labels, colors)
            : current,
        );
      } else {
        setHover(undefined);
      }
    },
    [setHover, indexedData, labels, colors],
  );
  const layers = useMemo(
    () => [
      new GeoJsonLayer({
        id: 'geojson-layer',
        data: geojson,
        pickable: true,
        stroked: true,
        filled: true,
        extruded: true,
        pointType: 'circle',
        lineWidthScale: 20,
        lineWidthMinPixels: 2,
        getFillColor: (d) => [
          ...chroma(colors(get(d.id, indexedData))).rgb(),
          115,
        ],
        getLineColor: (d) => [
          ...chroma(colors(get(d.id, indexedData))).rgb(),
          115,
        ],
        getPointRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        updateTriggers: {
          getFillColor: [indexedData],
          getLineWidth: [indexedData],
        },
        onHover,
      }),
    ],
    [geojson, colors, indexedData, onHover],
  );

  const colourUnit = units.parseColumn({
    unit: get(['data', 'metadata', 'colour', 'unit'], data),
  });

  const isProcessing = scenarioMismatch || state === 'processing';
  const loading = isNil(state);

  if (loading) {
    return <Loading offset={20} />;
  }

  // unrecoverable error.
  if (isError(data) && !isPlaceholderError(data)) {
    if (isProcessing && capsuleMismatch) {
      return (
        <Body>
          <MaxWidth>
            <Stack space='xsmall'>
              <Placeholder>Data is being processed.</Placeholder>
            </Stack>
          </MaxWidth>
        </Body>
      );
    }
    return (
      <BlockError
        error={get('data', data)}
        blockId={blockId}
        scenarioId={scenarioId}
        workspaceId={workspaceId}
      />
    );
  }

  return (
    <Full ref={ref}>
      {viewMode === 'maximised' && (
        <ChoroplethLegend
          heading={colourLabel}
          min={min}
          max={max}
          format={colourUnit.format}
        />
      )}
      <DeckGL
        layers={layers}
        controller={allowScrollZoom}
        initialViewState={bounds}
      >
        <StaticMap
          reuseMaps
          mapboxApiAccessToken={mapboxAccessToken}
          mapStyle={LIGHT_STYLE}
          preventStyleDiffing
        />
      </DeckGL>
      {hover && hoverPosition && (
        <ChoroplethTooltip
          x={hoverPosition.x + rect.x}
          y={hoverPosition.y + rect.y}
          value={hover.value}
          unit={colourUnit}
          color={hover.color}
          opacity={hover.opacity}
          title={hover.title}
        />
      )}
    </Full>
  );
};

Choropleth.propTypes = propTypes;
Choropleth.defaultProps = defaultProps;

export { Choropleth };
