import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, map, pipe } from 'lodash/fp';
import { Button, Cards } from '@kinesis/bungle';
import { ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import useFeatureFlag from 'hooks/useFeatureFlag';
import activeMembershipsSelector from 'selectors/activeMembershipsSelector';
import primaryMembershipSelector from 'selectors/primaryMembershipSelector';
import { datasetsList } from 'actions/datasetsList';
import datasetsSelector from 'selectors/datasetsSelector';
import { LoadableContent } from 'components/loadable-content';
import { PlaceholderMessage } from 'components/placeholder-message';
import { AppToolbar, BackButton, Breadcrumb } from 'components/app-header';
import { DatasetCard } from 'components/dataset-card';
import { DatasetCreate } from 'components/dataset-create';
import { NewDatasetModal } from 'components/modals/new-dataset';
import { PageLayout } from 'components/page-layout';
import { WorkspaceCardsWrapper } from 'components/workspace-card/workspace-card.styles';
import { ScrollableContent } from 'components/scrollable-content';

const Datasets = () => {
  const dispatch = useDispatch();
  const datasetsCreateFlag = useFeatureFlag('app.dataset-create-list');
  const hasFetchedDatasets = useSelector(get(['misc', 'hasFetchedDatasets']));
  const isFetchingDatasets = useSelector(get(['misc', 'isFetchingDatasets']));
  const isCreatingDataset = useSelector(get(['misc', 'isCreatingDataset']));
  const datasets = useSelector(datasetsSelector);
  const organisations = useSelector(get('organisations'));
  const memberships = useSelector(activeMembershipsSelector);
  const primaryOrganisationId = useSelector(
    pipe(primaryMembershipSelector, get(['organisation', 'id'])),
  );
  const membershipOranisations = useMemo(
    () => map('organisation', memberships),
    [memberships],
  );

  useEffect(() => {
    if (!hasFetchedDatasets && !isFetchingDatasets) {
      dispatch(datasetsList());
    }
  }, [dispatch, hasFetchedDatasets, isFetchingDatasets]);

  const [showNewDatasetModal, setShowNewDatasetModal] = useState(false);

  const openNewDataset = useCallback(() => {
    setShowNewDatasetModal(true);
  }, [setShowNewDatasetModal]);

  const closeNewDataset = useCallback(() => {
    setShowNewDatasetModal(false);
  }, [setShowNewDatasetModal]);

  if (isCreatingDataset) {
    return <DatasetCreate />;
  }

  return (
    <>
      <BackButton />
      <Breadcrumb to='/datasets' root>
        Datasets
      </Breadcrumb>
      {datasetsCreateFlag && (
        <AppToolbar>
          <ToolbarGroup justify='end'>
            <ToolbarItem>
              <Button onClick={openNewDataset} variant='primary'>
                New dataset
              </Button>
            </ToolbarItem>
          </ToolbarGroup>
        </AppToolbar>
      )}

      <PageLayout rounded>
        <LoadableContent loading={!hasFetchedDatasets}>
          <PlaceholderMessage
            text='Create a dataset to upload data or integrate with external systems.'
            isEmpty={isEmpty(datasets)}
          >
            <ScrollableContent>
              <WorkspaceCardsWrapper>
                <Cards view='grid'>
                  {datasets.map((dataset) => (
                    <DatasetCard
                      key={dataset.id}
                      id={dataset.id}
                      type={dataset.type}
                      name={dataset.name}
                      owner={get(dataset.owner, organisations)}
                      updated={dataset.updatedAt}
                    />
                  ))}
                </Cards>
              </WorkspaceCardsWrapper>
            </ScrollableContent>
          </PlaceholderMessage>
        </LoadableContent>
        {showNewDatasetModal && (
          <NewDatasetModal
            onClose={closeNewDataset}
            primaryOrganisationId={primaryOrganisationId}
            organisations={membershipOranisations}
          />
        )}
      </PageLayout>
    </>
  );
};

export { Datasets };
