import PropTypes from 'prop-types';

const propTypes = {
  datasetId: PropTypes.number.isRequired,
};

const defaultProps = {};
const DatasetLocationsMap = ({ datasetId }) => <div>todo {datasetId}</div>;

DatasetLocationsMap.propTypes = propTypes;
DatasetLocationsMap.defaultProps = defaultProps;

export { DatasetLocationsMap };
