import { isArray, fromPairs, map } from 'lodash/fp';

// There are two formats that levels are encoded at the moment, this
// operation exists to gloss over the difference between them for now.
//
// format 1:  { 'level-key-one': 0, 'level-key-two': '1' }
//
// format 2:  ['level-key-one', 'level-key-two']
//
// This converts format 2 into format 1, and leaves format 1.
//
const normalizeLevels = (levels) => {
  if (!levels || !isArray(levels)) return levels;
  return fromPairs(levels.map((l, index) => [l, index]));
};

const normalizeUnit = (unit) =>
  unit && unit.levels
    ? { ...unit, levels: normalizeLevels(unit.levels) }
    : unit;

const normalizeColumn = (column) => ({
  ...column,
  unit: normalizeUnit(column.unit),
});

const normalizeColumns = (columns) => map(normalizeColumn, columns);

export { normalizeLevels, normalizeUnit, normalizeColumn, normalizeColumns };
