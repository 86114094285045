import PropTypes from 'prop-types';

const propTypes = {
  datasetId: PropTypes.number.isRequired,
};

const defaultProps = {};

const DatasetLocationsProfile = ({ datasetId }) => <div>todo {datasetId}</div>;

DatasetLocationsProfile.propTypes = propTypes;
DatasetLocationsProfile.defaultProps = defaultProps;

export { DatasetLocationsProfile };
