import { createSelector } from 'reselect';
import { filter, last, noop, get, isNil, pipe } from 'lodash/fp';
import Units from 'data/units';
import blockDataSelector from 'selectors/blockDataSelector';
import { Series, X, ChartData } from 'data/xy-chart';
import namedScenariosSelector from 'selectors/namedScenariosSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import locationsSelector from 'selectors/locationsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';

const units = new Units();

const pickYear = (year, data) =>
  pipe(
    get(['data', 'time_series']),
    filter(({ time }) => isNil(time) || isNil(year) || time <= year),
    last,
    get('value'),
  )(data);

const pickData = (blockData, selectedYear) => {
  switch (get(['data', 'type'], blockData)) {
    case 'stacked-column':
    case 'clustered-column':
    case 'line':
    case 'stacked-area':
      return get(['data', 'data'], blockData);
    case 'clustered-column-time-series':
    case 'line-time-series':
    case 'stacked-column-time-series':
    case 'stacked-area-time-series':
      return pickYear(selectedYear, blockData);
    default:
      return undefined;
  }
};

const xyChartSeriesSelector = createSelector(
  blockDataSelector,
  locationsSelector,
  workspaceInstantiationsSelector,
  namedScenariosSelector,
  makeAggregationFilterSelector('year'),
  (blockData, locations, instances, namedScenarios, selectedYear) => {
    const data = pickData(blockData, selectedYear);
    const metadata = get(['data', 'metadata'], blockData);
    const xUnit =
      metadata &&
      units.parseColumn(
        get(['x', 'column'], metadata),
        locations,
        instances,
        namedScenarios,
      );
    const xUnitComparator = xUnit ? xUnit.compare : noop;
    const xLevels = X.levels(metadata, xUnitComparator);
    const yUnit =
      metadata &&
      units.parseColumn(
        get(['y', 'column'], metadata),
        locations,
        instances,
        namedScenarios,
      );
    const yUnitFormatter = yUnit ? yUnit.format : noop;
    const seriesUnitFormatter = Series.formatter(
      metadata,
      locations,
      instances,
      namedScenarios,
    );
    const seriesUnitComparator = Series.comparator(
      metadata,
      locations,
      instances,
      namedScenarios,
    );
    return ChartData.fromBlockData(
      data,
      metadata,
      xLevels,
      yUnitFormatter,
      seriesUnitFormatter,
      seriesUnitComparator,
    );
  },
);

export default xyChartSeriesSelector;
