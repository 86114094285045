import styled from 'styled-components';

const Label = styled('label')(({ theme }) => ({
  color: theme.color.gray9,
  display: 'block',
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.default,
  lineHeight: theme.lineHeight.default,
  marginBottom: 4,
}));

const Section = styled('div')(() => ({
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 8,
  paddingBottom: 8,
}));

export { Label, Section };
