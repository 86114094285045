import { createSelector } from 'reselect';
import { get, map, pipe, values } from 'lodash/fp';

const workspaceLayersSelector = createSelector(
  get('workspaces'),
  pipe(
    values,
    map((w) => ({
      workspace: w.data.id,
      layers: w.layers,
    })),
  ),
);

export default workspaceLayersSelector;
