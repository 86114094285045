import { useCallback, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isEmpty,
  defaultTo,
  get,
  last,
  map,
  pipe,
  set,
  some,
  update,
  flatMap,
  find,
  first,
  reject,
  isNil,
  toPairs,
  fromPairs,
  sortBy,
  sortedUniq,
  identity,
} from 'lodash/fp';
import { addDays, format, parseISO } from 'date-fns/fp';
import {
  DateInput,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuList,
  DropdownMenuSection,
  DropdownMenuItem,
  Tab,
  TabAddButton,
  TabBar,
  TabBarFinal,
  TabList,
  TabMenu,
  TabMenuItem,
  Tabs,
  UtilityButton,
  useToast,
} from '@kinesis/bungle';
import Modal from 'components/modals/modal/Modal';
import {
  normalise,
  supportsStages,
  supportsItems,
  locationById,
  labelOf,
} from 'data/attributes';
import { geographyUpdate } from 'actions/geographyUpdate';
import { actions as genericAttributesActions } from 'reducers/genericAttributesReducer';
import { actions as usageAttributesActions } from 'reducers/usageAttributesReducer';
import workspaceGeographiesSelector from 'selectors/workspaceGeographiesSelector';
import workspaceInstantiationSelector from 'selectors/workspaceInstantiationSelector';
import attributesSelector from 'selectors/attributesSelector';
import baselineScenarioKeySelector from 'selectors/baselineScenarioKeySelector';
import locationAttributesSelector from 'selectors/locationAttributesSelector';
import useAttributesReducer from 'hooks/useAttributesReducer';
import usePermission from 'hooks/usePermission';
import {
  normalizeAttributeStages,
  normalizeCompoundAttributeStages,
  normalizeUsageAttributeStages,
  selectAttributeStageDates,
  selectAttributeStageEntries,
  selectAvailableCategories,
  selectAvailableEntries,
  selectAttributeStageEntriesWithFields,
  selectAttributeStageHistory,
  selectCategoricalEntries,
  serializeAttributeCategories,
  serializeAttributeEntries,
  serializeAttributeFields,
} from 'utils/attributesUtils';
import { nanoid } from 'nanoid';
import { startOfYear } from 'date-fns';
import { useSelectorWithProps } from 'hooks';
import { workspacePatch } from 'actions/workspacePatch';
import CompoundAttributeValues from './compound-attribute-values';
import CustomAttributeValue from './custom-attribute-value';
import ItemisedAttributeValues from './itemised-attribute-values';
import UsageAttributes from './attribute-values.usage';
import SpatialAttributes from './attribute-values.spatial';
import GeographyAttributes from './attribute-values.geography';
import FineAreaCorrespondenceAttributes from './attribute-values.fine-area-correspondence';
import AttributeValuesHeader from './attribute-values.header';
import { DateInputWrapper } from './attribute-values.styles';

const mapWithIndex = map.convert({ cap: false });

const propTypes = {
  animation: PropTypes.bool,
  attributeKey: PropTypes.string.isRequired,
  initialDate: PropTypes.string,
  locationId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  animation: true,
  initialDate: undefined,
  privacy: 'private',
};

const takeStages = pipe(
  (location) =>
    flatMap(
      (attribute) =>
        flatMap((event) => event.date, get('time_series', attribute)),
      get('attributes', location),
    ),
  reject(isNil),
  sortBy(identity),
  sortedUniq,
);

const AttributeValues = ({
  animation,
  attributeKey,
  initialDate,
  locationId,
  onClose,
  privacy,
  scenarioId,
  workspaceId,
}) => {
  const dispatch = useDispatch();
  const [persistScenarioIds, setPersistScenarioIds] = useState([scenarioId]);
  const toast = useToast('globalTop');
  const editor = usePermission('editor');
  const [attributesState, attributeDispatch] = useAttributesReducer({
    initialDate,
    locationId,
    scenarioId,
    attributeKey,
    workspaceId,
  });
  const geographies = useSelectorWithProps(workspaceGeographiesSelector, {
    workspaceId,
  });
  const instantiation = useSelectorWithProps(workspaceInstantiationSelector, {
    key: attributeKey,
    workspaceId,
  });
  const type = instantiation.attribute;

  const { activeStageKey } = attributesState;

  const activeDate = pipe(
    get(['stages', activeStageKey, 'date']),
    defaultTo(attributesState.defaultDate),
  )(attributesState);

  const dates = useMemo(
    () => selectAttributeStageDates(attributesState),
    [attributesState],
  );

  const includeEmptyCategories = editor && privacy !== 'public';
  const categories = useMemo(
    () =>
      selectCategoricalEntries(attributesState, instantiation, {
        includeEmptyCategories,
      }),
    [attributesState, includeEmptyCategories, instantiation],
  );

  const entries = useMemo(
    () =>
      type === 'land' || type === 'solar'
        ? selectAttributeStageEntriesWithFields(attributesState, instantiation)
        : selectAttributeStageEntries(attributesState, instantiation),
    [attributesState, type, instantiation],
  );

  const availableEntries = useMemo(
    () =>
      type === 'usage-v2'
        ? selectAvailableCategories(attributesState, instantiation)
        : selectAvailableEntries(attributesState, instantiation),
    [attributesState, type, instantiation],
  );

  const {
    addStage,
    deleteStage,
    setActiveStageKey,
    setDate,
    setStages,
    setValue,
    showEntry,
  } = useMemo(
    () => bindActionCreators(genericAttributesActions, attributeDispatch),
    [attributeDispatch],
  );

  const { setDwellingsByCategory, setSpaceByCategory, setDwellings, setSpace } =
    useMemo(
      () => bindActionCreators(usageAttributesActions, attributeDispatch),
      [attributeDispatch],
    );
  const itemHistory = useMemo(
    () => selectAttributeStageHistory(attributesState),
    [attributesState],
  );

  const attributes = useSelectorWithProps(attributesSelector, {
    scenarioId,
    workspaceId,
  });
  const [fineAreaValues, setFineAreaValues] = useState(
    pipe(
      get([
        'values',
        locationId,
        'attributes',
        'fine-area-correspondence',
        'time_series',
      ]),
      first,
      get('entries'),
      map(({ key, amount }) => [key, amount.value]),
      fromPairs,
    )(attributes),
  );
  const [geographyId, setGeographyId] = useState(
    pipe(
      locationById(locationId),
      normalise(instantiation),
      get('attributes'),
      find({ key: 'geography' }),
      get('time_series'),
      first,
      get('value'),
    )(attributes),
  );
  const geography = get(geographyId, geographies);
  const [coordinate, setCoordinate] = useState({
    longitude: get(['point', 'longitude'], geography),
    latitude: get(['point', 'latitude'], geography),
  });
  const [isCreatingGeography, setIsCreatingGeography] = useState(false);

  const handleGeography = useCallback(
    ({ point }) => setCoordinate(point),
    [setCoordinate],
  );

  const current = useMemo(() => {
    const timeSeries = (() => {
      switch (type) {
        case 'fine-area-correspondence':
          return [
            {
              date: null,
              year: null,
              entries: pipe(
                toPairs,
                map(([key, value]) => ({
                  key,
                  amount: { encoding: 'float64', value },
                })),
              )(fineAreaValues),
            },
          ];
        case 'geography':
          return [
            {
              date: null,
              year: null,
              encoding: 'int64',
              value: geographyId,
            },
          ];
        case 'service-v2':
        case 'rainwater-tank':
        case 'recycled-water-system':
          return serializeAttributeEntries(attributesState);

        case 'land':
        case 'solar':
          return serializeAttributeFields(attributesState);

        case 'usage-v2':
          return serializeAttributeCategories(attributesState);

        default:
          return map(
            (item) => ({
              date: get('date', item),
              encoding: get(['value', 'encoding'], attributesState.entries),
              value: get(['values', 'value'], item),
              year: parseISO(get('date', item)).getFullYear(),
            }),
            attributesState.stages,
          );
      }
    })();

    return pipe(
      locationById(locationId),
      normalise(instantiation),
      set('coordinate', coordinate),
      update(
        'attributes',
        map((item) =>
          item.key === attributeKey
            ? set('time_series', timeSeries, item)
            : item,
        ),
      ),
    )(attributes);
  }, [
    attributes,
    attributesState,
    coordinate,
    locationId,
    instantiation,
    type,
    attributeKey,
    fineAreaValues,
    geographyId,
  ]);

  const [editDate, setEditDate] = useState();
  const [showCalendar, setShowCalendar] = useState();

  const handleTabAdd = useCallback(() => {
    const date = pipe(
      last,
      get('date'),
      parseISO,
      addDays(1),
      format('yyyy-MM-dd'),
    )(dates);

    addStage({ date, stageKey: nanoid() });
    setEditDate(date);
  }, [addStage, dates]);

  const handleTabDelete = useCallback(
    (stageKey) => {
      deleteStage({ stageKey });
    },
    [deleteStage],
  );

  const handleTabSelect = useCallback(
    (stageKey) => {
      setShowCalendar(false);
      setActiveStageKey({ stageKey });
    },
    [setActiveStageKey],
  );

  const handleCalendarOpen = useCallback((date) => {
    setEditDate(date);
    setShowCalendar(true);
  }, []);

  const handleDateChange = useCallback(
    (stageKey, newDate) => {
      setShowCalendar(false);

      if (!newDate) {
        return;
      }

      const formattedDate = format('yyyy-MM-dd', newDate);

      if (formattedDate === activeDate) {
        return;
      }

      if (some({ date: formattedDate }, dates)) {
        toast('There is already a stage on this date.', {
          variant: 'info',
          duration: 6000,
        });
      } else {
        setDate({ stageKey, date: formattedDate });
        setEditDate(newDate);
      }
    },
    [activeDate, dates, setDate, toast],
  );

  const baselineScenarioKey = useSelectorWithProps(
    baselineScenarioKeySelector,
    { workspaceId },
  );
  const baselineAttributes = useSelectorWithProps(locationAttributesSelector, {
    locationId,
    scenarioKey: baselineScenarioKey,
    workspaceId,
  });

  const handleEntryChange = useCallback(
    (entryKey, value) => {
      setValue({
        entryKey,
        value,
      });
    },
    [setValue],
  );

  const handleNewEntry = useCallback(
    (entryKey) => {
      showEntry({
        entryKey,
      });
    },
    [showEntry],
  );

  const handleCategoryDwellingsChange = useCallback(
    (categoryKey, value) => {
      setDwellingsByCategory({
        categoryKey,
        value,
      });
    },
    [setDwellingsByCategory],
  );

  const handleCategorySpaceChange = useCallback(
    (categoryKey, value) => {
      setSpaceByCategory({
        categoryKey,
        value,
      });
    },
    [setSpaceByCategory],
  );

  const handleEntryDwellingsChange = useCallback(
    (entryKey, value) => {
      setDwellings({
        entryKey,
        value,
      });
    },
    [setDwellings],
  );

  const handleEntrySpaceChange = useCallback(
    (entryKey, value) => {
      setSpace({
        entryKey,
        value,
      });
    },
    [setSpace],
  );

  const handleSave = useCallback(() => {
    // This looks/is dumb, it is to allow onBlur to fire before we pick up the values,
    // there must be a better way, but at the moments the components race, and this
    // ensures onBlur wins, and save happens after, it probably only needs to be 0,
    // to give up the thread, but there isn't a reliable test case that verifies this.
    setTimeout(() => {
      switch (type) {
        case 'geography': {
          setIsCreatingGeography(true);
          const onUpdate = (id) => {
            setGeographyId(id);
            setIsCreatingGeography(false);
          };
          dispatch(
            geographyUpdate({
              point: coordinate,
              onUpdate,
              scenarioIds: persistScenarioIds,
              locationId: current.id,
              workspaceId,
            }),
          );
          break;
        }
        default:
          dispatch(
            workspacePatch({
              targetScenarioIds: persistScenarioIds,
              patchOps: [
                {
                  'save-attribute': {
                    location: current.id,
                    attribute: current.attributes,
                  },
                },
                {
                  'add-stage': {
                    stages: takeStages(current),
                  },
                },
              ],
              actions: [
                {
                  type: 'modify-attribute-value',
                  value: {
                    name: attributeKey,
                    locations: [current.id],
                  },
                },
              ],
              workspaceId,
            }),
          );
          break;
      }
      onClose();
    }, 100);
  }, [
    type,
    onClose,
    dispatch,
    persistScenarioIds,
    current,
    attributeKey,
    workspaceId,
    coordinate,
  ]);

  const handleSetToBaseline = useCallback(() => {
    const timeSeries = get(
      ['attributes', attributeKey, 'time_series'],
      baselineAttributes,
    );

    const baselineValues = (() => {
      switch (type) {
        case 'service-v2':
        case 'rainwater-tank':
        case 'recycled-water-system':
          return normalizeAttributeStages(timeSeries);

        case 'land':
        case 'solar':
          return normalizeCompoundAttributeStages(timeSeries, {
            entryKey: attributeKey,
          });

        case 'usage-v2':
          return normalizeUsageAttributeStages(timeSeries);

        default:
      }

      return [];
    })();

    const baselineItems =
      baselineValues.length > 0
        ? mapWithIndex(
            (item, index) =>
              index < dates.length
                ? set('key', get([index, 'key'], dates), item)
                : update('key', nanoid, item),
            baselineValues,
          )
        : [
            {
              key: nanoid(),
              date: format('yyyy-MM-dd', startOfYear(new Date())),
              values: {},
            },
          ];

    setStages({
      stages: baselineItems,
    });
  }, [baselineAttributes, dates, setStages, type, attributeKey]);
  const supportsTabs = ![
    'spatial',
    'geography',
    'fine-area-correspondence',
  ].includes(type);

  return (
    <Modal
      header={
        <AttributeValuesHeader
          attributeKey={attributeKey}
          locationId={locationId}
          privacy={privacy}
          scenarioId={scenarioId}
          scenarioIds={persistScenarioIds}
          setScenarioIds={setPersistScenarioIds}
          setToBaseline={handleSetToBaseline}
          workspaceId={workspaceId}
        />
      }
      aria-label={labelOf({ attribute: type })}
      valid={!isCreatingGeography}
      magnitude={
        [
          'spatial',
          'geography',
          'usage-v2',
          'fine-area-correspondence',
        ].includes(type)
          ? 'large'
          : 'small'
      }
      onClose={onClose}
      onSave={handleSave}
      saveable={privacy !== 'public'}
      animation={animation}
    >
      {supportsTabs && (
        <Tabs activeKey={activeStageKey} onChange={handleTabSelect}>
          <TabBar>
            <TabList>
              {dates.map((item) => (
                <Tab
                  key={item.key} // eslint-disable-line react/no-array-index-key
                  onEntered={() => {
                    if (editDate === item.date) {
                      setShowCalendar(true);
                    }
                  }}
                  tabKey={item.key}
                  title={format('d MMM yyyy', parseISO(item.date))}
                >
                  {showCalendar && item.date === editDate && (
                    <DateInputWrapper>
                      <DateInput
                        alwaysOpen
                        magnitude='large'
                        onCancel={() => {
                          setShowCalendar(false);
                          setEditDate(undefined);
                        }}
                        onChange={(newDate) => {
                          handleDateChange(item.key, newDate);
                        }}
                        value={parseISO(item.date)}
                      />
                    </DateInputWrapper>
                  )}
                  {privacy !== 'public' && supportsStages(type) && (
                    <TabMenu>
                      <TabMenuItem
                        onSelect={() => handleCalendarOpen(item.date)}
                      >
                        Change date
                      </TabMenuItem>
                      <TabMenuItem
                        variant='danger'
                        onSelect={() => handleTabDelete(item.key)}
                      >
                        Remove
                      </TabMenuItem>
                    </TabMenu>
                  )}
                </Tab>
              ))}
            </TabList>
            {privacy !== 'public' && supportsStages(type) && (
              <TabAddButton onClick={handleTabAdd} />
            )}
            {privacy !== 'public' && supportsItems(type) && (
              <TabBarFinal>
                <DropdownMenu justify='end'>
                  <DropdownMenuButton
                    as={UtilityButton}
                    icon='plus'
                    magnitude='small'
                  >
                    Add item
                  </DropdownMenuButton>
                  <DropdownMenuList maxHeight={240}>
                    {(type === 'service-v2' ||
                      type === 'rainwater-tank' ||
                      type === 'recycled-water-system') &&
                      availableEntries.map((item) => (
                        <DropdownMenuItem
                          key={item.key}
                          disabled={item.visible}
                          onSelect={() => handleNewEntry(item.key)}
                        >
                          {item.label || 'New item'}
                        </DropdownMenuItem>
                      ))}
                    {type === 'usage-v2' &&
                      availableEntries.map((cat) => (
                        <DropdownMenuSection key={cat.key} title={cat.label}>
                          {cat.entries.map((item) => (
                            <DropdownMenuItem
                              key={item.key}
                              disabled={item.visible}
                              onSelect={() => handleNewEntry(item.key)}
                            >
                              {item.label || 'New item'}
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuSection>
                      ))}
                  </DropdownMenuList>
                </DropdownMenu>
              </TabBarFinal>
            )}
          </TabBar>
        </Tabs>
      )}
      {type === 'geography' && (
        <GeographyAttributes
          latitude={get('latitude', coordinate)}
          locationId={locationId}
          longitude={get('longitude', coordinate)}
          onChange={handleGeography}
          privacy={privacy}
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      )}
      {type === 'spatial' && (
        <SpatialAttributes
          latitude={get('latitude', coordinate)}
          locationId={locationId}
          longitude={get('longitude', coordinate)}
          onChange={setCoordinate}
          privacy={privacy}
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      )}
      {type === 'fine-area-correspondence' && (
        <FineAreaCorrespondenceAttributes
          values={fineAreaValues}
          setValues={setFineAreaValues}
        />
      )}
      {type === 'usage-v2' && (
        <UsageAttributes
          categories={categories}
          date={activeDate}
          history={
            isEmpty(itemHistory)
              ? [
                  {
                    date: activeDate,
                    value: 0,
                    label: format('d MMM yyyy', parseISO(activeDate)),
                  },
                ]
              : itemHistory
          }
          locationId={locationId}
          onCategoryDwellingsChange={handleCategoryDwellingsChange}
          onCategorySpaceChange={handleCategorySpaceChange}
          onEntryDwellingsChange={handleEntryDwellingsChange}
          onEntrySpaceChange={handleEntrySpaceChange}
          privacy={privacy}
          scenarioId={scenarioId}
          workspaceId={workspaceId}
        />
      )}
      {(type === 'land' || type === 'solar') && (
        <CompoundAttributeValues
          entries={entries}
          onChange={handleEntryChange}
          privacy={privacy}
        />
      )}
      {(type === 'service-v2' ||
        type === 'rainwater-tank' ||
        type === 'recycled-water-system') && (
        <ItemisedAttributeValues
          entries={entries}
          onChange={handleEntryChange}
          privacy={privacy}
        />
      )}
      {type === 'custom' && (
        <CustomAttributeValue
          onChange={handleEntryChange}
          privacy={privacy}
          value={get(
            ['stages', activeStageKey, 'values', 'value'],
            attributesState,
          )}
        />
      )}
    </Modal>
  );
};

AttributeValues.defaultProps = defaultProps;
AttributeValues.propTypes = propTypes;

export default AttributeValues;
