import { filter, get, map, pipe } from 'lodash/fp';
import createShallowEqualSelector from 'utils/createShallowEqualSelector';
import workspaceSelector from './workspaceSelector';

const enabledLayersSelector = createShallowEqualSelector(
  pipe(workspaceSelector, get(['layers', 'entities'])),
  pipe(filter('enabled'), map('id')),
);

export default enabledLayersSelector;
