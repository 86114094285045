import { createSelector } from 'reselect';
import { get, filter, pipe } from 'lodash/fp';
import blockPerspectiveSelector from './blockPerspectiveSelector';
import workspaceSelector from './workspaceSelector';

const fetchablePublicDataSelector = createSelector(
  blockPerspectiveSelector,
  pipe(workspaceSelector, get(['data', 'lifecycle'])),
  (perspectives, lifecycle) =>
    filter(
      (id) => get(id, lifecycle) !== 'OK' && get(id, lifecycle) !== 'FETCHING',
      perspectives,
    ),
);

export default fetchablePublicDataSelector;
