import PropTypes from 'prop-types';
import GenericSettings from './attribute-settings.generic';

const propTypes = {
  instantiation: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};
const defaultProps = {};

const description =
  'Land area describes the size and expected floor space ratio for this location.';

const LandSettings = ({ instantiation, setting, workspaceId }) => (
  <GenericSettings
    description={description}
    hasSettings={false}
    instantiation={instantiation}
    setting={setting}
    workspaceId={workspaceId}
  />
);

LandSettings.defaultProps = defaultProps;
LandSettings.propTypes = propTypes;

export default LandSettings;
