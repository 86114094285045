import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { get, cloneDeep } from 'lodash/fp';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatAnnualEmissionsTooltip } from 'utils/charts/tooltips';
import { Stack, H2 } from '@kinesis/bungle';
import useResponsive from 'hooks/useResponsive';
import { useTheme } from 'styled-components';
import Units from 'data/units';
import { AnnualEmissionsLegend } from './annual-emissions-legend';
import {
  ChartContainer,
  HighchartsWrapper,
  policyContextColors as seriesColors,
} from './chart.styles';

const units = new Units();
const yearUnit = units.parseType({ type: 'year' });

const AnnualEmissions = () => {
  const { isDesktopAndUp } = useResponsive();
  const theme = useTheme();
  const ref = useRef();

  // This is a workaround for immutable data attempting to be mutated, possibly by Highcharts,
  // which would cause the page to go blank and error out completely whenever Pathways inputs
  // got updated. Deep-cloning the values from redux works around this issue for now.
  // See https://github.com/kinesisptyltd/marathon/issues/8957
  const reduxEmissions = useSelector(get(['pathways', 'emissions']));
  const emissions = useMemo(() => cloneDeep(reduxEmissions), [reduxEmissions]);

  const unit = units.parseType(emissions.unit);
  const yAxisUnit = units.parseType(emissions.y_axis_unit || emissions.unit);

  const options = useMemo(
    () => ({
      plotOptions: {
        series: {
          animation: false,
          pointStart: get('base', emissions),
          pointPlacement: 'on',
        },
        line: {
          marker: {
            enabled: false,
            symbol: 'circle',
            states: {
              hover: {
                radius: 4,
                lineWidth: 0,
              },
            },
          },
          states: {
            hover: {
              enabled: true,
              halo: null,
              lineWidthPlus: 0,
            },
          },
        },
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: get('current', emissions).map(
          (_, index) => get('base', emissions) + index,
        ),
        gridLineWidth: 1,
        gridLineColor: theme.colors.gray4.hex,
        lineColor: theme.colors.gray4.hex,
        lineWidth: 1,
        tickInterval: 10,
        labels: {
          useHTML: true,
          formatter(o) {
            return yearUnit.formatCell(o.value);
          },
          style: {
            color: 'rgba(0,0,0,0.65)',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
          },
          rotation: 0,
        },
        title: {
          style: {
            color: 'rgba(0,0,0,0.85)',
            fontWeight: 500,
          },
          y: 8, // move title to edge
        },
      },
      yAxis: {
        gridLineWidth: 1,
        gridLineColor: theme.colors.gray4.hex,
        lineColor: theme.colors.gray4.hex,
        lineWidth: 1,
        endOnTick: false,
        labels: {
          x: -9,
          useHTML: true,
          formatter(o) {
            return yAxisUnit.formatCell(o.value);
          },
          style: {
            color: 'rgba(0,0,0,0.65)',
            fontSize: '12px',
            lineHeight: '20px',
            fontWeight: 400,
          },
        },
        title: {
          useHTML: true,
          text: `Annual emissions (${unit.symbol})`,
          style: {
            color: 'rgba(0,0,0,0.85)',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '20px',
          },
          offset: 106,
        },
      },
      credits: { enabled: false },
      title: { text: undefined },
      lang: {
        noData: 'There is no data.',
      },
      noData: {
        style: {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          color: 'rgba(0,0,0,0.25)',
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: 'rgba(255,255,255,0)',
        borderWidth: 0,
        shadow: false,
        animation: false,
        hideDelay: 0,
        outside: true,
        padding: 0,
        formatter() {
          return formatAnnualEmissionsTooltip(this);
        },
      },
      chart: {
        height: 338,
        marginLeft: 120,
        type: 'line',
        animation: false,
        plotBorderWidth: 1,
        plotBorderColor: theme.colors.gray4.hex,
        plotBackgroundColor: `rgba(0,0,0,0)`,
        spacing: [1, 1, 0, 1],
        style: {
          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
          fontSize: '12px',
        },
      },
      series: [
        {
          color: seriesColors.current,
          name: 'Current settings',
          data: get('current', emissions),
          unitFormatter: unit.format,
        },
        {
          color: seriesColors.intermediate,
          name: 'Intermediate',
          data: get('intermediate', emissions),
          unitFormatter: unit.format,
        },
        {
          color: seriesColors.aggressive,
          name: 'Aggressive',
          data: get('aggressive', emissions),
          unitFormatter: unit.format,
        },
      ],
    }),
    [theme, emissions, unit, yAxisUnit],
  );

  return (
    <Stack space='medium'>
      <H2>Annual pathway emissions</H2>
      <ChartContainer>
        <HighchartsWrapper>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={ref}
          />
        </HighchartsWrapper>
        {isDesktopAndUp && <AnnualEmissionsLegend />}
      </ChartContainer>
    </Stack>
  );
};

export { AnnualEmissions };
