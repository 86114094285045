import { createAsyncThunk } from '@reduxjs/toolkit';
import createApi from 'api/createApi';

const userRelogin = createAsyncThunk(
  'user/relogin',
  async (_, { getState }) => {
    const { auth } = getState();
    const api = createApi(auth.token);

    const response = await api.get('/api/authenticate');

    return { ...response.data, newLogin: true };
  },
);

export { userRelogin };
