import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceProgress = createAsyncThunk(
  'workspaceProgress/fetch',
  async ({ workspaceId, scenarios }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/workspace/${workspaceId}/progress`, {
      scenarios,
    });
    return response.data;
  },
);

export { workspaceProgress };
