import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, MultiSlider, NumberInput, Stack } from '@kinesis/bungle';

const propTypes = {
  lowerValue: PropTypes.number,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  upperValue: PropTypes.number,
};

const defaultProps = {
  lowerValue: undefined,
  onBlur: undefined,
  upperValue: undefined,
};

const RangeSliderWithInputNumbers = ({
  lowerValue,
  max,
  min,
  onBlur,
  onChange,
  upperValue,
}) => {
  const [range, setRange] = useState([lowerValue || min, upperValue || max]);

  const handleLeftInputChange = useCallback((value) => {
    setRange(([, to]) => [value, to]);
  }, []);

  const handleRightInputChange = useCallback((value) => {
    setRange(([from]) => [from, value]);
  }, []);

  const isRangeValid =
    Number.isInteger(range[0]) &&
    Number.isInteger(range[1]) &&
    range[0] >= min &&
    range[1] <= max &&
    range[0] <= range[1];

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (isRangeValid) {
        // update with new values
        onChange(range);
      } else {
        // reset state to previous valid values
        setRange([lowerValue, upperValue]);
      }
    }, 500);

    return () => clearTimeout(timerId);
  }, [isRangeValid, lowerValue, onChange, range, upperValue]);

  return (
    <Stack space='small'>
      <InputGroup magnitude='large'>
        <NumberInput
          autoFocus
          min={min}
          max={max}
          value={range[0]}
          placeholder='Enter value'
          onChange={handleLeftInputChange}
          onBlur={onBlur}
        />
        <NumberInput
          min={min}
          max={max}
          value={range[1]}
          placeholder='Enter value'
          onChange={handleRightInputChange}
          onBlur={onBlur}
        />
      </InputGroup>
      <MultiSlider
        highlightTrack
        min={min}
        max={max}
        onBlur={onBlur}
        onChange={setRange}
        values={[lowerValue, upperValue]}
      />
    </Stack>
  );
};

RangeSliderWithInputNumbers.propTypes = propTypes;
RangeSliderWithInputNumbers.defaultProps = defaultProps;

export default RangeSliderWithInputNumbers;
