import PropTypes from 'prop-types';
import { Inline, InlineItem, Text } from '@kinesis/bungle';
import useTheme from 'hooks/useTheme';
import { BreakdownIcon } from './breakdown-icon';

const propTypes = {
  label: PropTypes.string.isRequired,
};

const defaultProps = {};

const BreakdownLabel = ({ label }) => {
  const theme = useTheme();
  return (
    <Inline space='small'>
      <InlineItem>
        <BreakdownIcon magnitude='small' color={theme.color.gray8} />
      </InlineItem>
      <InlineItem>
        <Text>{label}</Text>
      </InlineItem>
    </Inline>
  );
};

BreakdownLabel.propTypes = propTypes;
BreakdownLabel.defaultProps = defaultProps;

export { BreakdownLabel };
