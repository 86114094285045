import { every, get } from 'lodash/fp';
import Bowser from 'bowser';
const { getParser } = Bowser;

const parser = getParser(window.navigator.userAgent);
const osName = parser.getOSName();

const Shortcuts = (isMacOs) => {
  const modifiers = {
    none: {
      keys: [],
      inverseKeys: ['ctrlKey', 'altKey', 'metaKey', 'shiftKey'],
    },
    primary: isMacOs
      ? {
          keys: ['metaKey'],
          inverseKeys: ['ctrlKey', 'altKey', 'shiftKey'],
          description: '⌘',
        }
      : {
          keys: ['ctrlKey'],
          inverseKeys: ['altKey', 'metaKey', 'shiftKey'],
          description: 'Ctrl',
        },
    alternative: isMacOs
      ? {
          keys: ['altKey'],
          inverseKeys: ['ctrlKey', 'metaKey', 'shiftKey'],
          description: '⌥',
        }
      : {
          keys: ['altKey'],
          inverseKeys: ['ctrlKey', 'metaKey', 'shiftKey'],
          description: 'Alt',
        },
    secondary: isMacOs
      ? {
          keys: ['metaKey', 'shiftKey'],
          inverseKeys: ['ctrlKey', 'altKey'],
          description: '⌘+⇧',
        }
      : {
          keys: ['ctrlKey', 'shiftKey'],
          inverseKeys: ['metaKey', 'altKey'],
          description: 'Ctrl+Shift',
        },
  };

  const keys = {
    slash: {
      symbol: '/',
      code: 'Slash',
    },
    k: {
      symbol: 'k',
      code: 'KeyK',
    },
    y: {
      symbol: 'y',
      code: 'KeyY',
    },
    z: {
      symbol: 'z',
      code: 'KeyZ',
    },
  };

  const generateMnemonic = (modifier, key) => {
    const ks = [];
    if (modifier.description) {
      ks.push(modifier.description);
    }

    ks.push(key.symbol);

    return ks.join('+');
  };

  const modifierMatch = (event, modifier) =>
    every((key) => event[key], modifier.keys) &&
    every((key) => !event[key], modifier.inverseKeys);

  const codeMatch = (event, key) => get('code', event) === key.code;

  const makeShortcut = (sequence) => {
    const modifier = modifiers[sequence.modifier];
    const key = keys[sequence.key];
    const mnemonic = generateMnemonic(modifier, key);
    const handler = (e) => {
      if (modifierMatch(e, modifier) && codeMatch(e, key)) {
        e.preventDefault();
        return true;
      }
      return false;
    };

    return {
      handler,
      mnemonic,
    };
  };

  const shortcut = (modifier, key) => makeShortcut({ modifier, key });

  return {
    FILTER_LOCATIONS: shortcut('alternative', 'slash'),
    REDO: isMacOs ? shortcut('secondary', 'z') : shortcut('primary', 'y'),
    SWITCHER: shortcut('primary', 'k'),
  };
};

const shortcuts = Shortcuts(osName === 'macOS');

export { shortcuts, Shortcuts };
