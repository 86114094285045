import styled from 'styled-components';

const ValueCardWrapper = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  background: white;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ValueCardSubtitle = styled('div')`
  flex: 0 0 auto;
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  line-height: ${({ theme }) => theme.lineHeight.lg};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.color.gray8};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ValueCard = styled('div')`
  box-sizing: border-box;
  padding: 0 16px;
  margin: 0 0 16px;
  text-align: center;
  max-width: 100%;
`;

const ValueCardValue = styled('div')`
  box-sizing: border-box;
  font-size: 38px;
  line-height: 46px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme, lighten }) =>
    lighten ? theme.color.gray6 : theme.color.gray9};
  margin-bottom: 4px;
  font-variant-numeric: normal;
  font-feature-settings: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ValueCardCaret = styled('div')`
  display: inline-block;
  padding-right: 4px;
  margin-bottom: 4px;
`;

const ValueCardNoData = styled('div')`
  font-size: ${({ theme }) => theme.fontSize.md}px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.md};
  color: ${({ theme }) => theme.color.gray6};
  margin-bottom: 16px;
`;

export {
  ValueCard,
  ValueCardCaret,
  ValueCardNoData,
  ValueCardSubtitle,
  ValueCardValue,
  ValueCardWrapper,
};
