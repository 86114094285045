import styled from 'styled-components';

const ToolbarText = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.gray8};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.default};
`;

export { ToolbarText };
