import { createSlice } from '@reduxjs/toolkit';
import {
  camelCase,
  get,
  keyBy,
  last,
  mapKeys,
  pipe,
  map,
  set,
  unset,
} from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { boardCreate } from 'actions/boardCreate';
import { boardUpdate } from 'actions/boardUpdate';
import { boardDelete } from 'actions/boardDelete';
import { appsUpgrade } from 'actions/appsUpgrade';
import { blockAdd } from 'actions/blockAdd';
import { blocksRemove } from 'actions/blocksRemove';
import { blocksReorder } from 'actions/blocksReorder';
import { latestBlockIdUnset } from 'actions/latestBlockIdUnset';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';

const updateBoards = (state, action) =>
  pipe(
    map((board) => ({
      ...pipe(mapKeys(camelCase))(board),
      label: board.name,
      blocks: map('permalink', board.blocks),
    })),
    keyBy('id'),
  )(action.payload.boards);

const { reducer, actions } = createSlice({
  name: 'boards',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(workspaceFetch.fulfilled, updateBoards);
    builder.addCase(workspacePatch.fulfilled, updateBoards);
    builder.addCase(appAdd.fulfilled.type, updateBoards);
    builder.addCase(appsUpgrade.fulfilled.type, updateBoards);

    builder.addCase(blockAdd.fulfilled.type, (state, action) =>
      set(
        action.payload.id,
        {
          ...pipe(mapKeys(camelCase))(action.payload),
          label: action.payload.name,
          blocks: map('permalink', action.payload.blocks),
          latestBlockId: get('permalink', last(action.payload.blocks)),
        },
        state,
      ),
    );

    builder.addCase(boardCreate.fulfilled.type, (state, action) =>
      set(
        action.payload.id,
        {
          ...pipe(mapKeys(camelCase))(action.payload),
          label: action.payload.name,
          blocks: map('permalink', action.payload.blocks),
        },
        state,
      ),
    );

    builder.addCase(boardUpdate.pending.type, (state, action) => {
      const { boardId, params } = action.meta.arg;

      return set(
        boardId,
        {
          ...state[boardId],
          ...params,
          nameInitialized: !!params.name,
          label: params.name || state[boardId].label,
        },
        state,
      );
    });

    builder.addCase(boardUpdate.fulfilled.type, (state, action) =>
      set(
        action.payload.id,
        {
          ...pipe(mapKeys(camelCase))(action.payload),
          label: action.payload.name,
          blocks: map('permalink', action.payload.blocks),
        },
        state,
      ),
    );

    builder.addCase(boardDelete.fulfilled.type, (state, action) =>
      unset(action.meta.arg.boardId, state),
    );

    builder.addCase(latestBlockIdUnset.type, (state, action) =>
      unset([action.payload.id, 'latestBlockId'], state),
    );

    builder.addCase(blocksReorder.pending.type, (state, action) => {
      const { requestId } = action.meta;
      const { boardId, blockIds } = action.meta.arg;

      const currentBoard = state[boardId];
      const fixedBlockIds = blockIds.map((bid) =>
        bid === 'row-break' ? requestId : bid,
      );

      return set(
        action.meta.arg.boardId,
        {
          ...currentBoard,
          blocks: fixedBlockIds,
        },
        state,
      );
    });

    builder.addCase(blocksReorder.fulfilled.type, (state, action) =>
      set(
        action.payload.id,
        {
          ...pipe(mapKeys(camelCase))(action.payload),
          label: action.payload.name,
          blocks: map('permalink', action.payload.blocks),
        },
        state,
      ),
    );

    builder.addCase(blocksRemove.fulfilled.type, (state, action) =>
      set(
        action.payload.id,
        {
          ...pipe(mapKeys(camelCase))(action.payload),
          label: action.payload.name,
          blocks: map('permalink', action.payload.blocks),
        },
        state,
      ),
    );
  },
});

export { reducer, actions };
