import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const populationDatasetFetch = createAsyncThunk(
  'population-dataset/fetch',
  async ({ datasetId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.get(
      `/api/resources/custom:dpie:population/datasets/${datasetId}`,
    );
    return response.data;
  },
);

export { populationDatasetFetch };
