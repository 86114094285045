import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const boardUpdate = createAsyncThunk(
  'board/update',
  async ({ boardId, workspaceId, params }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.patch(
      `/api/workspace/${workspaceId}/board/${boardId}`,
      { boardId, ...params },
    );

    return response.data;
  },
);

export { boardUpdate };
