import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const userLogout = createAsyncThunk(
  'auth/userLogout',
  async (_, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    await api.post('/api/logout');
    // force reload of login app.
    window.location = '/';
  },
);

export { userLogout };
