import PropTypes from 'prop-types';

const propTypes = {
  to: PropTypes.string.isRequired,
};

const RedirectReload = ({ to }) => {
  window.location.replace(to);
};

RedirectReload.propTypes = propTypes;

export { RedirectReload };
