import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  groupBy,
  map,
  uniq,
  toPairs,
  flatMap,
  isNil,
  get,
  pipe,
  mapValues,
  find,
} from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';
import createApiFromState from 'api/createApiFromState';

const statisticsFetch = createAsyncThunk(
  'statistics/fetch',
  async ({ workspaceId, scenarios }, { dispatch, getState }) => {
    const state = getState();
    const api = createApiFromState({ dispatch, getState });
    const { resources } = workspaceSelector(state, { workspaceId });
    const required = pipe(
      groupBy('scenarioKey'),
      mapValues(pipe(map('table'), uniq)),
      toPairs,
      flatMap(([scenarioKey, tables]) =>
        map((table) => ({
          scenarioKey,
          table,
        }))(tables),
      ),
    )(scenarios);
    const responses = await Promise.all(
      required.map(({ scenarioKey, table }) =>
        api
          .get(
            `/api/workspace/${workspaceId}/scenario/${scenarioKey}/statistics/${get(
              table,
              resources,
            )}`,
          )
          .then((response) => ({
            scenarioKey,
            table,
            statistics: response.data.metadata,
          })),
      ),
    );
    return scenarios.map(({ blockId, scenarioId, scenarioKey, table }) => {
      const fetched = find({ scenarioKey, table }, responses);
      return {
        blockId,
        scenarioId,
        table,
        scenarioKey,
        result: isNil(fetched) ? 'cached' : 'fetched',
        statistics: get('statistics', fetched),
      };
    });
  },
);

export { statisticsFetch };
