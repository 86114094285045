import styled from 'styled-components';

const Panel = styled.div`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  width: 304px;
  background: #ffffff;
  max-width: 100%;
  padding: 16px;
`;

const Message = styled.div`
  color: ${({ theme }) => theme.color.gray8};
  font-size: ${({ theme }) => theme.fontSize.default}px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.default};
`;

export { Panel, Message };
