import { createSelector } from 'reselect';
import { filter, get, map, pipe, values } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const layersSelector = createSelector(
  pipe(workspaceSelector, get(['layers', 'entities'])),
  pipe(workspaceSelector, get('locations')),
  (layers, locations) =>
    pipe(
      values,
      map((layer) => ({
        id: layer.id,
        enabled: layer.enabled,
        label: layer.label,
        color: layer.color,
        type: layer.type,
        locations: filter({ layer: layer.id }, locations),
      })),
    )(layers),
);

export default layersSelector;
