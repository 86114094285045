/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  filter,
  find,
  get,
  identity,
  includes,
  map,
  pipe,
  reject,
  set,
} from 'lodash/fp';
import { WebMercatorViewport } from '@math.gl/web-mercator';
import {
  H3,
  Inline,
  InlineItem,
  NumberInput,
  Stack,
  Subheading,
} from '@kinesis/bungle';
import { withinBounds } from 'utils/spatialUtils';
import Layout from 'components/layout';
import Section from 'components/section';
import Map from 'components/map/Map';
import PositionHover from 'components/position-hover/PositionHover';
import MapContainer from 'components/map-container/MapContainer';
import layersSelector from 'selectors/layersSelector';
import layerBoundsSelector from 'selectors/layerBoundsSelector';
import layerColorSelector from 'selectors/layerColorSelector';
import makePublicTokenSelector from 'selectors/makePublicTokenSelector';
import scenarioLocationsSelector from 'selectors/scenarioLocationsSelector';
import { visualisationLocationLayers } from 'components/spatial-visualisation/location-layers';
import editLayers from 'components/spatial-visualisation/edit-layers';
import { useSelectorWithProps } from 'hooks';
import useTheme from 'hooks/useTheme';

import { SidePane } from './attribute-values.styles';

const propTypes = {
  latitude: PropTypes.number,
  locationId: PropTypes.number.isRequired,
  longitude: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['privacy', 'public']),
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  latitude: undefined,
  longitude: undefined,
  privacy: 'private',
};

const mapboxAccessTokenSelector = makePublicTokenSelector('mapboxAccessToken');

const determineBounds = (state) => {
  const viewport = new WebMercatorViewport(state);
  return [
    viewport.unproject([0, 0]),
    viewport.unproject([viewport.width, viewport.height]),
  ];
};

const SpatialAttributes = ({
  latitude,
  locationId,
  longitude,
  onChange,
  privacy,
  scenarioId,
  workspaceId,
}) => {
  const theme = useTheme();
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);
  const layers = useSelectorWithProps(layersSelector, {
    public: privacy === 'public',
    workspaceId,
  });
  const layer = useMemo(
    () =>
      find(
        pipe(
          get('locations'),
          map((l) => l.id),
          includes(locationId),
        ),
      )(layers),
    [layers, locationId],
  );
  const layerId = get('id', layer);
  const colorName = useSelectorWithProps(layerColorSelector, {
    layerId,
    public: privacy === 'public',
    workspaceId,
  });
  const layerColor = get(['color', 'layer', colorName], theme);
  const scenarioLocations = useSelectorWithProps(scenarioLocationsSelector, {
    layerId,
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });
  const locationIds = map(get('id'), get('locations', layer));
  const bounds = useSelectorWithProps(layerBoundsSelector, {
    layerId,
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });

  const [viewport, setViewport] = useState();
  const [target, setTarget] = useState(undefined);
  const [hover, setHover] = useState(undefined);
  const [hoverEdit, setHoverEdit] = useState(undefined);
  const [drag, setDrag] = useState(undefined);
  const [isDragging, setIsDragging] = useState(undefined);

  const setRoundedCoordinate = useCallback(
    (coord) => {
      const rounded = {
        latitude: parseFloat(coord.latitude.toFixed(5)),
        longitude: parseFloat(coord.longitude.toFixed(5)),
      };
      onChange(rounded);
      const currentBounds = viewport ? determineBounds(viewport) : bounds;
      if (!withinBounds(currentBounds, rounded)) {
        return setTarget(rounded);
      }
    },
    [bounds, onChange, viewport],
  );

  const onHover = useCallback(
    ({ object }) => {
      setHoverEdit(!!object);
    },
    [setHoverEdit],
  );

  const onDrag = useCallback(
    ({ coordinate: dragCoordinate, x, y }) => {
      if (dragCoordinate) {
        setDrag({ latitude: dragCoordinate[1], longitude: dragCoordinate[0] });
        setHover({
          longitude: dragCoordinate[0],
          latitude: dragCoordinate[1],
          x,
          y,
        });
      }
      return true;
    },
    [setDrag],
  );

  const handleLatitudeChange = useCallback(
    (value) => {
      onChange({
        latitude: value,
        longitude,
      });
    },
    [longitude, onChange],
  );

  const handleLongitudeChange = useCallback(
    (value) => {
      onChange({
        latitude,
        longitude: value,
      });
    },
    [latitude, onChange],
  );

  const onDragStart = useCallback(() => {
    setIsDragging(true);
    return true;
  }, [setIsDragging]);

  const onDragEnd = useCallback(
    ({ coordinate: dragCoordinate }) => {
      setIsDragging(false);
      setDrag(undefined);
      setHover(undefined);
      if (dragCoordinate) {
        setRoundedCoordinate({
          longitude: dragCoordinate[0],
          latitude: dragCoordinate[1],
        });
      }
      return true;
    },
    [setRoundedCoordinate],
  );

  const getCursor = useCallback(() => {
    if (isDragging) return 'none';
    return 'grab';
  }, [isDragging]);

  const deckLocationLayers = useMemo(
    () =>
      visualisationLocationLayers({
        locations: pipe(
          privacy === 'public' ? identity : reject({ id: locationId }),
          filter((l) => includes(l.id, locationIds)),
          map(set('layerColor', layerColor)),
        )(scenarioLocations),
        focus: false,
        selectedId: privacy === 'public' ? locationId : undefined,
      }),
    [layerColor, locationId, locationIds, privacy, scenarioLocations],
  );
  const deckEditLayers = useMemo(
    () =>
      editLayers({
        hovering: hoverEdit,
        latitude,
        layerColor,
        longitude,
        onDrag,
        onDragEnd,
        onDragStart,
        onHover,
        ...(drag || {}),
      }),
    [
      drag,
      hoverEdit,
      latitude,
      layerColor,
      longitude,
      onDrag,
      onDragEnd,
      onDragStart,
      onHover,
    ],
  );

  return (
    <Layout direction='row'>
      <SidePane>
        <Section collapsible={false} heading='Location'>
          <Stack space='medium'>
            {privacy === 'public' ? (
              <Inline space='small'>
                <InlineItem sizing='fill-container'>
                  <H3>Latitude</H3>
                  <Subheading>{latitude}</Subheading>
                </InlineItem>
                <InlineItem sizing='fill-container'>
                  <H3>Longitude</H3>
                  <Subheading>{longitude}</Subheading>
                </InlineItem>
              </Inline>
            ) : (
              <Inline space='small'>
                <InlineItem sizing='fill-container'>
                  <NumberInput
                    label='Latitude'
                    magnitude='large'
                    format={(val) => val.toFixed(6)}
                    value={latitude}
                    onChange={handleLatitudeChange}
                    numberType='decimal'
                    min={-85}
                    max={85}
                    negative
                    step={0.0001}
                  />
                </InlineItem>
                <InlineItem sizing='fill-container'>
                  <NumberInput
                    label='Longitude'
                    magnitude='large'
                    format={(val) => val.toFixed(6)}
                    value={longitude}
                    onChange={handleLongitudeChange}
                    numberType='decimal'
                    min={-180}
                    max={180}
                    negative
                    step={0.0001}
                  />
                </InlineItem>
              </Inline>
            )}
          </Stack>
        </Section>
      </SidePane>
      <Layout direction='column'>
        <MapContainer>
          {hover && isDragging && (
            <PositionHover x={hover.x} y={hover.y}>
              {hover.latitude.toFixed(5)}, {hover.longitude.toFixed(5)}
            </PositionHover>
          )}
          <Map
            dragPan={!isDragging}
            mapboxAccessToken={mapboxAccessToken}
            mapStyle='light'
            layers={
              privacy === 'public'
                ? deckLocationLayers
                : [...deckLocationLayers, ...deckEditLayers]
            }
            getCursor={getCursor}
            bounds={target ? undefined : bounds}
            target={target || undefined}
            onViewportChange={setViewport}
          />
        </MapContainer>
      </Layout>
    </Layout>
  );
};

SpatialAttributes.defaultProps = defaultProps;
SpatialAttributes.propTypes = propTypes;

export default SpatialAttributes;
