import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Cards,
  ConfirmationDialog,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  UtilityButton,
} from '@kinesis/bungle';
import { Toolbar, ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { filter, get, identity, map, noop, pipe, size } from 'lodash/fp';
import { isValid, max, parseISO } from 'date-fns/fp';
import sortedScenariosSelector from 'selectors/sortedScenariosSelector';
import { scenarioCreate } from 'actions/scenarioCreate';
import { scenarioDuplicate } from 'actions/scenarioDuplicate';
import { scenarioDelete } from 'actions/scenarioDelete';
import locationsSelector from 'selectors/locationsSelector';
import scenarioSelector from 'selectors/scenarioSelector';
import { useSelectorWithProps } from 'hooks';
import { makeKeyboardShortcut } from 'utils/keyboardUtils';
import { ContentSection, ActionsCard } from 'components/content-section';
import { PublishDraftsWrapper } from './named-scenarios.styles';
import NamedScenarioItem from './NamedScenarioItem';

const maxDate = pipe(filter(identity), map(parseISO), max, (d) =>
  isValid(d) ? d : undefined,
);

const propTypes = {
  editor: PropTypes.bool.isRequired,
  showPrompt: PropTypes.bool,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  showPrompt: false,
};

const NamedScenarioChooser = ({ editor, showPrompt, workspaceId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortScenariosField, setSortScenariosField] = useState('name');
  const [deleteScenarioId, setDeleteScenarioId] = useState();
  const locationsCount = size(
    useSelectorWithProps(locationsSelector, { workspaceId }),
  );

  const deleteScenarioName = useSelector(
    useCallback(
      (state) =>
        deleteScenarioId
          ? scenarioSelector(state, {
              scenarioId: deleteScenarioId,
              workspaceId,
            })?.name
          : undefined,
      [deleteScenarioId, workspaceId],
    ),
  );

  const sortedScenarios = useSelectorWithProps(sortedScenariosSelector, {
    sortField: sortScenariosField,
    workspaceId,
  });

  const handleDuplicate = useCallback(
    async (scenarioId) => {
      const { id } = await dispatch(
        scenarioDuplicate({ namedScenarioId: scenarioId, workspaceId }),
      ).unwrap();

      if (id) {
        navigate(`/workspaces/${workspaceId}/scenarios/${id}`, {
          state: { autoSelect: true },
        });
      }
    },
    [dispatch, navigate, workspaceId],
  );

  const handlePublishDrafts = noop;

  const handleCreate = useCallback(async () => {
    const { id } = await dispatch(scenarioCreate({ workspaceId })).unwrap();

    if (id) {
      navigate({
        pathname: `/workspaces/${workspaceId}/scenarios/${id}`,
        state: { autoSelect: true },
      });
    }
  }, [dispatch, navigate, workspaceId]);

  const handleDelete = useCallback(() => {
    dispatch(scenarioDelete({ scenarioId: deleteScenarioId, workspaceId }));
    setDeleteScenarioId(undefined);
  }, [deleteScenarioId, dispatch, workspaceId]);

  const handleRestore = useCallback(() => {
    setDeleteScenarioId(undefined);
  }, []);

  const showPublishDrafts = false;

  const newScenarioShortcut = makeKeyboardShortcut({ key: 'n' });

  return (
    <>
      <ContentSection title='Scenarios'>
        <ActionsCard>
          <Toolbar inline>
            <ToolbarGroup>
              <ToolbarItem>
                <DropdownMenu justify='start'>
                  <DropdownMenuButton
                    as={UtilityButton}
                    magnitude='small'
                    icon='sort'
                  />
                  <DropdownMenuList>
                    <DropdownMenuItem
                      onSelect={() => setSortScenariosField('name')}
                      variant={
                        sortScenariosField === 'name' ? 'accent' : undefined
                      }
                    >
                      Name
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onSelect={() => setSortScenariosField('updatedAt')}
                      variant={
                        sortScenariosField === 'updatedAt'
                          ? 'accent'
                          : undefined
                      }
                    >
                      Last updated
                    </DropdownMenuItem>
                  </DropdownMenuList>
                </DropdownMenu>
              </ToolbarItem>
            </ToolbarGroup>
            <ToolbarGroup>
              <ToolbarItem>
                <UtilityButton
                  disabled={!editor}
                  icon='plus'
                  keyboardShortcut={newScenarioShortcut}
                  magnitude='small'
                  onClick={handleCreate}
                >
                  New scenario
                </UtilityButton>
              </ToolbarItem>
              {showPublishDrafts && (
                <ToolbarItem>
                  <PublishDraftsWrapper>
                    <Button magnitude='small' onClick={handlePublishDrafts}>
                      Publish drafts
                    </Button>
                  </PublishDraftsWrapper>
                </ToolbarItem>
              )}
            </ToolbarGroup>
          </Toolbar>
        </ActionsCard>
        <Cards view='list'>
          {sortedScenarios.map(
            ({ id, name, isDefault, published, updatedAt, draft }) => (
              <NamedScenarioItem
                key={id}
                isDefault={isDefault}
                locationsCount={locationsCount}
                name={name}
                onDelete={setDeleteScenarioId}
                onDuplicate={handleDuplicate}
                publishMode={draft ? 'draft' : 'published'}
                scenarioId={id}
                showPrompt={showPrompt}
                updatedAt={maxDate([
                  updatedAt,
                  get('updatedAt', draft || published),
                ])}
                workspaceId={workspaceId}
              />
            ),
          )}
        </Cards>
      </ContentSection>

      {deleteScenarioId && (
        <ConfirmationDialog
          cancelText='Don’t delete'
          confirmText='Delete scenario'
          icon='question'
          title={`Delete “${deleteScenarioName}”?`}
          onConfirm={handleDelete}
          onCancel={handleRestore}
          variant='danger'
        >
          This scenario will be permanently deleted.
        </ConfirmationDialog>
      )}
    </>
  );
};

NamedScenarioChooser.propTypes = propTypes;
NamedScenarioChooser.defaultProps = defaultProps;

export default NamedScenarioChooser;
