import { Icon } from '@kinesis/bungle';

const BreakdownIcon = (props) => (
  <Icon {...props} type='custom'>
    <svg viewBox='0 0 1024 1024' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M924 752C924 690.2 873.8 640 812 640C764.3 640 723.5 669.9 707.4 712H679.8L422.6 512L679.8 312H707.4C723.5 354.1 764.3 384 812 384C873.8 384 924 333.8 924 272C924 210.2 873.8 160 812 160C761.4 160 718.5 193.8 704.7 240H670.3C660.6 240 651 243.3 643.3 249.3L351.7 476H318.1C303.1 431.8 261.2 400 212 400C150.2 400 100 450.2 100 512C100 573.8 150.2 624 212 624C261.2 624 303 592.2 318.1 548H351.7L643.3 774.7C651 780.7 660.6 784 670.3 784H704.7C718.5 830.2 761.4 864 812 864C873.8 864 924 813.8 924 752ZM812 224C824.561 224.256 836.521 229.426 845.313 238.4C854.106 247.374 859.03 259.437 859.03 272C859.03 284.563 854.106 296.626 845.313 305.6C836.521 314.574 824.561 319.744 812 320C799.439 319.744 787.479 314.574 778.687 305.6C769.894 296.626 764.97 284.563 764.97 272C764.97 259.437 769.894 247.374 778.687 238.4C787.479 229.426 799.439 224.256 812 224ZM212 560C199.439 559.744 187.479 554.574 178.687 545.6C169.894 536.626 164.97 524.563 164.97 512C164.97 499.437 169.894 487.374 178.687 478.4C187.479 469.426 199.439 464.256 212 464C224.561 464.256 236.521 469.426 245.313 478.4C254.106 487.374 259.03 499.437 259.03 512C259.03 524.563 254.106 536.626 245.313 545.6C236.521 554.574 224.561 559.744 212 560ZM764 752C764.256 739.439 769.426 727.479 778.4 718.687C787.374 709.894 799.437 704.97 812 704.97C824.563 704.97 836.626 709.894 845.6 718.687C854.574 727.479 859.744 739.439 860 752C859.744 764.561 854.574 776.521 845.6 785.313C836.626 794.106 824.563 799.03 812 799.03C799.437 799.03 787.374 794.106 778.4 785.313C769.426 776.521 764.256 764.561 764 752Z' />
    </svg>
  </Icon>
);

BreakdownIcon.propTypes = {};
BreakdownIcon.defaultProps = {};

export { BreakdownIcon };
