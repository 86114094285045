import styled from 'styled-components';

const VerticalPanes = styled('div')({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});

export { VerticalPanes };
