import { createCachedSelector } from 're-reselect';
import { pipe, defaultTo, get } from 'lodash/fp';
import Units from 'data/units';
import { opacify, pickSequential } from 'utils/colors';
import { sequentialColors } from 'settings/colors';
import colorDomainSelector from './colorDomainSelector';
import blockStateSelector from './blockStateSelector';
import blockValueSelector from './blockValueSelector';
import locationsSelector from './locationsSelector';
import currentAttributeSettingsSelector from './currentAttributeSettingsSelector';

const units = new Units();

const defaultBlockValue = {};

const colorStopsSelector = createCachedSelector(
  pipe(blockStateSelector, get('schema')),
  colorDomainSelector,
  locationsSelector,
  currentAttributeSettingsSelector,
  pipe(blockValueSelector, defaultTo(defaultBlockValue)),
  (schema, colorDomain, locations, settings, blockValue) => {
    const color = get([get('visualisation', blockValue), 'colour'], blockValue);
    if (colorDomain.length === 0) {
      return [];
    }

    const { format } = units.parseColumn(
      schema[color.column],
      locations,
      settings,
    );
    const colors = pickSequential(sequentialColors, colorDomain.length);

    // FUTURE: generalise this, it should be based purely on unit + data.
    // This currently assumes levels is set and complete.
    if (get([color.column, 'encoding'], schema) === 'string') {
      return colorDomain.map((item, index) => ({
        color: opacify(colors[index], 0.65),
        label: format(item),
      }));
    }

    return colorDomain.map((item, index) => ({
      color: opacify(colors[index], 0.65),
      label:
        index < colorDomain.length - 1
          ? `${format(item)} to ${format(colorDomain[index + 1])}`
          : `> ${format(item)}`,
    }));
  },
)((state, props) => props.blockId);

export default colorStopsSelector;
