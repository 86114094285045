import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

const fineareaGeographySelector = createSelector(
  (state, props) =>
    get(['geography', 'dataIds', `fine-area-${props.granularity}`], state),
  get(['geography', 'bundles']),
  (bundleId, bundles) => get(bundleId, bundles),
);

export default fineareaGeographySelector;
