import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash/fp';
import createApiFromState from 'api/createApiFromState';
import { actions as uploadActions } from 'reducers/uploadsReducer';

const populationDatasetUpload = createAsyncThunk(
  'population-dataset/upload',
  async ({ file, key }, { dispatch, getState, rejectWithValue }) => {
    const api = createApiFromState({ dispatch, getState });
    const { data } = await api.post(`/api/uploads`, {
      format: 'zip',
    });
    const onUploadProgress = (e) => {
      dispatch(
        uploadActions.progress({
          key,
          loaded: e.loaded,
          total: e.total,
        }),
      );
    };
    await axios.put(data.url, file, {
      headers: {
        'Content-Type': file.type,
        'x-amz-server-side-encryption': 'AES256',
      },
      onUploadProgress,
    });
    try {
      const response = await api.post(
        `/api/resources/custom:dpie:population/datasets/upload`,
        { format: data.format, key: data.key, name: file.name },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue({
        status: get(['response', 'status'], err),
        data: get(['response', 'data'], err),
      });
    }
  },
);

export { populationDatasetUpload };
