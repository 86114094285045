import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { find, get } from 'lodash/fp';
import { Stack } from '@kinesis/bungle';
import {
  setChoroplethColour,
  setChoroplethGeography,
  setChoroplethTable,
  setChoroplethTime,
  setChoroplethScenario,
} from 'utils/visualisationConfigUtils';
import { isGeographyDimension } from 'data/table';
import { VisualisationDimensionSelect } from 'components/visualisation-dimension-select';
import { VisualisationMeasureSelect } from 'components/visualisation-measure-select';
import { VisualisationScenarioSelect } from 'components/visualisation-scenario-select';
import { VisualisationTimeSelect } from 'components/visualisation-time-select';
import { VisualisationTableSelect } from 'components/visualisation-table-select';
import { useSelectorWithProps } from 'hooks';
import blockValueSelector from 'selectors/blockValueSelector';
import tablesSelector from 'selectors/tablesSelector';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const ChoroplethConfiguration = ({ blockId, onChange, workspaceId }) => {
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId,
    workspaceId,
  });

  const tables = useSelectorWithProps(tablesSelector, { workspaceId });
  const table = find({ family: get('table', blockValue) }, tables);
  const tableIdentifier = get('resource', table);

  const handleChangeTable = useCallback(
    (selectedTable) => {
      const selectedTableResource = find({ family: selectedTable }, tables);
      onChange(
        setChoroplethTable(selectedTableResource, selectedTable, blockValue),
      );
    },
    [blockValue, onChange, tables],
  );

  const handleChangeGeography = useCallback(
    (geography) => onChange(setChoroplethGeography(geography, blockValue)),
    [blockValue, onChange],
  );

  const handleChangeColour = useCallback(
    (colour) => onChange(setChoroplethColour(table, colour, blockValue)),
    [blockValue, onChange, table],
  );

  const handleChangeScenario = useCallback(
    (scenario) => onChange(setChoroplethScenario(scenario, blockValue)),
    [blockValue, onChange],
  );

  const handleChangeTime = useCallback(
    (value) => onChange(setChoroplethTime(value, blockValue)),
    [blockValue, onChange],
  );

  return (
    <Stack space='medium'>
      <VisualisationScenarioSelect
        type='choropleth'
        blockId={blockId}
        value={get('scenario', blockValue)}
        onChange={handleChangeScenario}
        workspaceId={workspaceId}
      />

      <VisualisationTableSelect
        type='choropleth'
        blockId={blockId}
        value={get('table', blockValue)}
        onChange={handleChangeTable}
        workspaceId={workspaceId}
      />

      <VisualisationDimensionSelect
        blockId={blockId}
        label='Geography'
        table={tableIdentifier}
        value={get('geography', blockValue)}
        onChange={handleChangeGeography}
        criteria={isGeographyDimension}
        workspaceId={workspaceId}
      />

      <VisualisationMeasureSelect
        blockId={blockId}
        label='Value'
        table={tableIdentifier}
        tableRules={[isGeographyDimension]}
        value={get('colour', blockValue)}
        onChange={handleChangeColour}
        workspaceId={workspaceId}
      />

      <VisualisationTimeSelect
        blockId={blockId}
        table={tableIdentifier}
        tableRules={[isGeographyDimension]}
        value={get('time', blockValue)}
        onChange={handleChangeTime}
        workspaceId={workspaceId}
      />
    </Stack>
  );
};

ChoroplethConfiguration.propTypes = propTypes;

export { ChoroplethConfiguration };
