import PropTypes from 'prop-types';
import { noop } from 'lodash/fp';
import { Toolbar, ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { DatasetVersionSelect } from 'components/dataset-version-select';
import { DatasetUploadControl } from 'components/dataset-upload-control';
import { ToolbarBackground } from 'components/toolbar-background';

const propTypes = {
  loading: PropTypes.bool,
  onUpload: PropTypes.func,
  onVersionSelect: PropTypes.func,
  version: PropTypes.number,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      publishedAs: PropTypes.number,
      created: PropTypes.string.isRequired,
      updated: PropTypes.string.isRequired,
    }),
  ),
};

const defaultProps = {
  loading: false,
  onUpload: noop,
  onVersionSelect: noop,
  version: undefined,
  versions: [],
};

const DatasetToolbar = ({
  loading,
  onUpload,
  onVersionSelect,
  version,
  versions,
}) => (
  <ToolbarBackground>
    <Toolbar justify='space-between'>
      <ToolbarGroup>
        <ToolbarItem>
          <DatasetVersionSelect
            disabled={loading}
            onSelect={onVersionSelect}
            versions={versions}
            value={version}
          />
        </ToolbarItem>
      </ToolbarGroup>
      <ToolbarGroup justify='end'>
        <ToolbarItem>
          <DatasetUploadControl disabled={loading} onUpload={onUpload} />
        </ToolbarItem>
      </ToolbarGroup>
    </Toolbar>
  </ToolbarBackground>
);

DatasetToolbar.defaultProps = defaultProps;
DatasetToolbar.propTypes = propTypes;

export { DatasetToolbar };
