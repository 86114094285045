import {
  filter,
  keyBy,
  first,
  entries,
  get,
  has,
  identity,
  includes,
  keys,
  map,
  mapValues,
  pipe,
  reduce,
  set,
  values,
} from 'lodash/fp';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import filterableColumnsSelector from 'selectors/filterableColumnsSelector';
import locationsSelector from 'selectors/locationsSelector';
import schemaSelector from 'selectors/schemaSelector';
import statisticsSelector from 'selectors/statisticsSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import { constructFilterItem, zipToSchema } from 'utils/queryUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';
import { immutableSort } from 'utils/charts/series';
import Units from 'data/units';

const isContext = pipe(get('classification'), includes('context'));
const units = new Units();

const visualisationQuery = createAsyncThunk(
  'visualisationQuery/fetch',
  async (
    { blockId, currentScenarioId, scenarios, workspaceId },
    { dispatch, getState },
  ) => {
    const state = getState();
    const api = createApiFromState({ dispatch, getState });
    const blockState = blockStateSelector(state, { blockId, workspaceId });
    const blockValue = blockValueSelector(state, { blockId, workspaceId });

    const query = get([get('visualisation', blockValue), 'query'], blockValue);

    if (!query) {
      return;
    }
    const { resources } = workspaceSelector(state, { workspaceId });
    const resourceSchema = schemaSelector(state, { blockId, workspaceId });
    const groups = pipe(get('group'), map('column'))(query) || [];
    const contexts = values(resourceSchema)
      .filter(isContext)
      .filter((column) => !groups.includes(column.key));
    const statistics = statisticsSelector(state, {
      blockId,
      scenarioId: currentScenarioId,
      workspaceId,
    });
    const scenarioList = pipe(
      filter({ blockId }),
      map((x) => ({ ...x, scenario: x.scenarioKey })),
      keyBy('scenarioId'),
    )(scenarios);
    const locations = locationsSelector(state, { workspaceId });
    const instantiations = workspaceInstantiationsSelector(state, {
      workspaceId,
    });
    const filters = reduce(
      (accumulator, context) => {
        const unit = units.parseColumn(context, locations, instantiations);
        return has(context.key, accumulator)
          ? accumulator
          : set(
              context.key,
              first(
                immutableSort(
                  unit.compare,
                  keys(get([context.key, 'histogram'], statistics)),
                ),
              ),
              accumulator,
            );
      },
      blockState.filters,
      contexts,
    );

    const filterableColumns = filterableColumnsSelector(state, {
      blockId,
      workspaceId,
    });
    const aggregateColumns = (query.aggregate || []).map(get('column'));
    const allowed = contexts
      .map(get('key'))
      .concat(filterableColumns.map(get('key')));
    const allDimensions = entries(filters);
    const preDimensions = allDimensions.filter(
      ([key]) => allowed.includes(key) && !aggregateColumns.includes(key),
    );
    const postDimensions = allDimensions.filter(
      ([key]) => allowed.includes(key) && aggregateColumns.includes(key),
    );
    const preDimensionFilter = preDimensions
      .map(([key, value]) =>
        constructFilterItem(
          resourceSchema[key],
          value,
          locations,
          instantiations,
        ),
      )
      .filter(identity);
    const postDimensionFilter = postDimensions
      .map(([key, value]) =>
        constructFilterItem(
          resourceSchema[key],
          value,
          locations,
          instantiations,
        ),
      )
      .filter(identity);
    const queryFilter = query.where || query.filter || [];
    const response = await api.post(`/api/workspace/${workspaceId}/query`, {
      ...query,
      from: get(query.from, resources),
      filter: [...queryFilter, ...preDimensionFilter],
      having: postDimensionFilter.length > 0 ? postDimensionFilter : undefined,
      scenarios: map('scenario', scenarioList),
    });

    const {
      isDirty,
      isUpToDate,
      scenarios: rawScenarios,
      schema,
    } = response.data;

    const scenariosWithData = mapValues(
      (s) => ({
        asAt: s.scenario,
        data: zipToSchema(schema, rawScenarios[s.scenario].data),
      }),
      scenarioList,
    );

    return {
      blockId,
      isDirty,
      isUpToDate,
      scenariosWithData,
      schema,
    };
  },
);

export { visualisationQuery };
