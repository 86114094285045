import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceDuplicate = createAsyncThunk(
  'workspace/duplicate',
  async ({ workspaceId }, { dispatch, getState, rejectWithValue }) => {
    const api = createApiFromState({ dispatch, getState });

    try {
      const response = await api.post(`/api/workspace?source=${workspaceId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        id: err.data.errorId,
        message: "We couldn't duplicate the workspace. Please try again.",
      });
    }
  },
);

export { workspaceDuplicate };
