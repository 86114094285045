import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { find, get, pipe, isNil } from 'lodash/fp';
import { Loading } from 'components/loading';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import Units from 'data/units';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import {
  ValueCard,
  ValueCardNoData,
  ValueCardSubtitle,
  ValueCardValue,
  ValueCardWrapper,
} from 'components/value-card/value-card.styles';

const units = new Units();

const propTypes = {
  blockId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const yearSelector = makeAggregationFilterSelector('year');

const StaticIndicator = ({ blockId, onClick, privacy, workspaceId }) => {
  const previewYear = useSelectorWithProps(yearSelector, {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });
  const schema = useSelectorWithProps(pipe(blockStateSelector, get('schema')), {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });
  const scenarios = useSelectorWithProps(
    pipe(blockStateSelector, get('scenarios')),
    {
      blockId,
      public: privacy === 'public',
      workspaceId,
    },
  );
  const chart = useSelectorWithProps(pipe(blockValueSelector, get('chart')), {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });

  const scenarioId = useScenarioId();
  const indicatorColumn = get(['indicator', 'column'], chart);
  const timeColumn = get(['time', 'column'], chart);
  const title = get(['indicator', 'title'], chart);
  const { formatCell, symbol } = useMemo(
    () => units.parseColumn(schema[indicatorColumn]),
    [indicatorColumn, schema],
  );
  const data = useMemo(
    () => get([scenarioId, 'data'], scenarios),
    [scenarioId, scenarios],
  );
  const maximum = useMemo(
    () =>
      pipe(
        find((datum) => get(timeColumn, datum) === previewYear),
        get(indicatorColumn),
      )(data),
    [data, indicatorColumn, timeColumn, previewYear],
  );

  const loading = isNil(data);
  const empty = !loading && isNil(maximum);

  return (
    <ValueCardWrapper data-testid='indicator' onClick={onClick}>
      {loading && <Loading offset={16} delay={200} />}
      {empty && <ValueCardNoData>There is no data.</ValueCardNoData>}
      {!loading && !empty && (
        <ValueCard>
          <ValueCardValue>{formatCell(maximum)}</ValueCardValue>
          <ValueCardSubtitle>{symbol || title}</ValueCardSubtitle>
        </ValueCard>
      )}
    </ValueCardWrapper>
  );
};

StaticIndicator.propTypes = propTypes;
StaticIndicator.defaultProps = defaultProps;

export default StaticIndicator;
