import { createSelector } from 'reselect';
import {
  curry,
  filter,
  flatten,
  get,
  identity,
  map,
  mergeWith,
  pipe,
  values,
} from 'lodash/fp';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import blockDataByScenarioSelector from 'selectors/blockDataByScenarioSelector';
import locationsSelector from 'selectors/locationsSelector';
import workspaceInstantiationsSelector from 'selectors/currentAttributeSettingsSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import scenariosSelector from 'selectors/scenariosSelector';
import Units from 'data/units';
import { calculateBounds } from 'utils/charts/series';

const units = new Units();

const yearSelector = makeAggregationFilterSelector('year');
const immutableSort = curry((f, arr) => arr.slice().sort(f));

const multiScenarioCapsuleDataSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  pipe(blockValueSelector, get('chart')),
  scenariosSelector,
  blockDataByScenarioSelector,
  locationsSelector,
  yearSelector,
  workspaceInstantiationsSelector,
  (
    schema,
    chart,
    scenarios,
    blockDataByScenario,
    locations,
    year,
    instantiations,
  ) => {
    const { x, y, time, type } = chart;
    const timeColumn = get('column', time);
    const xUnit = units.parseColumn(
      schema[x.column],
      locations,
      instantiations,
    );
    const unitFormatterPicker = (column) =>
      get(
        'format',
        units.parseColumn(schema[column], locations, instantiations),
      );
    const isStacked = ['column', 'stacked-area', 'stacked-column'].includes(
      type,
    );

    return {
      data: pipe(
        mergeWith(
          (scenario, data) => ({
            name: scenario.name,
            data: pipe(
              timeColumn && year ? filter({ [timeColumn]: year }) : identity,
              map((datum) => ({
                x: get(x.column, datum),
                y: get(y.series.column, datum),
              })),
              immutableSort((a, b) => xUnit.compare(a.x, b.x)),
            )(data),
            unitFormatter: unitFormatterPicker(y.series.column),
          }),
          scenarios,
        ),
        values,
      )(blockDataByScenario),
      xUnit,
      yBounds: calculateBounds(
        pipe(values, flatten)(blockDataByScenario),
        timeColumn,
        x.column,
        [y.series.column],
        isStacked,
      ),
    };
  },
);

export default multiScenarioCapsuleDataSelector;
