import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';

import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';

const chartLegendTitleSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  pipe(blockValueSelector, get(['chart', 'y'])),
  (schema, y) => {
    const legendKey = get(['series', 0, 'secondary'], y);
    return legendKey ? get('label', schema[legendKey]) : undefined;
  },
);

export default chartLegendTitleSelector;
