import PropTypes from 'prop-types';
import GenericSettings from './attribute-settings.generic';

const propTypes = {
  instantiation: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};
const defaultProps = {};

const description =
  'Custom attributes allow locations to be annotated with text.';

const CustomSettings = ({ instantiation, setting, workspaceId }) => (
  <GenericSettings
    description={description}
    hasSettings={false}
    instantiation={instantiation}
    setting={setting}
    workspaceId={workspaceId}
  />
);

CustomSettings.defaultProps = defaultProps;
CustomSettings.propTypes = propTypes;

export default CustomSettings;
