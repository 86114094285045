import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isEmpty, pipe, size } from 'lodash/fp';
import {
  Badge,
  Inline,
  InlineItem,
  Prompt,
  Stack,
  UtilityButton,
} from '@kinesis/bungle';
import { actions as workspaceMiscActions } from 'reducers/workspaceMiscReducer';
import { useSelectorWithProps } from 'hooks';
import usePreference from 'hooks/usePreference';
import appUpgradesSelector from 'selectors/appUpgradesSelector';
import loadedAppsSelector from 'selectors/loadedAppsSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import { LoadableContent } from 'components/loadable-content';
import AppCard from 'components/app-mini-card/AppCard';
import AppManagement from 'components/modals/app-management/AppManagement';
import { ManageDataModal } from 'components/modals/manage-data';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import { AppMenu, AppMenuItem } from './app-list-section.styles';

const propTypes = {
  editor: PropTypes.bool.isRequired,
  showPrompt: PropTypes.bool,
  showNextPrompt: PropTypes.func,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  showPrompt: false,
  showNextPrompt: () => {},
};

const AppListSection = ({
  editor,
  showPrompt,
  showNextPrompt,
  workspaceId,
}) => {
  const dispatch = useDispatch();
  const apps = useSelectorWithProps(loadedAppsSelector, { workspaceId });
  const upgrades = useSelectorWithProps(pipe(appUpgradesSelector, size), {
    workspaceId,
  });
  const appsLoadingState = useSelector(get(['apps', 'loading']));
  const versionsLoadingState = useSelector(get(['appVersions', 'loading']));
  const isLoading =
    appsLoadingState === 'pending' || versionsLoadingState === 'pending';
  const workspace = useSelectorWithProps(workspaceSelector, { workspaceId });
  const showAppsModal = get(['misc', 'showAppManagementModal'], workspace);
  const [showManageDataModal, setShowManageDataModal] = useState(false);
  const [, setViewedAppsPrompt] = usePreference(
    `marathon:workspace:${workspaceId}:viewed-apps-prompt`,
    'f',
  );

  const [selectedAppId, setSelectedAppId] = useState(undefined);

  const showModal = useCallback(() => {
    dispatch(workspaceMiscActions.setShowAppManagementModal({ workspaceId }));
    setViewedAppsPrompt('t');
  }, [dispatch, setViewedAppsPrompt, workspaceId]);

  const openManageApps = useCallback(() => {
    setSelectedAppId(undefined);
    showModal();
  }, [setSelectedAppId, showModal]);

  const closeManageApps = useCallback(() => {
    setSelectedAppId(undefined);
    dispatch(workspaceMiscActions.clearShowAppManagementModal({ workspaceId }));
  }, [dispatch, workspaceId]);

  const openManageData = useCallback(() => {
    setShowManageDataModal(true);
  }, [setShowManageDataModal]);

  const closeManageData = useCallback(() => {
    setShowManageDataModal(false);
  }, [setShowManageDataModal]);

  return (
    <ToolboxSection collapsible title='Apps'>
      {(isLoading || !isEmpty(apps)) && (
        <AppMenu>
          <LoadableContent loading={isLoading}>
            {apps.map((app) => (
              <AppMenuItem
                key={app.key}
                onClick={
                  editor
                    ? () => {
                        setSelectedAppId(app.id);
                        showModal();
                      }
                    : undefined
                }
                content={
                  <AppCard
                    label={app.label}
                    publisher={app.publisher}
                    logo={app.image}
                  />
                }
              />
            ))}
          </LoadableContent>
        </AppMenu>
      )}
      <Stack space='small' alignX='left'>
        {!isEmpty(apps) && (
          <Prompt
            disabled={!editor}
            dismissOnKeyPress={false}
            onDismiss={() => {
              showNextPrompt();
              setViewedAppsPrompt('t');
            }}
            placement='right'
            title='Add apps to gain insights on your area of interest'
            showPrompt={showPrompt}
          >
            <UtilityButton
              disabled={isLoading}
              magnitude='small'
              icon='apps'
              onClick={openManageApps}
            >
              <Inline space='xsmall'>
                <InlineItem>Manage apps</InlineItem>
                {upgrades > 0 && editor && (
                  <InlineItem>
                    <Badge count={upgrades} />
                  </InlineItem>
                )}
              </Inline>
            </UtilityButton>
          </Prompt>
        )}
        <UtilityButton
          disabled={isLoading}
          magnitude='small'
          icon='outputs'
          onClick={openManageData}
        >
          Manage data
        </UtilityButton>
      </Stack>
      {showAppsModal && (
        <AppManagement
          initialAppId={selectedAppId}
          onClose={closeManageApps}
          editor={editor}
          workspaceId={workspaceId}
        />
      )}
      {showManageDataModal && (
        <ManageDataModal onClose={closeManageData} workspaceId={workspaceId} />
      )}
    </ToolboxSection>
  );
};

AppListSection.defaultProps = defaultProps;
AppListSection.propTypes = propTypes;

export { AppListSection };
