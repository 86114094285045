import PropTypes from 'prop-types';
import { Stack } from '@kinesis/bungle';
import { Container, TitleCard, ActionsCard } from './content-section.styles';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]),
  title: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  title: undefined,
};

const ContentSection = ({ children, title }) => (
  <Container equalSpacing={!(children && title)}>
    <Stack space='medium'>
      {title && (
        <TitleCard>
          <h5>{title}</h5>
        </TitleCard>
      )}
      {children}
    </Stack>
  </Container>
);

ContentSection.propTypes = propTypes;
ContentSection.defaultProps = defaultProps;

export { ContentSection, ActionsCard };
