import { useSelector } from 'react-redux';
import useSelectorWithProps from './useSelectorWithProps';
import blocksSelector from 'selectors/blocksSelector';
import { allSameBlockGroup } from 'data/block';

const blockPaneLabelMapping = {
  'editable-visualisation': 'Visualisation',
  visualisation: 'Visualisation',
  'pathway-input': 'Pathway summary',
  text: 'Text',
};

function useSelectedBlockLabel({ privacy, workspaceId }) {
  const { blocks: currentSelections, board: boardId } = useSelector(
    (state) => state.blockSelections,
  );

  const blocks = useSelectorWithProps(blocksSelector, {
    blockIds: currentSelections,
    boardId,
    public: privacy === 'public',
    workspaceId,
  }).map((b) => b.block);

  if (blocks.length === 0) {
    return undefined;
  }

  return blocks.length === 1 || allSameBlockGroup(blocks)
    ? blockPaneLabelMapping[blocks[0]?.type]
    : 'block';
}

export { useSelectedBlockLabel };
