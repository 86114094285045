import PropTypes from 'prop-types';
import { Listbox } from '@kinesis/bungle';
import LayerCircle from 'components/layer-circle/LayerCircle';
import layersSelector from 'selectors/layersSelector';
import { pipe, reject } from 'lodash/fp';
import { useSelectorWithProps } from 'hooks';
import { Item, Label } from './layer-select';

const propTypes = {
  appearance: PropTypes.oneOf(['neat', 'none', 'round']),
  buttonRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  closeable: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  appearance: 'round',
  closeable: false,
};

const LocationsSearch = ({
  appearance,
  buttonRef,
  closeable,
  onClose,
  onSelect,
  workspaceId,
}) => {
  const layers = useSelectorWithProps(
    pipe(layersSelector, reject({ type: 'synthetic' })),
    { workspaceId },
  );

  return (
    <Listbox
      alwaysHighlight
      appearance={appearance}
      autoFocus
      buttonRef={buttonRef}
      closeOnBlur={closeable}
      closeOnClickOutside={closeable}
      maxHeight={280}
      onChange={onSelect}
      onClose={onClose}
      options={layers}
      placeholder='Search locations…'
      renderItem={(layer) => (
        <Item>
          <LayerCircle layerId={layer.id} workspaceId={workspaceId} />
          <Label>{layer.label}</Label>
        </Item>
      )}
      valueKey='id'
      width={240}
      workspaceId={workspaceId}
    />
  );
};

LocationsSearch.defaultProps = defaultProps;
LocationsSearch.propTypes = propTypes;

export default LocationsSearch;
