import {
  isEmpty,
  every,
  has,
  find,
  omitBy,
  concat,
  constant,
  curry,
  defaultTo,
  filter,
  flatMap,
  get,
  isEqual,
  isNil,
  map,
  sortBy,
  pipe,
  reject,
  set,
  some,
  unset,
  first,
  negate,
} from 'lodash/fp';
import {
  isTimeDimension,
  getTimeDimensions,
  getMeasures,
  getDimensions,
  hasTime,
  isGeographyDimension,
} from 'data/table';
import { flattenY } from 'data/xy-chart';
import { aggregations } from 'data/block';
import Units from 'data/units';

// NOTE: many of these are re-exported as distinct names, as they will need to
// grow rules to validate and reset/manipulate indicator values, and we don't
// want the direct and rule based implementations to become conflated.
// Exporting both names lets us distinguish the intent upfront.

const units = new Units();

const mapWithIndex = map.convert({ cap: false });

const setTitle = curry((title, current) => set('title', title, current));

const omitNil = omitBy(isNil);

const tableTransform = curry((rules, columns) =>
  pipe(
    map((rule) => get('key', find(rule, columns))),
    (removals) => filter((column) => !removals.includes(column.key), columns),
  )(rules),
);

const isZeroDimensionTable = curry((rules, table) =>
  pipe(getDimensions, tableTransform(rules), isEmpty)(table),
);

const isSingleDimensionTable = curry((rules, table) =>
  pipe(
    getDimensions,
    tableTransform(rules),
    (dimensions) => dimensions.length === 1,
  )(table),
);

const isMultiDimensionTable = curry((rules, table) =>
  pipe(
    getDimensions,
    tableTransform(rules),
    (dimensions) => dimensions.length >= 2,
  )(table),
);

const isTimelineOff = (blockValue) =>
  !has('time', blockValue) || get(['time', 'type'], blockValue) === 'none';

const isValidConfigurationForTable = curry((rules, blockValue, table) => {
  const measure = get('value', blockValue);
  const measureType = get('type', measure);

  if (!hasTime(table) && !isTimelineOff(blockValue)) {
    return false;
  }

  // If the selected table is single dimension (time), the valid options are
  // (aggregate + no-time) or (identity + time), and these are mutually
  // exclusive.
  if (isSingleDimensionTable(rules, table) && hasTime(table)) {
    if (
      isNil(measure) ||
      (measureType === 'aggregate' && isTimelineOff(blockValue)) ||
      (measureType === 'identity' && !isTimelineOff(blockValue))
    ) {
      return true;
    }
  }

  if (
    (isSingleDimensionTable(rules, table) && !hasTime(table)) ||
    isMultiDimensionTable(rules, table)
  ) {
    if (isNil(measure) || measureType === 'aggregate') {
      return true;
    }
  }
  return false;
});

const isValidConfigurationForIndicatorTable = isValidConfigurationForTable([]);

const generateRawAggregateOptions = (table, column) =>
  pipe(
    filter((aggregation) =>
      // FIX we should have a stateless version of column metadata...
      units.parseColumn(column).aggregations.includes(aggregation.key),
    ),
    map((aggregation) => ({
      measure: column.label,
      value: {
        type: 'aggregate',
        aggregate: aggregation.key,
        measure: column.key,
      },
      label: aggregation.label,
    })),
  )(aggregations);

const generateAggregateOptions = (rules, table, column) =>
  isZeroDimensionTable(rules, table)
    ? []
    : pipe(
        filter((aggregation) =>
          // FIX we should have a stateless version of column metadata...
          units.parseColumn(column).aggregations.includes(aggregation.key),
        ),
        map((aggregation) => ({
          measure: column.label,
          value: {
            type: 'aggregate',
            aggregate: aggregation.key,
            measure: column.key,
          },
          label: aggregation.label,
        })),
      )(aggregations);

const generateRawIdentityOptions = (table, column) => [
  {
    value: {
      type: 'identity',
      column: column.key,
    },
    measure: column.label,
    label: column.label,
  },
];

const generateIdentityOptions = (rules, table, column) => {
  const timeDimensions = getTimeDimensions(table);
  if (isSingleDimensionTable(rules, table) && hasTime(table)) {
    const time = first(timeDimensions);
    return [
      {
        measure: column.label,
        value: {
          type: 'identity',
          column: column.key,
          time: {
            type: 'column',
            column: time.key,
          },
        },
        time: {
          type: 'column',
          column: time.key,
        },
        label: `By ${time.label}`,
      },
    ];
  }
  if (isZeroDimensionTable(rules, table)) {
    return [
      {
        value: {
          type: 'identity',
          column: column.key,
        },
        measure: column.label,
        label: column.label,
      },
    ];
  }
  return [];
};

const addKeys = (options) =>
  mapWithIndex((option, index) => ({ ...option, key: index }))(options);

const dimensionOptions = (resource, criteria) =>
  pipe(
    getDimensions,
    filter(criteria || constant(true)),
    map((column) => ({
      label: column.label,
      value: { type: 'column', column: column.key },
    })),
    sortBy('label'),
    addKeys,
  )(resource);

const measureOptions = curry((rules, table) =>
  pipe(
    getMeasures,
    flatMap((column) =>
      concat(
        generateIdentityOptions(rules, table, column),
        generateAggregateOptions(rules, table, column),
      ),
    ),
    addKeys,
  )(table),
);

const measureOptionsOfType = curry((rules, table, allOfType) => {
  switch (allOfType) {
    case 'identity':
      return pipe(
        getMeasures,
        flatMap((column) => generateRawIdentityOptions(table, column)),
        addKeys,
      )(table);
    case 'aggregate':
      return pipe(
        getMeasures,
        flatMap((column) => generateRawAggregateOptions(table, column)),
        addKeys,
      )(table);
    default:
      return measureOptions(rules, table);
  }
});

const measureOptionsIndicator = measureOptions([]);

const noTimelineOption = {
  label: 'No timeline',
  value: { type: 'none' },
  category: 'off',
};

const timeOptionsOf = (table) =>
  pipe(
    getDimensions,
    filter(isTimeDimension),
    map((column) => ({
      label: column.label,
      value: { type: 'column', column: column.key },
      category: 'list',
    })),
    sortBy('label'),
    concat([noTimelineOption]),
  )(table);

const timeOptions = curry((rules, tableResource, blockValue) => {
  const isSingleDimensionTimeTable =
    isSingleDimensionTable(rules, tableResource) && hasTime(tableResource);

  switch (get('visualisation', blockValue)) {
    case 'stacked-column':
    case 'clustered-column':
    case 'stacked-area':
    case 'line': {
      const allOptions = timeOptionsOf({
        schema: tableTransform(rules, getDimensions(tableResource)),
      });
      if (isSingleDimensionTimeTable) {
        const series = flattenY(blockValue);
        if (some(pipe(get('type'), isEqual('aggregate')), series)) {
          return pipe(filter({ value: { type: 'none' } }), addKeys)(allOptions);
        }
        if (
          get('type', first(series)) === 'identity' &&
          get(['time', 'type'], blockValue) === 'column'
        ) {
          return pipe(reject({ value: { type: 'none' } }), addKeys)(allOptions);
        }
      }
      return addKeys(allOptions);
    }
    case 'indicator':
    case 'choropleth':
    default: {
      const allOptions = timeOptionsOf(tableResource);
      if (isSingleDimensionTimeTable) {
        switch (get(['value', 'type'], blockValue)) {
          case 'aggregate':
            return pipe(
              filter({ value: { type: 'none' } }),
              addKeys,
            )(allOptions);
          case 'identity':
            return pipe(
              reject({ value: { type: 'none' } }),
              addKeys,
            )(allOptions);
          default:
            return addKeys(allOptions);
        }
      }
      return addKeys(allOptions);
    }
  }
});

const initial = {
  indicator: {
    scenario: { type: 'selected' },
    comparison: { type: 'none' },
  },
};

const initialisation = (visualisation) =>
  defaultTo({}, get(visualisation, initial));

const blockValueBuild = (visualisation, contents, current) =>
  omitNil({
    ...contents,
    type: 'editable-visualisation',
    visualisation,
    title: get('title', current),
    height: get('height', current),
  });

const blockValueInitialise = (visualisation, current) =>
  blockValueBuild(visualisation, initialisation(visualisation), current);

const blockValueCopy = (visualisation, current) =>
  blockValueBuild(visualisation, current, current);

const setVisualisationType = curry((visualisation, current) => {
  switch (current.visualisation) {
    case 'stacked-column':
    case 'clustered-column':
    case 'stacked-area':
    case 'line': {
      switch (visualisation) {
        case 'stacked-column':
        case 'clustered-column':
        case 'stacked-area':
        case 'line':
          // Hueristic
          //
          // * When switching between xy charts, copy the block value over.
          //
          // NOTE: Currently all xy charts can map one-to-one, but this may not
          // be the case as specifics change across each variant over time.
          return blockValueCopy(visualisation, current);
        case 'indicator':
        case 'choropleth':
        default:
          return blockValueInitialise(visualisation, current);
      }
    }
    case 'indicator':
    case 'choropleth':
    case 'empty':
    default:
      return blockValueInitialise(visualisation, current);
  }
});

const setTable = curry((table, current) => set('table', table, current));
const setTime = curry((time, current) => set('time', time, current));
const setScenario = curry((scenario, block) =>
  set('scenario', scenario, block),
);

const setWithTimeHeuristic = curry(
  (key, rules, tableResource, value, current) => {
    const valueType = get('type', value);

    // Heuristic:
    //
    // * for a table whose single dimension is time, if the option requires time,
    //   set the block's timeline with it, otherwise, clear the timeline if the
    //   option does not require it
    //
    // * for all other tables with more than one dimension where at least one of
    //   which is time, set the block's timeline to the first option if the
    //   timeline is off

    if (
      isSingleDimensionTable(rules, tableResource) &&
      hasTime(tableResource)
    ) {
      const timeRequirement = get('time', value);
      if (valueType === 'identity') {
        return pipe(set(key, value), set('time', timeRequirement))(current);
      }
      if (valueType === 'aggregate') {
        return pipe(set(key, value), unset('time'))(current);
      }
    }
    if (
      isMultiDimensionTable(rules, tableResource) &&
      hasTime(tableResource) &&
      isTimelineOff(current)
    ) {
      const firstTimeOption = pipe(
        timeOptions(rules, tableResource),
        filter((timeOption) => get(['value', 'type'], timeOption) !== 'none'),
        first,
        get('value'),
      )(current);
      return pipe(set(key, value), set('time', firstTimeOption))(current);
    }

    return set(key, value, current);
  },
);

const setIndicatorValue = setWithTimeHeuristic('value', []);

const setIndicatorTime = curry((time, current) => setTime(time, current));

const unsetIndicatorValue = pipe(unset('value'), unset('time'));

const setIndicatorTable = curry(
  (currentTableResource, selectedTableResource, table, current) => {
    const selectedMeasureOptions = measureOptionsIndicator(
      selectedTableResource,
    );
    const currentMeasureOptions = measureOptionsIndicator(currentTableResource);
    const currentMeasureOption = find(
      { value: get('value', current) },
      currentMeasureOptions,
    );
    const selectedOptionIsStillPresent = some(
      (selectedMeasureOption) =>
        isEqual(
          get('value', currentMeasureOption),
          get('value', selectedMeasureOption),
        ) &&
        isEqual(
          get('time', currentMeasureOption),
          get('time', selectedMeasureOption),
        ),
      selectedMeasureOptions,
    );

    // Heuristic
    //
    // * keep the measure and timeline values if the target output has the same
    //   measure option as the current output and the options are valid for the
    //   target output

    if (
      selectedOptionIsStillPresent &&
      isValidConfigurationForIndicatorTable(current, selectedTableResource)
    ) {
      return setTable(table, current);
    }

    return pipe(setTable(table), unsetIndicatorValue)(current);
  },
);

const setChoroplethScenario = setScenario;

const setChoroplethTable = curry((selectedTableResource, table, current) => {
  const geographyDimensions = dimensionOptions(
    selectedTableResource,
    isGeographyDimension,
  );
  const geography = pipe(first, get('value'))(geographyDimensions);

  // Heuristic
  //
  // * if we've never picked a geography dimension value, pick the first
  //   available geography dimension option as we pick the table.

  if (isNil(get('geography', current))) {
    return pipe(setTable(table), set('geography', geography))(current);
  }

  return setTable(table, current);
});

const setChoroplethColour = setWithTimeHeuristic('colour', [
  isGeographyDimension,
]);

const setChoroplethGeography = curry((geography, block) =>
  set('geography', geography, block),
);

const setChoroplethTime = setTime;

const setIndicatorScenario = (scenario, block) =>
  pipe(setScenario(scenario), (b) =>
    isEqual(get('scenario'), get('comparison'))
      ? set('comparison', initial.indicator.comparison, b)
      : b,
  )(block);

const setX = curry((x, block) => set('x', x, block));
const setY = curry((y, block) => set('y', y, block));

const initialSeriesExpression = {
  type: 'by-expression',
  series: [],
};

const unsetTime = set('time', { type: 'none' });

const unsetTable = unset('table');

const unsetX = (current) => {
  if (get(['scenario', 'type'], current) === 'all') {
    return set('x', { type: 'none' }, current);
  }
  return unset('x', current);
};

const unsetY = (current) => {
  if (get(['x', 'type'], current) === 'by-scenario') {
    return set('y', initialSeriesExpression, current);
  }
  return unset('y', current);
};

const isOneScenarioSelection = (scenario) =>
  ['by-id', 'selected'].includes(scenario.type);

const isAllScenarioSelection = (scenario) => scenario.type === 'all';

const matchingScenarioSelection = (s1, s2) =>
  (isOneScenarioSelection(s1) && isOneScenarioSelection(s2)) ||
  (isAllScenarioSelection(s1) && isAllScenarioSelection(s2));

const byScenarioOption = { value: { type: 'by-scenario' } };

const XYChartTypeCheck = {
  x: (tableResource, blockValue) => {
    const dimensions = pipe(
      dimensionOptions,
      concat(byScenarioOption),
    )(tableResource);
    return !isNil(
      find((dimension) => isEqual(dimension.value, blockValue.x), dimensions),
    );
  },
  y: (tableResource, blockValue) => {
    const isXAxis = (c) => c.key === get(['x', 'column'], blockValue);
    const dimensionsAndMeasures = concat(
      dimensionOptions(tableResource, negate(isXAxis)),
      measureOptionsOfType(
        [isXAxis],
        tableResource,
        get('type', first(flattenY(blockValue))),
      ),
    );
    return every(
      (expressionOrBreakdown) =>
        find(
          (x) => isEqual(x.value, expressionOrBreakdown),
          dimensionsAndMeasures,
        ),
      map(unset('time'), flattenY(blockValue)),
    );
  },
  time: (tableResource, blockValue) => {
    const excludeX = (c) => c.key === get(['x', 'column'], blockValue);
    const excludeBreakdown =
      get(['y', 'type'], blockValue) === 'by-dimension'
        ? (c) => c.key === get(['y', 'by', 'column'], blockValue)
        : constant(false);
    const times = timeOptions(
      [excludeX, excludeBreakdown],
      tableResource,
      blockValue,
    );
    return !isNil(find((time) => isEqual(time.value, blockValue.time), times));
  },
};

const typeCheck = (tableResource, blockValue) => {
  switch (get('visualisation', blockValue)) {
    case 'stacked-column':
    case 'clustered-column':
    case 'stacked-area':
    case 'line':
      return (
        XYChartTypeCheck.x(tableResource, blockValue) &&
        XYChartTypeCheck.y(tableResource, blockValue) &&
        XYChartTypeCheck.time(tableResource, blockValue)
      );
    case 'indicator':
    case 'choropleth':
    default:
      return false;
  }
};

const setXYChartScenario = (scenario, current) => {
  // Heuristic
  //
  // * If the scenario switches from one to one, or all to all, then keep all
  //   subsequent configurations.
  if (matchingScenarioSelection(scenario, current.scenario)) {
    return setScenario(scenario, current);
  }
  return pipe(
    setScenario(scenario),
    unsetTable,
    unsetX,
    unsetY,
    unsetTime,
  )(current);
};

const setXYChartTable = curry((selectedTableResource, table, current) => {
  // Heuristic
  //
  // * If the selected table allows all the same options to be picked, keep
  //   the subsequent configurations.
  if (typeCheck(selectedTableResource, current)) {
    return setTable(table, current);
  }
  return pipe(setTable(table), unsetX, unsetY, unsetTime)(current);
});

const isExpression = (x) => ['identity', 'aggregate'].includes(get('type', x));

const setXYChartX = curry((tableResource, x, blockValue) => {
  const comparingScenarios = get(['scenario', 'type'], blockValue) === 'all';
  const expressionsAndBreakdowns = flattenY(blockValue);
  const measures = measureOptions(
    [(c) => c.key === get(['x', 'column'], blockValue)],
    tableResource,
    get('type', first(flattenY(blockValue))),
  );

  // Heuristic
  //
  // If we are
  //
  // * comparing across scenarios
  // * setting the x axis to a dimension column, and
  // * have previously set an expression
  //
  // then keep all subsequent configurations.
  if (
    comparingScenarios &&
    get('type', x) === 'column' &&
    expressionsAndBreakdowns.length === 1 &&
    isExpression(first(expressionsAndBreakdowns))
  ) {
    const updatedBlockValue = pipe(
      setX(x),
      setY(first(expressionsAndBreakdowns)),
    )(blockValue);
    if (tableResource && typeCheck(tableResource, updatedBlockValue)) {
      return updatedBlockValue;
    }
  }

  // Heuristic
  //
  // If we are
  //
  // * comparing across scenarios
  // * setting the x axis to a dimension column, and
  // * have previously not set an expression
  //
  // then default to the first measure option.
  if (
    comparingScenarios &&
    get('type', x) === 'column' &&
    isEmpty(expressionsAndBreakdowns) &&
    !isEmpty(measures)
  ) {
    const updatedBlockValue = pipe(
      setX(x),
      setY(get('value', first(measures))),
    )(blockValue);
    if (tableResource && typeCheck(tableResource, updatedBlockValue)) {
      return updatedBlockValue;
    }
  }

  // Heuristic
  //
  // If we are
  //
  // * comparing across scenarios
  // * setting the x axis by scenario, and
  // * have previously set an expression
  //
  // keep all subsequent configurations.
  if (
    comparingScenarios &&
    get('type', x) === 'by-scenario' &&
    !isNil(get('y', blockValue))
  ) {
    const updatedBlockValue = pipe(
      setX(x),
      setY({
        type: 'by-expression',
        series: [get('y', blockValue)],
      }),
    )(blockValue);
    if (tableResource && typeCheck(tableResource, updatedBlockValue)) {
      return updatedBlockValue;
    }
  }

  // Heuristic
  //
  // * If the selected x axis allows all the same options to be picked, keep
  //   the subsequent configurations.
  if (tableResource && typeCheck(tableResource, setX(x, blockValue))) {
    // NOTE: If x is by-scenario and y is not set, we may wind up with an
    // invalid y, even if the type check passes. Unsetting y here defaults
    // correctly but we are careful to only do this when y is actually missing.
    if (isNil(get('y', blockValue))) {
      return pipe(setX(x), unsetY)(blockValue);
    }
    return setX(x, blockValue);
  }

  return pipe(setX(x), unsetY, unsetTime)(blockValue);
});

const setXYChartY = curry((tableResource, y, time, blockValue) => {
  // Heuristic
  //
  // * If the selected y allows all the same options to be picked, keep
  //   the subsequent configurations.
  if (tableResource && typeCheck(tableResource, setY(y, blockValue))) {
    if (time) {
      return pipe(setY(y), setTime(time))(blockValue);
    }
    return setY(y, blockValue);
  }
  return pipe(setY(y), unsetTime)(blockValue);
});

const setStackedColumnScenario = setXYChartScenario;
const setStackedColumnTable = setXYChartTable;
const setStackedColumnX = setXYChartX;
const setStackedColumnY = setXYChartY;
const setStackedColumnTime = setTime;

const setLineScenario = setXYChartScenario;
const setLineTable = setXYChartTable;
const setLineX = setXYChartX;
const setLineY = setXYChartY;
const setLineTime = setTime;

const setStackedAreaScenario = setXYChartScenario;
const setStackedAreaTable = setXYChartTable;
const setStackedAreaX = setXYChartX;
const setStackedAreaY = setXYChartY;
const setStackedAreaTime = setTime;

const setClusteredColumnScenario = setXYChartScenario;
const setClusteredColumnTable = setXYChartTable;
const setClusteredColumnX = setXYChartX;
const setClusteredColumnY = setXYChartY;
const setClusteredColumnTime = setTime;

export {
  setVisualisationType,
  setTable,
  setTitle,
  setTime,
  setChoroplethScenario,
  setChoroplethTable,
  setChoroplethColour,
  setChoroplethGeography,
  setChoroplethTime,
  setIndicatorScenario,
  setIndicatorTable,
  setIndicatorValue,
  setIndicatorTime,
  setStackedColumnTable,
  setStackedColumnTime,
  setStackedColumnScenario,
  setStackedColumnX,
  setStackedColumnY,
  setLineTable,
  setLineTime,
  setLineScenario,
  setLineX,
  setLineY,
  setStackedAreaTable,
  setStackedAreaTime,
  setStackedAreaScenario,
  setStackedAreaX,
  setStackedAreaY,
  setClusteredColumnTable,
  setClusteredColumnTime,
  setClusteredColumnScenario,
  setClusteredColumnX,
  setClusteredColumnY,
  unsetIndicatorValue,
  dimensionOptions,
  measureOptions,
  measureOptionsOfType,
  timeOptions,
  tableTransform,
  setWithTimeHeuristic,
  setXYChartScenario,
  setXYChartTable,
  setXYChartX,
  setXYChartY,
};
