import { createSelector } from 'reselect';
import { flatMap, get, map, max, min, pipe, values } from 'lodash/fp';
import currentAttributesSelector from './currentAttributesSelector';

const scenarioYearMinMaxSelector = createSelector(
  currentAttributesSelector,
  (currentAttrs) =>
    pipe(
      get('values'),
      values,
      flatMap((a) => values(a.attributes)),
      flatMap('time_series'),
      map('year'),
      (years) => [min(years), max(years)],
    )(currentAttrs),
);

export default scenarioYearMinMaxSelector;
