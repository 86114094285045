import { createSelector } from 'reselect';
import { concat, get } from 'lodash/fp';
import { dimensionOptions } from 'utils/visualisationConfigUtils';

const scenarioDimension = {
  label: 'Scenario',
  value: { type: 'by-scenario' },
};

const visualisationDimensionOptionsSelector = createSelector(
  (state, props) => get(['resources', props.table], state),
  (_state, props) => props.criteria,
  (_state, props) => props.includeScenarioDimension,
  (tables, criteria, includeScenarioDimension) =>
    concat(
      includeScenarioDimension ? scenarioDimension : [],
      dimensionOptions(tables, criteria),
    ),
);

export default visualisationDimensionOptionsSelector;
