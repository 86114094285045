import { createSelector } from 'reselect';
import { flatMap, map, pipe, uniq } from 'lodash/fp';
import { requiredTablesOf } from 'data/block';
import blocksSelector from 'selectors/blocksSelector';

const dependenciesSelector = createSelector(
  blocksSelector,
  pipe(map('block'), flatMap(requiredTablesOf), uniq),
);

export default dependenciesSelector;
