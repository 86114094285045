import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Toolbar } from '@kinesis/bungle/legacy';

const BreadcrumbToolbar = styled(Toolbar)`
  width: 100%;
  min-width: 0;
  padding-right: 0;
  overflow: hidden;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const StyledNavLink = styled(NavLink)(
  ({ theme }) => ({
    flex: '0 0 auto',
    fontSize: theme.fontSize.lg,
    fontWeight: theme.fontWeight.medium,
    color: theme.color.gray8,
  }),
  ({ isActive, theme }) =>
    isActive
      ? {
          color: theme.color.gray9,
          pointerEvents: 'none',
        }
      : {},
);

const BreadcrumbSeparator = styled('span')(({ theme }) => ({
  color: theme.color.gray6,
  padding: 6,
}));

const BreadcrumbsNav = styled('nav')(({ theme }) => ({
  alignItems: 'center',
  color: theme.color.default,
  display: 'flex',
  flex: '1 1 auto',
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.lg,
  fontWeight: theme.fontWeight.medium,
  height: '100%',
  lineHeight: theme.lineHeight.default,
}));

export {
  BackButtonWrapper,
  BreadcrumbSeparator,
  BreadcrumbToolbar,
  BreadcrumbsNav,
  StyledNavLink,
};
