import { InPlaceInput } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { title as pathwayInputBlockTitle } from 'data/block/pathway-input';
import PropTypes from 'prop-types';
import blockValueSelector from 'selectors/blockValueSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const PathwayInputBlockPane = ({ blockId, privacy, workspaceId }) => {
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId,
    public: privacy === 'public',
    workspaceId,
  });

  return (
    <ToolboxPanel fadeScroll>
      <Content as='header' overflow='visible' padding='medium'>
        <InPlaceInput
          disabled
          value={pathwayInputBlockTitle(blockValue)}
          variant='title'
        />
      </Content>
    </ToolboxPanel>
  );
};

PathwayInputBlockPane.propTypes = propTypes;
PathwayInputBlockPane.defaultProps = defaultProps;

export { PathwayInputBlockPane };
