import { createSelector } from 'reselect';
import {
  sum,
  get,
  pipe,
  filter,
  find,
  first,
  groupBy,
  last,
  values,
  flatMap,
  concat,
  map,
  toPairs,
} from 'lodash/fp';
import Units from 'data/units';
import {
  categoryByKey,
  itemByKey,
  settingByKey,
  normalise,
  sortAttributesBy,
  labelOf,
} from 'data/attributes';
import currentAttributesSelector from 'selectors/currentAttributesSelector';
import scenarioLocationSelector from 'selectors/scenarioLocationSelector';
import paneYearSelector from 'selectors/paneYearSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import workspaceGeographiesSelector from 'selectors/workspaceGeographiesSelector';

const units = new Units();

const zeroed = (value) => ({
  categories: (value.categories || []).map((category) => ({
    ...category,
    entries: [],
  })),
  entries: (value.entries || []).map((entry) => ({
    ...entry,
    amount: { ...entry.amount, value: 0 },
  })),
});

const lastAt = (year, all) =>
  last(filter((event) => !event.year || event.year <= year, all));

const valuesAt = (year, all) =>
  lastAt(year, all) || zeroed(first(all)) || { categories: [], entries: [] };

const usageEntries = (year, scenario, usage) => {
  if (!year) return undefined;
  if (!usage) return [];

  const selected = valuesAt(year, usage.time_series);

  if (selected.categories.length === 0) {
    return [];
  }

  const areas = selected.categories.map((category) => {
    const { label } = pipe(
      settingByKey('usage'),
      categoryByKey(category.key),
    )(scenario);
    const value = sum(category.entries.map((entry) => entry.amount.value));
    return { label, value };
  });
  const total = sum(areas.map((area) => area.value));
  return areas
    .map(({ label, value }) => ({
      label,
      amount: `${(total === 0 ? 0 : (value / total) * 100).toLocaleString(
        undefined,
        {
          maximumFractionDigits: 0,
        },
      )}%`,
    }))
    .concat([
      {
        label: 'Total',
        amount: `${total.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })} m²`,
      },
    ]);
};
const usageSummary = (year, scenario, usage) => ({
  attributeKey: 'usage-v2',
  title: 'Usage',
  entries: usageEntries(year, scenario, usage),
});

const serviceEntries = (year, scenario, service) => {
  if (!year) return undefined;
  if (!service) return [];

  const selected = valuesAt(year, service.time_series);
  return selected.entries.map((entry) => {
    const { label, unit } = pipe(
      settingByKey('service'),
      itemByKey(entry.key),
    )(scenario);
    const amount = `${entry.amount.value.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    })} ${unit.label || units.parseType(unit).symbol}`;
    return { label, amount };
  });
};

const serviceSummary = (year, scenario, service) => ({
  attributeKey: 'service-v2',
  title: 'Services',
  entries: serviceEntries(year, scenario, service),
});

const rainwaterStorageEntries = (year, scenario, rainwater) => {
  if (!year) return undefined;
  if (!rainwater) return [];

  const selected = valuesAt(year, rainwater.time_series);

  return selected.entries.map((entry) => ({
    amount: units.litres().format(entry.amount.value),
    label: pipe(
      settingByKey('rainwater-tank'),
      itemByKey(entry.key),
      get('label'),
    )(scenario),
  }));
};

const rainwaterStorageSummary = (year, scenario, rainwater) => ({
  attributeKey: 'rainwater-tank',
  title: 'Rainwater storage',
  entries: rainwaterStorageEntries(year, scenario, rainwater),
});

const recycledWaterEntries = (year, scenario, water) => {
  if (!year) return undefined;
  if (!water) return [];

  const selected = valuesAt(year, water.time_series);

  return selected.entries.map((entry) => ({
    amount: units.litres().format(entry.amount.value),
    label: pipe(
      settingByKey('recycled-water-system'),
      itemByKey(entry.key),
      get('label'),
    )(scenario),
  }));
};

const recycledWaterSummary = (year, scenario, water) => ({
  attributeKey: 'recycled-water-system',
  title: 'Recycled water',
  entries: recycledWaterEntries(year, scenario, water),
});

const solarSummary = (year, _scenario, value) => {
  const v = lastAt(year, get('time_series', value));
  const capacity = get(['fields', 'peak-capacity', 'value'], v) || 0;
  const storage = get(['fields', 'storage', 'value'], v) || 0;
  const power = get(['fields', 'peak-power', 'value'], v) || 0;
  const charge = get(['fields', 'rate-of-charge', 'value'], v) || 0;
  const emptyCase = year ? [] : undefined;
  return {
    attributeKey: 'solar',
    title: 'Solar',
    entries: value
      ? [
          {
            label: 'Peak capacity',
            amount: units.kw().format(capacity),
          },
          { label: 'Storage', amount: units.kwh().format(storage) },
          { label: 'Peak power', amount: units.kw().format(power) },
          {
            label: 'Rate of charge',
            amount: units.kw().format(charge),
          },
        ]
      : emptyCase,
  };
};

const landSummary = (year, _scenario, value) => {
  const v = lastAt(year, get('time_series', value));
  const area = get(['fields', 'area', 'value'], v) || 0;
  const fsr = get(['fields', 'floor-space-ratio', 'value'], v);
  const roof = get(['fields', 'roof-area', 'value'], v);
  const rcp = get(['fields', 'rainwater-connected-proportion', 'value'], v);
  const emptyCase = year ? [] : undefined;
  return {
    attributeKey: 'land',
    title: 'Land area',
    entries: value
      ? [
          { label: 'Area', amount: units.sqm().format(area) },
          {
            label: 'Floor space ratio',
            amount: fsr ? `${units.ratio().format(fsr)}:1` : 'Not set',
          },
          {
            label: 'Roof area',
            amount: roof ? units.sqm().format(roof) : 'Not set',
          },
          {
            label: 'Rainwater connected proportion',
            amount: rcp ? units.decimal().format(rcp) : 'Not set',
          },
        ]
      : emptyCase,
  };
};

const fineAreaCorrespondenceEntry = (entry) => {
  const label = entry.key;
  const amount = `${entry.amount.value.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })}%`;
  return { label, amount };
};
const fineAreaCorrespondenceEntries = (year, scenario, value) => {
  if (!year) return undefined;
  if (!value) return [];
  // No time dimension, only uses "start-of-workspace" date.
  const selected = first(value.time_series) || { entries: [] };
  return pipe(
    groupBy(get(['amount', 'value'])),
    toPairs,
    flatMap(([amount, entries]) =>
      entries.length < 5
        ? map(fineAreaCorrespondenceEntry, entries)
        : [
            {
              label: `${entries.length} fine areas`,
              amount: `${amount.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}%`,
            },
          ],
    ),
  )(selected.entries);
};
const fineAreaCorrespondenceSummary = (year, scenario, value) => ({
  attributeKey: 'fine-area-correspondence',
  title: 'Fine area correspondence',
  entries: fineAreaCorrespondenceEntries(year, scenario, value),
});

const customSummary = (year, _scenario, setting, instantiation, value) => {
  const v = lastAt(year, get('time_series', value));
  const s = get('value', v);
  const emptyCase = year ? [] : undefined;
  return {
    attributeKey: instantiation.key,
    title: labelOf(instantiation),
    entries: value ? [{ label: s || 'Not set' }] : emptyCase,
  };
};

const geographySummary = (year, _scenario, value, geographies) => {
  const current = lastAt(year, get('time_series', value));
  const geography = get(get('value', current), geographies);
  const format = units.parseColumn({
    unit: { type: 'coordinate' },
  }).formatCell;
  return {
    attributeKey: 'geography',
    title: 'Position',
    entries: geography
      ? [
          {
            label: 'Latitude',
            amount: format(get(['point', 'latitude'], geography)),
          },
          {
            label: 'Longitude',
            amount: format(get(['point', 'longitude'], geography)),
          },
        ]
      : [],
  };
};

const attributesSummarySelector = createSelector(
  paneYearSelector,
  currentAttributesSelector,
  scenarioLocationSelector,
  workspaceInstantiationsSelector,
  workspaceGeographiesSelector,
  (year, scenario, location, instantiations, geographies) =>
    pipe(
      flatMap((key) => {
        const locationId = get('id', location);
        const raw = get(['values', locationId, 'attributes', key], scenario);
        const setting = get(['settings', key], scenario);
        if (!setting) {
          return [];
        }
        const instantiation = get(key, instantiations);
        const normalised = normalise(instantiation, location);
        const value = raw
          ? find({ key }, get('attributes', normalised))
          : undefined;
        return {
          attribute: key,
          value,
          setting,
          instantiation,
        };
      }),
      concat(
        pipe(
          values,
          filter({ attribute: 'custom' }),
          map((instance) => ({
            attribute: 'custom',
            setting: get(['settings', instance.key], scenario),
            instantiation: instance,
          })),
        )(instantiations),
      ),
      sortAttributesBy(({ instantiation }) => instantiation),
      flatMap(({ attribute, value, setting, instantiation }) => {
        switch (attribute) {
          case 'usage-v2':
            return [usageSummary(year, scenario, value)];
          case 'service-v2':
            return [serviceSummary(year, scenario, value)];
          case 'solar':
            return [solarSummary(year, scenario, value)];
          case 'land':
            return [landSummary(year, scenario, value)];
          case 'rainwater-tank':
            return [rainwaterStorageSummary(year, scenario, value)];
          case 'recycled-water-system':
            return [recycledWaterSummary(year, scenario, value)];
          case 'geography':
            return [geographySummary(year, scenario, value, geographies)];
          case 'fine-area-correspondence':
            return [fineAreaCorrespondenceSummary(year, scenario, value)];
          case 'custom':
            return [
              customSummary(
                year,
                scenario,
                setting,
                instantiation,
                find({ key: instantiation.key }, get('attributes', location)),
              ),
            ];
          default:
            return [];
        }
      }),
    )([
      'geography',
      'spatial',
      'land',
      'usage-v2',
      'service-v2',
      'solar',
      'rainwater-tank',
      'recycled-water-system',
      'fine-area-correspondence',
    ]),
);

export default attributesSummarySelector;
