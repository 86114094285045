import { createSelector } from 'reselect';
import { get, find, isEqual } from 'lodash/fp';
import blockDataSelector from 'selectors/blockDataSelector';
import tablesSelector from 'selectors/tablesSelector';
import { measureOptions } from 'utils/visualisationConfigUtils';
import { isGeographyDimension } from 'data/table';
import { labelOfMeasure } from 'data/block';

const colourExpressionLabelSelector = createSelector(
  blockDataSelector,
  tablesSelector,
  (data, tables) => {
    const family = get(['query', 'table'], data);
    const table = find({ family }, tables);
    const colour = get(['query', 'colour'], data);
    const column = get('measure', colour) || get('column', colour);
    const label = get(['schema', column, 'label'], table);
    const options = measureOptions([isGeographyDimension], table);
    const option = find((o) => isEqual(o.value, colour), options);
    return option ? labelOfMeasure(option) : label;
  },
);

export default colourExpressionLabelSelector;
