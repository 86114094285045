import { createSelector } from 'reselect';
import { get, isNil, pipe } from 'lodash/fp';
import blockValueSelector from './blockValueSelector';
import isCapsuleMismatchSelector from './isCapsuleMismatchSelector';
import isScenarioMismatchSelector from './isScenarioMismatchSelector';
import blockIsProcessingSelector from './blockIsProcessingSelector';
import blockPerspectiveStateSelector from './blockPerspectiveStateSelector';

const isBlockAwaitingAnswerSelector = createSelector(
  blockPerspectiveStateSelector,
  pipe(blockValueSelector, get('type')),
  isCapsuleMismatchSelector,
  isScenarioMismatchSelector,
  blockIsProcessingSelector,
  (
    blockPerspectiveState,
    blockType,
    isCapsuleMismatch,
    isScenarioMismatch,
    isProcessing,
  ) => {
    if (blockType !== 'editable-visualisation') return isProcessing;

    return (
      !isNil(blockPerspectiveState) && (isCapsuleMismatch || isScenarioMismatch)
    );
  },
);

export default isBlockAwaitingAnswerSelector;
