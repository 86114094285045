import { createSelector } from 'reselect';
import { get, lowerCase, map, orderBy, pick, pipe, values } from 'lodash/fp';

const getToLowerCase = (key) => pipe(get(key), lowerCase);

const sortedWorkspacesSelector = createSelector(
  get('workspaces'),
  get('organisations'),
  (state, props) => get('sortField', props) || 'name',
  (workspaces, organisations, sortField) =>
    pipe(
      values,
      map('data'),
      map((d) => ({
        ...pick(['description', 'id', 'organisationId', 'touchedAt'], d),
        name: d.name || 'New workspace',
        editor: get(['permissions', 'editor'], d),
        organisationImageUrl: get(
          [[d.organisationId], 'avatarUrl'],
          organisations,
        ),
        organisationName: get([d.organisationId, 'name'], organisations),
      })),
      orderBy(
        [getToLowerCase(sortField), getToLowerCase('name')],
        [sortField === 'touchedAt' ? 'desc' : 'asc', 'asc'],
      ),
    )(workspaces),
);

export default sortedWorkspacesSelector;
