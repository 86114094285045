import { createSelector } from 'reselect';
import { filter, get, pipe, sortBy, values } from 'lodash/fp';

const locationsChooserSelector = createSelector(
  (state, { workspaceId }) =>
    get(['workspaces', workspaceId, 'locations'], state),
  (state, { scenarioId, workspaceId }) => {
    const publishedScenarioKey = get(
      [
        'workspaces',
        workspaceId,
        'scenarios',
        'named',
        scenarioId,
        'published',
        'scenario',
      ],
      state,
    );

    const draftScenarioKey = get(
      [
        'workspaces',
        workspaceId,
        'scenarios',
        'named',
        scenarioId,
        'draft',
        'scenario',
      ],
      state,
    );

    const scenarioKey = draftScenarioKey || publishedScenarioKey;

    return get(
      ['workspaces', workspaceId, 'attributes', scenarioKey, 'values'],
      state,
    );
  },
  (state, { layerId }) => layerId,
  (locations, attributeValues, layerId) =>
    pipe(
      values,
      filter(
        (location) =>
          get(location.id, attributeValues) && location.layer === layerId,
      ),
      sortBy('label'),
    )(locations),
);

export default locationsChooserSelector;
