import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, isNil, get, map, size, pipe } from 'lodash/fp';
import { Tooltip, UtilityButton } from '@kinesis/bungle';
import {
  ToolbarGroup,
  ToolbarItem,
  ToolbarSeparator,
} from '@kinesis/bungle/legacy';
import { AppToolbar, BackButton, Breadcrumb } from 'components/app-header';
import Layout from 'components/layout';
import { Loading } from 'components/loading';
import { ScenarioBoardDropdown } from 'components/scenario-board-dropdown';
import { ScenarioSwitcher } from 'components/scenario-switcher';
import { PageLayout } from 'components/page-layout';
import { Toolbox } from 'components/toolbox';
import { BoardToolbar } from 'components/board-toolbar';
import boardSelector from 'selectors/boardSelector';
import anyDirtyBlocksSelector from 'selectors/anyDirtyBlocksSelector';
import tablesSelector from 'selectors/tablesSelector';
import processingSelector from 'selectors/processingSelector';
import filteredScenarioLocationsSelector from 'selectors/filteredScenarioLocationsSelector';
import workspacePublishedSelector from 'selectors/workspacePublishedSelector';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import { dirtyBlocksDiscard } from 'actions/dirtyBlocksDiscard';
import { BlockGrid } from 'components/block-grid';
import { ImmutableBlockGrid } from 'components/immutable-block-grid';
import { EmptyBoard } from 'components/empty-board';
import { BoardTimeSlider } from 'components/board-time-slider';
import { ViewOnlyIndicator } from 'components/view-only-indicator';
import {
  useActions,
  useBlockId,
  useBoardId,
  usePermission,
  useScenarioId,
  useSelectorWithProps,
  useWorkspaceId,
} from 'hooks';
import useToolboxOpenState from 'hooks/useToolboxOpenState';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import isEmptyBoardSelector from 'selectors/isEmptyBoardSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import selectedBlockIdsWithTimeSelector from 'selectors/selectedBlockIdsWithTimeSelector';
import { Lifecycle } from './lifecycle';
import { Progress } from './progress';

const isBlockEmpty = pipe(get('visualisation'), isEqual('empty'));

const Board = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();
  const boardId = useBoardId();
  const scenarioId = useScenarioId();
  const outputCount = useSelectorWithProps(pipe(tablesSelector, size), {
    workspaceId,
  });
  const processing = useSelectorWithProps(processingSelector, { workspaceId });
  const isPublished = useSelectorWithProps(workspacePublishedSelector, {
    workspaceId,
  });
  const scenarioLocations = useSelectorWithProps(
    filteredScenarioLocationsSelector,
    { scenarioId, workspaceId },
  );
  const panes = useSelector(get(['toolbox', 'panes']));
  const [isToolboxOpen, setToolboxOpenState] = useToolboxOpenState();

  const board = useSelectorWithProps(boardSelector, { boardId, workspaceId });
  const maximisedBlockId = useBlockId();
  const selectedBlockIdsWithTime = useSelectorWithProps(
    selectedBlockIdsWithTimeSelector,
    {
      blockId: maximisedBlockId,
      boardId,
      scenarioId,
      workspaceId,
    },
  );
  const needsTimeSlider =
    isNil(maximisedBlockId) ||
    selectedBlockIdsWithTime.includes(maximisedBlockId);
  const maximisedBlockValue = useSelectorWithProps(blockValueSelector, {
    blockId: maximisedBlockId,
    workspaceId,
  });
  const selectedBlockIds = useSelector(blockSelectionsSelector);
  const onScenarioSwitch = useCallback(
    (sid) => {
      navigate({ search: `?scenario=${sid}` });
    },
    [navigate],
  );
  const { select, removePane } = useActions(toolboxActions);
  const {
    selectBlock,
    selectBoard,
    reset: resetBoardAndBlockSelections,
  } = useActions(blockSelectionsActions);

  const editor = usePermission('editor');
  const shouldReselectBlock =
    maximisedBlockId && !isEqual([maximisedBlockId], selectedBlockIds);
  const isEmptyBoard = useSelectorWithProps(isEmptyBoardSelector, {
    boardId,
    workspaceId,
  });

  // NOTE: This must occur before the next useEffect.
  useEffect(() => {
    select({
      pane: 'context',
      selection: { id: boardId, type: 'board' },
    });
    selectBoard(boardId);
  }, [boardId, select, selectBoard, workspaceId]);

  useEffect(() => {
    if (shouldReselectBlock) {
      selectBlock(maximisedBlockId);
    }
  }, [shouldReselectBlock, selectBlock, maximisedBlockId]);

  // This removes the 'Location' pane when you go from:
  // - being on a scenario...
  // - with a location selected on a spatial vis,
  // and you change to a scenario that does NOT have the location.
  useEffect(() => {
    const locationPane = panes.find(
      (p) => p.type === 'detail' && p.label === 'location',
    );
    if (locationPane) {
      const locationId = get(['selection', 'id'], locationPane);

      if (!map('id', scenarioLocations).includes(locationId)) {
        removePane('detail');
      }
    }
  }, [scenarioLocations, panes, removePane]);

  const anyDirtyBlocks = useSelectorWithProps(anyDirtyBlocksSelector, {
    workspaceId,
  });

  useEffect(
    () => () => {
      if (!editor && anyDirtyBlocks) {
        dispatch(dirtyBlocksDiscard({ workspaceId }));
      }
    },
    [anyDirtyBlocks, dispatch, editor, workspaceId],
  );

  // reset board and block selections on unmount
  useEffect(
    () => () => {
      resetBoardAndBlockSelections();
    },
    [resetBoardAndBlockSelections],
  );

  const handleToolboxStateChange = useCallback(() => {
    setToolboxOpenState((isOpen) => !isOpen);
  }, [setToolboxOpenState]);

  const BlockGridImpl = useMemo(() => {
    if (!board) return undefined;
    if (isEmptyBoard) return EmptyBoard;

    return editor ? BlockGrid : ImmutableBlockGrid;
  }, [board, editor, isEmptyBoard]);

  if (!board) {
    return <Loading delay={200} />;
  }

  if (
    maximisedBlockId &&
    (isNil(maximisedBlockValue) ||
      (isBlockEmpty(maximisedBlockValue) && !editor))
  ) {
    return <Navigate to={`/workspaces/${workspaceId}/boards/${boardId}`} />;
  }

  return (
    <PageLayout rounded>
      <BackButton to={`/workspaces/${workspaceId}`} />
      <Breadcrumb>
        <ScenarioBoardDropdown workspaceId={workspaceId} />
      </Breadcrumb>
      <AppToolbar>
        <ToolbarGroup>
          {!editor && (
            <>
              <ToolbarItem>
                <ViewOnlyIndicator />
              </ToolbarItem>
              <ToolbarSeparator />
            </>
          )}
          <ToolbarItem>
            <ScenarioSwitcher
              onScenarioSwitch={onScenarioSwitch}
              workspaceId={workspaceId}
            />
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip
              justify='end'
              placement='bottom'
              title={isToolboxOpen ? 'Hide toolbox' : 'Show toolbox'}
            >
              <UtilityButton
                expanded={isToolboxOpen}
                icon='control'
                variant={isToolboxOpen ? 'accent' : 'default'}
                onClick={handleToolboxStateChange}
              />
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </AppToolbar>
      <Layout direction='row'>
        <Layout direction='column' overflow='hidden'>
          {!maximisedBlockId && (
            <BoardToolbar
              isPublished={isPublished}
              processing={processing}
              total={outputCount}
            />
          )}
          <Progress workspaceId={workspaceId}>
            <Lifecycle boardId={boardId} workspaceId={workspaceId}>
              <BlockGridImpl boardId={boardId} workspaceId={workspaceId} />
            </Lifecycle>
          </Progress>
          {needsTimeSlider && (
            <BoardTimeSlider
              boardId={boardId}
              maximisedBlockId={maximisedBlockId}
              workspaceId={workspaceId}
            />
          )}
        </Layout>
        {isToolboxOpen && <Toolbox />}
      </Layout>
    </PageLayout>
  );
};

export { Board };
