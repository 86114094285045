import { forwardRef, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get, includes } from 'lodash/fp';
import { Draggable } from 'react-beautiful-dnd';
import { Block } from 'components/block';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import useLongPress from 'hooks/useLongPress';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import GrabberSvg from 'utils/GrabberSvg';
import useActions from 'hooks/useActions';
import {
  BlockCard as BlockCardSection,
  DragHandle,
  DraggableWrapper,
  PlaceholderBlock,
} from './block-grid.styles';

const propTypes = {
  blockIndex: PropTypes.number,
  darkenPlaceholder: PropTypes.bool,
  draggingId: PropTypes.string,
  enableReorderBlocks: PropTypes.bool,
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  removeBlocks: PropTypes.func,
  span: PropTypes.number,
  startColumn: PropTypes.number,
};

const defaultProps = {
  blockIndex: undefined,
  darkenPlaceholder: undefined,
  draggingId: undefined,
  enableReorderBlocks: false,
  height: undefined,
  removeBlocks: undefined,
  span: 1,
  startColumn: undefined,
};

const BlockCard = forwardRef(
  (
    {
      blockIndex,
      darkenPlaceholder,
      draggingId,
      enableReorderBlocks,
      height,
      id,
      removeBlocks,
      span,
      startColumn,
      ...props
    },
    ref,
  ) => {
    const containerRef = useRef();
    const selectedBlockIds = useSelector(blockSelectionsSelector);
    const [placeholderHeight, setPlaceholderHeight] = useState(0);
    const { selectBlock, toggleBlock } = useActions(blockSelectionsActions);

    const updatePlaceholderHeight = useCallback(() => {
      if (containerRef && containerRef.current) {
        setPlaceholderHeight(containerRef.current.clientHeight);
      }
    }, [containerRef]);

    useEffect(() => {
      updatePlaceholderHeight();
    }, [containerRef, updatePlaceholderHeight]);

    const handleSelect = useCallback(
      (event) => {
        event.stopPropagation();

        if (get('shiftKey', event)) {
          toggleBlock(id);
        } else {
          selectBlock(id);
        }
      },
      [id, toggleBlock, selectBlock],
    );
    const handleMouseDown = useCallback((event) => {
      // disables text selecting on shift-click
      if (event.shiftKey) {
        document.getSelection().removeAllRanges();
      }
    }, []);

    const handleMultiSelect = useCallback(() => {
      toggleBlock(id);
    }, [id, toggleBlock]);

    const { onTouchStart, onTouchEnd } = useLongPress(handleMultiSelect);

    const isSelected = includes(id, selectedBlockIds);

    useEffect(() => {
      if (isSelected && ref && ref.current) {
        ref.current.focus();
      }
    }, [ref, isSelected]);

    const [showDragHandle, setShowDragHandle] = useState(false);
    const [darkenHandle, setDarkenHandle] = useState(false);
    const [handleHover, setHandleHover] = useState(false);

    const onMouseEnter = useCallback(() => {
      if (!draggingId) {
        setShowDragHandle(true);
      }
    }, [draggingId]);

    const onMouseLeave = () => {
      setShowDragHandle(false);
      setDarkenHandle(false);
    };

    const onHandleMouseDown = () => setDarkenHandle(true);
    const onHandleMouseUp = () => setDarkenHandle(false);
    const onHandleMouseEnter = () => setHandleHover(true);
    const onHandleMouseLeave = () => setHandleHover(false);

    return enableReorderBlocks ? (
      <>
        <PlaceholderBlock
          height={placeholderHeight}
          span={span}
          visible={draggingId === id}
          darken={darkenPlaceholder}
        />
        <Draggable key={id} draggableId={id} index={blockIndex}>
          {(provided, snapshot) => (
            <DraggableWrapper
              ref={provided.innerRef}
              {...provided.draggableProps}
              isDragging={snapshot.isDragging}
              span={span}
              startColumn={startColumn}
            >
              <div ref={containerRef}>
                <BlockCardSection
                  handleHover={handleHover}
                  height={height}
                  isDragging={snapshot.isDragging}
                  onClick={handleSelect}
                  onMouseDown={handleMouseDown}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onTouchEnd={onTouchEnd}
                  onTouchStart={onTouchStart}
                  ref={ref}
                  selected={isSelected}
                  span={span}
                  tabIndex={0}
                  {...props}
                >
                  <>
                    <DragHandle
                      {...provided.dragHandleProps}
                      tabIndex={-1}
                      darken={darkenHandle || snapshot.isDragging}
                      onMouseDown={onHandleMouseDown}
                      onMouseEnter={onHandleMouseEnter}
                      onMouseLeave={onHandleMouseLeave}
                      onMouseUp={onHandleMouseUp}
                      selected={isSelected}
                      showHoverStyles
                      visible={showDragHandle || snapshot.isDragging}
                    >
                      <GrabberSvg />
                    </DragHandle>

                    <Block
                      dragHandleProps={provided.dragHandleProps}
                      id={id}
                      removeBlocks={removeBlocks}
                      selected={isSelected}
                      updatePlaceholderHeight={updatePlaceholderHeight}
                    />
                  </>
                </BlockCardSection>
              </div>
            </DraggableWrapper>
          )}
        </Draggable>
      </>
    ) : (
      <BlockCardSection
        height={height}
        onClick={handleSelect}
        onMouseDown={handleMouseDown}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
        ref={ref}
        selected={isSelected}
        span={span}
        tabIndex={0}
        {...props}
      >
        <Block id={id} removeBlocks={removeBlocks} selected={isSelected} />
      </BlockCardSection>
    );
  },
);

BlockCard.propTypes = propTypes;
BlockCard.defaultProps = defaultProps;

export default BlockCard;
