import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import publicVisualisationLoadingSelector from 'selectors/publicVisualisationLoadingSelector';
import { toolboxChartDetailIdSelector } from 'selectors/toolboxDetailSelectors';
import BaselineComparison from 'components/baseline-comparison/BaselineComparison';
import Chart from 'components/charts/highcharts/Chart';
import { StaticIndicator } from 'components/static-indicator';
import Layout from 'components/layout';
import { Loading } from 'components/loading';
import PublicSpatialVisualisation from 'components/spatial-visualisation/PublicSpatialVisualisation';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import {
  useActions,
  useBoardId,
  usePerspectives,
  useSelectorWithProps,
} from 'hooks';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  type: PropTypes.string.isRequired,
  viewMode: PropTypes.oneOf(['maximised', 'minimised', 'standalone'])
    .isRequired,
};

const defaultProps = {
  isSelected: false,
};

const VisualisationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 0;
  height: 100%;
  justify-content: center;
  position: relative;
`;

const PublicStaticVisualisation = ({
  blockId,
  isSelected,
  type,
  viewMode,
  workspaceId,
}) => {
  const boardId = useBoardId();
  const perspectives = usePerspectives();
  const { select } = useActions(toolboxActions);
  const { selectBlock, toggleBlock } = useActions(blockSelectionsActions);
  const isLoading = useSelectorWithProps(publicVisualisationLoadingSelector, {
    blockIds: [blockId],
    boardId,
    perspectives,
    public: true,
    workspaceId,
  });
  const selectedDetailId = useSelector(toolboxChartDetailIdSelector);
  const onWrapperClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleChartSelect = useCallback(
    (value) => {
      selectBlock(blockId);

      if (value) {
        select({
          pane: 'detail',
          selection: { id: value, type: 'chart-detail' },
          label: 'details',
        });
      }
    },
    [blockId, select, selectBlock],
  );

  const handleBlockMultiSelect = useCallback(() => {
    toggleBlock(blockId);
  }, [blockId, toggleBlock]);

  if (isLoading) {
    return (
      <Layout direction='column'>
        <VisualisationWrapper data-testid='block-loading-view'>
          <Loading
            offset={viewMode === 'maximised' || type === 'spatial' ? 0 : 16}
            delay={200}
          />
        </VisualisationWrapper>
      </Layout>
    );
  }

  return (
    <Layout direction='column' scroll={type === 'table'}>
      <VisualisationWrapper onClick={onWrapperClick}>
        {type === 'spatial' && (
          <PublicSpatialVisualisation
            allowScrollZoom={
              isSelected ||
              viewMode === 'maximised' ||
              viewMode === 'standalone'
            }
            blockId={blockId}
            boardId={boardId}
            perspectives={perspectives}
            viewMode={viewMode}
            workspaceId={workspaceId}
          />
        )}
        {type === 'indicator' && (
          <StaticIndicator
            blockId={blockId}
            privacy='public'
            workspaceId={workspaceId}
          />
        )}
        {type === 'baseline-comparison' && (
          <BaselineComparison
            blockId={blockId}
            privacy='public'
            workspaceId={workspaceId}
          />
        )}
        {!['spatial', 'indicator', 'baseline-comparison'].includes(type) && (
          <Chart
            blockId={blockId}
            boardId={boardId}
            clickable={viewMode !== 'standalone'}
            isSelected={isSelected}
            perspectives={perspectives}
            selectedDetailId={
              isSelected || viewMode === 'maximised'
                ? selectedDetailId
                : undefined
            }
            maxHeight={viewMode === 'maximised' ? 768 : undefined}
            maxWidth={viewMode === 'maximised' ? 1280 : undefined}
            onBlockMultiSelect={handleBlockMultiSelect}
            onSelect={handleChartSelect}
            privacy='public'
            showLegend={viewMode === 'maximised'}
            workspaceId={workspaceId}
          />
        )}
      </VisualisationWrapper>
    </Layout>
  );
};

PublicStaticVisualisation.propTypes = propTypes;
PublicStaticVisualisation.defaultProps = defaultProps;

export { PublicStaticVisualisation };
