import styled from 'styled-components';

import Layout from 'components/layout';

const GlobalLayout = styled(Layout)`
  background-color: #04324f;
  position: fixed;
  height: 100%;
  width: 100%;
`;

export { GlobalLayout };
