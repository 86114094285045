import { H2, Inline, InlineItem, Stack } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { BudgetIndicator } from './budget-indicator';

const BudgetIndicators = () => (
  <Content overflow='visible' padding='none' alignX='stretch'>
    <Stack space='medium'>
      <Stack space='xsmall'>
        <H2>Remaining carbon budget at 2050</H2>
      </Stack>

      <Inline>
        <InlineItem sizing='fill-container'>
          <BudgetIndicator policy='current' />
        </InlineItem>
        <InlineItem sizing='fill-container'>
          <BudgetIndicator policy='intermediate' />
        </InlineItem>
        <InlineItem sizing='fill-container'>
          <BudgetIndicator policy='aggressive' />
        </InlineItem>
      </Inline>
    </Stack>
  </Content>
);

export { BudgetIndicators };
