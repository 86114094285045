import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Menu } from '@kinesis/bungle';
import LayerCircle from 'components/layer-circle/LayerCircle';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import nearbyLocationsSelector from 'selectors/nearbyLocationsSelector';
import { useScenarioId, useSelectorWithProps } from 'hooks';

const propTypes = {
  locationId: PropTypes.number.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const NearbyLocationsSection = ({ locationId, privacy, workspaceId }) => {
  const dispatch = useDispatch();
  const scenarioId = useScenarioId();
  const nearbyLocations = useSelectorWithProps(nearbyLocationsSelector, {
    locationId,
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });
  const handleSelect = useCallback(
    (id) => {
      dispatch(
        toolboxActions.select({
          pane: 'detail',
          selection: { id, type: 'location' },
        }),
      );
    },
    [dispatch],
  );

  if (nearbyLocations.length === 0) {
    return null;
  }

  return (
    <ToolboxSection collapsible title='Nearby Locations'>
      <Menu>
        {nearbyLocations.map((location) => (
          <Menu.Item
            key={location.id}
            onClick={() => handleSelect(location.id)}
            icon={
              <LayerCircle layerId={location.layer} workspaceId={workspaceId} />
            }
            content={location.label || 'New location'}
          />
        ))}
      </Menu>
    </ToolboxSection>
  );
};

NearbyLocationsSection.propTypes = propTypes;
NearbyLocationsSection.defaultProps = defaultProps;

export default NearbyLocationsSection;
