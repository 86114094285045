import { Secondary } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import styled from 'styled-components';
import { PlaceholderContainer } from './pathways-visualisations.styles';

// NOTE: This is used as a workaround for not being able to center Secondary text.
const AlignedText = styled('div')`
  text-align: center;
`;

const PathwaysVisualisationsPlaceholder = () => (
  <PlaceholderContainer>
    <Content height='100%' alignY='center'>
      <AlignedText>
        <Secondary>Select some locations to preview your pathway.</Secondary>
      </AlignedText>
    </Content>
  </PlaceholderContainer>
);

export { PathwaysVisualisationsPlaceholder };
