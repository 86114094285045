import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceShareRevoke = createAsyncThunk(
  'workspace-share/revoke',
  async ({ token }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.delete(`/api/workspace-shares/${token}`);

    return response.data;
  },
);

export { workspaceShareRevoke };
