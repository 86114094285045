import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetSetName = createAsyncThunk(
  'dataset/set-name',
  async ({ id, name }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.put(`/api/datasets/${id}/name`, { name });
    return response.data;
  },
);

export { datasetSetName };
