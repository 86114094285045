import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, find, identity } from 'lodash/fp';
import {
  Avatar,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  DropdownMenuSeparator,
  DropdownMenuText,
  UtilityButton,
} from '@kinesis/bungle';
import {
  OrganisationName,
  OrganisationMenuItem,
} from './organisation-dropdown.styles';

const propTypes = {
  disabled: PropTypes.bool,
  editor: PropTypes.bool,
  value: PropTypes.number,
  message: PropTypes.string,
  onSelect: PropTypes.func,
  organisations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
    }),
  ),
};
const defaultProps = {
  disabled: false,
  value: undefined,
  message: undefined,
  editor: true,
  organisations: [],
  onSelect: identity,
};

const OrganisationDropdown = ({
  disabled,
  editor,
  organisations,
  value,
  message,
  onSelect,
}) => {
  const selected = useMemo(
    () => find({ id: value }, organisations),
    [organisations, value],
  );
  return (
    <DropdownMenu>
      <DropdownMenuButton
        as={UtilityButton}
        disabled={disabled || !editor}
        dropdown
        inline
        magnitude='xsmall'
      >
        {get('name', selected)}
      </DropdownMenuButton>
      <DropdownMenuList width={240}>
        {message && (
          <>
            <DropdownMenuText>{message}</DropdownMenuText>
            <DropdownMenuSeparator />
          </>
        )}
        {organisations.map(({ id, name, avatarUrl }) => (
          <DropdownMenuItem
            key={id}
            onSelect={() => onSelect(id)}
            variant={id === value ? 'accent' : undefined}
          >
            <OrganisationMenuItem>
              <Avatar
                alt={`${name} logo`}
                image={avatarUrl}
                magnitude='xsmall'
                variant='organisation'
              >
                {name.slice(0, 1)}
              </Avatar>
              <OrganisationName>{name}</OrganisationName>
            </OrganisationMenuItem>
          </DropdownMenuItem>
        ))}
      </DropdownMenuList>
    </DropdownMenu>
  );
};

OrganisationDropdown.defaultProps = defaultProps;
OrganisationDropdown.propTypes = propTypes;

export { OrganisationDropdown };
