import { createSlice } from '@reduxjs/toolkit';
import { get, take, uniq } from 'lodash/fp';
import { actions as toolboxActions } from './toolboxReducer';

const isSelectLocation = (action) =>
  get(['payload', 'pane'], action) === 'detail' &&
  get(['payload', 'selection', 'type'], action) === 'location';

const getLocation = get(['payload', 'selection', 'id']);

const { reducer, actions } = createSlice({
  name: 'recentLocations',
  initialState: [],
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(toolboxActions.select, (state, action) =>
      isSelectLocation(action)
        ? uniq(take(10, [getLocation(action), ...state]))
        : state,
    );
  },
});

export { reducer, actions };
