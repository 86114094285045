import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map, first, get } from 'lodash/fp';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import blocksSelector from 'selectors/blocksSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import usePermission from 'hooks/usePermission';
import { allSameBlockGroup } from 'data/block';
import { PathwayInputBlockPane } from 'components/toolbox/panes/pathway-input-block-pane';
import { PublicVisualisationPane } from '../public-visualisation-pane';
import { VisualisationPane } from '../visualisation-pane';

const multiBlockLabels = {
  text: 'text blocks',
  visualisation: 'visualisations',
  'editable-visualisation': 'visualisations',
  'pathway-input': 'pathway summaries',
};

const propTypes = {
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const blockType = get(['block', 'type']);

const BlockPane = ({ privacy, workspaceId }) => {
  const editor = usePermission('editor');
  const { blocks: blockIds, board: boardId } = useSelector(
    get('blockSelections'),
  );
  const blocks = useSelectorWithProps(blocksSelector, {
    blockIds,
    boardId,
    public: privacy === 'public',
    workspaceId,
  });

  const multiBlockLabel = useMemo(() => {
    const allSameType = allSameBlockGroup(map('block', blocks));
    return allSameType ? multiBlockLabels[blockType(first(blocks))] : 'blocks';
  }, [blocks]);

  if (blocks.length > 1) {
    return (
      <ToolboxPanel
        fadeScroll
        title={`${blocks.length} ${multiBlockLabel} selected`}
      />
    );
  }

  const block = first(blocks);
  const blockId = get('id', first(blocks));

  switch (blockType(block)) {
    case 'pathway-input':
      return (
        <PathwayInputBlockPane
          key={blockId}
          blockId={blockId}
          privacy={privacy}
          workspaceId={workspaceId}
        />
      );
    case 'editable-visualisation':
    case 'visualisation': {
      return privacy === 'public' ? (
        <PublicVisualisationPane
          key={blockId}
          blockId={blockId}
          boardId={boardId}
          workspaceId={workspaceId}
        />
      ) : (
        <VisualisationPane
          key={blockId}
          blockId={blockId}
          boardId={boardId}
          editor={editor}
          workspaceId={workspaceId}
        />
      );
    }
    default:
      return null;
  }
};

BlockPane.propTypes = propTypes;
BlockPane.defaultProps = defaultProps;

export { BlockPane };
