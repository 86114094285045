import PropTypes from 'prop-types';
import { Inline, InlineItem, Stack } from '@kinesis/bungle';
import { identity, isNil } from 'lodash/fp';
import { MapLegendIsland } from 'components/map-legend-island';
import { GradientPill } from 'components/gradient-pill';
import {
  LegendHeading,
  LegendList,
  LegendNoData,
  LegendItemWrapper,
} from './choropleth-legend.styles';

const propTypes = {
  heading: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  format: PropTypes.func,
};
const defaultProps = {
  min: undefined,
  max: undefined,
  format: identity,
};

const ChoroplethLegend = ({ heading, min, max, format }) => (
  <MapLegendIsland>
    <Stack space='xsmall'>
      <Stack space='none'>
        <LegendHeading>{heading}</LegendHeading>
        <LegendHeading subheading>Colour</LegendHeading>
      </Stack>

      {!isNil(min) && !isNil(max) && (
        <Inline alignY='top' space='small'>
          <InlineItem>
            <GradientPill />
          </InlineItem>

          <InlineItem>
            <LegendList>
              <LegendItemWrapper>{format(min)}</LegendItemWrapper>
              <LegendItemWrapper>{format(max)}</LegendItemWrapper>
            </LegendList>
          </InlineItem>
        </Inline>
      )}
      {(isNil(min) || isNil(max)) && <LegendNoData />}
    </Stack>
  </MapLegendIsland>
);

ChoroplethLegend.propTypes = propTypes;
ChoroplethLegend.defaultProps = defaultProps;

export { ChoroplethLegend };
