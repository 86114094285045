import { get } from 'lodash/fp';
import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  Icon,
  Inline,
  InlineItem,
  Secondary,
  Text,
  Toast,
  Tooltip,
  UtilityButton,
  useToast,
} from '@kinesis/bungle';
import { ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { AppToolbar, BackButton, Breadcrumb } from 'components/app-header';
import { pathwayDownload } from 'actions/pathwayDownload';
import { useCallback, useState } from 'react';
import usePathwayInputs from 'hooks/usePathwayInputs';
import { PathwaysPublishModal } from 'components/pathways-publish-modal';
import { PathwaysPublishSuccessNotice } from 'components/pathways-publish-success-notice';
import { LinkIcon } from './link-icon';

const propTypes = {};
const defaultProps = {};

const PathwaysToolbar = () => {
  const [inputs] = usePathwayInputs();
  const pathwaysState = useSelector(get(['pathways', 'state']));
  const downloadState = useSelector(get(['pathways', 'downloadState']));
  const publishState = useSelector(get(['pathways', 'publishState']));
  const toast = useToast('globalTop');
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showPublishModal, setShowPublishModal] = useState(false);

  const handleDownload = useCallback(() => {
    dispatch(pathwayDownload(inputs));
  }, [dispatch, inputs]);

  const copyLink = async () => {
    await navigator.clipboard.writeText(window.location.href);
    toast('Link copied to clipboard.', { variant: 'success' });
  };

  return (
    <>
      <BackButton />
      <Breadcrumb to='/pathways' root>
        Pathways
      </Breadcrumb>
      <AppToolbar>
        <ToolbarGroup>
          <ToolbarItem>
            {['generating', 'generating-fresh'].includes(pathwaysState) && (
              <Inline space='small'>
                <InlineItem>
                  <Icon
                    color={theme.color.blue6}
                    animation='rotate'
                    type='spinner'
                    magnitude='small'
                  />
                </InlineItem>
                <InlineItem>
                  <Secondary>Updating preview...</Secondary>
                </InlineItem>
              </Inline>
            )}
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarItem>
            <Tooltip
              title='Copy a link to this pathway to share it with others or return to it later'
              placement='bottom'
              maxWidth={217}
            >
              <UtilityButton onClick={copyLink}>
                <Inline space='xsmall'>
                  <InlineItem>
                    <LinkIcon magnitude='small' />
                  </InlineItem>
                  <InlineItem>
                    <Text>Copy link</Text>
                  </InlineItem>
                </Inline>
              </UtilityButton>
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem>
            <DropdownMenu justify='end' placement='bottom'>
              <DropdownMenuButton
                as={Button}
                dropdown
                variant={
                  // NOTE: This is a workaround for ensuring the dropdown caret
                  // is the appropriate color for disabled states.
                  // cf. https://github.com/kinesisptyltd/bungle/issues/3608
                  pathwaysState === 'placeholder' ? 'secondary' : 'primary'
                }
                loading={
                  downloadState === 'downloading' ||
                  publishState === 'publishing'
                }
                disabled={pathwaysState === 'placeholder'}
              >
                Export outputs
              </DropdownMenuButton>
              <DropdownMenuList>
                <DropdownMenuItem onSelect={() => setShowPublishModal(true)}>
                  Publish to workspace..
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={handleDownload}>
                  Download as ZIP
                </DropdownMenuItem>
              </DropdownMenuList>
            </DropdownMenu>
          </ToolbarItem>
        </ToolbarGroup>
      </AppToolbar>
      {downloadState === 'error' && (
        <Toast toasterId='globalTop' variant='error'>
          We couldn&rsquo;t start your download. Please try again.
        </Toast>
      )}
      {showPublishModal && (
        <PathwaysPublishModal
          onDismiss={() => setShowPublishModal(false)}
          onPublish={({ scenarioLabel, workspace, workspaceLabel }) => {
            toast(
              <PathwaysPublishSuccessNotice
                scenarioLabel={scenarioLabel}
                workspaceLabel={workspaceLabel}
                workspaceId={workspace.id || workspace}
              />,
              { variant: 'success' },
            );
            setShowPublishModal(false);
          }}
        />
      )}
    </>
  );
};

PathwaysToolbar.propTypes = propTypes;
PathwaysToolbar.defaultProps = defaultProps;

export { PathwaysToolbar };
