import { createSelector } from 'reselect';
import { get, pipe, find, split, defaultTo } from 'lodash/fp';
import attributesSelector from './attributesSelector';

const populationDatasetVersionSelector = createSelector(
  pipe(attributesSelector, get('selectors')),
  pipe(
    find({ resource: 'custom:dpie:population' }),
    get('provider'),
    split(':'),
    get(3),
    defaultTo('baseline'),
  ),
);

export default populationDatasetVersionSelector;
