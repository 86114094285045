import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Stack, InPlaceInput, Prompt, UtilityButton } from '@kinesis/bungle';
import { ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import { get, trim, pipe } from 'lodash/fp';
import { workspaceUpdate } from 'actions/workspaceUpdate';
import { workspaceUpdateOwner } from 'actions/workspaceUpdateOwner';
import organisationsSelector from 'selectors/organisationsSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import useWorkspacePrompts from 'hooks/useWorkspacePrompts';
import { BoardChooser } from 'components/board-chooser';
import { AppToolbar } from 'components/app-header';
import { IconMenuItem } from 'components/icon-menu-item';
import { OrganisationDropdown } from 'components/organisation-dropdown';
import GettingStarted from 'components/workspace/GettingStarted';
import NamedScenarioChooser from 'components/named-scenarios/NamedScenarioChooser';
import Layout from 'components/layout';
import { LegacyContent } from 'components/legacy-content';
import { useSelectorWithProps, useWorkspaceId } from 'hooks';
import usePreference from 'hooks/usePreference';
import { ViewOnlyIndicator } from 'components/view-only-indicator';
import { AppListSection } from 'components/app-list-section';
import WorkspaceSharingModal from 'components/workspace-sharing/WorkspaceSharingModal';
import { Separator } from 'components/separator';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import { ToolboxWrapper } from 'components/toolbox/toolbox-wrapper';

const StyledContent = styled(LegacyContent)({
  paddingBottom: 24,
  overflow: 'visible',
});

const WorkspacePane = styled.div`
  background: white;
  flex: 0 0 304px;
  width: 304px;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
`;

const Workspace = () => {
  const dispatch = useDispatch();
  const workspaceId = useWorkspaceId();
  const organisations = useSelectorWithProps(organisationsSelector, {
    workspaceId,
  });
  const workspace = useSelectorWithProps(pipe(workspaceSelector, get('data')), {
    workspaceId,
  });
  const description = get('description', workspace);
  const editor = get(['permissions', 'editor'], workspace);
  const name = get('name', workspace);
  const selectedOrganisationId = get('organisationId', workspace);

  const [isCurrentPrompt, showNextPrompt] = useWorkspacePrompts({
    workspaceId,
  });

  const handleNameChange = (value) => {
    const newName = trim(value);
    dispatch(
      workspaceUpdate({
        fields: { name: newName },
        workspaceId,
      }),
    );
    showNextPrompt();
  };

  const handleDescriptionChange = (value) =>
    dispatch(
      workspaceUpdate({
        fields: { description: trim(value) },
        workspaceId,
      }),
    );

  const [showWorkspaceSharingModal, setShowWorkspaceSharingModal] =
    useState(false);

  const toggleShowWorkspaceSharingModal = () =>
    setShowWorkspaceSharingModal(!showWorkspaceSharingModal);

  const location = useLocation();
  const { autoSelect = false } = location.state || {};

  const [hideGettingStarted, setHideGettingStarted] = usePreference(
    'marathon:workspaces:hide-getting-started',
    false,
  );

  const handleUpdateOrganisationId = (newOrgId) => {
    dispatch(workspaceUpdateOwner({ owner: newOrgId, workspaceId }));
  };

  return (
    <>
      <AppToolbar>
        <ToolbarGroup>
          <ToolbarItem>
            {editor ? (
              <UtilityButton onClick={toggleShowWorkspaceSharingModal}>
                Share
              </UtilityButton>
            ) : (
              <ViewOnlyIndicator />
            )}
          </ToolbarItem>
        </ToolbarGroup>
      </AppToolbar>
      <Layout>
        <WorkspacePane>
          <ToolboxWrapper arrangment='left' width='100%'>
            <ToolboxPanel>
              <StyledContent>
                <Stack space='medium'>
                  <Prompt
                    disabled={!editor}
                    dismissOnKeyPress={false}
                    onDismiss={showNextPrompt}
                    placement='right'
                    title='Give this workspace a name'
                    showPrompt={isCurrentPrompt('name')}
                  >
                    <div data-testid='workspace-name'>
                      <InPlaceInput
                        autoFocus={autoSelect}
                        disabled={!editor}
                        onChange={handleNameChange}
                        placeholder='Workspace name'
                        value={name}
                        variant='title'
                      />
                    </div>
                  </Prompt>
                  <InPlaceInput
                    onChange={handleDescriptionChange}
                    disabled={!editor}
                    placeholder='Write a description'
                    value={description}
                  />
                  <IconMenuItem icon='building-filled'>
                    <OrganisationDropdown
                      editor={editor}
                      value={selectedOrganisationId}
                      message='The owner organisation will affect the apps available for use in this workspace.'
                      organisations={organisations}
                      onSelect={handleUpdateOrganisationId}
                    />
                  </IconMenuItem>
                </Stack>
              </StyledContent>
              <AppListSection
                editor={editor}
                showPrompt={isCurrentPrompt('apps')}
                showNextPrompt={showNextPrompt}
                workspaceId={workspaceId}
              />
            </ToolboxPanel>
          </ToolboxWrapper>
        </WorkspacePane>
        <Layout direction='column'>
          {!hideGettingStarted && (
            <>
              <GettingStarted onDismiss={() => setHideGettingStarted(true)} />
              <Separator />
            </>
          )}
          <NamedScenarioChooser
            editor={editor}
            showPrompt={isCurrentPrompt('locations')}
            workspaceId={workspaceId}
          />
          <Separator />
          <BoardChooser editor={editor} workspaceId={workspaceId} />
        </Layout>
      </Layout>
      {showWorkspaceSharingModal && (
        <WorkspaceSharingModal
          handleClose={toggleShowWorkspaceSharingModal}
          workspaceId={workspaceId}
        />
      )}
    </>
  );
};

export { Workspace };
