import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { locationRename } from 'actions/locationRename';
import { actions as locationsActions } from 'reducers/locationsReducer';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  InPlaceInput,
  UtilityButton,
} from '@kinesis/bungle';
import { useScenarioId } from 'hooks';

const propTypes = {
  editor: PropTypes.bool,
  id: PropTypes.number.isRequired,
  label: PropTypes.string,
  layer: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  editor: false,
  label: '',
};

const InputWithAction = styled.div`
  display: flex;
`;

const InPlaceInputWrapper = styled.div`
  flex: 0 1 100%;
  min-width: 0;
  margin-top: 1px;
  margin-bottom: -1px;
`;

const MenuWrapper = styled('div')`
  height: 24px;
  margin-left: 8px;
  margin-top: -1px;
  margin-bottom: -1px;
`;

const LocationDetailSection = ({ editor, id, label, layer, workspaceId }) => {
  const dispatch = useDispatch();
  const scenarioId = useScenarioId();
  const handleSubmit = useCallback(
    (value) => {
      if (value !== label) {
        dispatch(
          locationRename({
            locationId: id,
            label: value,
            layer,
            workspaceId,
          }),
        );
      }
    },
    [dispatch, id, label, layer, workspaceId],
  );
  const handleRemove = useCallback(() => {
    dispatch(
      locationsActions.removeLocation({
        locationId: id,
        scenarioId,
        workspaceId,
      }),
    );
  }, [dispatch, id, scenarioId, workspaceId]);

  return (
    <InputWithAction>
      <InPlaceInputWrapper>
        <InPlaceInput
          disabled={!editor}
          onChange={handleSubmit}
          placeholder='Location name'
          value={label}
          variant='title'
        />
      </InPlaceInputWrapper>
      <MenuWrapper>
        <DropdownMenu justify='end'>
          <DropdownMenuButton
            as={UtilityButton}
            disabled={!editor}
            icon='ellipsis'
            magnitude='small'
          />
          <DropdownMenuList>
            <DropdownMenuItem onSelect={handleRemove}>
              Remove from scenario
            </DropdownMenuItem>
          </DropdownMenuList>
        </DropdownMenu>
      </MenuWrapper>
    </InputWithAction>
  );
};

LocationDetailSection.propTypes = propTypes;
LocationDetailSection.defaultProps = defaultProps;

export default LocationDetailSection;
