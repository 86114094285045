import { useEffect, useRef } from 'react';
import { ToolbarGroupRight } from '@kinesis/bungle/legacy';
import useAppHeaderContext from './useAppHeaderContext';
import { InlineToolbar } from './app-header.styles';

const AppToolbarPortal = () => {
  const portalNodeRef = useRef();
  const { setAppToolbarPortalNode } = useAppHeaderContext();

  useEffect(() => {
    setAppToolbarPortalNode(portalNodeRef.current);
  }, [setAppToolbarPortalNode]);

  return (
    <ToolbarGroupRight>
      <InlineToolbar ref={portalNodeRef} />
    </ToolbarGroupRight>
  );
};

export default AppToolbarPortal;
