import { concat, get, map, max, reduce, set, size } from 'lodash/fp';

import { rowBreak } from 'utils/blockVersions';

const COLUMNS_PER_ROW = 6;
const EXPLICIT_ROW_BREAK_TYPES = ['row-break'];
const IMPLICIT_ROW_BREAK_TYPES = ['section'];

const setSpanForBlocks = (row) => {
  const withSpans = map(set('span', COLUMNS_PER_ROW / row.length), row);
  if (withSpans.length > 0) {
    withSpans[0].isFirst = true;
    withSpans[withSpans.length - 1].isLast = true;
  }
  return withSpans;
};

export const blocksToSpanBlocks = (blocks, isFullSize) => {
  const maxBlocksPerRow = isFullSize ? 3 : 1;
  const empty = [[], []];
  const reducer = ([current, acc], block) => {
    if (EXPLICIT_ROW_BREAK_TYPES.includes(block.blockType))
      return [[], [...acc, ...setSpanForBlocks(current)]];
    if (IMPLICIT_ROW_BREAK_TYPES.includes(block.blockType))
      return [
        [],
        [...acc, ...setSpanForBlocks(current), ...setSpanForBlocks([block])],
      ];
    if (current.length >= maxBlocksPerRow)
      return [[block], [...acc, ...setSpanForBlocks(current)]];
    return [[...current, block], acc];
  };
  const [last, rest] = reduce(reducer, empty, blocks);
  const layout = [...rest, ...setSpanForBlocks(last)];
  return layout;
};

export const trimBlock = (b) => ({
  id: b.id,
  blockType: get(['capsule', 'type'], b),
  visualisation: get(['capsule', 'visualisation'], b),
  height: get(['capsule', 'height'], b),
  title: get(['capsule', 'title'], b),
});

export const blocksToBlockRows = (blocks, isFullSize) => {
  const maxBlocksPerRow = isFullSize ? 3 : 1;
  const rows = [];
  let rowIndex = 0;

  blocks.forEach((block) => {
    if (get('blockType', block) !== 'row-break') {
      if (
        get('blockType', block) === 'section' &&
        rows[rowIndex] &&
        rows[rowIndex].length > 0
      ) {
        rowIndex += 1;
      }
      if (rows[rowIndex] && rows[rowIndex].length >= maxBlocksPerRow) {
        rowIndex += 1;
      }
      (rows[rowIndex] || (rows[rowIndex] = [])).push(block);
      if (get('blockType', block) === 'section') {
        rowIndex += 1;
      }
    } else {
      if (!rows[rowIndex] || !rows[rowIndex].length) {
        return;
      }

      rowIndex += 1;
    }
  });

  return rows.map((rowBlocks, index) => ({
    index: `block-row-${index}`,
    blocks: rowBlocks,
    span: max([2, 6 / size(rowBlocks)]),
  }));
};

export const calculateBlockHeight = (block, blocks) => {
  const onlyBlockInRow = size(blocks) === 1;
  const blockType =
    block.blockType === 'editable-visualisation'
      ? block.visualisation
      : block.blockType;
  if (['text', 'empty', 'pathway-input'].includes(blockType)) return undefined;

  return block.height || (onlyBlockInRow ? 500 : 180);
};

export const addRowBreaks = (blocks) => {
  let sequentialBlocks = 0;
  let newBlocks = [];

  return blocks.reduce((accum, block) => {
    if (get('version', block) === rowBreak) {
      sequentialBlocks = 0;
      return concat(accum, block);
    }

    newBlocks =
      sequentialBlocks === 3 ? [{ version: rowBreak }, block] : [block];
    sequentialBlocks = (sequentialBlocks % 3) + 1;

    return concat(accum, newBlocks);
  }, []);
};
