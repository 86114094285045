import { useMatch } from 'react-router-dom';

function useBoardId() {
  const match = [
    useMatch('/embed/:workspaceId/boards/:boardId/*'),
    useMatch('/public/:workspaceId/boards/:boardId/*'),
    useMatch('/workspaces/:workspaceId/boards/:boardId/*'),
  ].find((matchObj) => matchObj);

  const boardId = parseInt(match?.params?.boardId, 10);

  return !Number.isNaN(boardId) ? boardId : undefined;
}

export { useBoardId };
