import styled from 'styled-components';

const Header = styled.header`
  padding-bottom: ${({ collapsed }) => (collapsed ? 0 : 16)}px;
  color: ${({ theme }) => theme.color.gray7};
  display: flex;
  flex-direction: row;
  align-items: center;
  :hover {
    cursor: ${({ collapsible }) => (collapsible ? 'pointer' : 'default')};
  }
  h5 {
    margin: 0;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    line-height: 18px;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    letter-spacing: 0.15px;
  }
`;

const Block = styled.section`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: ${({ collapsed, titleOnly }) =>
    collapsed || titleOnly ? '16' : '24'}px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  :last-of-type {
    border-bottom: ${({ theme, collapsed }) =>
      collapsed ? `1px solid ${theme.borderColor}` : 'none'};
  }
`;

const Collapse = styled.span`
  color: ${({ theme }) => theme.color.gray6};
  padding-right: 8px;
  font-size: ${({ theme }) => theme.fontSize.default}px;
`;

export { Block, Header, Collapse };
