import styled from 'styled-components';
import { borderRadius } from 'polished';

const ModalHeader = styled.header`
  ${borderRadius('top', 'inherit')}
  box-sizing: content-box;
  height: 48px;
  flex: 0 0 auto;
  align-items: center;
  display: flex;
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

const ModalHeading = styled.span`
  display: flex;
  flex: 1 1 auto;
  padding: 0 16px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  line-height: ${({ theme }) => theme.lineHeight.lg};
  color: ${({ theme }) => theme.color.gray9};
`;

export { ModalHeader, ModalHeading };
