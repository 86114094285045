import { createSelector } from 'reselect';
import { get, map, pipe, placeholder } from 'lodash/fp';
import blocksSelector from 'selectors/blocksSelector';
import workspaceSelector from 'selectors/workspaceSelector';

const multiBlockTimeSelector = createSelector(
  (state, props) => props?.public,
  blocksSelector,
  pipe(workspaceSelector, get(['time', 'blocks'])),
  pipe(workspaceSelector, get(['aggregationFilters', 'blocks'])),
  (isPublic, blocks, blockTimes, blockAggregationFilters) =>
    map(
      pipe(
        get('id'),
        get(placeholder, isPublic ? blockTimes : blockAggregationFilters),
      ),
      blocks,
    ),
);

export default multiBlockTimeSelector;
