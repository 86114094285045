import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { capitalize, get, map, size } from 'lodash/fp';
import {
  Tab,
  TabBar,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@kinesis/bungle';
import toolboxSelector from 'selectors/toolboxSelector';
import {
  toolboxDetailsSelector,
  toolboxSpatialDetailIdSelector,
} from 'selectors/toolboxDetailSelectors';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import { ToolboxWrapper } from 'components/toolbox/toolbox-wrapper';
import {
  AppPane,
  BlockPane,
  BoardDetailsPane,
  ChartDetailsPane,
  DatasetPane,
  DatasetDraftPane,
  LocationPane,
  PublicBoardPane,
  ScenarioPane,
  SpatialVisualisationDetailsPane,
} from 'components/toolbox/panes';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import {
  useBlockId,
  usePublicWorkspaceId,
  useSelectedBlockLabel,
  useWorkspaceId,
} from 'hooks';
import useAction from 'hooks/useAction';

const typeOf = get('type');
const selectionTypeOf = get(['selection', 'type']);

const propTypes = {
  privacy: PropTypes.oneOf(['private', 'public']),
};

const defaultProps = {
  privacy: 'private',
};

const Toolbox = ({ privacy }) => {
  const { activePane, panes } = useSelector(toolboxSelector);
  const selection = useSelector(toolboxDetailsSelector);
  const workspaceId = (
    privacy === 'public' ? usePublicWorkspaceId : useWorkspaceId
  )();
  const maximisedBlockId = useBlockId();
  const selectedBlockIds = useSelector(blockSelectionsSelector);
  const blockId = maximisedBlockId ?? selectedBlockIds[0];
  const selectedBlockLabel = useSelectedBlockLabel({ privacy, workspaceId });

  const getDetailId = useCallback(
    (type) => (get('type', selection) === type ? selection.id : undefined),
    [selection],
  );
  const locationId = useMemo(() => getDetailId('location'), [getDetailId]);
  const spatialDetailId = useSelector(toolboxSpatialDetailIdSelector);
  const chartDetailId = useMemo(
    () => getDetailId('chart-detail'),
    [getDetailId],
  );

  const renderTabs = useMemo(() => {
    // TODO: Find a way to not have to define these up front but still have animations working
    const allPaneTypes = [
      'detail',
      'block',
      'context',
      'dataset-draft',
      'dataset',
      'app',
    ]; // these could actually be anything as long as they're consistent
    const visiblePaneTypes = map('type', panes);

    const tabs = allPaneTypes.map((paneType) => {
      if (visiblePaneTypes.includes(paneType)) {
        const pane = panes.find((p) => p.type === paneType);

        return (
          <Tab key={paneType} tabKey={paneType}>
            {capitalize(
              paneType === 'block' ? selectedBlockLabel : pane?.label,
            )}
          </Tab>
        );
      }

      return null;
    });

    return <TabList>{tabs}</TabList>;
  }, [panes, selectedBlockLabel]);

  const renderPanes = useMemo(
    () =>
      map((pane) => {
        const type = typeOf(pane);
        if (type === 'detail') {
          const selectionType = selectionTypeOf(pane);
          return (
            <TabPanel key='detail' tabKey='detail'>
              {selectionType === 'location' && (
                <LocationPane
                  locationId={locationId}
                  privacy={privacy}
                  workspaceId={workspaceId}
                />
              )}
              {selectionType === 'spatial-detail' && (
                <SpatialVisualisationDetailsPane
                  blockId={blockId}
                  locationId={spatialDetailId}
                  privacy={privacy}
                  workspaceId={workspaceId}
                />
              )}
              {selectionType === 'chart-detail' && (
                <ChartDetailsPane
                  blockId={blockId}
                  privacy={privacy}
                  value={chartDetailId}
                  workspaceId={workspaceId}
                />
              )}
            </TabPanel>
          );
        }

        if (type === 'block') {
          return (
            <TabPanel key='block' tabKey='block'>
              <BlockPane privacy={privacy} workspaceId={workspaceId} />
            </TabPanel>
          );
        }

        if (type === 'dataset-draft') {
          return (
            <TabPanel key='dataset-draft' tabKey='dataset-draft'>
              <DatasetDraftPane workspaceId={workspaceId} />
            </TabPanel>
          );
        }

        if (type === 'dataset') {
          return (
            <TabPanel key='dataset' tabKey='dataset'>
              <DatasetPane privacy={privacy} workspaceId={workspaceId} />
            </TabPanel>
          );
        }

        if (type === 'app') {
          return (
            <TabPanel key='app' tabKey='app'>
              <AppPane />
            </TabPanel>
          );
        }

        if (type === 'context') {
          const selectionType = selectionTypeOf(pane);
          return (
            <TabPanel key='context' tabKey='context'>
              {selectionType === 'public-board' && (
                <PublicBoardPane workspaceId={workspaceId} />
              )}
              {selectionType === 'board' && (
                <BoardDetailsPane workspaceId={workspaceId} />
              )}
              {selectionType === 'scenario' && (
                <ScenarioPane privacy={privacy} workspaceId={workspaceId} />
              )}
            </TabPanel>
          );
        }
      }, panes),
    [
      blockId,
      chartDetailId,
      locationId,
      panes,
      privacy,
      spatialDetailId,
      workspaceId,
    ],
  );

  const handleSelectPane = useAction(toolboxActions.selectPane);

  return (
    <ToolboxWrapper arrangement='right' width={305}>
      {size(panes) > 0 && (
        <Tabs
          activeKey={activePane}
          onChange={(pane) => handleSelectPane(pane)}
        >
          <TabBar>{renderTabs}</TabBar>
          <TabPanels>{renderPanes}</TabPanels>
        </Tabs>
      )}
    </ToolboxWrapper>
  );
};

Toolbox.propTypes = propTypes;
Toolbox.defaultProps = defaultProps;

export { Toolbox };
