import { createSelector } from 'reselect';
import { get, keys, pipe } from 'lodash/fp';
import Units from 'data/units';
import filterableColumnsSelector from 'selectors/filterableColumnsSelector';
import { immutableSort } from 'utils/charts/series';
import blockStateSelector from './blockStateSelector';
import locationsSelector from './locationsSelector';
import workspaceInstantiationsSelector from './workspaceInstantiationsSelector';
import statisticsSelector from './statisticsSelector';

const units = new Units();

const visualisationDimensionsSelector = createSelector(
  pipe(blockStateSelector, get('filters')),
  filterableColumnsSelector,
  locationsSelector,
  statisticsSelector,
  workspaceInstantiationsSelector,
  (filters, filterableColumns, locations, statistics, instantiations) => {
    if (!filters) {
      return [];
    }

    return filterableColumns.map((sch) => {
      const unit = units.parseColumn(sch, locations, instantiations);
      const itemData = get(sch.key, statistics);

      return {
        encoding: sch.encoding,
        format: unit.formatCell,
        key: sch.key,
        label: sch.label,
        max: get('max', itemData),
        min: get('min', itemData),
        options: immutableSort(unit.compare, keys(get('histogram', itemData))),
        symbol: unit.symbol,
        type: unit.filter,
        value: filters[sch.key],
      };
    });
  },
);

export default visualisationDimensionsSelector;
