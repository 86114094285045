import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu } from '@kinesis/bungle';
import enablePopulationResourceSelector from 'selectors/enablePopulationResourceSelector';
import { PopulationInputDataModal } from 'components/modals/population-input-data';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import { get } from 'lodash/fp';
import { useSelectorWithProps } from 'hooks';

const propTypes = {
  editor: PropTypes.bool,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  editor: false,
  privacy: 'private',
};

const ResourceSection = ({ editor, privacy, workspaceId }) => {
  const isPatchingWorkspace = useSelector(get(['misc', 'isPatchingWorkspace']));
  const enablePopulation = useSelectorWithProps(
    enablePopulationResourceSelector,
    {
      public: privacy === 'public',
      workspaceId,
    },
  );
  const [showPopulation, setShowPopulation] = useState(false);
  const openPopulation = useCallback(() => setShowPopulation(true), []);
  const closePopulation = useCallback(() => setShowPopulation(false), []);

  if (!enablePopulation) return null;

  return (
    <ToolboxSection collapsible title='Resources'>
      <Menu>
        <Menu.Item
          disabled={!editor || isPatchingWorkspace}
          key='population-input'
          icon='setting'
          content='Population input data'
          onClick={openPopulation}
        />
      </Menu>
      {showPopulation && (
        <PopulationInputDataModal
          onClose={closePopulation}
          workspaceId={workspaceId}
        />
      )}
    </ToolboxSection>
  );
};

ResourceSection.propTypes = propTypes;
ResourceSection.defaultProps = defaultProps;

export { ResourceSection };
