import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/popover/Popover';
import DataPopover from 'components/popover/DataPopover';

const propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  title: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  unit: PropTypes.object.isRequired,
  color: PropTypes.string,
  opacity: PropTypes.number,
};
const defaultProps = {
  title: undefined,
  label: undefined,
  color: undefined,
  opacity: undefined,
};
const ChoroplethTooltip = ({
  x,
  y,
  title,
  label,
  value,
  unit,
  color,
  opacity,
}) => {
  const content = useMemo(
    () => [
      {
        label: label || 'Value',
        value: unit ? unit.format(value) : value,
        color,
        opacity,
      },
    ],
    [label, value, unit, color, opacity],
  );
  return (
    <Popover pointerX={x} pointerY={y} disablePointerEvents>
      <DataPopover disablePointerEvents content={content} title={title} />
    </Popover>
  );
};

ChoroplethTooltip.propTypes = propTypes;
ChoroplethTooltip.defaultProps = defaultProps;

export { ChoroplethTooltip };
