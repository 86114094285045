import { createSelector } from 'reselect';
import { get, pipe, identity } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const progressSelector = createSelector(
  pipe(workspaceSelector, get('progress')),
  identity,
);

export default progressSelector;
