import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';
import { Select } from '@kinesis/bungle';
import { useScenarioId, useSelectorWithProps } from 'hooks';
import baselineScenarioSelector from 'selectors/baselineScenarioSelector';
import DecoratedScenarioName from 'components/workspace/DecoratedScenarioName';
import sortedScenariosSelector from 'selectors/sortedScenariosSelector';

const Wrapper = styled.div`
  width: 208px;
`;

const propTypes = {
  onScenarioSwitch: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const ScenarioSwitcher = ({ onScenarioSwitch, privacy, workspaceId }) => {
  const scenarios = useSelectorWithProps(sortedScenariosSelector, {
    public: privacy === 'public',
    workspaceId,
  });
  const scenarioId = useScenarioId();
  const { id: baselineScenarioId } = useSelectorWithProps(
    baselineScenarioSelector,
    {
      public: privacy === 'public',
      workspaceId,
    },
  );

  if (!scenarioId) {
    return <Navigate to={`?scenario=${baselineScenarioId}`} />;
  }

  return (
    <Wrapper>
      <Select
        onChange={onScenarioSwitch}
        options={scenarios.map((s) => ({
          value: s.id,
          label: s.name,
          isDefault: s.isDefault,
          isDraft: !!s.draft,
        }))}
        renderItem={(item) => (
          <DecoratedScenarioName
            name={item.label}
            isDefault={item.isDefault}
            isDraft={false}
          />
        )}
        value={scenarioId ?? baselineScenarioId}
      />
    </Wrapper>
  );
};

ScenarioSwitcher.propTypes = propTypes;
ScenarioSwitcher.defaultProps = defaultProps;

export { ScenarioSwitcher };
