import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import Circle from 'components/circle/Circle';
import layerColorSelector from 'selectors/layerColorSelector';
import useTheme from 'hooks/useTheme';
import useSelectorWithProps from 'hooks/useSelectorWithProps';

const propTypes = {
  enabled: PropTypes.bool,
  layerId: PropTypes.number.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  workspaceId: PropTypes.number,
};

const defaultProps = {
  enabled: true,
  privacy: 'private',
  workspaceId: undefined,
};

const LayerCircle = ({ enabled, layerId, privacy, workspaceId }) => {
  const theme = useTheme();
  const color = useSelectorWithProps(layerColorSelector, {
    layerId,
    public: privacy === 'public',
    workspaceId,
  });

  return (
    <Circle
      color={get(['color', 'layer', color, 'fill'], theme)}
      enabled={enabled}
    />
  );
};

LayerCircle.propTypes = propTypes;
LayerCircle.defaultProps = defaultProps;

export default LayerCircle;
