import PropTypes from 'prop-types';
import { Tooltip, UtilityButton, Strong } from '@kinesis/bungle';
import { Link } from 'react-router-dom';
import { map } from 'lodash/fp';
import {
  ScenarioName,
  PathwayLinkContainer,
  Row,
  RowItem,
} from './pathway-input-block.styles';

const mapWithIndex = map.convert({ cap: false });

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      scenario: PropTypes.number.isRequired,
      pathway_url_params: PropTypes.string.isRequired,
    }),
  ).isRequired,
  scenarioFormat: PropTypes.func.isRequired,
};
const defaultProps = {};

const Headers = ({ data, scenarioFormat }) => (
  <Row withBottomBorder>
    <RowItem header />
    {mapWithIndex(
      (datum, index) => (
        <RowItem header lastColumn={data.length === index + 1}>
          <ScenarioName>
            <Strong>{scenarioFormat(datum.scenario)}</Strong>
          </ScenarioName>
          <PathwayLinkContainer>
            <Tooltip placement='top' title='Open in Pathways'>
              <UtilityButton
                magnitude='small'
                icon='waterfall'
                as={Link}
                to={`/pathways/${datum.pathway_url_params}`}
              />
            </Tooltip>
          </PathwayLinkContainer>
        </RowItem>
      ),
      data,
    )}
  </Row>
);

Headers.propTypes = propTypes;
Headers.defaultProps = defaultProps;

export { Headers };
