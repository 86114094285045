import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import {
  measureOptions,
  measureOptionsOfType,
} from 'utils/visualisationConfigUtils';

const visualisationMeasureOptionsSelector = createSelector(
  (state, props = {}) => props.tableRules,
  (state, props = {}) => get(['resources', props.table], state),
  (state, props = {}) => props.allOfType,
  (rules, table, allOfType) =>
    allOfType
      ? measureOptionsOfType(rules, table, allOfType)
      : measureOptions(rules, table),
);

export default visualisationMeasureOptionsSelector;
