import { useCallback, useState, useRef } from 'react';
import pluralize from 'pluralize';
import { map, pipe, sum } from 'lodash/fp';
import PropTypes from 'prop-types';
import { AnimatedPopover, UtilityButton } from '@kinesis/bungle';
import { LoadingIcon } from 'components/loading-icon';
import { ProcessingStatusPane } from 'components/processing-status-pane';

const propTypes = {
  defaultOpen: PropTypes.bool,
  processing: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      isBaseline: PropTypes.bool,
      complete: PropTypes.number.isRequired,
    }),
  ),
  total: PropTypes.number,
};

const defaultProps = {
  defaultOpen: false,
  processing: [],
  total: 0,
};

const ProcessingStatus = ({ processing, total, defaultOpen }) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const toggle = useCallback(() => setIsOpen((o) => !o), [setIsOpen]);
  const remaining = pipe(
    map(({ complete }) => total - complete),
    sum,
  )(processing);
  return (
    <>
      <UtilityButton
        icon={<LoadingIcon magnitude='small' />}
        ref={ref}
        dropdown
        magnitude='small'
        onClick={toggle}
      >
        Processing {remaining} {pluralize('outputs', remaining)}
      </UtilityButton>
      <AnimatedPopover
        justify='end'
        placement='bottom'
        offset={4}
        open={isOpen}
        targetRef={ref}
      >
        <ProcessingStatusPane processing={processing} total={total} />
      </AnimatedPopover>
    </>
  );
};

ProcessingStatus.propTypes = propTypes;
ProcessingStatus.defaultProps = defaultProps;

export { ProcessingStatus };
