import { createSelector } from 'reselect';
import { get, mapValues, pipe } from 'lodash/fp';
import blockStateSelector from 'selectors/blockStateSelector';

const blockDataByScenarioSelector = createSelector(
  pipe(blockStateSelector, get('scenarios')),
  mapValues(get('data')),
);

export default blockDataByScenarioSelector;
