import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { format, parseISO } from 'date-fns/fp';
import {
  Avatar,
  Card,
  CardBanner,
  CardButton,
  CardContent,
  CardSubtitle,
  CardTitle,
  Tooltip,
} from '@kinesis/bungle';
import useDateDistance from 'hooks/useDateDistance';

const propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
  }).isRequired,
  updated: PropTypes.string.isRequired,
};

const defaultProps = {};

const AppCard = forwardRef(({ id, label, owner, updated }, ref) => {
  const updatedDate = useMemo(
    () => (updated ? parseISO(updated) : undefined),
    [updated],
  );
  const updatedDistance = useDateDistance(updatedDate);
  return (
    <Card ref={ref}>
      <CardButton as={Link} to={`/apps/${id}`}>
        <CardBanner>
          <Tooltip placement='top' title={get('name', owner)}>
            <Avatar
              alt={get('name', owner)}
              appearance='outlined'
              image={get('avatarUrl', owner)}
              magnitude='large'
              variant='organisation'
            >
              {owner && get('name', owner).slice(0, 1).toUpperCase()}
            </Avatar>
          </Tooltip>
        </CardBanner>
        <CardContent noWrap>
          <CardTitle>{label}</CardTitle>
          {updated && (
            <Tooltip title={format('d MMM yyyy, h:mm a', updatedDate)}>
              <CardSubtitle>Last updated {updatedDistance}</CardSubtitle>
            </Tooltip>
          )}
        </CardContent>
      </CardButton>
    </Card>
  );
});

AppCard.propTypes = propTypes;
AppCard.defaultProps = defaultProps;
export { AppCard };
