import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

const fullStorySelector = createSelector(
  get('auth'),
  get(['publicTokens', 'entities']),
  (
    { analytics, emailAddress, firstName, lastName, userId },
    { fullStoryOrgId },
  ) => ({
    analytics,
    emailAddress,
    firstName,
    fullStoryOrgId,
    lastName,
    userId,
  }),
);

export default fullStorySelector;
