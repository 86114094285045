import { nanoid } from 'nanoid';
import {
  every,
  flatMap,
  get,
  intersection,
  isEmpty,
  isNil,
  map,
  pipe,
} from 'lodash/fp';
import { flatten } from 'data/attributes';

const itemisedAttribute = (operator, operand, attributeType) => ({
  type: 'itemisedAttribute',
  key: nanoid(),
  operator,
  operand,
  attributeType,
});

const and = (operand) => ({ type: 'and', key: nanoid(), operand });

const compile = (expression) =>
  ({
    and,
    itemisedAttribute,
  })[expression.type].compile(expression);

itemisedAttribute.compile = (expression) => {
  switch (expression.operator) {
    case 'any':
      return (location) => {
        const attribute = get(
          ['attributes', expression.attributeType],
          location,
        );
        if (!attribute) return false;
        const entries = pipe(
          get('time_series'),
          flatMap(flatten),
          map('key'),
        )(attribute);
        if (isNil(expression.operand)) {
          return !isEmpty(entries);
        }
        return !isEmpty(intersection(entries, expression.operand));
      };
    default:
  }
};

and.compile = (expression) => {
  const expressions = map(compile, expression.operand);
  return (element) => every((e) => e(element), expressions);
};

export { and, compile, itemisedAttribute };
