import PropTypes from 'prop-types';
import { Stack, Select, LabelText } from '@kinesis/bungle';
import { labelOfMeasure } from 'data/block';
import {
  take,
  remove,
  find,
  isEmpty,
  every,
  get,
  map,
  filter,
} from 'lodash/fp';
import { useRef, useState, useCallback, useMemo } from 'react';
import {
  itemsFilter,
  labelOfMeasureOrBreakdown,
  groupKeyOf,
} from 'data/block/visualisation/configuration/series-select';
import {
  Row,
  Separator,
  InputContainer,
} from './visualisation-series-select.styles';
import { BreakdownLabel } from './breakdown-label';
import { DeleteSeriesButton } from './delete-series-button';
import { AddSeriesButton } from './add-series-button';

const propTypes = {
  defaultExpanded: PropTypes.number,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  unit: PropTypes.string,
};

const defaultProps = {
  defaultExpanded: undefined,
  unit: undefined,
};

const itemsRender = (item) => {
  const label = labelOfMeasureOrBreakdown(item);
  switch (item.type) {
    case 'expression':
      return label;
    case 'breakdown':
      return <BreakdownLabel label={label} />;
    default:
      return undefined;
  }
};

const mapWithIndex = map.convert({ cap: false });

const MultiExpression = ({
  defaultExpanded,
  label,
  options,
  series,
  onChange,
  onDelete,
  unit,
}) => {
  const expressions = useMemo(
    () => filter((o) => o.type === 'expression', options),
    [options],
  );
  const [addingSeries, setAddingSeries] = useState(false);
  const newSeriesRef = useRef();
  const addNewSeries = useCallback(() => {
    if (newSeriesRef.current) {
      newSeriesRef.current.focus();
    }
    setAddingSeries(true);
  }, [setAddingSeries]);
  const removeNewSeries = useCallback(
    () => setAddingSeries(false),
    [setAddingSeries],
  );
  const allOptionsUsed = useMemo(
    () =>
      !isEmpty(expressions) &&
      every((option) => find(option.value, series), expressions),
    [series, expressions],
  );
  const handleOnChangeAtSlot = useCallback(
    (position) => (v) => {
      removeNewSeries();
      return onChange({ position, value: v });
    },
    [onChange, removeNewSeries],
  );
  const expressionsWithoutSelections = useMemo(
    () => remove((o) => find(o.value, series), expressions),
    [expressions, series],
  );
  const indexedOptions = useMemo(
    () =>
      mapWithIndex((s, index) => {
        if (index === 1) {
          return remove((o) => find(o.value, take(index, series)), options);
        }
        return remove((o) => find(o.value, take(index, series)), expressions);
      }, series),
    [expressions, options, series],
  );

  return (
    <Stack space='xsmall'>
      <LabelText>{label}</LabelText>
      {mapWithIndex(
        (s, index) => (
          <Row id='A'>
            <InputContainer>
              <Select
                defaultExpanded={defaultExpanded}
                itemKey='key'
                groupKey={groupKeyOf(indexedOptions[index])}
                value={s}
                onChange={handleOnChangeAtSlot(index)}
                options={indexedOptions[index]}
                search
                itemsLabel={
                  index === 1 && get('length', series) < 3
                    ? 'measure or breakdowns'
                    : 'measures'
                }
                overrideEmptyText={
                  index === 1
                    ? 'Select an output to view available measures or breakdowns.'
                    : 'Select an output to view available measures.'
                }
                magnitude='large'
                renderSelected={itemsRender}
                itemsFilter={itemsFilter}
              />
            </InputContainer>
            <Separator />
            <DeleteSeriesButton onClick={onDelete(index)} />
          </Row>
        ),
        series,
      )}
      {addingSeries && (
        <Row>
          <InputContainer>
            <Select
              defaultExpanded={false}
              itemKey='key'
              groupKey={groupKeyOf(expressionsWithoutSelections)}
              value={undefined}
              onChange={handleOnChangeAtSlot(series.length)}
              options={expressionsWithoutSelections}
              search
              itemsLabel='measures'
              overrideEmptyText='Select an output to view available measures.'
              magnitude='large'
              renderSelected={labelOfMeasure}
              itemsFilter={itemsFilter}
              ref={newSeriesRef}
            />
          </InputContainer>
          <Separator />
          <DeleteSeriesButton onClick={removeNewSeries} />
        </Row>
      )}
      <div>
        <AddSeriesButton
          tooltip={
            allOptionsUsed
              ? `All available ${unit} measures are shown`
              : undefined
          }
          disabled={allOptionsUsed}
          onClick={addNewSeries}
        />
      </div>
    </Stack>
  );
};

MultiExpression.propTypes = propTypes;
MultiExpression.defaultProps = defaultProps;

export { MultiExpression };
