import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Listbox } from '@kinesis/bungle';
import LayerCircle from 'components/layer-circle/LayerCircle';
import recentLocationsSelector from 'selectors/recentLocationsSelector';
import selectableLocationsSelector from 'selectors/selectableLocationsSelector';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { useSelectorWithProps } from 'hooks';
import useAction from 'hooks/useAction';
import { Item, Label } from './locations-search.styles';

const propTypes = {
  buttonRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  privacy: PropTypes.oneOf(['private', 'public']),
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  privacy: 'private',
};

const LocationsSearch = ({
  buttonRef,
  onClose,
  privacy,
  scenarioId,
  workspaceId,
}) => {
  const [isEmptyQuery, setEmptyQuery] = useState(true);
  const recentLocations = useSelectorWithProps(recentLocationsSelector, {
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });
  const selectableLocations = useSelectorWithProps(
    selectableLocationsSelector,
    {
      public: privacy === 'public',
      scenarioId,
      workspaceId,
    },
  );
  const select = useAction(toolboxActions.select);

  const recentOptions = useMemo(
    () => recentLocations.map((loc) => ({ ...loc, group: 'Recently viewed' })),
    [recentLocations],
  );

  const handleSelect = useCallback(
    (locationId) => {
      select({
        pane: 'detail',
        selection: { id: locationId, type: 'location' },
      });
    },
    [select],
  );

  return (
    <Listbox
      alwaysHighlight
      autoFocus
      buttonRef={buttonRef}
      closeOnBlur
      closeOnClickOutside
      closeOnSelect
      itemsLabel='locations'
      groupKey={isEmptyQuery ? 'group' : undefined}
      maxHeight={280}
      onChange={handleSelect}
      onClose={onClose}
      onSearch={(q) => setEmptyQuery(q === '')}
      options={isEmptyQuery ? recentOptions : selectableLocations}
      placeholder='Search locations…'
      renderItem={(loc) => (
        <Item>
          <LayerCircle layerId={loc.layer} workspaceId={workspaceId} />
          <Label>{loc.label}</Label>
        </Item>
      )}
      search
      valueKey='id'
      width={300}
    />
  );
};

LocationsSearch.defaultProps = defaultProps;
LocationsSearch.propTypes = propTypes;

export { LocationsSearch };
