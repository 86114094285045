import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { get, isNil } from 'lodash/fp';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  Heading,
  Tooltip,
  UtilityButton,
} from '@kinesis/bungle';
import { ToolbarGroup, ToolbarItem } from '@kinesis/bungle/legacy';
import blockValueSelector from 'selectors/blockValueSelector';
import blockTimeSelector from 'selectors/blockTimeSelector';
import { rawBlockTypes } from 'constants/block-types';
import { LoadingIcon } from 'components/loading-icon';
import { StaticVisualisation } from 'components/static-visualisation';
import { Visualisation } from 'components/visualisation';
import { PathwayInputBlock } from 'components/pathway-input-block';
import { BlockError } from 'components/block-error';
import { BlockSection } from 'components/block/block.section';
import { BlockText } from 'components/block/block.text';
import { useScenarioId, useSelectorWithProps, useWorkspaceId } from 'hooks';
import usePermission from 'hooks/usePermission';
import isBlockAwaitingAnswerSelector from 'selectors/isBlockAwaitingAnswerSelector';
import { title as pathwayInputBlockTitle } from 'data/block/pathway-input';

import { BlockContent, BlockToolbar, BlockWrapper } from './block.styles';

const propTypes = {
  dragHandleProps: PropTypes.object,
  id: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  removeBlocks: PropTypes.func,
  selected: PropTypes.bool,
  updatePlaceholderHeight: PropTypes.func,
};

const defaultProps = {
  dragHandleProps: undefined,
  isFirst: false,
  isLast: false,
  removeBlocks: undefined,
  selected: false,
  updatePlaceholderHeight: undefined,
};

const blockTypes = {
  visualisation: StaticVisualisation,
  'pathway-input': PathwayInputBlock,
  'editable-visualisation': Visualisation,
  section: BlockSection,
  text: BlockText,
};

const Block = ({
  dragHandleProps,
  id,
  isFirst,
  isLast,
  removeBlocks,
  selected,
  updatePlaceholderHeight,
}) => {
  const ref = useRef();
  const location = useLocation();
  const { block: maximisedBlockId } = useParams();
  const workspaceId = useWorkspaceId();
  const scenarioId = useScenarioId();
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId: id,
    workspaceId,
  });
  const blockTime = useSelectorWithProps(blockTimeSelector, {
    blockId: id,
    workspaceId,
  });
  const isBlockAwaitingAnswer = useSelectorWithProps(
    isBlockAwaitingAnswerSelector,
    {
      blockId: id,
      scenarioId,
      workspaceId,
    },
  );

  const { visualisation, type } = blockValue;
  const title = useMemo(() => {
    switch (type) {
      case 'pathway-input':
        return pathwayInputBlockTitle(blockValue);
      default:
        return get('title', blockValue);
    }
  }, [type, blockValue]);
  const viewMode = maximisedBlockId === id ? 'maximised' : 'minimised';
  const editor = usePermission('editor');

  const blockTypeAttr = useMemo(
    () => (type === 'editable-visualisation' ? visualisation : type),
    [type, visualisation],
  );

  const getToolbarVariant = () => {
    if (viewMode === 'maximised') return 'dark';
    if (blockTypeAttr === 'visualisation' && visualisation === 'spatial')
      return 'translucent';
    return 'light';
  };

  const getContentVariant = () => {
    if (
      viewMode === 'minimised' &&
      visualisation === 'spatial' &&
      blockTypeAttr === 'visualisation'
    )
      return 'stretch';
    if (viewMode === 'minimised' && blockTypeAttr === 'text') return 'content';
    if (viewMode === 'minimised' && blockTypeAttr === 'empty') return 'content';
    return 'default';
  };

  const getBlockVariant = () => {
    if (viewMode === 'minimised' && blockTypeAttr === 'text') return 'content';
    if (viewMode === 'minimised' && blockTypeAttr === 'empty') return 'content';
    return 'default';
  };

  const BlockImpl = useMemo(() => {
    if (
      type === 'visualisation' &&
      get(['chart', 'type'], blockValue) === 'dual-axis-stacked-column'
    )
      return BlockError;

    if (
      type === 'visualisation' &&
      get('visualisation', blockValue) === 'table'
    )
      return BlockError;

    return get(type, blockTypes);
  }, [type, blockValue]);

  const handleRemoveBlocks = () => {
    if (removeBlocks) {
      removeBlocks([id]);
    }
  };

  const onClickStopProp = (event) => {
    event.stopPropagation();
  };

  const changeViewURL = viewMode === 'minimised' ? `blocks/${id}` : '..';

  if (rawBlockTypes.includes(type)) {
    return (
      <BlockImpl
        blockId={id}
        viewMode={viewMode}
        isSelected={selected}
        removeBlocks={removeBlocks}
        isFirst={isFirst}
        isLast={isLast}
        workspaceId={workspaceId}
      />
    );
  }

  return (
    <BlockWrapper
      data-testid={`block-${id}`}
      ref={ref}
      variant={getBlockVariant()}
      selected={selected}
    >
      <BlockToolbar variant={getToolbarVariant()} {...dragHandleProps}>
        <ToolbarGroup>
          <ToolbarItem>
            <Heading as='h3' noWrap size='medium'>
              {title || 'New visualisation'}
              {isNil(blockTime) ? null : ` (${blockTime})`}
            </Heading>
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup>
          {isBlockAwaitingAnswer && (
            <ToolbarItem>
              <LoadingIcon magnitude='small' />
            </ToolbarItem>
          )}
          <ToolbarItem>
            <DropdownMenu justify='end'>
              <DropdownMenuButton
                disabled={!editor}
                stopPropagation
                as={UtilityButton}
                magnitude='small'
                data-testid='block-ellipsis-button'
                icon='ellipsis'
              />
              <DropdownMenuList>
                <DropdownMenuItem
                  disabled={!editor}
                  onClick={onClickStopProp}
                  onSelect={handleRemoveBlocks}
                  variant='danger'
                  data-testid='block-menu-delete'
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuList>
            </DropdownMenu>
          </ToolbarItem>
          <ToolbarItem flex={0}>
            <Tooltip
              placement='top'
              justify='end'
              title={viewMode === 'minimised' ? 'Maximise' : 'Minimise'}
            >
              <UtilityButton
                as={Link}
                data-testid='change-view-mode'
                icon={viewMode === 'minimised' ? 'expand' : 'shrink'}
                magnitude='small'
                stopPropagation
                to={`${changeViewURL}${location.search}`}
              />
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </BlockToolbar>
      <BlockContent
        variant={getContentVariant()}
        maximised={viewMode === 'maximised'}
      >
        <BlockImpl
          blockId={id}
          isSelected={selected}
          viewMode={viewMode}
          updatePlaceholderHeight={updatePlaceholderHeight}
          workspaceId={workspaceId}
        />
      </BlockContent>
    </BlockWrapper>
  );
};

Block.propTypes = propTypes;
Block.defaultProps = defaultProps;

export { Block };
