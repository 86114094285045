import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const workspaceShareReissue = createAsyncThunk(
  'workspace-share/reissue',
  async ({ token }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    const response = await api.patch(`/api/workspace-shares/reissue/${token}`);

    return response.data;
  },
);

export { workspaceShareReissue };
