import PropTypes from 'prop-types';
import { identity } from 'lodash/fp';
import pluralize from 'pluralize';

const propTypes = {
  count: PropTypes.number.isRequired,
  formatCount: PropTypes.func,
  inclusive: PropTypes.bool,
  word: PropTypes.string.isRequired,
};

const defaultProps = {
  formatCount: identity,
  inclusive: false,
};

const Plural = ({ count, formatCount, inclusive, word }) => (
  <>
    {inclusive && `${formatCount(count)}  `}
    {pluralize(word, count)}
  </>
);

Plural.propTypes = propTypes;
Plural.defaultProps = defaultProps;

export default Plural;
