import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { isEmpty } from 'lodash/fp';

const { selectBoard, selectBlock, selectBlocks, toggleBlock, resetBlocks } =
  blockSelectionsActions;

const resetToBoardPane = () => toolboxActions.resetTo('context');

function createBlockSelectionsMiddleware() {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      next(action);
      switch (action.type) {
        // Selections always empty.
        case resetBlocks.type: {
          dispatch(resetToBoardPane());
          break;
        }
        // Selections cleared, filled, or modified.
        case toggleBlock.type:
        case selectBoard.type:
        case selectBlocks.type: {
          const state = getState();
          const { blocks: currentSelections } = state.blockSelections;

          if (isEmpty(currentSelections)) {
            dispatch(resetToBoardPane());
          } else {
            dispatch(
              toolboxActions.select({
                pane: 'block',
              }),
            );
          }
          break;
        }
        // Selections always a singleton.
        case selectBlock.type: {
          dispatch(
            toolboxActions.select({
              pane: 'block',
            }),
          );

          break;
        }
        default:
      }
    };
}

export default createBlockSelectionsMiddleware;
