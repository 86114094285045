import { createSelector } from 'reselect';
import {
  defaultTo,
  entries,
  filter,
  get,
  groupBy,
  identity,
  map,
  pipe,
} from 'lodash/fp';

import activeScenarioCapsuleDataSelector from 'selectors/activeScenarioCapsuleDataSelector';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import locationsSelector from 'selectors/locationsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import Units from 'data/units';
import { immutableSort, calculateBounds } from 'utils/charts/series';

const units = new Units();
const yearSelector = makeAggregationFilterSelector('year');
const defaultData = [];

const secondaryCategorisationCapsuleDataSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  pipe(blockValueSelector, get('chart')),
  locationsSelector,
  yearSelector,
  workspaceInstantiationsSelector,
  pipe(activeScenarioCapsuleDataSelector, defaultTo(defaultData)),
  (schema, chart, locations, year, instantiations, data) => {
    const { x, y, time, type } = chart;

    const timeColumn = get('column', time);
    const xUnit = units.parseColumn(
      schema[x.column],
      locations,
      instantiations,
    );
    const unitPicker = (column) =>
      units.parseColumn(schema[column], locations, instantiations);
    const unitFormatterPicker = (column) => get('format', unitPicker(column));

    const secondaryCategorisation = get(['series', 'secondary'], y);
    const isStacked = ['column', 'stacked-area', 'stacked-column'].includes(
      type,
    );

    return {
      yBounds: calculateBounds(
        data,
        timeColumn,
        x.column,
        [y.series.column],
        isStacked,
      ),
      data: pipe(
        groupBy(secondaryCategorisation),
        entries,
        map(([category, seriesData]) => ({
          name: category,
          data: pipe(
            timeColumn && year ? filter({ [timeColumn]: year }) : identity,
            map((d) => ({ x: d[x.column], y: d[y.series.column] })),
            immutableSort((a, b) => xUnit.compare(a.x, b.x)),
          )(seriesData),
          unitFormatter: unitFormatterPicker(y.series.column),
          nameFormatter: unitFormatterPicker(y.series.secondary),
        })),
        immutableSort((a, b) =>
          unitPicker(y.series.secondary).compare(a.name, b.name),
        ),
      )(data),
      xUnit,
    };
  },
);

export default secondaryCategorisationCapsuleDataSelector;
