import { createSelector } from 'reselect';
import {
  eq,
  first,
  filter,
  get,
  has,
  isNil,
  map,
  pipe,
  placeholder,
  reject,
  values,
  where,
} from 'lodash/fp';
import currentAttributesSelector from './currentAttributesSelector';
import workspaceSelector from './workspaceSelector';
import workspaceGeographiesSelector from './workspaceGeographiesSelector';

const pointOf = (location, attributeValues, geographies) => {
  const geographyId = pipe(
    get([location, 'attributes', 'geography', 'time_series']),
    first,
    get('value'),
  )(attributeValues);
  return get([geographyId, 'point'], geographies);
};
const scenarioLocationsSelector = createSelector(
  pipe(workspaceSelector, get('locations')),
  workspaceGeographiesSelector,
  pipe(currentAttributesSelector, get('values')),
  (state, props) => get('layerId', props),
  (locations, geographies, attributeValues, layerId) =>
    pipe(
      values,
      filter(
        where({ id: has(placeholder, attributeValues), layer: eq(layerId) }),
      ),
      map((loc) => ({
        ...loc,
        attributes: values(get([loc.id, 'attributes'], attributeValues)),
        category: get([loc.id, 'category'], attributeValues),
        ...pointOf(loc.id, attributeValues, geographies),
      })),
      reject((v) => isNil(v.longitude) || isNil(v.latitude)),
    )(locations),
);

export default scenarioLocationsSelector;
