import PropTypes from 'prop-types';
import { noop } from 'lodash/fp';
import {
  SegmentedControl,
  SegmentedControlItem,
  LabelText,
  Stack,
  useId,
} from '@kinesis/bungle';

const propTypes = {
  value: PropTypes.oneOf(['lga', 'suburb']),
  onChange: PropTypes.func,
};
const defaultProps = {
  value: 'lga',
  onChange: noop,
};

const PathwaysLocationType = ({ value, onChange }) => {
  const id = useId();
  return (
    <Stack space='xsmall'>
      <LabelText as='label' id={`${id}-label`} htmlFor={`${id}-control`}>
        Location granularity
      </LabelText>

      <SegmentedControl
        value={value}
        onChange={onChange}
        id={`${id}-control`}
        widthMode='fill-container'
      >
        <SegmentedControlItem title='LGAs' value='lga'>
          LGAs
        </SegmentedControlItem>
        <SegmentedControlItem title='Suburbs' value='suburb'>
          Suburbs
        </SegmentedControlItem>
      </SegmentedControl>
    </Stack>
  );
};
PathwaysLocationType.propTypes = propTypes;
PathwaysLocationType.defaultProps = defaultProps;

export { PathwaysLocationType };
