import styled from 'styled-components';

const ModalHeaderActionOuter = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  &&:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.borderColor};
  }
`;

const ModalHeaderActionInner = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 100%;
  padding: 0 16px;
`;

export { ModalHeaderActionOuter, ModalHeaderActionInner };
