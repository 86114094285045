import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { H2, H3, Tooltip, UtilityButton } from '@kinesis/bungle';
import {
  ToolbarGroup,
  ToolbarItem,
  ToolbarSeparator,
} from '@kinesis/bungle/legacy';
import KinesisLogo from 'components/primary-navigation/KinesisLogo';
import { PublicStaticVisualisation } from 'components/public-static-visualisation';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useSelectorWithProps } from 'hooks';
import blockValueSelector from 'selectors/blockValueSelector';
import { BlockSection } from 'components/block/block.section';
import { BlockText } from 'components/block/block.text';
import {
  BlockContent,
  BlockToolbar,
  BlockWrapper,
} from 'components/block/block.styles';

const propTypes = {
  changeViewURL: PropTypes.string,
  id: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isSelected: PropTypes.bool,
  viewMode: PropTypes.oneOf(['maximised', 'minimised', 'standalone']),
  workspaceId: PropTypes.string.isRequired,
};

const defaultProps = {
  changeViewURL: undefined,
  isFirst: false,
  isLast: false,
  isSelected: false,
  viewMode: undefined,
};

// STRUCTURAL CONSOLE LOG, vite is not producing export in some cases
// not sure how to address yet, https://github.com/vitejs/vite/issues/8800
// shows a similar issue but on an older version, so not sure if it
// is the same thing?
console.log(MediaQuery, useMediaQuery);

const PublicBlock = ({
  changeViewURL,
  id,
  isFirst,
  isLast,
  isSelected,
  viewMode,
  workspaceId,
}) => {
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId: id,
    public: true,
    workspaceId,
  });
  const blockRef = useRef();
  const isNotNarrowScreen = useMediaQuery({ minWidth: 496 });

  const toolbarVariant = useMemo(() => {
    if (viewMode === 'maximised') {
      return 'dark';
    }

    if (viewMode === 'minimised') {
      if (blockValue.type === 'text') {
        return 'bordered';
      }
      if (
        blockValue.type === 'visualisation' &&
        blockValue.visualisation === 'spatial'
      ) {
        return 'translucent';
      }
    }

    if (viewMode === 'standalone' && blockValue.visualisation === 'spatial') {
      return 'box';
    }

    return 'light';
  }, [viewMode, blockValue]);

  const contentVariant = useMemo(() => {
    if (viewMode === 'minimised' && blockValue.visualisation === 'spatial') {
      return 'stretch';
    }

    if (viewMode === 'minimised' && blockValue.type === 'text') {
      return 'content';
    }

    if (viewMode === 'standalone' && blockValue.visualisation === 'spatial') {
      return 'box';
    }

    return 'default';
  }, [viewMode, blockValue]);

  const blockVariant = viewMode === 'standalone' ? 'embed' : undefined;

  if (blockValue.type === 'section') {
    return (
      <BlockSection
        blockId={id}
        blockRef={blockRef}
        privacy='public'
        workspaceId={workspaceId}
      />
    );
  }

  if (blockValue.type === 'text') {
    return (
      <BlockText
        blockId={id}
        blockRef={blockRef}
        isFirst={isFirst}
        isLast={isLast}
        privacy='public'
        workspaceId={workspaceId}
      />
    );
  }

  return (
    <BlockWrapper ref={blockRef} variant={blockVariant}>
      <BlockToolbar variant={toolbarVariant}>
        <ToolbarGroup>
          <ToolbarItem>
            {viewMode === 'standalone' ? (
              <H2 as='h1' noWrap>
                {blockValue.title}
              </H2>
            ) : (
              <H3 noWrap>{blockValue.title}</H3>
            )}
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup>
          {viewMode === 'maximised' && (
            <ToolbarItem flex={0}>
              <Tooltip placement='top' justify='end' title='Minimise'>
                <UtilityButton
                  as={Link}
                  data-testid='change-view-mode'
                  icon='shrink'
                  magnitude='small'
                  stopPropagation
                  to={changeViewURL}
                />
              </Tooltip>
            </ToolbarItem>
          )}
          {viewMode === 'minimised' && (
            <ToolbarItem flex={0}>
              <Tooltip placement='top' justify='end' title='Maximise'>
                <UtilityButton
                  as={Link}
                  data-testid='change-view-mode'
                  icon='expand'
                  magnitude='small'
                  stopPropagation
                  to={changeViewURL}
                />
              </Tooltip>
            </ToolbarItem>
          )}
          {viewMode === 'standalone' && (
            <>
              {isNotNarrowScreen && (
                <>
                  <ToolbarItem flex={0}>
                    <KinesisLogo height={18} width={24} />
                  </ToolbarItem>
                  <ToolbarItem>
                    Powered by{' '}
                    <a
                      href='https://platform.kinesis.org/'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Kinesis
                    </a>
                  </ToolbarItem>
                  <ToolbarSeparator />
                </>
              )}
              <ToolbarItem flex={0}>
                <Tooltip
                  placement='top'
                  justify='end'
                  title='View visualisation in Kinesis'
                >
                  <UtilityButton
                    as='a'
                    icon='external-link'
                    href={changeViewURL}
                    magnitude='small'
                    stopPropagation
                    target='_blank'
                  />
                </Tooltip>
              </ToolbarItem>
            </>
          )}
        </ToolbarGroup>
      </BlockToolbar>
      <BlockContent variant={contentVariant}>
        {blockValue.type === 'visualisation' && (
          <PublicStaticVisualisation
            blockId={id}
            blockRef={blockRef}
            isSelected={isSelected}
            type={
              blockValue.visualisation === 'chart'
                ? blockValue.chart.type
                : blockValue.visualisation
            }
            viewMode={viewMode}
            workspaceId={workspaceId}
          />
        )}
      </BlockContent>
    </BlockWrapper>
  );
};

PublicBlock.propTypes = propTypes;
PublicBlock.defaultProps = defaultProps;

export { PublicBlock };
