import useSelectorWithProps from './useSelectorWithProps';
import { useWorkspaceId } from './useWorkspaceId';
import { get, pipe, defaultTo } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

function usePermission(permissionName) {
  const workspaceId = useWorkspaceId();

  return useSelectorWithProps(
    pipe(
      workspaceSelector,
      get(['data', 'permissions', permissionName]),
      defaultTo(false),
    ),
    { workspaceId },
  );
}

export default usePermission;
