import PropTypes from 'prop-types';
import { Menu, Stack } from '@kinesis/bungle';
import { iconOf } from 'data/block';
import { BlockHeader, BlockGrid } from './block-menu.styles';

const propTypes = {
  appBoards: PropTypes.array.isRequired,
  blockId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const defaultProps = {};

const BlockMenu = ({ appBoards, blockId, onSelect }) => (
  <Stack space='large'>
    {appBoards.map((board) => (
      <Stack space='medium' key={board.id}>
        <BlockHeader>{board.title}</BlockHeader>
        <BlockGrid>
          {board.blocks.map((block) => {
            if (!['visualisation'].includes(block.type)) return null;
            return (
              <Menu key={block.id}>
                <Menu.Item
                  icon={iconOf(block)}
                  onClick={() =>
                    onSelect({
                      blockId,
                      blockVersion: block.address,
                      capsule: block,
                    })
                  }
                  content={block.title}
                />
              </Menu>
            );
          })}
        </BlockGrid>
      </Stack>
    ))}
  </Stack>
);

BlockMenu.propTypes = propTypes;
BlockMenu.defaultProps = defaultProps;

export { BlockMenu };
