import { createSelector } from 'reselect';
import { find, map, pipe, filter, get } from 'lodash/fp';
import sortedAppsSelector from 'selectors/sortedAppsSelector';
import loadedAppVersionsSelector from 'selectors/loadedAppVersionsSelector';
import makeFeatureFlagSelector from 'selectors/makeFeatureFlagSelector';
import workspaceSelector from 'selectors/workspaceSelector';

const enableLimitedReleaseAppsSelector = makeFeatureFlagSelector(
  'app.limited-release-apps',
);

const isAvailable = (enableLimitedReleaseApps, app, licences) => {
  const versionAvailable =
    get('available', app) || (enableLimitedReleaseApps && get('limited', app));
  const licenced =
    ['open', 'marketplace'].includes(app.licence) || licences.includes(app.id);
  return versionAvailable && licenced;
};

const appManagementSelector = createSelector(
  sortedAppsSelector,
  loadedAppVersionsSelector,
  pipe(workspaceSelector, get(['data', 'licences'])),
  enableLimitedReleaseAppsSelector,
  (apps, loadedAppVersions, licences, enableLimitedReleaseApps) =>
    pipe(
      filter(
        (app) =>
          isAvailable(enableLimitedReleaseApps, app, licences || []) ||
          find({ app: app.id }, loadedAppVersions),
      ),
      map((app) => ({
        ...app,
        available: enableLimitedReleaseApps
          ? app.limited || app.available
          : app.available || app.limited,
        version: find({ app: app.id }, loadedAppVersions),
      })),
    )(apps),
);

export default appManagementSelector;
