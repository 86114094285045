import PropTypes from 'prop-types';
import { format } from 'date-fns/fp';
import {
  Card,
  CardButton,
  CardContent,
  CardSubtitle,
  CardTitle,
  Icon,
  Tooltip,
} from '@kinesis/bungle';
import { Link } from 'react-router-dom';

import { ScenarioIcon } from 'components/named-scenarios/named-scenarios.styles';
import useDateDistance from 'hooks/useDateDistance';

const propTypes = {
  id: PropTypes.number.isRequired,
  isDefault: PropTypes.bool,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.object.isRequired,
  workspaceId: PropTypes.string.isRequired,
};

const defaultProps = {
  isDefault: false,
};

const PublicNamedScenarioItem = ({
  id,
  workspaceId,
  name,
  isDefault,
  updatedAt,
}) => (
  <Card key={`scenario-${id}`}>
    <CardButton as={Link} to={`/public/${workspaceId}/scenarios/${id}`}>
      <CardContent clipRight noWrap>
        <CardTitle>
          {name}
          {isDefault && (
            <Tooltip title='Baseline scenario' placement='top'>
              <ScenarioIcon>
                <Icon type='baseline' />
              </ScenarioIcon>
            </Tooltip>
          )}
        </CardTitle>
        <CardSubtitle>
          <Tooltip title={format('d MMM yyyy, h:mm a', updatedAt)}>
            <span>Last updated {useDateDistance(updatedAt)}</span>
          </Tooltip>
        </CardSubtitle>
      </CardContent>
    </CardButton>
  </Card>
);

PublicNamedScenarioItem.propTypes = propTypes;
PublicNamedScenarioItem.defaultProps = defaultProps;

export default PublicNamedScenarioItem;
