import { createSelector } from 'reselect';
import { pipe, get, values, size, filter } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

const nonSyntheticLocationCountSelector = createSelector(
  pipe(workspaceSelector, get('locations')),
  pipe(workspaceSelector, get(['layers', 'entities'])),
  (locations, layers) =>
    pipe(
      values,
      filter(({ layer }) => get([layer, 'type'], layers) !== 'synthetic'),
      size,
    )(locations),
);

export default nonSyntheticLocationCountSelector;
