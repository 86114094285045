import styled from 'styled-components';

const Body = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  background: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 16px 20px;
  width: 100%;
`;

const MaxWidth = styled('div')`
  max-width: 100%;
`;

const Full = styled('div')`
  width: 100%;
  height: 100%;
`;

export { Body, MaxWidth, Full };
