import PropTypes from 'prop-types';
import Popover from 'components/popover/Popover';
import DataPopover from 'components/popover/DataPopover';
import { find, isNil, reject } from 'lodash/fp';
import { BasicLocationPopover } from './spatial-popover.styles';

const rejectNil = reject(isNil);

const propTypes = {
  type: PropTypes.string,
  data: PropTypes.array,
  locations: PropTypes.array.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  colorHeading: PropTypes.string,
  colorFormat: PropTypes.func,
  sizeHeading: PropTypes.string,
  sizeFormat: PropTypes.func,
};

const defaultProps = {
  data: [],
  type: null,
  colorHeading: undefined,
  colorFormat: undefined,
  sizeHeading: undefined,
  sizeFormat: undefined,
};

const SpatialPopover = ({
  type,
  id,
  x,
  y,
  locations,
  data,
  colorHeading,
  colorFormat,
  sizeHeading,
  sizeFormat,
}) => {
  if (type === 'location') {
    const match = find({ id }, locations);
    return (
      <Popover pointerX={x} pointerY={y} disablePointerEvents>
        <BasicLocationPopover>
          {match.label || 'New location'}
        </BasicLocationPopover>
      </Popover>
    );
  }

  if (type === 'bubble' || type === 'hex') {
    const match = find({ id }, data);
    return (
      <Popover pointerX={x} pointerY={y} disablePointerEvents>
        <DataPopover
          disablePointerEvents
          title={match.label}
          content={rejectNil([
            !isNil(match.color)
              ? {
                  label: colorHeading,
                  value: colorFormat ? colorFormat(match.color) : undefined,
                  color: `rgb(${match.colors.base[0]},${match.colors.base[1]},${match.colors.base[2]})`,
                }
              : undefined,
            !isNil(match.size)
              ? {
                  label: sizeHeading,
                  value: sizeFormat(match.size),
                }
              : undefined,
          ])}
        />
      </Popover>
    );
  }

  return null;
};

SpatialPopover.propTypes = propTypes;
SpatialPopover.defaultProps = defaultProps;

export { SpatialPopover };
