import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { first, get, isEmpty, map, pipe, slice, flatMap } from 'lodash/fp';
import { Avatar, Menu, Placeholder } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { hasEntries, entries, keyOf, labelOf } from 'data/attributes';
import loadedAppsSelector from 'selectors/loadedAppsSelector';
import Layout from 'components/layout';
import { useSelectorWithProps } from 'hooks';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import FormEntry from './form-entry';
import {
  AttributeSettingsToolbox,
  Description,
} from './attribute-settings.styles';

const propTypes = {
  addItem: PropTypes.func, // FUTURE: .isRequired
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node).isRequired,
  ]),
  description: PropTypes.string.isRequired,
  errors: PropTypes.array,
  hasSettings: PropTypes.bool,
  instantiation: PropTypes.object.isRequired,
  itemAddition: PropTypes.bool,
  selections: PropTypes.array,
  setSelections: PropTypes.func,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  addItem: undefined,
  children: null,
  errors: [],
  hasSettings: true,
  itemAddition: false,
  selections: undefined,
  setSelections: undefined,
};

const GenericSettings = ({
  addItem,
  children,
  description,
  errors,
  hasSettings,
  instantiation,
  itemAddition,
  selections,
  setSelections,
  workspaceId,
}) => {
  const items = useMemo(() => entries(instantiation), [instantiation]);
  const apps = useSelectorWithProps(loadedAppsSelector, { workspaceId });
  useEffect(() => {
    if (isEmpty(selections) && hasSettings) {
      setSelections([
        keyOf(first(flatMap((e) => (hasEntries(e) ? entries(e) : [e]), items))),
      ]);
    }
  }, [items, selections, hasSettings, setSelections]);
  const allowMultiSelect = false;
  const onClick = (item) => (e) => {
    // NOTE: this is important, event (e) can not be referenced in async set call.
    const { shiftKey } = e;
    setSelections((current) =>
      shiftKey && allowMultiSelect ? [...current, keyOf(item)] : [keyOf(item)],
    );
  };
  const variant = (item) => {
    if (selections.includes(keyOf(item))) {
      return 'accent';
    }
    if (map(get('categoryKey'), errors).includes(keyOf(item))) {
      return 'danger';
    }
    return undefined;
  };

  const defaultLabel = (item) =>
    get('entries', item) ? 'New category' : 'New item';

  const menuItem = (item) => (
    <Menu.Item
      header={hasEntries(item)}
      variant={variant(item)}
      key={keyOf(item)}
      content={isEmpty(get('label', item)) ? defaultLabel(item) : labelOf(item)}
      onClick={onClick(item)}
    >
      {hasEntries(item) && (
        <>
          {map(menuItem, entries(item))}
          {itemAddition && (
            <Menu.Item
              icon='plus'
              key={`add-item-${keyOf(item)}`}
              content='Add item'
              onClick={addItem(keyOf(item))}
            />
          )}
        </>
      )}
    </Menu.Item>
  );

  return (
    <Layout direction='row'>
      <AttributeSettingsToolbox arrangement='left' width={304}>
        <ToolboxSection collapsible title='Description'>
          <Description>{description}</Description>
        </ToolboxSection>
        {hasSettings && (
          <ToolboxSection collapsible title='Items'>
            <Menu>{map(menuItem, items)}</Menu>
          </ToolboxSection>
        )}
        <ToolboxSection collapsible title='Used by'>
          <Menu>
            {map((app) => (
              <Menu.Item
                key={app.name}
                content={app.label}
                icon={
                  <Avatar
                    alt={`${app.publisher} logo`}
                    image={`data:image/png;base64,${app.image}`}
                    magnitude='xsmall'
                    variant='organisation'
                  >
                    {pipe(get('publisher'), slice(0, 1))(app)}
                  </Avatar>
                }
              />
            ))(apps)}
          </Menu>
        </ToolboxSection>
      </AttributeSettingsToolbox>
      <Layout direction='column'>
        {isEmpty(selections) && hasSettings && (
          <Content alignX='center' alignY='center'>
            <Placeholder>
              Select a category or item to view settings.
            </Placeholder>
          </Content>
        )}
        {/* FUTURE: we should be able to data-drive this from the setting, for
            now we are coding up the specific settings bodies. */}
        {!isEmpty(selections) && children}
        {!hasSettings && (
          <FormEntry label='Settings'>
            <Content padding='none'>
              <Placeholder>
                There are no settings for this attribute.
              </Placeholder>
            </Content>
          </FormEntry>
        )}
      </Layout>
    </Layout>
  );
};

GenericSettings.defaultProps = defaultProps;
GenericSettings.propTypes = propTypes;

export default GenericSettings;
