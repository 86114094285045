import { find, get, isNil, pipe } from 'lodash/fp';
import PropTypes from 'prop-types';
import Units from 'data/units';
import { Placeholder, Stack } from '@kinesis/bungle';
import { Loading } from 'components/loading';
import blockDataSelector from 'selectors/blockDataSelector';
import blockPerspectiveStateSelector from 'selectors/blockPerspectiveStateSelector';
import isScenarioMismatchSelector from 'selectors/isScenarioMismatchSelector';
import makeAggregationFilterSelector from 'selectors/makeAggregationFilterSelector';
import { useSelectorWithProps } from 'hooks';
import { BlockError } from 'components/block-error';
import isCapsuleMismatchSelector from 'selectors/isCapsuleMismatchSelector';
import { Body, MaxWidth, Value, Unit } from './indicator.styles';

const units = new Units();

const propTypes = {
  blockId: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const isError = (data) => get('type', data) === 'error';

const isPlaceholderError = (data) =>
  ['query-missing-table', 'query-missing-measure'].includes(
    get(['data', 'code'], data),
  );

const format = (value, unit, data) => {
  if (isPlaceholderError(data)) return 'Value';
  if (!isNil(value) && unit) return unit.formatCell(value);
  return null;
};

const yearSelector = makeAggregationFilterSelector('year');

const Indicator = ({ blockId, scenarioId, workspaceId }) => {
  const data = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const state = useSelectorWithProps(blockPerspectiveStateSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const selectedYear = useSelectorWithProps(yearSelector, {
    blockId,
    workspaceId,
  });
  const scenarioMismatch = useSelectorWithProps(isScenarioMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const capsuleMismatch = useSelectorWithProps(isCapsuleMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });

  const unit = units.parseColumn({
    unit: get(['data', 'metadata', 'value', 'unit'], data),
  });

  const isProcessing = scenarioMismatch || state === 'processing';
  const placeholder = isError(data) && isPlaceholderError(data);
  const loading = isNil(state);

  let value;
  switch (get(['data', 'type'], data)) {
    case 'indicator':
      value = get(['data', 'value'], data);
      break;
    case 'indicator-time-series': {
      value = pipe(
        get(['data', 'time_series']),
        find({ time: selectedYear }),
        get('value'),
      )(data);
      break;
    }
    default:
      value = undefined;
  }
  const formatted = format(value, unit, data);

  if (loading) {
    return <Loading offset={20} />;
  }

  // unrecoverable error.
  if (isError(data) && !isPlaceholderError(data)) {
    if (isProcessing && capsuleMismatch) {
      return (
        <Body>
          <MaxWidth>
            <Stack space='xsmall'>
              <Placeholder>Data is being processed.</Placeholder>
            </Stack>
          </MaxWidth>
        </Body>
      );
    }
    return (
      <BlockError
        error={get('data', data)}
        blockId={blockId}
        scenarioId={scenarioId}
        workspaceId={workspaceId}
      />
    );
  }

  return (
    <Body>
      <MaxWidth>
        <Stack space='xsmall'>
          {!formatted && !isProcessing && (
            <Placeholder>There is no data.</Placeholder>
          )}
          {!formatted && isProcessing && (
            <Placeholder>Data is being processed.</Placeholder>
          )}
          {formatted && (
            <Value isProcessing={isProcessing} $placeholder={placeholder}>
              {formatted}
            </Value>
          )}
          {unit && unit.symbol && formatted && (
            <Unit isProcessing={isProcessing}>{unit.symbol}</Unit>
          )}
        </Stack>
      </MaxWidth>
    </Body>
  );
};

Indicator.propTypes = propTypes;
Indicator.defaultProps = defaultProps;

export { Indicator };
