import { createSelector } from 'reselect';
import blockIdsWithTimeSelector from 'selectors/blockIdsWithTimeSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import { get, map, min, max, pick, pipe, values } from 'lodash/fp';
import { getBounds } from 'utils/boundsUtils';
import { getActiveVersion } from 'data/block';

const minMax = (d) => [min(d), max(d)];

const blocksTimeBoundsSelector = createSelector(
  blockIdsWithTimeSelector,
  get('data'),
  get('blockValues'),
  pipe(workspaceSelector, get('blocks')),
  (state, props) => props.scenarioId,
  (blockIds, blockData, blockValues, blockStates, scenarioId) =>
    pipe(
      map((blockId) => {
        const blockValue = get(
          pipe(get(blockId), getActiveVersion)(blockStates),
          blockValues,
        );

        if (get('type', blockValue) === 'editable-visualisation') {
          const blockDataId = get(
            [blockId, 'scenarios', scenarioId, 'dataId'],
            blockStates,
          );
          return pipe(
            get([blockDataId, 'data', 'metadata', 'time']),
            pick(['min', 'max']),
            values,
          )(blockData);
        }

        return pipe(
          get([blockId, 'scenarios', scenarioId, 'data']),
          map('year'),
          minMax,
        )(blockStates);
      }),
      getBounds,
    )(blockIds),
);

export default blocksTimeBoundsSelector;
