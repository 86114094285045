import { createSelector } from 'reselect';
import {
  conforms,
  eq,
  filter,
  get,
  isNil,
  isUndefined,
  omitBy,
  orderBy,
  pipe,
  uniqBy,
  values,
} from 'lodash/fp';
import { lte } from 'lodash';
import attributesSelector from './attributesSelector';

const omitNil = omitBy(isNil);

const attributeEntitiesSelector = createSelector(
  pipe(attributesSelector, get('entities')),
  (state, props) => get('definition', props),
  (state, props) => get('locationId', props),
  (state, props) => get('date', props),
  (attributeEntities, definition, locationId, date) =>
    pipe(
      values,
      filter(
        conforms(
          omitNil({
            definition: definition ? eq(definition) : undefined,
            locationId: !isNil(locationId) ? eq(locationId) : undefined,
            date: (x) =>
              !isUndefined(date) ? isNil(x) || lte(x, date) : undefined,
          }),
        ),
      ),
      orderBy('date', 'desc'),
      uniqBy((x) => `${get('definition', x)}/${get('entry', x)}`),
    )(attributeEntities),
);

export default attributeEntitiesSelector;
