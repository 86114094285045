import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { UtilityButton } from '@kinesis/bungle';
import {
  ToolbarGroupLeft,
  ToolbarItem,
  ToolbarSeparator,
} from '@kinesis/bungle/legacy';
import useAppHeaderContext from './useAppHeaderContext';
import { BackButtonWrapper, BreadcrumbsNav } from './breadcrumbs.styles';

const AnimatedBackButtonWrapper = motion(BackButtonWrapper);

const BreadcrumbPortal = () => {
  const portalNodeRef = useRef();
  const { backTo, setBreadcrumbPortalNode } = useAppHeaderContext();
  const [showBack, setShowBack] = useState(!!backTo);

  useEffect(() => {
    setShowBack(!!backTo);
  }, [setShowBack, backTo]);

  useEffect(() => {
    setBreadcrumbPortalNode(portalNodeRef.current);
  }, [setBreadcrumbPortalNode]);

  return (
    <ToolbarGroupLeft>
      <AnimatePresence initial={false}>
        {showBack && (
          <AnimatedBackButtonWrapper
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: 65 }}
            exit={{ opacity: 0, width: 0 }}
            transition={{ duration: 0.1 }}
          >
            <ToolbarItem style={{ minWidth: 'auto' }}>
              <UtilityButton as={Link} to={backTo || '/'} icon='arrow-left' />
            </ToolbarItem>
            <ToolbarSeparator />
          </AnimatedBackButtonWrapper>
        )}
      </AnimatePresence>
      <BreadcrumbsNav aria-label='Breadcrumb' ref={portalNodeRef} />
    </ToolbarGroupLeft>
  );
};

export default BreadcrumbPortal;
