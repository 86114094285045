import styled from 'styled-components';

const CardsWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  padding: 16px;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 304px);
  grid-auto-rows: auto;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
`;

const Card = styled.section`
  position: relative;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 2px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  transition: 0.3s;
  cursor: pointer;
  min-width: 0;

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    box-shadow:
      0px 2px 8px rgba(0, 0, 0, 0.05),
      0 0 0 2px rgba(0, 0, 0, 0.05);
  }
  &:hover:focus {
    box-shadow:
      0px 2px 8px rgba(0, 0, 0, 0.1),
      0 0 0 2px rgba(0, 0, 0, 0.05);
  }
  &:active {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05) !important;
  }
`;

export { Card, CardsWrapper };
