import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loading } from 'components/loading';
import { workspaceShareAccept } from 'actions/workspaceShareAccept';

const AcceptShare = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      dispatch(workspaceShareAccept({ token }))
        .unwrap()
        .then(({ id }) => {
          if (id) {
            navigate(`/workspaces/${id}`);
          }
        })
        .catch(() => {
          navigate('/workspaces');
        });
    }
  }, [dispatch, navigate, token]);

  return <Loading />;
};

export { AcceptShare };
