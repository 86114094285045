import { createSlice } from '@reduxjs/toolkit';
import { licenceList } from 'actions/licenceList';
import { userLogin } from 'actions/userLogin';
import { userRelogin } from 'actions/userRelogin';

const { reducer, actions } = createSlice({
  name: 'licences',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(licenceList.fulfilled, (state, action) => action.payload);
    builder.addCase(
      userLogin.fulfilled,
      (state, action) => action.payload.licences,
    );
    builder.addCase(
      userRelogin.fulfilled,
      (state, action) => action.payload.licences,
    );
  },
});

export { reducer, actions };
