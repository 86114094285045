import styled from 'styled-components';

const PathwaysWrapper = styled('div')({
  alignItems: 'stretch',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  width: '100%',
});

export { PathwaysWrapper };
