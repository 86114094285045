import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import { ToolboxSection } from 'components/toolbox/toolbox-section';

const propTypes = {
  definitions: PropTypes.arrayOf(
    PropTypes.shape({
      resourceId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      notes: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};
const defaultProps = {
  definitions: [],
};

const TableDefinitionsSection = ({ definitions }) => (
  <>
    {definitions
      .filter((definition) => !isEmpty(definition.notes))
      .map(({ label, notes, resourceId }) => (
        <ToolboxSection collapsible key={resourceId} title={label}>
          {notes.map((note, j) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={j}>{note}</p>
          ))}
        </ToolboxSection>
      ))}
  </>
);

TableDefinitionsSection.propTypes = propTypes;
TableDefinitionsSection.defaultProps = defaultProps;

export default TableDefinitionsSection;
