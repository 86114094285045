import { combineReducers } from 'redux';
import { reducer as appsReducer } from './appsReducer';
import { reducer as appVersionsReducer } from './appVersionsReducer';
import { reducer as authReducer } from './authReducer';
import { reducer as blockValuesReducer } from './blockValuesReducer';
import { reducer as customResourcesReducer } from './customResourcesReducer';
import { reducer as dataReducer } from './dataReducer';
import { reducer as datasetsReducer } from './datasetsReducer';
import { reducer as datasetVersionsReducer } from './datasetVersionsReducer';
import { reducer as featureFlagsReducer } from './featureFlagsReducer';
import { reducer as geographyReducer } from './geographyReducer';
import identitiesReducer from './identitiesReducer';
import { reducer as licenceReducer } from './licenceReducer';
import { reducer as organisationsReducer } from './organisationsReducer';
import { reducer as miscReducer } from './miscReducer';
import { reducer as pathwaysReducer } from './pathwaysReducer';
import { reducer as preferencesReducer } from './preferencesReducer';
import { reducer as publicTokensReducer } from './publicTokensReducer';
import { reducer as resourcesReducer } from './resourcesReducer';
import { reducer as toolboxReducer } from './toolboxReducer';
import { reducer as uploadsReducer } from './uploadsReducer';
import { reducer as publicWorkspacesReducer } from './publicWorkspacesReducer';
import { reducer as blockSelectionsReducer } from './blockSelectionsReducer';
import { reducer as workspacePreviewReducer } from './workspacePreviewReducer';
import workspacesReducer from './workspacesReducer';
import { api } from '@/api/api';

const appReducer = () =>
  combineReducers({
    [api.reducerPath]: api.reducer,
    apps: appsReducer,
    appVersions: appVersionsReducer,
    auth: authReducer,
    blockValues: blockValuesReducer,
    blockSelections: blockSelectionsReducer,
    custom: customResourcesReducer,
    data: dataReducer,
    datasets: datasetsReducer,
    datasetVersions: datasetVersionsReducer,
    featureFlags: featureFlagsReducer,
    geography: geographyReducer,
    identities: identitiesReducer,
    licences: licenceReducer,
    organisations: organisationsReducer,
    misc: miscReducer,
    pathways: pathwaysReducer,
    preferences: preferencesReducer,
    publicTokens: publicTokensReducer,
    publicWorkspaces: publicWorkspacesReducer,
    resources: resourcesReducer,
    toolbox: toolboxReducer,
    uploads: uploadsReducer,
    workspaces: workspacesReducer,
    workspacePreview: workspacePreviewReducer,
  });

export default appReducer;
