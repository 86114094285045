import { createSelector } from 'reselect';
import { get, keys, pick, pipe } from 'lodash/fp';
import workspaceSelector from 'selectors/workspaceSelector';

const accessIdentitiesSelector = createSelector(
  pipe(workspaceSelector, get(['data', 'access'])),
  get('identities'),
  (access, identities) => pick(keys(access), identities),
);

export default accessIdentitiesSelector;
