import { useMatch } from 'react-router-dom';

function useWorkspaceId() {
  const match = useMatch('/workspaces/:workspaceId/*');

  const workspaceId = parseInt(match?.params?.workspaceId, 10);

  return !Number.isNaN(workspaceId) ? workspaceId : undefined;
}

export { useWorkspaceId };
