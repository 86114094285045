import { createSelector } from 'reselect';
import { find, get, pipe } from 'lodash/fp';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import currentAttributesSelector from 'selectors/currentAttributesSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import Units from 'data/units';
import spatialVisualisationDataSelector from 'selectors/spatialVisualisationDataSelector';

const units = new Units();

const spatialDetailsSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  pipe(blockValueSelector),
  spatialVisualisationDataSelector,
  pipe(currentAttributesSelector, get('values')),
  (state, props) => get('locationId', props),
  workspaceInstantiationsSelector,
  (schema, blockValue, dataViz, locations, locationId, instantiations) => {
    const color = get(['spatial', 'colour', 'column'], blockValue);
    const size = get(['spatial', 'size', 'column'], blockValue);
    if (!locationId) {
      return [];
    }

    const match = find(
      {
        id: locationId,
      },
      dataViz,
    );

    if (!match) {
      return [];
    }

    const details = [];

    if (color) {
      details.push({
        color: `rgb(${match.colors.base[0]},${match.colors.base[1]},${match.colors.base[2]})`,
        label: get([color, 'label'], schema),
        value: units
          .parseColumn(schema[color], locations, instantiations)
          .format(match.color),
      });
    }
    if (size) {
      details.push({
        label: get([size, 'label'], schema),
        value: units
          .parseColumn(schema[size], locations, instantiations)
          .format(match.size),
      });
    }

    return details;
  },
);

export default spatialDetailsSelector;
