import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Icon, Inline, InlineItem } from '@kinesis/bungle';

const propTypes = {
  isDefault: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  isDraft: false,
};

const DecoratedScenarioName = ({ isDefault, isDraft, name }) => {
  const theme = useTheme();

  return isDefault ? (
    <Inline space='xsmall'>
      <InlineItem>
        <span>
          {name}
          {isDraft ? ' (Draft)' : ''}
        </span>
      </InlineItem>
      <InlineItem>
        <Icon color={theme.color.black7} magnitude='xsmall' type='baseline' />
      </InlineItem>
    </Inline>
  ) : (
    <span>
      {name}
      {isDraft ? ' (Draft)' : ''}
    </span>
  );
};

DecoratedScenarioName.propTypes = propTypes;
DecoratedScenarioName.defaultProps = defaultProps;

export default DecoratedScenarioName;
