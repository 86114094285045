import PropTypes from 'prop-types';
import { Icon, Tooltip, UtilityButton } from '@kinesis/bungle';
import styled from 'styled-components';

import { CardsWrapper } from 'components/card';

const Grid = styled(CardsWrapper)`
  min-width: 336px;
  max-width: 656px;
`;

const GettingStartedWrapper = styled.div`
  display: flex;
  align-items: top;
  grid-column: 1 / -1;
`;

const CheckCircleIconWrapper = styled.div`
  margin-top: 2px;
  flex: 0 0 auto;
  color: ${({ theme }) => theme.color.green6};

  svg {
    width: 16px;
    height: 16px;
  }
`;

const CloseWrapper = styled.div`
  margin-top: -2px;
  margin-bottom: -2px;
  height: 24px;
`;

const Text = styled.div`
  line-height: 20px;
  flex-grow: 1;
  padding: 0px 8px;
`;

const propTypes = {
  onDismiss: PropTypes.func.isRequired,
};

const defaultProps = {};

const GettingStarted = ({ onDismiss }) => (
  <Grid>
    <GettingStartedWrapper>
      <CheckCircleIconWrapper>
        <Icon type='check-circle' />
      </CheckCircleIconWrapper>
      <Text>
        Read our{' '}
        <a
          href='https://help.kinesis.org/en/articles/8649084-getting-started-with-workspaces'
          target='_blank'
          rel='noopener noreferrer'
        >
          getting started guide
        </a>{' '}
        to learn more about how to set up and use workspaces.
      </Text>
      <Tooltip title='Dismiss' placement='bottom' justify='center'>
        <CloseWrapper>
          <UtilityButton icon='close' magnitude='small' onClick={onDismiss} />
        </CloseWrapper>
      </Tooltip>
    </GettingStartedWrapper>
  </Grid>
);

GettingStarted.propTypes = propTypes;
GettingStarted.defaultProps = defaultProps;

export default GettingStarted;
