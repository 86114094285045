import { useCallback, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { identity } from 'lodash/fp';
import { useHandler } from '@kinesis/bungle';
import memoizeOne from 'memoize-one';

const areObjectsEqual = ([next], [prev]) => shallowEqual(next, prev);

function useSelectorWithProps(selector, props = {}) {
  const { current: memoize } = useRef(memoizeOne(identity, areObjectsEqual));
  const select = useHandler(selector);
  const memoizedProps = memoize(props);

  return useSelector(
    useCallback(
      (state) => select(state, memoizedProps),
      [select, memoizedProps],
    ),
  );
}

export default useSelectorWithProps;
