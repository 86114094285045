import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { get, map, reject, size, pipe } from 'lodash/fp';
import {
  AnimatedPopover,
  ConfirmationDialog,
  Prompt,
  useEventListener,
} from '@kinesis/bungle';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { actions as miscActions } from 'reducers/miscReducer';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import { blockAdd } from 'actions/blockAdd';
import { blocksReorder } from 'actions/blocksReorder';
import { blocksRemove } from 'actions/blocksRemove';
import { latestBlockIdUnset } from 'actions/latestBlockIdUnset';
import blocksForBlockGridSelector from 'selectors/blocksForBlockGridSelector';
import boardSelector from 'selectors/boardSelector';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import Layout from 'components/layout';
import { Block } from 'components/block';
import { keyboardShortcutString } from 'utils/keyboardUtils';
import { useBlockId, useSelectorWithProps } from 'hooks';
import useScrollPosition from 'hooks/useScrollPosition';
import useResponsive from 'hooks/useResponsive';
import { blocksToBlockRows } from 'utils/blocks';
import { AddBlockMenu } from 'components/add-block-menu';
import useActions from 'hooks/useActions';
import { rawBlockTypes } from 'constants/block-types';
import {
  BlockGridWrapper,
  DropDestinationBar,
  NewBlockRowGridTop,
} from './block-grid.styles';
import BlockRow from './block-row';
import Bowser from 'bowser';
const { getParser } = Bowser;

const isMacOS = getParser(window.navigator.userAgent).getOSName() === 'macOS';

const propTypes = {
  boardId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const BlockGrid = ({ boardId, workspaceId }) => {
  const navigate = useNavigate();
  const [deleteDialogData, setDeleteDialogData] = useState();
  const [beforeCaptureDraggingId, setBeforeCaptureDraggingId] = useState();
  const [draggingId, setDraggingId] = useState();
  const [draggingSource, setDraggingSource] = useState();
  const [dropDestination, setDropDestination] = useState();
  const [dragHasJustEnded, setDragHasJustEnded] = useState(false);
  const blocks = useSelectorWithProps(blocksForBlockGridSelector, {
    boardId,
    workspaceId,
  });
  const maximisedBlockId = useBlockId();
  const { latestBlockId } = useSelectorWithProps(boardSelector, {
    boardId,
    workspaceId,
  });
  const selectedBlockIds = useSelector(blockSelectionsSelector);
  const footerRef = useRef();
  const gridRef = useRef();
  const selectedRef = useRef();
  const dispatch = useDispatch();

  const showPrompt = useSelector(get(['misc', 'showMultiSelectPrompt']));
  const handleHidePrompt = useCallback(() => {
    if (showPrompt) {
      dispatch(miscActions.clearShowMultiSelectPrompt());
    }
  }, [dispatch, showPrompt]);

  const { selectBlock, selectBlocks, resetBlocks } = useActions(
    blockSelectionsActions,
  );

  const handleReset = useCallback(() => {
    if (!draggingId && size(selectedBlockIds) > 0) {
      resetBlocks();
    }

    handleHidePrompt();
  }, [draggingId, handleHidePrompt, resetBlocks, selectedBlockIds]);
  const [scrollPosition, setScrollPosition] = useScrollPosition();

  const { isFullSizeBoard } = useResponsive();
  const blockRows = useMemo(
    () => blocksToBlockRows(blocks, isFullSizeBoard),
    [blocks, isFullSizeBoard],
  );

  const handleDelete = useCallback(
    (blockIds) => {
      const toDeleteBlocks = blocks.filter((b) => blockIds.includes(b.id));
      const isSingleBlock = toDeleteBlocks.length === 1;
      const isEmptyBlock =
        isSingleBlock && get('visualisation', toDeleteBlocks[0]) === 'empty';
      const isTextBlock =
        isSingleBlock && get('blockType', toDeleteBlocks[0]) === 'text';

      // TODO: default name gen could apply here
      const blockTitle = get('title', toDeleteBlocks[0]) || 'New visualisation';

      let confirmText;
      let title;
      let subtext;

      if (isEmptyBlock) {
        title = `Delete “${blockTitle}”?`;
        confirmText = 'Delete block';
        subtext = 'This block will be removed from this board.';
      } else if (isTextBlock) {
        title = `Delete text?`;
        confirmText = 'Delete text';
        subtext = 'This text will be removed from this board.';
      } else {
        const derivedBlockType =
          get('blockType', toDeleteBlocks[0]) === 'editable-visualisation'
            ? 'visualisation'
            : get('blockType', toDeleteBlocks[0]);

        confirmText = isSingleBlock
          ? `Delete ${derivedBlockType}`
          : 'Delete blocks';
        title = isSingleBlock
          ? `Delete “${blockTitle}”?`
          : `Delete ${toDeleteBlocks.length} blocks?`;
        subtext = isSingleBlock
          ? `This ${derivedBlockType} will be removed from this board.`
          : `The selected blocks will be removed from this board.`;
      }

      setDeleteDialogData({
        blockIds,
        confirmText,
        title,
        subtext,
      });
    },
    [blocks],
  );

  const handleRemoveBlocks = useCallback(
    (blockIds) => {
      const rowBreakIdsToDelete = reject(
        (b) => blockIds.includes(b.id), // remove the deleted ones from the list of all blocks
        blocks,
      )
        // Also delete:
        // 1. Any row break that might have ended up in position 0 (i.e. all blocks in first row have been deleted)
        // 2. Any row breaks where the block before it is also a row break (i.e. the blocks between them have been deleted)
        // 3. Any row breaks that have ended up at the end
        // Note: can't easily use lodash/fp with pipe here because of the capped arguments to the iteratee of filter().
        .filter(
          (block, index, allBlocks) =>
            (index === 0 && block.blockType === 'row-break') ||
            (index !== 0 &&
              block.blockType === 'row-break' &&
              allBlocks[index - 1].blockType === 'row-break') ||
            (index === allBlocks.length - 1 && block.blockType === 'row-break'),
        )
        .map((block) => get('id', block));

      const blockIdsToDelete = blockIds.concat(rowBreakIdsToDelete);
      dispatch(
        blocksRemove({
          workspaceId,
          boardId,
          blocks: blockIdsToDelete,
        }),
      );

      if (maximisedBlockId) {
        navigate(`/workspaces/${workspaceId}/boards/${boardId}`);
      } else {
        handleReset();
      }
    },
    [
      blocks,
      boardId,
      dispatch,
      handleReset,
      maximisedBlockId,
      navigate,
      workspaceId,
    ],
  );

  const handleCloseDeleteModal = () => {
    setDeleteDialogData(undefined);
  };

  const handleConfirmDelete = useCallback(() => {
    handleRemoveBlocks(deleteDialogData.blockIds);
    setDeleteDialogData(undefined);
  }, [deleteDialogData, handleRemoveBlocks]);

  useEffect(() => {
    if (maximisedBlockId) {
      return;
    }

    if (scrollPosition.y) {
      gridRef.current.scrollTo(0, scrollPosition.y);
    } else if (selectedRef.current) {
      const gridRect = gridRef.current.getBoundingClientRect();
      const blockRect = selectedRef.current.getBoundingClientRect();

      gridRef.current.scrollTo(0, blockRect.top - gridRect.top - 16);
    }
  }, [maximisedBlockId, scrollPosition]);

  const promptText = useMemo(
    () =>
      'ontouchstart' in window
        ? 'Tap and hold to select more than one block'
        : `Hold shift to select more than one block (${keyboardShortcutString({
            key: 'click',
            shift: true,
          })})`,
    [],
  );

  // Auto dismiss after a while
  useEffect(() => {
    if (showPrompt && size(selectedBlockIds) === 1) {
      const timerId = setTimeout(handleHidePrompt, 6000);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [handleHidePrompt, selectedBlockIds, showPrompt]);

  useEventListener(
    'keydown',
    useCallback(
      (event) => {
        if (maximisedBlockId) {
          return;
        }

        const isCommandKey = isMacOS && event.metaKey;
        const isCtrlKey = !isMacOS && event.ctrlKey;
        const isCommandOrControlKey = isCommandKey || isCtrlKey;
        const isSelectAllCombo = isCommandOrControlKey && event.key === 'a';

        if (isSelectAllCombo) {
          // disables text selecting on select all
          event.preventDefault();
          const selections = pipe(
            reject({ blockType: 'row-break' }),
            reject({ blockType: 'text' }),
            reject({ blockType: 'section' }),
          )(blocks);
          selectBlocks(map('id', selections));
          return;
        }

        if (event.key === 'Delete') {
          if (selectedBlockIds.length) {
            handleDelete(selectedBlockIds);
          }
        }

        if (event.key === 'v') {
          dispatch(blockAdd({ type: 'visualisation', workspaceId, boardId }));
        }

        if (event.key === 't') {
          dispatch(blockAdd({ type: 'text', workspaceId, boardId }));
        }
      },
      [
        blocks,
        boardId,
        dispatch,
        handleDelete,
        maximisedBlockId,
        selectedBlockIds,
        workspaceId,
        selectBlocks,
      ],
    ),
  );

  const persistReorder = useCallback(
    (newBlocks) => {
      dispatch(blocksReorder({ workspaceId, boardId, blockIds: newBlocks }));
    },
    [boardId, dispatch, workspaceId],
  );

  const flattenBlockRows = useCallback((brs) => {
    const flattened = [];

    brs.forEach((br, index) => {
      const ids = br.blocks.map((b) => b.id);
      flattened.push(...ids);

      const isLastRow = index === brs.length - 1;
      const isSection =
        br.blocks.length === 1 && br.blocks[0].blockType === 'section';

      const needsRowBreak = !(isLastRow || isSection);

      if (needsRowBreak) {
        flattened.push('row-break');
      }
    });

    return flattened;
  }, []);

  const removeDraggingFromStartRow = (startRow, sourceIndex, newBlockRows) => {
    let rowRemoved = false;
    const startRowBlocks = Array.from(startRow.blocks);
    startRowBlocks.splice(sourceIndex, 1);

    if (!startRowBlocks.length) {
      // remove empty rows for now
      newBlockRows.splice(newBlockRows.indexOf(startRow), 1);
      rowRemoved = true;
    } else {
      const newStartRow = { ...startRow, blocks: startRowBlocks };
      newBlockRows.splice(newBlockRows.indexOf(startRow), 1, newStartRow);
    }

    return [newBlockRows, rowRemoved];
  };

  const onDragEnd = useCallback(
    (result) => {
      setDragHasJustEnded(true);
      setBeforeCaptureDraggingId(undefined);
      setDraggingId(undefined);
      setDraggingSource(undefined);
      setDropDestination(undefined);

      const { destination, source, draggableId } = result;
      const draggingBlock = blocks.find((b) => b.id === draggableId);
      let rowRemoved = false;

      // Make sure the dragged block is selected
      if (!rawBlockTypes.includes(draggingBlock.blockType)) {
        selectBlock(draggingBlock.id);
      }

      if (
        !destination ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return;
      }

      const startRow = blockRows.find(
        (row) => row.index === source.droppableId,
      );
      const finishRow = blockRows.find(
        (row) => row.index === destination.droppableId,
      );

      let newBlockRows = [...blockRows];

      // moving within the same row
      if (startRow === finishRow) {
        const newBlocks = Array.from(startRow.blocks);
        newBlocks.splice(source.index, 1);
        newBlocks.splice(destination.index, 0, draggingBlock);

        const newRow = { ...startRow, blocks: newBlocks };
        newBlockRows[newBlockRows.indexOf(startRow)] = newRow;
        persistReorder(flattenBlockRows(newBlockRows));
        return;
      }

      // moving to a new row
      if (destination.droppableId.startsWith('new-row')) {
        [newBlockRows, rowRemoved] = removeDraggingFromStartRow(
          startRow,
          source.index,
          newBlockRows,
        );

        const sourceRowIndex = parseInt(
          source.droppableId.slice(source.droppableId.lastIndexOf('-') + 1),
          10,
        );

        let newRowIndex = parseInt(
          destination.droppableId.slice(
            destination.droppableId.lastIndexOf('-') + 1,
          ),
          10,
        );

        // This is to deal with the indexes being funky because an empty row has been removed.
        if (newRowIndex < sourceRowIndex || !rowRemoved) {
          newRowIndex += 1;
        }

        const newRow = { blocks: [draggingBlock] };
        newBlockRows.splice(newRowIndex, 0, newRow);

        newBlockRows = newBlockRows.map((row, index) => ({
          ...row,
          index: `block-row-${index}`,
        }));

        persistReorder(flattenBlockRows(newBlockRows));
        return;
      }

      // moving between existing rows
      [newBlockRows, rowRemoved] = removeDraggingFromStartRow(
        startRow,
        source.index,
        newBlockRows,
      );

      const finishRowBlocks = Array.from(finishRow.blocks);
      finishRowBlocks.splice(destination.index, 0, draggingBlock);

      const newFinishRow = { ...finishRow, blocks: finishRowBlocks };
      newBlockRows.splice(newBlockRows.indexOf(finishRow), 1, newFinishRow);

      persistReorder(flattenBlockRows(newBlockRows));
    },
    [blocks, blockRows, flattenBlockRows, persistReorder, selectBlock],
  );

  const onDragStart = (result) => {
    setDraggingId(result.draggableId);
    setDraggingSource(result.source);
  };

  const onDragUpdate = (result) => {
    setDropDestination(result.destination);
  };

  const onBeforeCapture = (result) => {
    setBeforeCaptureDraggingId(result.draggableId);
  };

  const draggingWasOnlyOneInFirstRow = useMemo(
    () =>
      size(get('blocks', blockRows[0])) === 1 &&
      map('id')(blockRows[0].blocks).includes(beforeCaptureDraggingId),
    [beforeCaptureDraggingId, blockRows],
  );

  const afterScroll = useCallback(() => {
    dispatch(latestBlockIdUnset({ id: boardId }));
  }, [boardId, dispatch]);

  return (
    <Layout direction='column'>
      <Layout
        direction='column'
        onScroll={!maximisedBlockId ? setScrollPosition : undefined}
        ref={gridRef}
      >
        {maximisedBlockId ? (
          <Block
            key={maximisedBlockId}
            id={maximisedBlockId}
            removeBlocks={handleDelete}
          />
        ) : (
          <>
            <BlockGridWrapper enableReorderBlocks onClick={handleReset}>
              <DragDropContext
                onBeforeCapture={onBeforeCapture}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragUpdate={onDragUpdate}
              >
                <Droppable droppableId='new-row-top' direction='horizontal'>
                  {(provided, snapshot) => (
                    <>
                      <NewBlockRowGridTop
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <DropDestinationBar
                          direction='horizontal'
                          visible={
                            !draggingWasOnlyOneInFirstRow &&
                            snapshot.isDraggingOver
                          }
                        />
                      </NewBlockRowGridTop>
                      <div style={{ display: 'none' }}>
                        {provided.placeholder}
                      </div>
                    </>
                  )}
                </Droppable>

                {blockRows.map((row) => (
                  <BlockRow
                    afterScroll={afterScroll}
                    beforeCaptureDraggingId={beforeCaptureDraggingId}
                    blockRows={blockRows}
                    blocks={row.blocks}
                    dragHasJustEnded={dragHasJustEnded}
                    draggingId={draggingId}
                    draggingSource={draggingSource}
                    dropDestination={dropDestination}
                    droppableId={row.index}
                    handleDelete={handleDelete}
                    key={row.index}
                    latestBlockId={latestBlockId}
                    selectedRef={selectedRef}
                    setDragHasJustEnded={setDragHasJustEnded}
                  />
                ))}

                <div style={{ margin: '6px auto 0' }}>
                  <AddBlockMenu
                    workspaceId={workspaceId}
                    boardId={boardId}
                    hasBlocks={size(blockRows) > 0}
                  />
                </div>
              </DragDropContext>
            </BlockGridWrapper>

            <AnimatedPopover
              justify='center'
              offset={49}
              open={showPrompt && size(selectedBlockIds) === 1}
              placement='top'
              preventOverflow={false}
              targetRef={footerRef}
            >
              <Prompt
                dismissOnKeyPress={false}
                showPrompt={showPrompt && size(selectedBlockIds) === 1}
                showTriangle={false}
                title={promptText}
              >
                <div />
              </Prompt>
            </AnimatedPopover>
          </>
        )}
        {deleteDialogData && (
          <ConfirmationDialog
            cancelText='Don’t delete'
            confirmText={deleteDialogData.confirmText}
            icon='question'
            title={deleteDialogData.title}
            onCancel={handleCloseDeleteModal}
            onConfirm={handleConfirmDelete}
            variant='danger'
            minWidth={320}
          >
            {deleteDialogData.subtext}
          </ConfirmationDialog>
        )}
      </Layout>

      <footer ref={footerRef} />
    </Layout>
  );
};

BlockGrid.propTypes = propTypes;
BlockGrid.defaultProps = defaultProps;

export default BlockGrid;
