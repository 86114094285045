import styled from 'styled-components';
import { padding } from 'polished';

const LegacyContent = styled('div')(
  ({ theme }) => ({
    boxSizing: 'border-box',
    color: theme.color.default,
    fontFamily: theme.fontFamily.default,
    fontSize: theme.fontSize.default,
    lineHeight: theme.lineHeight.default,
    overflow: 'auto',
    ...padding(null, 16),
    width: '100%',
  }),
  ({ appearance, theme }) =>
    ({
      dark: {
        backgroundColor: theme.color.gray2,
      },
    })[appearance],
  ({ spacing }) =>
    ({
      small: {
        ...padding(8, null),
      },
      medium: {
        ...padding(16, null),
      },
    })[spacing],
);

export { LegacyContent };
