import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash/fp';
import { appAdd } from 'actions/appAdd';
import { appsUpgrade } from 'actions/appsUpgrade';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';

const { reducer, actions } = createSlice({
  name: 'apps',
  initialState: {},
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(workspaceFetch.fulfilled, (state, action) =>
      keyBy('app', action.payload.apps),
    );

    builder.addCase(workspacePatch.fulfilled, (state, action) =>
      keyBy('app', action.payload.apps),
    );

    builder.addCase(appsUpgrade.fulfilled.type, (state, action) =>
      keyBy('app', action.payload.apps),
    );

    builder.addCase(appAdd.fulfilled.type, (state, action) =>
      keyBy('app', action.payload.apps),
    );
  },
});

export { reducer, actions };
