import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { find, get, isEqual } from 'lodash/fp';
import { Stack } from '@kinesis/bungle';
import {
  setIndicatorValue,
  setIndicatorTable,
  setIndicatorTime,
  setIndicatorScenario,
} from 'utils/visualisationConfigUtils';
import { VisualisationMeasureSelect } from 'components/visualisation-measure-select';
import { VisualisationScenarioSelect } from 'components/visualisation-scenario-select';
import { VisualisationTimeSelect } from 'components/visualisation-time-select';
import { VisualisationTableSelect } from 'components/visualisation-table-select';
import { useSelectorWithProps } from 'hooks';
import blockValueSelector from 'selectors/blockValueSelector';
import tablesSelector from 'selectors/tablesSelector';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const IndicatorConfiguration = ({ blockId, onChange, workspaceId }) => {
  const blockValue = useSelectorWithProps(blockValueSelector, {
    blockId,
    workspaceId,
  });

  const tables = useSelectorWithProps(tablesSelector, { workspaceId });
  const table = find({ family: get('table', blockValue) }, tables);
  const tableIdentifier = get('resource', table);

  const handleChangeTable = useCallback(
    (selectedTable) => {
      const currentTableResource = find(
        (resource) =>
          isEqual(get('family', resource), get('table', blockValue)),
        tables,
      );
      const selectedTableResource = find(
        (resource) => isEqual(get('family', resource), selectedTable),
        tables,
      );
      onChange(
        setIndicatorTable(
          currentTableResource,
          selectedTableResource,
          selectedTable,
          blockValue,
        ),
      );
    },
    [blockValue, onChange, tables],
  );

  const handleChangeValue = useCallback(
    (value) => onChange(setIndicatorValue(table, value, blockValue)),
    [blockValue, onChange, table],
  );

  const handleChangeScenario = useCallback(
    (scenario) => onChange(setIndicatorScenario(scenario, blockValue)),
    [blockValue, onChange],
  );

  const handleChangeTime = useCallback(
    (value) => onChange(setIndicatorTime(value, blockValue)),
    [blockValue, onChange],
  );

  return (
    <Stack space='medium'>
      <VisualisationScenarioSelect
        type='indicator'
        blockId={blockId}
        value={get('scenario', blockValue)}
        onChange={handleChangeScenario}
        workspaceId={workspaceId}
      />

      <VisualisationTableSelect
        type='indicator'
        blockId={blockId}
        value={get('table', blockValue)}
        onChange={handleChangeTable}
        workspaceId={workspaceId}
      />

      <VisualisationMeasureSelect
        blockId={blockId}
        label='Value'
        table={tableIdentifier}
        value={get('value', blockValue)}
        onChange={handleChangeValue}
        workspaceId={workspaceId}
      />

      <VisualisationTimeSelect
        blockId={blockId}
        table={tableIdentifier}
        value={get('time', blockValue)}
        onChange={handleChangeTime}
        workspaceId={workspaceId}
      />
    </Stack>
  );
};

IndicatorConfiguration.propTypes = propTypes;

export { IndicatorConfiguration };
