import PropTypes from 'prop-types';
import { get, pipe, trim } from 'lodash/fp';
import { useLocation } from 'react-router-dom';
import { InPlaceInput } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { ToolboxPanel } from 'components/toolbox/toolbox-panel';
import boardSelector from 'selectors/boardSelector';
import TableDefinitionsSection from 'components/toolbox/sections/TableDefinitionsSection';
import { boardUpdate } from 'actions/boardUpdate';
import { useBoardId, useSelectorWithProps } from 'hooks';
import useAction from 'hooks/useAction';
import usePermission from 'hooks/usePermission';
import resourcesSelector from 'selectors/resourcesSelector';

const propTypes = {
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const BoardDetailsPane = ({ workspaceId }) => {
  const editor = usePermission('editor');
  const boardId = useBoardId();
  const label = useSelectorWithProps(pipe(boardSelector, get('label')), {
    boardId,
    workspaceId,
  });
  const nameInitialized = useSelectorWithProps(
    pipe(boardSelector, get('nameInitialized')),
    { boardId, workspaceId },
  );
  const handleUpdate = useAction(boardUpdate);

  const handleNameChange = (value) => {
    const name = trim(value);
    if (name.length) {
      handleUpdate({ boardId, workspaceId, params: { name } });
    }
  };

  const location = useLocation();
  const { autoSelect = false } = location.state || {};
  const definitions = useSelectorWithProps(resourcesSelector, {
    boardId,
    workspaceId,
  });

  if (!boardId) {
    return null;
  }

  return (
    <ToolboxPanel fadeScroll>
      <Content as='header' overflow='visible' padding='medium'>
        <InPlaceInput
          autoFocus={autoSelect}
          disabled={!editor}
          onChange={handleNameChange}
          placeholder='New board'
          value={nameInitialized ? label : undefined}
          variant='title'
        />
      </Content>

      <TableDefinitionsSection definitions={definitions} />
    </ToolboxPanel>
  );
};

BoardDetailsPane.propTypes = propTypes;
BoardDetailsPane.defaultProps = defaultProps;

export { BoardDetailsPane };
