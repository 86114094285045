import PropTypes from 'prop-types';
import {
  ModalHeaderActionInner,
  ModalHeaderActionOuter,
} from './modal-header-action.styles';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: undefined,
};

const ModalHeaderAction = ({ children, ...restProps }) => (
  <ModalHeaderActionOuter {...restProps}>
    <ModalHeaderActionInner>{children}</ModalHeaderActionInner>
  </ModalHeaderActionOuter>
);

ModalHeaderAction.propTypes = propTypes;
ModalHeaderAction.defaultProps = defaultProps;

// eslint-disable-next-line import/prefer-default-export
export { ModalHeaderAction };
