import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const datasetSetDescription = createAsyncThunk(
  'dataset/set-description',
  async ({ id, description }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.put(`/api/datasets/${id}/description`, {
      description,
    });
    return response.data;
  },
);

export { datasetSetDescription };
