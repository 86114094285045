import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty, map, noop } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Button, H2, Subheading } from '@kinesis/bungle';
import { Inline as OldInline } from '@kinesis/bungle/legacy';
import dependentAppsSelector from 'selectors/dependentAppsSelector';
import { appAdd } from 'actions/appAdd';
import { useSelectorWithProps } from 'hooks';
import { AppHeaderContent } from './app-management.styles';
import AddConfirmationDialog from './app-management.add-confirmation-dialog';

const propTypes = {
  app: PropTypes.object.isRequired,
  appName: PropTypes.string.isRequired,
  editor: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool,
  publisher: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  isLoaded: false,
};

const AppManagementHeader = ({
  app,
  appName,
  editor,
  isLoaded,
  publisher,
  workspaceId,
}) => {
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const isAddingApp = useSelector(get(['misc', 'isAddingApp']));
  const dependencies = useSelectorWithProps(dependentAppsSelector, {
    app: app.id,
    workspaceId,
  });
  const onAddClick = useCallback(() => {
    if (isEmpty(dependencies)) {
      dispatch(appAdd({ app, dependencies, workspaceId }));
    } else {
      setShowConfirmationModal(true);
    }
  }, [app, dependencies, dispatch, workspaceId]);
  const onAddConfirmation = useCallback(() => {
    dispatch(appAdd({ app, dependencies, workspaceId }));
    setShowConfirmationModal(false);
  }, [app, dependencies, dispatch, workspaceId]);

  return (
    <AppHeaderContent>
      <header>
        <H2>{appName}</H2>
        <Subheading>{publisher}</Subheading>
      </header>
      <OldInline space='small' alignX='right'>
        {isLoaded ? (
          <Button
            disabled
            onClick={() => console.log("Clicked 'Remove app'")}
            variant='secondary'
          >
            Remove app
          </Button>
        ) : (
          <Button
            disabled={!editor}
            loading={isAddingApp}
            onClick={isAddingApp ? noop : onAddClick}
            variant='action'
          >
            Add app
          </Button>
        )}
      </OldInline>
      {showConfirmationModal && (
        <AddConfirmationDialog
          dependencyLabels={map('label', dependencies)}
          appName={appName}
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={onAddConfirmation}
        />
      )}
    </AppHeaderContent>
  );
};

AppManagementHeader.defaultProps = defaultProps;
AppManagementHeader.propTypes = propTypes;

export default AppManagementHeader;
