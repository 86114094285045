import { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  dotPlotClickHandler,
  handleBubbleSelectPointValue,
  returnBubblesToUnselectedState,
} from 'utils/charts/bubble';
import scatterChartCapsuleSelector from 'selectors/scatterChartCapsuleSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import ChartImpl from './ChartImpl';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  boardId: PropTypes.number.isRequired,
  perspectives: PropTypes.object,
  privacy: PropTypes.oneOf(['private', 'public']),
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  perspectives: undefined,
  privacy: 'private',
};

const ScatterChart = ({
  blockId,
  boardId,
  perspectives,
  privacy,
  scenarioId,
  workspaceId,
  ...restProps
}) => {
  const { options } = useSelectorWithProps(scatterChartCapsuleSelector, {
    blockId,
    boardId,
    perspectives,
    public: privacy === 'public',
    scenarioId,
    workspaceId,
  });

  const handleCrosshairClick = useCallback((chart) => {
    dotPlotClickHandler(chart);
  }, []);

  const handleSelectPointValue = useCallback((chart, value) => {
    handleBubbleSelectPointValue(chart, value);
  }, []);

  return (
    <ChartImpl
      onExternalClick={returnBubblesToUnselectedState}
      onCrosshairClick={handleCrosshairClick}
      onSelectPointValue={handleSelectPointValue}
      options={options}
      {...restProps}
    />
  );
};

ScatterChart.propTypes = propTypes;
ScatterChart.defaultProps = defaultProps;

export default ScatterChart;
