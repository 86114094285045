import { set } from 'lodash/fp';
import { locationCreate } from 'actions/locationCreate';
import { appAdd } from 'actions/appAdd';
import { workspaceFetch } from 'actions/workspaceFetch';
import { workspacePatch } from 'actions/workspacePatch';
import { geographyUpdate } from 'actions/geographyUpdate';
import { scenarioSend } from 'actions/scenarioSend';
import { tagUpdate } from 'actions/tagUpdate';

// FIX this should be addressed, but is a new rule, and don't
// want to bundle a large refactor so just leaving the fixes.
// This one should change to createSlice.
// eslint-disable-next-line default-param-last
function historyReducer(state = {}, action) {
  switch (action.type) {
    case locationCreate.fulfilled.type: {
      return {
        ...state,
        ...action.payload.scenario.history,
      };
    }
    case geographyUpdate.fulfilled.type:
    case tagUpdate.fulfilled.type:
    case scenarioSend.fulfilled.type:
    case workspacePatch.fulfilled.type:
    case appAdd.fulfilled.type:
    case workspaceFetch.fulfilled.type: {
      return {
        ...state,
        ...action.payload.history,
      };
    }
    case workspaceFetch.rejected.type: {
      return set('errors', action.payload.errors, state);
    }

    default:
  }

  return state;
}

export default historyReducer;
