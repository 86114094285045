import { useContext, useState } from 'react';
import { GlobalEditContext } from 'contexts/global-edit-context';

function useGlobalEditContext() {
  const contextState = useContext(GlobalEditContext);
  const fallbackState = useState(true);

  // use fallback if used outside provider context
  return contextState.length > 1 ? contextState : fallbackState;
}

export default useGlobalEditContext;
