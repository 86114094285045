import { createSelector } from 'reselect';
import { get, map } from 'lodash/fp';
import blocksSelector from 'selectors/blocksSelector';
import scenariosSelector from 'selectors/scenariosSelector';
import {
  normalizeState,
  requiredScenariosOf,
  requiredTablesOf,
} from 'data/block';

const requirementsSelector = createSelector(
  blocksSelector,
  scenariosSelector,
  (state, props) => props.currentScenarioId,
  (blocks, scenarios, currentScenarioId) =>
    map((block) => {
      const tables = requiredTablesOf(block.block);
      const all = map((s) => ({
        blockId: block.id,
        scenarioId: parseInt(s.id, 10),
        isDefault: s.isDefault,
        scenarioKey:
          get(['draft', 'scenario'], s) || get(['published', 'scenario'], s),
        tables,
        state: normalizeState(get(['scenarios', s.id, 'state'], block)),
      }))(scenarios);

      return {
        blockId: block.id,
        scenarios: requiredScenariosOf(
          block,
          parseInt(currentScenarioId, 10),
          all,
        ),
      };
    }, blocks),
);

export default requirementsSelector;
