import { GeoJsonLayer, ScatterplotLayer } from '@deck.gl/layers';
import {
  curry,
  filter,
  get,
  isEmpty,
  pipe,
  reject,
  isArray,
  isNil,
} from 'lodash/fp';
import chroma from 'chroma-js';
import { lighten } from 'utils/colors';

const position = (location) => [location.longitude, location.latitude];

const fillOpacity = (shapey, focus) => {
  if (!focus) return 64;
  return shapey ? 115 : 255;
};

const fillColor = curry((focus, shapey, object) => [
  ...chroma(get(['layerColor', 'fill'], object)).rgb(),
  fillOpacity(shapey, focus),
]);

const lineColor = (object) =>
  chroma(get(['layerColor', 'outline'], object)).rgb();

const lightenedLineColor = (object) =>
  chroma(lighten(get(['layerColor', 'outline'], object))).rgb();

const hoverLayer = ({ data, focus }) =>
  new ScatterplotLayer({
    id: 'location-hover-layer',
    data,
    pickable: false,
    stroked: true,
    filled: false,
    radiusMinPixels: 8,
    lineWidthMinPixels: 2,
    getRadius: 16,
    getPosition: position,
    getLineColor: focus ? lineColor : lightenedLineColor,
    getLineWidth: 4,
  });

const selectedLayer = ({ data, onClick, onHover }) =>
  new ScatterplotLayer({
    id: 'location-selected-layer',
    data,
    pickable: true,
    stroked: true,
    filled: true,
    radiusMinPixels: 8,
    lineWidthMinPixels: 2,
    getRadius: 16,
    getPosition: position,
    getFillColor: fillColor(true, false),
    getLineColor: lineColor,
    getLineWidth: 4,
    onClick,
    onHover,
  });

const baseLayer = ({ data, focus, onClick, onHover }) =>
  new ScatterplotLayer({
    id: 'location-base-layer',
    data,
    pickable: true,
    stroked: true,
    filled: true,
    radiusMinPixels: 8,
    lineWidthMinPixels: 1,
    getRadius: 16,
    getPosition: position,
    getFillColor: fillColor(focus, false),
    getLineColor: [255, 255, 255],
    getLineWidth: 2,
    onClick,
    onHover,
  });

const shapeLayers = ({
  data,
  hoverId,
  onClick,
  onHover,
  selectedPredicate,
  selectedData,
  visible,
  focus,
}) => [
  new GeoJsonLayer({
    id: 'geojson-base-layer',
    visible,
    data,
    pickable: true,
    stroked: true,
    filled: true,
    getFillColor: get(['properties', 'colors', 'base']),
    getLineWidth: 1,
    lineWidthMinPixels: 1,
    getLineColor: [255, 255, 255],
    onClick,
    onHover,
  }),
  new GeoJsonLayer({
    id: 'geojson-diff-base-layer',
    visible: visible && focus,
    data,
    pickable: false,
    stroked: true,
    filled: true,
    getFillColor: get(['properties', 'colors', 'diff']),
    getLineWidth: 1,
    lineWidthMinPixels: 1,
    getLineColor: [255, 255, 255],
  }),
  new GeoJsonLayer({
    id: 'geojson-selected-layer',
    visible: visible && !isEmpty(selectedData),
    data: selectedData,
    pickable: false,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getFillColor: get(['properties', 'colors', 'diff']),
    getLineColor: get(['properties', 'colors', 'selectedBorder']),
    getLineWidth: 4,
  }),
  new GeoJsonLayer({
    id: 'geojson-hover-layer',
    visible: visible && !isNil(hoverId) && !selectedPredicate({ id: hoverId }),
    data: filter(({ properties }) => properties.id === hoverId, data),
    pickable: false,
    stroked: true,
    filled: false,
    lineWidthMinPixels: 2,
    getFillColor: [0, 0, 0, 0],
    getLineColor: get(['properties', 'colors', 'selectedBorder']),
    getLineWidth: 4,
  }),
];

// FUTURE: this can be heavily optimised, see bubble-layers, use visible,
// and be more selective about changing the data that is fed to base layer.
const scenarioLocationLayers = ({
  shapeLocations,
  pointLocations,
  hoverId,
  selectedId,
  dragCoordinate,
  focus,
  ...events
}) => {
  const selectedPredicate = isArray(selectedId)
    ? ({ id }) => selectedId.includes(id)
    : ({ id }) => id === selectedId;
  const base = reject(selectedPredicate, pointLocations);
  const hover = pipe(
    filter({ id: hoverId }),
    reject(selectedPredicate),
  )(pointLocations);
  const selected = filter(selectedPredicate, pointLocations);
  const selectedShapeData = filter(
    ({ properties }) => selectedPredicate(properties),
    shapeLocations,
  );
  const layers = [];
  layers.push(
    ...shapeLayers({
      data: shapeLocations,
      selectedPredicate,
      selectedData: selectedShapeData,
      focus,
      hoverId,
      selectedId,
      visible: true,
      ...events,
    }),
  );
  layers.push(
    baseLayer({
      data: base,
      focus,
      ...events,
    }),
  );
  if (!isEmpty(selected)) {
    layers.push(selectedLayer({ data: selected, ...events }));
  }
  if (!isEmpty(hover)) {
    layers.push(hoverLayer({ data: hover, focus }));
  }
  return layers;
};

const visualisationLocationLayers = ({
  locations,
  hoverId,
  selectedId,
  dragCoordinate,
  focus,
  ...events
}) => {
  const base = pipe(reject({ id: selectedId }))(locations);
  const hover = pipe(
    filter({ id: hoverId }),
    reject({ id: selectedId }),
  )(locations);
  const selected = pipe(filter({ id: selectedId }))(locations);
  const layers = [];
  layers.push(
    baseLayer({
      data: base,
      focus,
      ...events,
    }),
  );
  if (!isEmpty(selected)) {
    layers.push(selectedLayer({ data: selected, ...events }));
  }
  if (!isEmpty(hover)) {
    layers.push(hoverLayer({ data: hover, focus }));
  }
  return layers;
};

const locationLayers = scenarioLocationLayers;

export {
  locationLayers,
  visualisationLocationLayers,
  scenarioLocationLayers,
  fillColor,
};
