import { createCachedSelector } from 're-reselect';
import { find, get, isEqual, isNil, map, pipe, fromPairs } from 'lodash/fp';
import { pickToArray } from 'utils/functionalUtils';
import blockStateSelector from 'selectors/blockStateSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import schemaSelector from 'selectors/schemaSelector';
import publicVisualisationContextsSelector from 'selectors/publicVisualisationContextsSelector';

const publicVisualisationDataSelector = createCachedSelector(
  pipe(blockStateSelector, get('perspectives')),
  schemaSelector,
  publicVisualisationContextsSelector,
  pipe(workspaceSelector, get(['data', 'entities'])),
  (perspectives, tableSchema, selectedPerspectives, data) => {
    const activePerspectives = pipe(
      map(pickToArray(['key', 'value'])),
      fromPairs,
    )(selectedPerspectives);
    const activePerspectiveId = pipe(
      find(
        (p) =>
          isNil(p.perspective) || isEqual(p.perspective, activePerspectives),
      ),
      get('id'),
    )(perspectives);
    return get(activePerspectiveId, data);
  },
)((state, props) => props.blockId);

export default publicVisualisationDataSelector;
