import { createSelector } from 'reselect';
import {
  get,
  flatMap,
  pipe,
  values,
  find,
  filter,
  isNil,
  map,
  includes,
} from 'lodash/fp';
import appManagementSelector from 'selectors/appManagementSelector';
import workspaceAppsSelector from 'selectors/workspaceAppsSelector';

const appUpgradesMissingDependencySelector = createSelector(
  appManagementSelector,
  workspaceAppsSelector,
  (state, props) => props.upgrades,
  (apps, workspaceApps, upgrades) => {
    const dependencies = flatMap(get(['available', 'dependencies']), upgrades);
    const dependencyApps = pipe(
      values,
      filter(
        ({ owner, key }) =>
          !isNil(find({ owner: owner.key, key }, dependencies)),
      ),
    )(apps);
    const workspaceAppIds = map('app', workspaceApps);
    const required = filter(
      ({ id }) => !includes(id, workspaceAppIds),
      dependencyApps,
    );
    return required;
  },
);

export default appUpgradesMissingDependencySelector;
