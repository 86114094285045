import {
  curry,
  every,
  filter,
  flatMap,
  get,
  identity,
  isEmpty,
  keys,
  pickBy,
  reject,
  some,
  update,
} from 'lodash/fp';

const filterLocationsByAttributes = curry(
  (attributeFilters, locationAttributes) => {
    const op = (name) => (name === 'and' ? every : some);
    const serviceValues = keys(
      pickBy(identity, attributeFilters.service.values),
    );
    const usageValues = keys(pickBy(identity, attributeFilters.usage.values));
    const serviceOperation = isEmpty(serviceValues)
      ? 'and'
      : attributeFilters.service.operation;
    const usageOperation = isEmpty(usageValues)
      ? 'and'
      : attributeFilters.usage.operation;
    const serviceEntries = flatMap(
      'entries',
      get(['service-v2', 'time_series'], locationAttributes),
    );
    const usageCategories = flatMap(
      'categories',
      get(['usage-v2', 'time_series'], locationAttributes),
    );

    return every(identity, [
      op(serviceOperation)(
        (val) =>
          val === 'none'
            ? isEmpty(serviceEntries)
            : some({ key: val }, serviceEntries),
        serviceValues,
      ),
      op(usageOperation)(
        (val) =>
          val === 'none'
            ? isEmpty(usageCategories)
            : some({ key: val }, usageCategories),
        usageValues,
      ),
    ]);
  },
);

const updateAttribute = (location, attribute) =>
  update(
    'attributes',
    (attributes) =>
      reject(
        {
          attribute: attribute.attribute,
          type: attribute.type,
        },
        attributes,
      ).concat([attribute]),
    location,
  );

const revertAttribute = (original, location, predicate) =>
  update(
    'attributes',
    (attributes) =>
      reject(predicate, attributes).concat(
        filter(predicate, original.attributes),
      ),
    location,
  );

export { filterLocationsByAttributes, updateAttribute, revertAttribute };
