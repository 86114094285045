import { identity, minBy, range, uniq } from 'lodash/fp';
import stats from 'stats-lite';

// NOTE: This is not the best algorithm, it is just good enough
//       until the back-end supports binning in queries though.
const bin = (data) => {
  if (data.length === 0) return [];
  if (data.length === 1) return [data[0] - data[0] / 2, data[0] + data[0] / 2];
  const bins = Math.min(5, Math.max(2, Math.floor(data.length / 2)));
  const cuts = bins - 1;
  const width = 1 / bins;
  return uniq(
    range(0, cuts).map((n) => stats.percentile(data, (n + 1) * width)),
  );
};

const binWithBounds = (data) => {
  const bins = bin(data);
  if (data.length <= 1) return bins;
  const candidate = uniq([Math.min(0, minBy(identity, data))].concat(bins));
  if (candidate.length === 1 && candidate[0] === 0) {
    return uniq([0].concat(bin(data.filter((d) => d !== 0))));
  }

  return candidate;
};

export { bin, binWithBounds };
