import PropTypes from 'prop-types';
import styled from 'styled-components';

const ChartDetailsWrapper = styled.div`
  background-color: #ffffff;
  line-height: 20px;
`;

const ChartDetailsTitle = styled.div`
  color: rgba(0, 0, 0, 0.85);
`;

const ChartDetailsItem = styled.div`
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  margin-top: 4px;
`;

const ChartDetailsIcon = styled.span`
  color: ${(props) => props.color};
  font-size: 20px;
  margin-right: 8px;
  &::before {
    content: '\u25CF';
  }
`;

const ChartDetailsLabel = styled.span`
  flex: 1 1 0;
  text-align: left;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ChartDetailsValue = styled.span`
  color: rgba(0, 0, 0, 0.85);
  flex: 0 0 auto;
  font-weight: 500;
  text-align: right;
`;

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: undefined,
};

const ChartDetails = ({ items, title }) => (
  <ChartDetailsWrapper>
    {title && <ChartDetailsTitle>{title}</ChartDetailsTitle>}
    {items &&
      items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ChartDetailsItem key={i}>
          {item.color && <ChartDetailsIcon color={item.color} />}
          <ChartDetailsLabel>{item.label}</ChartDetailsLabel>
          <ChartDetailsValue>{item.value}</ChartDetailsValue>
        </ChartDetailsItem>
      ))}
  </ChartDetailsWrapper>
);

ChartDetails.propTypes = propTypes;
ChartDetails.defaultProps = defaultProps;

export default ChartDetails;
