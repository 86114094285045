import styled from 'styled-components';

const WorkspaceCardsWrapper = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 16px 16px 24px;
  width: 976px;
`;

export { WorkspaceCardsWrapper };
