import { useRef } from 'react';
import { usePrevious } from '@kinesis/bungle';
import { nanoid } from 'nanoid';
import { difference, indexOf, map, placeholder, pipe, slice } from 'lodash/fp';

const mapWithIndex = map.convert({ cap: false });

function useKeyedList(list = []) {
  const keysRef = useRef();
  const prevList = usePrevious(list);

  if (!prevList) {
    keysRef.current = map(() => nanoid(), list);
  } else if (list.length > prevList.length) {
    const newIndicies = pipe(
      difference,
      slice(0, list.length - prevList.length),
      map(indexOf(placeholder, list)),
    )(list, prevList);

    newIndicies.forEach((index) => {
      keysRef.current = [
        ...slice(0, index, keysRef.current),
        nanoid(),
        ...slice(index, keysRef.current.length, keysRef.current),
      ];
    });
  } else if (list.length < prevList.length) {
    const oldIndicies = pipe(
      difference,
      slice(0, prevList.length - list.length),
      map(indexOf(placeholder, prevList)),
    )(prevList, list);

    oldIndicies.forEach((index) => {
      keysRef.current = [
        ...slice(0, index, keysRef.current),
        ...slice(index + 1, keysRef.current.length, keysRef.current),
      ];
    });
  }

  return mapWithIndex((value, i) => ({ key: keysRef.current[i], value }), list);
}

export default useKeyedList;
