import { createSelector } from 'reselect';
import { get, map } from 'lodash/fp';
import workspaceAppsSelector from './workspaceAppsSelector';

const workspaceAppVersionsSelector = createSelector(
  workspaceAppsSelector,
  map(get('version')),
);

export default workspaceAppVersionsSelector;
