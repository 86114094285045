import { createSelector } from 'reselect';
import {
  get,
  identity,
  pipe,
  reject,
  sortBy,
  sortedUniq,
  isNil,
} from 'lodash/fp';
import attributesSelector from './attributesSelector';

const scenarioDatesSelector = createSelector(
  pipe(attributesSelector, get('stages')),
  pipe(sortBy(identity), sortedUniq, reject(isNil)),
);

export default scenarioDatesSelector;
