import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Editor = styled.div`
  position: relative;
  color: ${({ theme }) => theme.color.gray8};
  max-width: 700px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: ${({ isFirst }) => (isFirst ? '16px' : '0')};
  margin-right: ${({ isLast }) => (isLast ? '16px' : '0')};
  font-size: ${({ theme }) => theme.fontSize.md}px;
  line-height: ${({ theme }) => theme.lineHeight.md};

  .remirror-editor-wrapper {
    p {
      margin: 0 0 12px;
    }
    ul {
      margin: -12px 0 12px 0;
      padding-left: 12px;
    }
    ul:first-child {
      margin: 0 0 12px;
    }
    *:last-child {
      margin: 0;
    }
  }

  .remirror-editor:focus {
    border: none;
    outline: none;
  }

  .remirror-is-empty:first-of-type::before {
    position: absolute;
    color: ${({ theme }) => theme.color.gray6};
    pointer-events: none;
    height: 0;
    content: attr(data-placeholder);
  }
`;

const Actions = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.gray2.hex};
  box-shadow: -4px 4px 8px 5px ${({ theme }) => theme.colors.gray2.hex};
`;

export { Actions, Editor, Container };
