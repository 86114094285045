import styled from 'styled-components';

const EmptyContainer = styled.div`
  padding: 16px 16px 24px 16px;
  margin: 0 auto;
  max-width: 752px;
  width: 100%;
`;

export { EmptyContainer };
