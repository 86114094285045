import { createSelector } from 'reselect';
import { get, pipe } from 'lodash/fp';
import blockStateSelector from './blockStateSelector';

const blockGeographySelector = createSelector(
  get('geography'),
  pipe(blockStateSelector, get('scenarios')),
  (state, props) => props.scenarioId,
  (geography, scenarios, scenarioId) => {
    const dataId = scenarios?.[scenarioId]?.dataId;
    const bundleId = geography.dataIds[dataId];

    return geography.bundles[bundleId];
  },
);

export default blockGeographySelector;
