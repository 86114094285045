import {
  cloneElement,
  isValidElement,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { TableCellWrapper, TableCellInner } from './table-cell.styles';
import { tableContext } from './table-context';

const propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  appearance: PropTypes.oneOf(['accent', 'dark', 'light']),
  children: PropTypes.node,
  columnIndex: PropTypes.number,
  variant: PropTypes.oneOf(['default', 'heading']),
};

const defaultProps = {
  align: 'left',
  appearance: 'light',
  children: undefined,
  columnIndex: undefined,
  variant: 'default',
};

const TableCell = ({
  align,
  appearance,
  children,
  columnIndex,
  variant,
  ...restProps
}) => {
  const ref = useRef();
  const {
    columnWidths,
    setColumnWidth,
    scrollWidth,
    stickyColumns,
    tableWidth,
  } = useContext(tableContext);
  const uniformWidth = columnWidths[columnIndex];

  useEffect(() => {
    if (typeof columnIndex !== 'number' || uniformWidth || !ref.current) {
      return;
    }

    const { width } = ref.current.getBoundingClientRect();

    setColumnWidth(columnIndex, Math.min(Math.ceil(width), 304));
  }, [columnIndex, setColumnWidth, uniformWidth]);

  return (
    <TableCellWrapper
      ref={ref}
      appearance={appearance}
      showEndBorder={tableWidth > scrollWidth}
      stickToX={get(columnIndex, stickyColumns)}
      variant={variant}
      width={uniformWidth}
      {...restProps}
    >
      <TableCellInner align={align}>
        {isValidElement(children)
          ? cloneElement(children, { columnIndex })
          : children}
      </TableCellInner>
    </TableCellWrapper>
  );
};

TableCell.propTypes = propTypes;
TableCell.defaultProps = defaultProps;

export { TableCell };
