import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { map, range } from 'lodash/fp';
import { format } from 'date-fns/fp';
import { Fieldset, InputGroup, Select, useUpdateEffect } from '@kinesis/bungle';
import ResettableFormItem from 'components/form/ResettableFormItem';
import {
  RelationalOperatorSelect,
  RelationalOperatorSelectContent,
} from 'components/form/RelationalOperatorSelect';
import useValueRange from 'hooks/useValueRange';
import { numberOfOperands } from 'utils/numberUtils';

const mapOptions = map((i) => ({
  label: format('MMMM', new Date(2000, i, 1)),
  value: i + 1,
}));

const propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    firstOperand: PropTypes.number,
    relationalOperator: PropTypes.string,
    secondOperand: PropTypes.number,
  }),
};

const defaultProps = {
  value: undefined,
};

const MonthFilter = ({ label, onChange, value }) => {
  const {
    firstOperand,
    relationalOperator,
    secondOperand,
    setFirstOperand,
    setRelationalOperator,
    setSecondOperand,
  } = useValueRange(value);
  const numOperands = numberOfOperands(relationalOperator);

  const handleReset = useCallback(() => {
    setFirstOperand(undefined);
    setRelationalOperator('all');
    setSecondOperand(undefined);
  }, [setFirstOperand, setRelationalOperator, setSecondOperand]);

  useUpdateEffect(() => {
    if (numOperands === 0) {
      onChange(null);
    } else if (
      firstOperand &&
      (numOperands === 1 || (numOperands === 2 && secondOperand))
    ) {
      onChange({
        firstOperand,
        relationalOperator,
        secondOperand,
      });
    }
  }, [firstOperand, numOperands, onChange, relationalOperator, secondOperand]);

  return (
    <ResettableFormItem
      showReset={relationalOperator !== 'all'}
      onReset={handleReset}
    >
      <Fieldset legend={label}>
        <RelationalOperatorSelect
          onChange={setRelationalOperator}
          value={relationalOperator}
        />
        {numOperands >= 1 && (
          <RelationalOperatorSelectContent>
            <InputGroup magnitude='large' variant='select'>
              <Select
                options={mapOptions(
                  range(
                    0,
                    numOperands > 1 && secondOperand ? secondOperand : 12,
                  ),
                )}
                onChange={setFirstOperand}
                itemsLabel='month'
                value={firstOperand}
              />
              {numOperands > 1 && (
                <Select
                  options={mapOptions(
                    range(firstOperand ? firstOperand - 1 : 0, 12),
                  )}
                  onChange={setSecondOperand}
                  itemsLabel='month'
                  value={secondOperand}
                />
              )}
            </InputGroup>
          </RelationalOperatorSelectContent>
        )}
      </Fieldset>
    </ResettableFormItem>
  );
};

MonthFilter.propTypes = propTypes;
MonthFilter.defaultProps = defaultProps;

export default MonthFilter;
