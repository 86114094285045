import { flatten } from 'lodash/fp';
import { opacify } from 'utils/colors';
import { column as chartOpacities } from 'utils/charts/opacities';

const selectColumnPoint = (point) => {
  const newColor = opacify(point.color, chartOpacities.normal);

  point.update(
    {
      selected: true,
      color: newColor,
      states: {
        select: {
          color: newColor,
          borderColor: `#ffffff`,
        },
      },
    },
    false,
    false,
  );
};

const deselectColumnPoint = (point) => {
  const newColor = opacify(point.color, chartOpacities.notSelected);

  point.update(
    {
      selected: false,
      color: newColor,
      borderColor: `#ffffff`,
    },
    false,
    false,
  );
};

const returnColumnsToUnselectedState = (points) => {
  points.forEach((point) => {
    const newColor = opacify(point.color, chartOpacities.normal);

    point.update(
      {
        selected: false,
        color: newColor,
      },
      false,
      false,
    );
  });
};

const deselectNonCategoryColumns = (chart) => {
  const point = chart.hoverPoint;

  if (point) {
    const nonCategoryPoints = flatten(
      point.series.chart.series.map((s) =>
        s.points.filter((p) => p.category !== point.category),
      ),
    );

    nonCategoryPoints.forEach((p) => {
      deselectColumnPoint(p);
    });
  }
};

const selectHoveredColumns = (chart) => {
  if (chart.hoverPoints) {
    chart.hoverPoints.forEach((point) => {
      selectColumnPoint(point);
    });
  }
};

const selectColumns = (chart, value) => {
  const allPoints = flatten(chart.series.map((s) => s.points));
  allPoints.forEach((point) => {
    if (point.category === value) {
      selectColumnPoint(point);
    } else {
      deselectColumnPoint(point);
    }
  });
};

export {
  returnColumnsToUnselectedState,
  deselectNonCategoryColumns,
  selectHoveredColumns,
  selectColumns,
};
