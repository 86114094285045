import { defaultTo, get, pipe } from 'lodash/fp';
import workspaceSelector from './workspaceSelector';

function makeAggregationFilterSelector(fieldName) {
  return (state, props = {}) => {
    const { blockId } = props;
    const workspace = workspaceSelector(state, props);

    return pipe(
      get(['aggregationFilters', 'blocks', blockId, fieldName]),
      defaultTo(get(['aggregationFilters', 'base', fieldName], workspace)),
    )(workspace);
  };
}

export default makeAggregationFilterSelector;
