import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import DeckGL from '@deck.gl/react';
import { StaticMap } from 'react-map-gl';
import {
  every,
  noop,
  concat,
  map,
  get,
  pipe,
  set,
  reject,
  eq,
} from 'lodash/fp';
import { useRect } from '@kinesis/bungle';
import { geographyBundle } from 'actions/geographyBundle';
import { ImportLocationTooltip } from 'components/import-location-tooltip';
import { Loading } from 'components/loading';
import { scenarioLocationLayers } from 'components/spatial-visualisation/location-layers';
import {
  baseColor,
  diffColor,
  selectedLineColor,
  deselectedLineColor,
} from 'components/spatial-visualisation/layer-colors';
import platformLocationGeographySelector from 'selectors/platformLocationGeographySelector';
import makePublicTokensSelector from 'selectors/makePublicTokenSelector';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import LIGHT_STYLE from 'components/map/styles/light.json';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MultiSelectPrompt } from 'components/locations-importer/multi-select-prompt';
import { SelectAllButton } from 'components/select-all-button';
import {
  knownPlatformLocations,
  buildKnownPlatformLocationLabels,
} from './platform-locations';

const Full = styled('div')`
  width: 100%;
  height: 100%;
`;

const mapboxAccessTokenSelector = makePublicTokensSelector('mapboxAccessToken');

const propTypes = {
  platformKey: PropTypes.string,
  setValues: PropTypes.func,
  values: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
};
const defaultProps = {
  platformKey: undefined,
  setValues: noop,
  values: [],
  options: [],
};

const buildHover = (object) => ({
  object,
  id: get(['properties', 'id'], object),
  label: get(['properties', 'id'], object),
});

const NSW = {
  longitude: 146,
  latitude: -32,
  zoom: 4,
};

const pointLocations = [];

const PlatformLocationsMap = ({ platformKey, setValues, values, options }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const ref = useRef();
  const rect = useRect(ref);
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);
  const [hover, setHover] = useState(undefined);
  const [hoverPosition, setHoverPosition] = useState(undefined);
  const geojson = useSelectorWithProps(platformLocationGeographySelector, {
    platformKey,
  });
  const knownPlatformLocationLabels = useMemo(
    () => buildKnownPlatformLocationLabels(platformKey),
    [platformKey],
  );

  const selectAll = useCallback(() => {
    setValues(map(get('value'), options));
  }, [options, setValues]);
  const clearAll = useCallback(() => {
    setValues([]);
  }, [setValues]);

  useEffect(() => {
    if (!geojson) {
      const geographies = map('geography', knownPlatformLocations[platformKey]);
      dispatch(
        geographyBundle({
          dataId: `platform-location-${platformKey}`,
          geographies,
        }),
      );
    }
  }, [dispatch, platformKey, geojson, knownPlatformLocationLabels]);

  const onHover = useCallback(
    ({ object, x, y }) => {
      if (object) {
        setHoverPosition({ x, y });
        setHover((current) =>
          !current || !current.object || current.object.id !== object.id
            ? buildHover(object)
            : current,
        );
      } else {
        setHover(undefined);
      }
    },
    [setHover, setHoverPosition],
  );
  const onClick = useCallback(
    ({ object }, { srcEvent }) => {
      if (object) {
        const { id } = object.properties;
        setValues((current) => {
          if (srcEvent.shiftKey) {
            if (current.includes(id)) {
              return reject(eq(id), current);
            }
            return concat(id, current);
          }
          return [id];
        });
        return true;
      }
    },
    [setValues],
  );

  const onMapClick = useCallback(() => {
    setValues([]);
  }, [setValues]);

  const color = useMemo(() => theme.color.layer.yellow, [theme]);

  const colors = useMemo(
    () => ({
      base: baseColor(color.fill),
      diff: diffColor(color.fill),
      deselectedBorder: deselectedLineColor(color.fill),
      selectedBorder: selectedLineColor(color.fill),
    }),
    [color],
  );

  const shapeLocations = useMemo(
    () =>
      pipe(
        get('features'),
        map((feature) =>
          pipe(
            set(['properties', 'colors'], colors),
            set(['properties', 'id'], knownPlatformLocationLabels[feature.id]),
          )(feature),
        ),
      )(geojson),
    [colors, geojson, knownPlatformLocationLabels],
  );

  const allOptionsSelected = useMemo(
    () => geojson && every((option) => values.includes(option.value), options),
    [values, options, geojson],
  );

  const layers = useMemo(
    () =>
      scenarioLocationLayers({
        shapeLocations,
        pointLocations,
        hoverId: get('id', hover),
        selectedId: values,
        onHover,
        onClick,
      }),
    [onHover, onClick, shapeLocations, hover, values],
  );
  if (!geojson) {
    return (
      <Full ref={ref}>
        <Loading />
      </Full>
    );
  }
  return (
    <MultiSelectPrompt>
      <Full ref={ref}>
        <DeckGL
          layers={layers}
          controller
          initialViewState={NSW}
          onClick={onMapClick}
        >
          <StaticMap
            mapboxApiAccessToken={mapboxAccessToken}
            mapStyle={LIGHT_STYLE}
            preventStyleDiffing
          />
        </DeckGL>
        {hover && hoverPosition && (
          <ImportLocationTooltip
            x={hoverPosition.x + rect.x}
            y={hoverPosition.y + rect.y}
            label={hover.label}
          />
        )}
        <SelectAllButton
          allSelected={allOptionsSelected}
          onSelectAll={selectAll}
          onClearAll={clearAll}
        />
      </Full>
    </MultiSelectPrompt>
  );
};

PlatformLocationsMap.propTypes = propTypes;
PlatformLocationsMap.defaultProps = defaultProps;
export { PlatformLocationsMap };
