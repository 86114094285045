import styled from 'styled-components';

const HorizontalPanes = styled('div')({
  alignItems: 'stretch',
  display: 'flex',
  flexBasis: '100%',
  flexDirection: 'row',
  flexGrow: 0,
  flexShrink: 0,
  height: '100%',
  minWidth: 0,
});

export { HorizontalPanes };
