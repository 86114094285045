import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isNil, map, max, min, pipe } from 'lodash/fp';
import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import boundsSelector from 'selectors/boundsSelector';
import colorFormatSelector from 'selectors/colorFormatSelector';
import colorStopsSelector from 'selectors/colorStopsSelector';
import workspaceInstantiationsSelector from 'selectors/workspaceInstantiationsSelector';
import publicVisualisationDataSelector from 'selectors/publicVisualisationDataSelector';
import publicSpatialVisualisationDataSelector from 'selectors/publicSpatialVisualisationDataSelector';
import layersSelector from 'selectors/layersSelector';
import filteredScenarioLocationsSelector from 'selectors/filteredScenarioLocationsSelector';
import makePublicTokenSelector from 'selectors/makePublicTokenSelector';
import workspaceSelector from 'selectors/workspaceSelector';
import Units from 'data/units';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import { getH3Bounds } from 'utils/boundsUtils';
import {
  toolboxLocationIdSelector,
  toolboxSpatialDetailIdSelector,
} from 'selectors/toolboxDetailSelectors';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import { useScenarioId } from 'hooks';
import useActions from 'hooks/useActions';
import SpatialVisualisation from './SpatialVisualisation';

const mapboxAccessTokenSelector = makePublicTokenSelector('mapboxAccessToken');

const units = new Units();

const isShiftKey = get(['srcEvent', 'shiftKey']);

const propTypes = {
  allowScrollZoom: PropTypes.bool,
  blockId: PropTypes.string.isRequired,
  boardId: PropTypes.number.isRequired,
  perspectives: PropTypes.object,
  viewMode: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired,
};

const defaultProps = {
  allowScrollZoom: undefined,
  perspectives: undefined,
};

const PublicSpatialVisualisation = ({
  allowScrollZoom,
  blockId,
  boardId,
  perspectives,
  viewMode,
  workspaceId,
}) => {
  const scenarioId = useScenarioId();
  const bounds = useSelectorWithProps(boundsSelector, {
    public: true,
    scenarioId,
    workspaceId,
  });
  const colorFormat = useSelectorWithProps(colorFormatSelector, {
    blockId,
    public: true,
    workspaceId,
  });
  const colorStops = useSelectorWithProps(colorStopsSelector, {
    blockId,
    boardId,
    perspectives,
    public: true,
    scenarioId,
    workspaceId,
  });
  const data = useSelectorWithProps(publicSpatialVisualisationDataSelector, {
    blockId,
    boardId,
    perspectives,
    public: true,
    scenarioId,
    workspaceId,
  });
  const unfilteredData = useSelectorWithProps(publicVisualisationDataSelector, {
    blockId,
    boardId,
    perspectives,
    public: true,
    workspaceId,
  });

  const layers = useSelectorWithProps(layersSelector, {
    public: true,
    workspaceId,
  });
  const locations = useSelectorWithProps(filteredScenarioLocationsSelector, {
    public: true,
    scenarioId,
    workspaceId,
  });
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);

  const sizeMin = pipe(map('size'), min)(unfilteredData);
  const sizeMax = pipe(map('size'), max)(unfilteredData);
  const visualisationLayerEnabled = useSelectorWithProps(
    pipe(workspaceSelector, get(['layers', 'visualisationLayerEnabled'])),
    { public: true, workspaceId },
  );
  const blockValueSpatial = useSelectorWithProps(
    pipe(blockValueSelector, get('spatial')),
    { blockId, public: true, workspaceId },
  );
  const schema = useSelectorWithProps(pipe(blockStateSelector, get('schema')), {
    blockId,
    public: true,
    workspaceId,
  });
  const hexBounds = useMemo(
    () =>
      isNil(get('hex', blockValueSpatial)) ? undefined : getH3Bounds(data),
    [blockValueSpatial, data],
  );
  const instantiations = useSelectorWithProps(workspaceInstantiationsSelector, {
    public: true,
    workspaceId,
  });

  const colorKey = get(['colour', 'column'], blockValueSpatial);
  const sizeKey = get(['size', 'column'], blockValueSpatial);

  const selectedBlockIds = useSelector(blockSelectionsSelector);
  const toolboxLocationId = useSelector(toolboxLocationIdSelector);
  const toolboxSpatialDetailId = useSelector(toolboxSpatialDetailIdSelector);

  const { select, removePane } = useActions(toolboxActions);
  const { selectBlock, toggleBlock } = useActions(blockSelectionsActions);

  const multiSelectCurrentBlock = useCallback(() => {
    toggleBlock(blockId);
  }, [blockId, toggleBlock]);

  const selectCurrentBlock = useCallback(() => {
    selectBlock(blockId);
  }, [blockId, selectBlock]);

  const onDetailSelect = useCallback(
    (spatialDetailId, deckEvent) => {
      if (isShiftKey(deckEvent)) {
        multiSelectCurrentBlock();
      } else {
        selectCurrentBlock();
        select({
          pane: 'detail',
          selection: { id: spatialDetailId, type: 'spatial-detail' },
          label: 'details',
        });
      }
    },
    [selectCurrentBlock, multiSelectCurrentBlock, select],
  );

  const selected = useMemo(
    () => selectedBlockIds.includes(blockId),
    [selectedBlockIds, blockId],
  );
  const onDragStart = useCallback(
    (_info, deckEvent) => {
      if (!selected) {
        const selectFn = isShiftKey(deckEvent)
          ? multiSelectCurrentBlock
          : selectCurrentBlock;
        selectFn();
      }
    },
    [selected, selectCurrentBlock, multiSelectCurrentBlock],
  );

  const onLocationSelect = useCallback(
    (locationId, deckEvent) => {
      if (isShiftKey(deckEvent)) {
        multiSelectCurrentBlock();
      } else {
        selectCurrentBlock();
        select({
          pane: 'detail',
          selection: { id: locationId, type: 'location' },
        });
      }
    },
    [selectCurrentBlock, multiSelectCurrentBlock, select],
  );

  const onReset = useCallback(
    (_info, deckEvent) => {
      if (isShiftKey(deckEvent)) {
        multiSelectCurrentBlock();
        removePane('detail');
      } else {
        selectCurrentBlock();
      }
    },
    [multiSelectCurrentBlock, selectCurrentBlock, removePane],
  );

  const selectedLocationId =
    selectedBlockIds.length === 1 && blockId === selectedBlockIds[0]
      ? toolboxLocationId
      : undefined;
  const selectedSpatialDetailId =
    selectedBlockIds.length === 1 && blockId === selectedBlockIds[0]
      ? toolboxSpatialDetailId
      : undefined;
  const sizeFormat = useMemo(
    () =>
      sizeKey && schema[sizeKey]
        ? units.parseColumn(schema[sizeKey], locations, instantiations).format
        : undefined,
    [locations, instantiations, sizeKey, schema],
  );

  return (
    <SpatialVisualisation
      allowScrollZoom={allowScrollZoom}
      bounds={hexBounds || bounds}
      colorFormat={colorFormat}
      colorHeading={get([colorKey, 'label'], schema)}
      colorStops={colorStops}
      data={data}
      interactive={viewMode !== 'standalone'}
      layers={layers}
      locations={locations}
      mapboxAccessToken={mapboxAccessToken}
      onDragStart={onDragStart}
      onDetailSelect={onDetailSelect}
      onLocationSelect={onLocationSelect}
      onReset={onReset}
      privacy='public'
      selectedLocationId={selectedLocationId}
      selectedSpatialDetailId={selectedSpatialDetailId}
      showFixedOverlays={viewMode === 'maximised'}
      sizeFormat={sizeFormat}
      sizeHeading={get([sizeKey, 'label'], schema)}
      sizeMax={sizeMax}
      sizeMin={sizeMin}
      viewMode={viewMode}
      visualisationLayerEnabled={visualisationLayerEnabled}
      visualisationType={get('type', blockValueSpatial)}
      workspaceId={workspaceId}
    />
  );
};

PublicSpatialVisualisation.propTypes = propTypes;
PublicSpatialVisualisation.defaultProps = defaultProps;

export default PublicSpatialVisualisation;
