import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash/fp';
import { MultiSelect } from '@kinesis/bungle';
import {
  locations as pathwayLocations,
  updatedLocations as updatedPathwayLocations,
} from 'data/pathways';

const propTypes = {
  type: PropTypes.oneOf(['lga', 'suburb']),
  value: PropTypes.array,
  onChange: PropTypes.func,
};
const defaultProps = {
  type: 'lga',
  value: [],
  onChange: noop,
};

const options = {
  old: pathwayLocations,
  new: updatedPathwayLocations,
};

const PathwaysLocations = ({ type, value, onChange }) => {
  const ref = useRef();
  const useUpdatedLocations = true;

  useEffect(() => {
    if (ref && ref.current) {
      const button = ref.current.querySelector('button');
      button.focus();
    }
  }, [ref]);

  return (
    // Wrapping div works around missing forwardRef on MultiSelect
    // ref: https://github.com/kinesisptyltd/bungle/issues/3392
    <div ref={ref}>
      <MultiSelect
        label='Locations'
        onChange={onChange}
        options={options[useUpdatedLocations ? 'new' : 'old'][type]}
        values={value}
        itemsLabel='locations'
      />
    </div>
  );
};

PathwaysLocations.propTypes = propTypes;
PathwaysLocations.defaultProps = defaultProps;

export { PathwaysLocations };
