import { get } from 'lodash/fp';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import useActions from './useActions';
import useResponsive from './useResponsive';

function useToolboxOpenState() {
  const isToolboxOpen = useSelector(get(['toolbox', 'isToolboxOpen']));
  const { setToolboxOpen } = useActions(toolboxActions);
  const { isTabletLandscapeAndUp } = useResponsive();

  const handleToolboxOpen = useCallback(
    (arg) => {
      if (typeof arg === 'function') {
        setToolboxOpen(arg(isToolboxOpen));
      } else {
        setToolboxOpen(arg);
      }
    },
    [isToolboxOpen, setToolboxOpen],
  );

  useEffect(() => {
    if (isToolboxOpen === undefined) {
      setToolboxOpen(isTabletLandscapeAndUp);
    }
  });

  return [isToolboxOpen, handleToolboxOpen];
}

export default useToolboxOpenState;
