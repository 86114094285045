import { createSelector } from 'reselect';
import { get, map, pipe, set, zipWith } from 'lodash/fp';

import blockStateSelector from 'selectors/blockStateSelector';
import blockValueSelector from 'selectors/blockValueSelector';
import capsuleDataSelector from 'selectors/capsuleDataSelector';
import locationsSelector from 'selectors/locationsSelector';
import { bubble as chartOpacities } from 'utils/charts/opacities';
import { formatDotTooltip } from 'utils/charts/tooltips';
import { pickQualitative, opacify, soften } from 'utils/colors';
import { qualitativeColors } from 'settings/colors';

const scatterChartCapsuleSelector = createSelector(
  pipe(blockStateSelector, get('schema')),
  pipe(blockValueSelector, get('chart')),
  locationsSelector,
  capsuleDataSelector,
  (
    schema,
    chart,
    locations,
    { bounds: { x: xBounds, y: yBounds }, data: columnCapsuleData },
  ) => {
    const { x, y } = chart;
    const xLabel = get([x.column, 'label'], schema);
    const yLabel = get([y.column, 'label'], schema);
    const chartDataWithDataLabels = pipe(
      set([0, 'xLabel'], xLabel),
      set([0, 'yLabel'], yLabel),
      set([0, 'dataLabels'], {
        borderWidth: 0,
        format: '{point.label}',
        color: 'rgba(0, 0, 0, 0.65)',
        style: {
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '20px',
        },
        y: 28,
      }),
    )(columnCapsuleData);

    const colors = pipe(
      (d) => pickQualitative(qualitativeColors, d.length),
      map((col) => opacify(col, chartOpacities.normal)),
    )(chartDataWithDataLabels);

    const chartDataWithColours = zipWith(
      (color, data) => ({
        ...data,
        marker: {
          fillOpacity: 1,
          fillColor: color,
          lineWidth: 0,
          states: {
            hover: {
              fillColor: color,
              lineColor: soften(color),
              lineWidth: 2,
              radiusPlus: 0,
            },
            select: {
              fillColor: color,
              lineWidth: 0,
            },
          },
        },
      }),
      colors,
      chartDataWithDataLabels,
    );

    const options = {
      chart: { type: 'scatter' },
      yAxis: {
        title: { text: y.title },
        endOnTick: true,
        ...yBounds,
      },
      xAxis: {
        title: { text: x.title },
        endOnTick: true,
        ...xBounds,
      },
      tooltip: {
        shared: true,
        formatter() {
          return formatDotTooltip(this);
        },
      },
      series: chartDataWithColours,
    };

    return {
      options,
      xLabel,
      yLabel,
      series: chartDataWithColours,
    };
  },
);

export default scatterChartCapsuleSelector;
