/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import Units from 'data/units';
import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { update, defaultTo, curry, get, last, isNil, set } from 'lodash/fp';
import { WebMercatorViewport } from '@math.gl/web-mercator';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  InPlaceInput,
  Inline,
  InlineItem,
  Input,
  Tab,
  TabAddButton,
  TabBar,
  TabList,
  Tabs,
  UtilityButton,
  useToast,
} from '@kinesis/bungle';
import { withinBounds } from 'utils/spatialUtils';
import Layout from 'components/layout';
import Map from 'components/map/Map';
import LayerCircle from 'components/layer-circle/LayerCircle';
import PositionHover from 'components/position-hover/PositionHover';
import MapContainer from 'components/map-container/MapContainer';
import Modal from 'components/modals/modal/Modal';
import {
  ModalHeader,
  ModalHeaderAction,
  ModalHeading,
} from 'components/modals/modal-header';
import PlaceLocationPanel from 'components/place-location-panel/PlaceLocationPanel';
import Section from 'components/section';
import { locationCreate } from 'actions/locationCreate';
import layerBoundsSelector from 'selectors/layerBoundsSelector';
import layerColorSelector from 'selectors/layerColorSelector';
import layerSelector from 'selectors/layerSelector';
import makePublicTokenSelector from 'selectors/makePublicTokenSelector';
import scenarioLocationsSelector from 'selectors/scenarioLocationsSelector';
import scenarioKeySelector from 'selectors/scenarioKeySelector';
import { useSelectorWithProps } from 'hooks';
import useTheme from 'hooks/useTheme';
import { visualisationLocationLayers } from 'components/spatial-visualisation/location-layers';
import editLayers from 'components/spatial-visualisation/edit-layers';
import { normaliseLongitude } from 'utils/lngLatUtils';
import { SidePane, NameInput } from './new-location.styles';

const units = new Units();

const format = units.parseColumn({
  unit: { type: 'coordinate' },
}).formatCell;

const checkBoundsInclusive = curry(
  ([lowerBound, upperBound], value) =>
    value >= lowerBound && value <= upperBound,
);
const validateLatitude = checkBoundsInclusive([-85, 85]);
const validateLongitude = checkBoundsInclusive([-180, 180]);

const propTypes = {
  animation: PropTypes.bool,
  layerId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  animation: true,
};

const mapboxAccessTokenSelector = makePublicTokenSelector('mapboxAccessToken');

const determineBounds = (state) => {
  const viewport = new WebMercatorViewport(state);
  return [
    viewport.unproject([0, 0]),
    viewport.unproject([viewport.width, viewport.height]),
  ];
};

const NewLocation = ({
  animation,
  layerId,
  onClose,
  scenarioId,
  workspaceId,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const toast = useToast('globalTop');
  const mapboxAccessToken = useSelector(mapboxAccessTokenSelector);
  const bounds = useSelectorWithProps(layerBoundsSelector, {
    layerId,
    scenarioId,
    workspaceId,
  });
  const [viewport, setViewport] = useState();
  const [target, setTarget] = useState(undefined);
  const { label } = useSelectorWithProps(layerSelector, {
    layerId,
    workspaceId,
  });
  const locations = useSelectorWithProps(scenarioLocationsSelector, {
    layerId,
    scenarioId,
    workspaceId,
  });
  const colorName = useSelectorWithProps(layerColorSelector, {
    layerId,
    workspaceId,
  });
  const scenarioKey = useSelectorWithProps(scenarioKeySelector, {
    scenarioId,
    workspaceId,
  });
  const layerColor = get(['color', 'layer', colorName], theme);
  const [name, setName] = useState('');

  const [latitudeInputDraft, setLatitudeInputDraft] = useState(undefined);
  const [longitudeInputDraft, setLongitudeInputDraft] = useState(undefined);
  const [latitudeInput, setLatitudeInput] = useState('');
  const [longitudeInput, setLongitudeInput] = useState('');
  const setRoundedLatitudeInput = useCallback(
    (latitude) => setLatitudeInput(latitude.toFixed(5)),
    [setLatitudeInput],
  );
  const setRoundedLongitudeInput = useCallback(
    (longitude) => setLongitudeInput(normaliseLongitude(longitude).toFixed(5)),
    [setLongitudeInput],
  );
  const [coordinate, setCoordinate] = useState(undefined);
  const setRoundedCoordinate = useCallback(
    ({ latitude, longitude }) => {
      const rounded = {
        latitude: parseFloat(latitude.toFixed(5)),
        longitude: normaliseLongitude(longitude),
      };
      setCoordinate(rounded);
      const currentBounds = viewport ? determineBounds(viewport) : bounds;
      if (!withinBounds(currentBounds, rounded)) {
        return setTarget(rounded);
      }
    },
    [bounds, viewport],
  );

  const [hover, setHoverDirect] = useState(undefined);
  const [drag, setDrag] = useState(undefined);
  const [hoverEdit, setHoverEdit] = useState(false);
  const [isDragging, setIsDragging] = useState(undefined);
  const [years, setYears] = useState([2020]);
  const [year, setYear] = useState(2020);
  const setHover = useCallback(
    (object) =>
      isNil(object)
        ? setHoverDirect(object)
        : setHoverDirect(update('longitude', normaliseLongitude, object)),
    [setHoverDirect],
  );
  const creating = isNil(coordinate);
  const onChange = useCallback((t) => get(t, years), [years]);

  const onAdd = useCallback(
    () =>
      setYears((current) => {
        const newYear = last(current) + 1;
        setYear(newYear);
        return [...current, newYear];
      }),
    [setYears, setYear],
  );

  const onHover = useCallback(
    (reactMapGlProps) => {
      const { lngLat, point } = reactMapGlProps;
      if (lngLat && point) {
        setHover({
          longitude: lngLat[0],
          latitude: lngLat[1],
          x: point[0],
          y: point[1],
        });
      }
    },
    [setHover],
  );

  const onMouseOut = useCallback(() => {
    setHover(undefined);
  }, [setHover]);

  const onClick = useCallback(
    (deckGlProps) => {
      const {
        coordinate: [longitude, latitude],
      } = deckGlProps;
      if (longitude && latitude) {
        setRoundedCoordinate({
          longitude,
          latitude,
        });
        setRoundedLongitudeInput(longitude);
        setRoundedLatitudeInput(latitude);
      }
    },
    [setRoundedCoordinate, setRoundedLatitudeInput, setRoundedLongitudeInput],
  );

  const getCursor = useCallback(() => {
    if (creating) return 'crosshair';
    if (isDragging) return 'none';
    return 'grab';
  }, [creating, isDragging]);

  const onBlurInput = useCallback(() => {
    // Set the canonical input fields from the drafts.
    const latitudeDraft = parseFloat(latitudeInputDraft);
    const longitudeDraft = parseFloat(longitudeInputDraft);
    const isValidLatitudeDraft = validateLatitude(latitudeDraft);
    const isValidLongitudeDraft = validateLongitude(longitudeDraft);
    if (isValidLatitudeDraft) {
      setRoundedLatitudeInput(latitudeDraft);
    }
    if (isValidLongitudeDraft) {
      setRoundedLongitudeInput(longitudeDraft);
    }

    // Set the coordinate from the canonical inputs.
    const latitude = isValidLatitudeDraft
      ? latitudeDraft
      : parseFloat(latitudeInput);
    const longitude = isValidLongitudeDraft
      ? longitudeDraft
      : parseFloat(longitudeInput);
    const isValidLatitude = validateLatitude(latitude);
    const isValidLongitude = validateLongitude(longitude);
    if (isValidLatitude && isValidLongitude) {
      setRoundedCoordinate({ longitude, latitude });
    }

    // Always unset drafts on blur.
    setLatitudeInputDraft(undefined);
    setLongitudeInputDraft(undefined);
  }, [
    setRoundedCoordinate,
    latitudeInput,
    longitudeInput,
    latitudeInputDraft,
    longitudeInputDraft,
    setRoundedLatitudeInput,
    setRoundedLongitudeInput,
    setLatitudeInputDraft,
    setLongitudeInputDraft,
  ]);

  const onSave = useCallback(() => {
    // This looks/is dumb, it is to allow onBlur to fire before we pick up the values,
    // there must be a better way, but at the moments the components race, and this
    // ensures onBlur wins, and save happens after, it probably only needs to be 0,
    // to give up the thread, but there isn't a reliable test case that verifies this.
    setTimeout(() => {
      const isValid =
        coordinate &&
        validateLongitude(coordinate.longitude) &&
        validateLatitude(coordinate.latitude);
      if (isValid) {
        dispatch(
          locationCreate({
            layer: layerId,
            label: name,
            coordinate,
            scenarioId,
            scenarioKey,
            workspaceId,
          }),
        );
        onClose();
      } else {
        toast(
          'You must specify a point for your location before you can save.',
          { variant: 'error' },
        );
      }
    }, 100);
  }, [
    coordinate,
    dispatch,
    layerId,
    name,
    onClose,
    scenarioId,
    scenarioKey,
    toast,
    workspaceId,
  ]);

  const deckLayers = useMemo(
    () => [
      ...visualisationLocationLayers({
        locations: locations.map(set('layerColor', layerColor)),
        focus: false,
      }),
      ...editLayers({
        hovering: hoverEdit,
        ...(coordinate || {}),
        layerColor,
        onDragStart: () => {
          setIsDragging(true);
          return true;
        },
        onDragEnd: ({ coordinate: dragCoordinate }) => {
          setIsDragging(false);
          setDrag(undefined);
          setHover(undefined);
          if (dragCoordinate) {
            const longitude = dragCoordinate[0];
            const latitude = dragCoordinate[1];
            setRoundedCoordinate({ longitude, latitude });
            setRoundedLongitudeInput(longitude);
            setRoundedLatitudeInput(latitude);
          }
          return true;
        },
        onDrag: ({ coordinate: dragCoordinate, x, y }) => {
          if (dragCoordinate) {
            const longitude = dragCoordinate[0];
            const latitude = dragCoordinate[1];
            setDrag({ longitude, latitude });
            setHover({ longitude, latitude, x, y });
          }
          return true;
        },
        onHover: ({ object }) => {
          setHoverEdit(!!object);
        },
        ...(drag || {}),
      }),
    ],
    [
      coordinate,
      drag,
      hoverEdit,
      layerColor,
      locations,
      setRoundedCoordinate,
      setRoundedLatitudeInput,
      setRoundedLongitudeInput,
      setHover,
    ],
  );

  const headerLabel = 'New location';
  const enableScenarioDropdown = false;
  const header = (
    <ModalHeader>
      <ModalHeading>{headerLabel}</ModalHeading>
      <ModalHeaderAction>
        <span style={{ paddingRight: 8 }}>
          <LayerCircle layerId={layerId} workspaceId={workspaceId} />
        </span>
        <span>{label}</span>
      </ModalHeaderAction>
      {enableScenarioDropdown && (
        <ModalHeaderAction>
          <DropdownMenu>
            <DropdownMenuButton as={UtilityButton} dropdown>
              Baseline
            </DropdownMenuButton>
            <DropdownMenuList>
              <DropdownMenuItem>Baseline</DropdownMenuItem>
            </DropdownMenuList>
          </DropdownMenu>
        </ModalHeaderAction>
      )}
    </ModalHeader>
  );
  return (
    <Modal
      animation={animation}
      magnitude='large'
      onSave={onSave}
      onClose={onClose}
      header={header}
      aria-label={headerLabel}
    >
      <NameInput>
        <InPlaceInput
          onChange={setName}
          placeholder='Location name'
          value={name}
          variant='title'
          autoFocus
        />
      </NameInput>
      <Tabs activeKey={year} onChange={onChange} style={{ flexGrow: 0 }}>
        <TabBar>
          <TabList>
            {years.map((y) => (
              <Tab key={y} tabKey={y}>
                1 Jan {y}
              </Tab>
            ))}
          </TabList>
          {/* add tab button once position over time is supported */}
          {!creating && false && <TabAddButton onClick={onAdd} />}
        </TabBar>
      </Tabs>
      <Layout direction='row'>
        <SidePane>
          <Section collapsible={false} heading='Location'>
            <Inline space='small'>
              <InlineItem sizing='fill-container'>
                <Input
                  label='Latitude'
                  magnitude='large'
                  value={defaultTo(latitudeInput, latitudeInputDraft)}
                  onChange={(input) =>
                    setLatitudeInputDraft(defaultTo('', input))
                  }
                  onBlur={onBlurInput}
                  data-testid='latitude'
                />
              </InlineItem>
              <InlineItem sizing='fill-container'>
                <Input
                  label='Longitude'
                  magnitude='large'
                  value={defaultTo(longitudeInput, longitudeInputDraft)}
                  onChange={(input) =>
                    setLongitudeInputDraft(defaultTo('', input))
                  }
                  onBlur={onBlurInput}
                  data-testid='longitude'
                />
              </InlineItem>
            </Inline>
          </Section>
        </SidePane>
        <Layout direction='column'>
          <MapContainer>
            {hover && (creating || isDragging) && (
              <PositionHover x={hover.x} y={hover.y} crosshair={creating}>
                {format(hover.latitude)}, {format(hover.longitude)}
              </PositionHover>
            )}
            <Map
              dragPan={!isDragging}
              mapboxAccessToken={mapboxAccessToken}
              mapStyle='light'
              onClick={creating ? onClick : undefined}
              onHover={creating ? onHover : undefined}
              onMouseOut={onMouseOut}
              bounds={target ? undefined : bounds}
              target={target || undefined}
              layers={deckLayers}
              getCursor={getCursor}
              onViewportChange={setViewport}
            />
            {creating && <PlaceLocationPanel />}
          </MapContainer>
        </Layout>
      </Layout>
    </Modal>
  );
};

NewLocation.defaultProps = defaultProps;
NewLocation.propTypes = propTypes;

export default NewLocation;
