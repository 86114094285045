import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack } from '@kinesis/bungle';
import { actions as workspaceBlocksActions } from 'reducers/workspaceBlocksReducer';
import visualisationContextsSelector from 'selectors/visualisationContextsSelector';
import visualisationDimensionsSelector from 'selectors/visualisationDimensionsSelector';
import Filter from 'components/form/Filter';
import { ToolboxSection } from 'components/toolbox/toolbox-section';
import { useSelectorWithProps } from 'hooks';
import ContextsSection from './ContextsSection';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  scenarioId: PropTypes.number.isRequired,
  table: PropTypes.string,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  table: undefined,
};

const FiltersSection = ({ blockId, scenarioId, table, workspaceId }) => {
  const dispatch = useDispatch();
  const contexts = useSelectorWithProps(visualisationContextsSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const dimensions = useSelectorWithProps(visualisationDimensionsSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const onContextChange = useCallback(
    (key, value) => {
      dispatch(
        workspaceBlocksActions.setVisualisationContext({
          blockId,
          key,
          table,
          value,
        }),
      );
    },
    [dispatch, blockId, table],
  );
  const onDimensionChange = useCallback(
    (key, value) => {
      dispatch(
        workspaceBlocksActions.setVisualisationDimension({
          blockId,
          key,
          table,
          value,
        }),
      );
    },
    [dispatch, blockId, table],
  );

  if (!table) {
    return null;
  }

  return (
    <>
      {contexts.length > 0 && (
        <ContextsSection contexts={contexts} onChange={onContextChange} />
      )}
      {dimensions.length > 0 && (
        <ToolboxSection collapsible title='Filters'>
          <Stack space='medium'>
            {dimensions.map((filter) => (
              <Filter
                key={filter.key}
                encoding={filter.encoding}
                filterKey={filter.key}
                format={filter.format}
                label={filter.label}
                onChange={onDimensionChange}
                options={filter.options}
                symbol={filter.symbol}
                type={filter.type}
                value={filter.value}
                min={filter.min}
                max={filter.max}
              />
            ))}
          </Stack>
        </ToolboxSection>
      )}
    </>
  );
};

FiltersSection.propTypes = propTypes;
FiltersSection.defaultProps = defaultProps;

export default FiltersSection;
