import { useMemo } from 'react';
import { Anchor, Icon, Stack, Strong } from '@kinesis/bungle';
import PropTypes from 'prop-types';
import useTheme from 'hooks/useTheme';
import {
  Body,
  MaxWidth,
  Message,
  IconContainer,
  TechnicalDetails,
} from 'components/block-error/block-error.styles';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import blockValueAtDataVersionSelector from 'selectors/blockValueAtDataVersionSelector';
import { expressionFrom, labelOfExpression } from 'data/block';
import { get } from 'lodash/fp';
import versionedResourceSelector from 'selectors/versionedResourceSelector';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  error: PropTypes.shape({
    code: PropTypes.oneOf([
      'invalid-query-columns',
      'invalid-query-resource',
      'invalid-query-scenario-id',
      'query-incompatible-table-dimensions-not-present',
      'query-incompatible-table-dimensions-present',
      'query-unsupported-aggregate-on-column',
      'query-unsupported-time-dimension',
      'query-unsupported-x-dimension',
    ]),
    errorId: PropTypes.string,
  }),
  scenarioId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  error: undefined,
};

/* eslint-disable */
const mailtoBodyTemplate = (errorId) =>
  'There was a problem showing a block in Kinesis.\n' +
  `Technical details: ${errorId}. Please don't change this line.\n\n` +
  'This is enough information for us to look into the problem, but if you have any comments let us know below:\n\n';
/* eslint-enable */

const renderMailtoBody = (errorId) =>
  mailtoBodyTemplate(errorId).replaceAll(' ', '%20').replaceAll('\n', '%0D%0A');

const renderMailto = (errorId) => {
  if (errorId) {
    return `mailto:support@kinesis.org?subject=Problem%20with%20Kinesis&body=${renderMailtoBody(
      errorId,
    )}`;
  }
  return 'mailto:support@kinesis.org?Subject=Problem%20with%20Kinesis';
};

const BlockError = ({ blockId, error, scenarioId, workspaceId }) => {
  const theme = useTheme();

  const block = useSelectorWithProps(blockValueAtDataVersionSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });

  const code = get('code', error);
  const resource = get('resource', error);
  const table = useSelectorWithProps(versionedResourceSelector, {
    resource,
    workspaceId,
  });

  const content = useMemo(() => {
    switch (code) {
      case 'query-unsupported-aggregate-on-column':
      case 'query-incompatible-table-dimensions-present':
      case 'query-incompatible-table-dimensions-not-present': {
        return (
          <Message>
            <Strong>{labelOfExpression(expressionFrom(block), table)}</Strong>{' '}
            is not a valid measure and cannot be visualised. You may need to
            adjust this visualisation.
          </Message>
        );
      }
      case 'query-unsupported-time-dimension': {
        const columnLabel = get(
          ['schema', get('column', error), 'label'],
          table,
        );
        return (
          <Message>
            <Strong>{columnLabel}</Strong> is not a valid timeline field and
            cannot be visualised. You may need to adjust this visualisation.
          </Message>
        );
      }
      case 'invalid-query-scenario-id':
        return (
          <Message>
            A scenario needed for this visualisation is not available. You may
            need to adjust this visualisation.
          </Message>
        );
      case 'invalid-query-columns':
        return (
          <Message>
            A field needed for this visualisation is not available. You may need
            to add a missing output to the workspace or adjust this
            visualisation.
          </Message>
        );
      case 'invalid-query-resource':
        return (
          <Message>
            An output needed for this visualisation is not available. You may
            need to add a missing output to the workspace or adjust this
            visualisation.
          </Message>
        );
      default: {
        const errorId = get('errorId', error);
        return (
          <Message>
            We couldn’t show this block. If you continue to experience problems,{' '}
            <Anchor href={renderMailto(errorId)}>get in touch</Anchor> with our
            support team.
            {errorId && (
              <TechnicalDetails>Technical details: {errorId}</TechnicalDetails>
            )}
          </Message>
        );
      }
    }
  }, [code, error, block, table]);

  const errorIcon = useMemo(() => {
    switch (code) {
      case 'query-incompatible-table-dimensions-present':
      case 'query-incompatible-table-dimensions-not-present':
      case 'query-unsupported-time-dimension':
      case 'query-unsupported-aggregate-on-column':
        return <Icon color={theme.color.yellow5} type='info-circle' />;
      case 'invalid-query-scenario-id':
      case 'invalid-query-resource':
      case 'invalid-query-columns':
      default:
        return <Icon color={theme.color.red5} type='close-circle' />;
    }
  }, [code, theme.color.red5, theme.color.yellow5]);

  return (
    <Body>
      <MaxWidth>
        <Stack>
          <IconContainer>{errorIcon}</IconContainer>
          {content}
        </Stack>
      </MaxWidth>
    </Body>
  );
};

BlockError.propTypes = propTypes;
BlockError.defaultProps = defaultProps;

export { BlockError };
