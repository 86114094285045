import React from 'react';
import PropTypes from 'prop-types';
import { filter, get, has, pipe, reduce, isNil } from 'lodash/fp';
import { Icon, Pill } from '@kinesis/bungle';
import { Cell } from 'components/cell';
import {
  fineareas,
  fineareasBySubregion,
  regions,
  subregions,
} from './attribute-values.fine-area-correspondence.data';
import {
  Collapse,
  Row,
} from './attribute-values.fine-area-correspondence.styles';

const selectionMatches = (selection, value) => {
  if (selection === 'all') return true;
  if (selection === 'correspondence') return !isNil(value);
  if (selection === 'no-correspondence') return isNil(value);
  return true;
};

const propTypes = {
  values: PropTypes.object.isRequired,
  selection: PropTypes.oneOf(['all', 'correspondence', 'no-correspondence'])
    .isRequired,
  regionState: PropTypes.object.isRequired,
  subregionState: PropTypes.object.isRequired,
  toggleRegion: PropTypes.func.isRequired,
  toggleSubregion: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveCascade: PropTypes.func.isRequired,
};

const defaultProps = {};

const FineAreaCorrespondenceTableAreas = ({
  values,
  selection,
  regionState,
  subregionState,
  toggleRegion,
  toggleSubregion,
  onSave,
  onSaveCascade,
}) => {
  const calculateFor = (predicate) =>
    pipe(
      filter(predicate),
      reduce((acc, el) => {
        if (acc.multipleValues) {
          return acc;
        }
        const v = get(el.finearea, values);
        if (!has('singleValue', acc)) {
          return { singleValue: v };
        }
        if (v === acc.singleValue) {
          return acc;
        }
        return { multipleValues: true };
      }, {}),
    )(fineareas);

  const regionRow = (region, open) => {
    const regionValue = calculateFor({ region });
    return (
      <Row key={region}>
        <Cell
          appearance='dark'
          cursor='pointer'
          header
          onClick={toggleRegion(region)}
        >
          <Collapse>
            {!open ? <Icon type='right' /> : <Icon type='down' />}
          </Collapse>
          {region}
        </Cell>
        <Cell
          numeric
          editable
          multiple={!!regionValue.multipleValues}
          value={regionValue.singleValue || 0}
          onSave={onSaveCascade({ region })}
        >
          {regionValue.singleValue || 0} <Pill>%</Pill>
        </Cell>
      </Row>
    );
  };

  const subregionRow = (subregion, open) => {
    const subregionValue = calculateFor({ subregion });
    return (
      <Row key={subregion}>
        <Cell
          appearance='dark'
          header
          indent='single'
          cursor='pointer'
          onClick={toggleSubregion(subregion)}
        >
          <Collapse>
            {!open ? <Icon type='right' /> : <Icon type='down' />}
          </Collapse>
          {subregion}
        </Cell>
        <Cell
          numeric
          editable
          multiple={!!subregionValue.multipleValues}
          value={subregionValue.singleValue || 0}
          onSave={onSaveCascade({ subregion })}
        >
          {subregionValue.singleValue || 0} <Pill>%</Pill>
        </Cell>
      </Row>
    );
  };

  return (
    <>
      {regions.map(({ region }) =>
        !get([region, 'hidden'], regionState) ? (
          <React.Fragment key={region}>
            {regionRow(region, get([region, 'open'], regionState))}
            {get([region, 'open'], regionState) &&
              subregions.map(({ subregion, region: regionParent }) =>
                regionParent === region &&
                !get([subregion, 'hidden'], subregionState) ? (
                  <React.Fragment key={subregion}>
                    {subregionRow(
                      subregion,
                      get([subregion, 'open'], subregionState),
                    )}
                    {get([subregion, 'open'], subregionState) &&
                      get(subregion, fineareasBySubregion).map(
                        ({ finearea, subregion: subregionParent }) => {
                          const value = get(finearea, values);
                          return subregionParent === subregion &&
                            selectionMatches(selection, value) ? (
                            <Row key={finearea}>
                              <Cell appearance='dark' indent='double'>
                                {finearea}
                              </Cell>
                              <Cell
                                editable
                                numeric
                                value={value || 0}
                                onSave={onSave(finearea)}
                              >
                                {value || 0} <Pill>%</Pill>
                              </Cell>
                            </Row>
                          ) : null;
                        },
                      )}
                  </React.Fragment>
                ) : null,
              )}
          </React.Fragment>
        ) : null,
      )}
    </>
  );
};

FineAreaCorrespondenceTableAreas.propTypes = propTypes;
FineAreaCorrespondenceTableAreas.defaultProps = defaultProps;

export { FineAreaCorrespondenceTableAreas };
