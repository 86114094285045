import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import blockDataSelector from 'selectors/blockDataSelector';
import ChartImpl from 'components/charts/highcharts/ChartImpl';
import { isEmpty, flatten, isNil, get } from 'lodash/fp';
import isScenarioMismatchSelector from 'selectors/isScenarioMismatchSelector';
import { Placeholder, Stack } from '@kinesis/bungle';
import { BlockError } from 'components/block-error';
import isCapsuleMismatchSelector from 'selectors/isCapsuleMismatchSelector';
import { Loading } from 'components/loading';
import blockPerspectiveStateSelector from 'selectors/blockPerspectiveStateSelector';
import { useMemo, useCallback } from 'react';
import { XYChartLegend } from 'components/xy-chart-legend';
import {
  deselectNonCategoryColumns,
  returnColumnsToUnselectedState,
  selectHoveredColumns,
  selectColumns,
} from 'utils/charts/column';
import { toolboxChartDetailIdSelector } from 'selectors/toolboxDetailSelectors';
import useActions from 'hooks/useActions';
import { actions as toolboxActions } from 'reducers/toolboxReducer';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import xyChartSeriesSelector from 'selectors/xyChartSeriesSelector';
import xyChartOptionsSelector from 'selectors/xyChartOptionsSelector';
import { XYChartPlaceholder } from 'components/xy-chart-placeholder';
import { Container, Body, MaxWidth } from './clustered-column.styles';

const propTypes = {
  blockId: PropTypes.string.isRequired,
  scenarioId: PropTypes.number.isRequired,
  viewMode: PropTypes.oneOf(['maximised', 'minimised', 'standalone'])
    .isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {};

const isError = (data) => get('type', data) === 'error';

const isPlaceholderError = (data) =>
  [
    'query-missing-table',
    'query-missing-x',
    'query-missing-y',
    'query-missing-series',
    'query-missing-comparison',
  ].includes(get(['data', 'code'], data));

const ClusteredColumn = ({ blockId, scenarioId, viewMode, workspaceId }) => {
  const blockData = useSelectorWithProps(blockDataSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const state = useSelectorWithProps(blockPerspectiveStateSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const scenarioMismatch = useSelectorWithProps(isScenarioMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const capsuleMismatch = useSelectorWithProps(isCapsuleMismatchSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const series = useSelectorWithProps(xyChartSeriesSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const chartOptions = useSelectorWithProps(xyChartOptionsSelector, {
    blockId,
    scenarioId,
    workspaceId,
  });
  const selectedDetailId = useSelector(toolboxChartDetailIdSelector);
  const selections = useSelector(blockSelectionsSelector);
  const isSelected = useMemo(
    () => selections && selections.includes(blockId),
    [selections, blockId],
  );

  const { toggleBlock, selectBlock } = useActions(blockSelectionsActions);
  const { select } = useActions(toolboxActions);

  const processing = scenarioMismatch || state === 'processing';
  const loading = isNil(state);
  const unrecoverableError =
    isError(blockData) && !isPlaceholderError(blockData);
  const recoverableError = isError(blockData) && isPlaceholderError(blockData);

  const metadata = get(['data', 'metadata'], blockData);
  const showLegend = useMemo(() => viewMode === 'maximised', [viewMode]);
  const legendTitle = useMemo(
    () =>
      series.length > 1 && get(['series', 'type'], metadata) === 'by-value'
        ? get(['series', 'column', 'label'], metadata)
        : undefined,
    [series, metadata],
  );

  const handleSelectPoints = useCallback((chart) => {
    deselectNonCategoryColumns(chart);
    selectHoveredColumns(chart);
  }, []);

  const handleSelectPointValue = useCallback((chart, value) => {
    if (value) {
      selectColumns(chart, value);
    } else {
      returnColumnsToUnselectedState(
        flatten(chart.series.map((s) => s.points)),
      );
    }
    chart.redraw();
  }, []);

  const handleExternalClick = useCallback((points, chart) => {
    returnColumnsToUnselectedState(points, chart);
  }, []);

  const handleContainerClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleSelect = useCallback(
    (value) => {
      selectBlock(blockId);

      if (!isNil(value)) {
        select({
          pane: 'detail',
          selection: { id: value, type: 'chart-detail' },
          label: 'details',
        });
      }
    },
    [select, selectBlock, blockId],
  );

  const handleMultiSelect = useCallback(() => {
    toggleBlock(blockId);
  }, [blockId, toggleBlock]);

  if (loading) {
    return <Loading offset={20} />;
  }

  if (unrecoverableError) {
    if (processing && capsuleMismatch) {
      return (
        <Body>
          <MaxWidth>
            <Stack space='xsmall'>
              <Placeholder>Data is being processed.</Placeholder>
            </Stack>
          </MaxWidth>
        </Body>
      );
    }
    return (
      <BlockError
        error={get('data', blockData)}
        blockId={blockId}
        scenarioId={scenarioId}
        workspaceId={workspaceId}
      />
    );
  }

  if (recoverableError) {
    return (
      <>
        {!processing && (
          <XYChartPlaceholder
            maxHeight={viewMode === 'maximised' ? 768 : undefined}
            maxWidth={viewMode === 'maximised' ? 1280 : undefined}
          />
        )}
        {processing && (
          <Body>
            <MaxWidth>
              <Stack space='xsmall'>
                <Placeholder>Data is being processed.</Placeholder>
              </Stack>
            </MaxWidth>
          </Body>
        )}
      </>
    );
  }

  if (isEmpty(series)) {
    if (processing) {
      return (
        <Body>
          <MaxWidth>
            <Stack space='xsmall'>
              <Placeholder>Data is being processed.</Placeholder>
            </Stack>
          </MaxWidth>
        </Body>
      );
    }
    return (
      <XYChartPlaceholder
        mode='empty'
        xAxisLabel={get(['xAxis', 'title', 'text'], chartOptions)}
        yAxisLabel={get(['yAxis', 'title', 'text'], chartOptions)}
        maxHeight={viewMode === 'maximised' ? 768 : undefined}
        maxWidth={viewMode === 'maximised' ? 1280 : undefined}
      />
    );
  }

  return (
    <Container onClick={handleContainerClick}>
      <ChartImpl
        clickable={viewMode !== 'standalone'}
        selectedDetailId={
          isSelected || viewMode === 'maximised' ? selectedDetailId : undefined
        }
        isSelected={isSelected || viewMode === 'maximised'}
        maxHeight={viewMode === 'maximised' ? 768 : undefined}
        maxWidth={viewMode === 'maximised' ? 1280 : undefined}
        onSelect={handleSelect}
        onBlockMultiSelect={handleMultiSelect}
        onExternalClick={handleExternalClick}
        onSelectPoints={handleSelectPoints}
        onSelectPointValue={handleSelectPointValue}
        options={chartOptions}
        legend={
          showLegend ? (
            <XYChartLegend icon='line' series={series} title={legendTitle} />
          ) : undefined
        }
      />
    </Container>
  );
};

ClusteredColumn.propTypes = propTypes;
ClusteredColumn.defaultProps = defaultProps;

export { ClusteredColumn };
