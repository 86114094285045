import { pick } from 'lodash/fp';
import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const scenarioUpdate = createAsyncThunk(
  'scenario/update',
  async ({ scenarioId, fields = {} }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const fieldNames = ['name', 'description'];

    const sanitizedFields = pick(fieldNames, fields);

    const response = await api.put(
      `/api/scenario/${scenarioId}`,
      sanitizedFields,
    );

    return {
      scenarioId,
      ...pick(fieldNames, response.data),
      nameInitialized: response.data.name_initialized,
    };
  },
);

export { scenarioUpdate };
