import styled from 'styled-components';

const ScenarioName = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PathwayLinkContainer = styled.div`
  margin-left: 8px;
  flex: 0 0 auto;
`;

const Container = styled.div(
  {
    overflow: 'hidden',
    flex: '1 1 0',
  },
  ({ theme }) => ({
    borderColor: theme.borderColor,
    borderStyle: 'solid',
    borderWidth: '1px 0 0 0',
  }),
  ({ maximised }) =>
    maximised
      ? {
          borderTop: 0,
        }
      : undefined,
);

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 1px);
  overflow: auto;
`;

const Row = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
  },
  ({ theme, withBottomBorder }) =>
    withBottomBorder
      ? {
          borderColor: theme.borderColor,
          borderStyle: 'solid',
          borderWidth: `0 0 1px 0`,
        }
      : undefined,
);

const RowItem = styled.div(
  {
    padding: '6px 12px',
    flex: '0 0 156px',
    width: '156px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    fontVariantNumeric: 'tabular-nums',
  },
  ({ theme }) => ({
    borderColor: theme.borderColor,
    borderStyle: 'solid',
    borderWidth: `0 1px 1px 0`,
    background: theme.color.white10,
  }),
  ({ header }) =>
    header
      ? {
          alignItems: 'center',
          marginBottom: '-1px',
        }
      : {},
  ({ lastRow }) =>
    lastRow
      ? {
          borderBottom: 'none',
        }
      : {},
  ({ numeric }) => ({ justifyContent: numeric ? 'flex-end' : 'flex-start' }),
);

const Body = styled('div')(
  {
    boxSizing: 'border-box',
    display: 'flex',
    flex: '1 1 auto',
    background: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 16px 20px',
    width: '100%',
    minHeight: '180px',
  },
  ({ theme }) => ({
    borderColor: theme.borderColor,
    borderStyle: 'solid',
    borderWidth: '1px 0 0 0',
  }),
);

const MaxWidth = styled('div')`
  max-width: 100%;
`;

export {
  ScenarioName,
  PathwayLinkContainer,
  Table,
  Row,
  RowItem,
  Container,
  Body,
  MaxWidth,
};
