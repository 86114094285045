import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns/fp';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  DropdownMenuSeparator,
  Icon,
  Tooltip,
  UtilityButton,
  ConfirmationDialog,
  useEventListener,
} from '@kinesis/bungle';
import ModificationsList from 'components/modifications-list/ModificationsList';
import { pipe, first, last, get, sortBy } from 'lodash/fp';
import useDateDistance from 'hooks/useDateDistance';
import {
  InfoCircleIconWrapper,
  LastUpdatedSpan,
  MenuParagraph,
  SavingSpan,
  UnpublishedModificationsWrapper,
} from './persistence-actions.styles';
import Bowser from 'bowser';
const { getParser } = Bowser;

const propTypes = {
  author: PropTypes.string,
  conflict: PropTypes.bool,
  count: PropTypes.number,
  currentScenarioKey: PropTypes.string,
  isSaving: PropTypes.bool,
  modifications: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onSaveAsNewNamedScenario: PropTypes.func.isRequired,
  onUpdateTag: PropTypes.func.isRequired,
  publishFailed: PropTypes.bool,
  published: PropTypes.bool,
  tagName: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};

const defaultProps = {
  author: undefined,
  conflict: false,
  count: 0,
  isSaving: false,
  publishFailed: false,
  published: true,
  currentScenarioKey: undefined,
};

const isMacOS = getParser(window.navigator.userAgent).getOSName() === 'macOS';

const PersistenceActions = ({
  author,
  conflict,
  count,
  currentScenarioKey,
  isSaving,
  modifications,
  onDelete,
  onPublish,
  onSaveAsNewNamedScenario,
  onUpdateTag,
  publishFailed,
  published,
  tagName,
  updatedAt,
}) => {
  const touchedDate = updatedAt ? parseISO(updatedAt) : undefined;
  const distance = useDateDistance(touchedDate);

  const handleDelete = useCallback(() => {
    onDelete(tagName);
  }, [onDelete, tagName]);

  const handlePublish = useCallback(() => {
    onPublish(tagName);
  }, [onPublish, tagName]);

  const [showConflictDialog, setShowConflictDialog] = useState(publishFailed);

  useEffect(() => {
    setShowConflictDialog(publishFailed);
  }, [publishFailed]);

  const handleConflictDialogCancel = useCallback(() => {
    setShowConflictDialog(false);
  }, []);

  const handleConflictDialogConfirm = useCallback(() => {
    onSaveAsNewNamedScenario();
    setShowConflictDialog(false);
  }, [onSaveAsNewNamedScenario]);

  const {
    onUndo: onUndoImpl,
    onRedo: onRedoImpl,
    modificationItems: modificationItemsArray,
  } = (() => {
    const containsType = (xs, ty) => xs && xs.some((x) => x.type === ty);
    const containsUndo = (changes) => containsType(changes, 'undo');
    const containsRedo = (changes) => containsType(changes, 'redo');
    const containsTagStartCode = (xs) =>
      xs && xs.some((x) => x.type === 'code' && x.value === 'tag-start');

    const cancelUndoRedoPairs = (ms) =>
      pipe(
        sortBy((x) => -x.id),
        (reversedModificationItems) =>
          reversedModificationItems.reduce((acc, x) => {
            if (containsRedo(x.changes)) {
              const redo = x.changes.find((y) => y.type === 'redo');
              const result = acc
                .filter(
                  (y) =>
                    !(
                      y.changes.find((z) => z.type === 'undo') &&
                      y.id === redo.value.target
                    ),
                )
                .filter((y) => !(y.id === x.id));
              return result;
            }
            return acc;
          }, reversedModificationItems),
      )(ms);

    const processUndos = (ms) =>
      pipe(
        sortBy((x) => x.id),
        (sortedModificationItems) =>
          sortedModificationItems.reduce((acc, item) => {
            let rv = acc;
            if (containsUndo(item.changes)) {
              const undo = item.changes.find((x) => x.type === 'undo');
              rv = rv.filter((x) => !(x.id === undo.value.source));
            } else {
              rv.push(item);
            }
            return rv;
          }, []),
        sortBy((x) => -x.id),
      )(ms);

    const insertGaps = (ms) => {
      let assignableId = -1;
      let listWithGaps = sortBy((x) => x.id, ms)
        .reduce(
          (acc, x) => {
            const parent = get(['parent'], acc);
            if (parent && parent.scenario !== x.scenario_parent) {
              const gap = {
                id: assignableId,
                scenario: x.scenario_parent,
                scenario_parent: parent.scenario,
                time_created: x.time_created,
                time_prefix: 'Over ',
                changes: [
                  {
                    type: 'gap',
                  },
                ],
              };
              assignableId -= 1;
              return {
                parent: x,
                arr: [...acc.arr, gap, x],
              };
            }
            return {
              parent: x,
              arr: [...acc.arr, x],
            };
          },
          { parent: undefined, arr: [] },
        )
        .arr.reverse();
      const fst = first(ms);
      if (fst && fst.scenario !== currentScenarioKey) {
        // gap at topmost item.
        const parent = fst;
        const gap = {
          id: assignableId,
          scenario: currentScenarioKey,
          scenario_parent: parent.scenario,
          time_created: parent.time_created,
          time_prefix: 'Less than ',
          changes: [
            {
              type: 'gap',
            },
          ],
        };
        listWithGaps = [gap, ...listWithGaps];
        assignableId -= 1;
      }
      const lst = last(ms);
      if (!containsTagStartCode(get('changes', lst))) {
        // gap at beginning of list.
        if (lst) {
          listWithGaps = [
            ...listWithGaps,
            {
              id: assignableId,
              scenario: currentScenarioKey,
              scenario_parent: null,
              time_created: lst.time_created,
              time_prefix: 'Over ',
              changes: [
                {
                  type: 'gap',
                },
              ],
            },
          ];
        } else {
          listWithGaps = [
            ...listWithGaps,
            {
              id: assignableId,
              scenario: currentScenarioKey,
              scenario_parent: null,
              time_created: undefined,
              time_string: 'Previously',
              changes: [
                {
                  type: 'gap',
                },
              ],
            },
          ];
        }
        assignableId -= 1;
      }
      return listWithGaps;
    };

    const cleanModificationItems = (ms) => {
      const allowedItems = [
        'add-attribute-value',
        'remove-attribute-value',
        'modify-attribute-value',
        'add-attribute-setting',
        'remove-attribute-setting',
        'modify-attribute-setting',
        'modify-population-input',
        'add-locations',
        'import-locations',
        'remove-locations',
        'clear-values',
        'gap',
        'code',
      ];
      return ms.filter((x) =>
        x.changes.every((change) => allowedItems.includes(change.type)),
      );
    };

    const processedModificationItems = cancelUndoRedoPairs(modifications);
    const modificationItemsRaw = processUndos(processedModificationItems);
    const modificationItemsWithGaps = insertGaps(modificationItemsRaw);

    const currentItem = get([0], modificationItemsWithGaps);
    const secondToLastItem = get([1], modificationItemsWithGaps);
    const latestUndoItem = processedModificationItems.find(
      (x) =>
        containsUndo(x.changes) &&
        x.id === get([0, 'id'], processedModificationItems),
    );

    const onUndo =
      secondToLastItem || modificationItemsWithGaps.length === 1
        ? () => {
            if (modificationItemsWithGaps.length === 1) {
              return handleDelete();
            }
            const currentItemId = currentItem.id < 0 ? null : currentItem.id;
            const secondToLastItemId =
              secondToLastItem.id < 0 ? null : secondToLastItem.id;
            const action = {
              type: 'undo',
              value: {
                source: currentItemId,
                target: secondToLastItemId,
                source_scenario: currentItem.scenario,
                target_scenario: currentItem.scenario_parent,
              },
            };
            const target = currentItem.scenario_parent;
            return onUpdateTag(target, [action]);
          }
        : undefined;
    const onRedo = latestUndoItem
      ? () => {
          const action = {
            type: 'redo',
            value: {
              target: latestUndoItem.id,
            },
          };
          const target = get(
            ['changes', 0, 'value', 'source_scenario'],
            latestUndoItem,
          );
          return onUpdateTag(target, [action]);
        }
      : undefined;
    const modificationItems = cleanModificationItems(modificationItemsWithGaps);

    return { onUndo, onRedo, modificationItems };
  })();

  const [showFinalUndoDialog, setShowFinalUndoDialog] = useState(false);
  const handleRedo = useCallback(() => onRedoImpl(), [onRedoImpl]);
  const handleUndo = useCallback(
    () =>
      modificationItemsArray.length === 1
        ? setShowFinalUndoDialog(true)
        : onUndoImpl(),
    [onUndoImpl, setShowFinalUndoDialog, modificationItemsArray],
  );
  const handleUndoCancel = useCallback(() => {
    setShowFinalUndoDialog(false);
  }, [setShowFinalUndoDialog]);
  const handleUndoConfirm = useCallback(() => {
    setShowFinalUndoDialog(false);
    onUndoImpl();
  }, [onUndoImpl]);

  useEventListener(
    'keydown',
    useCallback(
      (event) => {
        const isCommandKey = isMacOS && event.metaKey;
        const isCtrlKey = !isMacOS && event.ctrlKey;
        const isCommandOrControlKey = isCommandKey || isCtrlKey;
        const isUndoKeyCombo = isCommandOrControlKey && event.key === 'z';
        const isRedoKeyCombo = isMacOS
          ? isCommandKey && event.shiftKey && event.key === 'z'
          : isCtrlKey && event.key === 'y';
        if (onUndoImpl && isUndoKeyCombo && !isRedoKeyCombo) {
          handleUndo();
        }
        if (onRedoImpl && isRedoKeyCombo) {
          handleRedo();
        }
      },
      [onUndoImpl, onRedoImpl, handleUndo, handleRedo],
    ),
  );

  if (isSaving) {
    return <SavingSpan>Saving…</SavingSpan>;
  }

  if (published) {
    if (!updatedAt) {
      return null;
    }

    return (
      <Tooltip title={format('d MMM yyyy, h:mm a', touchedDate)}>
        <LastUpdatedSpan>
          Last updated {distance} {author && `by ${author}`}
        </LastUpdatedSpan>
      </Tooltip>
    );
  }

  return (
    <>
      <DropdownMenu justify='end'>
        <DropdownMenuButton as={UtilityButton} dropdown>
          <UnpublishedModificationsWrapper>
            {conflict && (
              <InfoCircleIconWrapper>
                <Icon type='exclamation-circle' />
              </InfoCircleIconWrapper>
            )}
            {modificationItemsArray.length} unpublished modification
            {count !== 1 && 's'}
          </UnpublishedModificationsWrapper>
        </DropdownMenuButton>
        <DropdownMenuList grouped minWidth={304}>
          <div>
            {conflict ? (
              <MenuParagraph>
                {author ? <b>{author}</b> : 'Someone'} published modifications
                to this scenario after you began your draft.
              </MenuParagraph>
            ) : (
              <DropdownMenuItem onSelect={handlePublish}>
                Publish
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={onSaveAsNewNamedScenario}>
              Save as new scenario
            </DropdownMenuItem>
            <DropdownMenuItem variant='danger' onSelect={handleDelete}>
              Discard
            </DropdownMenuItem>
          </div>
          <ModificationsList
            onRedo={onRedoImpl && handleRedo}
            onUndo={onUndoImpl && handleUndo}
            modificationItems={modificationItemsArray}
          />
        </DropdownMenuList>
      </DropdownMenu>
      {showConflictDialog && (
        <ConfirmationDialog
          cancelText="Don't publish"
          confirmText='Save as new scenario'
          icon='exclamation'
          onCancel={handleConflictDialogCancel}
          onConfirm={handleConflictDialogConfirm}
          title='We are unable to publish your modifications'
          variant='primary'
        >
          {author ? <b>{author}</b> : 'Someone'} published modifications to this
          scenario after you began your draft. You can save your modifications
          as a new scenario to avoid overwriting their work.
        </ConfirmationDialog>
      )}
      {showFinalUndoDialog && (
        <ConfirmationDialog
          cancelText='Don’t undo'
          confirmText='Undo modification and discard draft'
          onCancel={handleUndoCancel}
          onConfirm={handleUndoConfirm}
          title='Undo your last modification?'
          variant='danger'
          minWidth={320}
        >
          When you undo your last modification your current draft will be
          discarded. This cannot be redone.
        </ConfirmationDialog>
      )}
    </>
  );
};

PersistenceActions.propTypes = propTypes;
PersistenceActions.defaultProps = defaultProps;

export { PersistenceActions };
