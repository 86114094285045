const bubble = {
  notSelected: 0.25,
  normal: 0.65,
};

const typical = {
  notSelected: 0.45,
  normal: 1,
};

const areaFills = {
  notSelected: 0.2,
  normal: 0.45,
};

export { areaFills, bubble, typical as column, typical as line };
