import PropTypes from 'prop-types';
import { Tooltip, UtilityButton } from '@kinesis/bungle';
import { noop } from 'lodash/fp';

const propTypes = {
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: noop,
  disabled: false,
  tooltip: undefined,
};

// FIXME: The tooltip here currently won't show if disabled is passed. This is
// because the tooltip relies on the disabled state of the first child.
// cf. https://github.com/kinesisptyltd/bungle/issues/3735
const AddSeriesButton = ({ tooltip, disabled, onClick }) => {
  const button = (
    <UtilityButton
      magnitude='xsmall'
      disabled={disabled}
      icon='plus'
      onClick={onClick}
    >
      Add series
    </UtilityButton>
  );
  return tooltip ? (
    <Tooltip placement='top' title={tooltip}>
      {button}
    </Tooltip>
  ) : (
    button
  );
};

AddSeriesButton.propTypes = propTypes;
AddSeriesButton.defaultProps = defaultProps;

export { AddSeriesButton };
