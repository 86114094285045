import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const scenarioDelete = createAsyncThunk(
  'scenarios/delete',
  async ({ scenarioId, workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });

    await api.delete(
      `/api/workspace/${workspaceId}/namedScenario/${scenarioId}`,
    );

    return { scenarioId };
  },
);

export { scenarioDelete };
