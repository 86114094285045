import { FullStory, init as initFullStory } from '@fullstory/browser';

const FullStoryService = {
  initialised: false,

  initialise({
    analytics,
    emailAddress,
    firstName,
    fullStoryOrgId,
    lastName,
    userId,
  }) {
    if (analytics !== 'full') {
      if (this.initialised) {
        FullStory('shutdown');
      }
      return;
    }

    if (!fullStoryOrgId) {
      console.log('FullStory could not be initialised as orgId is undefined.');
      return;
    }

    initFullStory({ orgId: fullStoryOrgId });
    this.initialised = true;

    FullStory('restart');
    FullStory('setIdentity', {
      uid: userId,
      properties: {
        displayName: `${firstName} ${lastName}`,
        email: emailAddress,
      },
    });
  },

  shutdown() {
    if (this.initialised) {
      FullStory('shutdown');
    }
  },
};

export default FullStoryService;
