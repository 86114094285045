import axios from 'axios';
import { server } from './server';

const deployment = import.meta.env.MODE;

const deploymentHeaders =
  deployment !== 'production' ? { 'Kinesis-Deployment': deployment } : {};

function createApi(token, flags) {
  return axios.create({
    baseURL: server,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': 'application/json',
      'Kinesis-Feature-Flags': flags,
      ...deploymentHeaders,
    },
  });
}

export default createApi;
