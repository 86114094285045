import PropTypes from 'prop-types';
import { Anchor, Strong } from '@kinesis/bungle';

const EmptyMessage = () => (
  <div>
    Select a visualisation type above to start configuring your visualisation.
  </div>
);

// Non-editable, non-indicator
const StaticNonIndicatorMessage = () => (
  <div>
    The configuration for this type of visualisation cannot be changed yet.
  </div>
);

// No source, or board not linked to app
// (i.e. custom block, or any block on custom board)
const NoLinkedAppMessage = () => (
  <div>
    This visualisation was provided by an older version of an app and cannot be
    configured. Create a new visualisation instead.
  </div>
);

// Non-editable indicator.
// The app linked to the board is in need of an upgrade.
const AppNeedsUpgradeMessage = ({ appName, handleCheckForUpdate }) => (
  <div>
    <span>
      This visualisation was provided by an older version of{' '}
      <Strong>{appName}</Strong>.{' '}
    </span>
    <span>
      <Anchor onClick={handleCheckForUpdate}>Check for an update</Anchor>
    </span>
    <span> to this app to enable configuration for this visualisation.</span>
  </div>
);

AppNeedsUpgradeMessage.propTypes = {
  appName: PropTypes.string.isRequired,
  handleCheckForUpdate: PropTypes.func.isRequired,
};

export {
  AppNeedsUpgradeMessage,
  EmptyMessage,
  NoLinkedAppMessage,
  StaticNonIndicatorMessage,
};
