import PropTypes from 'prop-types';
import { ConfirmationDialog } from '@kinesis/bungle';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  animation: PropTypes.bool,
};

const defaultProps = {
  animation: true,
};

const ClearValuesConfirmationDialog = ({
  onCancel,
  onConfirm,
  label,
  animation,
}) => (
  <ConfirmationDialog
    animation={animation}
    cancelText='Don&rsquo;t remove'
    confirmText='Remove all values'
    icon='question'
    onCancel={onCancel}
    onConfirm={onConfirm}
    title={`Remove all values for “${label}”?`}
    variant='danger'
  >
    Values for this attribute will be removed from all locations in this
    scenario.
  </ConfirmationDialog>
);

ClearValuesConfirmationDialog.defaultProps = defaultProps;
ClearValuesConfirmationDialog.propTypes = propTypes;

export default ClearValuesConfirmationDialog;
