import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import populationDatasetVersionSelector from './populationDatasetVersionSelector';

const populationDatasetSelector = createSelector(
  populationDatasetVersionSelector,
  get(['custom', 'population', 'datasets']),
  get,
);

export default populationDatasetSelector;
