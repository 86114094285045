import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Inline, InlineItem, NumberInput, Slider } from '@kinesis/bungle';

const propTypes = {
  debounce: PropTypes.number,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

const defaultProps = {
  debounce: 500,
  value: undefined,
};

const SliderWithInputNumber = ({ debounce, max, min, onChange, value }) => {
  const [inputValue, setInputValue] = useState(value || min);

  const isValidValue =
    Number.isInteger(inputValue) && inputValue >= min && inputValue <= max;

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (isValidValue) {
        onChange(inputValue);
      } else {
        setInputValue(value);
      }
    }, debounce);

    return () => clearTimeout(timerId);
  }, [debounce, inputValue, isValidValue, onChange, value]);

  return (
    <Inline space='small'>
      <InlineItem sizing='fill-container'>
        <NumberInput
          magnitude='large'
          min={min}
          max={max}
          value={inputValue}
          onChange={setInputValue}
        />
      </InlineItem>
      <InlineItem sizing='fill-container'>
        <Slider
          highlightTrack
          min={min}
          max={max}
          value={inputValue}
          onChange={setInputValue}
        />
      </InlineItem>
    </Inline>
  );
};

SliderWithInputNumber.propTypes = propTypes;
SliderWithInputNumber.defaultProps = defaultProps;

export default SliderWithInputNumber;
