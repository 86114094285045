import styled from 'styled-components';

const BlockHeader = styled.h5`
  color: ${({ theme }) => theme.color.gray7};
  margin: 0;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  letter-spacing: 0.15px;
`;

const BlockGrid = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 0px;
  display: grid;
  align-items: center;
  max-width: 720px;
`;

export { BlockHeader, BlockGrid };
