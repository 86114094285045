import { createSelector } from 'reselect';
import { get, map } from 'lodash/fp';
import workspaceAppsSelector from './workspaceAppsSelector';

const workspaceAppDetailsSelector = createSelector(
  workspaceAppsSelector,
  get(['appVersions', 'entities']),
  get(['apps', 'entities']),
  (workspaceApps, appVersions, apps) =>
    map((workspaceApp) => {
      const appVersion = appVersions[workspaceApp.version];
      const app = apps[workspaceApp.app];

      return { label: get('label', app), ...appVersion };
    })(workspaceApps),
);

export default workspaceAppDetailsSelector;
