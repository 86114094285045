import { actions as blockSelectionsActions } from 'reducers/blockSelectionsReducer';
import { actions as miscActions } from 'reducers/miscReducer';
import { blockAdd } from 'actions/blockAdd';
import { get, last } from 'lodash/fp';

const { selectBlock } = blockSelectionsActions;
const { setNewTextBlockId } = miscActions;

function createBlockAddMiddleware() {
  return ({ dispatch }) =>
    (next) =>
    (action) => {
      next(action);
      switch (action.type) {
        case blockAdd.fulfilled.type: {
          const blockId = get('permalink', last(action.payload.blocks));
          const type = get('type', action.meta.arg);
          if (type !== 'text') {
            dispatch(selectBlock(blockId));
          } else {
            dispatch(setNewTextBlockId(blockId));
          }
          break;
        }
        default:
      }
    };
}

export default createBlockAddMiddleware;
