import styled from 'styled-components';

const EmptyScenariosCard = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1/ -1;
  height: 52px;
  justify-content: center;
  max-width: 640px;
  text-align: center;
  color: ${({ theme }) => theme.color.gray6};
`;

const PublishDraftsWrapper = styled.div`
  padding-left: 16px;
  margin-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.borderColor};
`;

const ScenarioIcon = styled.i`
  color: rgba(0, 0, 0, 0.45);
  display: inline-flex;
  height: 12px;
  margin-left: 4px;
  position: relative;
  top: 2px;
  width: 12px;
`;

export { EmptyScenariosCard, PublishDraftsWrapper, ScenarioIcon };
