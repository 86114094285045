import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Legend } from '@kinesis/bungle';
import { MapLegendIsland } from 'components/map-legend-island';

const propTypes = {
  colorHeading: PropTypes.string,
  colorStops: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  sizeHeading: PropTypes.string,
  sizeMin: PropTypes.string,
  sizeMax: PropTypes.string,
};

const defaultProps = {
  colorHeading: undefined,
  colorStops: undefined,
  sizeHeading: undefined,
  sizeMin: undefined,
  sizeMax: undefined,
};

const MapLegend = ({
  colorHeading,
  colorStops,
  sizeHeading,
  sizeMin,
  sizeMax,
}) => {
  const sizeItems = useMemo(() => {
    if (!sizeMin || !sizeMax) {
      return [];
    }

    if (sizeMin === sizeMax) {
      return [{ size: 'regular', label: sizeMax }];
    }

    return [
      { size: 'small', label: sizeMin },
      { size: 'regular', label: sizeMax },
    ];
  }, [sizeMin, sizeMax]);

  return (
    <MapLegendIsland className='map-legend'>
      {colorHeading && (
        <Legend
          heading={colorHeading}
          subheading='Colour'
          items={colorStops}
          icon='circle'
        />
      )}
      {sizeHeading && (
        <Legend
          heading={sizeHeading}
          subheading='Size'
          items={sizeItems}
          icon='circle'
        />
      )}
    </MapLegendIsland>
  );
};

MapLegend.propTypes = propTypes;
MapLegend.defaultProps = defaultProps;

export default MapLegend;
