import { useContext } from 'react';
import AppHeaderContext from './AppHeaderContext';

const useAppHeaderContext = () => {
  const context = useContext(AppHeaderContext);

  if (!context) {
    throw new Error('Missing AppHeaderProvider');
  }

  return context;
};

export default useAppHeaderContext;
