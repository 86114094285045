import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { first, get, map, max, size } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import blockSelectionsSelector from 'selectors/blockSelectionsSelector';
import RawBlock from 'components/block/raw-block';
import { calculateBlockHeight } from 'utils/blocks';
import { rawBlockTypes, rowBreakBlockTypes } from 'constants/block-types';
import BlockCard from './block-card';
import {
  BlockRowGrid,
  DropDestinationBar,
  NewBlockRowGridUnder,
  PlaceholderBlock,
} from './block-grid.styles';

const propTypes = {
  afterScroll: PropTypes.func,
  beforeCaptureDraggingId: PropTypes.string,
  blockRows: PropTypes.array.isRequired,
  blocks: PropTypes.array.isRequired,
  dragHasJustEnded: PropTypes.bool,
  draggingId: PropTypes.string,
  draggingSource: PropTypes.string,
  dropDestination: PropTypes.string,
  droppableId: PropTypes.string,
  forceDropBars: PropTypes.bool,
  handleDelete: PropTypes.func,
  isAboutToDrag: PropTypes.bool,
  latestBlockId: PropTypes.number,
  selectedRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  setDragHasJustEnded: PropTypes.func,
};

const defaultProps = {
  afterScroll: undefined,
  beforeCaptureDraggingId: undefined,
  dragHasJustEnded: undefined,
  draggingId: undefined,
  draggingSource: undefined,
  dropDestination: undefined,
  droppableId: undefined,
  forceDropBars: false,
  handleDelete: undefined,
  isAboutToDrag: undefined,
  latestBlockId: undefined,
  selectedRef: undefined,
  setDragHasJustEnded: undefined,
};

const BlockRow = ({
  afterScroll,
  beforeCaptureDraggingId,
  blockRows,
  blocks,
  dragHasJustEnded,
  draggingId,
  draggingSource,
  dropDestination,
  droppableId,
  forceDropBars,
  handleDelete,
  isAboutToDrag,
  latestBlockId,
  selectedRef,
  setDragHasJustEnded,
  ...restProps
}) => {
  const selectedBlockIds = useSelector(blockSelectionsSelector);

  const [minBlockRowHeight, setMinBlockRowHeight] = useState(0);
  useEffect(() => {
    if (dragHasJustEnded) {
      setMinBlockRowHeight(0);
      setDragHasJustEnded(false);
    }
  }, [dragHasJustEnded, setDragHasJustEnded]);

  const containerRef = useRef();
  useEffect(() => {
    if (beforeCaptureDraggingId && containerRef && containerRef.current) {
      setMinBlockRowHeight(containerRef.current.clientHeight);
    }
  }, [beforeCaptureDraggingId, containerRef]);

  useEffect(() => {
    if (
      map('id', blocks).includes(latestBlockId) &&
      get('current', containerRef)
    ) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
      if (afterScroll) afterScroll();
    }
  }, [afterScroll, blocks, containerRef, latestBlockId]);

  const handleSelectedBlockRef = useCallback(
    (block) =>
      size(selectedBlockIds) === 1 && first(selectedBlockIds) === block.id
        ? selectedRef
        : undefined,
    [selectedBlockIds, selectedRef],
  );

  const blockIsImplicitRowBreak = useMemo(
    () =>
      size(blocks) === 1 && rowBreakBlockTypes.includes(blocks[0].blockType),
    [blocks],
  );

  const blockIsSection = useMemo(
    () => size(blocks) === 1 && ['section'].includes(blocks[0].blockType),
    [blocks],
  );

  const draggingWasInRow = useMemo(
    () => map('id')(blocks).includes(draggingId),
    [blocks, draggingId],
  );

  const showDropBar = useMemo(
    () => get('droppableId', dropDestination) === droppableId,
    [dropDestination, droppableId],
  );

  const darkenPlaceholder = useMemo(() => {
    const draggingOverSource =
      get('droppableId', dropDestination) ===
        get('droppableId', draggingSource) &&
      get('index', dropDestination) === get('index', draggingSource);

    const beforeCaptureDraggingWasInRow = map('id')(blocks).includes(
      beforeCaptureDraggingId,
    );
    const draggingWasOnlyOneInThisRow =
      beforeCaptureDraggingWasInRow && size(blocks) === 1;
    const draggingOverThisRowNewRowZone =
      get('droppableId', dropDestination) === `new-row-${droppableId}`;

    const thisRowIndex = parseInt(
      droppableId.slice(droppableId.lastIndexOf('-') + 1),
      10,
    );
    const prevRowNewRowZoneDroppableId =
      thisRowIndex === 0
        ? 'new-row-top'
        : `new-row-block-row-${thisRowIndex - 1}`;

    const draggingOverPrevRowNewRowZone =
      get('droppableId', dropDestination) === prevRowNewRowZoneDroppableId;

    return (
      draggingOverSource ||
      (draggingWasOnlyOneInThisRow &&
        (draggingOverThisRowNewRowZone || draggingOverPrevRowNewRowZone))
    );
  }, [
    beforeCaptureDraggingId,
    blocks,
    draggingSource,
    dropDestination,
    droppableId,
  ]);

  const dropBar = useMemo(() => {
    if (!darkenPlaceholder) {
      const effectiveBlockCount = draggingWasInRow
        ? size(blocks) - 1
        : size(blocks);
      const droppingOnFullRow = !draggingWasInRow && size(blocks) === 3;

      let location;

      if (dropDestination) {
        if (dropDestination.index === 0) {
          location = 'left';
        } else if (effectiveBlockCount === dropDestination.index) {
          location = 'right';
        } else if (droppingOnFullRow) {
          if (dropDestination.index === 1) {
            location = 'center-left';
          } else {
            // dropDestination.index === 2
            location = 'center-right';
          }
        } else if (dropDestination.index === 1) {
          if (draggingWasInRow && size(blocks) === 3) {
            // dragging from edges of same row
            if (draggingSource.index === 0) {
              location = 'center-right';
            } else {
              location = 'center-left';
            }
          } else {
            // dragging into center of row with 2 blocks
            location = 'center';
          }
        }
      }
      return (
        <DropDestinationBar
          direction='vertical'
          location={location}
          visible={showDropBar}
        />
      );
    }
  }, [
    blocks,
    darkenPlaceholder,
    draggingSource,
    draggingWasInRow,
    dropDestination,
    showDropBar,
  ]);

  const disableNewRowDropBar = useMemo(() => {
    const beforeCaptureDraggingWasInRow = map('id')(blocks).includes(
      beforeCaptureDraggingId,
    );
    const draggingWasOnlyOneInThisRow =
      beforeCaptureDraggingWasInRow && size(blocks) === 1;

    const nextRowIndex =
      parseInt(droppableId.slice(droppableId.lastIndexOf('-') + 1), 10) + 1;
    const nextRow = blockRows[nextRowIndex];

    const draggingWasOnlyOneInNextRow =
      nextRow &&
      map('id')(nextRow.blocks).includes(beforeCaptureDraggingId) &&
      size(nextRow.blocks) === 1;

    return draggingWasOnlyOneInThisRow || draggingWasOnlyOneInNextRow;
  }, [beforeCaptureDraggingId, blockRows, blocks, droppableId]);

  const defaultSpan = useMemo(() => max([2, 6 / size(blocks)]), [blocks]);

  const getStartColumn = (blockIndex) => {
    if (blockIndex === 0) return 1;
    if (blockIndex === 1) {
      return size(blocks) === 2 ? 4 : 3;
    }
    return 5;
  };

  return (
    <>
      <Droppable
        direction='horizontal'
        droppableId={droppableId}
        isDropDisabled={blockIsImplicitRowBreak}
      >
        {(provided) => (
          <div ref={containerRef}>
            <BlockRowGrid
              blockIsSection={blockIsSection}
              enableReorderBlocks
              height={minBlockRowHeight}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {blocks.map((block, blockIndex) =>
                rawBlockTypes.includes(block.blockType) ? (
                  <>
                    <RawBlock
                      id={block.id}
                      blockType={block.blockType}
                      span={defaultSpan}
                      startColumn={getStartColumn(blockIndex)}
                      blockIndex={blockIndex}
                      removeBlocks={handleDelete}
                      isFirst={blockIndex === 0}
                      isLast={blockIndex === blocks.length - 1}
                    />
                    <PlaceholderBlock
                      height={block.height}
                      span={defaultSpan}
                      visible={draggingId === block.id}
                    />
                  </>
                ) : (
                  <BlockCard
                    blockIndex={blockIndex}
                    darkenPlaceholder={darkenPlaceholder}
                    draggingId={draggingId}
                    enableReorderBlocks
                    height={calculateBlockHeight(block, blocks)}
                    id={block.id}
                    key={block.id}
                    ref={handleSelectedBlockRef(block)}
                    removeBlocks={handleDelete}
                    span={defaultSpan}
                    startColumn={getStartColumn(blockIndex)}
                    {...restProps}
                  />
                ),
              )}

              {dropBar}
            </BlockRowGrid>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </div>
        )}
      </Droppable>

      <Droppable droppableId={`new-row-${droppableId}`} direction='horizontal'>
        {(provided, snapshot) => (
          <>
            <NewBlockRowGridUnder
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <DropDestinationBar
                direction='horizontal'
                visible={
                  forceDropBars ||
                  (snapshot.isDraggingOver && !disableNewRowDropBar)
                }
              />
            </NewBlockRowGridUnder>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </>
        )}
      </Droppable>
    </>
  );
};

BlockRow.propTypes = propTypes;
BlockRow.defaultProps = defaultProps;

export default BlockRow;
