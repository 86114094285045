import { createAsyncThunk } from '@reduxjs/toolkit';
import createApiFromState from 'api/createApiFromState';

const appAdd = createAsyncThunk(
  'apps/add',
  async ({ app, dependencies, workspaceId }, { dispatch, getState }) => {
    const api = createApiFromState({ dispatch, getState });
    const response = await api.post(`/api/workspace/${workspaceId}/add-app`, {
      dependencies,
      app,
    });
    return response.data;
  },
);

export { appAdd };
