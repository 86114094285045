import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Text, Anchor } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import { pathwayDownload } from 'actions/pathwayDownload';
import usePathwayInputs from 'hooks/usePathwayInputs';

const PathwaysVisualisationsDownloadLink = () => {
  const dispatch = useDispatch();
  const [inputs] = usePathwayInputs();

  const handleDownload = useCallback(() => {
    dispatch(pathwayDownload(inputs));
  }, [dispatch, inputs]);

  return (
    <Content padding='none' paddingBottom='large'>
      <Text>
        <Anchor as='button' type='button' onClick={handleDownload}>
          Download the complete data
        </Anchor>
        &nbsp;for more details and to compare this pathway with alternatives.
      </Text>
    </Content>
  );
};

export { PathwaysVisualisationsDownloadLink };
