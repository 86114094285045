import { useState, useMemo, useCallback } from 'react';
import useSelectorWithProps from 'hooks/useSelectorWithProps';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import {
  size,
  startsWith,
  unset,
  curry,
  set,
  keys,
  pipe,
  filter,
  first,
  find,
  get,
  toPairs,
  isEmpty,
  isNil,
  keyBy,
  reduce,
} from 'lodash/fp';
import { Stack, InPlaceInput, Select } from '@kinesis/bungle';
import { Content } from '@kinesis/bungle/legacy';
import {
  items,
  categories,
  flatten,
  ensureSettingItem,
  updateSettingItem,
  relabelItem,
  setItemDefinitionType,
  addItemToDefinitionWithCategory,
  addItemToInstantiationWithCategory,
  updateItemType,
} from 'data/attributes';
import FormSection from 'components/form-section/FormSection';
import settingsSelector from 'selectors/settingsSelector';
import { isItem, isCategory, validSettings } from 'utils/settingsModal';
import GenericSettings from './attribute-settings.generic';
import Distribution from './distribution';
import SettingsGrid from './settings-grid';

const propTypes = {
  errors: PropTypes.array,
  initialSelection: PropTypes.string,
  instantiation: PropTypes.object.isRequired,
  itemAddition: PropTypes.bool,
  setInstantiation: PropTypes.func.isRequired,
  setSetting: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

const defaultProps = {
  errors: [],
  initialSelection: undefined,
  itemAddition: false,
};

const description =
  'Usage describes how floor space is utilised and defines different dwelling typologies and yield.';

const unitLabels = {
  'floor-space': 'Non-residential',
  'floor-space-and-dwellings': 'Residential',
};

const fetchValueAt = (category, k, obj) =>
  pipe(
    get('categories'),
    keyBy('key'),
    get([category, 'fields']),
    keyBy('key'),
    get([k, 'value']),
  )(obj);

const UsageSettings = ({
  errors,
  initialSelection,
  instantiation,
  itemAddition,
  setInstantiation,
  setSetting,
  setting,
  workspaceId,
}) => {
  const [selections, setSelections] = useState(
    initialSelection ? [initialSelection] : [],
  );

  const [isHeaderInFocus, setIsHeaderInFocus] = useState(false);

  const settingsSpecifications = useSelectorWithProps(settingsSelector, {
    attribute: 'usage-v2',
    workspaceId,
  });

  const singleSelection = useMemo(
    () =>
      selections.length === 1
        ? pipe(flatten, filter({ key: first(selections) }), first)(setting)
        : undefined,
    [selections, setting],
  );

  const setDefaultDistributionFields = curry(
    (category, item, type, settingState) =>
      pipe(
        ensureSettingItem(category, `distribution-of-types`, {
          group: { label: 'Category settings' },
          label: `Default distribution of values by type`,
          key: `distribution-of-types`,
          value: {
            fields: {
              [type]: {
                value: 1,
                encoding: 'float64',
              },
            },
          },
        }),
        (base) => {
          const priorValue = fetchValueAt(
            category,
            'distribution-of-types',
            base,
          );
          const v = get(['fields', type, 'value'], priorValue);
          return updateSettingItem(
            category,
            `distribution-of-types`,
            set(
              ['fields', type],
              {
                value: isNil(v) ? 0 : v,
                encoding: 'float64',
              },
              priorValue,
            ),
            base,
          );
        },
        (base) =>
          ensureSettingItem(
            category,
            `distribution-of-values-${type}`,
            {
              group: { label: 'Category settings' },
              label: `Default distribution of values for ${type}`,
              key: `distribution-of-values-${type}`,
              value: {
                fields: {
                  [item]: {
                    value: isEmpty(
                      fetchValueAt(
                        category,
                        `distribution-of-values-${type}`,
                        base,
                      ),
                    )
                      ? 1
                      : 0,
                    encoding: 'float64',
                  },
                },
              },
            },
            base,
          ),
        (base) => {
          const priorValue = fetchValueAt(
            category,
            `distribution-of-values-${type}`,
            base,
          );
          const v = isEmpty(get('fields', priorValue))
            ? 1
            : get(['fields', item, 'value'], priorValue);
          return updateSettingItem(
            category,
            `distribution-of-values-${type}`,
            set(
              ['fields', item],
              {
                value: isNil(v) ? 0 : v,
                encoding: 'float64',
              },
              priorValue,
            ),
            base,
          );
        },
      )(settingState),
  );

  const onItemTypeChange = useCallback(
    (value) => {
      setSetting((current) => {
        const complete = setItemDefinitionType(
          singleSelection.key,
          value,
          current,
        );
        const category = complete.categories.find((c) =>
          find({ key: singleSelection.key }, c.entries),
        );
        return pipe(
          // sweep.
          (base) => {
            const priorFields = pipe(
              get('categories'),
              find({ key: category.key }),
              get('fields'),
              filter((f) => startsWith('distribution-of-values', f.key)),
            )(base);
            return reduce(
              (acc, field) =>
                updateSettingItem(
                  category.key,
                  field.key,
                  unset(['fields', singleSelection.key], get('value', field)),
                  acc,
                ),
              base,
              priorFields,
            );
          },
          // install.
          setDefaultDistributionFields(
            category.key,
            singleSelection.key,
            value,
          ),
          // fix.
          (base) => {
            const priorFields = pipe(
              get('categories'),
              find({ key: category.key }),
              get('fields'),
              filter((f) => startsWith('distribution-of-values', f.key)),
            )(base);
            return reduce(
              (acc, field) => {
                const ty = field.key.replace('distribution-of-values-', '');
                if (
                  !isNil(get(['value', 'fields'], field)) &&
                  size(get(['value', 'fields'], field)) === 0
                ) {
                  const priorField = pipe(
                    get('categories'),
                    find({ key: category.key }),
                    get('fields'),
                    find({ key: 'distribution-of-types' }),
                  )(base);
                  return updateSettingItem(
                    category.key,
                    'distribution-of-types',
                    unset(['fields', ty], get('value', priorField)),
                    acc,
                  );
                }
                if (
                  !isNil(get(['value', 'fields'], field)) &&
                  size(get(['value', 'fields'], field)) === 1
                ) {
                  return reduce(
                    (acc1, fieldKey) =>
                      updateSettingItem(
                        category.key,
                        field.key,
                        set(
                          ['fields', fieldKey],
                          {
                            value: 1,
                            encoding: 'float64',
                          },
                          get('value', field),
                        ),
                        acc1,
                      ),
                    acc,
                    keys(get(['value', 'fields'], field)),
                  );
                }
                return acc;
              },
              base,
              priorFields,
            );
          },
        )(complete);
      });
      setInstantiation(updateItemType(get('key', singleSelection), value));
    },
    [
      singleSelection,
      setSetting,
      setDefaultDistributionFields,
      setInstantiation,
    ],
  );

  const categoryHeading = useMemo(
    () =>
      isCategory(singleSelection)
        ? pipe(
            categories,
            keyBy('key'),
            get([singleSelection.key, 'label']),
          )(instantiation)
        : undefined,
    [singleSelection, instantiation],
  );

  const onChangeCategoryLabel = useCallback(
    (value) => {
      if (singleSelection) {
        setInstantiation((current) =>
          relabelItem(singleSelection.key, value, current),
        );
      }
    },
    [setInstantiation, singleSelection],
  );

  const itemHeading = useMemo(
    () =>
      isItem(singleSelection)
        ? pipe(
            items,
            keyBy('key'),
            get([get('key', singleSelection), 'label']),
          )(instantiation)
        : undefined,
    [singleSelection, instantiation],
  );

  const onChangeItemLabel = useCallback(
    (value) => {
      if (singleSelection) {
        setInstantiation((current) =>
          relabelItem(singleSelection.key, value, current),
        );
      }
    },
    [setInstantiation, singleSelection],
  );

  const addItem = (category) => () => {
    const key = nanoid();
    setSetting((current) => {
      const type = first(keys(unitLabels));
      const complete = addItemToDefinitionWithCategory(category, key, current, {
        type,
      });
      return setDefaultDistributionFields(category, key, type, complete);
    });
    setInstantiation((current) => {
      const type = first(keys(unitLabels));
      return addItemToInstantiationWithCategory(category, key, current, type);
    });
    setSelections([key]);
    setIsHeaderInFocus(true);
  };

  const activeSettings = useMemo(
    () => validSettings(singleSelection, settingsSpecifications),
    [singleSelection, settingsSpecifications],
  );

  const withDivider = (children) => (
    <Content borderPlacement='bottom' padding='none' paddingBottom='large'>
      {children}
    </Content>
  );

  const itemType = useMemo(
    () =>
      pipe(
        flatten,
        find({ key: get('key', singleSelection) }),
        get('item_type'),
      )(instantiation),
    [instantiation, singleSelection],
  );

  return (
    <GenericSettings
      addItem={addItem}
      description={description}
      errors={errors}
      instantiation={instantiation}
      itemAddition={itemAddition}
      selections={selections}
      setSelections={(x) => {
        setIsHeaderInFocus(false);
        return setSelections(x);
      }}
      setting={setting}
      workspaceId={workspaceId}
    >
      <FormSection key={get('key', singleSelection)}>
        <Stack space='medium'>
          {!isNil(categoryHeading) && (
            <InPlaceInput
              onChange={onChangeCategoryLabel}
              placeholder='Category name'
              value={
                !isEmpty(categoryHeading) ? categoryHeading : 'New category'
              }
              variant='title'
              autoFocus={isHeaderInFocus}
            />
          )}
          {!isNil(itemHeading) && (
            <InPlaceInput
              onChange={onChangeItemLabel}
              placeholder='Item name'
              value={!isEmpty(itemHeading) ? itemHeading : 'New item'}
              variant='title'
              autoFocus={isHeaderInFocus}
            />
          )}
          {isCategory(singleSelection) && (
            <Distribution
              category={singleSelection}
              instantiation={instantiation}
              setSetting={setSetting}
            />
          )}
          {isItem(singleSelection) &&
            withDivider(
              <Select
                label='Item type'
                onChange={onItemTypeChange}
                value={itemType}
                disabled={!isNil(itemType) && !get('newItem', singleSelection)}
                options={toPairs(unitLabels).map(([value, label]) => ({
                  label,
                  value,
                }))}
              />,
            )}

          {!isCategory(singleSelection) &&
            !isNil(get(['unit', 'type'], singleSelection)) && (
              <SettingsGrid
                item={singleSelection}
                setSetting={setSetting}
                settings={activeSettings}
                specifications={settingsSpecifications}
                workspaceId={workspaceId}
              />
            )}
        </Stack>
      </FormSection>
    </GenericSettings>
  );
};

UsageSettings.defaultProps = defaultProps;
UsageSettings.propTypes = propTypes;

export default UsageSettings;
