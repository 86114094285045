import { useState, useMemo } from 'react';
import { usePrevious } from '@kinesis/bungle';
import { differenceWith, isEqual, map, pipe, values } from 'lodash/fp';
import { useSelectorWithProps } from 'hooks';
import boundsSelector from 'selectors/boundsSelector';
import workspaceGeographiesSelector from 'selectors/workspaceGeographiesSelector';
import { coordinatesToBounds } from 'utils/spatialUtils';

const differenceDeep = differenceWith(isEqual);

function useExpandableBounds({ privacy, scenarioId, workspaceId }) {
  const [bounds, setBounds] = useState(
    useSelectorWithProps(boundsSelector, {
      public: privacy === 'public',
      scenarioId,
      workspaceId,
    }),
  );
  const geographies = useSelectorWithProps(workspaceGeographiesSelector, {
    public: privacy === 'public',
    workspaceId,
  });
  const coordinates = useMemo(
    () => pipe(values, map('point'))(geographies),
    [geographies],
  );

  const prevCoordinates = usePrevious(coordinates);

  if (!prevCoordinates) {
    return bounds;
  }

  const newLocations = map(
    ({ latitude, longitude }) => [longitude, latitude],
    differenceDeep(coordinates, prevCoordinates),
  );

  if (newLocations.length > 0) {
    if (bounds) {
      const newBounds = coordinatesToBounds([...bounds, ...newLocations]);

      if (!isEqual(newBounds, bounds)) {
        setBounds(newBounds);
      }
    } else {
      setBounds(coordinatesToBounds(newLocations));
    }
  }

  return bounds;
}

export default useExpandableBounds;
