import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LinkStyle } from 'components/styled/BaseStyles';

const ResettableFormItemWrapper = styled.div`
  position: relative;
`;

const ResetWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const ResetButton = styled(LinkStyle)`
  font-size: 10px;
  line-height: 18px;
`;

const propTypes = {
  children: PropTypes.node.isRequired,
  showReset: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
};

const defaultProps = {
  showReset: false,
};

const ResettableFormItem = ({ children, showReset, onReset }) => (
  <ResettableFormItemWrapper>
    {children}
    {showReset && (
      <ResetWrapper>
        <ResetButton onClick={onReset}>Reset</ResetButton>
      </ResetWrapper>
    )}
  </ResettableFormItemWrapper>
);

ResettableFormItem.propTypes = propTypes;
ResettableFormItem.defaultProps = defaultProps;

export default ResettableFormItem;
